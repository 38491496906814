import React, { useRef, useState, useEffect,useMemo  } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {

  getUren,
  removeUren,
} from "store/actions";
import Select from "react-select"
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import { startOfDay } from "date-fns";

const TrashModal = ({t, show, handleClose,selectedTrashItem}) => {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [sendNotification, setSendNotification] = useState(false);
  const {
    urendata,
    removeuren,
    error
  } = useSelector(state => ({
    removeuren: state.getShiftData.removeuren,
    urendata: state.getShiftData.urendata,
    error: state.getShiftData.error,
  }));

  //meta title xlsid
 
  const handleCheckboxChange = (event) => {
    setSendNotification(event.target.checked);
  };
  const handleRemove = () => {
    const formatDate = {
      date:selectedTrashItem.date,
      uren_id:selectedTrashItem.id,
      worker_id:selectedTrashItem.employers_id,
      sendNotification:sendNotification,
      type:'single',
      account_id:account_id,
     }
     dispatch(removeUren(formatDate));
  };

  // Handle remove all function (for recurring shifts)
  const handleRemoveAll = () => {
   const formatDate = {
    date:selectedTrashItem.date,
    uren_id:selectedTrashItem.id,
    worker_id:selectedTrashItem.employers_id,
    sendNotification:sendNotification,
    type:'all',
    account_id:account_id,
   }

   dispatch(removeUren(formatDate));
  };


  useEffect(() => {
    if (removeuren && removeuren.success) {
      toastr.success(t('Shift removed successfully! The selected Shift has been deleted and is no longer active'));
      dispatch(getUren(account_id));
    }else if (removeuren && removeuren.error) {
      toastr.error(t('Failed to remove the Shift. Please try again or contact support if the issue persists.'));
    }
  }, [removeuren, dispatch, account_id]);
  return (
    <React.Fragment>
      <Modal
                     
                      isOpen={show} 
                      toggle={handleClose} centered
                      style={{ bottom: '0' }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="mySmallModalLabel"
                        >
                          {t('Remove Shift')}
                        
                        </h5>
                        <button
                        onClick={handleClose}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <h5>{t('Do you want to delete all instances of this shifit, or just the selected shifit?')}</h5>
                      <div
                       className="form-check form-checkbox-outline form-check-success mb-3">
                        <input
          className="form-check-input"
          type="checkbox"
          id="send_mail_check"
          onChange={handleCheckboxChange} // Handle checkbox change
        />
                        <label className="form-check-label" htmlFor="send_mail_check">{t('Send Notification')}</label>
                        </div>
                       
                      </div>
                      <div className="modal-footer">
                      <button type="button" className="btn btn-secondary waves-effect" >{t('Cancel')}</button>
                      <button
        type="button"
        onClick={handleRemove} // Handle remove only this shift
        className="btn btn-danger waves-effect waves-light"
      >
        {t('Remove only this shift')}
      </button>
      
      {/* Remove all recurring shifts */}
      <button
        type="button"
        onClick={handleRemoveAll} // Handle remove all recurring shifts
        className="btn btn-danger waves-effect waves-light"
      >
        {t('Remove all recurring shifts')}
      </button>
                      </div>
                    </Modal>

    </React.Fragment>
  );
};
export default withTranslation()(withRouter(TrashModal));

