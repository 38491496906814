import {
    ADD_NEW_DOC,
    ADD_NEW_DOC_SUCCESS,
    ADD_NEW_DOC_FAIL,
    ADD_RECIEPIEN_DOC,
    ADD_RECIEPIEN_DOC_SUCCESS,
    ADD_RECIEPIEN_DOC_FAIL,
    GET_TEMPLATE,
    GET_TEMPLATE_SUCCESS,
    GET_TEMPLATE_FAIL,
    DELETE_TEPLATE,
    DELETE_TEPLATE_SUCCESS,
    DELETE_TEPLATE_FAIL,
    GET_TEMPLATE_DATA,
    GET_TEMPLATE_DATA_SUCCESS,
    GET_TEMPLATE_DATA_FAIL,
  } from "./actionTypes";
  
    // add stemp
    export const createNewDoc = doc => ({
      type: ADD_NEW_DOC,
      payload: doc,
    })
    
    export const createNewDocSuccess = doc => ({
      type: ADD_NEW_DOC_SUCCESS,
      payload: doc,
    })
    
    export const createNewDocFail = error => ({
      type: ADD_NEW_DOC_FAIL,
      payload: error,
    })

    // add Reciepien
    export const postNewReciepien = users => ({
      type: ADD_RECIEPIEN_DOC,
      payload: users,
    })
    
    export const postNewReciepienSuccess = users => ({
      type: ADD_RECIEPIEN_DOC_SUCCESS,
      payload: users,
    })
    
    export const postNewReciepienFail = error => ({
      type: ADD_RECIEPIEN_DOC_FAIL,
      payload: error,
    })


       // get teplate
       export const getTemplate = template => ({
        type: GET_TEMPLATE,
        payload: template,
      })
      
      export const getTemplateSuccess = template => ({
        type: GET_TEMPLATE_SUCCESS,
        payload: template,
      })
      
      export const getTemplateFail = error => ({
        type: GET_TEMPLATE_FAIL,
        payload: error,
      })

      export const deleteTemplate = deletetemp => ({
        type: DELETE_TEPLATE,
        payload: deletetemp,
      })
      
      export const deleteTemplateSuccess = deletetemp => ({
        type: DELETE_TEPLATE_SUCCESS,
        payload: deletetemp,
      })
      
      export const deleteTemplateFail = error => ({
        type: DELETE_TEPLATE_FAIL,
        payload: error,
      })


         // get teplate
       export const getTemplateData = templatedata => ({
        type: GET_TEMPLATE_DATA,
        payload: templatedata,
      })
      
      export const getTemplateDataSuccess = templatedata => ({
        type: GET_TEMPLATE_DATA_SUCCESS,
        payload: templatedata,
      })
      
      export const getTemplateDataFail = error => ({
        type: GET_TEMPLATE_DATA_FAIL,
        payload: error,
      })