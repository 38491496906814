import React, { useRef, useState, useEffect } from 'react';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import { map } from "lodash";
import '../../assets/scss/theme.scss';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  getSignatureImgByEmail,
  getSignatureImgByEmailSuccess,
  getSignatureImgByEmailFail,
  getDocument,
  getDocumentSuccess,
  getDocumentFail,
  postSigndata,
  postSigndataFail,
  postSigndatatSuccess
} from "store/actions";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const PostToDataBackend = (props) => {
  const { t, selectedZoom,permissions} = props;
      useEffect(() => {
        toastr.options = {
          positionClass: 'toast-top-center',
          closeButton: true,
          debug: true,
          progressBar:true,
          timeOut: 30000, // Duration in milliseconds
        };
      }, []);
      const dispatch = useDispatch();
      const {
        jsonpostdata,
    
        error
      } = useSelector(state => ({
        jsonpostdata: state.Prepare.jsonpostdata,
       
        error: state.Prepare.error,
      }));
      const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
      const isNotStarterAllowed = plan_status === 'active' && plan_name === 'enterprise';
      const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [token, settoken] = useState(user.account_token);
  const { uuid } = useParams();
  const postObjectFormToBacken = async (action) => { 
    const pdfContainer = document.querySelector('svg#pdf-container');
    const labelDataList = pdfContainer.querySelectorAll('[data-label]');
    if (labelDataList.length > '0') {
      const orderCounters = {};    
    const  UsersDimensions = {};
    const  UsersJson = {};
    const processedGroupContainers = new Set();
    labelDataList.forEach(item => {
      
      const dataType = item.getAttribute('data-type');
      const width = parseFloat(item.getAttribute('width'));
      const height = parseFloat(item.getAttribute('height'));
      const x = parseFloat(item.getAttribute('x'));
      const y = parseFloat(item.getAttribute('y'));
      const targetid = item.id;
      const format = item.dataset.type;
      const signContent = item.dataset.content;
      const roll = item.dataset.roll;
      const names = item.dataset.recipien;
      const Messeges = item.dataset.msg;
      const mails = item.dataset.mail;
      const recipienids = item.getAttribute('recipienid');
      const fontColor = item.dataset.fontColor;
      const fontSize = item.dataset.fontSize;
      const fontFormat = item.dataset.fontFormat;
      const fontFamily = item.dataset.fontFamily;
      const page = item.dataset.page;
      const dataFormat = item.dataset.dataFormat;
      const colors = item.getAttribute('stroke');
      const group = item.parentElement;

      const isSigned = 'false';
    const groupContainer = group.parentElement;
    if (!UsersJson[names]) {
      UsersJson[names] = {};
  }
      if (!UsersJson[names][format]) {
        // If the fullname does not exist in UsersDimensions, create a new object
        UsersJson[names][format] = {
          client_token: token,
          fullname: names,
          format: format,
          email: mails,
          messeges:Messeges,
          colors:colors,
          send_status:'sending',
          zoom_int:selectedZoom,
          sign_id:uuid,
          userid:recipienids,
          signContent:[],
          width: [],
          height: [],
          x: [],
          y: [],
          type: [],
          page:[],
          fgColor:[],
          bgColor:[],
          fontColor:[],
          fontSize:[],
          fontFormat:[],
          fontFamily:[],
          dataFormat:[],
          roll:[],
          min:[],
          max:[],
          selecttype:[],
          optionnumtype:[],
          valutacode:[],
          ischecked:[],
          groupContaineRule:[],
          groupContainevalidation:[],
          groupContaineMax:[],
          groupContaineMin:[],
          totalwidth:[],
          totalheight:[],
          containerX:[],
          containerY:[],
          selectValue:[],
          selectOption:[],
          labelValeu:[],
          targetid:[],
          isSigned:[],
          isOrder:[],
          groupContainerid:[],
          rectgroupContainerid:[],
        };
        orderCounters[names] = orderCounters[names] || {};
        orderCounters[names][format] = 0;
      }
      switch (dataType) {
        case 'handwriteing':
        case 'hand_praaf':
        case 'stemps':
        case 'calendar':
        case 'fullname':
        case 'email':
        case 'company':
        case 'title':
        case 'textarea':
        case 'drawing':
        case 'formula':
        case 'attachment': 
        case 'approve':
        case 'decline':
          UsersJson[names][format].width.push(width);
          UsersJson[names][format].height.push(height);
          UsersJson[names][format].x.push(x);
          UsersJson[names][format].y.push(y);
          UsersJson[names][format].type.push(format);
          UsersJson[names][format].signContent.push(signContent);
          UsersJson[names][format].page.push(page);
          UsersJson[names][format].fontColor.push(fontColor);
          UsersJson[names][format].fontSize.push(fontSize);
          UsersJson[names][format].fontFormat.push(fontFormat);
          UsersJson[names][format].fontFamily.push(fontFamily);
          UsersJson[names][format].dataFormat.push(dataFormat);
          UsersJson[names][format].roll.push(roll); 
          UsersJson[names][format].targetid.push(targetid); 
          UsersJson[names][format].isSigned.push(isSigned); 
          UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 
          // Additional properties for other data types isSigned group  isOrder:[],
          break;
        case 'qrcode':
     const fgColor = item.dataset.fgColor;
     const bgColor = item.dataset.bgColor;
     UsersJson[names][format].fgColor.push(fgColor);
     UsersJson[names][format].bgColor.push(bgColor);
     UsersJson[names][format].width.push(width);
     UsersJson[names][format].height.push(height);
     UsersJson[names][format].x.push(x);
     UsersJson[names][format].y.push(y);
     UsersJson[names][format].type.push(format);
     UsersJson[names][format].signContent.push(signContent);
     UsersJson[names][format].page.push(page);
     UsersJson[names][format].fontColor.push(fontColor);
     UsersJson[names][format].fontSize.push(fontSize);
     UsersJson[names][format].fontFormat.push(fontFormat);
     UsersJson[names][format].fontFamily.push(fontFamily);
     UsersJson[names][format].dataFormat.push('Y-m-d');
     UsersJson[names][format].roll.push(roll);
     UsersJson[names][format].targetid.push(targetid);  
     UsersJson[names][format].isSigned.push(isSigned); 
     UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 
          // Additional properties for other data types
          break;
        // Add more cases as needed for other data types
          case 'number':
          case 'payment':
            const selecttype = item.dataset.selecttype;
            const optionnumtype = item.dataset.optionnumtype;
            const valutacode = item.dataset.valutacode;
            const Max = item.dataset.max;
            const Min = item.dataset.min; 
            const labelValeu = item.dataset.label;   
            UsersJson[names][format].labelValeu.push(labelValeu);        
            UsersJson[names][format].width.push(width);
            UsersJson[names][format].height.push(height);
            UsersJson[names][format].x.push(x);
            UsersJson[names][format].y.push(y);
            UsersJson[names][format].type.push(format);
            UsersJson[names][format].signContent.push(signContent);
            UsersJson[names][format].page.push(page);
            UsersJson[names][format].fontColor.push(fontColor);
            UsersJson[names][format].fontSize.push(fontSize);
            UsersJson[names][format].fontFormat.push(fontFormat);
            UsersJson[names][format].fontFamily.push(fontFamily);
            UsersJson[names][format].dataFormat.push(dataFormat);
            UsersJson[names][format].selecttype.push(selecttype);
            UsersJson[names][format].optionnumtype.push(optionnumtype);
            UsersJson[names][format].valutacode.push(valutacode);
            UsersJson[names][format].max.push(Max);
            UsersJson[names][format].min.push(Min);
            UsersJson[names][format].roll.push(roll); 
            UsersJson[names][format].targetid.push(targetid);
            UsersJson[names][format].isSigned.push(isSigned); 
            UsersJson[names][format].isOrder.push(orderCounters[names][format]++);     
        break;
        case 'dropdown':
          const selectValue = item.dataset.value;
          const selectOption = item.dataset.option;
          if (!selectValue || !selectOption) {
            toastr.error(t('Select value is required!'));
            // Optionally, you might want to stop further processing if the validation fails
            return; // Stop processing further if the validation fails
          }
            // Proceed with pushing data to UsersDimensions
            UsersJson[names][format].width.push(width);
            UsersJson[names][format].height.push(height);
            UsersJson[names][format].x.push(x);
            UsersJson[names][format].y.push(y);
            UsersJson[names][format].type.push(format);
            UsersJson[names][format].signContent.push(signContent);
            UsersJson[names][format].page.push(page);
            UsersJson[names][format].fontColor.push(fontColor);
            UsersJson[names][format].fontSize.push(fontSize);
            UsersJson[names][format].fontFormat.push(fontFormat);
            UsersJson[names][format].fontFamily.push(fontFamily);
            UsersJson[names][format].dataFormat.push('Y-m-d');
            UsersJson[names][format].roll.push(roll);
            UsersJson[names][format].selectValue.push(selectValue);
            UsersJson[names][format].selectOption.push(selectOption);
            UsersJson[names][format].targetid.push(targetid);
            UsersJson[names][format].isSigned.push(isSigned); 
            UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 

          break;
        case 'checkbox':
          case 'radio':
          const rectgroupContainerid = item.dataset.groupContainerid
            const group = item.parentElement;
            const groupContainer = group.parentElement;
            const isChecked = item.dataset.checked;
            if (!processedGroupContainers.has(groupContainer)) {
              
              processedGroupContainers.add(groupContainer);
              const groupContainerRule = groupContainer.dataset.rule;
              const groupContainerValidation = groupContainer.dataset.validation;
              const groupContainerMax = groupContainer.dataset.max;
              const groupContainerMin = groupContainer.dataset.min;
              const totalWidth = groupContainer.dataset.totalwidth;
              const totalHeight = groupContainer.dataset.totalheight;
              const containerX = groupContainer.dataset.containerX;
              const containerY = groupContainer.dataset.containerY;
              const groupContainerid = groupContainer.id;
              // Assuming UsersJson, names, format, width, height, x, y, signContent, page, fontColor, fontSize, fontFormat, fontFamily, dataFormat, roll, targetid, isSigned are already defined
              UsersJson[names][format].groupContainerid.push(groupContainerid);
              UsersJson[names][format].groupContaineRule.push(groupContainerRule);
              UsersJson[names][format].groupContainevalidation.push(groupContainerValidation);
              UsersJson[names][format].groupContaineMax.push(groupContainerMax);
              UsersJson[names][format].groupContaineMin.push(groupContainerMin);
              UsersJson[names][format].totalwidth.push(totalWidth);
              UsersJson[names][format].totalheight.push(totalHeight);
              UsersJson[names][format].containerX.push(containerX);
              UsersJson[names][format].containerY.push(containerY);
           
            }
            UsersJson[names][format].rectgroupContainerid.push(rectgroupContainerid);
            UsersJson[names][format].width.push(width);
            UsersJson[names][format].height.push(height);
            UsersJson[names][format].x.push(x);
            UsersJson[names][format].y.push(y);
            UsersJson[names][format].type.push(format);
            UsersJson[names][format].signContent.push(signContent);
            UsersJson[names][format].page.push(page);
            UsersJson[names][format].fontColor.push(fontColor);
            UsersJson[names][format].fontSize.push(fontSize);
            UsersJson[names][format].fontFormat.push(fontFormat);
            UsersJson[names][format].fontFamily.push(fontFamily);
            UsersJson[names][format].dataFormat.push(dataFormat);
            UsersJson[names][format].roll.push(roll);
            UsersJson[names][format].ischecked.push(isChecked);
            UsersJson[names][format].targetid.push(targetid);
            UsersJson[names][format].isSigned.push(isSigned);
            UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 
            break;
        

        default:
          // Handle other data types if necessary
      }
      if (!UsersDimensions[names]) {
        // If the fullname does not exist in UsersDimensions, create a new object
        UsersDimensions[names] = {
          client_token: token,
          fullname: names,
          format: format,
          email: mails,
          messeges:Messeges,
          colors:colors,
          send_status:'sending',
          zoom_int:selectedZoom,
          sign_id:uuid,
          userid:recipienids,
          signContent:[],
          width: [],
          height: [],
          x: [],
          y: [],
          type: [],
          page:[],
          fgColor:[],
          bgColor:[],
          fontColor:[],
          fontSize:[],
          fontFormat:[],
          fontFamily:[],
          dataFormat:[],
          roll:[],
          min:[],
          max:[],
          selecttype:[],
          optionnumtype:[],
          valutacode:[],
          ischecked:[],
          groupContaineRule:[],
          groupContainevalidation:[],
          groupContaineMax:[],
          groupContaineMin:[],
          selectValue:[],
          selectOption:[],
        };
        
      }
      switch (dataType) {
        case 'handwriteing':
        case 'hand_praaf':
        case 'stemps':
        case 'calendar':
        case 'fullname':
        case 'email':
        case 'company':
        case 'title':
        case 'textarea':
        case 'drawing':
        case 'formula':
        case 'attachment': 
        case 'approve':
        case 'decline':
          UsersDimensions[names].width.push(width);
          UsersDimensions[names].height.push(height);
          UsersDimensions[names].x.push(x);
          UsersDimensions[names].y.push(y);
          UsersDimensions[names].type.push(format);
          UsersDimensions[names].signContent.push(signContent);
          UsersDimensions[names].page.push(page);
          UsersDimensions[names].fontColor.push(fontColor);
          UsersDimensions[names].fontSize.push(fontSize);
          UsersDimensions[names].fontFormat.push(fontFormat);
          UsersDimensions[names].fontFamily.push(fontFamily);
          UsersDimensions[names].dataFormat.push(dataFormat);
          UsersDimensions[names].roll.push(roll);  
          // Additional properties for other data types
          break;
        case 'qrcode':
     const fgColor = item.dataset.fgColor;
     const bgColor = item.dataset.bgColor;
     UsersDimensions[names].fgColor.push(fgColor);
     UsersDimensions[names].bgColor.push(bgColor);
     UsersDimensions[names].width.push(width);
     UsersDimensions[names].height.push(height);
     UsersDimensions[names].x.push(x);
     UsersDimensions[names].y.push(y);
     UsersDimensions[names].type.push(format);
     UsersDimensions[names].signContent.push(signContent);
     UsersDimensions[names].page.push(page);
     UsersDimensions[names].fontColor.push(fontColor);
     UsersDimensions[names].fontSize.push(fontSize);
     UsersDimensions[names].fontFormat.push(fontFormat);
     UsersDimensions[names].fontFamily.push(fontFamily);
     UsersDimensions[names].dataFormat.push('Y-m-d');
     UsersDimensions[names].roll.push(roll);  
          // Additional properties for other data types
          break;
        // Add more cases as needed for other data types
          case 'number':
          case 'payment':
            const selecttype = item.dataset.selecttype;
            const optionnumtype = item.dataset.optionnumtype;
            const valutacode = item.dataset.valutacode;
            const Max = item.dataset.max;
            const Min = item.dataset.min;             
          UsersDimensions[names].width.push(width);
          UsersDimensions[names].height.push(height);
          UsersDimensions[names].x.push(x);
          UsersDimensions[names].y.push(y);
          UsersDimensions[names].type.push(format);
          UsersDimensions[names].signContent.push(signContent);
          UsersDimensions[names].page.push(page);
          UsersDimensions[names].fontColor.push(fontColor);
          UsersDimensions[names].fontSize.push(fontSize);
          UsersDimensions[names].fontFormat.push(fontFormat);
          UsersDimensions[names].fontFamily.push(fontFamily);
          UsersDimensions[names].dataFormat.push(dataFormat);
          UsersDimensions[names].selecttype.push(selecttype);
          UsersDimensions[names].optionnumtype.push(optionnumtype);
          UsersDimensions[names].valutacode.push(valutacode);
          UsersDimensions[names].max.push(Max);
          UsersDimensions[names].min.push(Min);
          UsersDimensions[names].roll.push(roll);    
        break;
        case 'checkbox':
          case 'radio':
            const group = item.parentElement;
            const groupContainer = group.parentElement;
            const ischecked = item.dataset.checked;
            const groupContaineRule = groupContainer.dataset.rule;
            const groupContainevalidation = groupContainer.dataset.validation;
            const groupContaineMax = groupContainer.dataset.max;
            const groupContaineMin = groupContainer.dataset.min;
            
            UsersDimensions[names].width.push(width);
            UsersDimensions[names].height.push(height);
            UsersDimensions[names].x.push(x);
            UsersDimensions[names].y.push(y);
            UsersDimensions[names].type.push(format);
            UsersDimensions[names].signContent.push(signContent);
            UsersDimensions[names].page.push(page);
            UsersDimensions[names].fontColor.push(fontColor);
            UsersDimensions[names].fontSize.push(fontSize);
            UsersDimensions[names].fontFormat.push(fontFormat);
            UsersDimensions[names].fontFamily.push(fontFamily);
            UsersDimensions[names].dataFormat.push(dataFormat);
            UsersDimensions[names].roll.push(roll);
            UsersDimensions[names].ischecked.push(ischecked);
            UsersDimensions[names].groupContaineRule.push(groupContaineRule);
            UsersDimensions[names].groupContainevalidation.push(groupContainevalidation);
            UsersDimensions[names].groupContaineMax.push(groupContaineMax);
            UsersDimensions[names].groupContaineMin.push(groupContaineMin);
            break;
          case 'dropdown':
          const selectValue = item.dataset.value;
          const selectOption = item.dataset.option;
          if (!selectValue || !selectOption) {
            toastr.error(t('Select value is required!'));
            // Optionally, you might want to stop further processing if the validation fails
            return; // Stop processing further if the validation fails
          }
            // Proceed with pushing data to UsersDimensions
            UsersDimensions[names].width.push(width);
            UsersDimensions[names].height.push(height);
            UsersDimensions[names].x.push(x);
            UsersDimensions[names].y.push(y);
            UsersDimensions[names].type.push(format);
            UsersDimensions[names].signContent.push(signContent);
            UsersDimensions[names].page.push(page);
            UsersDimensions[names].fontColor.push(fontColor);
            UsersDimensions[names].fontSize.push(fontSize);
            UsersDimensions[names].fontFormat.push(fontFormat);
            UsersDimensions[names].fontFamily.push(fontFamily);
            UsersDimensions[names].dataFormat.push('Y-m-d');
            UsersDimensions[names].roll.push(roll);
            UsersDimensions[names].selectValue.push(selectValue);
            UsersDimensions[names].selectOption.push(selectOption);

          break;

        default:
          // Handle other data types if necessary
      }
      
    });
    const usersArray = Object.values(UsersDimensions);
    const jsonArray = Object.values(UsersJson);
    // Convert FormData to JSON object data-fonteot={"LovlyTropical.ttf"}
    const formObject = {
      folder:action,
      uuid: uuid,
      users: usersArray,
      jsondata: jsonArray,
    };
    
      try {
        
   
        
        // Dispatch the action to initiate the post request
      await dispatch(postSigndata(formObject));
  
      } catch (error) {
        toastr.error(t('Error Post signature data!'));
        // Additional logic or state updates after failure
      }
    
    }else{
      toastr.error(t('you can not be post empty'));
    }
    
  }
  useEffect(() => {
    if (!jsonpostdata.message) {
      // Do nothing if jsonpostdata is undefined or empty
      return;
    }
    if (jsonpostdata.success) {
      toastr.success(t('Envelope Successfully Completed'));
    } else if (!jsonpostdata.success) {
      toastr.error(jsonpostdata.message);
    } else {
      toastr.error(t('No response from server!'));
    }
  }, [jsonpostdata, dispatch]);
  return (
    <div className=" css-x9h4z9">
    <div className='css-jus432'>
    <button
         type="button"

         className="olv-button olv-ignore-transform css-3rwt7q" >
         <span data-qa="footer-prev-link-text" className="css-16hz1ch">{t('Back')}</span>
         </button>
         <button   
         className="olv-button olv-ignore-transform css-1n4kelk"
         type="button"
         onClick={() => postObjectFormToBacken('inbox')}
         >
           <span data-qa="footer-send-button-text" className="css-16hz1ch" >{t('Send')}</span>
           </button>
    </div>
     </div>
  );
};

export default withTranslation()(withRouter(PostToDataBackend));
