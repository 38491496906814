import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { utils, writeFile } from 'xlsx';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Table,
} from "reactstrap";

import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from "classnames";
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getReportData,
  postReportData,
  removeReportData,
} from "store/actions";
import { withTranslation } from "react-i18next";
function formatDate(dateString) {
  const date = new Date(dateString); // Convert string to Date object
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
const ShiftRaport = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = t("User Profile - View and Edit Your Personal Information | eSignatureHub.com");
  }, [t]);
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isEditorAllowed = editor === 1;

  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [token, setToken] = useState(user.token);
  const [team, setTeam] = useState(''); // Team selection
  const [department, setDepartment] = useState(''); // Department selection
  const [employer, setEmployer] = useState(''); // Employer selection
  const [contractType, setContractType] = useState(''); // Contract Type selection
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [reportType, setReportType] = useState('');
  const [apply_dateRange, setApply_DateRange] = useState([null, null]);
  const [apply_startDate, apply_endDate] = apply_dateRange;
  const [reportName, setReportName] = useState('');
  const [modal_scroll, setmodal_scroll] = useState(false);
  const itemsPerPage = 11; 
  const [currentPage_ab, setCurrentPage_ab] = useState(1);
  const {
    getreportdata,
    postreportdata,
    removereportdata,
    error
  } = useSelector(state => ({
    getreportdata: state.getReportData.getreportdata,
    postreportdata: state.getReportData.postreportdata,
    removereportdata: state.getReportData.removereportdata,
    error: state.getReportData.error,

  }));
  //meta title
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReportData(account_id));  
  }, [dispatch]);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }


  
  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }
 
  const filteredReportData = getreportdata 
  ? getreportdata.filter(
      item => 
        item.report_type === 'Urenregistratie totalen' || 
        item.report_type === 'Urenregistratie gedetailleerd'
    )
  : [];
  const totalItems =  filteredReportData ? filteredReportData.length : 0;
const indexOfLastItem = currentPage_ab * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems =  filteredReportData ? filteredReportData.slice(indexOfFirstItem, indexOfLastItem): [];
const handlePageChange = (newPage) => {
  setCurrentPage_ab(newPage);
}
const handleReportTypeChange = (e) => {
  setReportType(e.target.value);
};
const handleApply_DateChange = (update) => {
    
  setApply_DateRange(update); // Update the date range (start and end)
};

const handleReportNameChange = (e) => {
  setReportName(e.target.value);
}

const handleSubmit = async (e) => {
  e.preventDefault();

  // Prepare form data
  const formData = {
    reportType: reportType,
    startDate: formatDate(apply_startDate),
    endDate: formatDate(apply_endDate),
    reportName: reportName,
    account_id:account_id,
    user_id:userId,
  };
  dispatch(postReportData(formData));
 
};

const DownloadXls = (data,report_type) => {
  const parsedData = Array.isArray(data) ? data : JSON.parse(data);
  if (!Array.isArray(parsedData)) {
    console.error('Parsed data is not an array');
    return;
  }
if (report_type === 'Urenregistratie totalen') {
 // Grouping data by employer

 const groupedByEmployer = parsedData.reduce((acc, item) => {
  if (!acc[item.employers_id]) {
    acc[item.employers_id] = {
      items: [],
      employersId: item.employers_id,
      employeeName:  `${item.frist_name} ${item.last_name}` || t('Unknown Employee'), // Default value if name is missing
      profileImage: item.profile_img || 'default_profile_image_url.jpg', // Fallback to a default image
      Contracturen: item.totalweek,
      Dagen: item.days,
      Diensten: item.shift_name,
      Toeslagen: item.bonus || '0',
      Loonkosten: item.uurloon,
    };
  }
  acc[item.employers_id].items.push(item);
  return acc;
}, {});

const reportData = [];

// Loop through each employer
Object.keys(groupedByEmployer).map(employerId => { 
  const { items, employeeName, profileImage, employersId, Contracturen, Dagen, Diensten, Toeslagen, Loonkosten } = groupedByEmployer[employerId];

  let totalHours = 0;
  let totalMinutes = 0;

  // Calculate total worked hours for the employer
  items.map(item => {
    const workedDayTotalHours = item.total_day_hour;
    const [hours, minutes] = workedDayTotalHours.split(':').map(Number);
    totalHours += hours;
    totalMinutes += minutes;
  });

  // Convert extra minutes to hours
  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes = totalMinutes % 60;

  // Format the total hours and minutes as 'HH:MM'
  const formattedTotalHours = String(totalHours).padStart(2, '0');
  const formattedTotalMinutes = String(totalMinutes).padStart(2, '0');
  const totalLoonSalary = formattedTotalHours * Loonkosten
  const totalWorkedHoursInDecimal = totalHours + (totalMinutes / 60)
  const costToBusiness = (totalWorkedHoursInDecimal * Loonkosten) + Number(Toeslagen);
  // Add to report data
  reportData.push({
    [t('Employer ID')]: employersId,
    [t('Employee Name')]: employeeName,
    [t('Profile Image')]: profileImage,
    [t('Contract Hours')]: Contracturen,
    [t('Worked Days')]: Dagen,
    [t('Shifts')]: Diensten,
    [t('Total Worked Hours')]: `${formattedTotalHours}:${formattedTotalMinutes}`,
    [t('Surcharge')]: Toeslagen,
    [t('Labor Costs')]: totalLoonSalary.toFixed(2),
    [t('Cost to Business')]: costToBusiness.toFixed(2),
  });
});


const worksheet = utils.json_to_sheet(reportData);
const workbook = utils.book_new(); 
utils.book_append_sheet(workbook, worksheet, 'Report'); 
writeFile(workbook, 'TimeRegistrationTotals.xlsx'); 

}else if(report_type === 'Urenregistratie gedetailleerd'){
  // Calculate total worked hours for the employer
  const newArray = parsedData.map(item => {
    const employers_id = item.employers_id;
    const frist_name = `${item.frist_name} ${item.last_name}`;
    const start_time_shift = item.start_time_shift;
    const eind_time_shift = item.eind_time_shift;
    const punchIn = item.start_time_uren;
    const punchOut = item.eind_time_uren;
    const Pauze = item.pause_uren;
    const Dienst = item.shift_name;
    const department = item.dep_name;
    const Team = item.team_name;
    const Contracttype = item.type_name;
    const workedDayTotalHours = item.total_day_hour;
    const Toeslagen  = item.total_day_hour;
    const Loonkosten = item.uurloon;
    const Contracturen = item.totalweek;
    const Gekeurd = item.keuring;
    const urenid = item.uren_id ;
    const shiftid = item.shift_id_uren;
    const depid = item.departmen_id_uren;
    const temaid = item.team_id_uren;
    const Contracttypeid = item.contract_type;
    // Return a new object with the properties you need
    return {
      [t('Employer ID')]: employers_id,  // Using dynamic key with translation
      [t('Employee Name')]: frist_name,
      [t('Shift Start Time')]: start_time_shift,
      [t('Shift End Time')]: eind_time_shift,
      [t('Punch-In')]: punchIn,
      [t('Punch-Out')]: punchOut,
      [t('Pauze')]: Pauze,
      [t('Shift name')]: Dienst,
      [t('Department Name')]: department,
      [t('Team Name')]: Team,
      [t('Contract Type')]: Contracttype,
      [t('worked')]: workedDayTotalHours,
      [t('Surcharge')]: Toeslagen,
      [t('Wage costs')]: Loonkosten,
      [t('Contract hours')]: Contracturen,
      [t('Approved')]: Gekeurd, // date
      [t('Rooster id')]: urenid,
      [t('Shift id')]: shiftid,
      [t('Department id')]: depid,
      [t('Team id')]: temaid,
      [t('Contract id')]: Contracttypeid,
    };
    
  });


  const worksheet = utils.json_to_sheet(newArray);
const workbook = utils.book_new(); 
utils.book_append_sheet(workbook, worksheet, 'Report'); 
writeFile(workbook, 'TimeRegistrationDetails.xlsx'); 

}
 
};
const removeModal = (id) => {
  if (id) {
    dispatch(removeReportData(id));
  }

};


useEffect(() => {
  if (removereportdata && removereportdata.success) {
    toastr.success(t('Folder created successfully.'));
    dispatch(getReportData(account_id));   
  }

}, [removereportdata])
useEffect(() => {
  if (postreportdata && postreportdata.success) {
    toastr.success(t('Folder created successfully.'));
    dispatch(getReportData(account_id));    

  }

}, [postreportdata]);
  return (
    <React.Fragment>
           <Modal
                      isOpen={modal_scroll}
                      toggle={() => {
                        tog_scroll();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                        {t('Apply Report')}
                        </h5>
                        <button
                          type="button"
                          onClick={() =>
                            setmodal_scroll(false)
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <form onSubmit={handleSubmit}>
  <div className="row">
    <div className="col-lg-12">
     <Row>
     <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="approved" className="col-md-2 col-form-label">
        {t('Inspection')}
  </label>
  <div className="col-lg-8">

  <select
         className="css-12ihcxq"
         name="report_type"
         id="report_type"
         value={reportType}
         onChange={handleReportTypeChange}
        >

            <option value="">{t('Select Report Type')}</option>
            <option value="Urenregistratie totalen">{t('Timesheet total')}</option>
            <option value="Urenregistratie gedetailleerd">{t('Timesheet detailed')}</option>
        </select>

  </div>
      </div>

      </Col>

      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="Period" className="col-md-2 col-form-label">
        {t('Period')}
        </label>
        <div className="col-lg-8">
        <DatePicker
          className="css-12ihcxq"
          selectsRange={true}
          startDate={apply_startDate}
          endDate={apply_endDate}
          onChange={handleApply_DateChange}
          dateFormat={'yyyy-MM-dd'}
          placeholderText="Select start and end date"
          isClearable={true}
        />
    
        </div>
      </div>
       </Col>
  
   
       <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="approved" className="col-md-2 col-form-label">
        {t('Name')}
  </label>
  <div className="col-lg-8">

  <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="report_name"
                  placeholder={t("Type report name..")}
                  value={reportName}
                  onChange={handleReportNameChange}
                  required
                />
              </div>
  </div>
      </div>

      </Col>
    

     
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light"  onClick={() => {setmodal_scroll(false)}}>
                            
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>
                       
                      </div>
                    </Modal>
    <div className="inbox-container">
      {/* Header */}
      <div className="css-rapors32" style={{ padding: '10px' }}>
        <div className="row" style={{ fontWeight:'700' }}>
      
        <Button
        id="pdfButton1"
        type="button"
        className="btn  css-1bl3roz"
        style={{ background: '#d6d8da',color:'#000000', border: 'none',height:'40px',justifyContent:'space-between' }}
        >
           {t('Shift Report')}
           <div className="mb-3 row" style={{position:'relative',top:'7px'}}>
           {isEditorAllowed && (
  <div className="btn-group sub-header__buttons">
  <button className="css-1m5o92us" type="button"onClick={() => {tog_scroll()}}data-toggle="modal">
    <span role="img" className="css-23dsfxs" >
      <svg viewBox="0 0 16 16" height="100%" width="100%" >
        <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
      </svg>
    </span> 
    <span  className="css-1ixbps20l d-none d-md-block">  {t('Apply Report')}</span>
  </button>
</div>
)}
         
      </div>
      
        </Button> 
        </div>

   
      </div>

      {/* Report Table */}
      <Row>
        <div className="table-responsive">
          <Table className="align-middle mb-0 table-striped" >
            <thead>
              <tr>
                <th>{t('Type')}</th>
                <th>{t('Name')}</th>
                <th>{t('Period')}</th>
                <th>{t('Apply Date')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems.map(item => (
                  <tr key={item.applyDate}>
                    <td>{item.report_type}</td>
                    <td>{item.report_name}</td>
                    <td>{`${formatDate(item.date_range_start)} To ${formatDate(item.date_range_end)}`}</td>
                    <td>{formatDate(item.generated_at)}</td>
                    <td>
                    {isEditorAllowed && (
   <button type="button" 
   className="btn btn-primary btn-sm" 
     style={{ marginRight: '10px' }}
     onClick={() => DownloadXls(item.report_data,item.report_type)}
     >
       {t('Excel')}
     </button>
)}
                 
  {/* <button
   type="button" 
   className="btn btn-success btn-sm" 
   style={{ marginRight: '10px' }}
   >View</button> */}
                       {isEditorAllowed && (
 <button type="button"
 className="btn btn-danger  btn-sm"
 onClick={() => removeModal(item.report_id )}
 >{t('Remove')}</button>
)}
 
  </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    <h6>{t('No Record Found')}</h6>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Row>

      {/* Pagination */}
      <Row>
        <Col xs="7">
          {t('Showing')} {currentPage_ab === 1 ? 1 : (currentPage_ab - 1) * itemsPerPage + 1} -{' '}
          {Math.min(currentPage_ab * itemsPerPage, totalItems)} {t('of')} {totalItems}
        </Col>
        <Col xs="5">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPage_ab === 1}
              onClick={() => handlePageChange(currentPage_ab - 1)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPage_ab === Math.ceil(totalItems / itemsPerPage)}
              onClick={() => handlePageChange(currentPage_ab + 1)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  </React.Fragment>
  );
};

export default withTranslation()(withRouter(ShiftRaport));