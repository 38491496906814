import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_ACCOUNT_DATA,
  PUT_ACCOUNT_DATA,
  GET_ABSENCE_POLICY,
  POST_ABSENCE_POLICY,
  PUT_ABSENCE_POLICY,
  DELETE_ABSENCE_POLICY,
  GET_ABSENCE_TYPE,
  POST_ABSENCE_TYPE,
  PUT_ABSENCE_TYPE,
  DELETE_ABSENCE_TYPE,
  GET_ABSENCE,
  PUT_ABSENCE,
  POST_ABSENCE,
  DELETE_ABSENCE,
  GET_CORRECTION,
  POST_CORRECTION,
  PUT_CORRECTION,
  GET_FULL_ABSENCE_ACCESS,
  GET_ABSENCE_TOTAL,
  GET_HOURS_TOTAL,
  GET_ALL_ABSENCE
  } from "./actionTypes";
 import {
  getAccountDataSuccess,
  getAccountDataFail,
  putAccountDataSuccess,
  putAccountDataFail,
  getAbsencePolicyDataSuccess,
  getAbsencePolicyDataFail,
  postAbsencePolicyDataSuccess,
  postAbsencePolicyDataFail,
  putAbsencePolicyDataSuccess,
  putAbsencePolicyDataFail,
  deleteAbsencePolicySuccess,
  deleteAbsencePolicyFail,
  getAbsenceTypeSuccess,
  getAbsenceTypeFail,
  postAbsencetypeSuccess,
  postAbsencetypeFail,
  putAbsencetypeSuccess,
  putAbsencetypeFail,
  deleteAbsenceTypeSuccess,
  deleteAbsenceTypeFail,
  getAbsenceSuccess,
  getAbsenceFail,
  putAbsenceSuccess,
  putAbsenceFail,
  postAbsenceSuccess,
  postAbsenceFail,
  deleteAbsenceSuccess,
  deleteAbsenceFail,
  getCorrectionSuccess,
  getCorrectionFail,
  postCorrectionSuccess,
  postCorrectionFail,
  putCorrectionSuccess,
  putCorrectionFail,
  getFullAccessSuccess,
  getFullAccessFail,
  getAbsenceTotalSuccess,
  getAbsenceTotalFail,
  getHoursTotalSuccess,
  getHoursTotalFail,
  getAllAbsenceSuccess,
  getAllAbsenceFail,
  } from "./actions";

  //Include Both Helper File with needed methods
import {
  getAccountDataApi,
  putAccountDataApi,
  getAbsencePolicyDataApi,
  postAbsencePolicyDataApi,
  putAbsencePolicyDataApi,
  deleteAbsencePolicyApi,
  getAbsenceTypeApi,
  postAbsencetypeApi,
  putAbsencetypeApi,
  deleteAbsenceTypeApi,
  getAbsenceApi,
  putAbsenceApi,
  postAbsenceApi,
  deleteAbsenceApi,
  getCorrectionApi,
  postCorrectionApi,
  putCorrectionApi,
  getFullAbsenceAccess,
  getAbsenceTotalApi,
  getHoursTotalApi,
  getAllAbsenceApi,
  } from "helpers/fakebackend_helper";
  function* getHoursTotalSaga({ payload: ContractAndWorkHours }) {
    try {
      const response = yield call(getHoursTotalApi, ContractAndWorkHours);
      yield put(getHoursTotalSuccess(response));
    } catch (error) {
      yield put(getHoursTotalFail(error));
    }
  }
  function* getAbsenceTotalSaga({ payload: getTotalAbsence }) {
    try {
      const response = yield call(getAbsenceTotalApi, getTotalAbsence);
      yield put(getAbsenceTotalSuccess(response));
    } catch (error) {
      yield put(getAbsenceTotalFail(error));
    }
  }
  function* getFullAccessSaga({ payload: useraccess }) {
    try {
      const response = yield call(getFullAbsenceAccess, useraccess);
      yield put(getFullAccessSuccess(response));
    } catch (error) {
      yield put(getFullAccessFail(error));
    }
  }
  function* getAccountDataSaga({ payload: getaccountdata }) {
    try {
      const response = yield call(getAccountDataApi, getaccountdata);
      yield put(getAccountDataSuccess(response));
    } catch (error) {
      yield put(getAccountDataFail(error));
    }
  }
  function* putAccountDataSaga({ payload: putaccountdata }) {
    try {
      const response = yield call(putAccountDataApi, putaccountdata);
      yield put(putAccountDataSuccess(response));
    } catch (error) {
      yield put(putAccountDataFail(error));
    }
  }
 
  function* getAbsencePolicySaga({ payload: getabsencepolicy }) {
    try {
      const response = yield call(getAbsencePolicyDataApi, getabsencepolicy);
      yield put(getAbsencePolicyDataSuccess(response));
    } catch (error) {
      yield put(getAbsencePolicyDataFail(error));
    }
  }

  function* postAbsencePolicySaga({ payload: postabsencepolicy }) {
    try {
      const response = yield call(postAbsencePolicyDataApi, postabsencepolicy);
      yield put(postAbsencePolicyDataSuccess(response));
    } catch (error) {
      yield put(postAbsencePolicyDataFail(error));
    }
  }

  function* putAbsencePolicySaga({ payload: putabsencepolicy }) {
    try {
      const response = yield call(putAbsencePolicyDataApi, putabsencepolicy);
      
      yield put(putAbsencePolicyDataSuccess(response));
    } catch (error) {
      yield put(putAbsencePolicyDataFail(error));
    }
  }

  function* deleteAbsencePolicySaga({ payload: deleteabsencepolicy }) {
    try {
      const response = yield call(deleteAbsencePolicyApi, deleteabsencepolicy);
      yield put(deleteAbsencePolicySuccess(response));
    } catch (error) {
      yield put(deleteAbsencePolicyFail(error));
    }
  }

  function* getAbsenceTypeSaga({ payload: getabsencetype }) {
    try {
      const response = yield call(getAbsenceTypeApi, getabsencetype);
      yield put(getAbsenceTypeSuccess(response));
    } catch (error) {
      yield put(getAbsenceTypeFail(error));
    }
  }

  function* postAbsenceTypeSaga({ payload: postabsencetype }) {
    try {
      const response = yield call(postAbsencetypeApi, postabsencetype);
      yield put(postAbsencetypeSuccess(response));
    } catch (error) {
      yield put(postAbsencetypeFail(error));
    }
  }

  function* putAbsenceTypeSaga({ payload: putabsencetype }) {
    try {
      const response = yield call(putAbsencetypeApi, putabsencetype);
      yield put(putAbsencetypeSuccess(response));
    } catch (error) {
      yield put(putAbsencetypeFail(error));
    }
  }

  function* deleteAbsenceTypeSaga({ payload: deleteabsencetype }) {
    try {
      const response = yield call(deleteAbsenceTypeApi, deleteabsencetype);
      yield put(deleteAbsenceTypeSuccess(response));
    } catch (error) {
      yield put(deleteAbsenceTypeFail(error));
    }
  }

  function* getAbsenceSaga({ payload: getabsence }) {
    try {
      const response = yield call(getAbsenceApi, getabsence);
      yield put(getAbsenceSuccess(response));
    } catch (error) {
      yield put(getAbsenceFail(error));
    }
  }
  function* getAllAbsenceSaga({ payload: getalltabsence }) {
    try {
      const response = yield call(getAllAbsenceApi, getalltabsence);
      yield put(getAllAbsenceSuccess(response));
    } catch (error) {
      yield put(getAllAbsenceFail(error));
    }
  }
  function* putAbsenceSaga({ payload: putabsence }) {
    try {
      const response = yield call(putAbsenceApi, putabsence);
      yield put(putAbsenceSuccess(response));
    } catch (error) {
      yield put(putAbsenceFail(error));
    }
  }

  function* postAbsenceSaga({ payload: postabsence }) {
    try {
      const response = yield call(postAbsenceApi, postabsence);
      yield put(postAbsenceSuccess(response));
    } catch (error) {
      yield put(postAbsenceFail(error));
    }
  }

  function* deleteAbsenceSaga({ payload: deleteabsence }) {
    try {
      const response = yield call(deleteAbsenceApi, deleteabsence);
      yield put(deleteAbsenceSuccess(response));
    } catch (error) {
      yield put(deleteAbsenceFail(error));
    }
  }

  function* getCorrectionSaga({ payload: getcorrection }) {
    try {
      const response = yield call(getCorrectionApi, getcorrection);
      yield put(getCorrectionSuccess(response));
    } catch (error) {
      yield put(getCorrectionFail(error));
    }
  }

  function* postCorrectionSaga({ payload: postcorrection }) {
    try {
      const response = yield call(postCorrectionApi, postcorrection);
      yield put(postCorrectionSuccess(response));
    } catch (error) {
      yield put(postCorrectionFail(error));
    }
  }

  function* putCorrectionSaga({ payload: putcorrection }) {
    try {
      const response = yield call(putCorrectionApi, putcorrection);
      yield put(putCorrectionSuccess(response));
    } catch (error) {
      yield put(putCorrectionFail(error));
    }
  }

  function* AbsenceSaga() {
    yield takeEvery(GET_ACCOUNT_DATA, getAccountDataSaga);
    yield takeEvery(PUT_ACCOUNT_DATA, putAccountDataSaga);
    yield takeEvery(GET_ABSENCE_POLICY, getAbsencePolicySaga);
    yield takeEvery(POST_ABSENCE_POLICY, postAbsencePolicySaga);
    yield takeEvery(PUT_ABSENCE_POLICY, putAbsencePolicySaga);
    yield takeEvery(DELETE_ABSENCE_POLICY, deleteAbsencePolicySaga);
    yield takeEvery(GET_ABSENCE_TYPE, getAbsenceTypeSaga);
    yield takeEvery(POST_ABSENCE_TYPE, postAbsenceTypeSaga);
    yield takeEvery(PUT_ABSENCE_TYPE, putAbsenceTypeSaga);
    yield takeEvery(DELETE_ABSENCE_TYPE, deleteAbsenceTypeSaga);
    yield takeEvery(GET_ABSENCE, getAbsenceSaga);
    yield takeEvery(PUT_ABSENCE, putAbsenceSaga);
    yield takeEvery(POST_ABSENCE, postAbsenceSaga);
    yield takeEvery(GET_CORRECTION, getCorrectionSaga);
    yield takeEvery(DELETE_ABSENCE, deleteAbsenceSaga);
    yield takeEvery(POST_CORRECTION, postCorrectionSaga);
    yield takeEvery(PUT_CORRECTION, putCorrectionSaga);
    yield takeEvery(GET_FULL_ABSENCE_ACCESS, getFullAccessSaga);
    yield takeEvery(GET_ABSENCE_TOTAL, getAbsenceTotalSaga)
    yield takeEvery(GET_HOURS_TOTAL, getHoursTotalSaga)
    yield takeEvery(GET_ALL_ABSENCE, getAllAbsenceSaga)
  }
  
  export default AbsenceSaga;
