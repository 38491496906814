import {
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  POST_VEHICLES,
  POST_VEHICLES_SUCCESS,
  POST_VEHICLES_FAIL,
  PUT_VEHICLES,
  PUT_VEHICLES_SUCCESS,
  PUT_VEHICLES_FAIL,
  DELETE_VEHICLES,
  DELETE_VEHICLES_SUCCESS,
  DELETE_VEHICLES_FAIL,
  GET_VEHICLES_DOC,
  GET_VEHICLES_DOC_SUCCESS,
  GET_VEHICLES_DOC_FAIL,
  POST_VEHICLES_DOC,
  POST_VEHICLES_DOC_SUCCESS,
  POST_VEHICLES_DOC_FAIL,
  PUT_VEHICLES_DOC,
  PUT_VEHICLES_DOC_SUCCESS,
  PUT_VEHICLES_DOC_FAIL,
  DELETE_VEHICLES_DOC,
  DELETE_VEHICLES_DOC_SUCCESS,
  DELETE_VEHICLES_DOC_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  getVhiclesdata: [],
  error: null,
  postVhiclesdata: null,
  putVhiclesdata: null,
  delVhiclesdata: null,
  getVhiclesDocdata: [],
  postVhiclesDocdata: null,
  putVhiclesDocdata: null,
  delVhiclesDocdata: null,
  loading: false,
};

const Vhicles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VEHICLES_SUCCESS:
      return {
        ...state,
        getVhiclesdata: action.payload,
      };

    case GET_VEHICLES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
   
        case POST_VEHICLES:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case POST_VEHICLES_SUCCESS:
        return {
        ...state,
        loading: false,
        postVhiclesdata: action.payload,
        };
        case POST_VEHICLES_FAIL:
        return {
        ...state,
        loading: false,
        postVhiclesdata: null,
        error: action.payload,
        };

        case PUT_VEHICLES:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case PUT_VEHICLES_SUCCESS:
        return {
        ...state,
        loading: false,
        putVhiclesdata: action.payload,
        };
        case PUT_VEHICLES_FAIL:
        return {
        ...state,
        loading: false,
        putVhiclesdata: null,
        error: action.payload,
        };


        case DELETE_VEHICLES:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case DELETE_VEHICLES_SUCCESS:
        return {
        ...state,
        loading: false,
        delVhiclesdata: action.payload,
        };
        case DELETE_VEHICLES_FAIL:
        return {
        ...state,
        loading: false,
        delVhiclesdata: null,
        error: action.payload,
        };

  //sasa
  case GET_VEHICLES_DOC_SUCCESS:
    return {
      ...state,
      getVhiclesDocdata: action.payload,
    };

  case GET_VEHICLES_DOC_FAIL:
    return {
      ...state,
      error: action.payload,
    };
 
      case POST_VEHICLES_DOC:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case POST_VEHICLES_DOC_SUCCESS:
      return {
      ...state,
      loading: false,
      postVhiclesDocdata: action.payload,
      };
      case POST_VEHICLES_DOC_FAIL:
      return {
      ...state,
      loading: false,
      postVhiclesDocdata: null,
      error: action.payload,
      };

      case PUT_VEHICLES_DOC:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case PUT_VEHICLES_DOC_SUCCESS:
      return {
      ...state,
      loading: false,
      putVhiclesDocdata: action.payload,
      };
      case PUT_VEHICLES_DOC_FAIL:
      return {
      ...state,
      loading: false,
      putVhiclesDocdata: null,
      error: action.payload,
      };


      case DELETE_VEHICLES_DOC:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case DELETE_VEHICLES_DOC_SUCCESS:
      return {
      ...state,
      loading: false,
      delVhiclesDocdata: action.payload,
      };
      case DELETE_VEHICLES_DOC_FAIL:
      return {
      ...state,
      loading: false,
      delVhiclesDocdata: null,
      error: action.payload,
      };

    default:
      return state;
  }
};

export default Vhicles;