import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  TabContent,
  TabPane,
  Form ,
  UncontrolledCollapse
} from "reactstrap";
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ModalComponent from './ModalPersonal';
import classnames from "classnames";
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import IdentityCard from './Indentitycard';
import IndentitycardBak from './IndentitycardBak';
import LicenseFront from './LicenseFront';
import LicenceBack from './LicenceBack';
import ContractTable from './Contract';
import Absence from './Absence';
import Docu from './Docu';

import {
  getMailsLists as onGetMailsLists,
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  postContractType,
  postContract,
  postLinks,
  createfolder,
  selectFolders,
  putProfile,
} from "store/actions";
import Select from "react-select"
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import { format, parseISO, isBefore, isEqual } from 'date-fns';
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
const S3_BUCKET = 'shiftspec';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5CBDQX2KENTO7LJY';
const SECRET_ACCESS_KEY = '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';
AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION
});
const s3 = new AWS.S3();
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};
const Details = (props) => {
  const { user_id } = useParams();
  const {

    mailslists,
    newfolder,
    folders,
  } = useSelector(state => ({

    mailslists: state.mails.mailslists,
    newfolder: state.mails.newfolder,
    folders: state.mails.folders,
  }));
  const {
    client,
    xlsid,
    contractdata,
    postDeps,
    postTeams,
    postContractTypes,
    link,
    profile,
    error
  } = useSelector(state => ({
    
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    contractdata: state.getClientData.contractdata,
    postDeps: state.getClientData.postDeps,
    postTeams: state.getClientData.postTeams,
    postContractTypes: state.getClientData.postContractTypes,
    link: state.getClientData.link,
    profile: state.getClientData.profile,
    error: state.getClientData.error,
  }));
  
  const dispatch = useDispatch();
  const { t } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const [modal_small, setmodal_small] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { permissions, loading } = useUserPermissions();

  const { editor, plan_name } = permissions || {};
  
  const isEditorAllowed = editor === 1;
  useEffect(() => {
    document.title = t("Collaborator Details - Manage and Search Users | Shiftspec");
  }, [t]);

  //meta title xlsid
  useEffect(() => {
    if (account_id) {
      dispatch(onGetcollaborator(account_id));
      dispatch(getXlsdata(account_id));
      dispatch(onGetMailsLists(account_id));
      dispatch(selectFolders(account_id));
    }
    
  }, [dispatch]);

  const [modalVisible, setModalVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [clientData, setClientData] = useState(null);


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const handleOpenModal = () => setModalShow(true);
  const handleCloseModal = () => setModalShow(false);
  
  const handleDrop = useCallback(async (acceptedFiles, type) => {
    const file = acceptedFiles[0];
    if (file) {
      setIsLoading(true);
      setImagePreview(null);
      const fileUrl = URL.createObjectURL(file);
      const params = {
        Bucket: S3_BUCKET,
        Key: `${company_name}/${type}/${file.name}`, // Path or key where file will be stored in S3
        Body: file,
        ACL: 'public-read', // Set file as public read
      };
      try {
        const data = await s3.upload(params).promise();
        // Get the URL from the response
        const fileUrl = data.Location;
        setTimeout(() => {
          setImagePreview(fileUrl);
          setIsLoading(false); // End loading
        }, 300)

        const formData = {
          userid:user_id,
          columnName:'profile_img',
          doc:fileUrl,
        }
        dispatch(putProfile(formData));
        // Update form data with the S3 file URL
      } catch (error) {
        console.error('Error uploading file:', error);
        setIsLoading(false);
      }
     
    }
  }, []);


  useEffect(() => {
    if (profile && profile.success) {
      toastr.success(t('The file has been updated successfully.'));
      dispatch(onGetcollaborator(account_id));
    } else if (profile) {
      toastr.error(t('The file has been updated unsuccessfully.'));
    }
  }, [profile])

  const createDropzoneConfig = (type) => ({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, type),
    accept: 'image/*',
    maxFiles: 1,
  });

  const { getRootProps: getRootPropsProfile, getInputProps: getInputPropsProfile } = useDropzone(createDropzoneConfig('profile'));


  const handleModalToggle = () => {
    setModalVisible(!modalVisible);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
  };

  const handleImageClick = () => {
    setIsLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setIsLightboxOpen(false);
  };

  

  useEffect(() => {
    const userIdNumber = parseInt(user_id, 10);
    const data = client.find(item => item.clientId === userIdNumber);
    setClientData(data);
  }, [user_id, client])
  if (!clientData) {
    return <div>Loading...</div>; // Or handle the case when data is not available
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  const statusOptions = [
    { value: 'active', label: t('Active') },
    { value: 'pending', label: t('Pending')},
    { value: 'willekeurig', label: t('Random') },
    { value: 'onboarding', label: t('Onboarding') },
    { value: 'close', label: t('Left') },
    { value: 'block', label: t('Block') },
   
  ];





 const handleSubmitstatus = (e) => {
    e.preventDefault();
    const formData = {
      userid:user_id,
      columnName:'client_status',
      doc:selectedStatus.value,
    }
    dispatch(putProfile(formData));
    // Further logic to submit the selected status
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };


  

 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t('Detail')} breadcrumbItemLink="/collaborator" breadcrumbItem={t('Back To Previous Page')} />
    
          <Modal
                      size="sm"
                      isOpen={modal_small}
                      toggle={() => {
                        tog_small();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="mySmallModalLabel"
                        >
                        {t('Modify Status')} 
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_small(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span >&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmitstatus}>
                        <Select
        options={statusOptions}
        value={statusOptions.value}
        onChange={handleStatusChange}
        placeholder={t('Select Status')} 
      />
        <Button style={{marginTop:'30px'}} variant="primary" type="submit" className="css-1bl3roz">
        {t('Submit')}
          </Button>
                        </form>
             
                      </div>
                    </Modal>
          <Row>
            <Col xs="2">
            <div className="drop_img" >
          <img
            height="200"
            style={{ maxWidth: '100%', cursor: 'pointer',width:'100%',objectFit:'cover',objectPosition:'center' }}
            src={clientData.profile_img}
            alt=""
            onClick={handleImageClick}
          />
       
        <div className="bewerkenbtn"
          style={{
         width:'86%',
         marginTop:'10px'
             }}
         >
         <button onClick={handleModalToggle} className="btn btn-success css-1bl3roz">
         {t('Modify')} 
          </button>
        </div>
        {isLightboxOpen && (
        <div className="lightbox" onClick={handleLightboxClose}>
          <img
            className="lightbox-img"
            src={clientData.profile_img}
            alt={t('Profile')} 
          />
        </div>
      )}
      </div>

      {/* Modal */}
      {modalVisible && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('Modify Profile Picture')} </h5>
                <button type="button" className="btn-close" onClick={handleModalToggle} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <form onSubmit={handleFormSubmit} >
                        <div className="card-body">
                         
                          <div
                           style={{
                           position:"relative",
                           display:'flex',
                          justifyContent:'center'
                            }}
                           >
                            <div className="drop_img col-5"
                            {...getRootPropsProfile()}
                            style={{
                            marginBottom: '10px',
                            backgroundColor: imagePreview ? '#f0f8ff' : '#fff',
                            padding: '10px',
                            border: '2px dashed #007bff',
                            borderRadius: '4px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            }}
                            >
                        {isLoading ? (
                      <div role="status" className="ms-2 spinner-border text-primary"><span className="visually-hidden">{t('Loading...')}</span></div>// Display a loading message or spinner
                    ) : imagePreview ? (
                      <img src={imagePreview} alt="Profile" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    ) : (
  <svg
    fill="#000000"
    height="150px"
    width="150px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 402.161 402.161"
  >
    <g>
      <g>
        <g>
          <path d="M201.08,49.778c-38.794,0-70.355,31.561-70.355,70.355c0,18.828,7.425,40.193,19.862,57.151c14.067,19.181,32,29.745,50.493,29.745c18.494,0,36.426-10.563,50.494-29.745c12.437-16.958,19.862-38.323,19.862-57.151C271.436,81.339,239.874,49.778,201.08,49.778z M201.08,192.029c-13.396,0-27.391-8.607-38.397-23.616c-10.46-14.262-16.958-32.762-16.958-48.28c0-30.523,24.832-55.355,55.355-55.355s55.355,24.832,55.355,55.355C256.436,151.824,230.372,192.029,201.08,192.029z"/>
          <path d="M201.08,0C109.387,0,34.788,74.598,34.788,166.292c0,91.693,74.598,166.292,166.292,166.292s166.292-74.598,166.292-166.292C367.372,74.598,292.773,0,201.08,0z M201.08,317.584c-30.099-0.001-58.171-8.839-81.763-24.052c0.82-22.969,11.218-44.503,28.824-59.454c6.996-5.941,17.212-6.59,25.422-1.615c8.868,5.374,18.127,8.099,27.52,8.099c9.391,0,18.647-2.724,27.511-8.095c8.201-4.97,18.39-4.345,25.353,1.555c17.619,14.93,28.076,36.526,28.895,59.512C259.25,308.746,231.178,317.584,201.08,317.584z M296.981,283.218c-3.239-23.483-15.011-45.111-33.337-60.64c-11.89-10.074-29.1-11.256-42.824-2.939c-12.974,7.861-26.506,7.86-39.483-0.004c-13.74-8.327-30.981-7.116-42.906,3.01c-18.31,15.549-30.035,37.115-33.265,60.563c-33.789-27.77-55.378-69.868-55.378-116.915C49.788,82.869,117.658,15,201.08,15c83.423,0,151.292,67.869,151.292,151.292C352.372,213.345,330.778,255.448,296.981,283.218z"/>
          <path d="M302.806,352.372H99.354c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h203.452c4.142,0,7.5-3.358,7.5-7.5C310.307,355.73,306.948,352.372,302.806,352.372z"/>
          <path d="M302.806,387.161H99.354c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h203.452c4.142,0,7.5-3.358,7.5-7.5C310.307,390.519,306.948,387.161,302.806,387.161z"/>
        </g>
      </g>
    </g>
  </svg>
   )}
</div>

                            <input {...getInputPropsProfile()} />
                            <div className="dz-messages needsclicks" id="drop-region">
                            {imagePreview && (
                    <button type="button" className="trash img_class" id="trash" onClick={() => setImagePreview(null)}>
                      <i className="bx bx-trash"></i>{t('Trash')} 
                    </button>
                  )}
                      
                            </div>
                          </div>
                        </div>
              
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
            
            </Col>

            <Col xs="10">
           <Row>
           <h5>{clientData.frist_name} {clientData.last_name}<span>
            <i className="mdi mdi-circle text-info  align-middle me-1"></i>{clientData.client_status}
            </span>
{isEditorAllowed && (
 <span>
 <button type="submit"  onClick={() => {tog_small()}}className="btn  css-1bl3roz"style={{width:'140px',marginLeft:'10px', background: '#0463b9', border: 'none' }}>{t('Modify Status')}</button> 
 </span>
)}
        
              </h5>
           <div className="left_detail col-6">
        <div className="list_details"><strong>{t('Service Areas')}:</strong> {clientData.service_type}</div>
        <div className="list_details"><strong>{t('Email')}:</strong>{clientData.email}</div>
        <div className="list_details"><strong>{t('Mobile Number')}:</strong>{clientData.phonenumber}</div>
        <div className="list_details"><strong>{t('Birthday')}:</strong>{clientData.brithday}</div>
        {isEditorAllowed && (
 <div className="list_details">
 <a href={`/put/collaborator/${user_id}`}><strong>{t('Modify Profile Information')}</strong></a>

 </div>
)}
        
      </div>
      <div className="right_detail col-6">
     
        <div className="list_details"><strong>{t('Gender')}:</strong> {clientData.gender}</div>
        <div className="list_details"><strong>{t('Nationality')}:</strong>{clientData.nationality}</div>
        <div className="list_details"><strong>{t('Onboarding Date')}:</strong>{format(new Date(clientData.registration_date),'EEEE')} {format(new Date(clientData.registration_date), DateTimeFormat)}</div>
        
        <div className="list_details"
         style={{
          position:"relative",
          display:'grid',
           }}
        >

      {isEditorAllowed && (

<button
type="button"
style={{
  background: '#0463b9',
  border: 'none',
  marginTop: '5px'
}}
className="btn btn-dark waves-effect waves-light css-1bl3roz"
data-bs-toggle="modal"
data-bs-target="#exampleModalScrollable"
onClick={handleOpenModal}
>
{t('View Personal Information')} 
</button>
)}
    </div>
    <ModalComponent clientData={clientData} t={t} show={modalShow} handleClose={handleCloseModal} />
      </div>

           </Row>
           </Col>
           <Col xs="12">

           
            <Nav tabs className="nav-tabs-custom nav-justified">
   
  <NavItem>
  <NavLink
    style={{ cursor: "pointer" }}
    className={classnames({
      active: customActiveTab === "1",
    })}
    onClick={() => {
      toggleCustom("1");
    }}
  >
    <span className="d-block d-sm-none">
      <i className="fas fa-home"></i>
    </span>
    <span className="d-none d-sm-block">{t('Personal Details')}</span>
  </NavLink>
</NavItem>
<NavItem>
  <NavLink
    style={{ cursor: "pointer" }}
    className={classnames({
      active: customActiveTab === "2",
    })}
    onClick={() => {
      toggleCustom("2");
    }}
  >
    <span className="d-block d-sm-none">
      <i className="far fa-user"></i>
    </span>
    <span className="d-none d-sm-block">{t('Personal Identity')}</span>
  </NavLink>
</NavItem>
<NavItem>
  <NavLink
    style={{ cursor: "pointer" }}
    className={classnames({
      active: customActiveTab === "3",
    })}
    onClick={() => {
      toggleCustom("3");
    }}
  >
    <span className="d-block d-sm-none">
      <i className="fas fa-cog"></i>
    </span>
    <span className="d-none d-sm-block">{t('Personal Contract')}</span>
  </NavLink>
</NavItem>

{plan_name?.toLowerCase() !== "starter" && (
  <NavItem>
    <NavLink
      style={{ cursor: "pointer" }}
      className={classnames({
        active: customActiveTab === "4",
      })}
      onClick={() => toggleCustom("4")}
    >
      <span className="d-block d-sm-none">
        <i className="fas fa-cog"></i>
      </span>
      <span className="d-none d-sm-block">{t('Absence')}</span>
    </NavLink>
  </NavItem>
)}

</Nav>

           

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                    <Row className="justify-content-center">
                    <Docu isEditorAllowed={isEditorAllowed} clientData={clientData} mailslists={mailslists} t={t} folders={folders} />
                     
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                    <Row className="justify-content-center">
                      <Col sm="6">
                        <ul className="list-group list-group-flush">
                        <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none' }}
                          >
                         {t('Track Personal indentity')} 
                          </Button> 
                          <IdentityCard isEditorAllowed={isEditorAllowed} clientData={clientData} t={t} />
                          <IndentitycardBak isEditorAllowed={isEditorAllowed} clientData={clientData} t={t} />
                         </ul>
                        </Col>
                        <Col sm="6">
                        <ul className="list-group list-group-flush">
                        <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none' }}
                          >
                         {t('Track Personal License')} 
                          </Button> 
                          <LicenseFront isEditorAllowed={isEditorAllowed} clientData={clientData} t={t} />
                          <LicenceBack isEditorAllowed={isEditorAllowed} clientData={clientData} t={t} />
                  </ul>
                        </Col>
                      </Row>
                    </TabPane>
                   
                    <TabPane tabId="3">
                    <ContractTable isEditorAllowed={isEditorAllowed} plan_name={plan_name}  clientData={clientData} t={t} />
                    </TabPane>
                    <TabPane tabId="4">
                    <Row className="justify-content-center">
                    <Absence isEditorAllowed={isEditorAllowed} clientData={clientData} />
                      </Row>
                    </TabPane>
                  </TabContent>
           </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(Details));

