import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { withTranslation } from "react-i18next";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Uploadfile from "./Uploadfile";
import Addrecipien from "./Addrecipien";
import ModalComponent from "../Prepare/ModalComponent";
import axios from "axios";

const PerpareComponent = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [fristname, setFristname] = useState(user.firstName);
  const [lastname, setLastname] = useState(user.lastName);
  const [token, setToken] = useState(user.token);
  const [email, setEmail] = useState(user.email);
  const [signatureImge, setSignatureImge] = useState(user.signature);
  const [paraaf, setParaaf] = useState(user.paraaf);
  const [stemp, setStemp] = useState(user.stemp);
  const [userId, setuserId] = useState(user.clientId);
  const { uuid } = useParams();
  const fullNames = `${fristname} ${lastname}`;
  const [loading, setLoading] = useState(false);

  const previous = () => {
    window.location.href = "/home";
  };

  document.title = t(
    "Create Envelope & Upload Documents for E-Signature | eSignatureHub"
  );

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [selectedFiles, setFiles] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [isstatus, setStatus] = useState([]);

  // UseEffect to enable/disable submit button
  useEffect(() => {
    if (selectedFiles.length > 0 && recipients.length > 0) {
      setSubmitButtonDisabled(false); // Enable button
    } else {
      setSubmitButtonDisabled(true); // Disable button
    }
  }, [selectedFiles, recipients]); // Dependencies to watch for changes

  const handleRecipientDataChange = (updatedRecipients, status) => {
    setRecipients(updatedRecipients);
    setStatus(status);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    const recipientsData = recipients
      .filter(Boolean) // Filter out null or undefined recipients
      .map((recipient) => {
        const {
          recipientName,
          recipientEmail,
          recipientMsg,
          recipientroll,
          recipientExpire,
          color,
          recipienid,
        } = recipient;

        const isOwner =
          recipientEmail.trim().toLowerCase() === user.email.trim().toLowerCase();

        const signatureImge = isOwner ? user.signature : "client";
        const paraaf = isOwner ? user.paraaf : "client";
        const stemp = isOwner ? user.stemp : "client";

        return {
          recipientName,
          recipientEmail,
          recipientMsg,
          recipientroll,
          recipientExpire,
          color,
          recipienid,
          isOwner,
          signatureImge,
          paraaf,
          stemp,
        };
      });

    formData.append("recipients", JSON.stringify(recipientsData));
    formData.append("userId", userId);
    formData.append("uuid", uuid);
    formData.append("status", isstatus);
    formData.append("token", token);
    formData.append("fullNames", fullNames);
    // Alternative: Convert to plain object for easier inspection
    const formDataObject = Object.fromEntries(formData.entries());
   
    try {
      const response = await axios.post(`https://dspmetrics.com/api/onvert/pdfapi/onvert/pdf?token=${token}&fullNames=${fullNames}`, formDataObject, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
      });
      
      if (response.data && response.data.message) {
        toastr.success(response.data.message); 
        navigate(`/api/prepare/${uuid}`);
      } else {
        toastr.success(t('Create New Envalop is successfully!'));
       
        navigate(`/api/prepare/${uuid}`);
        
      }
      setLoading(false);
  } catch (error) {
      console.error("Error during submission:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toastr.error(error.response.data.error); // Display error notification with server message
      } else {
        toastr.error(t('Error submitting form. Please try again.')); // Display generic error notification
      }
      setLoading(false);
  }
    
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItemLink="/home"
            breadcrumbItem="Back To Home Page"
          />
          <Row>
            <Col xl="12">
              <div className="section-title">
                <h2>
                  <i className="bx bxs-file-doc"></i>
                  {t("ADD DOCUMENTS")}
                </h2>
              </div>

              <Uploadfile setFiles={setFiles} />
            </Col>
            <Col xl="12">
              <div className="section-title">
                <h2>
                  <i className="bx bxs-user-pin"></i>
                  {t("ADD RECIPIENTS")}
                </h2>
              </div>

              <Addrecipien onRecipientDataChange={handleRecipientDataChange} />
            </Col>
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary btn-sub"
              onClick={handleSubmit}
              disabled={submitButtonDisabled} // Disable the button based on the state
            >
              {loading ? t("Loading...") : t("Submit")}
            </Button>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(PerpareComponent);
