
import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  POST_REPORT_DATA,
  POST_REPORT_DATA_SUCCESS,
  POST_REPORT_DATA_FAIL,
  DELETE_REPORT_DATA,
  DELETE_REPORT_DATA_SUCCESS,
  DELETE_REPORT_DATA_FAIL,
  POST_REPORT_DATA_ABSENCE,
  POST_REPORT_DATA_ABSENCE_SUCCESS,
  POST_REPORT_DATA_ABSENCE_FAIL,
  GET_WEB_SETTING,
  GET_WEB_SETTING_SUCCESS,
  GET_WEB_SETTING_FAIL
  } from "./actionTypes";

  export const getWebStting = websetting => ({
    type: GET_WEB_SETTING,
    payload: websetting,
  })
  
  export const getWebSttingSuccess = websetting => ({
    type: GET_WEB_SETTING_SUCCESS,
    payload: websetting,
  })
  
  export const getWebSttingFail = error => ({
    type: GET_WEB_SETTING_FAIL,
    payload: error,
  })
  
  export const getReportData = getreportdata => ({
    type: GET_REPORT_DATA,
    payload: getreportdata,
  })
  export const getReportDataSuccess = getaccountdata => ({
    type: GET_REPORT_DATA_SUCCESS,
    payload: getaccountdata,
  })
  
  export const getReportDataFail = error => ({
    type: GET_REPORT_DATA_FAIL,
    payload: error,
  })


  export const postReportData = postreportdata => ({
    type: POST_REPORT_DATA,
    payload: postreportdata,
  })
  export const postReportDataSuccess = postreportdata => ({
    type: POST_REPORT_DATA_SUCCESS,
    payload: postreportdata,
  })
  
  export const postReportDataFail = error => ({
    type: POST_REPORT_DATA_FAIL,
    payload: error,
  })

  export const postReportDataAbsence = postreportdataabsence => ({
    type: POST_REPORT_DATA_ABSENCE,
    payload: postreportdataabsence,
  })
  export const postReportDataAbsenceSuccess = postreportdataabsence => ({
    type: POST_REPORT_DATA_ABSENCE_SUCCESS,
    payload: postreportdataabsence,
  })
  
  export const postReportDataAbsenceFail = error => ({
    type: POST_REPORT_DATA_ABSENCE_FAIL,
    payload: error,
  })

  export const removeReportData = removereportdata => ({
    type: DELETE_REPORT_DATA,
    payload: removereportdata,
  })
  export const removeReportDataSuccess = removereportdata => ({
    type: DELETE_REPORT_DATA_SUCCESS,
    payload: removereportdata,
  })
  
  export const removeReportDataFail = error => ({
    type: DELETE_REPORT_DATA_FAIL,
    payload: error,
  })


