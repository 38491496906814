import React, { useRef, useState, useEffect,useMemo } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import EditableField  from './EditableField'
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Modal,
} from "reactstrap";
import './css.css';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { map } from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  getXlsdata,
  getcollaborator as onGetcollaborator,
  getVhicles as onGetgetVhicles,
  postVhicles as onPostgetVhicles,
  putVhicles as onPutVhicles,
  delVhicles as onDelVhicles,
} from "store/actions";
import { format, parseISO, isBefore, isEqual } from 'date-fns';
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
//redux
import { useSelector, useDispatch } from "react-redux";

const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};
const Fleet = (props) => {
  const { t } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const [uniqueDepartments, setuniqueDepartments] = useState([]);
  const {
    getVhiclesdata,
    xlsid,
    postVhiclesdata,
    putVhiclesdata,
    delVhiclesdata,
    client,
    error
  } = useSelector(state => ({
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    getVhiclesdata: state.Vhicles.getVhiclesdata,
    postVhiclesdata: state.Vhicles.postVhiclesdata,
    putVhiclesdata: state.Vhicles.putVhiclesdata,
    delVhiclesdata: state.Vhicles.delVhiclesdata,
    error: state.mails.error,
  }));
  //meta title

  useEffect(() => {
    document.title = t("Efficient Vehicle Management | Optimize Fleet Operations with ShiftSpec");
  }, [t]);
  const dispatch = useDispatch();
  const { permissions, loading } = useUserPermissions();

  const { rule, template_content = [], editor, plan_type, plan_status, plan_name } = permissions || {};
  const isEditorAllowed = editor === 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12); // Number of items per page
  const [searchTerm, setSearchTerm] = useState('');
  // Filtered and paginated data state
  const [filteredData, setFilteredData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [errors, setError] = useState(null);
  const [showArrayData, setShowArrayData] = useState([]);
  const [showArrayTrashData, setShowArrayTrashData] = useState([]);
  const [modal_vehicles, setmodal_Vehicles] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  
  const [selectedFuel, setSelectedFuel] = useState(null);
  const [formData, setFormData] = useState({
    fleet_image: "https://analyticscarg-public.s3.us-east-2.amazonaws.com/fleet.png",
    fleet_make: "",
    fleet_modal: "",
    fleet_vin: "",
    fleet_year: "",
    fuel: "",
    department_id:"",
    fleet_plate:"",
    account_id:account_id,
  });
  const fuelOptions = [
    { value: "Petrol", label: t("Petrol") },
    { value: "Diesel", label: t("Diesel") },
    { value: "Electric", label: t("Electric") },
    { value: "Hybrid", label: t("Hybrid") },
    { value: "CNG", label: t("CNG") },
    { value: "Other", label: t("Other") },
  ];
  function tog_vehicles() {
    setmodal_Vehicles(!modal_vehicles);
    removeBodyCss();
  }

 
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  useEffect(() => {
    dispatch(getXlsdata(account_id));
    dispatch(onGetcollaborator(account_id));
    dispatch(onGetgetVhicles(account_id));
  }, [dispatch, userId,account_id]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (Array.isArray(xlsid) && xlsid.length > 0) {
        const seenDepartments = new Set();
        const uniqueDepartments = xlsid.filter((dept) => {
          if (!seenDepartments.has(dept.departments_id)) {
            seenDepartments.add(dept.departments_id);
            return true;
          }
          return false;
        });
        setuniqueDepartments(uniqueDepartments)
        setFormData((prev) => ({
          ...prev,
          department_id: uniqueDepartments[0].departments_id,
        }));
        const options = uniqueDepartments.length > 0
          ? [
              ...uniqueDepartments.map((dept, index) => ({
                value: dept.departments_id,
                label: dept.dep_name,
                default: index === 0, // Set first department as default
              })),
              
            ]
          : [{ value: "", label: t("Select Department") }];
        setDepartmentOptions(options);

        // Automatically select the first department
        const defaultOption = options.find((opt) => opt.default) || options[0];
        setSelectedDepartment(defaultOption);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [xlsid]);

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    if (selectedOption.value === "new") {
      // Clear selection if "Create New Department" is selected
      setFormData((prev) => ({ ...prev, department_id: "" }));
    } else {
      setFormData((prev) => ({
        ...prev,
        department_id: selectedOption.value,
      }));
    }
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };
  const handleFuelChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      fuel: selectedOption.value,
    }));
  };

  useEffect(() => {      
    setFilteredData(getVhiclesdata); // Initialize with filtered data
    setTotalItems(getVhiclesdata.length); // Total items count based on filtered data
  }, [getVhiclesdata]);
// Function to handle pagination
const handlePageChange = (page) => {
  setCurrentPage(page);
};

// Function to handle search input change
const handleSearchChange = (e) => {
  const { value } = e.target;
  setSearchTerm(value);
  filterData(value);
};

const filterData = (searchTerm) => {
  const lowercasedFilter = searchTerm.toLowerCase();

  const filteredData = getVhiclesdata.filter(item => 
    (item.LicensePlate ?? "").toLowerCase().includes(lowercasedFilter) ||
    (item.FuelType ?? "").toLowerCase().includes(lowercasedFilter) ||
    (item.Model ?? "").toLowerCase().includes(lowercasedFilter) ||
    (item.Make ?? "").toLowerCase().includes(lowercasedFilter) ||
    (item.frist_name ?? "").toLowerCase().includes(lowercasedFilter) ||
    (item.last_name ?? "").toLowerCase().includes(lowercasedFilter)
  );

  setFilteredData(filteredData);
  setTotalItems(filteredData.length); // Update total items count
  setCurrentPage(1); // Reset to first page after filtering
};

const paginatedData = useMemo(() => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  return filteredData.slice(startIndex, startIndex + itemsPerPage);
}, [filteredData, currentPage, itemsPerPage]);


const handleDeleteClick = () => {
const data = {
  id: showArrayData // assuming showArrayData is an array of IDs you want to delete
};

setShowArrayData([]);
};

const handlePermanentlyClick = () => {
const data = {
  id: showArrayTrashData // assuming showArrayData is an array of IDs you want to permanently delete
};

setShowArrayData([]);
};


const [isEditing, setIsEditing] = useState(false);
const [currentValue, setCurrentValue] = useState('');

const handleSave = (id, tableName, columnName, value, condition) => {
  handleFieldEdit(id, tableName, columnName, value, condition);
  setIsEditing(false);
};

const handleVehiclesSubmit = (e) => {
  e.preventDefault();

  const submissionData = {
    ...formData,
    department_id: selectedDepartment?.value,
  };

  dispatch(onPostgetVhicles(submissionData));
};


const handleFieldEdit = (id, tableName, columnName, value, condition) => {
  const submissionData = {
    id,
    tableName,
    columnName,
    value,
    condition,
  };

  dispatch(onPutVhicles(submissionData));
};
const handleRemove  = (id) => {
  const fleets = {
    fleets: [id] // Convert single ID to an array if it's not already
  };
  dispatch(onDelVhicles(fleets));
};
useEffect(() => {
  const depSuccessHandler = (message) => {
    toastr.success(t(message));
    dispatch(onGetgetVhicles(account_id));
  };
  
  const depErrorHandler = (message) => {
    toastr.error(t(message));
  };

  if (postVhiclesdata) {
    postVhiclesdata.success 
      ? depSuccessHandler('Vehicle record created! You can now manage and track this vehicle seamlessly')
      : depErrorHandler('Failed to add vehicle data. Please check the input details and try again.');
  }

  if (putVhiclesdata) {
    putVhiclesdata.success 
      ? depSuccessHandler('Vehicle record updated! The latest details are now reflected in the system.') 
      : depErrorHandler('Failed to update vehicle data. Please verify the information and try again.');
  }

  if (delVhiclesdata) {
    delVhiclesdata.success 
      ? depSuccessHandler('Vehicle record deleted! All associated information has been cleared.') 
      : depErrorHandler('Failed to delete vehicle data. Please try again or contact support if the issue persists.');
  }

}, [postVhiclesdata,putVhiclesdata,delVhiclesdata]);


  return (
    <React.Fragment>
      <Modal
                      size="lg"
                      isOpen={modal_vehicles}
                      toggle={() => {
                        tog_vehicles();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <div>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Fleet')}
                        </h5>
                        </div>
                        
                        <button
                          onClick={() => {
                            tog_vehicles(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                    
                      <form onSubmit={handleVehiclesSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <Row>
            {/* Department Selection */}
            <Col lg="12">
              <div className="mb-3 row">
                <label htmlFor="department_id" className="col-md-3 col-form-label">
                  {t("Department")}
                </label>
                <div className="col-md-7">
                  <Select
                    id="department_id"
                    name="department_id"
                    options={departmentOptions}
                    onChange={handleDepartmentChange}
                    value={selectedDepartment}
                  />
                </div>
              </div>
            </Col>

            {/* Vehicle Make */}
            <Col lg="6">
              <div className="mb-3 row">
                <label htmlFor="fleet_make" className="col-md-3 col-form-label">
                  {t("Make")}
                </label>
                <div className="col-md-9">
                  <Input
                    type="text"
                    placeholder={t("Enter make...")}
                    id="fleet_make"
                    value={formData.fleet_make}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </Col>

            {/* Vehicle Modal */}
            <Col lg="6">
              <div className="mb-3 row">
                <label htmlFor="fleet_modal" className="col-md-3 col-form-label">
                  {t("Model")}
                </label>
                <div className="col-md-9">
                  <Input
                    type="text"
                    placeholder={t("Enter Model...")}
                    id="fleet_modal"
                    value={formData.fleet_modal}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </Col>

            {/* VIN */}
            <Col lg="6">
              <div className="mb-3 row">
                <label htmlFor="fleet_vin" className="col-md-3 col-form-label">
                  {t("VIN")}
                </label>
                <div className="col-md-9">
                  <Input
                    type="text"
                    placeholder={t("Enter VIN...")}
                    id="fleet_vin"
                    value={formData.fleet_vin}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </Col>

            {/* Year */}
            <Col lg="6">
              <div className="mb-3 row">
                <label htmlFor="fleet_year" className="col-md-3 col-form-label">
                  {t("Year")}
                </label>
                <div className="col-md-9">
                  <Input
                    type="date"
                    id="fleet_year"
                    value={formData.fleet_year}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </Col>
            <Col lg="6">

              <div className="mb-3 row">
                <label htmlFor="fuel_type" className="col-md-3 col-form-label">
                  {t("Fuel type")}
                </label>
                <div className="col-md-7">
                <Select
  id="fuel_type"
  name="fuel_type"
  options={fuelOptions}
  onChange={handleFuelChange}
  defaultValue={selectedFuel}
/>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3 row">
                <label htmlFor="fleet_plate" className="col-md-3 col-form-label">
                  {t("Plate")}
                </label>
                <div className="col-md-9">
                  <Input
                    type="text"
                    placeholder={t("Enter Plate...")}
                    id="fleet_plate"
                    value={formData.fleet_plate}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Form Footer */}
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
        onClick={() => {
                            tog_vehicles(false);
                          }}
        >
          {t("Cancel")}
        </button>
        <button type="submit" className="btn btn-primary">
          {t("Save")}
        </button>
      </div>
    </form>

                      </div>
                    </Modal>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t('Fleet')} breadcrumbItemLink="/home" breadcrumbItem={t('Back To Home')} />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
            

              <div className="row" style={{direction:"rtl",marginBottom:'10px'}}>
              {isEditorAllowed && (
       <div className="col-md-4">
       <button type="button"onClick={() => { tog_vehicles()}}  className="btn btn-primary css-1bl3roz "> {t('Add Vehicles ')} </button>
       </div>
)}
             
             {isEditorAllowed && (
 <div className="col-md-2">
 
 <button type="button"  style={{background:'#34c38f'}} className="btn btn-success css-1bl3roz">
     <i className="bx bx-link font-size-16 align-middle me-2"></i>
     {t('Download Xls')}
    </button>
 </div>
)}
              
               </div>
              <div className="mb-3">
                <Card>
                {getVhiclesdata.length > 0 ?
                    <>
                <div className="inbox-container">
                  <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
                  <div className="listHeader css-jcpkb3">
                  <div className="listHeader_titleRow" >
                                <h1 className="listHeader_heading m-bottom-xx-small" >{t('Notofication')}</h1>
                               
                                <div className="listHeader_filter">
                                    <div className="cheshire-templates" >
                                        <div className="m-left-large cheshire_visibleContent cheshire_visible-active" >
                                            <span className="templates_filterBtn u-inline-block" >
                                                <div className="listHeader_search">
                                                    <div className="css-0">
                                                        <Label for="KRkDwy3Kp3-sdfdsf" className="css-5gs0ys"></Label>
                                                    </div>
                                                    <div  className="css-k008qs">
                                                        <div className="input-text-box-wrapper css-1eq84o" >
                                                            <div  className="css-1nenmzo">
                                                                <span  className="css-zf56fy">
                                                                    <span aria-hidden="true" className="SVGInline">
                                                                        <svg className="SVGInline-svg" fill="#868686" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                            <path d="M10.926 9.426 16 14.5 14.5 16l-5.074-5.074a6 6 0 1 1 1.5-1.5zM6 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
                          </svg>
                        </span>
                    </span>
                </div> 
                
                <Input 
                id="KRkDwy3Kp3-sdfdsf"
                 placeholder={t("Search Inbox and Folders")}
                  className="css-1ez4hss" 
                   type="text" 
                   value={searchTerm}
                   onChange={handleSearchChange} 
                   />
            </div>
        </div>
    </div>
    <button aria-haspopup="true" aria-expanded="false" data-qa="manage-envelopes-header-form-menu" className="olv-button olv-ignore-transform css-qhnch2" type="button">
        <span data-qa="manage-envelopes-header-form-menu-icon" className="css-1pig3b7">
            <span className="css-zf56fy">
                <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                            <path d="M4.063 12H0v1h4.063a2 2 0 0 0 3.874 0H16v-1H7.937a2 2 0 0 0-3.874 0zm6-5H0v1h10.063a2 2 0 0 0 3.874 0H16V7h-2.063a2 2 0 0 0-3.874 0zm-8-5H0v1h2.063a2 2 0 0 0 3.874 0H16V2H5.937a2 2 0 0 0-3.874 0z"></path>
                          </svg>
                        </span>
                    </span>
                </span>
                <span aria-hidden="true" data-qa="manage-envelopes-header-form-menu-text" className="css-16hz1ch">{t('Filters')}</span>
                <span data-qa="manage-envelopes-header-form-menu-accessibility-text" className="css-1pdgtej">{t('Edit Filters')}</span>
            </button>
        </span>
    </div>
</div>
</div>
</div>
                  </div>
                  </div>
 

         <div className="css-1obf64m">
     

<table className="css-1u2njnl_fleet" key={currentPage}>
  <thead className="css-1s8spa1_fleet">
    <tr>
      <th className="css-17bqg1m_fleet">
        <div className="btn-group me-2 mb-2 mb-sm-0" id="deletebtn">
          {showArrayData.length > 0 && (
            <Button
              type="button"
              onClick={handleDeleteClick}
              className="btn btn-danger waves-light waves-effect"
            >
              <i className="far fa-trash-alt"></i>
            </Button>
          )}
          {showArrayTrashData.length > 0 && (
            <Button
              type="button"
              onClick={handlePermanentlyClick}
              className="btn btn-danger waves-light waves-effect"
            >
              <i className="far fa-trash-alt"></i>
            </Button>
          )}
        </div>
      </th>
      <th className="css-17bqg1m_fleet">{t("Icon")}</th>
      <th className="css-rhk3of_fleet">{t("Subject")}</th>
      <th className="css-rhk3of">{t("Last Change")}</th>
      <th className="css-rhk3of">{t("Status")}</th>
      <th className="css-rhk3of">{t("Type")}</th>
      <th className="css-17bqg1m">{t("Actions")}</th>
    </tr>
  </thead>
  <tbody>
    {paginatedData.length > 0 ? (
      paginatedData.map((row, index) => (
        <tr className="css-cir4u5_fleet" key={index}>
          {/* Action Checkbox */}
          <td className="css-85irbq_fleet">
            <input
              type="checkbox"
              className="css-checkbox"
              onChange={() => console.log(`Checkbox clicked for ${row.fleet_id}`)}
            />
          </td>

          {/* Image */}
          <td className="css-85irbq_fleet">
            <img
              src={row.fleet_image || "https://via.placeholder.com/30"}
              alt="Vehicle"
              height="30"
            />
          </td>

          {/* Editable Fields */}
          <td className="css-85irbq_fleet">
            
             <div> 
             <EditableField
  label={t("Make")}
   type="text"
  content={row.Make}
  onSave={(newValue) =>
    handleSave(row.fleet_id, "Vehicles", "Make", newValue, "fleet_id")
  }
/>
           </div>
            <div> 
            <EditableField
              label={t("Model")}
               type="text"
              content={row.Model}
              onSave={(newValue) =>
                handleSave(row.fleet_id, "Vehicles", "Model", newValue, "fleet_id")
              }
            />
           </div>
           <div> 
           <EditableField
              label={t("Plate")}
              type="text"
              content={row.LicensePlate}
              onSave={(newValue) =>
                handleSave(row.fleet_id, "Vehicles", "LicensePlate", newValue, "fleet_id")
              }
            />
           </div>
            
          </td>

          {/* Registration Details */}
          <td className="css-85irbq_fleet">
          
             <div> 
             <EditableField
  label={t("Fuel")}
  content={row.FuelType} // Default value
  onSave={(newValue) =>
    handleSave(row.fleet_id, "Vehicles", "FuelType", newValue, "fleet_id")
  }
  type="select"
  options={fuelOptions}
/>
      
           </div>
            
               <div> 
               <EditableField
              label={t("Year")}
              type="date"
              content={row.Year}
              onSave={(newValue) =>
                handleSave(row.fleet_id, "Vehicles", "Year", newValue, "fleet_id")
              }
            />
           </div>
          
                  <div> 
                  <EditableField
              label={t("VIN")}
               type="text"
              content={row.VIN}
              onSave={(newValue) =>
                handleSave(row.fleet_id, "Vehicles", "VIN", newValue, "fleet_id")
              }
            />
           </div>
          </td>
          <td className="css-85irbq_fleet capitalize">
          <div> 
          <EditableField
              label={t("Apk Date")}
              type="date"
              content={row.MaintenanceDate}
              onSave={(newValue) =>
                handleSave(row.fleet_id, "Maintenance", "MaintenanceDate", newValue, "VehicleID")
              }
            />
           </div>
           <div> 
           <EditableField
              label={t("inFleetDate")}
              type="date"
              content={row.inFleetDate}
              onSave={(newValue) =>
                handleSave(row.fleet_id, "Vehicles", "inFleetDate", newValue, "fleet_id")
              }
            />
           </div>
           <div> 
           <EditableField
              label={t("defleetDate")}
              type="date"
              content={row.defleetDate}
              onSave={(newValue) =>
                handleSave(row.fleet_id, "Vehicles", "defleetDate", newValue, "fleet_id")
              }
            />
           </div>
            
          </td>
          {/* Status and Dropdowns */}
          <td className="css-85irbq_fleet">
  {/* Status Dropdown */}
  <EditableField
    label={t("Status")}
    content={row.fleet_status}
    onSave={(newValue) =>
      handleSave(row.fleet_id, "Vehicles", "fleet_status", newValue, "fleet_id")
    }
    type="select"
    options={[
      { value: "Active", label: t("Active") },
      { value: "Inactive", label: t("Inactive") },
      { value: "Maintenance", label: t("Maintenance") },
      { value: "Retired", label: t("Retired") },
    ]}
  />

  {/* Department Dropdown */}
  <EditableField
    label={t("Department")}
    content={row.department || ""}
    onSave={(newValue) =>
      handleSave(row.fleet_id, "Vehicles", "department", newValue, "fleet_id")
    }
    type="select"
    options={[
      { value: "", label: t("Select a Department") },
      ...uniqueDepartments.map((item) => ({
        value: item.departments_id,
        label: item.dep_name,
      })),
    ]}
  />

  {/* Driver Dropdown */}
  <EditableField
    label={t("Driver")}
    content={row.DriverID || ""}
    onSave={(newValue) =>
      handleSave(row.AssignmentID, "Assignments", "DriverID", newValue, "AssignmentID")
    }
    type="select"
    options={[
      { value: "", label: t("Select Driver") },
      ...client.map((item) => ({
        value: item.client_id,
        label: item.frist_name,
      })),
    ]}
  />
</td>


          {/* Actions */}
          <td className="css-85irbq_fleet">
          <div>
            <a href={`/fleet/details/${row.fleet_id}`}>
            <button
              className="olv-button css-remove-button"
              style={{width:'80px',marginBottom:'10px',backgroundColor:'#059c25',color:'white'}}
            >
              {t("Details")}
            </button>
            </a>
            </div>
            {isEditorAllowed && (
   <div>
   <button
    style={{width:'80px'}}
     className="olv-button css-remove-button"
     onClick={() => handleRemove(row.fleet_id)}
   >
     {t("Remove")}
   </button>
   </div>
)}
          
          </td>
        </tr>
      ))
    ) : (
      <tr className="css-cir4u5_fleet">
        <td className="css-85irbq_fleet" style={{ width: "100%" }}>
          <div className="align-items-center text-center p-4">
            <i className="mdi mdi-email-outline me-2 display-5"></i>
            <h4>{t("No Record Found")}</h4>
          </div>
        </td>
      </tr>
    )}
  </tbody>
</table>

         </div>
                </div>
                </>
                    : <div className="align-items-center text-center p-4"> <i className="mdi mdi-email-outline me-2 display-5"></i> <h4> No Recored Found </h4>
                    </div>}
                </Card>
               
                {getVhiclesdata.length > 0 &&
                  <Row>
  <Col xs="7">
    {t("Showing")} {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} -{" "}
    {Math.min(currentPage * itemsPerPage, totalItems)} {t("of")} {totalItems}
  </Col>
  <Col xs="5">
    <div className="btn-group float-end">
      <Button
        type="button"
        color="primary"
        size="sm"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <i className="fa fa-chevron-left" />
      </Button>
      <Button
        type="button"
        color="primary"
        size="sm"
        disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        <i className="fa fa-chevron-right" />
      </Button>
    </div>
  </Col>
</Row>

                }
              
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(Fleet));

