import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ListGroup, Collapse, Button,
    Dropdown, DropdownToggle, DropdownMenu 
  } from "reactstrap";
  import {
    helpCenterData,
  } from "../../common/data";
  import Breadcrumbs from "../../components/Common/Breadcrumb";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import TawkToWidget from "components/LiveChat";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import { Link ,useLocation } from "react-router-dom";
import SearchHelp from './searchHelp';
import { Helmet } from 'react-helmet';
const Submenu = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language
  const location = useLocation();
  let activeMainMenus = [];

  // Loop through helpCenterData to collect all main menu data
  helpCenterData.forEach((menuItem) => {
  
    if (menuItem.mainMenu && menuItem.mainMenu.lang === currentLanguage) {
      const isActive = location.pathname === `/help-center/${menuItem.mainMenu.url}`;
  
      if (isActive) {
        activeMainMenus = menuItem.mainMenu;
      }
    }
  });
  
  
    useEffect(() => {
     
        const headerColor = document.getElementById('header_color');
        
        // Check if the element exists before accessing it
        if (headerColor) {
          headerColor.style.backgroundColor = '#f0f8ff';
        }
  
      }, [])

     
      
      useEffect(() => {
        // Function to update meta tags
        const updateMetaTags = () => {
          document.title = `${t(`Help Center - ${activeMainMenus.name}`)} | Shiftspec.com`;
          const metaElement = document.querySelector('meta[name="description"]');
          if (metaElement) {
            metaElement.setAttribute('content', 'Updated meta description content.');
          } else {
            addOrUpdateMeta('description', 'This is the new meta description.');
          }
          addOrUpdateMeta('keywords', 'HTML, CSS, JavaScript, Web Development');
          function addOrUpdateMeta(name, content) {
            // Check if a meta tag with the given name already exists
            let metaTag = document.querySelector(`meta[name="${name}"]`);
            
            if (metaTag) {
              // If the meta tag exists, update its content
              metaTag.setAttribute('content', content);
            } else {
              // If the meta tag doesn't exist, create it
              metaTag = document.createElement('meta');
              metaTag.setAttribute('name', name);
              metaTag.setAttribute('content', content);
              document.head.appendChild(metaTag); // Append it to <head>
            }
          }
          
          // Array of meta tags to add/update
          const metaTags = [
            { name: 'description', content: 'This is a sample description for the page.' },
            { name: 'keywords', content: 'HTML, CSS, JavaScript' },
            { name: 'author', content: 'John Doe' }
          ];
          
          // Iterate over the metaTags array and add/update each meta tag
          metaTags.forEach(tag => {
            addOrUpdateMeta(tag.name, tag.content);
          });
          // Helper function to add or update meta tags
          const addOrUpdateMetaTag = (property, content) => {
            let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
            if (tag) {
              tag.setAttribute('content', content);
            } else {
              tag = document.createElement('meta');
              tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
              tag.setAttribute('content', content);
              document.head.appendChild(tag);
            }
          };
    
          // Open Graph Meta Tags
          addOrUpdateMetaTag('og:title', activeMainMenus?.name || 'Help Center');
          addOrUpdateMetaTag('og:description', activeMainMenus?.description || 'Find helpful articles to get started with ShiftSpec');
          addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
          addOrUpdateMetaTag('og:type', 'website');
    
          // Twitter Meta Tags
          addOrUpdateMetaTag('twitter:title', activeMainMenus?.name || 'Help Center');
          addOrUpdateMetaTag('twitter:description', activeMainMenus?.description || 'Find helpful articles to get started with ShiftSpec');
          addOrUpdateMetaTag('twitter:card', 'summary_large_image');
        };
    
        // Call the function to update meta tags
        updateMetaTags();
    
      }, [activeMainMenus, location.pathname, t]);
      
  return (
    <React.Fragment>
     
  <header style={styles.header}>
      <nav style={styles.nav}>
        <ul style={styles.menu}>
          <li>
            <Link to="/" style={styles.link}>
            {t('Go to shiftspec.com')} 
            </Link>
          </li>
          <li>
            <Link to="/help-center" style={styles.link}>
            {t('Help Center')} 
            </Link>
          </li>
          <li style={styles.languageSelector}>
          <LanguageDropdown /> 
          </li>
        </ul>
      </nav>
    </header>
  
    <section className="relative" style={{padding:'50px', background: '#fafff0' }}>
      <Row>
        <Col sm={12} className="d-flex align-items-center justify-content-center">
        <div className="lg:col-span-7 xl:pr-1 text-center">
        <h1 className="font-bold text-dark text-[30px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold  mb-1.5 lg:mt-3 md:mb-6">
        {t('What can we help you with?')} 
        </h1>
        <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6">
        {t('We are happy to provide support')} 
        </p>
      </div>
         
        </Col>
        <Col sm={12} className="d-flex align-items-center justify-content-center">
        <SearchHelp props={props} />
</Col>

      
      </Row>
    </section>


    <main className=" bg-white" style={{padding:'10px'}}>
    <Breadcrumbs title={t('Help Center')} breadcrumbItemLink={`/help-center`} breadcrumbItem={t(activeMainMenus.name)} />
  <Row>
  <Col sm={4} className="bg-white p-3">
  <div className='kb-sidebar'>
  <div className='kb-category-menu'>
  <ul>
  {helpCenterData.map((menuItem, index) => {
  
    if (menuItem.mainMenu && menuItem.mainMenu.name && menuItem.mainMenu.lang === currentLanguage) { // Check for valid main menu
      const isActive = location.pathname === `/help-center/${menuItem.mainMenu.url}`;
      return (
        <li key={menuItem.mainMenu.id} className='open'>
          <a href={`/help-center/${menuItem.mainMenu.url}`} className='font-bold'>
            <div className='kb-category-menu-option'>
              <span className='kb-category-menu-option__color-bar font-bold' style={{ backgroundColor: '#2d3e50' }}>
                {menuItem.mainMenu.name} {/* Render main menu name */}
              </span>
            </div>
            <div>
              <svg width="12" height="7" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6817 1.6816l-4.5364 4-4.5364-3.9315" stroke="#2d3e50" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
          </a>

          {/* Render Submenus */}
          {isActive && menuItem.mainMenu.subMenu && Array.isArray(menuItem.mainMenu.subMenu) && menuItem.mainMenu.subMenu.length > 0 && (
   <ul>
   {menuItem.mainMenu.subMenu.map(sub => (
     <li key={sub.id}>
       <a href={`#${sub.url}`}>
         <span className='kb-category-menu-option__color-bar' style={{ backgroundColor: '#2d3e50' }}>
           {sub.name} {/* Render submenu name */}
         </span>
       </a>
     </li>
   ))}
 </ul>
)}
        </li>
      );
    }
    return null;
  })}
</ul>
   
    </div>
  </div>

    </Col>

    
    <Col sm={8}>
      {helpCenterData.map((menuItem) => {
        if (menuItem && menuItem.mainMenu && menuItem.mainMenu.subMenu && Array.isArray(menuItem.mainMenu.subMenu)) {
          // Check if the current URL matches the mainMenu's URL
          const isActive = location.pathname === `/help-center/${menuItem.mainMenu.url}`;

          return (
            isActive && (
              <div key={menuItem.mainMenu.id}>
                {/* Submenu Header */}
                <h3 className="font-bold" style={{ fontSize: '26px' }}>
                  {menuItem.mainMenu.name} {/* Main Menu Name */}
                </h3>
                <h6 className="kb-index__category-description">
                  {menuItem.mainMenu.description} {/* Main Menu Description */}
                </h6>
                <hr style={styles.hrline} />

                {/* Submenu List */}
                {menuItem.mainMenu.subMenu.map((subMenu) => (
                  <div key={subMenu.id}>
                    {/* Submenu Title */}
                    <div className="kb-category-menu-option">
                      <span
                        className="font-bold kb-category-menu-option__color-bar"
                        style={{ backgroundColor: '#2d3e50', fontSize: '20px' }}
                      >
                        {subMenu.name} {/* Submenu Name */}
                      </span>
                    </div>

                    {/* Child Menus under Submenu */}
                    {subMenu.childMenu && subMenu.childMenu.length > 0 && (
                      <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
                        {subMenu.childMenu.map((child) => (
                          <li key={child.id} style={{ marginBottom: '10px' }}>
                            <a
                              href={`/help-center/resolve/${child.url}`}
                              style={{
                                textDecoration: 'none',
                                display: 'block',
                                padding: '10px 15px',
                                color: '#2d3e50',
                                borderRadius: '5px',
                                transition: 'background-color 0.3s ease',
                                fontSize: '18px',
                                fontWeight: '400',
                              }}
                            >
                              <span className="kb-category-menu-option__color-bar">
                                {child.name} {/* Child Menu Name */}
                              </span>
                            </a>
                            
                          </li>
                          
                        ))}
                         <hr style={styles.hrline} />
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            )
          );
        }
        return null; // Return null if the menu item is empty or invalid
      })}
    </Col>
  </Row>
</main>


    </React.Fragment>
  );
};

const styles = {
    header: {
      background: '#f1f8fa',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      height: '70px', // Set a fixed height for the header
    },
    nav: {
      display: 'flex',
      justifyContent: 'center', // Center content horizontally within the nav
      alignItems: 'center', // Align vertically
      width: '100%', // Ensure nav takes full width
      maxWidth: '1200px', // Max width for larger screens
    },
    menu: {
      display: 'flex',
      justifyContent: 'center', // Center the menu items
      alignItems: 'center', // Align items vertically
      listStyleType: 'none',
      padding: 0,
      margin: 0,
    },
    link: {
      textDecoration: 'none',
      color: '#333',
      marginRight: '20px',
      fontSize: '16px',
    },
    languageSelector: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center', // Ensure the dropdown is vertically centered
    },
    select: {
      padding: '5px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '600px',  // Set the max width of the form to 600px or adjust as needed
      margin: '0 auto',  // Center the form horizontally
      position: 'relative',  // To position the dropdown below
    },
    input: {
      flex: 1,
      padding: '10px 15px',
      fontSize: '16px',
      borderRadius: '4px 0 0 4px',
      border: '1px solid #ccc',
      outline: 'none',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '0 4px 4px 0',
      cursor: 'pointer',
    },
    dropdown: {
      position: 'absolute',
      top: '100%',
      left: '0',
      width: '100%',  // Make the dropdown match the form width
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '0 0 4px 4px',
      zIndex: 10,
      maxHeight: '200px',
      overflowY: 'auto',  // Allow scrolling if the dropdown content exceeds the height
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    dropdownItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 15px',
      textDecoration: 'none',
      color: '#333',
      borderBottom: '1px solid #eaeaea',
      transition: 'background-color 0.3s ease',
      borderRadius: '8px',
    },
      contentContainer: {
        margin: '0 auto !important',
        float: 'none !important',
      },
      hrline: {
        paddingBottom: '1.3125rem',
    marginBottom: '1.75rem',
    marginTop: '.375rem',
    borderBottom: '1px solid #DFE3EB',
      },

      ulsub: {
        paddingBottom: '1.3125rem',
    marginBottom: '1.75rem',
    marginTop: '.375rem',
    borderBottom: '1px solid #DFE3EB',
      },
  };
  
  Submenu.propTypes = {
    history: PropTypes.object,
    t: PropTypes.func.isRequired,
  };
export default withTranslation()(withRouter(Submenu));