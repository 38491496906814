import React, { useRef, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Col, Row, Modal, Button,Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"; // Import Button for the print button
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { format, addDays } from "date-fns";
import classnames from 'classnames';
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const daysOfWeekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const dateSchedule = ["01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00"];
function formatDateuren(dateString) {
  const date = new Date(dateString);
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
const roundToNearestHour = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return `${String(minutes >= 30 ? hours + 1 : hours).padStart(2, '0')}:00`;
};
const PrintShiftModal = ({ t, show, handleClose, urendata, absence, currentDetails}) => {
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [company_name , setCompany_name ] = useState(user.company_name );
  const printContentRef = useRef(); // Reference to the content to print
  const [activeTab, setActiveTab] = useState('list');
  const toggleTab = (tab) => setActiveTab(tab);
  const [isPrinting, setIsPrinting] = useState(false);
  const handlePrint = () => {
    const navbar = document.getElementById('nave-tabs');
    if (navbar) navbar.style.display = 'none';
  
    // Create a hidden iframe for printing
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0';
    printFrame.style.height = '0';
    printFrame.style.border = 'none';
    document.body.appendChild(printFrame);
    
    // Access the document of the iframe and write the content to print
    const doc = printFrame.contentDocument || printFrame.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>${company_name}</title>
          <style>
            /* Include any CSS needed for the print layout here */
            body { font-family: Arial, sans-serif; }
            .modal-body { overflow-y: auto; max-height: calc(100vh - 150px); padding-bottom: 60px; }
          </style>
        </head>
        <body>${printContentRef.current.innerHTML}</body>
      </html>
    `);
    doc.close();
  
    // Trigger the print dialog after the iframe content loads
    printFrame.onload = () => {
      printFrame.contentWindow.print();
      // Remove the iframe after printing
      document.body.removeChild(printFrame);
      if (navbar) navbar.style.display = 'flex';
    };
  };
  

  const generateDates = () => {
    const dates = [];
    const startDate = new Date(currentDetails.startDate);
    for (let i = 0; i < currentDetails.totalDays; i++) {
      dates.push(addDays(startDate, i));
    }
    return dates;
  };

  const dates = generateDates();

  const formatDate = (date) => {
    if (!date || isNaN(date.getTime())) {
      console.error("Invalid date provided to formatDate:", date);
      return { day: "", date: "" }; // or some other default value
    }
  
    return {
      day: format(date, 'EEE'),
      date: format(date, 'd MMM'),
    };
  };

  const getShiftDetails = (date, employerId) => {
    if (!date || isNaN(new Date(date).getTime())) return null;
    const formattedDate = format(new Date(date), 'yyyy-MM-dd');
    const shiftData = urendata[employerId];
    return shiftData?.items.filter(
      item => format(new Date(item.uren_date), 'yyyy-MM-dd') === formattedDate
    ) || null;
  };

  const isEmployeeAbsentOnDate = (date, employerId) => {
    const absenceDetails = absence.find(abs => {
      const absenceStartDate = formatDateuren(abs.startdate_absence);
      const absenceEndDate = formatDateuren(abs.enddate_absence);
      const isSameWorker = String(abs.workerid) === String(employerId);
      const isSameOrAfterStart = format(new Date(date), 'yyyy-MM-dd') >= absenceStartDate;
      const isSameOrBeforeEnd = format(new Date(date), 'yyyy-MM-dd') <= absenceEndDate;

      return isSameWorker && isSameOrAfterStart && isSameOrBeforeEnd;
    });

    return absenceDetails || null;
  };

  const uniqueTeams = [...new Set(Object.values(urendata).map(data => data.teamNames).filter(Boolean))];
  const generateDatesTypeDate = (teamname) => {
    if (!currentDetails) {
      console.error('currentDetails is null or undefined 2');
      return [];
    }
 
    const dates = [];
    const formattedDate = `${currentDetails.year}-${String(monthNames.indexOf(currentDetails.monthName) + 1).padStart(2, '0')}-${String(currentDetails.date).padStart(2, '0')}`;
       
    const timeSlots = dateSchedule.map(time => ({
      formattedDate,
      timeSlot: time,
      teamnames: teamname 
    }));
    dates.push(...timeSlots); // Flatten the array to include all time slots
  return dates;
  }

 
  const renderSheduleTemp = () => {
    if (!currentDetails) return null;
  
    if (currentDetails.type === "month" || currentDetails.type === "week") {
      // Existing month/week logic
      return uniqueTeams.map(teamName => (
        <div key={teamName}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 0',
          }}>
            <h6 style={{ margin: 0, fontWeight: 'bold', padding: '5px', textAlign: 'left' }}>
              {`${currentDetails.title}`}
            </h6>
            <h6 style={{ margin: 0, fontWeight: 'bold', padding: '5px', textAlign: 'right' }}>{teamName}</h6>
          </div>
          <table style={{
            width: '100%',
            fontFamily: 'Arial, sans-serif',
            fontSize: '12px',
            color: 'black',
            borderCollapse: 'collapse',
            tableLayout: 'fixed',
          }}>
            <tbody>
              {Array.from({ length: Math.ceil(dates.length / 7) }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {dates.slice(rowIndex * 7, rowIndex * 7 + 7).map((date, colIndex) => {
                    const formattedDate = formatDate(date);
                    const employerIds = Object.keys(urendata).filter(
                      id => urendata[id].teamNames === teamName
                    );
  
                    return (
                      <td key={colIndex} style={{ padding: '2px', border: '1px solid black', verticalAlign: 'top', width: '14%' }}>
                        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          {t(formattedDate.day)} {t(formattedDate.date)}
                        </div>
  
                        {employerIds.map(employerId => {
                          const shifts = getShiftDetails(date, employerId);
                          const absenceRecord = isEmployeeAbsentOnDate(date, employerId);
  
                          if (absenceRecord) {
                            return (
                              <div key={`${employerId}-absence`} style={{ marginBottom: "2px", border: "1px solid black", boxShadow: `#ffde00 3px 0px 0px inset`, padding: "5px" }}>
                                <div style={{ fontWeight: "bold" }}>{absenceRecord.count_as === 'vk' ? t('Vacation') : absenceRecord.count_as === 'zk' ? t('Sick') : t('Other')}</div>
                                <div>{absenceRecord.frist_name} {absenceRecord.last_name}</div>
                              </div>
                            );
                          } else if (shifts) {
                            return shifts.map(shift => (
                              <div key={shift.uren_id} style={{ marginBottom: '2px', border: '1px solid black', boxShadow: `${shift.shift_color} 3px 0px 0px inset`, padding: '5px' }}>
                                <div style={{ fontWeight: 'bold' }}>{shift.shift_name || ''}</div>
                                <div>{shift.frist_name} {shift.last_name}</div>
                                <div>{shift.start_time_uren} - {shift.eind_time_uren}</div>
                              </div>
                            ));
                          } else {
                            return (
                              <div key={employerId} style={{ textAlign: 'left', padding: '5px', fontStyle: 'italic', color: 'gray' }}>
                                {t('No Shift Assigned')}
                              </div>
                            );
                          }
                        })}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ));
    } else if (currentDetails.type === "date") {
      const date = currentDetails.startDate;

      return (
        <div>
           
          <Nav tabs id="nave-tabs">
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'list' })} onClick={() => { toggleTab('list'); }}>
              {t('List')} 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'employer' })} onClick={() => { toggleTab('employer'); }}>
              {t('Employeer')}  
              </NavLink>
            </NavItem>
          </Nav>
      
          <TabContent activeTab={activeTab}>
            {/* List Tab */}
            <TabPane tabId="list">
  <div style={{ overflowX: 'auto' }}>
    {uniqueTeams.map(teamName => (
      <div key={teamName} style={{ marginBottom: '20px' }}>
        {/* Team header with title and team name */}
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '1rem' }}>
          <thead>
            <tr>
              <th colSpan="100%">
                <div style={{ textAlign: 'left', padding: '32px 0', display: 'flex', justifyContent: 'space-between', color: '#000', fontSize: '12px', fontFamily: 'sans-serif', gap: '20px', alignItems: 'center' }}>
                  <h1 style={{ fontSize: '1.3rem', margin: 0, flex: 1 }}>{currentDetails.title}</h1>
                  <p style={{ fontSize: '13px', fontWeight: '500', marginTop: '12px', lineHeight: '1' }}>
                    {teamName} - {teamName}
                  </p>
                </div>
              </th>
            </tr>
            <tr style={{ fontWeight: 'bold', borderBottom: '2px solid #000', textAlign: 'left' }}>
              <th style={{ padding: '0.5rem', width: '25%' }}>{t('Employeer')}</th>
              <th style={{ padding: '0.5rem', width: '10%' }}>{t('Shift')}</th>
              <th style={{ padding: '0.5rem', width: '25%' }}>{t('Description')}</th>
              <th style={{ padding: '0.5rem', width: '25%' }}>{t('Team')}</th>
            </tr>
          </thead>
          
          {/* Team-specific data rows */}
          <tbody style={{ fontSize: '0.7rem' }}>
            {Object.keys(urendata).map(employerId => {
              const shifts = getShiftDetails(date, employerId);
              const absenceRecord = isEmployeeAbsentOnDate(date, employerId);
              if (absenceRecord && urendata[employerId].teamNames === teamName) {
                // Absence row for the current team
                const teamData = Object.values(urendata).find(data => data.team_id === absenceRecord.team_id);
                const actualTeamName = teamData ? teamData.team_name : t('No Team Assigned');
                
                return (
                  <tr style={{ borderBottom: '1px solid #b0b0b0' }} key={`${employerId}-absence`}>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{absenceRecord.frist_name} {absenceRecord.last_name}</td>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{t('Absence')} {absenceRecord.absencestatus}</td>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{t('Absence due to')} {absenceRecord.count_as === 'vk' ? t('Vacation') : absenceRecord.count_as === 'zk' ? t('Sick') : t('Other')}</td>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{teamName}</td>
                  </tr>
                );
              } else if (shifts && urendata[employerId].teamNames === teamName) {
                // Shift rows for the current team
                return shifts.map(shift => (
                  <tr style={{ borderBottom: '1px solid #b0b0b0' }} key={shift.uren_id}>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{shift.frist_name} {shift.last_name}</td>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{shift.start_time_uren} - {shift.eind_time_uren}</td>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{shift.shift_name}</td>
                    <td style={{ padding: '0.5rem', verticalAlign: 'top' }}>{shift.team_name}</td>
                  </tr>
                ));
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
    ))}
  </div>
</TabPane>

            
{/* Employer Tab */}
{/* Employer Tab */}
<TabPane tabId="employer">
  <div role="tabpanel" id="department-content" aria-hidden={activeTab !== 'employer'}>
    {uniqueTeams.map(teamName => (
      <div key={teamName} style={{ marginBottom: '20px' }}>
        {/* Header table with date and team name */}
        <table style={{ width: '100%', fontFamily: 'Arial, sans-serif', fontSize: '12px', color: '#000', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={{ width: '50%' }}>
                <p style={{ margin: '10px 0', padding: '5px', fontWeight: 'bold', textAlign: 'left' }}>
                  {currentDetails.title}
                </p>
              </th>
              <th style={{ width: '50%' }}>
                <p style={{ margin: '10px 0', padding: '5px', fontWeight: 'bold', textAlign: 'right' }}>
                  {teamName} - {teamName}
                </p>
              </th>
            </tr>
          </thead>
        </table>

        {/* Main table with hourly headers for each team */}
        <table style={{ width: '100%', fontFamily: 'Arial, sans-serif', fontSize: '12px', color: '#000', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={{ padding: '5px', border: '1px solid black', textAlign: 'left', fontWeight: 'bold', width: '10%' }}>{t('Employee')}</th>
              {dateSchedule.map((time, index) => (
                <th key={index} style={{ padding: '5px', border: '1px solid black', textAlign: 'center', fontWeight: 'bold', width: `${100 / dateSchedule.length}%` }}>
                  {time.split(':')[0]}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {Object.keys(urendata).map(employerId => {
              const { items = [], teamNames, employeeName } = urendata[employerId];
              if (teamNames !== teamName) return null;
              const absenceRecord = isEmployeeAbsentOnDate(currentDetails.startDate, employerId);

              return (
                <React.Fragment key={employerId}>
                  {/* Absence Row */}
                  {absenceRecord && (
                    <tr key={`${employerId}-absence`}>
                      <td style={{ padding: '5px', fontSize: '10px', border: '1px solid black', textAlign: 'left', fontWeight: 'bold', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      {t('Absence')}
                      </td>
                      <td colSpan={dateSchedule.length} style={{ border: '1px solid black', verticalAlign: 'top', padding: '2px 4px' }}>
                        <div style={{ position: 'relative', width: '100%', left: '0%', marginTop: '0px', border: '1px solid black', fontWeight: 'normal', textAlign: 'left', boxShadow: 'rgb(242, 202, 0) 3px 0px 0px inset' }}>
                          <div style={{ margin: '2px 0px 2px 7px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            <span>{absenceRecord.count_as === 'vk' ? 'Vacation' : absenceRecord.count_as === 'zk' ? 'Sick' : 'Other'}</span><br />
                            <span>{absenceRecord.frist_name} {absenceRecord.last_name}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* Schedule Row */}
                  {items.map(shift => {
                    if (!shift.start_time_uren || !shift.eind_time_uren) return null;

                    const startTime = shift.start_time_uren;
                    const endTime = shift.eind_time_uren;
                    // Find start and end index in dateSchedule
                    let startIndex = dateSchedule.indexOf(roundToNearestHour(shift.start_time_uren));
                    let endIndex = dateSchedule.indexOf(roundToNearestHour(shift.eind_time_uren));

                    if (startTime > endTime) {
                      // Part 1: From start time to the end of the current day (23:59)
                      let endOfDayIndex = dateSchedule.length - 1;
                      const part1Width = `${((endOfDayIndex - startIndex + 1) * 100) / dateSchedule.length}%`;
                      const part1LeftPosition = `${(startIndex * 100) / dateSchedule.length}%`;
                  
                      // Part 2: From the start of the next day (00:00) to the end time
                      const startOfDayIndex = 0;
                      endIndex = dateSchedule.indexOf(roundToNearestHour(endTime));
                      const part2Width = `${((endIndex - startOfDayIndex + 1) * 100) / dateSchedule.length}%`;

                      return (
                        <React.Fragment key={shift.uren_id}>
                          {/* Part 1: Display from start time to end of day */}
                          <tr>
                            <td style={{ padding: '5px', fontSize: '10px', border: '1px solid black', textAlign: 'left', fontWeight: 'bold' }}>
                              {employeeName}
                            </td>
                            <td colSpan={dateSchedule.length} style={{ border: '1px solid black', verticalAlign: 'top', padding: '2px 4px' }}>
                              <div style={{ position: 'relative', width: part1Width, left: part1LeftPosition, marginTop: '0px', border: '1px solid black', fontWeight: 'normal', textAlign: 'left', boxShadow: `${shift.shift_color} 3px 0px 0px inset` }}>
                                <div style={{ margin: '2px 0px 2px 5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                  <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    <span><strong>{shift.shift_name || ''}</strong></span>
                                    <span> {startTime} - 23:59</span>
                                  </div>
                                  <span>{shift.frist_name} {shift.last_name}</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                  
                          {/* Part 2: Display from start of day to end time */}
                          <tr>
                            <td style={{ padding: '5px', fontSize: '10px', border: '1px solid black', textAlign: 'left', fontWeight: 'bold' }}>
                              {employeeName}
                            </td>
                            <td colSpan={dateSchedule.length} style={{ border: '1px solid black', verticalAlign: 'top', padding: '2px 4px' }}>
                              <div style={{ position: 'relative', width: part2Width, left: '0%', marginTop: '0px', border: '1px solid black', fontWeight: 'normal', textAlign: 'left', boxShadow: `${shift.shift_color} 3px 0px 0px inset` }}>
                                <div style={{ margin: '2px 0px 2px 5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                  <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    <span><strong>{shift.shift_name || ''}</strong></span>
                                    <span> 00:00 - {endTime}</span>
                                  </div>
                                  <span>{shift.frist_name} {shift.last_name}</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    } else {
                      // Normal case: Shift within the same day
                      const blockWidth = `${((endIndex - startIndex + 1) * 100) / dateSchedule.length}%`;
                      const leftPosition = `${(startIndex * 100) / dateSchedule.length}%`;
                  
                      return (
                        <tr key={shift.uren_id}>
                          <td style={{ padding: '5px', fontSize: '10px', border: '1px solid black', textAlign: 'left', fontWeight: 'bold' }}>
                            {employeeName}
                          </td>
                          <td colSpan={dateSchedule.length} style={{ border: '1px solid black', verticalAlign: 'top', padding: '2px 4px' }}>
                            <div style={{ position: 'relative', width: blockWidth, left: leftPosition, marginTop: '0px', border: '1px solid black', fontWeight: 'normal', textAlign: 'left', boxShadow: `${shift.shift_color} 3px 0px 0px inset` }}>
                              <div style={{ margin: '2px 0px 2px 5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                  <span><strong>{shift.shift_name || ''}</strong></span>
                                  <span> {startTime} - {endTime}</span>
                                </div>
                                <span>{shift.frist_name} {shift.last_name}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    ))}
  </div>
</TabPane>




          </TabContent>
        </div>
      );
    }
  };
  
  return (
    <React.Fragment>
      <Modal size="lg" isOpen={show} toggle={handleClose} centered style={{ bottom: '0' }} scrollable>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Print Schedule</h5>
          <button onClick={handleClose} type="button" className="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* {currentDetails.type === 'motn' && currentDetails.type === 'week' && (

)} */}
        {/* Scrollable modal body */}
        <div className="modal-body" ref={printContentRef} style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 150px)', paddingBottom: '60px' }}>
          {renderSheduleTemp()}
        </div>

        {/* Fixed footer outside of scrollable content */}
        <div className="modal-footer d-flex justify-content-between align-items-center fixed-footer">
          <button type="button" className="btn btn-primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </Modal>

      <style>
        {`
          .fixed-footer {
            position: sticky;
            bottom: 0;
            background: white;
            width: 100%;
            box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
            z-index: 10;
          }
          .modal-body {
            padding-bottom: 70px; /* Space for the fixed footer */
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(PrintShiftModal));
