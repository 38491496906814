import React from "react";
import { Navigate ,Routes, Route } from "react-router-dom";

// Document Pages Component
import Home from "../pages/Document/index";
import Prepare from "../pages/Document/Perpare";

//Email
import ManageInbox from "../pages/Manage/email-inbox";
// SignEditor Component

import CreateDoc from "../pages/Prepare/ModalComponent";
import PreviewModal from "../pages/Prepare/PreviewModal";
// Profile
import UserProfile from "../pages/Authentication/user-profile";
import Client from "../pages/Client/Signing";

import RapporData from "../pages/Rapport/rapost-dashbord";
import Raport from "../pages/Rapport/raport"
import Setting from "../pages/Setting/default";
import Template from "../pages/Template/template";
import Notification from "../pages/Notification/notification";
import PagesPricing from "../pages/Unuser/checkout";
import CollaboratirList from "../pages/Collaborator/list";
import AddCollaborator from "../pages/Collaborator/AddCollaboarator";
import AddCollaboratorMobile from "../pages/Collaborator/Addmobile";
import Details from "../pages/Collaborator/Details";
import PutCollaboarator from "../pages/Collaborator/PutCollaboarator";
import Timesheet from "../pages/Planning/Timesheet";
import Schedule from "../pages/Planning/Schedule";
import Demo from "../pages/Planning/Demo";
import Fleet from "../pages/Fleet/List";
import FleetDetails from "../pages/Fleet/Details";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ContactSales from "../pages/Page/contacsales";
import AboutUs from "../pages/Page/about-us";
import Support from "../pages/Page/support";
import Helpdesk from "../pages/Page/Helpdesk";
import Submenu from "../pages/Page/submenu";
import ContentHelp from "../pages/Page/content";
import Blogs from "../pages/Page/Blogs";
import BlogView from "../pages/Page/BlogView";
import BlogCat from "../pages/Page/BlogCat";
import SoluTimeSheet from "../pages/Page/Solutions/Timasheet";
import DSignature from "../pages/Page/Solutions/Signature";
import SoluSchedule from "../pages/Page/Solutions/Schedule";
import SoluAbsense from "../pages/Page/Solutions/Absense";
import InHotel from "../pages/Page/Industies/Hotel";
import Production from "../pages/Page/Industies/Production";
import ReCreation from "../pages/Page/Industies/Recreation";
import Restaurant from "../pages/Page/Industies/Restaurant";
import Retail from "../pages/Page/Industies/Retail";
import Transport from "../pages/Page/Industies/Transport";
import Industries from "../pages/Page/industrie";
import HomePage from "../pages/Page/HomePage";
//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";;
import AccessDenied from "../components/Permissions/AccessDenied";
import RouteGuard from "../components/Permissions/withPermission";
// Dashboard


const authProtectedRoutes = [
  { path: "/home", component:<Home  />},
  { path: "/access-denied", component:<AccessDenied  />},
  { path: "/api/create-new/doc/:uuid", component: <RouteGuard component={Prepare} content='post' /> },
  
 
 //Email 
 { path: "/fleet/details/:fleet_id", component: <RouteGuard component={FleetDetails} content='fleet' />},
 { path: "/fleet", component: <RouteGuard component={Fleet} content='fleet' />},
 { path: "/manage", component: <RouteGuard component={ManageInbox} content="manage" /> },
 { path: "/reports", component: <RouteGuard component={RapporData} content='report' /> },
 { path: "/report", component: <RouteGuard component={Raport} content='report' /> },
 { path: "/settings", component: <RouteGuard component={Setting} content='setting' /> },
 { path: "/template", component: <RouteGuard component={Template} content='manage' />},
 { path: "/notification", component: <Notification /> },
 { path: "/collaborator", component: <RouteGuard component={CollaboratirList} content='collaborator' />},
 { path: "/add/collaborator", component: <RouteGuard component={AddCollaborator} content='collaborator' />},
 { path: "/details/collaborator/:user_id", component: <RouteGuard component={Details} content='collaborator' />},
 { path: "/put/collaborator/:user_id", component: <RouteGuard component={PutCollaboarator} content='collaborator' />},
 { path: "/timesheet/:type/:date", component: <RouteGuard component={Timesheet} content='timesheet' />},
 { path: "/shift/:type/:date", component: <RouteGuard component={Schedule} content='schedule' />},
 { path: "/demo/:type", component: <RouteGuard component={Demo} content='manage' />},

 // SignEdit
 { path: "/api/prepare/:uuid", component: <RouteGuard component={CreateDoc} content="post" /> },
 { path: "/api/preview/:uuid", component: <RouteGuard component={PreviewModal} content="post" /> },
  // //profile
  { path: "/profile", component: <UserProfile /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: < Navigate to="/home" />,
  },
];

const publicRoutes = [
  { path: "/", component: <HomePage /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/contact-sales", component: <ContactSales /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/support", component: <Support /> },
  { path: "/help-center", component: <Helpdesk /> },
  { path: "/help-center/:menu", component: <Submenu /> },
  { path: "/help-center/:submenu/:childmenu", component: <ContentHelp /> },
  { path: "/blog", component: <Blogs /> },
  { path: "/blog/tag/:category", component: <BlogCat /> },
  { path: "/blog/:title", component: <BlogView /> },
  { path: "/time-tracking", component: <SoluTimeSheet /> },
  { path: "/digital-signature", component: <DSignature /> },
  { path: "/employee-scheduling", component: <SoluSchedule /> },
  { path: "/absence-management", component: <SoluAbsense /> },
  { path: "/hotel", component: <InHotel /> },
  { path: "/production", component: <Production /> },
  { path: "/recreation", component: <ReCreation /> },
  { path: "/restaurant", component: <Restaurant /> },
  { path: "/retail", component: <Retail /> },
  { path: "/transport", component: <Transport /> },
  { path: "/industries", component: <Industries /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register/:plane", component: <Register /> },
  { path: "/plan-and-price/:userType", component: <PagesPricing /> },
  { path: "/add/collaborator/mobile/:account_id", component: <AddCollaboratorMobile  />},
  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
  { path: "/api/clinet/:uuid", component: <Client /> },
];

export { authProtectedRoutes, publicRoutes };
