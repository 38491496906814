
import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate ,useParams,useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardTitle,
  FormGroup,
} from "reactstrap";
import AWS from 'aws-sdk';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import PropTypes from "prop-types";
//Import Breadcrumb
import classnames from "classnames";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import PlanningModal from './Planningmodal';
import TimeTrackingButton from './TimeTrackingButton';
import AbsenceModal from './AbsenceModal';
import AddSignleDateUren from './AddSignleDateUren';
import PutSignleDateUren from './PutSignleDateUren';
import DatShiftModal from './DateShift';
import PrintTimesheetModal from './PrintTimesheetModal';
import {
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  selectFolders,
  getUren,
  getShiftTeam,
  putDep,
  getShift,
  getAllAbsence,
  PostInspectionTimeSheet,
  GetSurcharger,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { format, parseISO, isBefore, isEqual } from 'date-fns';
import './style.css';
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';

const currencySymbols = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  TRY: "₺",
  default: "€"
  // Add other currencies as needed
};

function getWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
}


const roundToNearestHour = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return `${String(minutes >= 30 ? hours + 1 : hours).padStart(2, '0')}:00`;
};


// Helper function to get the start of the week (Sunday)
function getStartOfWeek(date) {
  const day = date.getDay();
  const diff = date.getDate() - day; // Adjust when day is not Sunday
  return new Date(date.setDate(diff));
}

// Helper function to get the end of the week (Saturday)
function getEndOfWeek(date) {
  const day = date.getDay();
  const diff = date.getDate() + (6 - day); // Adjust when day is not Saturday
  return new Date(date.setDate(diff));
}

function getStartOfWeekFromWeekNumber(year, week) {
  const startOfYear = new Date(year, 0, 1);
  const days = (week - 1) * 7;
  const startOfWeek = new Date(startOfYear.setDate(startOfYear.getDate() + days));
  return getStartOfWeek(startOfWeek);
}

function hexToRGBA(hexColor) {
  const hex = hexColor.slice(1); // Remove the leading #
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, 1)`;
}

function dimColor(color, opacity) {
  // Ensure the color is defined and in RGBA format
  if (!color) {
    console.warn("dimColor received an undefined color, using fallback.");
    return `rgba(0, 0, 0, ${opacity})`; // Fallback to black
  }

  // Handle hex color format
  if (color.startsWith("#")) {
    color = hexToRGBA(color);
  }

  // Parse the RGBA values
  const rgba = color.match(/[\d.]+/g);
  if (!rgba) {
    return color; // Invalid color format, return original
  }

  // Reduce the opacity
  const dimmedColor = `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${opacity})`;
  return dimmedColor;
}

function formatTime(timeString) {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}`;
}
function formatDateuren(dateString) {
  const date = new Date(dateString); // Convert string to Date object
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
function formatDate(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); 
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

const Schedule = (props) => {
  const { t } = props;
  const { type: paramType,date:urlDate } = useParams();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 // const monthNames = [t("Jan"), t("Feb"), t("Mar"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep"), t("Oct"), t("Nov"), t("Dec")];
const daysOfWeekNames = [t("Sun"), t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat")];
const reels = [ t("Start"), t("End"), t("Break"), t("Team"), t("Shift"), t("Actions")];
const dateSchedule = ["01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00"];
  const [type, setType] = useState(paramType || "month");
  const [dateurl, setDateUrl] = useState(urlDate || formatDate(new Date()));
  const {
    postDeps,
    shift,
    shiftdata,
    putshift,
    removeshift,
    urendata,
    posturen,
    teamdata,
    putDeps,
    puturen,
    removeuren,
    contractemployer,
    getalltabsence,
    inspection,
    getsurcharger,
    error
  } = useSelector(state => ({
    getalltabsence: state.getAbsenceData.getalltabsence,
    urendata: state.getShiftData.urendata,
    shift: state.getShiftData.shift,
    postDeps: state.getClientData.postDeps,
    putDeps: state.getClientData.putDeps,
    teamdata: state.getShiftData.teamdata,
    inspection: state.getShiftData.inspection,
    getsurcharger: state.getShiftData.getsurcharger,
    error: state.getShiftData.error,
  }));
  const departmentLimits = {
    starter: 1,
    professional: 6,
    enterprise: Infinity, // Assuming enterprise has no limit
  };
  
  const TeamLimits = {
    starter: 5,
    professional: Infinity,
    enterprise: Infinity, // Assuming enterprise has no limit
  };
  const dispatch = useDispatch();
 
  const { permissions } = useUserPermissions();

  const { editor, plan_name,template_content = [],teamleider } = permissions || {};
  const isEditorAllowed = editor === 1;
  const maxDepartments = departmentLimits[plan_name] || 1;
  const maxTeams = TeamLimits[plan_name] || 5;
 

  const location = useLocation();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [language, setLanguage] = useState(user.language);
  const [timezone, setTimezone] = useState(user.timezone);
  const [valuta, setValuta] = useState(user.valuta);
  const [DateTimeFormat, setDateFormat] = useState(user.datetimeformat);
 
  const [toast, setToast] = useState(false);
  const toggleToast = () => {
    setToast(!toast);
};
const [addDepModal, setAddDepModal] = useState(false);
const toggleAddDep = () => {
  setAddDepModal(!addDepModal);
};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShiftItem, setSelectedDateShiftItem] = useState({teamid: null, depid: null });
  const [isAbsenceModalOpen, setIsAbsenceModalOpen] = useState(false);
  const [isDateShiftModalOpen, setIsDateShiftModalOpen] = useState(false);
  const [isPrintShiftModalopen, setIsPrintShiftModalOpen] = useState(false);
  const [selectedTrashItem, setSelectedTrashItem] = useState({employers_id: null, id: null, date: null });
  const [selectedAbsenceItem, setSelectedAbsenceItem] = useState({ absence: null });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedAddItem, setSelectedAddItem] = useState({teamid:null,depid:null, date: null,dep_name:null,type:false});
  const [isPutModalOpen, setIsPutModalOpen] = useState(false);
  const [selectedPutItem, setSelectedPutItem] = useState({start:null,eind:null,item:null,teamid:null,depid:null,employers_id: null, id: null, date: null, shiftid: null,shiftname:null});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [currentDetails, setCurrentDetails] = useState({});
  const [isDepSliderVisible, setDepSliderVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({teamid: null, depid: null });
  const [isTeamAndShiftModalOpen, setIsTeamAndShiftModalOpen] = useState(false);
  const itemsPerPage = 12;
  const [currentPageDep, setCurrentDepPage] = useState(1);
  const [searchDep, setSearchDep] = useState(""); 
  const [checkedItems, setCheckedItems] = useState([]);
  const [isReloadData, setIsReloadData] = useState(false);
  const [isDisableDepPut, setIsDisableDepPut] = useState(false);
  const [isDropOpen, setIsDropOpen] = useState(false)
  const [modal_surcharger, setmodal_surcharger] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState({});
  const [departmentName, setDepartmentName] = useState("");
const [departmentAddress, setDepartmentAddress] = useState("");
  const toggleDropdown = (teamName) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [teamName]: !prevState[teamName], // Toggle the dropdown for the specific team
    }));
  };
  const [checkedApprove, setApproveChecked] = useState({
    date: [],
    employeer: [],
    status:'approve',
  });
  
  const [putDepartMend, setPutDepartment] = useState({
    id: null, 
    columnName: 'dep_name',
    value: null,
    tableName: 'departments',
    condition:'departments_id'
  });

 
 
  
  useEffect(() => {
    document.title = t("Time Sheet Management | Efficient Employee Time Tracking & Reporting - ShiftSpec");
  }, [t]);
 

  useEffect(() => {
   
    if (paramType && urlDate) {
      setType(paramType); // Update the state when the URL param changes
      setDateUrl(urlDate)
    }
  }, [paramType,urlDate]);
  const toggleDepSlider = () => {
    setDepSliderVisible(!isDepSliderVisible);
  };

  
 
  const handleCloseDateShiftModal = () => setIsDateShiftModalOpen(false);
  
 
  const handleClosePrintShiftModal = () => setIsPrintShiftModalOpen(false);
  const handlePrintShiftModal = () => {
    setIsPrintShiftModalOpen(true);
  };
  const handleOpenAbsenceModal = (absence) => {
    setSelectedAbsenceItem({absence});
    setIsAbsenceModalOpen(true);
 
  };
  const handleCloseAbnseceModal = () => setIsAbsenceModalOpen(false);
 
  
  const handleOpenAddModal = (teamid,depid, date,dep_name,type) => {
    setSelectedAddItem({ teamid,depid, date,dep_name,type });
    setIsAddModalOpen(true);
    
  };
  const handleCloseAddModal = () => setIsAddModalOpen(false);
  const handleOpenPutModal = (start,eind,item,teamid,depid,employers_id, id, date,shiftid,shiftname) => {
    setSelectedPutItem({start,eind,item,teamid,depid,employers_id, id, date,shiftid,shiftname});
    setIsPutModalOpen(true);
    
  };
  const handleClosePutModal = () => setIsPutModalOpen(false);


  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTabClick = (path,type) => {

    if (location.pathname !== path) {
      setLoading(true);
      navigate(path);
      setActiveTab(path);
      setType(type);
      setDateUrl(urlDate)
      setTimeout(() => {
        setLoading(false);
        
      }, 2000);
    }
  };

  const handelNextOrPrev = (value) => {
    let newStartDate, newEndDate;
  
    // Handle week navigation
    if (type === "week") {
      const currentWeekNumber = currentDetails.weekNumber;
      const currentYear = currentDetails.year;
  
      if (value === 'prev') {
        // Going to the previous week
        newStartDate = new Date(currentDetails.startDate);
        newStartDate.setDate(newStartDate.getDate() - 7);
        newEndDate = new Date(currentDetails.endDate);
        newEndDate.setDate(newEndDate.getDate() - 7);
      } else {
        // Going to the next week
        newStartDate = new Date(currentDetails.startDate);
        newStartDate.setDate(newStartDate.getDate() + 7);
        newEndDate = new Date(currentDetails.endDate);
        newEndDate.setDate(newEndDate.getDate() + 7);
      }
      setDateUrl(formatDate(newStartDate));
    // Handle month navigation
    } else if (type === "month") {
      const currentMonth = new Date(currentDetails.startDate).getMonth();
      const currentYear = new Date(currentDetails.startDate).getFullYear();
  
      if (value === 'prev') {
        // Going to the previous month
        newStartDate = new Date(currentYear, currentMonth - 1, 1);
        newEndDate = new Date(currentYear, currentMonth, 0); // Last day of previous month
      } else {
        // Going to the next month
        newStartDate = new Date(currentYear, currentMonth + 1, 1);
        newEndDate = new Date(currentYear, currentMonth + 2, 0); // Last day of next month
      }
  
      setDateUrl(formatDate(newStartDate));
    // Handle date navigation (daily)
    } else if (type === "date") {
      newStartDate = new Date(currentDetails.startDate);
  
      if (value === 'prev') {
        newStartDate.setDate(newStartDate.getDate() - 1); // Previous day
      } else {
        newStartDate.setDate(newStartDate.getDate() + 1); // Next day
      }
      setDateUrl(formatDate(newStartDate));
    }
  };

    // Function to change the URL when date changes
    const changeUrlDate = () => {
      const newPath = `/timesheet/${type}/${dateurl}`;
      navigate(newPath, { replace: true });
    };
  const handleDateChange = (event) => {
    const value = event.target.value;
    let selectedDate;
    if (type === "week" && value.includes('-W')) {
      const [year, week] = value.split('-W');
      selectedDate = getStartOfWeekFromWeekNumber(parseInt(year), parseInt(week));
  } else {
    selectedDate = new Date(value);
} 

if (!isNaN(selectedDate.getTime())) {
  setDateUrl(formatDate(selectedDate));
} else {
  console.error("Invalid Date provided:", value);
}

};


function getWorkStatusDetails(work_status, dienst_start_time, dienst_eind_time, totalhour, shift_color,shift_name) {
  
  const details = {
    checkin: {
      colors: shift_color || '#239933',
      textContent: formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
    },
    checkout: {
      colors: shift_color || '#992323',
      textContent: formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
    },
    wait: {
      colors: shift_color,
      textContent: formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
    },
    auto: {
      colors: shift_color || '#236899',
      textContent: formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
    },
    vk: {
      colors: shift_color,
      textContent: formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
      
    },
    zk: {
      colors: shift_color,
      textContent: formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
    },
    no: {
      colors: shift_color,
      textContent: formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
    },
    default: {
      colors: shift_color || '',
      totalWorkHour:formatTime(totalhour),
      totalWorkHour:formatTime(totalhour),
    },
  };

  // Return the corresponding details based on work_status, or default if not found
  return details[work_status] || details.default;
}

const formatTitle = (firstDay, lastDay, monthName, year) => {
   // title: `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`,
  if (DateTimeFormat === "MM/DD/YYYY") {
    return `${monthName} ${firstDay.getDate()} - ${lastDay.getDate()} ${year}`;
  } else if (DateTimeFormat === "DD/MM/YYYY") {
    return `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`;
  } else if (DateTimeFormat === "YYYY-MM-DD") {
    return `${year} ${monthName} ${firstDay.getDate()} - ${lastDay.getDate()}`;
  } else if (DateTimeFormat === "MM-DD-YYYY") {
    return `${monthName} ${firstDay.getDate()} - ${lastDay.getDate()} ${year}`;
  } else if (DateTimeFormat === "DD-MM-YYYY") {
    return `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`;
  } else {
    // Default format
    return `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`;
  }
};

  function displayCurrentTemp(type) {
    try {
      
      const selectedDate = new Date(dateurl)
      if (!(selectedDate instanceof Date) || isNaN(selectedDate.getTime())) {
        throw new Error("Invalid Date object provided");
      }

        if (type === "month") {
            const month = selectedDate.getMonth();
            const year = selectedDate.getFullYear();

            const firstDay = new Date(year, month, 1);
            const firstDayOfWeek = daysOfWeekNames[firstDay.getDay()];

            const lastDay = new Date(year, month + 1, 0);
            const lastDayOfWeek = daysOfWeekNames[lastDay.getDay()];

            const totalDays = lastDay.getDate();
            const monthName = monthNames[month];
            const startDate = formatDate(firstDay);
            const endDate = formatDate(lastDay);
             // Create a 'today' object
            const todayDate = new Date(); 
            const isTodayInCurrentMonth = todayDate.getMonth() === month && todayDate.getFullYear() === year;

            const today = isTodayInCurrentMonth
              ? {
                  day: todayDate.getDate(),
                  dayOfWeek: daysOfWeekNames[todayDate.getDay()],
                  date: formatDate(todayDate), // Formatted date as yyyy-mm-dd
                }
              : null;
            return {
                type: "month",
                firstDay: firstDay.getDate(),
                firstDayOfWeek: firstDayOfWeek,
                lastDay: lastDay.getDate(),
                lastDayOfWeek: lastDayOfWeek,
                monthName: monthName,
                totalDays: totalDays,
               // title: `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`,
                title: formatTitle(firstDay, lastDay, t(monthName), year),
                year: year,
                startDate: startDate, // Formatted start date (yyyy-mm-dd)
                endDate: endDate, // Formatted end date (yyyy-mm-dd)
                today: today
            };
        } else if (type === "week") {

        
            const currentWeekNumber = getWeekNumber(selectedDate);
            const startOfWeek = getStartOfWeek(selectedDate);
            const endOfWeek = getEndOfWeek(selectedDate);
            
            const totalDays = (endOfWeek - startOfWeek) / (1000 * 60 * 60 * 24) + 1;
            const startDate = formatDate(startOfWeek);
            const endDate = formatDate(endOfWeek);

            const todayDate = new Date();
            const isTodayInCurrentWeek = todayDate >= startOfWeek && todayDate <= endOfWeek;
            const today = isTodayInCurrentWeek
              ? {
                  day: todayDate.getDate(),
                  dayOfWeek: daysOfWeekNames[todayDate.getDay()],
                  date: formatDate(todayDate), // Formatted date as yyyy-mm-dd
                }
              : null;
            return {
              type: "week",
                weekNumber: currentWeekNumber,
                startOfWeek: startOfWeek.getDate(),
                startOfWeekMonthName: monthNames[startOfWeek.getMonth()],
                endOfWeek: endOfWeek.getDate(),
                endOfWeekMonthName: monthNames[endOfWeek.getMonth()],
                totalDays: totalDays,
                title: `Week ${currentWeekNumber}, ${selectedDate.getFullYear()}`,
                year: selectedDate.getFullYear(),
                startDate: startDate, // Formatted start date (yyyy-mm-dd)
                endDate: endDate, // Formatted end date (yyyy-mm-dd)
                today: today, 
            };
        } else if (type === "date") {
            const dayOfWeek = daysOfWeekNames[selectedDate.getDay()];
            const date = selectedDate.getDate();
            const monthName = monthNames[selectedDate.getMonth()];
            const year = selectedDate.getFullYear();
            const formattedDate = formatDate(selectedDate);
            return {
                type: "date",
                dayOfWeek: dayOfWeek,
                date: date,
                monthName: monthName,
                title: `${dayOfWeek}, ${date} ${monthName} ${year}`, 
                year: year,
                startDate: formattedDate,
                endDate: formattedDate
            };
        } else {
            console.warn("Invalid type provided to displayCurrentTemp");
            return null;
        }
    } catch (error) {
        console.error("Error in displayCurrentTemp:", error);
        return null;
    }
}



useEffect(() => {

  const fetchData = async () => {
    dispatch(getShiftTeam(account_id));
    dispatch(getAllAbsence(account_id));
    dispatch(getShift(account_id));
    dispatch(GetSurcharger(account_id));
    const data = displayCurrentTemp(type);
    setCurrentDetails(data);
  };
  fetchData();

  if (isReloadData) {
    fetchData();
    setIsReloadData(false);
  }
  changeUrlDate();
}, [dispatch, type ,isReloadData,dateurl]);



useEffect(() => {
  if (!currentDetails) {
    console.error('currentDetails is null or undefined');
    return;
  }

  const fetchDatas = async () => {
    
    const today = format(new Date(), 'yyyy-MM-dd')
    const obj = {
      start:currentDetails.startDate,
      eind:currentDetails.endDate <= today ? currentDetails.endDate : today,
      account_id: account_id,
    };

    setLoading(true); // Set loading to true before fetching
    await dispatch(getUren(obj));
    setIsReloadData(false);
    setLoading(false); // Set loading to false after fetching completes
  };

  fetchDatas();
}, [currentDetails, dispatch, isReloadData, account_id]);

const generateDates = (teamname) => {
  if (!currentDetails) {
    console.error('currentDetails is null or undefined 2');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }
  const dates = [];
 
  if (currentDetails.type === "month") {
   
   
    for (let i = 1; i <= currentDetails.totalDays; i++) {
      const date = new Date(currentDetails.year, monthNames.indexOf(currentDetails.monthName), i);
      const formattedDate = `${currentDetails.year}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
      dates.push({
        formattedDate,
        displayDate: `${daysOfWeekNames[date.getDay()]} ${i}`,
        datename: daysOfWeekNames[date.getDay()],
        teamnames: teamname 
      });
    }
  } else if (currentDetails.type === "week") {
    let currentDate = new Date(currentDetails.year, monthNames.indexOf(currentDetails.startOfWeekMonthName), currentDetails.startOfWeek);
    for (let i = 0; i < currentDetails.totalDays; i++) {
      const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
      dates.push({
        formattedDate,
        displayDate: `${daysOfWeekNames[currentDate.getDay()]} ${currentDate.getDate()}`,
        datename: daysOfWeekNames[currentDate.getDay()],
        teamnames: teamname 
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
  } else if (currentDetails.type === "date") {
  
    const formattedDate = `${currentDetails.year}-${String(monthNames.indexOf(currentDetails.monthName) + 1).padStart(2, '0')}-${String(currentDetails.date).padStart(2, '0')}`;
    const date = new Date(
      currentDetails.year,
      monthNames.indexOf(currentDetails.monthName), // Month is zero-indexed in Date
      currentDetails.date
    );
    
    // Get the day name
    const datename = daysOfWeekNames[date.getDay()];
    const timeSlots = reels.map((title,index) => ({
      formattedDate,
      datename,
      timeSlot: dateSchedule[index],
      title: title,
      teamnames: teamname
    }));
    dates.push(...timeSlots); // Flatten the array to include all time slots
  }

  return dates;
};
const renderDates = () => {
  if (!currentDetails) {
    console.error('currentDetails is null or undefined 3');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }
  if (currentDetails.type === "month") {
      const dates = [];
      for (let i = currentDetails.firstDay; i <= currentDetails.totalDays; i++) {
          dates.push(`${t(currentDetails.monthName)} ${i}`);
      }
      return dates.map((date,index) => (
          <div key={`month-${date}-${index}`} className="css-s3o0sad3-sheet css-s3o0fff3">
              {date}
          </div>
      ));
  } else if (currentDetails.type === "week") {
      // For week, you might need to adjust this depending on your requirements
      const dates = [];
      let currentDate = new Date(currentDetails.year, monthNames.indexOf(currentDetails.startOfWeekMonthName), currentDetails.startOfWeek);
  
      for (let i = 0; i < currentDetails.totalDays; i++) {         
        const dayName = monthNames[currentDate.getMonth()];
       
        dates.push(`${t(dayName)}  ${currentDate.getDate()}`);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      
      return dates.map((date,index) => (
          <div key={`week-${date}-${index}`} className="css-s3o0sad3-sheet css-s3o0fff3">
              {date}
          </div>
      ));
  } else if (currentDetails.type === "date") {

    return dateSchedule.map(time => (
      <div key={`date-${time}`} className="css-s3o0sad3-sheet css-s3o0fff3">
      </div>
  ));
    
  }
};

const handleApproveCheckboxChange = (event) => {
  const { name, checked, id, dataset } = event.target;

  setApproveChecked((prevChecked) => {
    // Determine whether we are handling a date or employeer checkbox
    if (name === "date") {
      const { fullDate } = dataset;
      const updatedDates = checked
        ? [...prevChecked.date, fullDate]
        : prevChecked.date.filter((date) => date !== fullDate);

      return {
        ...prevChecked,
        date: updatedDates,
      };
    } else if (name === "employeer") {
      const employerId = dataset.emplooyerId;
      const updatedEmployers = checked
        ? [...prevChecked.employeer, employerId]
        : prevChecked.employeer.filter((id) => id !== employerId);

      return {
        ...prevChecked,
        employeer: updatedEmployers,
      };
    }
    return prevChecked;
  });
};



const renderWithDateName = () => {
  if (!currentDetails) {
    console.error('currentDetails is null or undefined 4');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }
  if (currentDetails.type === "month") {
      const dates = [];
      const { firstDay, totalDays, monthName, year,today } = currentDetails;
      
      for (let i = firstDay; i <= totalDays; i++) {
        const date = new Date(year, monthNames.indexOf(monthName), i);
        const dayName = daysOfWeekNames[date.getDay()];
        const formattedDate = `${year}-${String(monthNames.indexOf(monthName) + 1).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
        const dayLabel = today && today.day === i ? t("Today") : `${dayName} ${i}`;
        dates.push({ dayLabel, fullDate: formattedDate });
      }
 
      return dates.map(({ dayLabel, fullDate }, index) => (
      <>
        <div
        key={`date-${fullDate}-${index}`}
         
          className="css-s3o0sad3-sheet css-s3o0fff3"
          style={dayLabel === t("Today") ? { background: "#0096ec", padding: "10px", color: "white" } : {}}
        >
          <Row style={{ position: 'relative', zIndex: 0 }}>
            <Col sm={12}>
            <div >
            <input
              type="checkbox"
              name="date"
              id={`checkbox-${fullDate}`}
              data-full-date={fullDate}
              onChange={handleApproveCheckboxChange}
            />
  </div>
            </Col>
            <Col sm={12}>
            <Link style={{color:'#4c4f56'}} to={`/timesheet/date/${fullDate}`}>{dayLabel}</Link>
            </Col>
          </Row>
        </div>
        
      </>
      ));
      
  } else if (currentDetails.type === "week") {
    const dates = [];
    const { firstDay, totalDays, monthName, year,today } = currentDetails;
    let currentDate = new Date(currentDetails.year, monthNames.indexOf(currentDetails.startOfWeekMonthName), currentDetails.startOfWeek);

    for (let i = 0; i < totalDays; i++) {
      const dayName = daysOfWeekNames[currentDate.getDay()];
      
      const dayLabel = today && today.dayOfWeek === dayName ? t("Today") : `${dayName} ${currentDate.getDate()}`;
      dates.push(dayLabel);
      // dates.push(`${dayName}  ${currentDate.getDate()}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates.map(date => (
      <div
      key={`date-${date}`}
        className="css-s3o0sad3-sheet css-s3o0fff3"
        style={date === t("Today") ? { background: "#0096ec", padding: "10px", color: "white" } : {}}
      >
        {date}
      </div>
    ));
  } else if (currentDetails.type === "date") {
    
    return reels.map((title, index) => {
      let width;

                  // Set specific widths based on index values
                  if (index === 5) {
                    width = '130px';
                  } else if (index === 4 || index === 3) {
                    width = '100px';
                  } else if (index === 2) {
                    width = '80px';
                  }else {
                    width = '120px';
                  }
    
      return (
        <div
        key={`title-${title}`}
          className="css-s3o0sad3-sheet css-s3o0fff3"
          style={{ width }}
        >
          {title}
        </div>
      );
    });
    
    
  }
};


const ScheduleItem = ({ title, value, backgroundColor, borderColor }) => (
  <div
  className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts"
  style={{ backgroundColor, borderColor }}
>
  <div className="sh-tl__static w-full" style={{justifyContent:'center'}}>
  <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
        {title}
        </span>
  </div>
</div>
);




const calculateTeamTotalHoursAndSalary = (urendata, dates, teamname) => {
  const dailyTotals = {};
  const dailySalaries = {};
  const tooltip = {};
  const tooltipsurcharge = {};
  let totalMinutes = 0;
  let totalSalary = 0;
  let totalPauseMinutes = 0;

  Object.values(urendata).forEach((employer) => {
    const { items, teamNames } = employer;

    if (teamNames !== teamname) return;

    dates.forEach(({ formattedDate }) => {
      let dailyMinutes = 0;
      let dailySalary = 0;
      let dailyPauseMinutes = 0; // Reset per date
      let dailySurchargeCost = 0; // Reset per date
    
      const itemsForDate = items.filter((item) => formatDateuren(item.uren_date) === formattedDate);
    
      itemsForDate.forEach(({ total_day_hour, uurloon, bonus, pause_uren, start_time_uren, eind_time_uren, uren_date }) => {
        if (total_day_hour !== '00:00')  {
          const [hours, minutes] = total_day_hour.split(":").map(Number);
    
          if (!isNaN(hours) && !isNaN(minutes)) {
            const itemMinutes = hours * 60 + minutes;
            const adjustedMinutes = itemMinutes - (parseInt(pause_uren, 10) || 0);
    
            dailyPauseMinutes += parseInt(pause_uren, 10) || 0; // Aggregate pause for the date
            dailyMinutes += Math.max(0, adjustedMinutes);
    
            if (!isNaN(uurloon) && uurloon > 0) {
              const baseRatePerMinute = uurloon / 60;
              const baseSalary = adjustedMinutes * baseRatePerMinute;
              const date = new Date(uren_date);
              const datename = daysOfWeekNames[date.getDay()];
    
              // Find matching surcharges
              const matchingSurcharges = getsurcharger.filter(
                (surcharge) =>
                  surcharge.token_sur === bonus && surcharge.day_sur === datename
              );
    
              let totalItemSalary = baseSalary;
    
              if (matchingSurcharges.length > 0) {
                matchingSurcharges.forEach((surcharge) => {
                  const surchargeStart = convertToMinutes(surcharge.start_time_sur);
                  const surchargeEnd = convertToMinutes(surcharge.end_time_sur);
                  const startMinutes = convertToMinutes(start_time_uren);
                  const endMinutes = convertToMinutes(eind_time_uren);
    
                  // Calculate overlap
                  const overlapStart = Math.max(startMinutes, surchargeStart);
                  const overlapEnd = Math.min(endMinutes, surchargeEnd);
    
                  if (overlapStart < overlapEnd) {
                    const overlapDuration = overlapEnd - overlapStart; // Duration in minutes
                    const multiplier = parseFloat(surcharge.paid_surcharge_id) / 100; // Surcharge multiplier
                    const surchargeCost = overlapDuration * baseRatePerMinute * multiplier;
    
                    totalItemSalary += surchargeCost;
                     // Add to daily surcharge cost
                  }
                });
              } else {
                // No surcharge: use base salary
                totalItemSalary = adjustedMinutes * baseRatePerMinute;
              }

              dailySurchargeCost += totalItemSalary - baseSalary;
              dailySalary += totalItemSalary;
            }
          }
        }
      });
    
      dailyTotals[formattedDate] = (dailyTotals[formattedDate] || 0) + dailyMinutes;
      dailySalaries[formattedDate] = (dailySalaries[formattedDate] || 0) + dailySalary;
      tooltip[formattedDate] = (tooltip[formattedDate] || 0) + dailyPauseMinutes;
      tooltipsurcharge[formattedDate] = (tooltipsurcharge[formattedDate] || 0) + dailySurchargeCost;
      
      totalMinutes += dailyMinutes;
      totalSalary += dailySalary;
    });
    
  });
  const formattedTooltip = Object.fromEntries(
    Object.entries(tooltip).map(([date, minutes]) => {
      const totalMinutes = parseInt(minutes, 10);
      const hours = Math.floor(totalMinutes / 60);
      const mins = totalMinutes % 60;
      return [date, `${String(hours).padStart(2, "0")}h ${String(mins).padStart(2, "0")}m`];
    })
  );

  const formattedTooltipSurcharge = Object.fromEntries(
    Object.entries(tooltipsurcharge).map(([date, surchargeCost]) => {
      // Ensure surchargeCost is a valid number
      const validSurchargeCost = parseFloat(surchargeCost) || 0;
      return [
        date,
        `${currencySymbols[valuta] || '€'}${validSurchargeCost.toFixed(2)}`,
      ];
    })
  );
  
  
  const formattedDailyTotals = Object.fromEntries(
    Object.entries(dailyTotals).map(([date, minutes]) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return [date, `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`];
    })
  );

  const formattedDailySalaries = Object.fromEntries(
    Object.entries(dailySalaries).map(([date, salary]) => [
      date,
      `${currencySymbols[valuta]}${!isNaN(salary) ? salary.toFixed(2) : "0.00"}`,
    ])
  );

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;
  const formattedTotalHours = `${String(totalHours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`;
  const formattedTotalSalary = `${currencySymbols[valuta]}${!isNaN(totalSalary) ? totalSalary.toFixed(2) : "0.00"}`;

  return {
    formattedTooltip,
    formattedDailyTotals,
    formattedDailySalaries,
    formattedTooltipSurcharge,
    formattedTotalHours: formattedTotalHours || "00:00",
    formattedTotalSalary: formattedTotalSalary || `${currencySymbols[valuta]}0.00`,
  };
};


const calculateTotalWorkerWorkSalary = (items, getsurcharger) => {
  const totalSalaryByDay = {};
  let totalMinutes = 0;
  let totalSalary = 0;
  let adjustedWorkingTimeByDay = {};

  items.forEach((item) => {
    const { bonus, start_time_uren, eind_time_uren, uren_date, total_day_hour, uurloon, pause_uren } = item;

    // Parse total_day_hour into minutes
    if (total_day_hour !== '00:00') {
      const [hours, minutes] = total_day_hour.split(":").map(Number);
      const itemMinutes = (isNaN(hours) ? 0 : hours) * 60 + (isNaN(minutes) ? 0 : minutes);

      // Adjust minutes for pause time
      const adjustedMinutes = Math.max(0, itemMinutes - (parseInt(pause_uren, 10) || 0));
      totalMinutes += adjustedMinutes; // Accumulate adjusted minutes

      const date = new Date(uren_date);
      const datename = daysOfWeekNames[date.getDay()];

      // Initialize adjusted working time for this day
      if (!adjustedWorkingTimeByDay[datename]) {
        adjustedWorkingTimeByDay[datename] = 0;
      }
      adjustedWorkingTimeByDay[datename] += adjustedMinutes;

      // Find matching surcharges
      const matchingSurcharges = getsurcharger.filter(
        (surcharge) =>
          surcharge.token_sur === bonus && surcharge.day_sur === datename
      );

      if (uurloon) {
        const baseRatePerMinute = uurloon / 60; // Calculate rate per minute

        if (matchingSurcharges.length > 0) {
          matchingSurcharges.forEach((surcharge) => {
            const surchargeStart = convertToMinutes(surcharge.start_time_sur);
            const surchargeEnd = convertToMinutes(surcharge.end_time_sur);

            const startMinutes = convertToMinutes(start_time_uren);
            const endMinutes = convertToMinutes(eind_time_uren);

            // Adjust for full-day cases
            const effectiveEnd = surchargeEnd === 1440 ? endMinutes : Math.min(endMinutes, surchargeEnd);

            // Calculate overlap between the item's hours and the surcharge hours
            const overlapStart = Math.max(startMinutes, surchargeStart);
            const overlapEnd = Math.min(effectiveEnd, surchargeEnd);

            if (overlapStart < overlapEnd) {
              const overlapDuration = overlapEnd - overlapStart; // Duration in minutes
              const multiplier = parseFloat(surcharge.paid_surcharge_id) / 100; // Convert to multiplier
              const surchargeSalary = overlapDuration * baseRatePerMinute * multiplier;

              // Aggregate salary for this day
              if (!totalSalaryByDay[datename]) {
                totalSalaryByDay[datename] = 0;
              }
              totalSalaryByDay[datename] += surchargeSalary;
              totalSalary += surchargeSalary; // Add to total salary
            }
          });
        } else {
          // No surcharges: use base salary
          const baseSalary = adjustedMinutes * baseRatePerMinute;
          if (!totalSalaryByDay[datename]) {
            totalSalaryByDay[datename] = 0;
          }
          totalSalaryByDay[datename] += baseSalary;
          totalSalary += baseSalary;
        }
      }
    }
  });

  // Format adjusted working time
  const formattedAdjustedWorkingTime = `${Math.floor(totalMinutes / 60)
    .toString()
    .padStart(2, "0")}h ${(totalMinutes % 60).toString().padStart(2, "0")}m`;

  // Format total salary
  const currencySymbol = items.length > 0 ? (currencySymbols[items[0].valuta] || currencySymbols.default) : currencySymbols.default;
  const formattedTotalSalary = `${currencySymbol}${totalSalary.toFixed(2)}`;

  return {
    totalSalaryByDay,
    totalWithSurchargeSalary: formattedTotalSalary, // Format to 2 decimals
    adjustedWorkingTime: formattedAdjustedWorkingTime,
  };
};


// Helper to convert time strings (HH:MM) to total minutes
const convertToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};




const renderSchedule = (teamname,team) => {
  const dates = generateDates(teamname);

  if (!currentDetails) {
    console.error('currentDetails is null or undefined 5');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }
  
  if (currentDetails.type === "month") {
    const hasMatchingEmployees = Object.values(urendata).some(
      (data) => data.teamNames === teamname
    );
    const { formattedDailyTotals, formattedDailySalaries, formattedTotalHours, formattedTotalSalary,  formattedTooltip, formattedTooltipSurcharge,
    } = calculateTeamTotalHoursAndSalary(urendata, dates, teamname);
   
  // team total 
    return (
      <>
        {hasMatchingEmployees && Object.keys(urendata).map((employerId) => {
          
          const { items, teamNames, employeeName, profileImage ,totalWorkerWorkHour, totalWorkerWorkSalary } = urendata[employerId];
          const isTeamMatch = teamname === teamNames;
          const totalWithSurchargeSalary = calculateTotalWorkerWorkSalary(items, getsurcharger);
          
          return (

            
            <div
            key={`employer-${employerId}`}
              className="css-k23serv3s"
              style={{ display: isTeamMatch ? "inline-flex" : "none" }}
            >
              {isTeamMatch && (
                <>
                
                  <div className="css-li23s54-sheet border-style">
                  <input
              type="checkbox"
              name="employeer"
              id={`checkbox-${employerId}`}
              data-emplooyer-id={employerId}
              onChange={handleApproveCheckboxChange}
              style={{margin: '0 8px'}}
            />
                    <img
                      style={{ marginRight: '5px'}}
                      className="rounded-circle header-profile-user_plane"
                      src={profileImage || 'default_profile_image_url.jpg'}
                      alt={`${employeeName}_profile_shiftOnline`}
                    />
                    {employeeName}
                  </div>
  
                  {/* Render dates for team-matching entries */}
                  {dates.map(({ formattedDate }) => {
                   
                    const itemsForDate = items.filter(
                      (item) => item.team_name === teamname && formatDateuren(item.uren_date) === formattedDate
                    );
  
                    const absenceDetails = getalltabsence.find(absence => {
                      const absenceStartDate = formatDateuren(absence.startdate_absence); 
                      const absenceEndDate = formatDateuren(absence.enddate_absence);
                      const isSameWorker = String(absence.workerid) === String(employerId);
                      const isSameOrAfterStart = formattedDate >= absenceStartDate;
                      const isSameOrBeforeEnd = formattedDate <= absenceEndDate;
  
                      return isSameWorker && isSameOrAfterStart && isSameOrBeforeEnd;
                    });
  
                    const isAbsent = !!absenceDetails;
                    const absenceStartDate = isAbsent ? absenceDetails.startdate_absence : null;
                    const absenceEndDate = isAbsent ? absenceDetails.enddate_absence : null;
                    const absenceStatus = isAbsent ? absenceDetails.absencestatus : null;
                    const count_as = isAbsent ? absenceDetails.count_as : null;
                    const substatus = isAbsent ? absenceDetails.substatus : null;
                    
                    return itemsForDate.length === 0 ? (
                      <div key={`no-items-${formattedDate}`} className="css-s3o0sad3-sheet border-style" style={{ display: 'block' }}>
                        <div >
                          {isAbsent && (
                            <div className="css-vk-absen">
                              <div className="schedule-template" id="schedule-template" >
                                <div className="tooltip-text-absence">
                                  <div className="tooltip-header">
                                    <span className="count-as">
                                      {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
                                    </span>
                                    <span className="absence-status">{absenceStatus}</span>
                                  </div>
                                  <div className="absence-dates">
                                  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
                                  </div>
                                </div>
                                <div className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts" style={{ borderColor:'1px solid #333333', width:'46px' }}>
                                  <button className="css-1m92us_absence_sheet"
                                   style={{ backgroundColor: absenceStatus === 'approved' ? '#e4d03b' : '#f1f1f1' }} 
                                   type="button"
                                   disabled={!isEditorAllowed}
                                    onClick={() => handleOpenAbsenceModal(absenceDetails)}
                                   >
                                    <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                                      {'00:00'}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                         <button  className="css-1m92us  add-plus schedule-button" type="button" style={{ width: '100%' }} onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate, team.dep_name, false)}>
                              <span className="css-230ofxs" role="img" aria-hidden="true">
                                <i className="bx bx-plus"></i>
                              </span>
                            </button>
                        </div>
                      </div>
                    ) : (
                      <div key={`items-${formattedDate}`} className="css-s3o0sad3-sheet border-style" style={{ display: 'block' }}>
                        {itemsForDate.map((item) => {
                          const { colors, textContent, totalWorkHour } = getWorkStatusDetails(item.work_status, item.start_time_uren, item.eind_time_uren, item.total_day_hour, item.shift_color, item.shift_name);
                          const dimmedColor = dimColor(colors, 0.1);
                          
                          return (
                            <div key={item.uren_id} >
                               {isAbsent && (
                            <div className="css-vk-absen">
                              <div className="schedule-template" id="schedule-template" >
                                <div className="tooltip-text-absence">
                                  <div className="tooltip-header">
                                    <span className="count-as">
                                    {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
                                    </span>
                                    <span className="absence-status">{absenceStatus}</span>
                                  </div>
                                  <div className="absence-dates">
                                  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
                                  </div>
                                </div>
                                <div className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts" style={{ borderColor:'1px solid #333333', width:'46px' }}>
                                  <button className="css-1m92us_absence_sheet" 
                                  style={{ backgroundColor: absenceStatus === 'approved' ? '#e4d03b' : '#f1f1f1' }}
                                   type="button" 
                                   disabled={!isEditorAllowed}
                                   onClick={() => handleOpenAbsenceModal(absenceDetails)}>
                                    <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                                      {'00:00'}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                              <div className="parent-container">
                                <div className="schedule-template" id="schedule-template" >
                                <div className="new-btn_group me-2 edit-vds3g">
                                    <div className="tooltip-text">
                                    <div className="tooltip-header">
                                    <span className="count-as">
                                    {`${item.shift_name} ${item.start_time_uren} - ${item.eind_time_uren}`}
                                    </span>
                                    </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      style={{width:'100%'}}
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenPutModal(
                                          currentDetails.startDate,
                                          currentDetails.endDate,
                                          item,
                                          item.team_id_uren,
                                          item.departmen_id_uren,
                                          item.employers_id,
                                          item.uren_id,
                                          formatDateuren(item.uren_date),
                                          item.shift_id_uren,
                                          item.shift_name
                                        )
                                      }
                                    >
                                      <i className="bx bx-pencil"></i>
                                    </button>
                                    </div>
                                  <ScheduleItem title={textContent} value={totalWorkHour} backgroundColor={dimmedColor} borderColor={colors} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                    
                  })}
  
                  {/* Total Column below the schedule */}
                  <div className="fixed-total-colum border-style">
                    <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
                      <div className="d-flex grid-full-height">
                        <div className="sh-totals-wrapper">
                          <div className="sh-totals">
                          <div className="sh-totals__time">{totalWithSurchargeSalary.adjustedWorkingTime}</div>
                          <div className="sh-totals__salary">{totalWithSurchargeSalary.totalWithSurchargeSalary}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
                </>
              )}
 
            </div>
     
          );
       
        })}
  
        {/* Placeholder if no matching employees */}
        {!hasMatchingEmployees && (
          <div className="css-k23serv3s">
            <div className="css-li23s54-sheet border-style" >
              <span>{t('Schedule')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
              <div className="css-s3o0sad3-sheet border-style targethover" key={`Rooster-${formattedDate}`}>
                <div className="add-40os34">
                  <button  className="css-1m92us add-plus schedule-button" type="button" style={{ width: '100%' }} onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate, team.dep_name, false)}>
                    <span className="css-230ofxs" role="img" aria-hidden="true">
                      <i className="bx bx-plus"></i>
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Horizontal Total Row Outside the Mapping Function */}
        <div className="css-k23serv3s">
            <div className="css-li23s54-sheet border-style" >
              <span>{t('Team Totals')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
  <div
    className="css-s3o0sad3-sheet border-style tooltip-container"
    key={`Totals-${formattedDate}`}
  >
    <span>
      {formattedDailyTotals[formattedDate] || "00:00"}
    </span>
    <div className="tooltip-text-total">
      <div className="tooltip-header-total">
        {/* Tooltip item for total pause */}
        <div className="tooltip-item">
          <span className="tooltip-label">{t('Total Break:')}</span>
          <span className="tooltip-value">
            {formattedTooltip[formattedDate]|| "N/A"}
          </span>
        </div>
        {/* Tooltip item for total surcharge cost */}
        <div className="tooltip-item">
          <span className="tooltip-label">{t('Total Surcharge Cost:')}</span>
          <span className="tooltip-value">
          {formattedTooltipSurcharge[formattedDate]|| "N/A"}
          </span>
        </div>
      </div>
    </div>
  </div>
))}


<div className="fixed-total-colum border-style ">
                    <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
                      <div className="d-flex grid-full-height">
                        <div className="sh-totals-wrapper">
                          <div className="sh-totals">
                            <div className="sh-totals__time">{formattedTotalHours}</div>
                            <div className="sh-totals__salary">{formattedTotalSalary}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>


          <div className="css-k23serv3s" style={{background:'#eaf0e5'}}>
            <div className="css-li23s54-sheet border-style" >
              <span> {t('Totals')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
              <>

<div style={{display:'block'}} className="css-s3o0sad3-sheet border-style tooltip-container"key={`Totals2-${formattedDate}`}>
        <span className="schedule-template"  >
        {formattedDailyTotals[formattedDate] || "00:00"}
        </span>
        <span className="schedule-template" style={{fontSize:'11px',fontWeight:'bold'}} >
        {formattedDailySalaries[formattedDate] || "€0.00"} 
        </span>
        <div className="tooltip-text-total">
    <div className="tooltip-header-total">
    <div className="tooltip-item">
      <span className="tooltip-label">{t('Total Break:')}</span>
      <span className="tooltip-value"> {formattedTooltip[formattedDate]|| "N/A"}</span>
    </div>
    <div className="tooltip-item">
      <span className="tooltip-label">{t('Total Surcharge Cost:')}</span>
      <span className="tooltip-value"> {formattedTooltipSurcharge[formattedDate]|| "N/A"}</span>
    </div>
    </div>
  </div>
</div>
         
            
              </>
             
              
            ))}

<div className="fixed-total-colum border-style" style={{background:'#eaf0e5'}}>
                    <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
                      <div className="d-flex grid-full-height">
                        <div className="sh-totals-wrapper">
                          <div className="sh-totals">
                          <div className="sh-totals__time">{formattedTotalHours}</div>
                          <div className="sh-totals__salary">{formattedTotalSalary}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
      </>
    );
  }
  else if (currentDetails.type === "week") {
    const hasMatchingEmployees = Object.values(urendata).some(
      (data) => data.teamNames === teamname
    );
    const { formattedDailyTotals, formattedDailySalaries, formattedTotalHours, formattedTotalSalary,  formattedTooltip, formattedTooltipSurcharge,
    } = calculateTeamTotalHoursAndSalary(urendata, dates, teamname);
    
  // team total 
    return (
      <>
        {hasMatchingEmployees && Object.keys(urendata).map((employerId) => {
          const { items, teamNames, employeeName, profileImage,totalWorkerWorkHour,totalWorkerWorkSalary } = urendata[employerId];
          const isTeamMatch = teamname === teamNames;
          const totalWithSurchargeSalary = calculateTotalWorkerWorkSalary(items, getsurcharger);
          return (

            
            <div
              key={`week-${employerId}`}
              className="css-k23serv3s week-sv232 grid-full-width"
              style={{ display: isTeamMatch ? "inline-flex" : "none" }}
            >
              {isTeamMatch && (
                <>
                
                  <div className="css-li23s54-sheet border-style" style={{ width: '28%' }}>
                    <img
                      style={{ marginRight: '5px'}}
                      className="rounded-circle header-profile-user_plane"
                      src={profileImage || 'default_profile_image_url.jpg'}
                      alt={`${employeeName}_profile_shiftOnline`}
                    />
                    {employeeName}
                  </div>
  
                  {/* Render dates for team-matching entries */}
                  {dates.map(({ formattedDate }) => {
                    const itemsForDate = items.filter(
                      (item) => item.team_name === teamname && formatDateuren(item.uren_date) === formattedDate
                    );
  
                    const absenceDetails = getalltabsence.find(absence => {
                      const absenceStartDate = formatDateuren(absence.startdate_absence); 
                      const absenceEndDate = formatDateuren(absence.enddate_absence);
                      const isSameWorker = String(absence.workerid) === String(employerId);
                      const isSameOrAfterStart = formattedDate >= absenceStartDate;
                      const isSameOrBeforeEnd = formattedDate <= absenceEndDate;
  
                      return isSameWorker && isSameOrAfterStart && isSameOrBeforeEnd;
                    });
  
                    const isAbsent = !!absenceDetails;
                    const absenceStartDate = isAbsent ? absenceDetails.startdate_absence : null;
                    const absenceEndDate = isAbsent ? absenceDetails.enddate_absence : null;
                    const absenceStatus = isAbsent ? absenceDetails.absencestatus : null;
                    const count_as = isAbsent ? absenceDetails.count_as : null;
                    const substatus = isAbsent ? absenceDetails.substatus : null;
  
                    return itemsForDate.length === 0 ? (
                      <div key={`itemsForDate-${formattedDate}`} className="css-s3o0sad3-sheet border-style" style={{ display: 'block' }}>
                        <div >
                          {isAbsent && (
                            <div className="css-vk-absen">
                              <div className="schedule-template" id="schedule-template" >
                                <div className="tooltip-text-absence">
                                  <div className="tooltip-header">
                                    <span className="count-as">
                                    {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
                                    </span>
                                    <span className="absence-status">{absenceStatus}</span>
                                  </div>
                                  <div className="absence-dates">
                                  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
                                  </div>
                                </div>
                                <div className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts" style={{ borderColor:'1px solid #333333', width:'46px' }}>
                                  <button className="css-1m92us_absence_sheet" style={{ backgroundColor: absenceStatus === 'approved' ? '#e4d03b' : '#f1f1f1' }}
                                   type="button"
                                   disabled={!isEditorAllowed}
                                    onClick={() => handleOpenAbsenceModal(absenceDetails)}>
                                    <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                                      {'00:00'}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                         <button  className="css-1m92us  add-plus schedule-button" type="button" style={{ width: '100%' }} onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate, team.dep_name, false)}>
                              <span className="css-230ofxs" role="img" aria-hidden="true">
                                <i className="bx bx-plus"></i>
                              </span>
                            </button>
                        </div>
                      </div>
                    ) : (
                      <div key={`s3o0sad3-${formattedDate}`} className="css-s3o0sad3-sheet border-style" style={{ display: 'block' }}>
                        {itemsForDate.map((item) => {
                          const { colors, textContent, totalWorkHour } = getWorkStatusDetails(item.work_status, item.start_time_uren, item.eind_time_uren, item.total_day_hour, item.shift_color, item.shift_name);
                          const dimmedColor = dimColor(colors, 0.1);
                          
                          return (
                            <div key={`uren_id-${item.uren_id}`} >
                               {isAbsent && (
                            <div className="css-vk-absen">
                              <div className="schedule-template" id="schedule-template" >
                                <div className="tooltip-text-absence">
                                  <div className="tooltip-header">
                                    <span className="count-as">
                                    {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
                                    </span>
                                    <span className="absence-status">{absenceStatus}</span>
                                  </div>
                                  <div className="absence-dates">
                                  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
                                  </div>
                                </div>
                                <div className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts" style={{ borderColor:'1px solid #333333', width:'46px' }}>
                                  <button className="css-1m92us_absence_sheet" style={{ backgroundColor: absenceStatus === 'approved' ? '#e4d03b' : '#f1f1f1' }}
                                   type="button"
                                   disabled={!isEditorAllowed}
                                    onClick={() => handleOpenAbsenceModal(absenceDetails)}>
                                    <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                                      {'00:00'}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                              <div className="parent-container">
                                <div className="schedule-template" id="schedule-template" >
                                <div className="new-btn_group me-2 edit-vds3g">
                                    <div className="tooltip-text">
                                    <div className="tooltip-header">
                                    <span className="count-as">
                                    {`${item.shift_name} ${item.start_time_uren} - ${item.eind_time_uren}`}
                                    </span>
                                    </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      style={{width:'100%'}}
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenPutModal(
                                          currentDetails.startDate,
                                          currentDetails.endDate,
                                          item,
                                          item.team_id_uren,
                                          item.departmen_id_uren,
                                          item.employers_id,
                                          item.uren_id,
                                          formatDateuren(item.uren_date),
                                          item.shift_id_uren,
                                          item.shift_name
                                        )
                                      }
                                    >
                                      <i className="bx bx-pencil"></i>
                                    </button>
                                    </div>
                                  <ScheduleItem title={textContent} value={totalWorkHour} backgroundColor={dimmedColor} borderColor={colors} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                    
                  })}
  
                  {/* Total Column below the schedule */}
                  <div className="fixed-total-colum border-style">
                    <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
                      <div className="d-flex grid-full-height">
                        <div className="sh-totals-wrapper">
                          <div className="sh-totals">
                          <div className="sh-totals__time">{totalWithSurchargeSalary.adjustedWorkingTime}</div>
                          <div className="sh-totals__salary">{totalWithSurchargeSalary.totalWithSurchargeSalary}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
                </>
              )}
 
            </div>
     
          );
       
        })}
  
        {/* Placeholder if no matching employees */}
        {!hasMatchingEmployees && (
          <div className="css-k23serv3s week-sv232 grid-full-width">
            <div className="css-li23s54-sheet border-style" style={{ width: '28%' }}>
              <span>{t('Schedule')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
              <div className="css-s3o0sad3-sheet border-style targethover" key={`formattedDate-${formattedDate}`}>
                <div className="add-40os34">
                  <button  disabled={!isEditorAllowed} className="css-1m92us add-plus schedule-button" type="button" style={{ width: '100%' }} onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate, team.dep_name, false)}>
                    <span className="css-230ofxs" role="img" aria-hidden="true">
                      <i className="bx bx-plus"></i>
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Horizontal Total Row Outside the Mapping Function */}
        <div className="css-k23serv3s week-sv232 grid-full-width">
        <div className="css-li23s54-sheet border-style" style={{ width: '28%' }}>
              <span> {t('Team Totals')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
             
              <>
               <div className="css-s3o0sad3-sheet border-style tooltip-container" key={`targethover-${formattedDate}`}>
                   <span   >
                   {formattedDailyTotals[formattedDate] || "00:00"}
                    </span>
                    <div className="tooltip-text-total">
      <div className="tooltip-header-total">
        {/* Tooltip item for total pause */}
        <div className="tooltip-item">
          <span className="tooltip-label">{t('Total Break:')}</span>
          <span className="tooltip-value">
            {formattedTooltip[formattedDate]|| "N/A"}
          </span>
        </div>
        {/* Tooltip item for total surcharge cost */}
        <div className="tooltip-item">
          <span className="tooltip-label">{t('Total Surcharge Cost:')}</span>
          <span className="tooltip-value">
          {formattedTooltipSurcharge[formattedDate]|| "N/A"}
          </span>
        </div>
      </div>
    </div>
  </div>
            
              </>
             
              
            ))}

<div className="fixed-total-colum border-style">
                    <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
                      <div className="d-flex grid-full-height">
                        <div className="sh-totals-wrapper">
                          <div className="sh-totals">
                            <div className="sh-totals__time">{formattedTotalHours}</div>
                            <div className="sh-totals__salary">{formattedTotalSalary}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>


          <div className="css-k23serv3s week-sv232 grid-full-width" style={{background:'#eaf0e5'}}>
            <div className="css-li23s54-sheet border-style" style={{ width: '28%' }}>
              <span> {t('Totals')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
              <>

<div style={{display:'block'}} className="css-s3o0sad3-sheet border-style tooltip-container"key={`Totals2-${formattedDate}`}>
        <span className="schedule-template"  >
        {formattedDailyTotals[formattedDate] || "00:00"}
        </span>
        <span className="schedule-template" style={{fontSize:'11px',fontWeight:'bold'}} >
        {formattedDailySalaries[formattedDate] || "€0.00"} 
        </span>
        <div className="tooltip-text-total">
    <div className="tooltip-header-total">
    <div className="tooltip-item">
      <span className="tooltip-label">{t('Total Break:')}</span>
      <span className="tooltip-value"> {formattedTooltip[formattedDate]|| "N/A"}</span>
    </div>
    <div className="tooltip-item">
      <span className="tooltip-label">{t('Total Surcharge Cost:')}</span>
      <span className="tooltip-value"> {formattedTooltipSurcharge[formattedDate]|| "N/A"}</span>
    </div>
    </div>
  </div>
</div>
         
            
              </>
             
              
            ))}

<div className="fixed-total-colum border-style" style={{background:'#eaf0e5'}}>
                    <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
                      <div className="d-flex grid-full-height">
                        <div className="sh-totals-wrapper">
                          <div className="sh-totals">
                          <div className="sh-totals__time">{formattedTotalHours}</div>
                          <div className="sh-totals__salary">{formattedTotalSalary}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
      </>
    );
  }
  
else if (currentDetails.type === "date") {
  const hasMatchingEmployees = Object.values(urendata).some(
    (data) => data.teamNames === teamname
  );

  const { formattedDailyTotals, formattedDailySalaries, formattedTotalHours, formattedTotalSalary } = calculateTeamTotalHoursAndSalary(urendata, dates, teamname);
  
// team total 
return (
  <>
    {hasMatchingEmployees && Object.keys(urendata).map((employerId) => {
      const { items, teamNames, employeeName, profileImage,totalWorkerWorkHour,totalWorkerWorkSalary} = urendata[employerId];
      const isTeamMatch = teamname === teamNames;
      const totalWithSurchargeSalary = calculateTotalWorkerWorkSalary(items, getsurcharger);
      return (
        <div
          key={`employer-${employerId}`}
          className="css-k23serv3s week-sv232 grid-full-width"
          style={{ display: isTeamMatch ? "inline-flex" : "none" }}
        >
          {isTeamMatch && (
            <>
              <div className="css-li23s54-sheet border-style" style={{ width: '28%' }}>
                <img
                  style={{ marginRight: '5px'}}
                  className="rounded-circle header-profile-user_plane"
                  src={profileImage || 'default_profile_image_url.jpg'}
                  alt={`${employeeName}_profile_shiftOnline`}
                />
                {employeeName}
              </div>

              {/* Render dates for team-matching entries */}
              {dates.map(({ formattedDate, title }, index) => {
                const itemsForDate = items.filter(
                  (item) => item.team_name === teamname && formatDateuren(item.uren_date) === formattedDate
                );

                let width;
                if (index === 5) {
                  width = '130px';
                } else if (index === 4 || index === 3) {
                  width = '100px';
                } else if (index === 2) {
                  width = '80px';
                } else {
                  width = '120px';
                }

                return itemsForDate.length === 0 ? (
                  <div
                    key={`no-items-${employerId}-${formattedDate}-${index}`}
                    className="css-s3o0sad3-sheet border-style"
                    style={{ display: 'block' }}
                  >
                    <div>
                      <button
                        className="css-1m92us add-plus schedule-button"
                        type="button"
                        style={{ width: '100%' }}
                        disabled={!isEditorAllowed}
                        onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate, team.dep_name, false)}
                      >
                        <span className="css-230ofxs" role="img" aria-hidden="true">
                          <i className="bx bx-plus"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    key={`items-${employerId}-${formattedDate}-${index}`}
                    className="css-s3o0sad3-sheet border-style"
                    style={{ display: 'flex', width }}
                  >
                    {itemsForDate.map((item) => {
                      const filteredShifts = shift.filter(s => s.team_id_shift === item.team_id_uren);
                            
                      const tableArr = [
                        <Input
                        disabled={!isEditorAllowed}
                        onChange={(e) =>
                          handleChangeTypeDate(e, {
                            urenId: item.uren_id,
                            start: e.target.value,  // Updated start time
                            end: item.eind_time_uren,
                            shiftId: item.shift_id_uren,
                            pause: item.pause_uren,
                          })
                        }
                        name="start_time_uren"
                        type="time"
                        style={{ width: '100%' }}
                        key={`start-${item.uren_id}`}
                        defaultValue={item.start_time_uren.split(":").slice(0, 2).join(":")}
                      />,
                      <Input
                      disabled={!isEditorAllowed}
                      onChange={(e) =>
                        handleChangeTypeDate(e, {
                          urenId: item.uren_id,
                          start: item.start_time_uren,
                          end: e.target.value,  // Updated end time
                          shiftId: item.shift_id_uren,
                          pause: item.pause_uren,
                        })
                      }
                      name="eind_time_uren"
                      type="time"
                      style={{ width: '100%' }}
                      key={`end-${item.uren_id}`}
                      defaultValue={item.eind_time_uren.split(":").slice(0, 2).join(":")}
                    />,
                        <Input
                        disabled={!isEditorAllowed}
                        onChange={(e) => handleChangeTypeDate(e, {
                          urenId: item.uren_id,
                          start: item.start_time_uren,
                          end: item.eind_time_uren,
                          shiftId: item.shift_id_uren,
                          pause: item.pause_uren,
                        })}
                        name="pause_uren"
                        type="number"
                        style={{ textAlign: 'center', width: '100%' }}
                        key={`pause-${item.uren_id}`}
                        defaultValue={item.pause_uren}
                      />,
                        <select  defaultValue={item.team_name} key={`select-team-${item.uren_id}`} style={{ textAlign: 'center', width: '100px', height: '36px' }}>
                          <option value={item.team_name}>{item.team_name}</option>
                        </select>,
                       <select
                       disabled={!isEditorAllowed}
                       onChange={(e) => handleChangeTypeDate(e, {
                        urenId: item.uren_id,
                        start: item.start_time_uren,
                        end: item.eind_time_uren,
                        shiftId: item.shift_id_uren,
                        pause: item.pause_uren,
                       })}
                       name="shift_id_uren"
                       key={`select-shift-${item.uren_id}`}
                       style={{ textAlign: 'center', width: '100px', height: '36px' }}
                       defaultValue={item.shift_id_uren}
                     >
                       {filteredShifts.map((shift,index) => (
                         <option
                         key={`team-${shift.shift_name}-${index}`}
                           value={shift.shift_id} // Use shift ID here for unique identification
                           data-start={shift.start_time_shift}
                           data-end={shift.eind_time_shift}
                         >
                           {shift.shift_name}
                         </option>
                       ))}
                     </select>,
                     
                        <TimeTrackingButton key={`tracking-${item.uren_id}`} isEditorAllowed={isEditorAllowed} item={item} props={props}  setIsReloadData={setIsReloadData}/>,
                      ];

                      return (
                        <div key={`item-${item.uren_id}`} className="parent-container">
                          <div className="schedule-template" id="schedule-template">
                            {tableArr[index]}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}

              {/* Total Column below the schedule */}
              <div className="fixed-total-colum border-style">
                <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
                  <div className="d-flex grid-full-height">
                    <div className="sh-totals-wrapper">
                      <div className="sh-totals">
                        <div className="sh-totals__time">{totalWithSurchargeSalary.adjustedWorkingTime}</div>
                        <div className="sh-totals__salary">{totalWithSurchargeSalary.totalWithSurchargeSalary}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    })}

    {/* Placeholder if no matching employees */}
    {!hasMatchingEmployees && (
      <div className="css-k23serv3s week-sv232 grid-full-width">
        <div className="css-li23s54-sheet border-style" style={{ width: '28%' }}>
          <span>{t('Schedule')}</span>
        </div>
        {dates.map(({ formattedDate }, index) => (
          <div className="css-s3o0sad3-sheet border-style targethover" key={`add-${formattedDate}-${index}`}>
            <div className="add-40os34">
              <button
                className="css-1m92us add-plus schedule-button"
                type="button"
                style={{ width: '100%' }}
                disabled={!isEditorAllowed}
                onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate, team.dep_name, false)}
              >
                <span className="css-230ofxs" role="img" aria-hidden="true">
                  <i className="bx bx-plus"></i>
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>
    )}
  </>
);

}


};


const handleChangeTypeDate = (e, { urenId, start, end, shiftId, pause }) => {
  const { name, value } = e.target;
  let startTime = name === 'start_time_uren' ? value : start;
  let endTime = name === 'eind_time_uren' ? value : end;
  let updatedPause = name === 'pause_uren' ? value : pause;
  // If the shift_id_uren is changed, get shift start and end times from the selected option
  if (name === 'shift_id_uren') {
    const selectedOption = e.target.options[e.target.selectedIndex];
    startTime = selectedOption.getAttribute('data-start');
    endTime = selectedOption.getAttribute('data-end');
  }

  // Calculate total hours based on start and end times
  const total = calculateWorkTime(startTime, endTime, parseInt(updatedPause, 10));

  // Build formdata object with updated values
  const formdata = {
    uren_id: urenId,
    start_time_uren: startTime,
    eind_time_uren: endTime,
    shift_id_uren: name === 'shift_id_uren' ? value : shiftId,
    pause_uren: updatedPause,
    total_day_hour: total,
  };
  postSheet(formdata)
};

const postSheet = async (data) => {
  try {
    const response = await fetch('/api/post/timesheet/adjustment', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.ok) {
      setIsReloadData(true); // Trigger data reload
    }
  } catch (error) {
    console.error("Error posting data:", error);
  }
};
function calculateWorkTime(start, end, pause = 0) {
  // Parse start and end times
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.split(':').map(Number);

  // Convert start and end times to total minutes
  const startTimeInMinutes = startHour * 60 + startMinute;
  const endTimeInMinutes = endHour * 60 + endMinute;

  // Calculate time difference in minutes
  let timeDifference = endTimeInMinutes - startTimeInMinutes;

  // Handle negative time difference (e.g., end time is before start time, across midnight)
  if (timeDifference < 0) {
    timeDifference += 24 * 60; // Add 24 hours worth of minutes
  }

  // Subtract pause time from total time (pause is in minutes)
  // timeDifference -= pause;

  // Ensure non-negative total after subtracting pause
  if (timeDifference < 0) timeDifference = 0;

  // Convert time difference to hours and minutes
  const totalHours = Math.floor(timeDifference / 60);
  const totalMinutes = timeDifference % 60;

  // Format hours and minutes with leading zero if necessary
  const formattedTotalHours = totalHours.toString().padStart(2, '0');
  const formattedTotalMinutes = totalMinutes.toString().padStart(2, '0');

  // Return total work time as a string in HH:MM format
  return `${formattedTotalHours}:${formattedTotalMinutes}`;
}

const filteredTeamByTeamLeider = teamdata.filter((team) => {
  const matches = teamleider.includes(String(team.team_id));
  return matches;
});


useEffect(() => {
  if (filteredTeamByTeamLeider.length > 0) {
    setCheckedItems(filteredTeamByTeamLeider.map(dep => ({ depid: dep.departments_id, checked: true })));
  }
}, [teamdata,filteredTeamByTeamLeider]);

const filteredDepData = useMemo(() => 
  filteredTeamByTeamLeider.filter(dep => 
    dep.dep_name?.toLowerCase().includes(searchDep?.toLowerCase() || "")
  ), 
  [filteredTeamByTeamLeider, searchDep]
);
const totalTeamPages = Math.ceil(filteredDepData.length / itemsPerPage);
const startTeamIndex = (currentPageDep - 1) * itemsPerPage;
const paginatedDep = useMemo(() => {
  const filteredData = filteredTeamByTeamLeider.filter(dep => 
    dep.dep_name && // Ensure dep_name exists
    dep.dep_name.toLowerCase().includes(searchDep.toLowerCase()|| "")
  );
  const startIndex = (currentPageDep - 1) * itemsPerPage;
  return filteredData.slice(startIndex, startIndex + itemsPerPage);
}, [filteredTeamByTeamLeider, searchDep, currentPageDep]);

const handleSearchDepChange = (e) => {
  const newValue = e.target.value;
  if (newValue !== searchDep) {
    setSearchDep(newValue);
    setCurrentDepPage(1); 
  }
};
const handlePageChangeDep = (newPage) => {
  setCurrentDepPage(newPage);
};

const handleCheckboxChange = (event, depid) => {
  setCheckedItems(prevItems => 
    prevItems.map(item => item.depid === depid ? { ...item, checked: event.target.checked } : item)
  );
};



const filteredTeamData = useMemo(() => 
  filteredTeamByTeamLeider.filter(dep => checkedItems.some(item => item.checked && item.depid === dep.departments_id)), 
  [filteredTeamByTeamLeider, checkedItems]
);



 const handlePutDepartmentChange = (e) => {
  const id = e.target.getAttribute('data-id');
  setPutDepartment((prevData) => ({
    ...prevData,
    id: id,    // Set the department ID
    value: e.target.value,          // Set the new value of the input
  }));
  setIsDisableDepPut(true)
};

const handlePutSubmitDepart = () => {

  if (putDepartMend.id && putDepartMend.value) {
    dispatch(putDep(putDepartMend));
  }
};

useEffect(() => {
  const depSuccessHandler = (message) => {
    toastr.success(t(message));
    const today = format(new Date(), 'yyyy-MM-dd')
    const obj = {
      start:currentDetails.startDate,
      eind:currentDetails.endDate <= today ? currentDetails.endDate : today,
      account_id:account_id,
    }
    dispatch(getUren(obj));
    dispatch(getShiftTeam(account_id));
    setApproveChecked(
      {
        date: [],
        employeer: [],
        status:'approve',
      }
    )
    
  };
  
  const depErrorHandler = (message) => {
    toastr.error(t(message));
  };

  if (putDeps) {
    putDeps.success 
      ? depSuccessHandler('The department data has been successfully updated.') 
      : depErrorHandler('Failed to update department data.');
  }

  if (postDeps) {
    postDeps.success 
      ? depSuccessHandler('New department data has been successfully submitted.')
      : depErrorHandler('Failed to submt department data.');
  }

  if (inspection) {
    inspection.success 
      ? depSuccessHandler('Inspection added successfully!') 
      : depErrorHandler('Failed to add inspection.');
  }
}, [putDeps, postDeps,inspection]);
const departmentInputClass = "form-control col-md-8";
const buttonClass = "css-smal4s9 hovered";
const navigationButtons = [
  { label: t('Dag'), onClick: () => handleTabClick(`/timesheet/date/${dateurl}`, 'date'), activeTab: '/timesheet/day' },
  { label: t('Week'), onClick: () => handleTabClick(`/timesheet/week/${dateurl}`, 'week'), activeTab: '/timesheet/week' },
  { label: t('Maand'), onClick: () => handleTabClick(`/timesheet/month/${dateurl}`, 'month'), activeTab: '/timesheet/month' }
];

const filterUrendataByTeam = (urendata, filteredTeamData) => {
  // Create a lookup for team IDs from filteredTeamData
  const teamIds = new Set(filteredTeamData.map(team => team.team_id));

  // Filter urendata based on whether items contain any matching team_id_uren
  const filteredUrendata = Object.fromEntries(
    Object.entries(urendata).filter(([_, data]) => 
      data.items.some(item => teamIds.has(item.team_id_uren))
    )
  );

  return filteredUrendata;
};

 const handlePosetApproved =(status) => {
  const formObj = {
    date: checkedApprove.date,
    employeer: checkedApprove.employeer,
    status: status,
  }
 dispatch(PostInspectionTimeSheet(formObj));
 }

 const handlePostSubmitDepart = () => {

  const data = {
    account_id: account_id,
    dep_name: departmentName,
    dep_adres:departmentAddress,
  };

  dispatch(postDep(data));
  // Add your submission logic here
};
//approve
const isApproveDisabled = !checkedApprove.date.length || !checkedApprove.employeer.length;
const filteredUrendata = filterUrendataByTeam(urendata, filteredTeamData);
const disabledButtonStyle = {
  backgroundColor: '#41a5e561', // Light blue with opacity
  cursor: 'not-allowed',
};
const disabledButtonNone = {
  display: 'none',
};
const activeButtonStyle = {
  backgroundColor: '#41a5e5', // Normal active button color
};
  return (
    
    <React.Fragment>
      <Modal isOpen={toast}>
    <ModalHeader toggle={toggleToast}>
      {t('Upgrade your plan')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
      {isPrintShiftModalopen && (
  <PrintTimesheetModal t={t} 
  show={isPrintShiftModalopen} 
  handleClose={handleClosePrintShiftModal} 
  urendata={filteredUrendata}
  absence={getalltabsence}
  currentDetails={currentDetails}
  checkedApprove={checkedApprove}
   />
)}
 
{isModalOpen && (
  <PlanningModal t={t} show={isModalOpen} handleClose={handleCloseModal} setIsReloadData={setIsReloadData} />
)}

{isDateShiftModalOpen && (
     <DatShiftModal 
     t={t} 
     show={isDateShiftModalOpen} 
     handleClose={handleCloseDateShiftModal} 
     selectedShiftItem={selectedShiftItem} 
   />
)}



      {isAbsenceModalOpen && (
   <AbsenceModal 
   t={t} 
   show={isAbsenceModalOpen} 
   handleClose={handleCloseAbnseceModal} 
   selectedAbsenceItem={selectedAbsenceItem} 
   getalltabsence={getalltabsence}
   setIsReloadData={setIsReloadData}
 />
)}
   

{isAddModalOpen && (
     <AddSignleDateUren 
     t={t} 
     show={isAddModalOpen} 
     handleClose={handleCloseAddModal} 
     selectedAddItem={selectedAddItem} 
     setIsReloadData={setIsReloadData}
   />
)}
    

{isPutModalOpen && (
  <PutSignleDateUren 
    t={t} 
    show={isPutModalOpen} 
    handleClose={handleClosePutModal} 
    selectedPutItem={selectedPutItem}
    setIsReloadData={setIsReloadData} 
  />
)}


<Modal isOpen={addDepModal}>
    <ModalHeader toggle={toggleAddDep} style={{backgroundColor:'#31a7f0',color:'white'}}>
      {t('Add New Department')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>

                <FormGroup>
          <Label for="departmentName">{t("Department Name")}</Label>
          <Input
            type="text"
            id="departmentName"
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
            placeholder={t("Enter department name")}
          />
        </FormGroup>

        <FormGroup>
          <Label for="departmentAddress">{t("Department Address")}</Label>
          <Input
            type="text"
            id="departmentAddress"
            value={departmentAddress}
            onChange={(e) => setDepartmentAddress(e.target.value)}
            placeholder={t("Enter or select address")}
          />
        </FormGroup>        
        <Button
          color="Primary"
          className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn"
          onClick={handlePostSubmitDepart}
        >
          {t("Submit")}
        </Button>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
        <Container fluid>
          {/* left Sidebar  */}
<div

className="right-slider-diens-0 modalopen" 
style={{
position: 'fixed',
width: '350px',
height: '320px',
overflow:'auto',
background: '#fff',
zIndex: 1000,
padding: '10px',
 top: '125px',
right:'225px',
display: isDepSliderVisible ? 'block' : 'none',
}}>
<div>
<div   className="closecontainer" style={{ paddingBottom: '10px', direction: 'rtl' }}>
<div onClick={toggleDepSlider} className="close_are" id="close_side" style={{ color: 'rgb(207, 8, 8)' }}>
<span>{t("close")}</span>
<i className="bx bx-chevron-right" style={{ fontSize: '20px' }}></i>
</div>
</div>

<div className="section_search col-12">
<div className="search-container">
<input type="text"
 className="search-input form-control"
 placeholder={t("search or add new")}
 onChange={handleSearchDepChange}
  />


{isEditorAllowed && (
  <>
   {paginatedDep && paginatedDep.length >= maxDepartments ? (
  // If the number of departments has reached the limit, show the toast toggle button
  <button className="css-smal4s9" type="button" onClick={toggleToast}>
    <span className="css-23dsfxs">
      <i className="bx bx-plus" style={{ fontSize: '24px' }}></i>
    </span>
  </button>
) : (
  // If the number of departments is below the limit, allow adding a new department
  <button className="css-smal4s9" type="button" onClick={toggleAddDep}>
    <span className="css-23dsfxs">
      <i className="bx bx-plus" style={{ fontSize: '24px' }}></i>
    </span>
  </button>
)}
  </>
)}


</div>

</div>

<div className="section_search col-12">
<div className="search-container">
<input type="text"
 className="search-input form-control"
 placeholder={t("search by department name")}
 onChange={handleSearchDepChange}
  />
<button className=" css-smal4s9" type="button">
<span className="css-23dsfxs">
  <i className="bx bx-search-alt-2" style={{ fontSize: '24px' }}></i>
</span>
</button>
</div>

</div>
<label htmlFor="dienst_siade_check">{t('Update Team')}</label>
{paginatedDep && paginatedDep.length > 0 ? (
  paginatedDep.map((dep, index) => (

    <div key={index} className="section_search col-12">
<div className="search-container">
<input
    className="form-check-input relative"
    type="checkbox"
    defaultChecked
    onChange={(e) => handleCheckboxChange(e, dep.departments_id)}
    style={{ right: "5px",width: "18px",height: "18px",top: "6px" }}
  />

<input type="text"
 className="search-input form-control"
 defaultValue={dep.dep_name}
 data-id={dep.departments_id}
 onChange={handlePutDepartmentChange}
  />
<button className=" css-smal4s9" type="button">
<span className="css-23dsfxs">
  <i className="bx bx-pencil" style={{ fontSize: '24px' }}></i>
</span>
</button>
</div>

</div>

  ))
) : (
  <p>{t('No teams available.')}</p>
)}


<Row>
        <Col xs="7">
          {t('Showing')} {currentPageDep === 1 ? 1 : (currentPageDep - 1) * itemsPerPage + 1} - {Math.min(currentPageDep * itemsPerPage, filteredTeamByTeamLeider.length)} {t('of')} {filteredTeamByTeamLeider.length}
        </Col>
        <Col xs="5">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPageDep === 1}
              onClick={() => handlePageChangeDep(currentPageDep - 1)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPageDep === totalTeamPages}
              onClick={() => handlePageChangeDep(currentPageDep + 1)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
      </Row>
</div>
</div>
        <div className="content-header">
          
      <div className="content-header__buttons">
        <div className="flex-1 flex">
          <div role="group" className="btn-group sub-header__buttons">
          <ul className="nav nav-pills">
    {navigationButtons.map((btn, index) => (
      <li key={index} className="nav-item">
        <a onClick={btn.onClick} className={`nav-link ${activeTab === btn.activeTab ? 'active' : ''}`}>
          <span>{btn.label}</span>
        </a>
      </li>
    ))}
  </ul>
          </div>
          <div role="group" className="btn-group sub-header__buttons">
            <button type="button" onClick={() => handelNextOrPrev('prev')} className="css-sq80gj" id="prev-month-btn">
              <span className="css-1tsc6yp">
                <span aria-label="Achteruit navigeren in datums" role="img" className="css-34iy07">
                <svg aria-hidden="true" focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 5l4 4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
                <span role="img" aria-label="Achteruit navigeren in datums" className="css-34iy07">
                <svg aria-hidden="true" focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 5l4 4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
              </span>
            </button>
            <button type="button" className="css-sq80gj">
              <input type={type}  onChange={handleDateChange}  style={{ width: '21px', border: 'none', outline: 'none', fontSize: '16px', color: '#1bbee3' }} />
            </button>
            {currentDetails ? (
            <p className="css-10ewgza d-none d-lg-block" id="month-label">
                {currentDetails.title}
            </p>
        ) : (
            <p>{t('Error: Could not load month details')}</p>
        )}
            <button type="button" onClick={() => handelNextOrPrev('next')} className="css-sq80gj" id="next-month-btn">
              <span className="css-1tsc6yp">
                <span aria-label="Vooruit navigeren in datums" role="img" className="css-34iy07">
                <svg aria-hidden="true"  focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9l4-4-4-4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
                <span role="img" aria-label="Vooruit navigeren in datums" className="css-34iy07">
                <svg aria-hidden="true"  focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9l4-4-4-4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
              </span>
            </button>
          </div>
          <div className="btn-group sub-header__buttons">
            <button className="css-1m5o92us" type="button"
             onClick={handleOpenModal}
             disabled={!isEditorAllowed}
             >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
              <span  className="css-1ixbps20l d-none d-md-block">{t('Add schedule')}</span>
            </button>
            <button onClick={() => setIsDropOpen(!isDropOpen)} style={{ borderLeft:'1px solid',width: '20%' }} className=" css-1m5o92sus" type="button" >
            <span _ngcontent-jjn-c215="" role="img" className="css-23dsfxsu" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="sum">
        <i className="bx bx-chevron-down"></i>
        </span>
            </button>
            {isDropOpen && (
           <ul
           className="dropUl"
            >
            <li
            className="dropLi"
            onClick={handlePrintShiftModal}
            >
          {t('Print statements')} 
            </li>
           </ul>
            )}
          </div>
     
          <div className="btn-group sub-header__buttons"style={{marginLeft:'10px'}} >
            <button  onClick={toggleDepSlider} style={{fontWeight:'700',marginLeft: '10px'}} className=" css-1m92us" type="button" >
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">{t('All departments')}</span>
              <span role="img" className="css-230ofxs" aria-hidden="true">
                <i className="bx bx-chevron-down"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* header */}
    <div className="page__contentB">
    <div className="card card__schedule-optimized ng-star-inserted">
   
<div className="top_reels" id="top_reels" style={{background:'#ffffff'}}>
{loading && (
        <div style={{display:'flex',background:'#fff'}} className="loadingPage" id="loadingPage">
        <span className="loader"></span>
        </div>
      )}
<div className={type === 'week' ? 'css-k23serv3s blue-sv232 sticky_day grid-full-width' : ' sticky_day css-k23serv3s blue-sv232 sticky_day'}
 style={{
  display: type === "date" ? 'none' : 'inline-flex'
}}
>
              <div className="css-li23s54" style={type === 'week' ? { width: '28%' } : { width: '200px' }} ></div>
              {renderDates()}
            </div>
          
            {filteredTeamData && filteredTeamData.length > 0 ? (
  filteredTeamData.map((team, index) => (
    <div  key={`team-${team.team_id}`} className="col-lg-12 no-left-padding-row">
      
    <div className="block-style-rows block-border-radius ">
      
<div className="card-heading card-heading__table department-header-border" 
  style={
    type === 'month' 
      ? { width: '1750px' }
      : type === 'date' 
      ? { width: '100%' }
      : { width: '100%' } 

  }
>


  <div className="sticky-department-header-left-col card-heading__title card-heading__no-padding">

    <div id="schedultitle" className="schedultitle">
    {currentDetails ? (
      <span
      id="date_currently"
      className="css-1ixbps20l"
      style={{ fontWeight: 600 }}
    >
      {company_name} - {team.team_name} {currentDetails.title}
    </span>
  ) : (
    <span
    id="date_currently"
    className="css-1ixbps20l"
    style={{ fontWeight: 600 }}
  >
   {t('Error: Could not load month details')}
  </span>
      
  )}
      
    </div>
  </div>
  <div className="sticky-department-header-right-col card-heading__button-group card-optimized-roster">
  <div className="btn-group sub-header__buttons">
<button className="css-1m5o92us" type="button"
               disabled={!isEditorAllowed && isApproveDisabled}
               onClick={() => handlePosetApproved('approve')}

               style={isApproveDisabled ? disabledButtonStyle : activeButtonStyle}
             >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
              <span  className="css-1ixbps20l d-none d-md-block">{t('Approved')}</span>
            </button>
            <button   onClick={() => toggleDropdown(team.team_name)}  disabled={!isEditorAllowed && isApproveDisabled}
             style={{
              borderLeft: '1px solid',
              width: '20%',
              ...(isApproveDisabled ? disabledButtonStyle : activeButtonStyle)
            }}
            className=" css-1m5o92sus" type="button" >
            <span _ngcontent-jjn-c215="" role="img" className="css-23dsfxsu" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="sum">
        <i className="bx bx-chevron-right"></i>
        </span>
            </button>
            {isDropdownOpen[team.team_name] && (
            <div
            style={{display:'flex'}}
            >
           <button className="css-1m5o92us" type="button"
               onClick={() => handlePosetApproved('disapprove')}
               style={{
               marginLeft:'5px',
               marginRight:'5px',
               ...(isApproveDisabled ? disabledButtonNone : activeButtonStyle)
              }}

              disabled={isApproveDisabled}
             >
              <span  className="css-1ixbps20l d-none d-md-block">{t('Disapprove')}</span>
            </button>
            <button className="css-1m5o92us"  type="button"
           onClick={() => handlePosetApproved('pending')}
            style={{
              marginRight:'5px',
              ...(isApproveDisabled ? disabledButtonNone : activeButtonStyle)
             }}
             disabled={isApproveDisabled}
             >
              <span  className="css-1ixbps20l d-none d-md-block">{t('Pending')}</span>
            </button>
            </div>
          )}
</div>
    <button
      className=" css-1m5o92us"
      type="button"
      onClick={() => handleOpenAddModal(team.team_id,team.dep_id ,urlDate,team.dep_name ,true)}
      disabled={!isEditorAllowed}                               
    >
      <span
        className="css-23dsfxs"
        data-mat-icon-type="svg"
        data-mat-icon-name="sum"
      >
        <i
          className="bx bx-plus"
          style={{ fontSize: '20px' }}
        ></i>
      </span>
      <span className="css-1ixbps20l d-none d-md-block">{t('Add schedule')}</span>
    </button>

    
  </div>
</div>
</div>





      {/* Team specific row */}
      <div
        className={type === 'week' ? 'css-k23serv3s blue-sv232 grid-full-width' : 'css-k23serv3s blue-sv232'&&type === 'date' ? 'css-k23serv3s blue-sv232 grid-full-width' : 'css-k23serv3s blue-sv232'}
        style={{
          backgroundColor: dimColor(team.team_color, 0.1),
          borderBottom: `3px solid ${team.team_color}`,
          height:'60px'
        }}
      >
<div className="css-li23s54" style={type === 'week' ? { width: '28%' } : { width: '200px' } && type === 'date' ? { width: '28%' } : { width: '200px' }}>

<div className="row" style={{ width: '100px' }}>
            <div className="col-6 css-23fs434">
              <span
                className="css-spanfs3"
                style={{ border: '2px solid rgb(133, 160, 133)' }}
              >
                <i className="bx bx-chevron-right"></i>
              </span>
            </div>
            <div className="col-6" style={{ display: 'inline-grid' }}>
              {/* Dynamic team name */}
              <span>{team.dep_name}</span>
              <small style={{ position:'relative',width:'101px',textOverflow:'ellipsis' }}>{team.team_name}</small>
            </div>
          </div>
        </div>
        {renderWithDateName()}
        <div className="fixed-total-colum border-style"
          style={{
            backgroundColor: dimColor(team.team_color, 1),
            borderBottom: `3px solid ${team.team_color}`,
            height:'60px',
            color:'white',
           
          }}
         >
        <span className="css-s3o0sad3-sheet">{t('Totals')}</span>
     
      </div>
      </div>

      {/* Schedule section */}
      <div className="css-sa3422a">
      {renderSchedule(team.team_name,team)}
      </div>
    </div>
  ))
) : (
  <p>{t('No teams available.')}</p>
)}
   
      </div>

      </div>  

    </div>
   

{/* department-period-row */}

<div className="row" style={{ marginLeft: 'auto', marginRight: 'auto', padding: 'unset' }}>
  <div className="col-10 sectionTitle_top" style={{ display: 'none' }}>
    <div className="row seconRow_top">
      <div className="col-9 no-left-padding-row">
        <div className="btnmod">
          <button
            className="olv-button olv-ignore-transform css-1m92us ex-css3s4"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            disabled={!isEditorAllowed}
          >
            <span
              role="img"
              className="css-230ofxs"
              aria-hidden="true"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <i className="bx bx-chevron-right"></i>
            </span>
            <span data-qa="submit-username-text" className="css-1ixbps20l">
            {t('Shift/Teams')} 
            </span>
          </button>
        </div>
      </div>
      <div className="col-3 no-left-padding-row">
        <div>
          <button
            className="olv-button olv-ignore-transform css-1m5o92us"
            type="button"
            style={{ width: '80%' }}
            disabled={!isEditorAllowed}
          >
            <span
              role="img"
              className="css-23dsfxs"
              aria-hidden="true"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <svg
                filename="sum.svg"
                viewBox="0 0 16 16"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
              </svg>
            </span>
            <span data-qa="submit-username-text" className="css-1ixbps20l">
            {t('Add schedule')}  
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="row css-k23serv3s ">

</div>
        </Container>
      
      
    </React.Fragment>
  );
};
const dropdownItemStyle = {
  display: 'block',
  padding: '10px 20px',
  textDecoration: 'none',
  color: '#333',
  cursor: 'pointer',
};
export default withTranslation()(withRouter(Schedule));

