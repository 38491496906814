/* Shift */
export const GET_REPORT_DATA = "GET_REPORT_DATA"
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS"
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL"

export const POST_REPORT_DATA = "POST_REPORT_DATA"
export const POST_REPORT_DATA_SUCCESS = "POST_REPORT_DATA_SUCCESS"
export const POST_REPORT_DATA_FAIL = "POST_REPORT_DATA_FAIL"

export const DELETE_REPORT_DATA = "DELETE_REPORT_DATA"
export const DELETE_REPORT_DATA_SUCCESS = "DELETE_REPORT_DATA_SUCCESS"
export const DELETE_REPORT_DATA_FAIL = "DELETE_REPORT_DATA_FAIL"



export const POST_REPORT_DATA_ABSENCE = "POST_REPORT_DATA_ABSENCE"
export const POST_REPORT_DATA_ABSENCE_SUCCESS = "POST_REPORT_DATA_ABSENCE_SUCCESS"
export const POST_REPORT_DATA_ABSENCE_FAIL = "POST_REPORT_DATA_ABSENCE_FAIL"

export const GET_WEB_SETTING = "GET_WEB_SETTING"
export const GET_WEB_SETTING_SUCCESS = "GET_WEB_SETTING_SUCCESS"
export const GET_WEB_SETTING_FAIL = "GET_WEB_SETTING_FAIL"