import {
    ADD_NEW_DOC_SUCCESS,
    ADD_NEW_DOC_FAIL,
    ADD_RECIEPIEN_DOC_SUCCESS,
    ADD_RECIEPIEN_DOC_FAIL,
    GET_TEMPLATE_SUCCESS,
    GET_TEMPLATE_FAIL,
    DELETE_TEPLATE_SUCCESS,
    DELETE_TEPLATE_FAIL,
    GET_TEMPLATE_DATA_SUCCESS,
    GET_TEMPLATE_DATA_FAIL,
  } from "./actionTypes";
  const INIT_STATE = {
    documentData: [],
    reciepienData: [],
    postNewReciepie: {},
    postNewDocs: {},
    template: [],
    templatedata: [],
    deletetemp: {},
    error: {},
  };
  const getDocumentsData = (state = INIT_STATE, action) => {
    switch (action.type) {
      
     case ADD_NEW_DOC_SUCCESS:
      return {
        ...state,
        postNewDocs: [...state.documentData, action.payload],
      };

    case ADD_NEW_DOC_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      case ADD_RECIEPIEN_DOC_SUCCESS:
      return {
        ...state,
        postNewReciepie: [...state.reciepienData, action.payload],
      };

    case ADD_RECIEPIEN_DOC_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      

      //ss

        case GET_TEMPLATE_SUCCESS:
          return {
            ...state,
            template: action.payload,
          };
  
      case GET_TEMPLATE_FAIL:
        return {
          ...state,
          error: action.payload,
        };
        //dd

          case DELETE_TEPLATE_SUCCESS:
            return {
              ...state,
              deletetemp: action.payload,
            };
    
        case DELETE_TEPLATE_FAIL:
          return {
            ...state,
            error: action.payload,
          };

          case GET_TEMPLATE_DATA_SUCCESS:
            return {
              ...state,
              templatedata: action.payload,
            };
    
        case GET_TEMPLATE_DATA_FAIL:
          return {
            ...state,
            error: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default getDocumentsData;