import React, { useRef, useState, useEffect,useMemo,useCallback  } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardTitle,
  InputGroup,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  getShift,
  getUren,
  postUren,
  postShift,
  getAllAbsence,
  getContractEmployer,
} from "store/actions";
import Select from "react-select"
//redux
import { useSelector, useDispatch } from "react-redux";
import { addWeeks, format, parseISO, isValid } from 'date-fns';
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';

const daysOfWeek = ["zo","ma","di","wo","do","vr","za"];
const PlanningModal = ({t, show, handleClose,setIsReloadData}) => {
  function formatDateuren(dateString) {
    const date = new Date(dateString); // Convert string to Date object
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }

  const { permissions } = useUserPermissions();

  const { plan_name } = permissions || {};
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const { type: paramType,date:urlDate } = useParams();
  const departmentLimits = {
    starter: 1,
    professional: 6,
    enterprise: Infinity, // Assuming enterprise has no limit
  };

  const TeamLimits = {
    starter: 5,
    professional: Infinity,
    enterprise: Infinity, // Assuming enterprise has no limit
  };
  const {
    client,
    xlsid,
    contractdata,
    postDeps,
    postTeams,
    postContractTypes,
    shift,
    shiftdata,
    putshift,
    removeshift,
    urendata,
    posturen,
    puturen,
    removeuren,
    contractemployer,
    getalltabsence,
    error
  } = useSelector(state => ({
    client: state.getClientData.client,
    getalltabsence: state.getAbsenceData.getalltabsence,
    xlsid: state.getClientData.xlsid,
    shiftDatabase: state.getClientData.shiftDatabase,
    postDeps: state.getClientData.postDeps,
    postTeams: state.getClientData.postTeams,
    postContractTypes: state.getClientData.postContractTypes,
    removeuren: state.getShiftData.removeuren,
    shift: state.getShiftData.shift,
    shiftdata: state.getShiftData.shiftdata,
    putshift: state.getShiftData.putshift,
    removeshift: state.getShiftData.removeshift,
    urendata: state.getShiftData.urendata,
    posturen: state.getShiftData.posturen,
    puturen: state.getShiftData.puturen,
    contractemployer: state.getShiftData.contractemployer,
    error: state.getClientData.error,
  }));
  //meta title xlsid
  useEffect(() => {
    dispatch(onGetcollaborator(account_id));
    dispatch(getXlsdata(account_id));
    dispatch(getShift(account_id));
    dispatch(getContractEmployer(account_id));
    dispatch(getAllAbsence(account_id));
  }, [dispatch  ,account_id]);

  const useValidateEmployerData = (clients, getalltabsence, contractemployer, urendata, today) => {
    return useCallback((clientId) => {
      const consolidatedData = {
        exists: false,
        exists_contract: false,
        exists_schedule: false,
        client_id: clientId,
        profile_img: null,
        last_name: null,
        first_name: null,
        client_status: null,
        contract_expires: null,
        contract_id: null,
        service_type: null,
        absence: [],
        email:  null,
        scheduling: []
      };
  
      // 1. Check for Absence in getalltabsence
      const absenceData = getalltabsence.filter(absence => absence.workerid === clientId);
      if (Array.isArray(absenceData) && absenceData.length > 0) {
        consolidatedData.exists = true;
        absenceData.forEach(absence => {
          consolidatedData.absence.push({
            from: absence.startdate_absence,
            until: absence.enddate_absence
          });
        });
      }
  
      // 2. Check for Contract in contractemployer
      const contractData = contractemployer.find(contract => contract.worker_id === clientId);
      if (contractData) {
        consolidatedData.exists_contract = true;
        consolidatedData.contract_id = contractData.contrct_id;
        consolidatedData.contract_expires = contractData.extension_end_date;
      }
  
      // 3. Check for Schedule in urendata for the given date (today)
      const clientDataInUrendata = urendata[clientId];
      if (clientDataInUrendata && clientDataInUrendata.items) {
        const scheduleData = clientDataInUrendata.items
          .filter(
            schedule =>
              formatDateuren(schedule.uren_date) === today &&
              schedule.work_status !== 'vk' &&
              schedule.work_status !== 'zk'
          )
          .map(schedule => ({
            start_time: schedule.start_time_shift,
            end_time: schedule.eind_time_shift,
            shift_name: schedule.shift_name
          }));
  
        if (scheduleData.length > 0) {
          consolidatedData.exists_schedule = true;
          consolidatedData.scheduling = scheduleData;
        }
      }
  
      // 4. Add Client Data depa_id contract_id
      const clientData = clients.find(c => c.client_id === clientId);
      if (clientData) {
        consolidatedData.profile_img = clientData.profile_img;
        consolidatedData.last_name = clientData.last_name;
        consolidatedData.first_name = clientData.frist_name;
        consolidatedData.client_status = clientData.client_status;
        consolidatedData.service_type = clientData.service_type;
        consolidatedData.contract_id = clientData.contact_id;
        consolidatedData.email = clientData.email;
      }
  
      return consolidatedData;
    }, [clients, getalltabsence, contractemployer, urendata, today]);
  };
  // Create a consolidated list
  

  const [filteredDataModal, setFilteredDataModal] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [teamOptions, setTeamOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedShift, setSelectedShiftName] = useState(null);
  const [newTeamName, setNewTeamName] = useState('');
  const [newShiftName, setNewShiftName] = useState('');
  const [isCreatingNewTeam, setIsCreatingNewTeam] = useState(false);
  const [isCreatingNewShift, setIsCreatingNewShift] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showHerhaalPerson, setShowHerhaalPerson] = useState(false);
  const [showEinddatum, setShowEinddatum] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [consolidatedEmployerList, setConsolidatedEmployerList] = useState([]);
  const [workingTime, setWorkingTime] = useState(null);
  const [formattedTime, setFormattedTime] = useState('');
  const weekOptions = Array.from({ length: 52 }, (v, i) => ({
    value: `${i + 1}`,
    label:  `${t('Week')} ${i + 1}`,
  }));
  const [toast, setToast] = useState(false);
  const toggleToast = () => {
    setToast(!toast);
};
  

const maxDepartments = departmentLimits[plan_name] || 1;
const maxTeams = TeamLimits[plan_name] || 5;
  // Get unique team_id 

  const handleWeekChange = (selectedOption) => {
    // Parse svc_date to ensure it's in the correct date format (yyyy-mm-dd)
    const startDate = parseISO(shiftDatabase.svc_date);
    // Extract the number of weeks from selectedOption.value and parse it as an integer
    const weeksToAdd = parseInt(selectedOption.value, 10); // Convert to number
    // Validate the parsed date
    if (!isValid(startDate)) {
      console.error("Invalid svc_date detected:", shiftDatabase.svc_date);
      return; // Exit the function if svc_date is invalid
    }
  
    try {
      // Calculate the end date by adding the selected weeks
      const endDate = addWeeks(startDate, weeksToAdd);
      // Format end date as yyyy-mm-dd
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  
      // Update shiftDatabase with calculated end date
      setShiftDatabase((prev) => ({
        ...prev,
        repeat_select: selectedOption.value, // Set the selected week
        eind_date_svc: formattedEndDate, // Set calculated end date
      }));
  
      
    } catch (error) {
      console.error("Error calculating end date:", error);
    }
  };
    
  const [totalHours, setTotalHours] = useState(0);
  const  subject = t('New Work Schedule Notification!')
  const text = t('We’re writing to inform you that a new work schedule has been successfully posted for your employees. Please review the details below and take any necessary actions if required.')
  const [shiftDatabase, setShiftDatabase] = useState({
    account_id: account_id,
    discription: null,
    eind_date_svc: urlDate,
    eind_time: '',//2024-05-31
    pause: '', // 2025-05-31
    repeat_arr: ['ma', 'di'],
    repeat_select: 1,
    send_mail_check: false,
    start_time: null,
    svc_date: urlDate,// ['8' ,'6' ,'6']
    svc_dep: null,
    svc_dienst: null,
    svc_name: null,
    svc_team: null,// ['10' ,'20']
    werknemerid: [], 
    werknemer:[],
    werknemeremail:[],
    contractid: [],
    depa_id: null, 
    eind_date_check: false, 
    repeat_check: false,
    subject: subject,
    text: text,
    shortLink: 'https://shiftspec.com',
    company_name:company_name,
    sort: t('Notification Email (Informative, Action-Oriented)'),
    btntext: t('View Schedule'),
    
  });
  const validateEmployerData = useValidateEmployerData(client, getalltabsence, contractemployer, urendata, shiftDatabase.svc_date);
  useEffect(() => {
    const fetchConsolidatedList = async () => {
      const list = client.map(c => validateEmployerData(c.client_id));
      setConsolidatedEmployerList(list);
    };
    fetchConsolidatedList();
  }, [client, validateEmployerData]);
   
 
  useEffect(() => {
    const timer = setTimeout(() => {
      if (Array.isArray(xlsid) && xlsid.length >= 0) {
        
        const seenDepartments = new Set();
        const uniqueDepartment = xlsid.filter(dept => {
          if (!seenDepartments.has(dept.departments_id)) {
            seenDepartments.add(dept.departments_id);
            return true;
          }
          return false;
        });
  
        if (uniqueDepartment.length > 0) {
          setFilteredDataModal(filterClients(uniqueDepartment[0].dep_name));
          
        }
  
        const options = [
          // Map unique departments to options
          ...uniqueDepartment.map((dept, index) => ({
            value: dept.departments_id,
            label: dept.dep_name,
            default: index === 0, // Set the first option as default
          })),
          // Add the "Create New Department" option
          ...(uniqueDepartment.length < maxDepartments
            ? [{ value: 'new', label: t('Create New Department') }]
            : [{ value: 'over', label: t('Department limit reached!') }]),
        ];
  
        setDepartmentOptions(options);
  
        // Automatically select the first department option if available
        const defaultOption = options.find(opt => opt.default) || options[0];
        if (defaultOption) {
          setSelectedDepartment(defaultOption);
          setSelectedDepartmentId(defaultOption.value);
          setShiftDatabase(prev => ({
            ...prev,
            depa_id: defaultOption.value,
            svc_dep: defaultOption.label,
          }));
        }
      }
    }, 2000);
  
    return () => clearTimeout(timer);
  }, [xlsid, maxDepartments, t]);
  

 
  useEffect(() => {
   
    if (selectedDepartmentId) {
      // Introduce a 1-second delay before setting the options
        if (Array.isArray(xlsid) && xlsid.length > 0) {
          // Filter the teams by the selected department ID
          const filteredTeams = xlsid.filter(dept => dept.dep_id === selectedDepartmentId);
  
          // Create a Set to track unique team names to avoid duplication
          const seenTeams = new Set();
          const uniqueTeams = filteredTeams.filter(team => {
            if (!seenTeams.has(team.team_id)) {
              seenTeams.add(team.team_id);
              return true;
            }
            return false;
          });
          
          if (uniqueTeams.length > 0) {
            setShiftDatabase(prev => ({
              ...prev,
              svc_team: uniqueTeams[0].team_id,
            }));
            setSelectedTeam({value: uniqueTeams[0].team_id,label: uniqueTeams[0].team_name} );
            setSelectedTeamId(uniqueTeams[0].team_id);
          }
          // Map the unique teams to the options array
          const options = [
            ...(uniqueTeams.length > 0
              ? uniqueTeams.map(team => ({
                  value: team.team_id,
                  label: team.team_name,
                }))
              : [{ value: 'new', label: t('Create New Team') }]),
            ...(uniqueTeams.length < maxTeams
              ? [{ value: 'new', label: t('Create New Team') }]
              : [{ value: 'over', label: t('Team limit reached!') }]),
          ];
        
          // Set the filtered and unique team options
          setTeamOptions(options);
        } else {
          setTeamOptions([]);
        }
     
    } else {
      setTeamOptions([]); // Clear team options if no department is selected
    }
  }, [selectedDepartmentId, xlsid]);
  
  
  useEffect(() => {
    if (!selectedTeamId || !Array.isArray(shift) || shift.length === 0) {
      setShiftOptions([{ value: 'new', label: t('Create New Shift') }]); // Clear options if no team selected or no shifts available
      return;
    }
  
    // Filter shifts by selectedTeamId
    const filteredShifts = shift.filter(s => s.team_id_shift === selectedTeamId);
    
    // Use Set to find unique shifts
    const seenShiftIds = new Set();
    const uniqueShifts = filteredShifts.filter(s => {
      if (!seenShiftIds.has(s.shift_id)) {
        seenShiftIds.add(s.shift_id);
        return true;
      }
      return false;
    });
  
    if (uniqueShifts.length > 0) {
      // Update shift in shiftDatabase
      setShiftDatabase(prev => ({
        ...prev,
        svc_dienst: uniqueShifts[0].shift_id,
        svc_name: uniqueShifts[0].shift_name,
      }));
      
      // Set the first unique shift as selected by default
      setSelectedShiftName({
        value: uniqueShifts[0].shift_id,
        label: uniqueShifts[0].shift_name
      });
      setStartTime(uniqueShifts[0].start_time_shift);
      setEndTime(uniqueShifts[0].eind_time_shift);
      setShiftDatabase(prevData => ({
        ...prevData,
        eind_time: uniqueShifts[0].eind_time_shift,
        start_time: uniqueShifts[0].start_time_shift,
      })); 
      // Map options for dropdown and add "Create New Shift"
      const options = [
        ...uniqueShifts.map(s => ({
          value: s.shift_id,
          label: s.shift_name,
        })),
        { value: 'new', label: t('Create New Shift') },
      ];
      setShiftOptions(options);
    } else {
      const options = [
        { value: 'new', label: t('Create New Shift') },
      ];
      setShiftOptions(options);
    }
  
  }, [selectedTeamId, shift]);
  
  const handleNewDepartmentChange = (e) => {
    setNewDepartmentName(e.target.value);
   
  };
  const handleAddNewDepartment = () => {
    if (newDepartmentName.trim()) {
      const isDuplicate = departmentOptions.some(option => option.value === newDepartmentName);
  
      if (!isDuplicate) {
        const newOption = { value: newDepartmentName, label: newDepartmentName };
        setDepartmentOptions(prevOptions => [...prevOptions, newOption]);
        setSelectedDepartment(newOption);
        setNewDepartmentName('');
        setIsCreatingNew(false);
        setFilteredDataModal(filterClients(newDepartmentName));
        const data = {
          account_id: account_id,
          dep_name: newDepartmentName,
          dep_adres:newDepartmentName,
        };
        
       dispatch(postDep(data));
      } else {
        alert(t('This department already exists.'));
      }
    }
  };
  const handleDepartmentChange = (selectedOption) => {
    if (selectedOption.value === 'new') {
      setIsCreatingNew(true);
      setSelectedDepartment(null); // Clear the selection
      setSelectedDepartmentId(null); // Clear the department ID label
      setShiftDatabase({
        ...shiftDatabase,
        depa_id: null,
        svc_dep: null,
      });
    } else if (selectedOption.value === 'over') {
      toggleToast()
    } else {
      setSelectedDepartment(selectedOption);
      setSelectedDepartmentId(selectedOption.value); // Set the selected department ID
      setIsCreatingNew(false);
      
      setShiftDatabase({
        ...shiftDatabase,
        depa_id: selectedOption.value,
        svc_dep: selectedOption.label,
      });
      
      setFilteredDataModal(filterClients(selectedOption.label));
    }
   
  };
 

  const handleTeamChange = (selectedOption) => {
    
    if (selectedOption.value === 'new') {
      setIsCreatingNewTeam(true);
      setSelectedTeamId(null);
      setShiftDatabase({
        ...shiftDatabase,
        svc_team: null,
      });
    } else if (selectedOption.value === 'over') {
      toggleToast()
    } else {
      setSelectedTeam(selectedOption);
      setSelectedTeamId(selectedOption.value);
      setIsCreatingNewTeam(false);
      setShiftDatabase({
        ...shiftDatabase,
        svc_team: selectedOption.value,
      });
    }
  };

  const handleNewTeamChange = (e) => {
    setNewTeamName(e.target.value);
  };
  const handleNewShiftChange = (e) => {
    setNewShiftName(e.target.value);
    setShiftDatabase({
      ...shiftDatabase,
      svc_dienst: 'new',
      svc_name: e.target.value,
    });
  };

  const handleAddNewTeam = () => {
    if (newTeamName.trim()) {
      const isDuplicate = teamOptions.some(option => option.value === newTeamName);
  
      if (!isDuplicate) {
        const newOption = { value: newTeamName, label: newTeamName };
        setTeamOptions(prevOptions => [...prevOptions, newOption]);
        setSelectedTeam(newOption);
        setNewTeamName('');
        setIsCreatingNewTeam(false);
  
        const data = {
          account_id: account_id,
          team_name: newTeamName,
          dep_id: selectedDepartmentId, // Use the selected department ID
        };

        dispatch(postTeam(data));
        
      } else {
        toastr.error(t('This team already exists.')); // Use toastr for notifications
      }
    } else {
      toastr.error(t('Please enter a valid team name.')); // Use toastr for notifications
    }
  };  

  const [isCreatingNew, setIsCreatingNew] = useState(false);
  

  const handleNotificationChange = (e) => {
    const { checked } = e.target;

    setShiftDatabase((prev) => ({
      ...prev,
      send_mail_check: checked, // Update the send_mail_check in the state
    }));
  };

  const handleDayChange = (e) => {
    const { value, checked } = e.target;

    setShiftDatabase((prev) => {
      const updatedRepeatArr = checked
        ? [...prev.repeat_arr, value] // Add the day if checked
        : prev.repeat_arr.filter((day) => day !== value); // Remove the day if unchecked

      return {
        ...prev,
        repeat_arr: updatedRepeatArr, // Update the repeat_arr in state
      };
    });
  };

  const handleSearchChangeModal = (event) => {
    setFilteredDataModal(filterClients(event.target.value));
  };

const handleHerhaalChange = (e) => {
  setShowHerhaalPerson(e.target.checked);
  const startDate = parseISO(shiftDatabase.svc_date);
  const weeksToAdd = parseInt(shiftDatabase.repeat_select, 10);
  setShiftDatabase(prevData => ({
    ...prevData,
    repeat_check: e.target.checked,
  }));
  if (!isValid(startDate)) {
    console.error("Invalid svc_date detected:", shiftDatabase.svc_date);
    return; // Exit the function if svc_date is invalid
  }

  try {
    // Calculate the end date by adding the selected weeks
    const endDate = addWeeks(startDate, weeksToAdd);
    // Format end date as yyyy-mm-dd
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    // Update shiftDatabase with calculated end date
    setShiftDatabase((prev) => ({
      ...prev,
      eind_date_svc: formattedEndDate, // Set calculated end date
    }));
  } catch (error) {
    console.error("Error calculating end date:", error);
  }
};

const handleEinddatumChange = (e) => {
  setShowEinddatum(e.target.checked);
  setShiftDatabase(prevData => ({
    ...prevData,
    eind_date_check: e.target.checked,
  }));
};

const handleRepeatUntil = (e) => {
  setShiftDatabase((prev) => ({
    ...prev,
    eind_date_svc: e.target.value, // Save selected date in 'eind_time'
  }));
};
const handleUrendate = (e) => {
  const newDate = e.target.value;
  setShiftDatabase((prev) => ({
    ...prev,
    svc_date: newDate, // Update the date in shiftDatabase
  }));

  
};


const handlePauseChange = (e) => {
  setShiftDatabase(prevData => ({
    ...prevData,
    pause: e.target.value,
  }));
}
const handleNotesChange = (e) => {
  setShiftDatabase(prevData => ({
    ...prevData,
    discription: e.target.value,
  }));
}
const handleStartTimeChange = (selectedDates) => {
  // selectedDates is an array; pick the first value
  const newStartDate = selectedDates[0]; 

  setShiftDatabase(prevData => ({
    ...prevData,
    start_time: newStartDate ? newStartDate.toTimeString().slice(0, 5) : "",
  }));
};

const handleEndTimeChange = (selectedDates) => {
  // selectedDates is an array; pick the first value
  const newEndDate = selectedDates[0]; 

  setShiftDatabase(prevData => ({
    ...prevData,
    eind_time: newEndDate ? newEndDate.toTimeString().slice(0, 5) : "",
  }));
};

 
  const handleSelectAll = () => {
    const checkboxes = document.querySelectorAll('.plane_checkbox_pay_plane');
    if (selectAll) {
      // Deselect all clients
      setSelectedUsers([]);
      setShiftDatabase({
        ...shiftDatabase,
        werknemerid: [],
        contractid:[],
        werknemer:[],
        werknemeremail:[],
      });
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false
      });
    } else {
      const allClientIds = filteredDataModal.map(client => client.client_id);
      const allClientname = filteredDataModal.map(client => client.first_name);
      const allClientemail = filteredDataModal.map(client => client.email);
      const allClientContracId = filteredDataModal.map(client => client.contract_id);
      setSelectedUsers(allClientIds);
      setShiftDatabase({
        ...shiftDatabase,
        werknemerid: allClientIds,
        contractid:allClientContracId,
        werknemer:allClientname,
        werknemeremail:allClientemail,
      });
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true
      });
    
    }
    setSelectAll(!selectAll); // Toggle the select all state
  }
  const handleUserSelection = (userId, name, contractid,email) => {
  
    if (selectedUsers.includes(userId)) {
      // Remove userId, name, and contractid if already selected
      const updatedUserIds = selectedUsers.filter(id => id !== userId);
      const updatedUsernames = shiftDatabase.werknemer.filter(n => n !== name);
      const updatedUseremail = shiftDatabase.werknemeremail.filter(mail => mail !== email);
      const updatedUserContracts = shiftDatabase.contractid.filter(c => c !== contractid);
      
      setSelectedUsers(updatedUserIds);
      setShiftDatabase({
        ...shiftDatabase,
        werknemerid: updatedUserIds,
        contractid: updatedUserContracts,
        werknemer: updatedUsernames,
        werknemeremail:updatedUseremail,
      });
    } else {
      // Add userId, name, and contractid if not selected
      const updatedUserIds = [...selectedUsers, userId];
      const updatedUsernames = [...shiftDatabase.werknemer, name];
      const updatedUserContracts = [...shiftDatabase.contractid, contractid];
      const updatedUseremail = [...shiftDatabase.werknemeremail, email];
      setSelectedUsers(updatedUserIds);
      setShiftDatabase({
        ...shiftDatabase,
        werknemerid: updatedUserIds,
        contractid: updatedUserContracts,
        werknemer: updatedUsernames,
        werknemeremail:updatedUseremail,
      });
    }
  };
  

  const handleShiftChange = (selectedOption) => {
    if (selectedOption.value === 'new') {
      setIsCreatingNewShift(true);
      setShiftDatabase({
        ...shiftDatabase,
        svc_dienst: selectedOption.value,
        svc_name: selectedOption.label,
      });
    }else{
      setSelectedShiftName(selectedOption);
      const selectedShiftData = shift.find((shiftItem) =>
        String(shiftItem.shift_id) === String(selectedOption.value)
      );
      if (selectedShiftData) {
        setStartTime(selectedShiftData.start_time_shift);
        setEndTime(selectedShiftData.eind_time_shift);
        setShiftDatabase({
          ...shiftDatabase,
          svc_dienst: selectedOption.value,
          svc_name: selectedOption.label,
          start_time: selectedShiftData.start_time_shift,
          eind_time: selectedShiftData.eind_time_shift,
        });
      } 
    }
  
    
  };

  const handleAddNewShift = () => {
    if (newShiftName.trim()) {
      const isDuplicateInOptions = shiftOptions.some(option => option.value === newShiftName);
      const isDuplicateInShift = shift.some(item => item.shift_name === newShiftName);
      if (!isDuplicateInOptions && !isDuplicateInShift) {
        const newOption = { value: newShiftName, label: newShiftName };
        setShiftOptions(prevOptions => [...prevOptions, newOption]);
        setSelectedShiftName(newOption);
        setNewShiftName('');
        setIsCreatingNewShift(false);
        toastr.success(t('New Shift added successfully.'))
      } else {
        toastr.error(t('This Shift already exists.')); // Use toastr for notifications
      }
    } else {
      toastr.error(t('Please enter a valid shift name.')); // Use toastr for notifications
    }
  };  

// Filter clients based on keyword
const filterClients = useCallback(
  (keyword) => {
    const searchStr = (keyword || '').toString().toLowerCase().trim();
    return consolidatedEmployerList.filter(client =>
      (client.first_name || '').toLowerCase().includes(searchStr) ||
      (client.last_name || '').toLowerCase().includes(searchStr) ||
      (client.service_type || '').toLowerCase().includes(searchStr)
    );
  },
  [consolidatedEmployerList]
);

useEffect(() => {
  const timer = setTimeout(() => {
    if (consolidatedEmployerList.length > 0) {
      setFilteredDataModal(filterClients('')); // Populate initially without filter
    }
  }, 1000);

  return () => clearTimeout(timer);
}, [consolidatedEmployerList, filterClients]);

const validateForm = () => {
  const newErrors = {};

  if (!shiftDatabase.account_id) newErrors.account_id = t('Account  is required.');
  if (!shiftDatabase.eind_time) newErrors.eind_time = t('End time  is required.');
  if (!shiftDatabase.pause) newErrors.pause = t('Break time is required.');
  if (!shiftDatabase.svc_dienst) newErrors.svc_dienst = t('Shift is required.');
  if (!shiftDatabase.svc_name) newErrors.svc_name = t('Shift name is required.');
  if (!shiftDatabase.start_time) newErrors.start_time = t('Start date is required.');
   if (!shiftDatabase.svc_date) newErrors.svc_date = t('Date is required.');
  if (!shiftDatabase.werknemerid.length) newErrors.werknemerid = t('Employeer is required.');
  if (!shiftDatabase.depa_id) newErrors.depa_id = t('Department is required.');
  if (!shiftDatabase.discription) newErrors.discription = t('Discription is required.');
  if (shiftDatabase.repeat_check) {
    if (!shiftDatabase.repeat_select) newErrors.repeat_select = t('Repeat  is required.');
    if (!shiftDatabase.repeat_arr.length) newErrors.repeat_arr = t('Repeat date time is required.');
  }
  if (shiftDatabase.eind_date_check) {
    if (!shiftDatabase.eind_date_svc) newErrors.eind_date_svc = t('End date is required.');
  }
  
  
  return newErrors // Returns true if there are no errors
};
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toastr.error(error));
      // Proceed with form submission or further logic
    } else {
     dispatch(postUren(shiftDatabase));
    setShiftDatabase((prev) => ({
      account_id: account_id,
      discription: null,
      eind_date_svc: urlDate,
      eind_time: '',//2024-05-31
      pause: '', // 2025-05-31
      repeat_arr: ['ma', 'di'],
      repeat_select: 1,
      send_mail_check: false,
      start_time: null,
      svc_date: urlDate,// ['8' ,'6' ,'6']
      svc_dep: null,
      svc_dienst: null,
      svc_name: null,
      svc_team: null,// ['10' ,'20']
      werknemerid: [], 
      werknemer:[],
      contractid: [],
      depa_id: null, 
      eind_date_check: false, 
      repeat_check: false,
      
    }));
    }
  };



  useEffect(() => {
    const depSuccessHandler = (message) => {
      toastr.success(t(message));
      setIsReloadData(true)
    };
    
    const depErrorHandler = (message) => {
      toastr.error(t(message));
    };
  
    if (posturen) {
      posturen.success 
        ? depSuccessHandler('Schedule created successfully! Your schedule is now active and visible to the team.') 
        : depErrorHandler('Failed to create schedule. Please check the details and try again.');
    }
  
    if (postDeps) {
      postDeps.success 
        ? depSuccessHandler('New department data has been successfully submitted.')
        : depErrorHandler('Failed to submt department data.');
    }
    if (shiftdata) {
      shiftdata.success 
        ? depSuccessHandler('Shift added successfully! The new shift has been saved and is now part of the schedule.')
        : depErrorHandler('Unable to post the shift. Check for overlapping times or missing details.');
    }
  }, [postDeps, postDeps,account_id,shiftdata,setIsReloadData]);

  const convertTo24HourFormat = (time) => {
    if (!time) return { hours: 0, minutes: 0 };

    // Check if the time is in 12-hour format
    if (time.toLowerCase().includes('am') || time.toLowerCase().includes('pm')) {
      const [timePart, modifier] = time.split(' ');
      let [hours, minutes] = timePart.split(':').map(Number);

      if (modifier.toLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
      } else if (modifier.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
      }
      return { hours, minutes };
    }

    // Assume time is in 24-hour format (e.g., 14:30)
    const [hours, minutes] = time.split(':').map(Number);
    return { hours, minutes };
  };

  useEffect(() => {
    if (shiftDatabase.start_time && shiftDatabase.eind_time && shiftDatabase.pause) {
      const { hours: startHour, minutes: startMinute } = convertTo24HourFormat(shiftDatabase.start_time);
      const { hours: endHour, minutes: endMinute } = convertTo24HourFormat(shiftDatabase.eind_time);
      const pauseMinutes = parseInt(shiftDatabase.pause, 10);

      let totalMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute) - pauseMinutes;

      if (totalMinutes < 0) {
        totalMinutes += 24 * 60;
      }

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      setWorkingTime(`${hours}:${minutes.toString().padStart(2, '0')}`);
      setFormattedTime(`${shiftDatabase.start_time} - ${shiftDatabase.eind_time} (${shiftDatabase.pause})`);
    }
  }, [shiftDatabase]);
  return (
    <React.Fragment>
       <Modal isOpen={toast}>
    <ModalHeader toggle={toggleToast}>
      {t('Upgrade your plan')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
       <Modal
                      
                      isOpen={show} 
                      toggle={handleClose} centered
                      style={{ bottom: '0',maxWidth: '900px', width: '90%' }}
                      scrollable={true}
                    >
                      <div className="modal-header" style={{backgroundColor:'#31a7f0',color:'white'}}>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Date')}:{`${shiftDatabase.svc_date}`}
                        </h5>
                        <button
                        onClick={handleClose}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {show && (  
 <div className="modal-body">
  <Row>
  <Col lg="8">
  <form onSubmit={handleSubmit}>
  <div className="row">
    <div className="col-lg-12">
     <Row>
     <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="search_driver" className="col-md-1 col-form-label">{t('Date')}:</label>
        <div className="col-md-3 position-relative">
        <Input className="form-control col-md-8"
  onChange={handleUrendate} // Handle date selection
  value={shiftDatabase.svc_date || urlDate}
         type="date" 
          id="search_driver" 
          />
        </div>
      </div>
       </Col>
     <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-3 col-form-label">
          {t('Department')}
        </label>
        <div className="col-md-7" id="select_dep">
        <Select
           id="department_id"
           name="department_id"
           options={departmentOptions}
           onChange={handleDepartmentChange}
           value={selectedDepartment}
           
          />
        </div>
      </div>
      {isCreatingNew && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-3 col-form-label">
            
          </label>
          <div className="col-md-7">
            <input
               type="text"
               id="new_department"
               className="form-control"
               value={newDepartmentName}
               onChange={handleNewDepartmentChange}
               placeholder={t("Enter new department name")}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewDepartment}
            >
              {t('Add Department')}
            </button>
          </div>
        </div>
      )}
 
      </Col>
      <Col lg="12">
      <div>
      <div className="mb-3 row">
        <label htmlFor="svc_team" className="col-md-2 col-form-label">
        {t('Team')}
        </label>
        <div className="col-md-7" id="select_team">
        <Select
       id="team_id"
       name="team_id" 
       options={teamOptions}
       onChange={handleTeamChange}
       value={selectedTeam}
       isDisabled={!selectedDepartmentId} 
        
        />
        </div>
      </div>
      {isCreatingNewTeam  && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-2 col-form-label">
            {t('New Team')}
          </label>
          <div className="col-md-8">
            <input
             type="text"
             id="new_team"
             className="form-control"
             value={newTeamName}
             onChange={handleNewTeamChange}
             placeholder={t("Enter new team name")}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewTeam}
            >
            {t('Add Team')}
            
            </button>
          </div>
        </div>
      )}

      </div>
      </Col>
    
      <Col lg="12">
   <div>
   <div className="mb-3 row">
        <label htmlFor="contract_type" className="col-md-2 col-form-label">
        {t('Shift')}
        </label>
        <div className="col-md-6" id="contract_type">
        <Select
            id="contract_type"
            name="contract_type"
            options={shiftOptions}
            onChange={handleShiftChange}
            value={selectedShift}
            isDisabled={!selectedTeamId} 
          />
        </div>
      </div>
      {isCreatingNewShift  && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-2 col-form-label">
          {t('New Shift')} 
          </label>
          <div className="col-md-6">
            <input
             type="text"
             id="new_team"
             className="form-control"
             value={newShiftName}
             onChange={handleNewShiftChange}
             placeholder={t("Enter new Shift name")}
            />
             <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewShift}
            >
               {t('Add Shift')}
            </button>
          </div>
        </div>
      )}
   </div>
       </Col>
      <Col lg="6">
      <div className="mb-3 row">
        <label htmlFor="search_driver" className="col-md-3 col-form-label">{t('Search')}</label>
        <div className="col-md-9 position-relative">
        <Input className="form-control col-md-8"

         type="text" 
         placeholder={t("Type some keyword...")}
          id="search_driver" 
          onKeyUp={handleSearchChangeModal}
          />
        </div>
      </div>
       </Col>
      <Col lg="6">
      <div className="mb-3 row">
        <div className="col-md-9 select_all">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
          <Input className="form-check-input" type="checkbox" id="selectAll_employees"  
            onChange={handleSelectAll}/>
            <label className="form-check-label" htmlFor="selectAll_employees">
            {t('Select All')}
            </label>
          </div>
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row medewerkers" id="medewerkers">
        <label htmlFor="svc_team" className="col-md-4 col-form-label">
          
        </label>
        <div className="col-md-8" style={{ background: "aliceblue" }}>
        <ul className="plane_grid_pay_plane" id="plane_grid_pay_plane" style={{padding:'unset'}}>
  {filteredDataModal && filteredDataModal.length > 0 ? (
    filteredDataModal.map(client => (
      <li
       key={client.client_id} 
      className={` card_pay_plane ${selectedUsers.includes(client.client_id) ? 'selected' : ''}`}
      style={{
        display: 'inline-block',
        backgroundColor: (client.exists || client.exists_schedule || !client.exists_contract) ? '#f6e5e3' : 'transparent'
      }}
       >

        <div
         className="card_pay_plane"
         style={{
          backgroundColor: (client.exists || client.exists_schedule || !client.exists_contract) ? '#f6e5e3' : 'transparent'
        }}
        >
        <div>
          <img
            className="rounded-circle header-profile-user_plane"
            src={client.profile_img}
            alt="Avatar"
          />
        </div>
        <div 
        className="card__content_pay_plane"
        style={{
          backgroundColor: (client.exists || client.exists_schedule || !client.exists_contract) ? '#f6e5e3' : 'white'
        }}
        >
          <span>{`${client.first_name} ${client.last_name}`}</span>
        </div>
        <label 
        className="checkbox-control_pay_plane"
        style={{
          backgroundColor: (client.exists || client.exists_schedule || !client.exists_contract) ? '#f6e5e3' : 'white'
        }}
        >
          <p className="time_cost"></p>
          <p className="time_cost">{client.client_status}</p>
          <Input
            type="checkbox"
            className="checkbox_pay_plane plane_checkbox_pay_plane"
           
            onChange={() => handleUserSelection(client.client_id,client.first_name,client.contract_id,client.email)}
          />
          <span className="checkbox-control__target_pay_plane"></span>
        </label>
        </div>
   {/* Absence Section */}
   {client.exists && (
        <div className="flex justify-between px-1 ng-star-inserted">
          <span className="select-employee__title min-w-[120px]">{t('Absence')}</span>
          <span className="select-employee__value grow">{t('Vacation: All day')}</span>
        </div>
      )}

      {/* Schedule Section */}
      {client.exists_schedule && client.scheduling.map((scheduleItem, index) => (
        <div key={`forindex3-${index}`} className="flex justify-between px-1 ng-star-inserted">
           <span className="select-employee__title min-w-[120px]">{t('Schedule')}</span>
          <div className="relative grid" style={{ right: '120px' }}>
            <span className="select-employee__value grow">
              {scheduleItem.shift_name}: {scheduleItem.start_time} - {scheduleItem.end_time}
            </span>
          </div>
        </div>
      ))}

      {/* Contract Section */}
      {!client.exists_contract && (
           <div className="flex justify-between px-1 ng-star-inserted">
             <span className="select-employee__title min-w-[120px]">{t('Contract')}</span>
           <div className="relative grid" style={{ right: '120px' }}>
             <span className="select-employee__value grow">
             {t('No any Contrac with')} :{client.last_name}
             </span>
           </div>
         </div>

      )}
       
   
    
      </li>
    ))
  ) : (
    <p>{t('No employeer found.')}</p>
  )}
</ul>

        </div>
      </div>
       </Col>
       <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="start_time" className="col-md-4 col-form-label">
        {t('Start Time & End Time')}
        </label>
        <div className="col-lg-4">
          <div className="mb-3">
          <InputGroup>
          <Flatpickr
            id="start_time"
            className="form-control d-block"
            placeholder={t("Select time")}
            defaultValue={startTime || "01:00"} // Set default value
            onChange={handleStartTimeChange}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
          />
</InputGroup>
      
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-3">

          <InputGroup>
          <Flatpickr
            id="end_time"
            className="form-control d-block"
            placeholder={t("Select time")}
            defaultValue={endTime || "01:00"} // Set default value
            onChange={handleEndTimeChange}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
          />
</InputGroup>
  
          </div>
        </div>
      </div>
       </Col>
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="hourly_rate" className="col-md-2 col-form-label d-flex justify-content-center align-items-center">
        {t('Break')}
        </label>
        <div className="col-md-3">
        <Input onChange={handlePauseChange} className="form-control" type="number" name="hourly_rate" placeholder="0" id="hourly_rate" />
        </div>
   
      </div>
       </Col>

       <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              
              id="description"
              className="form-control"
              rows="3"
              onChange={handleNotesChange}
            ></textarea>
          
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3">
        <div className="form-check form-checkbox-outline form-check-success mb-3">
        <Input   onChange={handleHerhaalChange} className="form-check-input" name="Herhaal" type="checkbox" id="Herhaal" />
          <label className="form-check-label" htmlFor="Herhaal">
          {t('Repeat Shift')} 
          </label>
        </div>
      </div>
       </Col>
       {showHerhaalPerson && (
        <Col lg="12">
          <div className="mb-3 row">
            <label htmlFor="svc_team" className="col-md-2 col-form-label">
            {t('Repeat every')}
            </label>
            <div className="col-md-7" id="HerhaalPerson">
            <Select
          id="HerhaalPerson"
          name="HerhaalPerson"
          options={weekOptions}
          defaultValue={weekOptions[0]}
          value={weekOptions.find(option => option.value === shiftDatabase.repeat_select)}
          onChange={handleWeekChange}
        />
            </div>
          </div>
          <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              name="Einddatum"
              type="checkbox"
              id="Einddatum"
              onChange={handleEinddatumChange}
            />
            <label className="form-check-label" htmlFor="Einddatum">
            {t('Set end date')}  
            </label>
          </div>
        </div>
        </Col>
      
      )}
     
      {showEinddatum && (
         <Col lg="12">
         <div className="mb-3 row">
           <label htmlFor="search_driver" className="col-md-3 col-form-label">{t('Repeat until:')}</label>
           <div className="col-md-3 position-relative">
           <Input className="form-control col-md-8"
           onChange={handleRepeatUntil} // Handle date selection
           value={shiftDatabase.eind_date_svc || ''}
            type="date" 
             id="repeatuntill" 
             />
           </div>
         </div>
          </Col>
      )}
     
       {showHerhaalPerson && ( 
      <div className="mb-3 row">
      <label htmlFor="repeaton" className="col-md-2 col-form-label">
      {t('Repeat on:')} 
      </label>
      <div className="col-md-8" id="repeaton">
        <div className="checkbox-grid">
          {/* Header Row */}
          <div className="checkbox-row" style={{background:'#f0f8ff'}}>
          {[t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')].map((day, idx) => (
            <div key={`foriidx-${idx}`} className="checkbox-header">
              {day}
            </div>
          ))}
          </div>
         
    
          {/* Checkbox Row */}
    
          <div className="checkbox-row" style={{background:'#f1f1f1'}}>
          {daysOfWeek.map((day, idx) => (
              <div key={`foriidx2-${idx}`} className="checkbox-body">
              <input
                type="checkbox"
                value={day}
                className="daginput checkbox-input"
                onChange={handleDayChange}
                id={day}
                defaultChecked={['ma', 'di'].includes(day)} // Pre-check 'Ma' and 'Di'
              />
            </div>
          ))}
          </div>
        
        </div>
      </div>
    </div>

        )}
 



      <Col lg="12">
      <div className="mb-3">
        <div className="form-check form-checkbox-outline form-check-success mb-3">
        <Input
              className="form-check-input"
              name="send_notification"
              type="checkbox"
              id="send_notification"
              onChange={handleNotificationChange} // Handle checkbox state change
              checked={shiftDatabase.send_mail_check} // Sync checkbox with state
            />
          <label className="form-check-label" htmlFor="send_notification">
          {t('Send Notification')}
          </label>
        </div>
      </div>
      </Col>
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light"  onClick={handleClose} >
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>
  </Col>
  <Col lg="4">
  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ borderBottom: '1px solid #ddd' }}>
        <th style={{ padding: '5px', textAlign: 'left' }}>{t('Time')}</th>
        <th style={{ padding: '5px', textAlign: 'left' }}>{t('Payout')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: '5px', textAlign: 'left' }}>
            {formattedTime || ''}
          </td>
          <td style={{ padding: '5px', textAlign: 'left' }}>
            {workingTime ? `${workingTime}` : ''}
          </td>
        </tr>
      </tbody>
    </table>

</Col>

  </Row>

</div>

                      )}
                     
                    </Modal>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(PlanningModal));

