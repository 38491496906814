import React, { useRef, useState, useEffect } from 'react';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import { map } from "lodash";
import DraggableElement from './DraggableElement';
import DraggableElementNames from './DraggableElementNames';
import DraggableElementText from './DraggableElementText';
import DraggableElementFormula from './DraggableElementFormula';
import DraggableRect from './DraggableRect';
import '../../assets/scss/theme.scss';
import { createRoot } from 'react-dom/client';
import {QRCodeSVG} from 'qrcode.react';
import 'react-resizable/css/styles.css';
import {
  getTemplateData,
  getDocument,
} from "store/actions";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";

const DraggableList =  (props) => {
  const { t,pdfContainerRef ,
    selectedOption,
    setDraggableType ,
    setDraggableNameType ,
     draggableElements,
      draggableElementName,
      onDragStart,onDragEnd,selectedZoom,permissions } = props;
    const {
      templatedata,
      loading,
      error
    } = useSelector(state => ({
      templatedata: state.Documents.templatedata,
      loading: state.Documents.loading,
      error: state.Documents.error,
    }));
    const dispatch = useDispatch();
    const draggableElementRef = useRef(null);
    const { uuid } = useParams();
    const [recipientNames, setRecipientNames] = useState([]);
    const [recipientEmails, setRecipientEmail] = useState([])
    const [recipientMsg, setRecipientMsg] = useState([])
    const [recipientroll, setRecipientRoll] = useState([])
    const [Colors, setColor] = useState([]);
    const [recipienid, setRecipienid] = useState([]);
    const [isOwner, setIsOwner] = useState([]);
    const [docNames, setDocNames] = useState([]);
    const [signaturepng, setSignPng] = useState([]);
    const [paraafimg, setParaafImg] = useState([]);
    const [stamppng, setStampPng] = useState([]);
    const {editor,plan_status,plan_name } = permissions;
    const isEditorAllowed = editor === 1;
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [userId, setuserId] = useState(user.clientId);
    const [account_id, setAccountId] = useState(user.account_id);
    const [token, setToken] = useState(user.account_token);
    const [pdfTextArea, setpdfTextArea] = useState('Text');
    const removeButton = document.getElementById('removeIcons');
    const deleteIdRef = useRef(null);
    const arrayIdRef = useRef(null);
   const pdfContainer = document.querySelector('svg#pdf-container');
   const [dragItem, setDragItem] = useState(null);
   let startX = 0;
   let startY = 0;
    let selectedElement = null;
    let selectedCheckbox = null;
    let isDragging = false;
    let resizngElement = null;
    let minWidth = 102;
    let minHeight = 35;
    const RESIZE_TOP_LEFT = 0;
    const RESIZE_TOP_RIGHT = 1;
    const RESIZE_BOTTOM_LEFT = 2;
    const RESIZE_BOTTOM_RIGHT = 3;
     const resizers = []; 
     let resizing = false;
     let currentPage = 0;
     const selectDocState = (state) => state.Prepare;

     const DocumentProperties = createSelector(
       selectDocState,
       (getSigantureImgData) => ({
         docname: getSigantureImgData.docname,
       })
     );
   
     const { docname } = useSelector(DocumentProperties);
     useEffect(() => {
       const fetchData = async () => {
         try {
           if (uuid) {
             await dispatch(getDocument(uuid));
           } else {
             console.error("User ID is not defined");
           }
         } catch (error) {
           console.error("Error fetching document:", error);
         }
       };
   
       fetchData();
     }, [dispatch, uuid]);

  function generateRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
  }

 useEffect(() => {
  // Check if docname is an array
  if (Array.isArray(docname)) {
    // Assuming 'data' is the fetched data from your API
  
    // Use reduce to extract the required data from the 'data' array
    const extractedData = docname.reduce(
      (acc, item) => {
        const recipients = JSON.parse(item.recipient_data);
        recipients.forEach((recipient) => {
          acc.recipientNames.push(recipient.recipientName);
          acc.recipientEmail.push(recipient.recipientEmail);
          acc.recipientMsg.push(recipient.recipientMsg);
          acc.recipientroll.push(recipient.recipientroll);
          acc.Colors.push(recipient.color);
          acc.recipienid.push(recipient.recipienid);
          acc.isOwner.push(recipient.isOwner);
        });
        
        return acc;
      },
      {
        recipientEmail: [],
        recipientMsg: [],
        recipientroll: [],
        Colors: [],
        recipientNames: [],
        recipienid: [],
        isOwner:[]
      }
    );

    if (docname.length > 0) {
      setDocNames(docname[0].doc_name);
    }
    setRecipientNames(extractedData.recipientNames);
    setRecipientEmail(extractedData.recipientEmail);
    setRecipientMsg(extractedData.recipientMsg);
    setRecipientRoll(extractedData.recipientroll);
    setColor(extractedData.Colors);
    setRecipienid(extractedData.recipienid);
    setIsOwner(extractedData.isOwner)
  } else {
    console.error("docname is not an array");
  }
}, [docname]);
     useEffect(() => {
   
    }, [pdfContainer]);
     useEffect(() => {
       if (uuid) {
         dispatch(getTemplateData(uuid));
       }
     }, [dispatch, uuid,pdfContainer]);
   
     useEffect(() => {
      
     
      if (templatedata && templatedata.length > 0) {
      
        const jsons = templatedata.map(item => item.jsondata);
      
        jsons.forEach(data => createObjectdata(data));
        // Now you have an array of extracted data
       
      }else{
       
      }
    
    }, [templatedata,pdfContainer]);

    
    async function createObjectdata(data) {
      
      try {
          const parsedData = JSON.parse(data);
         
          if (!Array.isArray(parsedData) || parsedData.length === 0) {
              throw new Error('Parsed data is not an array or is empty');
          }
          
          // Loop through each item in the array
          const keyHandlers = {
            qrcode: imageObjectArray,
            formula: imageObjectArray,
            dropdown: imageObjectArray,
            company: imageObjectArray,
            fullname: imageObjectArray,
            email: imageObjectArray,
            title: imageObjectArray,
            handwriteing: imageObjectArray,
            hand_praaf: imageObjectArray,
            stemps: imageObjectArray,
            calendar: imageObjectArray,
            textarea: imageObjectArray,
            drawing: imageObjectArray,
            attachment: imageObjectArray,
            approve: imageObjectArray,
            decline: imageObjectArray,
            number: imageObjectArray,
            payment: imageObjectArray,
            radio: imageObjectArray,
            checkbox: imageObjectArray,
          };
      
          parsedData.forEach(obj => {
            Object.keys(obj).forEach(key => {
              if (keyHandlers[key]) {
                keyHandlers[key](obj[key]);
              }
            });
          });
      } catch (error) {
          console.error('Validation failed:', error.message);
      }
  }

  async function imageObjectArray(data) {
    
    // Check if data.targetid exists and is an array
    if (Array.isArray(data.targetid) && data.targetid.length > 0) {
      for (let i = 0; i < data.targetid.length; i++) {
        const currentItem = {
          targetid: data.targetid[i],
          height: data.height[i],
          x: data.x[i],
          y: data.y[i],
          width: data.width[i],
          content: data.signContent[i],
          dateformat: data.dataFormat[i],
          fontColor: data.fontColor[i],
          fontSize: data.fontSize[i],
          fontFamily: data.fontFamily[i],
          fontFormat: data.fontFormat[i],
          page: data.page[i],
          roll: data.roll[i],
          isOrder: data.isOrder[i],
          issigned: data.isSigned[i],
          fullname: data.fullname,
          colors: data.colors,
          token: data.client_token,
          uniqueid: data.userid,
          zoom: data.zoom_int,
          email: data.email,
          messeges: data.messeges,
          sign_id: data.sign_id,
          format: data.type[i],
          labelValeu: data.labelValeu[i],
          dropdownoption: data.selectOption,
          dropdownvalue: data.selectValue,
          groupischecked: data.ischecked,
          groupmax: data.groupContaineMax,
          groupmin: data.groupContaineMin,
          grouprule: data.groupContaineRule,
          containerY: data.containerY,
          containerX: data.containerX,
          totalheight: data.totalheight,
          totalwidth: data.totalwidth,
          grroupvalidation: data.groupContainevalidation,
          nummax: data.max,
          nummin: data.min,
          selecttype: data.selecttype,
          valutacode: data.valutacode[i],
          fgcolo: data.fgColor[i],
          bgcolor: data.bgColor[i],
          groupContainerid: data.groupContainerid,
          rectgroupContainerid: data.rectgroupContainerid[i],
        };
        const existingSignature = document.querySelector(`[data-id="${currentItem.targetid}"]`);
      if (existingSignature) {
        // Optionally remove the existing signature or skip creating a new one
        // pdfContainer.removeChild(existingSignature);
        continue; // Skip to avoid duplicates
      }
        switch (currentItem.format) {
          case 'handwriteing':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              t('Signature'),
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'hand_praaf':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              t('Initial'),
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'stemps':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              t('Stamp'),
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'calendar':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              currentItem.content ,
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'fullname':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              currentItem.content ,
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'email':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              currentItem.content ,
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'company':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              currentItem.content ,
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break; 
          case 'title':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              currentItem.content ,
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'drawing':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              t('Drawing'),
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'attachment': 
          createSignatureElement(
            currentItem.x,
            currentItem.y,
            currentItem.content ,
            currentItem.colors, 
            currentItem.format,
            currentItem.fullname,
            currentItem.messeges,
            currentItem.email,
            currentItem.roll,
            currentItem.content ,
            currentItem.content ,
            currentItem.content ,
            currentItem.format,
            '⇩',
            currentItem.targetid,
            currentItem.fontFamily,
            currentItem.fontSize,
            currentItem.fontFormat,
            currentItem.fontColor,
            currentItem.dateformat,
            currentItem.height,
            currentItem.width,
            currentItem.content ,
            currentItem.content ,
            currentItem.uniqueid,
            'true'
          );
          break;
          case 'approve':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              t('approve'),
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'decline':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              t('decline'),
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'number': 
          createSignatureElement(
            currentItem.x,
            currentItem.y,
            currentItem.content ,
            currentItem.colors, 
            currentItem.format,
            currentItem.fullname,
            currentItem.messeges,
            currentItem.email,
            currentItem.roll,
            currentItem.content ,
            currentItem.content ,
            currentItem.content ,
            currentItem.format,
            currentItem.content ,
            currentItem.targetid,
            currentItem.fontFamily,
            currentItem.fontSize,
            currentItem.fontFormat,
            currentItem.fontColor,
            currentItem.dateformat,
            currentItem.height,
            currentItem.width,
            currentItem.content ,
            currentItem.content ,
            currentItem.uniqueid,
            'true'
          );
          break;
          case 'payment':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              currentItem.content ,
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'dropdown':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              'select ▼' ,
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
            break;
          case 'formula':
           createSignatureElement(
            currentItem.x,
            currentItem.y,
            currentItem.content ,
            currentItem.colors, 
            currentItem.format,
            currentItem.fullname,
            currentItem.messeges,
            currentItem.email,
            currentItem.roll,
            currentItem.content ,
            currentItem.content ,
            currentItem.content ,
            currentItem.format,
            t('formula'),
            currentItem.targetid,
            currentItem.fontFamily,
            currentItem.fontSize,
            currentItem.fontFormat,
            currentItem.fontColor,
            currentItem.dateformat,
            currentItem.height,
            currentItem.width,
            currentItem.content ,
            currentItem.content ,
            currentItem.uniqueid,
            'true'
          );
            break;
            case 'textarea':
            createSignatureElement(
              currentItem.x,
              currentItem.y,
              currentItem.content ,
              currentItem.colors, 
              currentItem.format,
              currentItem.fullname,
              currentItem.messeges,
              currentItem.email,
              currentItem.roll,
              currentItem.content ,
              currentItem.content ,
              currentItem.content ,
              currentItem.format,
              t('Text'),
              currentItem.targetid,
              currentItem.fontFamily,
              currentItem.fontSize,
              currentItem.fontFormat,
              currentItem.fontColor,
              currentItem.dateformat,
              currentItem.height,
              currentItem.width,
              currentItem.content ,
              currentItem.content ,
              currentItem.uniqueid,
              'true'
            );
              break;
          case 'qrcode':
         
            setTimeout(() => {
              createQRCodeElement(
                currentItem.x,
                currentItem.y,
                currentItem.content ,
                currentItem.colors, 
                currentItem.format,
                currentItem.fullname,
                currentItem.messeges,
                currentItem.email,
                currentItem.roll,
                currentItem.content,
                currentItem.content,
                currentItem.content,
                currentItem.format,
                currentItem.content,
                currentItem.targetid,
                currentItem.fontFamily,
                currentItem.fontSize,
                currentItem.fontFormat,
                currentItem.fontColor,
                currentItem.dateformat,
                currentItem.width,
                currentItem.height,
                currentItem.content,
                '2',
                "1px solid #ccc",
                currentItem.width,
                currentItem.fgcolo,
                currentItem.bgcolor,
                currentItem.uniqueid,
                'true'
                ) 
            }, 3000);
          
            
            break; 
          case 'checkbox':
            case 'radio':
          
            setTimeout(() => {
              createGroupBoxCheckAndRadio(
                currentItem.x,
                currentItem.y,
                currentItem.content ,
                currentItem.colors, 
                currentItem.format,
                currentItem.fullname,
                currentItem.messeges,
                currentItem.email,
                currentItem.roll,
                currentItem.content,
                currentItem.content,
                currentItem.content,
                currentItem.format,
                currentItem.content,
                currentItem.targetid,
                currentItem.fontFamily,
                currentItem.fontSize,
                currentItem.fontFormat,
                currentItem.fontColor,
                currentItem.dateformat,
                currentItem.height,
                currentItem.width,
                currentItem.content,
                currentItem.content,
                currentItem.uniqueid,
                'true'
                ) 
            }, 3000);
              
            break; 
          default:
            break;
        }
        
      }
    } else {
      console.error('Invalid data: targetid is not an array or is empty');
    }
  }

    function dragStart(e) {
      const { id, dataset, clientX, clientY } = e.target;
      onDragStart(e, e.target);
    e.dataTransfer.setData('text/plain', id);
    e.dataTransfer.setData('type', dataset.type);
    e.dataTransfer.setData('dropType','allowedType');
    e.target.style.opacity = '0.4';
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = "move";
    setDragItem(e.target);
    
    }
    function dragEnd(e) { 
      const drvWrapper = document.getElementById('pdf-wrapper')
      const foreignObject = document.getElementById('foreignObject')
      drvWrapper.style.border = 'none';
        drvWrapper.style.background = 'none';
        drvWrapper.style.opacity = "5"
        foreignObject.setAttribute('opacity','5');
      onDragEnd(e);
      if (dragItem) {
        dragItem.style.opacity = '2';
    }

    setDragItem(null);
    } 
      useEffect(() => {
       
   
      }, [draggableElements,draggableElementName]);
      function createQRCodeElement(
        signatureLeft,signatureTop,
        content ,
        Colors, 
        type,
        names,
        msg,
        mails,
        roll,
        signPng,
        paraaf,
        stamp,
        format,
        pdftile,
        formatid,
        fontfamily,
        fontsize,
        fontformat,
        fontcolor,
        dateformat,
        initialWidths,
        initialHeights,
        company,
        qrCodeDisplay,
        qrCodeBorder,
        qrCodeSize,
        qrCodeFgColor,
        qrcodebgcolor,recipienids,isOwners
        ) {

          const pdfContainers = document.getElementById('pdf-container');
          const rectX = signatureLeft - initialWidths / 2;
          const rectY = signatureTop - initialHeights / 2;
          const uniqueId = `group_${Date.now()}`
          if (!pdfContainer) {
            console.error('pdfContainer is not defined');
            return;
          }
          const existingGroup = pdfContainers.querySelector(`g[data-id="${formatid}"]`);
          if (existingGroup) {
            pdfContainers.removeChild(existingGroup);
          }
        
        const cloneRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        cloneRect.setAttribute('stroke', Colors);
        cloneRect.setAttribute('fill', Colors);
        cloneRect.setAttribute('opacity', '0.75');
        cloneRect.setAttribute('rx', '5');
        cloneRect.setAttribute('ry', '5');
        cloneRect.setAttribute('x',rectX );
        cloneRect.setAttribute('y', rectY);
        cloneRect.setAttribute('width', qrCodeSize);
        cloneRect.setAttribute('height', qrCodeSize);
        cloneRect.setAttribute('data-content', content);
        cloneRect.setAttribute('data-recipien', names);
        cloneRect.setAttribute('data-msg', msg);
        cloneRect.setAttribute('data-mail', mails);
        cloneRect.setAttribute('data-max', '1000000000');
        cloneRect.setAttribute('data-min', '-10000');
        cloneRect.setAttribute('data-format', format);
        cloneRect.setAttribute('data-roll', roll);
        cloneRect.setAttribute('data-type', type);
        cloneRect.setAttribute('data-fontColor', fontcolor);
        cloneRect.setAttribute('data-fontSize', fontsize);
        cloneRect.setAttribute('data-fontFamily', fontfamily);
        cloneRect.setAttribute('data-fontFormat', fontformat);
        cloneRect.setAttribute('data-company', company);
        cloneRect.setAttribute('data-pdftile', pdftile);
        cloneRect.setAttribute('data-textarea', content);
        cloneRect.setAttribute('data-numbers', 'en-US');
        cloneRect.setAttribute('data-fgColor', qrCodeFgColor);
        cloneRect.setAttribute('data-bgColor', qrcodebgcolor);
        cloneRect.setAttribute('data-radio', 'false');
        cloneRect.setAttribute('class', 'objectelement');
        cloneRect.setAttribute('draggable', 'true');
        cloneRect.setAttribute('data-label', Date.now());
        cloneRect.setAttribute('recipienid', recipienids);
        cloneRect.setAttribute('data-id',formatid);
        cloneRect.setAttribute('data-page', currentPage);
        cloneRect.setAttribute('data-isOwner', isOwners);
        cloneRect.id = formatid
        arrayIdRef.current = formatid;
        cloneRect.setAttribute('strokeWidth', '0.7746987951807229');
        const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        group.setAttribute('data-view-name', 'MultiSelectMarquee');
        group.setAttribute('data-view-id', 'c262');
        group.setAttribute('role', 'list');
        group.setAttribute('data-id',formatid);
        group.id = uniqueId;
        group.setAttribute('style', 'cursor: move');
        group.addEventListener('mousedown', handleMouseDownObject);
        const qrCode = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        qrCode.setAttribute('x', rectX);
        qrCode.setAttribute('y', rectY);
        qrCode.setAttribute('width', qrCodeSize); // Set the width and height as needed
        qrCode.setAttribute('height', qrCodeSize);
        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        const centerX = rectX + initialWidths / 2;
        const centerY = rectY + initialHeights / 2;
        text.setAttribute('x', centerX);
        text.setAttribute('y', centerY);
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('dominant-baseline', 'middle');
        const lines = content.split('\n');
        for (let i = 0; i < lines.length; i++) {
          const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
          tspan.setAttribute('x', centerX);
          tspan.setAttribute('dy', i === 0 ? 0 : fontsize);
          text.appendChild(tspan);
        }
        const qrCodeValue = content; // The content you want to encode in the QR code
      
        // Use qrcode.react to generate the QR code image
        const qrCodeComponent = (
          <QRCodeSVG
            value={qrCodeValue}
            size={qrCodeSize}
            fgColor={qrCodeFgColor}
            bgColor={qrcodebgcolor}
          />
        );
     const root = createRoot(qrCode);
     root.render(qrCodeComponent);
        group.appendChild(qrCode);
        group.appendChild(cloneRect);
        group.appendChild(text);
      
      pdfContainer.appendChild(group);

      }

      function createGroupBoxCheckAndRadio(
        signatureLeft,signatureTop,  content, Colors, type, names, msg, mails, roll, signPng, paraaf, stamp, format,
         formatid, fontfamily, fontsize, fontformat, fontcolor, dateformat,
        initialHeights, initialWidths, company, pdftile,recipienids,isOwners
        ) {
          let border 
         if (type === 'checkbox') {
          border = 1
         } if (type === 'radio'){
          border = 50
         }
         const pdfContainers = document.getElementById('pdf-container');
         if (!pdfContainer) {
          console.error('pdfContainer is not defined');
          return;
        }
        const existingGroup = pdfContainer.querySelector(`g[data-id="${formatid}"]`);
        if (existingGroup) {
          pdfContainer.removeChild(existingGroup);
        }
          const rectX = signatureLeft - initialWidths / 2;
          const rectY = signatureTop - initialHeights / 2;
          const uniqueId = `group_${Date.now()}`
          const groupContainerid = `groupContainer_${Date.now()}`
        const svgNS = 'http://www.w3.org/2000/svg';
        const rectContainerStyle = {
          stroke: Colors,
          fill: 'transparent',
          opacity: '0.75',
          rx: '1',
          ry: '1',
          x: rectX,
          y: rectY,
          width: initialWidths,
          height: initialHeights,
          strokeWidth: '0.7746987951807229',
        };
        // Create a group element
        const randomColor = generateRandomColor();
        const groupContainer = document.createElementNS(svgNS, 'g');
        groupContainer.id = groupContainerid;
        groupContainer.setAttribute('class', 'groupContainer');
        groupContainer.setAttribute('data-rule', 'least');
        groupContainer.setAttribute('data-condition', 'false');
        groupContainer.setAttribute('data-max', '1');
        groupContainer.setAttribute('data-min', '0');
        groupContainer.setAttribute('data-validation', '0');
        groupContainer.setAttribute('data-colors', randomColor);
        groupContainer.setAttribute('recipienid', recipienids);
        groupContainer.setAttribute('data-groupContainer', names);
        groupContainer.setAttribute('data-format', format);
        groupContainer.setAttribute('data-totalwidth', initialWidths);
        groupContainer.setAttribute('data-totalheight', initialHeights);
        groupContainer.setAttribute('data-containerX', rectX);
        groupContainer.setAttribute('data-containerY', rectY);
        groupContainer.setAttribute('data-id',formatid);
        const group = document.createElementNS(svgNS, 'g');
        group.setAttribute('data-content', 'value');
        group.setAttribute('data-checked', 'false');
        group.setAttribute('data-id',formatid);
        group.id = uniqueId;
        // Create the rectangular container
        const rectContainer = document.createElementNS(svgNS, 'rect');
        rectContainer.setAttribute('data-type', type);
        rectContainer.setAttribute('rectContainer', 'rectContainer');
        rectContainer.setAttribute('stroke-dasharray', '2.1362126245847177,4.2724252491694354');
        Object.keys(rectContainerStyle).forEach((key) => {
          rectContainer.setAttribute(key, rectContainerStyle[key]);
        });
         const rectWidth = 10;
         const rectHeight = 10;
        // Create the first checkbox
        const firstRect = document.createElementNS(svgNS, 'rect');
        firstRect.setAttribute('x', rectX + 6);
        firstRect.setAttribute('y', rectY + 6);
        firstRect.setAttribute('width', rectWidth);
        firstRect.setAttribute('height', rectHeight);
        firstRect.setAttribute('stroke', Colors);
        firstRect.setAttribute('fill', '#ffffff');
        firstRect.setAttribute('fill-opacity', '0.5');
        firstRect.setAttribute('stroke-opacity', '1');
        firstRect.setAttribute('stroke-width', '3');
        firstRect.setAttribute('rx', border);
        firstRect.setAttribute('ry', border);
        firstRect.setAttribute('data-content',content);
        firstRect.setAttribute('data-format', format);
        firstRect.setAttribute('data-checked', 'false');
        firstRect.setAttribute('data-roll', roll);
        firstRect.setAttribute('data-type',type);
        firstRect.setAttribute('class', 'objectelement');
        firstRect.setAttribute('draggable', 'true');
        firstRect.setAttribute('data-label', Date.now());
        firstRect.setAttribute('data-page', currentPage);
        firstRect.setAttribute('recipienid', recipienids);
        firstRect.setAttribute('data-recipien',names);
        firstRect.setAttribute('data-isOwner', isOwners);
        firstRect.setAttribute('data-mail', mails);
        firstRect.setAttribute('data-id',formatid);
        firstRect.setAttribute('data-groupContainerid', groupContainerid);
        firstRect.id = formatid
        firstRect.current = formatid; 
        const newRect = document.createElementNS(svgNS, 'rect');
        newRect.setAttribute('x', rectX + 5); // Adjust the 'x' coordinate as needed
        newRect.setAttribute('y', rectY + 22); // Adjust the 'y' coordinate as needed
        newRect.setAttribute('width', '12');
        newRect.setAttribute('height', '12');
        newRect.setAttribute('fill', '#2463d1');
        newRect.setAttribute('className', 'plusgroup');

          const verticalLine = document.createElementNS(svgNS, 'line');
          const verticalLineLength = 10; // Adjust the desired length of the vertical line
          const verticalLineCenterX = parseFloat(newRect.getAttribute('x')) + parseFloat(newRect.getAttribute('width')) / 2;
          const verticalLineCenterY = parseFloat(newRect.getAttribute('y')) + parseFloat(newRect.getAttribute('height')) / 2;
          
          verticalLine.setAttribute('x1', verticalLineCenterX);
          verticalLine.setAttribute('y1', verticalLineCenterY - verticalLineLength / 2);
          verticalLine.setAttribute('x2', verticalLineCenterX);
          verticalLine.setAttribute('y2', verticalLineCenterY + verticalLineLength / 2);
          verticalLine.setAttribute('stroke', 'white'); // Adjust the color as needed
          
          const horizontalLine = document.createElementNS(svgNS, 'line');
          const horizontalLineLength = 10; // Adjust the desired length of the horizontal line
          const horizontalLineCenterX = parseFloat(newRect.getAttribute('x')) + parseFloat(newRect.getAttribute('width')) / 2;
          const horizontalLineCenterY = parseFloat(newRect.getAttribute('y')) + parseFloat(newRect.getAttribute('height')) / 2;
          
          horizontalLine.setAttribute('x1', horizontalLineCenterX - horizontalLineLength / 2);
          horizontalLine.setAttribute('y1', horizontalLineCenterY);
          horizontalLine.setAttribute('x2', horizontalLineCenterX + horizontalLineLength / 2);
          horizontalLine.setAttribute('y2', horizontalLineCenterY);
          horizontalLine.setAttribute('stroke', 'white'); // Adjust the color as needed
          newRect.addEventListener('click', (evt) => handelPlusMouseDown(evt,firstRect, rectContainer,groupContainer,group,newRect,verticalLine,horizontalLine,Colors,mails,groupContainerid));
          // Create the plus lines
          firstRect.addEventListener('mousedown', (evt) => handleMouseDownCheckbox(evt,firstRect, rectContainer,groupContainer,group,newRect,verticalLine,horizontalLine));

          
        // Append all elements to the group
        groupContainer.appendChild(rectContainer);
        group.appendChild(firstRect);
        groupContainer.appendChild(newRect);
        groupContainer.appendChild(verticalLine);
        groupContainer.appendChild(horizontalLine);
        groupContainer.appendChild(group);
      
        pdfContainers.appendChild(groupContainer);

      }


      function createSignatureElement(
        signatureLeft,signatureTop,  contents, Colors, type, names, msg, mails, roll, signPng, paraaf, stamp, format,
        TextContent, formatid, fontfamily, fontsize, fontformat, fontcolor, dateformat,
        initialHeights, initialWidths, company, pdftile,recipienids,isOwners
      ) {

        if (!pdfContainer) {
          console.error('pdfContainer is not defined');
          return;
        }
        const existingGroup = pdfContainer.querySelector(`g[data-id="${formatid}"]`);
        if (existingGroup) {
          pdfContainer.removeChild(existingGroup);
        }
        isDragging = false 
        // Create and append the text element
        const  text = createText(signatureLeft,signatureTop,TextContent, fontcolor, fontsize, initialWidths, initialHeights);
        // Create a rectangle element
       
        const cloneRect = createRectangle( signatureLeft,signatureTop,  contents, Colors, type, names, msg, mails, roll, signPng, paraaf, stamp, format,
          TextContent, formatid, fontfamily, fontsize, fontformat, fontcolor, dateformat,
          initialHeights, initialWidths, company, pdftile,recipienids,isOwners);
        // Create a group element to hold the signature elements
        const group = createGroup(formatid);
        group.appendChild(cloneRect);
        
       
        group.appendChild(text);
        switch (type) {
            case 'number':
            case 'checkbox':
            case 'dropdown':
            case 'email':
            case 'fullname':
            case 'calendar':
            case 'radio':
            case 'payment':
            case 'attachment':
            break;
          default:
            createResizerCircles(group, cloneRect);
            break;
        }

        
         pdfContainer.appendChild(group);
      }

      function createRectangle( signatureLeft,signatureTop,  contents, Colors, type, names, msg, mails, roll, signPng, paraaf, stamp, format,
        TextContent, formatid, fontfamily, fontsize, fontformat, fontcolor, dateformat,
        initialHeights, initialWidths, company, pdftile,recipienids,isOwners) {

        const cloneRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        cloneRect.setAttribute('stroke', Colors);
        cloneRect.setAttribute('fill', Colors);
        cloneRect.setAttribute('opacity', '0.75');
        cloneRect.setAttribute('rx', '5');
        cloneRect.setAttribute('ry', '5');
        const rectX = signatureLeft - initialWidths / 2;
        const rectY = signatureTop - initialHeights / 2;
        cloneRect.setAttribute('x',rectX );
        cloneRect.setAttribute('y', rectY);
        cloneRect.setAttribute('width', initialWidths);
        cloneRect.setAttribute('height', initialHeights);
        cloneRect.setAttribute('data-widths', initialWidths);
        cloneRect.setAttribute('data-heights', initialHeights);
        cloneRect.setAttribute('data-content', contents);
        cloneRect.setAttribute('data-recipien', names);
        cloneRect.setAttribute('data-msg', msg);
        cloneRect.setAttribute('data-mail', mails);
        cloneRect.setAttribute('data-isOwner', isOwners);
        
        if (format === 'number') {
          cloneRect.setAttribute('data-optionnumtype', 'USD');
          cloneRect.setAttribute('data-selecttype', 'number');
          cloneRect.setAttribute('data-valutacode', 'en-US');
          cloneRect.setAttribute('data-max', '1000000000');
          cloneRect.setAttribute('data-min', '-10000');
        }
        if ( format === 'payment') {
          cloneRect.setAttribute('data-optionnumtype', 'USD');
          cloneRect.setAttribute('data-selecttype', 'currency');
          cloneRect.setAttribute('data-valutacode', 'en-US');
          cloneRect.setAttribute('data-max', '1000000000');
          cloneRect.setAttribute('data-min', '-10000');
        }
        cloneRect.setAttribute('data-format', format);
        cloneRect.setAttribute('data-roll', roll);
        cloneRect.setAttribute('data-type', type);
        cloneRect.setAttribute('data-fontColor', fontcolor);
        cloneRect.setAttribute('data-fontSize', fontsize);
        cloneRect.setAttribute('data-fontFamily', fontfamily);
        cloneRect.setAttribute('data-fontFormat', fontformat);
        cloneRect.setAttribute('data-company', company);
        cloneRect.setAttribute('data-pdftile', pdftile);
        cloneRect.setAttribute('data-textarea', contents);
        cloneRect.setAttribute('data-dataFormat', dateformat);
        cloneRect.setAttribute('data-radio', 'false');
        cloneRect.setAttribute('class', 'objectelement');
        cloneRect.setAttribute('draggable', 'true');
        cloneRect.setAttribute('data-label', Date.now())
        cloneRect.setAttribute('recipienid', recipienids);
        cloneRect.setAttribute('data-page', currentPage);
        cloneRect.id = formatid
        arrayIdRef.current = formatid;
        cloneRect.setAttribute('strokeWidth', '0.7746987951807229');
        return cloneRect;
      }
      
      function createGroup(formatid) {
        // Create and configure a group element
        const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        group.setAttribute('data-view-name', 'MultiSelectMarquee');
        group.setAttribute('data-view-id', 'c262');
        group.setAttribute('role', 'list');
        const uniqueId = `group_${Date.now()}`
        group.id = uniqueId;
        group.setAttribute('data-id', formatid);
        group.setAttribute('style', 'cursor: move');
        group.addEventListener('mousedown', handleMouseDownObject);
        return group;
      }

      function createText(signatureLeft,signatureTop,TextContent, fontcolor, fontsize, width, height) {

      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      const rectX = signatureLeft - width / 2;
      const rectY = signatureTop - height / 2;
      const centerX = rectX + width / 2;
      const centerY = rectY + height / 2;
      text.setAttribute('x', centerX);
      text.setAttribute('y', centerY);
      text.setAttribute('fill', fontcolor);
      text.setAttribute('font-size', fontsize);
      text.setAttribute('text-anchor', 'middle');
      text.setAttribute('dominant-baseline', 'middle');
      const lines = TextContent.split('\n');
      for (let i = 0; i < lines.length; i++) {
        const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        tspan.setAttribute('x', centerX);
        tspan.setAttribute('dy', i === 0 ? 0 : fontsize);
        tspan.textContent = lines[i];
        text.appendChild(tspan);
      }
      // ... (configure other text attributes)
      return text;

          
      }
   
      function createResizerCircles(group, cloneRect) {
        const resizerClasses = ['topleft', 'topright', 'bottomleft', 'bottomright'];
        for (let i = 0; i < 4; i++) {
          const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
          circle.setAttribute('stroke', '#0241af');
          circle.setAttribute('fill', '#2463d1');
          circle.setAttribute('opacity', '1');
          circle.setAttribute('style', 'cursor: nwse-resize');
          circle.setAttribute('r', '2.7114457831325303');
          circle.setAttribute('strokeWidth', '1.5493975903614459');
          circle.setAttribute('className', `${resizerClasses[i]}`);
          // Position the resize handles based on the group's position and dimensions
          const groupX = parseFloat(cloneRect.getAttribute('x'));
          const groupY = parseFloat(cloneRect.getAttribute('y'));
          const groupWidth = parseFloat(cloneRect.getAttribute('width'));
          const groupHeight = parseFloat(cloneRect.getAttribute('height'));
        
          // Calculate the positions of the resize handles
          switch (i) {
            case 0: // Top-left corner
              circle.setAttribute('cx', groupX);
              circle.setAttribute('cy', groupY);
              break;
            case 1: // Top-right corner
              circle.setAttribute('cx', groupX + groupWidth);
              circle.setAttribute('cy', groupY);
              break;
            case 2: // Bottom-left corner
              circle.setAttribute('cx', groupX);
              circle.setAttribute('cy', groupY + groupHeight);
              break;
            case 3: // Bottom-right corner
              circle.setAttribute('cx', groupX + groupWidth);
              circle.setAttribute('cy', groupY + groupHeight);
              break;
            default:
              break;
          }
          group.appendChild(circle);
          resizers.push(circle);
          circle.addEventListener('mousedown', (evt) => handleResizeMouseDown(evt, i, cloneRect,group));
        }
      }
      const handleDrop = (e) => {
        e.preventDefault();
        const drvWrapper = document.getElementById('pdf-wrapper')
        const foreignObject = document.getElementById('foreignObject')
        const isDropTargetValid =
        (e.target && e.target.id === 'pdf-container') ||
        (e.target && e.target.parentNode && e.target.parentNode.id === 'pdf-container') ||
        (e.target && e.target.id === 'pdf-img') ||
        (e.target && e.target.parentNode && e.target.parentNode.id === 'pdf-img') ||
        (e.target && e.target.id === 'pdf-wrapper') ||
        (e.target && e.target.parentNode && e.target.parentNode.id === 'pdf-wrapper') ||
        (e.target && e.target.id === 'foreignObject') ||
        (e.target && e.target.parentNode && e.target.parentNode.id === 'foreignObject');
      
        if (isDropTargetValid) {
          // Add your drop logic here
          currentPage = e.target.getAttribute('page')
          const data = e.dataTransfer.getData('text/plain');
        

          drvWrapper.style.border = 'none';
          drvWrapper.style.background = 'none';
          drvWrapper.style.opacity = "5"
          foreignObject.setAttribute('opacity','5');
          const eTarget =  dragItem;
          const obj = eTarget.id;
          var names= eTarget.dataset.name;
          var msg= eTarget.dataset.msg;
          var mails= eTarget.dataset.mail
          var roll= eTarget.dataset.roll
          var signPng= eTarget.dataset.signpng
          var paraaf= eTarget.dataset.paraaf
          var stamp= eTarget.dataset.stamp
          var Colors= eTarget.dataset.colors
          var format= eTarget.dataset.format
          var formatid= eTarget.dataset.formatid
          var isOwners= eTarget.dataset.isowner
          const recipienids= recipienid[0]
          let type= '';
          
          const selects = 'select ▼'
          const fontfamily = 'helveticaFont'
          const fontsize = 12
          const fontformat = 'normal'
          const fontcolor = 'black'
          const dateformat = 'Y-m-d'
          const company = t('Company')
          const pdftile = t('Title')
          const pdfNumbers = '00.00';
          const payments= '€ 000.00 EUR';
          const attachment = '⇩'
          const formula = t('Formula')
          const drawing = t('Drawing')
          const approve= t('Approve')
          const decline= t('Decline')
          const qrcodevalue = 'www.digitalsignonline.com';
          const qrcodesize = 50;
          const qrcodebgcolor = '#000000';
          const qrcodefgcolor = '#FFFFFF';
          const qrcodedisplay = "2";
          const qrcodeborder = "1px solid #ccc";
          setDraggableType({ 
            targetid: formatid, 
            format: format,
            fontfamily:fontfamily,
            fontsize:fontsize,
            fontformat:fontformat,
            fontcolor:fontcolor,
            dateformat:dateformat,
            signpng:signPng,
            paraaf:paraaf,
            stamp:stamp,
            roll:roll,
            qrcodevalue:qrcodevalue,
            qrcodedisplay:qrcodedisplay,
            qrcodeborder:qrcodeborder,
            qrcodesize:qrcodesize,
            qrcodefgcolor:qrcodefgcolor,
            qrcodebgcolor:qrcodebgcolor,
          });
          setDraggableNameType({
             targetid: formatid, 
             format: format,
            });
          const { clientX, clientY } = e;
              // Create circles selectedZoom
          const containerRect = pdfContainer.getBoundingClientRect();
          const signatureLeft = (clientX - containerRect.left) / selectedZoom;
          const signatureTop = (clientY - containerRect.top) / selectedZoom;
          var currentDate = new Date(); 
          var year = currentDate.getFullYear();
          var month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
          var day = currentDate.getDate();
          var SigningDate =  day+'-'+month+'-'+year;
           let width
           let height
          switch (obj) { 
          case "handwriteing":
          type= 'handwriteing'
          createSignatureElement(signatureLeft,signatureTop,signPng,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,t('Signature'),formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'50','75',company,pdftile,recipienids,isOwners);
          break;
          case "hand_praaf":
          type= 'hand_praaf'
          createSignatureElement(signatureLeft,signatureTop,paraaf,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,t('Initial'),formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'50','75',company,pdftile,recipienids,isOwners);
          break;
          case "stemps":
          type= 'stemps'
          createSignatureElement(signatureLeft,signatureTop,stamp,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,t('Stamp'),formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'50','75',company,pdftile,recipienids,isOwners);
          break;
          case "calendar":
          type= 'calendar'
          createSignatureElement(signatureLeft,signatureTop,SigningDate,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,SigningDate,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'27','73',company,pdftile,recipienids,isOwners);
          break;
          case "qrcode":
            type= 'qrcode'
            createQRCodeElement(
              signatureLeft,signatureTop,
              qrcodevalue,
              '#ffffff',
              type,
              names,
              msg,
              mails,
              roll,
              signPng,
              paraaf,
              stamp,
              format,
              pdftile,
              formatid,
              fontfamily,
              fontsize,
              fontformat,
              fontcolor,
              dateformat,
              '100',
              '100',
              company,
              qrcodedisplay,
              qrcodeborder,
              qrcodesize,
              qrcodefgcolor,
              qrcodebgcolor,recipienids,isOwners
              );
           
          break;
          case "fullname":
            type= 'fullname'
            width =  names.length *0.6*12
            height = 1.2 * 12 * 1.8
            createSignatureElement(signatureLeft,signatureTop,names,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,names,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,height,width,company,pdftile,recipienids,isOwners);
            break;
            case "dragmove_email":
            type= 'email'
            createSignatureElement(signatureLeft,signatureTop,mails,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,t('Email'),formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'27','43',company,pdftile,recipienids,isOwners);
            break;
            case "company":
              width =  company.length *0.6*12+10
              height = 1.2 * 12 * 1.8
            type= 'company'
            createSignatureElement(signatureLeft,signatureTop,company,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,t('Company'),formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,height,width,company,pdftile,recipienids,isOwners);
            break;
            case "title":
              width =  pdftile.length *0.6*12
              height = 1.2 * 12 * 1.8
            type= 'title'
            createSignatureElement(signatureLeft,signatureTop,pdftile,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,t('Title'),formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,height,width,company,pdftile,recipienids,isOwners);
            break;
            case "textarea":
              width =  pdfTextArea.length *0.6*12
              height = 1.2 * 12 * 1.8
            type= 'textarea'
            createSignatureElement(signatureLeft,signatureTop,pdfTextArea,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,pdfTextArea,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,height,width,company,pdftile,recipienids,isOwners); 
            break;
            case "number":
              width =  pdfNumbers.length *0.6*12
              height = 1.2 * 12 * 1.8
            type= 'number'
            createSignatureElement(signatureLeft,signatureTop,pdfNumbers,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,pdfNumbers,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,height,width,company,pdftile,recipienids,isOwners);
            break;
            case "checkbox":
            type= 'checkbox'
             createGroupBoxCheckAndRadio(signatureLeft,signatureTop,'value',Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'20','22',company,pdftile,recipienids,isOwners);
     
            break;
            case "radio":
              type= 'radio'
             createGroupBoxCheckAndRadio(signatureLeft,signatureTop,'value',Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'25','25',company,pdftile,recipienids,isOwners);
         
              break;
            case "dropdown":
              type= 'dropdown'
              createSignatureElement(signatureLeft,signatureTop,selects,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,selects,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'25','70',company,pdftile,recipienids,isOwners);
              break;
            case "payment":
              type= 'payment'
              createSignatureElement(signatureLeft,signatureTop,payments,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,payments,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'27','88',company,pdftile,recipienids,isOwners);
              break;
            case "drawing":
              type= 'drawing'
              createSignatureElement(signatureLeft,signatureTop,drawing,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,drawing,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'25','70',company,pdftile,recipienids,isOwners);
              break;
            case "formula":
              type= 'formula'
              createSignatureElement(signatureLeft,signatureTop,formula,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,formula,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'25','70',company,pdftile,recipienids,isOwners);
              break;
            case "attachment":
              type= 'attachment'
              createSignatureElement(signatureLeft,signatureTop,attachment,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,attachment,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'67','40',company,pdftile,recipienids,isOwners);
              break;
            case "approve":
              type= 'approve'
              createSignatureElement(signatureLeft,signatureTop,approve,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,approve,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'27','61',company,pdftile,recipienids,isOwners);
              break;
            case "decline":
              type= 'decline'
              createSignatureElement(signatureLeft,signatureTop,decline,Colors,type,names,msg,mails,roll,signPng,paraaf,stamp,format,decline,formatid,fontfamily,fontsize,fontformat,fontcolor,dateformat,'27','61',company,pdftile,recipienids,isOwners);
              break;
          default:
          break;
          }
         
        } else {
          // The drop is not allowed outside the SVG element
          console.log('Drop not allowed outside the SVG element');
        
        }
      };
      useEffect(() => {
        const handleDragOver = (e) => {
          e.preventDefault();
          const drvWrapper = document.getElementById('pdf-wrapper')
          const foreignObject = document.getElementById('foreignObject')
          const isDropInArea =
          (e.target && e.target.id === 'pdf-container') ||
          (e.target && e.target.parentNode && e.target.parentNode.id === 'pdf-container') ||
          (e.target && e.target.id === 'pdf-img') ||
          (e.target && e.target.parentNode && e.target.parentNode.id === 'pdf-img') ||
          (e.target && e.target.id === 'pdf-wrapper') ||
          (e.target && e.target.parentNode && e.target.parentNode.id === 'pdf-wrapper') ||
          (e.target && e.target.id === 'foreignObject') ||
          (e.target && e.target.parentNode && e.target.parentNode.id === 'foreignObject');
          if (isDropInArea) {
            foreignObject.setAttribute('opacity','0.2')
            drvWrapper.style.border = '2px dashed #27ae60';
            drvWrapper.style.background = '#b1ecce';
            drvWrapper.style.opacity='0.5';
        
            
        } else {
        
          drvWrapper.style.border = '2px dashed #3498db';
          drvWrapper.style.background = 'none';
          drvWrapper.style.opacity = "0.2"
        }
        };
        document.addEventListener('dragover', handleDragOver);
        document.addEventListener('drop', handleDrop);
      
        // Clean up the event listeners when the component unmounts
        return () => {
          document.removeEventListener('dragover', handleDragOver);
          document.removeEventListener('drop', handleDrop);
        };
      }, [dragItem]);
          
      const handleResizeMouseDown = (evt, direction, rectangle,group) => {
        evt.preventDefault();
        resizngElement = {
          element: rectangle,
          direction,
          group,
          initialX: evt.clientX,
          initialY: evt.clientY,
          initialWidth: parseFloat(rectangle.getAttribute('width')),
          initialHeight: parseFloat(rectangle.getAttribute('height')),
        };
        document.addEventListener('mousemove', handleResizeMouseMove);
        document.addEventListener('mouseup', handleResizeMouseUp); 
      };
   
      const handleResizeMouseMove = (evt) => {
        evt.preventDefault();
        if (!resizngElement) return;
        const { element, direction, group,initialX, initialY, initialWidth, initialHeight } = resizngElement;
         
        const textToUpdate = group.querySelector('text');
        if (!group || !textToUpdate) {
          console.error('Group or Text element is not found.');
          return;
        }
        const data_type = selectedElement.getAttribute('data-type');
        switch (data_type) {
            case 'textarea':
          textareaFunction();

          break;
          default:
            const deltaX = evt.clientX - initialX;
            const deltaY = evt.clientY - initialY;
            let newWidth = initialWidth;
            let newHeight = initialHeight;
            switch (direction) {
              case RESIZE_TOP_LEFT: // Top-left handle
                newWidth -= deltaX;
                newHeight -= deltaY;
                element.setAttribute('x', initialX + deltaX);
                element.setAttribute('y', initialY + deltaY);
                break;
              case RESIZE_TOP_RIGHT: // Top-right handle
                newWidth += deltaX;
                newHeight -= deltaY;
                element.setAttribute('y', initialY + deltaY);
                break;
              case RESIZE_BOTTOM_LEFT: // Bottom-left handle
              newWidth -= deltaX;
              newHeight += deltaY;
                element.setAttribute('x', initialX + deltaX);
                break;
              case RESIZE_BOTTOM_RIGHT: // Bottom-right handle
                newWidth += deltaX;
                newHeight += deltaY;
                break;
              default:
                break;
            }
    
            newWidth = Math.max(minWidth, newWidth);
            newHeight = Math.max(minHeight, newHeight);
            element.setAttribute('width', newWidth);
            element.setAttribute('height', newHeight);
            // Handle the default case if needed
        }
       function  textareaFunction(){
        if (!element) {
          console.error('Element is not defined.');
          return;
        }
        const deltaX = evt.clientX - initialX;
        const deltaY = evt.clientY - initialY;
    
        const tspans = textToUpdate.getElementsByTagName('tspan');
        const fontSize = element.getAttribute('data-fontSize')
        const fontColor = element.getAttribute('data-fontColor')
        const fontFamily = element.getAttribute('data-fontFamily')
        const newTextContent = element.getAttribute('data-content')
        const fontFormat = element.getAttribute('data-fontFormat');
        if (!newTextContent || newTextContent.trim() === '') {
          console.error('Text content is empty.');
          return;
        }
      
        let newWidth = initialWidth;
            let newHeight = initialHeight;
        const newletterWidth = getLetterWidth(textToUpdate, fontSize)
        switch (direction) {
          case RESIZE_TOP_LEFT: // Top-left handle
            newWidth -= deltaX;
            newHeight -= deltaY;
            element.setAttribute('x', initialX + deltaX);
            element.setAttribute('y', initialY + deltaY);
            break;
          case RESIZE_TOP_RIGHT: // Top-right handle
          newWidth += deltaX;
          newHeight -= deltaY;
          element.setAttribute('y', initialY + deltaY);
            break;
          case RESIZE_BOTTOM_LEFT: // Bottom-left handle
            newWidth -= deltaX;
            newHeight += deltaY;
            element.setAttribute('x', initialX + deltaX);
            break;
          case RESIZE_BOTTOM_RIGHT: // Bottom-right handle
            newWidth += deltaX;
            newHeight += deltaY;
            break;
          default:
            break;
        }
  
        const textCount = Math.floor(newWidth / newletterWidth); 
        
        
          const str = splitTextByChunk(newTextContent, textCount)
        while (textToUpdate.firstChild) {
          textToUpdate.removeChild(textToUpdate.firstChild);
        }
        for (let i = 0; i < str.length; i++) {
          const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
          tspan.textContent = str[i];
          const textContent = tspan.textContent;
          tspan.setAttribute('dy', fontSize * i);
          tspan.setAttribute('fill', fontColor);
          tspan.setAttribute('font-size', fontSize);
          tspan.setAttribute('font-family', fontFamily);
          tspan.style.fontStyle = fontFormat === 'italic' ? 'italic' : 'normal';
          tspan.style.textDecoration = fontFormat === 'underline' ? 'underline' : 'none';
          tspan.style.fontWeight = fontFormat === 'bold' ? 'bold' : 'normal';
          textToUpdate.appendChild(tspan);
         
        }
        const textWidth = textToUpdate.getBBox().width;
        const textHeight = textToUpdate.getBBox().height;
      // newWidth = Math.max(initialWidth, newWidth);
      // newHeight = Math.max(initialHeight, newHeight);
          newWidth = Math.max(20, Math.min(5000, newWidth));
        newHeight = Math.max(20, Math.min(1000, newHeight));
        element.setAttribute('width', newWidth);
        element.setAttribute('height', newHeight);
        function getLetterWidth(tempText, fontSize) {
          
          if (fontSize <= 0) {
            console.error('Font size must be greater than zero.');
            return 0;
          }
          // Check for valid text content
          if (!tempText.textContent) {
            console.error('Text content is empty.2222');
            return 0;
          }  
          let letterWidth = 0;
          try {
            letterWidth = tempText.getSubStringLength(0, 1);
          } catch (error) {
            console.error('Error while measuring letter width:', error);
          }
          
          return letterWidth;
        }
        function splitTextByChunk(str, textCount) {
          if (!str || textCount <= 0) {
            return ['value'];
          }
         // count = 12
          const pattern = new RegExp(`.{1,${textCount}}`, 'g');
          const chunks = str.match(pattern) || [];
          return chunks;
        }
       }
      };
      const handleResizeMouseUp = () => {
        resizngElement = null;
        document.removeEventListener('mousemove', handleResizeMouseMove);
        document.removeEventListener('mouseup', handleResizeMouseUp);
        selectedElement = null;
        selectedCheckbox = null;
        document.removeEventListener('mousemove', handleMouseMove);
    
      };
  function createCheckBox(evt,firstRect, rectContainer,groupContainer,group,newRect,verticalLine,horizontalLine,Colors,mails,groupContainerid){
    const { clientX, clientY } = evt;
    const x = parseFloat(newRect.getAttribute("x"));
    const y = parseFloat(newRect.getAttribute("y"));
    const rectWidth = 10;
    const rectHeight = 10;
    const uniqueId = `group_${Date.now()}`
  const svgNS = 'http://www.w3.org/2000/svg';
  const groups = document.createElementNS(svgNS, 'g');
  groups.setAttribute('data-content', 'value');
  groups.setAttribute('data-checked', 'false');
  groups.id = uniqueId;
  let border 
           if (firstRect.dataset.type === 'checkbox') {
            border = 1
           } if (firstRect.dataset.type === 'radio'){
            border = 50
           }
  // Create the first checkbox
  const recipienids = firstRect.getAttribute('recipienid')
  const seciondRect = document.createElementNS(svgNS, 'rect');
  seciondRect.setAttribute('x', x );
  seciondRect.setAttribute('y', y );
  seciondRect.setAttribute('width', rectWidth);
  seciondRect.setAttribute('height', rectHeight);
  seciondRect.setAttribute('data-mail', mails)
  seciondRect.setAttribute('stroke', Colors);
  seciondRect.setAttribute('fill', '#ffffff');
  seciondRect.setAttribute('fill-opacity', '0.5');
  seciondRect.setAttribute('stroke-opacity', '1');
  seciondRect.setAttribute('stroke-width', '3');
  seciondRect.setAttribute('rx', border);
  seciondRect.setAttribute('ry', border);
  seciondRect.setAttribute('data-content', 'value');
  seciondRect.setAttribute('data-checked', 'false');
  seciondRect.setAttribute('data-format', firstRect.dataset.format);
  seciondRect.setAttribute('data-roll', firstRect.dataset.roll);
  seciondRect.setAttribute('data-type', firstRect.dataset.type);
  seciondRect.setAttribute('data-recipien', firstRect.dataset.recipien);
  seciondRect.setAttribute('recipienid', recipienids);
  seciondRect.setAttribute('class', 'objectelement');
  seciondRect.setAttribute('data-label', Date.now());
  seciondRect.setAttribute('data-page', currentPage);
  seciondRect.setAttribute('data-groupContainerid', groupContainerid);
  seciondRect.setAttribute('data-isOwner', firstRect.dataset.isOwner);
  const uniqurid = `drag_${Date.now()}`;
  seciondRect.id = uniqurid
  seciondRect.current = uniqurid;
   // Append all elements to the group
   seciondRect.addEventListener('mousedown', (evt) => handleMouseDownCheckbox(evt, seciondRect, rectContainer,groupContainer,groups,newRect,verticalLine,horizontalLine));
   groups.appendChild(seciondRect);
   return groups
  }
const handelPlusMouseDown = (evt,firstRect, rectContainer,groupContainer,group,newRect,verticalLine,horizontalLine,Colors,mails,groupContainerid) => {
  evt.preventDefault(); 
  if ( groupContainer) {
    groupContainer.appendChild(createCheckBox(evt,firstRect, rectContainer,groupContainer,group,newRect,verticalLine,horizontalLine,Colors,mails,groupContainerid));
  }
}

      const handleMouseDownCheckbox = (evt, firstRect, rectContainer,groupContainer,group,newRect,verticalLine,horizontalLine) => {
      
        evt.preventDefault(); 

        if (firstRect && rectContainer) {
        const targetid = firstRect.id;
        const format = firstRect.dataset.format;
       
          setDraggableNameType({
            targetid: targetid, 
            format: format,
        });
        
        selectedCheckbox = {
              element: firstRect,
              rectContainer,
              groupContainer,
              group,
              newRect,
              verticalLine,
              horizontalLine,
              initialX: evt.clientX - parseFloat(firstRect.getAttribute("x")),
              initialY: evt.clientY - parseFloat(firstRect.getAttribute("y")),
            };
            removeButton.style.background = '#d12424';
            removeBtn.disabled = false;
            deleteIdRef.current = group.id;
            isDragging = true;
            resizing= true;
           document.addEventListener('mousemove', handleMouseMoveCheckbox);
            document.addEventListener('mouseup', handleMouseUpCheck);
         
        }
      };
      deleteContainer()
      function deleteContainer() {
        const groupContainers = document.querySelectorAll('.groupContainer');
        groupContainers.forEach(groupContainer => {
          const groups = groupContainer.querySelectorAll('g');
      
          // Check if there are no <g> elements inside groupContainer
          if (groups.length === 0) {
            // If no <g> elements, delete the groupContainer
            groupContainer.parentNode.removeChild(groupContainer);
          }
        });
      }
      
      function handleMouseUpCheck() {
        
        isDragging = false;
        resizing= false
        selectedElement = null;
        resizngElement = null;
        selectedCheckbox = null;
        document.removeEventListener('mousemove', handleMouseMoveCheckbox);
        document.removeEventListener('mouseup', handleMouseUpCheck);
      }
      const handleMouseMoveCheckbox = (evt) => {
    
        if (!selectedCheckbox && isDragging) return;
        let {
          element,
          rectContainer,
          groupContainer,
          group,
          newRect,
          verticalLine,
          horizontalLine,
          initialX,
          initialY,
        } = selectedCheckbox;
        const dropZones = document.querySelector('div#dropZones')
        const gElement  = groupContainer.querySelectorAll('g');
        let deltaX = evt.clientX - initialX;
        let deltaY = evt.clientY - initialY;
        // if (dropZones) {
        //   deltaX = Math.max(0, Math.min(deltaX, dropZones.clientWidth - element.clientWidth));
          
        //  // Ensure y is within the container's boundaries
        //  deltaY = Math.max(0, Math.min(deltaY, dropZones.clientHeight - element.clientHeight));
         
        // }
        element.setAttribute('x',deltaX);
         element.setAttribute('y', deltaY);
        const rects = [];
        const paddingX = 5;
        const paddingY = 5;
        gElement.forEach(g => {
          const rect = g.querySelector('rect');
          if (rect) {
            const x = parseFloat(rect.getAttribute('x')) || 0;
            const y = parseFloat(rect.getAttribute('y')) || 0;
            const width = parseFloat(rect.getAttribute('width')) || 0;
            const height = parseFloat(rect.getAttribute('height')) || 0;
        
            const rectObject = { x, y, width, height };
            rects.push(rectObject);
          }
      });
      
    let containerX = rects[0] ? rects[0].x - paddingX : 0;
    let containerY = rects[0] ? rects[0].y - paddingY : 0;
    
    // Initialize container dimensions with the first rectangle's dimensions and padding
    let containerMaxX = rects[0] ? rects[0].x + rects[0].width + paddingX : 0;
    let containerMaxY = rects[0] ? rects[0].y + rects[0].height + paddingY : 0; 
    for (let i = 1; i < rects.length; i++) {
      const rect = rects[i];
      containerX = Math.min(containerX, rect.x - paddingX);
      containerY = Math.min(containerY, rect.y - paddingY);
      containerMaxX = Math.max(containerMaxX, rect.x + rect.width + paddingX);
      containerMaxY = Math.max(containerMaxY, rect.y + rect.height + paddingY);
    }
    let containerWidth = containerMaxX - containerX;
    let containerHeight = containerMaxY - containerY;
    groupContainer.setAttribute('data-totalwidth', containerWidth);
    groupContainer.setAttribute('data-totalheight', containerHeight);
    groupContainer.setAttribute('data-containerX', containerX);
    groupContainer.setAttribute('data-containerY', containerY);
rectContainer.setAttribute('width', containerWidth );
rectContainer.setAttribute('height', containerHeight );
rectContainer.setAttribute('x',containerX );
rectContainer.setAttribute('y', containerY);
const rectContainerCenterX = containerX + containerWidth / 2;
const newRectX = rectContainerCenterX - parseFloat(newRect.getAttribute('width')) / 2;

const newRectY = containerMaxY;
newRect.setAttribute('x', newRectX);
newRect.setAttribute('y', newRectY);
 const verticalLineCenterX = parseFloat(newRect.getAttribute('x')) + parseFloat(newRect.getAttribute('width')) / 2;
 const verticalLineCenterY = parseFloat(newRect.getAttribute('y')) + parseFloat(newRect.getAttribute('height')) / 2;
 const verticalLineLength = 10;
  verticalLine.setAttribute('x1', verticalLineCenterX);
   verticalLine.setAttribute('y1', verticalLineCenterY - verticalLineLength / 2);
   verticalLine.setAttribute('x2', verticalLineCenterX);
   verticalLine.setAttribute('y2', verticalLineCenterY + verticalLineLength / 2);
   const horizontalLineCenterX = parseFloat(newRect.getAttribute('x')) + parseFloat(newRect.getAttribute('width')) / 2;
   const horizontalLineCenterY = parseFloat(newRect.getAttribute('y')) + parseFloat(newRect.getAttribute('height')) / 2;
   const horizontalLineLength = 10;
   horizontalLine.setAttribute('x1', horizontalLineCenterX - horizontalLineLength / 2);
   horizontalLine.setAttribute('y1', horizontalLineCenterY);
   horizontalLine.setAttribute('x2', horizontalLineCenterX + horizontalLineLength / 2);
   horizontalLine.setAttribute('y2', horizontalLineCenterY);
      }

      const handleMouseDownObject = (evt) => {
        evt.preventDefault();
        const target = evt.target;
        const group = target.closest('g');
         selectedElement = group.querySelector('rect');

         const fontsize = selectedElement.dataset.fontSize;
        const targetid = selectedElement.id;
        const format = selectedElement.dataset.format;
        const signContent = selectedElement.dataset.content;
        const mails = selectedElement.dataset.mail; 
         const names = selectedElement.dataset.recipien; 
         const paraaf = selectedElement.dataset.paraaf; 
         const stamp = selectedElement.dataset.stamp; 
         const company = selectedElement.dataset.company; 
         const pdftile = selectedElement.dataset.pdftile;
         const roll = selectedElement.dataset.roll;
         const pdfTextArea = selectedElement.dataset.textarea;
         const pdfNumbers = selectedElement.dataset.numbers; 
         const radios = selectedElement.dataset.radio; 
         const dateformat = selectedElement.dataset.dataFormat
         ;
        const fontfamily = 'helveticaFont'
        const fontformat = 'normal'
        const fontcolor = 'black'
       
        const qrcodevalue = "www.digitalsignonline.com";
        const qrcodedisplay = "2";
        const qrcodeborder = "1px solid #ccc";
        const qrcodesize = "50";
        const qrcodebgcolor = '#000000';
        const qrcodefgcolor = '#FFFFFF';
        const textW = selectedElement.dataset.widths;
        const textH = selectedElement.dataset.heights;
        setDraggableType({ 
          targetid: targetid, 
          format: format,
          fontfamily:fontfamily,
          fontsize:fontsize,
          fontformat:fontformat,
          fontcolor:fontcolor,
          dateformat:dateformat,
          signpng:signContent,
          paraaf:paraaf,
          stamp:stamp,
          roll:roll,
          qrcodevalue:qrcodevalue,
          qrcodedisplay:qrcodedisplay,
          qrcodeborder:qrcodeborder,
          qrcodesize:qrcodesize,
          qrcodefgcolor:qrcodefgcolor,
          qrcodebgcolor:qrcodebgcolor,
        });
          setDraggableNameType({
            targetid: targetid, 
            format: format,
        })
       
        if (selectedElement) {
          selectedElement = selectedElement;
          removeButton.style.background = '#d12424';
          removeBtn.disabled = false;
          startX = evt.clientX - parseFloat(selectedElement.getAttribute("x"));
          startY = evt.clientY - parseFloat(selectedElement.getAttribute("y"));
          isDragging = true;
          deleteIdRef.current = group.id;
          arrayIdRef.current = targetid;
          document.addEventListener('mousemove', handleMouseMove);
        }
      
      }

      const handleMouseMove = (evt) => {
        
        evt.preventDefault();
        if (isDragging && selectedElement !== null) {
          evt.preventDefault();
          let x = evt.clientX - startX;
          let y = evt.clientY - startY; 
          if (selectedElement) {
            selectedElement.setAttribute("x", x);
            selectedElement.setAttribute("y", y);
            const totalPageP = pdfContainer.dataset.total
            const svgContainerHeight = pdfContainer.getBoundingClientRect().height;
            const currentPageNumber = Math.floor(y / (svgContainerHeight / totalPageP)) + 1;
            selectedElement.setAttribute('data-page', currentPageNumber);
            const data_type = selectedElement.getAttribute('data-type');
            const width = parseFloat(selectedElement.getAttribute('width'));
            const height = parseFloat(selectedElement.getAttribute('height'));
            const group = selectedElement.closest('g');
            const textToUpdate = group.querySelector('text');
            const tspans = textToUpdate.getElementsByTagName('tspan');
            const foreignObject = group.querySelector('foreignObject');
            if (foreignObject) {
              foreignObject.setAttribute("x", x);
              foreignObject.setAttribute("y", y);
            }
  
            const circles = group.querySelectorAll('circle');
            const fontColor = selectedElement.getAttribute('data-fontColor'); 
            const fontSize = selectedElement.getAttribute('data-fontSize'); 
            const fontFamily = selectedElement.getAttribute('data-fontFamily'); 
            const newTextContent = selectedElement.getAttribute('data-content');
            const fontFormat = selectedElement.getAttribute('data-fontFormat');
            const numLines = tspans.length;
            const lineHeight = parseFloat(tspans[0].getAttribute('dy'));
            const textHeight = numLines * lineHeight;
            const centerX = x + width / 2;
            const centerY = y + height / 2;
            const startYs = centerY - textHeight / 2;
            const padding = 10
            let currentY = startYs;
            switch (data_type) {
              case 'number':
              case 'checkbox':
              case 'dropdown':
              case 'email':
              case 'fullname':
              case 'calendar':
              case 'radio':
              case 'payment':
              case 'attachment':
              case 'qrcode':
              movingNoCircles()
                break;
                case 'textarea':
                  
              movingWithCircles()
              break;
             case 'title':
             case 'company':
             case 'stemps':
             case 'hand_praaf':
             case 'handwriteing':
             case 'drawing':
             case 'formula':
             case 'approve':
             case 'decline':
              movingWithCirclesTwo()
             break;
              default:
                // Handle the default case if needed
            }
          function  movingNoCircles(){
            const textWidth = textToUpdate.getBBox().width;
            const textHeight = textToUpdate.getBBox().height;
            // selectedElement.setAttribute('width', textWidth + padding);
            // selectedElement.setAttribute('height', textHeight + padding);
            for (let i = 0; i < tspans.length; i++) {
              tspans[i].setAttribute('x', centerX);
              tspans[i].setAttribute('y', currentY);
              currentY += lineHeight;
            }
            textToUpdate.setAttribute('text-anchor', 'middle'); 
            textToUpdate.setAttribute('dominant-baseline', 'middle');
            circles.forEach(circle => {
              group.removeChild(circle);
            });
            }
            function  movingWithCirclesTwo(){
              for (let i = 0; i < tspans.length; i++) {
                tspans[i].setAttribute('x', centerX);
                tspans[i].setAttribute('y', centerY);
                tspans[i].setAttribute('dy', fontSize * i);
              }
              textToUpdate.setAttribute('text-anchor', 'middle'); 
              textToUpdate.setAttribute('dominant-baseline', 'middle');
               // Top-left corner
               circles[0].setAttribute('cx', x);
               circles[0].setAttribute('cy', y);
           
               // Top-right corner
               circles[1].setAttribute('cx', x + width);
               circles[1].setAttribute('cy', y);
           
               // Bottom-left corner
               circles[2].setAttribute('cx', x);
               circles[2].setAttribute('cy', y + height);
           
               // Bottom-right corner
               circles[3].setAttribute('cx', x + width);
               circles[3].setAttribute('cy', y + height);
              }
            function  movingWithCircles(){
              for (let i = 0; i < tspans.length; i++) {
                tspans[i].setAttribute('x', centerX);
                tspans[i].setAttribute('y', y + 12);
                tspans[i].setAttribute('dy', fontSize * i);
              }
            
               // Top-left corner
               circles[0].setAttribute('cx', x);
               circles[0].setAttribute('cy', y);
           
               // Top-right corner
               circles[1].setAttribute('cx', x + width);
               circles[1].setAttribute('cy', y);
           
               // Bottom-left corner
               circles[2].setAttribute('cx', x);
               circles[2].setAttribute('cy', y + height);
           
               // Bottom-right corner
               circles[3].setAttribute('cx', x + width);
               circles[3].setAttribute('cy', y + height);
              }
          } else{
            console.error("Selected element is null or undefined.");
          }
        
          // Update the positions of the four corner resize handles (circles)
        }
      }
      const handleMouseUp = () => {
        isDragging = false;
        selectedElement = null;
        resizngElement = null;
        document.removeEventListener('mousemove', handleResizeMouseMove);
        // Remove the mousemove event listener from the document
        document.removeEventListener('mousemove', handleMouseMove);
      }

      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mouseup', handleResizeMouseUp);
      document.removeEventListener('mouseup', handleMouseUpCheck);

const removeBtn = document.getElementById('removeIcons');
if (removeBtn) {
  removeBtn.addEventListener('click', () => {
    deleteContainer()
    const groupIdToRemove = deleteIdRef.current;
    const elementToDeleteGroup = document.getElementById(groupIdToRemove);
    
    if (elementToDeleteGroup) {
      elementToDeleteGroup.remove();
      removeBtn.style.background = '#9d9d9d';
      removeBtn.disabled = true;
      const format = 'delete';
      setDraggableNameType({ 
        targetid: groupIdToRemove, 
        format: format,
      });
      setDraggableType({ 
        targetid: groupIdToRemove, 
        format: format,
      });

    } else {
     console.log(`Element with ID ${groupIdToRemove} not found.`);
    }
  });
}





  return (
   <div  >
   <div className="css-19u637c">
      <div className="css-21tq8i">
      <ul
    id="draggableElement"
    className="css-q7sdae "
    ref={draggableElementRef}

    data-type ="deleteType"
    onDragStart={dragStart}
    onDragEnd={dragEnd}

    >
    <DraggableElement  selectedOption={selectedOption} permissions={permissions} />
  
    </ul>
      </div>
      </div>
     
    <div className="css-19u637c">
      <div className="css-21tq8i">
      <ul
      id="draggableElement"
      className="css-q7sdae"
      ref={draggableElementRef}
      data-type ="deleteType"
      onDragStart={dragStart}
       onDragEnd={dragEnd}

    >
     <DraggableElementNames  selectedOption={selectedOption} permissions={permissions} />
     
          
        </ul>
      </div>
   </div>
   <div className="css-19u637c">
      <div className="css-21tq8i">
      <ul
     id="draggableElement"
      className="css-q7sdae"
      ref={draggableElementRef}
      
      data-type ="deleteType"
      onDragStart={dragStart}
       onDragEnd={dragEnd}
    >
     <DraggableElementText  selectedOption={selectedOption} permissions={permissions} />
     

        </ul>
      </div>
   </div>
   <div className="css-19u637c">
      <div className="css-21tq8i">
      <ul
  id="draggableElement"
      className="css-q7sdae"
      ref={draggableElementRef}
     
      data-type ="deleteType"
      onDragStart={dragStart}
      onDragEnd={dragEnd}
    >
     <DraggableElementFormula  selectedOption={selectedOption} permissions={permissions} /> 
        </ul>
      </div>
   </div>
   </div>
  
  );
};


export default withTranslation()(withRouter(DraggableList));