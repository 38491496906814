import {
    GET_SIGNATURES,
    GET_SIGNATURES_SUCCESS,
    GET_SIGNATURES_FAIL,
    ADD_NEW_SIGNATURES,
    ADD_SIGNATURES_SUCCESS,
    ADD_SIGNATURES_FAIL,
    ADD_NEW_HANDWRITING,
    ADD_HANDWRITING_SUCCESS,
    ADD_HANDWRITING_FAIL,
    ADD_NEW_STEPM,
    ADD_STEPM_SUCCESS,
    ADD_STEPM_FAIL,
    GET_SETTING,
    GET_SETTING_SUCCESS,
    GET_SETTING_FAIL,
    GET_HELPCENTER,
    GET_HELPCENTER_SUCCESS,
    GET_HELPCENTER_FAIL,
    GET_BLOGDATA,
    GET_BLOGDATA_SUCCESS,
    GET_BLOGDATA_FAIL,
    GET_BLOGCATDATA,
    GET_BLOGCATDATA_SUCCESS,
    GET_BLOGCATDATA_FAIL,
  } from "./actionTypes";
  export const getSettingData = setData => ({
    type: GET_SETTING,
    payload: setData,
  })
  export const getSettingDataSuccess = setData => ({
    type: GET_SETTING_SUCCESS,
    payload: setData,
  })
  
  export const getSettingDataFail = error => ({
    type: GET_SETTING_FAIL,
    payload: error,
  })

  export const getHelpCenterData = helpCenterData => ({
    type: GET_HELPCENTER,
    payload: helpCenterData,
  })
  export const getHelpCenterDataSuccess = helpCenterData => ({
    type: GET_HELPCENTER_SUCCESS,
    payload: helpCenterData,
  })
  
  export const getHelpCenterDataFail = error => ({
    type: GET_HELPCENTER_FAIL,
    payload: error,
  })

  export const getBlogData = blogData => ({
    type: GET_BLOGDATA,
    payload: blogData,
  })
  export const getBlogDataSuccess = blogData => ({
    type: GET_BLOGDATA_SUCCESS,
    payload: blogData,
  })
  
  export const getBlogDataFail = error => ({
    type: GET_BLOGDATA_FAIL,
    payload: error,
  })


  export const getBlogCatData = blogCatData => ({
    type: GET_BLOGCATDATA,
    payload: blogCatData,
  })
  export const getBlogCatDataSuccess = blogCatData => ({
    type: GET_BLOGCATDATA_SUCCESS,
    payload: blogCatData,
  })
  
  export const getBlogCatDataFail = error => ({
    type: GET_BLOGCATDATA_FAIL,
    payload: error,
  })


  export const getSignature = signatureid => ({
    type: GET_SIGNATURES,
    signatureid,
  })
  export const getSignatureSuccess = signatures => ({
    type: GET_SIGNATURES_SUCCESS,
    payload: signatures,
  })
  
  export const getSignatureFail = error => ({
    type: GET_SIGNATURES_FAIL,
    payload: error,
  })
  export const addNewSignature = signature => ({
    type: ADD_NEW_SIGNATURES,
    payload: signature,
  })
  
  export const addNewSignatureSuccess = signature => ({
    type: ADD_SIGNATURES_SUCCESS,
    payload: signature,
  })
  
  export const addNewSignatureFail = error => ({
    type: ADD_SIGNATURES_FAIL,
    payload: error,
  })

  // add Hand writing

  export const addNewHandWriting = signature => ({
    type: ADD_NEW_HANDWRITING,
    payload: signature,
  })
  
  export const addNewHandWritingSuccess = signature => ({
    type: ADD_HANDWRITING_SUCCESS,
    payload: signature,
  })
  
  export const addNewHandWritingFail = error => ({
    type: ADD_HANDWRITING_FAIL,
    payload: error,
  })
    // add stemp

    export const addNewStemp = stemp => ({
      type: ADD_NEW_STEPM,
      payload: stemp,
    })
    
    export const addNewStempSuccess = stemp => ({
      type: ADD_STEPM_SUCCESS,
      payload: stemp,
    })
    
    export const addNewStempFail = error => ({
      type: ADD_STEPM_FAIL,
      payload: error,
    })