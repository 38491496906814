import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

export const registerUser = (userdata) => {
  return {
    type: REGISTER_USER,
    payload: userdata,
  }
}

export const registerUserSuccessful = getuser => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: getuser,
  }
}

export const registerUserFailed = error => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  }
}


