import React, { useState, useEffect } from 'react';
import {
    Modal,
  } from "reactstrap";
  import toastr from "toastr";
import "toastr/build/toastr.min.css";
const TimeTrackingButton = ({ isEditorAllowed,item ,props,setIsReloadData}) => {
    const {t} =  props
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [timezone, setTimezone] = useState(user.timezone);
  const [isTracking, setIsTracking] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function formatTime(timeString) {
    if (!timeString) return "00:00:00"; // Handle case where timeString is null or undefined
  
    const date = new Date(timeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds}`;
  }
  
  const formatStartDateTimeWithTimezone = (uren_date, start_time_uren, timezone) => {
    // Extract the date part from `uren_date` (ignoring the 'T' part)
    const datePart = uren_date.split("T")[0];
    
    // Combine the date and time
    const combinedDateTimeString = `${datePart}T${start_time_uren}`;
    
    // Create a Date object based on combined date and time (assumes local timezone)
    let localDate = new Date(combinedDateTimeString);
  
    // Adjust the time to `Europe/Amsterdam` using `Intl.DateTimeFormat`
    const adjustedTime = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(localDate);
  
    // Parse the adjusted time back to a Date object
    const [date, time] = adjustedTime.split(", ");
    const [month, day, year] = date.split("/");
    const [hour, minute, second] = time.split(":");
    const finalDate = new Date(year, month - 1, day, hour, minute, second);
  
    return finalDate;
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }


  useEffect(() => {
    if (item.work_status === 'checkin') {
      const start = formatStartDateTimeWithTimezone(item.uren_date, item.start_time_uren,timezone);
         
      setStartTime(start);
      setIsTracking(true);
      
    } else if (item.work_status === 'checkout') {
      const start = formatStartDateTimeWithTimezone(item.uren_date, item.start_time_uren,timezone);
      const end = formatStartDateTimeWithTimezone(item.uren_date, item.eind_time_uren,timezone);
      setStartTime(start);
    }
  }, [item.work_status, item.start_time_uren, item.eind_time_uren]);

  useEffect(() => {
    if (!isTracking || !startTime) return;

    const interval = setInterval(() => {
        const currentTime = new Date();
        const timeDiff = currentTime - startTime;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        // Update the elapsedTime state with correctly formatted time
        setElapsedTime(
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        );
      }, 1000);
    
      return () => clearInterval(interval);
  }, [isTracking, startTime]);

  function calculateWorkTime(start, end, pause = 0) {
    // Parse start and end times
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);
  
    // Convert start and end times to minutes
    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes = endHour * 60 + endMinute;
  
    // Calculate time difference in minutes
    let timeDifference = endTimeInMinutes - startTimeInMinutes;
  
    // Handle negative time difference (e.g., if end time is before start time)
    if (timeDifference < 0) {
        timeDifference += 24 * 60; // Add 24 hours worth of minutes
    }
    // timeDifference -= pause;
    // Convert time difference to hours and minutes
    const totalHours = Math.floor(timeDifference / 60);
    const totalMinutes = timeDifference % 60;
    const formattedTotalHours = totalHours < 10 ? `0${totalHours}` : totalHours;
    const formattedTotalMinutes = totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes;
    // Return total work time as a string
    return `${formattedTotalHours}:${formattedTotalMinutes}`;
  }

  const handlePunch = () => {
    if (!isTracking && !isFinished) {
      const start = new Date();
      setStartTime(start);
      setIsTracking(true);
      postData({
        start_time: formatTime(start),
        uren_id: item.uren_id,
        work_status: 'checkin',
        eind_time: item.eind_time_uren,
        pause: item.discription_uren,
        description: item.uren_id,
        totalhour: item.total_day_hour,
      });
      
    } else if (isTracking) {
      const end = new Date();
      setEndTime(end);
      setIsTracking(false);
      setmodal_standard(true);
    }
  };

  const postData = (data) => {
    fetch('/api/post/timesheet/workhours', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
        setmodal_standard(false);
        setIsReloadData(true);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const pause = formData.get("pause");
    const description = formData.get("discription");
    if (!pause || isNaN(pause) || Number(pause) < 0) {
        toastr.error(t('Please enter a valid non-negative number for pause.'))
        return;
      }

      if (!description || description.trim() === "") {
        toastr.error(t('Please enter a description.'))
        return;
      }
    const newEndTime = new Date();
    
    const totalHour = calculateWorkTime(formatTime(startTime),formatTime(newEndTime),parseInt(pause, 10));
    postData({
      start_time: formatTime(startTime),
      eind_time: formatTime(newEndTime),
      pause,
      description,
      totalhour: totalHour,
      uren_id: item.uren_id,
      work_status: 'checkout',
    });
  };

  const renderTrackingButton = () => {
    const baseButtonStyle = "css-shord3-action bg-hover-2";

    if (item.work_status === 'wait') {
      return (
        <button
          type="button"
          className={baseButtonStyle}
          onClick={handlePunch}
          disabled={!isEditorAllowed}
          style={{ backgroundColor: '#04851e', color: '#ffffff' }}
        >
          Punch In
        </button>
      );
    } else if (item.work_status === 'checkin') {
      return (
        <button
          type="button"
          className={baseButtonStyle}
          disabled={!isEditorAllowed}
          onClick={() => {
            tog_standard();
          }}
          style={{
            backgroundColor: '#e61606',
            color: '#ffffff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          Punch Out
          {isTracking && <div style={{ fontSize: '12px', color: 'white' }}>{elapsedTime}</div>}
        </button>
      );
    } else if (item.work_status === 'checkout') {
      return (
        <button
          type="button"
          disabled={!isEditorAllowed}
          className={baseButtonStyle}
          style={{ backgroundColor: '#f9f9f9', color: '#1e1e1e' }}
        >
          Work Off
        </button>
      );
    }
  };

  return (
    <div>
      {renderTrackingButton()}

      <Modal
      isOpen={modal_standard}
      toggle={tog_standard}
    >
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-3 row">
                <label htmlFor="pause_id_m" className="col-md-2 col-form-label d-flex justify-content-center align-items-center">
                  Pauze
                </label>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="number"
                    name="pause"
                    placeholder="0"
                    id="pause_id_m"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="discription" className="col-md-3 col-form-label">
                  Description
                </label>
                <div className="col-md-9">
                  <textarea
                    required
                    id="discription"
                    name="discription"
                    className="form-control"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" onClick={tog_standard}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Punch Out
            </button>
          </div>
        </form>
      </div>
    </Modal>
    </div>
  );
};

export default TimeTrackingButton;
