/* DOCUMENTS */
export const ADD_NEW_DOC = "ADD_NEW_DOC"
export const ADD_NEW_DOC_SUCCESS = "ADD_NEW_DOC_SUCCESS"
export const ADD_NEW_DOC_FAIL = "ADD_NEW_DOC_FAIL"
/* RECIEPIEN */
export const ADD_RECIEPIEN_DOC = "ADD_RECIEPIEN_DOC"
export const ADD_RECIEPIEN_DOC_SUCCESS = "ADD_RECIEPIEN_DOC_SUCCESS"
export const ADD_RECIEPIEN_DOC_FAIL = "ADD_RECIEPIEN_DOC_FAIL"

/* Get Teamplate */
export const GET_TEMPLATE = "GET_TEMPLATE"
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS"
export const GET_TEMPLATE_FAIL = "GET_TEMPLATE_FAIL"

/* Delate Template */
export const DELETE_TEPLATE = "DELETE_TEPLATE"
export const DELETE_TEPLATE_SUCCESS = "DELETE_TEPLATE_SUCCESS"
export const DELETE_TEPLATE_FAIL = "DELETE_TEPLATE_FAIL"

/* Get Teamplate data */
export const GET_TEMPLATE_DATA = "GET_TEMPLATE_DATA"
export const GET_TEMPLATE_DATA_SUCCESS = "GET_TEMPLATE_DATA_SUCCESS"
export const GET_TEMPLATE_DATA_FAIL = "GET_TEMPLATE_DATA_FAIL"