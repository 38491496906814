import React, { useRef, useState, useEffect } from 'react';
import { Link ,useParams} from "react-router-dom";
import { renderToString } from 'react-dom/server';
import axios from 'axios';
import { map } from "lodash";
import '../../assets/scss/theme.scss';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Document, Page,pdfjs } from 'react-pdf';
import {SignaturePad  } from "../Document/SignaturePad"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
    UncontrolledCollapse
  } from "reactstrap";
import { createRoot } from 'react-dom/client';
import {QRCodeSVG} from 'qrcode.react';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const Preview = ({ 
   docNames ,selectedOption,recipientNames,pdftoimage,t
    }) => {
     
      useEffect(() => {
        toastr.options = {
          positionClass: 'toast-top-center',
          closeButton: true,
          debug: true,
          progressBar:true,
          timeOut: 30000, // Duration in milliseconds
        };
      }, []);
      const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [fristname, setFristname] = useState(user.firstName);
  const [lastname, setLastname] = useState(user.lastName);
  const [token, setToken] = useState(user.token);
  const fullNames = `${fristname} ${lastname}`;
  const { uuid } = useParams();
  const svgRef = useRef(null);
  const objRef = useRef(null);
  const [screenSize, setScreenSize] = useState('desktop');
  const [numPages, setNumPages] = useState(null);
  const [pdfUrls, setPdfUrl] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [scale, setScale] = useState(1);
  const pdfWidth = useRef();
  const pdfHeight = useRef();
  const zoom = useRef();
  const pdfContainerX = useRef();
  const pdfContainerY = useRef();
  const [renderedPages, setRenderedPages] = useState([]);
  const pdftoimageArray = pdftoimage.split(',');
  const [modal_center, setmodal_center] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const [signatureobj, setSignatureObje] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const handleSignatureData = (data) => {
    setSignatureData(data);
    
  }
  useEffect(() => {
    // Simulating an asynchronous operation
    const delay = setTimeout(() => {
      setIsloading(false);
    }, 2000);

    // Clean up the timeout on component unmount or when isLoading becomes false
    return () => clearTimeout(delay);
  }, []);
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  if (!Array.isArray(pdftoimageArray)) {
    console.error('pdftoimageArray is not an array');
    return null; // or handle the error accordingly
  }
  const pdfContainer = document.querySelector('svg#pdf-container');
  const svg =document.getElementById('previwe-container')
 

  const fixTopElement = document.getElementById('fix-top');
const PdfViewerContaine = document.getElementById('pdf-wrapper');

  const changeSize = (newSize) => {

    switch (newSize) {
      case 'desktop':
        fixTopElement.style.width = '80%'; // Adjust this value as needed
        fixTopElement.style.backgroundColor = 'lightblue'; 
        setScale(1)
        NewDiminition(1)
       
        // Add any additional styles for desktop
        break;
      case 'tablet':
        fixTopElement.style.width = '60vw'; // Adjust this value as needed
        fixTopElement.style.backgroundColor = 'lightgreen';
        
        setScale(1)
        NewDiminition(1)
        break;
      case 'mobile':
        fixTopElement.style.width = '40vw'; // Adjust this value as needed
        fixTopElement.style.backgroundColor = 'lightcoral'; // Example background color
       
        setScale(0.8)
        NewDiminition(0.8)
        break;
      default:
        setScale(1)
        NewDiminition(1);
       
        break;
    }

    setScreenSize(newSize);
  };


 
  
   
   
  const isActive = (size) => size === screenSize;
  const renderPage = async (pageNumber) => {
    try {
      const loadingTask = pdfjs.getDocument(pdfUrls);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const canvasWidth = viewport.width;
      const canvasHeight = viewport.height;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      pdfContainerX.current = canvas.width;
      pdfContainerY.current = canvas.height;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const dataUrl = canvas.toDataURL('image/png');
      setImageUrls((prevUrls) => [...prevUrls, { url: dataUrl, height: canvasHeight }]);
    } catch (error) {
      console.error('Error rendering page:', error);
    }
  };
  const fetchPdf = async () => {
    try {
      const response = await axios.get(`${docNames}`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };


  useEffect(() => {
    fetchPdf();
  }, [docNames,scale]);

  useEffect(() => {
    const renderPdf = async () => {
      try {
        const loadingTask = pdfjs.getDocument(pdfUrls);
        const pdf = await loadingTask.promise;

        setNumPages(pdf.numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    if (pdfUrls) {
      renderPdf();
    }
  }, [pdfUrls]);

  useEffect(() => {
    setImageUrls([]); // Clear existing image URLs before rendering new pages
  
    const renderAllPages = async () => {
      for (let i = 1; i <= numPages; i++) {
        await renderPage(i);
      }
    };
  
    if (numPages) {
      renderAllPages();
    }
  }, [numPages, scale, pdfUrls]);
    
  const totalImageHeight = imageUrls.reduce((totalHeight, imageData) => {
    return totalHeight + imageData.height;
  }, 0);
  async function NewDiminition(zoo) {
    const dataContent = pdfContainer.querySelectorAll(`[data-recipien="${selectedOption}"]`);
  
    if (dataContent.length !== 0) {
      dataContent.forEach((item, index) => {
        const dataType = item.getAttribute('data-type');
        const width = parseFloat(item.getAttribute('width'));
        const height = parseFloat(item.getAttribute('height'));
        const x1 = parseFloat(item.getAttribute('x'));
        const y1 = parseFloat(item.getAttribute('y'));
  
        const targetid = item.id;
  
        // Use querySelectorAll to handle multiple elements with the same data-id
        const reactelements = document.querySelectorAll(`.reactelement[data-id="${targetid}"]`);
  
        // Iterate through each matching element and update attributes
        reactelements.forEach((reactelement) => {
          const dataTypes = reactelement.getAttribute('data-type');
            const group = reactelement.closest('g');
            const foreignObject = group.querySelector('foreignObject');
            const newWidth = width * zoo;
            const newHeight = height * zoo;
            const newX = x1 * zoo;  // Adjust based on the anchor point
            const newY = y1 * zoo; // Adjust based on the anchor point
            if (foreignObject) {
              foreignObject.setAttribute("x", newX);
              foreignObject.setAttribute("y", newY);
            }
            // Update attributes
            reactelement.setAttribute('width', newWidth);
            reactelement.setAttribute('height', newHeight);
            reactelement.setAttribute('x', newX);
            reactelement.setAttribute('y', newY);
          
        });
      });
    }
  }
  const handleInputChange = (evt)=> {
    const inputValue = evt.target.value;
    const target = evt.target;

    const group = target.closest('g');
    const rect = group.querySelector('rect');
    const foreignObject = group.querySelector('foreignObject');
    const foreignObjectInput = target.parentElement;
    const span = foreignObject.querySelector('span');
    const targetid = rect.dataset.id;
    const format = rect.dataset.format;
    const signContent = rect.dataset.content;
    const isOwner = rect.dataset.isOwner; 
    const fontColor = rect.dataset.fontColor;
    const fontSize = rect.dataset.fontSize;
    const fontFormat = rect.dataset.fontFormat;
    const fontFamily = rect.dataset.fontFamily;

    const optionnumtype = rect.dataset.optionnumtype; 
    const selecttype = rect.dataset.selecttype;
    const valutacode = rect.dataset.valutacode;
    const Max = rect.dataset.max;
    const Min = rect.dataset.min;
    const width = parseFloat(rect.getAttribute('width'));
    const height = parseFloat(rect.getAttribute('height'));
    const x = parseFloat(rect.getAttribute('x'));
    const y = parseFloat(rect.getAttribute('y'));
    let formattedValue;
    if (format === 'payment') {
      target.setAttribute('type', 'number'); // You might want to change the input type accordingl
      // Disable the input for 'payment' format

    }
    if (format === 'number') {
      const formatOptions = {
        minimumFractionDigits: 2, // Number of decimal places
      };
      target.setAttribute('type', 'number');
    
      // Extract numeric value from the input
      const numericValue = Number(inputValue.replace(/\D/g, ''));
    
      if (!isNaN(numericValue)) { // Check if the input is a valid number
        // Validate against minimum and maximum values
        if (numericValue < Min) {
          // Handle case where value is less than Min
          // alert('Value cannot be less than ' + Min);
          foreignObject.setAttribute('opacity', '1');
          span.textContent = t('Value cannot be less than') + Max
        } else if (numericValue > Max) {
          // Handle case where value is greater than Max
          foreignObject.setAttribute('opacity', '1');
          span.textContent = t('Value cannot be greater than') + Max
         // alert('Value cannot be greater than ' + Max);
        } else {
          // Perform formatting based on selecttype
          if (selecttype === 'number') {
            if (valutacode === 'normal') {
              formattedValue = numericValue;
            } else {
              formattedValue = numericValue.toLocaleString(valutacode) + '.00';
            }
          }
          if (selecttype === 'currency') {
            formatOptions.style = 'currency';
            formatOptions.currency = optionnumtype;
            formattedValue = numericValue.toLocaleString(valutacode, formatOptions);
          }
        }
      } else {
        // Handle case where the input is not a valid number
        //alert('Please enter a valid number.');
        foreignObject.setAttribute('opacity', '1');
        span.textContent = t('Please enter a valid number.') + Max
      }
    } else {
      formattedValue = inputValue;
    }
    const maxWidth = 200;
    const calculatedWidth = calculateWidthBasedOnInput(inputValue, maxWidth);
    rect.setAttribute('width', calculatedWidth);
    rect.setAttribute('data-content', formattedValue);
    foreignObjectInput.setAttribute('width', calculatedWidth);
    foreignObject.setAttribute('width', '200');
    target.style.width = calculatedWidth + 'px';

  }
  function calculateWidthBasedOnInput(inputValue, maxWidth) {
    const minLength = 30; // Some minimum width
    const additionalWidth = 1.15;
    const calculatedWidth = Math.max(minLength, inputValue.length * 10 / additionalWidth);
    return calculatedWidth;
  }
  const handleClickObject = (evt) => {
    const target = evt.target;
    const group = target.closest('g');
    const rect = group.querySelector('rect');
    const targetid = rect.dataset.id;
    const format = rect.dataset.format;
    const signContent = rect.dataset.content;
    const isOwner = rect.dataset.isOwner; 
    const fontColor = rect.dataset.fontColor;
    const fontSize = rect.dataset.fontSize;
    const fontFormat = rect.dataset.fontFormat;
    const fontFamily = rect.dataset.fontFamily;
    const width = parseFloat(rect.getAttribute('width'));
    const height = parseFloat(rect.getAttribute('height'));
    const x = parseFloat(rect.getAttribute('x'));
    const y = parseFloat(rect.getAttribute('y'));
   
        switch (format) {
        case 'handwriteing':
        case 'hand_praaf':
        case 'stemps':
        setSignaturePNG(targetid,group,rect,width,height,x,y,signContent,isOwner)
        break;
        case 'drawing':
        setDrawing(targetid,group,rect,width,height,x,y,signContent,isOwner)
        break;
        case 'calendar':
        case 'fullname':
        case 'email':
        case 'approve':
        case 'decline':
        createPdfWithTextAndSvg(
        targetid,group,rect,width,height,x,y,signContent,
        fontColor,fontSize,fontFormat,fontFamily
        );
        break;
        case 'payment':
        break;
        case 'checkbox':
        case 'radio':
        break;
        
        case 'formula':
        case 'attachment':
          createformulafunction(
            targetid,group,rect,width,height,x,y,signContent,
            fontColor,fontSize,fontFormat,fontFamily
            );
        break;
        case 'dropdown':
          const select = group.querySelector('select');
          const text = group.querySelector('text');
          createSelectOption(
            targetid,group,rect,text,width,height,x,y,signContent,
            fontColor,fontSize,fontFormat,fontFamily,select
            );
        break;
        // tips : Required - Approve - Unchecked
       
    
        default:
        // Handle other data types if necessary
    }
   }
  
      if (svg) {
        if (!selectedOption) {
          selectedOption =recipientNames[0];
         
        }

       
        createObjectdata();
      function createObjectdata(){
        
        const dataContent = pdfContainer.querySelectorAll(`[data-recipien="${selectedOption}"]`);
        const groupContainer = pdfContainer.querySelectorAll(`[data-groupContainer="${selectedOption}"]`);
        
        if (groupContainer.length != 0) {
        groupContainer.forEach((item, index) => {
          const groupContaineRule = item.dataset.rule;
          const groupContainevalidation = item.dataset.validation;
          const groupContaineMax = item.dataset.max;
          const groupContaineMin = item.dataset.min;
          const groupColors = item.dataset.colors;
          const dataformat = item.dataset.format;
          if (dataformat === 'checkbox') {
            createGroupBoxCheckAndRadio(
              groupContaineRule,groupContainevalidation,groupContaineMax,
              groupContaineMin,item,groupColors
              )
          }else if(dataformat === 'radio'){
            createRadioGroup(
              item
              )
              
          }


       
        })
       }
        if (dataContent.length != 0) {
          const  UsersDimensions = {};
       
          dataContent.forEach((item, index) => {
       
            const dataType = item.getAttribute('data-type');
            const width = parseFloat(item.getAttribute('width'));
            const height = parseFloat(item.getAttribute('height'));
            const x = parseFloat(item.getAttribute('x'));
            const y = parseFloat(item.getAttribute('y'));
            const targetid = item.id;
            const format = item.dataset.type;
            const signContent = item.dataset.content;
            const roll = item.dataset.roll;
            const names = item.dataset.recipien;
            const Messeges = item.dataset.msg;
            const mails = item.dataset.mail;
            const recipienids = item.getAttribute('recipienid');
            const fontColor = item.dataset.fontColor;
            const fontSize = item.dataset.fontSize;
            const fontFormat = item.dataset.fontFormat;
            const fontFamily = item.dataset.fontFamily;
            const page = item.dataset.page;
            const dataFormat = item.dataset.dataFormat;
            const isOwner = item.dataset.isOwner;
            const group = item.parentElement;
          const groupContainer = group.parentElement;
          const selecttype = item.dataset.selecttype;
          const optionnumtype = item.dataset.optionnumtype;
          const valutacode = item.dataset.valutacode;
          const Max = item.dataset.max;
          const Min = item.dataset.min; 
          const label = item.dataset.option;
          const option = item.dataset.value;
          
          const datalabel = item.dataset.label;
          
    switch (dataType) {
      case 'handwriteing':
      case 'hand_praaf':
      case 'stemps':
      case 'calendar':
      case 'fullname':
      case 'email':
      case 'company': 
      case 'title':
      case 'textarea':
      case 'drawing':
      case 'attachment': 
      case 'approve':
      case 'decline':
        createSignatureElements(
          targetid,format,signContent,page,
          dataFormat,fontColor,fontSize,fontFormat,fontFamily,
          width,height,x,y,names,mails,isOwner,selecttype ,optionnumtype ,valutacode,Max,Min,roll
          )
        break;
      case 'qrcode':
        const fgColor = item.dataset.fgColor;
        const bgColor = item.dataset.bgColor;
        createQRCodeElement(targetid,width,height,x,y,names,fgColor,bgColor,signContent,format)
        break;

        case 'number': 
        case 'payment':
          
          createSignatureElements(
            targetid,format,signContent,page,
            dataFormat,fontColor,fontSize,fontFormat,fontFamily,
            width,height,x,y,names,mails,isOwner,selecttype ,optionnumtype ,
            valutacode,Max,Min,roll,datalabel
            )
      break;
      
        case 'dropdown':
         
          createDropDownElements(
            targetid,format,signContent,page,
            fontColor,fontSize,fontFormat,fontFamily,
            width,height,x,y,isOwner,label ,option,roll,names
            )

        break;
        case 'formula':
          createFormulaElements(
            targetid,format,signContent,page,
            dataFormat,fontColor,fontSize,fontFormat,fontFamily,
            width,height,x,y,names,mails,isOwner,selecttype ,optionnumtype ,valutacode,Max,Min,roll
            )
        break;
      default:
        // Handle other data types if necessary
    }
  
         
          })
         return UsersDimensions
          
       }
       
      }
      function createRadioGroup(item) {
        const gElements = item.querySelectorAll('g');
        const paddingX = 5;
        const paddingY = 5;
        const svgNS = 'http://www.w3.org/2000/svg';
        const groupContainerElement = document.createElementNS(svgNS, 'g'); // Create the group container
    
        gElements.forEach((g, index) => {
            const rect = g.querySelector('rect');
            if (rect) {
                const x = parseFloat(rect.getAttribute('x')) || 0;
                const y = parseFloat(rect.getAttribute('y')) || 0;
                const width = parseFloat(rect.getAttribute('width')) || 0;
                const height = parseFloat(rect.getAttribute('height')) || 0;
                const targetid = rect.id;
              const format = rect.dataset.type;
              const signContent = rect.dataset.content;
              const roll = rect.dataset.roll;
              const names = rect.dataset.recipien;
                groupContainerElement.setAttribute('class', 'previwe_user');
                groupContainerElement.setAttribute('data-displayname', names);
                groupContainerElement.setAttribute('data-id',targetid);
                groupContainerElement.setAttribute('data-isvalid','false');
                const viewportCenterX = x / scale; // adjust if needed
                const viewportCenterY = y / scale; // adjust if needed
                const viewportCenterW = width / scale; // adjust if needed
                const viewportCenterH = height / scale; // adjust if needed
                const newTop = viewportCenterY * scale;
                const newLeft = viewportCenterX * scale;
                const input = document.createElementNS(svgNS, 'foreignObject'); // Create the foreignObject
                input.setAttribute('x', newLeft - 1);
                input.setAttribute('y', newTop - 5);
                input.setAttribute('width', width);
                input.setAttribute('height', height + 10);
                input.setAttribute('data-format',format);
                const htmlInput = document.createElement('input'); // Create the HTML input
                htmlInput.setAttribute('type', 'radio');
                htmlInput.setAttribute('name', targetid);
                htmlInput.setAttribute('data-group-id', targetid);
                htmlInput.style.width = '100%';
                htmlInput.style.height = '100%';
                switch (roll) {
                  case 'false':
                    htmlInput.setAttribute('disabled', 'disabled');
                    break;
                }
              
                htmlInput.addEventListener('click', (event) => {
                  const clickedInput = event.target;
                  const groupContainerElement = clickedInput.closest('g[data-id]');
                  if (groupContainerElement) {
                      const inputsInGroup = groupContainerElement.querySelectorAll('foreignObject[data-format="radio"] input[type="radio"]');
                      inputsInGroup.forEach(input => {
                          input.checked = false; // Uncheck all inputs
                      });
                      clickedInput.checked = true; // Check the clicked input
                  }
              });
              
              
                // Attach click event listener to each radio button
               
              
    
                input.appendChild(htmlInput); // Append the HTML input to the foreignObject
                groupContainerElement.appendChild(input); // Append the foreignObject to the group container
            }
        });
    
        // Append the group container to the SVG element
        svg.appendChild(groupContainerElement);
    }
   async function handleradioBox(evt,targetid,format){
    const clickedInput = evt.target; 
    const groupContainerElement = clickedInput.closest(`g[data-id="${targetid}"]`);
    if (groupContainerElement) {
      const foreignObjects = groupContainerElement.querySelectorAll('foreignObject[data-format="radio"] input[type="radio"]');
      
    
  }

      }
    

      function createGroupBoxCheckAndRadio(
        groupContaineRule,groupContainevalidation,groupContaineMax,
        groupContaineMin,item,groupColors
        ) {
          
          if (item) {
            const svgNS = 'http://www.w3.org/2000/svg';
            const gElement = item.querySelectorAll('g');
            const groupContainerElement = document.createElementNS(svgNS, 'g');
              groupContainerElement.setAttribute('data-rule',groupContaineRule);
              groupContainerElement.setAttribute('data-max', groupContaineMax);
              groupContainerElement.setAttribute('data-min', groupContaineMin);
              groupContainerElement.setAttribute('data-validation', groupContainevalidation);
           
              const rects = [];
              const paddingX = 5;
              const paddingY = 5;
              gElement.forEach(g => {
                const rect = g.querySelector('rect');
                if (rect) {
                  const x = parseFloat(rect.getAttribute('x')) || 0;
                  const y = parseFloat(rect.getAttribute('y')) || 0;
                  const width = parseFloat(rect.getAttribute('width')) || 0;
                  const height = parseFloat(rect.getAttribute('height')) || 0;
              
                  const rectObject = { x, y, width, height };
                  rects.push(rectObject);
                }
            });
            let containerX = rects[0] ? rects[0].x - paddingX : 0;
            let containerY = rects[0] ? rects[0].y - paddingY : 0;
            
            // Initialize container dimensions with the first rectangle's dimensions and padding
            let containerMaxX = rects[0] ? rects[0].x + rects[0].width + paddingX : 0;
            let containerMaxY = rects[0] ? rects[0].y + rects[0].height + paddingY : 0; 
            for (let i = 1; i < rects.length; i++) {
              const rect = rects[i];
              containerX = Math.min(containerX, rect.x - paddingX);
              containerY = Math.min(containerY, rect.y - paddingY);
              containerMaxX = Math.max(containerMaxX, rect.x + rect.width + paddingX);
              containerMaxY = Math.max(containerMaxY, rect.y + rect.height + paddingY);
            }
            let containerWidth = containerMaxX - containerX;
            let containerHeight = containerMaxY - containerY;
            const rectContainerStyle = {
              stroke: groupColors,
              fill: 'transparent',
              opacity: '0.75',
              rx: '1',
              ry: '1',
              x: containerX,
              y: containerY,
              width: containerWidth,
              height: containerHeight,
              strokeWidth: '0.7746987951807229',
            };
            const rectContainer = document.createElementNS(svgNS, 'rect');
          
            rectContainer.setAttribute('stroke-dasharray', '2.1362126245847177,4.2724252491694354');
            Object.keys(rectContainerStyle).forEach((key) => {
              rectContainer.setAttribute(key, rectContainerStyle[key]);
            });
            groupContainerElement.appendChild(rectContainer);
            gElement.forEach(g => {
              const rect = g.querySelector('rect');
              
              const dataType = rect.getAttribute('data-type');
              const width = parseFloat(rect.getAttribute('width'));
              const height = parseFloat(rect.getAttribute('height'));
              const x = parseFloat(rect.getAttribute('x'));
              const y = parseFloat(rect.getAttribute('y'));
              const targetid = rect.id;
              const format = rect.dataset.type;
              const signContent = rect.dataset.content;
              const roll = rect.dataset.roll;
              const names = rect.dataset.recipien;
              const page = rect.dataset.page;
              const isOwner = rect.dataset.isOwner;
              const ischecked = rect.dataset.checked;
              const recipienids = rect.getAttribute('recipienid');
              const uniqueId = `group_${Date.now()}`
              groupContainerElement.setAttribute('class', 'previwe_user');
              groupContainerElement.setAttribute('data-displayname', names);
              groupContainerElement.setAttribute('data-id',targetid);
              groupContainerElement.setAttribute('data-isvalid','false');
        
              const viewportCenterX = x / scale; // adjust if needed
              const viewportCenterY = y / scale; // adjust if needed
              const viewportCenterW = width / scale; // adjust if needed
              const viewportCenterH = height / scale; // adjust if needed
              const newTop = viewportCenterY * scale;
              const newLeft = viewportCenterX * scale;
              const newW = viewportCenterW * scale;
              const newH= viewportCenterH * scale;
        
              let border 
              if (format === 'checkbox') {
               border = 1
              }
        
        
             
              
              const groupElement = document.createElementNS(svgNS, 'g');
              groupElement.setAttribute('data-content', signContent);
              groupElement.setAttribute('data-checked', ischecked);
            
              groupElement.id = uniqueId;
              // Create the rectangular container
               const rectWidth = 10;
               const rectHeight = 10;
              // Create the first checkbox
              const firstRect = document.createElementNS(svgNS, 'rect');
              firstRect.setAttribute('x', newLeft);
              firstRect.setAttribute('y', newTop);
              firstRect.setAttribute('width', rectWidth);
              firstRect.setAttribute('height', rectHeight);
              firstRect.setAttribute('stroke', '#ffd65bfc');
              firstRect.setAttribute('fill', '#ffffff');
              firstRect.setAttribute('fill-opacity', '1');
              firstRect.setAttribute('stroke-opacity', '1');
              firstRect.setAttribute('stroke-width', '3');
              firstRect.setAttribute('rx', border);
              firstRect.setAttribute('ry', border);
              firstRect.setAttribute('data-content',signContent);
              firstRect.setAttribute('data-format', format);
              firstRect.setAttribute('data-checked', 'false');
              firstRect.setAttribute('data-roll', roll);
              firstRect.setAttribute('data-type',format);
              firstRect.setAttribute('class', 'objectelement');
              firstRect.setAttribute('draggable', 'true');
              firstRect.setAttribute('data-label', Date.now());
              firstRect.setAttribute('data-page', page);
              firstRect.setAttribute('recipienid', recipienids);
              firstRect.setAttribute('data-recipien',names);
              firstRect.setAttribute('data-isOwner', isOwner);
              firstRect.id = Date.now();
      
              switch (roll) {
                case 'false':
                  firstRect.setAttribute('readOnly', 'true');
                  break;
                default:
                  firstRect.addEventListener('mousedown', (evt) => handleCheckbox(evt,firstRect,groupContainerElement,groupElement ,ischecked ,groupContaineRule,groupContainevalidation,groupContaineMax,
                    groupContaineMin,roll,width,height,x,y,format));
                  break;
              }
              
              groupElement.appendChild(firstRect);
              groupContainerElement.appendChild(groupElement);
              
            svg.appendChild(groupContainerElement);
               

             })
   
            
          }
     
     

      }

   function createQRCodeElement(targetid,width,height,x,y,names,fgColor,bgColor,signContent,format){
    const viewportCenterX = x / scale; // adjust if needed
    const viewportCenterY = y / scale; // adjust if needed

    const viewportCenterW = width / scale; // adjust if needed
    const viewportCenterH = height / scale; // adjust if needed
    const newTop = viewportCenterY * scale;
    const newLeft = viewportCenterX * scale;
    const newW = viewportCenterW * scale;
    const newH= viewportCenterH * scale;
    const cloneRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    cloneRect.setAttribute('stroke', '#ffffff');
    cloneRect.setAttribute('fill', '#ffffff');
            cloneRect.setAttribute('opacity', '0');
            cloneRect.setAttribute('rx', '5');
            cloneRect.setAttribute('ry', '5');
            cloneRect.setAttribute('x',viewportCenterX );
            cloneRect.setAttribute('y', viewportCenterY);
            cloneRect.setAttribute('width', newW);
            cloneRect.setAttribute('height', newH);
            cloneRect.setAttribute('data-content', signContent);
            cloneRect.setAttribute('data-recipien', names);
            cloneRect.setAttribute('data-format', format);
            cloneRect.setAttribute('data-fgColor', fgColor);
            cloneRect.setAttribute('data-bgColor', bgColor);
            cloneRect.setAttribute('data-id',targetid);
            cloneRect.setAttribute('strokeWidth', '0.7746987951807229');
            cloneRect.setAttribute('class','reactelement');
            cloneRect.setAttribute('data-type','qrcode');
            const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            group.setAttribute('data-view-name', 'MultiSelectMarquee');
            group.setAttribute('data-view-id', 'c262');
            group.setAttribute('role', 'list');
            group.setAttribute('style', 'cursor: move');
            group.setAttribute('class', 'previwe_user');
            group.setAttribute('data-displayname', names);
            group.setAttribute('data-id',targetid);
           
            const qrCode = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            qrCode.setAttribute('x', newLeft);
            qrCode.setAttribute('y', newTop);
            qrCode.setAttribute('width', newW); // Set the width and height as needed
            qrCode.setAttribute('height', newW);
            
            const qrCodeValue = signContent; // The content you want to encode in the QR code
          
            // Use qrcode.react to generate the QR code image
            const qrCodeComponent = (
              <QRCodeSVG
                value={qrCodeValue}
                size={newW}
                fgColor={fgColor}
                bgColor={bgColor}
              />
            );
         const root = createRoot(qrCode);
         root.render(qrCodeComponent);
            group.appendChild(qrCode);
            group.appendChild(cloneRect);
            svg.appendChild(group);
   }


  function createFormulaElements(
  targetid,format,signContent,page,
  dataFormat,fontColor,fontSize,fontFormat,fontFamily,
  width,height,x,y,names,mails,isOwner,
  ){
    
    var result = 0 
    if (!signContent || signContent.trim() === "" || signContent === "formula") {
      signContent = "0"; // Set signContent to "0" if it's undefined, null, or empty
  }
 
    if (signContent != 0) {
      var matches = signContent.match(/\[([^\]]+)\]|(\+|-|\*|\/)/g);

    // Process matches to extract values without brackets
    var valuesAndOperators = matches.map(match => {
        if (match.startsWith("[")) {
            return match.slice(1, -1); // Remove the brackets
        } else {
            return match; // Return the operator as it is
        }
    })
    var numericValues = [];
    
    for (var i = 0; i < valuesAndOperators.length; i++) {
      var element = valuesAndOperators[i];
      if (!isNaN(parseFloat(element))) {
        var queriedElement = document.querySelector(`[data-label="${element}"]`);
        
          if (queriedElement && queriedElement.hasAttribute('data-content')) {
              numericValues.push(parseFloat(queriedElement.getAttribute('data-content')));
          } else {
              console.error("Data for label " + element + " not found.");
          }
         
      } else if (element === '+' || element === '-' || element === '*' || element === '/') {
          numericValues.push(element); // Push operators directly
      } else {
        console.error("Invalid element:", element);
      }
  }
  
   result = numericValues[0];
  
  for (var j = 1; j < numericValues.length; j++) {
      var nextValue = numericValues[j];
      if (!isNaN(nextValue)) {
          switch (numericValues[j - 1]) { // Previous value in numericValues array is the operator
           
            case '+':
                  result += nextValue;
                  break;
              case '-':
                  result -= nextValue;
                  break;
              case '*':
                  result *= nextValue;
                  break;
              case '/':
                  if (nextValue !== 0) {
                      result /= nextValue;
                  } else {
                      console.error("Division by zero error!");
                      result = undefined;
                  }
                  break;
              default:
                  console.error("Invalid operator:", numericValues[j - 1]);
                  result = undefined;
          }
      }
  }
  
    }
    var Newwidth = result.toString().length * 10;
    const viewportCenterX = x / scale; // adjust if needed
    const viewportCenterY = y / scale; // adjust if needed
    const svgns = 'http://www.w3.org/2000/svg';
    const viewportCenterW = Newwidth / scale; // adjust if needed
    const viewportCenterH = height / scale; // adjust if needed
    const newTop = viewportCenterY * scale;
    const newLeft = viewportCenterX * scale;
    const newW = viewportCenterW * scale;
    const newH = viewportCenterH * scale;
    const centerX = newLeft + Newwidth / 2;
    const centerY = newTop + height / 2;
    // Create dropdown rectangle
    const dropdownRect = document.createElementNS(svgns, 'rect'); 
    dropdownRect.setAttribute('stroke', 'red');
    dropdownRect.setAttribute('fill', 'white');
    dropdownRect.setAttribute('opacity', '0.75');
    dropdownRect.setAttribute('rx', '5');
    dropdownRect.setAttribute('ry', '5');
    dropdownRect.setAttribute('x', newLeft);
    dropdownRect.setAttribute('y', newTop);
    dropdownRect.setAttribute('width', newW);
    dropdownRect.setAttribute('height', newH);
    dropdownRect.setAttribute('class', 'reactelement');
    dropdownRect.setAttribute('strokeWidth', '0.7746987951807229');
    dropdownRect.setAttribute('data-id', targetid);
    dropdownRect.setAttribute('data-format', format);
    dropdownRect.setAttribute('data-isOwner', isOwner);
    dropdownRect.setAttribute('data-fontColor', fontColor);
    dropdownRect.setAttribute('data-fontSize', fontSize);
    dropdownRect.setAttribute('data-fontFamily', fontFamily);
    dropdownRect.setAttribute('data-fontFormat', fontFormat);;
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute('data-view-name', 'MultiSelectMarquee');
    group.setAttribute('data-view-id', 'c262');
    group.setAttribute('role', 'list');
    group.setAttribute('style', 'cursor: move');
    group.setAttribute('class', 'previwe_user');
    group.setAttribute('data-displayname', names);
    group.setAttribute('data-id',targetid);
    group.addEventListener('mousedown', handleClickObject);
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.setAttribute('x', centerX);
    text.setAttribute('y', centerY);
    text.setAttribute('fill', fontColor);
    text.setAttribute('font-size', fontSize);
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('dominant-baseline', 'middle');
    text.textContent = result
  
    group.appendChild(dropdownRect); 
    group.appendChild(text);   
    svg.appendChild(group);
  }
  
   function createDropDownElements(targetid,format,signContent,page,
    fontColor,fontSize,fontFormat,fontFamily,
    width,height,x,y,isOwner,label ,options,roll,names) {

      const optionsArray = options ? options.split(",") : [];
      const valueArray = label ? label.split(",") : [];
     
    const viewportCenterX = x / scale; // adjust if needed
    const viewportCenterY = y / scale; // adjust if needed
    const svgns = 'http://www.w3.org/2000/svg';
    const viewportCenterW = width / scale; // adjust if needed
    const viewportCenterH = height / scale; // adjust if needed
    const newTop = viewportCenterY * scale;
    const newLeft = viewportCenterX * scale;
    const newW = viewportCenterW * scale;
    const newH = viewportCenterH * scale;
    const centerX = newLeft + width / 2;
    const centerY = newTop + height / 2;
    // Create dropdown rectangle
    const dropdownRect = document.createElementNS(svgns, 'rect'); 
    dropdownRect.setAttribute('stroke', '#ffd65bfc');
    dropdownRect.setAttribute('fill', '#ffd65bfc');
    dropdownRect.setAttribute('opacity', '0.75');
    dropdownRect.setAttribute('rx', '5');
    dropdownRect.setAttribute('ry', '5');
    dropdownRect.setAttribute('x', newLeft);
    dropdownRect.setAttribute('y', newTop);
    dropdownRect.setAttribute('width', newW);
    dropdownRect.setAttribute('height', newH);
    dropdownRect.setAttribute('class', 'reactelement');
    dropdownRect.setAttribute('strokeWidth', '0.7746987951807229');
    dropdownRect.setAttribute('data-id', targetid);
    dropdownRect.setAttribute('data-format', format);
    dropdownRect.setAttribute('data-isOwner', isOwner);
    dropdownRect.setAttribute('data-fontColor', fontColor);
    dropdownRect.setAttribute('data-fontSize', fontSize);
    dropdownRect.setAttribute('data-fontFamily', fontFamily);
    dropdownRect.setAttribute('data-fontFormat', fontFormat);
    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
    foreignObject.setAttribute('x', newLeft);
    foreignObject.setAttribute('y', newTop);
    foreignObject.setAttribute('width', newW);
    foreignObject.setAttribute('height', newH);
    foreignObject.setAttribute('opacity', '0');
    foreignObject.setAttribute('data-error', 'error');
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute('data-view-name', 'MultiSelectMarquee');
    group.setAttribute('data-view-id', 'c262');
    group.setAttribute('role', 'list');
    group.setAttribute('style', 'cursor: move');
    group.setAttribute('class', 'previwe_user');
    group.setAttribute('data-displayname', names);
    group.setAttribute('data-id',targetid);
    group.addEventListener('mousedown', handleClickObject);
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.setAttribute('x', centerX);
    text.setAttribute('y', centerY);
    text.setAttribute('fill', fontColor);
    text.setAttribute('font-size', fontSize);
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('dominant-baseline', 'middle');
    text.textContent = 'select ▼'
    const container = document.createElement('div');
    container.classList.add('container');
    const select = document.createElement('select');
    select.setAttribute('name', 'fruit');
    select.setAttribute('id', targetid);

    optionsArray.forEach((value, index) => {
    const option = document.createElement('option');
    const optionValue = valueArray[index];
    option.setAttribute('value', optionValue.toLowerCase());
    option.textContent = value;
    select.appendChild(option);
});
container.appendChild(select);
    group.appendChild(dropdownRect); 
  
    foreignObject.appendChild(container);
    group.appendChild(text);  
    group.appendChild(foreignObject); 
    svg.appendChild(group);
  }
      function createSignatureElements(
        targetid,format,signContent,page,
        dataFormat,fontColor,fontSize,fontFormat,fontFamily,
        width,height,x,y,names,mails,isOwner,selecttype ,optionnumtype ,valutacode,Max,Min,roll,datalabel
      ){
        const cloneRect = createRectangles(targetid,format,signContent,page,dataFormat,
          width,height,x,y,names,isOwner,fontColor,fontSize,fontFormat,fontFamily,
          selecttype ,optionnumtype ,valutacode,Max,Min,datalabel);
          const inputElement = createInput(format,width,height,x,y,signContent,
            fontSize,fontFormat,fontFamily,roll)
            const text = createText(width,height,x,y)
          const group = createGroups(names,targetid);
            group.appendChild(text);
            group.appendChild(cloneRect);
          if (format === 'title'||format === 'textarea' 
            ||format === 'company'||format === 'number'||format === 'payment') {
            group.appendChild(inputElement);
          }  
            svg.appendChild(group); 
           
            
      }
      function createGroups(names,targetid) {
        // Create and configure a group element
        const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        group.setAttribute('data-view-name', 'MultiSelectMarquee');
        group.setAttribute('data-view-id', 'c262');
        group.setAttribute('role', 'list');
        group.setAttribute('style', 'cursor: move');
        group.setAttribute('class', 'previwe_user');
        group.setAttribute('data-displayname', names);
        group.setAttribute('data-id',targetid);
        group.addEventListener('mousedown', handleClickObject);
        return group;
      }

      function createInput(format,width,height,x,y,signContent,fontSize,fontFormat,fontFamily,roll){
        const viewportCenterX = x / scale; // adjust if needed
        const viewportCenterY = y / scale; // adjust if needed
        
        const viewportCenterW = width / scale; // adjust if needed
        const viewportCenterH = height / scale; // adjust if needed
        const newTop = viewportCenterY * scale;
        const newLeft = viewportCenterX * scale;
        const newW = viewportCenterW * scale;
        const newH= viewportCenterH * scale;
        const inputElement = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
  inputElement.setAttribute('x', newLeft);
  inputElement.setAttribute('y', newTop);
  inputElement.setAttribute('data-input', 'input');
  if (format === 'payment') {
    inputElement.setAttribute('width', newW + 10);
  }else{
    inputElement.setAttribute('width', newW);
  }
  
  inputElement.setAttribute('height', newH);
  
        
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  input.setAttribute('value', signContent); // Set the initial value
  input.style.width = '100%';
  input.style.height = '100%';
  input.style.border = '1px solid red';
  switch (roll) {
    case 'false':
      input.setAttribute('readOnly', 'true');
      break;
    default:
      input.addEventListener('input', handleInputChange);
      break;
  }
  
 
  // Append the input to foreignObject, and foreignObject to the group
  inputElement.appendChild(input);

  return inputElement
      }

      function createText(width,height,x,y) {
    
        const viewportCenterX = x / scale; // adjust if needed
        const viewportCenterY = y / scale; // adjust if needed

        const viewportCenterW = width / scale; // adjust if needed
        const viewportCenterH = height / scale; // adjust if needed
        const newTop = viewportCenterY * scale - 20;
        const newLeft = viewportCenterX * scale;
        const newW = viewportCenterW * scale;
        const newH=  viewportCenterH * scale / 1.8;
    
        // Create a foreignObject element
        const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        foreignObject.setAttribute('x', newLeft);
        foreignObject.setAttribute('y', newTop);
        foreignObject.setAttribute('width', newW);
        foreignObject.setAttribute('height', newH);
        foreignObject.setAttribute('opacity', '0');
        foreignObject.setAttribute('data-error', 'error');
        // Create a div element with background color
        const div = document.createElement('div');
        div.style.width = '100%';
        div.style.height = '100%';
        div.style.backgroundColor = '#ffd65bfc'; // Background color
    
        // Create a span element for the text
        const span = document.createElement('span');
        span.textContent = 'Required field';
        span.style.color = 'black'; // Text color
        span.style.fontSize = '11px';
        span.style.display = 'block';
        span.style.textAlign = 'center';
        span.style.lineHeight = newH + 'px';
    
        // Append the span to the div, and the div to the foreignObject
        div.appendChild(span);
        foreignObject.appendChild(div);
    
        return foreignObject;

  
            
        }
      function createRectangles(
        targetid,format,signContent,page,dataFormat,
        width,height,x,y,names,isOwner,fontColor,fontSize,fontFormat,fontFamily,selecttype ,optionnumtype ,valutacode,Max,Min,datalabel
        ){
        const viewportCenterX = x / scale; // adjust if needed
        const viewportCenterY = y / scale; // adjust if needed

        const viewportCenterW = width / scale; // adjust if needed
        const viewportCenterH = height / scale; // adjust if needed
        const newTop = viewportCenterY * scale;
        const newLeft = viewportCenterX * scale;
        const newW = viewportCenterW * scale;
        const newH= viewportCenterH * scale;
        const cloneRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        cloneRect.setAttribute('stroke', '#ffd65bfc');
        cloneRect.setAttribute('fill', '#ffd65bfc');
        cloneRect.setAttribute('opacity', '0.75');
        cloneRect.setAttribute('rx', '5');
        cloneRect.setAttribute('ry', '5');
        cloneRect.setAttribute('x',newLeft );
        cloneRect.setAttribute('y', newTop);
        cloneRect.setAttribute('width', newW);
        cloneRect.setAttribute('height', newH);;
        cloneRect.setAttribute('data-content',signContent);
        cloneRect.setAttribute('class','reactelement');
        cloneRect.setAttribute('strokeWidth', '0.7746987951807229');
        cloneRect.setAttribute('data-id',targetid);
        cloneRect.setAttribute('data-format', format);
        cloneRect.setAttribute('data-isOwner',isOwner);
        cloneRect.setAttribute('data-fontColor', fontColor);
        cloneRect.setAttribute('data-fontSize', fontSize);
        cloneRect.setAttribute('data-fontFamily', fontFamily);
        cloneRect.setAttribute('data-fontFormat', fontFormat)
        if (format === 'number') {
          cloneRect.setAttribute('data-optionnumtype', optionnumtype);
          cloneRect.setAttribute('data-selecttype', selecttype);
          cloneRect.setAttribute('data-valutacode', valutacode);
          cloneRect.setAttribute('data-max', Max);
          cloneRect.setAttribute('data-min', Min);
          cloneRect.setAttribute('data-label', datalabel);
        }

        
        return cloneRect;
      }
    }
   const previwe_user = document.querySelectorAll('.previwe_user');
   const uniqueElements = {};
   previwe_user.forEach((element) => {
    // Get the data-id attribute value
    const dataId = element.getAttribute('data-id');
  
    // Check if the data-id is not in the uniqueElements object
    if (!uniqueElements[dataId]) {
      // Add the element to the uniqueElements object
      uniqueElements[dataId] = element;
    } else {
      // If the data-id already exists, remove the duplicate element
      element.parentNode.removeChild(element);
    }
  });
  

     // Iterate over the values of uniqueElements
for (const element of Object.values(uniqueElements)) {
  // Get the data-displayname attribute value
  const displayName = element.getAttribute('data-displayname');

  // Check if the data-displayname matches selectedOption
  if (displayName === selectedOption) {
    // Set opacity to 1 if there is a match
    element.setAttribute('opacity', '1');
  } else {
    // Set opacity to 0 for non-matching elements
    element.setAttribute('opacity', '0');
  }
}


// Validation Functions

const handleCheckbox = (evt, firstRect, groupContainerElement, groupElement, ischecked, groupContaineRule, groupContainevalidation, groupContaineMax, groupContaineMin, roll, width, height, x, y,format) => {
  evt.preventDefault();
  const targetRect = evt.target;
  targetRect.setAttribute('fill-opacity', '0');
  targetRect.setAttribute('stroke-opacity', '0');
  

  // Get information about the clicked checkbox
  const targetid = targetRect.id;

  const rectWidth = parseFloat(width);
  const rectHeight = parseFloat(height);
  const centerX = parseFloat(x) + rectWidth / 2;
  const centerY = parseFloat(y) + rectHeight / 2;
  const checkboxes = groupContainerElement.querySelectorAll('rect[data-format="checkbox"]');
  const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.dataset.checked === "true").length + 1;
  const totalCheckbox = checkboxes.length
  const validationCriteria = parseInt(groupContainevalidation);
const minCheckboxCount = parseInt(groupContaineMin);
const maxCheckboxCount = parseInt(groupContaineMax);
  targetRect.setAttribute('data-checked', 'true');

  let isValid = false;
  switch (groupContaineRule) {
      case 'least':
          isValid = checkedCount >= validationCriteria;
          
          break;
      case 'most':
          isValid = checkedCount <= validationCriteria;
          break;
      case 'exactly':
          isValid = checkedCount === validationCriteria;
          break;
      case 'range':
          isValid = checkedCount >= minCheckboxCount && checkedCount <= maxCheckboxCount;
          break;
      default:
          // Handle invalid rule
          break;
  }
  if (isValid) {
    groupContainerElement.setAttribute('data-valid', 'true');
    isValidationSection();
} else {
    groupContainerElement.setAttribute('data-valid', 'false');
}

  // Create a text element to display validation status
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  text.setAttribute('x', centerX);
  text.setAttribute('y', centerY);
  text.setAttribute('font-size', 12); // Use provided fontSize or default to 12
  text.setAttribute('text-anchor', 'middle');
  text.setAttribute('dominant-baseline', 'middle');
  text.setAttribute('fill', 'black'); // Use provided fontColor or default to black
  text.setAttribute('font-family', 'Arial, sans-serif');

  // Perform validation based on the rule
  text.textContent = 'X';

  // Append the text element to the group
  groupElement.appendChild(text);

  // Update the appearance of the clicked checkbox
  
};

async function createInputText(targetid,group,rect,width,height,x,y,signContent,
   fontColor,fontSize,fontFormat,fontFamily){
     
    const padding = 10;
    const foreignObject = group.querySelector('foreignObject');
    if (foreignObject) {
      const textToUpdate  = foreignObject.querySelector('text');
      const input  = foreignObject.querySelector('input');
      if (textToUpdate) {
        const inputRect = input.getBoundingClientRect();
      const inputWidth = inputRect.width;
      const inputHeight = inputRect.height

        const textWidth = textToUpdate.getBBox().width;
        const textHeight = textToUpdate.getBBox().height;
       
        rect.setAttribute('width', textWidth + padding);
        rect.setAttribute('height', textHeight + padding);
         textToUpdate.setAttribute('text-anchor', 'middle'); // Center horizontally
         textToUpdate.setAttribute('dominant-baseline', 'middle');
      }
    }
}

 async function setSignaturePNG(targetid,group,rect,width,height,x,y,signContent,owner){
  if(owner === 'true'){
    rect.setAttribute('opacity', '0');
    const imgageContent  = rect.getAttribute('data-content')
    const imageUrl = `${imgageContent}`;
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttribute('width', width);
    imageElement.setAttribute('height', height);
    imageElement.setAttribute('x', x);
    imageElement.setAttribute('y', y);
    imageElement.setAttribute('href', imageUrl);
    imageElement.setAttribute('data-id',targetid);
    imageElement.setAttribute('class','reactelement')
    group.appendChild(imageElement);
  }else{

   
    setmodal_center(true)

    const newSignatureObj = {
      targetid,
      group,
      rect,
      width,
      height,
      x,
      y,
      signContent,
    };
  
    setSignatureObje(newSignatureObj);
    const validFormats = ['image', 'paraaf', 'stemp'];
    if (!validFormats.includes(signContent)) {
      rect.setAttribute('opacity', '0');
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttribute('width', width);
    imageElement.setAttribute('height', height);
    imageElement.setAttribute('x', x);
    imageElement.setAttribute('y', y);
    imageElement.setAttribute('href', signContent);
    imageElement.setAttribute('data-id',targetid);
    imageElement.setAttribute('class','reactelement');
    group.appendChild(imageElement);
    setmodal_center(false)
    }
  }
}
async function setDrawing(targetid,group,rect,width,height,x,y,signContent,owner){
  setmodal_center(true)

  const newSignatureObj = {
    targetid,
    group,
    rect,
    width,
    height,
    x,
    y,
    signContent,
  };

  setSignatureObje(newSignatureObj);
  if (signContent != 'drawing') {
    rect.setAttribute('opacity', '0');
  const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
  imageElement.setAttribute('width', width);
  imageElement.setAttribute('height', height);
  imageElement.setAttribute('x', x);
  imageElement.setAttribute('y', y);
  imageElement.setAttribute('href', signContent);
  imageElement.setAttribute('data-id',targetid);
  imageElement.setAttribute('class','reactelement');
  group.appendChild(imageElement);
  setmodal_center(false)
  }
}
async function createPdfWithTextAndSvg(
  targetid,
  group,
  rect,
  width,
  height,
  x,
  y,
  signContent,
  fontColor,
  fontSize,
  fontFormat,
  fontFamily
) {
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  // Calculate center position
  var rectWidth = parseFloat(width);
  var rectHeight = parseFloat(height);
  var centerX = parseFloat(x) + rectWidth / 2;
  var centerY = parseFloat(y) + rectHeight / 2;

  rect.setAttribute('opacity', '0');

  // Set font properties
  text.setAttribute('x', centerX);
  text.setAttribute('y', centerY);
  text.setAttribute('font-size', fontSize || 11); // Use provided fontSize or default to 11
  text.setAttribute('text-anchor', 'middle');
  text.setAttribute('dominant-baseline', 'middle');
  text.setAttribute('fill', fontColor || 'black'); // Use provided fontColor or default to black
  text.setAttribute('font-family', fontFamily || 'Arial, sans-serif'); // Use provided fontFamily or default to Arial
  if (fontFormat) {
    const fontFormatValues = fontFormat.toLowerCase().split(' ');

    text.style.fontStyle = fontFormatValues.includes('italic') ? 'italic' : 'normal';
    text.style.textDecoration = fontFormatValues.includes('underline') ? 'underline' : 'none';
    text.style.fontWeight = fontFormatValues.includes('bold') ? 'bold' : 'normal';

    // Add more conditions for other font formatting options as needed
  }
  // Apply additional font properties based on your requirements (e.g., font-weight, font-style)

  text.textContent = signContent;
  group.appendChild(text);
}
isValidationSection()
async function isValidationSection() {
  const finishButton = document.getElementById('finish-button');

  const elementsWithValidData = document.querySelectorAll('[data-valid]');

  let allElementsValid = false;

  // Loop through each element
  elementsWithValidData.forEach(element => {
      // Get the value of the data-valid attribute
      const isValid = element.getAttribute('data-valid') === 'true';

      // Check if the element is valid
      if (isValid) {
          allElementsValid = true;
          // Handle invalid element
        //  console.log('Element is valid:', element);
          // Set error state or perform other actions
      }
  });
if (finishButton) {
  finishButton.style.backgroundColor = allElementsValid ? '#ffc820' : '#ffffff';
}
  // Set the button background color based on overall validity
  
}
  function createformulafunction(
  targetid,group,rect,width,height,x,y,signContent,
  fontColor,fontSize,fontFormat,fontFamily
  ){

  }
  function   createSelectOption(
    targetid,group,rect,text,width,height,x,y,signContent,
    fontColor,fontSize,fontFormat,fontFamily,select
    ){
      select.addEventListener('change', function () {
        // Update rect content
        rect.setAttribute('data-content', this.value);
        const selectedContent = this.options[this.selectedIndex].textContent;

        text.textContent = selectedContent;
    });
    }

  return (
    <div>
      
    <Nav tabs className="nav-tabs-custom nav-justified css-35sd45ul">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                      
                        onClick={() => changeSize('desktop')}
                      >
                      <button 
                      
                     className={`css-it5yrk ${isActive('desktop') ? 'btn-active' : ''}`}
                       >
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" >
<path d="M21 4H3a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h6v2H7v1h10v-1h-2v-2h6a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-8 17h-2v-2h2zm7-4H4v-2h16zm0-3H4V6h16z"></path>
</svg>
<span className="css-guny5b">{t('Preview on computer monitor selected')}</span>
</button>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        onClick={() => changeSize('tablet')}
                      >
                         <button 
                        className={`css-it5yrk ${isActive('tablet') ? 'btn-active' : ''}`}
                         >
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" focusable="false" data-qa="icon-element-tablet">
<path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-3 2v12H7V6zM4 6h2v5H5v2h1v5H4zm16 12h-2V6h2z"></path>
</svg>
<span className="css-guny5b">{t('Preview on tablet')}</span>
</button>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                      
                    onClick={() => changeSize('mobile')}
                      >
          
<button 
 className={`css-it5yrk ${isActive('mobile') ? 'btn-active' : ''}`}
>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true">
<path d="M18 2H6a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-1 2v13H7V4zM7 20v-2h4v1h2v-1h4v2z"></path>
</svg>
<span className="css-guny5b">{t('Preview on mobile phone')}</span>
</button>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  
   <ModalBody>
   <div id='fix-top' className="fixed-top css-ms32d5" style={{ position: 'relative',width: '80%'  }}>
      <div id="top-message" style={{ display: 'none' }}>
        {/* Styles moved to styles.css */}
        <div id="cookie-disclaimer">
          <div>
          {t('This site uses cookies, some of which are required for the operation of the site.')}{' '}
            <a
              href="https://www.docusign.com/company/privacy-policy"
              id="privacy-policy-link"
              target="privacyPolicy"
            >
              {t('Learn more')}
              <span className="screen-reader-text">{t('This link will open in a new window.')}</span>
              <span className="icon icon-external"></span>
            </a>
            <button type="button" id="cookie-disclaimer-accept" className="btn">
            {t('OK')} 
            </button>
          </div>
        </div>
      </div>
      {/* The rest of your HTML goes here */}
      <div id="action-bar-wrapper">
        <div id="action-bar">
          <div className="wrapper" style={{ float: 'right' }}>
            <div id="action-bar-consent-control" style={{ display: 'block' }}>
              <div className="row">
                <div className="cell hidden-xs">
              
                  <div className="css-zx8agq">
                    <button  className="css-gusat5s " id="finish-button" type="button" >
                      <span className="css-vxcmzt">{t('Finish')}</span>
                      </button>
                      </div>
                </div>
                <div className="cell action-bar-consent-message">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='css-23sad4'id='PdfViewerContaine'>
   
      <div className="css-quz66i " >
     
     <div className="css-1sdoc " id='pdf_content' >
    
         
     <div  id='pdf-wrapper' >

     <svg
       xmlns="http://www.w3.org/2000/svg"
       focusable="false"
       viewBox={`0 0 ${pdfContainerX.current} ${totalImageHeight}`}
       width={pdfContainerX.current}
       className="pdf-container"
       id="previwe-container"
       version="1.1"
     >
      <foreignObject 
        width={pdfContainerX.current}
        height={totalImageHeight}
      >
      {imageUrls.map((imageData, index) => (
      <div  key={index}>
          <img
            key={index}
            src={imageData.url}
            className='disable-mouse-events'
            alt={`${t('Page')} ${index + 1}`}
            height={imageData.height - 20} 
          />
        
          </div>
        ))}
      </foreignObject>
     
     </svg>
  
   
       </div>
     </div>


</div>
      </div>
     
    </div>
    </ModalBody>

    <Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">{t('Signature')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <SignaturePad 
                onSignatureData={handleSignatureData}
                 />
                 
                      </div>
                      <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              setmodal_center(false)
                            }
                          >
                           {t('Close')} 
                          </button>
                          <button type="button"
                           className="btn btn-primary"
                           onClick={() =>
                            setSignaturePNG(
                              signatureobj.targetid,
                              signatureobj.group,
                              signatureobj.rect,
                              signatureobj.width,
                              signatureobj.height,
                              signatureobj.x,
                              signatureobj.y,
                              signatureData
                            )
                            
                          }
                            >
                            {t('Save Changes')}
                          </button>
                        </div>
                    </Modal>
    </div>
 
  );
};

export default withTranslation()(withRouter(Preview));