import React, { useState, useEffect,useRef } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate ,useParams } from "react-router-dom";
import { Offcanvas, OffcanvasBody, OffcanvasHeader,Button, Nav, NavItem, NavLink,Collapse,Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { withTranslation } from "react-i18next";
import { showRightSidebarAction, toggleLeftmenu ,createNewDoc, } from "../../store/actions";
import classname from "classnames";
import { v4 as uuidv4 } from 'uuid';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { format } from 'date-fns'
import { useSelector, useDispatch ,connect} from "react-redux";
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
// header backround is scss /cutom/sttucture/topnave
const Header = props => {
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [signature, serSignature] = useState(false);
  const [vacantie, setVakantie] = useState(false);
  const [collaborator, serCollaborator] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const dropdownRef = useRef(null);
  const scheduleDropdownRef = useRef(null);
  const collaboratorDropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [token, setToken] = useState(user.token);
const [userId, setuserId] = useState(user.clientId);
const { permissions} = useUserPermissions();
const { editor, plan_name,template_content = [], } = permissions || {};

  const startNow = async (event) => {
    try {
      const uuid = uuidv4();
      var data = { 
        uuid:uuid,
        token:token,
        userId:userId
       };

      // Dispatch the action to initiate the post request
      await dispatch(createNewDoc(data));
      toastr.success(props.t('Create New Envalop is successfully!'));
      navigate(`/api/create-new/doc/${uuid}`);
      // Additional logic or state updates after success
      
    } catch (error) {
      toastr.error(props.t('The request timed out while creating the envelope. Please try again.'));
      // Additional logic or state updates after failure
    }
  };
  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };
  const handleClickOutside = (event) => {
    if (
      (scheduleDropdownRef.current && !scheduleDropdownRef.current.contains(event.target)) &&
      (dropdownRef.current && !dropdownRef.current.contains(event.target)) &&
      (collaboratorDropdownRef.current && !collaboratorDropdownRef.current.contains(event.target))
    ) {
      serSignature(false);
      setVakantie(false);
      setSchedule(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
   
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      
    };
  }, []);
  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const styles = {
    navbarBrandBox: {
      display: 'flex',
      alignItems: 'center'
    },
    logoSm: {
      display: 'flex',
      alignItems: 'center'
    },
    logoLg: {
      display: 'flex',
      alignItems: 'center'
    },
    brandName: {
      fontSize: '1.5rem', /* Adjust as needed */
      color: 'white', /* Adjust color as needed */
      fontWeight: 'bold', /* Adjust weight as needed */
      marginLeft: '0px' /* Space between logo and brand name */
    },

    
  };
  
  const today = new Date();
  const formattedToday = format(today, 'yyyy-MM-dd'); // Output: 2024-10-30
  const linkPath = `/shift/month/${formattedToday}`;
  const linkPath2 = `/timesheet/month/${formattedToday}`;
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
          <div style={styles.navbarBrandBox}>
      {/* <Link to="/" className="logo logo-dark" style={styles.logoSm}>
        <img src={logo} alt="Logo" height="50" />
        <span className="logo-lg" style={styles.logoLg}>
          <img src={logoDark} alt="Logo Dark" height="50" />
          <span style={styles.brandName}>eSignatureHub</span>
        </span>
      </Link> */}

      <Link to="/" className="logo logo-light" style={styles.logoSm}>
        <img src='https://shiftspec.s3.eu-north-1.amazonaws.com/web-logo+-+dark.png' alt="Logo Light" height="30" />
        {/* <span className="logo-lg" style={styles.logoLg}>
          <img src={logoLight} alt="Logo Light" height="50" />
        </span> */}
        {/* <span className="brand-name" style={styles.brandName}>AnalyticsCargo</span> */}
      </Link>
    </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              onClick={toggleOffcanvas}
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex ">
         <nav
            className="navbar   navbar-expand-lg topnav-menu"
            id="navigation"
          >
            
            <Collapse
  isOpen={props.leftMenu}
  className="navbar-collapse"
  id="topnav-menu-content"
>
  <ul className="navbar-nav">
    <li className="nav-item dropdown">
      <Link
        className="nav-link text-white arrow-none"
        to="/home"
      >
        <i className="bx bx-home-circle text-white me-2"></i>
        {props.t("Home")} {props.menuOpen}
      </Link>
    </li>

    <li className="nav-item">
      <Link
        to="/collaborator"
        className="nav-link text-white arrow-none"
      >
        <i className="bx bx-bot me-2 text-white"></i>
        {props.t("Collaborators")}
      </Link>
    </li>

    <li className="nav-item">
      <Link
        to="/planning/month"
        className="nav-link text-white arrow-none"
        onClick={(e) => {
          e.preventDefault();
          setSchedule(!schedule);
        }}
      >
        <i className="bx bx-book-content me-2 text-white"></i>
        {props.t("Schedule")} {props.menuOpen}
        <div className="arrow-down"></div>
      </Link>
      <div
        ref={dropdownRef}
        onMouseLeave={() => setSchedule(false)}
        style={{ margin: '10px 1px 0px 0px', borderRadius: 'initial' }}
        className={classname("dropdown-menu", { show: schedule })}
      >
        <Link to={linkPath2} className="dropdown-item">
          {props.t("Timesheet")}
        </Link>
        <Link to={linkPath} className="dropdown-item">
          {props.t("Schedule")}
        </Link>
        <Link to="/reports" className="dropdown-item">
          {props.t("Reports")}
        </Link>
      </div>
    </li>

    <li className="nav-item">
      <Link
        to="/report"
        className="nav-link text-white arrow-none"
      >
        <i className="bx bx-book-content me-2 text-white"></i>
        {props.t("Reports")}
      </Link>
    </li>

    {/* Conditionally render Signature */}
    {plan_name !== "starter" && (
      <li className="nav-item">
        <Link
          to="/signature"
          className="nav-link text-white arrow-none"
          onClick={(e) => {
            e.preventDefault();
            serSignature(!signature);
          }}
        >
          <i className="bx bx-line-chart me-2 text-white"></i>
          {props.t("Signature Hub")} {props.menuOpen}
          <div className="arrow-down"></div>
        </Link>
        <div
          ref={dropdownRef}
          onMouseLeave={() => serSignature(false)}
          style={{ margin: '10px 1px 0px 0px', borderRadius: 'initial' }}
          className={classname("dropdown-menu", { show: signature })}
        >
          <Link to="#">
            <Button
              color="Primira"
              onClick={() => startNow()}
              className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn"
            >
              {props.t("Start New Envelope")}
            </Button>
          </Link>
          <Link to="/manage" className="dropdown-item">
            {props.t("Manage")}
          </Link>
          <Link to="/template" className="dropdown-item">
            {props.t("Template")}
          </Link>
          <Link to="/reports" className="dropdown-item">
            {props.t("Reports")}
          </Link>
        </div>
      </li>
    )}

    {/* Conditionally render Fleet */}
    {plan_name !== "starter" && (
      <li className="nav-item">
        <Link
          className="nav-link text-white arrow-none"
          to="/fleet"
        >
          <i className="bx bx-cog me-2 text-white"></i>
          {props.t("Fleet")}
        </Link>
      </li>
    )}

    <li className="nav-item">
      <Link
        className="nav-link text-white arrow-none"
        to="/settings"
      >
        <i className="bx bx-cog me-2 text-white"></i>
        {props.t("Settings")}
      </Link>
    </li>
  </ul>
</Collapse>

          </nav>
           </div>
          <div className="d-flex">
            <LanguageDropdown />
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
               type="button"
               className="btn header-item noti-icon "
               onClick={() => {
                 toggleFullscreen();
               }}
               data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>

      <Offcanvas isOpen={offcanvasOpen} toggle={toggleOffcanvas} direction="start">
        <OffcanvasHeader toggle={toggleOffcanvas}>{props.t("Menu")}</OffcanvasHeader>
        <OffcanvasBody>
          <Nav vertical>
            <NavItem>
              <NavLink tag={Link} to="/home">
                <i className="bx bx-home-circle me-2"></i> {props.t("Home")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/manage">
                <i className="bx bx-bot me-2"></i> {props.t("Manage")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/template">
                <i className="bx bx-book-content me-2"></i> {props.t("Template")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/reports">
                <i className="bx bx-line-chart me-2"></i> {props.t("Reports")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/settings">
                <i className="bx bx-cog me-2"></i> {props.t("Settings")}
              </NavLink>
            </NavItem>
          </Nav>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(Header));
