import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { utils, writeFile } from 'xlsx';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Table,
} from "reactstrap";

import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from "classnames";
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getMailsLists as onGetMailsLists,
  getSelectedMails as onGetSelectedMails,
  updateMail as onUpdateMail,
  accountProfile as onGetAccountProfile,
  editAccountProfile,
  accountInvoice as onGetAccountInvoice,
  getBrandingInfo,
  postBrandingInfo,
  putBrandingInfo,
  deleteBrandingInfo,
} from "store/actions";
import { withTranslation } from "react-i18next";
const WorkerRaport = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = t("Rapport Dashboard | Comprehensive Reporting & Analytics for Workforce Management - ShiftSpec");
  }, [t]);
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
 
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [token, setToken] = useState(user.token);
  const [team, setTeam] = useState(''); // Team selection
  const [department, setDepartment] = useState(''); // Department selection
  const [employer, setEmployer] = useState(''); // Employer selection
  const [contractType, setContractType] = useState(''); // Contract Type selection
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const itemsPerPage = 11; 
  const [currentPage_ab, setCurrentPage_ab] = useState(1);
  const {
    mailslists,
    selectedmails,
    profiles,
    profilesdata,
    invoice,
    info,
    postinfo,
    putinfo,
    deleteinfo,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    selectedmails: state.mails.selectedmails,
    profiles: state.mails.profiles,
    profilesdata: state.mails.profilesdata,
    invoice: state.mails.invoice,
    info: state.mails.info,
    postinfo: state.mails.postinfo,
    putinfo: state.mails.putinfo,
    deleteinfo: state.mails.deleteinfo,
    error: state.mails.error,

  }));
  //meta title
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetMailsLists(account_id));
    dispatch(onGetAccountProfile(account_id));
  }, [dispatch]);
  
  const handleDateChange = (update) => {
    
    setDateRange(update); // Update the date range (start and end)
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value); // Update team selection
  };

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value); // Update department selection
  };

  const handleEmployerChange = (event) => {
    setEmployer(event.target.value); // Update employer selection
  };

  const handleContractTypeChange = (event) => {
    setContractType(event.target.value); // Update contract type selection
  };

  const reports = [
    {
      type: "Team Report",
      name: "Team Performance",
      period: "2023-01-01 to 2023-01-31",
      applyDate: "2023-02-01"
    },
    {
      type: "Department Report",
      name: "Department Summary",
      period: "2023-01-01 to 2023-01-31",
      applyDate: "2023-02-05"
    },
    {
      type: "Contract Report",
      name: "Contract Overview",
      period: "2023-01-01 to 2023-01-31",
      applyDate: "2023-02-10"
    },
    {
      type: "Employer Report",
      name: "Employer Analysis",
      period: "2023-01-01 to 2023-01-31",
      applyDate: "2023-02-12"
    }
  ];

  const totalItems =  reports ? reports.length : 0;
const indexOfLastItem = currentPage_ab * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems =  reports ? reports.slice(indexOfFirstItem, indexOfLastItem): [];
const handlePageChange = (newPage) => {
  setCurrentPage_ab(newPage);
}
  return (
    <React.Fragment>
    <div className="inbox-container">
      {/* Header */}
      <div className="css-rapors32" style={{ padding: '20px' }}>
        <div className="row" style={{ display: 'flex', padding:'10px 0px 10px 15px',background:'#d1d1d1',fontWeight:'700' }}>
        {t('Shift Report')}
        </div>

        {/* Filter Section */}
        <div className="select-row" style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', padding: '10px 0' }}>
          {/* Select for Period */}

          <div className="select-group" style={{ flexGrow: 3 }}>
        <DatePicker
          className="css-12ihcxq"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateChange}
          dateFormat={'yyyy-MM-dd'}
          placeholderText="Select start and end date"
          isClearable={true}
        />
      </div>

      {/* Select for Team */}
      <div className="select-group">
        <select className="css-12ihcxq" id="teamSelect" name="team" value={team} onChange={handleTeamChange}>
          <option value="" disabled>
            Select Team
          </option>
          <option value="team1">Team 1</option>
          <option value="team2">Team 2</option>
        </select>
      </div>

      {/* Select for Department */}
      <div className="select-group">
        <select className="css-12ihcxq" id="departmentSelect" name="department" value={department} onChange={handleDepartmentChange}>
          <option value="" disabled>
            Select Department
          </option>
          <option value="dept1">Department 1</option>
          <option value="dept2">Department 2</option>
        </select>
      </div>

      {/* Select for Employer */}
      <div className="select-group">
        <select className="css-12ihcxq" id="employerSelect" name="employer" value={employer} onChange={handleEmployerChange}>
          <option value="" disabled>
            Select Employer
          </option>
          <option value="emp1">Employer 1</option>
          <option value="emp2">Employer 2</option>
        </select>
      </div>

      {/* Select for Contract Type */}
      <div className="select-group">
        <select className="css-12ihcxq" id="contractTypeSelect" name="contractType" value={contractType} onChange={handleContractTypeChange}>
          <option value="" disabled>
            Select Contract Type
          </option>
          <option value="fullTime">Full Time</option>
          <option value="partTime">Part Time</option>
        </select>
      </div>
        </div>
      </div>

      {/* Report Table */}
      <Row>
        <div className="table-responsive">
          <Table className="align-middle mb-0 table-striped" >
            <thead>
              <tr>
                <th>{t('Type')}</th>
                <th>{t('Name')}</th>
                <th>{t('Period')}</th>
                <th>{t('Apply Date')}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems.map(item => (
                  <tr key={item.applyDate}>
                    <td>{item.type}</td>
                    <td>{item.name}</td>
                    <td>{item.period}</td>
                    <td>{item.applyDate}</td>
                    <td>
                      <button type="button" className="btn btn-light btn-sm" style={{ marginRight: '10px' }}>
                        {t('look at')}
                      </button>
                    </td>
                    <td>
                      <button type="button" className="btn btn-light btn-sm" style={{ marginRight: '10px' }}>
                        {t('Excel')}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    <h6>{t('No Record Found')}</h6>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Row>

      {/* Pagination */}
      <Row>
        <Col xs="7">
          {t('Showing')} {currentPage_ab === 1 ? 1 : (currentPage_ab - 1) * itemsPerPage + 1} -{' '}
          {Math.min(currentPage_ab * itemsPerPage, totalItems)} {t('of')} {totalItems}
        </Col>
        <Col xs="5">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPage_ab === 1}
              onClick={() => handlePageChange(currentPage_ab - 1)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPage_ab === Math.ceil(totalItems / itemsPerPage)}
              onClick={() => handlePageChange(currentPage_ab + 1)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  </React.Fragment>
  );
};

export default withTranslation()(withRouter(WorkerRaport));