import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_SIGNATURES,
    ADD_NEW_SIGNATURES,
    ADD_NEW_HANDWRITING,
    ADD_NEW_STEPM,
    GET_SETTING,
    GET_HELPCENTER,
    GET_BLOGDATA,
    GET_BLOGCATDATA,
  } from "./actionTypes";
 import {
    getSignature,
    getSignatureSuccess,
    getSignatureFail,
    addNewSignature,
    addNewSignatureSuccess,
    addNewSignatureFail,
    addNewHandWriting,
    addNewHandWritingSuccess,
    addNewHandWritingFail,
    addNewStemp,
    addNewStempSuccess,
    addNewStempFail,
    getSettingDataSuccess,
    getSettingDataFail,
    getHelpCenterDataSuccess,
    getHelpCenterDataFail,
    getBlogDataSuccess,
    getBlogDataFail,
    getBlogCatDataSuccess,
    getBlogCatDataFail,
  } from "./actions";

  //Include Both Helper File with needed methods
import {
    postSignature,
    getSignatures,
    postHandwriting,
    postStemp,
    getSettingApi,
    getHelpCenterApi,
    getBlogDataApi,
    getBlogCatApi,
  } from "helpers/fakebackend_helper";
  function* fetchSetData({ payload: setData }) {
    try {
      const response = yield call(getSettingApi, setData);
      yield put(getSettingDataSuccess(response));
    } catch (error) {
      yield put(getSettingDataFail(error));
    }
  }
 
  function* fetchHelpData({ payload: helpCenterData  }) {
    try {
      const response = yield call(getHelpCenterApi, helpCenterData);
      yield put(getHelpCenterDataSuccess(response));
    } catch (error) {
      yield put(getHelpCenterDataFail(error));
    }
  }
  
  function* fetchBlogData({ payload: blogData  }) {
    try {
      const response = yield call(getBlogDataApi, blogData);
      yield put(getBlogDataSuccess(response));
    } catch (error) {
      yield put(getBlogDataFail(error));
    }
  }
 
  function* fetchBlogCatData({ payload: blogCatData  }) {
    try {
      const response = yield call(getBlogCatApi, blogCatData);
      yield put(getBlogCatDataSuccess(response));
    } catch (error) {
      yield put(getBlogCatDataFail(error));
    }
  }



  function* fetchSignature({ signatureid }) {
    try {
      const response = yield call(getSignatures, signatureid);
      yield put(getSignatureSuccess(response));
    } catch (error) {
      yield put(getSignatureFail(error));
    }
  }
  function* onAddNewSignature({ payload: formdata }) {
    try {
      const response = yield call(postSignature, formdata);
      yield put(addNewSignatureSuccess(response));
    } catch (error) {
      yield put(addNewSignatureFail(error));
    }
  }
  function* onAddNewHandWriting({ payload: formdata }) {
    try {
      const response = yield call(postHandwriting, formdata);
      yield put(addNewHandWritingSuccess(response));
    } catch (error) {
      yield put(addNewHandWritingFail(error));
    }
  }
  function* onAddNewStemp({ payload: formdata }) {
    try {
      

      const response = yield call(postStemp, formdata);

      yield put(addNewStempSuccess(response));
    } catch (error) {
      yield put(addNewStempFail(error));
    }
  }
  function* signatureSaga() {
    yield takeEvery(GET_SIGNATURES, fetchSignature);
    yield takeEvery(ADD_NEW_SIGNATURES, onAddNewSignature);
    yield takeEvery(ADD_NEW_HANDWRITING, onAddNewHandWriting);
    yield takeEvery(ADD_NEW_STEPM, onAddNewStemp);
    yield takeEvery(GET_SETTING, fetchSetData);
    yield takeEvery(GET_HELPCENTER, fetchHelpData);
    yield takeEvery(GET_BLOGDATA, fetchBlogData);
    yield takeEvery(GET_BLOGCATDATA, fetchBlogCatData);
  }
      
  export default signatureSaga;
