import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
  } from "reactstrap";
import CustomNavbar from "components/HeaderUnUser/Header";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import PropTypes  from "prop-types";
const RequesDemo = (props) => {
    const { t,i18n  } = props;

  return (
    <React.Fragment>

  
    
    <section className="relative lg:pb-10 pt-20 overflow-hidden bg-white">
  <Row>
    <Col sm={12} className="d-flex flex-column align-items-center justify-content-center text-center">
    <h3 className=" text-dark     mb-4 mt-4">
    {t('Interested? Request a demonstration now without any obligation!')}
      </h3>
      <h2 className=" text-dark  font-extrabold mb-4 mt-4">
      {t('A personal tour of Shiftspec')} 
      </h2>
      <a href='/register/starter'>
        <button
          title="Purchase"
          block
          role="button"
          type="submit"
          className="css-pjhtp2"
          style={{ display: 'block', marginTop: '20px', marginBottom: '20px' }}
        >
          <span className="css-13wylk3">{t('Request a demo')} </span>
        </button>
      </a>
    </Col>
  </Row>
</section>

    </React.Fragment>
  );
};


RequesDemo.propTypes = {
    history: PropTypes.object,
    t: PropTypes.isRequired,
  };

  export default withRouter(withTranslation()(RequesDemo));

