import {
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  POST_VEHICLES,
  POST_VEHICLES_SUCCESS,
  POST_VEHICLES_FAIL,
  PUT_VEHICLES,
  PUT_VEHICLES_SUCCESS,
  PUT_VEHICLES_FAIL,
  DELETE_VEHICLES,
  DELETE_VEHICLES_SUCCESS,
  DELETE_VEHICLES_FAIL,
  GET_VEHICLES_DOC,
  GET_VEHICLES_DOC_SUCCESS,
  GET_VEHICLES_DOC_FAIL,
  POST_VEHICLES_DOC,
  POST_VEHICLES_DOC_SUCCESS,
  POST_VEHICLES_DOC_FAIL,
  PUT_VEHICLES_DOC,
  PUT_VEHICLES_DOC_SUCCESS,
  PUT_VEHICLES_DOC_FAIL,
  DELETE_VEHICLES_DOC,
  DELETE_VEHICLES_DOC_SUCCESS,
  DELETE_VEHICLES_DOC_FAIL,
} from "./actionTypes";

export const getVhicles = getVhiclesdata => ({
  type: GET_VEHICLES,
  getVhiclesdata,
})
export const getVhiclesSuccess = getVhiclesdata => ({
  type: GET_VEHICLES_SUCCESS,
  payload: getVhiclesdata,
})

export const getVhiclesFail = error => ({
  type: GET_VEHICLES_FAIL,
  payload: error,
})

export const postVhicles = postVhiclesdata => ({
  type: POST_VEHICLES,
  payload: postVhiclesdata,
});

export const postVhiclesSuccess = postVhiclesdata => ({
  type: POST_VEHICLES_SUCCESS,
  payload: postVhiclesdata,
});

export const postVhiclesFail = error => ({
  type: POST_VEHICLES_FAIL,
  payload: error,
});

export const putVhicles = putVhiclesdata => ({
  type: PUT_VEHICLES,
  payload: putVhiclesdata
});

export const putVhiclesSuccess = putVhiclesdata => ({
  type: PUT_VEHICLES_SUCCESS,
  payload: putVhiclesdata,
});

export const putVhiclesFail = error => ({
  type: PUT_VEHICLES_FAIL,
  payload: error,
});

export const delVhicles = delVhiclesdata => ({
  type: DELETE_VEHICLES,
  payload: delVhiclesdata
});

export const delVhiclesSuccess = delVhiclesdata => ({
  type: DELETE_VEHICLES_SUCCESS,
  payload: delVhiclesdata
});

export const delVhiclesFail = error => ({
  type: DELETE_VEHICLES_FAIL,
  payload: error,
});




export const getVhiclesDoc = getVhiclesDocdata => ({
  type: GET_VEHICLES_DOC,
  getVhiclesDocdata,
})
export const getVhiclesDocSuccess = getVhiclesDocdata => ({
  type: GET_VEHICLES_DOC_SUCCESS,
  payload: getVhiclesDocdata,
})

export const getVhiclesDocFail = error => ({
  type: GET_VEHICLES_DOC_FAIL,
  payload: error,
})

export const postVhiclesDoc = postVhiclesDocdata => ({
  type: POST_VEHICLES_DOC,
  payload: postVhiclesDocdata,
});

export const postVhiclesDocSuccess = postVhiclesDocdata => ({
  type: POST_VEHICLES_DOC_SUCCESS,
  payload: postVhiclesDocdata,
});

export const postVhiclesDocFail = error => ({
  type: POST_VEHICLES_DOC_FAIL,
  payload: error,
});

export const putVhiclesDoc = putVhiclesDocdata => ({
  type: PUT_VEHICLES_DOC,
  payload: putVhiclesDocdata
});

export const putVhiclesDocSuccess = putVhiclesDocdata => ({
  type: PUT_VEHICLES_DOC_SUCCESS,
  payload: putVhiclesDocdata,
});

export const putVhiclesDocFail = error => ({
  type: PUT_VEHICLES_DOC_FAIL,
  payload: error,
});

export const delVhiclesDoc = delVhiclesDocdata => ({
  type: DELETE_VEHICLES_DOC,
  payload: delVhiclesDocdata
});

export const delVhiclesDocSuccess = delVhiclesDocdata => ({
  type: DELETE_VEHICLES_DOC_SUCCESS,
  payload: delVhiclesDocdata
});

export const delVhiclesDocFail = error => ({
  type: DELETE_VEHICLES_DOC_FAIL,
  payload: error,
});
