import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { map } from "lodash";
import './style.css';
import {
  Table,
  Row,
  Col,
  Card,
  Modal,
  CardBody,
  Button,
  CardTitle,
  Label,
  Input,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux";
import {
  getTempInfo,
  postTempInfo,
  putTempInfo,
  deleteTempInfo,
  getUsersInfo,
  postUserInfo,
  putUserInfo,
  getContractEmployer,
  deleteUserInfo,
  getShiftTeam,
} from "store/actions";
import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import Select from "react-select";

const UserRule = (props) => {
  const { t, permissions } = props;

  const navigate = useNavigate();
  const {  editor,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const {
    rules,
    temps,
    puttemps,
    removeid,
    getuserrule,
    userrule,
    putuserrule,
    removeuser,
    contractemployer,
    loading,
    teamdata,
    
    error
  } = useSelector(state => ({
    teamdata: state.getShiftData.teamdata,
    rules: state.mails.rules,
    temps: state.mails.temps,
    puttemps: state.mails.puttemps,
    removeid: state.mails.removeid,
    getuserrule: state.mails.getuserrule,
    userrule: state.mails.userrule,
    putuserrule: state.mails.putuserrule,
    removeuser: state.mails.removeuser,
    contractemployer: state.getShiftData.contractemployer,
    error: state.mails.error,
    loading: state.mails.loading,
  }));

  const initialPermissions = [
    { value: 'manage', label: t('Manage'), type: t('Signature') },
    { value: 'signature', label: t('Signature'), type: t('Signature') },
    { value: 'post', label: t('Post'), type: t('Signature') },
    { value: 'collaborator', label: t('Collaborator'), type: t('Collaborator') },
    { value: 'contract', label: t('Contract'), type: t('Collaborator') },
    { value: 'absence', label: t('Absence'), type: t('Collaborator') },
    { value: 'setting', label: t('Setting'), type: t('Setting') },
    { value: 'schedule', label: t('Schedule'), type: t('Schedule') },
    { value: 'timesheet', label: t('Timesheet'), type: t('Schedule') },
    { value: 'report', label: t('Report'), type: t('Report') },
    { value: 'fleet', label: t('Fleet'), type: t('Fleet') },
  ];
  //meta title
  const [firstName, setFirstName] = useState('');
  const [template_name , setTemplateName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(null);
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [modal_center, setmodal_center] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [mode, setMode] = useState('create'); // 'create' or 'edit'
  const [hoveredSection, setHoveredSection] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [permissionData, setPermissionData] = useState({
    account_id: account_id,
    template_name: null,
    template_id: null,
    client_id: [],
    team: [],
  });
 
  const [permissionsContext, setPermissions] = useState([]);
  useEffect(() => {
    let updatedPermissions = [...initialPermissions];

    // Exclude permissions based on the plan
    if (plan_name === "starter") {
      // Exclude absence, manage, signature, post, fleet for starter
      updatedPermissions = updatedPermissions.filter(permission =>
        !['absence', 'manage', 'signature', 'post', 'fleet'].includes(permission.value)
      );
    } else if (plan_name === "professional") {
      updatedPermissions = initialPermissions
      // updatedPermissions = updatedPermissions.filter(permission =>
      //   !['absence'].includes(permission.value)
      //);
    } else if (plan_name === "enterprise") {
      // No exclusions for enterprise
      updatedPermissions = initialPermissions;
    }

    setPermissions(updatedPermissions);
  }, [plan_name]);
  const [selectedTempId, setSelectedTempId] = useState('');
  const [selectedTempName, setSelectedTempName] = useState('');
  const [selectedUserTempId, setSelectedUserTempId] = useState({});

  // State for storing the checked states (editor values) for each user
  const [checkedStates, setCheckedStates] = useState({});

  // State for selected status values
  const [selectedStatus, setSelectedStatus] = useState({});

  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4
  useEffect(() => {
    if (teamdata && teamdata.length > 0) {
      const allTeamIds = teamdata.map((team) => team.team_id); // Extract all team_id
      setPermissionData((prev) => ({
        ...prev,
        team: allTeamIds,
      }));
    
    }
  }, [teamdata]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (account_id) {
      dispatch(getTempInfo(account_id));
      dispatch(getUsersInfo(account_id))
      dispatch(getContractEmployer(account_id));
      dispatch(getShiftTeam(account_id));
    }
    
  }, [dispatch, account_id]);
 
  // Calculate the indices of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRules =  Array.isArray(rules) ? rules.slice(startIndex, endIndex) : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Handle page change


  function tog_center_Add() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedPermissions(prevPermissions => {
      // Ensure prevPermissions is an array
      const currentPermissions = Array.isArray(prevPermissions) ? prevPermissions : [];
      if (!checked) {
        // When unchecked (checked is false), add name to the array
        if (!currentPermissions.includes(name)) {
          return [...currentPermissions, name];
        }
      } else {
        // When checked (checked is true), remove name from the array
        return currentPermissions.filter(permission => permission !== name);
      }
  
      // Return currentPermissions if no changes are needed
      
    });
  };
  
  


  const handleCreateNewTemplate = () => {
    setTemplateName('');
    setSelectedPermissions([]);
    setMode('create');
    setCurrentTemplate(null);
  };

  const handleEditTemplate = (template) => {  
    // Parse template_content from JSON
    const { template_id, template_name, template_content } = template;
  
    let permissions = [];
    try {
      permissions = template_content || [];
    } catch (error) {
      console.error('Failed to parse template_content:', error);
    }

    setTemplateName(template_name);
    setSelectedPermissions(permissions);
    setCurrentTemplate({
      id: template_id,
      name: template_name
    });
    setMode('edit');
  };
  

  const handleDeleteTemplate = (template) => {
    // Parse template_content from JSON
    const { template_id, template_name, template_content } = template;
    dispatch(deleteTempInfo(template_id));
   
  };
  
const handleTemplateSubmit = async () => {
  try {
    if (!template_name.trim()) {
      toastr.error(t('Template name cannot be empty.'));
      return;
    }
    const data = {
      account_id,
      template_name,
      selectedPermissions,
    };
    if (mode === 'create') {
      dispatch(postTempInfo(data));
      
    } else if (mode === 'edit' && currentTemplate) {
      const updatedData = {
        ...data,
        template_id: currentTemplate.id,
      };
     
      dispatch(putTempInfo(updatedData));
    }

  } catch (error) {
    console.error('Error saving template:', error);
  }
};

const handleTemplateChange = (event) => {
  const selectedId = event.target.value;
  const selectedName = event.target.options[event.target.selectedIndex].dataset.name;
 
  setSelectedTempId(selectedId);
  setSelectedTempName(selectedName);
  setPermissionData({
    ...permissionData,
    template_id: selectedId,
    template_name: selectedName,
  });
};


const handleUserSelection = (userId) => {
  if (selectedUsers.includes(userId)) {
    const updatedUserIds = selectedUsers.filter(id => id !== userId);
    setSelectedUsers(updatedUserIds);
    setPermissionData({
      ...permissionData,
      client_id: updatedUserIds,
    });
  } else {
    const updatedUserIds = [...selectedUsers, userId];
    setSelectedUsers(updatedUserIds);
    setPermissionData({
      ...permissionData,
      client_id: updatedUserIds,
    });
  }
};


const validateForm = () => {
  const newErrors = {};

  if (!permissionData.account_id) newErrors.account_id = t('Account ID is required.');
  if (!permissionData.template_name) newErrors.eind_time = t('Template name is required.');
  if (!permissionData.template_id) newErrors.template_id = t('Template ID is required.');  
  if (!permissionData.client_id) newErrors.client_id = t('client ID is required.');  
  return newErrors // Returns true if there are no errors
};
const handleSubmit = (event) => {
  event.preventDefault();

  const validationErrors = validateForm();
  if (Object.keys(validationErrors).length > 0) {
    Object.values(validationErrors).forEach((error) => toastr.error(error));
    // Proceed with form submission or further logic
  } else {
   
  dispatch(postUserInfo(permissionData));
  }


  
   
};



useEffect(() => {
  if (!Array.isArray(getuserrule)) {
    console.error('getuserrule is not an array:', getuserrule);
    return; // Exit early if data is not in expected format
  }

  if (getuserrule.length > 0) {
    const initialStates = getuserrule.reduce((acc, user) => {
      if (user.client_id) {
        acc[user.client_id] = {
          editor: user.editor === '1' ? 'true' : 'false',
          templateId: user.template_id,
          status: user.client_status,
          team: user.team,
          templateName: user.template,
          
        };
      }
      return acc;
    }, {});
    setCheckedStates(initialStates);
    setSelectedUserTempId(initialStates);
    setSelectedStatus(initialStates);
  }
}, [getuserrule]);
const handleEditorChange = (e, clientId) => {
  const isChecked = e.target.checked ? 'false' : 'true';
  setCheckedStates(prevState => ({
    ...prevState,
    [clientId]: {
      ...prevState[clientId],
      editor: isChecked
    }
  }));
};


const handleTemplateUserChange = (event, clientId) => {
  const selectedId = event.target.value;
  const selectedName = event.target.options[event.target.selectedIndex].dataset.name;
  setCheckedStates(prevState => ({
    ...prevState,
    [clientId]: {
      ...prevState[clientId],
      templateId: selectedId,
      templateName: selectedName
    }
  }));
};

const optionGroup = teamdata.map((team) => ({
  value: team.team_id,
  label: team.team_name,
}));

const getDefaultSelectedOptions = (teamString) => {
  if (!teamString) return []; // Handle empty or undefined team string
  const teamIds = teamString.split(',').map((id) => id.trim()); // Split and trim to clean up the data
  // Filter valid options based on the team IDs
  return optionGroup.filter((option) => {
    return teamIds.includes(option.value?.toString()); // Safely handle undefined or null values
  });
};
const handleTeamChange = (selectedOptions, clientId) => {
  const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];

  setCheckedStates((prevStates) => ({
    ...prevStates,
    [clientId]: {
      ...prevStates[clientId],
      team: selectedValues, // Store team IDs as an array
    },
  }));
};

const handleStatusChange = (e, clientId) => {
  e.preventDefault();
  const status = e.target.value;
  setCheckedStates(prevState => ({
    ...prevState,
    [clientId]: {
      ...prevState[clientId],
      status: status,
     
    }
  }));
  
};

const handleUpdateClick = (user) => {

   let editor
   if (checkedStates[user.client_id]?.editor === 'false') {
    editor = 0
   }else{
    editor = 1
   }
  
   const updatedData = {
    template_id: checkedStates[user.client_id]?.templateId || '',
    editor: editor,
    client_status: checkedStates[user.client_id]?.status || '',
    template_name: checkedStates[user.client_id]?.templateName || '',
    team: checkedStates[user.client_id]?.team || '',
    client_id: user.client_id,
    account_id: account_id,
  };
 
 dispatch(putUserInfo(updatedData));
};


const handleRemoveClick = (clientId) => {
  
  if (window.confirm(t('Are you sure you want to remove this user?'))) {
    dispatch(deleteUserInfo(clientId));
  }
};

const divStyle = {
  display: "flex", 
  margin: "16px 0px", 
};

const groupedPermissions = permissionsContext.reduce((acc, permission) => {
  // Check if the type group already exists
  if (!acc[permission.type]) {
    acc[permission.type] = [];
  }
  // Add the permission to the corresponding type group
  acc[permission.type].push(permission);
  return acc;
}, {});
  
const handleMouseEnter = (section) => setHoveredSection(section);
  const handleMouseLeave = () => setHoveredSection(null);


  useEffect(() => {
    if (temps && temps.success) {
      toastr.success(t('Template Setup Complete'));
      setTemplateName('');
      setSelectedPermissions([]);
      dispatch(getTempInfo(account_id));
    }
    
  }, [temps]);
  
  useEffect(() => {
    if (puttemps && puttemps.success) {
      toastr.success(t('Template Modification Successful'));
      dispatch(getTempInfo(account_id));  // Dispatch only once
    }
   
  }, [puttemps, account_id]);
  
  useEffect(() => {
    if (removeid && removeid.success) {
      toastr.success(t('Template Deletion Completed'));
      dispatch(getTempInfo(account_id));  // Dispatch only once
    }
   
  }, [removeid, account_id]);
  
  useEffect(() => {
    if (userrule && userrule.success) {
      toastr.success(t('Permissions Set Successfully'));
      setSelectedTempId('');
      setSelectedTempName('');
      setmodal_center(false);
      dispatch(getUsersInfo(account_id));  // Dispatch only once
      dispatch(getTempInfo(account_id));   // Dispatch only once
    }
  
  }, [userrule, account_id]);
  
  useEffect(() => {
    if (removeuser && removeuser.success) {
      toastr.success(t('You Did It!'));
      dispatch(getUsersInfo(account_id));  // Dispatch only once
      dispatch(getTempInfo(account_id));   // Dispatch only once
    }
   
  }, [removeuser, account_id]);
  
  useEffect(() => {
    if (putuserrule && putuserrule.success) {
      toastr.success(t('Permissions Applied'));
      dispatch(getUsersInfo(account_id));  // Dispatch only once
      dispatch(getTempInfo(account_id));   // Dispatch only once
    }
   
  }, [putuserrule, account_id]);
  
  return (
    <React.Fragment>
        <Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center_Add();
                      }}
                      centered
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">{t('Welcome New User')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <Card >
      <CardBody>
        <CardTitle>{t('Essential Information')}</CardTitle>
        <form onSubmit={handleSubmit}>
          <div className="css-list">
          <label htmlFor="selectTemp" className="css-iekl2y">
          {t('Template Options')}  
              </label>
          <div className="css-q10huv">
          <div className="css-hboir5">
          <select
         className="css-12ihcxq" 
         id="selectTemp"
         defaultValue={selectedTempId}
         onChange={handleTemplateChange}
       >
          <option value="">{t('Template Options')}</option>
     {rules && rules.length > 0 ? (
      
    rules.map((rule,index) => (
      
      <option key={index} value={rule.template_id} data-name={rule.template_name}>
        {rule.template_name}
      </option>
    ))
  ) : (
    <option disabled>{t('We Could not Find Any Templates')}</option>
  )}
        </select>
              <span className="css-1baizyq">
                  <span className="css-lzaifp">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                      </svg>
                  </span>
              </span>
          </div>
      </div>
   
      <div className="mb-3 row medewerkers" id="medewerkers">
        <div className="col-md-12" style={{ background: "aliceblue" }}>
        <small>
        {t('All employees listed here are already under contract. Those who are not under contract will not appear in the selection options.')}
        </small>
        <ul className="plane_grid_pay_plane" id="plane_grid_pay_plane" style={{padding:'unset'}}>
  {contractemployer && contractemployer.length > 0 ? (
    contractemployer.map(client => (
      <li key={client.client_id} className={`card_pay_plane`}>
        <div>
          <img
            className="rounded-circle header-profile-user_plane"
            src={client.profile_img}
            alt="Avatar"
          />
        </div>
        <div className="card__content_pay_plane">
          <span>{`${client.frist_name} ${client.last_name}`}</span>
        </div>
        <label className="checkbox-control_pay_plane">
          <p className="time_cost">{client.service_type}</p>
          <p className="time_cost">{client.client_status}</p>
          <Input
            type="checkbox"
            className="checkbox_pay_plane plane_checkbox_pay_plane"
            defaultChecked = {false}
            onChange={() => handleUserSelection(client.client_id)}
          />
          <span className="checkbox-control__target_pay_plane"></span>
        </label>
      </li>
    ))
  ) : (
    <p>{t('No clients found.')}</p>
  )}
</ul>

        </div>
      </div>

        
           
            <button  style={{marginTop:'10px'}} type="submit" className=" btn  css-1bl3roz startbtn btn btn-Primira"> {t('Submit')}</button>
          </div>
        </form>
      </CardBody>
    </Card>
                      </div>
                    </Modal>
    <div style={{padding:'20px'}} >
    <h2 className="mt-4">{t('Templates')}</h2>
    <div className="row" style={{ padding: '20px' }}>
      <div className="col-md-4">
        <h6 className="mt-4">{t('Templates list')}</h6>
        <Card className="email-leftbar">
        {isEditorAllowed && (
      <Button
        color="Primira"
        style={{ backgroundColor: '#239933' }}
        className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn"
        onClick={handleCreateNewTemplate}
      >
        <svg
          className="SVGInline-svg"
          style={{ position: 'relative', right: '10px' }}
          width="14px"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          focusable="false"
        >
          <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
        </svg>
        <span data-qa="submit-username-text" className="css-1ixbp0l d-none d-md-block">{t('Create Template')}</span>
      </Button>
    )}

         <div className="mail-list mt-1">
  {paginatedRules && paginatedRules.length > 0 ? (
    paginatedRules.map((item, index) => (
      <div key={item.template_id || index} className="template-row">
        <span className="template-name">{item.template_name}</span>
        {isEditorAllowed && (
          <div className="template-actions">
            <button className="icon-button" onClick={() => handleDeleteTemplate(item)}>
              <span className="mdi mdi-trash-can text-danger"></span>
            </button>
            <button className="icon-button" onClick={() => handleEditTemplate(item)}>
              <span className="mdi mdi-pencil-box text-info"></span>
            </button>
          </div>
        )}
      </div>
    ))
  ) : (
    <p>{t('No Templates Available')}</p>
  )}
</div>

        <Row style={{marginTop:'10px'}}>
        
        <Col xs="12" className="col-sm-12 col-md-7">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={() => paginate(currentPage < Math.ceil(rules.length / itemsPerPage) ? currentPage + 1 : currentPage)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
        
        </Row>
        <Col xs="12">{t('Showing')} {startIndex + 1} - {endIndex} {t('of')} {rules.length}</Col>
        </Card>
       
      </div>
      <div className="col-md-8">
        <div className="row" style={{ padding: '20px' }}>
        <div className="css-list">
        
     
            <div>
            <div style={divStyle}>
      <div>
      <div className="css-1e0bg48">
    <Label
      className="form-check-label"
      htmlFor="css3tgHxyM33A"
      >
    <span>
    <span className="css-1cbszci">{t('Template name')}</span>
</span>
</Label>
</div>
      </div>
      {hoveredSection === 'templateName' && (
        <div className="css-3nkkse" id="css-3nkkse">
          <p className="css-1sad4s">
          {t('As the administrator, you can Create and manage permission groups and departments Once you are done, it will be saved in your account.')}  
          </p>
        </div>
      )}
     
      <div
     className={`qouteStyle ${hoveredSection === 'templateName' ? "active" : ""}`}
     onMouseEnter={() => handleMouseEnter('templateName')}
        onMouseLeave={handleMouseLeave}
         >

  <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
  <span className="css-1rpan28">
    <span className="css-zf56fy">
      <span aria-hidden="true" className="SVGInline">
        <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
  <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
</svg>
</span>
</span>
</span>
<span className="css-1pdgtej">{t('Learn more')}</span>
</button>
</div>
    </div>
            
      
              <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="template_name "
                  value={template_name}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>
            </div>

          </div>
          <div style={divStyle}>
      <div>
      <div className="css-1e0bg48">
    <Label
      className="form-check-label"
      htmlFor="css3tgHxyM33A"
      >
    <span>
    <span className="css-1cbszci">{t('Permissions Context')}</span>
</span>
</Label>
</div>
      </div>
      {hoveredSection === 'permissionsContext' && (
        <div className="css-3nkkse" id="css-3nkkse">
          <p className="css-1sad4s">
          {t('Check what permissions you need from the list below. This menu allows you to manage and view different features.')} 
          </p>
        </div>
      )}
     
      <div
     className={`qouteStyle ${hoveredSection === 'permissionsContext' ? "active" : ""}`}
      onMouseEnter={() => handleMouseEnter('permissionsContext')}
        onMouseLeave={handleMouseLeave}
         >

  <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
  <span className="css-1rpan28">
    <span className="css-zf56fy">
      <span aria-hidden="true" className="SVGInline">
        <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
  <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
</svg>
</span>
</span>
</span>
<span className="css-1pdgtej">{t('Learn more')}</span>
</button>
</div>
    </div>
          <div className="checkbox-group_c">
            
          {Object.entries(groupedPermissions).map(([type, permissions]) => (
        <div key={type} className="permission-group">
          <h6 style={{textTransform:'capitalize'}}>{type}</h6>
      
          {permissions.map(permission => (
            <div key={permission.value} className="permission-checkbox_c">
              <input
                type="checkbox"
                id={permission.value}
                name={permission.value}
                value={permission.value}
                checked = {selectedPermissions.includes(permission.value)}
               
                onClick={handleCheckboxChange}
                
              />
              <label style={{ position: 'relative', top: '4px' }} htmlFor={permission.value}>
                {permission.label}
              </label>
            </div>
          ))}
        </div>
      ))}
          </div>
        </div>
        <Card>
        {isEditorAllowed && (
      <Button
        color="Primira"
        style={{ backgroundColor: '#1e5ce2' }}
        className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn"
        onClick={handleTemplateSubmit}
      >
        <svg
          className="SVGInline-svg"
          style={{ position: 'relative', right: '10px' }}
          width="14px"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          focusable="false"
        >
          <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
        </svg>
        <span data-qa="submit-username-text" className="css-1ixbp0l d-none d-md-block">
          {mode === 'create' ? t('Save Template') : t('Update Template')}
        </span>
      </Button>
    )}
        </Card>
      </div>
    </div>
    <div className="card-header-c">
      
  <div className="card-title-group-c">
    <h4 className="card-title">{t('Permission Rules')}</h4>
    <p className="card-title-desc">
    {t('Content permissions: Locked including nested projects')}  
    </p>

    
  </div>
  {isEditorAllowed && (
      <button
        className="btn btn-primary btn-sm"
        onClick={tog_center_Add}
      >
       {t('Welcome New User')}
      </button>
    )}

   
</div>
<div className="table-responsive">
<Table className="align-middle mb-0 table">
  <thead>
    <tr>
      <th>#</th>
      <th>{t('User')}</th>
      <th>{t('Template')}</th>
      <th>{t('Editor')}</th>
      <th>{t('Status')}</th>
      <th>{t('Team')}</th>
      <th>{t('Action')}</th>
      <th>{t('Delete')}</th>
    </tr>
  </thead>
  <tbody>
  {getuserrule && getuserrule.length > 0 ? (
    getuserrule.map((user, index) => {
      const defaultSelectedOptions = getDefaultSelectedOptions(user.team); // Get the default selected options
      return (
      <tr key={index}>
        {/* Row Number */}
        <th scope="row" data-label="#">{index + 1}</th>

        {/* User Name */}
        <td data-label={t('User')}>
          <div className="d-flex relative justify-content-center" style={{ bottom: '4px' }}>
            {user.frist_name}
          </div>
        </td>

        {/* Template Dropdown */}
        <td data-label={t('Template')}>
          <div className="css-q10huv">
            <div className="css-hboir5">
              <select
                className="css-12ihcxq"
                id={`selectTemp-${user.client_id}`}
                value={checkedStates[user.client_id]?.templateId || ''}
                onChange={(e) => handleTemplateUserChange(e, user.client_id)}
              >
                {rules && rules.length > 0 ? (
                  rules.map((rule, ruleIndex) => (
                    <option key={ruleIndex} value={rule.template_id} data-name={rule.template_name}>
                      {rule.template_name}
                    </option>
                  ))
                ) : (
                  <option disabled>{t('No Templates Available')}</option>
                )}
              </select>
              <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        </td>

        {/* Editor Switch */}
        <td data-label={t('Editor')}>
          <div className="d-flex relative justify-content-center" style={{ bottom: '11px' }}>
            <label className="switch_c flat_c">
              <input
                type="checkbox"
                defaultChecked={checkedStates[user.client_id]?.editor === 'true'}
                onChange={(e) => handleEditorChange(e, user.client_id)}
              />
              <span className="slider_c"></span>
            </label>
          </div>
        </td>

        {/* Status Dropdown */}
        <td data-label={t('Status')}>
          <div className="css-q10huv">
            <div className="css-hboir5">
              <select
                className="css-12ihcxq"
                id={`selectStatus-${user.client_id}`}
                value={checkedStates[user.client_id]?.status || ''}
                onChange={(e) => handleStatusChange(e, user.client_id)}
              >
                <option value="" disabled>{t('Select Status')}</option>
                <option value="active">{t('Active')}</option>
                <option value="pending">{t('Pending')}</option>
                <option value="close">{t('Close')}</option>
                <option value="block">{t('Block')}</option>
              </select>
              <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        </td>

        {/* Team Multi-Select */}
        <td data-label={t('Team')}>
          <Select
            defaultValue={defaultSelectedOptions} // Ensure this is a valid array
            isMulti
            onChange={(selectedOptions) => handleTeamChange(selectedOptions, user.client_id)}
            options={optionGroup} // Options for teams
            className="select2-selection"
            placeholder={t("Select Teams")}
            menuPortalTarget={document.body} // Ensure dropdown renders above table
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </td>

        {/* Action Button */}
        <td data-label={t('Action')}>
          <div className="d-flex relative justify-content-center" style={{ bottom: '5px' }}>
            {isEditorAllowed && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => handleUpdateClick(user)}
              >
                {t('Update')}
              </button>
            )}
          </div>
        </td>

        {/* Delete Button */}
        <td data-label={t('Delete')}>
          <div className="d-flex relative justify-content-center" style={{ bottom: '5px' }}>
            {isEditorAllowed && (
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onMouseDown={() => handleRemoveClick(user.client_id)}
              >
                {t('Remove')}
              </button>
            )}
          </div>
        </td>
      </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="7">{t('No users available')}</td>
    </tr>
  )}
</tbody>

</Table>

</div>
      
    </div>
  </React.Fragment>
  )
}


export default withTranslation()(withRouter(UserRule));