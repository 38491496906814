import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import signatureSaga from "./signature/saga";
import documentsSaga from "./document/saga";
import DocumentInfoSaga from "./prepare/saga";
import mailsSaga from "./mails/saga";
import ClientSaga from "./collaborator/saga";
import ShiftSaga from "./shift/saga";
import AbsenceSaga from "./absence/saga";
import ReportSaga from "./report/saga";
import VhiclesSaga from "./Fleet/saga";
export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(signatureSaga),
    fork(DocumentInfoSaga),
    fork(mailsSaga),
    fork(documentsSaga),
    fork(ClientSaga),
    fork(ShiftSaga),
    fork(AbsenceSaga),
    fork(ReportSaga),
    fork(VhiclesSaga),
  ]);
}
