/* SIGNATURES */
export const GET_COLLABORATOR = "GET_COLLABORATOR"
export const GET_COLLABORATOR_SUCCESS = "GET_COLLABORATOR_SUCCESS"
export const GET_COLLABORATOR_FAIL = "GET_COLLABORATOR_FAIL"
/**
 * Signature IMG 
 */
export const POST_COLLABORATOR = "POST_COLLABORATOR"
export const POST_COLLABORATOR_SUCCESS = "POST_COLLABORATOR_SUCCESS"
export const POST_COLLABORATOR_FAIL = "POST_COLLABORATOR_FAIL"

export const PUT_COLLABORATOR = "PUP_COLLABORATOR"
export const PUT_COLLABORATOR_SUCCESS = "PUP_COLLABORATOR_SUCCESS"
export const PUT_COLLABORATOR_FAIL = "PUP_COLLABORATOR_FAIL"

export const GET_CONTRACT = "GET_CONTRACT"
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS"
export const GET_CONTRACT_FAIL = "GET_CONTRACT_FAIL"

export const POST_CONTRACT = "POST_CONTRACT"
export const POST_CONTRACT_SUCCESS = "POST_CONTRACT_SUCCESS"
export const POST_CONTRACT_FAIL = "POST_CONTRACT_FAIL"


export const GET_XLSDATA = "GET_XLSDATA"
export const GET_XLSDATA_SUCCESS = "GET_XLSDATA_SUCCESS"
export const GET_XLSDATA_FAIL = "GET_XLSDATA_FAIL"



export const POST_DEP = "POST_DEP"
export const POST_DEP_SUCCESS = "POST_DEP_SUCCESS"
export const POST_DEP_FAIL = "POST_DEP_FAIL"

export const PUT_DEP = "PUT_DEP"
export const PUT_DEP_SUCCESS = "PUT_DEP_SUCCESS"
export const PUT_DEP_FAIL = "PUT_DEP_FAIL"

export const DELETE_DEP = "DELETE_DEP"
export const DELETE_DEP_SUCCESS = "DELETE_DEP_SUCCESS"
export const DELETE_DEP_FAIL = "DELETE_DEP_FAIL"

export const POST_TEAM = "POST_TEAM"
export const POST_TEAM_SUCCESS = "POST_TEAM_SUCCESS"
export const POST_TEAM_FAIL = "POST_TEAM_FAIL"

export const PUT_TEAM = "PUT_TEAM"
export const PUT_TEAM_SUCCESS = "PUT_TEAM_SUCCESS"
export const PUT_TEAM_FAIL = "PUT_TEAM_FAIL"

export const DELETE_TEAM = "DELETE_TEAM"
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS"
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL"


export const POST_CONTRACT_TYPE = "POST_CONTRACT_TYPE"
export const POST_CONTRACT_TYPE_SUCCESS = "POST_CONTRACT_TYPE_SUCCESS"
export const POST_CONTRACT_TYPE_FAIL = "POST_CONTRACT_TYPE_FAIL"

export const PUT_CONTRACT_TYPE = "PUT_CONTRACT_TYPE"
export const PUT_CONTRACT_TYPE_SUCCESS = "PUT_CONTRACT_TYPE_SUCCESS"
export const PUT_CONTRACT_TYPE_FAIL = "PUT_CONTRACT_TYPE_FAIL"

export const DELETE_CONTRACT_TYPE = "DELETE_CONTRACT_TYPE"
export const DELETE_CONTRACT_TYPE_SUCCESS = "DELETE_CONTRACT_TYPE_SUCCESS"
export const DELETE_CONTRACT_TYPE_FAIL = "DELETE_CONTRACT_TYPE_FAIL"


export const POST_LINK = "POST_LINK"
export const POST_LINK_SUCCESS = "POST_LINK_SUCCESS"
export const POST_LINK_FAIL = "POST_LINK_FAIL"

export const PUT_COLLABORATOR_DOC = "PUT_COLLABORATOR_DOC"
export const PUT_COLLABORATOR_DOC_SUCCESS = "PUT_COLLABORATOR_DOC_SUCCESS"
export const PUT_COLLABORATOR_DOC_FAIL = "PUT_COLLABORATOR_DOC_FAIL"


export const POST_DOCUMENT = "POST_DOCUMENT"
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS"
export const POST_DOCUMENT_FAIL = "POST_DOCUMENT_FAIL"


export const PUT_COLLABORATOR_PROFILE = "PUT_COLLABORATOR_PROFILE"
export const PUT_COLLABORATOR_PROFILE_SUCCESS = "PUT_COLLABORATOR_PROFILE_SUCCESS"
export const PUT_COLLABORATOR_PROFILE_FAIL = "PUT_COLLABORATOR_PROFILE_FAIL"