import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Label,
    Input,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
  import img4 from "../../assets/images/upload_png.png";
  import { Link, useNavigate ,useParams } from "react-router-dom";
  import { useSelector, useDispatch } from "react-redux";
  import toastr from "toastr";
  import "toastr/build/toastr.min.css";
  import axios from "axios";
  import { v4 as uuidv4 } from 'uuid';
  import {
    getSignatureImgByEmail,
    getSignatureImgByEmailSuccess,
    getSignatureImgByEmailFail,
    getDocument,
    getDocumentSuccess,
    getDocumentFail,
    postSigndata,
    postSigndataFail,
    postSigndatatSuccess,
  } from "store/actions";
  import { createSelector } from "reselect";


const ActionDropDown = (props) => {
  const { t, selectedZoom,docname ,permissions} = props;
  const {
    jsonpostdata,

    error
  } = useSelector(state => ({
    jsonpostdata: state.Prepare.jsonpostdata,
   
    error: state.Prepare.error,
  }));
  const {  editor,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
    useEffect(() => {
        toastr.options = {
          positionClass: 'toast-top-center',
          closeButton: true,
          debug: true,
          progressBar:true,
          timeOut: 30000, // Duration in milliseconds
        };
      }, []);
    const dispatch = useDispatch();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [fristname, setFristname] = useState(user.firstName);
  const [lastname, setLastname] = useState(user.lastName);
  const fullNames = `${fristname} ${lastname}`;
  const [userId, setuserId] = useState(user.clientId);
  const [token, settoken] = useState(user.account_token);
  const [togModal2, setTogModal2] = useState(false);
  const [togModal3, setTogModal3] = useState(false);
  const [togModal4, setTogModal4] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { uuid } = useParams();
  const [recipientNames, setRecipientNames] = useState([]);
  const [pdftoimage, setpdftoimage] = useState([]);
  const [recipientEmails, setRecipientEmail] = useState([])
  const [recipientMsg, setRecipientMsg] = useState([])
  const [recipientroll, setRecipientRoll] = useState([]);
  const [recipienid, setRecipienid] = useState([]);
  const [Colors, setColor] = useState([]);
  const [docNames, setDocNames] = useState([]);
  const [recipientData, setRecipientData] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowColors, setRowColors] = useState(['#c30707']);
  const generateUniqueId = () => {
    return uuidv4();
  };
  const handleToggleDropdown = () => {
    if (isEditorAllowed) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };
 

  useEffect(() => {
    if (docname && docname.length > 0) {
      const extractedData = docname.reduce(
        (acc, item) => {
          if (item.recipient_data !== null) {
            const recipients = JSON.parse(item.recipient_data);
            setRecipientData(recipients)
            if (recipients !== null) {
              recipients.forEach((recipient) => {
                acc.recipientNames.push(recipient.recipientName);
                acc.recipientEmail.push(recipient.recipientEmail);
                acc.recipientMsg.push(recipient.recipientMsg);
                acc.recipientroll.push(recipient.recipientroll);
                acc.Colors.push(recipient.color);
                acc.recipienid.push(recipient.recipienid);
                
              });
            }
          }
          return acc;
        },
        {
          recipientEmail: [],
          recipientMsg: [],
          recipientroll: [],
          Colors: [],
          recipientNames: [],
          recipienid: [],
        }
      );
    
      // Check if extractedData is not empty before setting state variables
      if (Object.keys(extractedData).length !== 0) {
        setpdftoimage(docname[0].pdfpic);
        setDocNames(docname[0].doc_name);
        setRecipientNames(extractedData.recipientNames);
        setRecipientEmail(extractedData.recipientEmail);
        setRecipientMsg(extractedData.recipientMsg);
        setRecipientRoll(extractedData.recipientroll);
        setColor(extractedData.Colors);
        setRecipienid(extractedData.recipienid);
      }
    }
    
  }, [docname]);


 
  const saveObjectFormToBacken = async (action) => { 
  
    const pdfContainer = document.querySelector('svg#pdf-container');
   
    const labelDataList = pdfContainer.querySelectorAll('[data-label]');
  
    if (labelDataList.length === 0) {
      toastr.error(t('you can not be post empty'));
      return;
    }
        
    const  UsersDimensions = {};
    const  UsersJson = {};
    const processedGroupContainers = new Set();
    const orderCounters = {}; 
    labelDataList.forEach(item => {
      const dataType = item.getAttribute('data-type');
      if (!dataType) {
        console.error('Undefined data-type for item:', item);
        return; // Skip this item if data-type is undefined
      }
      const width = parseFloat(item.getAttribute('width'));
      const height = parseFloat(item.getAttribute('height'));
      const x = parseFloat(item.getAttribute('x'));
      const y = parseFloat(item.getAttribute('y'));
      const targetid = item.id;
      const format = item.dataset.type;
      const signContent = item.dataset.content;
      const roll = item.dataset.roll;
      const names = item.dataset.recipien;
      const Messeges = item.dataset.msg;
      const mails = item.dataset.mail;
      const recipienids = item.getAttribute('recipienid');
      const fontColor = item.dataset.fontColor;
      const fontSize = item.dataset.fontSize;
      const fontFormat = item.dataset.fontFormat;
      const fontFamily = item.dataset.fontFamily;
      const page = item.dataset.page;
      const dataFormat = item.dataset.dataFormat;
      const group = item.parentElement;
      const colors = item.getAttribute('stroke');
    const groupContainer = group.parentElement;
 
    const isSigned = 'false';
    if (!UsersJson[names]) {
      UsersJson[names] = {};
  }
      if (!UsersJson[names][format]) {
        // If the fullname does not exist in UsersDimensions, create a new object
        UsersJson[names][format] = {
          client_token: token,
          fullname: names,
          format: format,
          email: mails,
          messeges:Messeges,
          colors:colors,
          send_status:action,
          zoom_int:selectedZoom,
          sign_id:uuid,
          userid:recipienids,
          signContent:[],
          width: [],
          height: [],
          x: [],
          y: [],
          type: [],
          page:[],
          fgColor:[],
          bgColor:[],
          fontColor:[],
          fontSize:[],
          fontFormat:[],
          fontFamily:[],
          dataFormat:[],
          roll:[],
          min:[],
          max:[],
          selecttype:[],
          optionnumtype:[],
          valutacode:[],
          ischecked:[],
          groupContaineRule:[],
          groupContainevalidation:[],
          groupContaineMax:[],
          groupContaineMin:[],
          totalwidth:[],
          totalheight:[],
          containerX:[],
          containerY:[],
          selectValue:[],
          selectOption:[],
          labelValeu:[],
          targetid:[],
          isSigned:[],
          isOrder:[],
          groupContainerid:[],
          rectgroupContainerid:[],
        };
        orderCounters[names] = orderCounters[names] || {};
        orderCounters[names][format] = 0;
      }
      switch (dataType) {
        
        case 'handwriteing':
        case 'hand_praaf':
        case 'stemps':
        case 'calendar':
        case 'fullname':
        case 'email':
        case 'company':
        case 'title':
        case 'textarea':
        case 'drawing':
        case 'formula':
        case 'attachment': 
        case 'approve':
        case 'decline':
          UsersJson[names][format].width.push(width);
          UsersJson[names][format].height.push(height);
          UsersJson[names][format].x.push(x);
          UsersJson[names][format].y.push(y);
          UsersJson[names][format].type.push(format);
          UsersJson[names][format].signContent.push(signContent);
          UsersJson[names][format].page.push(page);
          UsersJson[names][format].fontColor.push(fontColor);
          UsersJson[names][format].fontSize.push(fontSize);
          UsersJson[names][format].fontFormat.push(fontFormat);
          UsersJson[names][format].fontFamily.push(fontFamily);
          UsersJson[names][format].dataFormat.push(dataFormat);
          UsersJson[names][format].roll.push(roll); 
          UsersJson[names][format].targetid.push(targetid); 
          UsersJson[names][format].isSigned.push(isSigned); 
          UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 
          // Additional properties for other data types isSigned group  isOrder:[],
          break;
        case 'qrcode':
     const fgColor = item.dataset.fgColor;
     const bgColor = item.dataset.bgColor;
     UsersJson[names][format].fgColor.push(fgColor);
     UsersJson[names][format].bgColor.push(bgColor);
     UsersJson[names][format].width.push(width);
     UsersJson[names][format].height.push(height);
     UsersJson[names][format].x.push(x);
     UsersJson[names][format].y.push(y);
     UsersJson[names][format].type.push(format);
     UsersJson[names][format].signContent.push(signContent);
     UsersJson[names][format].page.push(page);
     UsersJson[names][format].fontColor.push(fontColor);
     UsersJson[names][format].fontSize.push(fontSize);
     UsersJson[names][format].fontFormat.push(fontFormat);
     UsersJson[names][format].fontFamily.push(fontFamily);
     UsersJson[names][format].dataFormat.push('Y-m-d');
     UsersJson[names][format].roll.push(roll);
     UsersJson[names][format].targetid.push(targetid);  
     UsersJson[names][format].isSigned.push(isSigned); 
     UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 
          // Additional properties for other data types
          break;
        // Add more cases as needed for other data types
          case 'number':
          case 'payment':
            const selecttype = item.dataset.selecttype;
            const optionnumtype = item.dataset.optionnumtype;
            const valutacode = item.dataset.valutacode;
            const Max = item.dataset.max;
            const Min = item.dataset.min; 
            const labelValeu = item.dataset.label;   
            UsersJson[names][format].labelValeu.push(labelValeu);        
            UsersJson[names][format].width.push(width);
            UsersJson[names][format].height.push(height);
            UsersJson[names][format].x.push(x);
            UsersJson[names][format].y.push(y);
            UsersJson[names][format].type.push(format);
            UsersJson[names][format].signContent.push(signContent);
            UsersJson[names][format].page.push(page);
            UsersJson[names][format].fontColor.push(fontColor);
            UsersJson[names][format].fontSize.push(fontSize);
            UsersJson[names][format].fontFormat.push(fontFormat);
            UsersJson[names][format].fontFamily.push(fontFamily);
            UsersJson[names][format].dataFormat.push(dataFormat);
            UsersJson[names][format].selecttype.push(selecttype);
            UsersJson[names][format].optionnumtype.push(optionnumtype);
            UsersJson[names][format].valutacode.push(valutacode);
            UsersJson[names][format].max.push(Max);
            UsersJson[names][format].min.push(Min);
            UsersJson[names][format].roll.push(roll); 
            UsersJson[names][format].targetid.push(targetid);
            UsersJson[names][format].isSigned.push(isSigned); 
            UsersJson[names][format].isOrder.push(orderCounters[names][format]++);     
        break;
        case 'dropdown':
          const selectValue = item.dataset.value;
          const selectOption = item.dataset.option;
            // Proceed with pushing data to UsersDimensions
            UsersJson[names][format].width.push(width);
            UsersJson[names][format].height.push(height);
            UsersJson[names][format].x.push(x);
            UsersJson[names][format].y.push(y);
            UsersJson[names][format].type.push(format);
            UsersJson[names][format].signContent.push(signContent);
            UsersJson[names][format].page.push(page);
            UsersJson[names][format].fontColor.push(fontColor);
            UsersJson[names][format].fontSize.push(fontSize);
            UsersJson[names][format].fontFormat.push(fontFormat);
            UsersJson[names][format].fontFamily.push(fontFamily);
            UsersJson[names][format].dataFormat.push('Y-m-d');
            UsersJson[names][format].roll.push(roll);
            UsersJson[names][format].selectValue.push(selectValue);
            UsersJson[names][format].selectOption.push(selectOption);
            UsersJson[names][format].targetid.push(targetid);
            UsersJson[names][format].isSigned.push(isSigned); 
            UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 

          break;
        case 'checkbox':
          case 'radio':
          const rectgroupContainerid = item.dataset.groupContainerid
            const group = item.parentElement;
            const groupContainer = group.parentElement;
            const isChecked = item.dataset.checked;
            if (!processedGroupContainers.has(groupContainer)) {
              
              processedGroupContainers.add(groupContainer);
              const groupContainerRule = groupContainer.dataset.rule;
              const groupContainerValidation = groupContainer.dataset.validation;
              const groupContainerMax = groupContainer.dataset.max;
              const groupContainerMin = groupContainer.dataset.min;
              const totalWidth = groupContainer.dataset.totalwidth;
              const totalHeight = groupContainer.dataset.totalheight;
              const containerX = groupContainer.dataset.containerX;
              const containerY = groupContainer.dataset.containerY;
              const groupContainerid = groupContainer.id;
              // Assuming UsersJson, names, format, width, height, x, y, signContent, page, fontColor, fontSize, fontFormat, fontFamily, dataFormat, roll, targetid, isSigned are already defined
              UsersJson[names][format].groupContainerid.push(groupContainerid);
              UsersJson[names][format].groupContaineRule.push(groupContainerRule);
              UsersJson[names][format].groupContainevalidation.push(groupContainerValidation);
              UsersJson[names][format].groupContaineMax.push(groupContainerMax);
              UsersJson[names][format].groupContaineMin.push(groupContainerMin);
              UsersJson[names][format].totalwidth.push(totalWidth);
              UsersJson[names][format].totalheight.push(totalHeight);
              UsersJson[names][format].containerX.push(containerX);
              UsersJson[names][format].containerY.push(containerY);
           
            }
            UsersJson[names][format].rectgroupContainerid.push(rectgroupContainerid);
            UsersJson[names][format].width.push(width);
            UsersJson[names][format].height.push(height);
            UsersJson[names][format].x.push(x);
            UsersJson[names][format].y.push(y);
            UsersJson[names][format].type.push(format);
            UsersJson[names][format].signContent.push(signContent);
            UsersJson[names][format].page.push(page);
            UsersJson[names][format].fontColor.push(fontColor);
            UsersJson[names][format].fontSize.push(fontSize);
            UsersJson[names][format].fontFormat.push(fontFormat);
            UsersJson[names][format].fontFamily.push(fontFamily);
            UsersJson[names][format].dataFormat.push(dataFormat);
            UsersJson[names][format].roll.push(roll);
            UsersJson[names][format].ischecked.push(isChecked);
            UsersJson[names][format].targetid.push(targetid);
            UsersJson[names][format].isSigned.push(isSigned);
            UsersJson[names][format].isOrder.push(orderCounters[names][format]++); 
            break;
        

        default:
          // Handle other data types if necessary
      }   
      if (!UsersDimensions[names]) {
        // If the fullname does not exist in UsersDimensions, create a new object
        UsersDimensions[names] = {
          client_token: token,
          fullname: names,
          format: format,
          email: mails,
          messeges:Messeges,
          colors:colors,
          send_status:action,
          zoom_int:selectedZoom,
          sign_id:uuid,
          userid:recipienids,
          signContent:[],
          width: [],
          height: [],
          x: [],
          y: [],
          type: [],
          page:[],
          fgColor:[],
          bgColor:[],
          fontColor:[],
          fontSize:[],
          fontFormat:[],
          fontFamily:[],
          dataFormat:[],
          roll:[],
          min:[],
          max:[],
          selecttype:[],
          optionnumtype:[],
          valutacode:[],
          ischecked:[],
          groupContaineRule:[],
          groupContainevalidation:[],
          groupContaineMax:[],
          groupContaineMin:[],
          selectValue:[],
          selectOption:[],
        };
        
      }
      switch (dataType) {
        case 'handwriteing':
        case 'hand_praaf':
        case 'stemps':
        case 'calendar':
        case 'fullname':
        case 'email':
        case 'company':
        case 'title':
        case 'textarea':
        case 'drawing':
        case 'formula':
        case 'attachment': 
        case 'approve':
        case 'decline':
        
          UsersDimensions[names].width.push(width);
          UsersDimensions[names].height.push(height);
          UsersDimensions[names].x.push(x);
          UsersDimensions[names].y.push(y);
          UsersDimensions[names].type.push(format);
          UsersDimensions[names].signContent.push(signContent);
          UsersDimensions[names].page.push(page);
          UsersDimensions[names].fontColor.push(fontColor);
          UsersDimensions[names].fontSize.push(fontSize);
          UsersDimensions[names].fontFormat.push(fontFormat);
          UsersDimensions[names].fontFamily.push(fontFamily);
          UsersDimensions[names].dataFormat.push(dataFormat);
          UsersDimensions[names].roll.push(roll);  
          // Additional properties for other data types
          break;
        case 'qrcode':
     const fgColor = item.dataset.fgColor;
     const bgColor = item.dataset.bgColor;
     UsersDimensions[names].fgColor.push(fgColor);
     UsersDimensions[names].bgColor.push(bgColor);
     UsersDimensions[names].width.push(width);
     UsersDimensions[names].height.push(height);
     UsersDimensions[names].x.push(x);
     UsersDimensions[names].y.push(y);
     UsersDimensions[names].type.push(format);
     UsersDimensions[names].signContent.push(signContent);
     UsersDimensions[names].page.push(page);
     UsersDimensions[names].fontColor.push(fontColor);
     UsersDimensions[names].fontSize.push(fontSize);
     UsersDimensions[names].fontFormat.push(fontFormat);
     UsersDimensions[names].fontFamily.push(fontFamily);
     UsersDimensions[names].dataFormat.push('Y-m-d');
     UsersDimensions[names].roll.push(roll);  
          // Additional properties for other data types
          break;
        // Add more cases as needed for other data types
          case 'number':
          case 'payment':
            const selecttype = item.dataset.selecttype;
            const optionnumtype = item.dataset.optionnumtype;
            const valutacode = item.dataset.valutacode;
            const Max = item.dataset.max;
            const Min = item.dataset.min;             
          UsersDimensions[names].width.push(width);
          UsersDimensions[names].height.push(height);
          UsersDimensions[names].x.push(x);
          UsersDimensions[names].y.push(y);
          UsersDimensions[names].type.push(format);
          UsersDimensions[names].signContent.push(signContent);
          UsersDimensions[names].page.push(page);
          UsersDimensions[names].fontColor.push(fontColor);
          UsersDimensions[names].fontSize.push(fontSize);
          UsersDimensions[names].fontFormat.push(fontFormat);
          UsersDimensions[names].fontFamily.push(fontFamily);
          UsersDimensions[names].dataFormat.push(dataFormat);
          UsersDimensions[names].selecttype.push(selecttype);
          UsersDimensions[names].optionnumtype.push(optionnumtype);
          UsersDimensions[names].valutacode.push(valutacode);
          UsersDimensions[names].max.push(Max);
          UsersDimensions[names].min.push(Min);
          UsersDimensions[names].roll.push(roll);    
        break;
        case 'checkbox':
          case 'radio':
            const group = item.parentElement;
            const groupContainer = group.parentElement;
            const ischecked = item.dataset.checked;
            const groupContaineRule = groupContainer.dataset.rule;
            const groupContainevalidation = groupContainer.dataset.validation;
            const groupContaineMax = groupContainer.dataset.max;
            const groupContaineMin = groupContainer.dataset.min;
            
            UsersDimensions[names].width.push(width);
            UsersDimensions[names].height.push(height);
            UsersDimensions[names].x.push(x);
            UsersDimensions[names].y.push(y);
            UsersDimensions[names].type.push(format);
            UsersDimensions[names].signContent.push(signContent);
            UsersDimensions[names].page.push(page);
            UsersDimensions[names].fontColor.push(fontColor);
            UsersDimensions[names].fontSize.push(fontSize);
            UsersDimensions[names].fontFormat.push(fontFormat);
            UsersDimensions[names].fontFamily.push(fontFamily);
            UsersDimensions[names].dataFormat.push(dataFormat);
            UsersDimensions[names].roll.push(roll);
            UsersDimensions[names].ischecked.push(ischecked);
            UsersDimensions[names].groupContaineRule.push(groupContaineRule);
            UsersDimensions[names].groupContainevalidation.push(groupContainevalidation);
            UsersDimensions[names].groupContaineMax.push(groupContaineMax);
            UsersDimensions[names].groupContaineMin.push(groupContaineMin);
            break;
          case 'dropdown':
          const selectValue = item.dataset.value;
          const selectOption = item.dataset.option;

            // Proceed with pushing data to UsersDimensions
            UsersDimensions[names].width.push(width);
            UsersDimensions[names].height.push(height);
            UsersDimensions[names].x.push(x);
            UsersDimensions[names].y.push(y);
            UsersDimensions[names].type.push(format);
            UsersDimensions[names].signContent.push(signContent);
            UsersDimensions[names].page.push(page);
            UsersDimensions[names].fontColor.push(fontColor);
            UsersDimensions[names].fontSize.push(fontSize);
            UsersDimensions[names].fontFormat.push(fontFormat);
            UsersDimensions[names].fontFamily.push(fontFamily);
            UsersDimensions[names].dataFormat.push('Y-m-d');
            UsersDimensions[names].roll.push(roll);
            UsersDimensions[names].selectValue.push(selectValue);
            UsersDimensions[names].selectOption.push(selectOption);

          break;

        default:
          // Handle other data types if necessary
      }
      
    });

   
      try {
        const usersArray = Object.values(UsersDimensions);
        const jsonArray = Object.values(UsersJson);
        // Convert FormData to JSON object data-fonteot={"LovlyTropical.ttf"}
        const formObject = {
          folder:action,
          uuid: uuid,
          users: usersArray,
          jsondata: jsonArray,
        };
    
  
        // Dispatch the action to initiate the post request
         await dispatch(postSigndata(formObject));
      } catch (error) {
        toastr.error(t('Error Post signature data!'));
      
      }
    
 
    
  }
  
  useEffect(() => {
    if (!jsonpostdata) {
      // Do nothing if jsonpostdata is undefined or empty
      return;
    }
    if (jsonpostdata.success) {
      toastr.success(t('Envelope Successfully Completed'));
    } else if (!jsonpostdata.success) {
     // toastr.error(jsonpostdata.message);
    } else {
      toastr.error(t('No response from server!'))
    }
  }, [jsonpostdata, dispatch]);
  function tog_toggleModal2() {
    setTogModal2(!togModal2);
    
    removeBodyCss();
  }
  function tog_toggleModal3() {
    setTogModal3(!togModal3);
    
    removeBodyCss();
  }
  function tog_toggleModal4() {
    setTogModal4(!togModal4);
    
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  const [recipients, setRecipients] = useState(() => (
    recipientData.map((recipient, index) => ({
      recipientName: recipient.recipientName,
      recipientEmail:recipient.recipientEmail, 
      recipientMsg: recipient.recipientMsg,
      recipientroll: recipient.recipientroll,
      recipientExpire: recipient.recipientExpire,
      color: recipient.color,
      recipienid:recipient.recipienid,
      signatureImge:recipient.signatureImge,
      paraaf:recipient.paraaf,
      stemp:recipient.stemp,
      isOwner:recipient.isOwner,
    }))
  ));
  
  const handleRecipientInputChange = (event, index) => {
    const { name, value } = event.target;
   
    const updatedRecipientData = recipientData.map((recipient, i) => {
      if (i === index) {
        if (name === "recipientName" && value.trim() !== "") {
          // Only update recipientName if name is "recipientName" and value is not empty
          return {
            ...recipient,
            recipientName: value
          };
        }
        if (name === "recipientEmail" && value.trim() !== "") {
          const isOwner = value.trim().toLowerCase() === user.email.trim().toLowerCase();
          const signatureImge = isOwner ? user.signature : 'client';
          const paraaf = isOwner ? user.paraaf : 'client';
          const stemp = isOwner ? user.stemp : 'client';
          return {
            ...recipient,
            recipientEmail: value,
            signatureImge: signatureImge,
            paraaf: paraaf,
            stemp: stemp,
            isOwner:isOwner
          };
        }
        if (name === "recipientMsg" && value.trim() !== "") {
          // Only update recipientName if name is "recipientName" and value is not empty
          return {
            ...recipient,
            recipientMsg: value
          };
        }
      }
      return recipient;
    });
    setRecipientData(updatedRecipientData);

    
  };
  const handleAddRow = () => {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

    setRowColors(prevColors => [...prevColors, randomColor]);
    const newRecipient = {
      recipientName: "",
      recipientEmail: "",
      recipientMsg: "",
      recipientroll: "true",
      recipientExpire: "",
      color: randomColor,
      recipienid: generateUniqueId(),
      isOwner: false,
      signatureImge: "client",
      paraaf: "client",
      stemp: "client",
    };
    setRecipientData((prevRecipients) => [...prevRecipients, newRecipient]);

    //recipientData setRecipientData
    
  };

  const handleDeleteRow = (index) => {
    if (recipients.length === 1) {
      return; // Don't delete the last row
    }
    setRecipientData((prevRecipients) => {
      const updatedRecipients = prevRecipients.filter((recipient) => recipient.recipienid !== index);
      return updatedRecipients;
    });

  };
  const updateData  = async () => {
    const formData = new FormData();
    const updatedData = {
      recipients: JSON.stringify(recipientData)
    };
  formData.append("recipients", JSON.stringify(recipientData));
  formData.append("uuid", uuid);
  axios.post(`https://dspmetrics.com/api/update/reciepdata/${uuid}`, updatedData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then((response) => {
    if (response.data && response.data.message) {
      toastr.success(response.data.message); 
    } else {
      toastr.success(t('Form Successfully Submitted'));
    }
   
      setTimeout(() => {
        window.location.reload(); // Reload the page after 2 seconds
      }, 2000);
  })
  .catch((error) => {
    console.error('File upload error:', error);
    toastr.error('File upload failed. Please try again.');
  });
  
  };

  const handleUploadButtonClick  = () => {
    fileInputRef.current.click();   
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleUpload = () => {
    const formData = new FormData();
 
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("uuid", uuid);
    formData.append("docNames", docNames);
   
    axios.post(`https://dspmetrics.com/update/upload?token=${token}&fullNames=${fullNames}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (response.data && response.data.message) {
        toastr.success(response.data.message); 
      } else {
        toastr.success("Form submitted successfully!");
      }
      tog_toggleModal4(false); // Close the modal
      setTimeout(() => {
        window.location.reload(); // Reload the page after 2 seconds
      }, 2000);
    })
    .catch((error) => {
      console.error('File upload error:', error);
      toastr.error(t('File upload error. Please attempt to upload the file once more.'));
    });
    };
  return (
    <div>

<Modal
        isOpen={togModal4}
        toggle={() => {
          tog_toggleModal4();
        }}
        centered
        >
        <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">{t('Edit Documents')}</h5>
        <button type="button" className="btn-close"
        onClick={() => {
          tog_toggleModal4(false);
        }} aria-label="Close"></button>
        </div>
        <ModalBody>
        <div data-qa="add-document-content-card" data-callout="upload-file" className="css-130w8nn">
  <div className="css-6t6lyd">
    <div className="css-18lurlg">
      <div className="css-f2opis">
        <img className="css-90hogc" alt="200x200" width="200" src={img4} data-holder-rendered="true" />
      </div>
      <p className="css-yodtmi">{t('Drop files here')}</p>
      <div >
        <Button
          data-qa="upload-file-button"
          className="olv-button buttons-container olv-ignore-transform css-1bl3roz"
          type="button"
          aria-haspopup="true"
          onClick={handleUploadButtonClick}
        >
          <span data-qa="upload-file-button-text" className="css-1ixbp0l">{t('UPLOAD')}</span>
          <span data-qa="upload-file-button-icon" className="css-11sgmcq">
            <span className="css-zf56fy">
              <span aria-hidden="true" className="SVGInline">
                <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                  <path d="M4 5h8l-4 6z"></path>
                </svg>
              </span>
            </span>
          </span>
        </Button>
        <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
        accept=".pdf, .jpeg, .jpg, .png, .doc, .docx, .xls, .xlsx"
      />
      </div>
    </div>
  </div>
</div>
        </ModalBody>
        <ModalFooter>
        {selectedFiles.length > 0 && (
        <button className="btn btn-primary" onClick={handleUpload}>{t('Confirm Upload')}</button>
      )}
     
        </ModalFooter>
        </Modal>
              <Modal
        isOpen={togModal3}
        toggle={() => {
          tog_toggleModal3();
        }}
        centered
        >
        <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">{t('Edit Message')}</h5>
        <button type="button" className="btn-close"
        onClick={() => {
          tog_toggleModal3(false);
        }} aria-label="Close"></button>
        </div>
        <ModalBody>
        {recipientData.map((recipient, index) => (
        <div className="col-md-12" style={{ marginBottom: '15px', borderLeft: `8px solid ${Colors[index]}` }} key={index}>
        <div className="mb-3">
        <div className="input-group">
        <div className="input-group-text">
        <i className="bx bxs-user-rectangle lg"></i>
        </div>
        <input
        className="form-control"
        name="recipientMsg"
        list="datalistOptions"
        id={`fullname-${index}`}
        placeholder={t('Type to full name')}
        defaultValue={recipient.recipientMsg}
        onChange={(event) => handleRecipientInputChange(event, index)}
        />
        </div>
        <datalist id="datalistOptions"></datalist>
        </div>
      
        </div>
        ))}
        </ModalBody>
        <ModalFooter>
        <button className="btn btn-primary"
       onClick={updateData}
        >
       {t('Update')} 
       </button>
        </ModalFooter>
        </Modal>
        <Modal
        isOpen={togModal2}
        toggle={() => {
        tog_toggleModal2();
        }}
        centered
        >
        <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">{t('Edit Recipients')} </h5>
        <button type="button" className="btn-close"
        onClick={() => {
        setTogModal2(false);
        }} aria-label="Close"></button>
        </div>
        <ModalBody>
        {recipientData.map((recipient, index) => (
            <div className="col-md-8" style={{ marginBottom: '15px', borderLeft: `8px solid ${recipient.color}` }} key={recipient.recipienid}>
          
        <div className="mb-3">
          
        <div className="input-group">
          
        <div className="input-group-text">
        <i className="bx bxs-user-rectangle lg"></i>
        </div>
        <input
        className="form-control"
        name="recipientName"
        list="datalistOptions"
        id={`fullname-${recipient.recipienid}`}
        placeholder="Type full name"
        defaultValue={recipient.recipientName}
        onChange={(event) => handleRecipientInputChange(event, index)}
        />
        </div>
        <datalist id="datalistOptions"></datalist>
        </div>
        
        <div className="mb-3">
        <div className="input-group">
        <div className="input-group-text">
        <i className="bx bx-mail-send lg"></i>
        </div>
        <input
        className="form-control"
        name="recipientEmail"
        list="datalistOptions1"
        id={`email-${index}`}
        placeholder="Type email"
        defaultValue={recipient.recipientEmail}
        onChange={(event) => handleRecipientInputChange(event, index)}
        />
        </div>
        <datalist id="datalistOptions1"></datalist>
        </div>
        <div className="col-2">
                    <Button
                          className="btn btn-danger btn-sm rounded-0 delete"
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Delete"
                          onClick={() => handleDeleteRow(recipient.recipienid)}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>            
                     </div>
        </div>
        
        ))}
        
        </ModalBody>
        <ModalFooter>

        <Button type="button" id="addbtn" className="btn startNowbtn" onClick={handleAddRow}>
        {t('Add Reciepien')} 
            </Button>
            <Button type="button" id="addbtn"  style={{ backgroundColor: 'blue',color:'white' }} className="btn startNowbtn" onClick={updateData}>
            {t('Save Reciepien')}  
            </Button>
        </ModalFooter>
  
        </Modal>
  
        <div className="css-zx8agq">
      {isEditorAllowed && (
        <button className="css-kpt29i" type="button" onClick={handleToggleDropdown}>
          <span className="css-0">{t('Actions')}</span>
          <span className="css-gojv5r">
            <span className="css-zf56fy">
              <span className="SVGInline">
                <svg className="SVGInline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                  <path d="m13 6.2-1.4-1.4L8 8.4 4.4 4.8 3 6.2l5 5z"></path>
                </svg>
              </span>
            </span>
          </span>
        </button>
      )}
      {isDropdownOpen && (
        <div className="css-1iq8xl9">
          <div className="css-1v7l42u">
            <div className="css-mvyop0" style={{ position: 'absolute', top: '51.9932px', left: '812.741px' }}>
              <div className="css-1f1yei0" style={{ opacity: 1, transform: 'none' }}></div>
              <div className="css-1iiwxfh" style={{ opacity: 1, minWidth: '156px' }}>
                <ul role="menu" className="css-vkwu48">
                  <li role="presentation" style={{ opacity: 1 }}>
                    <button disabled={!isEditorAllowed} className="css-129ohls" onClick={() => saveObjectFormToBacken('saving')} type="button">
                      <span className="css-16hz1ch">{t('Save and Close')}</span>
                    </button>
                  </li>
                  <li role="presentation" style={{ opacity: 1 }}>
                    <button  disabled={!isEditorAllowed} className="css-129ohls" onClick={() => saveObjectFormToBacken('discard')} type="button">
                      <span className="css-16hz1ch">{t('Discard')}</span>
                    </button>
                  </li>
                </ul>
                <span className="css-1kbafqs" style={{ opacity: 1 }}></span>
                <ul role="menu" className="css-vkwu48">
                  <li role="presentation" className="css-1xasier" style={{ opacity: 1 }}>
                    <button
                      data-qa="edit-message"
                      className="css-75irmu"
                      role="menuitem"
                      type="button"
                      disabled={!isEditorAllowed}
                      onClick={() => setTogModal3(true)}
                    >
                      <span className="css-9cbk6x">
                        <span data-qa="edit-message-text">{t('Edit Message')}</span>
                      </span>
                    </button>
                  </li>
                  <li role="presentation" className="css-1xasier" style={{ opacity: 1 }}>
                    <button disabled={!isEditorAllowed} className="css-75irmu" role="menuitem" type="button" onClick={() => setTogModal2(true)}>
                      <span className="css-9cbk6x">
                        <span data-qa="edit-recipients-text">{t('Edit Recipients')}</span>
                      </span>
                    </button>
                  </li>
                  <li role="presentation" className="css-1xasier" style={{ opacity: 1 }}>
                    <button disabled={!isEditorAllowed} className="css-75irmu" role="menuitem" type="button" onClick={() => setTogModal4(true)}>
                      <span className="css-9cbk6x">
                        <span>{t('Edit Documents')}</span>
                      </span>
                    </button>
                  </li>
                  <li role="presentation" className="css-1xasier" style={{ opacity: 1 }}>
                    <button disabled={!isEditorAllowed} className="css-75irmu" role="menuitem" type="button">
                      <span className="css-9cbk6x">
                        <span>{t('Advanced Options')}</span>
                      </span>
                    </button>
                  </li>
                </ul>
                <span role="separator" className="css-1kbafqs" style={{ opacity: 1 }}></span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
</div>
  );
};
export default withTranslation()(withRouter(ActionDropDown));

