import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { useNavigate ,useParams,useHistory } from 'react-router-dom';
import PaymentFormNew  from "components/Payment/PaymentFormNew";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { registerUser,loginUser } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import withRouter from "components/Common/withRouter";
import {
  getMailsLists as onGetMailsLists,
  getSelectedMails as onGetSelectedMails,
  accountProfile as onGetAccountProfile,


} from "store/actions";
import { withTranslation } from "react-i18next";
const CheckoutFormUpdate = (props) => {
  const { t } = props;
  const {
    mailslists,
    selectedmails,
    profiles,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    selectedmails: state.mails.selectedmails,
    profiles: state.mails.profiles,
    error: state.mails.error,

  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [received, setReceived] = useState('');
  const [name, setName] = useState('');
  const [spaname, setNamespa] = useState('');
  const [iban, setIban] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [subscription_name, setSubscription_name] = useState('');
  const [plan_type, setPlan_type] = useState('');
  const [company_name, setCompany_name] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [phone_number, setPhone] = useState('');
  const [vat, setVat] = useState('');
  const [number_of_users, setNumberofusers] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState('');
  const [activeButton, setActiveButton] = useState('credit/debit');
  const [isEditing, setIsEditing] = useState(false);
  const [isadd, setIsAdd] = useState(false);
  
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [account_id , setAccountid ] = useState(user.account_id );
 
  const [paymentMethod, setPaymentMethod] = useState({
    card: [],
    sepa_debit: []
  });

  const addData = {
    email,
    iban,
    country,
    postalCode,
    subscription_name,
    plan_type,
    company_name,
    city,
    address,
    phone_number,
    vat,
    number_of_users
  };
  const [customerId, setcustomerId] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  useEffect(() => {
    if (profiles && profiles.length > 0) {
      const profile = profiles[0];      
      setCountry(profile.country)
      setPostalCode('1245')
      setSubscription_name(profile.plan_name)
      setEmail(profile.email)
      setPlan_type(profile.plan_type)
      setCompany_name(profile.company_name)
      setCity(profile.city)
      setAddress(profile.address)
      setPhone(profile.phonenumber)
      setVat(profile.vat_number)
      setNumberofusers(profile.number_of_users)

      fetchPaymentMethod(profile.customerid , profile.paymentid);
    }
  }, [profiles]);


  const postalCodeFormats = {
    US: /^[0-9]{5}(-[0-9]{4})?$/, // US: e.g., 12345 or 12345-6789
    GB: /^[A-Z]{1,2}[0-9R][0-9A-Z]? ?[0-9][A-Z]{2}$/, // UK: e.g., W1A 1AA
    NL: /^[0-9]{4} ?[A-Z]{2}$/, // Netherlands: e.g., 1234 AB
    EG: /^[0-9]{5}$/, // Egypt: e.g., 12345
    BE: /^[0-9]{4}$/, // Belgium: e.g., 1234
  };

  const handleSubmit =  (paymentMethodType) => async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    setPostalCodeError('');

    // Validate postal code based on selected country
    if (country && postalCode) {
      const regex = postalCodeFormats[country];
      if (regex && !regex.test(postalCode)) {
        setPostalCodeError(t('Invalid postal code format for the selected country.'));
        setLoading(false);
        return;
      }
    }

    if (!stripe || !elements) {
      setErrorMessage(t('Stripe has not loaded yet. Please try again.'));
      setLoading(false);
      return;
    }

   
    if (paymentMethodType === 'card') {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
          address: {
            country,
            postal_code: postalCode,
          },
        },
      });
  
      if (error) {
        setErrorMessage(error.message);
      } else {
       
        setSuccessMessage(`${t('Your payment was successful! Thank you for your purchase')} `)
  
        try {
          if (email) {
        
            const response = await fetch('https://dspmetrics.com/update-payment-method', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
              email:email, // Replace with actual email
              name:name,
              subscription_name: subscription_name,
              plan_type: plan_type,
              company_name: company_name,
              city: city,
              address: address,
              phone_number: phone_number,
              vat: vat,
              number_of_users: number_of_users,
              account_id:account_id,
              payment_method_id: paymentMethod.id,
              }),
            });
    
            if (!response.ok) {
              const responseText = await response.text();
              setErrorMessage(`Server error: ${responseText.message}`);
            } else {
              const result = await response.json();
              if (result.error) {
                setErrorMessage(`Server error: ${result.error}`);
              } else {
              
                dispatch(onGetAccountProfile(account_id));
                fetchPaymentMethod(result.customerId , paymentMethod.id);
                setSuccessMessage(`${t('Customer created successfully! Welcome aboard.')}`);
                setIsEditing(false)
              }
            }
          }
         
        } catch (err) {
          
          setErrorMessage(Error `${t('sending data to server:')} ${err.message}`);
        }
      }
    } else if (paymentMethodType === 'sepa') {

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: {
          iban,
        },
        billing_details: {
          name:spaname,
          email: email,
          address: {
            line1: 'Address Line 1',
            city: 'City',
            postal_code: '1901NL',
            country: 'NL',
          },
        },
      });
  
      if (error) {
        setErrorMessage(error.message);
      } else {
       
        setSuccessMessage(`${t('Your payment was successful! Thank you for your purchase')} `)
  
        try {
          if (email) {
            
            const response = await fetch('https://dspmetrics.com/update-payment-method', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                email:email, // Replace with actual email
                name:spaname,
                subscription_name: subscription_name,
                plan_type: plan_type,
                company_name: company_name,
                city: city,
                address: address,
                phone_number: phone_number,
                vat: vat,
                number_of_users: number_of_users,
                account_id:account_id,
                payment_method_id: paymentMethod.id,
              }),
            });
    
            if (!response.ok) {
              const responseText = await response.text();
              setErrorMessage(`Server error: ${responseText.message}`);
            } else {
              const result = await response.json();
              
              if (result.error) {
                setErrorMessage(`Server error: ${result.error}`);
              } else {
              
                dispatch(onGetAccountProfile(account_id));
                fetchPaymentMethod(result.customerId , paymentMethod.id);
                setSuccessMessage(t('Customer created successfully!'));
                setIsEditing(false)
              }
            }
          }
         
        } catch (err) {
          
          setErrorMessage(Error `sending data to server: ${err.message}`);
        }
      }
    }
    

    setLoading(false);
  };
  
    

  const customStyles = {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
        fontSize: '1.25rem',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };



  useEffect(() => {
    
    dispatch(onGetAccountProfile(account_id));
 
  }, [dispatch,account_id]);

  useEffect(() => {
    if (received.customerId && received.paymentMethodId) {
      fetchPaymentMethod(received.customerid, received.paymentMethodid);
    }
  }, [received]);
  
  const fetchPaymentMethod = async (customerId, paymentMethodId) => {
    
    try {
      const response = await fetch('https://dspmetrics.com/retrieve-payment-method', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId, paymentMethodId }),
      });
  
      const data = await response.json();
      
      if (response.ok) {
        // Ensure data is an array before setting state
        if (data && typeof data === 'object') {
          setPaymentMethod({
            card: data.card || [],
            sepa_debit: data.sepa_debit || []
          });
        } else {
         
          console.error(t('Expected object but got:'), data);
          setPaymentMethod({ card: [], sepa_debit: [] });;
        }
        
      } else {
        setErrorMsg(data.error);
      }
    } catch (error) {
      setErrorMsg(error.message);
    }
  };
 
  const cardLogos = {
    visa: 'https://www.visa.com.au/dam/VCOM/regional/ve/romania/blogs/hero-image/visa-logo-800x450.jpg',
    mastercard: 'https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg',
    americanexpress: 'https://webshoptiger.com/wp-content/uploads/2023/09/American-Express-Color.png',
    ideal:'https://wempewempe.nl/wp-content/uploads/2017/08/iDeal-logo-1.png',
    paypal:'https://upload.wikimedia.org/wikipedia/commons/a/a4/Paypal_2014_logo.png',
    sepa_debit:'https://logowik.com/content/uploads/images/sepa1593.jpg',
  };
 

  

  const handleRemove = async (paymentMethodId) => {
    toastr.error(t('Sorry not available remove card')); 
     };

     const handleAdd = async () => {
       setIsEditing(false);
       setIsAdd(!isadd)
       
       };

    const handleEditClick = () => {
      setIsAdd(false);
      setIsEditing(!isEditing);
    };

    useEffect(() => {
      // Set isAdd to true if paymentMethod is null or empty
      if (!paymentMethod || (Array.isArray(paymentMethod.card) && paymentMethod.card.length === 0 && Array.isArray(paymentMethod.sepa_debit) && paymentMethod.sepa_debit.length === 0)) {
        setIsAdd(true);
      } else {
        setIsAdd(false);
      }
    }, [paymentMethod]);
  return (
<section className="css-1nd7z9v">
{paymentMethod.card.length === 0 && paymentMethod.sepa_debit.length === 0 ? (
    <p></p>
  ) : (
    <>
      {paymentMethod.card.length > 0 && (
        paymentMethod.card.map((card, index) => (
          <div key={index} className="b3-payment-methods-instrument-details-section b3-payment-methods-instrument-details-section-ranked">
            <div className="b3-payment-methods-section-title-form b3id-payment-methods-section-title-form b3id-simple-form b3-simple-form header-only title-only">
              <div className="b3id-form-header b3-form-header b3id-simple-form-form-header">
                <div className="b3id-form-header-title b3-form-header-title">
                  <h2 className="b3-form-header-title-text">
                    {t('Primary')}
                  </h2>
                </div>
              </div>
            </div>
            <div className="b3-payment-methods-card">
              <div className="b3id-instrument-details b3-instrument-details">
                <div className="b3-instrument-details-info-panel b3id-instrument-details-info-panel">
                  <div className="b3id-image-with-caption b3-image-with-caption b3-instrument-details-image-container">
                    <img
                      src={cardLogos[card.card.brand] || 'https://www.visa.com.au/dam/VCOM/regional/ve/romania/blogs/hero-image/visa-logo-800x450.jpg'}
                      alt={`${card.card.brand} logo`}
                      className="b3-image b3id-image-with-data b3-instrument-details-image"
                    />
                  </div>
                  <div className="b3-instrument-details-header">
                    <div className="b3id-instrument-details-label">
                      {`**** **** **** ${card.card.last4}`}
                    </div>
                    
                    <div className="b3id-info-message-component b3-info-message-component b3-info-message-detail-info-non-header b3id-instrument-details-description">
                      <div className="b3id-info-message-html">
                        <span>{card.billing_details.name}</span>
                      </div>
                      <div className="b3id-info-message-html">
                        <span>{`${card.card.exp_month}/${card.card.exp_year}`}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ border: '1px solid #dbdbdb' }} />
                <div className="b3-instrument-details-actions-bar">
                  <div className="b3id-instrument-details-action-group b3id-instrument-rank-action-group">
                    <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-instrument-details-action-group-label">
                      <div className="b3id-info-message-html">
                        <span>{t('Primary')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="b3id-instrument-details-default-actions">
                  <div className="b3id-instrument-details-action" onClick={handleAdd}>
                      <a className="b3id-widget-link b3-widget-link b3-ripple-container b3id-instrument-details-action-link">
                        <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-link-display-text">
                          <div className="b3id-info-message-html">
                            <span>{t('Add')}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="b3id-instrument-details-action" onClick={handleRemove}>
                      <a className="b3id-widget-link b3-widget-link b3-ripple-container b3id-instrument-details-action-link">
                        <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-link-display-text">
                          <div className="b3id-info-message-html">
                            <span>{t('Remove')}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="b3id-instrument-details-action" onClick={handleEditClick}>
                      <a className="b3id-widget-link b3-widget-link b3-ripple-container b3id-instrument-details-action-link">
                        <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-link-display-text">
                          <div className="b3id-info-message-html">
                            <span>{t('Edit')}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      {paymentMethod.sepa_debit.length > 0 && (
        paymentMethod.sepa_debit.map((sepa, index) => (
          <div key={index} className="b3-payment-methods-instrument-details-section b3-payment-methods-instrument-details-section-ranked">
            <div className="b3-payment-methods-section-title-form b3id-payment-methods-section-title-form b3id-simple-form b3-simple-form header-only title-only">
              <div className="b3id-form-header b3-form-header b3id-simple-form-form-header">
                <div className="b3id-form-header-title b3-form-header-title">
                  <h2 className="b3-form-header-title-text">
                    {t('SEPA Debit')}
                  </h2>
                </div>
              </div>
            </div>
            <div className="b3-payment-methods-card">
              <div className="b3id-instrument-details b3-instrument-details">
                <div className="b3-instrument-details-info-panel b3id-instrument-details-info-panel">
                  <div className="b3id-image-with-caption b3-image-with-caption b3-instrument-details-image-container">
                    <img
                      src={'https://logowik.com/content/uploads/images/sepa1593.jpg'} // Placeholder image for SEPA
                      alt={`SEPA logo`}
                      className="b3-image b3id-image-with-data b3-instrument-details-image"
                    />
                  </div>
                  <div className="b3-instrument-details-header">
                    <div className="b3id-instrument-details-label">
                      {`IBAN: **** **** **** ${sepa.sepa_debit.last4}`}
                    </div>
                    <div className="b3id-info-message-component b3-info-message-component b3-info-message-detail-info-non-header b3id-instrument-details-description">
                      <div className="b3id-info-message-html">
                        <span>{sepa.billing_details.name}</span>
                      </div>
                      {/* Additional SEPA details can be added here */}
                    </div>
                  </div>
                </div>
                <hr style={{ border: '1px solid #dbdbdb' }} />
                <div className="b3-instrument-details-actions-bar">
                  <div className="b3id-instrument-details-action-group b3id-instrument-rank-action-group">
                    <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-instrument-details-action-group-label">
                      <div className="b3id-info-message-html">
                        <span> {t('SEPA Debit')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="b3id-instrument-details-default-actions">
                  <div className="b3id-instrument-details-action" onClick={handleAdd}>
                      <a className="b3id-widget-link b3-widget-link b3-ripple-container b3id-instrument-details-action-link">
                        <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-link-display-text">
                          <div className="b3id-info-message-html">
                            <span> {t('Add')} </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="b3id-instrument-details-action" onClick={handleRemove}>
                      <a className="b3id-widget-link b3-widget-link b3-ripple-container b3id-instrument-details-action-link">
                        <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-link-display-text">
                          <div className="b3id-info-message-html">
                            <span>{t('Remove')}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="b3id-instrument-details-action" onClick={handleEditClick}>
                      <a className="b3id-widget-link b3-widget-link b3-ripple-container b3id-instrument-details-action-link">
                        <div className="b3id-info-message-component b3-info-message-component b3-info-message-unknown b3id-link-display-text">
                          <div className="b3id-info-message-html">
                            <span>{t('Edit')}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  )}

  
<div className="css-1x7nnnc" style={{marginTop:'20px'}}>
  <main className="css-1bg3kgs">
    {!isEditing ? (
      <p></p>
    ) : (
      <>
        {paymentMethod.card.length === 0 && paymentMethod.sepa_debit.length === 0 ? (
          <p>{t('No card data available')}</p>
        ) : (
          <>
            {paymentMethod.card.length > 0 && paymentMethod.card.map((card, index) => (
              <section key={`card-${index}`}>
                <fieldset className="css-0">
                  <legend className="css-2bow0d">{t('Payment information')}</legend>
                  <form  onSubmit={handleSubmit('card')}>
                    <div className="css-ho7zc7">
                      <div>
                        <span className="css-15ylkfs">
                          <Input
                            type="text"
                            name="nameOnCard"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            maxLength="50"
                            autoComplete="cc-name"
                            placeholder={t('Cardholder Name')}
                            id="nameOnCard"
                            required
                            className="fs-mask css-xv1qun"
                          />
                          <div className="dxp-field-label css-1bzl99f" data-size="lg">
                            <div className="css-5q8i94"></div>
                            <div className="css-g9uw22"></div>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="css-bjn8wh">
                      <span className="css-15ylkfs">
                        <Input
                          type="text"
                          name="cardnumber"
                          value={`**** **** **** ${card.card.last4}`} // Correctly concatenate the last 4 digits
                          maxLength="50"
                          autoComplete="cc-number"
                          placeholder={t('Card Number')}
                          id="cardnumber"
                          required
                          className="fs-mask css-1qdl6m2"
                        />
                        <div className="dxp-field-label css-1bzl99f" data-size="lg">
                          <div className="css-5q8i94"></div>
                          <div className="css-g9uw22"></div>
                        </div>
                      </span>
                      <div className="css-fk62m5">
                        {/* Add your SVG icons here */}
                      </div>
                    </div>

                    <div className="css-1jt7e93" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ flex: '1', marginRight: '10px' }}>
                        <span className="css-15ylkfs">
                          <Input
                            type="text"
                            name="expirydate"
                            value={'12'} // Format month and year
                            maxLength="7" // Ensure length accommodates MM/YYYY format
                            autoComplete="cc-exp"
                            placeholder="MM/YYYY"
                            id="expirydate"
                            required
                            className="fs-mask css-1qdl6m2"
                          />
                          <div className="dxp-field-label css-1bzl99f" data-size="lg">
                            <div className="css-5q8i94"></div>
                            <div className="css-g9uw22"></div>
                          </div>
                        </span>
                      </div>
                      <div style={{ flex: '1', marginLeft: '10px' }}>
                        <span className="css-15ylkfs">
                          <CardCvcElement className="fs-mask css-xv1qun" options={{ style: customStyles }} />
                          <div className="dxp-field-label css-1bzl99f" data-size="lg">
                            <div className="css-5q8i94"></div>
                            <div className="css-g9uw22"></div>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="css-1m3b0b0">
                     
                    </div>
                    <button title="Purchase" role="button" type="submit" className="css-pjhtp2" disabled={!stripe || loading}>
                      <span className="css-13wylk3">{loading ? t('Processing...') : t('Pay')}</span>
                    </button>
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
                  </form>
                </fieldset>
              </section>
            ))}
            {paymentMethod.sepa_debit.length > 0 && paymentMethod.sepa_debit.map((sepa, index) => (
              <section key={`sepa-${index}`}>
                <fieldset className="css-0">
                  <legend className="css-2bow0d">{t('Payment information')}</legend>
                  <form onSubmit={handleSubmit('sepa')}>
                    <div className="css-ho7zc7">
                      <div>
                        <span className="css-15ylkfs">
                          <Input
                            type="text"
                            name="nameOnCard"
                            value={spaname}
                            onChange={(e) => setNamespa(e.target.value)}
                            maxLength="50"
                            autoComplete="cc-name"
                            placeholder={t('Cardholder Name')}
                            id="nameOnCard"
                            required
                            className="fs-mask css-xv1qun"
                          />
                          <div className="dxp-field-label css-1bzl99f" data-size="lg">
                            <div className="css-5q8i94"></div>
                            <div className="css-g9uw22"></div>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="css-bjn8wh">
                      <span className="css-15ylkfs">
                        <Input
                          type="text"
                          name="cardnumber"
                          value={iban}
                          onChange={(e) => setIban(e.target.value)} // Correctly concatenate the last 4 digits
                          maxLength="50"
                          autoComplete="cc-number"
                          placeholder={t('Card Number')}
                          id="cardnumber"
                          required
                          className="fs-mask css-1qdl6m2"
                        />
                        <div className="dxp-field-label css-1bzl99f" data-size="lg">
                          <div className="css-5q8i94"></div>
                          <div className="css-g9uw22"></div>
                        </div>
                      </span>
                      <div className="css-fk62m5">
                        {/* Add your SVG icons here */}
                      </div>
                    </div>

                

                    <div className="css-1m3b0b0">
                      
                    </div>
                    <button title="Purchase" role="button" type="submit" className="css-pjhtp2" disabled={!stripe || loading}>
                      <span className="css-13wylk3">{loading ? t('Processing...') : t('Pay')}</span>
                    </button>
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
                  </form>
                </fieldset>
              </section>
            ))}
          </>
        )}
      </>
    )}
  </main>
</div>

{isadd ? <PaymentFormNew addData={addData} onrecived={setReceived} account_id={account_id} /> : null}
   </section>
   
    
     
  );
};
export default withTranslation()(withRouter(CheckoutFormUpdate));
