import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const AccessDenied = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
<div className="page-content">
<Container fluid>


<Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  <i className="bx bx-lock-alt  text-primary display-3" />
                </h1>
                <h4 className="text-uppercase">{t('Access denied')}</h4>
                <p>
                {t('You do not have permissions to access this page or component.')}
                </p>
                <p>
                {t('Contact your an administrator get permisions or goto the home pageand browse other pages')}
                </p>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to="/home"
                  >
                     {t('Back To Home')}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
 
        </Container>
      </div>

      {/* subscribe ModalHeader */}
     

     
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(AccessDenied));