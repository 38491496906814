import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const ForgetPasswordPage = (props) => {
  const { t } = props;

  // Document title
  document.title = t("Forget Password | Shiftspec - React Admin & Dashboard Template");

  // State for error and success messages
  const [forgetError, setForgetError] = useState("");
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState("");

  // Formik for form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    validationSchema: Yup.object({
      
      email: Yup.string().email(t("Invalid email")).required(t("Please Enter Your Email")),
    }),
    onSubmit: async (values) => {
      try {
        const response = await postFakeForgetPwd(values);
        if (response.success) {
          setForgetSuccessMsg(t("Reset link has been sent to your email."));
          setForgetError(""); // Clear any previous errors
        } else {
          setForgetError(t("Failed to send reset link. Please try again."));
          setForgetSuccessMsg(""); // Clear any previous success messages
        }
      } catch (error) {
        setForgetError(t("An error occurred. Please try again later."));
        setForgetSuccessMsg("");
      }
    },
  });

  // API call to post forget password
  const postFakeForgetPwd = async (data) => {
    try {
      const response = await axios.post("https://dspmetrics.com/fake-forget-pwd", data);
      return response.data;
    } catch (error) {
      console.error("Error in forget password API call:", error);
      throw error;
    }
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div
                  className="bg-soft-primary"
                  style={{ backgroundColor: "#000000" }}
                >
                  <Row>
                    <Col xs={5}>
                      <div className="text-white p-1 flex justify-content-center">
                        <img
                          src="https://shiftspec.s3.eu-north-1.amazonaws.com/web-logo+-+dark.png"
                          alt="Logo Light"
                          height="30"
                        />
                      </div>
                    </Col>
                    <Col xs={7}>
                      <div className="text-white p-1 flex justify-content-center">
                        <h5>{t("Sign in to continue to Shiftspec.")}</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {forgetError && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    )}
                    {forgetSuccessMsg && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{t("Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={t("Please Enter Your Email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email && (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        )}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md"
                            type="submit"
                          >
                            {t("Reset")}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Go back to")}{" "}
                  <Link
                    to="/login"
                    className="font-weight-medium text-primary"
                  >
                    {t("Login")}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  {t("Shiftspec. Crafted with")}{" "}
                  <i className="mdi mdi-heart text-danger" />{" "}
                  {t("by Shiftspec")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(ForgetPasswordPage));
