import React, { useRef, useState } from "react";
import { Button } from "reactstrap";
import FileViewer from "react-file-viewer";
import img4 from "../../assets/images/upload_png.png";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";


const CarUploadFiles =  (props) => {
  const { t ,setFiles} = props;
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    
    const handleFileUpload = () => {
      fileInputRef.current.click();   
    };

    const handleFileChange = (e) => {
      const files = Array.from(e.target.files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      setFiles([...selectedFiles, ...files]); 
    };
    const renderPreview = (file) => {
      const fileUrl = URL.createObjectURL(file);
    
      const fileTypes = {
        image: ["png", "jpeg", "jpg", "gif", "bmp"],
        csv: ["csv"],
        excel: ["xlsx"],
        doc: ["doc", "docx"],
        pdf: ["pdf"],
      };
      const fileExt = file.name.split(".").pop().toLowerCase();
      if (fileTypes.image.includes(fileExt)) {
        return <img src={fileUrl} alt="Uploaded file preview" key={fileUrl} style={{ maxWidth: '100px', maxHeight: '100px' }} />;
      } else if (fileTypes.csv.includes(fileExt)) {
        return <FileViewer fileType="csv" filePath={fileUrl} key={fileUrl} />;
      } else if (fileTypes.excel.includes(fileExt)) {
        return <FileViewer fileType="xlsx" filePath={fileUrl} key={fileUrl} />;
      } else if (fileTypes.doc.includes(fileExt)) {
        return <FileViewer fileType="docx" filePath={fileUrl} key={fileUrl} />;
      } else if (fileTypes.pdf.includes(fileExt)) {
        return <FileViewer fileType="pdf" filePath={fileUrl} key={fileUrl} />;
      } else {
        return <div key={fileUrl}>{t('Preview not available')}</div>;
      }
    };

  return (
    <div data-qa="add-documents-content">
    <div className="css-10ib5jr" id="add-documents-content">
      <div className="css-z1d1ba">
      {selectedFiles.map((file,index) => (
        <div key={index} data-qa="uploaded-file" className="css-130w8nn">
        <div className="css-6t6lyd">
            <div data-page="1" className="css-1cr5iyr">
              <span className="cardHoverButton css-8dpnl8">
                <Button data-interactable="true" data-qa="file-thumb" className="olv-button olv-ignore-transform css-4hfwli" type="button">
                  <span aria-hidden="true" data-qa="file-thumb-text" className="css-1ixbp0l">Weergeven</span>
                  <span data-qa="file-thumb-accessibility-text" className="css-1pdgtej">b7cabba3-908b-4539-9919-dc991278eb86.docx weergeven</span>
                </Button>
              </span>
              <span className="css-4fnwh7">
                <div className="css-qfdd9t"></div>
              </span>
              <div className="css-n0qoxq" id="fileDisplay" style={{ overflow: "hidden" }}>
              {renderPreview(file)}
              
           </div>
            </div>
          </div>
          <div className="css-6t6lyd">
            <div data-callout="document-icon" className="css-2t5fla">
              <div>
                <div className="css-36j3xk">
                  <span data-qa="file-name" id="Doc_orginal_name"></span>
                </div>
                <p className="css-rxwkhh">
                  <span>
                    <span data-qa="file-page-count">{file.name}</span>
                  </span>
                </p>
              </div>
              <div className="css-79elbk">
                <Button data-interactable="true" data-qa="file-menu" className="olv-button olv-ignore-transform css-y1ho7e" type="button" aria-haspopup="true">
                  <span data-qa="file-menu-icon" className="css-1vwl5y3">
                    <span className="css-zf56fy">
                      <span aria-hidden="true" className="SVGInline">
                        <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                          <circle cx="8" cy="2" r="2"></circle>
                          <circle cx="8" cy="8" r="2"></circle>
                          <circle cx="8" cy="14" r="2"></circle>
                        </svg>
                      </span>
                    </span>
                  </span>
                  <span data-qa="file-menu-accessibility-text" className="css-1pdgtej">Meer opties voor b7cabba3-908b-4539-9919-dc991278eb86.docx</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))}
        
        <div data-qa="add-document-content-card" data-callout="upload-file" className="css-130w8nn">
  <div className="css-6t6lyd">
    <div className="css-18lurlg">
      <div className="css-f2opis">
        <img className="css-90hogc" alt="200x200" width="200" src={img4} data-holder-rendered="true" />
      </div>
      <p className="css-yodtmi">{t('Drop files here')}</p>
      <div >
        <Button
          data-qa="upload-file-button"
          className="olv-button buttons-container olv-ignore-transform css-1bl3roz"
          type="button"
          aria-haspopup="true"
          onClick={handleFileUpload}
        >
          <span data-qa="upload-file-button-text" className="css-1ixbp0l">{t('UPLOAD')}</span>
          <span data-qa="upload-file-button-icon" className="css-11sgmcq">
            <span className="css-zf56fy">
              <span aria-hidden="true" className="SVGInline">
                <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                  <path d="M4 5h8l-4 6z"></path>
                </svg>
              </span>
            </span>
          </span>
        </Button>
        <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
        accept=".pdf, .jpeg, .jpg, .png, .doc, .docx, .xls, .xlsx"
      />
          
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
    
  </div>
  




  );
};


export default withTranslation()(withRouter(CarUploadFiles));