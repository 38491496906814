import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_MAILS_LIST,
  GET_SELECTED_MAILS,
  SET_FOLDER_SELECTED_MAILS,
  SELECT_FOLDER,
  UPDATE_MAIL,
  UPDATE_STATUS,
  SET_RESEND,
  SET_VALID_DATE,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  UPDATE_POST_FOLDER,
  DELETE_POST_DATA,
  PERMANENTLY_DELETE,
  RECOVERY_MAIL,
  ACCOUNT_CLOSE,
  ACCOUNT_PROFILE,
  EDIT_ACCOUNT_PROFILE,
  GET_ACCOUNT_INVOICE,
  GET_BRANDING_INFO,
  POST_BRANDING_INFO,
  PUT_BRANDING_INFO,
  DELETE_BRANDING_INFO,
  GET_TEMPLATE_INFO,
  POST_TEMPLATE_INFO,
  PUT_TEMPLATE_INFO,
  DELETE_TEMPLATE_INFO,
  GET_USERS_INFO,
  POST_USERS_INFO,
  PUT_USERS_INFO,
  DELETE_USERS_INFO,
  GER_PERMISSION_CONTENT,
  GET_NOTIFICATION,
} from "./actionTypes";

import {
  getMailsListsSuccess,
  getMailsListsFail,
  getSelectedMailsSuccess,
  getSelectedMailsFail,
  setFolderOnSelectedMailsSuccess,
  setFolderOnSelectedMailsFail,
  selectFoldersSuccess,
  selectFoldersFail,
  updateMailSuccess,
  updateMailFail,
  resendmailSuccess,
  resendmailFail,
  updatestatusSuccess,
  updatestatusFail,
  setvalidSuccess,
  setvalidFail,
  createfolderSuccess,
  createfolderFail,
  updatefolderrSuccess,
  updatefolderFail,
  updatepostfolderSuccess,
  updatepostfolderFail,
  deletepostquerySuccess,
  deletepostqueryFail,
  deletepermantlyquerySuccess,
  deletepermantlyqueryFail,
  recoveryquerySuccess,
  recoveryqueryFail,
  accountCloseSuccess,
  accountCloseFail,
  accountProfileSuccess,
  accountProfileFail,
  editAccountProfileSuccess,
  editAccountProfileFail,
  accountInvoiceSuccess,
  accountInvoiceFail,
  getBrandingInfoSuccess,
  getBrandingInfoFail,
  postBrandingInfoSuccess,
  postBrandingInfoFail,
  putBrandingInfoSuccess,
  putBrandingInfoFail,
  deleteBrandingInfoSuccess,
  deleteBrandingInfoFail,
  getTempInfo,
  getTempInfoSuccess,
  getTempInfoFail,
  postTempInfo,
  postTempInfoSuccess,
  postTempInfoFail,
  putTempInfo,
  putTempInfoSuccess,
  putTempInfoFail,
  deleteTempInfo,
  deleteTempInfoSuccess,
  deleteTempInfoFail,
  getUsersInfoSuccess,
  getUsersInfoFail,
  postUserInfoSuccess,
  postUserInfoFail,
  putUserInfoSuccess,
  putUserInfoFail,
  deleteUserInfoSuccess,
  deleteUserInfoFail,
  getUsersPermissionsSuccess,
  getUsersPermissionsFail,
  getNotificationsSuccess,
  getNotificationsFail
} from "./actions";

//Include Both Helper File with needed methods
import {
  getMailsLists,
  getselectedmails,
  setfolderonmails,
  selectFolders,
  updateMail,
  postresend,
  addvalidate,
  addNewFolder,
  updatepoststatus,
  upatefolder,
  upatepostfolderapi,
  deletepostdataapi,
  recoveryapi,
  deletepermantlyapi,
  closeapi,
  getprofilesapi,
  editprofilesapi,
  invoiceapi,
  getbrandingapi,
  postbrandingapi,
  editbrandingapi,
  deletebrandingapi,
  postruletemp,
  gettemprule,
  puttemprule,
  deletetemprule,
  postruleuser,
  getuserrule,
  putuserrule,
  deleteuserrule,
  getPermission,
  getNotification,
} from "helpers/fakebackend_helper";
function* fetchNotification({notification}) {
  try {
    const response = yield call(getNotification, notification);
    yield put(getNotificationsSuccess(response));
  } catch (error) {
    yield put(getNotificationsFail(error));
  }
}
function* fetchMailsLists({ payload: filter }) {
  try {
    const response = yield call(getMailsLists, filter);
    yield put(getMailsListsSuccess(response));
  } catch (error) {
    yield put(getMailsListsFail(error));
  }
}

function* onSelectFolders({payload: userId }) {
  try {
    const response = yield call(selectFolders,userId);
    yield put(selectFoldersSuccess(response));
  } catch (error) {
    yield put(selectFoldersFail(error));
  }
}

function* onGetSelectedMails({ payload: selectedmails }) {
  try {
    const response = yield call(getselectedmails, selectedmails);
    yield put(getSelectedMailsSuccess(response));
  } catch (error) {
    yield put(getSelectedMailsFail(error));
  }
}

function* onSetFolderOnSelectedMails({ payload: { selectedmails, folderId, activeTab } }) {
  try {
    const response = yield call(setfolderonmails, selectedmails, folderId);
    yield put(setFolderOnSelectedMailsSuccess(response));

    try {
      const newresponse = yield call(getMailsLists, parseInt(activeTab));
      yield put(getMailsListsSuccess(newresponse));
    } catch (error) {
      yield put(getMailsListsFail(error));
    }

    try {
      const response = yield call(getselectedmails, null);
      yield put(getSelectedMailsSuccess(response));
    } catch (error) {
      yield put(getSelectedMailsFail(error));
    }

  } catch (error) {
    yield put(setFolderOnSelectedMailsFail(error));
  }
}

function* onUpdateMail({ payload: mail }) {
  try {
    const response = yield call(updateMail, mail)
    yield put(updateMailSuccess(response));
  } catch (error) {
    yield put(updateMailFail(error))
  }
}


function* onResend({ payload: postdata }) {
  try {
    const response = yield call(postresend, postdata)
    yield put(resendmailSuccess(response));
  } catch (error) {
    yield put(resendmailFail(error))
  }
}
function* onPoststatus({ payload: statusid }) {
  try {
    const response = yield call(updatepoststatus, statusid)
    yield put(updatestatusSuccess(response));
  } catch (error) {
    yield put(updatestatusFail(error))
  }
}
function* onValid({ payload: validdata }) {
  try {
    const response = yield call(addvalidate, validdata)
    yield put(setvalidSuccess(response));
  } catch (error) {
    yield put(setvalidFail(error))
  }
}

function* onFolder({ payload: newfolder }) {
  try {
    const response = yield call(addNewFolder, newfolder)
    yield put(createfolderSuccess(response));
  } catch (error) {
    yield put(createfolderFail(error))
  }
}

function* onUpdateFolder({ payload: updatefolder }) {
  try {
    const response = yield call(upatefolder, updatefolder)
    yield put(updatefolderrSuccess(response));
  } catch (error) {
    yield put(updatefolderFail(error))
  }
}

function* onUpdatepostFolder({ payload: updatepostfolderdata }) {
  try {
    const response = yield call(upatepostfolderapi, updatepostfolderdata)
    yield put(updatepostfolderSuccess(response));
  } catch (error) {
    yield put(updatepostfolderFail(error))
  }
}

function* onDeletepost({ payload: updatepostfolderdata }) {
  try {
    const response = yield call(deletepostdataapi, updatepostfolderdata)
    yield put(deletepostquerySuccess(response));
  } catch (error) {
    yield put(deletepostqueryFail(error))
  }
}


function* onPermantly({ payload: permantly }) {
  try {
    const response = yield call(deletepermantlyapi, permantly)
    yield put(deletepermantlyquerySuccess(response));
  } catch (error) {
    yield put(deletepermantlyqueryFail(error))
  }
}

function* onRecovery({ payload: revovery }) {
  try {
    const response = yield call(recoveryapi, revovery)
    yield put(recoveryquerySuccess(response));
  } catch (error) {
    yield put(recoveryqueryFail(error))
  }
}


function* onClose({ payload: userid }) {
  try {
    const response = yield call(closeapi, userid)
    yield put(accountCloseSuccess(response));
  } catch (error) {
    yield put(accountCloseFail(error))
  }
}


function* onProfile({ payload: profiles }) {
  try {
    const response = yield call(getprofilesapi, profiles)
    yield put(accountProfileSuccess(response));
  } catch (error) {
    yield put(accountProfileFail(error))
  }
}
function* onEditProfile({ payload: profilesdata }) {
  try {
    const response = yield call(editprofilesapi, profilesdata)
    yield put(editAccountProfileSuccess(response));
  } catch (error) {
    yield put(editAccountProfileFail(error))
  }
}

function* onInvoice({ payload: invoice }) {
  try {
    const response = yield call(invoiceapi, invoice)
    yield put(accountInvoiceSuccess(response));
  } catch (error) {
    yield put(accountInvoiceFail(error))
  }
}


function* onGetBrand({ payload: info }) {
  try {
    const response = yield call(getbrandingapi, info)
    yield put(getBrandingInfoSuccess(response));
  } catch (error) {
    yield put(getBrandingInfoFail(error))
  }
}


function* onPostBrand({ payload: postinfo }) {
  try {
    const response = yield call(postbrandingapi, postinfo)
    yield put(postBrandingInfoSuccess(response));
  } catch (error) {
    yield put(postBrandingInfoFail(error))
  }
}

function* onPutBrand({ payload: putinfo }) {
  try {
    const response = yield call(editbrandingapi, putinfo)
    yield put(putBrandingInfoSuccess(response));
  } catch (error) {
    yield put(putBrandingInfoFail(error))
  }
}

function* onDeleteBrand({ payload: deleteinfo }) {
  try {
   
    const response = yield call(deletebrandingapi, deleteinfo)
    yield put(deleteBrandingInfoSuccess(response));
  } catch (error) {
    yield put(deleteBrandingInfoFail(error))
  }
}



function* onGetTemp({ payload: rules }) {
  try {
    const response = yield call(gettemprule, rules)
    yield put(getTempInfoSuccess(response));
  } catch (error) {
    yield put(getTempInfoFail(error))
  }
}


function* onPostTemp({ payload: temps }) {
  try {
    const response = yield call(postruletemp, temps)
    yield put(postTempInfoSuccess(response));
  } catch (error) {
    yield put(postTempInfoFail(error))
  }
}

function* onPutTemp({ payload: puttemps }) {
  try {
    const response = yield call(puttemprule, puttemps)
    yield put(putTempInfoSuccess(response));
  } catch (error) {
    yield put(putTempInfoFail(error))
  }
}

function* onDeleteTemp({ payload: removeid }) {
  try {
   
    const response = yield call(deletetemprule, removeid)
    yield put(deleteTempInfoSuccess(response));
  } catch (error) {
    yield put(deleteTempInfoFail(error))
  }
}


function* onGetUser({ payload: rules }) {
  try {
    const response = yield call(getuserrule, rules)
    yield put(getUsersInfoSuccess(response));
  } catch (error) {
    yield put(getUsersInfoFail(error))
  }
}


function* onPostUser({ payload: temps }) {
  try {
    const response = yield call(postruleuser, temps)
    yield put(postUserInfoSuccess(response));
  } catch (error) {
    yield put(postUserInfoFail(error))
  }
}

function* onPutUser({ payload: puttemps }) {
  try {
    const response = yield call(putuserrule, puttemps)
    yield put(putUserInfoSuccess(response));
  } catch (error) {
    yield put(putUserInfoFail(error))
  }
}

function* onDeleteUser({ payload: removeid }) {
  try {
   
    const response = yield call(deleteuserrule, removeid)
    yield put(deleteUserInfoSuccess(response));
  } catch (error) {
    yield put(deleteUserInfoFail(error))
  }
}

function* onGetPermission({ payload: permissionid }) {
  try {
   
    const response = yield call(getPermission, permissionid)
    yield put(getUsersPermissionsSuccess(response));
  } catch (error) {
    yield put(getUsersPermissionsFail(error))
  }
}
function* mailsSaga() {
    yield takeEvery(GET_MAILS_LIST, fetchMailsLists),
    yield takeEvery(SELECT_FOLDER, onSelectFolders),
    yield takeEvery(GET_SELECTED_MAILS, onGetSelectedMails),
    yield takeEvery(SET_FOLDER_SELECTED_MAILS, onSetFolderOnSelectedMails),
    yield takeEvery(UPDATE_MAIL, onUpdateMail);
    yield takeEvery(SET_RESEND, onResend);
    yield takeEvery(UPDATE_STATUS, onPoststatus);
    yield takeEvery(SET_VALID_DATE, onValid);
    yield takeEvery(CREATE_FOLDER, onFolder);
    yield takeEvery(UPDATE_FOLDER, onUpdateFolder);
    yield takeEvery(UPDATE_POST_FOLDER, onUpdatepostFolder);
    yield takeEvery(DELETE_POST_DATA, onDeletepost);
    yield takeEvery(PERMANENTLY_DELETE, onPermantly);
    yield takeEvery(RECOVERY_MAIL, onRecovery);
    yield takeEvery(ACCOUNT_CLOSE, onClose);
    yield takeEvery(ACCOUNT_PROFILE, onProfile);
    yield takeEvery(EDIT_ACCOUNT_PROFILE, onEditProfile);
    yield takeEvery(GET_ACCOUNT_INVOICE, onInvoice);
    yield takeEvery(GET_BRANDING_INFO, onGetBrand);
    yield takeEvery(POST_BRANDING_INFO, onPostBrand);
    yield takeEvery(PUT_BRANDING_INFO, onPutBrand);
    yield takeEvery(DELETE_BRANDING_INFO, onDeleteBrand);
    yield takeEvery(GET_TEMPLATE_INFO, onGetTemp);
    yield takeEvery(POST_TEMPLATE_INFO, onPostTemp);
    yield takeEvery(PUT_TEMPLATE_INFO, onPutTemp);
    yield takeEvery(DELETE_TEMPLATE_INFO, onDeleteTemp);
    yield takeEvery(GET_USERS_INFO, onGetUser);
    yield takeEvery(POST_USERS_INFO, onPostUser);
    yield takeEvery(PUT_USERS_INFO, onPutUser);
    yield takeEvery(DELETE_USERS_INFO, onDeleteUser);
    yield takeEvery(GER_PERMISSION_CONTENT, onGetPermission);
    yield takeEvery(GET_NOTIFICATION, fetchNotification);
}

export default mailsSaga;