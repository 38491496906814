import React, { useRef, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DraggableList from './DraggableList';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
  } from "reactstrap";
  import classnames from "classnames";
import { func } from 'prop-types';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
 function Formula({
  t, targetid,datatype,onDateSelected ,
  }) {
    useEffect(() => {
      toastr.options = {
        positionClass: 'toast-top-center',
        closeButton: true,
        debug: true,
        progressBar:true,
        timeOut: 30000, // Duration in milliseconds
      };
    }, []);
   
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col5, setcol5] = useState(true); 
    const [col9, setcol9] = useState(true);
    const [requreField, setRequre] = useState(true);
    const [validations, setValid] = useState(false);
    const [modal_scroll, setmodal_scroll] = useState(false);
    const t_col1 = () => {
      setcol1(!col1);
      setcol2(false);
      setcol3(false);
    };
    
    const t_Text = () => {
      setRequre(!requreField);
      setValid(false);
    };
    const t_col9 = () => {
        setcol9(!col9);
      };
    const t_col3 = () => {
        setcol3(!col3);
        setcol1(false);
        setcol2(false);
      };
    const t_col2 = () => {
      setcol2(!col2);
      setcol1(false);
      setcol3(false);
    };
    const t_col5 = () => {
        setcol5(!col5);
      };
     
      const fontFamilies = [
        { dataQa: 'arial', value: 'arial', label: 'Arial' },
        { dataQa: 'Courier', value: 'Courier', label: 'Courier' },
        { dataQa: 'CourierBold', value: 'CourierBold', label: 'CourierBold' },
        { dataQa: 'CourierBoldOblique', value: 'CourierBoldOblique', label: 'CourierBoldOblique' },
        { dataQa: 'CourierOblique', value: 'CourierOblique', label: 'CourierOblique' },
        { dataQa: 'HelveticaBold', value: 'HelveticaBold', label: 'HelveticaBold' },
        { dataQa: 'Helvetica', value: 'helvetica', label: 'Helvetica' },
        { dataQa: 'HelveticaBoldOblique', value: 'HelveticaBoldOblique', label: 'HelveticaBoldOblique' },
        { dataQa: 'TimesRoman', value: 'TimesRoman', label: 'TimesRoman' },
        { dataQa: 'TimesRomanBold', value: 'TimesRomanBold', label: 'TimesRomanBold' },
        { dataQa: 'TimesRomanItalic', value: 'TimesRomanItalic', label: 'TimesRomanItalic' },
        { dataQa: 'TimesRomanBoldItalic', value: 'TimesRomanBoldItalic', label: 'TimesRomanBoldItalic' },
        { dataQa: 'Symbol', value: 'Symbol', label: 'Symbol' },
        { dataQa: 'ZapfDingbats', value: 'ZapfDingbats', label: 'ZapfDingbats' },
      ];
      const getSVGContent = (format) => {
        switch (format) {
          case 'bold':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="m8.59 6.33.157-.006c.369 0 .674-.09.907-.267.219-.168.33-.45.33-.843 0-.22-.035-.397-.11-.54a.826.826 0 0 0-.288-.313 1.417 1.417 0 0 0-.444-.156 3.36 3.36 0 0 0-.562-.049H6.595V6.33H8.59zm.292 4.534c.203 0 .408-.024.61-.067a1.42 1.42 0 0 0 .486-.202.93.93 0 0 0 .316-.364c.079-.152.117-.351.117-.61 0-.502-.126-.844-.388-1.047-.275-.213-.633-.316-1.097-.316H6.595v2.606h2.287zM4 13V2h5.044c.504 0 .97.046 1.386.137.42.092.791.248 1.1.463.316.223.56.518.726.88.17.354.256.801.256 1.327 0 .58-.126 1.05-.386 1.44-.194.295-.46.549-.794.76.504.209.896.52 1.17.931.336.507.498 1.097.498 1.803 0 .575-.105 1.058-.322 1.475-.216.42-.51.765-.87 1.024a3.755 3.755 0 0 1-1.229.577 5.283 5.283 0 0 1-1.4.183H4z"></path>
            </svg>
            );
          case 'italic':
            return (
              <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
              <path d="M7 2v2h1.8l-3.101 7.5H3.6v2h5.999v-2H7.8L11 4h1.999V2z"></path>
            </svg>
            );
          case 'underline':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="M1 14h14v2H1zM2.846 2H4.98v5.956c0 .384.02.773.06 1.168.04.395.154.75.342 1.064.187.315.472.573.853.776.381.203.918.304 1.612.304.695 0 1.231-.101 1.612-.304.381-.203.667-.461.854-.776.192-.33.308-.692.34-1.064.04-.395.06-.784.06-1.168V2h2.134v6.612c0 .736-.12 1.376-.359 1.92a3.7 3.7 0 0 1-1.014 1.368 4.322 4.322 0 0 1-1.58.824 7.22 7.22 0 0 1-2.047.272 7.2 7.2 0 0 1-2.048-.272 4.322 4.322 0 0 1-1.577-.824 3.701 3.701 0 0 1-1.016-1.368c-.239-.544-.359-1.184-.359-1.92V2z"></path>
            </svg>
            );
          default:
            return null;
        }
      };
      const FontFormats = [
        { value: 'bold', label: 'Bold' },
        { value: 'italic', label: 'Italic' },
        { value: 'underline', label: 'Underline' },
        // Add more formats as needed
      ];
  
      const FormulatRect = document.getElementById(targetid);
      const fontFamilyValues = FormulatRect.getAttribute('data-fontFamily')
      const fontSizeValues = FormulatRect.getAttribute('data-fontSize')
      const fontFormatValues = FormulatRect.getAttribute('data-fontFormat')
      const fontColorsValues = FormulatRect.getAttribute('data-fontColor');
      const datalabel = FormulatRect.getAttribute('data-label')
       const [selectedFontFamily, setSelectedFontFamily] = useState(fontFamilyValues);
      const [selectedFontSize, setSelectedFontSize] = useState(fontSizeValues);
      const [selectedFontFormat, setSelectedFontFormat] = useState(fontFormatValues);
      const [selectedFontColor, setSelectedFontColor] = useState(fontColorsValues);
      const [datalab, setDataLabel] = useState(datalabel);
      const initialRequest  = FormulatRect.getAttribute('data-roll')
      const [request, setRequest] = useState(initialRequest);
      const newTextContent = FormulatRect.getAttribute('data-content');
    
      const [selectedLabels, setSelectedLabels] = useState([]);
      const [selectedFormulas, setSelectedFormulas] = useState([]);
      const [isDropdownOpen, setIsDropdownOpen] = useState(false);
      useEffect(() => {
        const fetchDataLabels = async () => {
          try {
            const labelData = document.querySelectorAll('[data-label]');
            
            // Filter labels based on conditions (data-type is "textarea" and value is a number)
            const filteredLabels = Array.from(labelData).filter(element => {
              const dataType = element.getAttribute('data-type');
              const value = element.textContent.trim();
              return dataType === 'number' && !isNaN(value);
            });
    
            // Extract the data-label attributes from the filtered labels
            const labelsFromAPI = filteredLabels.map(element => element.getAttribute('data-label'));
            
            setDataLabels(labelsFromAPI);
          } catch (error) {
            console.error('Error fetching data labels:', error);
          }
        };
    
        fetchDataLabels();
      }, []);
     
      const [dataLabels, setDataLabels] = useState([]);
      const formulas = ['+', '-', '*', '/'];
      const textareaRef = useRef(null);

     
      const  labelStyle = {
        border: fontFormatValues === fontFormatValues ? '1px solid #cecece' : 'none',
        // Add other styles as needed
      };
      const extractLabelsAndFormulas = (textContent) => {
      const regex = /\[([^\]]+)\]([+\-*/])?/g;
      let selectedLabels1 = [];
      let selectedFormulas1 = [];
    
      let match;
      while ((match = regex.exec(textContent)) !== null) {
        const label = match[1];
        const operator = match[2];
    
        selectedLabels1.push(label);
    
        if (operator !== undefined) {
          selectedFormulas1.push(operator);
        }
      }
    
      return { selectedLabels1, selectedFormulas1 };
    };
    const { selectedLabels1, selectedFormulas1 } = extractLabelsAndFormulas(newTextContent);
      const handleLabelTextChange = (e) => {
        const selectedLabelValue = e.target.value;
        setSelectedLabels([...selectedLabels, selectedLabelValue]);
        setIsDropdownOpen(true);
      };
    
      const handleFormulaChange = (e) => {
        const selectedFormulaValue = e.target.value;
        setSelectedFormulas([...selectedFormulas, selectedFormulaValue]);
      };
      const handleClearButtonClick = () => {
        setSelectedLabels([]);
        setSelectedFormulas([]);
      };
      
    

      const constructFormula = () => {
        let formula = '';
        for (let i = 0; i < Math.max(selectedLabels.length, selectedFormulas.length); i++) {
          formula += `[${selectedLabels[i]}]` || ''; // Append label inside square brackets
          formula += selectedFormulas[i] || ''; // Append formula
          formula += ' '; // Add space between label and formula
        }
        return formula.trim();
      };
    
      const handleTextareaClick = () => {
        setIsDropdownOpen(true);
      };
    
      const handleOptionSelect = (selectedValue) => {
        setIsDropdownOpen(false);
        setSelectedLabels([...selectedLabels, selectedValue]);
        textareaRef.current.focus();
      };
      const handleSave = () => {
        const textareaValue = textareaRef.current.value;
        if (!textareaValue) {
          toastr.error(t('Formula cannot be empty. Please enter a formula.'));
          return;
        }
        if (!isValidFormula(textareaValue)) {
          toastr.error(t('Invalid formula. Please correct the formula.'));
          return;
        }
        
      FormulatRect.setAttribute('data-content', textareaValue);
    
        setmodal_scroll(!modal_scroll);
        toastr.success(t('Formula saved successfully!'));
        setSelectedLabels([]);
        setSelectedFormulas([]);
      };
      const isValidFormula = (formula) => {
      const pattern = /^\s*\[[a-zA-Z0-9]+\]\s*([+\-*/]\s*\[[a-zA-Z0-9]+\]\s*)*$/;
      if (formula.includes('[undefined]')) {
        return false;
      }
      return pattern.test(formula);
      };

      useEffect(() => {
        const formulaFromLabelsAndFormulas = constructFormula();
        if (textareaRef.current) {
          textareaRef.current.value = formulaFromLabelsAndFormulas;
        }
      }, [selectedLabels, selectedFormulas]);
      const handleFontFamilyChange = (event) => {
        const selectedFontFamilyValue = event.target.value;
        setSelectedFontFamily(event.target.value);
        FormulatRect.setAttribute('data-fontFamily', selectedFontFamilyValue);

      };
        const handleFontColorChange = (event) => {
            setSelectedFontColor(event.target.value);
            FormulatRect.setAttribute('data-fontColor', event.target.value);


        };
        const handleFontSizeChange = (event) => {
            setSelectedFontSize(event.target.value);
            FormulatRect.setAttribute('data-fontSize', event.target.value);
            
        };
        const handleLiClick = (value) => {
          const newFontFormat = value;
          setSelectedFontFormat(value);
          FormulatRect.setAttribute('data-fontFormat', value);
        };
        const handleCheckboxChange = (event) => {
            setRequest(event.target.value);
            FormulatRect.setAttribute('data-roll', event.target.value);
          };
        
    const itemStyle = {
        display: 'flex',
      };
      function tog_scroll() {
        setmodal_scroll(!modal_scroll);
        removeBodyCss();
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding");
      }
      const handleLabelChange = (event) => {
        setDataLabel(event.target.value);
        FormulatRect.setAttribute('data-label', event.target.value);
      };
      
      useEffect(() => {
        // Replace 'your-node-server-url' with the URL of your Node.js server
        onDateSelected( 
          targetid,datatype
          )
      }, [targetid,datatype]);
      
     
      return (
        <React.Fragment>
        <Row>
         <Col lg={12}>
   <div className="css-vlt30q">
     <div style={itemStyle}>
         <span className="css-zf56fy">
             <span aria-hidden="true" className="SVGInline">
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" aria-hidden="true" focusable="false" data-qa="tab-palette-item-icon"><path d="m15.78 15-3.2-4H15l2 2.48L19 11h2.44l-3.2 4 3.2 4H19l-2-2.48L15 19h-2.42zM10 3a3 3 0 0 0-3 3v4H5v2h2v7a1 1 0 0 1-2 0v-1H3v1a3 3 0 0 0 6 0v-7h2v-2H9V6a1 1 0 0 1 2 0v1h2V6a3 3 0 0 0-3-3z"></path></svg>

 </span>
 </span>
 <span data-qa="pannel-header" style={{paddingLeft:'0.625em'}}>{t('Formula')}</span>
 </div>
 </div>
 
   <div className="accordion " id="accordion" >
   <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                           <button
                             className={classnames(
                               "accordion-button",
                               "fw-medium",
                               { collapsed: !col3 }
                             )}
                             type="button"
                             onClick={t_col5}
                             style={{ cursor: "pointer" }}
                           >
                            {t('Set Formula')} 
                           </button>
                         </h2>
 
                         <Collapse isOpen={col5} className="accordion-collapse">
                           <div className="accordion-body">
                             <div className="text-muted">
                             <div style={{marginTop:'8px', marginBottom: '8px'}}>
                             <Col className="col-12" style={{ display: newTextContent === "formula" ? 'none' : 'block' }}>
                   <p className='css-rhk3of'>{t('You has set')}:{newTextContent}</p>
                       </Col>
                        <button
                       
                         onClick={() => {
                            tog_scroll();
                          }}
                          data-toggle="modal"
                          className="olv-button olv-ignore-transform css-129ohl "
                         type="button">
                            <span data-qa="edit-dropdown-values-text" className="css-16hz1ch">
                            {newTextContent === "formula" ? t('Set Formula') : t('Edit Formula')}
                              </span>
                     </button>
                     </div>
 
                             </div>
                           </div>
                         </Collapse>
                         <Modal
                      isOpen={modal_scroll}
                      toggle={() => {
                        tog_scroll();
                      }}
                      
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                        {t('Set Up Formula')} 
                        </h5>
                        <button
                          type="button"
                          onClick={() =>
                            setmodal_scroll(false)
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <small> {t('Build a formula from number and date fields in you  envelope.Field names must be enclosed in square bracket')}  
                        ("[]"). {t('Select from suggested fields and date functions.Once complated click anywhere outside the box to make auticomplate disappear and save.')}
                        
                        </small>
                     
                        <Row>
      <Col className="col-12">
        <p className='css-rhk3of'>{t('Formula')}</p>
      </Col>
      <Col className="col-12" style={{ display: newTextContent === "formula" ? 'none' : 'block' }}>
                   <p className='css-rhk3of'>{t('You has set')} :{newTextContent}</p>
      </Col>
      <Col className="col-12">
        <textarea 
          style={{ width: '100%', cursor: 'pointer', height: '6em' }}
          value={constructFormula()}
          readOnly
          onClick={handleTextareaClick}
          ref={textareaRef}
        />
        {isDropdownOpen && (
          <select onChange={(e) => handleLabelTextChange(e)} onBlur={() => setIsDropdownOpen(false)}>
            <option value="">{t('Select Data Label')}</option>
            {dataLabels.map((label, index) => (
              <option key={index} value={label} onClick={() => handleOptionSelect(label)}>
                {label}
              </option>
            ))}
          </select>
        )}
           {selectedLabels.length > 0 && (
          <select onChange={handleFormulaChange}>
            <option value="">{t('Select Formula')}</option>
            {formulas.map((formula, index) => (
              <option key={index} value={formula}>
                {formula}
              </option>
            ))}
          </select>
        )}
    {selectedLabels.length > 0 && (
          <button
            style={{
              position: 'absolute',
              top: '0',
              right: '13px',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color:'red'
            }}
            onClick={handleClearButtonClick}
          >
           {t('Clear')} 
          </button>
        )}
      </Col>
    </Row>
        <div>


</div>
      
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              setmodal_scroll(false)
                            }
                          >
                            {t('Close')}
                          </button>
                          <button type="button"  className="btn btn-primary" onClick={handleSave}> 
                          {t('Save')}
                          </button>
                        </div>
                      </div>
                    </Modal>
                       </div>
   <div className="accordion-item">
                               <h2 className="accordion-header" id="headingOne">
                                 <button
                                   className={classnames(
                                     "accordion-button",
                                     "fw-medium",
                                     { collapsed: !col1 }
                                   )}
                                   type="button"
                                   onClick={t_col1}
                                   style={{ cursor: "pointer" }}
                                 >
                                   {t('Formatting')}
                                 </button>
                               </h2>
       
           <Collapse isOpen={col1} className="accordion-collapse">
             <div className="accordion-body">
             <div className="text-muted">
           <div className="css-q10huv">
           <div className="css-hboir5">
           <select
          className="css-12ihcxq" 
          value={fontFamilyValues}
         onChange={handleFontFamilyChange} >
         {fontFamilies.map((font) => (
     <option key={font.dataQa} value={font.value} data-qa={font.dataQa}>
       {font.label}
     </option>
   ))}
         </select>
               <span className="css-1baizyq">
                   <span className="css-lzaifp">
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                           <path d="M4 5h8l-4 6z"></path>
                       </svg>
                   </span>
               </span>
           </div>
       </div>
       <div className="css-9f4jv1">
           <div  style={{ marginRight: '-1px' ,width:"50px" }}>
               <div className="css-0">
                   <label data-qa="font-size-label" htmlFor="G29wOvqLVz" className="css-5gs0ys">{t('Font Size')}</label>
               </div>
               <div className="css-q10huv">
                   <div className="css-hboir5">
                   <select 
                 data-qa="font-size"
                  id="G29wOvqLVz"
                   className="css-12ihcxq"
                   value={fontSizeValues}
                    onChange={handleFontSizeChange}>
                     <option data-qa="font-7" value="7">7</option>
                     <option data-qa="font-8" value="8">8</option>
                     <option data-qa="font-9" value="9">9</option>
                     <option data-qa="font-10" value="10">10</option>
                     <option data-qa="font-11" value="11" >11</option>
                     <option data-qa="font-12" value="12" >12</option>
                     <option data-qa="font-14" value="14">14</option>
                     <option data-qa="font-16" value="16">16</option>
                     <option data-qa="font-18" value="18">18</option>
                     <option data-qa="font-20" value="20">20</option>
                     <option data-qa="font-22" value="22">22</option>
                     <option data-qa="font-24" value="24">24</option>
                     <option data-qa="font-26" value="26">26</option>
                     <option data-qa="font-28" value="28">28</option>
                     <option data-qa="font-36" value="36">36</option>
                     <option data-qa="font-48" value="48">48</option>
                     <option data-qa="font-72" value="72">72</option>
                 </select>
                       <span className="css-1baizyq">
                           <span className="css-lzaifp">
                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                                   <path d="M4 5h8l-4 6z"></path>
                               </svg>
                           </span>
                       </span>
                   </div>
               </div>
           </div>
           <div className="css-199kmjd">
           {FontFormats.map((format) => (
         <div key={format.value} className="css-vxcmzt">
           <Input
             id={format.value}
             name="radioGroup"
             type="radio"
             className="css-1pdgtej"
             value={fontFormatValues}
             checked={selectedFontFormat === format.value}
           />
           <label htmlFor={format.value}
            className={`css-4lt7ka`} 
            style={selectedFontFormat === format.value ? labelStyle : {}}
            onClick={() => handleLiClick(format.value)}>
             <span className="css-zf56fy">
               <span aria-hidden="true" className="SVGInline">
               {getSVGContent(format.value)}
               </span>
             </span>
             <span className="css-16htk9o">{format.label}</span>
           </label>
         </div>
       ))}
       </div>
       </div>
       <div className="css-q10huv">
           <div className="css-hboir5">
               <select className="css-12ihcxq"
                value={fontColorsValues} 
                onChange={handleFontColorChange}>
                     <option data-qa="#ffffff" value="#ffffff" selected>{t('White')}</option>
            <option data-qa="#0053eb" value="#0053eb">{t('Blue')}</option>
            <option data-qa="#f7e601" value="#f7e601">{t('Yellow')}</option>
            <option data-qa="#f70101" value="#f70101">{t('Red')}</option>
            <option data-qa="#000000" value="#000000">{t('Black')}</option>
            <option data-qa="#0087ff" value="#0087ff">{t('Bright Blue')}</option>
            <option data-qa="#7e0101" value="#7e0101">{t('Dark Red')}</option>
            <option data-qa="#ff0000" value="#ff0000">{t('Bright-Red')}</option>
            <option data-qa="#FFD700" value="#FFD700">{t('Gold')}</option>
            <option data-qa="#008000" value="#008000">{t('Green')}</option>
            <option data-qa="#006400" value="#006400">{t('Dark Green')}</option>
               </select>
               <span className="css-1baizyq">
                   <span className="css-lzaifp">
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                           <path d="M4 5h8l-4 6z"></path>
                       </svg>
                   </span>
               </span>
           </div>
       </div>
       
       
                       </div>
                           </div>
                       </Collapse>
                     </div>
                     <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                           <button
                             className={classnames(
                               "accordion-button",
                               "fw-medium",
                               { collapsed: !col3 }
                             )}
                             type="button"
                             onClick={t_col9}
                             style={{ cursor: "pointer" }}
                           >
                            {t('Data Label')} 
                           </button>
                         </h2>
 
                         <Collapse isOpen={col9} className="accordion-collapse">
                           <div className="accordion-body">
                             <div className="text-muted">
                             <div className="css-q10huv">
                  <div className="css-hboir5">
                  <Input
                   className="css-12ihcxq"
                   type="text"
                   value={datalabel}
                   onChange={handleLabelChange}
                   />
                   
                  </div>
                  </div>
                             </div>
                           </div>
                         </Collapse>
                       </div>
                       <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                           <button
                             className={classnames(
                               "accordion-button",
                               "fw-medium",
                               { collapsed: !col3 }
                             )}
                             type="button"
                             onClick={t_col5}
                             style={{ cursor: "pointer" }}
                           >
                            {t('Request')} 
                           </button>
                         </h2>
 
                         <Collapse isOpen={col5} className="accordion-collapse">
                           <div className="accordion-body">
                             <div className="text-muted">
                             <Label>{t('Set the rules')} </Label>
                             <div className="css-q10huv">
                             <div className="css-hboir5">
                             <select
                            className="css-12ihcxq"
                            value={initialRequest}
                            onChange={handleCheckboxChange}
                             >
                      <option  value="true" >{t('Mandatory field')} </option>
                      <option  value="false">{t('Optional field')} </option>
                       </select>
                       <span className="css-1baizyq">
                       <span className="css-lzaifp">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                       <path d="M4 5h8l-4 6z"></path>
                       </svg>
                      </span>
                      </span>
                      </div>
                     </div>
 
 
                             </div>
                           </div>
                         </Collapse>
                       </div>
 
                     </div>
                   </Col>
                 
                 </Row>
   </React.Fragment>
        
        );
      }
      
      export default withTranslation()(Formula);