import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
  } from "reactstrap";
  import { Link ,useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import PropTypes  from "prop-types";
import RequesDemo from "./RequesDemo";
import CustomNavbar from "components/HeaderUnUser/Header";

const Industries = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language
  const location = useLocation();
  
        useEffect(() => {
          const headerColor = document.getElementById('header_color');
        
          // Check if the element exists before accessing it
          if (headerColor) {
            headerColor.style.backgroundColor = '#f3f7ff';
          }
          // Function to update meta tags
          const updateMetaTagsForIndustry = (industryData) => {
            const industryName = industryData?.name || 'Industry Solutions';
            const industryDescription = industryData?.description || 'Explore our tailored workforce management solutions designed to enhance business operations across various industries.';
        
            // Update document title dynamically based on the industry name
            document.title = `${industryName} - Workforce Management Solutions | ShiftSpec.com`;
        
            // Function to add or update meta tags
            const addOrUpdateMeta = (name, content) => {
                let metaTag = document.querySelector(`meta[name="${name}"]`);
                if (metaTag) {
                    // Update content if meta tag exists
                    metaTag.setAttribute('content', content);
                } else {
                    // Create new meta tag if it doesn't exist
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('name', name);
                    metaTag.setAttribute('content', content);
                    document.head.appendChild(metaTag);
                }
            };
        
            // Array of meta tags to add or update
            const metaTags = [
                { name: 'description', content: industryDescription },
                { name: 'keywords', content: `${industryName}, Workforce Management, Employee Scheduling, Time Tracking, Digital Signatures, Business Efficiency, Industry Solutions` },
                { name: 'author', content: 'ShiftSpec' }
            ];
        
            // Add or update each meta tag
            metaTags.forEach(tag => addOrUpdateMeta(tag.name, tag.content));
        
            // Helper function to handle Open Graph and Twitter meta tags
            const addOrUpdateMetaTag = (property, content) => {
                let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
                if (tag) {
                    tag.setAttribute('content', content);
                } else {
                    tag = document.createElement('meta');
                    tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
                    tag.setAttribute('content', content);
                    document.head.appendChild(tag);
                }
            };
        
            // Open Graph Meta Tags for better social sharing
            addOrUpdateMetaTag('og:title', industryName);
            addOrUpdateMetaTag('og:description', industryDescription);
            addOrUpdateMetaTag('og:url', `https://www.shiftspec.com/industries/${industryData?.slug}`);
            addOrUpdateMetaTag('og:type', 'website');
        
            // Twitter Meta Tags
            addOrUpdateMetaTag('twitter:title', industryName);
            addOrUpdateMetaTag('twitter:description', industryDescription);
            addOrUpdateMetaTag('twitter:card', 'summary_large_image');
        };

        const industryData = {
          name: 'industries',
          description: 'Our workforce management solutions for retail streamline employee scheduling, time tracking, and labor cost management for retail businesses.',
          slug: 'industries',
          keywords: 'industries  Workforce Management, Employee Scheduling, Time Tracking, Labor Cost Management'
        };
          
    
          // Call the function to update meta tags
         updateMetaTagsForIndustry(industryData);
      
        }, [ location.pathname, t]);
        const industriesData = [
          {
            id: 'hotel',
            title: t('Hotel'),
            description: t('All personnel planning for your hotel in one simple package'),
            imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.26.08_fdd6f7c1.jpg',
          },
          {
            id: 'production',
            title: t('Production'),
            description: t('User-friendly planning software, perfect for your production company'),
            imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.27.40_6a189d3f.jpg',
          },
          {
            id: 'recreation',
            title: t('Recreation'),
            description: t('The solution in personnel planning and time registration for recreation companies'),
            imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.29.10_f2f866d2.jpg',
          },
          {
              id: 'restaurant',
              title: t('Restaurant'),
              description:  t('Most complete employee scheduling software for the hospitality industry'),
              imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.30.12_d2365e42.jpg',
            },
    
            {
              id: 'retail',
              title: t('Retail'),
              description: t('Employee scheduling and time registration for your store'),
              imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.30.57_9a858d62.jpg',
            },
    
            {
              id: 'transport',
              title: t('Transportation'),
              description: t('All personnel planning for your transport company in one simple package'),
              imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.33.14_72f27240.jpg',
            },
          // Add more industry data as needed
        ];
  return (
    <React.Fragment>
<CustomNavbar />
  
  <section className="relative lg:pb-10 pt-20 overflow-hidden" style={{background:'#f3f7ff'}}>
  <Row>
      <Col sm={6} className='flex align-items-center justify-content-center'>
     
      <div className=" text-left">
<h1 className="font-bold text-dark text-[30px]  font-extrabold">
{t('Industries')}
</h1>
<h2 className="text-base  font-light text-dark-30 ">
  {t('Revolutionizing Workforce Management Across All Industries')}
</h2>

{/* Input and Button Row */}
<div className="flex items-center mt-4">
  <input
    type="text"
    placeholder={t('Your business email')}
    
    className="flex-grow px-4 py-2 border border-gray-300 rounded-l-lg text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
  />

  <a href='/register/starter'>
    <button
      title="Try for free"
      role="button"
      type="submit"
      className=" text-white bg-blue-500  font-semibold"
      style={styles.button}
    >
     {t('Try for Free')} 
    </button>
  </a>
</div>
</div>


      </Col>
      <Col sm={6}>
      <div className="lg:col-span-5 text-center lg:text-right">
      <img
        src="https://shiftspec.s3.eu-north-1.amazonaws.com/aSDAWSDSAD32SAD.png"
        width="450"
        height="283"
        className="mx-auto lg:mr-0"
        alt="header image"
        srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/aSDAWSDSAD32SAD.png 1350w"
        sizes="(max-width: 450px) 100vw, 450px"
      />
      <div className="lg:hidden flex flex-col items-center text-center mt-12">
        <div className="flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-5 mb-4 lg:mb-3 max-w-xs"></div>
      </div>
    </div>
    {/* Triangle SVG */}
<svg
  className="absolute top-0  inset-x-0 -z-[5] mx-auto h-[40%] lg:translate-x-[20%] mt-32 opacity-20"
  style={{right:'24px'}}
  width="732"
  height="389"
  viewBox="0 0 732 389"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clipPath="url(#clip0_26_895)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M625.302 293.386C626.169 291.133 627.896 289.21 630.278 288.153C631.888 287.438 633.666 287.188 635.412 287.432V287.432L720.091 299.248C722.671 299.608 724.861 300.98 726.316 302.906C727.772 304.833 728.491 307.315 728.131 309.895C727.91 311.483 727.287 312.988 726.322 314.268V314.268L676.344 380.577C674.776 382.658 672.513 383.906 670.122 384.242C667.731 384.578 665.211 384.002 663.13 382.434C661.85 381.469 660.836 380.194 660.185 378.729V378.729L625.484 300.603C624.426 298.223 624.434 295.639 625.302 293.386Z"
      stroke="#2A86CD"
      strokeWidth="6.29227"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.921 2.23295C172.217 1.48943 174.797 1.62246 177.117 2.80827C178.686 3.61016 180 4.83541 180.909 6.34525V6.34525L225.019 79.6095C226.363 81.8422 226.676 84.4081 226.094 86.7518C225.513 89.0955 224.038 91.2169 221.807 92.5606C220.434 93.3875 218.873 93.8506 217.271 93.9062V93.9062L134.303 96.784C131.7 96.8743 129.306 95.9005 127.54 94.253C125.774 92.6055 124.636 90.2844 124.545 87.6802C124.488 86.0775 124.841 84.487 125.57 83.059V83.059L164.428 6.9169C165.612 4.59653 167.624 2.97646 169.921 2.23295Z"
      stroke="#2A86CD"
      strokeWidth="6.29227"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.2132 113.629C63.4079 110.257 66.8895 107.722 71.1378 106.824C74.0107 106.216 76.9964 106.425 79.757 107.427V107.427L213.711 156.045C217.793 157.527 220.889 160.522 222.593 164.168C224.298 167.813 224.612 172.109 223.131 176.19C222.22 178.701 220.683 180.939 218.667 182.692V182.692L114.223 273.468C110.946 276.316 106.824 277.566 102.81 277.285C98.795 277.003 94.8872 275.191 92.0379 271.914C90.2844 269.897 89.0737 267.467 88.5203 264.852V264.852L59.01 125.459C58.1106 121.211 59.0185 117.002 61.2132 113.629Z"
      stroke="#2A86CD"
      strokeWidth="10.4871"
    ></path>
  </g>
  <defs>
    <clipPath id="clip0_26_895">
      <rect width="732" height="388.117" fill="white"></rect>
    </clipPath>
  </defs>
</svg>
      </Col>
  </Row>
  </section>

  <section className="relative bg-white px-8 pt-12 pb-12">
        <Row className="align-items-center">
          {industriesData.map((industry) => (
            <Col key={industry.id} xs={12} sm={6} md={4} className="mb-4 mb-md-0">
              <Link to={`/${industry.id}`} className="text-decoration-none">
                <Card
                  className="h-100 shadow-sm card-hover"
                >
                  <div className="card-img-top-wrapper">
                    <img
                      src={industry.imgSrc}
                      alt={industry.title}
                      className="card-img-top img-fluid"
                      style={{ objectFit: 'cover',  width: '100%' }}
                    />
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center text-left">
                    <h5 className="card-title text-dark font-extrabold">{industry.title}</h5>
                    <p className="font-bold font-open-sans mb-12 text-secondary">
                      {industry.description}
                    </p>
                  </div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </section>

<RequesDemo />
    </React.Fragment>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#00a0f0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    color: '#555',
  },
  cardHover: {
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '4px 0 0 4px',
    border: '1px solid #ccc',
    outline: 'none',
    transition: 'border-color 0.3s ease',
  },
  input: {
    flex: 1,
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '4px 0 0 4px',
    border: '1px solid #ccc',
    outline: 'none',
  },

  
};

Industries.propTypes = {
    history: PropTypes.object,
    t: PropTypes.isRequired,
  };

  export default withRouter(withTranslation()(Industries));

