import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
//signature
import Signature from "./signature/reducer";

//Documents
import Documents from "./document/reducer";
//Prepare
import Prepare from "./prepare/reducer";

//mails
import mails from "./mails/reducer";
import getClientData from "./collaborator/reducer";
import getShiftData from "./shift/reducer";
import getAbsenceData from "./absence/reducer";
import getReportData from "./report/reducer";
import Vhicles from "./Fleet/reducer";
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Signature,
  Documents,
  Prepare,
  mails,
  getClientData,
  getShiftData,
  getAbsenceData,
  getReportData,
  Vhicles,
});

export default rootReducer;
