import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersPermissions } from 'store/actions';

// Create a context for permissions
const PermissionsContext = createContext();

// Custom hook to use permissions context
export const useUserPermissions = () => {
  return useContext(PermissionsContext);
};

const PermissionsProvider = ({ children }) => {
  const dispatch = useDispatch();

  // Get user information from localStorage
  const authUser = localStorage.getItem('authUser');
  const user = authUser ? JSON.parse(authUser) : null;

  // Ensure clientId exists
  const userId = user?.clientId || null;

  // Redux state
  const { permissionid, loading: permissionsLoadingFromRedux, error } = useSelector(state => ({
    permissionid: state.mails.permissionid,
    error: state.mails.error,
    loading: state.mails.loading,
  }));

  // Local state
  const [permissions, setPermissions] = useState(null);
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // Fetch permissions on mount or when userId changes
  useEffect(() => {
    if (userId) {
      dispatch(getUsersPermissions(userId));
    }
  }, [dispatch, userId]);

  // Sync Redux state to local state
  useEffect(() => {
    if (permissionid) {
      setPermissions(permissionid);
    }

    if (!permissionsLoadingFromRedux) {
      setTimeout(() => {
        setPermissionsLoading(false);
      }, 2000);
    }
  }, [permissionid, permissionsLoadingFromRedux]);

  // Handle loading state
  if (permissionsLoading) {
    return (
      <div id="preloader">
        <div id="status">
          <div className="punchloader">
            <span className="punchloader__item"></span>
            <span className="punchloader__item"></span>
          </div>
          <div className="digital-sign-online">
            <span className="sign-onlinetext">Shiftspec</span>
          </div>
        </div>
      </div>
    );
  }

  // Render provider with loaded permissions
  return (
    <PermissionsContext.Provider value={{ permissions, loading: permissionsLoading }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;
