import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_SHIFT,
  POST_SHIFT,
  PUT_SHIFT,
  DELETE_SHIFT,
  GET_UREN,
  POST_UREN,
  PUT_UREN,
  DELETE_UREN,
  GET_CONTRACT_EMPLOYEER,
  GET_TEAM,
  POST_NEW_TEAM,
  GET_SCHEDULE,
  POST_INSPECTION_EMPLOYEE,
  GET_SURCHARGER,
  POST_SURCHARGER,
  PUT_SURCHARGER,
  DELETE_SURCHARGER,
  } from "./actionTypes";
 import {
  getShiftSuccess,
  getShiftFail,
  postShiftSuccess,
  postShiftFail,
  putShiftSuccess,
  putShiftFail,
  deleteShiftSuccess,
  deleteShiftFail,
  getUrenSuccess,
  getUrenFail,
  postUrenSuccess,
  postUrenFail,
  putUrenSuccess,
  putUrenFail,
  removeUrenSuccess,
  removeUrenFail,
  getContractEmployerSuccess,
  getContractEmployerFail,
  getShifTeamtSuccess,
  getShiftTeamFail,
  postNewTeamtSuccess,
  postNewTeamTeamFail,
  getShifScheduleSuccess,
  getShifScheduleFail,
  PostInspectionTimeSheetSuccess,
  PostInspectionTimeSheetFail,
  DelSurchargerSuccess,
  DelSurchargerFail,
  GetSurchargerSuccess,
  GetSurchargerFail,
  PutSurchargerSuccess,
  PutSurchargerFail,
  PostSurchargerSuccess,
  PostSurchargerFail,
  } from "./actions";

  //Include Both Helper File with needed methods
import {
  getShiftApi,
  postShiftApi,
  putShiftApi,
  deleteShiftApi,
  getUrenApi,
  postUrenApi,
  putUrenApi,
  removeUrenApi,
  getContractEmployerApi,
  getTeamdataApi,
  postNewTeamApi,
  getScheduleApi,
  postInspectionTimeSheetApi,
  getSurchargerApi,
  putSurchargerApi,
  postSurchargerApi,
  deleteSurchargerApi,
  } from "helpers/fakebackend_helper";
  function* getSurchargertSaga({ payload: getsurcharger }) {
    try {
      const response = yield call(getSurchargerApi, getsurcharger);
      yield put(GetSurchargerSuccess(response));
    } catch (error) {
      yield put(GetSurchargerFail(error));
    }
  }  
  function* postSurchargertSaga({ payload: postsurcharger }) {
    try {
      const response = yield call(postSurchargerApi, postsurcharger);
      yield put(PostSurchargerSuccess(response));
    } catch (error) {
      yield put(PostSurchargerFail(error));
    }
  } 
  function* putSurchargertSaga({ payload: putsurcharger }) {
    try {
      const response = yield call(putSurchargerApi, putsurcharger);
      yield put(PutSurchargerSuccess(response));
    } catch (error) {
      yield put(PutSurchargerFail(error));
    }
  }

  function* delSurchargertSaga({ payload: deletesurcharger }) {
    try {
      const response = yield call(deleteSurchargerApi, deletesurcharger);
      yield put(DelSurchargerSuccess(response));
    } catch (error) {
      yield put(DelSurchargerFail(error));
    }
  }


  function* postInspectionTimeSheetSaga({ payload: inspection }) {
    try {
      const response = yield call(postInspectionTimeSheetApi, inspection);
      yield put(PostInspectionTimeSheetSuccess(response));
    } catch (error) {
      yield put(PostInspectionTimeSheetFail(error));
    }
  }
  function* fetchScheduleSaga({ payload: schedule }) {
    try {
      const response = yield call(getScheduleApi, schedule);
      yield put(getShifScheduleSuccess(response));
    } catch (error) {
      yield put(getShifScheduleFail(error));
    }
  }
  function* fetchTeamData({ payload: teamdata }) {
    try {
      const response = yield call(getTeamdataApi, teamdata);
      yield put(getShifTeamtSuccess(response));
    } catch (error) {
      yield put(getShiftTeamFail(error));
    }
  }
  function* fetchShiftData({ payload: shift }) {
    try {
      const response = yield call(getShiftApi, shift);
      yield put(getShiftSuccess(response));
    } catch (error) {
      yield put(getShiftFail(error));
    }
  }
 
  function* postShiftData({ payload: shiftdata }) {
    try {
      const response = yield call(postShiftApi, shiftdata);
      yield put(postShiftSuccess(response));
    } catch (error) {
      yield put(postShiftFail(error));
    }
  }

  function* putShiftData({ payload: putshift }) {
    try {
      const response = yield call(putShiftApi, putshift);
      yield put(putShiftSuccess(response));
    } catch (error) {
      yield put(putShiftFail(error));
    }
  }

  function* removeShiftData({ payload: removeshift }) {
    try {
      const response = yield call(deleteShiftApi, removeshift);
      
      yield put(deleteShiftSuccess(response));
    } catch (error) {
      yield put(deleteShiftFail(error));
    }
  }

  function* fetchUrenData({ payload: urendata }) {
    try {
      const response = yield call(getUrenApi, urendata);
      yield put(getUrenSuccess(response));
    } catch (error) {
      yield put(getUrenFail(error));
    }
  }

  function* postUrenData({ payload: posturen }) {
    try {
      const response = yield call(postUrenApi, posturen);
      yield put(postUrenSuccess(response));
    } catch (error) {
      yield put(postUrenFail(error));
    }
  }

  function* putUrenData({ payload: puturen }) {
    try {
      const response = yield call(putUrenApi, puturen);
      yield put(putUrenSuccess(response));
    } catch (error) {
      yield put(putUrenFail(error));
    }
  }

  function* deleteUrenData({ payload: removeuren }) {
    try {
      const response = yield call(removeUrenApi, removeuren);
      yield put(removeUrenSuccess(response));
    } catch (error) {
      yield put(removeUrenFail(error));
    }
  }

  function* fetchContractEmployerData({ payload: contractemployer }) {
    try {
      const response = yield call(getContractEmployerApi, contractemployer);
      yield put(getContractEmployerSuccess(response));
    } catch (error) {
      yield put(getContractEmployerFail(error));
    }
  }

  function* postNewTeamSaga({ payload: postnewteam }) {
    try {
      const response = yield call(postNewTeamApi, postnewteam);
      yield put(postNewTeamtSuccess(response));
    } catch (error) {
      yield put(postNewTeamTeamFail(error));
    }
  }


  function* ShiftSaga() {
    yield takeEvery(GET_SHIFT, fetchShiftData);
    yield takeEvery(POST_SHIFT, postShiftData);
    yield takeEvery(PUT_SHIFT, putShiftData);
    yield takeEvery(DELETE_SHIFT, removeShiftData);
    yield takeEvery(GET_UREN, fetchUrenData);
    yield takeEvery(POST_UREN, postUrenData);
    yield takeEvery(PUT_UREN, putUrenData);
    yield takeEvery(DELETE_UREN, deleteUrenData);
    yield takeEvery(GET_CONTRACT_EMPLOYEER, fetchContractEmployerData);
    yield takeEvery(GET_TEAM, fetchTeamData);
    yield takeEvery(POST_NEW_TEAM, postNewTeamSaga);
    yield takeEvery(GET_SCHEDULE, fetchScheduleSaga);
    yield takeEvery(POST_INSPECTION_EMPLOYEE, postInspectionTimeSheetSaga);
    yield takeEvery(GET_SURCHARGER, getSurchargertSaga);
    yield takeEvery(POST_SURCHARGER, postSurchargertSaga);
    yield takeEvery(PUT_SURCHARGER, putSurchargertSaga);
    yield takeEvery(DELETE_SURCHARGER, delSurchargertSaga);
  
  }
  
  export default ShiftSaga;
