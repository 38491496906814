import React, { useRef, useState, useEffect } from 'react';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import { map } from "lodash";
import {
  getSignatureImgByEmail,
  getSignatureImgByEmailSuccess,
  getSignatureImgByEmailFail,
  getDocument,
  getDocumentSuccess,
  getDocumentFail,
} from "store/actions";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const DraggableElementText = (props) => {
  const { t , onPageChange , selectedOption  ,permissions } = props;
  const dispatch = useDispatch();
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isNotProfessionalAllowed = plan_status === 'active' && plan_name !== 'professional';
  const [data, setData] = useState([]);
  const { uuid } = useParams();
  const [recipientNames, setRecipientNames] = useState([]);
  const [recipientEmails, setRecipientEmail] = useState([])
  const [recipientMsg, setRecipientMsg] = useState([])
  const [recipientroll, setRecipientRoll] = useState([]);
  const [recipienid, setRecipienid] = useState([]);
  const [Colors, setColor] = useState([]);
  const [status, setStatus] = useState([]);
  const [docIds, setDocIds] = useState([]);
  const [docNames, setDocNames] = useState([]);
  const [signaturepng, setSignPng] = useState([]);
  const [paraafimg, setParaafImg] = useState([]);
  const [stamppng, setStampPng] = useState([]);
  const [isOwner, setIsowner] = useState([]);
  const [recipientData, setRecipientData] = useState([]);
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const selectDocState = (state) => state.Prepare;

  const DocumentProperties = createSelector(
    selectDocState,
    (getSigantureImgData) => ({
      docname: getSigantureImgData.docname,
    })
  );

  const { docname } = useSelector(DocumentProperties);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (uuid) {
          await dispatch(getDocument(uuid));
        } else {
          console.error("User ID is not defined");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [dispatch, uuid]);
  const selectIMGState = (state) => state.Prepare;
  const ImgProperties = createSelector(
    selectIMGState,
     (getSigantureImgData) => ({
      signatureImgData: getSigantureImgData.signatureImgData,
     })
   );
 
   const { imgData } = useSelector(ImgProperties);
   useEffect(() => {
    const fetchImgData = async () => {
      try {
        if (userId) {
          await dispatch(getSignatureImgByEmail(userId));
        } else {
          console.error("User ID is not defined");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchImgData();
  }, [dispatch, userId]); 
  
 

  useEffect(() => {
    // Check if docname is an array
    if (Array.isArray(docname)) {
      // Assuming 'data' is the fetched data from your API
    
      // Use reduce to extract the required data from the 'data' array
      const extractedData = docname.reduce(
        (acc, item) => {
          const recipients = JSON.parse(item.recipient_data);
          
          recipients.forEach((recipient) => {
            acc.recipientNames.push(recipient.recipientName);
            acc.recipientEmail.push(recipient.recipientEmail);
            acc.recipientMsg.push(recipient.recipientMsg);
            acc.recipientroll.push(recipient.recipientroll);
            acc.Colors.push(recipient.color);
            acc.recipienid.push(recipient.recipienid);
            acc.signatureImge.push(recipient.signatureImge);
            acc.paraaf.push(recipient.paraaf);
            acc.stemp.push(recipient.stemp);
            acc.isOwner.push(recipient.isOwner);
          });
          
          return acc;
        },
        {
          recipientEmail: [],
          recipientMsg: [],
          recipientroll: [],
          Colors: [],
          recipientNames: [],
          recipienid: [],
          signatureImge: [],
          paraaf: [],
          stemp: [],
          isOwner: [],
        }
      );
  
      if (docname.length > 0) {
        setDocNames(docname[0].doc_name);
      }
      setRecipientNames(extractedData.recipientNames);
      setRecipientEmail(extractedData.recipientEmail);
      setRecipientMsg(extractedData.recipientMsg);
      setRecipientRoll(extractedData.recipientroll);
      setColor(extractedData.Colors);
      setRecipienid(extractedData.recipienid);
      setSignPng(extractedData.signatureImge);
      setParaafImg(extractedData.paraaf);
      setStampPng(extractedData.stemp);
      setIsowner(extractedData.isOwner);
    } else {
      console.error("docname is not an array");
    }
  }, [docname]);
  const obj = {
    recipientEmails:recipientEmails,
    recipientNames:recipientNames,
    recipientMsg:recipientMsg,
    recipientroll:recipientroll,
    Colors:Colors,
    status:status,
    docIds:docIds,
    docNames:docNames,
    signaturepng:signaturepng,
    paraafimg:paraafimg,
    stamppng:stamppng,
    recipientData:recipientData,
    recipienid:recipienid,
    isOwner:isOwner,
  }
  
 
  const LockedButton = () => (
    <button data-type="deleteType" data-qa="featureWall PaymentFixed" type="button" className="css-t01nit">
      <span className="css-zf56fy">
        <span aria-hidden="true" className="SVGInline">
          <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
            <path d="M13.5 6H13V5c0-2.8-2.2-5-5-5S3 2.2 3 5v1h-.5C1.7 6 1 6.7 1 7.5v7c0 .8.7 1.5 1.5 1.5h11c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5zM8 12.5c-.8 0-1.5-.7-1.5-1.5S7.2 9.5 8 9.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM11 6H5V5c0-1.7 1.3-3 3-3s3 1.3 3 3v1z"></path>
          </svg>
        </span>
      </span>
      <span className="css-1pdgtej">{t('More Info')}</span>
    </button>
  );
  return (
    <div>
   {recipientNames.map((item,index) => (
    <React.Fragment key={`${item}_${index}`}>

{(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
        <div 
        key={'qrcode'}
        id='qrcode'
        data-allow='allow'
        className="css-h0trhu dragmove_content" 
        draggable={isNotProfessionalAllowed ? "true" : "false"}
        data-type='qrcode'
        data-format='qrcode'
        data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
          >
         <button  data-type ="deleteType" data-qa="Signature" data-id="tab-palette-item" type="button" className="css-zuu3pb">
         <span className="css-xfo8x5"  style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}}>
         <span className="css-zf56fy">
         <span aria-hidden="true" className="SVGInline">

<svg fill="#000000"    className="SVGInline-svg"  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 64 64" style={{background:'#ffffff'}}>
<g>
	<rect x="38" y="23" width="2" height="5"/>
	<rect x="42" y="23" width="2" height="5"/>
	<rect x="38" y="30" width="2" height="2"/>
	<rect x="42" y="30" width="2" height="2"/>
	<rect x="31" y="34" width="2" height="2"/>
	<rect x="26" y="54" width="2" height="2"/>
	<rect x="42" y="34" width="2" height="2"/>
	<rect x="38" y="38" width="2" height="2"/>
	<rect x="42" y="38" width="2" height="2"/>
	<path d="M2,2h3V0H1C0.448,0,0,0.448,0,1v4h2V2z"/>
	<path d="M63,0h-4v2h3v3h2V1C64,0.448,63.552,0,63,0z"/>
	<path d="M2,59H0v4c0,0.552,0.448,1,1,1h4v-2H2V59z"/>
	<path d="M62,62h-3v2h4c0.552,0,1-0.448,1-1v-4h-2V62z"/>
	<path d="M4,5v12c0,0.552,0.448,1,1,1h12c0.552,0,1-0.448,1-1V5c0-0.552-0.448-1-1-1H5C4.448,4,4,4.448,4,5z M6,6h10v10H6V6z"/>
	<path d="M13,8H9C8.448,8,8,8.448,8,9v4c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1V9C14,8.448,13.552,8,13,8z M12,12h-2v-2h2V12z"
		/>
	<path d="M47,18h12c0.552,0,1-0.448,1-1V5c0-0.552-0.448-1-1-1H47c-0.552,0-1,0.448-1,1v12C46,17.552,46.448,18,47,18z M48,6h10v10
		H48V6z"/>
	<path d="M55,8h-4c-0.552,0-1,0.448-1,1v4c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1V9C56,8.448,55.552,8,55,8z M54,12h-2v-2h2V12z
		"/>
	<path d="M17,46H5c-0.552,0-1,0.448-1,1v12c0,0.552,0.448,1,1,1h12c0.552,0,1-0.448,1-1V47C18,46.448,17.552,46,17,46z M16,58H6V48
		h10V58z"/>
	<path d="M9,56h4c0.552,0,1-0.448,1-1v-4c0-0.552-0.448-1-1-1H9c-0.552,0-1,0.448-1,1v4C8,55.552,8.448,56,9,56z M10,52h2v2h-2V52z"
		/>
	<path d="M44,19h-7V7h-2v13c0,0.552,0.448,1,1,1h8V19z"/>
	<rect x="22" y="12" width="7" height="2"/>
	<rect x="26" y="8" width="7" height="2"/>
	<rect x="4" y="19" width="2" height="9"/>
	<path d="M18,25H8v2h9v4h2v-5C19,25.448,18.552,25,18,25z"/>
	<rect x="9" y="20" width="2" height="2"/>
	<rect x="21" y="18" width="2" height="8"/>
	<rect x="21" y="28" width="9" height="2"/>
	<path d="M61,29h-2v10h-3v2h4c0.552,0,1-0.448,1-1V29z"/>
	<rect x="26" y="18" width="2" height="2"/>
	<rect x="4" y="33" width="2" height="8"/>
	<rect x="4" y="42" width="9" height="2"/>
	<path d="M50,28h2v-4c0-0.552-0.448-1-1-1h-5v2h4V28z"/>
	<rect x="9" y="33" width="2" height="2"/>
	<rect x="20" y="32" width="2" height="8"/>
	<rect x="20" y="42" width="9" height="2"/>
	<rect x="8" y="38" width="9" height="2"/>
	<rect x="21" y="52" width="2" height="4"/>
	<rect x="20" y="48" width="9" height="2"/>
	<path d="M36,44v-5c0-0.552-0.448-1-1-1H25v2h9v4H36z"/>
	<rect x="26" y="32" width="2" height="2"/>
	<rect x="15" y="34" width="2" height="2"/>
	<rect x="59" y="53" width="2" height="7"/>
	<rect x="54" y="58" width="3" height="2"/>
	<rect x="54" y="52" width="2" height="4"/>
	<path d="M61,44c0-0.552-0.448-1-1-1H49v2h10v6h2V44z"/>
	<path d="M48,56v-5c0-0.552-0.448-1-1-1H36v2h10v4H48z"/>
	<rect x="50" y="47" width="7" height="2"/>
	<rect x="20" y="58" width="7" height="2"/>
	<rect x="30" y="51" width="2" height="9"/>
	<rect x="32" y="46" width="4" height="2"/>
	<path d="M47,36h5v-2h-4v-6h-2v7C46,35.552,46.448,36,47,36z"/>
	<rect x="50" y="30" width="5" height="2"/>
	<rect x="54" y="20" width="3" height="2"/>
	<rect x="59" y="20" width="2" height="5"/>
	<rect x="54" y="25" width="2" height="2"/>
	<path d="M39,17h4c0.552,0,1-0.448,1-1V3h-2v12h-3V17z"/>
	<path d="M24,5h15V3H23c-0.552,0-1,0.448-1,1v5h2V5z"/>
	<path d="M25,24h7c0.552,0,1-0.448,1-1v-8h-2v7h-6V24z"/>
	<rect x="34" y="23" width="2" height="4"/>
	<rect x="36" y="54" width="7" height="2"/>
	<rect x="36" y="58" width="9" height="2"/>
	<path d="M50,58h-2v2h3c0.552,0,1-0.448,1-1v-8h-2V58z"/>
	<rect x="16" y="42" width="2" height="2"/>
	<rect x="39" y="42" width="4" height="2"/>
	<rect x="46" y="38" width="2" height="3"/>
	<rect x="45" y="43" width="2" height="2"/>
	<rect x="39" y="46" width="3" height="2"/>
	<rect x="50" y="38" width="2" height="2"/>
	<rect x="54" y="34" width="3" height="2"/>
	<rect x="35" y="34" width="5" height="2"/>
	<rect x="34" y="29" width="2" height="3"/>
</g>
</svg>

         </span>
         </span>
         </span>
         <span className="css-1ny2g5x">
         <span className="css-195jadi">{t('QR-Codes')}</span>
         </span>
         </button>
         {!isNotProfessionalAllowed  && (
              LockedButton()
              )}
         </div>
         )}
         {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
         <div 
         key={'number'}
         id='number'
          className="css-h0trhu dragmove_content" 
          draggable={isNotProfessionalAllowed ? "true" : "false"}
           data-type='number'
           data-format='number'
           data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
           >
           <button  data-type ="deleteType" data-qa="Signature"
            data-id="tab-palette-item" 
            type="button"
             className="css-zuu3pb"
             >
           <span className="css-xfo8x5" style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}} >
           <span className="css-zf56fy">
           <span aria-hidden="true" className="SVGInline">
           <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
           <path d="m15.531 3-.312 2h-2.5l-.938 6h2.5l-.312 2h-2.5L11 16H9l.469-3h-4.5L4.5 16h-2l.469-3h-2.5l.312-2h2.5l.938-6h-2.5l.312-2h2.5L5 0h2l-.469 3h4.5l.469-3h2l-.469 3h2.5zm-10.25 8h4.5l.938-6h-4.5l-.938 6z"></path>
            </svg>
           </span>
           </span>
           </span>
           <span className="css-1ny2g5x">
           <span className="css-195jadi">{t('Number')}</span>
           </span>
           </button>
           {!isNotProfessionalAllowed  && (
              LockedButton()
              )}
           </div>
           )}
           {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
           <div
           id='checkbox'
           key={'checkbox'}
           draggable={isNotProfessionalAllowed ? "true" : "false"}
              data-type='checkbox'
              data-format='checkbox'
              data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
              >
             <button data-type ="deleteType" data-qa="Signature" data-id="tab-palette-item" type="button" className="css-zuu3pb">
             <span className="css-xfo8x5" style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}}>
             <span className="css-zf56fy">
             <span aria-hidden="true" className="SVGInline">
             <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
             <path d="M13.7 2c.2 0 .3.1.3.3v11.4c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3h11.4m0-2H2.3C1 0 0 1 0 2.3v11.4C0 15 1 16 2.3 16h11.4c1.3 0 2.3-1 2.3-2.3V2.3C16 1 15 0 13.7 0z"></path>
             <path d="m13 5.5-1.4-1.4L6.7 9 4.4 6.8 3 8.2l3.7 3.7z"></path>
             </svg>
             </span>
             </span>
             </span>
             <span className="css-1ny2g5x">
             <span className="css-195jadi">{t('Checkox')}</span>
             </span>
             </button>
             {!isNotProfessionalAllowed  && (
              LockedButton()
              )}
             </div>
             )}

               {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
             <div 
             key={'radio'}
             id='radio' 
              className="css-h0trhu dragmove_content"
              draggable={isNotProfessionalAllowed ? "true" : "false"}
               data-type='radio'
               data-format='radio'
               data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
               >
               <button data-type ="deleteType" data-qa="Signature" data-id="tab-palette-item" type="button" className="css-zuu3pb">
               <span className="css-xfo8x5" style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}}>
               <span className="css-zf56fy">
               <span aria-hidden="true" className="SVGInline">
               <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
               <path d="M8 2c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6m0-2C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"></path>
               <circle cx="8" cy="8" r="3.5"></circle>
               </svg>
               </span>
               </span>
               </span>
               <span className="css-1ny2g5x">
               <span className="css-195jadi">{t('Radio')}</span>
               </span>
               </button>
               {!isNotProfessionalAllowed  && (
              LockedButton()
              )}
               </div>
              
               )}
        </React.Fragment>
      ))}
</div> 
  );
};


export default withTranslation()(withRouter(DraggableElementText));