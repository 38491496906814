import React, { useRef, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DraggableList from './DraggableList';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    InputGroup,
    UncontrolledCollapse
  } from "reactstrap";
  import classnames from "classnames";
import { func } from 'prop-types';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
 function CheckBoxGroup({
t,targetid,datatype,onDateSelected
  }) {
    useEffect(() => {
      toastr.options = {
        positionClass: 'toast-top-center',
        closeButton: true,
        debug: true,
        progressBar:true,
        timeOut: 30000, // Duration in milliseconds
      };
    }, []);
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col9, setcol9] = useState(true);
    const [requreField, setRequre] = useState(true);
    const [validations, setValid] = useState(false);
    const [isCheckedInput, setIsCheckedInput] = useState(null);
    const [checkboxValue, setCheckboxValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedValidation, setselectedValidation] = useState('least'); // Initial value, you can set it to the default value if needed
    const [options, setOptions] = useState([]);
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
 
    const t_Text = () => {
      setRequre(!requreField);
      setValid(false);
    };
    const t_Text1 = () => {
      setValid(!validations);
      setRequre(false);
    };
    const t_col2 = () => {
      setcol2(!col2);
      setcol1(false);
    };
    const t_col9 = () => {
      setcol9(!col9);
    };
    const Rectangle = document.getElementById(targetid);
    const Group = Rectangle.parentElement;
    const GroupContainer = Group.parentElement;
    const gElement = GroupContainer.querySelectorAll('g');
    const validationContainer = GroupContainer.getAttribute('data-rule');
    const conditionContainer = GroupContainer.getAttribute('data-condition')
    const ContainerValidation = GroupContainer.getAttribute('data-validation')
    const ContainerMax = GroupContainer.getAttribute('data-max')
    const ContainerMin = GroupContainer.getAttribute('data-min');
    const newRoll = Rectangle.getAttribute('data-roll');
    const [formData, setFormData] = useState(Array.from(gElement).map(() => ({ isChecked: false, inputValue: '' })));

    const handleRadioChange = (value) => {
      setSelectedValue(value);
      Rectangle.setAttribute('data-roll', value);
    };
    const handleSelectChange = (event) => {
      setSelectedValue(event.target.value);
      GroupContainer.setAttribute('data-rule', event.target.value);
    };
    const handleValueChecked = (index, value,target) => {
      const newFormData = [...formData];
      newFormData[index] = {
        ...newFormData[index],
        isChecked: value,
      };
      setFormData(newFormData);
      const group = document.getElementById(target)
      const targetRect = group.querySelector('rect');
      targetRect.setAttribute('data-checked', value);
      group.setAttribute('data-checked', value);
    };
    const handleValueOfInputText = (index, inputValue,target) => {
      const newFormData = [...formData];
      newFormData[index] = {
        ...newFormData[index],
        inputValue,
      };
      setFormData(newFormData);
      const group = document.getElementById(target)
      const targetRect = group.querySelector('rect');
      targetRect.setAttribute('data-content', inputValue);
      group.setAttribute('data-content', inputValue);
    };
    
    const handleSelectOptionChange = (e) => {
      setSelectedOption(e.target.value);
      GroupContainer.setAttribute('data-validation', e.target.value);
    };
    const handleMinSelectChange = (e) => {
      setMinValue(e.target.value);
      GroupContainer.setAttribute('data-min', e.target.value)
    };
  
    const handleMaxSelectChange = (e) => {
      setMaxValue(e.target.value);
      GroupContainer.setAttribute('data-max', e.target.value)
    };
    const itemStyle = {
        display: 'flex',
      };

      useEffect(() => {
        const gElements = GroupContainer.querySelectorAll('g');
    // Set options based on the number of g elements
    setOptions(Array.from({ length: gElements.length + 1}, (_, index) => index));
        // Replace 'your-node-server-url' with the URL of your Node.js server
        onDateSelected( 
        targetid,datatype
          )
      }, [targetid,datatype,GroupContainer
      ]);
     
      return (
        <React.Fragment>
             <Row>
                        <Col lg={12}>
                        <div className="css-vlt30q">
          <div style={itemStyle}>
              <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
             
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" fill="gray8" focusable="false" data-qa="icon-element-checkbox"><path d="m17.49 8-5.75 8.6a.88.88 0 0 1-1.35.13L7 13.34 8.34 12l2.51 2.51L15.21 8zm1.61-3.1H4.9v14.2h14.2V4.9M20 3a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"></path></svg>
      </span>
      </span>
      <span data-qa="pannel-header" style={{paddingLeft:'0.625em'}}>{t('Checkbox Group')}</span>
      </div>
      </div>
      <div className="accordion " id="accordion">
      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo2">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !requreField }
                            )}
                            type="button"
                            onClick={t_Text}
                            style={{ cursor: "pointer" }}
                          >
                           {t('Rule')} 
                          </button>
                        </h2>
    <Collapse isOpen={requreField} className="accordion-collapse">
    <div className="accordion-body">
    <div className="text-muted">
    <Label>{t('Set Rule')} </Label>

    <div className="css-q10huv">
    <div className="css-hboir5" style={{display:'block'}}>
    <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
    <input
        type="radio"
        className="form-check-input"
        id="verplicht"
        name='checkbox'
        checked={newRoll === 'true'}
        onClick={() => handleRadioChange('true')}              
        />
        <label
        className="form-check-label"
        htmlFor="true"
        onClick={() => handleRadioChange('true')}
         >
        {t('Required field')}
        </label>
     </div>
     <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
     <input
        type="radio"
        className="form-check-input"
        id="lezen"
        name='checkbox' 
        checked={newRoll === 'false'}
        onClick={() => handleRadioChange('false')}                 
        />
        <label
        className="form-check-label"
        htmlFor="alleenLezen"
        onClick={() => handleRadioChange('false')}
         >
       {t('Read-only')}
        </label>
     </div>                       
    </div>
    </div>
      </div>
      </div>
    </Collapse>
    </div>
    </div>
    <div className="accordion " id="accordion">
      <div className="accordion-item" >
                        <h2 className="accordion-header" id="headingTwo2">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !requreField }
                            )}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}
                          >
                           {t('CheckBox Value')}
                          </button>
                        </h2>
    <Collapse isOpen={col2} className="accordion-collapse">
    <div className="accordion-body">
    <div className="text-muted">
    {Array.from(gElement).map((gElement, index) => (
            <div key={index} className="css-q10huv">
              <div className="css-hboir5" style={{ display: 'block' }}>
                <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{ margin: '10px' }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`checkbox${index + 1}`}
                    checked={ document.getElementById(gElement.id).getAttribute('data-checked') === 'true'}
                    onClick={() => handleValueChecked(index, !formData[index]?.isChecked,gElement.id)}
                  />
                <Input
                className="css-12ihcxq"
                value={ document.getElementById(gElement.id).getAttribute('data-content')}
                onChange={(e) => handleValueOfInputText(index, e.target.value,gElement.id)}
                type="text"
                />
                </div>
              </div>
            </div>
          ))}
      </div>
      </div>
    </Collapse>
    </div>
    </div>
    <div className="accordion " id="accordion">

                 <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                            <button
                        className={classnames("accordion-button", "fw-medium", { collapsed: !validations })}
                        type="button"
                        onClick={t_Text1}
                        style={{ cursor: "pointer" }}
                         >
                         {t('Validation')} 
                          </button>
                          </h2>
                  <Collapse
                    isOpen={validations}
                    className="accordion-collapse"
                  >
                  <div className="accordion-body">
                  <div className="text-muted">
                  <div className="css-q10huv">
                  <div className="css-hboir5">
                      <select
                       className="css-12ihcxq"
                       value={validationContainer}
                       onChange={handleSelectChange}
                       >
                        {/* user must choose a minimum number of checkboxes */}
                        <option value="least"> {t('Select at least')} </option> 
                          {/* user is required to choose an exact number of checkboxes */}
                          <option  value="most">{t('Select at most')}</option>
                           {/* ser is required to choose an exact number of checkboxes */}
                          <option  value="exactly">{t('Select exactly')}</option>
                           {/* user is required to choose a number of checkboxes within a specified range. For example, "Select 2 to 4 options" would mean the user must choose at least 2 checkboxes and at most 4 checkboxes from the provided options. */}
                          <option  value="range">{t('Select a range')}</option>
                      </select>
                      <span className="css-1baizyq">
                          <span className="css-lzaifp">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                                  <path d="M4 5h8l-4 6z"></path>
                              </svg>
                          </span>
                      </span>
                  </div>
              </div>
                  <div className="css-q10huv" style={{ background: validationContainer === 'range' ? 'none' : '' }}>
                  <div className="css-hboir5">
                  {validationContainer === 'range' ? null : (
                  <select 
                  className="css-12ihcxq" 
                   value={ContainerValidation}
                   onChange={handleSelectOptionChange}
                      >
                    {options.map((option) => (
                <option key={option} value={option}>
                   {option}
                </option>
              ))}
                      </select>
                      )}
                      {validationContainer === 'range' ? null : (
                        
      <span className="css-1baizyq">
        <span className="css-lzaifp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="16"
            height="16"
            focusable="false"
            aria-hidden="true"
            fill="currentColor"
          >
            <path d="M4 5h8l-4 6z"></path>
          </svg>
        </span>
      </span>
    )}
                      {validationContainer === 'range' && (
              <>
           <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
           <select
          id="minSelect"
          value={ContainerMin}
          onChange={handleMinSelectChange}
          style={{ width: '60px', marginRight: '10px' }}
        >
          {/* Add options for Min select */}
          {options.map((option) => (
                <option key={option} value={option}>
                   {option}
                </option>
              ))}
          {/* Add more options as needed */}
        </select>
        <select
          id="maxSelect"
          value={ContainerMax}
          onChange={handleMaxSelectChange}
          style={{ width: '60px' }}
        >
          {/* Add options for Max select */}
          {options.map((option) => (
                <option key={option} value={option}>
                   {option}
                </option>
              ))}
          {/* Add more options as needed */}
        </select>
        </div> 
              </>
             
            )}
          
                  </div>
              </div>
                  <div className="css-164r41r">
        
                  </div>
                  </div>
                  </div>
                </Collapse>
                 </div>
         
                 
                          </div>
                        </Col>
                      
                      </Row>
        </React.Fragment>
        
        );
      }
      
      export default withTranslation()(CheckBoxGroup);
