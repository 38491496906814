import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAIL,
  PUT_ACCOUNT_DATA,
  PUT_ACCOUNT_DATA_SUCCESS,
  PUT_ACCOUNT_DATA_FAIL,
  GET_ABSENCE_POLICY,
  GET_ABSENCE_POLICY_SUCCESS,
  GET_ABSENCE_POLICY_FAIL,
  POST_ABSENCE_POLICY,
  POST_ABSENCE_POLICY_SUCCESS,
  POST_ABSENCE_POLICY_FAIL,
  PUT_ABSENCE_POLICY,
  PUT_ABSENCE_POLICY_SUCCESS,
  PUT_ABSENCE_POLICY_FAIL,
  DELETE_ABSENCE_POLICY,
  DELETE_ABSENCE_POLICY_SUCCESS,
  DELETE_ABSENCE_POLICY_FAIL,
  GET_ABSENCE_TYPE,
  GET_ABSENCE_TYPE_SUCCESS,
  GET_ABSENCE_TYPE_FAIL,
  POST_ABSENCE_TYPE,
  POST_ABSENCE_TYPE_SUCCESS,
  POST_ABSENCE_TYPE_FAIL,
  PUT_ABSENCE_TYPE,
  PUT_ABSENCE_TYPE_SUCCESS,
  PUT_ABSENCE_TYPE_FAIL,
  DELETE_ABSENCE_TYPE,
  DELETE_ABSENCE_TYPE_SUCCESS,
  DELETE_ABSENCE_TYPE_FAIL,
  GET_ABSENCE,
  GET_ABSENCE_SUCCESS,
  GET_ABSENCE_FAIL,
  PUT_ABSENCE,
  PUT_ABSENCE_SUCCESS,
  PUT_ABSENCE_FAIL,
  POST_ABSENCE,
  POST_ABSENCE_SUCCESS,
  POST_ABSENCE_FAIL,
  DELETE_ABSENCE,
  DELETE_ABSENCE_SUCCESS,
  DELETE_ABSENCE_FAIL,
  GET_CORRECTION,
  GET_CORRECTION_SUCCESS,
  GET_CORRECTION_FAIL,
  POST_CORRECTION,
  POST_CORRECTION_SUCCESS,
  POST_CORRECTION_FAIL,
  PUT_CORRECTION,
  PUT_CORRECTION_SUCCESS,
  PUT_CORRECTION_FAIL,
  GET_FULL_ABSENCE_ACCESS,
  GET_FULL_ABSENCE_ACCESS_SUCCESS,
  GET_FULL_ABSENCE_ACCESS_FAIL,
  GET_ABSENCE_TOTAL,
  GET_ABSENCE_TOTAL_SUCCESS,
  GET_ABSENCE_TOTAL_FAIL,
  GET_HOURS_TOTAL,
  GET_HOURS_TOTAL_SUCCESS,
  GET_HOURS_TOTAL_FAIL,
  GET_ALL_ABSENCE,
  GET_ALL_ABSENCE_SUCCESS,
  GET_ALL_ABSENCE_FAIL,
  } from "./actionTypes";
  const INIT_STATE = {
    getaccountdata: [],
    putaccountdata: null,
    getabsencepolicy: [],
    putabsencepolicy: null,
    postabsencepolicy: null,
    deleteabsencepolicy: null,
    getabsencetype: [],
    postabsencetype: null,
    putabsencetype: null,
    deleteabsencetype: null,
    getabsence: [],
    getalltabsence: [],
    postabsence: null,
    putabsence: null,
    deleteabsence: null,
    getcorrection: null,
    postcorrection: null,
    putcorrection: null,
    useraccess: null,
    getTotalAbsence:null,
    ContractAndWorkHours:null,
    loading: false,
    error: {},
  };
  const getAbsenceData = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_HOURS_TOTAL:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_HOURS_TOTAL_SUCCESS:
      return {
      ...state,
      loading: false,
      ContractAndWorkHours: action.payload,
      error: null,
      
      };
      
      case GET_HOURS_TOTAL_FAIL:
      return {
        ...state,
        loading: false,
        ContractAndWorkHours: null,
        error: action.payload,
      };
      case GET_ABSENCE_TOTAL:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_ABSENCE_TOTAL_SUCCESS:
      return {
      ...state,
      loading: false,
      getTotalAbsence: action.payload,
      error: null,
      
      };
      
      case GET_ABSENCE_TOTAL_FAIL:
      return {
        ...state,
        loading: false,
        getTotalAbsence: null,
        error: action.payload,
      };
      case GET_FULL_ABSENCE_ACCESS:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_FULL_ABSENCE_ACCESS_SUCCESS:
      return {
      ...state,
      loading: false,
      useraccess: action.payload,
      error: null,
      
      };
      
      case GET_FULL_ABSENCE_ACCESS_FAIL:
      return {
        ...state,
        loading: false,
        useraccess: null,
        error: action.payload,
      };
      case GET_ACCOUNT_DATA:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_ACCOUNT_DATA_SUCCESS:
      return {
      ...state,
      loading: false,
      getaccountdata: action.payload,
      error: null,
      
      };
      
      case GET_ACCOUNT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        getaccountdata: null,
        error: action.payload,
      };

      case PUT_ACCOUNT_DATA:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case PUT_ACCOUNT_DATA_SUCCESS:
      return {
      ...state,
      loading: false,
      putaccountdata: action.payload,
      error: null,
      
      };
      
      case PUT_ACCOUNT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        putaccountdata: null,
        error: action.payload,
      };

      case GET_ABSENCE_POLICY:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };


case GET_ABSENCE_POLICY_SUCCESS:
return {
...state,
loading: false,
getabsencepolicy: action.payload,
error: null,

};

case GET_ABSENCE_POLICY_FAIL:
return {
  ...state,
  loading: false,
  getabsencepolicy: null,
  error: action.payload,
};

case POST_ABSENCE_POLICY:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case POST_ABSENCE_POLICY_SUCCESS:
return {
...state,
loading: false,
postabsencepolicy: action.payload,
error: null,

};

case POST_ABSENCE_POLICY_FAIL:
return {
  ...state,
  loading: false,
  postabsencepolicy: null,
  error: action.payload,
};

case PUT_ABSENCE_POLICY:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case PUT_ABSENCE_POLICY_SUCCESS:
return {
...state,
loading: false,
putabsencepolicy: action.payload,
error: null,

};

case PUT_ABSENCE_POLICY_FAIL:
return {
  ...state,
  loading: false,
  putabsencepolicy: null,
  error: action.payload,
};


case DELETE_ABSENCE_POLICY:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case DELETE_ABSENCE_POLICY_SUCCESS:
return {
...state,
loading: false,
deleteabsencepolicy: action.payload,
error: null,

};

case DELETE_ABSENCE_POLICY_FAIL:
return {
  ...state,
  loading: false,
  deleteabsencepolicy: null,
  error: action.payload,
};


case GET_ABSENCE_TYPE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case GET_ABSENCE_TYPE_SUCCESS:
return {
...state,
loading: false,
getabsencetype: action.payload,
error: null,

};

case GET_ABSENCE_TYPE_FAIL:
return {
  ...state,
  loading: false,
  getabsencetype: null,
  error: action.payload,
};


case POST_ABSENCE_TYPE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case POST_ABSENCE_TYPE_SUCCESS:
return {
...state,
loading: false,
postabsencetype: action.payload,
error: null,

};

case POST_ABSENCE_TYPE_FAIL:
return {
  ...state,
  loading: false,
  postabsencetype: null,
  error: action.payload,
};


case PUT_ABSENCE_TYPE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case PUT_ABSENCE_TYPE_SUCCESS:
return {
...state,
loading: false,
putabsencetype: action.payload,
error: null,

};

case PUT_ABSENCE_TYPE_FAIL:
return {
  ...state,
  loading: false,
  putabsencetype: null,
  error: action.payload,
};


case DELETE_ABSENCE_TYPE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case DELETE_ABSENCE_TYPE_SUCCESS:
return {
...state,
loading: false,
deleteabsencetype: action.payload,
error: null,

};

case DELETE_ABSENCE_TYPE_FAIL:
return {
  ...state,
  loading: false,
  deleteabsencetype: null,
  error: action.payload,
};

case GET_ABSENCE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case GET_ABSENCE_SUCCESS:
return {
...state,
loading: false,
getabsence: action.payload,
error: null,

};

case GET_ABSENCE_FAIL:
return {
  ...state,
  loading: false,
  getabsence: null,
  error: action.payload,
};

case GET_ALL_ABSENCE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case GET_ALL_ABSENCE_SUCCESS:
return {
...state,
loading: false,
getalltabsence: action.payload,
error: null,

};

case GET_ALL_ABSENCE_FAIL:
return {
  ...state,
  loading: false,
  getalltabsence: null,
  error: action.payload,
};
case PUT_ABSENCE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case PUT_ABSENCE_SUCCESS:
return {
...state,
loading: false,
putabsence: action.payload,
error: null,

};

case PUT_ABSENCE_FAIL:
return {
  ...state,
  loading: false,
  putabsence: null,
  error: action.payload,
};

case POST_ABSENCE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case POST_ABSENCE_SUCCESS:
return {
...state,
loading: false,
postabsence: action.payload,
error: null,

};

case POST_ABSENCE_FAIL:
return {
  ...state,
  loading: false,
  postabsence: null,
  error: action.payload,
};

case DELETE_ABSENCE:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case DELETE_ABSENCE_SUCCESS:
return {
...state,
loading: false,
deleteabsence: action.payload,
error: null,

};

case DELETE_ABSENCE_FAIL:
return {
  ...state,
  loading: false,
  deleteabsence: null,
  error: action.payload,
};

case GET_CORRECTION:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case GET_CORRECTION_SUCCESS:
return {
...state,
loading: false,
getcorrection: action.payload,
error: null,

};

case GET_CORRECTION_FAIL:
return {
  ...state,
  loading: false,
  getcorrection: null,
  error: action.payload,
};

case POST_CORRECTION:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case POST_CORRECTION_SUCCESS:
return {
...state,
loading: false,
postcorrection: action.payload,
error: null,

};

case POST_CORRECTION_FAIL:
return {
  ...state,
  loading: false,
  postcorrection: null,
  error: action.payload,
};


case PUT_CORRECTION:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case PUT_CORRECTION_SUCCESS:
return {
...state,
loading: false,
putcorrection: action.payload,
error: null,

};

case PUT_CORRECTION_FAIL:
return {
  ...state,
  loading: false,
  putcorrection: null,
  error: action.payload,
};


      default:
        return state;
    }
  };
  
  export default getAbsenceData;