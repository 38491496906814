import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';

const RouteGuard = ({ component: Component, content, ...rest }) => {
  const authUser = localStorage.getItem('authUser');
  const user = authUser ? JSON.parse(authUser) : null;
  
  const { permissions, loading } = useUserPermissions();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!permissions) {
    return <Navigate to="/access-denied" />;
  }

  const isAllowed = () => {
    if (!permissions) return false;

    const { rule, template_content, editor } = permissions;

    if (rule === 'admin') return template_content.includes(content); // Admin has access to all routes
    if (rule === 'user') return template_content.includes(content);

    return false;
  };

  return isAllowed() ? (
    <Component {...rest} permissions={permissions} />
  ) : (
    <Navigate to="/access-denied" />
  );
};

export default RouteGuard;
