import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_REPORT_DATA,
  POST_REPORT_DATA,
  DELETE_REPORT_DATA,
  POST_REPORT_DATA_ABSENCE,
  GET_WEB_SETTING,
  } from "./actionTypes";
 import {
  getReportDataSuccess,
  getReportDataFail,
  postReportDataSuccess,
  postReportDataFail,
  removeReportDataSuccess,
  removeReportDataFail,
  postReportDataAbsenceSuccess,
  postReportDataAbsenceFail,
  getWebSttingSuccess,
  getWebSttingFail,
  } from "./actions";

  //Include Both Helper File with needed methods
import {
  postReportApi,
  removeReportApi,
  getReportApi,
  postReportAbsenceApi,
  getWebInfo,
  } from "helpers/fakebackend_helper";
  function* getReportDataSaga({ payload: getreportdata }) {
    try {
      const response = yield call(getReportApi, getreportdata);
      yield put(getReportDataSuccess(response));
    } catch (error) {
      yield put(getReportDataFail(error));
    }
  }
  function* portReportDataSaga({ payload: postreportdata }) {
    try {
      const response = yield call(postReportApi, postreportdata);
      yield put(postReportDataSuccess(response));
    } catch (error) {
      yield put(postReportDataFail(error));
    }
  }

  function* portReportDataAbsenceSaga({ payload: postreportdataabsence }) {
    try {
      const response = yield call(postReportAbsenceApi, postreportdataabsence);
      yield put(postReportDataAbsenceSuccess(response));
    } catch (error) {
      yield put(postReportDataAbsenceFail(error));
    }
  }
  
  function* deletReporSaga({ payload: removereportdata }) {
    try {
      const response = yield call(removeReportApi, removereportdata);
      yield put(removeReportDataSuccess(response));
    } catch (error) {
      yield put(removeReportDataFail(error));
    }
  }

  function* getWebSettingSaga({ payload: websetting }) {
    try {
      const response = yield call(getWebInfo, websetting);
      yield put(getWebSttingSuccess(response));
    } catch (error) {
      yield put(getWebSttingFail(error));
    }
  }

  function* ReportSaga() {
    yield takeEvery(GET_REPORT_DATA, getReportDataSaga);
    yield takeEvery(POST_REPORT_DATA, portReportDataSaga)
    yield takeEvery(DELETE_REPORT_DATA, deletReporSaga)
    yield takeEvery(POST_REPORT_DATA_ABSENCE, portReportDataAbsenceSaga)
    yield takeEvery(GET_WEB_SETTING, getWebSettingSaga)
  }
  
  export default ReportSaga;
