import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_VEHICLES,
  POST_VEHICLES,
  PUT_VEHICLES,
  DELETE_VEHICLES,
  GET_VEHICLES_DOC,
  POST_VEHICLES_DOC,
  PUT_VEHICLES_DOC,
  DELETE_VEHICLES_DOC,
} from "./actionTypes";

import {
  getVhiclesSuccess,
  getVhiclesFail,
  postVhiclesSuccess,
  postVhiclesFail,
  putVhiclesSuccess,
  putVhiclesFail,
  delVhiclesSuccess,
  delVhiclesFail,
  getVhiclesDocSuccess,
  getVhiclesDocFail,
  postVhiclesDocSuccess,
  postVhiclesDocFail,
  putVhiclesDocSuccess,
  putVhiclesDocFail,
  delVhiclesDocSuccess,
  delVhiclesDocFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getVhiclesApi,
  postVhiclesApi,
  putVhiclesApi,
  delVhiclesApi,
  getVhiclesDocApi,
  postVhiclesDocApi,
  putVhiclesDocApi,
  delVhiclesDocApi,
} from "helpers/fakebackend_helper";
function* fetchVhivles({getVhiclesdata}) {
  try {
    const response = yield call(getVhiclesApi, getVhiclesdata);
    yield put(getVhiclesSuccess(response));
  } catch (error) {
    yield put(getVhiclesFail(error));
  }
}
function* postVhiclesSaga({ payload: postVhiclesdata}) {
  try {
    const response = yield call(postVhiclesApi, postVhiclesdata);
    yield put(postVhiclesSuccess(response));
  } catch (error) {
    yield put(postVhiclesFail(error));
  }
}

function* putVhiclesSaga({payload: putVhiclesdata}) {
  try {
    const response = yield call(putVhiclesApi,putVhiclesdata);
    yield put(putVhiclesSuccess(response));
  } catch (error) {
    yield put(putVhiclesFail(error));
  }
}

function* delVhiclesSaga({ payload: delVhiclesdata}) {
  try {
    const response = yield call(delVhiclesApi, delVhiclesdata);
    yield put(delVhiclesSuccess(response));
  } catch (error) {
    yield put(delVhiclesFail(error));
  }
}

//sd
function* fetchVhivlesDoc({getVhiclesDocdata}) {
  try {
    const response = yield call(getVhiclesDocApi, getVhiclesDocdata);
    yield put(getVhiclesDocSuccess(response));
  } catch (error) {
    yield put(getVhiclesDocFail(error));
  }
}
function* postVhiclesDocSaga({ payload: postVhiclesDocdata}) {
  try {
    const response = yield call(postVhiclesDocApi, postVhiclesDocdata);
    yield put(postVhiclesDocSuccess(response));
  } catch (error) {
    yield put(postVhiclesDocFail(error));
  }
}

function* putVhiclesDocSaga({payload: putVhiclesDocdata}) {
  try {
    const response = yield call(putVhiclesDocApi,putVhiclesDocdata);
    yield put(putVhiclesDocSuccess(response));
  } catch (error) {
    yield put(putVhiclesDocFail(error));
  }
}

function* delVhiclesDocSaga({ payload: delVhiclesDocdata}) {
  try {
    const response = yield call(delVhiclesDocApi, delVhiclesDocdata);
    yield put(delVhiclesDocSuccess(response));
  } catch (error) {
    yield put(delVhiclesDocFail(error));
  }
}

function* VhiclesSaga() {
    yield takeEvery(GET_VEHICLES, fetchVhivles),
    yield takeEvery(POST_VEHICLES, postVhiclesSaga),
    yield takeEvery(PUT_VEHICLES, putVhiclesSaga),
    yield takeEvery(DELETE_VEHICLES, delVhiclesSaga),
    yield takeEvery(GET_VEHICLES_DOC, fetchVhivlesDoc),
    yield takeEvery(POST_VEHICLES_DOC, postVhiclesDocSaga),
    yield takeEvery(PUT_VEHICLES_DOC, putVhiclesDocSaga),
    yield takeEvery(DELETE_VEHICLES_DOC, delVhiclesDocSaga);
   
}

export default VhiclesSaga;