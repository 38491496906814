import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import axios from "axios"; // Add axios for API calls
import { format, parseISO, isBefore, isEqual } from 'date-fns';

//i18n
import { withTranslation } from "react-i18next";
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};
const NotificationDropdown = (props) => {
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [account_id] = useState(user.account_id);
  const [language, setLanguage] = useState(user.language);
  const [timezone, setTimezone] = useState(user.timezone);
  const [valuta, setValuta] = useState(user.valuta);
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const [notifications, setNotifications] = useState([]);
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch notifications by account_id
  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://dspmetrics.com/get/notification/${account_id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        const unreadNotifications = result.filter(notification => notification.note_status === "unread");
        setNotifications(unreadNotifications);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching notifications:", err);
        setError("Failed to load notifications.");
        setLoading(false);
      }
    };

    fetchNotifications();
    const intervalId = setInterval(() => {
      fetchNotifications();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [account_id]);
  const markAsRead = async (notificationIds) => {
    try {
      await axios.post(
        `https://dspmetrics.com/put/notification/${account_id}`,
        { notificationIds }
      );
      // Update the local notifications state
      setNotifications((prev) =>
        prev.filter((notification) => !notificationIds.includes(notification.notification_id))
      );
    } catch (err) {
      console.error("Error updating notifications:", err);
      setError("Failed to update notifications.");
    }
  };

 

  // Mark all notifications as read
  const handleMarkAllAsRead = () => {
    const allIds = notifications.map((notification) => notification.notification_id);
    markAsRead(allIds);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {notifications.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="/notification" onClick={() => handleMarkAllAsRead()}  className="small">
                  {props.t("View All")}
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <Link
                  to=""
                  className="text-reset notification-item"
                  key={notification.notification_id}
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3" style={{width: '3rem'}}>
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className={notification.icon_type} />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1"><strong>{notification.note_type}</strong></h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{notification.text_msg}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {format(new Date(notification.update_time), DateTimeFormat)}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-center">{props.t("No notifications found.")}</p>
            )}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
