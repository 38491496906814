import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_COLLABORATOR,
  POST_COLLABORATOR,
  PUT_COLLABORATOR,
  POST_CONTRACT,
  GET_XLSDATA,
  POST_DEP,
  PUT_DEP,
  DELETE_DEP,
  POST_TEAM,
  PUT_TEAM,
  DELETE_TEAM,
  POST_CONTRACT_TYPE,
  PUT_CONTRACT_TYPE,
  DELETE_CONTRACT_TYPE,
  POST_LINK,
  GET_CONTRACT,
  PUT_COLLABORATOR_DOC,
  POST_DOCUMENT,
  PUT_COLLABORATOR_PROFILE,
  } from "./actionTypes";
 import {
  getcollaboratorSuccess,
  getcollaboratorFail,
  postCollaboratorSuccess,
  postCollaboratorFail,
  putCollaboratorSuccess,
  putCollaboratorFail,
  getContractSuccess,
  getContractFail,
  postContractSuccess,
  postContractFail,
  getXlsdataSuccess,
  getXlsdataFail,
  postDepSuccess,
  postDepFail,
  putDepSuccess,
  putDepFail,
  deleteDepSuccess,
  deleteDepFail,
  postTeamSuccess,
  postTeamFail,
  putTeamSuccess,
  putTeamFail,
  deleteTeamSuccess,
  deleteTeamFail,
  postContractTypeSuccess,
  postContractTypeFail,
  putContractTypeSuccess,
  putContractTypeFail,
  deleteContactTypeSuccess,
  deleteContactTypeFail,
  postLinkstSuccess,
  postLinksFail,
  pustProofSuccess,
  pustProofFail,
  postDocumentSuccess,
  postDocumentFail,
  putProfileSuccess,
  putProfileFail
  } from "./actions";

  //Include Both Helper File with needed methods
import {
  getcollaborators,
  addNewUser,
  updateUser,
  postContract,
  getXLS,
  postDepsapi,
  putDepsapi,
  deleteDepsapi,
  postTeamsapi,
  putTeamsapi,
  deleteTeamsapi,
  postContractTypesapi,
  putContractTypesapi,
  deleteContactTypesapi,
  postLinkapi,
  getContract,
  putProofApi,
  postDocApi,
  postProfileApi,
  } from "helpers/fakebackend_helper";
  function* fetchClientData({ payload: client }) {
    try {
      const response = yield call(getcollaborators, client);
      yield put(getcollaboratorSuccess(response));
    } catch (error) {
      yield put(getcollaboratorFail(error));
    }
  }
 
  function* postClientData({ payload: userdata }) {
    try {
      const response = yield call(addNewUser, userdata);
      yield put(postCollaboratorSuccess(response));
    } catch (error) {
      yield put(postCollaboratorFail(error));
    }
  }

  function* putClientData({ payload: putuser }) {
    try {
      const response = yield call(updateUser, putuser);
      yield put(putCollaboratorSuccess(response));
    } catch (error) {
      yield put(putCollaboratorFail(error));
    }
  }

  function* postContracts({ payload: contractdata }) {
    try {
      const response = yield call(postContract, contractdata);
      
      yield put(postContractSuccess(response));
    } catch (error) {
      yield put(postContractFail(error));
    }
  }

  function* getXlsdatas({ payload: xlsid }) {
    try {
      const response = yield call(getXLS, xlsid);
      yield put(getXlsdataSuccess(response));
    } catch (error) {
      yield put(getXlsdataFail(error));
    }
  }

  function* postDepSaga({ payload: postDeps }) {
    try {
      const response = yield call(postDepsapi, postDeps);
      yield put(postDepSuccess(response));
    } catch (error) {
      yield put(postDepFail(error));
    }
  }

  function* putDepSaga({ payload: putDeps }) {
    try {
      const response = yield call(putDepsapi, putDeps);
      yield put(putDepSuccess(response));
    } catch (error) {
      yield put(putDepFail(error));
    }
  }

  function* deleteDepSaga({ payload: deleteDeps }) {
    try {
      const response = yield call(deleteDepsapi, deleteDeps);
      yield put(deleteDepSuccess(response));
    } catch (error) {
      yield put(deleteDepFail(error));
    }
  }

  function* postTeamSaga({ payload: postTeams }) {
    try {
      const response = yield call(postTeamsapi, postTeams);
      yield put(postTeamSuccess(response));
    } catch (error) {
      yield put(postTeamFail(error));
    }
  }

  function* putTeamSaga({ payload: putTeams }) {
    try {
      const response = yield call(putTeamsapi, putTeams);
      yield put(putTeamSuccess(response));
    } catch (error) {
      yield put(putTeamFail(error));
    }
  }

  function* deleteTeamSaga({ payload: deleteTeams }) {
    try {
      const response = yield call(deleteTeamsapi, deleteTeams);
      yield put(deleteTeamSuccess(response));
    } catch (error) {
      yield put(deleteTeamFail(error));
    }
  }

  function* postContractTypeSaga({ payload: postContractTypes }) {
    try {
      const response = yield call(postContractTypesapi, postContractTypes);
      yield put(postContractTypeSuccess(response));
    } catch (error) {
      yield put(postContractTypeFail(error));
    }
  }

  function* putContractTypeSaga({ payload: putContractTypes }) {
    try {
      const response = yield call(putContractTypesapi, putContractTypes);
      yield put(putContractTypeSuccess(response));
    } catch (error) {
      yield put(putContractTypeFail(error));
    }
  }

  function* deleteContactTypeSaga({ payload: deleteContactTypes }) {
    try {
      const response = yield call(deleteContactTypesapi, deleteContactTypes);
      yield put(deleteContactTypeSuccess(response));
    } catch (error) {
      yield put(deleteContactTypeFail(error));
    }
  }

  function* postLinkSaga({ payload: link }) {
    try {
      const response = yield call(postLinkapi, link);
      yield put(postLinkstSuccess(response));
    } catch (error) {
      yield put(postLinksFail(error));
    }
  }

  function* getContractSaga({ payload: contract }) {
    try {
      const response = yield call(getContract, contract);
      yield put(getContractSuccess(response));
    } catch (error) {
      yield put(getContractFail(error));
    }
  }

  function* putCollaboratorProof({ payload: proof }) {
    try {
      const response = yield call(putProofApi, proof);
      yield put(pustProofSuccess(response));
    } catch (error) {
      yield put(pustProofFail(error));
    }
  }

  function* postCollaboratorDoc({ payload: doc }) {
    try {
      const response = yield call(postDocApi, doc);
      yield put(postDocumentSuccess(response));
    } catch (error) {
      yield put(postDocumentFail(error));
    }
  }

  function* postCollaboratorProfile({ payload: profile }) {
    try {
      const response = yield call(postProfileApi, profile);
      yield put(putProfileSuccess(response));
    } catch (error) {
      yield put(putProfileFail(error));
    }
  }

  function* ClientSaga() {
    yield takeEvery(GET_COLLABORATOR, fetchClientData);
    yield takeEvery(POST_COLLABORATOR, postClientData);
    yield takeEvery(PUT_COLLABORATOR, putClientData);
    yield takeEvery(POST_CONTRACT, postContracts);
    yield takeEvery(GET_XLSDATA, getXlsdatas);
    yield takeEvery(POST_DEP, postDepSaga);
    yield takeEvery(PUT_DEP, putDepSaga);
    yield takeEvery(DELETE_DEP, deleteDepSaga);
    yield takeEvery(POST_TEAM, postTeamSaga);
    yield takeEvery(PUT_TEAM, putTeamSaga);
    yield takeEvery(DELETE_TEAM, deleteTeamSaga);
    yield takeEvery(POST_CONTRACT_TYPE, postContractTypeSaga);
    yield takeEvery(PUT_CONTRACT_TYPE, putContractTypeSaga);
    yield takeEvery(DELETE_CONTRACT_TYPE, deleteContactTypeSaga);
    yield takeEvery(POST_LINK, postLinkSaga);
    yield takeEvery(GET_CONTRACT, getContractSaga);
    yield takeEvery(PUT_COLLABORATOR_DOC, putCollaboratorProof);
    yield takeEvery(POST_DOCUMENT, postCollaboratorDoc);
    yield takeEvery(PUT_COLLABORATOR_PROFILE, postCollaboratorProfile);
  }
  
  export default ClientSaga;
