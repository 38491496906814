import {
  GET_SIGNATURESIMG,
  GET_SIGNATURESIMG_SUCCESS,
  GET_SIGNATURESIMG_FAIL,
  GET_DOCUMENTEN,
  GET_DOCUMENTEN_SUCCESS,
  GET_DOCUMENTEN_FAIL,
  POST_SIGN_DATA,
  POST_SIGN_DATA_SUCCESS,
  POST_SIGN_DATA_FAIL,
  GET_SIGN_UPDATE,
  GET_SIGN_SUCCESS,
  GET_SIGN_FAIL,

  } from "./actionTypes";
  
 
  export const getSignatureImgByEmail = signaturesemail => ({
    type: GET_SIGNATURESIMG,
    signaturesemail,
  })
  export const getSignatureImgByEmailSuccess = email => ({
    type: GET_SIGNATURESIMG_SUCCESS,
    payload: email,
  })
  
  export const getSignatureImgByEmailFail = error => ({
    type: GET_SIGNATURESIMG_FAIL,
    payload: error,
  })

  export const getDocument = uuid => ({
    type: GET_DOCUMENTEN,
    payload: uuid,
  })
  
  export const getDocumentSuccess = id => ({
    type: GET_DOCUMENTEN_SUCCESS,
    payload: id,
  })
  
  export const getDocumentFail = error => ({
    type: GET_DOCUMENTEN_FAIL,
    payload: error,
  })

  export const postSigndata = jsonpostdata => ({
    type: POST_SIGN_DATA,
    payload: jsonpostdata,
  })
  
  export const postSigndatatSuccess = jsonpostdata => ({
    type: POST_SIGN_DATA_SUCCESS,
    payload: jsonpostdata,
  })
  
  export const postSigndataFail = error => ({
    type: POST_SIGN_DATA_FAIL,
    payload: error,
  })
  export const getSigndata = uuid => ({
    type: GET_SIGN_UPDATE,
    payload: uuid,
  })
  export const getSignSuccess = id => ({
    type: GET_SIGN_SUCCESS,
    payload: id,
  })
  
  export const getSignFail = error => ({
    type: GET_SIGN_FAIL,
    payload: error,
  })