import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { map } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  NavItem,
  NavLink,
  CardTitle,
  CardSubtitle,
  TabContent,
  TabPane,
  Modal,
  Input,
} from "reactstrap"
import classnames from "classnames"
import { format } from "date-fns";
//redux
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  getMailsLists as onGetMailsLists,
  accountInvoice as onGetAccountInvoice,
  getAbsencePolicyData as onGetAbsencePolicyData,
  getAbsenceType,
  postAbsencetype,
  putAbsencetype,
  deleteAbsenceType,
} from "store/actions";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
const Absence = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [modal_center, setmodal_center] = useState(false);
  const [updatemodal_center, setUpdatmodal_center] = useState(false);
  const dispatch = useDispatch();
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const [upactiveTab, setupactiveTab] = useState(1)
  const [passedUpSteps, setPassedUpSteps] = useState([1])
  const [policies, setPolicies] = useState([]);
  const [errors, setErrors] = useState({});
  const [uperrors, setUpErrors] = useState({});
  const [hoveredSection, setHoveredSection] = useState(null);

  const handleMouseEnter = (section) => setHoveredSection(section);
  const handleMouseLeave = () => setHoveredSection(null);
  const [absenceData, setAbsenceData] = useState({
    account_id: account_id,
    typeName:null,
    discription:null,
    disableHourCalculation:false,
    accruesTimeOff:false,
    accrualUnit:'hours',
    supportsWaitHours:false,
    countAs:'vk',
    shiftHandling:'Leave',
    balance:'',
    isPaid:false,
    policy_id:null,
    accrual_rate:'',
    max_accrual:'',
    requires_approval:false,
    affects_balance:false,
  });


  const [updateAbsenceData, setUpdateAbsenceData] = useState({
    account_id: account_id,
    typeName:null,
    discription:null,
    disableHourCalculation:false,
    accruesTimeOff:false,
    accrualUnit:'hours',
    supportsWaitHours:false,
    countAs:'Leave',
    shiftHandling:'Leave',
    balance:'',
    isPaid:false,
    policy_id:null,
    accrual_rate:'',
    max_accrual:'',
    requires_approval:false,
    affects_balance:false,
  });
  const {
    mailslists,
    invoice,
    getabsencepolicy,
    getabsencetype,
    postabsencetype,
    putabsencetype,
    deleteabsencetype,
    loading,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    invoice: state.mails.invoice,
    getabsencepolicy: state.getAbsenceData.getabsencepolicy,
    getabsencetype: state.getAbsenceData.getabsencetype,
    postabsencetype: state.getAbsenceData.postabsencetype,
    putabsencetype: state.getAbsenceData.putabsencetype,
    deleteabsencetype: state.getAbsenceData.deleteabsencetype,
    error: state.mails.error,
    loading: state.mails.loading,
  }));
  //meta title

  function toggleTab(tab) {
    // Validate only fields relevant to the current tab
    if (validateForm(activeTab)) {
      // Proceed only if validation is successful
      if (activeTab !== tab) {
        const modifiedSteps = [...passedSteps, tab];
        if (tab >= 1 && tab <= 2) {
          setactiveTab(tab);
          setPassedSteps(modifiedSteps);
        }
      }
    }
  }

  function toggleUpTab(tab) {
    // Validate only fields relevant to the current tab
    if (validateUpForm(upactiveTab)) {
      // Proceed only if validation is successful
      if (upactiveTab !== tab) {
        const modifiedSteps = [...passedUpSteps, tab];
        if (tab >= 1 && tab <= 2) {
          setupactiveTab(tab);
          setPassedUpSteps(modifiedSteps);
        }
      }
    }
  }

  useEffect(() => {
    if (account_id) {
      dispatch(getAbsenceType(account_id));
      dispatch(onGetAbsencePolicyData(account_id));
    }
  }, [dispatch, account_id]);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_Updatecenter() {
    setUpdatmodal_center(!updatemodal_center);
    removeBodyCss();
  }

  const openEditModal = (type) => {

    setUpdateAbsenceData({
      account_id: account_id,
      typeName:type.type_name,
      discription:type.description,
      disableHourCalculation:type.disable_hour_calculation,
      accruesTimeOff:type.accrues_time_off,
      accrualUnit:type.accrual_unit,
      supportsWaitHours:type.supports_wait_hours,
      countAs:type.count_as,
      shiftHandling:type.shift_handling,
      balance:type.balance,
      isPaid:type.is_paid,
      policy_id:type.policy_id,
      typeid:type.absence_type_id,
      accrual_rate:type.accrual_rate,
      max_accrual:type.max_accrual,
      requires_approval:type.requires_approval,
      affects_balance:type.affects_balance,
    });

    tog_Updatecenter(true);
    removeBodyCss();
  };
  // Handle input changes
  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setAbsenceData({
      ...absenceData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };

  const handleUpdateInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setUpdateAbsenceData({
      ...updateAbsenceData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };
  const validateForm = (tabNumber) => {
    const newErrors = {};
    const tab1RequiredFields = ['account_id', 'typeName','balance'];
  const tab2RequiredFields = ['policy_id', 'accrual_rate', 'max_accrual'];
  const requiredFields = tabNumber === 1 ? tab1RequiredFields : tab2RequiredFields;
    requiredFields.forEach((field) => {
      if (!absenceData[field] || (Array.isArray(absenceData[field]) && absenceData[field].length === 0)) {
        newErrors[field] = t('This field is required');
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

   const validateUpForm = (tabNumber) => {

    const newErrors = {};
    const tab1RequiredFields = ['account_id', 'typeName','balance'];
  const tab2RequiredFields = ['policy_id', 'accrual_rate', 'max_accrual'];
  const requiredFields = tabNumber === 1 ? tab1RequiredFields : tab2RequiredFields;
    requiredFields.forEach((field) => {
    
      if (!updateAbsenceData[field] || (Array.isArray(updateAbsenceData[field]) && updateAbsenceData[field].length === 0)) {
        newErrors[field] = t('This field is required');
      }
    });
    setUpErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // Handle form submission
  const submitForm = async () => {

    if (validateForm()) {
      dispatch(postAbsencetype(absenceData));
      toastr.success(t('Form submitted.'));
      setmodal_center(!modal_center);
    } else {
     
      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }
    // Perform any submission logic here, such as sending data to a server
  };
  const submitUpForm = async () => {

    if (validateUpForm()) {
      dispatch(putAbsencetype(updateAbsenceData));
      toastr.success(t('Form submitted.'));
      setUpdatmodal_center(!updatemodal_center);
    } else {
      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }
    // Perform any submission logic here, such as sending data to a server
  };

  useEffect(() => {
    if (getabsencepolicy.length > 0) {
      // Map over getabsencetype and extract the id and name
      const policy = getabsencepolicy.map((p) => ({
        id: p.policy_id , // Assuming 'typeid' is the ID
        name: p.policy_name, // Assuming 'type_name' is the name
      }));
  
      // Set the extracted data to the policies state
      setPolicies(policy);
    }
  }, [getabsencepolicy]);

 

  const removeModal = (id) => {
    if (id) {
      
      dispatch(deleteAbsenceType(id));;
      toastr.success(t('Form submitted.'));
    } else {
      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }

  };
  useEffect(() => {
    if (postabsencetype && postabsencetype.success) {
      toastr.success(t('Form submitted'));
      dispatch(getAbsenceType(account_id));;
    }
  
  }, [postabsencetype]);

  useEffect(() => {
    if (putabsencetype && putabsencetype.success) {
      toastr.success(t('Form submitted'));
      dispatch(getAbsenceType(account_id));;
    }
  
  }, [putabsencetype]);
  useEffect(() => {
    if (deleteabsencetype && deleteabsencetype.success) {
      toastr.success(t('Form submitted'));
      dispatch(getAbsenceType(account_id));;
    }
  
  }, [deleteabsencetype]);

  return (
    <React.Fragment>
<Modal
                      isOpen={updatemodal_center}
                      toggle={() => {
                        tog_Updatecenter();
                      }}
                      centered
                      scrollable={true}
                    >
                      <div className="modal-header">
                      
                        <h5 className="modal-title mt-0">{t('Absence Type')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            tog_Updatecenter(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: upactiveTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: upactiveTab === 1 })}
                            onClick={() => {
                              setupactiveTab(1)
                            }}
                            disabled={!(passedUpSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> {t('Absence Type')}
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: upactiveTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: upactiveTab === 2 })}
                            onClick={() => {
                              setupactiveTab(2)
                            }}
                            disabled={!(passedUpSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> 
                            {t('Policy Details')} 
                          </NavLink>
                        </NavItem>
                     
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={upactiveTab} className="body">
                        <TabPane tabId={1}>
                     
      <Row>
                  <Col lg="12" >
      <form>
      <div className="css-list">
        
        {/* Absence Name */}
        <div>
          <label htmlFor="typeName" className="css-iekl2y">{t('Absence Name')}</label>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="text"
              id="typeName"
              name="typeName"
              value={updateAbsenceData.typeName}
              onChange={handleUpdateInputChange}
            />
          </div>
          {uperrors.typeName && <p className="text-danger">{uperrors.typeName}</p>}
        </div>
        {/* Support Wait Hours */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="supportsWaitHours"
              name="supportsWaitHours"
              defaultChecked={updateAbsenceData.supportsWaitHours}
              onChange={handleUpdateInputChange}
            />
            <label className="form-check-label" htmlFor="supportsWaitHours">{t('Supports Wait Hours')}</label>
          </div>
        </div>

        {/* Disable Hour Calculation */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="disableHourCalculation"
              name="disableHourCalculation"
              defaultChecked={updateAbsenceData.disableHourCalculation}
              onChange={handleUpdateInputChange}
            />
            <label className="form-check-label" htmlFor="disableHourCalculation">{t('Disable Hour Calculation')}</label>
          </div>
        </div>

        {/* Accrues Time Off */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="accruesTimeOff"
              name="accruesTimeOff"
              defaultChecked={updateAbsenceData.accruesTimeOff}
              onChange={handleUpdateInputChange}
            />
            <label className="form-check-label" htmlFor="accruesTimeOff">{t('Accrues Time Off')}</label>
          </div>
        </div>

        {/* Accrual Unit */}
        <div>
          <label htmlFor="accrualUnit" className="css-iekl2y">{t('Accrual Unit')}</label>
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="accrualUnit"
              name="accrualUnit"
              value={updateAbsenceData.accrualUnit}
              onChange={handleUpdateInputChange}
            >
              <option value="hours">{t('Hours')}</option>
              <option value="days">{t('Days')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>

        {/* Must Be Counted As */}
        <div>
          <label htmlFor="countAs" className="css-iekl2y">{t('Must Be Counted As')}</label>
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="countAs"
              name="countAs"
              value={updateAbsenceData.countAs}
              onChange={handleUpdateInputChange}
              required
            >
              <option value="vk">{t('Vacation')}</option>
              <option value="zk">{t('Sick')}</option>
              <option value="no">{t('Non-attendance')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>

        {/* Existing Shifts Handling */}
        <div>
          <label htmlFor="shiftHandling" className="css-iekl2y">{t('Existing Shifts Handling')}</label>
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="shiftHandling"
              name="shiftHandling"
              value={updateAbsenceData.shiftHandling}
              onChange={handleUpdateInputChange}
              required
            >
              <option value="Leave">{t('Leave in schedule')}</option>
              <option value="Remove">{t('Remove from schedule')}</option>
              <option value="Open">{t('Move to open shift')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>
 {/* Balance */}
 <div>
          <label htmlFor="balance" className="css-iekl2y">{t('Balance (if applicable)')}</label>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="number"
              id="balance"
              name="balance"
              step="0.01"
              min="0"
              placeholder="160"
              value={updateAbsenceData.balance || ''}
              onChange={handleUpdateInputChange}
            />
          </div>
          {uperrors.balance && <p className="text-danger">{uperrors.balance}</p>}
        </div>

        {/* Paid Leave */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isPaid"
              name="isPaid"
              defaultChecked={updateAbsenceData.isPaid}
              onChange={handleUpdateInputChange}
            />
            <label className="form-check-label" htmlFor="isPaid">{t('Paid Leave')}</label>
          </div>
        </div>
        <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              name="discription"
              id="discription"
              className="form-control"
              rows="3"
             
              onChange={handleUpdateInputChange}
            >{updateAbsenceData.discription}</textarea>
         
        </div>
      </div>
      </div>
    </form>
    
                  </Col>
                </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Row>
                            <Col lg='12'>
                            <div>
          <label htmlFor="policyId" className="css-iekl2y">{t('Absence Policy')}</label>
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="policy_id"
              name="policy_id"
              value={updateAbsenceData.policy_id}
              onChange={handleUpdateInputChange}
           
            >
              <option >{t('Select Policy')}</option>
              {policies.map((policy) => (
    <option key={policy.id} value={policy.id}>
      {policy.name}
    </option>
  ))}
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
          {uperrors.policy_id && <p className="text-danger">{uperrors.policy_id}</p>}
        </div>


        <div>
          <label htmlFor="balance" className="css-iekl2y">{t('Accrual rate')} </label>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="number"
              id="accrual_rate"
              name="accrual_rate"
              value={updateAbsenceData.accrual_rate}
              onChange={handleUpdateInputChange}
              step="0.01"
              min="0"
              placeholder="0.0777"
            />
          </div>
          {uperrors.accrual_rate && <p className="text-danger">{uperrors.accrual_rate}</p>}
        </div>


        <div>
          <label htmlFor="balance" className="css-iekl2y">{t('Max accrual')} </label>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="number"
              id="max_accrual"
              name="max_accrual"
              value={updateAbsenceData.max_accrual}
              onChange={handleUpdateInputChange}
              step="0.01"
              min="0"
              placeholder="0.00"
            />
          </div>
          {uperrors.max_accrual && <p className="text-danger">{uperrors.max_accrual}</p>}
        </div>

        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="requires_approval"
              name="requires_approval"
              defaultChecked={updateAbsenceData.requires_approval}
              onChange={handleUpdateInputChange}
            />
            <label className="form-check-label" htmlFor="supportsWaitHours">{t('Requires Approval')} </label>
          </div>
        </div>


        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="affects_balance"
              name="affects_balance"
              defaultChecked={updateAbsenceData.affects_balance}
              onChange={handleUpdateInputChange}
            />
            <label className="form-check-label" htmlFor="supportsWaitHours">{t('Affects Balance')} </label>
          </div>
        </div>
                            </Col>
                          </Row>
                        </TabPane>
                    
                      </TabContent>
                    </div>
                   
                    {/* <div className="actions clearfix">
              
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 2 ? "next" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              
                              if (activeTab < 2) {
                                toggleTab(activeTab + 1);
                              } else if (activeTab === 2) {
                                submitForm(); // Submit the form when on the last tab
                              }
                            }}
                          >
                       {activeTab === 2 ? t('Submit') : t('Next')}
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>    
                      </div>
                      <div className="modal-footer">
                          <button type="button"
                          
                           className="btn css-1bl3roz startbtn btn btn-Primira"
                           style={{width:'20%',marginRight:'10px'}}
                           onClick={() => {
                            toggleUpTab(upactiveTab - 1)
                          }}
                           >
                           {t('Previous')}
                          </button>
                          <button
  type="button"
  style={{ width: '20%' }}
  className="btn css-1bl3roz startbtn btn btn-Primira"
  onClick={() => {
    // Check if the current tab has validation errors
    if (upactiveTab < 2) {
      if (validateUpForm(upactiveTab)) {
        // Move to the next tab if validation passes
        toggleUpTab(upactiveTab + 1);
      }
    } else if (upactiveTab === 2) {
      // If on the last tab, validate and submit the form
      if (validateUpForm(upactiveTab)) {
        submitUpForm(); // Submit the form if validation passes
      }
    }
  }}
>
  {upactiveTab === 2 ? t('Submit') : t('Next')}
</button>

                        </div>
                    </Modal>
<Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      centered
                      scrollable={true}
                    >
                      <div className="modal-header">
                      
                        <h5 className="modal-title mt-0">{t('Absence Type')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> {t('Absence Type')}
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> 
                            {t('Policy Details')}
                          </NavLink>
                        </NavItem>
                     
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                     
      <Row>
                  <Col lg="12" >
       <form >
      <div className="css-list">
        
        {/* Absence Name */}
        <div>
          <label htmlFor="typeName" className="css-iekl2y">{t('Absence Name')}</label>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="text"
              id="typeName"
              name="typeName"
              value={absenceData.typeName}
              onChange={handleInputChange}
            />
          </div>
          {errors.typeName && <p className="text-danger">{errors.typeName}</p>}
        </div>
        {/* Support Wait Hours */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="supportsWaitHours"
              name="supportsWaitHours"
              onChange={handleInputChange}
            />

<Row>
          <Col lg='6'>
          <label className="form-check-label" htmlFor="supportsWaitHours">{t('Supports Wait Hours')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'supportsWaitHours' && (
        <div
        onMouseEnter={() => handleMouseEnter('supportsWaitHours')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('Supports Wait Hours refers to whether the absence type accommodates waiting periods before an employee can start using accrued time off. This feature helps to control when employees can start taking leave based on their accruals. This can be useful for ensuring that employees have sufficient hours accrued before they can begin using their time off, and is commonly used in policies where new employees must wait for a certain period or until a specific accrual threshold is met.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'supportsWaitHours' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('supportsWaitHours')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
            
          </div>
        </div>

        {/* Disable Hour Calculation */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="disableHourCalculation"
              name="disableHourCalculation"
              onChange={handleInputChange}
            />
            <Row>
          <Col lg='6'>
          <label className="form-check-label" htmlFor="disableHourCalculation">{t('Disable Hour Calculation')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'disableHourCalculation' && (
        <div
        onMouseEnter={() => handleMouseEnter('disableHourCalculation')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('Disable Hour Calculation stops automatic calculations of hours for this absence type. This is useful for cases where hours should not be tracked or deducted based on the absence, such as when only full days are taken into account, or when tracking isn’t needed for the specific absence type.')}          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'disableHourCalculation' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('disableHourCalculation')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
            
          </div>
        </div>

        {/* Accrues Time Off */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="accruesTimeOff"
              name="accruesTimeOff"
              onChange={handleInputChange}
            />

<Row>
          <Col lg='6'>
          <label className="form-check-label" htmlFor="accruesTimeOff">{t('Accrues Time Off')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'accruesTimeOff' && (
        <div
        onMouseEnter={() => handleMouseEnter('accruesTimeOff')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('Accrues Time Off determines whether employees will accumulate time off hours as they work. When enabled, time off will be added to the balance periodically, depending on the accrual policy settings. This is useful for employees who earn vacation or other time off based on hours worked, length of service, or other factors.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'accruesTimeOff' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('accruesTimeOff')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
            
          </div>
        </div>

        {/* Accrual Unit */}
        <div>
        <Row>
          <Col lg='6'>
          <label htmlFor="accrualUnit" className="css-iekl2y">{t('Accrual Unit')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'accrualUnit' && (
        <div
        onMouseEnter={() => handleMouseEnter('accrualUnit')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('The Accrual Unit determines the measurement unit for time off accrual. It can be set to either hours or days. If you select (hours), the accrual will be calculated based on the number of hours an employee works. If you choose (days), the accrual will be based on full workdays, regardless of the specific hours worked in those days.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'accrualUnit' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('accrualUnit')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
         
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="accrualUnit"
              name="accrualUnit"
              value={absenceData.accrualUnit}
              onChange={handleInputChange}
            >
              <option value="hours">{t('Hours')}</option>
              <option value="days">{t('Days')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>

        {/* Must Be Counted As */}
        <div>
        <Row>
          <Col lg='6'>
          <label htmlFor="countAs" className="css-iekl2y">{t('Must Be Counted As')} </label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'countAs' && (
        <div
        onMouseEnter={() => handleMouseEnter('countAs')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('The (Must Be Counted As) field ensures that specific absences or time-off types are categorized correctly. It can be used to specify whether the time off should be counted as vacation, sick leave, maternity leave, or other types of absences. Proper categorization is critical for legal compliance, accurate payroll processing, and correct calculation of accruals.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'countAs' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('countAs')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
          
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="countAs"
              name="countAs"
              value={absenceData.countAs}
              onChange={handleInputChange}
              required
            >
             <option value="vk">{t('Vacation')}</option>
              <option value="zk">{t('Sick')}</option>
              <option value="no">{t('Non-attendance')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>

        {/* Existing Shifts Handling */}
        <div>
        <Row>
          <Col lg='6'>
          <label htmlFor="shiftHandling" className="css-iekl2y">{t('Existing Shifts Handling')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'shiftHandling' && (
        <div
        onMouseEnter={() => handleMouseEnter('shiftHandling')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('The (Existing Shifts Handling) option determines how any scheduled shifts during the time off or absence should be treated. It may provide options such as keeping the shift active, canceling it, or marking it as unavailable for replacement. Depending on the policy, the shifts can be reassigned or automatically canceled to avoid confusion in workforce planning and ensure smooth operations. Proper shift handling is critical in preventing miscommunication and avoiding understaffing.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'shiftHandling' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('shiftHandling')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
          
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="shiftHandling"
              name="shiftHandling"
              value={absenceData.shiftHandling}
              onChange={handleInputChange}
              required
            >
              <option value="Leave">{t('Leave in schedule')}</option>
              <option value="Remove">{t('Remove from schedule')}</option>
              <option value="Open">{t('Move to open shift')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>
  {/* Balance */}
  <div>
          <label htmlFor="balance" className="css-iekl2y">{t('Balance (if applicable)')}</label>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="number"
              id="balance"
              name="balance"
              step="0"
              min="0"
              placeholder="160"
              value={absenceData.balance || ''}
              onChange={handleInputChange}
            />
          </div>
          {errors.balance && <p className="text-danger">{errors.balance}</p>}
        </div>

        {/* Paid Leave */}
        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="isPaid"
              name="isPaid"
              onChange={handleInputChange}
            />
            <Row>
          <Col lg='6'>
          <label className="form-check-label" htmlFor="isPaid">{t('Paid Leave')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'isPaid' && (
        <div
        onMouseEnter={() => handleMouseEnter('isPaid')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('Paid Leave refers to the type of leave or absence where the employee is still entitled to receive their regular salary or wage while not working. It can include vacation days, sick leave, or other types of leave where the company policy allows the employee to take time off without financial loss. Ensuring clear policies around paid leave helps in maintaining employee satisfaction and organizational transparency.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'isPaid' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('isPaid')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
            
          </div>
        </div>
        <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              name="discription"
              id="discription"
              className="form-control"
              rows="3"
              onChange={handleInputChange}
            ></textarea>
         
        </div>
      </div>
      </div>
    </form>
    
                  </Col>
                </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Row>
                            <Col lg='12'>
                            <div>
                            <Row>
          <Col lg='6'>
          <label htmlFor="policyId" className="css-iekl2y">{t('Absence Policy')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'policyId' && (
        <div
        onMouseEnter={() => handleMouseEnter('policyId')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('The Absence Policy ID is a unique identifier that connects each absence type to a specific policy governing that absence. It ensures that the rules related to time off accrual, waiting periods, and other policy-related details are applied to the correct absence type, such as sick leave, vacation, or paid leave. Each absence type must have a corresponding policy to ensure accurate tracking and enforcement of leave regulations.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'policyId' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('policyId')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
         
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="policy_id"
              name="policy_id"
              value={absenceData.policy_id}
              onChange={handleInputChange}
           
            >
              <option >{t('Select Policy')}</option>
              {policies.map((policy) => (
    <option key={policy.id} value={policy.id}>
      {policy.name}
    </option>
  ))}
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
          {errors.policy_id && <p className="text-danger">{errors.policy_id}</p>}
        </div>


        <div>
        <Row>
          <Col lg='6'>
          <label htmlFor="Accrualrate" className="css-iekl2y">{t('Accrual rate')} </label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'Accrualrate' && (
        <div
        onMouseEnter={() => handleMouseEnter('Accrualrate')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('The Accrual Rate determines how much time off an employee earns over a specific period. It represents the rate at which hours or days are added to the employee’s time-off balance, based on their work hours or contract. For example, if the accrual rate is 1 hour for every 40 hours worked, the employee will accumulate 1 hour of time off for every week worked in a standard 40-hour work week.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'Accrualrate' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('Accrualrate')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
         
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="number"
              id="accrual_rate"
              name="accrual_rate"
              value={absenceData.accrual_rate}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              placeholder="0.00"
            />
          </div>
          {errors.accrual_rate && <p className="text-danger">{errors.accrual_rate}</p>}
        </div>


        <div>
          
          <Row>
          <Col lg='6'>
          <label htmlFor="max_accrual" className="css-iekl2y">{t('Max accrual')} </label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'max_accrual' && (
        <div
        onMouseEnter={() => handleMouseEnter('max_accrual')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('Max Accrual sets the upper limit on how much time off an employee can accumulate. Once this maximum is reached, the employee will stop accruing additional time off until they use some of their balance. For example, if the maximum accrual is set to 160 hours, any hours earned beyond this amount will not be added to the time-off balance.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'max_accrual' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('max_accrual')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="number"
              id="max_accrual"
              name="max_accrual"
              value={absenceData.max_accrual}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              placeholder="0.00"
            />
          </div>
          {errors.max_accrual && <p className="text-danger">{errors.max_accrual}</p>}
        </div>

        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="requires_approval"
              name="requires_approval"
              value={absenceData.requires_approval}
              onChange={handleInputChange}
            />
             <Row>
          <Col lg='6'>
          <label className="form-check-label" htmlFor="requires_approval">{t('Requires Approval')} </label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'requires_approval' && (
        <div
        onMouseEnter={() => handleMouseEnter('requires_approval')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('If the (Requires Approval) checkbox is checked, the absence requests made by employees using this absence type will need to be approved by an administrator or supervisor before they can be applied to the employee’s balance. If left unchecked, absences of this type will be automatically applied without requiring approval.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'requires_approval' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('requires_approval')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
            
          </div>
        </div>


        <div className="mb-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="affects_balance"
              name="affects_balance"
              value={absenceData.affects_balance}
              onChange={handleInputChange}
            />
            <Row>
          <Col lg='6'>
          <label className="form-check-label" htmlFor="affects_balance">{t('Affects Balance')} </label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'affects_balance' && (
        <div
        onMouseEnter={() => handleMouseEnter('affects_balance')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('If this option is checked, any absence of this type will directly affect the employee’s time-off balance. For example, if the absence type is marked as affecting the balance and an employee takes time off, their remaining time-off balance will be reduced. If left unchecked, absences of this type will not change the balance.')}
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'affects_balance' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('affects_balance')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    </button>
                    </div>
          </Col>
        </Row>
          </div>
        </div>
                            </Col>
                          </Row>
                        </TabPane>
                    
                      </TabContent>
                    </div>
                   
                    {/* <div className="actions clearfix">
              
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 2 ? "next" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              
                              if (activeTab < 2) {
                                toggleTab(activeTab + 1);
                              } else if (activeTab === 2) {
                                submitForm(); // Submit the form when on the last tab
                              }
                            }}
                          >
                       {activeTab === 2 ? t('Submit') : t('Next')}
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>    
                      </div>
                      <div className="modal-footer">
                          <button type="button"
                          
                           className="btn css-1bl3roz startbtn btn btn-Primira"
                           style={{width:'20%',marginRight:'10px'}}
                           onClick={() => {
                            toggleTab(activeTab - 1)
                          }}
                           >
                           {t('Previous')}
                          </button>
                          <button
  type="button"
  style={{ width: '20%' }}
  className="btn css-1bl3roz startbtn btn btn-Primira"
  onClick={() => {
    // Check if the current tab has validation errors
    if (activeTab < 2) {
      if (validateForm(activeTab)) {
        // Move to the next tab if validation passes
        toggleTab(activeTab + 1);
      }
    } else if (activeTab === 2) {
      // If on the last tab, validate and submit the form
      if (validateForm(activeTab)) {
        submitForm(); // Submit the form if validation passes
      }
    }
  }}
>
  {activeTab === 2 ? t('Submit') : t('Next')}
</button>

                        </div>
                    </Modal>
    <div style={{padding:'20px'}}>
    <div className="table-responsive" style={{marginTop:'20px'}}>
        <Button
        id="pdfButton1"

        type="button"
        className="btn  css-1bl3roz"
        style={{ background: '#d6d8da',color:'#000000', border: 'none',height:'40px',justifyContent:'space-between' }}
        >
           {t('Absence Type')}
           <div className="mb-3 row" style={{position:'relative',top:'7px'}}>
    
           <div className="btn-group sub-header__buttons">
            <button  onClick={() => {tog_center()}} className="css-1m5o92us" type="button">
              <span  className="css-23dsfxs" >
                <svg viewBox="0 0 16 16" height="100%" width="100%" >
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span> 
              <span  className="css-1ixbps20l d-none d-md-block">{t('Add Absence')}</span>
            </button>
          </div>
      </div>
      
        </Button>  
                      <Table className="align-middle mb-0">

                        <thead>
                          <tr>
                            <th>{t('Name')}</th>
                            <th>{t('Accrual')}</th>
                            <th>{t('Accrual unit')}</th>
                            <th>{t('Balance')}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {getabsencetype.length > 0 ? (
  getabsencetype.map(item => (
    <tr key={item.absence_type_id}>
    <td>{item.type_name}</td>
    <td>{item.accrues_time_off}</td>
<td>
  {item.accrual_unit === 'hours' ? t('Hours') : 
   item.accrual_unit === 'days' ? t('Days') : 'N/A'}
</td>
    <td>{item.balance}</td>
    <td>
  <button
   type="button" 
   className="btn btn-light btn-sm" 
   style={{ marginRight: '10px' }}
   onClick={() => openEditModal(item)}
   >{t('Edit')}</button>
  <button type="button"
   className="btn btn-light btn-sm"
   onClick={() => removeModal(item.absence_type_id)}
   >{t('Remove')}</button>
  </td>
  </tr>
  ))
) : (
<h6 >{t('No Record Found')}</h6>
)}
                        </tbody>
                      </Table>
                    </div>
  
       
     
        
         
      
    </div>
  </React.Fragment>
  )
}


export default withTranslation()(withRouter(Absence));