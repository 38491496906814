import React, { useRef, useState, useEffect } from 'react';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import { map } from "lodash";
import {
  getSignatureImgByEmail,
  getSignatureImgByEmailSuccess,
  getSignatureImgByEmailFail,
  getDocument,
  getDocumentSuccess,
  getDocumentFail,
} from "store/actions";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const DraggableElementNames = (props) => {
  const { t , onPageChange , selectedOption  ,permissions } = props;
  const dispatch = useDispatch();
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isNotProfessionalAllowed = plan_status === 'active' && plan_name !== 'professional';
  const [data, setData] = useState([]);
  const { uuid } = useParams();
  const [recipientNames, setRecipientNames] = useState([]);
  const [recipientEmails, setRecipientEmail] = useState([])
  const [recipientMsg, setRecipientMsg] = useState([])
  const [recipientroll, setRecipientRoll] = useState([]);
  const [recipienid, setRecipienid] = useState([]);
  const [Colors, setColor] = useState([]);
  const [status, setStatus] = useState([]);
  const [docIds, setDocIds] = useState([]);
  const [docNames, setDocNames] = useState([]);
  const [signaturepng, setSignPng] = useState([]);
  const [paraafimg, setParaafImg] = useState([]);
  const [stamppng, setStampPng] = useState([]);
  const [isOwner, setIsowner] = useState([]);
  const [recipientData, setRecipientData] = useState([]);
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const selectDocState = (state) => state.Prepare;

  const DocumentProperties = createSelector(
    selectDocState,
    (getSigantureImgData) => ({
      docname: getSigantureImgData.docname,
    })
  );

  const { docname } = useSelector(DocumentProperties);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (uuid) {
          await dispatch(getDocument(uuid));
        } else {
          console.error("User ID is not defined");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [dispatch, uuid]);
  const selectIMGState = (state) => state.Prepare;
  const ImgProperties = createSelector(
    selectIMGState,
     (getSigantureImgData) => ({
      signatureImgData: getSigantureImgData.signatureImgData,
     })
   );
 
   const { imgData } = useSelector(ImgProperties);
   useEffect(() => {
    const fetchImgData = async () => {
      try {
        if (userId) {
          await dispatch(getSignatureImgByEmail(userId));
        } else {
          console.error("User ID is not defined");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchImgData();
  }, [dispatch, userId]); 

  useEffect(() => {
    // Check if docname is an array
    if (Array.isArray(docname)) {
      // Assuming 'data' is the fetched data from your API
    
      // Use reduce to extract the required data from the 'data' array
      const extractedData = docname.reduce(
        (acc, item) => {
          const recipients = JSON.parse(item.recipient_data);
          
          recipients.forEach((recipient) => {
            acc.recipientNames.push(recipient.recipientName);
            acc.recipientEmail.push(recipient.recipientEmail);
            acc.recipientMsg.push(recipient.recipientMsg);
            acc.recipientroll.push(recipient.recipientroll);
            acc.Colors.push(recipient.color);
            acc.recipienid.push(recipient.recipienid);
            acc.signatureImge.push(recipient.signatureImge);
            acc.paraaf.push(recipient.paraaf);
            acc.stemp.push(recipient.stemp);
            acc.isOwner.push(recipient.isOwner);
          });
          
          return acc;
        },
        {
          recipientEmail: [],
          recipientMsg: [],
          recipientroll: [],
          Colors: [],
          recipientNames: [],
          recipienid: [],
          signatureImge: [],
          paraaf: [],
          stemp: [],
          isOwner: [],
        }
      );
  
      if (docname.length > 0) {
        setDocNames(docname[0].doc_name);
      }
      setRecipientNames(extractedData.recipientNames);
      setRecipientEmail(extractedData.recipientEmail);
      setRecipientMsg(extractedData.recipientMsg);
      setRecipientRoll(extractedData.recipientroll);
      setColor(extractedData.Colors);
      setRecipienid(extractedData.recipienid);
      setSignPng(extractedData.signatureImge);
      setParaafImg(extractedData.paraaf);
      setStampPng(extractedData.stemp);
      setIsowner(extractedData.isOwner);
    } else {
      console.error("docname is not an array");
    }
  }, [docname]);
  const obj = {
    recipientEmails:recipientEmails,
    recipientNames:recipientNames,
    recipientMsg:recipientMsg,
    recipientroll:recipientroll,
    Colors:Colors,
    status:status,
    docIds:docIds,
    docNames:docNames,
    signaturepng:signaturepng,
    paraafimg:paraafimg,
    stamppng:stamppng,
    recipientData:recipientData,
    recipienid:recipienid,
    isOwner:isOwner,
  }

  const LockedButton = () => (
    <button data-type="deleteType" data-qa="featureWall PaymentFixed" type="button" className="css-t01nit">
      <span className="css-zf56fy">
        <span aria-hidden="true" className="SVGInline">
          <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
            <path d="M13.5 6H13V5c0-2.8-2.2-5-5-5S3 2.2 3 5v1h-.5C1.7 6 1 6.7 1 7.5v7c0 .8.7 1.5 1.5 1.5h11c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5zM8 12.5c-.8 0-1.5-.7-1.5-1.5S7.2 9.5 8 9.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM11 6H5V5c0-1.7 1.3-3 3-3s3 1.3 3 3v1z"></path>
          </svg>
        </span>
      </span>
      <span className="css-1pdgtej">{t('More Info')}</span>
    </button>
  );
  return (
    <div>
   {recipientNames.map((item,index) => (
    <React.Fragment key={`${item}_${index}`}>

        {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
        <div 
         key={'fullname'}
        id='fullname'
        data-allow='allow'
        className="css-h0trhu dragmove_content" 
        draggable={isNotProfessionalAllowed ? "true" : "false"}
        data-type='fullname'
        data-format='fullname'
        data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
          >
         <button  data-type ="deleteType" data-qa="Signature" data-id="tab-palette-item" type="button" className="css-zuu3pb">
         <span className="css-xfo8x5"  style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}}>
         <span className="css-zf56fy">
         <span aria-hidden="true" className="SVGInline">
         <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M8 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c4 0 8 2 8 4v3H0v-3c0-2 4-4 8-4z"></path>
</svg>
         </span>
         </span>
         </span>
         <span className="css-1ny2g5x">
         <span className="css-195jadi">{t('Name')}</span>
         </span>
         </button>
         {!isNotProfessionalAllowed  && (
              LockedButton()
              )}
         </div>
         )}
         {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
         <div 
         key={'dragmove_email'}
         id='dragmove_email'
          className="css-h0trhu dragmove_content" 
          draggable={isNotProfessionalAllowed ? "true" : "false"}
           data-allow='allow'
           data-type='email'
           data-format='email'
           data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
        style={{display:'flex'}}
           >
           <button data-type ="deleteType" data-qa="Signature"
            data-id="tab-palette-item" 
            type="button"
             className="css-zuu3pb"
             >
           <span className="css-xfo8x5" style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}} >
           <span className="css-zf56fy">
           <span aria-hidden="true" className="SVGInline">
           <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
         <path d="M8 7.8 16 3c0-1.1-.9-2-2-2H2C.9 1 0 1.9 0 3v.1l8 4.7z"></path>
         <path d="M8 10.2 0 5.4V13c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V5.3l-8 4.9z"></path>
        </svg>
           </span>
           </span>
           </span>
           <span className="css-1ny2g5x">
           <span className="css-195jadi">{t('Email')}</span>
           </span>
           </button>
           {!isNotProfessionalAllowed  && (
              LockedButton()
              )}
           </div>
           )}
          
      
        </React.Fragment>
      ))}
</div> 
  );
};


export default withTranslation()(withRouter(DraggableElementNames));