/* SIGNATURES */
export const GET_SIGNATURES = "GET_SIGNATURES"
export const GET_SIGNATURES_SUCCESS = "GET_SIGNATURES_SUCCESS"
export const GET_SIGNATURES_FAIL = "GET_SIGNATURES_FAIL"
/**
 * Signature IMG 
 */
export const GET_SIGNATURESIMG = "GET_SIGNATURESIMG"
export const GET_SIGNATURESIMG_SUCCESS = "GET_SIGNATURESIMG_SUCCESS"
export const GET_SIGNATURESIMG_FAIL = "GET_SIGNATURESIMG_FAIL"
/**
 * Documenten 
 */
export const GET_DOCUMENTEN = "GET_DOCUMENTEN"
export const GET_DOCUMENTEN_SUCCESS = "GET_DOCUMENTEN_SUCCESS"
export const GET_DOCUMENTEN_FAIL = "GET_DOCUMENTEN_FAIL"
/**
 * Documenten UPDATE
 */
export const GET_SIGN_UPDATE = "GET_SIGN_UPDATE"
export const GET_SIGN_SUCCESS = "GET_SIGN_SUCCESS"
export const GET_SIGN_FAIL = "GET_SIGN_FAIL"

/**
 * add SIGNATURES
 */
export const ADD_NEW_SIGNATURES = "ADD_NEW_SIGNATURES"
export const ADD_SIGNATURES_SUCCESS = "ADD_SIGNATURES_SUCCESS"
export const ADD_SIGNATURES_FAIL = "ADD_SIGNATURES_FAIL"

/**
 * add HANDWRITING 
 */
export const ADD_NEW_HANDWRITING = "ADD_NEW_HANDWRITING"
export const ADD_HANDWRITING_SUCCESS = "ADD_HANDWRITING_SUCCESS"
export const ADD_HANDWRITING_FAIL = "ADD_HANDWRITING_FAIL"
/**
 * add STEPM 
 */
export const ADD_NEW_STEPM = "ADD_NEW_STEPM"
export const ADD_STEPM_SUCCESS = "ADD_STEPM_SUCCESS"
export const ADD_STEPM_FAIL = "ADD_STEPM_FAIL"

/**
 * Post Sign Data 
 */
export const POST_SIGN_DATA = "POST_SIGN_DATA"
export const POST_SIGN_DATA_SUCCESS = "ADD_STEPM_SUCCESS"
export const POST_SIGN_DATA_FAIL = "ADD_STEPM_FAIL"



