import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import {
  setData,
} from "../../common/data";
//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";
import CustomNavbar from "components/HeaderUnUser/Header";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate ,useHistory } from 'react-router-dom';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// actions
import { loginUser, socialLogin } from "../../store/actions";
import { withTranslation } from "react-i18next";
import { Link ,useLocation } from "react-router-dom";
const ContactSales = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language
  const location = useLocation();
    useEffect(() => {
      const headerColor = document.getElementById('header_color');
        
      // Check if the element exists before accessing it
      if (headerColor) {
        headerColor.style.backgroundColor = '#f4fff0';
      }
      // Function to update meta tags
      const updateMetaTags = () => {
        document.title = t(`Contact Our Sales Team | Learn More About ShiftSpec Solutions`);
      
        const metaElement = document.querySelector('meta[name="description"]');
        if (metaElement) {
          metaElement.setAttribute('content', 'Connect with the ShiftSpec sales team to discover how our workforce management solutions can help your business. Learn more about employee scheduling, time tracking, and digital signature tools.');
        } else {
          addOrUpdateMeta('description', 'Get in touch with the ShiftSpec sales team to explore how our comprehensive workforce management solutions can benefit your business. Whether you need employee scheduling, time tracking, or digital signatures, we’re here to help streamline your operations.');
        }
        
        addOrUpdateMeta('keywords', 'Contact Sales, ShiftSpec Sales, Workforce Management Solutions, Employee Scheduling Solutions, Time Tracking Software, Digital Signatures, Workforce Automation, Sales Inquiry, Business Efficiency Tools, ShiftSpec Contact, Request a Demo');
      
        function addOrUpdateMeta(name, content) {
          let metaTag = document.querySelector(`meta[name="${name}"]`);
          if (metaTag) {
            metaTag.setAttribute('content', content);
          } else {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag);
          }
        }
        
        // Meta tags for the "Contact Sales" page
        const metaTags = [
          { name: 'description', content: 'Get in touch with the ShiftSpec sales team to explore how our comprehensive workforce management solutions can benefit your business. Whether you need employee scheduling, time tracking, or digital signatures, we’re here to help streamline your operations.' },
          { name: 'keywords', content: 'Contact Sales, ShiftSpec Sales, Workforce Management Solutions, Employee Scheduling Solutions, Time Tracking Software, Digital Signatures, Workforce Automation, Sales Inquiry, Business Efficiency Tools, ShiftSpec Contact, Request a Demo' },
          { name: 'author', content: 'ShiftSpec Sales Team' }
        ];
        
        // Apply each meta tag
        metaTags.forEach(tag => {
          addOrUpdateMeta(tag.name, tag.content);
        });
        
        // Helper function for Open Graph and Twitter meta tags
        const addOrUpdateMetaTag = (property, content) => {
          let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
          if (tag) {
            tag.setAttribute('content', content);
          } else {
            tag = document.createElement('meta');
            tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
            tag.setAttribute('content', content);
            document.head.appendChild(tag);
          }
        };
      
        // Open Graph Meta Tags for "Contact Sales" page
        addOrUpdateMetaTag('og:title', 'Contact Our Sales Team | Learn More About ShiftSpec Solutions');
        addOrUpdateMetaTag('og:description', 'Connect with the ShiftSpec sales team to discover how our workforce management solutions can help your business. Learn more about employee scheduling, time tracking, and digital signature tools.');
        addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
        addOrUpdateMetaTag('og:type', 'website');
      
        // Twitter Meta Tags for "Contact Sales" page
        addOrUpdateMetaTag('twitter:title', 'Contact Our Sales Team | Learn More About ShiftSpec Solutions');
        addOrUpdateMetaTag('twitter:description', 'Connect with the ShiftSpec sales team to discover how our workforce management solutions can help your business. Learn more about employee scheduling, time tracking, and digital signature tools.');
        addOrUpdateMetaTag('twitter:card', 'summary_large_image');
      };
      

      // Call the function to update meta tags
      updateMetaTags();
  
    }, [ location.pathname, t]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      fristname: "",
      lastname: "",
      phone: "",
      country: "",
      textarea: "",
      company: "",
          
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t('Please Enter Your Email')),
      fristname: Yup.string().required(t('Please Enter Your fristname')),
      lastname: Yup.string().required(t('Please Enter Your lastname')),
      phone: Yup.string().required(t("Please Enter Your phone Number")),
      country: Yup.string().required(t("Please Enter Your country Name")),
      company: Yup.string().required(t("Please Enter Your Company Name")),
      textarea: Yup.string().required(t("Please Enter Your Messages")),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        
        const formData = new URLSearchParams();
      formData.append("email", values.email);
      formData.append("fristname", values.fristname);
      formData.append("lastname", values.lastname);
      formData.append("phone", values.phone);
      formData.append("country", values.country);
      formData.append("textarea", values.textarea);
      formData.append("company", values.company);
        
        const response = await fetch("https://dspmetrics.com/add/contact-sales", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: formData.toString(),
        });
      const data = await response.json();
     
      if (data.success) {
        toastr.success(data.message);
      } else {
        toastr.error(data.message);
        setErrorMessage(data.message);
      }
    
  
    } catch (error) {
      
      setErrorMessage(t("An error occurred during login Please try again"));
      console.error("Error during login:", error);
    }
    }
  });



  return (
    <React.Fragment>
          <CustomNavbar />
      <div className="" style={{background:'white'}}>
        <Container >

        <Row className="justify-content-left ">
            <Col md={8} lg={6} xl={7}>
            <section className="css-1nd7z9v px-8 pt-12 pb-12">
            <div className="gcdc-form-intro gcdc-form-group gcdc-form-group-padded">
      <div className="gcdc-form-message">
        <h1 className="text-dark text-[26px] font-extrabold">{t('Connect with our sales team')}</h1>
        <p style={{fontSize:'24px'}}>
        {t(`Complete this form and we'll aim to respond within one business day.If you want to connect now, call us at`)}{" "}
          <a className="intro-tel" href="tel:18777202040">
          {setData[0].phone_number}
          </a>
          .
        </p>
      </div>
    </div>
           
            <div className="css-1x7nnnc">
            <main className="css-1bg3kgs">
    <section>
    <fieldset className="css-0">

    <form onSubmit={validation.handleSubmit}>

    <div className="css-ho7zc7" style={{ display: 'flex', gap: '20px' }}>
  {/* First Name Input */}
  <div>
    <span className="css-15ylkfs">
      <Input
        type="text"
        name="fristname"
        maxLength="50"
        autoComplete="fristname"
        placeholder={t('Enter First Name')}
        id="fristname"
        className="fs-mask css-xv1qun"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.fristname || ""}
        invalid={validation.touched.fristname && !!validation.errors.fristname}
      />

      <div className="dxp-field-label css-1bzl99f" data-size="lg">
        <div className="css-5q8i94"></div>
        <div className="css-g9uw22"></div>
        {validation.touched.fristname && validation.errors.fristname && (
          <FormFeedback type="invalid">{validation.errors.fristname}</FormFeedback>
        )}
      </div>
    </span>
  </div>

  {/* Last Name Input */}
  <div>
    <span className="css-15ylkfs">
      <Input
        type="text"
        name="lastname"
        maxLength="50"
        autoComplete="lastname"
        placeholder={t('Enter Last Name')}
        id="lastname"
        className="fs-mask css-xv1qun"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.lastname || ""}
        invalid={validation.touched.lastname && !!validation.errors.lastname}
      />

      <div className="dxp-field-label css-1bzl99f" data-size="lg">
        <div className="css-5q8i94"></div>
        <div className="css-g9uw22"></div>
        {validation.touched.lastname && validation.errors.lastname && (
          <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
        )}
      </div>
    </span>
  </div>
</div>

<div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="email" 
     name="email" 
     maxLength="50" 
     autoComplete="email"
     placeholder={t('Enter email')}
      id="email"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.email || ""}
        invalid={validation.touched.email && !!validation.errors.email}
        />
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    
    
    <div className="css-g9uw22"></div>
    {validation.touched.email && validation.errors.email && (
          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
        )}
    </div>
    </span>
    </div>
    </div>

    <div className="css-ho7zc7" style={{ display: 'flex', gap: '20px' }}>
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="phone" 
     maxLength="50" 
     autoComplete="phone"
     placeholder={t('Enter Phone Numbe')}
      id="phone"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.phone || ""}
        invalid={validation.touched.phone && !!validation.errors.phone}
      />
    
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>

    <div className="css-g9uw22"></div>
    {validation.touched.phone && validation.errors.phone && (
        <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="company" 
     maxLength="50" 
     autoComplete="company"
     placeholder= {t('Enter Company Name')}
      id="company"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.company || ""}
        invalid={validation.touched.company && !!validation.errors.company}
      />
     
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>

    <div className="css-g9uw22"></div>
    {validation.touched.company && validation.errors.company && (
        <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    </div>
    <div className="css-ho7zc7">
  <div>
    <span className="css-15ylkfs"style={{height:'7rem'}}>
      <textarea
      
        name="textarea"
        maxLength="800"
        autoComplete="textarea"
        placeholder={t('Enter Country Name')}
        id="textarea"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.textarea || ""}
        rows="4" // You can adjust the number of rows to control the height of the textarea
        invalid={validation.touched.textarea && !!validation.errors.textarea}
      />
    
      <div className="dxp-field-label css-1bzl99f" data-size="lg">
        <div className="css-5q8i94"></div>
        <div className="css-g9uw22"></div>
        {validation.touched.textarea && validation.errors.textarea && (
          <FormFeedback type="invalid">{validation.errors.textarea}</FormFeedback>
        )}
      </div>
    </span>
  </div>
</div>

    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="country" 
     maxLength="50" 
     autoComplete="country"
     placeholder={t('Enter Country Name')}
      id="country"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.country || ""}
        invalid={validation.touched.country && !!validation.errors.country}
      />
    
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    <div className="css-g9uw22"></div>
    {validation.touched.country && validation.errors.country && (
        <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    </div>


    

      
    <div className="css-1m3b0b0">
      <label className="css-1rrra7o">
        <Input name="acceptAgreement" onChange={handleCheckboxChange} type="checkbox" id="acceptAgreement" data-id="acceptAgreement" className="css-1u9mbv2" value="true" />
        <span className="css-1wvofwg">{t('I agree to receive marketing communications from Shiftspec and acknowledge that I can opt out at any time by visiting the')} <a data-context="checkout-account-form" data-action="preference center" href="https://pref.docusign.com/preference-center/EmailVerification?params=bG9jYWxlaWQ9ZW5fR0I%3D" data-id="preferenceCenterLink" target="_blank" rel="noopener noreferrer" className="css-tc8190">{t('Preference Centre')}</a>.</span>
        </label>
        <div className="css-125vr0n">{t('By clicking the Continue button below, you agree to the')} <a data-context="checkout-account-form" data-action="terms and conditions" data-type="link" href="https://www.docusign.co.uk/en-gb/legal/terms-and-conditions/" data-id="termsLink" target="_blank" rel="noopener noreferrer" className="css-tc8190">{t('Terms & Conditions')}</a> {t('and')} <a data-context="checkout-account-form" data-action="privacy notice" data-type="link" href="https://www.docusign.co.uk/en-gb/privacy/" data-id="privacyLink" target="_blank" rel="noopener noreferrer" className="css-tc8190">{t('Privacy Policy')}</a>.</div>
    </div>
        <button title="Purchase"block  disabled={!isChecked} role="button"  type="submit"   className="css-pjhtp2">
    <span className="css-13wylk3">{t('Submit')}</span>
</button>
      </form>
      </fieldset>
    </section>
              </main>
              </div>
             </section>
            </Col>
            <Col md={12} lg={12} xl={5} >
            <aside className="css-15ftrm px-8 pt-12 pb-12" style={{background:'white'}}>
            <div className="css-1f7j8xl e7j4mzb0">
      <div className="css-1e83wej e1ae3iiv1">
        {/* Not an Enterprise Section */}
        <span className="css-1srnewm e1ae3iiv0">
  
          <span>
            <h2 id="not-an-enterprise font-bold" className="css-8yhmh0 e1et20720">
              Not an enterprise?
            </h2>
            <p className="css-1hltug8 e11vtv6p0 font-bold">{t(`Find a plan that's right for you.`)}</p>
            <a
              href="/plan-and-price/new"
              data-context="form-section-sidebar"
              data-type="link"
              data-action="plans and pricing"
              className="css-1uinr3x e762bb00"
            >
              <span className="css-n219vx e762bb01 font-bold">{t(`View Plans & Pricing`)}</span>
              <span aria-hidden="true">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.707 5.293l6 6a1 1 0 010 1.414l-6 6-1.414-1.414L16.586 13H4v-2h12.586l-4.293-4.293 1.414-1.414z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </a>
          </span>
        </span>

        {/* Help and Support Section */}
        <span className="css-1srnewm e1ae3iiv0">
          
          <span>
            <h2 id="help-support" className="css-8yhmh0 e1et20720">
            {t(`Help & support`)}  
            </h2>
            <p className="css-1hltug8 e11vtv6p0 font-bold">{t(`We’re here to help you with any technical or billing questions.`)}</p>
            <a
              href="/support"
              data-context="form-section-sidebar"
              data-type="link"
              data-action="contact customer support"
              className="css-1uinr3x e762bb00"
            >
              <span className="css-n219vx e762bb01 font-bold">{t(`Get Support`)}</span>
              <span aria-hidden="true">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.707 5.293l6 6a1 1 0 010 1.414l-6 6-1.414-1.414L16.586 13H4v-2h12.586l-4.293-4.293 1.414-1.414z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </a>
          </span>
        </span>
      </div>
    </div>
</aside>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};



ContactSales.propTypes = {
  history: PropTypes.object,
  t: PropTypes.isRequired,
};

export default withTranslation()(withRouter(ContactSales));