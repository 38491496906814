import React, { useState } from "react";
import { Container, Row, Col, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown, } from "reactstrap";
  import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
const Footer = () => {
  const [btnprimary1, setBtnprimary1] = useState(false)

  
  return (
    <React.Fragment>
      <footer className="footer" id="footer">
        <Container fluid={true}>
          <Row>
            <Col md={2}>{new Date().getFullYear()} © eSignatureHub.</Col>
            <Col md={6}>
              <div className="css-mg5nk5-FOOTER_MAIN_ROW_NO_LOGO">
                <ul className="css-57uoyj-FOOTER_LINK_LIST">
                  <li>
                  <div className="d-flex">
                  <LanguageDropdown />
                   </div>
                  </li>
                  <li data-link="true">
                    <a
                      href="https://support.docusign.com/contactSupport"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-qa="footer-contact-us"
                      className="css-1lvmctb-FOOTER_LINK"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li data-link="true">
                    <a
                      href="https://www.docusign.com/legal/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-qa="footer-terms-of-use"
                      className="css-1lvmctb-FOOTER_LINK"
                    >
                      Terms of Use
                    </a>
                  </li>
                  <li data-link="true">
                    <a
                      href="https://www.docusign.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-qa="footer-privacy"
                      className="css-1lvmctb-FOOTER_LINK"
                    >
                      Privacy
                    </a>
                  </li>
                  <li data-link="true">
                    <a
                      href="https://www.docusign.com/IP"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-qa="footer-intellectual-property"
                      className="css-1lvmctb-FOOTER_LINK"
                    >
                      Intellectual Property
                    </a>
                  </li>
                  <li data-link="true">
                    <a
                      href="https://www.docusign.com/trust"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-qa="footer-link-TRUST"
                      className="css-1lvmctb-FOOTER_LINK"
                    >
                      Trust
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-sm-end d-none d-sm-block">
                Copyright © 2024 eSignatureHub, Inc. All rights reserved
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
