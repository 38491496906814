import React, { useState, useRef } from 'react';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
function UploadStemp(props) {
  const { t } = props;
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const [StempData, setStempData] = useState(null);
    const {  onStempsData } = props;
  
    const handleDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      handleImage(file);
    };
  
    const handleImage = (file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    };
  
    const handleClear = () => {
      setSelectedImage(null);
      setStempData(null);
      onStempsData(null)
    };
  
    const handleSelectImage = () => {
      fileInputRef.current.click();
    };
  
    const handleFileInputChange = (e) => {
      const file = e.target.files[0];
      handleImage(file);
      setStempData(file);
      onStempsData(file)
    };
  
    const preventDefault = (e) => {
      e.preventDefault();
    };
  
    return (
      <div style={{ position: 'relative' }}>
        <button
          onClick={handleClear}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}><i className="bx bx-trash-alt"></i></span>
        </button>
        <div
          style={{
            width: '400px',
            height: '230px',
            border: '1px dashed #ccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onDrop={handleDrop}
          onDragOver={preventDefault}
          onClick={handleSelectImage}
        >
          {selectedImage ? (
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          ) : (
            t('Drag and drop image here or click to select')
          )}
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
        </div>
      </div>
    );

}

function UploadStempParaaf(props) {
  const { t } = props;
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const {  onStempsData } = props;
    const [StempData, setStempData] = useState(null);
    const handleDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      handleImage(file);
    };
  
    const handleImage = (file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    };
  
    const handleClear = () => {
      setSelectedImage(null);
      setStempData(null);
      onStempsData(null)
    };
  
    const handleSelectImage = () => {
      fileInputRef.current.click();
    };
  
    const handleFileInputChange = (e) => {
      const file = e.target.files[0];
      handleImage(file);
      setStempData(file);
      onStempsData(file)
    };
  
    const preventDefault = (e) => {
      e.preventDefault();
    };
  
    return (
      <div style={{ position: 'relative' }}>
        <button
          onClick={handleClear}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}><i className="bx bx-trash-alt"></i></span>
        </button>
        <div
          style={{
            width: '270px',
            height: '230px',
            border: '1px dashed #ccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign:'center',
          }}
          onDrop={handleDrop}
          onDragOver={preventDefault}
          onClick={handleSelectImage}
        >
          {selectedImage ? (
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          ) : (
            t('Drag and drop image here or click to select')
          )}
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
        </div>
      </div>
    );

}

export default withTranslation()(withRouter(UploadStemp));
export const UploadStempParaafWrapped = withTranslation()(withRouter(UploadStempParaaf));