
import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAIL,
  PUT_ACCOUNT_DATA,
  PUT_ACCOUNT_DATA_SUCCESS,
  PUT_ACCOUNT_DATA_FAIL,
  GET_ABSENCE_POLICY,
  GET_ABSENCE_POLICY_SUCCESS,
  GET_ABSENCE_POLICY_FAIL,
  POST_ABSENCE_POLICY,
  POST_ABSENCE_POLICY_SUCCESS,
  POST_ABSENCE_POLICY_FAIL,
  PUT_ABSENCE_POLICY,
  PUT_ABSENCE_POLICY_SUCCESS,
  PUT_ABSENCE_POLICY_FAIL,
  DELETE_ABSENCE_POLICY,
  DELETE_ABSENCE_POLICY_SUCCESS,
  DELETE_ABSENCE_POLICY_FAIL,
  GET_ABSENCE_TYPE,
  GET_ABSENCE_TYPE_SUCCESS,
  GET_ABSENCE_TYPE_FAIL,
  POST_ABSENCE_TYPE,
  POST_ABSENCE_TYPE_SUCCESS,
  POST_ABSENCE_TYPE_FAIL,
  PUT_ABSENCE_TYPE,
  PUT_ABSENCE_TYPE_SUCCESS,
  PUT_ABSENCE_TYPE_FAIL,
  DELETE_ABSENCE_TYPE,
  DELETE_ABSENCE_TYPE_SUCCESS,
  DELETE_ABSENCE_TYPE_FAIL,
  GET_ALL_ABSENCE,
  GET_ALL_ABSENCE_SUCCESS,
  GET_ALL_ABSENCE_FAIL,
  GET_ABSENCE,
  GET_ABSENCE_SUCCESS,
  GET_ABSENCE_FAIL,
  PUT_ABSENCE,
  PUT_ABSENCE_SUCCESS,
  PUT_ABSENCE_FAIL,
  POST_ABSENCE,
  POST_ABSENCE_SUCCESS,
  POST_ABSENCE_FAIL,
  DELETE_ABSENCE,
  DELETE_ABSENCE_SUCCESS,
  DELETE_ABSENCE_FAIL,
  GET_CORRECTION,
  GET_CORRECTION_SUCCESS,
  GET_CORRECTION_FAIL,
  POST_CORRECTION,
  POST_CORRECTION_SUCCESS,
  POST_CORRECTION_FAIL,
  PUT_CORRECTION,
  PUT_CORRECTION_SUCCESS,
  PUT_CORRECTION_FAIL,
  GET_FULL_ABSENCE_ACCESS,
  GET_FULL_ABSENCE_ACCESS_SUCCESS,
  GET_FULL_ABSENCE_ACCESS_FAIL,
  GET_ABSENCE_TOTAL,
  GET_ABSENCE_TOTAL_SUCCESS,
  GET_ABSENCE_TOTAL_FAIL,
  GET_HOURS_TOTAL,
  GET_HOURS_TOTAL_SUCCESS,
  GET_HOURS_TOTAL_FAIL,
  } from "./actionTypes";
  export const getAccountData = getaccountdata => ({
    type: GET_ACCOUNT_DATA,
    payload: getaccountdata,
  })
  export const getAccountDataSuccess = getaccountdata => ({
    type: GET_ACCOUNT_DATA_SUCCESS,
    payload: getaccountdata,
  })
  
  export const getAccountDataFail = error => ({
    type: GET_ACCOUNT_DATA_FAIL,
    payload: error,
  })


  export const putAccountData = putaccountdata => ({
    type: PUT_ACCOUNT_DATA,
    payload: putaccountdata,
  })
  export const putAccountDataSuccess = putaccountdata => ({
    type: PUT_ACCOUNT_DATA_SUCCESS,
    payload: putaccountdata,
  })
  
  export const putAccountDataFail = error => ({
    type: PUT_ACCOUNT_DATA_FAIL,
    payload: error,
  })



  export const getAbsencePolicyData = getabsencepolicy => ({
    type: GET_ABSENCE_POLICY,
    payload: getabsencepolicy,
  })
  export const getAbsencePolicyDataSuccess = getabsencepolicy => ({
    type: GET_ABSENCE_POLICY_SUCCESS,
    payload: getabsencepolicy,
  })
  
  export const getAbsencePolicyDataFail = error => ({
    type: GET_ABSENCE_POLICY_FAIL,
    payload: error,
  })


  export const postAbsencePolicyData = postabsencepolicy => ({
    type: POST_ABSENCE_POLICY,
    payload: postabsencepolicy,
  })
  export const postAbsencePolicyDataSuccess = postabsencepolicy => ({
    type: POST_ABSENCE_POLICY_SUCCESS,
    payload: postabsencepolicy,
  })
  
  export const postAbsencePolicyDataFail = error => ({
    type: POST_ABSENCE_POLICY_FAIL,
    payload: error,
  })


  export const putAbsencePolicyData = putabsencepolicy => ({
    type: PUT_ABSENCE_POLICY,
    payload: putabsencepolicy,
  })
  export const putAbsencePolicyDataSuccess = putabsencepolicy => ({
    type: PUT_ABSENCE_POLICY_SUCCESS,
    payload: putabsencepolicy,
  })
  
  export const putAbsencePolicyDataFail = error => ({
    type: PUT_ABSENCE_POLICY_FAIL,
    payload: error,
  })


  export const deleteAbsencePolicy = deleteabsencepolicy => ({
    type: DELETE_ABSENCE_POLICY,
    payload: deleteabsencepolicy,
  })
  export const deleteAbsencePolicySuccess = deleteabsencepolicy => ({
    type: DELETE_ABSENCE_POLICY_SUCCESS,
    payload: deleteabsencepolicy,
  })
  
  export const deleteAbsencePolicyFail = error => ({
    type: DELETE_ABSENCE_POLICY_FAIL,
    payload: error,
  })



  export const getAbsenceType = getabsencetype => ({
    type: GET_ABSENCE_TYPE,
    payload: getabsencetype,
  })
  export const getAbsenceTypeSuccess = getabsencetype => ({
    type: GET_ABSENCE_TYPE_SUCCESS,
    payload: getabsencetype,
  })
  
  export const getAbsenceTypeFail = error => ({
    type: GET_ABSENCE_TYPE_FAIL,
    payload: error,
  })


  export const postAbsencetype = postabsencetype => ({
    type: POST_ABSENCE_TYPE,
    payload: postabsencetype,
  })
  export const postAbsencetypeSuccess = postabsencetype => ({
    type: POST_ABSENCE_TYPE_SUCCESS,
    payload: postabsencetype,
  })
  
  export const postAbsencetypeFail = error => ({
    type: POST_ABSENCE_TYPE_FAIL,
    payload: error,
  })


  export const putAbsencetype = putabsencetype => ({
    type: PUT_ABSENCE_TYPE,
    payload: putabsencetype,
  })
  export const putAbsencetypeSuccess = putabsencetype => ({
    type: PUT_ABSENCE_TYPE_SUCCESS,
    payload: putabsencetype,
  })
  
  export const putAbsencetypeFail = error => ({
    type: PUT_ABSENCE_TYPE_FAIL,
    payload: error,
  })


  export const deleteAbsenceType = deleteabsencetype => ({
    type: DELETE_ABSENCE_TYPE,
    payload: deleteabsencetype,
  })
  export const deleteAbsenceTypeSuccess = deleteabsencetype => ({
    type: DELETE_ABSENCE_TYPE_SUCCESS,
    payload: deleteabsencetype,
  })
  
  export const deleteAbsenceTypeFail = error => ({
    type: DELETE_ABSENCE_TYPE_FAIL,
    payload: error,
  })
  export const getAllAbsence = getalltabsence => ({
    type: GET_ALL_ABSENCE,
    payload: getalltabsence,
  })
  export const getAllAbsenceSuccess = getalltabsence => ({
    type: GET_ALL_ABSENCE_SUCCESS,
    payload: getalltabsence,
  })
  
  export const getAllAbsenceFail = error => ({
    type: GET_ALL_ABSENCE_FAIL,
    payload: error,
  })


  export const getAbsence = getabsence => ({
    type: GET_ABSENCE,
    payload: getabsence,
  })
  export const getAbsenceSuccess = getabsence => ({
    type: GET_ABSENCE_SUCCESS,
    payload: getabsence,
  })
  
  export const getAbsenceFail = error => ({
    type: GET_ABSENCE_FAIL,
    payload: error,
  })


  export const putAbsence = putabsence => ({
    type: PUT_ABSENCE,
    payload: putabsence,
  })
  export const putAbsenceSuccess = putabsence => ({
    type: PUT_ABSENCE_SUCCESS,
    payload: putabsence,
  })
  
  export const putAbsenceFail = error => ({
    type: PUT_ABSENCE_FAIL,
    payload: error,
  })


  export const postAbsence = postabsence => ({
    type: POST_ABSENCE,
    payload: postabsence,
  })
  export const postAbsenceSuccess = postabsence => ({
    type: POST_ABSENCE_SUCCESS,
    payload: postabsence,
  })
  
  export const postAbsenceFail = error => ({
    type: POST_ABSENCE_FAIL,
    payload: error,
  })


  export const deleteAbsence = deleteabsence => ({
    type: DELETE_ABSENCE,
    payload: deleteabsence,
  })
  export const deleteAbsenceSuccess = deleteabsence => ({
    type: DELETE_ABSENCE_SUCCESS,
    payload: deleteabsence,
  })
  
  export const deleteAbsenceFail = error => ({
    type: DELETE_ABSENCE_FAIL,
    payload: error,
  })


  export const getCorrection = getcorrection => ({
    type: GET_CORRECTION,
    payload: getcorrection,
  })
  export const getCorrectionSuccess = getcorrection => ({
    type: GET_CORRECTION_SUCCESS,
    payload: getcorrection,
  })
  
  export const getCorrectionFail = error => ({
    type: GET_CORRECTION_FAIL,
    payload: error,
  })


  export const postCorrection = postcorrection => ({
    type: POST_CORRECTION,
    payload: postcorrection,
  })
  export const postCorrectionSuccess = postcorrection => ({
    type: POST_CORRECTION_SUCCESS,
    payload: postcorrection,
  })
  
  export const postCorrectionFail = error => ({
    type: POST_CORRECTION_FAIL,
    payload: error,
  })


  export const putCorrection = putcorrection => ({
    type: PUT_CORRECTION,
    payload: putcorrection,
  })
  export const putCorrectionSuccess = putcorrection => ({
    type: PUT_CORRECTION_SUCCESS,
    payload: putcorrection,
  })
  
  export const putCorrectionFail = error => ({
    type: PUT_CORRECTION_FAIL,
    payload: error,
  })

  

  export const getFullAccess = useraccess => ({
    type: GET_FULL_ABSENCE_ACCESS,
    payload: useraccess,
  })
  export const getFullAccessSuccess = useraccess => ({
    type: GET_FULL_ABSENCE_ACCESS_SUCCESS,
    payload: useraccess,
  })
  
  export const getFullAccessFail = error => ({
    type: GET_FULL_ABSENCE_ACCESS_FAIL,
    payload: error,
  })


  export const getAbsenceTotal = getTotalAbsence => ({
    type: GET_ABSENCE_TOTAL,
    payload: getTotalAbsence,
  })
  export const getAbsenceTotalSuccess = getTotalAbsence => ({
    type: GET_ABSENCE_TOTAL_SUCCESS,
    payload: getTotalAbsence,
  })
  
  export const getAbsenceTotalFail = error => ({
    type: GET_ABSENCE_TOTAL_FAIL,
    payload: error,
  })



  export const getHoursTotal = ContractAndWorkHours => ({
    type: GET_HOURS_TOTAL,
    payload: ContractAndWorkHours,
  })
  export const getHoursTotalSuccess = ContractAndWorkHours => ({
    type: GET_HOURS_TOTAL_SUCCESS,
    payload: ContractAndWorkHours,
  })
  
  export const getHoursTotalFail = error => ({
    type: GET_HOURS_TOTAL_FAIL,
    payload: error,
  })