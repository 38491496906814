import React, { useRef, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DraggableList from './DraggableList';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    InputGroup,
    UncontrolledCollapse
  } from "reactstrap";
  import classnames from "classnames";
import { func } from 'prop-types';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
 function Payment({
   t, targetid,datatype,onDateSelected ,
  }) {
    useEffect(() => {
      toastr.options = {
        positionClass: 'toast-top-center',
        closeButton: true,
        debug: true,
        progressBar:true,
        timeOut: 30000, // Duration in milliseconds
      };
    }, []);
    const fontFamilies = [
      { dataQa: 'arial', value: 'arial', label: 'Arial' },
      { dataQa: 'Courier', value: 'Courier', label: 'Courier' },
      { dataQa: 'CourierBold', value: 'CourierBold', label: 'CourierBold' },
      { dataQa: 'CourierBoldOblique', value: 'CourierBoldOblique', label: 'CourierBoldOblique' },
      { dataQa: 'CourierOblique', value: 'CourierOblique', label: 'CourierOblique' },
      { dataQa: 'HelveticaBold', value: 'HelveticaBold', label: 'HelveticaBold' },
      { dataQa: 'Helvetica', value: 'helvetica', label: 'Helvetica' },
      { dataQa: 'HelveticaBoldOblique', value: 'HelveticaBoldOblique', label: 'HelveticaBoldOblique' },
      { dataQa: 'TimesRoman', value: 'TimesRoman', label: 'TimesRoman' },
      { dataQa: 'TimesRomanBold', value: 'TimesRomanBold', label: 'TimesRomanBold' },
      { dataQa: 'TimesRomanItalic', value: 'TimesRomanItalic', label: 'TimesRomanItalic' },
      { dataQa: 'TimesRomanBoldItalic', value: 'TimesRomanBoldItalic', label: 'TimesRomanBoldItalic' },
      { dataQa: 'Symbol', value: 'Symbol', label: 'Symbol' },
      { dataQa: 'ZapfDingbats', value: 'ZapfDingbats', label: 'ZapfDingbats' },
    ];
      const getSVGContent = (format) => {
        switch (format) {
          case 'bold':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="m8.59 6.33.157-.006c.369 0 .674-.09.907-.267.219-.168.33-.45.33-.843 0-.22-.035-.397-.11-.54a.826.826 0 0 0-.288-.313 1.417 1.417 0 0 0-.444-.156 3.36 3.36 0 0 0-.562-.049H6.595V6.33H8.59zm.292 4.534c.203 0 .408-.024.61-.067a1.42 1.42 0 0 0 .486-.202.93.93 0 0 0 .316-.364c.079-.152.117-.351.117-.61 0-.502-.126-.844-.388-1.047-.275-.213-.633-.316-1.097-.316H6.595v2.606h2.287zM4 13V2h5.044c.504 0 .97.046 1.386.137.42.092.791.248 1.1.463.316.223.56.518.726.88.17.354.256.801.256 1.327 0 .58-.126 1.05-.386 1.44-.194.295-.46.549-.794.76.504.209.896.52 1.17.931.336.507.498 1.097.498 1.803 0 .575-.105 1.058-.322 1.475-.216.42-.51.765-.87 1.024a3.755 3.755 0 0 1-1.229.577 5.283 5.283 0 0 1-1.4.183H4z"></path>
            </svg>
            );
          case 'italic':
            return (
              <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
              <path d="M7 2v2h1.8l-3.101 7.5H3.6v2h5.999v-2H7.8L11 4h1.999V2z"></path>
            </svg>
            );
          case 'underline':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="M1 14h14v2H1zM2.846 2H4.98v5.956c0 .384.02.773.06 1.168.04.395.154.75.342 1.064.187.315.472.573.853.776.381.203.918.304 1.612.304.695 0 1.231-.101 1.612-.304.381-.203.667-.461.854-.776.192-.33.308-.692.34-1.064.04-.395.06-.784.06-1.168V2h2.134v6.612c0 .736-.12 1.376-.359 1.92a3.7 3.7 0 0 1-1.014 1.368 4.322 4.322 0 0 1-1.58.824 7.22 7.22 0 0 1-2.047.272 7.2 7.2 0 0 1-2.048-.272 4.322 4.322 0 0 1-1.577-.824 3.701 3.701 0 0 1-1.016-1.368c-.239-.544-.359-1.184-.359-1.92V2z"></path>
            </svg>
            );
          default:
            return null;
        }
      };
      const FontFormats = [
        { value: 'bold', label: 'Bold' },
        { value: 'italic', label: 'Italic' },
        { value: 'underline', label: 'Underline' },
        // Add more formats as needed
      ];
        const currencyOptions = [
      { value: 'usd', label: 'USD' },
      { value: 'aud', label: 'AUD' },
      { value: 'brl', label: 'BRL' },
      { value: 'cad', label: 'CAD' },
      { value: 'chf', label: 'CHF' },
      { value: 'cop', label: 'COP' },
      { value: 'eur', label: 'EUR' },
      { value: 'gbp', label: 'GBP' },
      { value: 'inr', label: 'INR' },
      { value: 'jpy', label: 'JPY' },
      { value: 'mxn', label: 'MXN' },
      { value: 'rmb', label: 'RMB' },
      { value: 'sgd', label: 'SGD' },
      { value: 'vnd', label: 'VND' },
      { value: 'krw', label: 'KRW' },
    ];
    const numberFormatOptions = [
      { value: 'en-US', label: '123,456,789.00' },
      { value: 'es-AR', label: '123.456.789,00' },
      { value: 'de-CH', label: "123'456'789.00" },
      { value: 'fr-CA', label: '123 456 789,00' },
      { value: 'hi-IN', label: '12,34,56,789.00' }, // Unique value
      { value: 'de-AT', label: '123 456 789.00' }, // Unique value
      { value: 'ar-SA', label: '١٢٣٬٤٥٦٬٧٨٩' },
    ];
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
  
    const [col5, setcol5] = useState(true);
    const [col6, setcol6] = useState(true);
    const [col7, setcol7] = useState(true);
    const [col9, setcol9] = useState(true);
    const [col10, setcol10] = useState(false);
    const [col11, setcol11] = useState(false);
    const [requreField, setRequre] = useState(true);
    const [validations, setValid] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState('currency');
    const [writeNumber, setFNumbers] = useState('00.00');
    const [selectNumbrFormat, setNumbrFormat] = useState(numberFormatOptions[0].value);
  
    const [selectCurrence, setSelectCurrence] = useState('USD');
    const [isChecked, setIsChecked] = useState(false);
    const [currencyDisplay, setCurrencyDisplay] = useState(false);
    const [minNumber, setMinNumber] = useState('-10000');
    const [maxNumber, setMaxNumber] = useState('1000000000');
    const [errorMessage, setErrorMessage] = useState('');
    const t_col1 = () => {
      setcol1(!col1);
      setcol2(false);
      setcol3(false);
    };
    
    const t_Text = () => {
      setRequre(!requreField);
      setValid(false);
    };
    const t_Text1 = () => {
      setValid(!validations);
      setRequre(false);
    };
    const t_col2 = () => {
      setcol2(!col2);
      setcol1(false);
      setcol3(false);
    };
  
    const t_col3 = () => {
      setcol3(!col3);
      setcol1(false);
      setcol2(false);
    };
  
    const t_col5 = () => {
      setcol5(!col5);
    };
  
    const t_col6 = () => {
      setcol6(!col6);
    };
  
    const t_col7 = () => {
      setcol7(!col7);
    };
  
    const t_col8 = () => {
      setcol6(!col6);
      setcol7(!col7);
    };
  
    const t_col9 = () => {
      setcol9(!col9);
      setcol10(false);
      setcol11(false);
    };
  
    const t_col10 = () => {
      setcol10(!col10);
      setcol9(false);
      setcol11(false);
    };
  
    const t_col11 = () => {
      setcol11(!col11);
      setcol10(false);
      setcol9(false);
    };
    const NumberRect = document.getElementById(targetid);
    const fontFamilyValues = NumberRect.getAttribute('data-fontFamily')
    const fontSizeValues = NumberRect.getAttribute('data-fontSize')
    const fontFormatValues = NumberRect.getAttribute('data-fontFormat')
    const fontColorsValues = NumberRect.getAttribute('data-fontColor')
    const newTextContent = NumberRect.getAttribute('data-content');
    const MinNum = NumberRect.getAttribute('data-min');
    const MaxNum = NumberRect.getAttribute('data-max');
    const optionnumtype = NumberRect.getAttribute('data-optionnumtype');
    const selecttype = NumberRect.getAttribute('data-selecttype');
    const valutacode = NumberRect.getAttribute('data-valutacode');

    const datalabel = NumberRect.getAttribute('data-label');
    const [datalabels, setDataLabel] = useState(datalabel);
    const rolls = NumberRect.getAttribute('data-roll');
    const [newNumber, setNewNumbers] = useState(newTextContent);
    const [selectedFontFamily, setSelectedFontFamily] = useState(fontFamilyValues);
    const [selectedFontSize, setSelectedFontSize] = useState(fontSizeValues);
    const [selectedFontFormat, setSelectedFontFormat] = useState(fontFormatValues);
    const [selectedFontColor, setSelectedFontColor] = useState(fontColorsValues);
    const [selectedValue, setSelectedValue] = useState(rolls);
    const padding = 10;
    if (NumberRect) {
      const parentGroup = NumberRect.parentElement;
     
      if (parentGroup) {
          const textToUpdate = parentGroup.querySelector('text');
          if (textToUpdate) {
              const x = parseFloat(NumberRect.getAttribute('x'));
              const y = parseFloat(NumberRect.getAttribute('y'));
              const textWidth = textToUpdate.getBBox().width;
              const textHeight = textToUpdate.getBBox().height;
              NumberRect.setAttribute('width', textWidth + padding);
              NumberRect.setAttribute('height', textHeight + padding);
               textToUpdate.setAttribute('text-anchor', 'middle'); // Center horizontally
               textToUpdate.setAttribute('dominant-baseline', 'middle');
               const width = parseFloat(NumberRect.getAttribute('width'));
               const height = parseFloat(NumberRect.getAttribute('height'));
              const tspans = textToUpdate.getElementsByTagName('tspan');
              const centerX = x + width / 2;
              const centerY = y + height / 2;
              const lineHeight = parseFloat(tspans[0].getAttribute('dy'));
              let currentY = centerY - (tspans.length - 1) * lineHeight / 2;
              if (tspans) {
                const textLength = newTextContent.length * fontSizeValues;
                const circles = parentGroup.querySelectorAll('circle');
                // Top-left corner
                if (circles) {
                  circles.forEach(circle => {
                    parentGroup.removeChild(circle);
                  });
                 
                }
                
         for (let i = 0; i < tspans.length; i++) {
                  const tspan = tspans[i];
                  tspan.textContent = newTextContent;
                  tspan.setAttribute('fill', fontColorsValues);
                  tspan.setAttribute('font-size', fontSizeValues);
                  tspan.setAttribute('font-family', fontFamilyValues);
                  tspans[i].setAttribute('x', centerX);
                  tspans[i].setAttribute('y', currentY);
                  currentY += lineHeight;
                  // Handle font formatting (italic, underline, bold)
                  tspan.style.fontStyle = fontFormatValues === 'italic' ? 'italic' : 'normal';
                  tspan.style.textDecoration = fontFormatValues === 'underline' ? 'underline' : 'none';
                  tspan.style.fontWeight = fontFormatValues === 'bold' ? 'bold' : 'normal';
        
                }
              }else {
                  console.log("No <tspan> elements found within <text>.");
                }
          } else {
              console.log("No <text> element found within parentGroup.")
            }
      } else {
          console.log("Parent group not found.");
        }
    } else {
      console.log("ElementToUpdate is null or undefined.");
    }
   
    const  labelStyle = {
        border: fontFormatValues === fontFormatValues ? '1px solid #cecece' : 'none',
        // Add other styles as needed
      };
    const itemStyle = {
        display: 'flex',
      };


      const handleLabelChange = (event) => {
        setDataLabel(event.target.value);
        NumberRect.setAttribute('data-label', event.target.value);
      };
      const handleMinNumberChange = (e) => {
        const value = e.target.value;
        NumberRect.setAttribute('data-min', value)
        setMinNumber(value);
        getMinMaxNumbers(value, maxNumber);
      };
    
      const handleMaxNumberChange = (e) => {
        const value = e.target.value;
        NumberRect.setAttribute('data-max', value);
        setMaxNumber(value);
        getMinMaxNumbers(minNumber, value);
      };
    
      // Step 3: Create a function to get the minimum and maximum numbers
      const getMinMaxNumbers = (min, max) => {
        const minVal = parseInt(min, 10);
        const maxVal = parseInt(max, 10);
    
        if (!isNaN(minVal) && !isNaN(maxVal)) {
          if (minVal > maxVal) {
            toastr.error(t('Minimum Amount cannot be greater than Maximum Amount.'));
            setErrorMessage(t('Minimum Amount cannot be greater than Maximum Amount.'));
          } else {
            toastr.clear()
            setErrorMessage(''); // Clear the error message if values are valid
          }
        }
      };
      const handleCheckboxChange = (even) => {
        setIsChecked(!isChecked);
        setCurrencyDisplay(isChecked);
        NumberRect.setAttribute('data-dataFormat', isChecked);
        NewNumberDate(writeNumber,selectNumbrFormat,selectedNumber,selectCurrence,currencyDisplay) 
      };
      const handleNumberTypeChange = (event) => {
        const numtype = event.target.value;
        setSelectedNumber(event.target.value); 
        NewNumberDate(writeNumber,selectNumbrFormat,numtype,selectCurrence,currencyDisplay)       
      };

      //alue={selectCurrence} onChange={handleSelectCurrence}
      const handleSelectCurrence = (event) => {
        const currncy = event.target.value;
        NumberRect.setAttribute('data-optionnumtype', currncy);
        setSelectCurrence(event.target.value);

        NewNumberDate(writeNumber,selectNumbrFormat,selectedNumber,currncy,currencyDisplay)
      };
      const handlenumberFormat = (event) => {
        const selectdb = event.target.value;
        NumberRect.setAttribute('data-valutacode', selectdb);
        setNumbrFormat(event.target.value);
        NewNumberDate(writeNumber,selectdb,selectedNumber,selectCurrence,currencyDisplay)
      };

      const handleNumberChange = (event) => {
        const inputValue = event.target.value;
        setFNumbers(event.target.value);
        NewNumberDate(inputValue,selectNumbrFormat,selectedNumber,selectCurrence,currencyDisplay)
        const checkValue = Number(inputValue);
        if (isNaN(checkValue)) {
          toastr.error(t('Please enter a valid number'));
          setErrorMessage(t('Please enter a valid number'));
        } else if (checkValue < minNumber || checkValue > maxNumber) {
          toastr.error(t('Minimum Amount cannot be greater than Maximum Amount.'));
          setErrorMessage(`${t('Value must be between')} ${minNumber} ${t('and')} ${maxNumber}.`);
        } else {
          toastr.clear()
          setErrorMessage('');
        }
      };
      //const [newNumber, setNewNumbers] = useState('');
      function NewNumberDate(inputValue, selectNumbrFormat, selectedNumber, selectCurrence,currencyDisplay) {
        const formatOptions = {
          minimumFractionDigits: 2, // Number of decimal places
        };
      
        if (selectedNumber === 'currency') {
          formatOptions.style = 'currency';
          formatOptions.currency = selectCurrence;
          
        }

        if (currencyDisplay) {
          formatOptions.currencyDisplay = 'code';
        }
       
        const numericValue = Number(inputValue.replace(/\D/g, ''));
        let formattedValue;
      
        if (selectedNumber === 'currency') {
          formattedValue = numericValue.toLocaleString(selectNumbrFormat, formatOptions);
        } else {
          formattedValue = numericValue.toLocaleString(selectNumbrFormat) + '.00';
        }
        setNewNumbers(formattedValue);
      //  const result = '[' + formattedValue + ']';
        NumberRect.setAttribute('data-content', formattedValue);
      }
     
     const handleFontFamilyChange = (event) => {
      setSelectedFontFamily(event.target.value);
      NumberRect.setAttribute('data-fontFamily', event.target.value);
    };
      const handleFontColorChange = (event) => {
        setSelectedFontColor(event.target.value);
        NumberRect.setAttribute('data-fontColor', event.target.value);
      };
      const handleFontSizeChange = (event) => {
        const newFontSize = event.target.value;
        setSelectedFontSize(event.target.value);
        NumberRect.setAttribute('data-fontSize', newFontSize);
      };
      const handleLiClick = (value) => {
        const newFontFormat = value;
        setSelectedFontFormat(value);
        NumberRect.setAttribute('data-fontFormat', value);
      };
      const handleRadioChange = (value) => {
        setSelectedValue(value);
        NumberRect.setAttribute('data-roll', value);
      };
      useEffect(() => {
        // Replace 'your-node-server-url' with the URL of your Node.js server
        onDateSelected( 
          targetid,datatype
          )
      }, [targetid,datatype]);
     
      return (
        <React.Fragment>
             <Row>
                        <Col lg={12}>
                        <div className="css-vlt30q">
          <div style={itemStyle}>
              <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <rect x="1" y="4" width="22" height="16" rx="2" ry="2"/>
  <line x1="1" y1="10" x2="23" y2="10"/>
</svg>



      </span>
      </span>
      <span data-qa="pannel-header" style={{paddingLeft:'0.625em'}}>{t('Payment')}</span>
      </div>
      </div>

    <div className="accordion " id="accordion">
    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo2">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !requreField }
                            )}
                            type="button"
                            onClick={t_Text}
                            style={{ cursor: "pointer" }}
                          >
                           {t('Rule')} 
                          </button>
                        </h2>
    <Collapse isOpen={requreField} className="accordion-collapse">
    <div className="accordion-body">
    <div className="text-muted">
    <Label>{t('Set Rule')}</Label>

    <div className="css-q10huv">
    <div className="css-hboir5" style={{display:'block'}}>
    <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
    <input
        type="radio"
        className="form-check-input"
        id="verplicht"
        name='checkbox'
        checked={rolls === 'true'}
        onClick={() => handleRadioChange('true')}              
        />
        <label
        className="form-check-label"
        htmlFor="verplicht"
        onClick={() => handleRadioChange('true')}
         >
       {t('Required field')}
        </label>
     </div>
     <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
     <input
        type="radio"
        className="form-check-input"
        id="lezen"
        name='checkbox' 
        checked={rolls === 'false'}
        onClick={() => handleRadioChange('false')}                 
        />
        <label
        className="form-check-label"
        htmlFor="alleenLezen"
        onClick={() => handleRadioChange('false')}
         >
       {t('Read-only')}
        </label>
     </div>                       
    </div>
    </div>
      </div>
      </div>
    </Collapse>
    </div>
    </div>
    <div className="accordion " id="accordion">
    <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !col1 }
                                  )}
                                  type="button"
                                  onClick={t_col1}
                                  style={{ cursor: "pointer" }}
                                >
                                    {t('Formatting')}
                                </button>
                              </h2>
      
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
            <div className="text-muted">
          <div className="css-q10huv">
          <div className="css-hboir5">
          <select
         className="css-12ihcxq" 
         value={fontFamilyValues}
        onChange={handleFontFamilyChange} >
        {fontFamilies.map((font) => (
    <option key={font.dataQa} value={font.value} data-qa={font.dataQa}>
      {font.label}
    </option>
  ))}
        </select>
              <span className="css-1baizyq">
                  <span className="css-lzaifp">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                      </svg>
                  </span>
              </span>
          </div>
      </div>
      <div className="css-9f4jv1">
          <div  style={{ marginRight: '-1px' ,width:"50px" }}>
              <div className="css-0">
                  <label data-qa="font-size-label" htmlFor="G29wOvqLVz" className="css-5gs0ys">{t('Font Size')}</label>
              </div>
              <div className="css-q10huv">
                  <div className="css-hboir5">
                  <select 
                data-qa="font-size"
                 id="G29wOvqLVz"
                  className="css-12ihcxq"
                  value={fontSizeValues}
                   onChange={handleFontSizeChange}>
                    <option data-qa="font-7" value="7">7</option>
                    <option data-qa="font-8" value="8">8</option>
                    <option data-qa="font-9" value="9">9</option>
                    <option data-qa="font-10" value="10">10</option>
                    <option data-qa="font-11" value="11" >11</option>
                    <option data-qa="font-12" value="12" >12</option>
                    <option data-qa="font-14" value="14">14</option>
                    <option data-qa="font-16" value="16">16</option>
                    <option data-qa="font-18" value="18">18</option>
                    <option data-qa="font-20" value="20">20</option>
                    <option data-qa="font-22" value="22">22</option>
                    <option data-qa="font-24" value="24">24</option>
                    <option data-qa="font-26" value="26">26</option>
                    <option data-qa="font-28" value="28">28</option>
                    <option data-qa="font-36" value="36">36</option>
                    <option data-qa="font-48" value="48">48</option>
                    <option data-qa="font-72" value="72">72</option>
                </select>
                      <span className="css-1baizyq">
                          <span className="css-lzaifp">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                                  <path d="M4 5h8l-4 6z"></path>
                              </svg>
                          </span>
                      </span>
                  </div>
              </div>
          </div>
          <div className="css-199kmjd">
          {FontFormats.map((format) => (
        <div key={format.value} className="css-vxcmzt">
          <Input
            id={format.value}
            name="radioGroup"
            type="radio"
            className="css-1pdgtej"
            value={fontFormatValues}
            checked={fontFormatValues === format.value}
          />
          <label htmlFor={format.value}
           className={`css-4lt7ka`} 
           style={fontFormatValues === format.value ? labelStyle : {}}
           onClick={() => handleLiClick(format.value)}>
            <span className="css-zf56fy">
              <span aria-hidden="true" className="SVGInline">
              {getSVGContent(format.value)}
              </span>
            </span>
            <span className="css-16htk9o">{format.label}</span>
          </label>
        </div>
      ))}
      </div>
      </div>
      <div className="css-q10huv">
          <div className="css-hboir5">
              <select className="css-12ihcxq"
               value={fontColorsValues} 
               onChange={handleFontColorChange}>
                     <option data-qa="#ffffff" value="#ffffff" selected>{t('White')}</option>
            <option data-qa="#0053eb" value="#0053eb">{t('Blue')}</option>
            <option data-qa="#f7e601" value="#f7e601">{t('Yellow')}</option>
            <option data-qa="#f70101" value="#f70101">{t('Red')}</option>
            <option data-qa="#000000" value="#000000">{t('Black')}</option>
            <option data-qa="#0087ff" value="#0087ff">{t('Bright Blue')}</option>
            <option data-qa="#7e0101" value="#7e0101">{t('Dark Red')}</option>
            <option data-qa="#ff0000" value="#ff0000">{t('Bright-Red')}</option>
            <option data-qa="#FFD700" value="#FFD700">{t('Gold')}</option>
            <option data-qa="#008000" value="#008000">{t('Green')}</option>
            <option data-qa="#006400" value="#006400">{t('Dark Green')}</option>
              </select>
              <span className="css-1baizyq">
                  <span className="css-lzaifp">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                      </svg>
                  </span>
              </span>
          </div>
      </div>
      
      
                      </div>
                          </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                           <button
                             className={classnames(
                               "accordion-button",
                               "fw-medium",
                               { collapsed: !col3 }
                             )}
                             type="button"
                             onClick={t_col9}
                             style={{ cursor: "pointer" }}
                           >
                              {t('Data Label')} 
                           </button>
                         </h2>
 
                         <Collapse isOpen={col9} className="accordion-collapse">
                           <div className="accordion-body">
                             <div className="text-muted">
                             <div className="css-q10huv">
                  <div className="css-hboir5">
                  <Input
                   className="css-12ihcxq"
                   type="text"
                   value={datalabel}
                   onChange={handleLabelChange}
                   />
                   
                  </div>
                  </div>
                             </div>
                           </div>
                         </Collapse>
                       </div>
                 <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                            <button
                        className={classnames("accordion-button", "fw-medium", { collapsed: !validations })}
                        type="button"
                        onClick={t_Text1}
                        style={{ cursor: "pointer" }}
                         >
                           {t('Validation')} 
                          </button>
                          </h2>
                  <Collapse
                    isOpen={validations}
                    className="accordion-collapse"
                  >
                  <div className="accordion-body">
                  <div className="text-muted">
                  <Label>{t('Minimum Amount')} </Label>
                  <div className="css-q10huv">
                  <div className="css-hboir5">
                  
                  <Input
                   className="css-12ihcxq"
                   type="text"
                   placeholder="-10000"
                   value={MinNum}
                   onChange={handleMinNumberChange}
                   />
                  
                  </div>
                  </div>
                  <Label>{t('Maximum Amount')}</Label>
                  <div className="css-q10huv">
                  <div className="css-hboir5">
                  
                  <Input
                   className="css-12ihcxq"
                   type="text"
                   placeholder="1000000"
                   value={MaxNum}
                   onChange={handleMaxNumberChange}
                   />
                  
                  </div>
                  </div>
                  <div className="css-164r41r">
                  {errorMessage && (
          <p
            data-qa="numerical-tab-value-error"
            id="8_rkQlwNOU"
            className="css-s3217f"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="16"
              height="16"
              focusable="false"
              aria-hidden="true"
              fill="currentColor"
            >
              <path d="M15.9 14.6 8.9.7C8.8.3 8.4 0 8 0s-.8.2-.9.6l-7 13.9c-.1.2-.1.3-.1.5 0 .6.4 1 1 1h14c.6 0 1-.4 1-1 0-.2 0-.3-.1-.4zM9 14H7v-2h2v2zm0-4H7V5h2v5z"></path>
            </svg>
            {errorMessage}
          </p>
        )}
                  </div>
                  </div>
                  </div>
                </Collapse>
                 </div>
                 <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                            <button
                        className={classnames("accordion-button", "fw-medium", { collapsed: !col3 })}
                        type="button"
                        onClick={t_col3}
                        style={{ cursor: "pointer" }}
                         >
                          {t('Number format')}
                          </button>
                          </h2>
                  <Collapse
                    isOpen={col3}
                    className="accordion-collapse"
                  >
                  <div className="accordion-body">
                  <div className="text-muted">
                  <Label>{t('Number Area')}</Label>
                  <div className="css-q10huv">
                  <div className="css-hboir5">
                  <select data-qa="format-font-selector" id="tvmENIox4K" className="css-12ihcxq"
                   value={selecttype}
                    onChange={handleNumberTypeChange} 
                    >
                  <option data-qa="Courier" value="currency">{t('Currencies')}</option>
                  </select>
                  <span className="css-1baizyq">
                  <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                  <path d="M4 5h8l-4 6z"></path>
                  </svg>
                  </span>
                  </span>
                  </div>
                  </div>
                  <div className="css-q10huv" style={{ display: selecttype === "currency" ? 'block' : 'none' }}>
                  <div className="css-hboir5">
                  <select data-qa="format-font-selector" id="tvmENIox4K" className="css-12ihcxq" value={optionnumtype} onChange={handleSelectCurrence} >
                  {currencyOptions .map((format) => (
              <option key={format.value} value={format.value}>
               {format.label}
              </option>
                 ))}
                  </select>
                  <span className="css-1baizyq">
                  <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                  <path d="M4 5h8l-4 6z"></path>
                  </svg>
                  </span>
                  </span>
                  </div>
                  </div>
                  <div className="css-q10huv" >
                  <div className="css-hboir5">
                  <select data-qa="format-font-selector" id="tvmENIox4K" className="css-12ihcxq" value={valutacode} onChange={handlenumberFormat} >
                  {numberFormatOptions .map((format) => (
              <option key={format.value} value={format.value}>
               {format.label}
              </option>
                 ))}
                  </select>
                  <span className="css-1baizyq">
                  <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                  <path d="M4 5h8l-4 6z"></path>
                  </svg>
                  </span>
                  </span>
                  </div>
                  </div>
                
                  <div className="css-q10huv" style={{ display: selecttype === "currency" ? 'block' : 'none' }}>
                  <div className="css-hboir5" style={{display:'block'}}>
                  <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
                <input
                 type="checkbox"
                className="form-check-input"
                placeholder="code"
                id="a4fa3f234324sa3" 
                value='code' 
                onClick={() => handleCheckboxChange('code')}
                checked={isChecked}      
                />
               <label
              
                className="form-check-label"
                htmlFor="a4fa3f234324sa3"
               >
             {t('Valutacode')}  
             </label>
            </div>
                  </div>
                  </div>
                  <div className="css-q10huv">
                  <div className="css-hboir5">
                  
                  <Input
                   className="css-12ihcxq"
                   type="text"
                   placeholder={writeNumber}
                   value={writeNumber}
                   onChange={handleNumberChange}
                   />
                  
                  </div>
                  </div>
                  <div className="css-164r41r">
                  {errorMessage && (
          <p
            data-qa="numerical-tab-value-error"
            id="8_rkQlwNOU"
            className="css-s3217f"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="16"
              height="16"
              focusable="false"
              aria-hidden="true"
              fill="currentColor"
            >
              <path d="M15.9 14.6 8.9.7C8.8.3 8.4 0 8 0s-.8.2-.9.6l-7 13.9c-.1.2-.1.3-.1.5 0 .6.4 1 1 1h14c.6 0 1-.4 1-1 0-.2 0-.3-.1-.4zM9 14H7v-2h2v2zm0-4H7V5h2v5z"></path>
            </svg>
            {errorMessage}
          </p>
        )}
                  </div>
                  </div>
                  </div>
                </Collapse>
                 </div>
                 
                          </div>
                        </Col>
                      
                      </Row>
        </React.Fragment>
        
        );
      }
      
      export default withTranslation()(Payment);
