import React, { useEffect } from 'react';
import {
    Row,
    Col,
  } from "reactstrap";
  import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import PropTypes from "prop-types";
  import { Link ,useLocation } from "react-router-dom";
import CustomNavbar from "components/HeaderUnUser/Header";
const AboutUs = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language
  const location = useLocation();
    useEffect(() => {
      const headerColor = document.getElementById('header_color');
        
      // Check if the element exists before accessing it
      if (headerColor) {
        headerColor.style.backgroundColor = '#f4fff0';
      }
      // Function to update meta tags
      const updateMetaTags = () => {
        document.title = t(`About ShiftSpec | Transforming Workforce Management Solutions`);
        
        const metaElement = document.querySelector('meta[name="description"]');
        if (metaElement) {
          metaElement.setAttribute('content', 'Learn more about ShiftSpec, a leader in workforce management solutions, offering innovative tools for employee scheduling, time tracking, and digital signatures. Empowering businesses to operate efficiently and increase productivity.');
        } else {
          addOrUpdateMeta('description', 'ShiftSpec is dedicated to providing cutting-edge workforce management solutions that streamline operations and drive business efficiency. Learn how we help businesses improve productivity through employee scheduling, time tracking, and digital signatures.');
        }
        
        addOrUpdateMeta('keywords', 'About ShiftSpec, Workforce Management Solutions, Employee Scheduling, Time Tracking, Digital Signatures, HR Solutions, Business Efficiency Tools, Shift Planning Software, About Workforce Solutions, Company Profile, Workforce Management Leader');
        
        function addOrUpdateMeta(name, content) {
          let metaTag = document.querySelector(`meta[name="${name}"]`);
          if (metaTag) {
            metaTag.setAttribute('content', content);
          } else {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag);
          }
        }
        
        // Meta tags for the "About Us" page
        const metaTags = [
          { name: 'description', content: 'ShiftSpec is dedicated to providing cutting-edge workforce management solutions that streamline operations and drive business efficiency. Learn how we help businesses improve productivity through employee scheduling, time tracking, and digital signatures.' },
          { name: 'keywords', content: 'About ShiftSpec, Workforce Management Solutions, Employee Scheduling, Time Tracking, Digital Signatures, HR Solutions, Business Efficiency Tools, Shift Planning Software, About Workforce Solutions, Company Profile, Workforce Management Leader' },
          { name: 'author', content: 'ShiftSpec Team' }
        ];
        
        // Apply each meta tag
        metaTags.forEach(tag => {
          addOrUpdateMeta(tag.name, tag.content);
        });
        
        // Helper function for Open Graph and Twitter meta tags
        const addOrUpdateMetaTag = (property, content) => {
          let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
          if (tag) {
            tag.setAttribute('content', content);
          } else {
            tag = document.createElement('meta');
            tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
            tag.setAttribute('content', content);
            document.head.appendChild(tag);
          }
        };
      
        // Open Graph Meta Tags for "About Us" page
        addOrUpdateMetaTag('og:title', 'About ShiftSpec | Transforming Workforce Management Solutions');
        addOrUpdateMetaTag('og:description', 'ShiftSpec provides innovative workforce management solutions that improve productivity, including tools for employee scheduling, time tracking, and digital signatures.');
        addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
        addOrUpdateMetaTag('og:type', 'website');
      
        // Twitter Meta Tags for "About Us" page
        addOrUpdateMetaTag('twitter:title', 'About ShiftSpec | Transforming Workforce Management Solutions');
        addOrUpdateMetaTag('twitter:description', 'ShiftSpec provides innovative workforce management solutions that improve productivity, including tools for employee scheduling, time tracking, and digital signatures.');
        addOrUpdateMetaTag('twitter:card', 'summary_large_image');
      };

      // Call the function to update meta tags
      updateMetaTags();
  
    }, [ location.pathname, t]);
  return (
    <React.Fragment>
    <CustomNavbar />
  
    <section className="relative lg:pb-10 pt-20 overflow-hidden" style={{background:'#f4fff0'}}>
    <Row>
        <Col sm={6} className='flex align-items-center justify-content-center'>
       
    <div className="lg:col-span-7 xl:pr-1 text-left">
        <h1 className="font-bold text-dark text-[30px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold  mb-1.5 lg:mt-3 md:mb-6">
        {t('About Shiftspec')}
        </h1>
        <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6">
        {t('The story behind the software we build with a team full of passion!')}
          
        </p>
      </div>

        </Col>
        <Col sm={6}>
        <div className="lg:col-span-5 text-center lg:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.37.43_ed606ac6.jpg"
          width="450"
          height="283"
          className="mx-auto lg:mr-0"
          alt="header image"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.37.43_ed606ac6.jpg 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
        <div className="lg:hidden flex flex-col items-center text-center mt-12">
          <div className="flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-5 mb-4 lg:mb-3 max-w-xs"></div>
        </div>
      </div>
      {/* Triangle SVG */}
  <svg
    className="absolute top-0  inset-x-0 -z-[5] mx-auto h-[40%] lg:translate-x-[20%] mt-32 opacity-20"
    style={{right:'24px'}}
    width="732"
    height="389"
    viewBox="0 0 732 389"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_26_895)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M625.302 293.386C626.169 291.133 627.896 289.21 630.278 288.153C631.888 287.438 633.666 287.188 635.412 287.432V287.432L720.091 299.248C722.671 299.608 724.861 300.98 726.316 302.906C727.772 304.833 728.491 307.315 728.131 309.895C727.91 311.483 727.287 312.988 726.322 314.268V314.268L676.344 380.577C674.776 382.658 672.513 383.906 670.122 384.242C667.731 384.578 665.211 384.002 663.13 382.434C661.85 381.469 660.836 380.194 660.185 378.729V378.729L625.484 300.603C624.426 298.223 624.434 295.639 625.302 293.386Z"
        stroke="#2A86CD"
        strokeWidth="6.29227"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.921 2.23295C172.217 1.48943 174.797 1.62246 177.117 2.80827C178.686 3.61016 180 4.83541 180.909 6.34525V6.34525L225.019 79.6095C226.363 81.8422 226.676 84.4081 226.094 86.7518C225.513 89.0955 224.038 91.2169 221.807 92.5606C220.434 93.3875 218.873 93.8506 217.271 93.9062V93.9062L134.303 96.784C131.7 96.8743 129.306 95.9005 127.54 94.253C125.774 92.6055 124.636 90.2844 124.545 87.6802C124.488 86.0775 124.841 84.487 125.57 83.059V83.059L164.428 6.9169C165.612 4.59653 167.624 2.97646 169.921 2.23295Z"
        stroke="#2A86CD"
        strokeWidth="6.29227"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.2132 113.629C63.4079 110.257 66.8895 107.722 71.1378 106.824C74.0107 106.216 76.9964 106.425 79.757 107.427V107.427L213.711 156.045C217.793 157.527 220.889 160.522 222.593 164.168C224.298 167.813 224.612 172.109 223.131 176.19C222.22 178.701 220.683 180.939 218.667 182.692V182.692L114.223 273.468C110.946 276.316 106.824 277.566 102.81 277.285C98.795 277.003 94.8872 275.191 92.0379 271.914C90.2844 269.897 89.0737 267.467 88.5203 264.852V264.852L59.01 125.459C58.1106 121.211 59.0185 117.002 61.2132 113.629Z"
        stroke="#2A86CD"
        strokeWidth="10.4871"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_26_895">
        <rect width="732" height="388.117" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
        </Col>
    </Row>
    </section>

    <section className="relative lg:pb-10 pt-20 overflow-hidden bg-white ">
    <Row>

    <Col sm={6}>
        <div className="lg:col-span-5 text-center lg:text-right mb-4 mt-4">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.28.39_f1fa91aa.jpg"
          width="450"
          height="283"
          className="mx-auto lg:mr-0"
          alt="header image"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.28.39_f1fa91aa.jpg 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
        <div className="lg:hidden flex flex-col items-center text-center mt-12">
          <div className="flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-5 mb-4 lg:mb-3 max-w-xs"></div>
        </div>
      </div>
      {/* Triangle SVG */}

        </Col>
        <Col sm={6} className='flex align-items-center justify-content-center '>
       
    <div className="lg:col-span-7 xl:pr-1 text-left">
        <h1 className="font-bold text-dark text-[30px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold  mb-1.5 lg:mt-3 md:mb-6">
        {t('Advanced Workforce Management Solutions')} 
        </h1>
        <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6">
        {t('ShiftSpec, created in 2021 by Bluewhite Marketing, leverages extensive expertise in marketing, software development, and data analytics. Focused on enhancing workforce management, ShiftSpec offers comprehensive solutions for scheduling, absence management, digital signatures, and more, empowering businesses to streamline their operations efficiently..')} 
        
        </p>
      </div>

        </Col>
    
    </Row>
    </section>

    <section className="relative lg:pb-10 pt-20 overflow-hidden bg-white">
  <Row>
    <Col sm={12} className="d-flex flex-column align-items-center justify-content-center text-center">
    <h2 className="font-bold text-dark text-[36px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold mb-4 mt-4">
    {t('A personal tour of Shiftspec')}   
      </h2>
      <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6"style={{ padding: '20px' }}>
     
    {t('Founded in 2021 by Bluewhite Marketing, ShiftSpec is a powerful web platform dedicated to transforming workforce management. With expertise in marketing, software development, and data analytics, we provide innovative solutions like employee scheduling, absence management, and digital signatures. Our mission is to help businesses streamline operations and optimize workforce efficiency, making management smarter and simpler.')}
      </p>
      <a href='/register/starter'>
        <button
          title="Purchase"
          block
          role="button"
          type="submit"
          className="css-pjhtp2"
          style={{ display: 'block', marginTop: '20px', marginBottom: '20px' }}
        >
          <span className="css-13wylk3">{t('Request a demo')}</span>
        </button>
      </a>
    </Col>
  </Row>
</section>

    </React.Fragment>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#00a0f0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    color: '#555',
  },
};

AboutUs.propTypes = {
  history: PropTypes.object,
  t: PropTypes.isRequired,
};

export default withRouter(withTranslation()(AboutUs));
