import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from "uuid";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";

  import { Link, useNavigate ,useParams } from "react-router-dom";
  import axios from 'axios';
  import { Document, Page,pdfjs } from 'react-pdf';
  import { createSelector } from "reselect";
  import { useSelector, useDispatch } from "react-redux";
  import {SignaturePad  } from "../Document/SignaturePad"
  import toastr from "toastr";
  import { createRoot } from 'react-dom/client';
import {QRCodeSVG} from 'qrcode.react';
import "toastr/build/toastr.min.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import {
    getSigndata,
    getSignSuccess,
    getSignFail,

  } from "store/actions";
  import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const SignToPdf =  (props) => {
  const { t } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const generateUniqueId = () => {
      return uuidv4();
    };
    const { uuid } = useParams();
    document.title = "Add Fields | Envelope | DigitalSignOnline";
    const [modal_fullscreen, setmodal_fullscreen] = useState(true);
    const [loading, setLoading] = useState(true);
    const pdfContainer = document.querySelector('svg#pdf-container');
    const svg =document.getElementById('previwe-container');
    const pdfContainerX = useRef();
    const pdfContainerY = useRef();
    const zooms = useRef();
    const [pdfUrls, setPdfUrl] = useState('');
    const [imageUrls, setImageUrls] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [dataContent, setDataContent] = useState({});
    const [doc_name, setDoc_name] = useState([])
    const [scale , setScale] = useState('');
    const [isValidate, setValidate] = useState(false);
   const { updatedata } = useSelector(state => state.Prepare);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_centerparaaf, setmodal_centerparaaf] = useState(false);
  const [modal_centerstemp, setmodal_centerstemp] = useState(false);
  const [modal_centerattach, setmodal_centerattach] = useState(false);
  const [signatureobj, setSignatureObje] = useState({});
  const [signatureParaafobj, setSignatureParaafObje] = useState({});
  const [signatureStempobj, setSignatureStempObje] = useState({});
  const [signatureData, setSignatureData] = useState(null);
  const [parrafData, setParaafData] = useState(null);
  const [stempData, setStempData] = useState(null);
  const [attachmentData, setAttachmentData] = useState(null);
  const [fullName, setFullname] = useState('');
  const [jsondata, setJson] = useState(null);
  const [database, setJsonData] = useState(null);
  const [signid, setSignid] = useState(null);
  const [firstName, setFristname] = useState(null);
  const [lastName, setLastname] = useState(null);
  const clientName = `${firstName} ${lastName}`;
  //const [updatedata, setUpdateData] = useState(null);
  const handleSignatureData = (data) => {
    setSignatureData(data);
  }
  const handleParaafData = (data) => {
    setParaafData(data);
  }
  const handleStemsData = (e) => {
    const file = e.target.files[0];
    setStempData(file);
  }
  const handleAtachmentData = (e) => {
    const file = e.target.files[0];
    setAttachmentData(file);
  }
  function tog_centerparaf() {
    setmodal_centerparaaf(!modal_centerparaaf);
    removeBodyCss();
  }

  function tog_centerstemps() {
    setmodal_centerstemp(!modal_centerstemp);
    removeBodyCss();
  }
  function tog_centerattach() {
    setmodal_centerattach(!modal_centerattach);
    removeBodyCss();
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen);
        removeBodyCss();
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding");
      }  

 
    useEffect(() => {
      if (uuid) {
          dispatch(getSigndata(uuid));
      } else {
          console.error("User ID is not defined");
      }
  }, []);
 
    useEffect(() => {
      setLoading(true);
     
      if (updatedata && updatedata.length > 0) {
        const isMobile = window.innerWidth <= 768; 
        const jsons = updatedata.map(item => item.jsondata);
        jsons.forEach(data => createObjectdata(data));
        jsons.forEach(data => setJson(data))
      
        setJsonData(jsons[0])
        updatedata.forEach(item => {
          const initialZoomAdjusted = isMobile ? item.scale / 2 : item.scale;
    const scales = initialZoomAdjusted / 1;
          setScale(item.scale)
          zooms.current = scales;
          setDoc_name(item.doc_name);
          setFullname(item.full_name);
          setSignid(item.uuid)
          setFristname(item.frist_name)
          setLastname(item.last_name)
        });
        // Now you have an array of extracted data
       
      }else{
        setLoading(true);
      }
      setLoading(false);
    }, [updatedata]);
 
  
    
  
    
 
    const isMobile = window.innerWidth <= 768; 
    const initialZoomAdjusted = isMobile ? scale / 2 : scale;
    const scales = initialZoomAdjusted / 1;
  
    const renderPage = async (pageNumber, newscale) => {
      try {
        const loadingTask = pdfjs.getDocument(pdfUrls);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(pageNumber);
        
      
       const viewport = page.getViewport({ scale: newscale });
  
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const canvasWidth = viewport.width;
        const canvasHeight = viewport.height;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        pdfContainerX.current = canvas.width;
        pdfContainerY.current = canvas.height;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
  
        await page.render(renderContext).promise;
        const dataUrl = canvas.toDataURL('image/png');
        setImageUrls((prevUrls) => [...prevUrls, { url: dataUrl, height: canvasHeight }]);
        await page.cleanup();
        canvas.remove();
      } catch (error) {
        console.error('Error rendering page:', error);
      }
    };
    const fetchPdf = async () => {
      try {
        const response = await axios.get(`${doc_name}`, {
          responseType: 'arraybuffer',
        });
  
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };
  
  
    useEffect(() => {
      fetchPdf();
    }, [doc_name,scales,zooms]);

    useEffect(() => {
      const renderPdf = async () => {
        try {
          const loadingTask = pdfjs.getDocument(pdfUrls);
          const pdf = await loadingTask.promise;
  
          setNumPages(pdf.numPages);
        } catch (error) {
          console.error('Error loading PDF:', error);
        }
      };
  
      if (pdfUrls) {
        renderPdf();
      }
    }, [pdfUrls]);
   
    useEffect(() => {
      setImageUrls([]); // Clear existing image URLs before rendering new pages
  
      const renderAllPages = async () => {
        for (let i = 1; i <= numPages; i++) {
          await renderPage(i,scales);
        }
      };
    
      if (numPages) {
        renderAllPages();
      }
    }, [numPages, scales, pdfUrls]);
      
    const totalImageHeight = imageUrls.reduce((totalHeight, imageData) => {
      return totalHeight + imageData.height;
    }, 0);


    async function createObjectdata(data) {
      try {
          const parsedData = JSON.parse(data);
        
          if (!Array.isArray(parsedData) || parsedData.length === 0) {
              throw new Error('Parsed data is not an array or is empty');
          }
          
          // Loop through each item in the array
          const keyHandlers = {
            qrcode: imageObjectArray,
            formula: imageObjectArray,
            dropdown: imageObjectArray,
            company: imageObjectArray,
            fullname: imageObjectArray,
            email: imageObjectArray,
            title: imageObjectArray,
            handwriteing: imageObjectArray,
            hand_praaf: imageObjectArray,
            stemps: imageObjectArray,
            calendar: imageObjectArray,
            textarea: imageObjectArray,
            drawing: imageObjectArray,
            attachment: imageObjectArray,
            approve: imageObjectArray,
            decline: imageObjectArray,
            number: imageObjectArray,
            payment: imageObjectArray,
            radio: imageObjectArray,
            checkbox: imageObjectArray,
          };
      
          parsedData.forEach(obj => {
            Object.keys(obj).forEach(key => {
              if (keyHandlers[key]) {
                keyHandlers[key](obj[key]);
              }
            });
          });
      } catch (error) {
          console.error('Validation failed:', error.message);
      }
  }
  
  

async function imageObjectArray(data) {

  // Check if data.targetid exists and is an array
  if (Array.isArray(data.targetid) && data.targetid.length > 0) {
    const numberOfItems = data.targetid.length;
    
    for (let i = 0; i < numberOfItems; i++) {
      const currentItem = {
        targetid: data.targetid[i],
        height: data.height[i],
        x: data.x[i],
        y: data.y[i],
        width: data.width[i],
        w: data.width,
        h: data.height,
        left: data.x,
        top: data.y,
        content: data.signContent[i],
        dateformat: data.dataFormat[i],
        fontColor: data.fontColor[i],
        fontFormat: data.fontFormat[i],
        fontSize: data.fontSize[i],
        fontFamily: data.fontFamily[i],
        page: data.page[i],
        roll: data.roll[i],
        isOrder: data.isOrder[i],
        issigned: data.isSigned[i],
        fullname: data.fullname,
        token: data.client_token,
        uniqueid: data.userid,
        zoom: data.zoom_int,
        email: data.email,
        messeges: data.messeges,
        sign_id: data.sign_id,
        format: data.type[i],
        labelValeu: data.labelValeu[i],
        dropdownoption: data.selectOption,
        dropdownvalue: data.selectValue,
        groupischecked: data.ischecked,
        groupmax: data.groupContaineMax,
        groupmin: data.groupContaineMin,
        grouprule: data.groupContaineRule,
        containerY: data.containerY,
        containerX: data.containerX,
        totalheight: data.totalheight,
        totalwidth: data.totalwidth,
        grroupvalidation: data.groupContainevalidation,
        nummax: data.max,
        nummin: data.min,
        selecttype: data.selecttype,
        valutacode: data.valutacode[i],
        fgcolo: data.fgColor,
        bgcolor: data.bgColor,
        groupContainerid: data.groupContainerid,
        rectgroupContainerid: data.rectgroupContainerid[i],
      };


     // console.log(currentItem,'currentItem')
      switch (currentItem.format) {
        case 'handwriteing':
        case 'hand_praaf':
        case 'stemps':
        case 'calendar':
        case 'fullname':
        case 'email':
        case 'company': 
        case 'title':
        case 'textarea':
        case 'drawing':
        case 'attachment': 
        case 'approve':
        case 'decline':
        case 'number': 
        case 'payment':
          createSignatureElements(currentItem);
          break;
        case 'qrcode':
          createQRCodeElement(currentItem);
          break; 
        case 'checkbox':
          
       createGroupBoxCheckAndRadio(currentItem);      
          break; 
        case 'radio':
          createRadioGroup(currentItem);
          break; 
        case 'dropdown':
          if (currentItem.dropdownoption && Array.isArray(currentItem.dropdownoption)) {
            currentItem.dropdownoption = currentItem.dropdownoption.filter(option => option !== null);

            createDropDownElements(currentItem);
          } else {
            console.log('dropdownoption is either null or not an array');
          }
          
          break;
        case 'formula':
          setTimeout(() => {
            createFormulaElements(currentItem);
          }, 3000);
     
          break;
        default:
          break;
      }
    }
  } else {
    console.error('Invalid data: targetid is not an array or is empty');
  }
}

async function createRadioGroup(data) {
  const { x, y, width, height,left, top, w, h, format, content, fullname, targetid,
    groupischecked,groupmax,groupmin,grouprule,grroupvalidation,isOrder,
    targetids, uniqueid,token, email, optionnumtype,zoom, roll,issigned,totalheight,totalwidth,
     containerY,containerX } = data;
     const initialZoomAdjusted = isMobile ? zoom / 2 : zoom;
     const scales = initialZoomAdjusted / 1;
     const scaledX = x * scales;
     const scaledY = y * scales;
     const scaledWidth = width * scales;
     const scaledHeight = height * scales;
     const existingGroup = svg.querySelector(`g[data-id="${targetid}"]`);
     if (existingGroup) {
         svg.removeChild(existingGroup);
     }
 
  const svgNS = 'http://www.w3.org/2000/svg';

  async function createGroupContainer() {
    return new Promise(async (resolve, reject) => {
      try {
        const groupContainers = [];
  
        for (let index = 0; index < totalheight.length; index++) {
          const groupContainerElement = document.createElementNS(svgNS, 'g');
  
          groupContainerElement.setAttribute('data-rule',grouprule);
          groupContainerElement.setAttribute('data-max', groupmax);
          groupContainerElement.setAttribute('data-min', groupmin);
          groupContainerElement.setAttribute('data-validation', grroupvalidation);
          groupContainerElement.setAttribute('class', 'previwe_user');
          groupContainerElement.setAttribute('data-displayname', fullname);
          groupContainerElement.setAttribute('data-id',targetid);
          groupContainerElement.setAttribute('data-isvalid','false');
          groupContainers.push(groupContainerElement);
        }
  
        resolve(groupContainers);
      } catch (error) {
        reject(error);
      }
    });
  }


  async function createinputElement() {
    return new Promise(async (resolve, reject) => {
      try {
        const firstRect = document.createElementNS(svgNS, 'rect');
        firstRect.setAttribute('x', scaledX);
        firstRect.setAttribute('y', scaledY);
        firstRect.setAttribute('width', scaledWidth);
        firstRect.setAttribute('height', scaledHeight);
        firstRect.setAttribute('stroke', '#ffd65bfc');
        firstRect.setAttribute('fill', '#ffffff');
        firstRect.setAttribute('fill-opacity', '1');
        firstRect.setAttribute('stroke-opacity', '1');
        firstRect.setAttribute('data-fullname', fullname);
        firstRect.setAttribute('data-content', content);
        firstRect.setAttribute('data-uniqueid', uniqueid);
        firstRect.setAttribute('data-format', format);
        firstRect.setAttribute('data-issigned',issigned);
        firstRect.setAttribute('data-roll', roll);
        firstRect.setAttribute('data-type', format);
        firstRect.setAttribute('data-token', token);
        firstRect.setAttribute('data-index',isOrder);
        firstRect.setAttribute('data-fgColor', '##FFFFFF');
        firstRect.setAttribute('data-bgColor', '#000000')
        firstRect.setAttribute('class', 'objectelement');
        const input = document.createElementNS(svgNS, 'foreignObject'); // Create the foreignObject
  input.setAttribute('x', scaledX - 1);
  input.setAttribute('y', scaledY - 5);
  input.setAttribute('width', scaledWidth);
  input.setAttribute('height', scaledHeight + 10);
  input.setAttribute('data-format',format);
  input.setAttribute('data-issigned',issigned);
  const htmlInput = document.createElement('input'); // Create the HTML input
  htmlInput.setAttribute('type', 'radio');
  htmlInput.setAttribute('name', targetid);
  htmlInput.setAttribute('data-group-id', targetid);
  htmlInput.style.width = '100%';
  htmlInput.style.height = '100%';
  switch (roll) {
    case 'false':
      htmlInput.setAttribute('disabled', 'disabled');
      break;
  }

  htmlInput.addEventListener('click', (event) => {
    const clickedInput = event.target;
    const groupContainerElement = clickedInput.closest('g[data-id]');
    if (groupContainerElement) {
        const inputsInGroup = groupContainerElement.querySelectorAll('foreignObject[data-format="radio"] input[type="radio"]');
        inputsInGroup.forEach(input => {
            input.checked = false; // Uncheck all inputs
            input.setAttribute('data-issigned','true');
            firstRect.setAttribute('data-issigned', 'true');
        });
        updateSignToPdf()
        clickedInput.checked = true; // Check the clicked input
    }
});

input.appendChild(firstRect);
input.appendChild(htmlInput); // Append the HTML input to the foreignObject

        resolve(input);
      } catch (error) {
        reject(error);
      }
    });
  }
  async function main() {
    try {
      const groupContainers = await createGroupContainer();
      const InputElements = await createinputElement();
     
      groupContainers.forEach((container) => {
        const groupId = container.getAttribute('id');
        const groupElementsId = InputElements.getAttribute('data-cid');
        const groupRule = container.dataset.rule
        const groupMax = container.dataset.max
        const groupMin = container.dataset.min
        const groupIsvalid = container.dataset.validation
        if (groupId === groupElementsId) {
          InputElements.setAttribute('data-rule', groupRule);
          InputElements.setAttribute('data-max', groupMax);
          InputElements.setAttribute('data-min', groupMin);
          InputElements.setAttribute('data-validation', groupIsvalid);
          container.appendChild(InputElements);
        }
  
        svg.appendChild(container); // Assuming svgElement is the ID of your SVG element
      });
    } catch (error) {
      console.error(error);
    }
  }
  
  main();

}
  async  function createGroupBoxCheckAndRadio(data ) {

    if (data) {

      const { x, y, width, height, format, content, fullname, targetid,
        groupischecked,groupmax,groupmin,grouprule,grroupvalidation,
        zoom, roll,issigned,totalheight,totalwidth,targetids,uniqueid,token,
         containerY,containerX,groupContainerid,rectgroupContainerid,isOrder } = data;
         const svgNS = 'http://www.w3.org/2000/svg';
         const initialZoomAdjusted = isMobile ? zoom / 2 : zoom;
         const scales = initialZoomAdjusted / 1;
         const numberOfItems = groupContainerid.length;
    
         const existingGroup = svg.querySelector(`g[data-id="${targetids}"]`);
         
         if (existingGroup) {
          svg.removeChild(existingGroup);
      }
   
      let border 
      if (format === 'checkbox') {
       border = 1
      }

      async function createGroupContainer() {
        return new Promise(async (resolve, reject) => {
          try {
            const groupContainers = [];
      
            for (let index = 0; index < totalheight.length; index++) {
              const groupContainerElement = document.createElementNS(svgNS, 'g');
      
              groupContainerElement.setAttribute('data-rule', grouprule[index]);
              groupContainerElement.setAttribute('data-max', groupmax[index]);
              groupContainerElement.setAttribute('data-min', groupmin[index]);
              groupContainerElement.setAttribute('data-validation', grroupvalidation[index]);
              groupContainerElement.setAttribute('class', 'previwe_user');
              groupContainerElement.setAttribute('data-displayname', fullname);
              groupContainerElement.setAttribute('data-id', groupContainerid[index]);
              groupContainerElement.setAttribute('id', groupContainerid[index]);
              groupContainerElement.setAttribute('data-isvalid','false');
              const rectContainer = document.createElementNS(svgNS, 'rect');
              const rectContainerStyle = {
                stroke: '#2463d1',
                fill: 'transparent',
                opacity: '0.75',
                rx: '1',
                ry: '1',
                x: containerX[index] * scales,
                y: containerY[index] * scales,
                width: totalwidth[index] * scales,
                height: totalheight[index] * scales,
                'stroke-width': '0.7746987951807229',
              };
      
              rectContainer.setAttribute('stroke-dasharray', '2.1362126245847177,4.2724252491694354');
              Object.keys(rectContainerStyle).forEach((key) => {
                rectContainer.setAttribute(key, rectContainerStyle[key]);
              });
      
            //  groupContainerElement.appendChild(rectContainer);
              groupContainers.push(groupContainerElement);
            }
      
            resolve(groupContainers);
          } catch (error) {
            reject(error);
          }
        });
      }

   
      
     
      async function createGroupElement() {
        return new Promise(async (resolve, reject) => {
          try {
            const groupElement = document.createElementNS(svgNS, 'g');
            groupElement.setAttribute('data-content', content);
            groupElement.setAttribute('data-id', targetid);
            groupElement.setAttribute('data-cid', rectgroupContainerid);
            const firstRect = document.createElementNS(svgNS, 'rect');
      
            const scaledX = x * scales;
            const scaledY = y * scales;
            const scaledWidth = width * scales;
            const scaledHeight = height * scales;
            const rectWidth = 10;
            const rectHeight = 10;
      
            firstRect.setAttribute('x', scaledX - 5);
            firstRect.setAttribute('y', scaledY - 8);
            firstRect.setAttribute('width', rectWidth);
            firstRect.setAttribute('height', rectHeight);
            firstRect.setAttribute('stroke', '#ffd65bfc');
            firstRect.setAttribute('fill', '#ffffff');
            firstRect.setAttribute('fill-opacity', '1');
            firstRect.setAttribute('stroke-opacity', '1');
            firstRect.setAttribute('stroke-width', '3');
            firstRect.setAttribute('rx', border);
            firstRect.setAttribute('ry', border);
            firstRect.setAttribute('data-content', content);
            firstRect.setAttribute('data-uniqueid', uniqueid);
            firstRect.setAttribute('data-format', format);
            firstRect.setAttribute('data-issigned',issigned);
            firstRect.setAttribute('data-roll', roll);
            firstRect.setAttribute('data-type', format);
            firstRect.setAttribute('data-token', token);
            firstRect.setAttribute('data-fullname', fullname);
            firstRect.setAttribute('class', 'objectelement');
            firstRect.setAttribute('draggable', 'true');
            firstRect.setAttribute('data-index',isOrder);
            firstRect.setAttribute('data-fgColor', '##FFFFFF');
            firstRect.setAttribute('data-bgColor', '#000000')
            firstRect.style.pointerEvents = "auto";

            const centerX = parseFloat(scaledX - 4) + scaledWidth / 2;
            const centerY = parseFloat(scaledY - 8) + scaledHeight / 2;
            const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            text.setAttribute('x', centerX);
            text.setAttribute('y', centerY);
            text.setAttribute('font-size', 12); // Use provided fontSize or default to 12
            text.setAttribute('text-anchor', 'middle');
            text.setAttribute('dominant-baseline', 'middle');
            text.setAttribute('fill', 'black'); // Use provided fontColor or default to black
            text.setAttribute('font-family', 'Arial, sans-serif');
          
            // Perform validation based on the rule
            text.textContent = 'X';
         
            if (roll === 'false') {
              firstRect.setAttribute('readOnly', 'true');
            } else {
              firstRect.addEventListener('mousedown', (evt) => handleCheckbox(evt, firstRect,
                groupElement, roll, scaledWidth, scaledHeight, scaledX, scaledY, format));
            }
            if ( issigned === 'true' ) {
              firstRect.setAttribute('opacity', '0');
              groupElement.appendChild(text);
             } else{
              groupElement.appendChild(firstRect);
             }
             
             
            
            resolve(groupElement);
          } catch (error) {
            reject(error);
          }
        });
      }

      async function main() {
        try {
          const groupContainers = await createGroupContainer();
          const groupElements = await createGroupElement();
         
          groupContainers.forEach((groupContainerElement) => {
            const groupId = groupContainerElement.getAttribute('id');
            const groupElementsId = groupElements.getAttribute('data-cid');
            const groupRule = groupContainerElement.dataset.rule
            const groupMax = groupContainerElement.dataset.max
            const groupMin = groupContainerElement.dataset.min
            const groupIsvalid = groupContainerElement.dataset.validation
            if (groupId === groupElementsId) {
              groupElements.setAttribute('data-rule', groupRule);
              groupElements.setAttribute('data-max', groupMax);
              groupElements.setAttribute('data-min', groupMin);
              groupElements.setAttribute('data-validation', groupIsvalid);
              groupContainerElement.appendChild(groupElements);
            }
      
            svg.appendChild(groupContainerElement); // Assuming svgElement is the ID of your SVG element
          });
        } catch (error) {
          console.error(error);
        }
      }
      
      main();
    }else{
      console.error(`Element with ID ${data} not found`);
    }
  
}

function calculateWidthBasedOnInput(inputValue, maxWidth) {
  const minLength = 30; // Some minimum width
  const additionalWidth = 1.15;
  const calculatedWidth = Math.max(minLength, inputValue.length * 10 / additionalWidth);
  return calculatedWidth;
}

const handleCheckbox = (evt, firstRect,
  groupElement, roll, scaledWidth, scaledHeight, scaledX, scaledY, format) => {
  evt.preventDefault();

  const targetRect = evt.target;
    targetRect.setAttribute('fill-opacity', '0');
  targetRect.setAttribute('stroke-opacity', '0');
  const groupContainerElement = groupElement.parentElement;
  const groupRule = groupContainerElement.dataset.rule
  const groupMax = groupContainerElement.dataset.max
  const groupMin = groupContainerElement.dataset.min
  const groupIsvalid = groupContainerElement.dataset.validation

  // Get information about the clicked checkbox
  const targetid = targetRect.id;
  const existingGroup = svg.querySelector(`g[data-id="${targetid}"]`);
         
  if (existingGroup) {
   svg.removeChild(existingGroup);
}
  const rectWidth = parseFloat(scaledWidth);
  const rectHeight = parseFloat(scaledHeight);
  const centerX = parseFloat(scaledX - 5) + rectWidth / 2;
  const centerY = parseFloat(scaledY - 8) + rectHeight / 2;
  const checkboxes = groupContainerElement.querySelectorAll('rect[data-format="checkbox"]');
  
  const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.dataset.checked === "true").length + 1;

  const validationCriteria = parseInt(groupIsvalid);
const minCheckboxCount = parseInt(groupMax);
const maxCheckboxCount = parseInt(groupMin);
  targetRect.setAttribute('data-issigned','true' );
  let isValid = false;
  switch (groupRule) {
      case 'least':
          isValid = checkedCount >= validationCriteria;
          
          break;
      case 'most':
          isValid = checkedCount <= validationCriteria;
          break;
      case 'exactly':
          isValid = checkedCount === validationCriteria;
          break;
      case 'range':
          isValid = checkedCount >= minCheckboxCount && checkedCount <= maxCheckboxCount;
          break;
      default:
          // Handle invalid rule
          break;
  }
  
  if (isValid) {
    groupElement.setAttribute('data-valid', 'true');
   
   isValidationSection();
} else {
  groupElement.setAttribute('data-valid', 'false');
}

  // Create a text element to display validation status
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  text.setAttribute('x', centerX);
  text.setAttribute('y', centerY);
  text.setAttribute('font-size', 12); // Use provided fontSize or default to 12
  text.setAttribute('text-anchor', 'middle');
  text.setAttribute('dominant-baseline', 'middle');
  text.setAttribute('fill', 'black'); // Use provided fontColor or default to black
  text.setAttribute('font-family', 'Arial, sans-serif');

  // Perform validation based on the rule
  text.textContent = 'X';

  // Append the text element to the group
  groupElement.appendChild(text);
  updateSignToPdf()
  // Update the appearance of the clicked checkbox
  
};

 //isValidationSection()
async function isValidationSection() {
  const finishButton = document.getElementById('finish-button');

  const elementsWithValidData = document.querySelectorAll('[data-valid]');

  let allElementsValid = false;

  // Loop through each element
  elementsWithValidData.forEach(element => {
      // Get the value of the data-valid attribute
      const isValid = element.getAttribute('data-valid') === 'true';

      // Check if the element is valid
      if (isValid) {
          allElementsValid = true;
          // Handle invalid element
        //  console.log('Element is valid:', element);
          // Set error state or perform other actions
      }
  });
if (finishButton) {
  finishButton.style.backgroundColor = allElementsValid ? '#2463d1' : '#2463d1';
}
  // Set the button background color based on overall validity
  
}
const handleClickObject = (evt) => {
  const target = evt.target;
  const group = target.closest('g');
  const rect = group.querySelector('rect');
  const targetid = rect.dataset.id;
  const order = rect.dataset.index;
  const content = rect.dataset.content;
  const email = rect.dataset.mail;
  const fullname = rect.dataset.fullname;
  const uniqueid = rect.dataset.uniqueid;
  const token = rect.dataset.token;
  const fontColor = rect.dataset.fontColor;
  const fontSize = rect.dataset.fontSize;
  const fontFormat = rect.dataset.fontFormat;
  const fontFamily = rect.dataset.fontFamily;
  const width = parseFloat(rect.getAttribute('width'));
  const height = parseFloat(rect.getAttribute('height'));
  const x = parseFloat(rect.getAttribute('x'));
  const y = parseFloat(rect.getAttribute('y'));
  const format = rect.dataset.format;
  
      switch (format) {
      case 'handwriteing':
        setSignaturePNG(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order);
       
      break;
      case 'hand_praaf':
      setSignatureParaafPNG(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order);

      break;
      case 'stemps':
      setSignatureStemsPNG(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order);

      break;
      case 'drawing':
       // setDrawing(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format)
       break;
       case 'calendar':
        case 'fullname':
        case 'email':
        case 'approve':
        case 'decline':
        createPdfWithTextAndSvg(
        targetid,group,rect,width,height,x,y,content,
        fontColor,fontSize,fontFormat,fontFamily,order
        );
        break;
       case 'dropdown':
        const select = group.querySelector('select');
        const text = group.querySelector('text');
        createSelectOption( group,rect,text,width,height,x,y,content,select,order);
      break;
      case 'attachment':
        createAttachment( group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order);
      break;
      default:
      
  }
  updateSignToPdf()
 
 }


 async function createPdfWithTextAndSvg(
  targetid,
  group,
  rect,
  width,
  height,
  x,
  y,
  signContent,
  fontColor,
  fontSize,
  fontFormat,
  fontFamily,
  order
) {
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  // Calculate center position
  var rectWidth = parseFloat(width);
  var rectHeight = parseFloat(height);
  var centerX = parseFloat(x) + rectWidth / 2;
  var centerY = parseFloat(y) + rectHeight / 2;

  rect.setAttribute('opacity', '0');
  rect.setAttribute('data-issigned','true' );
  // Set font properties
  text.setAttribute('x', centerX);
  text.setAttribute('y', centerY);
  text.setAttribute('font-size', fontSize || 11); // Use provided fontSize or default to 11
  text.setAttribute('text-anchor', 'middle');
  text.setAttribute('dominant-baseline', 'middle');
  text.setAttribute('fill', fontColor || 'black'); // Use provided fontColor or default to black
  text.setAttribute('font-family', fontFamily || 'Arial, sans-serif'); // Use provided fontFamily or default to Arial
  if (fontFormat) {
    const fontFormatValues = fontFormat.toLowerCase().split(' ');

    text.style.fontStyle = fontFormatValues.includes('italic') ? 'italic' : 'normal';
    text.style.textDecoration = fontFormatValues.includes('underline') ? 'underline' : 'none';
    text.style.fontWeight = fontFormatValues.includes('bold') ? 'bold' : 'normal';

    // Add more conditions for other font formatting options as needed
  }
  // Apply additional font properties based on your requirements (e.g., font-weight, font-style)

  text.textContent = `${signContent}`;
  group.appendChild(text);
  updateSignToPdf()
}

 async function    createSelectOption(
  group,rect,text,width,height,x,y,content,select,order
  ){
    rect.setAttribute('data-issigned','true' );
   
    select.addEventListener('change', function () {
      // Update rect content
      rect.setAttribute('data-content', this.value);
      const selectedContent = this.options[this.selectedIndex].textContent;
      
      text.textContent = `${selectedContent}`;
     
  });
  }
  
  function hexToRgb(hex) {
    // Remove the leading # if present
    hex = hex.replace(/^#/, '');

    // Parse the hex values
    const bigint = parseInt(hex, 16);

    // Extract RGB components
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return as RGB string
    return `rgb(${r}, ${g}, ${b})`;
}
  const handleFulldata= async () => {
    const pdfContainer = document.querySelector('svg#previwe-container');
    const rectElement = pdfContainer.querySelectorAll('[data-issigned]');
     const unique_id = uuid
    const uuids = signid
   
    if (rectElement.length > 0) { 
      const Elementobj = {};
      
      rectElement.forEach(item => {
          const width = parseFloat(item.getAttribute('width'));
          const height = parseFloat(item.getAttribute('height'));
          const x = parseFloat(item.getAttribute('x'));
          const y = parseFloat(item.getAttribute('y'));
          const format = item.dataset.format;
          const signContent = item.dataset.content;
          const fontColor = item.dataset.fontColor;
          const fontSize = item.dataset.fontSize;
          const fontFormat = item.dataset.fontFormat;
          const fontFamily = item.dataset.fontFamily;
          const page = item.dataset.page;
          const isSigned = item.dataset.issigned;
          const uniqueid = item.dataset.uniqueid;
          const fgColor = item.dataset.fgColor;
          const bgColor = item.dataset.bgColor;
          const rgbFontColor = fontColor.startsWith('#') ? hexToRgb(fontColor) : fontColor;
          if (!Elementobj[uniqueid]) {
              Elementobj[uniqueid] = {
                  width: [],
                  height: [],
                  format: [],
                  x: [],
                  y: [],
                  signContent: [],
                  fontColor: [],
                  fontSize: [],
                  fontFormat: [],
                  fontFamily: [],
                  page: [],
                  isigned: [],
                  zoom: scale, // Assuming scale is defined elsewhere
                  uniqueid: uniqueid,
                  fgColor:[],
                  bgColor:[],
                  uuid: uuids,
                  doc_name: doc_name
              };
          }

          // Push values into arrays
          Elementobj[uniqueid].width.push(width);
          Elementobj[uniqueid].height.push(height);
          Elementobj[uniqueid].x.push(x);
          Elementobj[uniqueid].y.push(y);
          Elementobj[uniqueid].format.push(format);
          Elementobj[uniqueid].signContent.push(signContent);
          Elementobj[uniqueid].page.push(page);
          Elementobj[uniqueid].fontColor.push(fontColor);
          Elementobj[uniqueid].fontSize.push(rgbFontColor);
          Elementobj[uniqueid].fontFormat.push(fontFormat);
          Elementobj[uniqueid].fontFamily.push(fontFamily);
          Elementobj[uniqueid].isigned.push(isSigned);
          Elementobj[uniqueid].fgColor.push(fgColor);
          Elementobj[uniqueid].bgColor.push(bgColor);
      });
      const fullNAmes = clientName
      const payload = {
          uuid: uuids,
          name:fullNAmes,
          item: Elementobj,
          doc_name: doc_name
         }
      try {
          const response = await fetch(`/api/sign/document/${unique_id}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(payload)
          });
          const result = await response.json();
          
      } catch (error) {
          console.error('Error sending data:', error);
      }
      console.log(Elementobj);
  }

  }
  
  async function updateSignToPdf() {
  var reciverData = document.querySelectorAll('rect');
  var UsersDimensions = {};
  const  jsonbase = {};
 
  if (updatedata && updatedata.length > 0) {
    const jsons = updatedata.map(item => item.jsondata);
    const UpdateJson  = JSON.parse(jsons);
    
    if (UpdateJson) {
    
      for (let i = 0; i < reciverData.length; i++) {
        const item = reciverData[i];
        const signContent = item.dataset.content;
        const status = item.dataset.issigned;
        const fullname = item.dataset.fullname;
        const uniqueid = item.dataset.uniqueid;
        const format = item.dataset.format;
        const token = item.dataset.token;
        const order = item.dataset.index;
        
        if (!jsonbase[format]) {
          jsonbase[format] = {
              signContent: [],
              isSigned: []
          };
      }
      
      jsonbase[format].signContent.push(signContent);
      jsonbase[format].isSigned.push(status);

     
   
        if (!UsersDimensions[fullname]) {
          UsersDimensions[fullname] = {
            send_status:'sending',
            user_id:uniqueid,
            fullname: fullname,
            token:token,
            format:[],
            signContent:[],
            status:[],
          };
        }
        UsersDimensions[fullname].signContent.push(signContent);
        UsersDimensions[fullname].status.push(status);
        UsersDimensions[fullname].format.push(format);
      
       
  
      }

    
      const usersArray = Object.values(UsersDimensions);
     

      for (const format in jsonbase) {
        const signContent = jsonbase[format].signContent;
        const isSigned = jsonbase[format].isSigned;
    
        UpdateJson.forEach(obj => {
            if (obj[format]) {
                // Assuming signContent and isSigned arrays should replace the existing ones
                obj[format].signContent = signContent;
                obj[format].isSigned = isSigned;
            }
        });
    }
      // Convert UsersDimensions object to an array
      
      
      const payload = {
        users: usersArray,
        UsersJson:UpdateJson
      };
      //console.log(payload,'payload')
      try {
        const response = await fetch(`/api/clint/send/${uuid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const result = await response.json();
       
    } catch (error) {
        console.error('Error sending data:', error);
    }
    }
  
  }
 


  
}


async function PostParaafPNG(group,rect,width,height,x,y,newcontent,email,fullname,uniqueid,token,format,order){
  const UsersJson  = JSON.parse(jsondata);
  const formObject = {
    signatureData: newcontent,
    format:format,
    userID: uniqueid,
    fullNames:fullname,
    token: token,
    email:email,
    UsersJson:UsersJson,
    order:order
  };
  
  const response = await fetch('/api/client/paraaf', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(formObject),
});
if (response.ok) {
  const data = await response.json();
  
  setSignatureParaafPNG(group,rect,width,height,x,y,data,email,fullname,uniqueid,token,format,order);
  dispatch(getSigndata(uuid));
} else {
  console.error('Failed to send signature data');
  // Handle error
}

 }

 async function PostattachmentPNG(group,rect,width,height,x,y,newcontent,email,fullname,uniqueid,token,format,order){

  const formData = new FormData();
  formData.append('attachment', attachmentData);
  formData.append('format', format);
  formData.append('userID', uniqueid);
  formData.append('fullNames', fullname);
  formData.append('token', token);
  formData.append('email', email);
  formData.append('order', order);

  const response = await fetch('/api/client/attachment/post', {
    method: 'POST',
    body: formData,
});
if (response.ok) {
  const data = await response.json();

  createAttachment(group,rect,width,height,x,y,data,email,fullname,uniqueid,token,format,order);
  dispatch(getSigndata(uuid));
} else {
  console.error('Failed to send Stemp data');
  // Handle error
}

 }
  async function PoststempsPNG(group,rect,width,height,x,y,newcontent,email,fullname,uniqueid,token,format,order){
    const UsersJson  = JSON.parse(jsondata);
    
  const formData = new FormData();
  formData.append('stemp', stempData);
  formData.append('format', format);
  formData.append('userID', uniqueid);
  formData.append('fullNames', fullname);
  formData.append('token', token);
  formData.append('email', email);
  formData.append('order', order);
  const response = await fetch('/api/client/stemp/post', {
    method: 'POST',
    body: formData,
});
if (response.ok) {
  const data = await response.json();
 
  setSignatureStemsPNG(group,rect,width,height,x,y,data,email,fullname,uniqueid,token,format,order);
  dispatch(getSigndata(uuid));
} else {
  console.error('Failed to send Stemp data');
  // Handle error
}

 }

 async function PostSignaturePNG(group,rect,width,height,x,y,newcontent,email,fullname,uniqueid,token,format,order){
  const UsersJson  = JSON.parse(jsondata);
  const formObject = {
    signatureData: newcontent,
    format:format,
    userID: uniqueid,
    fullNames:fullname,
    token: token,
    email:email,
    UsersJson:UsersJson,
    order:order
  };
  
  const response = await fetch('/api/client/hanwriting', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(formObject),
});
if (response.ok) {
  const data = await response.json();
 
  setSignaturePNG(group,rect,width,height,x,y,data,email,fullname,uniqueid,token,format,order);
  dispatch(getSigndata(uuid));
} else {
  console.error('Failed to send signature data');
  // Handle error
}

 }
 async function createAttachment(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order){
  const validFormats = ['⇩'];
  
  if (!validFormats.includes(content)) {
   
    rect.setAttribute('data-issigned','true' );
    rect.setAttribute('opacity', '0');
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttribute('width', width);
    imageElement.setAttribute('height', height);
    imageElement.setAttribute('x', x);
    imageElement.setAttribute('y', y);
    imageElement.setAttribute('href', content);
    imageElement.setAttribute('class','reactelement');
    group.appendChild(imageElement);
    
    
  } 

  if (validFormats.includes(content)) {
    
    tog_centerattach(true)
    const newSignatureObj = {
      group,
      rect,
      width,
      height,
      x,
      y,
      content,
      email,
      fullname,
      uniqueid,
      token,
      format
      ,order
    };
    setSignatureStempObje(newSignatureObj);
   
  }
 
}
 async function setSignatureStemsPNG(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order){
  const validFormats = ['stemp'];
  
  if (!validFormats.includes(content)) { 
    rect.setAttribute('data-issigned','true' );
    rect.setAttribute('opacity', '0');
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttribute('width', width);
    imageElement.setAttribute('height', height);
    imageElement.setAttribute('x', x);
    imageElement.setAttribute('y', y);
    imageElement.setAttribute('href', content);
    imageElement.setAttribute('class','reactelement');
    group.appendChild(imageElement);
   
    
  } 

  if (validFormats.includes(content)) {
    
    tog_centerstemps(true)
    const newSignatureObj = {
      group,
      rect,
      width,
      height,
      x,
      y,
      content,
      email,
      fullname,
      uniqueid,
      token,
      format
      ,order
    };
    setSignatureStempObje(newSignatureObj);
   
  }
 
}
 async function setSignatureParaafPNG(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order){
  const validFormats = ['paraaf'];
  if (!validFormats.includes(content)) { 
    rect.setAttribute('data-issigned','true' );
    rect.setAttribute('opacity', '0');
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttribute('width', width);
    imageElement.setAttribute('height', height);
    imageElement.setAttribute('x', x);
    imageElement.setAttribute('y', y);
    imageElement.setAttribute('href', content);
    imageElement.setAttribute('class','reactelement');
    group.appendChild(imageElement);
    
    
  } 

  if (validFormats.includes(content)) {
    tog_centerparaf(true)
    const newSignatureObj = {
      group,
      rect,
      width,
      height,
      x,
      y,
      content,
      email,
      fullname,
      uniqueid,
      token,
      format,
      order
    };
    setSignatureParaafObje(newSignatureObj);
   
  }
 
}

 async function setSignaturePNG(group,rect,width,height,x,y,content,email,fullname,uniqueid,token,format,order){
 
  const validFormats = ['image'];
  if (!validFormats.includes(content)) { 
    rect.setAttribute('data-issigned','true' );
    rect.setAttribute('opacity', '0');
    
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttribute('width', width);
    imageElement.setAttribute('height', height);
    imageElement.setAttribute('x', x);
    imageElement.setAttribute('y', y);
    imageElement.setAttribute('href', content);
    imageElement.setAttribute('class','reactelement');
    group.appendChild(imageElement);
    setmodal_center(false);
    
  } 

  if (validFormats.includes(content)) {
    setmodal_center(true)

    const newSignatureObj = {
      group,
      rect,
      width,
      height,
      x,
      y,
      content,
      email,
      fullname,
      uniqueid,
      token,
      format,
      order
    };
    setSignatureObje(newSignatureObj);
   
  }
 
}
function calculateResult(label) {
  var targetElement = document.querySelectorAll(`[data-result*="${label}"]`)
  var result = 0 
  var resultElements = document.querySelectorAll('[data-result]');
  resultElements.forEach(function(element) {
    // Get expression from data-result attribute
    var expression = element.getAttribute('data-result');
    var matches = expression.match(/\[([^\]]+)\]|(\+|-|\*|\/)/g);
    var valuesAndOperators = matches.map(match => {
      if (match.startsWith("[")) {
          return match.slice(1, -1); // Remove the brackets
      } else {
          return match; // Return the operator as it is
      }
  })
  var numericValues = [];

  for (var i = 0; i < valuesAndOperators.length; i++) {
    var element = valuesAndOperators[i];
    
    if (!['+', '-', '*', '/'].includes(element)) {
      var queriedElement = document.querySelector(`[data-label="${element}"]`);
      
        if (queriedElement && queriedElement.hasAttribute('data-content')) {
          var dataContent = queriedElement.getAttribute('data-content');
          var numericValue = parseFloat(dataContent);
          
          numericValues.push(numericValue);
        } else {
            console.error("Data for label " + element + " not found.");
        }
        
    }  else if (['+', '-', '*', '/'].includes(element)) {
        numericValues.push(element); // Push operators directly
    } else {
      console.error("Invalid element:", element);
    }
}
result = numericValues[0];

for (var j = 1; j < numericValues.length; j++) {
    var nextValue = numericValues[j];
    
    if (!isNaN(nextValue)) {
        switch (numericValues[j - 1]) { // Previous value in numericValues array is the operator
         
          case '+':
                result += nextValue;
                break;
            case '-':
                result -= nextValue;
                break;
            case '*':
                result *= nextValue;
                break;
            case '/':
                if (nextValue !== 0) {
                    result /= nextValue;
                } else {
                    console.error("Division by zero error!");
                    result = undefined;
                }
                break;
            default:
                console.error("Invalid operator:", numericValues[j - 1]);
                result = undefined;
        }
    }
}

    // Extract box labels from expression
   
 
});



}

calculateResult()
async function createFormulaElements(data){
  return new Promise((resolve, reject) => {
  const { x, y, width, height, format,content, fullname, targetid, 
    fontColor, fontSize, fontFamily, zoom,fontFormat,roll,issigned,
    messeges,sign_id,email,uniqueid,token,isOrder
    } = data;
  var result = 0 
  const existingGroup = svg.querySelector(`g[data-id="${targetid}"]`);
  if (existingGroup) {
      svg.removeChild(existingGroup);
  }
  if (!content || content.trim() === "" || content === "formula") {
    content = "0"; // Set signContent to "0" if it's undefined, null, or empty
}

  if (content != 0) {
    var matches = content.match(/\[([^\]]+)\]|(\+|-|\*|\/)/g);
    
  // Process matches to extract values without brackets
  var valuesAndOperators = matches.map(match => {
      if (match.startsWith("[")) {
          return match.slice(1, -1); // Remove the brackets
      } else {
          return match; // Return the operator as it is
      }
  })
  var numericValues = [];

  for (var i = 0; i < valuesAndOperators.length; i++) {
    var element = valuesAndOperators[i];
   
    if (!['+', '-', '*', '/'].includes(element)) {
      var queriedElement = document.querySelector(`[data-label="${element}"]`);
     
        if (queriedElement && queriedElement.hasAttribute('data-content')) {
          var dataContent = queriedElement.getAttribute('data-content');
          var numericValue = parseFloat(dataContent);
          
          numericValues.push(numericValue);
        } else {
            console.error("Data for label " + element + " not found.");
        }
        
    }  else if (['+', '-', '*', '/'].includes(element)) {
        numericValues.push(element); // Push operators directly
    } else {
      console.error("Invalid element:", element);
    }
}

 result = numericValues[0];

for (var j = 1; j < numericValues.length; j++) {
    var nextValue = numericValues[j];
    
    if (!isNaN(nextValue)) {
        switch (numericValues[j - 1]) { // Previous value in numericValues array is the operator
         
          case '+':
                result += nextValue;
                break;
            case '-':
                result -= nextValue;
                break;
            case '*':
                result *= nextValue;
                break;
            case '/':
                if (nextValue !== 0) {
                    result /= nextValue;
                } else {
                    console.error("Division by zero error!");
                    result = undefined;
                }
                break;
            default:
                console.error("Invalid operator:", numericValues[j - 1]);
                result = undefined;
        }
    }
}

  }
  var roundedNumber = parseFloat(result.toFixed(1));
  var Newwidth = roundedNumber.toString().length * 10;
  
  const svgns = 'http://www.w3.org/2000/svg';
  const initialZoomAdjusted = isMobile ? zoom / 2 : zoom;
  const scales = initialZoomAdjusted / 1;
  const scaledX = x * scales;
  const scaledY = y * scales;
  const scaledWidth = width * scales;
  const scaledHeight = height * scales;
  const centerX = scaledX + Newwidth / 2;
  const centerY = scaledY + height / 2;
  // Create dropdown rectangle
  const dropdownRect = document.createElementNS(svgns, 'rect'); 
  dropdownRect.setAttribute('stroke', 'red');
  dropdownRect.setAttribute('fill', 'white');
  dropdownRect.setAttribute('opacity', '0.75');
  dropdownRect.setAttribute('rx', '5');
  dropdownRect.setAttribute('ry', '5');
  dropdownRect.setAttribute('x', scaledX);
  dropdownRect.setAttribute('y', scaledY);
  dropdownRect.setAttribute('width', scaledWidth);
  dropdownRect.setAttribute('height', scaledHeight);
  dropdownRect.setAttribute('data-widths', scaledWidth);
  dropdownRect.setAttribute('data-heights', scaledHeight);
  dropdownRect.setAttribute('data-content', roundedNumber);
  dropdownRect.setAttribute('data-result', content);
  dropdownRect.setAttribute('data-recipien', fullname);
  dropdownRect.setAttribute('data-fullname', fullname);
  dropdownRect.classList.add('calculated-result', 'reactelement');
  dropdownRect.setAttribute('strokeWidth', '0.7746987951807229');
  dropdownRect.setAttribute('data-id', targetid);
  dropdownRect.setAttribute('data-msg', messeges);
  dropdownRect.setAttribute('data-mail', email);
  dropdownRect.setAttribute('data-format', format);
  dropdownRect.setAttribute('data-token', token);
  dropdownRect.setAttribute('data-uniqueid', uniqueid);
  dropdownRect.setAttribute('data-issigned','true' );
  dropdownRect.setAttribute('data-fontColor', fontColor);
  dropdownRect.setAttribute('data-fontSize', fontSize);
  dropdownRect.setAttribute('data-fontFamily', fontFamily);
  dropdownRect.setAttribute('data-fontFormat', fontFormat);
  dropdownRect.setAttribute('data-index',isOrder);
  dropdownRect.setAttribute('data-fgColor', '##FFFFFF');
dropdownRect.setAttribute('data-bgColor', '#000000')
  const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  group.setAttribute('data-view-name', 'MultiSelectMarquee');
  group.setAttribute('data-view-id', 'c262');
  group.setAttribute('role', 'list');
  group.setAttribute('style', 'cursor: move');
  group.setAttribute('class', 'previwe_user');
  group.setAttribute('data-displayname', fullname);
  group.setAttribute('data-id',targetid);
  group.addEventListener('mousedown', handleClickObject);
  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  text.setAttribute('x', centerX);
  text.setAttribute('y', centerY);
  text.setAttribute('fill', fontColor);
  text.setAttribute('font-size', fontSize);
  text.setAttribute('text-anchor', 'middle');
  text.setAttribute('dominant-baseline', 'middle');
  text.textContent = `${roundedNumber}`;
  group.appendChild(dropdownRect); 
  group.appendChild(text);
  svg.appendChild(group);
  resolve(svg);
});
  // svg.appendChild(group);
}
async function  createDropDownElements(data) {
  return new Promise((resolve, reject) => {
  const { x, y, width, height,messeges,isOrder, format,dropdownoption,zoom,dropdownvalue, content, fullname, targetid, fontColor, fontSize, fontFamily, fontFormat, token, uniqueid, email, optionnumtype, roll,issigned,bgcolor,fgcolo } = data;
  if (dropdownoption === 'null' || dropdownoption === null) {
    console.error("dropdownoption is null or 'null'");
    reject("Invalid dropdownoption provided");
    return;
}
 
  const existingGroup = svg.querySelector(`g[data-id="${targetid}"]`);
  if (existingGroup) {
      svg.removeChild(existingGroup);
  }

  const selectV = dropdownoption.map(option => option.split(","));

const svgns = 'http://www.w3.org/2000/svg';
const initialZoomAdjusted = isMobile ? zoom / 2 : zoom;
const scales = initialZoomAdjusted / 1;
const scaledX = x * scales;
const scaledY = y * scales;
const scaledWidth = width * scales;
const scaledHeight = height * scales;
const centerX = scaledX + width / 2;
const centerY = scaledY + height / 2;
// Create dropdown rectangle
const dropdownRect = document.createElementNS(svgns, 'rect'); 
dropdownRect.setAttribute('stroke', '#ffd65bfc');
dropdownRect.setAttribute('fill', '#ffd65bfc');
dropdownRect.setAttribute('opacity', '0.75');
dropdownRect.setAttribute('rx', '5');
dropdownRect.setAttribute('ry', '5');
dropdownRect.setAttribute('x', scaledX);
dropdownRect.setAttribute('y', scaledY);
dropdownRect.setAttribute('width', scaledWidth);
dropdownRect.setAttribute('height', scaledHeight);
dropdownRect.setAttribute('data-widths', scaledWidth);
dropdownRect.setAttribute('data-heights', scaledHeight);
dropdownRect.setAttribute('data-content', content);
dropdownRect.setAttribute('data-recipien', fullname);
dropdownRect.setAttribute('class', 'reactelement');
dropdownRect.setAttribute('strokeWidth', '0.7746987951807229');
dropdownRect.setAttribute('data-id', targetid);
dropdownRect.setAttribute('data-msg', messeges);
dropdownRect.setAttribute('data-mail', email);
dropdownRect.setAttribute('data-format', format);
dropdownRect.setAttribute('data-fontColor', fontColor);
dropdownRect.setAttribute('data-fontSize', fontSize);
dropdownRect.setAttribute('data-fontFamily', fontFamily);
dropdownRect.setAttribute('data-fontFormat', fontFormat);
dropdownRect.setAttribute('data-index',isOrder);
dropdownRect.setAttribute('data-issigned',issigned);
dropdownRect.setAttribute('data-fgColor', '##FFFFFF');
dropdownRect.setAttribute('data-bgColor', '#000000')
switch (roll) {
  case 'false':
    dropdownRect.setAttribute('readOnly', 'true');
    // dropdownRect.setAttribute('data-valid',false);
    break;
  default:
    dropdownRect.setAttribute('data-valid',true);
    break;
}
const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
foreignObject.setAttribute('x', scaledX);
foreignObject.setAttribute('y', scaledY);
foreignObject.setAttribute('width', scaledWidth);
foreignObject.setAttribute('height', scaledHeight);
foreignObject.setAttribute('opacity', '0');
foreignObject.setAttribute('data-error', 'error');
const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
group.setAttribute('data-view-name', 'MultiSelectMarquee');
group.setAttribute('data-view-id', 'c262');
group.setAttribute('role', 'list');
group.setAttribute('style', 'cursor: move');
group.setAttribute('class', 'previwe_user');
group.setAttribute('data-displayname', fullname);
group.setAttribute('data-id',targetid);
group.addEventListener('mousedown', handleClickObject);
const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
text.setAttribute('x', centerX);
text.setAttribute('y', centerY);
text.setAttribute('fill', fontColor);
text.setAttribute('font-size', fontSize);
text.setAttribute('text-anchor', 'middle');
text.setAttribute('dominant-baseline', 'middle');
text.textContent = 'select ▼'

const container = document.createElement('div');
container.classList.add('container');
const select = document.createElement('select');
select.setAttribute('name', 'fruit');
select.setAttribute('id', targetid);



selectV.forEach(optionArray => {
  optionArray.forEach(optionValue => {
      const option = document.createElement('option');
      option.setAttribute('value', optionValue.trim().toLowerCase());
      option.textContent = `${optionValue.trim()}`;

      select.appendChild(option);

  });
});
container.appendChild(select);

group.appendChild(dropdownRect); 

foreignObject.appendChild(container);
group.appendChild(text);  
group.appendChild(foreignObject);
svg.appendChild(group);
resolve(svg);
  });
   //
}

async function  createQRCodeElement(data){

  const { x, y, width, height,left, top, w, h, format, content, fullname, targetid, fontColor, fontSize,
     fontFamily, fontFormat, token, uniqueid, email, 
     optionnumtype, roll,issigned,bgcolor,fgcolo,messeges,zoom ,isOrder} = data;

     const existingGroup = svg.querySelector(`g[data-id="${targetid}"]`);
     if (existingGroup) {
         svg.removeChild(existingGroup);
     }
  for (let i = 0; i < left.length; i++) {
    const initialZoomAdjusted = isMobile ? zoom / 2 : zoom;
    const scales = initialZoomAdjusted / 1;

    const scaledX = left[i] * scales;
    const scaledY = top[i] * scales;
    const scaledWidth = w[i] * scales;
    const scaledHeight = h[i] * scales;
    
    const cloneRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    cloneRect.setAttribute('stroke', '#ffffff');
    cloneRect.setAttribute('fill', '#ffffff');
            cloneRect.setAttribute('opacity', '0');
            cloneRect.setAttribute('rx', '5');
            cloneRect.setAttribute('ry', '5');
            cloneRect.setAttribute('x',scaledX );
            cloneRect.setAttribute('y', scaledY);
            cloneRect.setAttribute('width', scaledWidth);
            cloneRect.setAttribute('height', scaledHeight);
            cloneRect.setAttribute('data-widths', scaledWidth);
            cloneRect.setAttribute('data-heights', scaledHeight);
            cloneRect.setAttribute('data-content', content);
            cloneRect.setAttribute('data-recipien', fullname);
            cloneRect.setAttribute('data-format', format);
            cloneRect.setAttribute('data-msg', messeges);
            cloneRect.setAttribute('data-mail', email);
            cloneRect.setAttribute('data-fgColor', fgcolo[i]);
            cloneRect.setAttribute('data-bgColor', bgcolor[i]);
            cloneRect.setAttribute('data-id',targetid);
            cloneRect.setAttribute('data-max', '1000000000');
            cloneRect.setAttribute('data-min', '-10000');
            cloneRect.setAttribute('data-roll', roll);
            cloneRect.setAttribute('data-type', format);
            cloneRect.setAttribute('data-fontColor', fontColor);
            cloneRect.setAttribute('data-fontSize', fontSize);
            cloneRect.setAttribute('data-fontFamily', fontFamily);
            cloneRect.setAttribute('data-fontFormat', fontFormat);
            cloneRect.setAttribute('data-company', 'company');
            cloneRect.setAttribute('data-pdftile', 'pdftile');
            cloneRect.setAttribute('data-textarea', 'content');
            cloneRect.setAttribute('recipienid', uniqueid);
            cloneRect.setAttribute('data-page', data.page);
            cloneRect.setAttribute('data-index',isOrder);
            cloneRect.setAttribute('data-numbers', 'en-US');
            cloneRect.setAttribute('strokeWidth', '0.7746987951807229');
            cloneRect.setAttribute('class','reactelement');
            cloneRect.setAttribute('data-type','qrcode');
            cloneRect.setAttribute('data-valid',true);
            cloneRect.setAttribute('data-issigned',true);
            const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            group.setAttribute('data-view-name', 'MultiSelectMarquee');
            group.setAttribute('data-view-id', 'c262');
            group.setAttribute('role', 'list');
            group.setAttribute('style', 'cursor: move');
            group.setAttribute('class', 'previwe_user');
            group.setAttribute('data-displayname', fullname);
            group.setAttribute('data-id',targetid);
           
            const qrCode = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            qrCode.setAttribute('x', scaledX);
            qrCode.setAttribute('y', scaledY);
            qrCode.setAttribute('width', scaledWidth); // Set the width and height as needed
            qrCode.setAttribute('height', scaledHeight);
            
            const qrCodeValue = content; // The content you want to encode in the QR code
          
      
            const qrCodeComponent = (
              <QRCodeSVG
                value={qrCodeValue}
                size={scaledHeight}
                fgColor={fgcolo[i]}
                bgColor={bgcolor[i]}
              />
            );
         const root = createRoot(qrCode);
         root.render(qrCodeComponent);
            group.appendChild(qrCode);
            group.appendChild(cloneRect);
            svg.appendChild(group);
  }
  
 }

 
 async function  createSignatureElements(data){
 
  const { x, y, width, height, format, content, 
    fullname, targetid, fontColor, fontSize, fontFamily,
     fontFormat,token, uniqueid, email,isOrder, optionnumtype, roll,issigned,labelValeu,messeges,zoom } = data;
     const initialZoomAdjusted = isMobile ? zoom / 2 : zoom;
     const scales = initialZoomAdjusted / 1;
     const scaledX = x * scales;
     const scaledY = y * scales;
     const scaledWidth = width * scales;
     const scaledHeight = height * scales;
 const existingGroup = svg.querySelector(`g[data-id="${targetid}"]`);
 if (existingGroup) {
     svg.removeChild(existingGroup);
 }

 const cloneRect = await createRectangles(data);
 const text = await createText()
 const inputElement = await createInput();
 const isSignedImg =  await createImg()
 const isSignedText =  await createTextIsigned()
 const group = await createGroups();
 //group.appendChild(text)
 if (['attachment'].includes(format)) {
  group.appendChild(text)
}
 group.appendChild(cloneRect);
if (['handwriteing', 'hand_praaf', 'stemps', 'drawing','attachment'].includes(format) && issigned === 'true' ) {
 group.appendChild(isSignedImg);
 
}
if (['calendar', 'fullname', 'email', 'company','title', 'textarea', 'approve', 'decline','number'].includes(format) && issigned === 'true' ) {

  group.appendChild(isSignedText);
 }
 if (['title', 'textarea', 'company', 'number', 'payment'].includes(format)) {
   group.appendChild(inputElement);
}  
svg.appendChild(group); 

async function createText() {
  return new Promise((resolve, reject) => {
    const attachment = '⇩'
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

    const centerX = scaledX + width / 2;
    const centerY = scaledY + height / 2;
    text.setAttribute('x', centerX);
    text.setAttribute('y', centerY);
    text.setAttribute('font-size', 14);
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('dominant-baseline', 'middle');
    const lines = attachment.split('\n');
    for (let i = 0; i < lines.length; i++) {
      const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.setAttribute('x', centerX);
      tspan.textContent = lines[i];
      text.appendChild(tspan);
    }


  resolve(text);
});
      
  }

  async function createTextIsigned( ) {
    return new Promise((resolve, reject) => {
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    const centerX = parseFloat(x) + scaledWidth / 2;
    const centerY = parseFloat(y) + scaledHeight / 2;

    // Set font properties
    text.setAttribute('x', centerX);
    text.setAttribute('y', centerY);
    text.setAttribute('font-size', fontSize || 11); // Use provided fontSize or default to 11
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('dominant-baseline', 'middle');
    text.setAttribute('fill', fontColor || 'black'); // Use provided fontColor or default to black
    text.setAttribute('font-family', fontFamily || 'Arial, sans-serif'); // Use provided fontFamily or default to Arial
    if (fontFormat) {
      const fontFormatValues = fontFormat.toLowerCase().split(' ');
  
      text.style.fontStyle = fontFormatValues.includes('italic') ? 'italic' : 'normal';
      text.style.textDecoration = fontFormatValues.includes('underline') ? 'underline' : 'none';
      text.style.fontWeight = fontFormatValues.includes('bold') ? 'bold' : 'normal';
  
      // Add more conditions for other font formatting options as needed
    }
    // Apply additional font properties based on your requirements (e.g., font-weight, font-style)
  
    text.textContent = `${content}`;
    resolve(text);
  });
  }
async function createImg() {
  return new Promise((resolve, reject) => {
    const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    imageElement.setAttribute('width', width);
    imageElement.setAttribute('height', height);
    imageElement.setAttribute('x', x);
    imageElement.setAttribute('y', y);
    imageElement.setAttribute('href', content);
    imageElement.setAttribute('class', 'reactelement');
    resolve(imageElement);
  });
}


async function createGroups() {
  return new Promise((resolve, reject) => {
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute('data-view-name', 'MultiSelectMarquee');
    group.setAttribute('data-view-id', 'c262');
    group.setAttribute('role', 'list');
    group.setAttribute('style', 'cursor: move');
    group.setAttribute('class', 'previwe_user');
    group.setAttribute('data-class', 'previwe_user');
    group.setAttribute('data-displayname', fullname);
    group.setAttribute('data-id', targetid);
    group.addEventListener('mousedown', handleClickObject);
    resolve(group);
  });
}

async function createInput() {
  return new Promise((resolve, reject) => {
    const inputElement = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
    inputElement.setAttribute('x', scaledX);
    inputElement.setAttribute('y', scaledY);
    inputElement.setAttribute('data-input', 'input');
    inputElement.setAttribute('width', format === 'payment' ? scaledWidth + 10 : scaledWidth);
    inputElement.setAttribute('height', scaledHeight);
    if (data.format === 'payment') {
      inputElement.setAttribute('width', scaledWidth + 10);
    } else {
      inputElement.setAttribute('width', scaledWidth);
    }
    inputElement.setAttribute('height', scaledHeight);

    const input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('value', content); // Set the initial value
    input.style.width = '100%';
    input.style.height = '100%';
    input.style.border = '1px solid red';
    switch (roll) {
      case 'false':
        input.setAttribute('readOnly', 'true');
        break;
      default:
        
        input.addEventListener('input', handleInputChange);
        break;
    }

    // Append the input to foreignObject, and foreignObject to the group
    inputElement.appendChild(input);
    resolve(inputElement);
  });
}


async function  createRectangles(data){
  return new Promise((resolve, reject) => {


  // Ensure that scaledX, scaledY, scaledWidth, and scaledHeight are valid numbers
  if (isNaN(scaledX) || isNaN(scaledY) || isNaN(scaledWidth) || isNaN(scaledHeight)) {
    console.error("One or more of the variables is not a valid number.");
    reject("Invalid number provided");
    return; // Exit the function if any of the variables is NaN
  }
 
  const cloneRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  cloneRect.setAttribute('stroke', '#ffd65bfc');
  cloneRect.setAttribute('fill', '#ffd65bfc');
 
  cloneRect.setAttribute('opacity', '0.75');
  cloneRect.setAttribute('rx', '5');
  cloneRect.setAttribute('ry', '5');
  cloneRect.setAttribute('x', scaledX);
  cloneRect.setAttribute('y', scaledY);
  cloneRect.setAttribute('width', scaledWidth);
  cloneRect.setAttribute('height', scaledHeight);
  // cloneRect.setAttribute('data-widths', width);
  // cloneRect.setAttribute('data-heights', height);
  cloneRect.setAttribute('data-content', content);
  cloneRect.setAttribute('data-format', format);
  cloneRect.setAttribute('data-fontColor', fontColor);
  cloneRect.setAttribute('data-fontSize', fontSize);
  cloneRect.setAttribute('data-fontFamily', fontFamily);
  cloneRect.setAttribute('data-fontFormat', fontFormat);
  cloneRect.setAttribute('data-fullname', fullname);
  cloneRect.setAttribute('data-token', token);
  cloneRect.setAttribute('data-uniqueid', uniqueid);
  cloneRect.setAttribute('data-recipien', fullname);
  // cloneRect.setAttribute('data-msg', messeges);
  cloneRect.setAttribute('data-mail', email);
  cloneRect.setAttribute('data-id', targetid);
  cloneRect.setAttribute('data-issigned',issigned );
  cloneRect.setAttribute('class','reactelement');
  cloneRect.setAttribute('strokeWidth', '0.7746987951807229');
  cloneRect.setAttribute('data-index',isOrder);
  cloneRect.setAttribute('data-fgColor', '##FFFFFF');
  cloneRect.setAttribute('data-bgColor', '#000000');
  if (['handwriteing', 'hand_praaf', 'stemps', 'drawing','attachment','calendar', 'fullname', 'email', 'company','title', 'textarea', 'approve', 'decline','number'].includes(format) && issigned === 'true' ) {
   cloneRect.setAttribute('opacity', '0');
 }

 if (format === 'number') {
  cloneRect.setAttribute('data-issigned','ture' );
   cloneRect.setAttribute('data-optionnumtype', optionnumtype);
   cloneRect.setAttribute('data-label', labelValeu);
   cloneRect.setAttribute('data-selecttype',data.selecttype);
   cloneRect.setAttribute('data-valutacode', data.valutacode);
   cloneRect.setAttribute('data-max', data.nummax);
   cloneRect.setAttribute('data-min', data.nummin);
 } if ( format === 'payment') {
  cloneRect.setAttribute('data-issigned','ture' );
  cloneRect.setAttribute('data-optionnumtype', optionnumtype);
  cloneRect.setAttribute('data-selecttype',data.selecttype);
  cloneRect.setAttribute('data-valutacode', data.valutacode);
  cloneRect.setAttribute('data-max', data.nummax);
  cloneRect.setAttribute('data-min', data.nummin);
 }
 switch (roll) {
  case 'false':
    cloneRect.setAttribute('data-valid',true);
    break;
  default:
    cloneRect.setAttribute('data-valid',false);
    break;
}
 resolve(cloneRect);
});
 
 }

}


   


const handleInputChange = (evt)=> {
  const inputValue = evt.target.value;
  const target = evt.target;

  const group = target.closest('g');
  const rect = group.querySelector('rect');
  const foreignObject = group.querySelector('foreignObject');
  const foreignObjectInput = target.parentElement;
  const span = foreignObject.querySelector('span');
  const targetid = rect.dataset.id;
  const format = rect.dataset.format;
  const signContent = rect.dataset.content;
  const fontColor = rect.dataset.fontColor;
  const fontSize = rect.dataset.fontSize;
  const fontFormat = rect.dataset.fontFormat;
  const fontFamily = rect.dataset.fontFamily;
  const label = rect.dataset.label;
  rect.setAttribute('data-issigned','true' )

  const optionnumtype = rect.dataset.optionnumtype; 
  const selecttype = rect.dataset.selecttype;
  const valutacode = rect.dataset.valutacode;
  const Max = rect.dataset.max;
  const Min = rect.dataset.min;
  const width = parseFloat(rect.getAttribute('width'));
  const height = parseFloat(rect.getAttribute('height'));
  const x = parseFloat(rect.getAttribute('x'));
  const y = parseFloat(rect.getAttribute('y'));
  let formattedValue;
  if (format === 'payment') {
    target.setAttribute('type', 'number'); // You might want to change the input type accordingl
    // Disable the input for 'payment' format

  }
  if (format === 'number') {
    const formatOptions = {
      minimumFractionDigits: 2, // Number of decimal places
    };
    target.setAttribute('type', 'number');
  
    // Extract numeric value from the input
    const numericValue = Number(inputValue.replace(/\D/g, ''));
  
    if (!isNaN(numericValue)) { // Check if the input is a valid number
      // Validate against minimum and maximum values
      if (numericValue < Min) {
        // Handle case where value is less than Min
        // alert('Value cannot be less than ' + Min);
        foreignObject.setAttribute('opacity', '1');
        span.textContent = 'Value cannot be less than ' + Max
       
      } else if (numericValue > Max) {
        // Handle case where value is greater than Max
        foreignObject.setAttribute('opacity', '1');
        span.textContent = 'Value cannot be greater than ' + Max
       // alert('Value cannot be greater than ' + Max);
      } else {
        // Perform formatting based on selecttype
        if (selecttype === 'number') {
          if (valutacode === 'normal') {
            formattedValue = numericValue;
          } else {
            formattedValue = numericValue.toLocaleString(valutacode) + '.00';
          }
        }
        if (selecttype === 'currency') {
          formatOptions.style = 'currency';
          formatOptions.currency = optionnumtype;
          formattedValue = numericValue.toLocaleString(valutacode, formatOptions);
        }
      }
    } else {
      // Handle case where the input is not a valid number
      //alert('Please enter a valid number.');
      foreignObject.setAttribute('opacity', '1');
      span.textContent = t('Please enter a valid number.') + Max
    }
    calculateResult(label)
  } else {
    formattedValue = inputValue;
  }
 
 // rect.setAttribute('data-content',inputValue )
  const maxWidth = 200;
  const calculatedWidth = calculateWidthBasedOnInput(inputValue, maxWidth);
  rect.setAttribute('width', calculatedWidth);
  rect.setAttribute('data-content', formattedValue);
  foreignObjectInput.setAttribute('width', calculatedWidth);
  foreignObject.setAttribute('width', '200');
  target.style.width = calculatedWidth + 'px';
  updateSignToPdf()
}
  return (
<div>

            <Modal
            isOpen={modal_centerparaaf}
            toggle={() => {
              tog_centerparaf();
            }}
            scrollable={true}
            >
            <div className="modal-header">
            <h5 className="modal-title mt-0">{t('Setting Paraaf')}</h5>
            <button
            type="button"
            onClick={() => {
              tog_centerparaf(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"

            >
            <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">
            <SignaturePad 
            onSignatureData={handleParaafData}
            />

            </div>
            <div className="modal-footer">
            <button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              tog_centerparaf(false)
            }
            >
            {t('Close')}
            </button>
            <button type="button"
            className="btn btn-primary"
            onClick={() =>
            PostParaafPNG(
              signatureParaafobj.group,
              signatureParaafobj.rect,
              signatureParaafobj.width,
              signatureParaafobj.height,
              signatureParaafobj.x,
              signatureParaafobj.y,
            parrafData,
            signatureParaafobj.email,
            signatureParaafobj.fullname,
            signatureParaafobj.uniqueid,
            signatureParaafobj.token,
            signatureParaafobj.format,
            signatureParaafobj.order
            )

            }
            >
            {t('Save Changes')}
            </button>
            </div>
            </Modal>
            <Modal 
            isOpen={modal_centerattach}
            toggle={() => {
              tog_centerattach();
            }}
            scrollable={true}
            >
            <div className="modal-header">
            <h5 className="modal-title mt-0">{t('Setting Attachment')}</h5>
            <button
            type="button"
            onClick={() => {
              tog_centerattach(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"

            >
            <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">
   
 <input
            type="file"
            accept="image/*"
       
            onChange={handleAtachmentData}
          />
            </div>
            <div className="modal-footer">
            <button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              tog_centerstemps(false)
            }
            >
           {t('Close')} 
            </button>
            <button type="button"
            className="btn btn-primary"
            onClick={() =>
            PostattachmentPNG(
              signatureStempobj.group,
              signatureStempobj.rect,
              signatureStempobj.width,
              signatureStempobj.height,
              signatureStempobj.x,
              signatureStempobj.y,
              stempData,
              signatureStempobj.email,
              signatureStempobj.fullname,
              signatureStempobj.uniqueid,
              signatureStempobj.token,
              signatureStempobj.format,
              signatureStempobj.order,
            )

            }
            >
          {t('Save Changes')}  
            </button>
            </div>
            </Modal>
            <Modal
            isOpen={modal_centerstemp}
            toggle={() => {
              tog_centerstemps();
            }}
            scrollable={true}
            >
            <div className="modal-header">
            <h5 className="modal-title mt-0">{t('Setting Stemp')}</h5>
            <button
            type="button"
            onClick={() => {
              tog_centerstemps(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"

            >
            <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">
   
 <input
            type="file"
            accept="image/*"
       
            onChange={handleStemsData}
          />
            </div>
            <div className="modal-footer">
            <button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              tog_centerstemps(false)
            }
            >
           {t('Close')} 
            </button>
            <button type="button"
            className="btn btn-primary"
            onClick={() =>
            PoststempsPNG(
              signatureStempobj.group,
              signatureStempobj.rect,
              signatureStempobj.width,
              signatureStempobj.height,
              signatureStempobj.x,
              signatureStempobj.y,
              stempData,
              signatureStempobj.email,
              signatureStempobj.fullname,
              signatureStempobj.uniqueid,
              signatureStempobj.token,
              signatureStempobj.format,
              signatureStempobj.order,
            )

            }
            >
            {t('Save Changes')} 
            </button>
            </div>
            </Modal>
<Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">{t('Signature')} </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <SignaturePad 
                onSignatureData={handleSignatureData}
                 />
                 
                      </div>
                      <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              setmodal_center(false)
                            }
                          >
                            {t('Close')}
                          </button>
                          <button type="button"
                           className="btn btn-primary"
                           onClick={() =>
                            PostSignaturePNG(
                              signatureobj.group,
                              signatureobj.rect,
                              signatureobj.width,
                              signatureobj.height,
                              signatureobj.x,
                              signatureobj.y,
                              signatureData,
                              signatureobj.email,
                              signatureobj.fullname,
                              signatureobj.uniqueid,
                              signatureobj.token,
                              signatureobj.format,
                              signatureobj.order
                            )
                            
                          }
                            >
                            {t('Save Changes')}
                          </button>
                        </div>
                    </Modal>
<Modal
    size="xl"
    isOpen={modal_fullscreen}
    toggle={() => {
      tog_fullscreen();
      navigate(-1);
    }}
    className="modal-fullscreen"
  >
    {loading && (
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                        </div>
                        <div className="digital-sign-online">
                            <span className="sign-onlinetext"> {t('Loading...')}</span>
                        </div>
                    </div>
                </div>
            )}

            {/* Actual component content */}
            {!loading && (
                <div>
                    {/* Your component content goes here */}
                </div>
            )}
     <div>
    </div>
    <div className="css-1e1ivkv" style={{borderBottom:'3px solid #2463d1'}}>
    <div className=" css-y0keyn">
    <div className="olv-header olv-ignore-transform css-q3fgw5">
<div className="css-1vsgmeb">


<p  className="css-u2y338">{t('Complete with eSignatureHub')}: {fullName}</p>
</div>
<div className="css-11jt1m5">
<div className="css-14j54of"></div>



<div className="css-zx8agq">
<button
id='finish-button'
 data-bs-target="#secondmodal"
 onClick={() => {
  handleFulldata();
 }}
 className="css-gusat5"
 style={{background:'#2463d1',color:'white',border:'none',position:'relative',right:'100px'}}
 type="button">
<span className="css-vxcmzt">{t('Save')}</span>
</button>
</div>

</div>
</div>
     
    </div>
    </div>
    <ModalBody>
    <div id='fix-top' className="fixed-top css-ms32d5" style={{ position: 'relative',width: '100%'  }}>

    <Row>
         <div className="css-1k8emhg col-12">
       
 
         <div className='css-23sad4'id='PdfViewerContaine'>
   
   <div className="css-quz66i " >
  
  <div className="css-1sdoc " id='pdf_content' >
 
      
  <div  id='pdf-wrapper' >

  <svg
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    viewBox={`0 0 ${pdfContainerX.current} ${totalImageHeight}`}
    width={pdfContainerX.current}
    className="pdf-container"
    id="previwe-container"
    version="1.1"
  >
   <foreignObject 
     width={pdfContainerX.current}
     height={totalImageHeight}
   >
   {imageUrls.map((imageData, index) => (
   <div  key={index}>
       <img
         key={index}
         src={imageData.url}
         className='disable-mouse-events'
         alt={`${t('Page')} ${index + 1}`}
         height={imageData.height - 20} 
       />
     
       </div>
     ))}
   </foreignObject>
  
  </svg>


    </div>
  </div>


</div>
   </div>
      
         </div>
    
</Row>
    </div>
    </ModalBody>
    
  </Modal>
</div>

    )
};


export default withTranslation()(withRouter(SignToPdf));
