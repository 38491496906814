import React, { useRef, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DraggableList from './DraggableList';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Row,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
  } from "reactstrap";
  import classnames from "classnames";
import { func } from 'prop-types';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
 function DrapDown({
  t, targetid,datatype,onDateSelected ,
  }) {
    useEffect(() => {
      toastr.options = {
        positionClass: 'toast-top-center',
        closeButton: true,
        debug: true,
        progressBar:true,
        timeOut: 30000, // Duration in milliseconds
      };
    }, []);
   
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col5, setcol5] = useState(true); 
    const [requreField, setRequre] = useState(true);
    const [validations, setValid] = useState(false);
    const [modal_scroll, setmodal_scroll] = useState(false);
    const t_col1 = () => {
      setcol1(!col1);
      setcol2(false);
      setcol3(false);
    };
    function tog_scroll() {
        setmodal_scroll(!modal_scroll);
        removeBodyCss();
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding");
      }
    
    const t_Text = () => {
      setRequre(!requreField);
      setValid(false);
    };
    const t_col3 = () => {
        setcol3(!col3);
        setcol1(false);
        setcol2(false);
      };
    const t_col2 = () => {
      setcol2(!col2);
      setcol1(false);
      setcol3(false);
    };
    const t_col5 = () => {
        setcol5(!col5);
      };
      const fontFamilies = [
        { dataQa: 'arial', value: 'arial', label: 'Arial' },
        { dataQa: 'Courier', value: 'Courier', label: 'Courier' },
        { dataQa: 'CourierBold', value: 'CourierBold', label: 'CourierBold' },
        { dataQa: 'CourierBoldOblique', value: 'CourierBoldOblique', label: 'CourierBoldOblique' },
        { dataQa: 'CourierOblique', value: 'CourierOblique', label: 'CourierOblique' },
        { dataQa: 'HelveticaBold', value: 'HelveticaBold', label: 'HelveticaBold' },
        { dataQa: 'Helvetica', value: 'helvetica', label: 'Helvetica' },
        { dataQa: 'HelveticaBoldOblique', value: 'HelveticaBoldOblique', label: 'HelveticaBoldOblique' },
        { dataQa: 'TimesRoman', value: 'TimesRoman', label: 'TimesRoman' },
        { dataQa: 'TimesRomanBold', value: 'TimesRomanBold', label: 'TimesRomanBold' },
        { dataQa: 'TimesRomanItalic', value: 'TimesRomanItalic', label: 'TimesRomanItalic' },
        { dataQa: 'TimesRomanBoldItalic', value: 'TimesRomanBoldItalic', label: 'TimesRomanBoldItalic' },
        { dataQa: 'Symbol', value: 'Symbol', label: 'Symbol' },
        { dataQa: 'ZapfDingbats', value: 'ZapfDingbats', label: 'ZapfDingbats' },
      ];
      const getSVGContent = (format) => {
        switch (format) {
          case 'bold':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="m8.59 6.33.157-.006c.369 0 .674-.09.907-.267.219-.168.33-.45.33-.843 0-.22-.035-.397-.11-.54a.826.826 0 0 0-.288-.313 1.417 1.417 0 0 0-.444-.156 3.36 3.36 0 0 0-.562-.049H6.595V6.33H8.59zm.292 4.534c.203 0 .408-.024.61-.067a1.42 1.42 0 0 0 .486-.202.93.93 0 0 0 .316-.364c.079-.152.117-.351.117-.61 0-.502-.126-.844-.388-1.047-.275-.213-.633-.316-1.097-.316H6.595v2.606h2.287zM4 13V2h5.044c.504 0 .97.046 1.386.137.42.092.791.248 1.1.463.316.223.56.518.726.88.17.354.256.801.256 1.327 0 .58-.126 1.05-.386 1.44-.194.295-.46.549-.794.76.504.209.896.52 1.17.931.336.507.498 1.097.498 1.803 0 .575-.105 1.058-.322 1.475-.216.42-.51.765-.87 1.024a3.755 3.755 0 0 1-1.229.577 5.283 5.283 0 0 1-1.4.183H4z"></path>
            </svg>
            );
          case 'italic':
            return (
              <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
              <path d="M7 2v2h1.8l-3.101 7.5H3.6v2h5.999v-2H7.8L11 4h1.999V2z"></path>
            </svg>
            );
          case 'underline':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="M1 14h14v2H1zM2.846 2H4.98v5.956c0 .384.02.773.06 1.168.04.395.154.75.342 1.064.187.315.472.573.853.776.381.203.918.304 1.612.304.695 0 1.231-.101 1.612-.304.381-.203.667-.461.854-.776.192-.33.308-.692.34-1.064.04-.395.06-.784.06-1.168V2h2.134v6.612c0 .736-.12 1.376-.359 1.92a3.7 3.7 0 0 1-1.014 1.368 4.322 4.322 0 0 1-1.58.824 7.22 7.22 0 0 1-2.047.272 7.2 7.2 0 0 1-2.048-.272 4.322 4.322 0 0 1-1.577-.824 3.701 3.701 0 0 1-1.016-1.368c-.239-.544-.359-1.184-.359-1.92V2z"></path>
            </svg>
            );
          default:
            return null;
        }
      };
      const FontFormats = [
        { value: 'bold', label: 'Bold' },
        { value: 'italic', label: 'Italic' },
        { value: 'underline', label: 'Underline' },
        // Add more formats as needed
      ];
  
      const dropDownRect = document.getElementById(targetid);
      if (!dropDownRect) {
        console.error(`Element with ID ${targetid} not found.`);
        return null; // or return some default content or handle the case
      }
      const fontFamilyValues = dropDownRect.getAttribute('data-fontFamily')
      const fontSizeValues = dropDownRect.getAttribute('data-fontSize')
      const fontFormatValues = dropDownRect.getAttribute('data-fontFormat')
      const fontColorsValues = dropDownRect.getAttribute('data-fontColor')
      const newTextContent = dropDownRect.getAttribute('data-content');
      const newRoll = dropDownRect.getAttribute('data-roll');
      const [selectedFontFamily, setSelectedFontFamily] = useState(fontFamilyValues);
      const [selectedFontSize, setSelectedFontSize] = useState(fontSizeValues);
      const [selectedFontFormat, setSelectedFontFormat] = useState(fontFormatValues);
      const [selectedFontColor, setSelectedFontColor] = useState(fontColorsValues);
      const [selectedValue, setSelectedValue] = useState(null);
      const initialNames  = dropDownRect.getAttribute('data-option');
      const initialValue  = dropDownRect.getAttribute('data-value')
       const defaultNamesArray = initialNames ? initialNames.split(',') : [];
       const defaultValuesArray = initialValue ? initialValue.split(',') : [];
       const defaultOptions = defaultNamesArray
    .map((name, index) => ({
      id: index + 1,
      name,
      value: defaultValuesArray[index] || '', 
      target: '',
    }))
    .filter(option => option.name !== 'null' && option.value !== 'null');
      const [options, setOptions] = useState([]);
      const [initialOption, setInitialOption] = useState('-- Select --');
      
      const  labelStyle = {
        border: fontFormatValues === fontFormatValues ? '1px solid #cecece' : 'none',
        // Add other styles as needed
      };

        const padding = 10;
      if (dropDownRect) {
        const parentGroup = dropDownRect.parentElement;
        if (parentGroup) {
            const textToUpdate = parentGroup.querySelector('text');
            if (textToUpdate) {
                const x = parseFloat(dropDownRect.getAttribute('x'));
                const y = parseFloat(dropDownRect.getAttribute('y'));
                const width = parseFloat(dropDownRect.getAttribute('width'));
                 const height = parseFloat(dropDownRect.getAttribute('height'));
                const textWidth = textToUpdate.getBBox().width;
                const textHeight = textToUpdate.getBBox().height;
                dropDownRect.setAttribute('width', textWidth + padding);
                dropDownRect.setAttribute('height', textHeight + padding);
                 textToUpdate.setAttribute('text-anchor', 'middle'); // Center horizontally
                 textToUpdate.setAttribute('dominant-baseline', 'middle');
                 
                const tspans = textToUpdate.getElementsByTagName('tspan');
                if (tspans) {
                    const numLines = tspans.length;
                    const lineHeight = parseFloat(tspans[0].getAttribute('dy'));
                    const textHeight = numLines * lineHeight;
                    const centerX = x + width / 2;
                    const centerY = y + height / 2;
                    const startYs = centerY - textHeight / 2;
                    for (let i = 0; i < tspans.length; i++) {
                        const tspan = tspans[i];
                        tspan.textContent = newTextContent;
                        tspan.setAttribute('x', centerX);
                        tspan.setAttribute('y', centerY);
                        tspan.setAttribute('dy', fontSizeValues * i);
                        tspan.setAttribute('fill', fontColorsValues);
                        tspan.setAttribute('font-size', fontSizeValues);
                        tspan.setAttribute('font-family', fontFamilyValues);
                        // Handle font formatting (italic, underline, bold)
                        tspan.style.fontStyle = fontFormatValues === 'italic' ? 'italic' : 'normal';
                        tspan.style.textDecoration = fontFormatValues === 'underline' ? 'underline' : 'none';
                        tspan.style.fontWeight = fontFormatValues === 'bold' ? 'bold' : 'normal';
                      }
                      const circles = parentGroup.querySelectorAll('circle');
                      if (circles) {
          
                        const rectX = parseFloat(dropDownRect.getAttribute('x'));
                        const rectY = parseFloat(dropDownRect.getAttribute('y'));
                        const rectWidth = parseFloat(dropDownRect.getAttribute('width'));
                        const rectHeight = parseFloat(dropDownRect.getAttribute('height'));
                       
                        const cxValues = [rectX, rectX + rectWidth, rectX, rectX + rectWidth];
                        const cyValues = [rectY, rectY, rectY + rectHeight, rectY + rectHeight];
                    
                        circles.forEach((circle, index) => {
                          circle.setAttribute('cx', cxValues[index]);
                          circle.setAttribute('cy', cyValues[index]);
                        });
                      }
                } else {
                    console.log("No <tspan> elements found within <text>.");
                  }
            } else {
                console.log("No <text> element found within parentGroup.")
              }
        } else {
            console.log("Parent group not found.");
          }
      } else {
        console.log("ElementToUpdate is null or undefined.");
      }
     

      const handleFontFamilyChange = (event) => {
        const selectedFontFamilyValue = event.target.value;
        setSelectedFontFamily(event.target.value);
        dropDownRect.setAttribute('data-fontFamily', selectedFontFamilyValue);

      };
        const handleFontColorChange = (event) => {
            setSelectedFontColor(event.target.value);
            dropDownRect.setAttribute('data-fontColor', event.target.value);

           
        };
        const handleFontSizeChange = (event) => {
            setSelectedFontSize(event.target.value);
            dropDownRect.setAttribute('data-fontSize', event.target.value);
            
        };
        const handleLiClick = (value) => {
          const newFontFormat = value;
          setSelectedFontFormat(value);
          dropDownRect.setAttribute('data-fontFormat', value);
        };

    const handleRadioChange = (value) => {
            setSelectedValue(value);
            dropDownRect.setAttribute('data-roll', value);
          };
    const itemStyle = {
        display: 'flex',
      };
      const handleAddOption = () => {
        setOptions((prevOptions) => [
          ...prevOptions,
          {
            id: prevOptions.length + 1,
            name: '',
            value: '',
            target: targetid,
          },
        ]);
      };
      const handleRemoveOption = (id) => {
        setOptions((prevOptions) => prevOptions.filter((option) => option.id !== id));
      
        // Filter options based on targetid
        const optionsForTarget = options.filter((option) => option.target === targetid);
      };
      const handleSelectOptions = (id, property, value) => {
        setOptions(prevOptions =>
          prevOptions.map(option =>
            option.id === id ? { ...option, [property]: value, target: targetid } : option
          )
        );
      };
      const handleSave = () => {
        // Filter options based on targetid before saving
        const filteredOptions = options.filter(option => option.target === targetid);
      
        // Check if filteredOptions for the specific targetid are empty
        const hasOptions = filteredOptions.length > 0;
        const isValid = validateOptions(filteredOptions);

  if (!isValid) {
    toastr.error(t('Validation failed. Please check your options.'));
    return; // Stop the saving process if validation fails
  }
        // Set attributes to 'null' if options for the specific targetid are empty
        if (!hasOptions) {
          dropDownRect.setAttribute('data-option', 'null');
          dropDownRect.setAttribute('data-value', 'null');
        } else {
          // If there are options, concatenate names and values
          const optionNamesArray = filteredOptions.map(option => option.name);
          const optionValuesArray = filteredOptions.map(option => option.value);
          const names = optionNamesArray.join(',');
          const values = optionValuesArray.join(',');
      
          // Set attributes with the concatenated names and values
          dropDownRect.setAttribute('data-option', names);
          dropDownRect.setAttribute('data-value', values);
        }
      
        setmodal_scroll(false);
      };
      const validateOptions = (options) => {
        // Add your validation logic here
        const isValid = options.every((option) => option.name.trim() !== "" && !containsSpecialCharacters(option.name));
        return isValid;
      };
      const containsSpecialCharacters = (str) => {
        // Add your special character validation logic here
        const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
        return specialCharacters.test(str);
      };
      useEffect(() => {
        // Load defaultOptions when the component mounts
        setOptions(defaultOptions);
      }, []);

      useEffect(() => {
        onDateSelected( 
          targetid,datatype
          )
          
      }, [targetid,datatype]);
      
     
      return (
        <React.Fragment>
             <Row>
                        <Col lg={12}>
                        <div className="css-vlt30q">
          <div style={itemStyle}>
              <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
             
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" fill="gray8" focusable="false" data-qa="icon-element-dropdown"><path d="M19.1 4.9v14.2H4.9V4.9h14.2M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM7 10l4.26 4.69a1 1 0 0 0 1.48 0L17 10z"></path></svg>
      </span>
      </span>
      <span data-qa="pannel-header" style={{paddingLeft:'0.625em'}}> {t('Dropdown')}</span>
      </div>
      </div>
    <div className="accordion " id="accordion">
    <div className="accordion-item" >
                        <h2 className="accordion-header" id="headingTwo2">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !requreField }
                            )}
                            type="button"
                            onClick={t_Text}
                            style={{ cursor: "pointer" }}
                          >
                             {t('Rule')} 
                          </button>
                        </h2>
    <Collapse isOpen={requreField} className="accordion-collapse">
    <div className="accordion-body">
    <div className="text-muted">
    <Label>{t('Set Rule')}</Label>

    <div className="css-q10huv">
    <div className="css-hboir5" style={{display:'block'}}>
    <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
      <input
        type="radio"
        className="form-check-input"
        id="verplicht"
        name='checkbox'
        checked={newRoll === 'true'}
        onClick={() => handleRadioChange('true')}              
        />
        <label
        className="form-check-label"
        htmlFor="verplicht"
        onClick={() => handleRadioChange('true')}
         >
         {t('Required field')}
        </label>
     </div>
     <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
      <input
        type="radio"
        className="form-check-input"
        id="lezen"
        name='checkbox' 
        checked={newRoll === 'false'}
        onClick={() => handleRadioChange('false')}                 
        />
        <label
        className="form-check-label"
        htmlFor="alleenLezen"
        onClick={() => handleRadioChange('false')}
         >
        {t('Read-only')}
        </label>
     </div>                       
    </div>
    </div>
      </div>
      </div>
    </Collapse>
    </div>
    <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !col1 }
                                  )}
                                  type="button"
                                  onClick={t_col1}
                                  style={{ cursor: "pointer" }}
                                >
                                 {t('Formatting')}   
                                </button>
                              </h2>
      
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
            <div className="text-muted">
          <div className="css-q10huv">
          <div className="css-hboir5">
          <select
         className="css-12ihcxq" 
         value={fontFamilyValues}
        onChange={handleFontFamilyChange} >
        {fontFamilies.map((font) => (
    <option key={font.dataQa} value={font.value} data-qa={font.dataQa}>
      {font.label}
    </option>
  ))}
        </select>
              <span className="css-1baizyq">
                  <span className="css-lzaifp">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                      </svg>
                  </span>
              </span>
          </div>
      </div>
      <div className="css-9f4jv1">
          <div  style={{ marginRight: '-1px' ,width:"50px" }}>
              <div className="css-0">
                  <label data-qa="font-size-label" htmlFor="G29wOvqLVz" className="css-5gs0ys">{t('Font Size')}  </label>
              </div>
              <div className="css-q10huv">
                  <div className="css-hboir5">
                  <select 
                data-qa="font-size"
                 id="G29wOvqLVz"
                  className="css-12ihcxq"
                  value={fontSizeValues}
                   onChange={handleFontSizeChange}>
                    <option data-qa="font-7" value="7">7</option>
                    <option data-qa="font-8" value="8">8</option>
                    <option data-qa="font-9" value="9">9</option>
                    <option data-qa="font-10" value="10">10</option>
                    <option data-qa="font-11" value="11" >11</option>
                    <option data-qa="font-12" value="12" >12</option>
                    <option data-qa="font-14" value="14">14</option>
                    <option data-qa="font-16" value="16">16</option>
                    <option data-qa="font-18" value="18">18</option>
                    <option data-qa="font-20" value="20">20</option>
                    <option data-qa="font-22" value="22">22</option>
                    <option data-qa="font-24" value="24">24</option>
                    <option data-qa="font-26" value="26">26</option>
                    <option data-qa="font-28" value="28">28</option>
                    <option data-qa="font-36" value="36">36</option>
                    <option data-qa="font-48" value="48">48</option>
                    <option data-qa="font-72" value="72">72</option>
                </select>
                      <span className="css-1baizyq">
                          <span className="css-lzaifp">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                                  <path d="M4 5h8l-4 6z"></path>
                              </svg>
                          </span>
                      </span>
                  </div>
              </div>
          </div>
          <div className="css-199kmjd">
          {FontFormats.map((format) => (
        <div key={format.value} className="css-vxcmzt">
          <Input
            id={format.value}
            name="radioGroup"
            type="radio"
            className="css-1pdgtej"
            value={fontFormatValues}
            checked={fontFormatValues === format.value}
          />
          <label htmlFor={format.value}
           className={`css-4lt7ka`} 
           style={fontFormatValues === format.value ? labelStyle : {}}
           onClick={() => handleLiClick(format.value)}>
            <span className="css-zf56fy">
              <span aria-hidden="true" className="SVGInline">
              {getSVGContent(format.value)}
              </span>
            </span>
            <span className="css-16htk9o">{format.label}</span>
          </label>
        </div>
      ))}
      </div>
      </div>
      <div className="css-q10huv">
          <div className="css-hboir5">
              <select className="css-12ihcxq"
               value={fontColorsValues} 
               onChange={handleFontColorChange}>
                  <option data-qa="#ffffff" value="#ffffff" selected>{t('White')}</option>
            <option data-qa="#0053eb" value="#0053eb">{t('Blue')}</option>
            <option data-qa="#f7e601" value="#f7e601">{t('Yellow')}</option>
            <option data-qa="#f70101" value="#f70101">{t('Red')}</option>
            <option data-qa="#000000" value="#000000">{t('Black')}</option>
            <option data-qa="#0087ff" value="#0087ff">{t('Bright Blue')}</option>
            <option data-qa="#7e0101" value="#7e0101">{t('Dark Red')}</option>
            <option data-qa="#ff0000" value="#ff0000">{t('Bright-Red')}</option>
            <option data-qa="#FFD700" value="#FFD700">{t('Gold')}</option>
            <option data-qa="#008000" value="#008000">{t('Green')}</option>
            <option data-qa="#006400" value="#006400">{t('Dark Green')}</option>
              </select>
              <span className="css-1baizyq">
                  <span className="css-lzaifp">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                      </svg>
                  </span>
              </span>
          </div>
      </div>
      
      
                      </div>
                          </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item" >
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col3 }
                            )}
                            type="button"
                            onClick={t_col5}
                            style={{ cursor: "pointer" }}
                          >
                           {t('Options')} 
                          </button>
                        </h2>

                        <Collapse isOpen={col5} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted">
                            <div className="css-q10huv">
                            <div className="css-hboir5">
        <select
        className="css-12ihcxq"
        value={initialOption}
        onChange={(e) => setInitialOption(e.target.value)}
      >
        <option>-- Select --</option>
        {defaultOptions.map(option => (
               <option key={option.id} value={option.value}>{option.name}</option>
            ))}
      </select>
                      <span className="css-1baizyq">
                      <span className="css-lzaifp">
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                      <path d="M4 5h8l-4 6z"></path>
                      </svg>
                     </span>
                     </span>
                    
                     </div>
                     
                    </div>

                    <div style={{marginTop:'8px', marginBottom: '8px'}}>
                        <button
                       
                         onClick={() => {
                            tog_scroll();
                          }}
                          data-toggle="modal"
                          className="olv-button olv-ignore-transform css-129ohl "
                         type="button">
                            <span data-qa="edit-dropdown-values-text" className="css-16hz1ch"> {t('Edit Values')} </span>
                     </button>
                     </div>
                            </div>
                          </div>

                        </Collapse>
                       <Modal
                      isOpen={modal_scroll}
                      toggle={() => {
                        tog_scroll();
                      }}
                      
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                        {t('Edit Values')}
                        </h5>
                        <button
                          type="button"
                          onClick={() =>
                            setmodal_scroll(false)
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>{t('Enter internal data values for this list of dropdown options.')}</p>
                        <Row>
        <Col className="col-4">
          {/* Content for the first column */}
          <p className='css-rhk3of'>{t('Options')} </p>
        </Col>
        <Col className="col-4">
          {/* Content for the second column */}
          <p className='css-rhk3of'>{t('Values')} </p>
        </Col>
        <Col className="col-4">
          {/* Content for the second column */}
        
        </Col>
        </Row>
        {defaultOptions
  .filter(option => option.target === targetid) // Filter options based on targetid
  .map(option => (
    <Row key={option.id}>
      <Col className="col-4">
        <div className="css-q10huv">
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq option"
              type="text"
              placeholder="Option Value"
              value={option.value}
              onChange={(e) => handleSelectOptions(option.id, 'value', e.target.value)}
            />
          </div>
        </div>
      </Col>
      <Col className="col-4">
        <div className="css-q10huv">
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq valus"
              type="text"
              placeholder="Option Name"
              value={option.name}
              onChange={(e) => handleSelectOptions(option.id, 'name', e.target.value)}
            />
          </div>
        </div>
      </Col>
      <Col className="col-4">
        <div className="css-ww6f74">
          <Button
            className="olv-button olv-ignore-transform css-b1ilmt"
            type="button"
            onClick={() => handleRemoveOption(option.id)}
          >
            <span data-qa="remove-dropdown-option-icon" className="css-1rpan28">
              <span className="css-zf56fy">
                <span aria-hidden="true" className="SVGInline">
                  <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M12.95 11.536 9.414 8l3.536-3.536-1.414-1.414L8 6.586 4.464 3.05 3.05 4.464 6.586 8 3.05 11.536l1.414 1.414L8 9.414l3.536 3.536z"></path>
                  </svg>
                </span>
              </span>
            </span>
            <span className="css-1pdgtej">{t('Remove')}</span>
          </Button>
        </div>
      </Col>
    </Row>
  ))}
        {options
  .filter(option => option.target === targetid) // Filter options based on targetid
  .map(option => (
    <Row key={option.id}>
      <Col className="col-4">
        <div className="css-q10huv">
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq option"
              type="text"
              placeholder="Option Value"
              value={option.value}
              onChange={(e) => handleSelectOptions(option.id, 'value', e.target.value)}
            />
          </div>
        </div>
      </Col>
      <Col className="col-4">
        <div className="css-q10huv">
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq valus"
              type="text"
              placeholder="Option Name"
              value={option.name}
              onChange={(e) => handleSelectOptions(option.id, 'name', e.target.value)}
            />
          </div>
        </div>
      </Col>
      <Col className="col-4">
        <div className="css-ww6f74">
          <Button
            className="olv-button olv-ignore-transform css-b1ilmt"
            type="button"
            onClick={() => handleRemoveOption(option.id)}
          >
            <span data-qa="remove-dropdown-option-icon" className="css-1rpan28">
              <span className="css-zf56fy">
                <span aria-hidden="true" className="SVGInline">
                  <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M12.95 11.536 9.414 8l3.536-3.536-1.414-1.414L8 6.586 4.464 3.05 3.05 4.464 6.586 8 3.05 11.536l1.414 1.414L8 9.414l3.536 3.536z"></path>
                  </svg>
                </span>
              </span>
            </span>
            <span className="css-1pdgtej">{t('Remove')}</span>
          </Button>
        </div>
      </Col>
    </Row>
  ))}
        <div>
            <button onClick={handleAddOption} className="olv-button olv-ignore-transform css-qmb2mn" type="button">
                <span className="css-1pig3b7">
                    <span className="css-zf56fy">
                        <span aria-hidden="true" className="SVGInline">
                            <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M14 7H9V2H7v5H2v2h5v5h2V9h5z"></path>
</svg>
</span>
</span>
</span>
<span className="css-16hz1ch">{t('Add Option')}</span>
</button>
<span aria-live="assertive" className="css-1mc4uv4"></span>
</div>
      
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              setmodal_scroll(false)
                            }
                          >
                            {t('Close')}
                          </button>
                          <button type="button" onClick={handleSave} className="btn btn-primary">
                          {t('Save')} 
                          </button>
                        </div>
                      </div>
                    </Modal>
                      </div>

  
                 
                          </div>
                        </Col>
                      
                      </Row>
        </React.Fragment>
        
        );
      }
      
      export default withTranslation()(DrapDown);