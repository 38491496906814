import React, { useState } from 'react';
import { helpCenterData } from "../../common/data"; // Import your data here
import { useLocation,useNavigate  } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SearchHelp = ({ props }) => {
  const { t, i18n } = props;
  const currentLanguage = i18n.language; // Get the current language from i18n
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const performSearch = (searchQuery) => {
    
    if (searchQuery.trim() === '') {
      setFilteredResults([]);
      setShowDropdown(false);
      return;
    }
  
        const searchResults = helpCenterData.filter((menuItem) => {
        const mainMenuMatch =
        menuItem.mainMenu?.lang === currentLanguage &&
        menuItem.mainMenu?.name.toLowerCase().includes(searchQuery.toLowerCase());
    
        const subMenuMatch = menuItem.mainMenu?.subMenu?.some((subMenu) =>
            subMenu.lang === currentLanguage &&
            subMenu.name.toLowerCase().includes(searchQuery.toLowerCase())
          );

          const childMenuMatch = menuItem.mainMenu?.subMenu?.some((subMenu) =>
            subMenu.childMenu?.some((child) => {
              const childNameMatch =
                child.lang === currentLanguage &&
                child.name.toLowerCase().includes(searchQuery.toLowerCase());
      
              // Then search in childMenu.textContent
              const textContentMatch = child.textContent?.some((text) =>
                text.title.toLowerCase().includes(searchQuery.toLowerCase())
              );
      
              return childNameMatch || textContentMatch; // Return true if either child name or textContent matches
            })
          );
          return mainMenuMatch || subMenuMatch || childMenuMatch;
    });
    let results = [];
  
    searchResults.forEach((menuItem) => {
      menuItem.mainMenu?.subMenu?.forEach((subMenu) => {
        subMenu.childMenu?.forEach((childMenu) => {
          // Check if childMenu matches or has textContent matches
          const textContentMatch = childMenu.textContent?.filter((text) =>
            text.title.toLowerCase().includes(searchQuery.toLowerCase())
          );
  
          if (
            childMenu.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            textContentMatch?.length > 0
          ) {
            results.push({
              mainMenuName: menuItem.mainMenu.name, // Main Menu Name
              subMenuName: subMenu.name, // Sub Menu Name
              childMenuUrl: childMenu.url, // Child Menu URL
              childMenuName: childMenu.name, // Child Menu Name
              textContent: textContentMatch?.map((text) => ({
                title: text.title, // Text Content Title
                content: text.content, // Text Content
              })) || [],
            });
          }
        });
      });
    });
      setFilteredResults(results);
      setShowDropdown(true);  
  };
  
  // Handle input change and search on keyup
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);  // Update the query state
    performSearch(value);  // Perform the search as user types
  };
  
  // Handle clicking outside of the input to hide the dropdown
  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 150);  // Delay hiding to allow clicking on a result
  };
  const [hovered, setHovered] = useState(null);  // Track hovered item

  const handleMouseEnter = (index) => {
    setHovered(index);
  };
  
  const handleMouseLeave = () => {
    setHovered(null);
  };

  const handleUrl = (url) => {
    navigate(`/help-center/resolve/${url}`); // Navigate to the given URL
  };
  return (
    <React.Fragment>
      {/* Search bar form */}
      <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          placeholder={t("Search By..")}
          value={query}
          onChange={handleInputChange}  // Trigger search on keyup
          style={styles.input}
          onFocus={() => setShowDropdown(true)}  // Show dropdown when focused
            
        />
{showDropdown && filteredResults.length > 0 && (
  <ul style={styles.dropdown} className="list-unstyled megamenu-list">
    {filteredResults.map((result, index) => (
      <li
       key={index}
       onMouseEnter={() => handleMouseEnter(index)}
       onMouseLeave={handleMouseLeave}
       style={{
         backgroundColor: hovered === index ? '#f0f0f0' : 'transparent',  // Optional hover effect
       }}
       onClick={() => handleUrl(result.childMenuUrl)}
      >
        <a
          href={`/help-center/resolve/${result.childMenuUrl}`}
          className="flex items-center rounded-lg hover:bg-black"
          style={styles.dropdownItem}
        >
          
          <div className="ml-3">
            <p className="text-sm font-bold">{result.childMenuName}</p>
            {result.textContent.map((text, idx) => (
              <p key={idx} className="text-sm">{text.title}</p>
            ))}
            <p className="text-sm font-bold">{result.mainMenuName}-{result.subMenuName}</p>
          </div>
        </a>
      </li>
    ))}
  </ul>
)}

        
      </form>
    </React.Fragment>
  );
};

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '600px',  // Set the max width of the form to 600px or adjust as needed
    margin: '0 auto',  // Center the form horizontally
    position: 'relative',  // To position the dropdown below
  },
  input: {
    flex: 1,
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '4px 0 0 4px',
    border: '1px solid #ccc',
    outline: 'none',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  },
  dropdown: {
    position: 'absolute',
    top: '100%',
    left: '0',
    width: '100%',  // Make the dropdown match the form width
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '0 0 4px 4px',
    zIndex: 10,
    maxHeight: '200px',
    overflowY: 'auto',  // Allow scrolling if the dropdown content exceeds the height
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    textDecoration: 'none',
    color: '#333',
    borderBottom: '1px solid #eaeaea',
    transition: 'background-color 0.3s ease',
    borderRadius: '8px',
  },
};

export default SearchHelp;
