import React, { useRef, useState, useEffect,useMemo  } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { Modal,Row,Col,Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import './style.css';
//redux
import { useSelector, useDispatch } from "react-redux";
import {
    getShift,
    getShiftTeam,
    postNewTeam,
    putShift,
    GetSurcharger,
  } from "store/actions";

  function formatTimeShift(timeString) {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  }
const TeamAndShift = ({ t, show, handleClose, selectedTeam,setIsReloadData }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
    const {
        shift,
        teamdata,
        postnewteam,
        putshift,
        getsurcharger,
      } = useSelector(state => ({
        shift: state.getShiftData.shift,
        teamdata: state.getShiftData.teamdata,
        postnewteam: state.getShiftData.postnewteam,
        putshift: state.getShiftData.putshift,
        getsurcharger: state.getShiftData.getsurcharger,
      }));
   
      
  const [shiftActiveTab, setShiftActiveTab] = useState("tab_0");
  const [currentPageTeam, setCurrentTeamPage] = useState(1); // Current page number 
  const [currentPage, setCurrentPage] = useState(1); // Current page number Team
  const [searchShift, setSearchShift] = useState("");  // Search term
  const [searchTerm, setSearchTerm] = useState(""); 
  const [colorupdate, setColorUpdate] = useState(selectedTeam.color);
  const [color, setColor] = useState('#ff0000');
  const [shiftUpdates, setShiftUpdates] = useState({});

  const [putTeamdataUpdate, setPutTeamData] = useState({
    account_id: account_id, 
    team_name: selectedTeam.teamname, 
    dep_id: selectedTeam.depid,
    type:'update',
    team_id:selectedTeam.teamid,
    team_color:selectedTeam.color,
  });

  const [postNewTeamdata, setPostNewTeamdata ] = useState({
    account_id: account_id, 
    team_name: null, 
    dep_id: selectedTeam.depid,
    type:'new',
    team_id:selectedTeam.teamid,
    team_color:selectedTeam.color,
    
  });
  const itemsPerPage = 12;
  function hexToRGBA(hexColor) {
    const hex = hexColor.slice(1); // Remove the leading #
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, 1)`;
  }
  
  function dimColor(color, opacity) {
    // Ensure the color is defined and in RGBA format
    if (!color) {
      console.warn("dimColor received an undefined color, using fallback.");
      return `rgba(0, 0, 0, ${opacity})`; // Fallback to black
    }
  
    // Handle hex color format
    if (color.startsWith("#")) {
      color = hexToRGBA(color);
    }
  
    // Parse the RGBA values
    const rgba = color.match(/[\d.]+/g);
    if (!rgba) {
      return color; // Invalid color format, return original
    }
  
    // Reduce the opacity
    const dimmedColor = `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${opacity})`;
    return dimmedColor;
  }
  
  // Tab change handler
  const handleTabChange = (tabId) => {
    setShiftActiveTab(tabId);
  };





  const filteredTeam = useMemo(() => 
    teamdata.filter(item => selectedTeam.teamid === item.team_id), 
    [teamdata, selectedTeam.teamid]
);

const filteredShift = useMemo(() => 
  shift.filter(item => selectedTeam.teamid === item.team_id_shift)
    .filter(shift => shift.shift_short_name.toLowerCase().includes(searchShift.toLowerCase())), 
  [shift, selectedTeam.teamid, searchShift]
);

useEffect(() => {
  dispatch(getShift(account_id));
  dispatch(getShiftTeam(account_id));
  dispatch(GetSurcharger(account_id));
}, [dispatch  ,account_id,selectedTeam]);


  const filteredTeamData = filteredTeam.filter((team) =>
    team.team_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredData = filteredShift.filter((shift) =>
    shift.shift_short_name.toLowerCase().includes(searchShift.toLowerCase()) ||
    shift.shift_name.toLowerCase().includes(searchShift.toLowerCase())
  );

  // Pagination calculation
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedShifts = filteredData.slice(startIndex, startIndex + itemsPerPage);
// Pagination Team 
const totalTeamPages = Math.ceil(filteredTeamData.length / itemsPerPage);
const startTeamIndex = (currentPageTeam - 1) * itemsPerPage;
const paginatedTeams = filteredTeamData.slice(startTeamIndex, startTeamIndex + itemsPerPage);


  const handlePageChangeTeam = (newPage) => {
    setCurrentTeamPage(newPage);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handle search input change 
  const handleSearchTeamChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentTeamPage(1); // Reset to page 1 on search
  };
  const handleSearchChange = (e) => {
    setSearchShift(e.target.value);
    setCurrentPage(1); 
  };
  const handleColorChange = (event) => {
    setColor(event.target.value);
    setPostNewTeamdata(prevData => ({
      ...prevData,
      team_color: event.target.value,
    }));
  };
// 
const handleShiftChange = (field, value, shiftId) => {
  setShiftUpdates(prevUpdates => ({
    ...prevUpdates,
    [shiftId]: {
      ...prevUpdates[shiftId],
      [field]: value,
      shiftid: shiftId,
    }
  }));
};

  const handleColorUpdateChange = (event) => {
    setColorUpdate(event.target.value);
    setPutTeamData(prevData => ({
      ...prevData,
      team_color: event.target.value,
    }));

    
  };




  const validateShiftFields = (shiftData) => {
    const errors = {};
  
    // Check only the fields that have been modified
    if (shiftData.shiftname === null || shiftData.shiftname === undefined) {
      errors.shiftname = t('Shift name is required.');
    }
    if (shiftData.shift_start === null || shiftData.shift_start === undefined) {
      errors.shift_start = t('Start Time is required.');
    }
    if (shiftData.shift_eind === null || shiftData.shift_eind === undefined) {
      errors.shift_eind = t('End Time is required.');
    }
  
    return errors;
  };
  const mapShiftDataToDatabaseFields = (shiftData, originalShift) => {
    return {
      shiftname: shiftData.shiftname || originalShift.shift_name,
      shiftid: shiftData.shiftid || originalShift.shift_id,
      shift_eind: shiftData.shift_eind || originalShift.eind_time_shift,
      shift_start: shiftData.shift_start || originalShift.start_time_shift,
      bonus_percent: shiftData.bonus_percent || originalShift.bonus,
      shift_color: shiftData.shift_color || originalShift.shift_color,
    };
  };
  const handleSubmitShiftUpdate = (shiftId) => {
    const shiftData = shiftUpdates[shiftId];
    const originalShift = paginatedShifts.find(shift => shift.shift_id === shiftId);
    const shiftDataForDatabase = mapShiftDataToDatabaseFields(shiftData, originalShift);
    const errors = validateShiftFields(shiftDataForDatabase);
  
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => toastr.error(error));
      return;
    }

    // Dispatch update only for this shiftId
   dispatch(putShift(shiftDataForDatabase));
  };
  

  const handleNewTeamNamesChange = (e) => {
    const newEndDate = e.target.value;
    setPostNewTeamdata(prevData => ({
      ...prevData,
      team_name: newEndDate,
    }));
  };

  const handleTeamnameUpdateChange = (e) => {
    const newName = e.target.value;
    setPutTeamData(prevData => ({
      ...prevData,
      team_name: newName,
    }));
  };

  const handlePutTeamDataChange = (event) => {
    if (putTeamdataUpdate.team_name && putTeamdataUpdate.dep_id) {
      dispatch(postNewTeam(putTeamdataUpdate));
    }
    
  }

  const handlePostTeamDataChange = (event) => {
    if (postNewTeamdata.team_name && postNewTeamdata.dep_id && postNewTeamdata.team_id) {
      dispatch(postNewTeam(postNewTeamdata));
      
    }
  }

  useEffect(() => {
    const handleSuccess = (message) => {
      toastr.success(t(message));
      dispatch(getShift(account_id));
      dispatch(getShiftTeam(account_id));
    };
  
    const handleError = (message) => {
      toastr.error(t(message));
    };
  
    if (postnewteam?.success) {
      handleSuccess('The team data has been successfully updated.');
      setIsReloadData(true)
    } else if (postnewteam?.error) {
      handleError('Failed to update team data.');
    }
  
    if (putshift?.success && !postnewteam?.success) { // Avoid redundant calls
      handleSuccess('New shift data has been successfully submitted.');
      setIsReloadData(true)
    } else if (putshift?.error) {
      handleError('Failed to submit shift data.');
    }
  }, [postnewteam, putshift, dispatch, account_id, t,setIsReloadData]);
  const groupSurchargeData = (data) => {
    const groupedData = {};
  
    data.forEach((item) => {
      const {
        title_sur,
        token_sur,
        day_sur,
        start_time_sur,
        end_time_sur,
        paid_surcharge_id,
        time_surcharge_id,
        create_time_sur,
        update_time_sur,
      } = item;
  
      if (!groupedData[title_sur]) {
        groupedData[title_sur] = {
          title_sur,
          token_sur,
          create_time: create_time_sur || new Date().toISOString(),
          update_time: update_time_sur || new Date().toISOString(),
          days: [],
        };
      }
  
      groupedData[title_sur].days.push({
        day_sur,
        start_time_sur,
        end_time_sur,
        paid_surcharge_id,
        time_surcharge_id,
      });
    });
  
    return groupedData;
  };

  const groupedSurcharger = groupSurchargeData(getsurcharger); 
  
  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={show} // Ensures modal only renders when 'show' is true
        toggle={handleClose}
        centered
        style={{ bottom: "0" }}
        scrollable={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {t('Assign Shift')} 
          </h5>
          <button
            onClick={handleClose}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {show && (
          <div className="modal-body">
          
              <div className="right-slider-diens-0 modalopen">
                <div>
                 

                  {/* Tab Navigation */}
                  <ul
                    className="nav nav-pills nav-justified max-d-block"
                    role="tablist"
                    style={{ paddingLeft: "7px" }}
                  >
                    <li className="nav-item waves-effect waves-light css-sd2sfag3">
                      <a
                        className={`nav-link css-roster ${
                          shiftActiveTab === "tab_0" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("tab_0")}
                        role="tab"
                        aria-selected={shiftActiveTab === "tab_0"}
                        tabIndex="0"
                      >
                        <span className="d-none d-lg-block"> {t('Shift')} </span>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light css-sd2sfag3">
                      <a
                        className={`nav-link css-roster ${
                          shiftActiveTab === "tab_1" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("tab_1")}
                        role="tab"
                        aria-selected={shiftActiveTab === "tab_1"}
                        tabIndex="-1"
                      >
                        <span className="d-none d-lg-block">{t('Teams')}</span>
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {shiftActiveTab === "tab_0" && (
                      <div id="tab_0">
                        {/* Tab 0 content */}
                        <div className="section_search col-12">
                          <div className="search-container">
                            <input
                              type="text"
                              className="search-input form-control"
                              placeholder={t("Search By..")}
                              onChange={handleSearchChange}
                            />
                            <button className="olv-button css-smal4s9" type="button">
                              <span className="css-23dsfxs">
                                <i
                                  className="bx bx-search-alt-2"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>

                     
                        <label htmlFor="dienst_siade_check">{t('Update Shift info')}</label>
                        {paginatedShifts.map(shift => (
  <div key={shift.shift_id} className="checkboxGroup row align-items-center" style={{ padding: '10px 22px' }}>
    <div className="col-3">
      <input
        type="text"
        defaultValue={shift.shift_name}
        data-id={shift.shift_id}
        className="form-control"
        onChange={(e) => handleShiftChange('shiftname', e.target.value, shift.shift_id)}
      />
    </div>
    <div className="col-3" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    <select
      className="form-control"
      defaultValue={shift.bonus}
      data-id={shift.shift_id}
      onChange={(e) => handleShiftChange('bonus_percent', e.target.value, shift.shift_id)}
      style={{ width: '70%' }}  // Adjust width for alignment
  >
    <option value="">{t('Select surcharge')}</option>
    {Object.keys(groupedSurcharger).map((key) => (
      <option key={groupedSurcharger[key].token} value={groupedSurcharger[key].token_sur}>
        {groupedSurcharger[key].title_sur}
      </option>
    ))}
  </select>
  
  
  {/* Rounded Color Picker */}
  <input
    type="color"
    className="form-control-color-picker"
    defaultValue={shift.shift_color}
    data-id={shift.shift_id}
    onChange={(e) => handleShiftChange('shift_color', e.target.value, shift.shift_id)}
    style={{
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      border: '2px solid #ccc',
      padding: '0',
      cursor: 'pointer'
    }}
  />
</div>

     {/* Color Picker for Shift Color */}
    
    <div className="col-4 timeOfDiensRow" style={{ backgroundColor: dimColor(shift.shift_color, 0.1), border: `3px solid ${shift.shift_color}`, display: 'flex', justifyContent: 'space-around' }}>
      <input
        type="time"
        className="timeOfDiens"
        defaultValue={formatTimeShift(shift.start_time_shift)}
        onChange={(e) => handleShiftChange('shift_start', e.target.value, shift.shift_id)}
        style={{ backgroundColor: dimColor(shift.shift_color, 0), border: 'none' }}
      />
      {'-'}
      <input
        type="time"
        className="timeOfDiens"
        defaultValue={formatTimeShift(shift.eind_time_shift)}
        onChange={(e) => handleShiftChange('shift_eind', e.target.value, shift.shift_id)}
        style={{ backgroundColor: dimColor(shift.shift_color, 0), border: 'none' }}
      />
    </div>
    <div className="col-2">
      <button onClick={() => handleSubmitShiftUpdate(shift.shift_id)} className="css-smal4s9" style={{ height: '30px' }}>
        <span className="css-23dsfxs">
          <i className="bx bx-pencil" style={{ fontSize: "18px" }}></i>
        </span>
      </button>
    </div>
  </div>
))}
                       
                       <Row>
        <Col xs="7">
          {t('Showing')} {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredData.length)} {t('of')} {filteredData.length}
        </Col>
        <Col xs="5">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
      </Row>
                      
                      </div>
                    )}

                    {shiftActiveTab === "tab_1" && (
                      <div id="tab_1">
                        {/* Tab 1 content */}
                        <div className="section_search col-12">
                          <div className="search-container">
                            <input
                              type="text"
                              className="search-input form-control"
                              placeholder={t("Search By..")}
                              onChange={handleSearchTeamChange}
                            />
                            <button className="css-smal4s9" type="button">
                              <span className="css-23dsfxs">
                                <i
                                  className="bx bx-search-alt-2"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>

    <div
    className="checkboxGroup row align-items-center"
    style={{position:'relative',display:'flex',justifyContent:'space-around',padding:'10px 22px'}}
    >
<div className="col-3">
      <div className="color-input-wrapper">
        <input 
          className="form-control-color" 
          type="color"
          value={color}
          onChange={handleColorChange}
        />
         <div 
        className="color-display"
        style={{ backgroundColor: color }}
        onClick={() => document.querySelector('.form-control-color').click()} // Trigger color picker on display click
      ></div>
      </div>
    </div>
  <div className="col-7">
    <input 
    className="form-control col-md-8" 
     type="text"
     placeholder={t("Add new Team")}
     onChange={handleNewTeamNamesChange}
     />
  </div>

  <div className="col-2" >
    <button className="css-smal4s9"  onClick={handlePostTeamDataChange} style={{height:'30px'}} type="button">
      <span className="css-23dsfxs">
        <i className="bx bx-plus" style={{ fontSize: "18px" }}></i>
      </span>
    </button>
  </div>
</div>
                        <label htmlFor="dienst_siade_check">{t('Update Team')}</label>
                        {paginatedTeams && paginatedTeams.length > 0 ? (
                          
  paginatedTeams.map((team, index) => (
    <div key={index} 
    className="checkboxGroup row align-items-center"
    style={{position:'relative',display:'flex',justifyContent:'space-around',padding:'10px 22px'}}
    >
 <div className="col-3">
      <div className="color-input-wrapper">
        <input 
          className="form-control-color-update" 
          type="color"
          value={colorupdate}
          onChange={handleColorUpdateChange}
        />
         <div 
        className="color-display"
        style={{ backgroundColor: colorupdate }}
        onClick={() => document.querySelector('.form-control-color-update').click()} // Trigger color picker on display click
      ></div>
      </div>
    </div>
  
  <div className="col-7">
    <input 
    className="form-control col-md-8" 
     type="text"
     defaultValue= {team.team_name}
     onChange={handleTeamnameUpdateChange}
     />
  </div>

  <div className="col-2" >
    <button className="css-smal4s9"  onClick={handlePutTeamDataChange}  style={{height:'30px'}} type="button">
      <span className="css-23dsfxs">
        <i className="bx bx-pencil" style={{ fontSize: "18px" }}></i>
      </span>
    </button>
  </div>
</div>
      


  ))
) : (
  <p>{t('No teams available.')}</p>
)}
                                     <Row>
        <Col xs="7">
          {t('Showing')} {currentPageTeam === 1 ? 1 : (currentPageTeam - 1) * itemsPerPage + 1} - {Math.min(currentPageTeam * itemsPerPage, filteredTeamData.length)} {t('of')} {filteredTeamData.length}
        </Col>
        <Col xs="5">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPageTeam === 1}
              onClick={() => handlePageChangeTeam(currentPageTeam - 1)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPageTeam === totalTeamPages}
              onClick={() => handlePageChangeTeam(currentPageTeam + 1)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
      </Row>
                     
                      </div>
                    )}
                  </div>
                </div>
              </div>
        
            
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(TeamAndShift));
