/* Shift */
export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA"
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS"
export const GET_ACCOUNT_DATA_FAIL = "GET_ACCOUNT_DATA_FAIL"

export const PUT_ACCOUNT_DATA = "PUT_ACCOUNT_DATA"
export const PUT_ACCOUNT_DATA_SUCCESS = "PUT_ACCOUNT_DATA_SUCCESS"
export const PUT_ACCOUNT_DATA_FAIL = "PUT_ACCOUNT_DATA_FAIL"

export const GET_ABSENCE_POLICY = "GET_ABSENCE_POLICY"
export const GET_ABSENCE_POLICY_SUCCESS = "GET_ABSENCE_POLICY_SUCCESS"
export const GET_ABSENCE_POLICY_FAIL = "GET_ABSENCE_POLICY_FAIL"

export const POST_ABSENCE_POLICY = "POST_ABSENCE_POLICY"
export const POST_ABSENCE_POLICY_SUCCESS = "POST_ABSENCE_POLICY_SUCCESS"
export const POST_ABSENCE_POLICY_FAIL = "POST_ABSENCE_POLICY_FAIL"

export const PUT_ABSENCE_POLICY = "PUT_ABSENCE_POLICY"
export const PUT_ABSENCE_POLICY_SUCCESS = "PUT_ABSENCE_POLICY_SUCCESS"
export const PUT_ABSENCE_POLICY_FAIL = "PUT_ABSENCE_POLICY_FAIL"

export const DELETE_ABSENCE_POLICY = "DELETE_ABSENCE_POLICY"
export const DELETE_ABSENCE_POLICY_SUCCESS = "DELETE_ABSENCE_POLICY_SUCCESS"
export const DELETE_ABSENCE_POLICY_FAIL = "DELETE_ABSENCE_POLICY_FAIL"


export const GET_ABSENCE_TYPE = "GET_ABSENCE_TYPE"
export const GET_ABSENCE_TYPE_SUCCESS = "GET_ABSENCE_TYPE_SUCCESS"
export const GET_ABSENCE_TYPE_FAIL = "GET_ABSENCE_TYPE_FAIL"

export const POST_ABSENCE_TYPE = "POST_ABSENCE_TYPE"
export const POST_ABSENCE_TYPE_SUCCESS = "POST_ABSENCE_TYPE_SUCCESS"
export const POST_ABSENCE_TYPE_FAIL = "POST_ABSENCE_TYPE_FAIL"

export const PUT_ABSENCE_TYPE = "PUT_ABSENCE_TYPE"
export const PUT_ABSENCE_TYPE_SUCCESS = "PUT_ABSENCE_TYPE_SUCCESS"
export const PUT_ABSENCE_TYPE_FAIL = "PUT_ABSENCE_TYPE_FAIL"

export const DELETE_ABSENCE_TYPE = "DELETE_ABSENCE_TYPE"
export const DELETE_ABSENCE_TYPE_SUCCESS = "DELETE_ABSENCE_TYPE_SUCCESS"
export const DELETE_ABSENCE_TYPE_FAIL = "DELETE_ABSENCE_TYPE_FAIL"

export const GET_ALL_ABSENCE = "GET_ALL_ABSENCE"
export const GET_ALL_ABSENCE_SUCCESS = "GET_ALL_ABSENCE_SUCCESS"
export const GET_ALL_ABSENCE_FAIL = "GET_ALL_ABSENCE_FAIL"

export const GET_ABSENCE = "GET_ABSENCE"
export const GET_ABSENCE_SUCCESS = "GET_ABSENCE_SUCCESS"
export const GET_ABSENCE_FAIL = "GET_ABSENCE_FAIL"

export const PUT_ABSENCE = "PUT_ABSENCE"
export const PUT_ABSENCE_SUCCESS = "PUT_ABSENCE_SUCCESS"
export const PUT_ABSENCE_FAIL = "PUT_ABSENCE_FAIL"

export const POST_ABSENCE = "POST_ABSENCE"
export const POST_ABSENCE_SUCCESS = "POST_ABSENCE_SUCCESS"
export const POST_ABSENCE_FAIL = "POST_ABSENCE_FAIL"

export const DELETE_ABSENCE = "DELETE_ABSENCE"
export const DELETE_ABSENCE_SUCCESS = "DELETE_ABSENCE_SUCCESS"
export const DELETE_ABSENCE_FAIL = "DELETE_ABSENCE_FAIL"


export const GET_CORRECTION = "GET_CORRECTION"
export const GET_CORRECTION_SUCCESS = "GET_CORRECTION_SUCCESS"
export const GET_CORRECTION_FAIL = "GET_CORRECTION_FAIL"

export const POST_CORRECTION = "POST_CORRECTION"
export const POST_CORRECTION_SUCCESS = "POST_CORRECTION_SUCCESS"
export const POST_CORRECTION_FAIL = "POST_CORRECTION_FAIL"

export const PUT_CORRECTION = "PUT_CORRECTION"
export const PUT_CORRECTION_SUCCESS = "PUT_CORRECTION_SUCCESS"
export const PUT_CORRECTION_FAIL = "PUT_CORRECTION_FAIL"

export const GET_FULL_ABSENCE_ACCESS = "GET_FULL_ABSENCE_ACCESS"
export const GET_FULL_ABSENCE_ACCESS_SUCCESS = "GET_FULL_ABSENCE_ACCESS_SUCCESS"
export const GET_FULL_ABSENCE_ACCESS_FAIL = "GET_FULL_ABSENCE_ACCESS_FAIL"


export const GET_ABSENCE_TOTAL = "GET_ABSENCE_TOTAL"
export const GET_ABSENCE_TOTAL_SUCCESS = "GET_ABSENCE_TOTAL_SUCCESS"
export const GET_ABSENCE_TOTAL_FAIL = "GET_ABSENCE_TOTAL_FAIL"

export const GET_HOURS_TOTAL = "GET_HOURS_TOTAL"
export const GET_HOURS_TOTAL_SUCCESS = "GET_HOURS_TOTAL_SUCCESS"
export const GET_HOURS_TOTAL_FAIL = "GET_HOURS_TOTAL_FAIL"

