import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  POST_REPORT_DATA,
  POST_REPORT_DATA_SUCCESS,
  POST_REPORT_DATA_FAIL,
  DELETE_REPORT_DATA,
  DELETE_REPORT_DATA_SUCCESS,
  DELETE_REPORT_DATA_FAIL,
  POST_REPORT_DATA_ABSENCE,
  POST_REPORT_DATA_ABSENCE_SUCCESS,
  POST_REPORT_DATA_ABSENCE_FAIL,
  GET_WEB_SETTING,
  GET_WEB_SETTING_SUCCESS,
  GET_WEB_SETTING_FAIL
  } from "./actionTypes";
  const INIT_STATE = {
    getreportdata: [],
    postreportdata: null,
    postreportdataabsence: null,
    removereportdata: null,
    loading: false,
    error: {},
    websetting: [],
  };
  const getReportData = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_WEB_SETTING:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_WEB_SETTING_SUCCESS:
      return {
      ...state,
      loading: false,
      websetting: action.payload,
      error: null,
      
      };
      
      case GET_WEB_SETTING_FAIL:
      return {
        ...state,
        loading: false,
        websetting: [],
        error: action.payload,
      };
      case GET_REPORT_DATA:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_REPORT_DATA_SUCCESS:
      return {
      ...state,
      loading: false,
      getreportdata: action.payload,
      error: null,
      
      };
      
      case GET_REPORT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        getreportdata: [],
        error: action.payload,
      };
      case POST_REPORT_DATA:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case POST_REPORT_DATA_SUCCESS:
      return {
      ...state,
      loading: false,
      postreportdata: action.payload,
      error: null,
      
      };
      
      case POST_REPORT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        postreportdata: null,
        error: action.payload,
      };

      case POST_REPORT_DATA_ABSENCE:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case POST_REPORT_DATA_ABSENCE_SUCCESS:
      return {
      ...state,
      loading: false,
      postreportdataabsence: action.payload,
      error: null,
      
      };
      
      case POST_REPORT_DATA_ABSENCE_FAIL:
      return {
        ...state,
        loading: false,
        postreportdataabsence: null,
        error: action.payload,
      };


      case DELETE_REPORT_DATA:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case DELETE_REPORT_DATA_SUCCESS:
      return {
      ...state,
      loading: false,
      removereportdata: action.payload,
      error: null,
      
      };
      
      case DELETE_REPORT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        removereportdata: null,
        error: action.payload,
      };
      

      default:
        return state;
    }
  };
  
  export default getReportData;