import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  TabContent,
  TabPane,
  Form ,
  UncontrolledCollapse
} from "reactstrap";
import AWS from 'aws-sdk';
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import classnames from "classnames";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";

import {
  getMailsLists as onGetMailsLists,
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  postContractType,
  postContract,
  postLinks,
  createfolder,
  selectFolders,
  putProfile,
} from "store/actions";
import Select from "react-select"
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import { startOfDay } from "date-fns";
import './style.css';
const S3_BUCKET = 'analyticscarg-public';
const REGION = 'us-east-2';
const ACCESS_KEY = 'AKIA2NSVDMLW6CSKQOFQ';
const SECRET_ACCESS_KEY = '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';
AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION
});
const s3 = new AWS.S3();
const Planning = (props) => {
  const { user_id } = useParams();
  const {
    client,
    xlsid,
    contractdata,
    postDeps,
    postTeams,
    postContractTypes,
    link,
    profile,
    error
  } = useSelector(state => ({
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    contractdata: state.getClientData.contractdata,
    postDeps: state.getClientData.postDeps,
    postTeams: state.getClientData.postTeams,
    postContractTypes: state.getClientData.postContractTypes,
    link: state.getClientData.link,
    profile: state.getClientData.profile,
    error: state.getClientData.error,
  }));
  
  const dispatch = useDispatch();
  const { t } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  useEffect(() => {
    document.title = t("Collaborator Details - Manage and Search Users | eSignatureHub");
  }, [t]);

  //meta title xlsid
  useEffect(() => {
    dispatch(onGetcollaborator(account_id));
    dispatch(getXlsdata(account_id));
    dispatch(onGetMailsLists(account_id));
    dispatch(selectFolders(account_id));
  }, [dispatch]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <div className="content-header">
      <div className="content-header__buttons">
        <div className="flex-1 flex">
          <div role="group" className="btn-group sub-header__buttons">
            <ul className="nav nav-pills nav-justified" role="tablist">
              <li className="nav-item waves-effect waves-light css-sd2sfag3" role="presentation">
                <a className="nav-link css-roster active" data-bs-toggle="tab" href="#home-1" role="tab" aria-selected="true">
                  <span className="d-block d-lg-none"><i className="fas fa-home"></i></span>
                  <span className="d-none d-lg-block">Dag</span>
                </a>
              </li>
              <li className="nav-item waves-effect waves-light css-sd2sfag3" role="presentation">
                <a className="nav-link css-roster" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="false" tabIndex="-1">
                <span className="d-block d-lg-none"><i className="far fa-user"></i></span>
                  <span className="d-none d-lg-block">Week</span>
                </a>
              </li>
              <li className="nav-item waves-effect waves-light css-sd2sfag3" role="presentation">
                <a className="nav-link css-roster" data-bs-toggle="tab" href="#messages-1" role="tab" aria-selected="false" tabIndex="-1">
                <span className="d-block d-lg-none"><i className="far fa-envelope"></i></span>
                  <span className="d-none d-lg-block">Maand</span>
                </a>
              </li>
            </ul>
          </div>
          <div role="group" className="btn-group sub-header__buttons">
            <button type="button" className="css-sq80gj" id="prev-month-btn">
              <span className="css-1tsc6yp">
                <span aria-label="Achteruit navigeren in datums" role="img" className="css-34iy07">
                <svg aria-hidden="true" focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 5l4 4" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
                </span>
                <span role="img" aria-label="Achteruit navigeren in datums" className="css-34iy07">
                <svg aria-hidden="true" focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 5l4 4" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
                </span>
              </span>
            </button>
            <button type="button" className="css-sq80gj">
              <input type="month" id="month-input" value="2023-10" style={{ width: '21px', border: 'none', outline: 'none', fontSize: '16px', color: '#1bbee3' }} />
            </button>
            <p className="css-10ewgza d-none d-lg-block" id="month-label">1 Sep - 30 Sep 2024</p>
            <button type="button" className="css-sq80gj" id="next-month-btn">
              <span className="css-1tsc6yp">
                <span aria-label="Vooruit navigeren in datums" role="img" className="css-34iy07">
                <svg aria-hidden="true"  focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9l4-4-4-4" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
                </span>
                <span role="img" aria-label="Vooruit navigeren in datums" className="css-34iy07">
                <svg aria-hidden="true"  focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9l4-4-4-4" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
                </span>
              </span>
            </button>
          </div>
          <div className="btn-group sub-header__buttons">
            <button className="olv-button olv-ignore-transform css-1m5o92us" type="button" data-bs-toggle="modal" data-bs-target="#staticservice">
              <icon role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </icon>
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">Planning toevoegen</span>
            </button>
            <button style={{ borderLeft:'1px solid',width: '20%' }} className="olv-button olv-ignore-transform css-1m5o92sus" type="button">
            <icon _ngcontent-jjn-c215="" role="img" className="css-23dsfxsu" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="sum">
        <i className="bx bx-chevron-down"></i>
        </icon>
            </button>
          </div>
          <div className="btn-group sub-header__buttons">
            <button className="olv-button olv-ignore-transform css-1m5o92us" type="button" data-bs-toggle="modal" data-bs-target="#dienst_tovoegt">
              <icon role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </icon>
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">Dienst toevoegen</span>
            </button>
          </div>
          <div className="btn-group sub-header__buttons">
            <button className="olv-button olv-ignore-transform css-1m92us" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              <icon role="img" className="css-230ofxs" aria-hidden="true">
                <i className="bx bx-chevron-left"></i>
              </icon>
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">Filters</span>
            </button>
            <button className="olv-button olv-ignore-transform css-1m92us" type="button" id="openModalButton" style={{ marginLeft: '10px' }}>
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">Alle afdelingen</span>
              <icon role="img" className="css-230ofxs" aria-hidden="true">
                <i className="bx bx-chevron-down"></i>
              </icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* header */}
    <div className="page__contentB">
    <div className="card card__schedule-optimized ng-star-inserted">
        <div className="loadingPage" id="loadingPage">
        <div
  className="spinner"
  style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: '16px',
    borderStyle: 'solid',
    borderColor: 'rgb(52, 152, 219) rgb(243, 243, 243) rgb(243, 243, 243)',
    borderImage: 'initial',
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    animation: '2s linear 0s infinite normal none running spin'
  }}
></div>
        </div>
        <div className="css-f23as4" id="loading-spinner" style={{display:'none'}}>
            <div className="spinner-page"></div>
        </div>
      <div className="top_reels" id="top_reels" style={{background:'#ffffff'}}>
      <div className="block-style-rows block-border-radius sticky_top sticky_fris">
      <div className="card-heading card-heading__table department-header-border">
        <div className="sticky-department-header-left-col card-heading__title card-heading__no-padding">
          <button
            className="btn btn-white button-icon-text"
            type="button"
            
          >
            <icon
              className="mat-icon notranslate si button-icon__icon mat-icon-no-color"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <i
                className="bx bx-chevron-right"
                style={{ position: 'relative', top: '1px', fontSize: '20px' }}
              ></i>
            </icon>
            <span className="css-1ixbps20l">Diensten/Teams</span>
          </button>
          <div id="schedultitle" className="schedultitle">
            <span
              id="date_currently"
              className="css-1ixbps20l"
              style={{ fontWeight: 600 }}
            >
              Teamcargo .B.V - Amazon Sep - 30 Sep 2024
            </span>
          </div>
        </div>
        <div className="sticky-department-header-right-col card-heading__button-group card-optimized-roster">
          <button
            className="olv-button olv-ignore-transform css-1m5o92us"
            type="button"
            
          >
            <icon
              className="css-23dsfxs"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <i
                className="bx bx-plus"
                style={{ fontSize: '20px' }}
              ></i>
            </icon>
            <span className="css-1ixbps20l d-none d-md-block">Dienst toevoegen</span>
          </button>
        </div>
      </div>
    </div>
{/* schdeul head */}
<div className="col-lg-12 no-left-padding-row">
<div className="css-k23serv3s blue-sv232 sticky_day">
      <div className="css-li23s54"></div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;1</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;2</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;3</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;4</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;5</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;6</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;7</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;8</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;9</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;10</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;11</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;12</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;13</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;14</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;15</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;16</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;17</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;18</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;19</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;20</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;21</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;22</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;23</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;24</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;25</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;26</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;27</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;28</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;29</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;30</div>
    </div>

    <div
      className="css-k23serv3s blue-sv232"
      style={{
        backgroundColor: 'rgba(133, 160, 133, 0.1)',
        borderBottom: '3px solid rgb(133, 160, 133)',
      }}
    >
      <div className="css-li23s54">
        <div className="row" style={{width:'100px'}}>
          <div className="col-6 css-23fs434">
            <span className="css-spanfs3" style={{border: '2px solid rgb(133, 160, 133)',}}>
              <i
                className="bx bx-chevron-right"
               
              ></i>
            </span>
          </div>
          <div className="col-6" style={{ display: 'inline-grid' }}>
            <span>Amazon</span>
            <small>Amazon</small>
          </div>
        </div>

      </div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;1</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;2</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;3</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;4</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;5</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;6</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;7</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;8</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;9</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;10</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;11</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;12</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;13</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;14</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;15</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;16</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;17</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;18</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;19</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;20</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;21</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;22</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;23</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Di&nbsp;24</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Wo&nbsp;25</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Do&nbsp;26</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Vr&nbsp;27</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Za&nbsp;28</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Zo&nbsp;29</div>
      <div className="css-s3o0sad3 css-s3o0fff3">Ma&nbsp;30</div>
    
    </div>

    <div className="css-k23serv3s">
      <div className="css-li23s54 border-style">Roosters</div>

      {/* Fixed Column with Totals */}
      <div className="fixed-total-colum">
        <span className="css-totals">Totals</span>
        <div className="timesheet-period-employee-total awesome-grid-right timesheet-period-grid">
          <div className="d-flex grid-full-height" style={{ width: '135px' }}>
            <div className="sh-totals-wrapper">
              <div className="sh-totals">
                <div className="sh-totals__time">00:00</div>
                <div className="sh-totals__salary">€0.00</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rooster Content */}
      <div className="css-s3o0sad3 border-style targethover" data_date={`Amazon_2024_9_1`}>
        <div className="add-40os34">
          <button
            className="olv-button olv-ignore-transform css-1m92us add-plus schedule-button"
            type="button"
            style={{ width: '100%' }}
          >
            <icon className="css-230ofxs" role="img" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="sum">
              <i className="bx bx-plus"></i>
            </icon>
          </button>
        </div>
      </div>

      <div className="css-s3o0sad3 border-style targethover" data_date="Amazon_2024_9_2" data_teamname="Amazon">
        <div className="css-block">
          <div className="schedule-template" id="schedule-template">
            <div className="new-btn_group me-2 edit-vds3g" role="group" style={{ opacity: 0 }}></div>
            <div
              className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts"
              draggable="true"
              style={{ backgroundColor: 'rgba(147, 153, 35, 0.1)', borderColor: '#286c2b' }}
            >
              <div className="relative left-0 top-0">
                <div
                  className="rounded-full h-[26px] top-[-13px] absolute min-w-[6px] w-[6px] ml-[2px]"
                  style={{ background: '#286c2b' }}
                ></div>
              </div>
              <div className="sh-tl__static w-full">
                <div className="flex">
                  <div className="flex relative left_7">
                    <span className="overflow-hidden sb-text-grey-600 width-75px relative text-ellipsis text-xs">
                      asdsad
                    </span>
                  </div>
                </div>
                <div className="block">
                  <div className="block relative left_7">
                    <span className="overflow-hidden sb-text-grey-600 width-40px relative text-ellipsis text-xs">
                      543454
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
</div>

{/* Overall Totals Below */}

      </div>

      </div>  

    </div>
   

{/* department-period-row */}

<div className="row" style={{ marginLeft: 'auto', marginRight: 'auto', padding: 'unset' }}>
  <div className="col-10 sectionTitle_top" style={{ display: 'none' }}>
    <div className="row seconRow_top">
      <div className="col-9 no-left-padding-row">
        <div className="btnmod">
          <button
            className="olv-button olv-ignore-transform css-1m92us ex-css3s4"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <icon
              role="img"
              className="css-230ofxs"
              aria-hidden="true"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <i className="bx bx-chevron-right"></i>
            </icon>
            <span data-qa="submit-username-text" className="css-1ixbps20l">
              Diensten/Teams
            </span>
          </button>
        </div>
      </div>
      <div className="col-3 no-left-padding-row">
        <div>
          <button
            className="olv-button olv-ignore-transform css-1m5o92us"
            type="button"
            style={{ width: '80%' }}
          >
            <icon
              role="img"
              className="css-23dsfxs"
              aria-hidden="true"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <svg
                filename="sum.svg"
                viewBox="0 0 16 16"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
              </svg>
            </icon>
            <span data-qa="submit-username-text" className="css-1ixbps20l">
              Dienst toevoegen
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="row css-k23serv3s ">

</div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(Planning));

