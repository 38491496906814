import React, { useRef, useState, useEffect } from 'react';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import { map } from "lodash";
import '../../assets/scss/theme.scss';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Document, Page,pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
    UncontrolledCollapse
  } from "reactstrap";
  import { withTranslation } from "react-i18next";
  import withRouter from "components/Common/withRouter";
const PreviewModal = ({ 
   docNames ,recipientNames,t
    }) => {
     
      useEffect(() => {
        toastr.options = {
          positionClass: 'toast-top-center',
          closeButton: true,
          debug: true,
          progressBar:true,
          timeOut: 30000, // Duration in milliseconds
        };
      }, []);
      const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [token, settoken] = useState(user.token);
  const { uuid } = useParams();
  const [selectedOption, setSelectedOption] = useState("");
  const [screenSize, setScreenSize] = useState('desktop');
  const [numPages, setNumPages] = useState(null);
  let [scale, setScale] = useState('1');
  const [pdfUrls, setPdfUrl] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const pdfContainerX = useRef();
  const pdfContainerY = useRef();
  const [totalImageHeight, setTotalImageHeight] = useState(0);
  const [previwe, setPreviwe] = useState(true);
  function tog_togglepreviwe() {
    setPreviwe(!previwe);
    
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const changeSize = (newSize) => {
    const fixTopElement = document.getElementById('fix-top');
const PdfViewerContaine = document.getElementById('PdfViewerContaine');
    switch (newSize) {
      case 'desktop':
        fixTopElement.style.width = '100%'; // Adjust this value as needed
        fixTopElement.style.backgroundColor = 'lightblue'; // Example background color
        PdfViewerContaine.style.width = '100%';
        PdfViewerContaine.style.width = '100%';
        document.getElementById('pdf_content').style.position ='relative'
        document.getElementById('pdf_content').style.left ='44px'
        setScale('1')
        // Add any additional styles for desktop
        break;
      case 'tablet':
        fixTopElement.style.width = '80vw'; // Adjust this value as needed
        fixTopElement.style.backgroundColor = 'lightgreen'; // Example background color
        PdfViewerContaine.style.width = '80vw';
        PdfViewerContaine.style.width = '80vw';
        document.getElementById('pdf_content').style.position ='relative'
        document.getElementById('pdf_content').style.left ='44px'
        setScale('0.8')
        break;
      case 'mobile':
        fixTopElement.style.width = '50vw'; // Adjust this value as needed
        fixTopElement.style.backgroundColor = 'lightcoral'; // Example background color
        PdfViewerContaine.style.width = '50vw';
        PdfViewerContaine.style.width = '50vw';
        document.getElementById('pdf_content').style.position ='relative'
        document.getElementById('pdf_content').style.left ='44px'
        setScale('0.7')
        break;
      default:
        setScale('1')
        break;
    }

    setScreenSize(newSize);
  };
  const isActive = (size) => size === screenSize;

  return (
    <Modal
    size="xl"
      isOpen={previwe}
      toggle={() => {
        tog_togglepreviwe();
      }}
      className="modal-fullscreen"
    >
        <div className="modal-header">
        <div className="css-ml9n4n">
<div className="css-pre3sf">
<div className="custom-select">
<span className="css-13dzsk6"> {t('Select Recipients')} </span>

</div>  
</div>
</div>
<Nav tabs className="nav-tabs-custom nav-justified css-35sd45ul">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                      
                        onClick={() => changeSize('desktop')}
                      >
                      <button 
                      
                     className={`css-it5yrk ${isActive('desktop') ? 'btn-active' : ''}`}
                       >
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" >
<path d="M21 4H3a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h6v2H7v1h10v-1h-2v-2h6a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-8 17h-2v-2h2zm7-4H4v-2h16zm0-3H4V6h16z"></path>
</svg>
<span className="css-guny5b">{t('Preview on computer monitor selected')}</span>
</button>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        onClick={() => changeSize('tablet')}
                      >
                         <button 
                        className={`css-it5yrk ${isActive('tablet') ? 'btn-active' : ''}`}
                         >
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" focusable="false" data-qa="icon-element-tablet">
<path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-3 2v12H7V6zM4 6h2v5H5v2h1v5H4zm16 12h-2V6h2z"></path>
</svg>
<span className="css-guny5b">{t('Preview on tablet')}</span>
</button>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                      
                    onClick={() => changeSize('mobile')}
                      >
          
<button 
 className={`css-it5yrk ${isActive('mobile') ? 'btn-active' : ''}`}
>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true">
<path d="M18 2H6a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-1 2v13H7V4zM7 20v-2h4v1h2v-1h4v2z"></path>
</svg>
<span className="css-guny5b">{t('Preview on mobile phone')}</span>
</button>
                      </NavLink>
                    </NavItem>
                  </Nav>
        <button type="button" className="btn-close"
          onClick={() => {
            setPreviwe(false);
          }} aria-label="Close"></button>
      </div>
      <ModalBody>
        
   <div id='fix-top' className="fixed-top css-ms32d5" style={{ position: 'relative',width: '100%'  }}>
      <div id="top-message" style={{ display: 'none' }}>
        {/* Styles moved to styles.css */}
        <div id="cookie-disclaimer">
          <div>
          {t('This site uses cookies, some of which are required for the operation of the site.')} {' '}
            <a
              href="https://www.docusign.com/company/privacy-policy"
              id="privacy-policy-link"
              target="privacyPolicy"
            >
             {t('Learn more')} 
              <span className="screen-reader-text">{t('This link will open in a new window.')}</span>
              <span className="icon icon-external"></span>
            </a>
            <button type="button" id="cookie-disclaimer-accept" className="btn">
            {t('OK')} 
            </button>
          </div>
        </div>
      </div>
      {/* The rest of your HTML goes here */}
      <div id="action-bar-wrapper">
        <div id="action-bar">
          <div className="wrapper" style={{ float: 'right' }}>
            <div id="action-bar-consent-control" style={{ display: 'block' }}>
              <div className="row">
                <div className="cell hidden-xs">
              
                  <div className="css-zx8agq">
                    <button  className="css-gusat5s" type="button">
                      <span className="css-vxcmzt">{t('Finish')}</span>
                      </button>
                      </div>
                </div>
                <div className="cell action-bar-consent-message">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='css-23sad4'id='PdfViewerContaine'>
     
      <div className="css-quz66i " >
     
     <div className="css-1sdoc " id='pdf_content'>
         
     <div style={{ flexGrow: '1' }} id='pdf-wrapper'>

   
       </div>
     </div>


</div>
      </div>
     
    </div>
    </ModalBody>
    </Modal>
 
  );
};

export default withTranslation()(withRouter(PreviewModal));
