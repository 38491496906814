import React, { useRef, useEffect, useState } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import axios from 'axios';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
export function PdfViewer({ zoom, docNames ,setIsAllowed,t}) {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(zoom);
  const [pdfUrls, setPdfUrl] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const pdfContainerX = useRef();
  const pdfContainerY = useRef();
  const [totalImageHeight, setTotalImageHeight] = useState(0);
  
  const renderPage = async (pageNumber) => {
    try {
      const loadingTask = pdfjs.getDocument(pdfUrls);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const canvasWidth = viewport.width;
      const canvasHeight = viewport.height;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      pdfContainerX.current = canvas.width;
      pdfContainerY.current = canvas.height;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const dataUrl = canvas.toDataURL('image/png');
      setImageUrls((prevUrls) => [...prevUrls, { url: dataUrl, height: canvasHeight }]);
    } catch (error) {
      console.error('Error rendering page:', error);
    }
  };

  useEffect(() => {
    const fetchPdf = async () => {
      try {
      
        const response = await axios.get(`${docNames}`, {
          responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        
        const url = URL.createObjectURL(blob);
        
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [docNames]);

  useEffect(() => {
    const renderPdf = async () => {
      try {
        const loadingTask = pdfjs.getDocument(pdfUrls);
        const pdf = await loadingTask.promise;
        
        setNumPages(pdf.numPages);
        const firstPage = await pdf.getPage(1);
        const firstPageViewport = firstPage.getViewport({ scale });
        pdfContainerX.current = firstPageViewport.width;
        pdfContainerY.current = firstPageViewport.height;
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    if (pdfUrls) {
      renderPdf();
    }
  }, [pdfUrls, scale]);

  useEffect(() => {
    setImageUrls([]); // Clear existing image URLs before rendering new pages
    setTotalImageHeight(0);

    const renderAllPages = async () => {
      for (let i = 1; i <= numPages; i++) {
        await renderPage(i);
      }
    };

    if (numPages) {
      renderAllPages();
    }
  }, [numPages, scale, pdfUrls]);

  useEffect(() => {
    setScale(zoom);
  }, [zoom]);

  useEffect(() => {
    setTotalImageHeight(
      imageUrls.reduce((totalHeight, imageData) => {
        return totalHeight + imageData.height;
      }, 0)
    );
  }, [imageUrls]);

  return (
    <div style={{ flexGrow: '1' }} id='pdf-wrapper'>
    {totalImageHeight > 0 && (  // Check if totalImageHeight is greater than 0
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        className="pdf-container"
        id="pdf-container"
        version="1.1"
        data-type="allowedType"
        data-total={numPages}
        viewBox={`0 0 ${pdfContainerX.current} ${totalImageHeight}`}
        width={pdfContainerX.current * scale}
        height={totalImageHeight * scale}
      >
        <foreignObject width={pdfContainerX.current * scale} height={totalImageHeight * scale}
        id='foreignObject'
        >
          {imageUrls.map((imageData, index) => (
            <img
              key={index}
              src={imageData.url}
              className="disable-mouse-events "
              alt={`Page ${index + 1}`}
              width={pdfContainerX.current * scale}
              height={imageData.height * scale}
              data-type="allowedType"
              data-dm={'size'}
              page={`${index + 1}`}
            />
          ))}
        </foreignObject>
      </svg>
    )}
    </div>
  );
}
export function PdfPagination({docNames,t}) {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(0.25);
 const [pdfUrls, setPdfUrl] = useState('');
 const pdfContainerX = useRef();
 const pdfContainerY = useRef();
 const [imageUrls, setImageUrls] = useState([]);
  const renderPage = async (pageNumber) => {
    
    try {
      const loadingTask = pdfjs.getDocument(pdfUrls);
      const pdf = await loadingTask.promise;

      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const canvasWidth = viewport.width 
      const canvasHeight = viewport.height 
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      pdfContainerX.current = canvas.width;
      pdfContainerY.current = canvas.height;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const dataUrl = canvas.toDataURL('image/png');
      setImageUrls((prevUrls) => [...prevUrls, { url: dataUrl, height: canvasHeight }]);
    } catch (error) {
      console.error('Error rendering page:', error);
    }
  };

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(`${docNames}`, {
          responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error Pagination fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [docNames]);

  useEffect(() => {
    const renderPdf = async () => {
      try {
        const loadingTask = pdfjs.getDocument(pdfUrls);
        const pdf = await loadingTask.promise;

        setNumPages(pdf.numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    if (pdfUrls) {
      renderPdf();
    }
  }, [pdfUrls]);

  useEffect(() => {
    setImageUrls([]); // Clear existing image URLs before rendering new pages
  
    const renderAllPages = async () => {
      for (let i = 1; i <= numPages; i++) {
        await renderPage(i);
      }
    };
  
    if (numPages) {
      renderAllPages();
    }
  }, [numPages, scale, pdfUrls]);
  const totalImageHeight = imageUrls.reduce((totalHeight, imageData) => {
    return totalHeight + imageData.height;
  }, 0);

  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg"
    focusable="false" 
    className="pdf-pageContent"
    id="pdf-pageContent" 
    version="1.1"
      //  onMouseMove={onMouseMove}
      viewBox={`0 0 ${pdfContainerX.current} ${totalImageHeight}`}
      width={pdfContainerX.current}
    
    
     >
          <foreignObject 
          width={pdfContainerX.current}
          height={totalImageHeight}
           >
    
    {imageUrls.map((imageData, index) => (
      <div  key={index}>
          <img
            key={index}
            src={imageData.url}
            className='disable-mouse-events'
            alt={`${t('Page')} ${index + 1}`}
            width={pdfContainerX.current}
            height={imageData.height - 20} 
          />
          <div className='pageIndexID'>{t('Page')} {index + 1}</div>
          </div>
        ))}
        
      </foreignObject>
          </svg>
  
  );
}

export default withTranslation()(withRouter(PdfViewer));
export const PDFViewerWrapped = withTranslation()(withRouter(PdfPagination));