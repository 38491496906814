export const GET_MAILS_LIST = "GET_MAILS_LIST"
export const GET_MAILS_LIST_SUCCESS = "GET_MAILS_LIST_SUCCESS"
export const GET_MAILS_LIST_FAIL = "GET_MAILS_LIST_FAIL"

//select folder
export const SELECT_FOLDER = "SELECT_FOLDER"
export const SELECT_FOLDER_SUCCESS = "SELECT_FOLDER_SUCCESS"
export const SELECT_FOLDER_FAIL = "SELECT_FOLDER_FAIL"

//Selected mail
export const GET_SELECTED_MAILS = "GET_SELECTED_MAILS"
export const GET_SELECTED_MAILS_SUCCESS = "GET_SELECTED_MAILS_SUCCESS"
export const GET_SELECTED_MAILS_FAIL = "GET_SELECTED_MAILS_FAIL"

//set folder on selected mails
export const SET_FOLDER_SELECTED_MAILS = "SET_FOLDER_SELECTED_MAILS"
export const SET_FOLDER_SELECTED_MAILS_SUCCESS = "SET_FOLDER_SELECTED_MAILS_SUCCESS"
export const SET_FOLDER_SELECTED_MAILS_FAIL = "SET_FOLDER_SELECTED_MAILS_FAIL"

//update mail
export const UPDATE_MAIL = "UPDATE_MAIL"
export const UPDATE_MAIL_SUCCESS = "UPDATE_MAIL_SUCCESS"
export const UPDATE_MAIL_FAIL = "UPDATE_MAIL_FAIL"

//SET_RESEND folder
export const SET_RESEND = "SET_RESEND"
export const SET_RESEND_SUCCESS = "SET_RESEND_SUCCESS"
export const SET_RESEND_FAIL = "SET_RESEND_FAIL"

export const UPDATE_STATUS = "UPDATE_STATUS"
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS"
export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL"

export const DELETE_POST_DATA = "DELETE_POST_DATA"
export const DELETE_POST_DATA_SUCCESS = "DELETE_POST_DATA_SUCCESS"
export const DELETE_POST_DATA_FAIL = "DELETE_POST_DATA_FAIL"

export const UPDATE_POST_FOLDER = "UPDATE_POST_FOLDER"
export const UPDATE_POST_FOLDER_SUCCESS = "UPDATE_POST_FOLDER_SUCCESS"
export const UPDATE_POST_FOLDER_FAIL = "UPDATE_POST_FOLDER_FAIL"
//SET_VALID_DATE folder
export const SET_VALID_DATE = "SET_VALID_DATE"
export const SET_VALID_DATE_SUCCESS = "SET_VALID_DATE_SUCCESS"
export const SET_VALID_DATE_FAIL = "SET_VALID_DATE_FAIL"

//CREATE_FOLDER folder
export const CREATE_FOLDER = "CREATE_FOLDER"
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS"
export const CREATE_FOLDER_FAIL = "CREATE_FOLDER_FAIL"

//CREATE_FOLDER folder
export const UPDATE_FOLDER = "UPDATE_FOLDER"
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS"
export const UPDATE_FOLDER_FAIL = "UPDATE_FOLDER_FAIL"

//PERMANENTLY_DELETE  mail
export const PERMANENTLY_DELETE = "PERMANENTLY_DELETE"
export const PERMANENTLY_DELETE_SUCCESS = "PERMANENTLY_DELETE_SUCCESS"
export const PERMANENTLY_DELETE_FAIL = "PERMANENTLY_DELETE_FAIL"

//recovery mail
export const RECOVERY_MAIL = "RECOVERY_MAIL"
export const RECOVERY_MAIL_SUCCESS = "RECOVERY_MAIL_SUCCESS"
export const RECOVERY_MAIL_FAIL = "RECOVERY_MAIL_FAIL"

//Account Close
export const ACCOUNT_CLOSE = "ACCOUNT_CLOSE"
export const ACCOUNT_CLOSE_SUCCESS = "ACCOUNT_CLOSE_SUCCESS"
export const ACCOUNT_CLOSE_FAIL = "ACCOUNT_CLOSE_FAIL"

//Account PROFILE
export const ACCOUNT_PROFILE = "ACCOUNT_PROFILE"
export const ACCOUNT_PROFILE_SUCCESS = "ACCOUNT_PROFILE_SUCCESS"
export const ACCOUNT_PROFILE_FAIL = "ACCOUNT_PROFILE_FAIL"

export const EDIT_ACCOUNT_PROFILE = "EDIT_ACCOUNT_PROFILE"
export const EDIT_ACCOUNT_PROFILE_SUCCESS = "EDIT_ACCOUNT_PROFILE_SUCCESS"
export const EDIT_ACCOUNT_PROFILE_FAIL = "EDIT_ACCOUNT_PROFILE_FAIL"
//Get Account INVOICE
export const GET_ACCOUNT_INVOICE = "GET_ACCOUNT_INVOICE"
export const GET_ACCOUNT_INVOICE_SUCCESS = "GET_ACCOUNT_INVOICE_SUCCESS"
export const GET_ACCOUNT_INVOICE_FAIL = "GET_ACCOUNT_INVOICE_FAIL"

//BRANDING 
export const GET_BRANDING_INFO = "BRANDING_INFO"
export const GET_BRANDING_INFO_SUCCESS = "BRANDING_INFO_SUCCESS"
export const GET_BRANDING_INFO_FAIL = "BRANDING_INFO_FAIL"

export const POST_BRANDING_INFO = "POST_BRANDING_INFO"
export const POST_BRANDING_INFO_SUCCESS = "POST_BRANDING_INFO_SUCCESS"
export const POST_BRANDING_INFO_FAIL = "POST_BRANDING_INFO_FAIL"

export const PUT_BRANDING_INFO = "PUT_BRANDING_INFO"
export const PUT_BRANDING_INFO_SUCCESS = "PUT_BRANDING_INFO_SUCCESS"
export const PUT_BRANDING_INFO_FAIL = "PUT_BRANDING_INFO_FAIL"

export const DELETE_BRANDING_INFO = "DELETE_BRANDING_INFO"
export const DELETE_BRANDING_INFO_SUCCESS = "DELETE_BRANDING_INFO_SUCCESS"
export const DELETE_BRANDING_INFO_FAIL = "DELETE_BRANDING_INFO_FAIL"


//Template 
export const GET_TEMPLATE_INFO = "GET_TEMPLATE_INFO"
export const GET_TEMPLATE_INFO_SUCCESS = "GET_TEMPLATE_INFO_SUCCESS"
export const GET_TEMPLATE_INFO_FAIL = "GET_TEMPLATE_INFO_FAIL"

export const POST_TEMPLATE_INFO = "POST_TEMPLATE_INFO"
export const POST_TEMPLATE_INFO_SUCCESS = "POST_TEMPLATE_INFO_SUCCESS"
export const POST_TEMPLATE_INFO_FAIL = "POST_TEMPLATE_INFO_FAIL"

export const PUT_TEMPLATE_INFO = "PUT_TEMPLATE_INFO"
export const PUT_TEMPLATE_INFO_SUCCESS = "PUT_TEMPLATE_INFO_SUCCESS"
export const PUT_TEMPLATE_INFO_FAIL = "PUT_TEMPLATE_INFO_FAIL"

export const DELETE_TEMPLATE_INFO = "DELETE_TEMPLATE_INFO"
export const DELETE_TEMPLATE_INFO_SUCCESS = "DELETE_TEMPLATE_INFO_SUCCESS"
export const DELETE_TEMPLATE_INFO_FAIL = "DELETE_TEMPLATE_INFO_FAIL"

//Users 
export const GET_USERS_INFO = "GET_USERS_INFO"
export const GET_USERS_INFO_SUCCESS = "GET_USERS_INFO_SUCCESS"
export const GET_USERS_INFO_FAIL = "GET_USERS_INFO_FAIL"

export const POST_USERS_INFO = "POST_USERS_INFO"
export const POST_USERS_INFO_SUCCESS = "POST_USERS_INFO_SUCCESS"
export const POST_USERS_INFO_FAIL = "POST_USERS_INFO_FAIL"

export const PUT_USERS_INFO = "PUT_USERS_INFO"
export const PUT_USERS_INFO_SUCCESS = "PUT_USERS_INFO_SUCCESS"
export const PUT_USERS_INFO_FAIL = "PUT_USERS_INFO_FAIL"

export const DELETE_USERS_INFO = "DELETE_USERS_INFO"
export const DELETE_USERS_INFO_SUCCESS = "DELETE_USERS_INFO_SUCCESS"
export const DELETE_USERS_INFO_FAIL = "DELETE_USERS_INFO_FAIL"

// PErmission

export const GER_PERMISSION_CONTENT = "GER_PERMISSION_CONTENT"
export const GER_PERMISSION_CONTENT_SUCCESS = "GER_PERMISSION_CONTENT_SUCCESS"
export const GER_PERMISSION_CONTENT_FAIL = "GER_PERMISSION_CONTENT_FAIL"


export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"