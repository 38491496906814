import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Label,
} from "reactstrap";
import { v4 as uuidv4 } from 'uuid';

import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const Addreciepien = (props) => {
  const { t,onRecipientDataChange } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [fristname, setFristname] = useState(user.firstName);
  const [lastname, setLastname] = useState(user.lastName);
  const [token, setToken] = useState(user.token);
  const [email, setEmail] = useState(user.email);
  const [userId, setuserId] = useState(user.clientId);
  const [signatureImge, setSignatureImge] = useState(user.signature);
  const [paraaf, setParaaf] = useState(user.paraaf);
  const [stemp, setStemp] = useState(user.stemp);
  const fullNames = `${fristname} ${lastname}`;
  const [rowCount, setRowCount] = useState(1);
  const [rowColors, setRowColors] = useState(['#c30707']);
  const generateUniqueId = () => {
    return uuidv4();
  };
  const [recipients, setRecipients] = useState([
    {
       recipientName: "",
       recipientEmail: "", 
       recipientMsg: "",
       recipientroll: "true",
       recipientExpire: "",
       color: '#2463d1',
       recipienid:generateUniqueId(),
       }
  ]);
  const [recipientsSelf, setRecipientsSelf] = useState([
    {
       recipientName: fullNames,
       recipientEmail: email, 
       signatureImge:signatureImge,
       paraaf:paraaf,
       stemp:stemp,
       recipientMsg: "please....",
       recipientroll: "true",
       recipientExpire: "not",
       color: '#2463d1',
       recipienid:generateUniqueId(),
       }
  ]);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpireChecked, setIsExpireChecked] = useState(false);

  const [showNextDiv, setShowNextDiv] = useState(false);

  const handleAddRow = () => {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    setRowColors(prevColors => [...prevColors, randomColor]);
    setRecipients((prevRecipients) => [
      ...prevRecipients,
      {
        recipientName: "",
        recipientEmail: "",
        recipientMsg: "",
        recipientroll: "true",
        recipientExpire: "",
        color:randomColor,
        recipienid:generateUniqueId(),
      }
    ]);
  };
  const handleDeleteRow = (index) => {
    if (recipients.length === 1) {
      return; // Don't delete the last row
    }

    setRecipients((prevRecipients) => {
      const updatedRecipients = [...prevRecipients];
      updatedRecipients.splice(index, 1);
      return updatedRecipients;
    });
  };

  const handleRecipientInputChange = (event, index) => {
    const { name, value } = event.target;
    setRecipients((prevRecipients) => {
      const updatedRecipients = [...prevRecipients];
      updatedRecipients[index] = {
        ...updatedRecipients[index],
        [name]: value
      };
      return updatedRecipients;
    });
    // Call the callback to update parent component's recipients data
    if (isChecked) {
      // If the checkbox is checked, use recipientsSelf data
      onRecipientDataChange(recipientsSelf , status="nobody");
    } else {
      // If the checkbox is not checked, use recipients data
      onRecipientDataChange(recipients , status="anybody");
    }
  };
 

  const divStyle = {
    display: "flex", 
    margin: "16px 0px", 
  };
  const handleCheckboxExpire = () => {
    setIsExpireChecked(!isExpireChecked); // Toggle the checkbox state

  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state
    // Call the callback to update parent component's recipients data
    if (!isChecked) {
      // If the checkbox is now checked, use recipientsSelf data
      onRecipientDataChange(recipientsSelf);
    } else {
      // If the checkbox is now unchecked, use recipients data
      onRecipientDataChange(recipients);
    }
  };
  const handleMouseEnter = () => {
    setShowNextDiv(true);
  };

  const handleMouseLeave = () => {
    setShowNextDiv(false);
  };
  return (
    <Card className="card">
      <CardBody className="card-body">

    <div style={divStyle}>
      <div>
      <div className="css-1e0bg48">
    <Input 
     id="css3tgHxyM33A"
      type="checkbox" 
      className="css-check3sdw form-check-input" 
      defaultChecked={isChecked}
      onClick={handleCheckboxChange}
      />
    <Label
      className="form-check-label"
      htmlFor="css3tgHxyM33A"
      >
    <span>
    <span className="css-1cbszci">{t('I am the only signer')}</span>
</span>
</Label>
</div>
      </div>
      {showNextDiv && (
        <div className="css-3nkkse" id="css-3nkkse">
          <p className="css-1sad4s">
          {t('As the only signer, you can place fields and sign your document. Once you  are done, it will be saved in your account.')}  
          </p>
        </div>
      )}
     
      <div
      className={`qouteStyle ${showNextDiv ? "active" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
         >

  <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
  <span className="css-1rpan28">
    <span className="css-zf56fy">
      <span aria-hidden="true" className="SVGInline">
        <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
  <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
</svg>
</span>
</span>
</span>
<span className="css-1pdgtej">{t('Learn more')}</span>
</button>
</div>
    </div>

          <div id="rows">
            
            {recipients.map((recipient, index) => (
              <div
               className={`row increaseRow ${isChecked ? "hidden" : ""}`} 
               style={{ display: isChecked ? "none" : "flex" }}
               key={`recipient-${index}`}
               >
                <Label id="del-lable" className="del-lable" style={{ margin: '16px 0px 8px 0px' }}>
                  <div className="row">
                    <div className="col-10">
                      <span style={{ fontSize: '17px' }}>{t('Add recipient')}</span>
                    </div>
                    <div className="col-2">
                    <Button
                          className="btn btn-danger btn-sm rounded-0 delete"
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Delete"
                          onClick={() => handleDeleteRow(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>            
                     </div>
                  </div>
                </Label>
                <div className="col-md-6" style={{ marginBottom: '15px', borderLeft: `8px solid ${recipient.color}` }} key={index}>
                  <div className="mb-3">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bx bxs-user-rectangle lg"></i>
                      </div>
                      <Input 
                      className="form-control" 
                      name="recipientName"  
                      list="datalistOptions"
                      id="fullname" 
                      placeholder={t('Type to full name')}
                      value={recipient.recipientName}
               onChange={(event) => handleRecipientInputChange(event, index)}
                      />
                    </div>
                    <datalist id="datalistOptions"></datalist>
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bx bx-mail-send lg"></i>
                      </div>
                      <Input 
                      className="form-control" 
                      name="recipientEmail"  
                      list="datalistOptions1" 
                      id="email" 
                      placeholder={t('Type to email')}
                      value={recipient.recipientEmail}
  onChange={(event) => handleRecipientInputChange(event, index)}
                       />
                    </div>
                    <datalist id="datalistOptions1"></datalist>
                  </div>
                  <div className="form-floating mb-3">
                  
                  <div className="form-floating mb-3">
  <Input
    type="text"
    name="recipientMsg"
    className="form-control "
    id="SendMessages"
    placeholder="Enter Name"
    value={recipient.recipientMsg} // Update the value attribute
    onChange={(event) => handleRecipientInputChange(event, index)} // Handle changes
  />
  <Label>{t('Messages')}</Label>
</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <Label className="form-label">{t('Messages')} </Label>
                <select
                name="recipientroll"
                className="form-select "
                id="Statusoptin"
                aria-label="Floating label select example"
                value={recipient.recipientroll} // Update the value attribute
                onChange={(event) => handleRecipientInputChange(event, index)} // Handle changes
                >
                <option value="true">{t('Needs to Sign')}</option>
                <option value="false">{t('In Person Signer')}</option>
                </select>
                
                <div className="css-1e0bg48 css-1443df4">
      <input
        type="checkbox"
        className="css-check3sdw form-check-input"
        defaultChecked={isExpireChecked}
        onClick={handleCheckboxExpire}
        id="css3tgHxyM3dfsd"
      />
      <label className="form-check-label" htmlFor="css3tgHxyM3dfsd">
        <span>
          <span className="css-1cbszci">{t('Include an expire date.')}</span>
        </span>
      </label>

      {isExpireChecked && (
        <>
          <label className="form-label css-1443df4">{t('Add expire time')}</label>
          <Input
            type="datetime-local"
            name="recipientExpire"
            className="form-control"
            id="AddExpireTime"
            value={recipients.recipientExpire} // Update the value attribute
            onChange={(event) => handleRecipientInputChange(event, index)} // Handle changes
          />
        </>
      )}
    </div>
                </div>
              </div>
              ))}
              <Button type="button" id="addbtn" className="btn startNowbtn" onClick={handleAddRow}>
              {t('Add Reciepien')}
            </Button>
          </div>

          <div>
          </div>
     
      </CardBody>
    </Card>
  );
};

export default withTranslation()(withRouter(Addreciepien));

