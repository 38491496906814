import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import {
  getTemplate ,
  deleteTemplate,
} from "store/actions";
import { withTranslation } from "react-i18next";
const Template = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setUserId] = useState(user.clientId);
  const [account_id, setAccountId] = useState(user.account_id);
  const [token, setToken] = useState(user.account_token);
  const {
    template,
    deletetemp,
    loading,
    error
  } = useSelector(state => ({
    template: state.Documents.template,
    deletetemp: state.Documents.deletetemp,
    loading: state.Documents.loading,
    error: state.Documents.error,
  }));
  useEffect(() => {
    document.title = t("Signature Document Template - Create and Manage with Shiftspace.com");
  }, [t]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(getTemplate(token));
    }
  }, [dispatch, token]);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggleDropdown = (signId) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [signId]: !prevState[signId] // Toggle the state for the given signId
    }));
  };
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page
  // Calculate index of the last item of current page
  const indexOfLastItem = currentPage * itemsPerPage;
  // Calculate index of the first item of current page
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Slice the array to get the current items to display
  const currentItems = template.slice(indexOfFirstItem, indexOfLastItem)

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Number of pages
  const totalPages = Math.ceil(template.length / itemsPerPage);

  const deleteItem = (id) => {
    dispatch(deleteTemplate(id));
   
  };
  const editItemHandler = (uuid) => {
   navigate(`/api/prepare/${uuid}`);
       
      };
  useEffect(() => {
   
    if (deletetemp.success) {
      toastr.success(deletetemp.message);
      dispatch(getTemplate(token));
    }else{
      dispatch(getTemplate(token));
    }
  
  }, [deletetemp,dispatch]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t('Templates')}
            breadcrumbItemLink="/home"
            breadcrumbItem={t('Back To Home')}
          />
          <div className="row">
            <div className="col-lg-12">
              <Card>
                <CardBody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item) => (
                      <div className="css-7r1z99" style={{ margin: '7px 7px 0px 0px' }} key={item.post_sign_id}>
                        <div className="css-6t6lyd">
                          <div className="css-1shlyym">
                            <span className="cardHoverButton css-1qq4o7v">
                              <button type="button" className="olv-button olv-ignore-transform css-1rc02wc css-1qq4o7vbtns">
                                <a aria-hidden="true" className="css-1ixbp0l">{t('Edit')}</a>
                                <span className="css-1am6aje">{t('Edit')}</span>
                              </button>
                            </span>
                            {/* <span className="css-78lt08">
                              <span className="css-naupo3">Default</span>
                            </span> */}
                            <div className="css-32d82q">
                              <span className="css-150ral2">
                                {/* Embed PDF file using iframe */}
                                <iframe
                                  src={item.doc_name}
                                  width="100%"
                                  height="600px"
                                  style={{ border: 'none' }}
                                  title="PDF Viewer"
                                ></iframe>
                              </span> 
                            </div>
                          </div>
                        </div>
                        <div className="css-6t6lyd">
                          <div className="fxsjMpXQ_su8WhSkN8nm css-ipf7y1">
                            <div className="w4EGE1yYMfGWs7nXAypr css-60g013">{item.orginal_name}</div>
                            <div className="css-8qo53f23">
                              <button type="button" className="olv-button olv-ignore-transform css-eolcwi" onClick={() => toggleDropdown(item.post_sign_id)}>
                                <span className="css-g4t7oo">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" fill="currentColor" focusable="false">
                                    <path d="M10 12a2 2 0 1 1 2 2 2 2 0 0 1-2-2zm2-6a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 16a2 2 0 1 0-2-2 2 2 0 0 0 2 2z"></path>
                                  </svg>
                                </span>
                              </button>
                              
   <Dropdown isOpen={dropdownOpen[item.post_sign_id]} toggle={() => toggleDropdown(item.post_sign_id)}>
  <DropdownMenu>
    {isEditorAllowed ? (
      <>
        <DropdownItem onClick={() => editItemHandler(item.uuid)}>{t('Edit')}</DropdownItem>
        <DropdownItem onClick={() => deleteItem(item.post_sign_id)}>{t('Remove')}</DropdownItem>
      </>
    ) : (
      <DropdownItem disabled>{t('You do not have permission to perform these actions')}</DropdownItem>
    )}
  </DropdownMenu>
</Dropdown>

    
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>{t('No Record Found')}</p>
                  )}
                  {/* Pagination controls */}
                  <div className="d-flex justify-content-center mt-3">
                    <ul className="pagination">
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
                          disabled={currentPage === 1}
                        >
                         {t('Previous')} 
                        </button>
                      </li>
                      {[...Array(totalPages)].map((_, index) => (
                        <li key={index} className="page-item">
                          <button
                            className="page-link"
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li className="page-item">
                        <button
                          className="page-link"
                          onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
                          disabled={currentPage === totalPages}
                        >
                          {t('Next')}
                        </button>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Template));