import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
// Pages Components
import StartAction from "./StartAction";
import WelcomeComp from "./WelcomeComp";
//i18n
import { withTranslation } from "react-i18next";
import UserPermissions from '../../components/Permissions/UserPermissionsContext';
//redux
const Dashboard = (props) => {
  const { t } = props;
  const navigate = useNavigate()
 
  //meta title
  useEffect(() => {
    document.title = t("ShiftSpec - Comprehensive Workforce Management Platform | Optimize Scheduling, Time Tracking & Digital Signatures");
  }, [t]);

  return (
<React.Fragment>
<div className="page-content">
<Container fluid>
{/* Render Breadcrumb */}

    <Row>
             <Col xl="12">  
              <WelcomeComp />
            </Col>
            
            <Col xl="12">
            <StartAction />
           </Col>
          </Row>

        </Container>
      </div>

      {/* subscribe ModalHeader */}
     

     
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};
export default withTranslation()(withRouter(Dashboard));

