import React, { useState,useEffect } from "react";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Modal, ModalHeader, ModalBody, ModalFooter,
    UncontrolledCollapse
  } from "reactstrap";
import { Link ,useNavigate} from "react-router-dom"
import classNames from "classnames";
import {SignaturePad , SignatureParaadPad } from "./SignaturePad"
import UploadStemp, { UploadStempParaafWrapped as UploadStempParaaf } from './UploadDragDrop';

import {
  getSignature,
  addNewSignature,
  addNewSignatureSuccess,
  addNewSignatureFail,
  addNewHandWriting,
  addNewHandWritingSuccess,
  addNewHandWritingFail,
  addNewStemp,
  addNewStempSuccess,
  addNewStempFail
} from "store/actions";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const SignatureModal = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const {onModalData } = props;
  const [fristname, setFristname] = useState(user.firstName);
   const [lastname, setLastname] = useState(user.lastName);
   const [token, setToken] = useState(user.token);
   const [userId, setuserId] = useState(user.clientId);
 const fullNames = `${fristname} ${lastname}`;
 const shortName = `${fristname?.charAt(0)}${lastname?.charAt(0)}`;
 const frisletter = `${fristname?.charAt(0)}.${lastname?.charAt(0)}`;
 const [selectedValue, setSelectedValue] = useState("");
 const [isButtonClicked, setIsButtonClicked] = useState(false);
 const [isButtonStempClicked, setIsButtonStempClicked] = useState(false);
 const [signatureData, setSignatureData] = useState(null);
 const [signatureParafData, setSignatureParafData] = useState(null);
 const [StempData, setStempData] = useState(null);
 const [StempParafData, setStempParafData] = useState(null);
 const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
 const [isSubmitStempDisabled, setIsSubmitStemDisabled] = useState(true);

 const navigate = useNavigate();
 if (!fristname || !lastname) {
  navigate('/login'); 
}
 const handleLiClick = (value) => {
  setSelectedValue(value);
  setIsSubmitDisabled(false);
  setIsSubmitStemDisabled(false)
 
};

 const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  useEffect(() => {
    toastr.options = {
      positionClass: 'toast-top-center',
      closeButton: true,
      debug: true,
      progressBar:true,
      timeOut: 3000, // Duration in milliseconds
    };
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Collect form data
      const formData = new FormData(event.target);

      // Convert FormData to JSON object data-fonteot={"LovlyTropical.ttf"}
      const formObject = {};
      formData.forEach((value, key) => {
        formObject[key] = value;
      });
      formObject.postStatus = 'font';
      formObject.userID = userId;
      formObject.token = token;
      formObject.fullNames = fullNames;
      formObject.shortName = shortName;
      formObject.email = user.email;
      // Dispatch the action to initiate the post request
      await dispatch(addNewSignature(formObject));
      // Additional logic or state updates after success
      onModalData('true');
    } catch (error) {
      // If there is an error with the post request, dispatch the fail action
      toastr.error(t('Error saving signature data!'));
      
    }
    
   
  };


  const handleSignatureData = (data) => {
    setSignatureData(data);
    
  }
  const handleStempData = (fileData) => {
    setStempData(fileData);
 
  }
  const handleStempParafData = (data) => {
    setStempParafData(data);

    //console.log(data, 'signatureParafDataURL');
  };
  // Function to handle the signature data received from ChildComponentTwo
  const handleSignatureParafData = (data) => {
    setSignatureParafData(data);
    //console.log(data, 'signatureParafDataURL');
  };

  // Function to handle the button click and set the isButtonClicked state

    const handleButtonClick = async (event) => {

    if (signatureData && signatureParafData) {
    setIsButtonClicked(true);
    try {
      // Convert FormData to JSON object data-fonteot={"LovlyTropical.ttf"}
      const formObject = {
        signatureData: signatureData,
        signatureParafData: signatureParafData,
        postStatus: 'handwriting',
        userID: userId,
        fullNames:fullNames,
        token: token,
        email:user.email
      };
  

      // Dispatch the action to initiate the post request
      await dispatch(addNewHandWriting(formObject));

      onModalData('true');
    } catch (error) {
      toastr.error(t('Error saving signature data!'));
      // Additional logic or state updates after failure
    }
  }
};

const sendDataToServer = async (data) => {
  if (data) {
    // Assuming you have a function to handle the API call in the backend

    const formData = new FormData();
    formData.append('stamp_png', StempData); // Pass the file object to the backend
    formData.append('paraaf_stamp_img', StempParafData); // Pass the file object to the backend
    formData.append('users_id', userId);
    formData.append('email', user.email);

    try {
      // Replace 'http://your-api-url' with the actual URL of your backend server
      const response = await fetch(`https://dspmetrics.com/api/upload-stemp?token=${token}&fullNames=${fullNames}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        // Process the response data if needed
        toastr.success(t('Signature uploaded successfully! Your document is now ready for further processing.'));
        onModalData('true');
      } else {
        console.error('Error sending data to the server:', response.status);
        toastr.error(t('Signature upload failed. Please try again or contact support if the issue persists.'));
      }
    } catch (error) {
      console.error('Error sending data to the server:', error);
    }
  }
};

//console.log("Error saving signature data:", signatureData);
const isButtonDisabled = !(signatureData && signatureParafData);
const isButtonStempDisabled = !(StempData && StempParafData);
return(
    <React.Fragment>
    <Col lg={12}>
    <Card>
    <CardBody>
    <p className="card-title-desc" >{t('Choose Your Signature')} </p>

    <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">{t('CHOOSE')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">{t('DRAW')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">{t('UPLOAD')}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                      <TabPane tabId="1">
                      <Row>
                        <Col sm="12"> 
                  <form onSubmit={handleSubmit}>
                <ul className="grid_pay">
                <li className="card_pay" onClick={() => handleLiClick('LovlyTropical')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="frist_sign" className="frist_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3"> {token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="frist_sign " className="frist_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label  className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'lovelytropical.ttf'}
                checked={selectedValue === 'LovlyTropical'} // Add checked attribute
                onChange={() => setSelectedValue('LovlyTropical')} // Update selected value
                />
                 
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('BitterSignature')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="sceond_sign" className="sceond_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3"> {token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="sceond_sign " className="sceond_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'BitterSignature.ttf'}
                checked={selectedValue === 'BitterSignature'} 
                onChange={() => setSelectedValue('BitterSignature')} 
                />
                  
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('Histopher')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="three_sign" className="three_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3">{token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="three_sign " className="three_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'Histopher.ttf'}
                checked={selectedValue === 'Histopher'} 
                onChange={() => setSelectedValue('Histopher')} 
                />
                 
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('SignaturemomentRegular')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="four_sign" className="four_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3"> {token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="four_sign " className="four_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'Aika-Regular.ttf'}
                checked={selectedValue === 'SignaturemomentRegular'} 
                onChange={() => setSelectedValue('SignaturemomentRegular')} 
                />
                 
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('BarokahSignatureRegular')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="five_sign" className="five_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3">{token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="five_sign " className="five_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'BarokahSignatureRegular.ttf'}
                checked={selectedValue === 'BarokahSignatureRegular'} 
                onChange={() => setSelectedValue('BarokahSignatureRegular')} 
                />
                  
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('BugisniaPersonal')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="six_sign" className="six_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3"> {token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="six_sign " className="six_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'BugisniaPersonal.otf'}
                checked={selectedValue === 'BugisniaPersonal'} 
                onChange={() => setSelectedValue('BugisniaPersonal')} 
                />
                 
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('AlistaBlairRegular')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="seven_sign" className="seven_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3"> {token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="seven_sign " className="seven_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'AlistaBlair-Regular.otf'}
                checked={selectedValue === 'AlistaBlairRegular'} 
                onChange={() => setSelectedValue('AlistaBlairRegular')} 
                />
                  
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('Rossa')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="eight_sign" className="eight_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3"> {token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="eight_sign " className="eight_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'Rossa.otf'}
                checked={selectedValue === 'Rossa'} 
                onChange={() => setSelectedValue('Rossa')} 
                />
                 
                  </label>
             </li>
             <li className="card_pay" onClick={() => handleLiClick('DeniraSignature')}>
                <div tabIndex={0}  className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                  <div className="css-cmcycf">
                    <span>{t('Ondertekend door:')}</span>
                    <div className="css-fv3lde">
                      <h2 id="night_sign" className="night_sign">{fullNames}</h2>
                    </div>
                    <span aria-hidden="true" className="css-1j983t3"> {token}</span>
                  </div>
                </div>
                <div className="card__content_pay">
                  <h4 className="font-name"></h4>
              </div>
              <div tabIndex={0} className="hover-state-gray css-zm3qx2" data-qa="user-signature" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg"> 
                <div className="css-cmcycf">
                  <span>{t('DS:')}</span>
                  <div className="css-fv3lde">
                    <h2 id="night_sign " className="night_sign">{shortName}</h2>
                  </div>
                  <span aria-hidden="true" className="css-1j983t3">{token}</span>
                </div>
              </div>
                <label className="checkbox-control_pay" >
                <input
                className="checkbox_pay"
                type="radio"
                name="selectedValue"
                value={'DeniraSignature.ttf'}
                checked={selectedValue === 'DeniraSignature'} 
                onChange={() => setSelectedValue('DeniraSignature')} 
                />
                  
                  </label>
             </li>
           
             </ul>
             <div className="modals_footers">

      <div className="my-sub-btn">
      <button
        type="button"
        className="btn btn-secondary"
        
      >
        {t('Close')}
      </button>
      <button type="submit" disabled={isSubmitDisabled} className="btn btn-primary btn-sub">
      {t('Save Changes')} 
      </button>
      </div>
      
             </div>
             </form>         
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                <div className="row drawing-tab">
               
               <div className="col-xl-7 draw-signature-wrapper">
               
                <SignaturePad 
                isButtonClicked={isButtonClicked}
                onSignatureData={handleSignatureData}
                 />
                 <div className="signature-panel-label">
                   <span className="signature-panel-sign-here">
                     <span className="css-zf56fy"><span aria-hidden="true" className="SVGInline">
                       <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                   <path d="M12.95 11.536 9.414 8l3.536-3.536-1.414-1.414L8 6.586 4.464 3.05 3.05 4.464 6.586 8 3.05 11.536l1.414 1.414L8 9.414l3.536 3.536z"></path>
                 </svg>
               </span>
             </span>
           </span>
           {t('Handtekening plaatsen')} 
           </div>
               </div>
               <div className="col-xl-4 draw-signature-wrapper">
                <SignatureParaadPad
                 isButtonClicked={isButtonClicked}
                 onSignatureData={handleSignatureParafData } 
                  />
                 <div className="signature-panel-label">
                   <span className="signature-panel-sign-here">
                     <span className="css-zf56fy"><span aria-hidden="true" className="SVGInline">
                       <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                   <path d="M12.95 11.536 9.414 8l3.536-3.536-1.414-1.414L8 6.586 4.464 3.05 3.05 4.464 6.586 8 3.05 11.536l1.414 1.414L8 9.414l3.536 3.536z"></path>
                 </svg>
               </span>
             </span>
           </span>
           {t('Handtekening plaatsen')} 
           </div>
           
               </div>
              </div>
              <div className="modals_footers">
    
<div className="my-sub-btn">
<button
  type="button"
  className="btn btn-secondary"
>
{t('Close')}
</button>
<button type="submit" disabled={isButtonDisabled} onClick={handleButtonClick} className="btn btn-primary btn-sub">
{t('Save Changes')}
</button>
</div>

       </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="7">
                          <UploadStemp 
                          isButtonStempClicked={isButtonStempClicked}
                          onStempsData={handleStempData}

                          />
                        </Col>
                        <Col sm="5">
                          <UploadStempParaaf
                          isButtonStempClicked={isButtonStempClicked}
                          onStempsData={handleStempParafData}
                            />
                        </Col>
                      </Row>
                      <div className="modals_footers">

<div className="my-sub-btn">
<button
  type="button"
  className="btn btn-secondary"
  
>
{t('Close')}
</button>
<button type="submit" onClick={sendDataToServer}  disabled={isButtonStempDisabled}  className="btn btn-primary btn-sub">
{t('Save Changes')}
</button>
</div>
</div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
    </Col>
  </React.Fragment>
)
}

export default withTranslation()(withRouter(SignatureModal));