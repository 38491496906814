import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
  } from "reactstrap";
  import PropTypes from "prop-types";
  import {
    blogData,
    blogCatData,
  } from "../../common/data";
  import { Link ,useLocation } from "react-router-dom";
import CustomNavbar from "components/HeaderUnUser/Header";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";

const Blog = (props) => {
    const { t,i18n  } = props;
    const currentLanguage = i18n.language
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1); // Current page state
  const blogsPerPage = 12;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false)
    useEffect(() => {
        const headerColor = document.getElementById('header_color');
        
        // Check if the element exists before accessing it
        if (headerColor) {
          headerColor.style.backgroundColor = '#f0fff2';
        }

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // If screen width is <= 768px, treat it as small screen
          };
      
          window.addEventListener('resize', handleResize);
          handleResize(); // Call the function on component mount
      
          return () => window.removeEventListener('resize', handleResize);
      }, [])

      const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
      const filteredBlogs = blogData.filter(blog => blog.lang === currentLanguage);

      // Calculate total pages based on filtered blogs
      const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);
    
      // Get the current blogs for the page
      const currentBlogs = filteredBlogs.slice(
        (currentPage - 1) * blogsPerPage,
        currentPage * blogsPerPage
      );
    
      // Handle pagination click
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };


      useEffect(() => {
      
        // Function to update meta tags
        const updateMetaTags = () => {
          document.title = t(`Insights and Updates | Workforce Management Blog | Shiftspec.com`);
      
          const metaElement = document.querySelector('meta[name="description"]');
          if (metaElement) {
              metaElement.setAttribute('content', 'Stay up-to-date with the latest news, trends, and insights in workforce management. Explore ShiftSpec’s blog for expert advice on employee scheduling, time tracking, digital signatures, and more.');
          } else {
              addOrUpdateMeta('description', 'Explore the ShiftSpec blog to gain insights on workforce management, employee scheduling, time tracking, and digital signatures. Our blog offers valuable tips, industry updates, and expert advice to help businesses optimize their operations.');
          }
      
          addOrUpdateMeta('keywords', 'Workforce Management Blog, Employee Scheduling Blog, Time Tracking Insights, Digital Signatures Blog, Business Efficiency Tips, Labor Cost Management Blog, Shift Scheduling Articles, Workforce Automation Trends, Remote Work Solutions Blog, ShiftSpec News and Updates');
      
          function addOrUpdateMeta(name, content) {
              let metaTag = document.querySelector(`meta[name="${name}"]`);
              if (metaTag) {
                  metaTag.setAttribute('content', content);
              } else {
                  metaTag = document.createElement('meta');
                  metaTag.setAttribute('name', name);
                  metaTag.setAttribute('content', content);
                  document.head.appendChild(metaTag);
              }
          }
      
          // Meta tags for the "Blog" page
          const metaTags = [
              { name: 'description', content: 'Explore the ShiftSpec blog to gain insights on workforce management, employee scheduling, time tracking, and digital signatures. Our blog offers valuable tips, industry updates, and expert advice to help businesses optimize their operations.' },
              { name: 'keywords', content: 'Workforce Management Blog, Employee Scheduling Blog, Time Tracking Insights, Digital Signatures Blog, Business Efficiency Tips, Labor Cost Management Blog, Shift Scheduling Articles, Workforce Automation Trends, Remote Work Solutions Blog, ShiftSpec News and Updates' },
              { name: 'author', content: 'ShiftSpec Blog Team' }
          ];
      
          // Apply each meta tag
          metaTags.forEach(tag => {
              addOrUpdateMeta(tag.name, tag.content);
          });
      
          // Helper function for Open Graph and Twitter meta tags
          const addOrUpdateMetaTag = (property, content) => {
              let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
              if (tag) {
                  tag.setAttribute('content', content);
              } else {
                  tag = document.createElement('meta');
                  tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
                  tag.setAttribute('content', content);
                  document.head.appendChild(tag);
              }
          };
      
          // Open Graph Meta Tags for "Blog" page
          addOrUpdateMetaTag('og:title', 'Insights and Updates | Workforce Management Blog | Shiftspec.com');
          addOrUpdateMetaTag('og:description', 'Stay up-to-date with the latest news, trends, and insights in workforce management. Explore ShiftSpec’s blog for expert advice on employee scheduling, time tracking, digital signatures, and more.');
          addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
          addOrUpdateMetaTag('og:type', 'website');
      
          // Twitter Meta Tags for "Blog" page
          addOrUpdateMetaTag('twitter:title', 'Insights and Updates | Workforce Management Blog | Shiftspec.com');
          addOrUpdateMetaTag('twitter:description', 'Stay up-to-date with the latest news, trends, and insights in workforce management. Explore ShiftSpec’s blog for expert advice on employee scheduling, time tracking, digital signatures, and more.');
          addOrUpdateMetaTag('twitter:card', 'summary_large_image');
      };
      
  
        // Call the function to update meta tags
        updateMetaTags();
    
      }, [ location.pathname, t]);
  return (
    <React.Fragment>
    <CustomNavbar />
  
    <section className="relative" style={{ background: '#f0fff2' }}>
  <Row className="align-items-center">
    {/* Left Column - Text Section */}
    <Col xs={12} sm={12} md={6} className="d-flex align-items-center justify-content-center">
      <div className="text-left">
        <h1 className="font-bold text-dark text-[30px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold mb-1.5 lg:mt-3 md:mb-6">
        {t('News & Blogs')} 
        </h1>
        <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6">
        {t('Read about the latest trends & developments on this page')}    
        </p>
      </div>
    </Col>

    {/* Right Column - Image Section */}
    <Col xs={12} sm={12} md={6}>
      <div className="text-center lg:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-23+om+11.56.32_4e2d9b52.png"
          width="450"
          height="283"
          className="img-fluid mx-auto lg:mr-0" 
          alt="header image"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-23+om+11.56.32_4e2d9b52.png 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
        <div className="lg:hidden flex flex-col items-center text-center mt-12">
          <div className="flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-5 mb-4 lg:mb-3 max-w-xs"></div>
        </div>
      </div>
    </Col>
  </Row>

  {/* Triangle SVG */}
  <svg
  className="absolute top-0 inset-x-0 mx-auto h-[40%] lg:translate-x-[20%] mt-32 opacity-20 z-[-5]"
  style={{ right: '24px' }}
  width="732"
  height="389"
  viewBox="0 0 732 389"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clipPath="url(#clip0_26_895)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M625.302 293.386C626.169 291.133 627.896 289.21 630.278 288.153C631.888 287.438 633.666 287.188 635.412 287.432V287.432L720.091 299.248C722.671 299.608 724.861 300.98 726.316 302.906C727.772 304.833 728.491 307.315 728.131 309.895C727.91 311.483 727.287 312.988 726.322 314.268V314.268L676.344 380.577C674.776 382.658 672.513 383.906 670.122 384.242C667.731 384.578 665.211 384.002 663.13 382.434C661.85 381.469 660.836 380.194 660.185 378.729V378.729L625.484 300.603C624.426 298.223 624.434 295.639 625.302 293.386Z"
      stroke="#2A86CD"
      strokeWidth="6.29227"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.921 2.23295C172.217 1.48943 174.797 1.62246 177.117 2.80827C178.686 3.61016 180 4.83541 180.909 6.34525V6.34525L225.019 79.6095C226.363 81.8422 226.676 84.4081 226.094 86.7518C225.513 89.0955 224.038 91.2169 221.807 92.5606C220.434 93.3875 218.873 93.8506 217.271 93.9062V93.9062L134.303 96.784C131.7 96.8743 129.306 95.9005 127.54 94.253C125.774 92.6055 124.636 90.2844 124.545 87.6802C124.488 86.0775 124.841 84.487 125.57 83.059V83.059L164.428 6.9169C165.612 4.59653 167.624 2.97646 169.921 2.23295Z"
      stroke="#2A86CD"
      strokeWidth="6.29227"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.2132 113.629C63.4079 110.257 66.8895 107.722 71.1378 106.824C74.0107 106.216 76.9964 106.425 79.757 107.427V107.427L213.711 156.045C217.793 157.527 220.889 160.522 222.593 164.168C224.298 167.813 224.612 172.109 223.131 176.19C222.22 178.701 220.683 180.939 218.667 182.692V182.692L114.223 273.468C110.946 276.316 106.824 277.566 102.81 277.285C98.795 277.003 94.8872 275.191 92.0379 271.914C90.2844 269.897 89.0737 267.467 88.5203 264.852V264.852L59.01 125.459C58.1106 121.211 59.0185 117.002 61.2132 113.629Z"
      stroke="#2A86CD"
      strokeWidth="10.4871"
    ></path>
  </g>
  <defs>
    <clipPath id="clip0_26_895">
      <rect width="732" height="388.117" fill="white"></rect>
    </clipPath>
  </defs>
</svg>

</section>


<section className="relative bg-gray">
  <Row style={{padding:'20px'}}>
    {/* Category Sidebar Column */}
    <Col xs={12} sm={12} md={4} className="mb-4 ">
      <Card className="w-100 ">
        <CardBody >
          <div className="kb-sidebar">
            <div className="kb-category-menu">
              {/* Show button to toggle dropdown on small screens */}
              {isSmallScreen && (
                <h5
                  className="font-bold text-dark cursor-pointer"
                  onClick={toggleDropdown}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{t('Categories')}</span>
                  <svg
                    width="12"
                    height="7"
                    xmlns="http://www.w3.org/2000/svg"
                    className={isDropdownOpen ? 'rotate-180' : ''}
                  >
                    <path
                      d="M10.6817 1.6816l-4.5364 4-4.5364-3.9315"
                      stroke="#2d3e50"
                      strokeWidth="2"
                      fill="none"
                      fillRule="evenodd"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </h5>
              )}

              {/* Show categories if on large screens or if dropdown is open on small screens */}
              {(isDropdownOpen || !isSmallScreen) && (
                <ul>
                  {blogCatData
                    .filter(category => category.lang === currentLanguage)
                    .map((category, index) => (
                      <React.Fragment key={category.id}>
                        <li>
                          <a className="font-bold" href={`/blog/tag/${category.slug}`}>
                            <div className="kb-category-menu-option">
                              <span
                                className="kb-category-menu-option__color-bar font-bold"
                                style={{ backgroundColor: '#2d3e50' }}
                              >
                                {category.name}
                              </span>
                            </div>
                          </a>
                        </li>
                        <hr style={styles.hrline}></hr>
                      </React.Fragment>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>

    {/* Blog Section Column */}
    <Col xl={8} lg={8} md={12} sm={12} className="d-flex justify-content-center">
      <div className="w-100" style={{ padding: '24px' }}>
        <div className="mb-6">
          {/* Blog Rendering */}
          {currentBlogs.map((blog, index) => (
            <Link key={index} to={`/blog/${blog.bslug}`} title={blog.title}>
              <div className="bg-white shadow-10 rounded-lg overflow-hidden mb-8">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="h-40 sm:h-48 md:h-[262px] w-full object-cover"
                  loading="lazy"
                />
                <div className="px-3 sm:px-8 pt-3">
                  <h5 className="text-base sm:text-lg lg:text-xl font-extrabold text-dark-30 mb-1">
                    {blog.title}
                  </h5>
                  <div className="flex items-center space-x-3 mb-2">
                    <p className="text-[10px] sm:text-xs text-gray-70">
                      {new Date(blog.created_at).toLocaleDateString()}
                    </p>
                    <p className="text-[8px] sm:text-[10px] font-semibold text-blue-60 bg-blue-10 rounded">
                      {blog.category_name}
                    </p>
                  </div>
                  <p className="text-base text-dark-30 mb-4">
                    {blog.content.substring(0, 100)}...
                  </p>
                </div>
              </div>
            </Link>
          ))}

          {/* Pagination */}
          <div className="text-center">
            <ul className="pagination justify-content-center pagination-rounded">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="mdi mdi-chevron-left"></i>
                </button>
              </li>

              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(page)}>
                    {page}
                  </button>
                </li>
              ))}

              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <i className="mdi mdi-chevron-right"></i>
                </button>
              </li>
            </ul>
            <p className="text-sm text-dark-30">
            {t('Page')}<span className="font-bold">{currentPage}</span> {t('of')}  <span className="font-bold">{totalPages}</span>
            </p>
          </div>
        </div>
      </div>
    </Col>
  </Row>
</section>


<section className="relative lg:pb-10 pt-20 overflow-hidden bg-white">
  <Row>
    <Col sm={12} className="d-flex flex-column align-items-center justify-content-center text-center">
    <h2 className="font-bold text-dark text-[36px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold mb-4 mt-4">
    {t('A personal tour of Shiftspec')}    
      </h2>
      <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6"style={{ padding: '20px' }}>
      {t('Interested? Request a demonstration now without any obligation!')}    
      </p>
      <a href='/register/starter'>
        <button
          title="Purchase"
          block
          role="button"
          type="submit"
          className="css-pjhtp2"
          style={{ display: 'block', marginTop: '20px', marginBottom: '20px' }}
        >
          <span className="css-13wylk3">{t('Request a demo')} </span>
        </button>
      </a>
    </Col>
  </Row>
</section>

    </React.Fragment>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#00a0f0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    color: '#555',
  },
  hrline: {

    marginBottom: '0.75rem',
    marginTop: '0.2rem',
    borderBottom: '1px solid #DFE3EB',
      },
};
Blog.propTypes = {
    history: PropTypes.object,
    t: PropTypes.isRequired,
  };

  export default withRouter(withTranslation()(Blog));

