import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Dropdown, DropdownToggle, DropdownMenu 
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import withRouter from "components/Common/withRouter";
  import megamenuImg from "../../assets/images/megamenu-img.png";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const Resources = ({props}) => {
    const [menu, setMenu] = useState(false);
  return (
    <Dropdown
    className="dropdown-mega d-none d-lg-block ms-2"
    isOpen={menu}
    toggle={() => setMenu(!menu)}
  >
    
    <div className="css-qowjig">
    <DropdownToggle
      className="css-nx1dk9"
      caret
      tag="button"
    >
     
      {props.t("Resources")}
      <span aria-hidden="true" className="css-1d1unjo">
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
     <path fillRule="evenodd" clipRule="evenodd" d="M13.315 6.13814L12.1954 5.01855L8.00015 9.21382L3.80489 5.01855L2.6853 6.13814L8.00015 11.453L13.315 6.13814Z" fill="currentColor"></path>
   </svg>
 </span>
    </DropdownToggle>
    <DropdownMenu className="dropdown-megamenu" style={{margin:'0 0 0 -22px',width:'100%'}}>
<Row>
{/* First Column with Menu Items */}
<Col sm={8}>
<Row>
  <Col md={6}>
    {/* <h5 className="font-size-14 mt-0">{props.t("UI Components")}</h5> */}
    <ul className="list-unstyled megamenu-list ">
      <li>
      <a
  href="/about-us"
  className="flex items-center rounded-lg hover:bg-black"
  title="About us"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
    <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
       d="m10.518 13.317-.2-.131a1.165 1.165 0 0 0-1.283.262 1.038 1.038 0 0 0-.258.382 1.207 1.207 0 0 0-.095.465c.002.156.034.31.095.453.126.287.352.516.635.645.141.06.293.093.447.095.157-.003.313-.035.459-.095a1.205 1.205 0 0 0 .376-1.944l-.176-.132Zm-.66-8.481A3.225 3.225 0 0 0 7.06 6.41c-.232.37-.245.84-.034 1.222.21.382.61.617 1.043.611a1.175 1.175 0 0 0 1.026-.64.902.902 0 0 1 .765-.381c.487 0 .882.4.882.894a.889.889 0 0 1-.882.895c-.65 0-1.177.534-1.177 1.193s.527 1.193 1.177 1.193c1.787 0 3.235-1.47 3.235-3.28 0-1.812-1.448-3.281-3.235-3.281Zm10.094 4.39c-.317-3.556-2.455-6.68-5.63-8.227a9.882 9.882 0 0 0-9.851.688A10.235 10.235 0 0 0 .048 9.166c-.292 3.019.77 6.01 2.894 8.147l6.235 6.334a1.168 1.168 0 0 0 1.67 0l6.211-6.334a10.174 10.174 0 0 0 2.894-8.147v.06Zm-4.54 6.405L10 21.118l-5.412-5.487a7.805 7.805 0 0 1-2.2-6.226 7.856 7.856 0 0 1 3.4-5.762 7.524 7.524 0 0 1 8.424 0 7.855 7.855 0 0 1 3.4 5.738 7.805 7.805 0 0 1-2.2 6.25Z" 
       fill="#09F"
        fillRule="nonzero"
      />
    </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold text-dark m-1">{props.t("About us")}</p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/support"
  className="flex items-center rounded-lg"
  title="Support"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path
    d="M16.318 3.626c.61.342 1.387.134 1.738-.468.287-.5.88-.746 1.442-.597a1.266 1.266 0 0 1-.33 2.492 1.27 1.27 0 0 0-1.278 1.263 1.27 1.27 0 0 0 1.278 1.263c1.86 0 3.451-1.322 3.774-3.133.322-1.81-.718-3.589-2.466-4.217-1.748-.629-3.7.074-4.63 1.666a1.25 1.25 0 0 0-.129.963c.089.325.305.601.601.768Zm5.496 9.006a1.275 1.275 0 0 0-1.432 1.086c-.555 4.43-4.365 7.758-8.882 7.756H4.356l.83-.821a1.253 1.253 0 0 0 0-1.781 8.767 8.767 0 0 1-1.928-9.62C4.638 5.953 7.89 3.8 11.5 3.79a1.27 1.27 0 0 0 1.278-1.263A1.27 1.27 0 0 0 11.5 1.264C7.09 1.282 3.08 3.79 1.175 7.72a11.26 11.26 0 0 0 1.379 11.998L.368 21.84A1.252 1.252 0 0 0 .1 23.217c.196.472.66.78 1.176.783H11.5c5.795 0 10.685-4.259 11.413-9.94a1.252 1.252 0 0 0-.25-.94 1.281 1.281 0 0 0-.85-.488Zm-2.16-3.701a1.292 1.292 0 0 0-.742-.089l-.23.076-.23.114-.191.164a1.262 1.262 0 0 0-.269.404 1.05 1.05 0 0 0-.102.506 1.25 1.25 0 0 0 .09.492 1.286 1.286 0 0 0 1.189.77 1.27 1.27 0 0 0 1.277-1.262 1.05 1.05 0 0 0-.102-.48 1.36 1.36 0 0 0-.69-.683v-.012Z" fill="#F3A54B" fillRule="nonzero"></path>
  </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold text-dark m-1">{props.t("Support")}</p>
  </div>
</a>
      </li>
 

    </ul>
  </Col>
  <Col sm={6}>
  <ul className="list-unstyled megamenu-list ">
  <li>
      <a
  href="/contact-sales"
  className="flex items-center rounded-lg"
  title="contact sales"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path d="M12 0C5.373 0 0 5.472 0 12.222v8.556C0 21.453.537 22 1.2 22h3.6c1.988 0 3.6-1.642 3.6-3.667V15.89c0-2.025-1.612-3.667-3.6-3.667H2.4c0-5.4 4.298-9.778 9.6-9.778s9.6 4.378 9.6 9.778h-2.4c-1.988 0-3.6 1.642-3.6 3.667v2.444c0 2.025 1.612 3.667 3.6 3.667h3.6c.663 0 1.2-.547 1.2-1.222v-8.556C24 5.472 18.627 0 12 0ZM4.8 14.667c.663 0 1.2.547 1.2 1.222v2.444c0 .675-.537 1.223-1.2 1.223H2.4v-4.89h2.4Zm16.8 4.889h-2.4c-.663 0-1.2-.548-1.2-1.223V15.89c0-.675.537-1.222 1.2-1.222h2.4v4.889Z" fill="#3875A0" fillRule="nonzero"></path>
 
  </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold text-dark m-1">{props.t("Contact")}</p>
  </div>
</a>
      </li>

      <li>
      <a
  href="/blog"
  className="flex items-center rounded-lg"
  title="Blog"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path d="M16.8 1.905c.376 0 .739.072 1.068.205l-.056.057-.414.42-.236.237-.263.267-.141.143-.438.443-.24.243H2.965a.998.998 0 0 0-.989 1.005v8.04a.995.995 0 0 0 .989 1.005H16.8a.998.998 0 0 0 .988-1.005v-2.734c1.163-1.153 1.821-1.805 1.977-1.962v4.696c0 1.667-1.327 3.015-2.965 3.015h-5.93v2.01h3.954c.545 0 .988.452.988 1.005A.998.998 0 0 1 14.824 20H4.94a.998.998 0 0 1-.988-1.005.998.998 0 0 1 .988-1.005h3.953v-2.01h-5.93C1.328 15.98 0 14.627 0 12.965V4.92c0-1.667 1.327-3.015 2.965-3.015H16.8ZM21.04 0c.15 0 .296.057.4.167l2.395 2.424a.59.59 0 0 1 .165.405l-.038.029v.08a.677.677 0 0 1 0 .139.73.73 0 0 1-.122.19l-1.605 1.591-6.136 6.25a.556.556 0 0 1-.428.166h-2.396a.57.57 0 0 1-.564-.572V8.445c0-.153.056-.3.164-.405l6.165-6.259 1.6-1.62a.57.57 0 0 1 .4-.161Zm.104 1.534-7.14 7.297-.173 1.457 1.458-.286 7.158-7.154-1.308-1.314h.005Z" fill="#f79009" fillRule="nonzero"></path>
 
  </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold text-dark m-1">{props.t("Blog")}</p>
  </div>
</a>
      </li>

    </ul>
  </Col>
</Row>
</Col>

{/* Second Column with Image */}
<Col sm={4} className="megamenu-image-col">
<Row>
  <Col sm={12}>
    <div className="megamenu-image">
      <img
        src={'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-19+om+15.22.44_40d1161a.jpg'}
        alt=""
        className="img-fluid mx-auto d-block"
      />
    </div>
  </Col>
</Row>
</Col>
</Row>
</DropdownMenu>

</div>

  </Dropdown>
  );
};

export default Resources;
