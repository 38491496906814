import PropTypes from 'prop-types';
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const CardPricing = (props) => {
  const { t ,pricing, userType} = props;
  return (
    <React.Fragment>

      <Col xl="4" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
          
            <div className="d-flex">
              <div className="flex-grow-1">
           
                <h3 className='text-2xl sm:text-3xl lg:text-4xl text-dark-30 font-extrabold'>{pricing.title}</h3>
                <p className="text-sm text-dark-30 font-semibold mb-3 h-[45px] md:h-[80px] lg:h-[42px]">{pricing.description}</p>
              </div>
   
            </div>
            <div className="py-4">
              <h2>
                <sup className='text-2xl sm:text-4xl lg:text-5xl font-extrabold text-dark-30'>
                  {pricing.custom && <small>€</small>}
                </sup>{" "}
                <span className='text-2xl sm:text-4xl lg:text-5xl font-extrabold text-dark-30'>{pricing.price}/{" "}</span>
                
                <span className="font-size-13 text-dark-30">{pricing.duration}</span>
              </h2>
            </div>
            <div className="text-center plan-btn">
              {pricing.custom ? (
                <Link
                  to={userType === 'new' ? pricing.link : pricing.uplink}
                  className=" btn  olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn btn btn-Primira"
                >
                  {userType === 'new' ? t('Buy Now') : t('Upgrade Package')}
                </Link>
              ) : (
                <Link
                  to={pricing.contact}
                  className="btn  olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn btn btn-Primira"
                >
                  {t('Contact Us')}
                </Link>
              )}
            </div>
            <div className="plan-features mt-5 items-center mb-4 text-sm text-dark-30 " >
              {pricing.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                   <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              className="min-w-[18px] mr-2"
            >
              <path
                fill="#09F"
                fillRule="evenodd"
                d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0zM1.125 9a7.875 7.875 0 1 0 15.75 0 7.875 7.875 0 0 0-15.75 0z"
                clipRule="evenodd"
              ></path>
              <path
                fill="#09F"
                d="M12.584 6.234a.246.246 0 0 0-.022.024l-3.847 4.768L6.397 8.77a.848.848 0 0 0-1.154.02.792.792 0 0 0-.02 1.122l2.93 2.852a.843.843 0 0 0 1.196-.022l4.422-5.376a.792.792 0 0 0-.02-1.135.847.847 0 0 0-1.167.003z"
              ></path>
            </svg>
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardPricing.propTypes = {
  pricing: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
};


export default withTranslation()(withRouter(CardPricing));