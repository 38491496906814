import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
  } from "reactstrap";
  import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import PropTypes  from "prop-types";
const SectionIndustries = (props) => {
    const { t,i18n  } = props;
    const industriesData = [
      {
        id: 'hotel',
        title: t('Hotel'),
        description: t('All personnel planning for your hotel in one simple package'),
        imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.26.08_fdd6f7c1.jpg',
      },
      {
        id: 'production',
        title: t('Production'),
        description: t('User-friendly planning software, perfect for your production company'),
        imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.27.40_6a189d3f.jpg',
      },
      {
        id: 'recreation',
        title: t('Recreation'),
        description: t('The solution in personnel planning and time registration for recreation companies'),
        imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.29.10_f2f866d2.jpg',
      },
      {
          id: 'restaurant',
          title: t('Restaurant'),
          description:  t('Most complete employee scheduling software for the hospitality industry'),
          imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.30.12_d2365e42.jpg',
        },

        {
          id: 'retail',
          title: t('Retail'),
          description: t('Employee scheduling and time registration for your store'),
          imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.30.57_9a858d62.jpg',
        },

        {
          id: 'transport',
          title: t('Transportation'),
          description: t('All personnel planning for your transport company in one simple package'),
          imgSrc: 'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-02+om+18.33.14_72f27240.jpg',
        },
      // Add more industry data as needed
    ];
  return (
    <React.Fragment>


<section className="relative bg-white px-8 pt-12 pb-12">
  <Row className="align-items-center">
  <Col xs={12} sm={12} md={6} className="mb-4 mb-md-0">
  <Row className="align-items-center">
          {industriesData.map((industry) => (
            <Col key={industry.id} xs={12} sm={6} md={4} className="mb-4 mb-md-0">
              <Link to={`/${industry.id}`} className="text-decoration-none">
                <Card
                  className="h-100 shadow-sm card-hover"
                >
                  <div className="card-img-top-wrapper">
                    <img
                      src={industry.imgSrc}
                      alt={industry.title}
                      className="card-img-top img-fluid"
                      style={{ objectFit: 'cover', width: '100%' }}
                    />
                  </div>
                  <div className="card-body d-flex  justify-content-center text-left">
                    <h5 className="card-title text-dark font-extrabold">{industry.title}</h5>
                  </div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>

      
    </Col>
    <Col xs={12} sm={12} md={6} className="d-flex align-items-center justify-content-center">
  <div className="text-left px-3 px-md-4">
    {/* Title */}
    <h3 className=" text-dark  font-extrabold mb-3">
    {t('View the possibilities of Shiftspac for your sector')}
    </h3>

    {/* Subtitle */}
    <h2 className="text-base font-bold mb-3">
    {t('Our clients, big and small, come from different Branches. Discover how our software can help you with your goals.')}
    </h2>

    <ul className="list-none text-base font-light text-dark-30 mb-4">
  <li className="flex items-center mb-2">
    <a href='/industries' style={{color:'#31A7F0'}}>
  <span style={{paddingRight:'10px'}}>{t('More industries')}</span>
    <svg xmlns="http://www.w3.org/2000/svg"  width="15" height="11" fill="none" className="mr-3">
<path fill="#31A7F0" d="M11.92 5.669a1.001 1.001 0 0 0-.21-.33l-5-5a1.004 1.004 0 1 0-1.42 1.42l3.3 3.29H1a1 1 0 0 0 0 2h7.59l-3.3 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5-5c.091-.095.162-.207.21-.33a1 1 0 0 0 0-.76Z"></path>
</svg>
    </a>
   
  </li>
  
</ul>
  </div>
</Col>

  </Row>
</section>


    </React.Fragment>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#00a0f0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    color: '#555',
  },
  input: {
    flex: 1,
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '4px 0 0 4px',
    border: '1px solid #ccc',
    outline: 'none',
  },
};

SectionIndustries.propTypes = {
    history: PropTypes.object,
    t: PropTypes.isRequired,
  };

  export default withRouter(withTranslation()(SectionIndustries));

