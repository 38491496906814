import {
  GET_SHIFT,
  GET_SHIFT_SUCCESS,
  GET_SHIFT_FAIL,
  POST_SHIFT,
  POST_SHIFT_SUCCESS,
  POST_SHIFT_FAIL,
  PUT_SHIFT,
  PUT_SHIFT_SUCCESS,
  PUT_SHIFT_FAIL,
  DELETE_SHIFT,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAIL,
  GET_UREN,
  GET_UREN_SUCCESS,
  GET_UREN_FAIL,
  POST_UREN,
  POST_UREN_SUCCESS,
  POST_UREN_FAIL,
  PUT_UREN,
  PUT_UREN_SUCCESS,
  PUT_UREN_FAIL,
  DELETE_UREN,
  DELETE_UREN_SUCCESS,
  DELETE_UREN_FAIL,
  GET_CONTRACT_EMPLOYEER,
  GET_CONTRACT_EMPLOYEER_SUCCESS,
  GET_CONTRACT_EMPLOYEER_FAIL,
  GET_TEAM,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAIL,
  POST_NEW_TEAM,
  POST_NEW_TEAM_SUCCESS,
  POST_NEW_TEAM_FAIL,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAIL,
  POST_INSPECTION_EMPLOYEE,
  POST_INSPECTION_EMPLOYEE_SUCCESS,
  POST_INSPECTION_EMPLOYEE_FAIL,
  GET_SURCHARGER,
  GET_SURCHARGER_SUCCESS,
  GET_SURCHARGER_FAIL,
  POST_SURCHARGER,
  POST_SURCHARGER_SUCCESS,
  POST_SURCHARGER_FAIL,
  PUT_SURCHARGER,
  PUT_SURCHARGER_SUCCESS,
  PUT_SURCHARGER_FAIL,
  DELETE_SURCHARGER,
  DELETE_SURCHARGER_SUCCESS,
  DELETE_SURCHARGER_FAIL,
  } from "./actionTypes";

  export const DelSurcharger = deletesurcharger => ({
    type: DELETE_SURCHARGER,
    payload: deletesurcharger,
  })
  export const DelSurchargerSuccess = deletesurcharger => ({
    type: DELETE_SURCHARGER_SUCCESS,
    payload: deletesurcharger,
  })
  
  export const DelSurchargerFail = error => ({
    type: DELETE_SURCHARGER_FAIL,
    payload: error,
  })


  export const GetSurcharger = getsurcharger => ({
    type: GET_SURCHARGER,
    payload: getsurcharger,
  })
  export const GetSurchargerSuccess = getsurcharger => ({
    type: GET_SURCHARGER_SUCCESS,
    payload: getsurcharger,
  })
  
  export const GetSurchargerFail = error => ({
    type: GET_SURCHARGER_FAIL,
    payload: error,
  })

  export const PutSurcharger = putsurcharger => ({
    type: PUT_SURCHARGER,
    payload: putsurcharger,
  })
  export const PutSurchargerSuccess = putsurcharger => ({
    type: PUT_SURCHARGER_SUCCESS,
    payload: putsurcharger,
  })
  
  export const PutSurchargerFail = error => ({
    type: PUT_SURCHARGER_FAIL,
    payload: error,
  })

  export const PostSurcharger = postsurcharger => ({
    type: POST_SURCHARGER,
    payload: postsurcharger,
  })
  export const PostSurchargerSuccess = postsurcharger => ({
    type: POST_SURCHARGER_SUCCESS,
    payload: postsurcharger,
  })
  
  export const PostSurchargerFail = error => ({
    type: POST_SURCHARGER_FAIL,
    payload: error,
  })


  export const PostInspectionTimeSheet = inspection => ({
    type: POST_INSPECTION_EMPLOYEE,
    payload: inspection,
  })
  export const PostInspectionTimeSheetSuccess = inspection => ({
    type: POST_INSPECTION_EMPLOYEE_SUCCESS,
    payload: inspection,
  })
  
  export const PostInspectionTimeSheetFail = error => ({
    type: POST_INSPECTION_EMPLOYEE_FAIL,
    payload: error,
  })
  export const getShifSchedule = schedule => ({
    type: GET_SCHEDULE,
    payload: schedule,
  })
  export const getShifScheduleSuccess = schedule => ({
    type: GET_SCHEDULE_SUCCESS,
    payload: schedule,
  })
  
  export const getShifScheduleFail = error => ({
    type: GET_SCHEDULE_FAIL,
    payload: error,
  })
  export const postNewTeam = postnewteam => ({
    type: POST_NEW_TEAM,
    payload: postnewteam,
  })
  export const postNewTeamtSuccess = postnewteam => ({
    type: POST_NEW_TEAM_SUCCESS,
    payload: postnewteam,
  })
  
  export const postNewTeamTeamFail = error => ({
    type: POST_NEW_TEAM_FAIL,
    payload: error,
  })
  export const getShiftTeam = teamdata => ({
    type: GET_TEAM,
    payload: teamdata,
  })
  export const getShifTeamtSuccess = teamdata => ({
    type: GET_TEAM_SUCCESS,
    payload: teamdata,
  })
  
  export const getShiftTeamFail = error => ({
    type: GET_TEAM_FAIL,
    payload: error,
  })

  export const getShift = shift => ({
    type: GET_SHIFT,
    payload: shift,
  })
  export const getShiftSuccess = shift => ({
    type: GET_SHIFT_SUCCESS,
    payload: shift,
  })
  
  export const getShiftFail = error => ({
    type: GET_SHIFT_FAIL,
    payload: error,
  })

  export const postShift = shiftdata => ({
    type: POST_SHIFT,
    payload: shiftdata,
  })
  
  export const postShiftSuccess = shiftdata => ({
    type: POST_SHIFT_SUCCESS,
    payload: shiftdata,
  })
  
  export const postShiftFail = error => ({
    type: POST_SHIFT_FAIL,
    payload: error,
  })

  export const putShift = putshift => ({
    type: PUT_SHIFT,
    payload: putshift,
  })
  
  export const putShiftSuccess = putshift => ({
    type: PUT_SHIFT_SUCCESS,
    payload: putshift,
  })
  
  export const putShiftFail = error => ({
    type: PUT_SHIFT_FAIL,
    payload: error,
  })

  export const deleteShift = removeshift => ({
    type: DELETE_SHIFT,
    payload: removeshift,
  })
  export const deleteShiftSuccess = removeshift => ({
    type: DELETE_SHIFT_SUCCESS,
    payload: removeshift,
  })
  
  export const deleteShiftFail = error => ({
    type: DELETE_SHIFT_FAIL,
    payload: error,
  })

  export const getUren = urendata => ({
    type: GET_UREN,
    payload: urendata,
  })
  export const getUrenSuccess = urendata => ({
    type: GET_UREN_SUCCESS,
    payload: urendata,
  })
  
  export const getUrenFail = error => ({
    type: GET_UREN_FAIL,
    payload: error,
  })



  export const postUren = posturen => ({
    type: POST_UREN,
    payload: posturen,
  })
  export const postUrenSuccess = posturen => ({
    type: POST_UREN_SUCCESS,
    payload: posturen,
  })
  
  export const postUrenFail = error => ({
    type: POST_UREN_FAIL,
    payload: error,
  })


  export const putUren = puturen => ({
    type: PUT_UREN,
    payload: puturen,
  })
  export const putUrenSuccess = puturen => ({
    type: PUT_UREN_SUCCESS,
    payload: puturen,
  })
  
  export const putUrenFail = error => ({
    type: PUT_UREN_FAIL,
    payload: error,
  })


  export const removeUren = removeuren => ({
    type: DELETE_UREN,
    payload: removeuren,
  })
  export const removeUrenSuccess = removeuren => ({
    type: DELETE_UREN_SUCCESS,
    payload: removeuren,
  })
  
  export const removeUrenFail = error => ({
    type: DELETE_UREN_FAIL,
    payload: error,
  })


  export const getContractEmployer = contractemployer => ({
    type: GET_CONTRACT_EMPLOYEER,
    payload: contractemployer,
  })
  export const getContractEmployerSuccess = contractemployer => ({
    type: GET_CONTRACT_EMPLOYEER_SUCCESS,
    payload: contractemployer,
  })
  
  export const getContractEmployerFail = error => ({
    type: GET_CONTRACT_EMPLOYEER_FAIL,
    payload: error,
  })



 


  


  