import {
  GET_MAILS_LIST,
  GET_MAILS_LIST_FAIL,
  GET_MAILS_LIST_SUCCESS,
  GET_SELECTED_MAILS,
  GET_SELECTED_MAILS_SUCCESS,
  GET_SELECTED_MAILS_FAIL,
  SET_FOLDER_SELECTED_MAILS,
  SET_FOLDER_SELECTED_MAILS_SUCCESS,
  SET_FOLDER_SELECTED_MAILS_FAIL,
  SELECT_FOLDER,
  SELECT_FOLDER_SUCCESS,
  SELECT_FOLDER_FAIL,
  UPDATE_MAIL,
  UPDATE_MAIL_SUCCESS,
  UPDATE_MAIL_FAIL,
  SET_RESEND,
  SET_RESEND_SUCCESS,
  SET_RESEND_FAIL,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  SET_VALID_DATE,
  SET_VALID_DATE_SUCCESS,
  SET_VALID_DATE_FAIL,
  CREATE_FOLDER,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAIL,
  UPDATE_FOLDER,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAIL,
  UPDATE_POST_FOLDER,
  UPDATE_POST_FOLDER_SUCCESS,
  UPDATE_POST_FOLDER_FAIL,
  DELETE_POST_DATA,
  DELETE_POST_DATA_SUCCESS,
  DELETE_POST_DATA_FAIL,
  PERMANENTLY_DELETE,
  PERMANENTLY_DELETE_SUCCESS,
  PERMANENTLY_DELETE_FAIL,
  RECOVERY_MAIL,
  RECOVERY_MAIL_SUCCESS,
  RECOVERY_MAIL_FAIL,
  ACCOUNT_CLOSE,
  ACCOUNT_CLOSE_SUCCESS,
  ACCOUNT_CLOSE_FAIL,
  ACCOUNT_PROFILE,
  ACCOUNT_PROFILE_SUCCESS,
  ACCOUNT_PROFILE_FAIL,
  EDIT_ACCOUNT_PROFILE,
  EDIT_ACCOUNT_PROFILE_SUCCESS,
  EDIT_ACCOUNT_PROFILE_FAIL,
  GET_ACCOUNT_INVOICE,
  GET_ACCOUNT_INVOICE_SUCCESS,
  GET_ACCOUNT_INVOICE_FAIL,
  GET_BRANDING_INFO,
  GET_BRANDING_INFO_SUCCESS,
  GET_BRANDING_INFO_FAIL,
  POST_BRANDING_INFO,
  POST_BRANDING_INFO_SUCCESS,
  POST_BRANDING_INFO_FAIL,
  PUT_BRANDING_INFO,
  PUT_BRANDING_INFO_SUCCESS,
  PUT_BRANDING_INFO_FAIL,
  DELETE_BRANDING_INFO,
  DELETE_BRANDING_INFO_SUCCESS,
  DELETE_BRANDING_INFO_FAIL,
  GET_TEMPLATE_INFO,
  GET_TEMPLATE_INFO_SUCCESS,
  GET_TEMPLATE_INFO_FAIL,
  POST_TEMPLATE_INFO,
  POST_TEMPLATE_INFO_SUCCESS,
  POST_TEMPLATE_INFO_FAIL,
  PUT_TEMPLATE_INFO,
  PUT_TEMPLATE_INFO_SUCCESS,
  PUT_TEMPLATE_INFO_FAIL,
  DELETE_TEMPLATE_INFO,
  DELETE_TEMPLATE_INFO_SUCCESS,
  DELETE_TEMPLATE_INFO_FAIL,
  GET_USERS_INFO,
  GET_USERS_INFO_SUCCESS,
  GET_USERS_INFO_FAIL,
  POST_USERS_INFO,
  POST_USERS_INFO_SUCCESS,
  POST_USERS_INFO_FAIL,
  PUT_USERS_INFO,
  PUT_USERS_INFO_SUCCESS,
  PUT_USERS_INFO_FAIL,
  DELETE_USERS_INFO,
  DELETE_USERS_INFO_SUCCESS,
  DELETE_USERS_INFO_FAIL,
  GER_PERMISSION_CONTENT,
  GER_PERMISSION_CONTENT_SUCCESS,
  GER_PERMISSION_CONTENT_FAIL,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
} from "./actionTypes";

export const getNotifications = notification => ({
  type: GET_NOTIFICATION,
  notification,
})
export const getNotificationsSuccess = notification => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const getNotificationsFail = error => ({
  type: GET_NOTIFICATION_FAIL,
  payload: error,
})

export const getMailsLists = (filter) => ({
  type: GET_MAILS_LIST,
  payload: filter,
});

export const getMailsListsSuccess = mailslists => ({
  type: GET_MAILS_LIST_SUCCESS,
  payload: mailslists,
});

export const getMailsListsFail = error => ({
  type: GET_MAILS_LIST_FAIL,
  payload: error,
});

export const getSelectedMails = (selectedmails) => ({
  type: GET_SELECTED_MAILS,
  payload: selectedmails
});

export const getSelectedMailsSuccess = (selectedmails) => ({
  type: GET_SELECTED_MAILS_SUCCESS,
  payload: selectedmails,
});

export const getSelectedMailsFail = error => ({
  type: GET_SELECTED_MAILS_FAIL,
  payload: error,
});

export const setFolderOnSelectedMails = (selectedmails, folderId, activeTab) => ({
  type: SET_FOLDER_SELECTED_MAILS,
  payload: { selectedmails, folderId, activeTab }
});

export const setFolderOnSelectedMailsSuccess = () => ({
  type: SET_FOLDER_SELECTED_MAILS_SUCCESS
});

export const setFolderOnSelectedMailsFail = error => ({
  type: SET_FOLDER_SELECTED_MAILS_FAIL,
  payload: error,
});

export const selectFolders = (userId) => ({
  type: SELECT_FOLDER,
  payload: userId,
});

export const selectFoldersSuccess = folders => ({
  type: SELECT_FOLDER_SUCCESS,
  payload: folders,
});

export const selectFoldersFail = error => ({
  type: SELECT_FOLDER_FAIL,
  payload: error,
});

export const updateMail = mail => ({
  type : UPDATE_MAIL,
  payload : mail
})

export const updateMailSuccess = mail => ({
  type : UPDATE_MAIL_SUCCESS,
  payload : mail
})

export const updateMailFail = error => ({
  type : UPDATE_MAIL_FAIL,
  payload : error
})


export const resendmail = postdata => ({
  type : SET_RESEND,
  payload : postdata
})

export const resendmailSuccess = getdata => ({
  type : SET_RESEND_SUCCESS,
  payload : getdata
})

export const resendmailFail = error => ({
  type : SET_RESEND_FAIL,
  payload : error
})

export const updatestatus = statusid => ({
  type : UPDATE_STATUS,
  payload : statusid
})

export const updatestatusSuccess = getstatus => ({
  type : UPDATE_STATUS_SUCCESS,
  payload : getstatus
})

export const updatestatusFail = error => ({
  type : UPDATE_STATUS_FAIL,
  payload : error
})



export const setvalid = valid => ({
  type : SET_VALID_DATE,
  payload : valid
})

export const setvalidSuccess = getvalid => ({
  type : SET_VALID_DATE_SUCCESS,
  payload : getvalid
})

export const setvalidFail = error => ({
  type : SET_VALID_DATE_FAIL,
  payload : error
})

export const createfolder = newfolder => ({
  type : CREATE_FOLDER,
  payload : newfolder
})

export const createfolderSuccess = getfolder => ({
  type : CREATE_FOLDER_SUCCESS,
  payload : getfolder
})

export const createfolderFail = error => ({
  type : CREATE_FOLDER_FAIL,
  payload : error
})

export const updatefolderAction = updatefolder => ({
  type : UPDATE_FOLDER,
  payload : updatefolder
})

export const updatefolderrSuccess = resupdate => ({
  type : UPDATE_FOLDER_SUCCESS,
  payload : resupdate
})

export const updatefolderFail = error => ({
  type : UPDATE_FOLDER_FAIL,
  payload : error
})

export const deletepostquery = deletepost => ({
  type : DELETE_POST_DATA,
  payload : deletepost
})

export const deletepostquerySuccess = respostdelete => ({
  type : DELETE_POST_DATA_SUCCESS,
  payload : respostdelete
})

export const deletepostqueryFail = error => ({
  type : DELETE_POST_DATA_FAIL,
  payload : error
})


export const updatepostfolder = updatepostfolders => ({
  type : UPDATE_POST_FOLDER,
  payload : updatepostfolders
})

export const updatepostfolderSuccess = respostupdate => ({
  type : UPDATE_POST_FOLDER_SUCCESS,
  payload : respostupdate
})

export const updatepostfolderFail = error => ({
  type : UPDATE_POST_FOLDER_FAIL,
  payload : error
})

export const deletepermantlyquery = permantly => ({
  type : PERMANENTLY_DELETE,
  payload : permantly
})

export const deletepermantlyquerySuccess = permantlydelete => ({
  type : PERMANENTLY_DELETE_SUCCESS,
  payload : permantlydelete
})

export const deletepermantlyqueryFail = error => ({
  type : PERMANENTLY_DELETE_FAIL,
  payload : error
})


export const recoveryquery = revovery => ({
  type : RECOVERY_MAIL,
  payload : revovery
})

export const recoveryquerySuccess = revoverydelete => ({
  type : RECOVERY_MAIL_SUCCESS,
  payload : revoverydelete
})

export const recoveryqueryFail = error => ({
  type : RECOVERY_MAIL_FAIL,
  payload : error
})

export const accountClose = (userid) => ({
  type: ACCOUNT_CLOSE,
  payload: userid,
});
export const accountCloseSuccess = (userid) => ({
  type: ACCOUNT_CLOSE_SUCCESS,
  payload: userid,
});
export const accountCloseFail = (userid) => ({
  type: ACCOUNT_CLOSE_FAIL,
  payload: userid,
});

export const accountProfile = (profiles) => ({
  type: ACCOUNT_PROFILE,
  payload: profiles,
});
export const accountProfileSuccess = (profiles) => ({
  type: ACCOUNT_PROFILE_SUCCESS,
  payload: profiles,
});
export const accountProfileFail = (profiles) => ({
  type: ACCOUNT_PROFILE_FAIL,
  payload: profiles,
});


export const editAccountProfile = (profilesdata) => ({
  type: EDIT_ACCOUNT_PROFILE,
  payload: profilesdata,
});
export const editAccountProfileSuccess = (profilesdata) => ({
  type: EDIT_ACCOUNT_PROFILE_SUCCESS,
  payload: profilesdata,
});
export const editAccountProfileFail = (profilesdata) => ({
  type: EDIT_ACCOUNT_PROFILE_FAIL,
  payload: profilesdata,
});


export const accountInvoice = (invoice) => ({
  type: GET_ACCOUNT_INVOICE,
  payload: invoice,
});
export const accountInvoiceSuccess = (invoice) => ({
  type: GET_ACCOUNT_INVOICE_SUCCESS,
  payload: invoice,
});
export const accountInvoiceFail = (invoice) => ({
  type: GET_ACCOUNT_INVOICE_FAIL,
  payload: invoice,
});

export const getBrandingInfo = (info) => ({
  type: GET_BRANDING_INFO,
  payload: info,
});
export const getBrandingInfoSuccess = (info) => ({
  type: GET_BRANDING_INFO_SUCCESS,
  payload: info,
});
export const getBrandingInfoFail = (info) => ({
  type: GET_BRANDING_INFO_FAIL,
  payload: info,
});

export const postBrandingInfo = (postinfo) => ({
  type: POST_BRANDING_INFO,
  payload: postinfo,
});
export const postBrandingInfoSuccess = (postinfo) => ({
  type: POST_BRANDING_INFO_SUCCESS,
  payload: postinfo,
});
export const postBrandingInfoFail = (postinfo) => ({
  type: POST_BRANDING_INFO_FAIL,
  payload: postinfo,
});


export const putBrandingInfo = (putinfo) => ({
  type: PUT_BRANDING_INFO,
  payload: putinfo,
});
export const putBrandingInfoSuccess = (putinfo) => ({
  type: PUT_BRANDING_INFO_SUCCESS,
  payload: putinfo,
});
export const putBrandingInfoFail = (putinfo) => ({
  type: PUT_BRANDING_INFO_FAIL,
  payload: putinfo,
});


export const deleteBrandingInfo = (deleteinfo) => ({
  type: DELETE_BRANDING_INFO,
  payload: deleteinfo,
});
export const deleteBrandingInfoSuccess = (deleteinfo) => ({
  type: DELETE_BRANDING_INFO_SUCCESS,
  payload: deleteinfo,
});
export const deleteBrandingInfoFail = (deleteinfo) => ({
  type: DELETE_BRANDING_INFO_FAIL,
  payload: deleteinfo,
});




export const getTempInfo = (rules) => ({
  type: GET_TEMPLATE_INFO,
  payload: rules,
});

export const getTempInfoSuccess = (rules) => ({
  type: GET_TEMPLATE_INFO_SUCCESS,
  payload: rules,
});

export const getTempInfoFail = (error) => ({
  type: GET_TEMPLATE_INFO_FAIL,
  payload: error,
});

export const postTempInfo = (temps) => ({
  type: POST_TEMPLATE_INFO,
  payload: temps,
});

export const postTempInfoSuccess = (temps) => ({
  type: POST_TEMPLATE_INFO_SUCCESS,
  payload: temps,
});

export const postTempInfoFail = (temps) => ({
  type: POST_TEMPLATE_INFO_FAIL,
  payload: temps,
});


export const putTempInfo = (puttemps) => ({
  type: PUT_TEMPLATE_INFO,
  payload: puttemps,
});

export const putTempInfoSuccess = (puttemps) => ({
  type: PUT_TEMPLATE_INFO_SUCCESS,
  payload: puttemps,
});

export const putTempInfoFail = (puttemps) => ({
  type: PUT_TEMPLATE_INFO_FAIL,
  payload: puttemps,
});


export const deleteTempInfo = (removeid) => ({
  type: DELETE_TEMPLATE_INFO,
  payload: removeid,
});

export const deleteTempInfoSuccess = (removeid) => ({
  type: DELETE_TEMPLATE_INFO_SUCCESS,
  payload: removeid,
});

export const deleteTempInfoFail = (removeid) => ({
  type: DELETE_TEMPLATE_INFO_FAIL,
  payload: removeid,
});


export const getUsersInfo = (getuserrule) => ({
  type: GET_USERS_INFO,
  payload: getuserrule,
});

export const getUsersInfoSuccess = (getuserrule) => ({
  type: GET_USERS_INFO_SUCCESS,
  payload: getuserrule,
});

export const getUsersInfoFail = (getuserrule) => ({
  type: GET_USERS_INFO_FAIL,
  payload: getuserrule,
});

export const postUserInfo = (userrule) => ({
  type: POST_USERS_INFO,
  payload: userrule,
});

export const postUserInfoSuccess = (userrule) => ({
  type: POST_USERS_INFO_SUCCESS,
  payload: userrule,
});

export const postUserInfoFail = (userrule) => ({
  type: POST_USERS_INFO_FAIL,
  payload: userrule,
});


export const putUserInfo = (putuserrule) => ({
  type: PUT_USERS_INFO,
  payload: putuserrule,
});

export const putUserInfoSuccess = (putuserrule) => ({
  type: PUT_USERS_INFO_SUCCESS,
  payload: putuserrule,
});

export const putUserInfoFail = (putuserrule) => ({
  type: PUT_USERS_INFO_FAIL,
  payload: putuserrule,
});


export const deleteUserInfo = (removeuser) => ({
  type: DELETE_USERS_INFO,
  payload: removeuser,
});

export const deleteUserInfoSuccess = (removeuser) => ({
  type: DELETE_USERS_INFO_SUCCESS,
  payload: removeuser,
});

export const deleteUserInfoFail = (removeuser) => ({
  type: DELETE_USERS_INFO_FAIL,
  payload: removeuser,
});

export const getUsersPermissions = (permissionid) => ({
  type: GER_PERMISSION_CONTENT,
  payload: permissionid,
});

export const getUsersPermissionsSuccess = (permissionid) => ({
  type: GER_PERMISSION_CONTENT_SUCCESS,
  payload: permissionid,
});

export const getUsersPermissionsFail = (permissionid) => ({
  type: GER_PERMISSION_CONTENT_FAIL,
  payload: permissionid,
});