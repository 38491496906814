
const setData = [];

const getClientData = async () => {
  try {
    
    const response = await fetch("https://dspmetrics.com/api/website/setting");
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    
    // Assuming the response contains JSON data
    result.forEach(item => {
        setData.push(item);
    });
    return result;
  } catch (error) {
    console.error("Error fetching client data:", error);
    return []; // or handle the error in another way
  }
};

const fetchData = async () => {
  try {
    const data = await getClientData();
 
    
  } catch (error) {
    console.error("Error fetching client data:", error);
  }
};

fetchData();

export { setData };
