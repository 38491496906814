import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { map } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  NavItem,
  NavLink,
  CardTitle,
  CardSubtitle,
  TabContent,
  TabPane,
  Modal,
  InputGroup,
  Input,
} from "reactstrap"
import classnames from "classnames"
import { format } from "date-fns";
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  GetSurcharger,
  PostSurcharger,
  PutSurcharger,
  DelSurcharger,
} from "store/actions";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
const normalizeDateFormat = (formatString) => {
    // Replace incorrect tokens with valid date-fns tokens
    return formatString
      .replace(/DD/g, 'dd')   // Day tokens to lowercase
      .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
  };
const Surcharger = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const [modal_center, setmodal_center] = useState(false);
  const [updatemodal_center, setUpdatmodal_center] = useState(false);
  const dispatch = useDispatch();

  const [UpformData, setUpFormData] = useState({
    account_id:account_id,
    title: [{
        title:'',
    }],
    order: [{
        order:'0',
    }],
    Mon: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Tue: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Wed: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Thu:[{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Fri: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Sat: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Sun: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
  });

  const {
    deletesurcharger,
    getsurcharger,
    putsurcharger,
    postsurcharger,
    loading,
    error
  } = useSelector(state => ({
    getsurcharger: state.getShiftData.getsurcharger,
    postsurcharger: state.getShiftData.postsurcharger,
    putsurcharger: state.getShiftData.putsurcharger,
    deletesurcharger: state.getShiftData.deletesurcharger,
    error: state.getShiftData.error,
    loading: state.getShiftData.loading,
  }));
  useEffect(() => {
    if (account_id) {
      dispatch(GetSurcharger(account_id));
    }
  }, [dispatch, account_id]);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_Updatecenter() {
    setUpdatmodal_center(!updatemodal_center);
    removeBodyCss();
  }
  const [uprows, setUpRows] = useState({
    Mon: [{}],
    Tue: [{}],
    Wed: [{}],
    Thu: [{}],
    Fri: [{}],
    Sat: [{}],
    Sun: [{}],
  });
  const openEditModal = (group) => {

    const updatedFormData = {
        account_id: account_id, // Keep the current account_id
        title: [{ title: group.title_sur }], // Set the title
        token: group.token_sur, // Set the title
        order: [{ order: '0' }], // Example order value; adjust as needed
      };
    
      // Map days data (Mon, Tue, etc.)
      ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach((day) => {
        const dayData = group.days
          .filter((d) => d.day_sur === day)
          .map((entry, index) => ({
            index, // Add index to preserve the original order
            start: entry.start_time_sur,
            end: entry.end_time_sur,
            paid_surcharge_id: entry.paid_surcharge_id,
            time_surcharge_id: entry.time_surcharge_id,
          }))
          .sort((a, b) => b.index - a.index); // Sort by index in descending order
    
        updatedFormData[day] = dayData.length > 0 ? dayData : [{}]; // Ensure at least one default row exists
      });
      setUpFormData(updatedFormData);
      const updatedRows = {};
      Object.keys(updatedFormData).forEach((key) => {
        if (Array.isArray(updatedFormData[key])) {
          updatedRows[key] = updatedFormData[key].length ? updatedFormData[key] : [{}];
        }
      });
      setUpRows(updatedRows);
    tog_Updatecenter(true);
    removeBodyCss();
  };
  const addUpRow = (day) => {
    setUpRows((prevRows) => ({
      ...prevRows,
      [day]: [...prevRows[day], {}],
    }));
    setUpFormData((prevData) => {
        
        // Get the last row's end time if it exists, else default to '00:00'
        const lastRowEndTime = prevData[day]?.[prevData[day].length - 1]?.end || '00:00';
         
        return {
          ...prevData,
          [day]: [
            ...prevData[day],
            {
              start: lastRowEndTime,  // Set start time to previous row's end time
              end: '00:00',           // Default end time
              paid_surcharge_id: '100',
              time_surcharge_id: '100',
            },
          ],
        };
      });
    };

  const removeUpRow = (day, index) => {
    setUpRows((prevRows) => ({
      ...prevRows,
      [day]: prevRows[day].length > 1 ? prevRows[day].filter((_, i) => i !== index) : prevRows[day],
    }));
    setUpFormData((prevData) => ({
        ...prevData,
        [day]: prevData[day].length > 1
          ? prevData[day].filter((_, i) => i !== index)
          : prevData[day],
      }));
}

const removeModal = (title) => {
    if (title) {
      
      dispatch(DelSurcharger(title));;
      toastr.success(t('Form submitted.'));
    } else {
      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }

  };


  const [rows, setRows] = useState({
    Mon: [{}],
    Tue: [{}],
    Wed: [{}],
    Thu: [{}],
    Fri: [{}],
    Sat: [{}],
    Sun: [{}],
  });
  const [formData, setFormData] = useState({
    account_id:account_id,
    title: [{
        title:'',
    }],
    order: [{
        order:'0',
    }],
    Mon: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Tue: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Wed: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Thu:[{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Fri: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Sat: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
    Sun: [{
        start:'00:00',
        end:'23:00',
        paid_surcharge_id:'100',
        time_surcharge_id:'100',
    }],
  });

  const addRow = (day) => {
    setRows((prevRows) => ({
      ...prevRows,
      [day]: [...prevRows[day], {}],
    }));
    setFormData((prevData) => {
        
        // Get the last row's end time if it exists, else default to '00:00'
        const lastRowEndTime = prevData[day]?.[prevData[day].length - 1]?.end || '00:00';
         
        return {
          ...prevData,
          [day]: [
            ...prevData[day],
            {
              start: lastRowEndTime,  // Set start time to previous row's end time
              end: '00:00',           // Default end time
              paid_surcharge_id: '100',
              time_surcharge_id: '100',
            },
          ],
        };
      });
    };

  const removeRow = (day, index) => {
    setRows((prevRows) => ({
      ...prevRows,
      [day]: prevRows[day].length > 1 ? prevRows[day].filter((_, i) => i !== index) : prevRows[day],
    }));
    setFormData((prevData) => ({
        ...prevData,
        [day]: prevData[day].length > 1
          ? prevData[day].filter((_, i) => i !== index)
          : prevData[day],
      }));
}

const handleInputChange = (day, index, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [day]: prevData[day].map((row, i) =>
        i === index ? { ...row, [field]: value } : row
      ),
    }));
  };
  const handleUpdateInputChange = (day, index, field, value) => {
    setUpFormData((prevData) => ({
      ...prevData,
      [day]: prevData[day].map((row, i) =>
        i === index ? { ...row, [field]: value } : row
      ),
    }));
  };
  const validateFormData = (data) => {
    const errors = [];
  
    // Validate title
    if (!data.title || !data.title[0]?.title?.trim()) {
      errors.push(t("Title is required."));
    }
  
    // Validate order
    if (!data.order || !data.order[0]?.order?.trim()) {
      errors.push(t("Order is required."));
    }
  
    // Validate time ranges for each day
    ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach((day) => {
      const dayArray = data[day];
  
      if (Array.isArray(dayArray)) {
        dayArray.forEach((entry, index) => {
          const { start, end } = entry;
  
          // Ensure start and end times exist
          if (!start || !end) {
            errors.push(`${day} entry ${index + 1}: ${t('Start and End times are required.')}`);
            return;
          }
  
          // Ensure valid time range for this entry
          if (start >= end) {
            errors.push(`${day}  ${t('entry')}  ${index + 1}: ${t('Start time')}  (${start}) ${t('must be earlier than End time')} (${end}).`);
          }
  
          // Ensure no overlap with previous entries
          if (index > 0) {
            const prevEntry = dayArray[index - 1];
  
            // Ensure current start time is not earlier than the previous end time
            if (start < prevEntry.end) {
              errors.push(
                `${day} ${t('entry')} ${index + 1}: ${t('Start time')} (${start}) ${t('must not be earlier than the previous End time')} (${prevEntry.end}).`
              );
            }
          }
        });
      }
    });
  
    return errors;
  };
  
  const handleFormSubmit = () => {
    const validationErrors = validateFormData(formData);
  
    if (validationErrors.length > 0) {
      console.error("Validation Errors: ", validationErrors);
      toastr.error(t(validationErrors));
      return;
    }
  
    dispatch(PostSurcharger(formData));
  };
  const prepareUpdatePayload = (data) => {
    const { title, order,token, account_id, ...days } = data;
    const formatTime = (time) => {
        if (!time) return '00:00:00'; // Default value
        if (time.length === 5) return `${time}`; // Add seconds if missing
        return time; // Already in HH:mm:ss format
      };
    // Flatten the day-wise data into a single `updates` array
    const updates = Object.keys(days).flatMap((day) => {
      return days[day].map((entry) => ({
        day, // Day name (e.g., Mon, Tue)
        start_time: formatTime(entry.start), // Format start time
        end_time: formatTime(entry.end), 
        paid_surcharge_id: entry.paid_surcharge_id || '100', // Default ID
        time_surcharge_id: entry.time_surcharge_id || '100', // Default ID
        order_sur: order[0]?.order || 0, // Include the order number
      }));
    });
  
    // Return the formatted payload
    return {
      account_id,
      title: title[0]?.title || '', // Extract the title
      token: token || '',
      updates,
    };
  };
  
  const handleUpFormSubmit = () => {
    const validationErrors = validateFormData(UpformData);
    const payload = prepareUpdatePayload(UpformData);
    if (validationErrors.length > 0) {
      console.error("Validation Errors: ", validationErrors);
      toastr.error(t(validationErrors));
      return;
    }
    dispatch(PutSurcharger(payload));
  };
  const groupSurchargeData = (data) => {
    const groupedData = {};
  
    data.forEach((item) => {
      const {
        title_sur,
        token_sur,
        day_sur,
        start_time_sur,
        end_time_sur,
        paid_surcharge_id,
        time_surcharge_id,
        create_time_sur,
        update_time_sur,
      } = item;
  
      if (!groupedData[title_sur]) {
        groupedData[title_sur] = {
          title_sur,
          token_sur,
          create_time: create_time_sur || new Date().toISOString(),
          update_time: update_time_sur || new Date().toISOString(),
          days: [],
        };
      }
  
      groupedData[title_sur].days.push({
        day_sur,
        start_time_sur,
        end_time_sur,
        paid_surcharge_id,
        time_surcharge_id,
      });
    });
  
    return groupedData;
  };
  const groupedSurcharger = groupSurchargeData(getsurcharger); 
  

  useEffect(() => {
    const handleSuccess = (message) => {
      toastr.success(t(message));
      dispatch(GetSurcharger(account_id));
     
    };
  
    const handleError = (message) => {
      toastr.error(t(message));
    };
  
    if (deletesurcharger?.success) {
      handleSuccess('Surcharge deleted successfully! The data has been removed from the system.');
    } else if (deletesurcharger?.error) {
      handleError('Failed to delete surcharge data. Please try again or contact support for assistance.');
    }
  
    if (putsurcharger?.success ) { // Avoid redundant calls
      handleSuccess('Surcharge updated successfully! All changes have been saved.');
   
    } else if (putsurcharger?.error) {
      handleError('Failed to update surcharge data. Please verify the information and try again.');
    }

    if (postsurcharger?.success ) { // Avoid redundant calls
        handleSuccess('Surcharge added successfully! The new surcharge data is now available for use.');
      } else if (postsurcharger?.error) {
        handleError('Failed to add surcharge data. Please check the details and try again.');
      }
  }, [postsurcharger, putsurcharger, dispatch, account_id, t,deletesurcharger]);
  return (
    <React.Fragment>
<Modal
                      isOpen={updatemodal_center}
                      toggle={() => {
                        tog_Updatecenter();
                      }}
                      centered
                      scrollable={true}
                      size="lg"
                    >
                      <div className="modal-header">
                      
                        <h5 className="modal-title mt-0">{t('Modify Surcharge')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            tog_Updatecenter(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="row">
      {/* Name Field */}
      <div className="col-lg-8">
        <div className="form-modal__element">
          <label
            className="form-modal__label"
            htmlFor="holiday-name"
            style={{ textAlign: 'right' }}
          >
            {t('Name')}
          </label>
          <div className="items-center form-modal__group">
            <input
              type="text"
              name="name"
              id="holiday-name"
              className="form-modal__input"
              defaultValue={UpformData["title"][0]?.title} 
              onChange={(e) => handleUpdateInputChange("title", 0, "title", e.target.value)}
            />
          </div>
          <div className="form-modal__group">
            <div className="control-messages"></div>
          </div>
        </div>
      </div>

      {/* Order Field */}
      <div className="col-lg-4">
        <div className="form-modal__element">
          <label
            className="form-modal__label"
            htmlFor="order"
            style={{ textAlign: 'right' }}
          >
            {t('Order')}
          </label>
          <div className="items-center form-modal__group">
            <input
              type="number"
              name="order"
              id="order"
              min="1"
              max="999999"
              className="form-modal__input"
              defaultValue={UpformData["order"][0]?.order} 
              onChange={(e) => handleUpdateInputChange("order", 1, "order", e.target.value)}
              
            />
          </div>
          <div className="form-modal__group">
            <div className="control-messages"></div>
          </div>
        </div>
      </div>
    </div>

    {/* Table Structure */}
    <table className="table-border">
      <thead style={{ textAlign: 'center' }}>
        <tr className="tr-tbl">
          <th>{t('Start time')}</th>
          <th>{t('End time')}</th>
          <th>{t('Payout')}</th>
          <th>
          {t('Plus minus')}
            <span className="ng-star-inserted">
              <i className="icon-lock" aria-hidden="true"></i>
            </span>
          </th>
          <th className="actions"></th>
        </tr>
      </thead>

      {/* Table Body */}
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Mon')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
         {uprows.Mon.map((_, index) => (
        <tr key={`Mon-${index}`} className="group-inline">
          <td>
            <div className="group-tbl-w inline-block w-28">
            <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Mon"][index]?.start} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Mon", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>

            </div>
          </td>
          <td>
            <div className="group-tbl-w inline-block w-28">
            <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Mon"][index]?.end } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Mon", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>

            </div>
          </td>
          <td>
            <select name="paid_surcharge_id" 
            onChange={(e) => handleUpdateInputChange("Mon", index, "paid_surcharge_id", e.target.value)}
             className="select_tbl"
             defaultValue={UpformData["Mon"][index]?.paid_surcharge_id}
             >
       {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
            </select>
          </td>
          <td>
            <select name="time_surcharge_id" 
            onChange={(e) => handleUpdateInputChange("Mon", index, "time_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={UpformData["Mon"][index]?.time_surcharge_id}
            >
                  {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
            </select>
          </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addUpRow('Mon')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeUpRow('Mon', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Tue')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
        {uprows.Tue.map((_, index) => (
         <tr key={`Tue-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Tue"][index]?.start} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Tue", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
            
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Tue"][index]?.end } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Tue", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
     
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Tue", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={UpformData["Tue"][index]?.paid_surcharge_id}
            >
                      {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Tue", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={UpformData["Tue"][index]?.time_surcharge_id }
           >
                         {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addUpRow('Tue')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeUpRow('Tue', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Wed')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
     
     {uprows.Wed.map((_, index) => (
         <tr key={`Wed-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Wed"][index]?.start } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Wed", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
      
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Wed"][index]?.end } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Wed", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Wed", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={UpformData["Wed"][index]?.paid_surcharge_id }
            >
                       {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Wed", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={UpformData["Wed"][index]?.time_surcharge_id }
           >
                      {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addUpRow('Wed')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeUpRow('Wed', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Thu')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row  Thu */}
        {uprows.Thu.map((_, index) => (
         <tr key={`Thu-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Thu"][index]?.start } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Thu", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
             
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Thu"][index]?.end } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Thu", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Thu", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={UpformData["Thu"][index]?.paid_surcharge_id }
            >
                      {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Thu", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={UpformData["Thu"][index]?.time_surcharge_id }
           >
                {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addUpRow('Thu')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeUpRow('Thu', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Fri')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row  Fri */}
        {uprows.Fri.map((_, index) => (
         <tr key={`Fri-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Fri"][index]?.start } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Fri", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Fri"][index]?.end} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Fri", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Fri", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={UpformData["Fri"][index]?.paid_surcharge_id }
            >
                    {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Fri", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={UpformData["Fri"][index]?.time_surcharge_id }
           >
                     {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addUpRow('Fri')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeUpRow('Fri', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Sat')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
        {uprows.Sat.map((_, index) => (
         <tr key={`Sat-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Sat"][index]?.start } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Sat", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
            
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Sat"][index]?.end} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Sat", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Sat", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={UpformData["Sat"][index]?.paid_surcharge_id }
            >
                       {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Sat", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={UpformData["Sat"][index]?.time_surcharge_id }
           >
                   {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addUpRow('Sat')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeUpRow('Sat', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Sun')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
        {uprows.Sun.map((_, index) => (
         <tr key={`Sun-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Sun"][index]?.start } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Sun", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={UpformData["Sun"][index]?.end } // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleUpdateInputChange("Sun", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Sun", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={UpformData["Sun"][index]?.paid_surcharge_id}
            >

        {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleUpdateInputChange("Sun", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={UpformData["Sun"][index]?.time_surcharge_id }
           >
        {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addUpRow('Sun')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeUpRow('Sun', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
          
        </tr>
        
      ))}

        {/* Additional Rows as needed */}
      </tbody>
    </table>
    
                      </div>
                      <div className="modal-footer">
                          <button type="button"
                          
                           className="btn css-1bl3roz startbtn btn btn-Primira"
                           style={{width:'20%',marginRight:'10px'}}
                           onClick={() => {
                            handleUpFormSubmit()
                          }}
                           >
                           {t('Update')}
                          </button>

                        </div>
                    </Modal>
<Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      centered
                      scrollable={true}
                      size="lg"
                    >
                      <div className="modal-header">
                      
                        <h5 className="modal-title mt-0">{t('Add Surcharge')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="row">
      {/* Name Field */}
      <div className="col-lg-8">
        <div className="form-modal__element">
          <label
            className="form-modal__label"
            htmlFor="holiday-name"
            style={{ textAlign: 'right' }}
          >
            {t('Name')}
          </label>
          <div className="items-center form-modal__group">
            <input
              type="text"
              name="name"
              id="holiday-name"
              className="form-modal__input"
              onChange={(e) => handleInputChange("title", 0, "title", e.target.value)}
            />
          </div>
          <div className="form-modal__group">
            <div className="control-messages"></div>
          </div>
        </div>
      </div>

      {/* Order Field */}
      <div className="col-lg-4">
        <div className="form-modal__element">
          <label
            className="form-modal__label"
            htmlFor="order"
            style={{ textAlign: 'right' }}
          >
            {t('Order')}
          </label>
          <div className="items-center form-modal__group">
            <input
              type="number"
              name="order"
              id="order"
              min="1"
              max="999999"
              className="form-modal__input"
              onChange={(e) => handleInputChange("order", 0, "order", e.target.value)}
              
            />
          </div>
          <div className="form-modal__group">
            <div className="control-messages"></div>
          </div>
        </div>
      </div>
    </div>

    {/* Table Structure */}
    <table className="table-border">
      <thead style={{ textAlign: 'center' }}>
        <tr className="tr-tbl">
          <th>{t('Start time')}</th>
          <th>{t('End time')}</th>
          <th>{t('Payout')}</th>
          <th>
          {t('Plus minus')}
            <span className="ng-star-inserted">
              <i className="icon-lock" aria-hidden="true"></i>
            </span>
          </th>
          <th className="actions"></th>
        </tr>
      </thead>

      {/* Table Body */}
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Mon')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
         {rows.Mon.map((_, index) => (
        <tr key={`Mon-${index}`} className="group-inline">
          <td>
            <div className="group-tbl-w inline-block w-28">
            <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Mon"][index]?.start || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Mon", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>

            </div>
          </td>
          <td>
            <div className="group-tbl-w inline-block w-28">
            <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Mon"][index]?.end || "23:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Mon", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>

            </div>
          </td>
          <td>
            <select name="paid_surcharge_id" 
            onChange={(e) => handleInputChange("Mon", index, "paid_surcharge_id", e.target.value)}
             className="select_tbl"
             defaultValue={formData["Mon"][index]?.paid_surcharge_id || "100"}
             >
       {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
            </select>
          </td>
          <td>
            <select name="time_surcharge_id" 
            onChange={(e) => handleInputChange("Mon", index, "time_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={formData["Mon"][index]?.time_surcharge_id || "100"}
            >
                  {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
            </select>
          </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addRow('Mon')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeRow('Mon', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
          
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Tue')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
        {rows.Tue.map((_, index) => (
         <tr key={`Tue-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Tue"][index]?.start || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Tue", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
            
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Tue"][index]?.end || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Tue", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
     
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleInputChange("Tue", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={formData["Tue"][index]?.paid_surcharge_id || "100"}
            >
                      {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleInputChange("Tue", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={formData["Tue"][index]?.time_surcharge_id || "100"}
           >
                         {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addRow('Tue')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeRow('Tue', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Wed')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
     
     {rows.Wed.map((_, index) => (
         <tr key={`Wed-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Wed"][index]?.start || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Wed", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
      
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Wed"][index]?.end || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Wed", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleInputChange("Wed", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={formData["Wed"][index]?.paid_surcharge_id || "100"}
            >
                       {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleInputChange("Wed", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={formData["Wed"][index]?.time_surcharge_id || "100"}
           >
                      {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addRow('Wed')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeRow('Wed', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Thu')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row  Thu */}
        {rows.Thu.map((_, index) => (
         <tr key={`Thu-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Thu"][index]?.start || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Thu", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
             
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Thu"][index]?.end || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Thu", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleInputChange("Thu", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={formData["Thu"][index]?.paid_surcharge_id || "100"}
            >
                      {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleInputChange("Thu", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={formData["Thu"][index]?.time_surcharge_id || "100"}
           >
                {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addRow('Thu')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeRow('Thu', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Fri')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row  Fri */}
        {rows.Fri.map((_, index) => (
         <tr key={`Fri-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Fri"][index]?.start || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Fri", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Fri"][index]?.end || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Fri", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleInputChange("Fri", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={formData["Fri"][index]?.paid_surcharge_id || "100"}
            >
                    {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleInputChange("Fri", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={formData["Fri"][index]?.time_surcharge_id || "100"}
           >
                     {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addRow('Fri')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeRow('Fri', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Sat')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
        {rows.Sat.map((_, index) => (
         <tr key={`Sat-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Sat"][index]?.start || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Sat", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
            
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Sat"][index]?.end || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Sat", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleInputChange("Sat", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={formData["Sat"][index]?.paid_surcharge_id || "100"}
            >
                       {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleInputChange("Sat", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={formData["Sat"][index]?.time_surcharge_id || "100"}
           >
                   {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addRow('Sat')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeRow('Sat', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
      <tbody style={{ textAlign: 'center' }}>
        <tr className="group-tbl">
          <td colSpan="5">
            <div className="table-rate-card__day" style={{ textAlign: 'left' }}>
              <div>{t('Sun')}</div>
            </div>
          </td>
        </tr>

        {/* Example Row */}
        {rows.Sun.map((_, index) => (
         <tr key={`Sun-${index}`} className="group-inline">
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Sun"][index]?.start || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Sun", index, "start", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <div className="group-tbl-w inline-block w-28">
           <InputGroup>
  <Flatpickr
    className="form-control d-block"
    placeholder={t("Select time")}
    value={formData["Sun"][index]?.end || "01:00"} // Set default value
    onChange={([selectedDate]) => {
      const time = selectedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      handleInputChange("Sun", index, "end", time);
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    }}
  />
</InputGroup>
           </div>
         </td>
         <td>
           <select name="paid_surcharge_id" 
           onChange={(e) => handleInputChange("Sun", index, "paid_surcharge_id", e.target.value)}
            className="select_tbl"
            defaultValue={formData["Sun"][index]?.paid_surcharge_id || "100"}
            >

        {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
         <td>
           <select name="time_surcharge_id" 
           onChange={(e) => handleInputChange("Sun", index, "time_surcharge_id", e.target.value)}
           className="select_tbl"
           defaultValue={formData["Sun"][index]?.time_surcharge_id || "100"}
           >
        {Array.from({ length: 21 }, (_, i) => {
    const value = 100 + i * 5; // Generates values from 100 to 200 in increments of 5
    return (
      <option key={value} value={value}>
        {value.toFixed(2)}%
      </option>
    );
  })}
           </select>
         </td>
          <td className="actions">
          <div className="btn-group sub-header__buttons">
          <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#e9e9e9', color: '#5f5b5b', marginRight: '5px',minWidth:'35px' }}
              onClick={() => addRow('Sun')}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
            </button>
            <button
              className="css-1m5o92us"
              type="button"
              style={{ backgroundColor: '#ffcccc', color: '#ff0000' ,minWidth:'35px'}}
              onClick={() => removeRow('Sun', index)}
            >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 8c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1z"></path>
                </svg>
              </span>
            </button>
              </div>
            
          </td>
        </tr>
      ))}

        {/* Additional Rows as needed */}
      </tbody>
    </table>
    
                      </div>
                      <div className="modal-footer">
                          <button type="button"
                          
                           className="btn css-1bl3roz startbtn btn btn-Primira"
                           style={{width:'20%',marginRight:'10px'}}
                           onClick={() => {
                            handleFormSubmit()
                          }}
                           >
                           {t('Next')}
                          </button>


                        </div>
                    </Modal>
    <div style={{padding:'20px'}}>
    <div className="table-responsive" style={{marginTop:'20px'}}>
        <Button
        id="pdfButton1"
       
        type="button"
        className="btn  css-1bl3roz"
        style={{ background: '#d6d8da',color:'#000000', border: 'none',height:'40px',justifyContent:'space-between' }}
        >
           {t('Surcharge')}
           <div className="mb-3 row" style={{position:'relative',top:'7px'}}>
    
           <div className="btn-group sub-header__buttons">
            <button   disabled={!isEditorAllowed} onClick={() => {tog_center()}} className="css-1m5o92us" type="button">
              <span  className="css-23dsfxs" >
                <svg viewBox="0 0 16 16" height="100%" width="100%" >
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span> 
              <span  className="css-1ixbps20l d-none d-md-block">{t('Add Surcharge')}</span>
            </button>
          </div>
      </div>
      
        </Button>  
        <Table className="align-middle mb-0">
  <thead>
    <tr>
      <th>{t('Title')}</th>
      <th>{t('Create By')}</th>
      <th>{t('Update By')}</th>
      <th>{t('Actions')}</th>
    </tr>
  </thead>
  <tbody>
    {Object.keys(groupedSurcharger).map((key) => {
      const group = groupedSurcharger[key];
      return (
        <tr key={key}>
          <td>{group.title_sur}</td>
          <td>{format(new Date(group.create_time), DateTimeFormat)}</td>
          <td>{format(new Date(group.update_time), DateTimeFormat)}</td>
          <td>
            <button
              disabled={!isEditorAllowed}
              type="button"
              className="btn btn-light btn-sm"
              style={{ marginRight: '10px' }}
              onClick={() => openEditModal(group)}
            >
             {t('Edit')} 
            </button>
            <button
              disabled={!isEditorAllowed}
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => removeModal(group.title_sur)}
            >
             {t('Remove')} 
            </button>
          </td>
        </tr>
      );
    })}
  </tbody>
</Table>

                    </div>
  
       
     
        
         
      
    </div>
  </React.Fragment>
  )
}


export default withTranslation()(withRouter(Surcharger));