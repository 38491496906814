// ModalComponent.jsx
import React, { useState, useEffect, useRef } from 'react';
import withRouter from "components/Common/withRouter";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";

  import { Link, useNavigate ,useParams } from "react-router-dom";
  import PdfViewer, { PDFViewerWrapped as PdfPagination } from './PdfViewer';
  import  SettingText from './SettingText';
  import CheckBoxGroup from './CheckBoxGroup';
  import RadioBoxGroup from './RadioBoxGroup';
  import SignImg from './SignImg';
  import SettingCalendar from './SettingCalendar';
  import SettingTextarea from './SettingTextarea';
  import Fullname from './Fullname';
  import EmailSetting from './EmailSetting';
  import CompanySetting from './CompanySetting';
  import NumberSetting from './NumberSetting';
  import Payment from './Payment';
  import PdfTitle from './PdfTitle';
  import DrapDown from './DrapDown';
  import Drawing from './Drawing';
  import Formula from './Formula';
  import Attachment from './Attachment';
  import Approve from './Approve';
  import Decline from './Decline';
  import DraggableList from './DraggableList';
  import PostToDataBackend from './postData';
  import ActionDropDown from './Actions';
  import Preview from './Preview';
  import {
    getSignatureImgByEmail,
    getSignatureImgByEmailSuccess,
    getSignatureImgByEmailFail,
    getDocument,
    getDocumentSuccess,
    getDocumentFail,
    postSigndata,
    postSigndataFail,
    postSigndatatSuccess
  } from "store/actions";
  import { createSelector } from "reselect";
  import { useSelector, useDispatch } from "react-redux";
  import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";
const ModalComponent = (props) => {
  const { t ,permissions} = props;
  useEffect(() => {
    document.title = t("Set Your Digital Signature - Secure E-Signature Setup | eSignatureHub");
  }, [t]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plan_name ,editor } = permissions || {};
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, settoken] = useState(user.account_token);
  
    const [modal_fullscreen, setmodal_fullscreen] = useState(true);
    const [selectedOption, setSelectedOption] = useState("");
    const pdfContainerRef = useRef(null);
    const { uuid } = useParams();
  const [togModal1, setTogModal1] = useState(false);
  const [togModal2, setTogModal2] = useState(false);
  const [togModal3, setTogModal3] = useState(false);
    function tog_fullscreen() {
      setmodal_fullscreen(!modal_fullscreen);
      removeBodyCss();
    }
  
    function tog_toggleModal1() {
      setTogModal1(!togModal1);
      
      removeBodyCss();
    }
    function tog_toggleModal2() {
      setTogModal1(!togModal2);
      
      removeBodyCss();
    }
    function tog_toggleModal3() {
      setTogModal1(!togModal3);
      
      removeBodyCss();
    }
    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }
    const handleSelectOption = (option) => {
      setSelectedOption(option);
    };
  
    const [selectedZoom, setSelectedZoom] = useState(1);
    const [showZoomMenu, setShowZoomMenu] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recipientNames, setRecipientNames] = useState([]);
    const [pdftoimage, setpdftoimage] = useState([]);
    const [recipientEmails, setRecipientEmail] = useState([])
    const [recipientMsg, setRecipientMsg] = useState([])
    const [recipientroll, setRecipientRoll] = useState([]);
    const [recipienid, setRecipienid] = useState([]);
    const [Colors, setColor] = useState([]);
    const [docNames, setDocNames] = useState([]);
    const [originalName, setOriginalName] = useState('');
    const [draggableType, setDraggableType] = useState(null);
    const [draggableNameType, setDraggableNameType] = useState(null);
    const [draggableData, setDraggableData] = useState(null);
    const [draggableElements, setDraggableElements] = useState([]);
    const [draggableElementName, setDraggableElementName] = useState([]);
    const [isAllowed, setIsAllowed] = useState(false);
    const handleDragStart = (e, item) => {
      // Add your drag start logic here
      e.dataTransfer.setData('text/plain', item); // Set the data to be transferred during drag
    };
    const handleDragEnd = (e) => {
      // Add your drag end logic here
    };
    const zoomOptions = [
      { value: 1, label: '100%' },
      { value: 1.1, label: '110%' },
      { value: 1.2, label: '120%' },
      { value: 1.3, label: '130%' },
      { value: 1.4, label: '140%' },
      { value: 1.5, label: '150%' },
      { value: 1.6, label: '160%' },
      { value: 1.7, label: '170%' },
    ];// Adjust the zoom options as needed
  
    const handleZoomClick = (zoomValue) => {
      setSelectedZoom(zoomValue);
      setShowZoomMenu(false);
    };
  
    
    
    const handleZoomMenuToggle = () => {
      setShowZoomMenu((prevShowZoomMenu) => !prevShowZoomMenu);
    };
  
    const onPageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

  const selectDocState = (state) => state.Prepare;

  const DocumentProperties = createSelector(
    selectDocState,
    (getSigantureImgData) => ({
      docname: getSigantureImgData.docname,
    })
  );

  const { docname } = useSelector(DocumentProperties);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (uuid) {
          await dispatch(getDocument(uuid));
        } else {
          console.error("User ID is not defined");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [dispatch, uuid]);

    useEffect(() => {
      
      if (docname && docname.length > 0) {
        const extractedData = docname.reduce(
          (acc, item) => {
            setOriginalName(item.orginal_name)
            if (item.recipient_data !== null) {
              const recipients = JSON.parse(item.recipient_data);
            
              if (recipients !== null) {
                recipients.forEach((recipient) => {
                  acc.recipientNames.push(recipient.recipientName);
                  acc.recipientEmail.push(recipient.recipientEmail);
                  acc.recipientMsg.push(recipient.recipientMsg);
                  acc.recipientroll.push(recipient.recipientroll);
                  acc.Colors.push(recipient.color);
                  acc.recipienid.push(recipient.recipienid);
                });
              }
            }
            return acc;
          },
          {
            recipientEmail: [],
            recipientMsg: [],
            recipientroll: [],
            Colors: [],
            recipientNames: [],
            recipienid: [],
          }
        );
      
        // Check if extractedData is not empty before setting state variables
        if (Object.keys(extractedData).length !== 0) {
          setpdftoimage(docname[0].pdfpic);
          setDocNames(docname[0].doc_name);
          setRecipientNames(extractedData.recipientNames);
          setRecipientEmail(extractedData.recipientEmail);
          setRecipientMsg(extractedData.recipientMsg);
          setRecipientRoll(extractedData.recipientroll);
          setColor(extractedData.Colors);
          setRecipienid(extractedData.recipienid);
        }
      }
      
    }, [docname]);
    
    const handleDraggableType = (type) => {
      setDraggableType(type);
  
    };
    const handleDraggableNameType = (type) => {
      setDraggableNameType(type);
  
    };
  
  
    const handleNameSelected = (
      targetid,
      datatype,
      fontfamily,
      fontsize,
      fontformat,
      fontcolor,
      names,
      mails,
      company,
      pdftile,
      textarea,
      numbers,
      checkbox,
      dropdown,
      radios,
      payments,
      drawing,
      formula,
      attachment,
      approve,
      decline,
      ) => {
      // console.log('Input date:', date);
       const updatedNameData = {
         targetid:targetid,
         datatype:datatype,
         fontcolor:fontcolor,
         fontfamily:fontfamily,
         fontformat:fontformat,
         fontsize:fontsize,
         names:names,
         mails:mails,
         company:company,
         pdftile:pdftile,
         textarea:textarea,
         numbers:numbers,
         checkbox:checkbox,
         dropdown:dropdown,
         radios:radios,
         payments:payments,
         drawing:drawing,
         formula:formula,
         attachment:attachment,
         approve:approve,
         decline:decline,
       }
  
       setDraggableElementName(updatedNameData);
     };
    const handleDateSelected = (
      targetid,
       date,
       fontfamily,
       fontsize,
       fontformat,
       fontcolor,
       dateformat,
       signpng,
       paraaf,
       stamp,
       datatype,
       roll,
       qrcodevalue,
       qrcodedisplay,
       qrcodeborder,
       qrcodesize,
       qrcodefgcolor,
       qrcodebgcolor,
    
       ) => {
     // console.log('Input date:', date);
     // console.log('Date format:', dateformat);
    
      const formatDate = (date, format) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
    
        const formattedDate = format
          .replace('Y', year)
          .replace('m', month)
          .replace('d', day);
    
        return formattedDate;
      };
      const formattedDate = formatDate(new Date(date), dateformat);
      const updatedElements = {
        targetid:targetid,
        date:formattedDate,
        fontfamily:fontfamily,
        fontsize:fontsize,
        fontformat:fontformat,
        fontcolor:fontcolor,
        dateformat:dateformat,
        signpng:signpng,
        paraaf:paraaf,
        stamp:stamp,
        datatype:datatype,
        roll:roll,
        qrcodevalue:qrcodevalue,
        qrcodedisplay:qrcodedisplay,
        qrcodeborder:qrcodeborder,
        qrcodesize:qrcodesize,
        qrcodefgcolor:qrcodefgcolor,
        qrcodebgcolor:qrcodebgcolor,
      }
      setDraggableElements(updatedElements);
  
    };
    
  
   
  
    
    useEffect(() => {
    }, [draggableType,draggableNameType]);
    const addObjectToArray = async (event) => {

 
      
    };
  
    let draggableComponent = <PdfPagination id={draggableData?.id} docNames={docNames} />;
 
    switch (draggableNameType?.format ) {
      case 'delete':
        draggableComponent = <PdfPagination id={draggableData?.id} docNames={docNames} />;
      break;
      case 'handwriteing':
        
        draggableComponent = <SignImg
        datafontcolor={draggableType?.fontcolor}
        datafontfamily={draggableType?.fontfamily}
        datafontformat={draggableType?.fontformat}
        datafontsize={draggableType?.fontsize}
         datatype={draggableType?.format}
        dataparaaf={draggableType?.paraaf} 
        datasignPng={draggableType?.signpng} 
        datastamp={draggableType?.stamp} 
        dataroll={draggableType?.roll}
        dataqrcodevalue={draggableType?.qrcodevalue}
        dataqrqrcodedisplay={draggableType?.qrcodedisplay} 
        dataqrcodeborder={draggableType?.qrcodeborder} 
        dataqrcodesize={draggableType?.qrcodesize}
        dataqrcodefgcolor={draggableType?.qrcodefgcolor}
        dataqrcodebgcolor={draggableType?.qrcodebgcolor}
        recipientNames={recipientNames}
        targetid={draggableType?.targetid} 
        onDateSelected={handleDateSelected}
        selectedDate={draggableElements?.selectedDate}
           />;
           break;
           case 'hand_praaf':
            draggableComponent = <SignImg
            datafontcolor={draggableType?.fontcolor}
            datafontfamily={draggableType?.fontfamily}
            datafontformat={draggableType?.fontformat}
            datafontsize={draggableType?.fontsize}
             datatype={draggableType?.format}
            dataparaaf={draggableType?.paraaf} 
            datasignPng={draggableType?.signpng} 
            datastamp={draggableType?.stamp} 
            dataroll={draggableType?.roll}
            dataqrcodevalue={draggableType?.qrcodevalue}
            dataqrqrcodedisplay={draggableType?.qrcodedisplay} 
            dataqrcodeborder={draggableType?.qrcodeborder} 
            dataqrcodesize={draggableType?.qrcodesize}
            dataqrcodefgcolor={draggableType?.qrcodefgcolor}
            dataqrcodebgcolor={draggableType?.qrcodebgcolor}
            recipientNames={recipientNames}
            targetid={draggableType?.targetid} 
            onDateSelected={handleDateSelected}
            selectedDate={draggableElements?.selectedDate}
             />;
           break;
           case 'stemps':
            draggableComponent = <SignImg
            datafontcolor={draggableType?.fontcolor}
            datafontfamily={draggableType?.fontfamily}
            datafontformat={draggableType?.fontformat}
            datafontsize={draggableType?.fontsize}
             datatype={draggableType?.format}
            dataparaaf={draggableType?.paraaf} 
            datasignPng={draggableType?.signpng} 
            datastamp={draggableType?.stamp} 
            dataroll={draggableType?.roll}
            dataqrcodevalue={draggableType?.qrcodevalue}
            dataqrqrcodedisplay={draggableType?.qrcodedisplay} 
            dataqrcodeborder={draggableType?.qrcodeborder} 
            dataqrcodesize={draggableType?.qrcodesize}
            dataqrcodefgcolor={draggableType?.qrcodefgcolor}
            dataqrcodebgcolor={draggableType?.qrcodebgcolor}
            recipientNames={recipientNames}
            targetid={draggableType?.targetid} 
            onDateSelected={handleDateSelected}
            selectedDate={draggableElements?.selectedDate}
             />;
           break;
      case 'calendar':
     draggableComponent = <SettingCalendar 
      datafontcolor={draggableType?.fontcolor}
       datafontfamily={draggableType?.fontfamily}
       datafontformat={draggableType?.fontformat}
       datafontsize={draggableType?.fontsize}
        datatype={draggableType?.format}
       dataparaaf={draggableType?.paraaf} 
       datasignPng={draggableType?.signpng} 
       datastamp={draggableType?.stamp} 
       dataroll={draggableType?.roll}
       dataqrcodevalue={draggableType?.qrcodevalue}
       dataqrqrcodedisplay={draggableType?.qrcodedisplay} 
       dataqrcodeborder={draggableType?.qrcodeborder} 
       dataqrcodesize={draggableType?.qrcodesize}
       dataqrcodefgcolor={draggableType?.qrcodefgcolor}
       dataqrcodebgcolor={draggableType?.qrcodebgcolor}
       recipientNames={recipientNames}
       targetid={draggableType?.targetid} 
       onDateSelected={handleDateSelected}
       selectedDate={draggableElements?.selectedDate}
        />;
        break;
        case 'qrcode':
     draggableComponent = <SettingText
      datafontcolor={draggableType?.fontcolor}
       datafontfamily={draggableType?.fontfamily}
       datafontformat={draggableType?.fontformat}
       datafontsize={draggableType?.fontsize}
        datatype={draggableType?.format}
       dataparaaf={draggableType?.paraaf} 
       datasignPng={draggableType?.signpng} 
       datastamp={draggableType?.stamp} 
       dataroll={draggableType?.roll}
       dataqrcodevalue={draggableType?.qrcodevalue}
       dataqrqrcodedisplay={draggableType?.qrcodedisplay} 
       dataqrcodeborder={draggableType?.qrcodeborder} 
       dataqrcodesize={draggableType?.qrcodesize}
       dataqrcodefgcolor={draggableType?.qrcodefgcolor}
       dataqrcodebgcolor={draggableType?.qrcodebgcolor}
       recipientNames={recipientNames}
       targetid={draggableType?.targetid} 
       onDateSelected={handleDateSelected}
       selectedDate={draggableElements?.selectedDate}
        />;
        break;
      case 'fullname':
        draggableComponent = <Fullname
       datatype={draggableNameType?.format}
       targetid={draggableNameType?.targetid}  
       onDateSelected={handleNameSelected}
       selectedDate={draggableElementName?.selectedDate}
        />;
        break;
        case 'email':
        draggableComponent = <EmailSetting
        datatype={draggableNameType?.format}
        targetid={draggableNameType?.targetid}  
        onDateSelected={handleNameSelected}
        selectedDate={draggableElementName?.selectedDate}
        />;
        break;
        case 'company':
        draggableComponent = <CompanySetting
        datatype={draggableNameType?.format}
        targetid={draggableNameType?.targetid}  
        onDateSelected={handleNameSelected}
        selectedDate={draggableElementName?.selectedDate}
        />;
        break;
        case 'title':
        draggableComponent = <PdfTitle
        datatype={draggableNameType?.format}
        targetid={draggableNameType?.targetid}  
        onDateSelected={handleNameSelected}
        selectedDate={draggableElementName?.selectedDate}
        />;
        break; 
        case 'textarea':
        draggableComponent = <SettingTextarea
         datacompany={draggableNameType?.company}
       datafontcolor={draggableNameType?.fontcolor}
       datafontfamily={draggableNameType?.fontfamily}
       datafontformat={draggableNameType?.fontformat}
       datafontsize={draggableNameType?.fontsize}
        datatype={draggableNameType?.format}
       datamails={draggableNameType?.mails}
       datanames={draggableNameType?.names} 
       datapdftile={draggableNameType?.pdftile}
       datatextarea={draggableNameType?.textarea}
       datanumbers={draggableNameType?.numbers}
       datacheckbox={draggableNameType?.checkbox}
       dataselects={draggableNameType?.selects}
       dataradios={draggableNameType?.radios}
       datapayments={draggableNameType?.payments}
       datadrawing={draggableNameType?.drawing}
       dataformula={draggableNameType?.formula}
       dataattachment={draggableNameType?.attachment}
       dataapprove={draggableNameType?.approve}
       datadecline={draggableNameType?.decline}
       targetid={draggableNameType?.targetid}    
       onDateSelected={handleNameSelected}
  
       selectedDate={draggableElementName?.selectedDate}
        />;
        break;
    case 'number':
       draggableComponent = <NumberSetting
       datafontformat={draggableNameType?.fontformat}
      targetid={draggableNameType?.targetid}    
      onDateSelected={handleNameSelected}
      selectedDate={draggableElementName?.selectedDate}
      />;
      break;
  case 'checkbox':
    draggableComponent = <CheckBoxGroup
      datatype={draggableNameType?.format}
      targetid={draggableNameType?.targetid}    
      onDateSelected={handleNameSelected}
      selectedDate={draggableElementName?.selectedDate}
      />;
    break;
    case 'radio':
    draggableComponent = <RadioBoxGroup
    datatype={draggableNameType?.format}
      targetid={draggableNameType?.targetid}    
      onDateSelected={handleNameSelected}
      selectedDate={draggableElementName?.selectedDate}
        />;
    break;
    case 'dropdown':
    draggableComponent = <DrapDown
    datatype={draggableNameType?.format}
        targetid={draggableNameType?.targetid}  
        onDateSelected={handleNameSelected}
        selectedDate={draggableElementName?.selectedDate}
      />;
      break;
  case 'payment':
    draggableComponent = <Payment
    datafontformat={draggableNameType?.fontformat}
    targetid={draggableNameType?.targetid}    
    onDateSelected={handleNameSelected}
    selectedDate={draggableElementName?.selectedDate}
    />;
    break;
  case 'drawing':
    draggableComponent = <Drawing
    datafontformat={draggableNameType?.fontformat}
    targetid={draggableNameType?.targetid}    
    onDateSelected={handleNameSelected}
    selectedDate={draggableElementName?.selectedDate}
      />;
      break;
    case 'formula':
    draggableComponent = <Formula
    datafontformat={draggableNameType?.fontformat}
    targetid={draggableNameType?.targetid}    
    onDateSelected={handleNameSelected}
    selectedDate={draggableElementName?.selectedDate}
      />;
    break;
    case 'attachment':
    draggableComponent = <Attachment
    datafontformat={draggableNameType?.fontformat}
    targetid={draggableNameType?.targetid}    
    onDateSelected={handleNameSelected}
    selectedDate={draggableElementName?.selectedDate}
    />;
      break;
  case 'approve':
  draggableComponent = <Approve
  datafontformat={draggableNameType?.fontformat}
  targetid={draggableNameType?.targetid}    
  onDateSelected={handleNameSelected}
  selectedDate={draggableElementName?.selectedDate}
  />;
  break;
  case 'decline':
    draggableComponent = <Decline
    datafontformat={draggableNameType?.fontformat}
    targetid={draggableNameType?.targetid}    
    onDateSelected={handleNameSelected}
    selectedDate={draggableElementName?.selectedDate}
  />;
    break;
    default:
  
  draggableComponent = <PdfPagination id={draggableData?.id} docNames={docNames} />;
   
    }
   
    const handleSaveAndClose = async () => {
      navigate(`/api/create-new/doc/${uuid}`);
    };
  return (
    <div>
  
      

        <Modal
                  size="xl"
                    isOpen={togModal1}
                    toggle={() => {
                      tog_toggleModal1();
                    }}
                    className="modal-fullscreen"
                  >
                      <div className="modal-header">
                      <div className="css-ml9n4n">
        <div className="css-pre3sf">
        <div className="custom-select">
        <span className="css-13dzsk6"> {t('Select Recipients')} </span>
        <select
        className="css-1jbj2f5"
         value={selectedOption}
        onChange={(e) => handleSelectOption(e.target.value)}
        >
         {recipientNames.map((name, index) => (
          
          <option key={`${name}_${index}`} className="css-1342dz3ssk6" value={name}>
            {name}
           </option>
            ))}
         </select> 
        </div>  
        </div>
        </div>
                      <button type="button" className="btn-close"
                        onClick={() => {
                          setTogModal1(false);
                        }} aria-label="Close"></button>
                    </div>
                    <Preview
          docNames={docNames}
          selectedOption={selectedOption}
          recipientNames={recipientNames}
          pdftoimage={pdftoimage}
         />
                  </Modal>

                  <Modal
    size="xl"
    isOpen={modal_fullscreen}
    toggle={() => {
      tog_fullscreen();
      navigate(-1);
    }}
    className="modal-fullscreen"
  >
     <div>
    </div>
    <div className="css-1e1ivkv">
    <div className=" css-y0keyn">
    <div className="olv-header olv-ignore-transform css-q3fgw5">
<div className="css-1vsgmeb">

<button
onClick={handleSaveAndClose}
data-dismiss="modal"
aria-label="Close"
  className="css-x3ryoo" type="button">
<span  className="css-1k6s299">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" focusable="false" >
<path d="M20 5.36 18.64 4 12 10.65 5.36 4 4 5.36 10.65 12 4 18.66 5.34 20 12 13.35 18.66 20 20 18.66 13.35 12 20 5.36z"></path>
</svg>
</span>
<span  className="css-1pdgtej">{t('Save and Close')}</span>
</button>
<p  className="css-u2y338">{t('Complete with eSignatureHub')}: {originalName}</p>
</div>
<div className="css-11jt1m5">
<div className="css-14j54of"></div>
<a  className="css-x3ryoo" rel="noopener" target="_blank">
<span  className="css-1k6s299">
<span className="css-zf56fy">
<span  className="SVGInline">
<svg className="SVGInline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm0-2A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm-1-3.5h2v2H7v-2zm0-1v-2h1a1 1 0 1 0-1-1H5a3 3 0 1 1 3 3H7z"></path>
</svg>
</span>
</span>
</span>
<span  className="css-1pdgtej">{t('Help')}</span>
</a>
<ActionDropDown 
selectedZoom={selectedZoom}
docname={docname}
permissions={permissions}
 />

<div className="css-zx8agq">
<button 
 data-bs-target="#secondmodal"
 onClick={() => {
   tog_toggleModal1();
 }}
 className="css-gusat5"
 type="button">
<span className="css-vxcmzt">{t('Preview')}</span>
</button>
</div>
<a href={`/plan-and-price/${token}`} className="olv-button olv-ignore-transform css-bfq68w" >
<span  className="css-16hz1ch">{t('View Plans')}</span>
<span className="css-1pdgtej">{t('View Plans, opens in new window')}</span>
</a>
</div>
</div>
     
    </div>
    </div>
    <div style={{ overflow: 'hidden' }}>
    <Row>
        <Col lg={12}>
        <div className="css-16cz7xa">
        <div className="css-ml9n4n">
        <div className="css-pre3sf">
        <div className="custom-select">
        <span className="css-13dzsk6">{t('Select Recipients')} </span>
        <select
        className="css-1jbj2f5"
         value={selectedOption}
        onChange={(e) => handleSelectOption(e.target.value)}
        >
         {recipientNames.map((name, index) => (
          
          <option key={`${name}_${index}`} className="css-1342dz3ssk6" value={name}>
            {name}
           </option>
            ))}
         </select> 
        </div>  
        </div>
        </div>
        <div className="css-1nsshgk">
<div className="css-64bboa">
<div className="css-1apspd7"  >
<button
 className="css-1m1df6c removeIcons"
 id="removeIcons"
 style={{background:'#9d9d9d'}}
 disabled
   >
<span className="css-zf56fy">
<span aria-hidden="true" className="SVGInline">
<svg xmlns="http://www.w3.org/2000/svg" id="Delete"  viewBox="0 0 26 26" >
<path d="M19.795 27H9.205a2.99 2.99 0 0 1-2.985-2.702L4.505 7.099A.998.998 0 0 1 5.5 6h18a1 1 0 0 1 .995 1.099L22.78 24.297A2.991 2.991 0 0 1 19.795 27zM6.604 8 8.21 24.099a.998.998 0 0 0 .995.901h10.59a.998.998 0 0 0 .995-.901L22.396 8H6.604z" fill="#ffffff" className="color000000 svgShape"></path>
<path d="M26 8H3a1 1 0 1 1 0-2h23a1 1 0 1 1 0 2zM14.5 23a1 1 0 0 1-1-1V11a1 1 0 1 1 2 0v11a1 1 0 0 1-1 1zM10.999 23a1 1 0 0 1-.995-.91l-1-11a1 1 0 0 1 .905-1.086 1.003 1.003 0 0 1 1.087.906l1 11a1 1 0 0 1-.997 1.09zM18.001 23a1 1 0 0 1-.997-1.09l1-11c.051-.55.531-.946 1.087-.906a1 1 0 0 1 .905 1.086l-1 11a1 1 0 0 1-.995.91z" fill="#ffffff" className="color000000 svgShape"></path>
<path d="M19 8h-9a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-8-2h7V4h-7v2z" fill="#ffffff" className="color000000 svgShape">
</path>
</svg>
</span>
</span>
<span className="css-guny5b">{t('Enable Field Assist')}</span>
</button>
</div>

<span>
<button className="css-10w30g4" data-qa="undo-button" disabled="">
<span className="css-zf56fy">
<span aria-hidden="true" className="SVGInline">
<svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M7 0 0 5l7 5V6h3.99A3.005 3.005 0 0 1 14 9.01v1.98c0 1.66-1.347 3.01-2.996 3.01H3v2h8.004C13.76 16 16 13.754 16 10.99V9.01C16 6.238 13.76 4 10.99 4H7V0z"></path>
</svg>
</span>
</span>
<span className="css-guny5b">{t('Undo')}</span>
</button>
</span>
<span>
<button className="css-10w30g4" data-qa="redo-button" disabled="">
<span className="css-zf56fy">
<span aria-hidden="true" className="SVGInline">
<svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="m9 0 7 5-7 5V6H5.01A3.005 3.005 0 0 0 2 9.01v1.98C2 12.65 3.347 14 4.996 14H13v2H4.996C2.24 16 0 13.754 0 10.99V9.01C0 6.238 2.24 4 5.01 4H9V0z"></path>
</svg>
</span>
</span>
<span className="css-guny5b">{t('Redo')}</span>
</button>
</span>
</div>
<div className="css-1apspd7"><span>
<button className="css-10w30g4" data-qa="copy-toolbar-button" disabled="">
<span className="css-zf56fy">
<span aria-hidden="true" className="SVGInline">
<svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M3 12V2h8V0H3C1.9 0 1 .9 1 2v10h2zm10-6.1V14H6.9v2H13c1.1 0 2-.9 2-2V5.9h-2z"></path>
  <path d="M13 4H7c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 10H7V6h6v8z"></path>
</svg>
</span>
</span>
<span className="css-guny5b">{t('Copy')}</span>
</button>
</span>
<span>
<button className="css-10w30g4" data-qa="paste-toolbar-button" disabled="">
<span className="css-zf56fy">
<span aria-hidden="true" className="SVGInline">
<svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M8.5 16H2.998A2.001 2.001 0 0 1 1 13.994V4.006C1 2.898 1.894 2 3.003 2H4.67a3.001 3.001 0 0 1 5.658 0h1.668C13.103 2 14 2.896 14 3.997V11l-1 1-4 4h-.5zm3.5-6V6H3v8h5v-4h4zM7.5 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
</svg>
</span>
</span>
<span className="css-guny5b">{t('Paste')}</span>
</button>
</span>
</div>
<div className="css-1apspd7">
<span style={{ width: "70px", display: "inline-block" }}>
<div className="css-ud5b9z">
<div className="css-k008qs">
<div className="zoom-wrapper css-zspncp">
        <button
          className={` zoom-button ${showZoomMenu ? 'active' : ''}`}
          onClick={handleZoomMenuToggle}
          aria-expanded={showZoomMenu}
        >
         {zoomOptions.find((option) => option.value === selectedZoom)?.label}
        </button>
        {showZoomMenu && (
          <ul className="zoom-menu">
            {zoomOptions.map((zoomOption) => (
              <li
                key={zoomOption.value}
                className={` zoom-menu-item ${selectedZoom === zoomOption ? 'selected' : ''}`}
                onClick={() => {
                  handleZoomClick(zoomOption.value);
                  setShowZoomMenu(false);
                }}
              >
              {zoomOption.label}
              </li>
            ))}
          </ul>
        )}
      </div>

<span className="css-y93q9o">
<span className="css-lzaifp">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
<path d="M4 5h8l-4 6z"></path>
</svg>
</span>
</span>
</div>
</div>
</span>
</div>
<div className="css-1apspd7">
<span>
<button className="css-1ha7cte" data-qa="add-comment">
<span className="css-zf56fy">
<span aria-hidden="true" className="SVGInline">
<svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
  <path d="M14 2h2v2h-2v2h-2V4h-2V2h2V0h2v2zm2 3.004v4.987A2.003 2.003 0 0 1 14.006 12H8l-6 4v-4h-.006C.894 12 0 11.1 0 9.991V2.01C0 .902.893 0 1.994 0h9.009H8v2.002c-2.998.001-5.994.003-5.994.007 0 0 .001 7.991-.006 7.991 0 0 11.994-.002 11.994-.009V8H16V5.004z"></path>
</svg>
</span>
</span>
<span className="css-guny5b">{t('Add Comment')}</span>
</button>
</span>
</div>
</div>
        </div>  
        </Col>
    </Row>
    <Row>
         <div className="css-1k8emhg col-12">
         <div className="css-1f26bup col-2"
          data-qr="deleteZone"
          >
         <div className="css-1t11ffy">
            <div className="css-1twamlw"></div>
            <div className="css-1s9vx5s ">
            <div className="css-1b1eheb " >
                <div
                 className="css-8mm5ho"
                 
                 >
                <div className="css-fon8ac">
    <h5 className="olv-heading olv-ignore-transform css-dr8fc" data-qa="search-results-tagger-tag">
        <span data-qa="search-results-tagger" className="css-rdk5cf">{t('Standard Fields')}</span>
    </h5>
</div> 
   <div className="css-19u637c">
      <div className="css-21tq8i"> 
   <DraggableList 
   pdfContainerRef={pdfContainerRef} 
    selectedOption={selectedOption}
     setDraggableType={handleDraggableType} 
     setDraggableNameType={handleDraggableNameType}
     draggableElements={draggableElements} 
     draggableElementName={draggableElementName} 
     onDragStart={handleDragStart} onDragEnd={handleDragEnd}
     selectedZoom={selectedZoom}
     permissions={permissions}
      />
      </div>
   </div>
    </div>
    </div>
    </div>
         </div>
         </div>
 
         <div className="css-quz66i col-8" id='PdfViewer'>
         <div className="css-1sdoc">
         <PdfViewer
          zoom={selectedZoom}
          docNames={docNames} 
           setIsAllowed={setIsAllowed}
           />
           </div>
     </div>
         <div className="css-1szwono col-2" >

         {draggableComponent}
        {/* <SettingText docNames={docNames} /> */}
        
         </div>
         </div>
    
</Row>
    </div>
    <PostToDataBackend
       selectedZoom={selectedZoom}
       permissions={permissions}
        />
  </Modal>
    </div>
    
  );
};
export default withTranslation()(withRouter(ModalComponent));

