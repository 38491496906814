import {
  GET_COLLABORATOR,
  GET_COLLABORATOR_SUCCESS,
  GET_COLLABORATOR_FAIL,
  POST_COLLABORATOR,
  POST_COLLABORATOR_SUCCESS,
  POST_COLLABORATOR_FAIL,
  PUT_COLLABORATOR,
  PUT_COLLABORATOR_SUCCESS,
  PUT_COLLABORATOR_FAIL,
  POST_CONTRACT,
  POST_CONTRACT_SUCCESS,
  POST_CONTRACT_FAIL,
  GET_XLSDATA,
  GET_XLSDATA_SUCCESS,
  GET_XLSDATA_FAIL,
  POST_DEP,
  POST_DEP_SUCCESS,
  POST_DEP_FAIL,
  PUT_DEP,
  PUT_DEP_SUCCESS,
  PUT_DEP_FAIL,
  DELETE_DEP,
  DELETE_DEP_SUCCESS,
  DELETE_DEP_FAIL,
  POST_TEAM,
  POST_TEAM_SUCCESS,
  POST_TEAM_FAIL,
  PUT_TEAM,
  PUT_TEAM_SUCCESS,
  PUT_TEAM_FAIL,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  POST_CONTRACT_TYPE,
  POST_CONTRACT_TYPE_SUCCESS,
  POST_CONTRACT_TYPE_FAIL,
  PUT_CONTRACT_TYPE,
  PUT_CONTRACT_TYPE_SUCCESS,
  PUT_CONTRACT_TYPE_FAIL,
  DELETE_CONTRACT_TYPE,
  DELETE_CONTRACT_TYPE_SUCCESS,
  DELETE_CONTRACT_TYPE_FAIL,
  POST_LINK,
  POST_LINK_SUCCESS,
  POST_LINK_FAIL,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAIL,
  PUT_COLLABORATOR_DOC,
  PUT_COLLABORATOR_DOC_SUCCESS,
  PUT_COLLABORATOR_DOC_FAIL,
  POST_DOCUMENT,
  POST_DOCUMENT_SUCCESS,
  POST_DOCUMENT_FAIL,
  PUT_COLLABORATOR_PROFILE,
  PUT_COLLABORATOR_PROFILE_SUCCESS,
  PUT_COLLABORATOR_PROFILE_FAIL,
  } from "./actionTypes";
  


  export const getcollaborator = client => ({
    type: GET_COLLABORATOR,
    payload: client,
  })
  export const getcollaboratorSuccess = client => ({
    type: GET_COLLABORATOR_SUCCESS,
    payload: client,
  })
  
  export const getcollaboratorFail = error => ({
    type: GET_COLLABORATOR_FAIL,
    payload: error,
  })

  export const postCollaborator = userdata => ({
    type: POST_COLLABORATOR,
    payload: userdata,
  })
  
  export const postCollaboratorSuccess = userdata => ({
    type: POST_COLLABORATOR_SUCCESS,
    payload: userdata,
  })
  
  export const postCollaboratorFail = error => ({
    type: POST_COLLABORATOR_FAIL,
    payload: error,
  })

  export const putCollaborator = putuser => ({
    type: PUT_COLLABORATOR,
    payload: putuser,
  })
  
  export const putCollaboratorSuccess = putuser => ({
    type: PUT_COLLABORATOR_SUCCESS,
    payload: putuser,
  })
  
  export const putCollaboratorFail = error => ({
    type: PUT_COLLABORATOR_FAIL,
    payload: error,
  })

  export const getContract = contract => ({
    type: GET_CONTRACT,
    payload: contract,
  })
  export const getContractSuccess = contract => ({
    type: GET_CONTRACT_SUCCESS,
    payload: contract,
  })
  
  export const getContractFail = error => ({
    type: GET_CONTRACT_FAIL,
    payload: error,
  })

  export const postContract = contractdata => ({
    type: POST_CONTRACT,
    payload: contractdata,
  })
  export const postContractSuccess = contractdata => ({
    type: POST_CONTRACT_SUCCESS,
    payload: contractdata,
  })
  
  export const postContractFail = error => ({
    type: POST_CONTRACT_FAIL,
    payload: error,
  })



  export const getXlsdata = xlsid => ({
    type: GET_XLSDATA,
    payload: xlsid,
  })
  export const getXlsdataSuccess = xlsid => ({
    type: GET_XLSDATA_SUCCESS,
    payload: xlsid,
  })
  
  export const getXlsdataFail = error => ({
    type: GET_XLSDATA_FAIL,
    payload: error,
  })


  export const postDep = postDeps => ({
    type: POST_DEP,
    payload: postDeps,
  })
  export const postDepSuccess = postDeps => ({
    type: POST_DEP_SUCCESS,
    payload: postDeps,
  })
  
  export const postDepFail = error => ({
    type: POST_DEP_FAIL,
    payload: error,
  })


  export const putDep = putDeps => ({
    type: PUT_DEP,
    payload: putDeps,
  })
  export const putDepSuccess = putDeps => ({
    type: PUT_DEP_SUCCESS,
    payload: putDeps,
  })
  
  export const putDepFail = error => ({
    type: PUT_DEP_FAIL,
    payload: error,
  })


  export const deleteDep = deleteDeps => ({
    type: DELETE_DEP,
    payload: deleteDeps,
  })
  export const deleteDepSuccess = deleteDeps => ({
    type: DELETE_DEP_SUCCESS,
    payload: deleteDeps,
  })
  
  export const deleteDepFail = error => ({
    type: DELETE_DEP_FAIL,
    payload: error,
  })



  export const postTeam = postTeams => ({
    type: POST_TEAM,
    payload: postTeams,
  })
  export const postTeamSuccess = postTeams => ({
    type: POST_TEAM_SUCCESS,
    payload: postTeams,
  })
  
  export const postTeamFail = error => ({
    type: POST_TEAM_FAIL,
    payload: error,
  })


  export const putTeam = putTeams => ({
    type: PUT_TEAM,
    payload: putTeams,
  })
  export const putTeamSuccess = putTeams => ({
    type: PUT_TEAM_SUCCESS,
    payload: putTeams,
  })
  
  export const putTeamFail = error => ({
    type: PUT_TEAM_FAIL,
    payload: error,
  })


  export const deleteTeam = deleteTeams => ({
    type: DELETE_TEAM,
    payload: deleteTeams,
  })
  export const deleteTeamSuccess = deleteTeams => ({
    type: DELETE_TEAM_SUCCESS,
    payload: deleteTeams,
  })
  
  export const deleteTeamFail = error => ({
    type: DELETE_TEAM_FAIL,
    payload: error,
  })


  export const postContractType = postContractTypes => ({
    type: POST_CONTRACT_TYPE,
    payload: postContractTypes,
  })
  export const postContractTypeSuccess = postContractTypes => ({
    type: POST_CONTRACT_TYPE_SUCCESS,
    payload: postContractTypes,
  })
  
  export const postContractTypeFail = error => ({
    type: POST_CONTRACT_TYPE_FAIL,
    payload: error,
  })


  export const putContractType = putContractTypes => ({
    type: PUT_CONTRACT_TYPE,
    payload: putContractTypes,
  })
  export const putContractTypeSuccess = putContractTypes => ({
    type: PUT_CONTRACT_TYPE_SUCCESS,
    payload: putContractTypes,
  })
  
  export const putContractTypeFail = error => ({
    type: PUT_CONTRACT_TYPE_FAIL,
    payload: error,
  })


  export const deleteContactType = deleteContactTypes => ({
    type: DELETE_CONTRACT_TYPE,
    payload: deleteContactTypes,
  })
  export const deleteContactTypeSuccess = deleteContactTypes => ({
    type: DELETE_CONTRACT_TYPE_SUCCESS,
    payload: deleteContactTypes,
  })
  
  export const deleteContactTypeFail = error => ({
    type: DELETE_CONTRACT_TYPE_FAIL,
    payload: error,
  })


  export const postLinks = link => ({
    type: POST_LINK,
    payload: link,
  })
  export const postLinkstSuccess = link => ({
    type: POST_LINK_SUCCESS,
    payload: link,
  })
  
  export const postLinksFail = error => ({
    type: POST_LINK_FAIL,
    payload: error,
  })


  export const pustProof = proof => ({
    type: PUT_COLLABORATOR_DOC,
    payload: proof,
  })
  export const pustProofSuccess = proof => ({
    type: PUT_COLLABORATOR_DOC_SUCCESS,
    payload: proof,
  })
  
  export const pustProofFail = error => ({
    type: PUT_COLLABORATOR_DOC_FAIL,
    payload: error,
  })


  export const postDocument = doc => ({
    type: POST_DOCUMENT,
    payload: doc,
  })
  export const postDocumentSuccess = doc => ({
    type: POST_DOCUMENT_SUCCESS,
    payload: doc,
  })
  
  export const postDocumentFail = error => ({
    type: POST_DOCUMENT_FAIL,
    payload: error,
  })



  export const putProfile = profile => ({
    type: PUT_COLLABORATOR_PROFILE,
    payload: profile,
  })
  export const putProfileSuccess = profile => ({
    type: PUT_COLLABORATOR_PROFILE_SUCCESS,
    payload: profile,
  })
  
  export const putProfileFail = error => ({
    type: PUT_COLLABORATOR_PROFILE_FAIL,
    payload: error,
  })