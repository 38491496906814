import {
  GET_SIGNATURESIMG,
  GET_SIGNATURESIMG_SUCCESS,
  GET_SIGNATURESIMG_FAIL,
  GET_DOCUMENTEN,
  GET_DOCUMENTEN_SUCCESS,
  GET_DOCUMENTEN_FAIL,
  POST_SIGN_DATA,
  POST_SIGN_DATA_SUCCESS,
  POST_SIGN_DATA_FAIL,
  GET_SIGN_SUCCESS,
  GET_SIGN_FAIL,

  } from "./actionTypes";
  const INIT_STATE = {
    signatureImgData: [],
    docname: {},
    jsonpostdata: {},
    updatedata:[],
    
    loading: false,
    error: {},
  };
  const getSigantureImgData = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_SIGNATURESIMG_SUCCESS:
        return {
          ...state,
          signatureImgData: action.payload,
        };
  
      case GET_SIGNATURESIMG_FAIL:
        return {
          ...state,
          error: action.payload,
        };
     case GET_DOCUMENTEN_SUCCESS:
      return {
        ...state,
        docname: action.payload,
      };

    case GET_DOCUMENTEN_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      case POST_SIGN_DATA:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case POST_SIGN_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          jsonpostdata: action.payload,
        };
  
      case POST_SIGN_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case GET_SIGN_SUCCESS:
          return {
            ...state,
            updatedata: action.payload,
            loading: false,
          };
    
        case GET_SIGN_FAIL:
          return {
            ...state,
            error: action.payload,
            loading: false,
          };
      default:
        return state;
    }
  };
  
  export default getSigantureImgData;