import React from "react";
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';



export default function SpecifiedDomainRadarChart({data}) {
 
  const predefinedColors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#00c49f", "#0088FE"];
  const radarData = data
  .filter(item => item.name !== undefined)
  .map((item, index) => ({
    subject: item.folder, // Assuming 'folder' is used as 'subject' for PolarAngleAxis
    A: [item.totalpost, item.totalcompelation, item.totalpanding], // Assuming this represents radar data
    name: item.name, // Name for Legend and Radar component
    color: predefinedColors[index % predefinedColors.length] // Assign color from predefinedColors based on index
  }));
  return (
    <div style={{ width: '100%', height: 380 }}>
      <ResponsiveContainer>
        <RadarChart cx={300} cy={150} outerRadius={120} data={radarData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={30} domain={[0, 10]} />
          {data.map((entry, index) => (
          <Radar key={index} name={entry.name}  dataKey="A" data={entry.A} stroke={predefinedColors[index]}  fill={predefinedColors[index]} fillOpacity={0.6} />
        ))}
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}
