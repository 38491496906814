// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_DOC,
  ADD_RECIEPIEN_DOC,
  GET_TEMPLATE,
  DELETE_TEPLATE,
  GET_TEMPLATE_DATA
} from "./actionTypes";
import {
  createNewDocSuccess,
  createNewDocFail,
  postNewReciepienSuccess,
  postNewReciepienFail,
  getTemplateSuccess,
  getTemplateFail,
  deleteTemplateSuccess,
  deleteTemplateFail,
  getTemplateDataSuccess,
  getTemplateDataFail
} from "./actions";
import {
  postNewDoc,
  postReciepien,
  getTemplate,
  deleteTemplate,
  getTemplateData,
} from "helpers/fakebackend_helper";

function* createNewDocSaga({ payload: formdata, file  }) {
  try {
    const response = yield call(postNewDoc, formdata, file);
    yield put(createNewDocSuccess(response));
  } catch (error) {
    yield put(createNewDocFail(error));
  }
}

function* postNewReciepiens({ payload: formdata }) {  // Corrected function name
  try {
    const response = yield call(postReciepien, formdata);
    yield put(postNewReciepienSuccess(response));
  } catch (error) {
    yield put(postNewReciepienFail(error));
  }
}
function* onGetTemplate({ payload: template }) {
  try {
    const response = yield call(getTemplate, template)
    yield put(getTemplateSuccess(response));
  } catch (error) {
    yield put(getTemplateFail(error))
  }
}
function* onGetTemplateData({ payload: templatedata }) {
  try {
    const response = yield call(getTemplateData, templatedata)
    yield put(getTemplateDataSuccess(response));
  } catch (error) {
    yield put(getTemplateDataFail(error))
  }
}
function* onDeleteTemplate({ payload: deletetemp }) {
  try {
   
    const response = yield call(deleteTemplate, deletetemp)
    yield put(deleteTemplateSuccess(response));
  } catch (error) {
    yield put(deleteTemplateFail(error))
  }
}
function* documentsSaga() {
  yield takeEvery(ADD_NEW_DOC, createNewDocSaga);
  yield takeEvery(ADD_RECIEPIEN_DOC, postNewReciepiens);
  yield takeEvery(GET_TEMPLATE, onGetTemplate);
  yield takeEvery(GET_TEMPLATE_DATA, onGetTemplateData);
  yield takeEvery(DELETE_TEPLATE, onDeleteTemplate);
}

export default documentsSaga;
