import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Dropdown, DropdownToggle, DropdownMenu 
  } from "reactstrap";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import TawkToWidget from "components/LiveChat";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import { Link ,useLocation } from "react-router-dom";
import {
    helpCenterData,
  } from "../../common/data";
  import SearchHelp from './searchHelp';
const Helpdesk = (props) => {
    const { t,i18n  } = props;
    const currentLanguage = i18n.language
    const location = useLocation();
    useEffect(() => {

      
        const headerColor = document.getElementById('header_color');
        
        // Check if the element exists before accessing it
        if (headerColor) {
          headerColor.style.backgroundColor = '#f0f8ff';
        }
      }, [])

      useEffect(() => {
        // Function to update meta tags
        const updateMetaTags = () => {
          document.title = t(`Help Center | Support and Assistance for Workforce Management | Shiftspec.com`);
      
          const metaElement = document.querySelector('meta[name="description"]');
          if (metaElement) {
              metaElement.setAttribute('content', 'Get the help you need with ShiftSpec’s workforce management solutions. Access guides, tutorials, FAQs, and expert assistance for employee scheduling, time tracking, digital signatures, and more.');
          } else {
              addOrUpdateMeta('description', 'ShiftSpec Help Center provides comprehensive support for employee scheduling, time tracking, and digital signatures. Explore guides, FAQs, and resources to make the most of our workforce management platform.');
          }
      
          addOrUpdateMeta('keywords', 'ShiftSpec Help Center, Workforce Management Support, Employee Scheduling Help, Time Tracking Assistance, Digital Signatures Help, Troubleshooting Workforce Software, ShiftSpec Guides, Employee Management Resources, ShiftSpec Tutorials, Support for HR Solutions');
      
          function addOrUpdateMeta(name, content) {
              let metaTag = document.querySelector(`meta[name="${name}"]`);
              if (metaTag) {
                  metaTag.setAttribute('content', content);
              } else {
                  metaTag = document.createElement('meta');
                  metaTag.setAttribute('name', name);
                  metaTag.setAttribute('content', content);
                  document.head.appendChild(metaTag);
              }
          }
      
          // Meta tags for the Help Center page
          const metaTags = [
              { name: 'description', content: 'ShiftSpec Help Center provides comprehensive support for employee scheduling, time tracking, and digital signatures. Explore guides, FAQs, and resources to make the most of our workforce management platform.' },
              { name: 'keywords', content: 'ShiftSpec Help Center, Workforce Management Support, Employee Scheduling Help, Time Tracking Assistance, Digital Signatures Help, Troubleshooting Workforce Software, ShiftSpec Guides, Employee Management Resources, ShiftSpec Tutorials, Support for HR Solutions' },
              { name: 'author', content: 'ShiftSpec Support Team' }
          ];
      
          // Apply each meta tag
          metaTags.forEach(tag => {
              addOrUpdateMeta(tag.name, tag.content);
          });
      
          // Helper function for Open Graph and Twitter meta tags
          const addOrUpdateMetaTag = (property, content) => {
              let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
              if (tag) {
                  tag.setAttribute('content', content);
              } else {
                  tag = document.createElement('meta');
                  tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
                  tag.setAttribute('content', content);
                  document.head.appendChild(tag);
              }
          };
      
          // Open Graph Meta Tags for "Help Center" page
          addOrUpdateMetaTag('og:title', 'Help Center | Support and Assistance for Workforce Management | Shiftspec.com');
          addOrUpdateMetaTag('og:description', 'Get the help you need with ShiftSpec’s workforce management solutions. Access guides, tutorials, FAQs, and expert assistance for employee scheduling, time tracking, digital signatures, and more.');
          addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
          addOrUpdateMetaTag('og:type', 'website');
      
          // Twitter Meta Tags for "Help Center" page
          addOrUpdateMetaTag('twitter:title', 'Help Center | Support and Assistance for Workforce Management | Shiftspec.com');
          addOrUpdateMetaTag('twitter:description', 'Get the help you need with ShiftSpec’s workforce management solutions. Access guides, tutorials, FAQs, and expert assistance for employee scheduling, time tracking, digital signatures, and more.');
          addOrUpdateMetaTag('twitter:card', 'summary_large_image');
      };
      
    
        // Call the function to update meta tags
        updateMetaTags();
    
      }, [ location.pathname, t]);
     
  return (
    <React.Fragment>
  <header style={styles.header}>
      <nav style={styles.nav}>
        <ul style={styles.menu}>
          <li>
            <Link to="/" style={styles.link}>
            {t('Go to shiftspec.com')} 
            </Link>
          </li>
          <li>
            <Link to="/help-center" style={styles.link}>
            {t('Help Center')} 
            </Link>
          </li>
          <li style={styles.languageSelector}>
          <LanguageDropdown /> 
          </li>
        </ul>
      </nav>
    </header>
  
    <section style={{padding:'50px', background: '#fafff0' }}>
      <Row>
        <Col sm={12} className="d-flex align-items-center justify-content-center">
        <div className="lg:col-span-7 xl:pr-1 text-left">
        <h1 className="font-bold text-dark text-[30px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold  mb-1.5 lg:mt-3 md:mb-6">
        {t('What can we help you with?')} 
        </h1>
        <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6">
        {t('We are happy to provide support')} 
        </p>
      </div>
         
        </Col>
        <Col sm={12} className="d-flex align-items-center justify-content-center">

        <SearchHelp props={props} />
        </Col>
      </Row>
    </section>


    <main className=" bg-white">
  <Row>
    <Col sm={12} className="w-full relative" style={styles.contentContainer}>
   
     <div className='kb-index kb-index--minimal'>
     {helpCenterData.map((menuItem, index) => {
              if (menuItem && menuItem.mainMenu && menuItem.mainMenu.lang === currentLanguage) {
                return (
                  <a 
                    key={menuItem.mainMenu.id} // Add a key for React rendering optimization
                    href={`/help-center/${menuItem.mainMenu.url}`} 
                    className="kb-index__category kb-index__category--3col"
                  >
                    <div className="kb-index__category-icon" style={{ color: '#7c98b6' }}>
                    <span id="hs_cos_wrapper_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon" >
                    <i class={`${menuItem.mainMenu.icon}`}></i>
          </span>
                    </div>
                    <h3>{menuItem.mainMenu.name}</h3> {/* Render main menu name */}
                    <h6 className="kb-index__category-description">
                      {menuItem.mainMenu.description} {/* Render main menu description */}
                    </h6>
                  </a>
                );
              }
              return null; // If the item is empty or null, return null
            })}
     
  
     </div>
    </Col>
  </Row>
</main>


    </React.Fragment>
  );
};

const styles = {
    header: {
      background: '#f1f8fa',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      height: '70px', // Set a fixed height for the header
    },
    nav: {
      display: 'flex',
      justifyContent: 'center', // Center content horizontally within the nav
      alignItems: 'center', // Align vertically
      width: '100%', // Ensure nav takes full width
      maxWidth: '1200px', // Max width for larger screens
    },
    menu: {
      display: 'flex',
      justifyContent: 'center', // Center the menu items
      alignItems: 'center', // Align items vertically
      listStyleType: 'none',
      padding: 0,
      margin: 0,
    },
    link: {
      textDecoration: 'none',
      color: '#333',
      marginRight: '20px',
      fontSize: '16px',
    },
    languageSelector: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center', // Ensure the dropdown is vertically centered
    },
    select: {
      padding: '5px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '600px', // Adjust the width of the search bar as per your need
      },
      input: {
        flex: 1,
        padding: '10px 15px',
        fontSize: '16px',
        borderRadius: '4px 0 0 4px',
        border: '1px solid #ccc',
        outline: 'none',
      },
      button: {
        padding: '13px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '0 4px 4px 0',
        cursor: 'pointer',
      },
      contentContainer: {
        margin: '0 auto !important',
        float: 'none !important',
      },
  };
  
Helpdesk.propTypes = {
    history: PropTypes.object,
    t: PropTypes.func.isRequired,
  };
export default withTranslation()(withRouter(Helpdesk));