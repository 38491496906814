import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams,useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import  PlanAndBell from './PlanAndBelling';
import  Profile from './profile';
import  DatatableTables from './PaymentForm';
import  Absence from './Absence';
import  Absencepolicy from './Absencepolicy';
import  UserRule from './UserRule';
import  Branding from './Branding';
import  Surcharger from './Surcharger';
import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
import PaymentFormUpdate  from "components/Payment/PaymentFormUpdate";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getMailsLists as onGetMailsLists,
  getSelectedMails as onGetSelectedMails,
  accountProfile as onGetAccountProfile,


} from "store/actions";

import { withTranslation } from "react-i18next";
const EmailInbox = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  const { template_content, editor,plan_name } = permissions;
  const isPathAllowed = template_content.includes('setting');
  const isInvoiceAllowed = template_content.includes('setting');
  const isProfileAllowed = template_content.includes('setting');
  const isBrandingAllowed = template_content.includes('setting');
  const isPaymentAllowed = template_content.includes('setting');
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [customerId, setcustomerId] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = t("Account Settings - Manage Your Profile and Preferences | eSignatureHub.com");
  }, [t]);
  const {
    mailslists,
    selectedmails,
    profiles,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    selectedmails: state.mails.selectedmails,
    profiles: state.mails.profiles,
    error: state.mails.error,

  }));
  //meta title

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetAccountProfile(account_id));
  }, [dispatch]);

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      const profile = profiles[0];
    
    }
  }, [profiles]);

  
  const [activeTab, setActiveTab] = useState('/plan-billing');
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get('tab');
    if (tabParam) {
      setActiveTab(decodeURIComponent(tabParam));
    }
  }, [location.search]);
useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('tab', encodeURIComponent(activeTab));
    window.history.replaceState(null, '', url.toString());
  }, [activeTab]);
  const handleTabClick = (path) => {
    setActiveTab(path);
  };
  useEffect(() => {
    // Simulate a loading period
    const timer = setTimeout(() => {
      setLoading(false);
      // Simulate checking payment allowance (you can replace this with your actual logic)
    }, 2000);
  
    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);
  const renderContent = () => {
    switch (activeTab) {
      case '/plan-billing':
        return <PlanAndBell permissions={permissions} />;
      case '/account-profile':
        return isProfileAllowed ? <Profile permissions={permissions}  /> : <NotAllowed />;
      case '/invoices':
        return isInvoiceAllowed ? <DatatableTables permissions={permissions}  /> : <NotAllowed />;
        case '/absence':
          return plan_name !== 'starter' ? <Absence permissions={permissions}  /> : <NotAllowedPlane />;
          case '/absencepolicy':
            return plan_name !== 'starter' ? <Absencepolicy permissions={permissions}  /> : <NotAllowedPlane />;
      case '/payment-method':
        
      return isPaymentAllowed ? (
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={7} style={{ margin: '15px' }}>
              <PaymentFormUpdate permissions={permissions}  />
            </Col>
          </Row>
        ) : <NotAllowed />;
      case '/update':
        return plan_name !== 'starter' ? <Branding permissions={permissions} /> : <NotAllowedPlane />;
      case '/surcharger':
        return plan_name !== 'starter' ? <Surcharger permissions={permissions} /> : <NotAllowedPlane />;
      case '/user-rule':
        return isPathAllowed ? <UserRule permissions={permissions} /> : <NotAllowed />;
      default:
        return null;
    }
  };
  const NotAllowed = () => (
    <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    <i className="bx bx-lock-alt  text-primary display-3" />
                  </h1>
                  <h4 className="text-uppercase">{t('Access denied')}</h4>
                  <p>
                    {t('You do not have permissions to access this page or component.')}
                  </p>
                  <p>
                 {t('Contact your an administrator get permisions or goto the home pageand browse other pages')}
                  </p>
                  <div className="mt-5 text-center">
                    <Link
                      className="btn btn-primary waves-effect waves-light "
                      to="/home"
                    >
                      {t('Back To Home')}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
  );

  const NotAllowedPlane = () => (
    <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    <i className="bx bx-lock-alt  text-primary display-3" />
                  </h1>
                  <h4 className="text-uppercase"> {t('Upgrade your plan')}</h4>
                  <p>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  </p>
                  <p>
                 {t('Contact your an administrator get permisions or goto the home pageand browse other pages')}
                  </p>
                  <div className="mt-5 text-center">
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                  </div>
                </div>
              </Col>
            </Row>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t('Settings')} breadcrumbItemLink="/home" breadcrumbItem={t('Back To Home')} />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                <div className="mail-list mt-4">
                    <h6>{t('ACCOUNT')}</h6>
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                       className={classnames({ active: activeTab === '/plan-billing' })}
                       onClick={() => handleTabClick('/plan-billing')}
                      >
                       {t('Plan and Billing')} 
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                    className={classnames({ active: activeTab === '/account-profile' })}
                    onClick={() => handleTabClick('/account-profile')}
                      >
                      {t('Account Profile')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '/payment-method' })}
                        onClick={() => handleTabClick('/payment-method')}
                      >
                       {t('Payment Method')} 
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                    className={classnames({ active: activeTab === '/invoices' })}
                    onClick={() => handleTabClick('/invoices')}
                      >
                        {t('Invoices')} 
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                    className={classnames({ active: activeTab === '/absencepolicy' })}
                    onClick={() => handleTabClick('/absencepolicy')}
                      >
                        {t('Absence Policy')} 
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                    className={classnames({ active: activeTab === '/absence' })}
                    onClick={() => handleTabClick('/absence')}
                      >
                        {t('Absence Type')} 
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                         className={classnames({ active: activeTab === '/user-rule' })}
                         onClick={() => handleTabClick('/user-rule')}
                      >
                        {t('Permission')} 
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                         className={classnames({ active: activeTab === '/surcharger' })}
                         onClick={() => handleTabClick('/surcharger')}
                      >
                        {t('Surcharger')} 
                      </NavLink>
                    </NavItem>
                  </Nav>
                  
                  <h6>{t('Branding')}</h6>
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                         className={classnames({ active: activeTab === '/update' })}
                         onClick={() => handleTabClick('/update')}
                      >
                       {t('Update')} 
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

               
         
              </Card>

             
              <div className="email-rightbar mb-3">
              <Card>
                  {renderContent()}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(withRouter(EmailInbox));
