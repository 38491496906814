import {
    GET_SIGNATURES_SUCCESS,
    GET_SIGNATURES_FAIL,
    ADD_SIGNATURES_SUCCESS,
    ADD_SIGNATURES_FAIL,
    ADD_NEW_HANDWRITING,
    ADD_HANDWRITING_SUCCESS,
    ADD_HANDWRITING_FAIL,
    ADD_NEW_STEPM,
    ADD_STEPM_SUCCESS,
    ADD_STEPM_FAIL,
    GET_SETTING,
    GET_SETTING_SUCCESS,
    GET_SETTING_FAIL,
    GET_HELPCENTER,
    GET_HELPCENTER_SUCCESS,
    GET_HELPCENTER_FAIL,
    GET_BLOGDATA,
    GET_BLOGDATA_SUCCESS,
    GET_BLOGDATA_FAIL,
    GET_BLOGCATDATA,
    GET_BLOGCATDATA_SUCCESS,
    GET_BLOGCATDATA_FAIL,
  } from "./actionTypes";
  const INIT_STATE = {
    signatureData: [],
    handwritinfData: [],
    stempData: [],
    postSignature: {},
    postHandwritng: {},
    postStemps: {},
    setData: null,
    helpCenterData: null,
    blogData: null,
    blogCatData: null,
    error: {},
  };
  const getSigantureData = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_SETTING:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_SETTING_SUCCESS:
    return {
      ...state,
      loading: false,
      setData: action.payload,
      error: null,
     
    };
    case GET_SETTING_FAIL:
      return {
        ...state,
        loading: false,
        setData: null,
        error: action.payload,
      };

      case GET_HELPCENTER:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_HELPCENTER_SUCCESS:
    return {
      ...state,
      loading: false,
      helpCenterData: action.payload,
      error: null,
     
    };
    case GET_HELPCENTER_FAIL:
      return {
        ...state,
        loading: false,
        helpCenterData: null,
        error: action.payload,
      };


      case GET_BLOGDATA:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_BLOGDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      blogData: action.payload,
      error: null,
     
    };
    case GET_BLOGDATA_FAIL:
      return {
        ...state,
        loading: false,
        blogData: null,
        error: action.payload,
      };


      case GET_BLOGCATDATA:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_BLOGCATDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      blogCatData: action.payload,
      error: null,
     
    };
    case GET_BLOGCATDATA_FAIL:
      return {
        ...state,
        loading: false,
        blogCatData: null,
        error: action.payload,
      };
      case GET_SIGNATURES_SUCCESS:
        return {
          ...state,
          signatureData: action.payload,
        };
  
      case GET_SIGNATURES_FAIL:
        return {
          ...state,
          error: action.payload,
        };
     case ADD_SIGNATURES_SUCCESS:
      return {
        ...state,
        postSignature: [...state.signatureData, action.payload],
      };

    case ADD_SIGNATURES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      case ADD_HANDWRITING_SUCCESS:
        return {
          ...state,
          postHandwritng: [...state.handwritinfData, action.payload],
        };
  
      case ADD_HANDWRITING_FAIL:
        return {
          ...state,
          error: action.payload,
        };
        case ADD_STEPM_SUCCESS:
        return {
          ...state,
          postStemps: [...state.stempData, action.payload],
        };
  
      case ADD_STEPM_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getSigantureData;