import React, { useRef, useState, useEffect, } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { withTranslation } from "react-i18next";
export function SignaturePad(props) {
  const { t, onSignatureData } = props;
  const [signatureData, setSignatureData] = useState(null);
  const sigCanvas = React.useRef(null);
  const canvasRef = useRef(null);
  const clearCanvas = () => {
    sigCanvas.current.clear();
    handleSignatureChange();
  };
  const handleSignatureChange = () => {
    const canvas = sigCanvas.current.getCanvas();
    const newCanvas = document.createElement('canvas');
    const newCtx = newCanvas.getContext('2d');
    const boundingBox = getBoundingBox(canvas,newCtx)
    const signatureDataURL = sigCanvas.current.toDataURL();
    
    newCanvas.width = boundingBox.width;
    newCanvas.height = boundingBox.height;
    const centerX = newCanvas.width / 2;
  const centerY = newCanvas.height / 2;
  const sourceX = boundingBox.x;
  const sourceY = boundingBox.y;
  const sourceWidth = boundingBox.width;
  const sourceHeight = boundingBox.height;

  // Calculate destination coordinates to draw the cropped signature at the center
  const destX = centerX - sourceWidth / 2;
  const destY = centerY - sourceHeight / 2;
  const destWidth = sourceWidth;
  const destHeight = sourceHeight;
  newCtx.drawImage(canvas, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);

    const newSignatureDataURL = newCanvas.toDataURL();
    setSignatureData(newSignatureDataURL);
    onSignatureData(newSignatureDataURL);
   
  };

  const getBoundingBox = (canvas) => {
    const ctx = canvas.getContext('2d');
    const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    let minX = canvas.width;
    let minY = canvas.height;
    let maxX = 0;
    let maxY = 0;
  
    for (let x = 0; x < canvas.width; x++) {
      for (let y = 0; y < canvas.height; y++) {
        const index = (y * canvas.width + x) * 4;
        const alpha = data[index + 3];
  
        if (alpha > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }
  
    const width = maxX - minX + 1;
    const height = maxY - minY + 1;
  
    return {
      x: minX,
      y: minY,
      width,
      height,
    };
  };
  
  
 
  return (
    
    <div>
   
      
   <div className="signature-panel-clear"> 
        <span className="clear-canvas"><a role="button" onClick={clearCanvas} id="clear_signature" href="#">Clear</a></span>
    </div>
    <div className="signature-drawing-panel">
    <SignatureCanvas
        ref={sigCanvas}
        canvasProps={{ width: 400, height: 230, className: 'signature-pad' }}
        onEnd={handleSignatureChange}
      />
    </div>
    </div>
    
    
  );
}
export function SignatureParaadPad(props) {
  const { t,onSignatureData } = props;
  const [signatureData, setSignatureData] = useState(null);
  const sigCanvas = React.useRef(null);

  const clearCanvasParaaf = () => {
    sigCanvas.current.clear();
    handleSignatureParaafChange();
  };

  const handleSignatureParaafChange = () => {
    const canvas = sigCanvas.current.getCanvas();
    const newCanvas = document.createElement('canvas');
    const newCtx = newCanvas.getContext('2d');
    const boundingBox = getBoundingBoxParaaf(canvas);
   

    const signatureDataURL = sigCanvas.current.toDataURL();

    newCanvas.width = boundingBox.width;
    newCanvas.height = boundingBox.height;
    const centerX = newCanvas.width / 2;
    const centerY = newCanvas.height / 2;
    const sourceX = boundingBox.x;
    const sourceY = boundingBox.y;
    const sourceWidth = boundingBox.width;
    const sourceHeight = boundingBox.height;

    // Calculate destination coordinates to draw the cropped signature at the center
    const destX = centerX - sourceWidth / 2;
    const destY = centerY - sourceHeight / 2;
    const destWidth = sourceWidth;
    const destHeight = sourceHeight;

    newCtx.drawImage(canvas, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);

    const newSignatureDataURL = newCanvas.toDataURL();
    setSignatureData(newSignatureDataURL);
    onSignatureData(newSignatureDataURL);
  };

  const getBoundingBoxParaaf = (canvas) => {
    const ctx_paraaf = canvas.getContext('2d');
    const data = ctx_paraaf.getImageData(0, 0, canvas.width, canvas.height).data;
    let minX = canvas.width;
    let minY = canvas.height;
    let maxX = 0;
    let maxY = 0;
  
    for (let x = 0; x < canvas.width; x++) {
      for (let y = 0; y < canvas.height; y++) {
        const index = (y * canvas.width + x) * 4;
        const alpha = data[index + 3];
  
        if (alpha > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }
  
    const width = maxX - minX + 1;
    const height = maxY - minY + 1;
  
    return {
      x: minX,
      y: minY,
      width,
      height,
    };
  };

  return (
    <div>
      <div className="signature-panel-clear">
        <span className="clear-canvas">
          <a role="button" onClick={clearCanvasParaaf} id="clear_paraf" href="#">
            {t('Clear')}
          </a>
        </span>
      </div>
      <div className="signature-drawing-panel">
        <SignatureCanvas
          ref={sigCanvas}
          canvasProps={{ width: 230, height: 230, className: 'praaf-pad' }}
          onEnd={handleSignatureParaafChange}
        />
      </div>
    </div>
  );
}