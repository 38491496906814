/* SIGNATURES */
export const GET_SIGNATURES = "GET_SIGNATURES"
export const GET_SIGNATURES_SUCCESS = "GET_SIGNATURES_SUCCESS"
export const GET_SIGNATURES_FAIL = "GET_SIGNATURES_FAIL"

/**
 * add SIGNATURES
 */
export const ADD_NEW_SIGNATURES = "ADD_NEW_SIGNATURES"
export const ADD_SIGNATURES_SUCCESS = "ADD_SIGNATURES_SUCCESS"
export const ADD_SIGNATURES_FAIL = "ADD_SIGNATURES_FAIL"

/**
 * add HANDWRITING 
 */
export const ADD_NEW_HANDWRITING = "ADD_NEW_HANDWRITING"
export const ADD_HANDWRITING_SUCCESS = "ADD_HANDWRITING_SUCCESS"
export const ADD_HANDWRITING_FAIL = "ADD_HANDWRITING_FAIL"
/**
 * add STEPM 
 */
export const ADD_NEW_STEPM = "ADD_NEW_STEPM"
export const ADD_STEPM_SUCCESS = "ADD_STEPM_SUCCESS"
export const ADD_STEPM_FAIL = "ADD_STEPM_FAIL"


export const GET_SETTING = "GET_SETTING"
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS"
export const GET_SETTING_FAIL = "GET_SETTING_FAIL"


export const GET_HELPCENTER = "GET_HELPCENTER"
export const GET_HELPCENTER_SUCCESS = "GET_HELPCENTER_SUCCESS"
export const GET_HELPCENTER_FAIL = "GET_HELPCENTER_FAIL"


export const GET_BLOGDATA = "GET_BLOGDATA"
export const GET_BLOGDATA_SUCCESS = "GET_BLOGDATA_SUCCESS"
export const GET_BLOGDATA_FAIL = "GET_BLOGDATA_FAIL"


export const GET_BLOGCATDATA = "GET_BLOGCATDATA"
export const GET_BLOGCATDATA_SUCCESS = "GET_BLOGCATDATA_SUCCESS"
export const GET_BLOGCATDATA_FAIL = "GET_BLOGCATDATA_FAIL"