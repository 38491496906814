import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
  } from "reactstrap";
  import './css.css';
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import {
  getUren,
} from "store/actions";
function formatDate(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

function formatTime(timeString) {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}`;
}
const CardComponent = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    urendata,
    error
  } = useSelector(state => ({
    urendata: state.getShiftData.urendata,
    error: state.getShiftData.error,
  }));
  const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [token, setToken] = useState(user.token);
const [userId, setuserId] = useState(user.clientId);
const [account_id , setAccountid ] = useState(user.account_id );
const [dateurl, setDateUrl] = useState(formatDate(new Date()));
const selectedDate = new Date(dateurl)
if (!(selectedDate instanceof Date) || isNaN(selectedDate.getTime())) {
  throw new Error("Invalid Date object provided");
}

const month = selectedDate.getMonth();
const year = selectedDate.getFullYear();

const firstDay = formatDate(new Date(year, month, 1));
const lastDay = formatDate(new Date(year, month + 1, 0));
useEffect(() => { 
const obj = {
  start:firstDay,
  eind:lastDay,
  account_id:account_id,
}

  dispatch(getUren(obj));
}, [account_id,dispatch]);

const renderTableRows = () => {
  const rows = Object.values(urendata).flatMap(({ items }) =>
    items.map((item) => ({
      date: item.uren_date || 'N/A',
      time: `${item.start_time_uren || 'N/A'} - ${item.eind_time_uren || 'N/A'}`,
      shift: item.shift_name || 'N/A',
      team: item.team_name || 'N/A',
      department: item.dep_name || 'N/A',
    }))
  );

  return rows.map((row, index) => (
    <tr key={index}>
      <td className="ellipsis-cell">{formatDate(new Date(row.date))}</td>
      <td className="ellipsis-cell">{formatTime(row.time)} </td>
      <td className="ellipsis-cell">{row.shift}</td>
      <td className="ellipsis-cell">{row.team}</td>
      <td className="ellipsis-cell">{row.department}</td>
    </tr>
  ));
};

const renderTableRowsTimesheet = () => {
  // Flatten and filter items based on the work_status condition
  const rows = Object.values(urendata).flatMap(({ items }) =>
    items
      .filter((item) => !["no", "wait", "vk"].includes(item.work_status))
      .map((item) => ({
        date: item.uren_date || 'N/A',
        start: item.start_time_uren || 'N/A',
        end: item.eind_time_uren || 'N/A', 
        break: item.pause_uren || 'N/A',
        hours: item.total_day_hour || 'N/A',
      }))
  );

  return rows.map((row, index) => (
    <tr key={index}>
      <td className="ellipsis-cell">{formatDate(new Date(row.date))}</td>
      <td className="ellipsis-cell">{formatTime(row.start)}</td>
      <td className="ellipsis-cell">{formatTime(row.end)}</td>
      <td className="ellipsis-cell">{row.break}</td>
      <td className="ellipsis-cell">{row.hours}</td>
    </tr>
  ));
};
  return (
    <React.Fragment>
<Container fluid>
{/* Render Breadcrumb */}
<Row>
                  <Col md={6}>
                    <Card className=" text-white card-has-bg click-col ">
                   
                        <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none' }}
                          >
                         {t('Monthy schedule')} 
                          </Button> 
                       
                      <CardBody>
                      <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th>{t('Date')}</th>
                <th>{t('Time')}</th>
                <th>{t('Shift')}</th>
                <th>{t('Team')}</th>
                <th>{t('Department')}</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card className=" text-white card-has-bg click-col ">
                    <ul className="list-group list-group-flush">
                        <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none' }}
                          >
                         {t('Monthy timesheet')} 
                          </Button> 
                         </ul>
                         <CardBody>
                      <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th>{t('Date')}</th>
                <th>{t('Start')}</th>
                <th>{t('End')}</th>
                <th>{t('Break')}</th>
                <th>{t('Hours')}</th>
              </tr>
            </thead>
            <tbody>{renderTableRowsTimesheet()}</tbody>
          </table>
        </div>
                      </CardBody>
                    </Card>
                  </Col>

                </Row>

        </Container>
     
    </React.Fragment>
  );

};

export default withTranslation()(withRouter(CardComponent));