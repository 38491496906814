import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Row,
    Col,

  } from "reactstrap";
import CustomNavbar from "components/HeaderUnUser/Header";
import TawkToWidget from "components/LiveChat";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import { Link ,useLocation } from "react-router-dom";
const Support = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language
  const location = useLocation();
    useEffect(() => {
      document.title = t(`support  | Shiftspec.com`);
        const headerColor = document.getElementById('header_color');
        
        // Check if the element exists before accessing it
        if (headerColor) {
          headerColor.style.backgroundColor = '#f0f8ff';
        }
        const updateMetaTags = () => {
          document.title = t(`Customer Support | Get Help and Troubleshooting Assistance | ShiftSpec`);
          
          const metaElement = document.querySelector('meta[name="description"]');
          if (metaElement) {
            metaElement.setAttribute('content', 'Find comprehensive support resources for ShiftSpec, including FAQs, troubleshooting, and help documentation. Get assistance for employee scheduling, time tracking, and digital signature solutions.');
          } else {
            addOrUpdateMeta('description', 'Need help? ShiftSpec offers robust customer support resources to assist with all your workforce management needs, from scheduling to time tracking and digital signatures. Find FAQs, tutorials, and troubleshooting guides here.');
          }
          
          addOrUpdateMeta('keywords', 'ShiftSpec Support, Customer Support, Help, Troubleshooting, Workforce Management Help, Employee Scheduling Support, Time Tracking Assistance, Digital Signatures Help, ShiftSpec Help Desk, FAQ, Tutorials, Customer Service, Support Documentation, ShiftSpec FAQs, Tech Support');
        
          function addOrUpdateMeta(name, content) {
            let metaTag = document.querySelector(`meta[name="${name}"]`);
            if (metaTag) {
              metaTag.setAttribute('content', content);
            } else {
              metaTag = document.createElement('meta');
              metaTag.setAttribute('name', name);
              metaTag.setAttribute('content', content);
              document.head.appendChild(metaTag);
            }
          }
          
          // Meta tags for the "Support" page
          const metaTags = [
            { name: 'description', content: 'Need help? ShiftSpec offers robust customer support resources to assist with all your workforce management needs, from scheduling to time tracking and digital signatures. Find FAQs, tutorials, and troubleshooting guides here.' },
            { name: 'keywords', content: 'ShiftSpec Support, Customer Support, Help, Troubleshooting, Workforce Management Help, Employee Scheduling Support, Time Tracking Assistance, Digital Signatures Help, ShiftSpec Help Desk, FAQ, Tutorials, Customer Service, Support Documentation, ShiftSpec FAQs, Tech Support' },
            { name: 'author', content: 'ShiftSpec Support Team' }
          ];
          
          // Apply each meta tag
          metaTags.forEach(tag => {
            addOrUpdateMeta(tag.name, tag.content);
          });
          
          // Helper function for Open Graph and Twitter meta tags
          const addOrUpdateMetaTag = (property, content) => {
            let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
            if (tag) {
              tag.setAttribute('content', content);
            } else {
              tag = document.createElement('meta');
              tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
              tag.setAttribute('content', content);
              document.head.appendChild(tag);
            }
          };
        
          // Open Graph Meta Tags for "Support" page
          addOrUpdateMetaTag('og:title', 'Customer Support | Get Help and Troubleshooting Assistance | ShiftSpec');
          addOrUpdateMetaTag('og:description', 'Find comprehensive support resources for ShiftSpec, including FAQs, troubleshooting, and help documentation. Get assistance for employee scheduling, time tracking, and digital signature solutions.');
          addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
          addOrUpdateMetaTag('og:type', 'website');
        
          // Twitter Meta Tags for "Support" page
          addOrUpdateMetaTag('twitter:title', 'Customer Support | Get Help and Troubleshooting Assistance | ShiftSpec');
          addOrUpdateMetaTag('twitter:description', 'Find comprehensive support resources for ShiftSpec, including FAQs, troubleshooting, and help documentation. Get assistance for employee scheduling, time tracking, and digital signature solutions.');
          addOrUpdateMetaTag('twitter:card', 'summary_large_image');
        };
        
        // Call the function to update meta tags
        updateMetaTags();
    
      }, [ location.pathname, t]);
  return (
    <React.Fragment>
    <CustomNavbar />
  
    <section className="relative lg:pb-10 pt-20 overflow-hidden" style={{background:'#f0f8ff'}}>
    <Row>
        <Col sm={6} className='flex align-items-center justify-content-center'>
       
    <div className="lg:col-span-7 xl:pr-1 text-left">
        <h1 className="font-bold text-dark text-[30px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold  mb-1.5 lg:mt-3 md:mb-6">
        {t('What can we help you with?El')}
        </h1>
        <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6">
        {t('We are happy to provide support')} 
        </p>
      </div>

        </Col>
        <Col sm={6}>
        <div className="lg:col-span-5 text-center lg:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.37.43_ed606ac6.jpg"
          width="450"
          height="283"
          className="mx-auto lg:mr-0"
          alt="header image"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.37.43_ed606ac6.jpg 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
        <div className="lg:hidden flex flex-col items-center text-center mt-12">
          <div className="flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-5 mb-4 lg:mb-3 max-w-xs"></div>
        </div>
      </div>
      {/* Triangle SVG */}
  <svg
    className="absolute top-0  inset-x-0 -z-[5] mx-auto h-[40%] lg:translate-x-[20%] mt-32 opacity-20"
    style={{right:'24px'}}
    width="732"
    height="389"
    viewBox="0 0 732 389"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_26_895)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M625.302 293.386C626.169 291.133 627.896 289.21 630.278 288.153C631.888 287.438 633.666 287.188 635.412 287.432V287.432L720.091 299.248C722.671 299.608 724.861 300.98 726.316 302.906C727.772 304.833 728.491 307.315 728.131 309.895C727.91 311.483 727.287 312.988 726.322 314.268V314.268L676.344 380.577C674.776 382.658 672.513 383.906 670.122 384.242C667.731 384.578 665.211 384.002 663.13 382.434C661.85 381.469 660.836 380.194 660.185 378.729V378.729L625.484 300.603C624.426 298.223 624.434 295.639 625.302 293.386Z"
        stroke="#2A86CD"
        strokeWidth="6.29227"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.921 2.23295C172.217 1.48943 174.797 1.62246 177.117 2.80827C178.686 3.61016 180 4.83541 180.909 6.34525V6.34525L225.019 79.6095C226.363 81.8422 226.676 84.4081 226.094 86.7518C225.513 89.0955 224.038 91.2169 221.807 92.5606C220.434 93.3875 218.873 93.8506 217.271 93.9062V93.9062L134.303 96.784C131.7 96.8743 129.306 95.9005 127.54 94.253C125.774 92.6055 124.636 90.2844 124.545 87.6802C124.488 86.0775 124.841 84.487 125.57 83.059V83.059L164.428 6.9169C165.612 4.59653 167.624 2.97646 169.921 2.23295Z"
        stroke="#2A86CD"
        strokeWidth="6.29227"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.2132 113.629C63.4079 110.257 66.8895 107.722 71.1378 106.824C74.0107 106.216 76.9964 106.425 79.757 107.427V107.427L213.711 156.045C217.793 157.527 220.889 160.522 222.593 164.168C224.298 167.813 224.612 172.109 223.131 176.19C222.22 178.701 220.683 180.939 218.667 182.692V182.692L114.223 273.468C110.946 276.316 106.824 277.566 102.81 277.285C98.795 277.003 94.8872 275.191 92.0379 271.914C90.2844 269.897 89.0737 267.467 88.5203 264.852V264.852L59.01 125.459C58.1106 121.211 59.0185 117.002 61.2132 113.629Z"
        stroke="#2A86CD"
        strokeWidth="10.4871"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_26_895">
        <rect width="732" height="388.117" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
        </Col>
    </Row>
    </section>


    <section className="relative lg:pb-10 pt-20 overflow-hidden bg-white">
  <Row>
    <Col sm={12} className="d-flex flex-column align-items-center justify-content-center text-center">
      <h1 className="font-bold text-dark text-[36px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold mb-4 mt-4">
      {t('We are here to help you!')}   
      </h1>
      
      <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 mb-[60px]"style={{ padding: '20px' }}>
      {t('Do you need help or do you have questions? You have come to the right spot. These are the best ways to contact us.')}
      
      </p>
      <section className='container max-w-6xl mx-auto px-6 'style={{padding:'20px',paddingLeft:'1.5rem',paddingRight:'1.5rem',maxWidth:'72rem'}}>
  <div className="w-full p-8 bg-white rounded-lg shadow-10 mb-12" style={{borderRadius:'12px'}}>
    <div className="flex  md:flex-row items-center justify-between md:space-y-0 space-y-8 md:text-left text-left">
      <div className="md:w-2/3 px-4" style={{padding:'15px'}}>
        <h5 className="text-base sm:text-lg md:text-xl font-extrabold text-dark-30 mb-2">
        {t('Help Center')}  
        </h5>
        <p className="text-sm sm:text-base text-dark-30">
        {t('In our Help Center, you will find extensive manuals for both employees and managers.Updates and new features will be described, and you can also view the monthly newsletter here.')}  
        </p>
      </div>
      <div className="md:w-1/3 text-center md:text-right" style={{width:'25%',bottom:'17px'}}>
        <div className="flex flex-col">
          <div className="h-14 rounded-md text-white flex items-center justify-center">
          <a
  href="/help-center"
  className="text-sm sm:text-base text-blue-60 bg-blue-10 hover:bg-blue-20  px-4 rounded-lg font-bold"
  target="_blank"
  rel="noopener noreferrer"
  
>
  {t('Help Center')} 
</a>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className='container max-w-6xl mx-auto px-6 'style={{padding:'20px',paddingLeft:'1.5rem',paddingRight:'1.5rem',maxWidth:'72rem'}}>
  <div className="w-full p-8 bg-white rounded-lg shadow-10 mb-12" style={{borderRadius:'12px'}}>
    <div className="flex  md:flex-row items-center justify-between md:space-y-0 space-y-8 md:text-left text-left">
      <div className="md:w-2/3 px-4" style={{padding:'15px'}}>
        <h5 className="text-base sm:text-lg md:text-xl font-extrabold text-dark-30 mb-2">
        {t('YouTube Channel')} 
        </h5>
        <p className="text-sm sm:text-base text-dark-30">
        {t('Shiftspec has its own YouTube channel with many useful instructional videos, in this way we are happy to help you continue working with our software!')}
        </p>
      </div>
      <div className="md:w-1/3 text-center md:text-right" style={{width:'25%',bottom:'17px'}}>
        <div className="flex flex-col">
          <div className="h-14 rounded-md text-white flex items-center justify-center">
          <a
  href="https://www.youtube.com/@Shiftspece"
  className="text-sm sm:text-base text-blue-60 bg-blue-10 hover:bg-blue-20  px-4 rounded-lg font-bold"
  target="_blank"
  rel="noopener noreferrer"
  
>
{t('YouTube Channel')} 
</a>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className='container max-w-6xl mx-auto px-6 'style={{padding:'20px',paddingLeft:'1.5rem',paddingRight:'1.5rem',maxWidth:'72rem'}}>
  <div className="w-full p-8 bg-white rounded-lg shadow-10 mb-12" style={{borderRadius:'12px'}}>
    <div className="flex  md:flex-row items-center justify-between md:space-y-0 space-y-8 md:text-left text-left">
      <div className="md:w-2/3 px-4" style={{padding:'15px'}}>
        <h5 className="text-base sm:text-lg md:text-xl font-extrabold text-dark-30 mb-2">
        {t('Phone')} 
        </h5>
        <p className="text-sm sm:text-base text-dark-30">
        {t('Could you not find the answer in our Help Center?Please do not hesitate to call us. Our helpdesk will be happy toassist you and answer any questions regarding Shiftbase. You can reach us Monday through Friday between 9 AM and 5 PM CET on')}+316842754  
        </p>
      </div>
    </div>
  </div>
</section>


<section id='livechat' className='container max-w-6xl mx-auto px-6 'style={{padding:'20px',paddingLeft:'1.5rem',paddingRight:'1.5rem',maxWidth:'72rem'}}>
  <div className="w-full p-8 bg-white rounded-lg shadow-10 mb-12" style={{borderRadius:'12px'}}>
    <div className="flex  md:flex-row items-center justify-between md:space-y-0 space-y-8 md:text-left text-left">
      <div className="md:w-2/3 px-4" style={{padding:'15px'}}>
        <h5 className="text-base sm:text-lg md:text-xl font-extrabold text-dark-30 mb-2">
        {t('Chat')} 
        </h5>
        <p className="text-sm sm:text-base text-dark-30">
        {t('If you have a short question, we can often answer it via our chat. When you have multiple questions or the answer is to complex, we will suggest contacting us by phone.')}  
        </p>
      </div>
      <div className="md:w-1/3 text-center md:text-right" style={{width:'25%',bottom:'17px'}}>
        <div className="flex flex-col">
          <div className="h-14 rounded-md text-white flex items-center justify-center">
          <a
  href="#livechat"
  className="text-sm sm:text-base text-blue-60 bg-blue-10 hover:bg-blue-20  px-4 rounded-lg font-bold"
  rel="noopener noreferrer"
  
>
{t('Live chat')} 
  
</a>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section className='container max-w-6xl mx-auto px-6 'style={{padding:'20px',paddingLeft:'1.5rem',paddingRight:'1.5rem',maxWidth:'72rem'}}>
  <div className="w-full p-8 bg-white rounded-lg shadow-10 mb-12" style={{borderRadius:'12px'}}>
    <div className="flex  md:flex-row items-center justify-between md:space-y-0 space-y-8 md:text-left text-left">
      <div className="md:w-2/3 px-4" style={{padding:'15px'}}>
        <h5 className="text-base sm:text-lg md:text-xl font-extrabold text-dark-30 mb-2">
        {t('Remote support')}  
        </h5>
        <p className="text-sm sm:text-base text-dark-30">
        {t('In order to provide good support, it is sometimes necessary to look at the customer’s screen. For this, we use the AnyDesk tool. This way, we can look at the screen remotely on both Windows and OSX computers.')}  
        </p>
      </div>
      <div className="md:w-1/3 text-center md:text-right" style={{width:'30%',bottom:'17px'}}>
        <div className="flex flex-col">
          <div className="h-14 rounded-md text-white flex items-center justify-center">
          <a
  href="https://anydesk.com/en"
  className="text-sm sm:text-base text-blue-60 bg-blue-10 hover:bg-blue-20  px-4 rounded-lg font-bold"
  target="_blank"
  rel="noopener noreferrer"
  
>
{t('Remote support')} 
</a>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </Col>
  </Row>
</section>
<section className="relative lg:pb-10 pt-20 overflow-hidden bg-white">
  <Row>
    <Col sm={12} className="d-flex flex-column align-items-center justify-content-center text-center">
    <h2 className="font-bold text-dark text-[36px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold mb-4 mt-4">
    {t('A personal tour of Shiftspec')}    
      </h2>
      <p className="text-base sm:text-lg md:text-xl font-light text-dark-30 lg:mb-6"style={{ padding: '20px' }}>
      {t('Interested? Request a demonstration now without any obligation!')}    
      </p>
      <a href='/register/starter'>
        <button
          title="Purchase"
          block
          role="button"
          type="submit"
          className="css-pjhtp2"
          style={{ display: 'block', marginTop: '20px', marginBottom: '20px' }}
        >
          <span className="css-13wylk3">{t('Request a demo')} </span>
        </button>
      </a>
    </Col>
  </Row>
</section>

<TawkToWidget />
    </React.Fragment>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#00a0f0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    color: '#555',
  },
};
Support.propTypes = {
    history: PropTypes.object,
    t: PropTypes.isRequired,
  };
export default withTranslation()(withRouter(Support));