import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
//Import Breadcrumb

import classnames from "classnames";
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getMailsLists as onGetMailsLists,
  getSelectedMails as onGetSelectedMails,
  updateMail as onUpdateMail,
  accountProfile as onGetAccountProfile,
  editAccountProfile,
  accountInvoice as onGetAccountInvoice,
  getBrandingInfo,
  postBrandingInfo,
  putBrandingInfo,
  deleteBrandingInfo,
} from "store/actions";
import { withTranslation } from "react-i18next";
const Profile = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = t("User Profile - View and Edit Your Personal Information | eSignatureHub.com");
  }, [t]);
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [token, setToken] = useState(user.token);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const {
    mailslists,
    selectedmails,
    profiles,
    profilesdata,
    invoice,
    info,
    postinfo,
    putinfo,
    deleteinfo,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    selectedmails: state.mails.selectedmails,
    profiles: state.mails.profiles,
    profilesdata: state.mails.profilesdata,
    invoice: state.mails.invoice,
    info: state.mails.info,
    postinfo: state.mails.postinfo,
    putinfo: state.mails.putinfo,
    deleteinfo: state.mails.deleteinfo,
    error: state.mails.error,

  }));
  //meta title
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetMailsLists(account_id));
    dispatch(onGetAccountProfile(account_id));
  }, [dispatch]);
  
  useEffect(() => {
    if (profiles && profiles.length > 0) {
      const profile = profiles[0];
      setFirstName(profile.frist_name || '');
      setLastName(profile.last_name || '');
      setPhoneNumber(profile.phonenumber || '');
      setCountry(profile.country || '');
      setCity(profile.city || '');
      setAddress(profile.address || '');
    }
  }, [profiles]);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const profileObject = {
      client_id :userId,
      accountid :account_id,
      frist_name:firstName,
      last_name:lastName,
      phone_number:phoneNumber,
      country:country,
      city:city,
      address:address,
    }
    if (isEditorAllowed) {
  
      dispatch(editAccountProfile(profileObject));
    }
    
  };

  useEffect(() => {
   
    if (profilesdata.success) {
      toastr.success(t('Your profile has been successfully updated.'));
      dispatch(onGetAccountProfile(account_id));
    }
  
  }, [profilesdata,dispatch]);

  return (
    <React.Fragment>
         <div className="inbox-container">
              <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
                <div className="listHeader css-jcpkb3">
                  <div className="listHeader_titleRow">
                    <h2 style={{ fontSize: '20px' }} className="listHeader_heading m-bottom-xx-small">{t('Account Profile')}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                  <div className="col-lg-12" style={{ display:'inline-flex' }}>
                  <Card style={{ width: '50%', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
      <CardBody>
        <CardTitle>{t('Profile')}</CardTitle>
        <form onSubmit={handleSubmit}>
          <div className="css-list">
            <div>
              <label htmlFor="firstName" className="css-iekl2y">
              {t('First Name')} 
              </label>
              <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="css-iekl2y">
              {t('Last Name')}  
              </label>
              <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="phoneNumber" className="css-iekl2y">
              {t('Phone Number')}  
              </label>
              <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="country" className="css-iekl2y">
              {t('Country')}
              </label>
              <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="city" className="css-iekl2y">
              {t('City')}
              </label>
              <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="address" className="css-iekl2y">
              {t('Address')}
              </label>
              <div className="css-hboir5">
                <Input
                  className="css-12ihcxq"
                  type="text"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <button
        style={{ marginTop: '10px' }}
        type="submit"
        className="btn css-1bl3roz startbtn btn btn-Primira"
        disabled={!isEditorAllowed}
      >
        {t('Update')}
      </button>
          </div>
        </form>
      </CardBody>
    </Card>
                  </div>
                </div>
            </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Profile));