import React, { useRef, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DraggableList from './DraggableList';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    InputGroup,
    UncontrolledCollapse
  } from "reactstrap";
  import classnames from "classnames";
import { func } from 'prop-types';
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
 function SettingTextarea({
   t,targetid,datatype,onDateSelected ,onDateType,
   datacompany,datafontcolor,datafontfamily,
   datafontformat,datafontsize,datamails,datanames,
   datapdftile ,datatextarea,datanumbers,
   datacheckbox,dataselects,dataradios,
   datapayments,datadrawing,dataformula,dataattachment,
   dataapprove,datadecline,isAllowedText
  }) {
    useEffect(() => {
      toastr.options = {
        positionClass: 'toast-top-center',
        closeButton: true,
        debug: true,
        progressBar:true,
        timeOut: 30000, // Duration in milliseconds
      };
    }, []);
   

    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
  
    const [col5, setcol5] = useState(true);
    const [col6, setcol6] = useState(true);
    const [col7, setcol7] = useState(true);
  
    const [col8, setcol8] = useState(true);
    const [col9, setcol9] = useState(true);
    const [col10, setcol10] = useState(false);
    const [col11, setcol11] = useState(false);
    const [requreField, setRequre] = useState(true);
    const [validations, setValid] = useState(false);
    const [selectedFontFamily, setSelectedFontFamily] = useState(datafontfamily);
    const [selectedFontSize, setSelectedFontSize] = useState(datafontsize);
    const [selectedFontFormat, setSelectedFontFormat] = useState(datafontformat);
    const [selectedFontColor, setSelectedFontColor] = useState(datafontcolor);
    const [selectedOption, setSelectedOption] = useState(datanames);
    const [writeCompany, setWriteCompany] = useState(datacompany);
    const [writePdftitle, setWritePdftitle] = useState(datapdftile);
    const [inputData, setInputData] = useState(datamails);
    const [wiretextarea, setTextarea] = useState(datatextarea); 
    const [writecheckbox, setCheckbox] = useState(datacheckbox);
    const [writedropdown, setDropdown] = useState(dataselects);
    const [writeradio, setRadio] = useState(dataradios);
    const [payments, setPayments] = useState(datapayments);
    const [drawing, setDrawing] = useState(datadrawing);
    const [formula, setFormula] = useState(dataformula);
    const [attachment, setAttachment] = useState(dataattachment);
    const [approve, setApprove] = useState(dataapprove);
    const [decline, setDecline] = useState(datadecline);
    const [textarea, setTextareaValue] = useState("text");
    const [newNumber, setNewNumbers] = useState('00.00');
    const [selectedValue, setSelectedValue] = useState(null);

    const fontFamilies = [
      { dataQa: 'arial', value: 'arial', label: 'Arial' },
      { dataQa: 'Courier', value: 'Courier', label: 'Courier' },
      { dataQa: 'Courier-Bold', value: 'Courier-Bold', label: 'Courier-Bold' },
      { dataQa: 'Courier-Oblique', value: 'Courier-Oblique', label: 'Courier-Oblique' },
      { dataQa: 'Courier-BoldOblique', value: 'Courier-BoldOblique', label: 'Courier-BoldOblique' },
      { dataQa: 'Helvetica-Bold', value: 'Helvetica-Bold', label: 'Helvetica-Bold' },
      { dataQa: 'helvetica', value: 'helvetica', label: 'Helvetica' },
      { dataQa: 'Helvetica-BoldOblique', value: 'Helvetica-BoldOblique', label: 'Helvetica-BoldOblique' },
      { dataQa: 'Times-Roman', value: 'Times-Roman', label: 'Times-Roman' },
      { dataQa: 'Times-Bold', value: 'Times-Bold', label: 'Times-Bold' },
      { dataQa: 'Times-Italic', value: 'Times-Italic', label: 'Times-Italic' },
      { dataQa: 'Times-BoldItalic', value: 'Times-BoldItalic', label: 'Times-BoldItalic' },
      { dataQa: 'Symbol', value: 'Symbol', label: 'Symbol' },
      { dataQa: 'ZapfDingbats', value: 'ZapfDingbats', label: 'ZapfDingbats' },
    ];
    const getSVGContent = (format) => {
      switch (format) {
        case 'bold':
          return (
            <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
            <path d="m8.59 6.33.157-.006c.369 0 .674-.09.907-.267.219-.168.33-.45.33-.843 0-.22-.035-.397-.11-.54a.826.826 0 0 0-.288-.313 1.417 1.417 0 0 0-.444-.156 3.36 3.36 0 0 0-.562-.049H6.595V6.33H8.59zm.292 4.534c.203 0 .408-.024.61-.067a1.42 1.42 0 0 0 .486-.202.93.93 0 0 0 .316-.364c.079-.152.117-.351.117-.61 0-.502-.126-.844-.388-1.047-.275-.213-.633-.316-1.097-.316H6.595v2.606h2.287zM4 13V2h5.044c.504 0 .97.046 1.386.137.42.092.791.248 1.1.463.316.223.56.518.726.88.17.354.256.801.256 1.327 0 .58-.126 1.05-.386 1.44-.194.295-.46.549-.794.76.504.209.896.52 1.17.931.336.507.498 1.097.498 1.803 0 .575-.105 1.058-.322 1.475-.216.42-.51.765-.87 1.024a3.755 3.755 0 0 1-1.229.577 5.283 5.283 0 0 1-1.4.183H4z"></path>
          </svg>
          );
        case 'italic':
          return (
            <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
            <path d="M7 2v2h1.8l-3.101 7.5H3.6v2h5.999v-2H7.8L11 4h1.999V2z"></path>
          </svg>
          );
        case 'underline':
          return (
            <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
            <path d="M1 14h14v2H1zM2.846 2H4.98v5.956c0 .384.02.773.06 1.168.04.395.154.75.342 1.064.187.315.472.573.853.776.381.203.918.304 1.612.304.695 0 1.231-.101 1.612-.304.381-.203.667-.461.854-.776.192-.33.308-.692.34-1.064.04-.395.06-.784.06-1.168V2h2.134v6.612c0 .736-.12 1.376-.359 1.92a3.7 3.7 0 0 1-1.014 1.368 4.322 4.322 0 0 1-1.58.824 7.22 7.22 0 0 1-2.047.272 7.2 7.2 0 0 1-2.048-.272 4.322 4.322 0 0 1-1.577-.824 3.701 3.701 0 0 1-1.016-1.368c-.239-.544-.359-1.184-.359-1.92V2z"></path>
          </svg>
          );
        default:
          return null;
      }
    };
    const FontFormats = [
      { value: 'bold', label: 'Bold' },
      { value: 'italic', label: 'Italic' },
      { value: 'underline', label: 'Underline' },
      // Add more formats as needed
    ];
    const textAreaRect = document.getElementById(targetid);
    const fontFamilyValues = textAreaRect.getAttribute('data-fontFamily')
    const fontSizeValues = textAreaRect.getAttribute('data-fontSize')
    const fontFormatValues = textAreaRect.getAttribute('data-fontFormat')
    const fontColorsValues = textAreaRect.getAttribute('data-fontColor')
    const newTextContent = textAreaRect.getAttribute('data-content');
    const datalabel = textAreaRect.getAttribute('data-label');
    const newRoll = textAreaRect.getAttribute('data-roll');
    const [datalabels, setDataLabel] = useState(datalabel);
    const  labelStyle = {
      border: selectedFontFormat === fontFormatValues ? '1px solid #cecece' : 'none',
      // Add other styles as needed
    };

  function updateData(
    textarea,
    fontfamily,
    fontsize,
    format,
    color,
    requreField
    ){ 
    if (textAreaRect) {
  const padding = 20;
  const parentGroup = textAreaRect.parentElement;
  if (parentGroup) {
    const textToUpdate = parentGroup.querySelector('text');
    if (textToUpdate) {
      const x = parseFloat(textAreaRect.getAttribute('x'));
      const y = parseFloat(textAreaRect.getAttribute('y'));
      const width = parseFloat(textAreaRect.getAttribute('width'));
      const height = parseFloat(textAreaRect.getAttribute('height'));
      const tspans = textToUpdate.getElementsByTagName('tspan');
      
      const newletterWidth = getLetterWidth(textToUpdate, fontsize);
     
      function getLetterWidth(tempText,fontSize) {
          
        if (fontSize <= 0) {
          console.error('Font size must be greater than zero.');
          return 0;
        }
        // Check for valid text content
        if (!tempText.textContent) {
          console.error('Text content is empty.');
          return 0;
        }  
        let letterWidth = 0;
        try {
          letterWidth = tempText.getSubStringLength(0, 1);
        } catch (error) {
          console.error('Error while measuring letter width:', error);
        }
        
        return letterWidth;
      }
  if (tspans) {
    const textCount = Math.floor(width / newletterWidth); 
    function splitTextByChunk(str, textCount) {
      if (!str || textCount <= 0) {
        return ['value'];
      }
     // count = 12
      const pattern = new RegExp(`.{1,${textCount}}`, 'g');
      const chunks = str.match(pattern) || [];
      return chunks;
    }
  const str = splitTextByChunk(newTextContent, textCount)
 
    while (textToUpdate.firstChild) {
      textToUpdate.removeChild(textToUpdate.firstChild);
    }

    const centerX = x + width / 2;
    const centerY = y + height / 2;
    
    for (let i = 0; i < str.length; i++) {
      const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.textContent = str[i];
      const textContent = tspan.textContent;
      tspan.setAttribute('x', centerX); 
      tspan.setAttribute('y', y + 20);
      tspan.setAttribute('dy', fontsize * i);
      tspan.setAttribute('fill', color);
      tspan.setAttribute('font-size', fontsize);
      tspan.setAttribute('font-family', fontfamily);
      tspan.style.fontStyle = format === 'italic' ? 'italic' : 'normal';
      tspan.style.textDecoration = format === 'underline' ? 'underline' : 'none';
      tspan.style.fontWeight = format === 'bold' ? 'bold' : 'normal';
      textToUpdate.appendChild(tspan);
    }
    const textWidth = textToUpdate.getBBox().width + padding; // consider padding in width calculation
    const textHeight = textToUpdate.getBBox().height + padding; // consider padding in height calculation
    textAreaRect.setAttribute('width', textWidth);
    textAreaRect.setAttribute('height', textHeight)
    textToUpdate.setAttribute('text-anchor', 'middle'); 
    textToUpdate.setAttribute('dominant-baseline', 'middle');
    const circles = parentGroup.querySelectorAll('circle');
    // Top-left corner
    if (circles) {
    
      const rectX = parseFloat(textAreaRect.getAttribute('x'));
      const rectY = parseFloat(textAreaRect.getAttribute('y'));
      const rectWidth = parseFloat(textAreaRect.getAttribute('width'));
      const rectHeight = parseFloat(textAreaRect.getAttribute('height'));
  
      const cxValues = [rectX, rectX + rectWidth, rectX, rectX + rectWidth];
      const cyValues = [rectY, rectY, rectY + rectHeight, rectY + rectHeight];
  
      circles.forEach((circle, index) => {
        circle.setAttribute('cx', cxValues[index]);
        circle.setAttribute('cy', cyValues[index]);
      });
    }

  }    

    }
  }
  }
}
      
const t_col9 = () => {
  setcol9(!col9);
};

    const t_col1 = () => {
      setcol1(!col1);
      setcol2(false);
      setcol3(false);
    };
    
    const t_Text = () => {
      setRequre(!requreField);
      setValid(false);
    };
  
    const t_col3 = () => {
      setcol3(!col3);
      setcol1(false);
      setcol2(false);
    };
  
    const handleLabelChange = (event) => {
      setDataLabel(event.target.value);
      textAreaRect.setAttribute('data-label', event.target.value);
    };
    const itemStyle = {
        display: 'flex',
      };
      
      const handleRadioChange = (value) => {
        setSelectedValue(value);
        textAreaRect.setAttribute('data-roll', value);
      };
      const handleTextareaChange = (event) => {
        const newText = event.target.value
        setTextareaValue(event.target.value);
        textAreaRect.setAttribute('data-content', event.target.value);
        updateData(
          newText,
          selectedFontFamily,
          selectedFontSize,
          selectedFontFormat,
          selectedFontColor,
          requreField
          )
      };
     const handleFontFamilyChange = (event) => {
      const selectedFontFamilyValue = event.target.value;
      setSelectedFontFamily(event.target.value);
      textAreaRect.setAttribute('data-fontFamily', selectedFontFamilyValue);
      updateData(
        textarea,
        selectedFontFamilyValue,
        selectedFontSize,
        selectedFontFormat,
        selectedFontColor,
        requreField
        )
    };
      const handleFontColorChange = (event) => {
        setSelectedFontColor(event.target.value);
        textAreaRect.setAttribute('data-fontColor', event.target.value);
        updateData(
          textarea,
          selectedFontFamily,
          selectedFontSize,
          selectedFontFormat,
          event.target.value,
          requreField
          )
      };
      const handleFontSizeChange = (event) => {
        setSelectedFontSize(event.target.value);
        textAreaRect.setAttribute('data-fontSize', event.target.value);
        updateData(
          textarea,
          selectedFontFamily,
          event.target.value,
          selectedFontFormat,
          selectedFontColor,
          requreField
          )
      };
      const handleLiClick = (value) => {
        const newFontFormat = value;
        setSelectedFontFormat(value);
        textAreaRect.setAttribute('data-fontFormat', value);
        updateData(
          textarea,
          selectedFontFamily,
          selectedFontSize,
          newFontFormat,
          selectedFontColor,
          requreField
          )
      };

      useEffect(() => {
        // Replace 'your-node-server-url' with the URL of your Node.js server
        onDateSelected( 
          targetid,datatype,selectedFontFamily,
          selectedFontSize,selectedFontFormat,selectedFontColor,
          selectedOption,inputData,writeCompany,writePdftitle,
          wiretextarea,newNumber,writecheckbox,writedropdown,
          writeradio,payments,drawing,formula,attachment,approve,decline,selectedValue
          )
      }, [targetid,datatype,selectedFontFamily,selectedFontSize,
        selectedFontFormat,selectedFontColor,selectedOption,
        inputData,writeCompany,writePdftitle,wiretextarea,
        newNumber,writecheckbox,writedropdown,writeradio,
        payments,drawing,formula,attachment,approve,decline,selectedValue
      ]);
     
      return (
        <React.Fragment>
             <Row>
                        <Col lg={12}>
                        <div className="css-vlt30q">
          <div style={itemStyle}>
              <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
             
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" aria-hidden="true" fill="gray8" focusable="false" data-qa="icon-element-text"><path d="M4 3v4h2V5h5v15H9v2h6v-2h-2V5h5v2h2V3H4z"></path></svg>
      </span>
      </span>
      <span data-qa="pannel-header" style={{paddingLeft:'0.625em'}}>{t('Text')}</span>
      </div>
      </div>
      <div className="accordion " id="accordion">
      <div className="accordion-item" >
                        <h2 className="accordion-header" id="headingTwo2">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !requreField }
                            )}
                            type="button"
                            onClick={t_Text}
                            style={{ cursor: "pointer" }}
                          >
                           {t('Rule')} 
                          </button>
                        </h2>
    <Collapse isOpen={requreField} className="accordion-collapse">
    <div className="accordion-body">
    <div className="text-muted">
    <Label>{t('Set Rule')} </Label>

    <div className="css-q10huv">
    <div className="css-hboir5" style={{display:'block'}}>
    <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
      <input
        type="radio"
        className="form-check-input"
        id="verplicht"
        name='checkbox'
        checked={newRoll === 'true'}
        onClick={() => handleRadioChange('true')}              
        />
        <label
        className="form-check-label"
        htmlFor="true"
        onClick={() => handleRadioChange('true')}
         >
        {t('Required field')}
        </label>
     </div>
     <div className="form-check form-checkbox-outline form-check-primary mb-3" style={{margin: '10px'}}>
      <input
        type="radio"
        className="form-check-input"
        id="lezen"d
        name='checkbox' 
        checked={newRoll === 'false'}
        onClick={() => handleRadioChange('false')}                 
        />
        <label
        className="form-check-label"
        htmlFor="alleenLezen"
        onClick={() => handleRadioChange('false')}
         >
        {t('Read-only')}
        </label>
     </div>                       
    </div>
    </div>
      </div>
      </div>
    </Collapse>
    </div>
    </div>

    <div className="accordion " id="accordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !col1 }
                                  )}
                                  type="button"
                                  onClick={t_col1}
                                  style={{ cursor: "pointer" }}
                                >
                                  {t('Formatting')}
                                </button>
                              </h2>
      
          <Collapse isOpen={col1} className="accordion-collapse">
            <div className="accordion-body">
            <div className="text-muted">
          <div className="css-q10huv">
          <div className="css-hboir5">
          <select
         className="css-12ihcxq" 
         value={fontFamilyValues}
        onChange={handleFontFamilyChange} >
        {fontFamilies.map((font) => (
    <option key={font.dataQa} value={font.value} data-qa={font.dataQa}>
      {font.label}
    </option>
  ))}
        </select>
              <span className="css-1baizyq">
                  <span className="css-lzaifp">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                      </svg>
                  </span>
              </span>
          </div>
      </div>
      <div className="css-9f4jv1">
          <div  style={{ marginRight: '-1px' ,width:"50px" }}>
              <div className="css-0">
                  <label data-qa="font-size-label" htmlFor="G29wOvqLVz" className="css-5gs0ys">Font Size</label>
              </div>
              <div className="css-q10huv">
                  <div className="css-hboir5">
                  <select 
                data-qa="font-size"
                 id="G29wOvqLVz"
                  className="css-12ihcxq"
                  value={fontSizeValues}
                   onChange={handleFontSizeChange}>
                    <option data-qa="font-7" value="7">7</option>
                    <option data-qa="font-8" value="8">8</option>
                    <option data-qa="font-9" value="9">9</option>
                    <option data-qa="font-10" value="10">10</option>
                    <option data-qa="font-11" value="11" >11</option>
                    <option data-qa="font-12" value="12" >12</option>
                    <option data-qa="font-14" value="14">14</option>
                    <option data-qa="font-16" value="16">16</option>
                    <option data-qa="font-18" value="18">18</option>
                    <option data-qa="font-20" value="20">20</option>
                    <option data-qa="font-22" value="22">22</option>
                    <option data-qa="font-24" value="24">24</option>
                    <option data-qa="font-26" value="26">26</option>
                    <option data-qa="font-28" value="28">28</option>
                    <option data-qa="font-36" value="36">36</option>
                    <option data-qa="font-48" value="48">48</option>
                    <option data-qa="font-72" value="72">72</option>
                </select>
                      <span className="css-1baizyq">
                          <span className="css-lzaifp">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                                  <path d="M4 5h8l-4 6z"></path>
                              </svg>
                          </span>
                      </span>
                  </div>
              </div>
          </div>
          <div className="css-199kmjd">
          {FontFormats.map((format) => (
        <div key={format.value} className="css-vxcmzt">
          <Input
            id={format.value}
            name="radioGroup"
            type="radio"
            className="css-1pdgtej"
            value={fontFormatValues}
            checked={selectedFontFormat === format.value}
          />
          <label htmlFor={format.value}
           className={`css-4lt7ka`} 
           style={selectedFontFormat === format.value ? labelStyle : {}}
           onClick={() => handleLiClick(format.value)}>
            <span className="css-zf56fy">
              <span aria-hidden="true" className="SVGInline">
              {getSVGContent(format.value)}
              </span>
            </span>
            <span className="css-16htk9o">{format.label}</span>
          </label>
        </div>
      ))}
      </div>
      </div>
      <div className="css-q10huv">
          <div className="css-hboir5">
              <select className="css-12ihcxq"
               value={fontColorsValues} 
               onChange={handleFontColorChange}>
               <option data-qa="#ffffff" value="#ffffff" selected>{t('White')}</option>
            <option data-qa="#0053eb" value="#0053eb">{t('Blue')}</option>
            <option data-qa="#f7e601" value="#f7e601">{t('Yellow')}</option>
            <option data-qa="#f70101" value="#f70101">{t('Red')}</option>
            <option data-qa="#000000" value="#000000">{t('Black')}</option>
            <option data-qa="#0087ff" value="#0087ff">{t('Bright Blue')}</option>
            <option data-qa="#7e0101" value="#7e0101">{t('Dark Red')}</option>
            <option data-qa="#ff0000" value="#ff0000">{t('Bright-Red')}</option>
            <option data-qa="#FFD700" value="#FFD700">{t('Gold')}</option>
            <option data-qa="#008000" value="#008000">{t('Green')}</option>
            <option data-qa="#006400" value="#006400">{t('Dark Green')}</option>
              </select>
              <span className="css-1baizyq">
                  <span className="css-lzaifp">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                      </svg>
                  </span>
              </span>
          </div>
      </div>
      
      
                      </div>
                          </div>
                      </Collapse>
                    </div>

                 <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                            <button
                        className={classnames("accordion-button", "fw-medium", { collapsed: !col3 })}
                        type="button"
                        onClick={t_col3}
                        style={{ cursor: "pointer" }}
                         >
                         {t('Type text')} 
                          </button>
                          </h2>
                  <Collapse
                    isOpen={col3}
                    className="accordion-collapse"
                  >
                  <div className="accordion-body">
                  <div className="text-muted">
                  <Label>{t('Text Area')}</Label>
                  <div className="css-q10huv">
                  <div className="css-hboir5" style={{display:"block"}}>
                  <textarea
                  className="css-12ihcxq"
                  value={newTextContent}
                  placeholder={newTextContent}
                  onChange={handleTextareaChange}
                  style={{overflow:'hidden', resize:'none',height:90}}
                   ></textarea>
                      <div style={{marginTop:"8px"}}>
                            <div className="css-25k912">
                                <div className="css-0">
                                    <label data-qa="char-limit-label" htmlFor="vskhF7yvZS" className="css-5gs0ys">{t('Character limit')}</label>
                                </div>
                                <div data-qa="char-limit-inner-wrapper" className="css-k008qs" style={{ width:"50px"}}>
                                <div className="input-text-box-wrapper css-1eq84o" data-qa="char-limit-wrapper">
                                <input
                            className="form-control form-control-sm css-in32s3s"
                            id="vskhF7yvZS"
                            type="text"
                            placeholder="4000"
                            value={4000}
                          />
                                </div>
                                </div>
                            </div>
                            <span aria-hidden="true">{t('Character limit')}</span>
                        </div>
                 <div>
                       
                 </div>
                
                  </div>
                  
                  </div>
                  </div>
                  </div>
                </Collapse>
                 </div>
                 <div className="accordion-item" >
                         <h2 className="accordion-header" id="headingTwo">
                           <button
                             className={classnames(
                               "accordion-button",
                               "fw-medium",
                               { collapsed: !col3 }
                             )}
                             type="button"
                             onClick={t_col9}
                             style={{ cursor: "pointer" }}
                           >
                             {t('Data Label')}
                           </button>
                         </h2>
 
                         <Collapse isOpen={col9} className="accordion-collapse">
                           <div className="accordion-body">
                             <div className="text-muted">
                             <div className="css-q10huv">
                  <div className="css-hboir5">
                  <Input
                   className="css-12ihcxq"
                   type="text"
                   value={datalabel}
                   onChange={handleLabelChange}
                   />
                   
                  </div>
                  </div>
                             </div>
                           </div>
                         </Collapse>
                       </div>
                 
                          </div>
                        </Col>
                      
                      </Row>
        </React.Fragment>
        
        );
      }
      
      export default withTranslation()(SettingTextarea);