import React, { useRef, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Col,Card, Row,Input, Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardTitle,
} from "reactstrap"; // Import Button for the print button
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { format, addDays } from "date-fns";
import classnames from 'classnames';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import toastr from "toastr";
import axios from "axios"
import "toastr/build/toastr.min.css";
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};

const generateUniqueId = () => {
  return uuidv4();
};
const PrintTimesheetModal = ({ t, show, handleClose, urendata, absence, currentDetails,checkedApprove}) => {
  const navigate = useNavigate();
  const { permissions } = useUserPermissions();
  const [toast, setToast] = useState(false);
  const { plan_name ,editor } = permissions || {};
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [token, setToken] = useState(user.token);
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const printContentRef = useRef(); // Reference to the content to print
  const [isPrinting, setIsPrinting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); 
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 1;
  const urendataArray = Object.entries(urendata);
  // Pagination calculation
  const totalPages = Math.ceil(urendataArray.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginated = urendataArray.slice(startIndex, startIndex + itemsPerPage);
  const employeeOptions = urendataArray.map(([employerId, employerData], index) => ({
    label: employerData.employeeName || `${('Employeer')} ${index + 1}`,
    value: index + 1,
  }));
  const firstEmployee = paginated[0]?.[1]?.employeeName || '';
  const firstEmployeEemail = paginated[0]?.[1]?.email || '';
 
  const [recipients, setRecipients] = useState(() => {
    // Check if paginated array has at least one item to prevent undefined access
    const signatureImge =  'client';
   const paraaf = 'client';
   const stemp = 'client';
    return [
      {
        recipientName: firstEmployee,
        recipientEmail: firstEmployeEemail, 
        recipientMsg: "",
        recipientroll: "true",
        recipientExpire: "",
        color: '#2463d1',
        recipientId: generateUniqueId(),
        signatureImge : signatureImge,
        paraaf : paraaf,
        stemp  :stemp,
        isOwner : 'client',
      }
    ];
  });

  const handlePostToPDF = async () => {
    setIsLoading(true);
    const input = document.getElementById('table-to-pdf'); // Target your table
    const originalFileName = `${firstEmployee}-work-statement.pdf`;
    const canvas = await html2canvas(input);
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 190; // Adjust width to fit A4 page
        const pageHeight = 297; // A4 page height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        pdf.addImage(imgData, 'PNG', 10, position + 10, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 10, position + 10, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
      
  const pdfBlob = pdf.output('blob');
   const uuid = generateUniqueId()
   const token = generateUniqueId()
  
        const formData = new FormData();
        formData.append('files', pdfBlob,originalFileName);
        formData.append("recipients", JSON.stringify(recipients));
        formData.append("userId", userId);
        formData.append("uuid", uuid);
        formData.append("status", 'prepare');
        formData.append("token", token);
        formData.append("fullNames", firstEmployee);
        axios.post(`https://dspmetrics.com/post/stetement?token=${token}&fullNames=${firstEmployee}`, formData)
        .then((response) => {
          if (response.data && response.data.message) {
            toastr.success(response.data.message); 
             navigate(`/api/prepare/${uuid}`);
             setIsLoading(false);
          } else {
            toastr.success(t('Create New Envalop is successfully!'));
            console.log(response.data,'successfully')
            navigate(`/api/prepare/${uuid}`);
            setIsLoading(false);
          }
          
        })
        .catch((error) => {
          setIsLoading(true);
          // Handle any errors that occur during the request
          console.error("Error:", error);
          if (error.response && error.response.data && error.response.data.error) {
            toastr.error(error.response.data.error); // Display error notification with server message
          } else {
            toastr.error(t('Error submitting form. Please try again.')); // Display generic error notification
          }
         
        });
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const handleSelectChange = (e) => {
    const selectedPage = parseInt(e.target.value, 10);
    handlePageChange(selectedPage);
  };

  const handleConvetToPDF = () => {
    const input = document.getElementById('table-to-pdf'); // Target your table
    const originalFileName = `${firstEmployee}-work-statement.pdf`;
    setIsLoading(true);
    // Capture the table as a canvas
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 190; // Adjust width to fit A4 page
        const pageHeight = 297; // A4 page height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
  
        pdf.addImage(imgData, 'PNG', 10, position + 10, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        // Add pages if the content overflows
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 10, position + 10, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
  
        pdf.save(originalFileName); // Save the PDF with a name
        setIsLoading(false);
      })
      .catch((error) => console.error('Error generating PDF:', error));
  };



  const handlePrint = () => {
    const navbar = document.getElementById('nave-tabs');
    if (navbar) navbar.style.display = 'none';
  
    // Create a hidden iframe for printing
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0';
    printFrame.style.height = '0';
    printFrame.style.border = 'none';
    document.body.appendChild(printFrame);
    
    // Access the document of the iframe and write the content to print
    const doc = printFrame.contentDocument || printFrame.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>${company_name}</title>
          <style>
            /* Include any CSS needed for the print layout here */
            body { font-family: Arial, sans-serif; }
            .modal-body { overflow-y: auto; max-height: calc(100vh - 150px); padding-bottom: 60px; }
          </style>
        </head>
        <body>${printContentRef.current.innerHTML}</body>
      </html>
    `);
    doc.close();
  
    // Trigger the print dialog after the iframe content loads
    printFrame.onload = () => {
      printFrame.contentWindow.print();
      // Remove the iframe after printing
      document.body.removeChild(printFrame);
      if (navbar) navbar.style.display = 'flex';
    };
  };
  

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  }
  const calculateTeamTotalHoursAndSalary = ( items = []) => {
    if (!Array.isArray(items) || items.length === 0) {
      return {
        formattedDailyTotals: [],
        formattedDailySalaries: [],
        formattedTotalHours: "0:00",
        formattedTotalSalary: "0.00",
        fullname:'',
      };
    }
  
    let totalMinutes = 0;
    const hourlyRate = 20; // Set your hourly rate here
    let fullname = '';
    // Filter for items with work_status "checkout"
    const validItems = items.filter(item => {
      const hasApprovedAbsence = absence.some(abs => {
        const absenceStartDate = new Date(abs.startdate_absence);
        const absenceEndDate = new Date(abs.enddate_absence);
        const urenDate = new Date(item.uren_date);
        const isSameWorker = String(abs.workerid) === String(item.worker_id);
        const isWithinDateRange = urenDate >= absenceStartDate && urenDate <= absenceEndDate;
  
        return abs.absencestatus === "approved" && isSameWorker && isWithinDateRange;
      });
  
      return item.keuring === "approve"  && !hasApprovedAbsence;
    });
    if (validItems.length > 0) {
      fullname = `${validItems[0].frist_name || ''} ${validItems[0].last_name || ''}`.trim();
    }
    const formattedDailyTotals = validItems.map(item => {
      
      // Parse total_day_hour and pause_uren to calculate effective working minutes
      const [hours, minutes] = (item.total_day_hour || "0:00").split(':').map(Number);
      const dailyMinutes = (isNaN(hours) ? 0 : hours) * 60 + (isNaN(minutes) ? 0 : minutes);
      const breakMinutes = isNaN(parseInt(item.pause_uren)) ? 0 : parseInt(item.pause_uren);
      const effectiveMinutes = dailyMinutes - breakMinutes;
  
      // Calculate salary for effective working time
      const dailySalary = (effectiveMinutes / 60) * hourlyRate;
  
      totalMinutes += effectiveMinutes;
  
      return {
        date: new Date(item.uren_date).toLocaleDateString(),
        hours: `${Math.floor(effectiveMinutes / 60)}:${(effectiveMinutes % 60).toString().padStart(2, '0')}`,
        salary: dailySalary.toFixed(2)
      };
    });
  
    const totalHours = Math.floor(totalMinutes / 60);
    const totalHoursMinutes = totalMinutes % 60;
    const formattedTotalHours = `${totalHours}:${totalHoursMinutes.toString().padStart(2, '0')}`;
    const formattedTotalSalary = ((totalMinutes / 60) * hourlyRate).toFixed(2);
  
    return {
      formattedDailyTotals,
      formattedDailySalaries: formattedDailyTotals.map(d => d.salary),
      formattedTotalHours,
      formattedTotalSalary,
      fullname
    };
  };
  function generateVoiceNumber() {
    // Generate a random 5-digit number
    const randomNumber = Math.floor(100000 + Math.random() * 90000);
    // Concatenate parts to form the voice number
    return `${randomNumber}`;
  }
  const renderSheduleTemp = () => {
    if (!currentDetails) return null;

    return paginated.map(([employerId, employerData]) => {
      const {
        items, teamNames, employeeName, profileImage ,totalWorkerWorkHour, totalWorkerWorkSalary
      } = employerData;
      const { formattedDailyTotals, formattedDailySalaries, formattedTotalHours, formattedTotalSalary ,fullname} = calculateTeamTotalHoursAndSalary( items);
      const uniqueVoiceNumber = useMemo(() => generateVoiceNumber(), [employerId]);
      return  (
          <div key={employerId} id="table-to-pdf">
              {/* Title Section for Each Employer */}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
                  <h6 style={{ margin: 0, fontWeight: 'bold', padding: '5px', textAlign: 'left' }}>
                      {`${('Timesheet Statement')} ${currentDetails.title}`}
                      <br />
                      {employeeName} {uniqueVoiceNumber}
                  </h6>
                  <h6 style={{ margin: 0, fontWeight: 'bold', padding: '5px', textAlign: 'right' }}>
                      {currentDetails.company_name}
                      <br />
                      {`${('Team')}: ${teamNames}`}
                  </h6>
              </div>

              {/* Data Table for Each Employer */}
              <table 
              style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
                  <thead>
                      <tr style={{ color: '#000000', borderBottom: '2px solid #000000', fontWeight: 'bold' }}>
                          <th style={{ padding: '10px', textAlign: 'left' }}>{t('Employee')}</th>
                          <th style={{ padding: '10px', textAlign: 'center' }}>{t('Date')}</th>
                          <th style={{ padding: '10px', textAlign: 'center' }}>{t('Worked Hours')}</th>
                          <th style={{ padding: '10px', textAlign: 'center' }}>{t('Shift')}</th>
                          <th style={{ padding: '10px', textAlign: 'center' }}>{t('Breaks')}</th>
                          <th style={{ padding: '10px', textAlign: 'center' }}>{t('Absence')}</th>
                      </tr>
                  </thead>
                  <tbody>
                  {items
  .map((item, index) => {
    // Find relevant absence details based on worker ID and date range
    const absenceDetails = absence.find(abs => {
      const absenceStartDate = new Date(abs.startdate_absence);
      const absenceEndDate = new Date(abs.enddate_absence);
      const urenDate = new Date(item.uren_date);
      const isSameWorker = String(abs.workerid) === String(item.worker_id);
      const isWithinDateRange = urenDate >= absenceStartDate && urenDate <= absenceEndDate;

      return isSameWorker && isWithinDateRange;
    });

    const isAbsent = !!absenceDetails;
    const absenceStartDate = isAbsent ? absenceDetails.startdate_absence : null;
    const absenceEndDate = isAbsent ? absenceDetails.enddate_absence : null;
    const absenceStatus = isAbsent ? absenceDetails.absencestatus : null;
    const count_as = isAbsent ? absenceDetails.count_as : null;
    const formattedDate = item.uren_date
    ? format(new Date(item.uren_date), DateTimeFormat)
    : 'N/A';
    // Only proceed if `absenceDetails` exists and item is approved
    if ( item.keuring === "approve") {
      return (
        <tr key={index} style={{ borderBottom: '2px solid #000000', backgroundColor: '#ffffff' }}>
          <td style={{ padding: '10px' }}>{item.frist_name} {item.last_name}</td>
          <td style={{ padding: '10px', textAlign: 'center' }}>{formattedDate}</td>
          <td style={{ padding: '10px', textAlign: 'center' }}>{isAbsent ? '00:00' : item.total_day_hour}</td>
          <td style={{ padding: '10px', textAlign: 'center' }}>{`${formatTime(item.start_time_uren)} - ${formatTime(item.eind_time_uren)}`}</td>
          <td style={{ padding: '10px', textAlign: 'center' }}>{item.pause_uren} {t('min')}</td>
          <td style={{ padding: '10px', textAlign: 'center', color: absenceStatus === "approved" ? '#2ecc71' : '#2ecc71' }}>
          { absenceStatus === "approved" ?  count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other') : ''}
          </td>
        
        </tr>
      );
    }


    // Return null if the item doesn't meet the criteria
    return null;
  })
  .filter(Boolean) // Filter out any null entries
}

                      <tr style={{ borderBottom: '2px solid #000000', backgroundColor: '#ffffff' }}>
                          <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'right' }} colSpan="2">{t('Total Worked Hours:')}</td>
                          <td style={{ padding: '10px', textAlign: 'center' }}>{formattedTotalHours}</td>
                      </tr>
                  </tbody>
              </table>

              {/* Additional Info Table */}
              <div style={{ margin: '40px 0' }}></div>
              <table style={{ width: '100%', borderCollapse: 'collapse', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
                  <thead>
                      <tr style={{ color: '#000000', borderBottom: '2px solid #000000', fontWeight: 'bold' }}>
                          <th style={{ padding: '10px', textAlign: 'left' }}>{t('Hours Worked')}</th>
                          <th style={{ padding: '10px', textAlign: 'center' }}>{t('Absence')}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr style={{ backgroundColor: '#ffffff' }}>
                          <td style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold', fontSize: '12px' }}>{t('Contract')}:</td>
                          <td style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}>{t('Used')}:</td>
                      </tr>
                      <tr style={{ backgroundColor: '#ffffff' }}>
                          <td style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold', fontSize: '12px' }}>{t('Worked')}:</td>
                          <td style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}>{t('Time off Remaining')}:</td>
                      </tr>
                      <tr style={{ backgroundColor: '#ffffff' }}>
                          <td style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold', fontSize: '12px' }}>{t('Pay surcharge')}:</td>
                          <td style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}></td>
                      </tr>
                      <tr style={{ backgroundColor: '#ffffff' }}>
                          <td style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold', fontSize: '12px' }}>{t('Time surcharge')}:</td>
                          <td style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}></td>
                      </tr>
                  </tbody>
              </table>

              {/* Footer Section */}
              <div style={{ margin: '150px 0' }}></div>
              <table style={{ width: '100%', borderCollapse: 'collapse', fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
                  <tbody>
                      <tr style={{ color: '#000000', borderBottom: '2px solid #000000', fontWeight: 'bold' }}>
                          <td style={{ padding: '20px 10px', textAlign: 'left', fontWeight: 'bold', width: '50%' }}>
                              <div style={{ marginTop: '10px', borderBottom: '1px solid #333', width: '90%', height: '1px', marginBottom: '5px' }}></div>
                              <div style={{ fontSize: '12px', textAlign: 'left', color: '#666' }}>{t('Date')}</div>
                          </td>
                          <td style={{ padding: '20px 10px', textAlign: 'left', fontWeight: 'bold', width: '50%' }}>
                              <div style={{ marginTop: '10px', borderBottom: '1px solid #333', width: '90%', height: '1px', marginBottom: '5px' }}></div>
                              <div style={{ fontSize: '12px', textAlign: 'left', color: '#666' }}>{t('Signature')}</div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      );
  });
};

const toggleToast = () => {
  setToast(!toast);
};
  
  return (
    <React.Fragment>
         <Modal isOpen={toast}>
    <ModalHeader toggle={toggleToast}>
      {t('Upgrade your plan')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
      <Modal size="lg" isOpen={show} toggle={handleClose} centered style={{ bottom: '0' }} scrollable>
        <div className="modal-header">
          <h5 className="modal-title mt-0">{t('Print Schedule')}</h5>
          <button onClick={handleClose} type="button" className="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {/* Scrollable modal body */}
        <div className="modal-body"   ref={printContentRef} style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 150px)', paddingBottom: '60px' }}>
        
        {isLoading  && (
        <div className="spinner-overlay-modal">
        <div className="spinner-modal"></div>
      </div>
      )}
          {renderSheduleTemp()}
        </div>

        {/* Fixed footer outside of scrollable content */}
        <div className="modal-footer d-flex justify-content-between align-items-center fixed-footer">
          <button type="button" className="btn btn-primary" onClick={handlePrint}>
          {t('Print')} 
          </button>
          

{isEditorAllowed && (
  <>
    {plan_name === "starter"  ? (
      // If "starter" plan and the client limit is reached, show the "Show Plan" button
      <button onClick={toggleToast} type="button" className="btn btn-success">
     {t('Go to Signing')}  
    </button>
    ) : (
      // Otherwise, show the "Add Collaborator" button
      <button type="button" className="btn btn-success" onClick={handlePostToPDF}>
      {t('Go to Signing')} 
    </button>
    )}
  </>
)}
       
          <button type="button" className="btn btn-danger" onClick={handleConvetToPDF}>
          {t('Convert to PDF')}  
          </button>
          <Row>
      <Col xs="4">
        {t('Showing')} {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, urendataArray.length)} {t('of')} {urendataArray.length}
      </Col>
      <Col xs="5">
        <div className="d-flex justify-content-end">
          {/* Select Dropdown for Employee Names */}
          <Input
            type="select"
            value={currentPage}
            onChange={handleSelectChange}
            className="me-2"
            style={{ width: '200px' }}
          >
            {employeeOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        </div>
      </Col>
      <Col xs="3">
        <div className="d-flex justify-content-end">

          {/* Previous and Next Buttons */}
          <Button
            type="button"
            color="primary"
            size="sm"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <i className="fa fa-chevron-left" />
          </Button>
          <Button
            type="button"
            color="primary"
            size="sm"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <i className="fa fa-chevron-right" />
          </Button>
        </div>
      </Col>
    </Row>
        </div>
      </Modal>

      <style>
        {`
          .fixed-footer {
            position: sticky;
            bottom: 0;
            background: white;
            width: 100%;
            box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
            z-index: 10;
          }
          .modal-body {
            padding-bottom: 70px; /* Space for the fixed footer */
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(PrintTimesheetModal));
