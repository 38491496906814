import React, { useRef, useState, useEffect,useMemo  } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardTitle,
  InputGroup,
} from "reactstrap";
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  getShift,
  postShift,
  putShift,
  deleteShift,
  getUren,
  postUren,
  putUren,
  removeUren,
  getContractEmployer,
  GetSurcharger,
} from "store/actions";
import Select from "react-select"
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
function generateDarkColorHex() {
  // Helper function to generate a random number between min and max (inclusive)
  function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Generate dark color components by limiting the range to 0-127 (lower half of the 0-255 range)
  const red = getRandomInt(0, 127);
  const green = getRandomInt(0, 127);
  const blue = getRandomInt(0, 127);

  // Convert each component to a 2-digit hex string and concatenate them
  const hexColor = '#' +
      red.toString(16).padStart(2, '0') +
      green.toString(16).padStart(2, '0') +
      blue.toString(16).padStart(2, '0');

  return hexColor;
}
const ShiftModal = ({t, show, handleClose}) => {
  const { permissions } = useUserPermissions();

  const { plan_name } = permissions || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );

  const {
    client,
    xlsid,
    contractdata,
    postDeps,
    postTeams,
    postContractTypes,
    shift,
    shiftdata,
    putshift,
    removeshift,
    urendata,
    posturen,
    puturen,
    removeuren,
    contractemployer,
    getsurcharger,
    error
  } = useSelector(state => ({
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    contractdata: state.getClientData.contractdata,
    postDeps: state.getClientData.postDeps,
    postTeams: state.getClientData.postTeams,
    postContractTypes: state.getClientData.postContractTypes,
    removeuren: state.getShiftData.removeuren,
    shift: state.getShiftData.shift,
    shiftdata: state.getShiftData.shiftdata,
    putshift: state.getShiftData.putshift,
    removeshift: state.getShiftData.removeshift,
    urendata: state.getShiftData.urendata,
    posturen: state.getShiftData.posturen,
    puturen: state.getShiftData.puturen,
    getsurcharger: state.getShiftData.getsurcharger,
    error: state.getShiftData.error,
  }));
  //meta title xlsid
  useEffect(() => {
    dispatch(onGetcollaborator(account_id));
    dispatch(getXlsdata(account_id));
    dispatch(GetSurcharger(account_id));
  }, [dispatch,account_id]);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [newTeamName, setNewTeamName] = useState('');
  const [isNewShiftName, setisNewShiftName] = useState('');
  const [isCreatingNewTeam, setIsCreatingNewTeam] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const departmentLimits = {
    starter: 1,
    professional: 6,
    enterprise: Infinity, // Assuming enterprise has no limit
  };

  const TeamLimits = {
    starter: 5,
    professional: Infinity,
    enterprise: Infinity, // Assuming enterprise has no limit
  };
  const [toast, setToast] = useState(false);
  const toggleToast = () => {
    setToast(!toast);
};
  const maxDepartments = departmentLimits[plan_name] || 1;
const maxTeams = TeamLimits[plan_name] || 5;
  const [errors, setErrors] = useState({});
  const [shiftDataObject, setShiftData] = useState({
    shift_accound_id: account_id,
    depart_id: null,
    team_id_shift: null,
    start_time_shift: '',//2024-05-31
    eind_time_shift: '', // 2025-05-31
    shift_color: generateDarkColorHex(),
    discription_shift: null,
    shift_name: null,
    bonus_percent:0,
  });

  useEffect(() => {
    // Introduce a 2-second delay before setting the options
    const timer = setTimeout(() => {
      if (Array.isArray(xlsid) && xlsid.length > 0) {
        // Create a Set to track seen department IDs
        const seenDepartments = new Set();
        const uniqueDepartment = xlsid.filter(dept => {
          if (!seenDepartments.has(dept.departments_id)) {
            seenDepartments.add(dept.departments_id);
            return true;
          }
          return false;
        });
  
        const options = [
          // Map unique departments to options
          ...uniqueDepartment.map((dept, index) => ({
            value: dept.departments_id,
            label: dept.dep_name,
            default: index === 0, // Set the first option as default
          })),
          // Add the "Create New Department" option
          ...(uniqueDepartment.length < maxDepartments
            ? [{ value: 'new', label: t('Create New Department') }]
            : [{ value: 'over', label: t('Department limit reached!') }]),
        ];
  
        setDepartmentOptions(options);
        const defaultOption = options.find(opt => opt.default) || options[0];
        if (defaultOption) {
          setSelectedDepartment(defaultOption);
          setSelectedDepartmentId(defaultOption.value);
          setShiftData(prev => ({
            ...prev,
            depart_id: defaultOption.value,
          }));
        }
      } 
    }, 2000); // 2000 milliseconds = 2 seconds
  
    return () => clearTimeout(timer);
  }, [xlsid, maxDepartments, t]);
  
  useEffect(() => {
    if (selectedDepartmentId) {
      // Introduce a 1-second delay before setting the options
        if (Array.isArray(xlsid) && xlsid.length > 0) {
          // Filter the teams by the selected department ID
          const filteredTeams = xlsid.filter(dept => dept.dep_id === selectedDepartmentId);
  
          // Create a Set to track unique team names to avoid duplication
          const seenTeams = new Set();
          const uniqueTeams = filteredTeams.filter(team => {
            if (!seenTeams.has(team.team_id)) {
              seenTeams.add(team.team_id);
              return true;
            }
            return false;
          });

          if (uniqueTeams.length > 0) {
            setShiftData(prev => ({
              ...prev,
              team_id_shift: uniqueTeams[0].team_id,
            }));
            setSelectedTeam({value: uniqueTeams[0].team_id,label: uniqueTeams[0].team_name} );
          }
          // Map the unique teams to the options array
          const options = [
            ...(uniqueTeams.length > 0
              ? uniqueTeams.map(team => ({
                  value: team.team_id,
                  label: team.team_name,
                }))
              : [{ value: 'new', label: t('Create New Team') }]),
            ...(uniqueTeams.length < maxTeams
              ? [{ value: 'new', label: t('Create New Team') }]
              : [{ value: 'over', label: t('Team limit reached!') }]),
          ];
  
          // Set the filtered and unique team options
          setTeamOptions(options);
        } else {
          setTeamOptions([]);
        }
     
    } else {
      setTeamOptions([]); // Clear team options if no department is selected
    }
  }, [selectedDepartmentId, xlsid]);
  


  const handleDepartmentChange = (selectedOption) => {
    if (selectedOption.value === 'new') {
      // User chose to create a new department
      setIsCreatingNew(true);
      setSelectedDepartment(null); // Clear the selection
      setSelectedDepartmentId(null); // Clear the department ID
      setShiftData({
        ...shiftDataObject,
        depart_id: null,
      });
    }else if (selectedOption.value === 'over') {
      toggleToast()
    } else {
      // User selected an existing department
      setSelectedDepartment(selectedOption);
      setSelectedDepartmentId(selectedOption.value); // Set the selected department ID
      setIsCreatingNew(false);
      setShiftData({
        ...shiftDataObject,
        depart_id: selectedOption.value,
      });
    }
  };
  const handleNewDepartmentChange = (e) => {
    setNewDepartmentName(e.target.value);
   
  };

  const handleAddNewDepartment = () => {
    if (newDepartmentName.trim()) {
      const isDuplicate = departmentOptions.some(option => option.value === newDepartmentName);
  
      if (!isDuplicate) {
        const newOption = { value: newDepartmentName, label: newDepartmentName };
        setDepartmentOptions(prevOptions => [...prevOptions, newOption]);
        setSelectedDepartment(newOption);
        setNewDepartmentName('');
        setIsCreatingNew(false);
  
        const data = {
          account_id: account_id,
          dep_name: newDepartmentName,
        };
        
       dispatch(postDep(data));
      } else {
        alert(t('This department already exists.'));
      }
    }
  };
  
  const handleTeamChange = (selectedOption) => {
    if (selectedOption.value === 'new') {
      setIsCreatingNewTeam(true);
      setSelectedTeam(null);
      setShiftData({
        ...shiftDataObject,
        team_id_shift: null,
      });
    } else if (selectedOption.value === 'over') {
      toggleToast()
    } else {
      setSelectedTeam(selectedOption);
      setIsCreatingNewTeam(false);
      setShiftData({
        ...shiftDataObject,
        team_id_shift: selectedOption.value,
      });
    }
  };

  const handleisNewShiftNameChange = (e) => {
    setisNewShiftName(e.target.value);
    setShiftData(prevData => ({
      ...prevData,
      shift_name: e.target.value,
    }));
  };
  const handleStartTimeChange = (selectedDates) => {
    const newStartDate = selectedDates[0]; // Flatpickr passes an array, use the first date
    setShiftData(prevData => ({
      ...prevData,
      start_time_shift: newStartDate ? newStartDate.toTimeString().slice(0, 5) : "", // Format as "HH:mm"
    }));
  };
  
  const handleEndTimeChange = (selectedDates) => {
    const newEndDate = selectedDates[0]; // Flatpickr passes an array, use the first date
    setShiftData(prevData => ({
      ...prevData,
      eind_time_shift: newEndDate ? newEndDate.toTimeString().slice(0, 5) : "", // Format as "HH:mm"
    }));
  };
  
  const handleNotesChange = (e) => {
    setShiftData(prevData => ({
      ...prevData,
      discription_shift: e.target.value,
    }));
  }

  const handleBonusChange = (e) => {
    const bonusPercent = e.target.value;
    setShiftData(prevData => ({
      ...prevData,
      bonus_percent: bonusPercent,
    }));
  };
  const handleNewTeamChange = (e) => {
    setNewTeamName(e.target.value);
  };

  const handleAddNewTeam = () => {
    if (newTeamName.trim()) {
      const isDuplicate = teamOptions.some(option => option.value === newTeamName);
  
      if (!isDuplicate) {
        const newOption = { value: newTeamName, label: newTeamName };
        setTeamOptions(prevOptions => [...prevOptions, newOption]);
        setSelectedTeam(newOption);
        setNewTeamName('');
        setIsCreatingNewTeam(false);
  
        const data = {
          account_id: account_id,
          team_name: newTeamName,
          dep_id: selectedDepartmentId, // Use the selected department ID
        };
  
        dispatch(postTeam(data));
      } else {
        toastr.error(t('This team already exists.')); // Use toastr for notifications
      }
    } else {
      toastr.error(t('Please enter a valid team name.')); // Use toastr for notifications
    }
  };

 
  const [isCreatingNew, setIsCreatingNew] = useState(false);


  const validateForm = () => {
    const newErrors = {};

    if (!shiftDataObject.shift_accound_id) newErrors.shift_accound_id = t('Account ID is required.');
    if (!shiftDataObject.depart_id) newErrors.depart_id = t('Department is required.');
    if (!shiftDataObject.start_time_shift) newErrors.start_time_shift = t('Start date is required.');
    if (!shiftDataObject.eind_time_shift) newErrors.eind_time_shift = t('End date is required.');
     if (!shiftDataObject.team_id_shift) newErrors.team_id_shift = t('Team is required.');
    if (!shiftDataObject.shift_name) newErrors.shift_name = t('Shift name is required.');
    if (!shiftDataObject.shift_color) newErrors.days = t('Shift color is required.');
    if (!shiftDataObject.discription_shift) newErrors.discription_shift = t('Discription is required.');
    
    
    
    return newErrors // Returns true if there are no errors
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    
    const validationErrors = validateForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toastr.error(error));
      // Proceed with form submission or further logic
    } else {
      
    dispatch(postShift(shiftDataObject));
    }
  };


  useEffect(() => {
    if (shiftdata && shiftdata.success) {
      toastr.success(t('The Shift Data has been successfully submitted.'));
      dispatch(getXlsdata(account_id));
    }else if (shiftdata && shiftdata.error) {
      toastr.error(t('Oops! The Shift Data wasn’t submitted successfully.'));
    }
  }, [shiftdata, dispatch, account_id]);
  const groupSurchargeData = (data) => {
    const groupedData = {};
  
    data.forEach((item) => {
      const {
        title_sur,
        token_sur,
        day_sur,
        start_time_sur,
        end_time_sur,
        paid_surcharge_id,
        time_surcharge_id,
        create_time_sur,
        update_time_sur,
      } = item;
  
      if (!groupedData[title_sur]) {
        groupedData[title_sur] = {
          title_sur,
          token_sur,
          create_time: create_time_sur || new Date().toISOString(),
          update_time: update_time_sur || new Date().toISOString(),
          days: [],
        };
      }
  
      groupedData[title_sur].days.push({
        day_sur,
        start_time_sur,
        end_time_sur,
        paid_surcharge_id,
        time_surcharge_id,
      });
    });
  
    return groupedData;
  };

  const groupedSurcharger = groupSurchargeData(getsurcharger); 
  return (
    <React.Fragment>
       <Modal isOpen={toast}>
    <ModalHeader toggle={toggleToast}>
      {t('Upgrade your plan')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
       <Modal
                      size="lg"
                      isOpen={show} 
                      toggle={handleClose} centered
                      style={{ bottom: '0' }}
                      scrollable={true}
                    >
                      <div className="modal-header" >
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Assign Shift')}
                        </h5>
                        <button
                        onClick={handleClose}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {show && (  
 <div className="modal-body">
  <form onSubmit={handleSubmit}>
  <div className="row">
    <div className="col-lg-12">
     <Row>
     <Col lg="6">
      <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-3 col-form-label">
        {t('Department')}
        </label>
        <div className="col-md-7" id="select_dep">
        <Select
           id="depart_id"
           name="depart_id"
           options={departmentOptions}
           placeholder={t('Select Department')}
           onChange={handleDepartmentChange}
           value={selectedDepartment}
           
          />
        </div>
      </div>
      {isCreatingNew && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-3 col-form-label">
            
          </label>
          <div className="col-md-7">
            <input
               type="text"
               id="new_department"
               className="form-control"
               value={newDepartmentName}
               onChange={handleNewDepartmentChange}
               placeholder={t('Enter new department name')}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewDepartment}
            >
               {t('Add Department')}
            </button>
          </div>
        </div>
      )}

 
      </Col>
      <Col lg="6">
      <div>
      <div className="mb-3 row">
        <label htmlFor="svc_team" className="col-md-2 col-form-label">
        {t('Team')}
        </label>
        <div className="col-md-7" id="select_team">
        <Select
       id="team_id"
       name="team_id"
       options={teamOptions}
       placeholder={t('Select Team')}
       onChange={handleTeamChange}
       value={selectedTeam}
       isDisabled={!selectedDepartmentId} 
        
        />
        </div>
      </div>
      {isCreatingNewTeam  && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-3 col-form-label">
           
          </label>
          <div className="col-md-7">
            <input
             type="text"
             id="new_team"
             className="form-control"
             value={newTeamName}
             onChange={handleNewTeamChange}
             placeholder={t('Enter new team name')}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewTeam}
            >
              {t('Add Team')}
            </button>
          </div>
        </div>
      )}

      </div>
      </Col>
    
      <Col lg="6">
      <div>
      <div className="mb-3 row">
        <label htmlFor="contract_type" className="col-md-3 col-form-label">
        {t('Shift')}
        </label>
        <div className="col-md-9" id="contract_type">
        <Input className="form-control col-md-8"
         value={isNewShiftName}
          onChange={handleisNewShiftNameChange} 
         type="text" 
         placeholder={t("Type Shift Name")}
          />
        </div>
      </div>

      </div>
       </Col>
       <Col lg="4">
      <div>
      <div className="mb-3 row">
        <label htmlFor="Bouns" className="col-md-3 col-form-label">
        {t('Surcharge')}
        </label> 
        <div className="col-md-9" id="Shift">
        <select
      className="form-control"
      id="Bouns"
      onChange={handleBonusChange}
  >
    <option value="">{t('Select Surcharge')} %</option>
    {Object.keys(groupedSurcharger).map((key) => (
      <option key={groupedSurcharger[key].token} value={groupedSurcharger[key].token}>
        {groupedSurcharger[key].title}
      </option>
    ))}
  </select>
  

        </div>
     
      </div>

      </div>
       </Col>
       <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="start_time" className="col-md-4 col-form-label">
        {t('Start Time & End Time')}
        </label>
        <div className="col-lg-4">
          <div className="mb-3">
          <InputGroup>
          <Flatpickr
            id="start_time"
            className="form-control d-block"
            placeholder={t("Select time")}
            onChange={handleStartTimeChange}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
          />
</InputGroup>
      
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-3">

          <InputGroup>
          <Flatpickr
            id="end_time"
            className="form-control d-block"
            placeholder={t("Select time")}
            onChange={handleEndTimeChange}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
          />
</InputGroup>
  
          </div>
        </div>
      </div>
       </Col>


      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              
              id="description"
              className="form-control"
              rows="3"
              onChange={handleNotesChange}
            ></textarea>
          
        </div>
      </div>
      </Col>

      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light" onClick={handleClose}>
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>
</div>

                      )}
                     
                    </Modal>

    </React.Fragment>
  );
};
export default withTranslation()(withRouter(ShiftModal));

