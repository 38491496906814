import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Row,
    Col,
  } from "reactstrap";
  import {
    helpCenterData,
  } from "../../common/data";
  import Breadcrumbs from "../../components/Common/Breadcrumb";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import TawkToWidget from "components/LiveChat";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import { Link ,useLocation } from "react-router-dom";
import Cookies from 'js-cookie'; 
import SearchHelp from './searchHelp';
const ContentHelp = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language
  let activeChildMenuData = null;
  let activeSubMenu = null; // This will store the active sub menu
  let activeMainMenu = null;
  let childTextContent = null;
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();
  helpCenterData.forEach((menuItem) => {
    menuItem.mainMenu.subMenu?.forEach((subMenu) => {
      const foundChildMenu = subMenu.childMenu?.find(
        (child) => `/help-center/resolve/${child.url}` === location.pathname // Match child menu with the current URL parameter
      );
      if (foundChildMenu) {
        activeChildMenuData = foundChildMenu;
        activeSubMenu = subMenu;
        activeMainMenu = menuItem.mainMenu;
        
        foundChildMenu.textContent.forEach(text => {

          childTextContent = text
        });
      }

    });
  });

  if (!activeChildMenuData) {
    subMenu.childMenus?.forEach((child) => {
        const foundTextContent = child.textContent?.find(
            (text) => `/help-center/resolve/${child.url}` === location.pathname
        );
        if (foundTextContent) {
            activeChildMenuData = child;
            activeSubMenu = subMenu;
            activeMainMenu = menuItem.mainMenu;
          
        }
    });
}


    useEffect(() => {
    
        const headerColor = document.getElementById('header_color');
        
        // Check if the element exists before accessing it
        if (headerColor) {
          headerColor.style.backgroundColor = '#f0f8ff';
        }
        const hasSubmittedRating = Cookies.get(`user_rating_submitted_${activeChildMenuData.id}`);
        if (hasSubmittedRating) {
          setSubmitted(true); // Hide the rating buttons if the cookie exists
        }
      
      }, [activeChildMenuData.id])
    
      const handleRatingSubmit = async (rating,articleId,columnName) => {
        const object ={
          articleId:articleId,
          columnName:columnName,
          condition:'contex_id ',
          tableName:'help_center_context',
          value:rating,
        }

        
        try {
          const response = await fetch('/api/rate-article', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object), // Send articleId and rating
          });
    
          if (response.ok) {
            Cookies.set(`user_rating_submitted_${articleId}`, 'true', { expires: 1 });
            setSubmitted(true);
          } else {
            console.error('Failed to submit rating');
          }
        } catch (error) {
          console.error('Error submitting rating:', error);
        }
      };
    
      
      useEffect(() => {
      
        // Function to update meta tags
        const updateMetaTags = () => {
          document.title = t(`${childTextContent.seo_description} - ${childTextContent.title} | Shiftspec.com`);
          const metaElement = document.querySelector('meta[name="description"]');
          if (metaElement) {
            metaElement.setAttribute('content', 'Updated meta description content.');
          } else {
            addOrUpdateMeta('description', 'This is the new meta description.');
          }
          addOrUpdateMeta('keywords', 'HTML, CSS, JavaScript, Web Development');
          function addOrUpdateMeta(name, content) {
            // Check if a meta tag with the given name already exists
            let metaTag = document.querySelector(`meta[name="${name}"]`);
            
            if (metaTag) {
              // If the meta tag exists, update its content
              metaTag.setAttribute('content', content);
            } else {
              // If the meta tag doesn't exist, create it
              metaTag = document.createElement('meta');
              metaTag.setAttribute('name', name);
              metaTag.setAttribute('content', content);
              document.head.appendChild(metaTag); // Append it to <head>
            }
          }
          
          // Array of meta tags to add/update
          const metaTags = [
            { name: 'description', content: 'This is a sample description for the page.' },
            { name: 'keywords', content: 'HTML, CSS, JavaScript' },
            { name: 'author', content: 'John Doe' }
          ];
          
          // Iterate over the metaTags array and add/update each meta tag
          metaTags.forEach(tag => {
            addOrUpdateMeta(tag.name, tag.content);
          });
          // Helper function to add or update meta tags
          const addOrUpdateMetaTag = (property, content) => {
            let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
            if (tag) {
              tag.setAttribute('content', content);
            } else {
              tag = document.createElement('meta');
              tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
              tag.setAttribute('content', content);
              document.head.appendChild(tag);
            }
          };
    
          // Open Graph Meta Tags
          addOrUpdateMetaTag('og:title', childTextContent?.title || 'Help Center');
          addOrUpdateMetaTag('og:description', childTextContent?.seo_description || 'Find helpful articles to get started with ShiftSpec');
          addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
          addOrUpdateMetaTag('og:type', 'website');
    
          // Twitter Meta Tags
          addOrUpdateMetaTag('twitter:title', childTextContent?.title || 'Help Center');
          addOrUpdateMetaTag('twitter:description', childTextContent?.seo_description || 'Find helpful articles to get started with ShiftSpec');
          addOrUpdateMetaTag('twitter:card', 'summary_large_image');
        };
    
        // Call the function to update meta tags
        updateMetaTags();
    
      }, [activeChildMenuData, location.pathname, t,childTextContent]);
  return (
    <React.Fragment>
  <header style={styles.header}>
      <nav style={styles.nav}>
        <ul style={styles.menu}>
          <li>
            <Link to="/" style={styles.link}>
            {t('Go to shiftspec.com')} 
            </Link>
          </li>
          <li>
            <Link to="/help-center" style={styles.link}>
            {t('Help Center')}
            </Link>
          </li>
          <li style={styles.languageSelector}>
          <LanguageDropdown /> 
          </li>
        </ul>
      </nav>
    </header>
  
    <section  style={{padding:'50px', background: '#fafff0' }}>
      <Row>
        <Col sm={12} className="d-flex align-items-center justify-content-center">

        <SearchHelp props={props} />
        </Col>
      </Row>
    </section>


    <main className=" bg-white" style={{padding:'10px'}}>
    <Breadcrumbs title={t('Help Center')} breadcrumbItemLink={`/help-center`} breadcrumbItem={t(activeMainMenu.name)} />
    <Row>
    <Col sm={4} className="bg-white p-3">
  <div className='kb-sidebar'>
  <div className='kb-category-menu'>
  <ul>
  {helpCenterData.map((menuItem, index) => {
  
    if (menuItem.mainMenu && menuItem.mainMenu.name && menuItem.mainMenu.lang === currentLanguage) { // Check for valid main menu
      const isActive =
      location.pathname === `/help-center/resolve/${menuItem.mainMenu.url}` || // Check if current path matches main menu URL
      menuItem.mainMenu.subMenu?.some(subMenu =>
        subMenu.childMenu?.some(child => `/help-center/resolve/${child.url}` === location.pathname) // Check if current path matches any child menu URL
      );

      const activeSubMenu = menuItem.mainMenu.subMenu?.find((subMenu) =>
        subMenu.childMenu?.find(
          (child) => `/help-center/resolve/${child.url}` === location.pathname
        )
      );

      // Find the active child menu within the active submenu
      const activeChildMenu = activeSubMenu?.childMenu?.find(
        (child) => `/help-center/resolve/${child.url}` === location.pathname
      );

      // Get the active submenu and child menu IDs
      const activeSubMenuId = activeSubMenu ? activeSubMenu.id : null;
      const activeChildMenuId = activeChildMenu ? activeChildMenu.id : null;      
      return (
        <li key={menuItem.mainMenu.id} className='open'>
          <a href={`/help-center/${menuItem.mainMenu.url}`} className='font-bold'>
            <div className='kb-category-menu-option'>
              <span className='kb-category-menu-option__color-bar font-bold' style={{ backgroundColor: '#2d3e50' }}>
                {menuItem.mainMenu.name} {/* Render main menu name */}
              </span>
            </div>
            <div>
              <svg width="12" height="7" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6817 1.6816l-4.5364 4-4.5364-3.9315" stroke="#2d3e50" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
          </a>

          {/* Render Submenus */}
          {isActive && menuItem.mainMenu.subMenu && Array.isArray(menuItem.mainMenu.subMenu) && menuItem.mainMenu.subMenu.length > 0 && (
   
   <ul>
   {menuItem.mainMenu.subMenu.map(sub => (
    
    <li key={sub.id} style={{ backgroundColor: activeSubMenuId === sub.id ? '#eeeeee' : 'transparent' }}>
       <a href={`#${sub.url}`}>
         <span className='kb-category-menu-option__color-bar' style={{ backgroundColor: '#2d3e50' }}>
           {sub.name} {/* Render submenu name */}
         </span>
       </a>
     </li>
   ))}
 </ul>
)}
        </li>
      );
    }
    return null;
  })}
</ul>
   
    </div>
  </div>

    </Col>
    <Col sm={8}>
    <div >
    <h1 className='font-bold'style={{fontSize:'26px'}}>{activeChildMenuData.name}</h1>
    <h2 className="kb-index__category-description">
    {activeChildMenuData.description}
    </h2>
    </div>
    <hr style={styles.hrline}></hr>
    <div className='kb-category-menu-option'>
    <span className=' kb-category-menu-option__color-bar '
     style={{backgroundColor:'#2d3e50',fontSize:'18px'}}
     >
   {activeChildMenuData.textContent && activeChildMenuData.textContent.length > 0 ? (
            activeChildMenuData.textContent.map((text, index) => (
              <div
               style={{ ...styles.textContent, maxWidth: '100%', overflow: 'hidden' }}  
               key={index} dangerouslySetInnerHTML={{ __html: text.content }} />  
            ))
          ) : (
            <p>{t('No content available.')}</p>
          )}
    </span>
</div>

<hr style={styles.hrline}></hr>
{!submitted && (
<div className='flex' >
<div style={{ ...styles.kafeadbackquastion, position: 'relative', top: '7px' }}>

    <span className="ka-feedback-label-light" style={styles.kafeadbackquastiontext}>{t('Was this article helpful?')}</span>
  </div>
  <div className="ka-feedback-rating-buttons" style={styles.kafeadbackquastion}>
    <button  onClick={() => handleRatingSubmit('+1',activeChildMenuData.id,'helpful_count')} type="submit"style={styles.kafeadbackquastionbtn}  className="ka-feedback-rating-button" name="rating" value="1">
      <span style={styles.kafeadbackquastionrating}>{t('Yes')}</span>
    </button>
    <button  onClick={() => handleRatingSubmit('-1',activeChildMenuData.id,'not_helpful_count')} type="submit" style={styles.kafeadbackquastionbtn} className="ka-feedback-rating-button" name="rating" value="0">
      <span style={styles.kafeadbackquastionrating}>{t('No')}</span>
    </button>
  </div>
</div>
   )}
{submitted && <p>{t('Thank you for your feedback!')}</p>}
    </Col>
  </Row>
</main>

<TawkToWidget />
    </React.Fragment>
  );
};

const styles = {
  textContent: {
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
 
   },
  kafeadbackquastion: {
   display:'inline-block',
   marginLeft:'8px',

  },
  kafeadbackquastiontext: {
    color: 'black',
    fontSize:'14px',
    fontWeight:'inherit'
  },
  kafeadbackquastionbtn: {
    height: '32px',
    border: '1px solid #cbd6e2',
    borderRadius: '3px',
    backgroundColor: '#eaf0f6',
    color: '#425b76',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '12px',
    textAlign: 'center',
    width: '90px',
    margin:'0px 8px 0px 0px'
  },
  kafeadbackquastionrating: {
    fontFamily: 'Helvetica !important',
    fontSize: '12px !important',
    lineHeight: '14px',
  },
  kafeadbackquastionratingquestion: {
    fontFamily: 'Helvetica !important',
    fontSize: '12px !important',
    lineHeight: '14px',
  },
  header: {
    background: '#f1f8fa',
    padding: '10px 20px',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '70px', // Set a fixed height for the header
  },
  nav: {
    display: 'flex',
    justifyContent: 'center', // Center content horizontally within the nav
    alignItems: 'center', // Align vertically
    width: '100%', // Ensure nav takes full width
    maxWidth: '1200px', // Max width for larger screens
  },
  menu: {
    display: 'flex',
    justifyContent: 'center', // Center the menu items
    alignItems: 'center', // Align items vertically
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  link: {
    textDecoration: 'none',
    color: '#333',
    marginRight: '20px',
    fontSize: '16px',
  },
  languageSelector: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center', // Ensure the dropdown is vertically centered
  },
  select: {
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  form: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '600px', // Adjust the width of the search bar as per your need
    },
    input: {
      flex: 1,
      padding: '10px 15px',
      fontSize: '16px',
      borderRadius: '4px 0 0 4px',
      border: '1px solid #ccc',
      outline: 'none',
    },
    button: {
      padding: '13px 20px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '0 4px 4px 0',
      cursor: 'pointer',
    },
    contentContainer: {
      margin: '0 auto !important',
      float: 'none !important',
    },
    hrline: {
      paddingBottom: '1.3125rem',
  marginBottom: '1.75rem',
  marginTop: '.375rem',
  borderBottom: '1px solid #DFE3EB',
    },

    ulsub: {
      paddingBottom: '1.3125rem',
  marginBottom: '1.75rem',
  marginTop: '.375rem',
  borderBottom: '1px solid #DFE3EB',
    },
};

ContentHelp.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withRouter(ContentHelp));