import React, { useState } from "react";
import "./css.css";

const EditableField = ({ label, content, onSave, type = "text", options = [] }) => {
  const [value, setValue] = useState(
    type === "date" && content ? new Date(content).toISOString().split("T")[0] : content || "" // Format date for `date` input
  );

  const handleSave = (newValue) => {
    if (newValue !== content) {
      onSave(newValue);
    }
  };

  return (
    <div className="editable-container">
      <label>{label}: </label>
      {type === "select" ? (
        <select
          defaultValue={value}
          onChange={(e) => {
            const newValue = e.target.value;
            setValue(newValue); // Update local state
            handleSave(newValue); // Immediately post the change
          }}
          className="editable-select"
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === "date" ? (
        <input
          type="date"
          defaultValue={value}
          onChange={(e) => {
            const newValue = e.target.value;
            setValue(newValue); // Update local state
            handleSave(newValue); // Immediately post the change
          }}
          className="editable-input"
        />
      ) : (
        <>
          <input
            type={type}
            defaultValue={value}
            onChange={(e) => setValue(e.target.value)}
            className="editable-input"
          />
          <button
            type="button"
            onClick={() => handleSave(value)}
            className="post-icon"
            title="Post changes"
          >
            <i className="mdi mdi-send" aria-hidden="true"></i>
          </button>
        </>
      )}
    </div>
  );
};

export default EditableField;
