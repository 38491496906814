import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";

import { map } from "lodash";
import { withTranslation } from "react-i18next";

import {
  getMailsLists as onGetMailsLists,
  getSelectedMails as onGetSelectedMails,
  updateMail as onUpdateMail,
  createNewDoc,
  createNewDocSuccess,
  createNewDocFail,
  createfolder,
  selectFolders,
  updatestatus,
  updatefolderAction,
  resendmail,
  updatepostfolder,
  setvalid,
  createfolderFail,
  deletepostquery,
  deletepermantlyquery,
  recoveryquery
} from "store/actions";

import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";

const EmailInbox = (props) => {
  const { t, permissions } = props;
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [token, setToken] = useState(user.account_token);
  const {
    mailslists,
    selectedmails,
    newfolder,
    folders,
    updatefolder,
    resend,
    postid,
    updatepostfolderdata,
    validdata,
    deletepost,
    permantly,
    revovery,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    selectedmails: state.mails.selectedmails,
    newfolder: state.mails.newfolder,
    folders: state.mails.folders,
    updatefolder: state.mails.updatefolder,
    resend: state.mails.resend,
    postid: state.mails.postid,
    updatepostfolderdata: state.mails.updatepostfolderdata,
    validdata: state.mails.validdata,
    deletepost: state.mails.deletepost,
    permantly: state.mails.permantly,
    revovery: state.mails.revovery,
    error: state.mails.error,
  }));
 
  useEffect(() => {
    document.title = t("Document Inbox - Securely Manage Your Files Online | Shiftspec.com");
  }, [t]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items per page
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('inbox');
  // Filtered and paginated data state
  const [filteredData, setFilteredData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [activeTab, setactiveTab] = useState(0);
  const [modal, setmodal] = useState(false);
  const [Movemodal, setMovemodal] = useState(false);
  const [Voidmodal, setViodmodal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentOpenDropdownId, setCurrentOpenDropdownId] = useState(null);
  const [showArrayData, setShowArrayData] = useState([]);
  const [showArrayTrashData, setShowArrayTrashData] = useState([]);
   
  const [isInputVisible, setInputVisible] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [recipientExpire, setRecipientExpire] = useState('')
  const [selectedData, setSelectedData] = useState({ recipient_data: [], doc_id: '' });
  const [recipientjson, setRecipientData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({ folder: '', id: null });
  const [moveFolder, setmoveFolder] = useState('inbox');
  const [errorMSG, setError] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [editedFolderName, setEditedFolderName] = useState('');

  useEffect(() => {
    dispatch(selectFolders(account_id));
  }, [dispatch, account_id]);
  function tog_center() {
    setmodal(!modal);
    removeBodyCss();
  }
  const toggleMoveModal = () => {
    setMovemodal(!Movemodal);
    removeBodyCss();
  };

  function tog_centerViod() {
    setViodmodal(!Voidmodal);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }  

  const toggleDropdown = (postId) => {
    if (currentOpenDropdownId === postId) {
      setDropdownOpen(!dropdownOpen);
    } else {
      setDropdownOpen(true);
      setCurrentOpenDropdownId(postId);
    }
  };
 
  useEffect(() => {
    dispatch(onGetMailsLists(account_id));
  }, [dispatch]);

  const startNow = async (event) => {
    try {
      const uuid = uuidv4();
      var data = { 
        uuid:uuid,
        token:token,
        userId:userId
       };

      // Dispatch the action to initiate the post request
      await dispatch(createNewDoc(data));
      toastr.success(t('Create New Envalop is successfully!'));
      navigate(`/api/create-new/doc/${uuid}`);
      // Additional logic or state updates after success
      
    } catch (error) {
      toastr.error(t('The request timed out while creating the envelope. Please try again.'));
      // Additional logic or state updates after failure
    }
  };


  const handleFolderClick = (e) => {
    setInputVisible(!isInputVisible);
  };
  
  const handleCreateFolder = (e) => {
    const { value } = e.target;

    // Regular expression to allow only alphanumeric characters and spaces
    const isValid = /^[a-zA-Z0-9\s]*$/.test(value);

    if (value === '') {
      setError(t('Folder name cannot be empty.'));
    } else if (!isValid) {
      setError(t('Folder name can only contain letters, numbers, and spaces.'));
    } else if (value.length > 20) {
      setError(t('Folder name cannot be more than 20 characters long.'));
    } else {
      setError('');
      setFolderName(value);
    }
  };


    const handleAddNewFollder = async(e) => {
      if (folderName === '') {
        setError(t('Folder name cannot be empty.'));
        return;
      }
      var data = { 
        token:uuidv4(),
        account_id:account_id,
        name:folderName
       };

      dispatch(createfolder(data));
      
    }
    
  
    
    useEffect(() => {
      // Filter mailslists where folder is not 'trash'
      const filteredData = mailslists.filter(item => item.folder !== 'trash');
     
      setFilteredData(filteredData); // Initialize with filtered data
      setTotalItems(filteredData.length); // Total items count based on filtered data
    }, [mailslists]);

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to handle search input change
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    filterData(e.target.value, selectedFolder);
  };
  const handleFolderChange = (folder) => {
    setSelectedFolder(folder);
    filterData(searchTerm, folder);
  };
  

  // Function to filter data based on search term
  const filterData = (searchTerm, folder) => {
   
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = mailslists.filter(item => 
      (folder === 'inbox' || item.folder.toLowerCase() === folder) &&
      (
        item.full_name.toLowerCase().includes(lowercasedFilter) ||
        item.email.toLowerCase().includes(lowercasedFilter) ||
        item.post_status.toLowerCase().includes(lowercasedFilter) ||
        item.orginal_name.toLowerCase().includes(lowercasedFilter)
      )
    );

    setFilteredData(filteredData);
    setTotalItems(filteredData.length); // Update total items count
    setCurrentPage(1); // Reset to first page after filtering
  };
  

  // Function to paginate data
  const paginate = (data, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return data.slice(startIndex, startIndex + pageSize);
  };

  // Paginate the filtered data
  const paginatedData = paginate(filteredData, currentPage, itemsPerPage);
 
  const completeSvg = `
   <span role="img" aria-label="Completed" className="respTable-noColumns table_cell-icon icon">
   <span className="css-zf56fy33">
       <span aria-hidden="true" className="SVGInline">
           <svg className="SVGInline-svg" fill="#008938" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
<path d="M16 3.41 14.58 2l-8.92 8.92-4.25-4.24L0 8.09l5.66 5.66z"></path>
</svg>
</span>
  `
  const sendingSvg = `
<span role="img" aria-label="Completed" className="respTable-noColumns table_cell-icon icon">
   <span className="css-zf56fy33">
       <span aria-hidden="true" className="SVGInline">
       <svg className="SVGInline-svg" fill="#868686" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
       <path d="M11.477 3.109a6.002 6.002 0 0 0-8.368 8.368l8.368-8.368zM4.523 12.89a6.002 6.002 0 0 0 8.368-8.368L4.523 12.89zm-2.18.766A8 8 0 1 1 13.657 2.343 8 8 0 0 1 2.343 13.657z"></path>
     </svg>
</span>
  `
  const signedSvg = `
   <span role="img" aria-label="Completed" className="respTable-noColumns table_cell-icon icon">
   <span className="css-zf56fy33">
       <span aria-hidden="true" className="SVGInline">
       <svg className="SVGInline-svg" fill="#868686" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
       <path d="m11 8 2 2-5 5-3 1 1-3 5-5zm1-1 1.29-1.29a1 1 0 0 1 1.42 0l.58.58a1.01 1.01 0 0 1 0 1.42L14 9l-2-2zm-1-2V2H2v9h3l-2 2h-.997A2 2 0 0 1 0 10.997V2.003A2 2 0 0 1 2.001 0h8.988c1.105 0 2.002.894 2.003 2.003v1.004L11 5z"></path>
     </svg>
</span>
  `
  const getStatusIcon = (status) => {
    switch (status) {
      case 'complete':
        return completeSvg;
      case 'sending':
        return sendingSvg;
      case 'signed':
        return signedSvg;
      default:
        return '';
    }
  };
  function formatDate(date) {
    // Convert the date to a Date object if it's not already one
    const d = new Date(date);
    if (isNaN(d)) {
      // Handle invalid date
      return t('Invalid date');
    }
  
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const getButtonStyle = (status) => {
    let style = {};
    switch (status) {
      case 'complete':
        style = { backgroundColor: '#f9f9f9', color: '#1e1e1e' }; // Green with white text
        break;
      case 'sending':
        style = { backgroundColor: '#2463d1', color: '#ffffff' }; // Amber with white text
        break;
      case 'signed':
        style = { backgroundColor: '#239933', color: '#ffffff' }; // Light Blue with white text
        break;
      default:
        style = { backgroundColor: '#E0E0E0', color: '#000' }; // Grey with black text
    }
    return style;
  };
  const getButtonText = (status) => {
    let text;
    switch (status) {
      case 'complete':
        text = t('DOWNLOAD'); // Green
        break;
      case 'sending':
        text = t('PREVIEW'); // Amber 
        break;
      case 'signed':
        text = t('SIGNED'); // Light Blue
        break;
      default:
        text = t('Pendings'); // Grey
    }
    return text;
  };
  const getStatusText = (status) => {
    let text;
    switch (status) {
      case 'complete':
        text = t('Signature Complete'); // Green
        break;
      case 'sending':
        text = t('Pending Signatures'); // Amber 
        break;
      case 'signed':
        text = t('Waiting for Co-Signers'); // Light Blue
        break;
      default:
        text = t('In Progress'); // Grey
    }
    return text;
  };
  const handleButtonClick = (status,doc_name) => {
    switch (status) {
      case 'complete':
        handleComplete(doc_name); // Call function to handle 'complete' status
        break;
      case 'sending':
        handlePreview(doc_name); // Call function to handle 'sending' status
        break;
      case 'signed':
        handleSigned(); // Call function to handle 'signed' status
        break;
      default:
        // Handle default case if needed
        break;
    }
  };

  const handleComplete = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error(t('Invalid URL'));
    }
  };

  const handlePreview = (url) => {
    if (url) {
      // Open the URL in a new tab
      window.open(url, '_blank');
    } else {
      console.error(t('Invalid URL'));
    }
  };

  const handleSigned = () => {

  };
  
  const getRatingStar = (signed) => {
    const signedArray = signed.split(','); // Assuming 'signed' is a comma-separated string

    // Initialize an array to store JSX elements
    const stars = [];

    for (let i = 0; i < signedArray.length; i++) {
      const rat = signedArray[i].trim(); // Trim to handle any whitespace

      // Limit to 9 stars (as per your original logic)
      if (i < 9) {
        // Add star elements based on 'true' or 'false'
        if (rat === 'true') {
          stars.push(<div key={i} className="flex backColor"></div>);
        } else if (rat === 'false') {
          stars.push(<div key={i} className="flex backColorB"></div>);
        }
      } else {
        // Add a plus sign if more than 9 elements
        stars.push(<div key={i} className="flex fonts">+</div>);
        break; // Exit the loop after adding the '+' sign
      }
    }

    return stars; // Return the array of JSX elements
  };
  function getExpirationDate(recipientData) {
    if (!recipientData.recipientExpire) {
      return ''; // Return nothing if recipientExpire is empty
    }
  
    const expireDate = new Date(recipientData.recipientExpire);
    const currentDate = new Date();
    
    // Calculate the difference in time
    const timeDiff = expireDate.getTime() - currentDate.getTime();
    
    // Convert time difference from milliseconds to days
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const title = t('Valid:') 
    // Return how many days until expiration
    return dayDiff > 0 
    ? `${title} ${dayDiff} ${t('day(s)')}` 
    : t('Expired');
  }
  const getExpiredDate = (recipientData,email) => {
    const recipients = JSON.parse(recipientData);
    let response
    if (recipients !== null) {
      recipients.forEach(data => {
        if (data.recipientEmail === email) {
         response = getExpirationDate(data)
        }
        
      });
    }
    return response
  }


  const handleResend = (data) => {
    const object = {
      email:data.email,
      fullname:data.full_name,
      sign_id :data.unique_id,
      Messeges:data.msg,
      status:data.post_status,
      id:data.post_sign_id
      };
    dispatch( resendmail(object))
    // Add your logic for Resend action here postdata
  };


  const handleMove = (data) => {
    setSelectedValue({ folder:data.folder, id: data.post_sign_id  });
    setMovemodal(true);

  };
  const handleSelectChange = (e) => {
    const selectedValues = e.target.value;
    setmoveFolder((prevMoveFolder) => {
      // Example of updating based on previous state
      return selectedValues; // Update moveFolder to the selected value
    });
  };

  
  const handleMoveSubmit = (name,id) => {
    
     const updateTodo = {
      id: id,
      name: name
    };
   dispatch(updatepostfolder(updateTodo));
    // Add your logic to handle the move action
    toggleMoveModal();
  }


  const handleVoid = (data) =>  {
    
    dispatch(updatestatus(data.post_sign_id ));
    
  };
 
  const handleInputChange = (e) => {
    setRecipientExpire(e.target.value);
  };


  const handleSubmit = async () => {
    const { recipient_data,doc_id  } = selectedData;
    const jsondata = JSON.parse(recipient_data);

    // Update the recipient data with the new data before setting it in the state
    setRecipientData(jsondata);

    // Use functional form of setState to ensure you get the latest state
    setRecipientData((prevRecipientData) => {
      const updatedRecipientData = prevRecipientData.map((recipient) => {
        return {
          ...recipient,
          recipientExpire: recipientExpire.trim() !== "" ? recipientExpire : recipient.recipientExpire
        };
      });

      // Now you can make the API call with the updated data
      const data = {
        recipientExpire,
        selectedData: JSON.stringify(updatedRecipientData),
        doc_id:doc_id
      };
    dispatch(setvalid(data))
      return updatedRecipientData;
    });
  };
  
  const handleSetValidDate = (data) =>  {
    setSelectedData({ recipient_data: data.recipient_data, doc_id: data.doc_id });
    setmodal(true);
  };
  

//showarray, setShowarrayData
const handleShowClick = (postId) => {
  // Check if postId is already in showArrayData
  const index = showArrayData.indexOf(postId);
  if (index === -1) {
    // If not found, add postId to showArrayData
    setShowArrayData([...showArrayData, postId]);
  } else {
    // If found, remove postId from showArrayData
    setShowArrayData(showArrayData.filter(id => id !== postId));
  }
};

const handleShowTrashClick = (postId) => {
  // Check if postId is already in showArrayData
  const index = showArrayTrashData.indexOf(postId);
  if (index === -1) { 
    // If not found, add postId to showArrayData
    setShowArrayTrashData([...showArrayTrashData, postId]);
  } else {
    // If found, remove postId from showArrayData
    setShowArrayTrashData(showArrayTrashData.filter(id => id !== postId));
  }
};
const handleRemove = (id) => {
  const data = {
    id: [id] // Convert single ID to an array if it's not already
  };
 dispatch(deletepostquery(data))

  // Add your logic for Remove action here
};

const handleRecovery = (id) => {
  const data = {
    id: [id] // Convert single ID to an array if it's not already
  };
 dispatch(recoveryquery(data))
  // Add your logic for Remove action here
};


const handleDeleteClick = () => {
  const data = {
    id: showArrayData // assuming showArrayData is an array of IDs you want to delete
  };
 dispatch(deletepostquery(data))
  setShowArrayData([]);
};

const handlePermanentlyClick = () => {
  const data = {
    id: showArrayTrashData // assuming showArrayData is an array of IDs you want to permanently delete
  };
  dispatch(deletepermantlyquery(data));
  setShowArrayData([]);
};

const handleFolderFilterClick = (folderName) => {
  // Assuming searchTerm is available in the component state or as a prop
  filterData(searchTerm, folderName.toLowerCase());
};
const handleEditClick = (folderId, currentName) => {

  setEditIndex(folderId); // Set the index of the folder being edited
  setEditedFolderName(currentName); // Set the current folder name as initial value for editing
};

const handleUpdateFolderChange = (e) => {
  setEditedFolderName(e.target.value); // Update the edited folder name as user types
};

const handleUpdateSubmit = (folderId) => {
    // Regular expression to allow only alphanumeric characters and spaces
    const isValid = /^[a-zA-Z0-9\s]*$/.test(editedFolderName);
  
    if (editedFolderName === '') {
      setError(t('Folder name cannot be empty.'));
    } else if (!isValid) {
      setError(t('Folder name can only contain letters, numbers, and spaces.'));
    } else if (editedFolderName.length > 20) {
      setError(t('Folder name cannot be more than 20 characters long.'));
    } else {
      setError('');
      setEditedFolderName(editedFolderName);
    }
    const obj = {
      name:editedFolderName,
      folderid:folderId,
    }

  dispatch(updatefolderAction(obj));
  
  // After handling submission, reset edit state
  setEditIndex(-1);
};


useEffect(() => {
  const depSuccessHandler = (message) => {
    toastr.success(t(message));
    setEditedFolderName('');
    dispatch(onGetMailsLists(account_id));
    dispatch(selectFolders(account_id));
  };
  
  const depErrorHandler = (message) => {
    toastr.error(t(message));
  };

  if (updatefolder) {
    updatefolder.success 
      ? depSuccessHandler('Folder updated successfully! All changes have been saved.') 
      : depErrorHandler('Failed to update the folder. Please try again later.');
  }
  if (permantly) {
    permantly.success 
      ? depSuccessHandler('The item has been permanently deleted.') 
      : depErrorHandler('Failed to The item  permanently deleted.');
  }
  if (deletepost) {
    deletepost.success 
      ? depSuccessHandler('Envelope deleted successfully! All associated data has been removed.')
      : depErrorHandler('Failed to delete the envelope. Please try again later.');
  }
  if (validdata) {
    validdata.success 
      ? depSuccessHandler('Validation successful! All inputs meet the required criteria.')
      : depErrorHandler('Validation completed! All fields are correctly filled.');
  }

  if (revovery) {
    revovery.success 
      ? depSuccessHandler('Recovery successful! Your data has been restored.')
      : depErrorHandler('Recovery failed. Please try again or contact support for assistance.');
  }

  if (postid) {
    postid.success 
      ? depSuccessHandler('The status of the envelope has been successfully updated.') 
      : depErrorHandler('Failed to status updated. Please check the details and try again.');
  }
  if (updatepostfolderdata) {
    updatepostfolderdata.success 
      ? depSuccessHandler('Folder name updated successfully! The changes have been saved.') 
      : depErrorHandler('Failed to update folder name. Please verify the details and try again.');
  }
  if (resend) {
    resend.success 
      ? depSuccessHandler('Resend successful! The recipient has been notified.') 
      : depErrorHandler('Failed to resend the message. Verify the details and try again.');
  }
  if (newfolder) {
    newfolder.success 
      ? depSuccessHandler('Folder created and ready for use.') 
      : depErrorHandler('Failed to create new folder. Please check the folder name and try again.');
  }
}, [updatefolder, permantly,deletepost,account_id,revovery,validdata,postid,updatepostfolderdata,resend,newfolder]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title= {t('Manage')} breadcrumbItemLink="/home" breadcrumbItem={t('Back To Home')} />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
             
                <Link to={"#"}>
                <Button color="Primira" onClick={() => startNow()} className=" btn  olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">               
                {t('Start New Envelope')}
                </Button>
                </Link>
                <Link to={"#"}>
                {isEditorAllowed && (
        <Button
          color="Primary" 
          style={{ backgroundColor: '#239933' }}
          onClick={handleFolderClick}
          className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn"
        >
          <svg
            className="SVGInline-svg"
            style={{ position: 'relative', right: '10px' }}
            width="14px"
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            focusable="false"
          >
            <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
          </svg>
          <span
            data-qa="submit-username-text"
            className="css-1ixbp0l d-none d-md-block"
          >
            {t('Create New Folder')}
            
          </span>
        </Button>
      )}
                </Link>
                {isInputVisible && (
          <div>
        <div className="input-text-box-wrapper css-1eq84o">
          <Input 
            placeholder="Create New Folder..." 
            className="css-1ez4hss" 
            style={{ textAlign: 'center', fontSize: '11px' }} 
            type="text" 
            onChange={handleCreateFolder} 
            value={folderName}
          />
                <Button color="Primira" style={{backgroundColor:'#343434',width:'25px',borderRadius:'initial'}} onClick={handleAddNewFollder}   className=" btn  ">               
              <svg className="SVGInline-svg" style={{position:'relative', right:'6px',bottom:'5px'}}  width="14px" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
              <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
              </svg>
                </Button>
                  </div>
       {errorMSG && <div style={{ color: 'red',fontSize:'10px' }}>{errorMSG}</div>}

       </div>
        
      )}
                
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0,
                        })}
                        onClick={() => {
                          setactiveTab(0);
                          handleFolderChange('inbox')
                        }}
                      >
                        <i className="mdi mdi-email-outline me-2"></i>  {t('Inbox')}{" "}
                        <span className="ml-1 float-end">({totalItems})</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        onClick={() => {
                          setactiveTab(2);
                          handleFolderChange('draft')
                        }}
                      >
                        <i className="mdi mdi-file-outline me-2"></i>{t('Draft')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 4,
                        })}
                        onClick={() => {
                          setactiveTab(4);
                          handleFolderChange('trash');
                        }}
                      >
                        <i className="mdi mdi-trash-can-outline me-2"></i>{t('Trash')}
                      </NavLink>
                    </NavItem>
                
                  </Nav>
                </div>

               
                <h6 className="mt-4">{t('Folder')}</h6>
                <div className="mail-list mt-1">

      
            
                {folders.map((folder) => (
        <div key={folder.folder_id}>
           {editIndex === folder.folder_id ? (
            <>
              <div className="input-text-box-wrapper css-1eq84o">
             
             
              <Input
                className="css-1ez4hss" 
                style={{ textAlign: 'center', fontSize: '11px' }} 
                type="text" 
                value={editedFolderName}
                onChange={handleUpdateFolderChange}
                autoFocus 
                onBlur={() => handleUpdateSubmit(folder.folder_id)} // Handle submission on blur
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleUpdateSubmit(folder.folder_id); // Handle submission on Enter key press
                }}
              />
               
               <Button
                color="Primira"
                style={{ backgroundColor: '#343434', width: '25px', borderRadius: 'initial' }}
                onClick={() => handleUpdateSubmit(folder.folder_id)}
                className="btn"
              >
                <svg
                  className="SVGInline-svg"
                  style={{ position: 'relative', right: '6px', bottom: '5px' }}
                  width="14px"
                  fill="#ffffff"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  focusable="false"
                >
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </Button>
              </div>
            </>
          ) : (

            <div>
  {editor === 1 && (
            <Link
              to="#"
              onClick={() => handleFolderFilterClick(folder.folder_name)}
              style={{ textDecoration: 'none' }}
            >
              <span
                className="mdi mdi-pencil-box text-info float-end"
                onClick={() => handleEditClick(folder.folder_id, folder.folder_name)}
                style={{ cursor: editor === 1 ? 'pointer' : 'not-allowed' }}
              >
                {t('Edit')}
              </span>
              {folder.folder_name}
            </Link>
          )}
          {/* If editor is not 1, just display folder name */}
          {editor !== 1 && (
            <div>
              {folder.folder_name}
            </div>
          )}
            </div>
            
          )}
        </div>
      ))}
                  {errorMSG && <div style={{ color: 'red',fontSize:'10px' }}>{errorMSG}</div>}
                  
                </div>
                <Nav>
     
                </Nav>
              </Card>

              <Modal
                isOpen={modal}
                autoFocus={true}
                centered={true}
                toggle={() => {
                  tog_center(!modal);
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      tog_center(!modal);
                    }}
                  >
                    {t('Configure Valid Date')}
                  </ModalHeader>
                  <ModalBody>
                  <div className="css-1e0bg48 css-1443df4">
            
                     
                  <Input
                   name="recipientExpire" 
                  id="AddExpireTime" 
                  type="datetime-local"
                  className="form-control"
                  value={recipientExpire}
                  onChange={handleInputChange}
                    />
                  </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        tog_center(!modal);
                      }}
                    >
                      {t('Close')}
                    </Button>
                    <Button type="button" color="primary" onClick={handleSubmit}>
                    {t('Send')} <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                  </ModalFooter>
                </div>
              </Modal>

              <Modal
                isOpen={Movemodal}
                autoFocus={true}
                centered={true}
                toggle={toggleMoveModal}
              >
                <div className="modal-content">
                  <ModalHeader
                   toggle={toggleMoveModal}
                  >
                    {t('Move To')}
                  </ModalHeader>
                  <ModalBody>
                  <div className="css-q10huv">
                  <div className="css-hboir5">
                      <select
                       className="css-12ihcxq"
                       value={moveFolder}
                       onChange={handleSelectChange}
                       >
                      
                         <option value="inbox">{t('Select Inbox')}</option> 
                          <option  value="discard">{t('Select Draft')}</option>
                          {folders.map((folder) => (
                            <option  key={folder.folder_id} value={folder.folder_name}>{t('Select')} {folder.folder_name}</option>
                          ))}
                      
                      </select>
                      <span className="css-1baizyq">
                          <span className="css-lzaifp">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                                  <path d="M4 5h8l-4 6z"></path>
                              </svg>
                          </span>
                      </span>
                  </div>
              </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="secondary"
                      toggle={toggleMoveModal}
                    >
                      {t('Close')}
                    </Button>
                    <Button type="button" color="primary"
                     onClick={() =>
                     
                      handleMoveSubmit(
                        moveFolder,
                        selectedValue.id
                      )
          
                      }
                  
                     >
                       {t('Send')} <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                  </ModalFooter>
                </div>
              </Modal>

              <Modal
                isOpen={Voidmodal}
                autoFocus={true}
                centered={true}
                toggle={() => {
                  setViodmodal(!Voidmodal);
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      setViodmodal(!Voidmodal);
                    }}
                  >
                   {t('Change Status')}
                  </ModalHeader>
                  <ModalBody>
                  <div className="css-1e0bg48 css-1443df4">
            
                     
                  <Input
                   name="recipientExpire" 
                  id="AddExpireTime" 
                  type="datetime-local"
                  className="form-control"
                  value={recipientExpire}
                  onChange={handleInputChange}
                    />
                  </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        setViodmodal(!Voidmodal);
                      }}
                    >
                       {t('Close')}
                    </Button>
                    <Button type="button" color="primary" onClick={handleSubmit}>
                    {t('Send')} <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                  </ModalFooter>
                </div>
              </Modal>
              <div className="email-rightbar mb-3">
                <Card>
                {mailslists.length > 0 ?
                    <>
                <div className="inbox-container">
                  <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
                  <div className="listHeader css-jcpkb3">
                  <div className="listHeader_titleRow" >
                                <h1 className="listHeader_heading m-bottom-xx-small" >{t('Envelope')}</h1>
                               
                                <div className="listHeader_filter">
                                    <div className="cheshire-templates" >
                                        <div className="m-left-large cheshire_visibleContent cheshire_visible-active" >
                                            <span className="templates_filterBtn u-inline-block" >
                                                <div className="listHeader_search">
                                                    <div className="css-0">
                                                        <Label for="KRkDwy3Kp3-sdfdsf" className="css-5gs0ys"></Label>
                                                    </div>
                                                    <div  className="css-k008qs">
                                                        <div className="input-text-box-wrapper css-1eq84o" >
                                                            <div  className="css-1nenmzo">
                                                                <span  className="css-zf56fy">
                                                                    <span aria-hidden="true" className="SVGInline">
                                                                        <svg className="SVGInline-svg" fill="#868686" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                            <path d="M10.926 9.426 16 14.5 14.5 16l-5.074-5.074a6 6 0 1 1 1.5-1.5zM6 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
                          </svg>
                        </span>
                    </span>
                </div> 
                
                <Input 
                id="KRkDwy3Kp3-sdfdsf"
                 placeholder={t('Search Inbox and Folders')}
                  className="css-1ez4hss" 
                   type="text" 
                   value={searchTerm}
                   onChange={handleSearchChange} 
                   />
            </div>
        </div>
    </div>
    <button aria-haspopup="true" aria-expanded="false" data-qa="manage-envelopes-header-form-menu" className="olv-button olv-ignore-transform css-qhnch2" type="button">
        <span data-qa="manage-envelopes-header-form-menu-icon" className="css-1pig3b7">
            <span className="css-zf56fy">
                <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                            <path d="M4.063 12H0v1h4.063a2 2 0 0 0 3.874 0H16v-1H7.937a2 2 0 0 0-3.874 0zm6-5H0v1h10.063a2 2 0 0 0 3.874 0H16V7h-2.063a2 2 0 0 0-3.874 0zm-8-5H0v1h2.063a2 2 0 0 0 3.874 0H16V2H5.937a2 2 0 0 0-3.874 0z"></path>
                          </svg>
                        </span>
                    </span>
                </span>
                <span aria-hidden="true" data-qa="manage-envelopes-header-form-menu-text" className="css-16hz1ch">{t('Filters')}</span>
                <span data-qa="manage-envelopes-header-form-menu-accessibility-text" className="css-1pdgtej">{t('Edit Filters')}</span>
            </button>
        </span>
    </div>
</div>
</div>
</div>
                  </div>
                  </div>
 

         <div className="css-1obf64m">
         <table className="css-1u2njnl ">
        <thead className="css-1s8spa1">
          <tr>
            <th className="css-17bqg1m">
            <div className="btn-group me-2 mb-2 mb-sm-0" id="deletebtn">
            {showArrayData.length > 0 && (
        <Button type="button" onClick={handleDeleteClick} className="btn btn-danger waves-light waves-effect">
          <i className="far fa-trash-alt"></i> 
        </Button>
      )}
           {showArrayTrashData.length > 0 && (
        <Button type="button" onClick={handlePermanentlyClick} className="btn btn-danger waves-light waves-effect">
          <i className="far fa-trash-alt"></i> 
        </Button>
      )}
              </div>                    
            </th>
            <th className="css-17bqg1m">
            <span className="css-3div8j">{t('Icon')}</span>
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Subject')}</span>
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Status')}</span>
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Last Change')}</span> 
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Folder')} </span> 
            </th>
            <th className="css-17bqg1m">
            <span className="css-11jaa7e">{t('Actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          
        {paginatedData.length > 0 ?
                    <>
        {map(paginatedData, (mail, index) => (
        <tr  className="css-cir4u5" key={index}>
  <td data-delegate="ignore" className="css-85irbq"> 
  <div className="css-1mwciyk">
  <div className="css-1e0bg48">
    <Input
    type="checkbox"
    
    onChange={() => mail.folder === 'trash' ? handleShowTrashClick(mail.post_sign_id) : handleShowClick(mail.post_sign_id)}
    className="css-chekcsa32332"
    />

  </div>
</div>

</td>
<td  className="css-85irbq">
  <div className="css-1mwciyk"  dangerouslySetInnerHTML={{ __html: getStatusIcon(mail.post_status) }}>
 

</div>
</td>
<td className="css-85irbq ">
<span className="table_copy-line-1 table_copy-link css-rpuriw"  >
<div className="u-ellipsis ">{t('File')}: {mail.orginal_name}</div>
<div className="u-ellipsis">{t('Email')}: {mail.email}</div>
</span>
<span className="table_copy-line-2">
<span>
<span>{t('Name')}: {mail.full_name}</span>
</span>
</span>
</td>
<td className="css-85irbq">
<span className="table_copy-line-1 table_copy-link css-rpuriw" >
<div className="capitalize"> {getStatusText(mail.post_status)}</div>
</span>
<div>
<span className="flex">
{getRatingStar(mail.issigned)}
</span>
</div>
</td>
<td className="css-85irbq">
<span className="table_date">{formatDate(mail.update_time)}</span>
<p>{getExpiredDate(mail.recipient_data,mail.email)}</p>
</td>
<td className="css-85irbq capitalize">
<span className="capitalize">{mail.folder}</span>

</td>
<td data-delegate="ignore" className="css-85irbq capitalize">
<span className="css-79elbk">
<span className="css-1mw9iin">

<button  onClick={() => handleButtonClick(mail.post_status,mail.doc_name)} className="olv-button olv-ignore-transform css-lzi6zi" style={getButtonStyle(mail.post_status)} type="button" >
<span className="css-16hz1ch" >{getButtonText(mail.post_status)}</span>
</button>
<Dropdown isOpen={dropdownOpen && mail.post_sign_id === currentOpenDropdownId} toggle={() => toggleDropdown(mail.post_sign_id)}>
<DropdownToggle className="olv-button olv-ignore-transform css-dqnnaa dropdown-toggle" caret>
                <span className="css-1hz9l7">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" aria-hidden="true" fill="currentColor" focusable="false">
                    <path d="M4 5h8l-4 6z" />
                  </svg>
                </span>
</DropdownToggle>

<DropdownMenu>
        {isEditorAllowed && (
          <>
          <DropdownItem onClick={() => handleResend(mail)}>{t('Resend')}</DropdownItem>
            <DropdownItem onClick={() => handleMove(mail)}>{t('Move')}</DropdownItem>
            <DropdownItem onClick={() => handleVoid(mail)}>{t('Void')}</DropdownItem>
            <DropdownItem onClick={() => handleSetValidDate(mail)}>{t('Set Valid Date')}</DropdownItem>
            <DropdownItem onClick={() => handleRemove(mail.post_sign_id)}>{t('Remove')}</DropdownItem>
          </>
        )}
        {!isEditorAllowed && (
          <DropdownItem disabled>{t('You do not have permission to perform these actions')}</DropdownItem>
        )}
      </DropdownMenu>
            </Dropdown>
</span>

</span>

</td>
</tr>
  ))}
</>
:  <tr className="css-cir4u5"> 
<td className="css-85irbq" style={{width:"100%"}}>
  <div className="align-items-center text-center p-4">
    <i className="mdi mdi-email-outline me-2 display-5"></i>
    <h4>{t('No Record Found')}</h4>
  </div>
</td>
</tr>
}
        </tbody>
      </table>
         </div>
                </div>
                </>
                    : <div className="align-items-center text-center p-4"> <i className="mdi mdi-email-outline me-2 display-5"></i> <h4>{t('No Record Found')} </h4>
                    </div>}
                </Card>
               
                {mailslists.length > 0 &&
                  <Row>
                    <Col xs="7">{t('Showing')} {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, totalItems)} {t('of')} {totalItems}</Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }
              
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(withRouter(EmailInbox));