import React, { useRef, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DraggableList from './DraggableList';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    InputGroup,
    UncontrolledCollapse
  } from "reactstrap";
  import classnames from "classnames";
  import Flatpickr from "react-flatpickr";
  import "flatpickr/dist/themes/material_blue.css";
  import Slider from "react-rangeslider"
  import "react-rangeslider/lib/index.css"
  import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
 function SettingCalendar({ 
  t,
  targetid,
   onDateSelected,
   datafontfamily,
   datafontsize,
   datafontformat,
   datafontcolor,
   datatype,
   datasignPng,
   datastamp,
   dataparaaf,
   recipientNames,
   dataroll,
   dataqrcodevalue,
   dataqrqrcodedisplay,
   dataqrcodeborder,
   dataqrcodesize,
   dataqrcodefgcolor,
   dataqrcodebgcolor,
   datadeletebtn,
   datacopybtn
   }) {
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col5, setcol5] = useState(true);
    const [col6, setcol6] = useState(true);
    const [col7, setcol7] = useState(true);
  
    const [linkqr, setlinqr] = useState(true);
    const [qrsize, setqrsize] = useState(false);
    const [background, setbackground] = useState(false);
    const [borderstayle, setborderstayle] = useState(false);


    const [col8, setcol8] = useState(true);
    const [col9, setcol9] = useState(true);
    const [col10, setcol10] = useState(false);
    const [col11, setcol11] = useState(false);
    const [selectedFontFamily, setSelectedFontFamily] = useState(datafontfamily);
    const [selectedFontSize, setSelectedFontSize] = useState(datafontsize);
    const [selectedFontFormat, setSelectedFontFormat] = useState(datafontformat);
    const [selectedFontColor, setSelectedFontColor] = useState(datafontcolor);
    const [selectedFormat, setSelectedFormat] = useState('Y-m-d');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [updateSignpng, setUpdateSignpng] = useState(datasignPng);
    const [updateStamp, setUpdateStamp] = useState(datastamp);
    const [updateParaaf, setUpdateParaaf] = useState(dataparaaf);
    const [selectedrecipien, setSelectedrecipien] = useState("");
    const [isChecked, setIsChecked] = useState(dataroll);
    const [sliderValue, setSliderValue] = useState(4);
    const [qrcodeValue, setQrcodeValue] = useState('www.analyticscargo.com');
    const [selectedQrfgColor, setSelectedQrfgColor] = useState(dataqrcodefgcolor);
    const [selectedQrbgColor, setSelectedQrbgColor] = useState(dataqrcodebgcolor);
    const [qrcodeSize, setQrcodeSize] = useState(40);
    const [borderWidth, setBorderWidth] = useState(1);
    const [borderStyle, setBorderStyle] = useState('solid');
    const [borderColor, setBorderColor] = useState('#000');
    const [accordionState, setAccordionState] = useState({
        col1: true,
        col2: false,
      });
      const toggleAccordion = (accordionItem) => {
        setAccordionState((prevState) => ({
          ...Object.keys(prevState).reduce((acc, key) => {
            acc[key] = key === accordionItem ? !prevState[key] : false;
            return acc;
          }, {}),
        }));
      };

    const t_qrlink = () => {
      setlinqr(!linkqr);
      setqrsize(false);
      setbackground(false);
      setborderstayle(false);
    };
    const t_sizeqr = () => {
      setqrsize(!qrsize);
      setlinqr(false);
      setbackground(false);
      setborderstayle(false);
    };
    const t_qrback = () => {
      setbackground(!background);
      setqrsize(false);
      setlinqr(false);
      setborderstayle(false);
    };
    const t_qrborder = () => {
      setborderstayle(!borderstayle);
      setqrsize(false);
      setbackground(false);
      setlinqr(false);
    };
    const t_col1 = () => {
      setcol1(!col1);
      setcol2(false);
      setcol3(false);
    };
  
    const t_col2 = () => {
      setcol2(!col2);
      setcol1(false);
      setcol3(false);
    };
  
    const t_col3 = () => {
      setcol3(!col3);
      setcol1(false);
      setcol2(false);
    };
  
    const t_col5 = () => {
      setcol5(!col5);
    };
  
    const t_col6 = () => {
      setcol6(!col6);
    };
  
    const t_col7 = () => {
      setcol7(!col7);
    };
  
    const t_col8 = () => {
      setcol6(!col6);
      setcol7(!col7);
    };
  
    const t_col9 = () => {
      setcol9(!col9);
      setcol10(false);
      setcol11(false);
    };
  
    const t_col10 = () => {
      setcol10(!col10);
      setcol9(false);
      setcol11(false);
    };
  
    const t_col11 = () => {
      setcol11(!col11);
      setcol10(false);
      setcol9(false);
    };
       
    const itemStyle = {
        display: 'flex',
      };
      const dateFormats = [
        { value: '', label: t('Select a format') },
        { value: 'Y-m-d', label: 'YYYY-m-d' },
        { value: 'd-m-Y', label: 'dd-mm-YYYY' },
        { value: 'm-d-Y', label: 'mm-dd-YYYY' },
        { value: 'd.m.Y', label: 'dd.mm.YYYY' },
        // Add more formats as needed selectedrecipien
      ];
 
      const fontFamilies = [
        { dataQa: 'arial', value: 'arial', label: 'Arial' },
        { dataQa: 'Courier', value: 'Courier', label: 'Courier' },
        { dataQa: 'CourierBold', value: 'CourierBold', label: 'CourierBold' },
        { dataQa: 'CourierBoldOblique', value: 'CourierBoldOblique', label: 'CourierBoldOblique' },
        { dataQa: 'CourierOblique', value: 'CourierOblique', label: 'CourierOblique' },
        { dataQa: 'HelveticaBold', value: 'HelveticaBold', label: 'HelveticaBold' },
        { dataQa: 'Helvetica', value: 'helvetica', label: 'Helvetica' },
        { dataQa: 'HelveticaBoldOblique', value: 'HelveticaBoldOblique', label: 'HelveticaBoldOblique' },
        { dataQa: 'TimesRoman', value: 'TimesRoman', label: 'TimesRoman' },
        { dataQa: 'TimesRomanBold', value: 'TimesRomanBold', label: 'TimesRomanBold' },
        { dataQa: 'TimesRomanItalic', value: 'TimesRomanItalic', label: 'TimesRomanItalic' },
        { dataQa: 'TimesRomanBoldItalic', value: 'TimesRomanBoldItalic', label: 'TimesRomanBoldItalic' },
        { dataQa: 'Symbol', value: 'Symbol', label: 'Symbol' },
        { dataQa: 'ZapfDingbats', value: 'ZapfDingbats', label: 'ZapfDingbats' },
      ];
      const getSVGContent = (format) => {
        switch (format) {
          case 'bold':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="m8.59 6.33.157-.006c.369 0 .674-.09.907-.267.219-.168.33-.45.33-.843 0-.22-.035-.397-.11-.54a.826.826 0 0 0-.288-.313 1.417 1.417 0 0 0-.444-.156 3.36 3.36 0 0 0-.562-.049H6.595V6.33H8.59zm.292 4.534c.203 0 .408-.024.61-.067a1.42 1.42 0 0 0 .486-.202.93.93 0 0 0 .316-.364c.079-.152.117-.351.117-.61 0-.502-.126-.844-.388-1.047-.275-.213-.633-.316-1.097-.316H6.595v2.606h2.287zM4 13V2h5.044c.504 0 .97.046 1.386.137.42.092.791.248 1.1.463.316.223.56.518.726.88.17.354.256.801.256 1.327 0 .58-.126 1.05-.386 1.44-.194.295-.46.549-.794.76.504.209.896.52 1.17.931.336.507.498 1.097.498 1.803 0 .575-.105 1.058-.322 1.475-.216.42-.51.765-.87 1.024a3.755 3.755 0 0 1-1.229.577 5.283 5.283 0 0 1-1.4.183H4z"></path>
            </svg>
            );
          case 'italic':
            return (
              <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
              <path d="M7 2v2h1.8l-3.101 7.5H3.6v2h5.999v-2H7.8L11 4h1.999V2z"></path>
            </svg>
            );
          case 'underline':
            return (
              <svg className="SVGInline-svg" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path d="M1 14h14v2H1zM2.846 2H4.98v5.956c0 .384.02.773.06 1.168.04.395.154.75.342 1.064.187.315.472.573.853.776.381.203.918.304 1.612.304.695 0 1.231-.101 1.612-.304.381-.203.667-.461.854-.776.192-.33.308-.692.34-1.064.04-.395.06-.784.06-1.168V2h2.134v6.612c0 .736-.12 1.376-.359 1.92a3.7 3.7 0 0 1-1.014 1.368 4.322 4.322 0 0 1-1.58.824 7.22 7.22 0 0 1-2.047.272 7.2 7.2 0 0 1-2.048-.272 4.322 4.322 0 0 1-1.577-.824 3.701 3.701 0 0 1-1.016-1.368c-.239-.544-.359-1.184-.359-1.92V2z"></path>
            </svg>
            );
          default:
            return null;
        }
      };
      const FontFormats = [
        { value: 'bold', label: 'Bold' },
        { value: 'italic', label: 'Italic' },
        { value: 'underline', label: 'Underline' },
        // Add more formats as needed
      ];
     
      const calendarRect = document.getElementById(targetid);
      const fontFamilyValues = calendarRect.getAttribute('data-fontFamily')
      const fontSizeValues = calendarRect.getAttribute('data-fontSize')
      const fontFormatValues = calendarRect.getAttribute('data-fontFormat')
      const fontColorsValues = calendarRect.getAttribute('data-fontColor')
      const dateTimeValues = calendarRect.getAttribute('data-content')
      const dateTimeFotmatValues = calendarRect.getAttribute('data-calendarFormat');
      const labelStyle = {
        border: selectedFontFormat === fontFormatValues ? '1px solid #cecece' : 'none',
      };
      if (calendarRect) {
        updateData(calendarRect)
        function updateData(calendarRect){
            const parentGroup = calendarRect.parentElement;
            const padding = 10
            if (parentGroup) {
              const textToUpdate = parentGroup.querySelector('text');
              if (textToUpdate) {
                const textWidth = textToUpdate.getBBox().width;
                const textHeight = textToUpdate.getBBox().height; 
                calendarRect.setAttribute('width', textWidth + padding);
                calendarRect.setAttribute('height', textHeight + padding);
                const x = parseFloat(calendarRect.getAttribute('x'));
               const y = parseFloat(calendarRect.getAttribute('y'));
               const width = parseFloat(calendarRect.getAttribute('width'));
               const height = parseFloat(calendarRect.getAttribute('height'));
                const tspans = textToUpdate.getElementsByTagName('tspan');
                const centerX = x + width / 2;
                const centerY = y + height / 2;
                if (tspans) {
                  for (let i = 0; i < tspans.length; i++) {
                    const tspan = tspans[i];
                    tspan.textContent = dateTimeValues;
                    tspan.setAttribute('x', centerX);
                    tspan.setAttribute('y', centerY)
                    tspan.setAttribute('fill', fontColorsValues);
                    tspan.setAttribute('font-size', fontSizeValues);
                    tspan.setAttribute('font-family', fontFamilyValues);
                    // Handle font formatting (italic, underline, bold)
                    tspan.style.fontStyle = fontFormatValues === 'italic' ? 'italic' : 'normal';
                    tspan.style.textDecoration = fontFormatValues === 'underline' ? 'underline' : 'none';
                    tspan.style.fontWeight = fontFormatValues === 'bold' ? 'bold' : 'normal';
                  }
                } else {
                  console.log("No <tspan> elements found within <text>.");
                }
              } else {
                console.log("No <text> element found within parentGroup.");
              }
            } else {
              console.log("Parent group not found.");
            }
       
      }
      }
      function updatedDatetime(){
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth() + 1;
        const day = selectedDate.getDate();
    
        const formattedDate = selectedFormat
          .replace('Y', year)
          .replace('m', month)
          .replace('d', day);
          calendarRect.setAttribute('data-calendarFormat', selectedFormat);
          calendarRect.setAttribute('data-content', formattedDate);
      }
      const handleFormatChange = (event) => {
        setSelectedFormat(event.target.value);
        updatedDatetime()
      };
      const handleDateSelection = (dates) => {
        if (dates.length > 0) {
        setSelectedDate(dates[0])
        updatedDatetime()
        }
      };

      const handleFontFamilyChange = (event) => {
        const selectedFontFamilyValue = event.target.value;
        const selectedFontFamily = fontFamilies.find((font) => font.value === selectedFontFamilyValue);
        setSelectedFontFamily(selectedFontFamily);
        calendarRect.setAttribute('data-fontFamily', selectedFontFamilyValue);
      };
      const handleFontColorChange = (event) => {
        setSelectedFontColor(event.target.value);
        calendarRect.setAttribute('data-fontColor', event.target.value);
      };
      const handleFontSizeChange = (event) => {
        setSelectedFontSize(event.target.value);
        calendarRect.setAttribute('data-fontSize', event.target.value);
      };
 
      const handleLiClick = (value) => {
        setSelectedFontFormat(value);
        calendarRect.setAttribute('data-fontFormat', value);
      };
   
      const borderStyleValue = `${borderWidth}px ${borderStyle} ${borderColor}`;
      useEffect(() => {
        
      // Replace 'your-node-server-url' with the URL of your Node.js server
        onDateSelected(
          targetid, 
          selectedDate,
          selectedFontFamily,
          selectedFontSize,
          selectedFontFormat,
          selectedFontColor,
          selectedFormat,
          updateSignpng,
          updateParaaf,
          updateStamp,
          datatype,
          isChecked,
          qrcodeValue,
          sliderValue,
          borderStyleValue,
          qrcodeSize,
          selectedQrfgColor,
          selectedQrbgColor,
          )
      }, [
        targetid,
        selectedDate,
        selectedFontFamily,
        selectedFontSize,
        selectedFontFormat,
        selectedFontColor,
        selectedFormat,
        updateSignpng,
        updateParaaf,
        updateStamp,
        datatype,
        isChecked,
        qrcodeValue,
        sliderValue,
        borderStyleValue,
        qrcodeSize,
        selectedQrfgColor,
        selectedQrbgColor,
      ]);
    
   
      
  return (
  <React.Fragment>
       <Row key={targetid}>
        <Col lg={12}>
  <div className="css-vlt30q">
    <div style={itemStyle}>
        <span className="css-zf56fy">
            <span aria-hidden="true" className="SVGInline">
                <svg className="SVGInline-svg" fill="#868686" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
    <path d="M14 2H2C.9 2 0 2.9 0 4v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM2 14V6h12v8H2zM3 0h2v2H3zm8 0h2v2h-2z"></path>
  </svg>
</span>
</span>
<span data-qa="pannel-header" style={{paddingLeft:'0.625em'}}>{t('Date Signed')} </span>
</div>
</div>

  <div className="accordion " id="accordion" >
                      <div className="accordion-item" >
                        <h2 className="accordion-header" id="headingOne">
                        <button
              className={classnames("accordion-button", "fw-medium", { collapsed: !accordionState.col1 })}
              type="button"
              onClick={() => toggleAccordion("col1")}
              style={{ cursor: "pointer" }}
            >
                            {t('Formatting')}
                          </button>
                        </h2>

                        <Collapse isOpen={accordionState.col1} className="accordion-collapse" >
                          <div className="accordion-body">
                            <div className="text-muted">
    <div className="css-q10huv">
    <div className="css-hboir5">
        <select
         className="css-12ihcxq" 
         value={fontFamilyValues}
        onChange={handleFontFamilyChange} >
        {fontFamilies.map((font) => (
    <option key={font.dataQa} value={font.value} data-qa={font.dataQa}>
      {font.label}
    </option>
  ))}
        </select>
        <span className="css-1baizyq">
            <span className="css-lzaifp">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                </svg>
            </span>
        </span>
    </div>
</div>
<div className="css-9f4jv1">
    <div  style={{ marginRight: '-1px' ,width:"50px" }}>
        <div className="css-0">
            <label data-qa="font-size-label" htmlFor="G29wOvqLVz" className="css-5gs0ys">{t('Font Size')}</label>
        </div>
        <div className="css-q10huv">
            <div className="css-hboir5">
                <select 
                data-qa="font-size"
                 id="G29wOvqLVz"
                  className="css-12ihcxq"
                  value={fontSizeValues}
                   onChange={handleFontSizeChange}>
                    <option data-qa="font-7" value="7">7</option>
                    <option data-qa="font-8" value="8">8</option>
                    <option data-qa="font-9" value="9">9</option>
                    <option data-qa="font-10" value="10">10</option>
                    <option data-qa="font-11" value="11" >11</option>
                    <option data-qa="font-12" value="12" >12</option>
                    <option data-qa="font-14" value="14">14</option>
                    <option data-qa="font-16" value="16">16</option>
                    <option data-qa="font-18" value="18">18</option>
                    <option data-qa="font-20" value="20">20</option>
                    <option data-qa="font-22" value="22">22</option>
                    <option data-qa="font-24" value="24">24</option>
                    <option data-qa="font-26" value="26">26</option>
                    <option data-qa="font-28" value="28">28</option>
                    <option data-qa="font-36" value="36">36</option>
                    <option data-qa="font-48" value="48">48</option>
                    <option data-qa="font-72" value="72">72</option>
                </select>
                <span className="css-1baizyq">
                    <span className="css-lzaifp">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                            <path d="M4 5h8l-4 6z"></path>
                        </svg>
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div className="css-199kmjd">
    {FontFormats.map((format) => (
        <div key={format.value} className="css-vxcmzt">
          <Input
            id={format.value}
            name="radioGroup"
            type="radio"
            className="css-1pdgtej"
            value={fontFormatValues}
            checked={selectedFontFormat === format.value}
          />
          <label htmlFor={format.value}
           className={`css-4lt7ka`} 
           style={selectedFontFormat === format.value ? labelStyle : {}}
           onClick={() => handleLiClick(format.value)}>
            <span className="css-zf56fy">
              <span aria-hidden="true" className="SVGInline">
              {getSVGContent(format.value)}
              </span>
            </span>
            <span className="css-16htk9o">{format.label}</span>
          </label>
        </div>
      ))}

</div>
</div>
<div className="css-q10huv">
    <div className="css-hboir5">
        <select 
        value={fontColorsValues}
          className="css-12ihcxq" 
          onChange={handleFontColorChange}>
            <option data-qa="#ffffff" value="#ffffff" selected>{t('White')}</option>
            <option data-qa="#0053eb" value="#0053eb">{t('Blue')}</option>
            <option data-qa="#f7e601" value="#f7e601">{t('Yellow')}</option>
            <option data-qa="#f70101" value="#f70101">{t('Red')}</option>
            <option data-qa="#000000" value="#000000">{t('Black')}</option>
            <option data-qa="#0087ff" value="#0087ff">{t('Bright Blue')}</option>
            <option data-qa="#7e0101" value="#7e0101">{t('Dark Red')}</option>
            <option data-qa="#ff0000" value="#ff0000">{t('Bright-Red')}</option>
            <option data-qa="#FFD700" value="#FFD700">{t('Gold')}</option>
            <option data-qa="#008000" value="#008000">{t('Green')}</option>
            <option data-qa="#006400" value="#006400">{t('Dark Green')}</option>
        </select>
        <span className="css-1baizyq">
            <span className="css-lzaifp">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                </svg>
            </span>
        </span>
    </div>
</div>


                </div>
                    </div>
                </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col2 }
                            )}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}
                          >
                            {t('Select Date')} 
                          </button>
                        </h2>

                        <Collapse isOpen={col2} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted">
                            <Label>{t('Select Custom Date')} </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          value={dateTimeValues}
                          onChange={handleDateSelection}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: selectedFormat,
                          }}
                          
                        />
                      </InputGroup>
    <div className="css-q10huv" style={{ marginTop: '10px' }}>
    <div className="css-hboir5">

    <select
     id="tvmENIox4K" 
     className="css-12ihcxq" 
    value={dateTimeFotmatValues}
     onChange={handleFormatChange}>
        {dateFormats.map((format) => (
          <option key={format.value} value={format.value}>
            {format.label}
          </option>
        ))}
      </select>
        <span className="css-1baizyq">
            <span className="css-lzaifp">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                </svg>
            </span>
        </span>
    </div>
</div>

                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                
                </Row>
  </React.Fragment>
  
  );
}
export default withTranslation()(SettingCalendar);
