const blogCatData = [];

const getClientData = async () => {
  try {
    const response = await fetch("https://dspmetrics.com/api/categories");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    // Process each item in the result and create the hierarchy
    result.forEach(item => {
    
      // Push the structured data into the helpCenterData array
      blogCatData.push(item);
    });

    return result; // Return the organized data
  } catch (error) {
    console.error('Error fetching help center data:', error);
    throw error; // Re-throw the error to handle it later
  }
};

const fetchData = async () => {
  try {
    const data = await getClientData(); // Get the data from getClientData()
    // console.log("Fetched Data:", JSON.stringify(data, null, 2)); // Use the fetched data
  } catch (error) {
    console.error("Error fetching client data:", error);
  }
};

// Call the fetchData function to fetch and process the data
fetchData();

export { blogCatData };
