import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardTitle,
} from "reactstrap";
import AWS from 'aws-sdk';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";

import {

  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  postContractType,
  postContract,
  postLinks,
  getContract as onGetContract

} from "store/actions";
import Select from "react-select"
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import { startOfDay } from "date-fns";
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const S3_BUCKET = 'analyticscarg-public';
const REGION = 'us-east-2';
const ACCESS_KEY = 'AKIA2NSVDMLW6CSKQOFQ';
const SECRET_ACCESS_KEY = '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';
AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION
});
const s3 = new AWS.S3()

const departmentLimits = {
  starter: 1,
  professional: 6,
  enterprise: Infinity, // Assuming enterprise has no limit
};

const TeamLimits = {
  starter: 1,
  professional: Infinity,
  enterprise: Infinity, // Assuming enterprise has no limit
};
const ContractTable = (props) => {
  const { user_id } = useParams();
    const {
        client,
        xlsid,
        contractdata,
        postDeps,
        postTeams,
        postContractTypes,
        link,
        contract,
        error
      } = useSelector(state => ({
        client: state.getClientData.client,
        xlsid: state.getClientData.xlsid,
        contractdata: state.getClientData.contractdata,
        postDeps: state.getClientData.postDeps,
        postTeams: state.getClientData.postTeams,
        postContractTypes: state.getClientData.postContractTypes,
        link: state.getClientData.link,
        contract: state.getClientData.contract,
        error: state.getClientData.error,
      }));
    const dispatch = useDispatch();
    const { t,clientData,isEditorAllowed,plan_name } = props;
   
    const navigate = useNavigate();
    
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [userId, setuserId] = useState(user.clientId);
    const [token, setToken] = useState(user.token);
    const [account_id , setAccountid ] = useState(user.account_id );
    const [company_name , setCompany_name ] = useState(user.company_name );
    const maxDepartments = departmentLimits[plan_name] || 1;
    const maxTeams = TeamLimits[plan_name] || 1;
    const [errors, setErrors] = useState({});
    const [toast, setToast] = useState(false);
    useEffect(() => {
        dispatch(onGetcollaborator(account_id));
        dispatch(getXlsdata(account_id));
        dispatch(onGetContract(user_id));
      }, [dispatch]);
      const toggleToast = () => {
        setToast(!toast);
    };
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [newDepartmentName, setNewDepartmentName] = useState('');
    const [teamOptions, setTeamOptions] = useState([]);
    const [contractOptions, setContractOptions] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState({ value: '', label: t('Select Team') });
    const [selectedContract, setSelectedContract] = useState(null);
    const [newTeamName, setNewTeamName] = useState('');
    const [newContractType, setNewContractType] = useState('');
    const [isCreatingNewTeam, setIsCreatingNewTeam] = useState(false);
    const [isCreatingNewContract, setIsCreatingNewContract] = useState(false);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
    const [totalHours, setTotalHours] = useState(0);
    const [contractData, setContractData] = useState({
      account_id: account_id,
      department_id: null,
      dep_name: null,
      startdate: '',
      einddate: '',
      loontype: false,
      uurloon: null,
      team_id: null,
      team_name: null,
      perday: [],
      notes: '',
      contract_type: null,
      worker_id: [userId],
      totalweek: null,
      days: [],
    }); 
    
    useEffect(() => {
     
      if (Array.isArray(contract) && contract.length > 0) {
     
        const timer = setTimeout(() => {
          contract.forEach(contractItem => { 
            setContractData({
              account_id: account_id,
              department_id: contractItem.departmen_id || null,
              dep_name: contractItem.dep_name || null,
              startdate: formatDate(contractItem.startdate),
              einddate: formatDate(contractItem.einddate),
              loontype: contractItem.loontype === "1", // Assuming "1" represents true for loontype
              uurloon: contractItem.uurloon || null,
              team_id: contractItem.team_id || null,
              team_name: contractItem.team_name || null,
              perday: contractItem.perday ? contractItem.perday.split(',').map(day => parseFloat(day)) : [],
              notes: contractItem.notes || '',
              contract_type: contractItem.contract_type || null,
              worker_id: Array.isArray(contractItem.worker_id)
                ? contractItem.worker_id.length > 0
                  ? contractItem.worker_id
                  : [userId]
                : [contractItem.worker_id || userId],
                worker_name:[clientData.frist_name],
                
              totalweek: contractItem.totalweek || null,
              days: contractItem.days ? contractItem.days.split(',') : [],
            });
          });
        }, 2000);
      } else {
        console.warn('contract is not an array:', contract);
      }
    }, [contract, account_id, userId]);

    const [hours, setHours] = useState({
      mo: 0,
      tu: 0,
      we: 0,
      th: 0,
      fr: 0,
      sa: 0,
      su: 0,
    });

    useEffect(() => {
      // Initialize a new hours object with default values
      const updatedHours = {
        mo: 0,
        tu: 0,
        we: 0,
        th: 0,
        fr: 0,
        sa: 0,
        su: 0,
      };
    
      // Ensure that contractData.days and contractData.perday are arrays and have values
      if (Array.isArray(contractData.days) && Array.isArray(contractData.perday)) {
        // Loop through the days and set the corresponding perday values
        contractData.days.forEach((day, index) => {
          // Ensure index is within bounds of perday array
          if (index < contractData.perday.length) {
            // Update the hours object for the corresponding day
            updatedHours[day] = contractData.perday[index];
          }
        });
      }
    
      // Update the state with the new hours object
      setHours(updatedHours);
    }, [contractData.days, contractData.perday]);
    useEffect(() => {
      const timer = setTimeout(() => {
        if (Array.isArray(xlsid) && xlsid.length > 0) {
          const seenContractTypes = new Set();
          const uniqueContractTypes = [];
    
          xlsid.forEach(contract => {
            if (contract.type_id !== null && !seenContractTypes.has(contract.type_id)) {
              seenContractTypes.add(contract.type_id);
              uniqueContractTypes.push(contract);
            }
          });
    
          const options = [
            { value: '', label: t('Select Contract Type') },
            ...uniqueContractTypes.map(contract => ({
              value: String(contract.type_id),
              label: contract.type_name,
            })),
            { value: 'new', label: t('New Contract Type') },
          ];
    
          const selectedContractType = options.find(option => String(option.value) === String(contractData.contract_type));
          
    
          if (selectedContractType) {
            setSelectedContract(selectedContractType);
          } else {
            setSelectedContract({ value: '', label: t('Select Contract Type') });
          }
    
          setContractOptions(options);
        } else {
          setContractOptions([
            { value: '', label: t('Select Contract Type') },
            { value: 'new', label: t('New Contract Type') },
          ]);
          setSelectedContract({ value: '', label: t('Select Contract Type') });
        }
        
      }, 2000);
    
      return () => clearTimeout(timer);
    }, [xlsid, contractData.contract_type]);
    
    
    
      
    useEffect(() => {
      // Introduce a 2-second delay before setting the options
      const timer = setTimeout(() => {
        if (Array.isArray(xlsid) && xlsid.length > 0) {
          // Create a Set to track seen department IDs
          const seenDepartments = new Set();
          const uniqueDepartments = [];
    
          // Filter unique departments
          xlsid.forEach(dept => {
            if (!seenDepartments.has(dept.departments_id)) {
              seenDepartments.add(dept.departments_id);
              uniqueDepartments.push(dept);
            }
          });
    
          // Create options for the department select dropdown
          const options = [
            { value: '', label: t('Select Department') },
            ...uniqueDepartments.map(dept => ({
              value: dept.departments_id,
              label: dept.dep_name,
              selected: contractData.department_id === dept.departments_id, // Add this condition
            })),
            
          ];
    
          // Update the department options state
          setDepartmentOptions(options);
    
          // Automatically select the department if there's a match
          const selectedDept = options.find(
            option => option.value === contractData.department_id
          );
          if (contractData.department_id) {
            setSelectedDepartmentId(contractData.department_id);
          }
          if (selectedDept) {
            setSelectedDepartment(selectedDept);
          } else {
            setSelectedDepartment({ value: '', label: t('Select Department') });
          }
        } else {
          // Handle case where xlsid is empty or not an array
          const defaultOptions = [
            { value: '', label: t('Select Department') },
            
          ];
          setDepartmentOptions(defaultOptions);
          setSelectedDepartment({ value: '', label: t('Select Department') });
        }
      }, 2000); // 2000 milliseconds = 2 seconds
    
      return () => clearTimeout(timer);
    }, [xlsid, contractData.department_id]);
      
    useEffect(() => {
      if (selectedDepartmentId) {
        const timer = setTimeout(() => {
          if (Array.isArray(xlsid) && xlsid.length > 0) {
            // Filter teams by department ID
            const filteredTeams = xlsid.filter(dept => dept.dep_id === selectedDepartmentId);
    
            // Get unique teams by `team_id`
            const seenTeams = new Set();
            const uniqueTeams = filteredTeams.filter(team => {
              if (!seenTeams.has(team.team_id)) {
                seenTeams.add(team.team_id);
                return true;
              }
              return false;
            });
    
            // Map unique teams to options
            const options = [
              ...(uniqueTeams.length > 0
                ? uniqueTeams.map(team => ({
                    value: team.team_id,
                    label: team.team_name,
                  }))
                : []),
              ...(uniqueTeams.length < maxTeams
                ? [{ value: 'new', label: t('Create New Team') }]
                : [{ value: 'over', label: t('Team limit reached!')}]),
            ];
    
            // Set team options
            setTeamOptions(options);
            setSelectedTeam(options || { value: '', label: t('Select Team') });
          } else {
            setTeamOptions([]);
           
            setSelectedTeam({ value: '', label: t('Select Team') });
          }
        }, 1000);
    
        return () => clearTimeout(timer);
      } else {
        setTeamOptions([]);
        setSelectedTeam({ value: '', label: t('Select Team') });
      }
    }, [selectedDepartmentId, xlsid, contractData.team_id, t]);
    
      
    
    
      const handleDepartmentChange = (selectedOption) => {
        if (selectedOption.value === 'new') {
          // User chose to create a new department
          setIsCreatingNew(true);
          setSelectedDepartment(null); // Clear the selection
          setSelectedDepartmentId(null);
          setContractData({
            ...contractData,
            department_id: null,
            dep_name: null,
          });
        } else {
          // User selected an existing department
          setSelectedDepartment(selectedOption);
          setSelectedDepartmentId(selectedOption.value); // Set the selected department ID
          setIsCreatingNew(false);
          setContractData({
            ...contractData,
            department_id: selectedOption.value,
            dep_name: selectedOption.label,
          });
        }
       
      };
      const handleNewDepartmentChange = (e) => {
        setNewDepartmentName(e.target.value);
       
      };
    
      const handleAddNewDepartment = () => {
        if (newDepartmentName.trim()) {
          const isDuplicate = departmentOptions.some(option => option.value === newDepartmentName);
      
          if (!isDuplicate) {
            const newOption = { value: newDepartmentName, label: newDepartmentName };
            setDepartmentOptions(prevOptions => [...prevOptions, newOption]);
            setSelectedDepartment(newOption);
            setNewDepartmentName('');
            setIsCreatingNew(false);
      
            const data = {
              account_id: account_id,
              dep_name: newDepartmentName,
            };
            
           dispatch(postDep(data));
          } else {
            
          }
        }
      };
      

      const handleTeamChange = (selectedOption) => {
        if (selectedOption.value === 'new') {
          setIsCreatingNewTeam(true);
          setSelectedTeam(null);
          setContractData({
            ...contractData,
            team_id: null,
            team_name: null,
          });
        }else if (selectedOption.value === 'over') {
          toggleToast()
        }  else {
          setSelectedTeam(selectedOption);
          setIsCreatingNewTeam(false);
          setContractData({
            ...contractData,
            team_id: selectedOption.value,
            team_name: selectedOption.label,
          });
        }
      };
    

    
    
      const handleContractChange = (selectedOption) => {
        if (selectedOption.value === 'new') {
          setIsCreatingNewContract(true);
          setSelectedContract(null);
          setContractData({
            ...contractData,
            contract_type: null,
          });
        } else {
          setSelectedContract(selectedOption);
          setContractData({
            ...contractData,
            contract_type: selectedOption.value,
          });
          setIsCreatingNewContract(false);
        }
      };
    
      const handleNewTeamChange = (e) => {
        setNewTeamName(e.target.value);
      };
    
      const handleAddNewTeam = () => {
        if (newTeamName.trim()) {
          const isDuplicate = teamOptions.some(option => option.value === newTeamName);
      
          if (!isDuplicate) {
            const newOption = { value: newTeamName, label: newTeamName };
            setTeamOptions(prevOptions => [...prevOptions, newOption]);
            setSelectedTeam(newOption);
            setNewTeamName('');
            setIsCreatingNewTeam(false);
      
            const data = {
              account_id: account_id,
              team_name: newTeamName,
              dep_id: selectedDepartmentId, // Use the selected department ID
            };
      
            dispatch(postTeam(data));
          } else {
            toastr.error(t('This team already exists.')); // Use toastr for notifications
          }
        } else {
          toastr.error(t('Please enter a valid team name.')); // Use toastr for notifications
        }
      };
    
      const handleNewContractChange = (e) => {
        setNewContractType(e.target.value);
      };
    
      const handleAddNewContract = () => {
        if (newContractType.trim()) {
          const isDuplicate = contractOptions.some(option => option.value === newContractType);
      
          if (!isDuplicate) {
            const newOption = { value: newContractType, label: newContractType };
          setContractOptions(prevOptions => [...prevOptions, newOption]);
          setSelectedContract(newOption);
          setNewContractType('');
          setIsCreatingNewContract(false);
      
            const data = {
              account_id: account_id,
              type_name: newContractType,
            };
      
            dispatch(postContractType(data));
           
          } else {
            toastr.error(t('This Type already exists.')); // Use toastr for notifications
          }
        } else {
          toastr.error(t('Please enter a valid Type.')); // Use toastr for notifications
        }
      
    
       
      };
    
      
    
      const [isCreatingNew, setIsCreatingNew] = useState(false);
      
    
     
    
      const handleInputChange =  (day, value) => {
        const numValue = value === '' ? null : Math.max(0, Math.min(24, parseFloat(value) || 0));
        
        setContractData(prevData => {
          // Create a copy of the perday array
          const updatedPerday = [...prevData.perday];
    
          // Update or add the value in the array
          if (numValue === null) {
              // If value is null (i.e., input is cleared), remove the value at the specified index
              const index = Object.keys(hours).indexOf(day);
              if (index > -1) {
                  updatedPerday.splice(index, 1);
              }
          } else {
              // Otherwise, update the value at the specified index
              const index = Object.keys(hours).indexOf(day);
              if (index > -1) {
                  updatedPerday[index] = numValue;
              } else {
                  // Add a new value if it doesn't exist
                  updatedPerday.push(numValue);
              }
          }
    
          // Remove any null or invalid entries
          return {
              ...prevData,
              perday: updatedPerday.filter(v => v !== null),
          };
      });
    
    
      setContractData(prevData => {
        // Create a copy of the perday array
        const updatedPerday = [...prevData.days];
    
        // Update or add the value in the array
        if (numValue === null) {
            // If value is null (i.e., input is cleared), remove the value at the specified index
            const index = Object.keys(hours).indexOf(day);
            if (index > -1) {
                updatedPerday.splice(index, 1);
            }
        } else {
            // Otherwise, update the value at the specified index
            const index = Object.keys(hours).indexOf(day);
            if (index > -1) {
                updatedPerday[index] = day;
            } else {
                // Add a new value if it doesn't exist
                updatedPerday.push(day);
            }
        }
    
        // Remove any null or invalid entries
        return {
            ...prevData,
            days: updatedPerday.filter(v => v !== null),
        };
    });
        
        setHours(prevHours => ({
          ...prevHours,
          [day]: numValue
        }));
      };
      function convertToHourFormat(hours) {
        const wholeHours = Math.floor(hours);
        const remainingMinutes = Math.round((hours - wholeHours) * 60);
        const formattedHours = wholeHours.toString().padStart(2, '0');
        const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }
      useEffect(() => {
        const total = Object.values(hours).reduce((acc, curr) => acc + curr, 0);
        const totalTime = convertToHourFormat(total);
        setTotalHours(totalTime);
        setContractData(prevData => ({
          ...prevData,
          totalweek: totalTime,
        }));
      }, [hours]);
    
    
    

      
      const handleStartTimeChange = (e) => {
        const newStartDate = e.target.value;
        setContractData(prevData => ({
          ...prevData,
          startdate: newStartDate,
        }));
      };
    
      const handleEndTimeChange = (e) => {
        const newEndDate = e.target.value;
        setContractData(prevData => ({
          ...prevData,
          einddate: newEndDate,
        }));
      };
      const handleHourlyRateChange = (e) => {
        setContractData(prevData => ({
          ...prevData,
          uurloon: e.target.value,
        }));
      };
    
    
      const handleLoontypeChange = (e) => {
        setContractData(prevData => ({
          ...prevData,
          loontype: e.target.checked, // Use e.target.checked to get true or false
        }));
      };
    
      const handleNotesChange = (e) => {
        setContractData(prevData => ({
          ...prevData,
          notes: e.target.value,
        }));
      }
    

    
    
      const validateForm = () => {
        const newErrors = {};
    
        if (!contractData.account_id) newErrors.account_id = t('Account ID is required.');
        if (!contractData.department_id) newErrors.department_id = t('Department ID is required.');
        // if (!contractData.dep_name) newErrors.dep_name = t('Department name is required.');
        if (!contractData.startdate) newErrors.startdate = t('Start date is required.');
        if (!contractData.einddate) newErrors.einddate = t('End date is required.');
         if (!contractData.worker_id) newErrors.worker_id = t('At least one worker ID is required.');
        if (!contractData.totalweek) newErrors.totalweek = t('Total week time is required.');
        if (!contractData.days.length) newErrors.days = t('At least one day is required.');
        if (!contractData.contract_type) newErrors.contract_type = t('Contract type is required.');
        
        
        
        return newErrors // Returns true if there are no errors
      };
    
    
      
    
      const handleSubmit = (e) => {
        e.preventDefault();
        
        const validationErrors = validateForm();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
          Object.values(validationErrors).forEach((error) => toastr.error(error));
          // Proceed with form submission or further logic
        } else {
          
          dispatch(postContract(contractData));
          // Handle the validation errors (e.g., show them in the UI)
        }
      };

      useEffect(() => {
        const depSuccessHandler = (message) => {
          toastr.success(t(message));
          dispatch(onGetContract(clientData.clientId));
          dispatch(getXlsdata(account_id));
        };
        
        const depErrorHandler = (message) => {
          toastr.error(t(message));
        };
      
        if (postTeams) {
          postTeams.success 
            ? depSuccessHandler('Team created successfully! Your team is now set up and ready to collaborate.') 
            : depErrorHandler('Failed to create the team. Please check the details and try again or contact support if the issue persists.');
        }
        if (postDeps) {
          postDeps.success 
            ? depSuccessHandler('New department data has been successfully submitted.')
            : depErrorHandler('Failed to submt department data.');
        }
        if (postContractTypes) {
          postContractTypes.success 
            ? depSuccessHandler('Contract type added successfully! It is now available for use.')
            : depErrorHandler('Failed to add the contract type. Please check the details and try again, or contact support for assistance.');
        }
      
        if (contractdata) {
          contractdata.success 
            ? depSuccessHandler('Contract created successfully! Your new contract is now active and ready to use.') 
            : depErrorHandler('Failed to create the contract. Please check the details and try again or contact support if the issue persists.');
        }
      
      }, [postDeps,postTeams,postContractTypes,contractdata,dispatch, account_id]);
    
  return (
    <>

<Modal isOpen={toast}>
    <ModalHeader toggle={toggleToast}>
      {t('Upgrade your plan')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
      <Row className="justify-content-center">
                    <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none',marginBottom:'15px' }}
                          >
                          {t('Maintenance Contract Management')}
                          </Button>    
                    <Col lg="6" md="8" sm="10">
                        <form onSubmit={handleSubmit}>
                        <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-5 col-form-label">
        {t('Department')}
        </label>
        <div className="col-md-7" id="select_dep">
        <Select
           id="department_id"
           name="department_id"
           options={departmentOptions}
           placeholder={t('Select Department')}
           onChange={handleDepartmentChange}
           value={selectedDepartment}
           isDisabled={!isEditorAllowed}
          />
        </div>
      </div>
      {isCreatingNew && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-4 col-form-label">
          {t('New Department')}
          </label>
          <div className="col-md-8">
            <input
               type="text"
               id="new_department"
               className="form-control"
               defaultValue={newDepartmentName}
               onChange={handleNewDepartmentChange}
               placeholder={t('Enter new department name')}
               isDisabled={!isEditorAllowed}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewDepartment}
              isDisabled={!isEditorAllowed}
            >
              {t('Add Department')}
            </button>
          </div>
        </div>
      )}
   

   <div>
      <div className="mb-3 row">
        <label htmlFor="svc_team" className="col-md-5 col-form-label">
        {t('Team')}
        </label>
        <div className="col-md-7" id="select_team">
        <Select
  id="team_id"
  name="team_id"
  options={teamOptions}
  value={selectedTeam} // Ensure `selectedTeam` matches the format of options
  onChange={(selected) => {
    setSelectedTeam(selected); // Update the selected team state
    handleTeamChange(selected); // Call your existing handler if needed
  }}
  isDisabled={!selectedDepartmentId}
/>
        </div>
      </div>
      {isCreatingNewTeam  && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-4 col-form-label">
          {t('New Team')}
          </label>
          <div className="col-md-8">
            <input
             type="text"
             id="new_team"
             className="form-control"
             defaultValue={newTeamName}
             onChange={handleNewTeamChange}
             placeholder={t('Enter new team name')}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewTeam}
            >
             {t('Add Team')}
            </button>
          </div>
        </div>
      )}

      </div>
    
      <div>
      <div className="mb-3 row">
        <label htmlFor="contract_type" className="col-md-5 col-form-label">
        {t('Contract Type')}
        </label>
        <div className="col-md-7" id="contract_type">
        <Select
            id="contract_type"
            name="contract_type"
            options={contractOptions}
            placeholder={t('Select Contract Type')}
            onChange={handleContractChange}
            value={selectedContract}
            isDisabled={!isEditorAllowed}
          />
        </div>
      </div>
      {isCreatingNewContract && (
        <div className="mb-3 row">
          <label htmlFor="new_contract" className="col-md-5 col-form-label">
          {t('New Contract Type')}
          </label>
          <div className="col-md-7">
            <input
              type="text"
              id="new_contract"
              className="form-control"
              defaultValue={newContractType}
              onChange={handleNewContractChange}
              placeholder={t('Enter new contract type')}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewContract}
            >
              {t('Add Contract Type')}
            </button>
          </div>
        </div>
      )}
      </div>

      <div className="mb-3 row">
        <label htmlFor="start_time" className="col-md-4 col-form-label">
        {t('Start date & End date')}
        </label>
        <div className="col-lg-4">
          <div className="mb-3">
            <div className="input-group">
            <Input id="start_time"
             type="date" 
            className="form-control"
            name="start_time"
            defaultValue={contractData.startdate ? new Date(contractData.startdate).toISOString().split('T')[0] : ''}
            onChange={handleStartTimeChange}
             />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-3">
            <div className="input-group">
            <Input id="end_time" 
            type="date"
             name="end_time" 
             className="form-control"
             defaultValue={contractData.einddate ? new Date(contractData.einddate).toISOString().split('T')[0] : ''}
             onChange={handleEndTimeChange}
              />
            </div>
          </div>
        </div>
      </div>
  
      <div className="mb-3 row">
        <label htmlFor="hourly_rate" className="col-md-4 col-form-label d-flex justify-content-center align-items-center">
        {t('Hourly Rate')}
        </label>
        <div className="col-md-2">
        <Input
              className="form-control"
              type="number"
              name="hourly_rate"
              placeholder="0"
              id="hourly_rate"
              defaultValue={contractData.uurloon}
              onChange={handleHourlyRateChange}
            />
        </div>
        <div className="col-md-6">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
          <Input
                className="form-check-input"
                type="checkbox"
                name="tax_withholding"
                id="tax_withholding"
                onChange={handleLoontypeChange}
                
              />
            <label className="form-check-label" htmlFor="tax_withholding">
            {t('Tax Withholding')}
            </label>
          </div>
      
        </div>
      </div>

      <Col lg="12" className="d-flex justify-content-center">
  <div id="toggleContainer" className="text-center">
    <div className="mb-3">
      <label className="col-form-label">{t('Contract Hours Per Day')}</label>
      <div id="checkboxContainer" className="mx-auto" >
        <table className="table table-striped" id="checkboxTable">
          <thead style={{ background: "aliceblue" }}>
            <tr className="main">
              
              <th><label htmlFor="mo">{t('Mon')}</label></th>
              <th><label htmlFor="tu">{t('Tue')}</label></th>
              <th><label htmlFor="we">{t('Wed')}</label></th>
              <th><label htmlFor="th">{t('Thu')}</label></th>
              <th><label htmlFor="fr">{t('Fri')}</label></th>
              <th><label htmlFor="sa">{t('Sat')}</label></th>
              <th><label htmlFor="su">{t('Sun')}</label></th>
              <th><label htmlFor="total_hours">{t('Total')}</label></th>
            </tr>
          </thead>
          <tbody>
            
            <tr>
              {Object.keys(hours).map(day => (
                <td key={day}>
                  <label className="checkbox">
                    <input
                      type="text" 
                      defaultValue={hours[day]}
                      onChange={(e) => handleInputChange(day, e.target.value)}
                      style={{ width: "45px", border: "none", marginBottom: "5px" }}
                      className="day-input"
                      data-id={day}
                    />
                    <span className="checkbox-indicator"></span>
                  </label>
                </td>
              ))}
              <td>
                <label className="checkbox">
                  <input
                    type="text"
                    defaultValue={totalHours}
                    readOnly
                    style={{ width: "45px", border: "none", marginBottom: "5px" }}
                    className="total-hours"
                    data-id="total"
                    id="total_hours"
                  />
                  <span className="checkbox-indicator"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</Col>
  
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              
              id="description"
              name="description"
              className="form-control"
              rows="3"
              value={contractData.notes}
              onChange={handleNotesChange}
            ></textarea>
        </div>
      </div>
      </Col>
      <div
      style={{ display:'flex',justifyContent:'flex-end',gap:'10px'}}
       >

        <Button
          id="pdfButton1"
          type="submit"
          className="btn  css-1bl3roz"
          style={{ background: '#0463b9', border: 'none' }}
        disabled={isEditorAllowed}
        >
        {t('Update')} 
        </Button>
      </div>
                         </form>
                        </Col>
                      </Row>
    </>
  );
};


export default withTranslation()(withRouter(ContractTable));
