//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";
//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const GET_NOTIFICATION = "/get/notification";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";
//SIGNATURE
export const GET_SIGNATUR= "/api/signature";
export const GET_SIGNATURIMG= "/api/img/signature";
export const POST_SIGNATUR = "/post/signture";
export const POST_HANDWRITNG = "/api/saveSignature";
export const POST_STEMP = "/api/upload-stemp";
export const POST_NEW_DOC = "/api/create-new/doc";
export const POST_RECIEPIEN_DOC = "/post/upload"
// 
export const GET_DOCUMENT= "/api/signeditor";
export const POST_SIGN_DATA = "/api/post/data";

export const GET_DOC_SIGN_DETAIL = "/api/signer/signature";

//TEMPLATE
export const GET_TEMPLATE = "/get/template";
export const DELETE_TEMPLATE = "/delete/template";
export const DELETE_TEMPLATE_DATA = "/get/template/data";
//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_MAILS_LIST = "/inbox";
export const SELECT_FOLDER = "/folders";
export const GET_SELECTED_MAILS = "/selectedmails";
export const SET_FOLDER_SELECTED_MAILS = "/setfolderonmail";
export const UPDATE_MAIL = "/update/mail";

export const POST_RESEND = "/resend";
export const ADD_NEW_VALID = "/add/valid";
export const UPDATE_POST_STATUS = "/update/post/status";
export const ADD_NEW_FOLDER = "/add/folder";
export const UPDATE_FOLDER = "/update/folder";
export const UPDATE_POST_FOLDER_API = "/update/post/folder";
export const DELETE_POSTDATA = "/api/envelop/delete";

export const PERMANENTLY_DELETE = "/api/mails/remove";
export const RECOVERY_MAIL = "/api/mail/recovery";

export const ACCOUNT_CLOSE = "/api/account/close";
export const GET_ACCOUNT_PROFILE = "/api/account/profile";
export const EDIT_ACCOUNT_PROFILE = "/api/update/profile";
export const GET_ACCOUNT_INVOICE = "/api/account/invoice";
export const GET_BRANDING_INFO = "/api/branding/info";
export const POST_BRANDING_INFO = "/api/branding/set/default";
export const EDIT_BRANDING_INFO = "/api/branding/update";
export const DELETE_BRANDING_INFO = "/api/branding/delete";

export const POST_TEMPLATE_RULE = "/api/template/permission/post";
export const GET_TEMPLATE_RULE = "/get/permission/temp/data";
export const PUT_TEMPLATE_RULE = "/put/permission/temp/data";
export const DELETE_TEMPLATE_RULE = "/delete/permission/temp/data";


export const POST_User_RULE = "/api/user/permission/post";
export const GET_User_RULE = "/get/permission/user/data";
export const PUT_User_RULE = "/put/permission/user/data";
export const DELETE_User_RULE = "/delete/permission/user/data";

// User Permission 
export const GET_PPERMISSION = "/get/user/permission";

export const GET_SETTING = "/api/website/setting";
export const GET_HELPCENTER = "/get/helpcenter";
export const GET_BLOGDATA = "/api/blogs";
export const GET_BLOGCATDATA = "/api/categories";

export const GET_USERS = "/get/client";
export const ADD_NEW_USER = "/post/client";
export const UPDATE_USER = "/update/client";
export const GET_CONTRACT = "/get/contract";
export const POST_CONTRACT = "/api/contract/form";
export const GET_XLS = "/get/xls";

// Shift

export const GET_SHIFT = "/get/shift";
export const POST_SHIFT = "/post/shift";
export const PUT_SHIFT = "/update/shift";
export const DELETE_SHIFT = "/delete/shift";
export const GET_UREN = "/get/uren"; 
export const GET_UREN_SCHEDULE = "/api/get/rooster";
export const POST_UREN = "/post/uren";
export const PUT_UREN = "/update/uren";
export const DELETE_UREN = "delete/uren";
export const GET_CONTRACT_EMPLOYEERS = "/api/collaborate/plane";
export const GET_TEAM_DATA = "/api/teamdata";
export const GET_WEB_INFO = "/api/website/setting";
//CONTACTS


// Absence

export const GET_ACCOUNT_DATA = "/accounts";
export const PUT_ACCOUNT_DATA = "/put/accounts";
export const GET_ABSENCE_POLICY = "/get/absence/policy";
export const POST_ABSENCE_POLICY = "/post/absence/policy";
export const PUT_ABSENCE_POLICY = "/put/absence/policy";
export const DELETE_ABSENCE_POLICY = "/delete/absence/policy";
export const GET_ABSENCE_TYPE = "/get/absence/type";
export const POST_ABSENCE_TYPE = "/post/absence/type";
export const PUT_ABSENCE_TYPE = "/put/absence/type";
export const DELETE_ABSENCE_TYPE = "/delete/absence-type-and-policy";
export const GET_ABSENCE = "/get/absence";
export const GET_ALL_ABSENCE = "/get/all/absence";
export const PUT_ABSENCE = "/put/absence";
export const POST_ABSENCE = "/post/absence";
export const DELETE_ABSENCE = "/delete/absence";
export const GET_CORRECTION = "/get/correction";
export const POST_CORRECTION = "/post/correction";
export const PUT_CORRECTION = "/delete/correction";
export const GET_EMPLOYEERS_FULL_ABSENCE = "/api/hours";
export const GET_ABSENCE_TOTAL = "/api/absence/pre";
export const GET_HOURS_TOTAL = "/api/uren/pre";
export const GET_USER_PROFILE = "/user";
export const POST_INSPECTION = "/api/post/inspection";
export const DELETE_USER = "/delete/user";
export const POST_SURCHARGER = "/post/surcharger";
export const PUT_SURCHARGER = "/put/surcharger";
export const DELETE_SURCHARGER = "/delete/surcharger";
export const GET_SURCHARGER = "/get/surcharger";
//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";
//report
export const GET_REPORT = "/get/report";
export const POST_REPORT_ABSENCE = "/post/report/absence";
export const POST_REPORT = "/post/report";
export const DELETE_REPORT = "/delete/report";


//fleet
export const GET_VEHICLES = "/api/fleet/manage";
export const POST_VEHICLES = "/post/vehicles";
export const PUT_VEHICLES = "/put/vehicles";
export const DELETE_VEHICLES = "/delete/fleet";

export const GET_VEHICLES_DOC = "/api/fleet/document";
export const POST_VEHICLES_DOC = "/post/vehicles/document";
export const PUT_VEHICLES_DOC = "/put/vehicles/document";
export const DELETE_VEHICLES_DOC = "/delete/fleet/document";
//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

export const POST_DEPART = "/post/dep";
export const PUT_DEPART = "/put/dep";
export const DELETE_DEPART = "/delete/dep";
export const POST_TEAM = "/post/team";
export const PUT_TEAM = "/put/team";
export const DELETE_TEAM = "/delete/team";
export const POST_NEW_TEAM = "/post/new/team";
export const POST_CONTRACT_TYPE = "/post/contract/type";
export const PUP_CONTRACT_TYPE = "/put/contract/type";
export const DELETE_CONTRACT_TYPE = "/delete/contract/type";
export const POST_LINK = "/api/post/link";
export const PUT_PROOF = "/api/update/document";
export const POS_DOCUMENT_COLLABORATOR = "/post/document/collaborator";
export const PUT_COLLABORATOR_PROFILE = "/api/collaboratoe/profile";
//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";
export const GET_CRYPTO_PRODUCTS = "/crypto-products";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

// JOBS
export const GET_JOB_LIST = "/jobs";
export const ADD_NEW_JOB_LIST = "/add/job";
export const UPDATE_JOB_LIST = "/update/job";
export const DELETE_JOB_LIST = "/delete/job";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "add/applyjob";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";



//Blog
export const GET_VISITOR_DATA = "/visitor-data";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

//dashboard crypto
export const GET_WALLET_DATA = "/wallet-balance-data";

//dashboard jobs
export const GET_STATISTICS_DATA = "/Statistics-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";