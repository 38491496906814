import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { FORGET_PASSWORD } from "./actionTypes";
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
} from "./actions";

// Include helper functions for API calls
import { postFakeForgetPwd } from "../../../helpers/fakebackend_helper";

// Worker Saga for handling the Forget Password flow
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postFakeForgetPwd, { email: user.email });
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          "Reset link has been sent to your mailbox. Please check your email."
        )
      );
    }
  } catch (error) {
    yield put(userForgetPasswordError("Failed to send reset email."));
  }
}

// Watcher Saga for Forget Password action
export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

// Root saga
function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
