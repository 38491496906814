import React, { useRef, useState, useEffect } from 'react';
import { Link ,useParams} from "react-router-dom";
import axios from 'axios';
import { map } from "lodash";

import {
  getSignatureImgByEmail,
  getSignatureImgByEmailSuccess,
  getSignatureImgByEmailFail,
  getDocument,
  getDocumentSuccess,
  getDocumentFail,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
const DraggableElement = (props) => {
  const { t , onPageChange , selectedOption  ,permissions } = props;
  const dispatch = useDispatch();
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const [data, setData] = useState([]);
  const { uuid } = useParams();
  const [recipientNames, setRecipientNames] = useState([]);
  const [recipientEmails, setRecipientEmail] = useState([])
  const [recipientMsg, setRecipientMsg] = useState([])
  const [recipientroll, setRecipientRoll] = useState([])
  const [Colors, setColor] = useState([]);
  const [status, setStatus] = useState([]);
  const [recipienid, setRecipienid] = useState([]);
  const [docIds, setDocIds] = useState([]);
  const [docNames, setDocNames] = useState([]);
  const [signaturepng, setSignPng] = useState([]);
  const [paraafimg, setParaafImg] = useState([]);
  const [stamppng, setStampPng] = useState([]);
  const [isOwner, setIsowner] = useState([]);
  const [recipientData, setRecipientData] = useState([]);
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const selectDocState = (state) => state.Prepare;

     const DocumentProperties = createSelector(
       selectDocState,
       (getSigantureImgData) => ({
         docname: getSigantureImgData.docname,
       })
     );
   
     const { docname } = useSelector(DocumentProperties);
    
     useEffect(() => {
       const fetchData = async () => {
         try {
           if (uuid) {
             await dispatch(getDocument(uuid));
           } else {
             console.error("User ID is not defined");
           }
         } catch (error) {
           console.error("Error fetching document:", error);
         }
       };
   
       fetchData();
     }, [dispatch, uuid]);

     const selectIMGState = (state) => state.Prepare;

     const ImgProperties = createSelector(
      selectIMGState,
       (getSigantureImgData) => ({
        signatureImgData: getSigantureImgData.signatureImgData,
       })
     );
   
     const { imgData } = useSelector(ImgProperties);
     useEffect(() => {
      const fetchImgData = async () => {
        try {
          if (userId) {
            await dispatch(getSignatureImgByEmail(userId));
          } else {
            console.error("User ID is not defined");
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        }
      };
  
      fetchImgData();
    }, [dispatch, userId]); 
  useEffect(() => {
    // Check if docname is an array
    if (Array.isArray(docname)) {
      // Assuming 'data' is the fetched data from your API
    
      // Use reduce to extract the required data from the 'data' array
      const extractedData = docname.reduce(
        (acc, item) => {
          const recipients = JSON.parse(item.recipient_data);

          if (recipients !== null) {
          recipients.forEach((recipient) => {
            acc.recipientNames.push(recipient.recipientName);
            acc.recipientEmail.push(recipient.recipientEmail);
            acc.recipientMsg.push(recipient.recipientMsg);
            acc.recipientroll.push(recipient.recipientroll);
            acc.Colors.push(recipient.color);
            acc.recipienid.push(recipient.recipienid);
            acc.signatureImge.push(recipient.signatureImge);
            acc.paraaf.push(recipient.paraaf);
            acc.stemp.push(recipient.stemp);
            acc.isOwner.push(recipient.isOwner);
          });
        }
          return acc;
        },
        {
          recipientEmail: [],
          recipientMsg: [],
          recipientroll: [],
          Colors: [],
          recipientNames: [],
          recipienid: [],
          signatureImge: [],
          paraaf: [],
          stemp: [],
          isOwner: [],
        }
      );
  
      if (docname.length > 0) {
        setDocNames(docname[0].doc_name);
      }
      setRecipientNames(extractedData.recipientNames);
      setRecipientEmail(extractedData.recipientEmail);
      setRecipientMsg(extractedData.recipientMsg);
      setRecipientRoll(extractedData.recipientroll);
      setColor(extractedData.Colors);
      setRecipienid(extractedData.recipienid);
      setSignPng(extractedData.signatureImge);
      setParaafImg(extractedData.paraaf);
      setStampPng(extractedData.stemp);
      setIsowner(extractedData.isOwner);
    } else {
      console.error("docname is not an array");
    }
  }, [docname]);
  const obj = {
    recipientEmails:recipientEmails,
    recipientNames:recipientNames,
    recipientMsg:recipientMsg,
    recipientroll:recipientroll,
    Colors:Colors,
    status:status,
    docIds:docIds,
    docNames:docNames,
    signaturepng:signaturepng,
    paraafimg:paraafimg,
    stamppng:stamppng,
    recipientData:recipientData,
    recipienid:recipienid,
    isOwner:isOwner,
  }
  
  return (
    <div>
   {recipientNames.map((item,index) => (
    <React.Fragment key={`${item}_${index}`}>
       
        {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
        <div 
      key={'handwriteing'}
        id='handwriteing'
        className="css-h0trhu dragmove_content  " 
        draggable="true"
        data-allow='allow'
        data-type='handwriteing'
        data-format='handwriteing'
        data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
          >
         <button  
         data-qa="Signature" 
         data-id="tab-palette-item"
          type="button" 
         className="css-zuu3pb wrapped_object"
         >
         <span className="css-xfo8x5"  style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}}>
         <span className="css-zf56fy">
         <span aria-hidden="true" className="SVGInline">
         <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
           <path d="M16 16H0v-1h16zM13.8.3c.4.4.4 1 0 1.4L11 4.5c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L12.4.3c.3-.4 1-.4 1.4 0zM1.4 9.9 0 14.1l4.2-1.4z"></path>
           <path d="M4.9 12 11 5.9l.5-.5.9-.9c.4-.4.4-1 0-1.4L11 1.7c-.4-.4-1-.4-1.4 0l-.9.9-6.6 6.6L4.9 12z"></path>
         </svg>
         </span>
         </span>
         </span>
         <span className="css-1ny2g5x">
          
         <span className="css-195jadi">{t('Signature')}</span>
         </span>
         </button>
         </div>
         )}
         {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
         <div 
         key={'hand_praaf'}
         id='hand_praaf'
         data-allow='allow'
          className="css-h0trhu dragmove_content" 
           draggable="true"
           data-type='hand_praaf'
           data-format='hand_praaf'
           data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
        // onMouseDown={onMouseDown}
           >
           <button 
            data-qa="hand_praaf"
            data-id="tab-palette-item" 
            type="button"
             className="css-zuu3pb wrapped_object"
             >
           <span className="css-xfo8x5" style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}} >
           <span className="css-zf56fy">
           <span aria-hidden="true" className="SVGInline">
             <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
               <path d="M0 14h16v2H0v-2zM3.72.74c.79 0 1.42.13 1.91.39s.86.62 1.13 1.1c.26.48.44 1.05.54 1.73.09.67.14 1.42.14 2.25 0 .99-.06 1.85-.18 2.58s-.33 1.33-.62 1.81c-.29.47-.69.83-1.18 1.06-.5.22-1.13.34-1.88.34H0V.74h3.72zm-.44 9.59c.4 0 .72-.07.96-.21.24-.14.43-.36.58-.68.14-.31.23-.73.28-1.24.05-.51.07-1.16.07-1.92 0-.64-.02-1.2-.06-1.69-.04-.49-.13-.89-.26-1.21-.13-.32-.33-.56-.59-.72-.26-.16-.6-.24-1.03-.24h-.96v7.91h1.01zm7.5-1.52c0 .26.02.5.06.72.04.22.12.4.24.54.12.15.27.26.46.35.19.08.44.13.73.13.35 0 .66-.11.94-.34.28-.23.42-.58.42-1.05 0-.25-.03-.47-.1-.65a1.35 1.35 0 0 0-.34-.5c-.16-.15-.37-.28-.62-.4a7.91 7.91 0 0 0-.95-.37c-.5-.17-.94-.35-1.31-.55-.37-.2-.68-.43-.92-.7-.25-.28-.43-.59-.55-.94-.11-.35-.17-.76-.17-1.22 0-1.11.31-1.94.93-2.49.62-.55 1.47-.82 2.55-.82.5 0 .97.05 1.39.17.42.12.79.29 1.1.54.31.25.55.56.73.95.17.38.26.84.26 1.38v.32h-2.17c0-.54-.09-.95-.28-1.24-.19-.29-.5-.43-.95-.43-.25 0-.46.04-.63.11a1.011 1.011 0 0 0-.61.71c-.04.16-.06.32-.06.49 0 .35.07.64.22.87.15.24.46.45.95.65l1.75.76c.43.19.78.39 1.06.59.27.21.49.43.65.66.16.24.28.5.34.78.07.27.1.59.1.93 0 1.19-.34 2.05-1.03 2.59s-1.65.81-2.88.81c-1.28 0-2.2-.28-2.75-.84-.55-.56-.83-1.36-.83-2.4v-.44h2.27v.33z"></path>
             </svg>
           </span>
           </span>
           </span>
           <span className="css-1ny2g5x">
           <span className="css-195jadi">{t('initial')}</span>
           </span>
           </button>
           </div>
           )}
           {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
           <div
           id='stemps'
           key={'stemps'}
              draggable="true"
              data-type='stemps'
              data-format='stemps'
              data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
        // onMouseDown={onMouseDown}
              >
             <button   data-qa="stemps" data-id="tab-palette-item" type="button" className="css-zuu3pb wrapped_object" >
             <span className="css-xfo8x5" style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}}>
             <span className="css-zf56fy">
             <span aria-hidden="true" className="SVGInline">
               <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                 <path d="M2 14h12v2H2z"></path>
                 <path d="M14 14H2v2h12v-2zm-4-5V6.4c.9-.6 1.5-1.7 1.5-2.9C11.5 1.6 9.9 0 8 0S4.5 1.6 4.5 3.5c0 1.2.6 2.2 1.5 2.9V9H0v4h16V9h-6z"></path>
               </svg>
             </span>
             </span>
             </span>
             <span className="css-1ny2g5x">
             <span className="css-195jadi">{t('Stamp')}</span>
             </span>
             </button>
             </div>
             )}
             
             {(selectedOption === item || (!selectedOption && item === recipientNames[0])) && (
             <div 
        key={'calendar'}
        id='calendar' 
        className="css-h0trhu dragmove_content"
        draggable="true"
        data-type='calendar'
        data-format='calendar'
        data-formatid={`drag_${Date.now()}`}
        data-name={item}
        data-msg={recipientMsg[index]}
        data-mail={recipientEmails[index]}
        data-roll={recipientroll[index]}
        data-colors={Colors[index]}
        data-signpng='image'
        data-paraaf='paraaf'
        data-stamp='stemp'
        data-isOwner={isOwner[index]}
        // onMouseDown={onMouseDown}
               >
               <button   data-qa="calendar" data-id="tab-palette-item" type="button" className="css-zuu3pb wrapped_object">
               <span className="css-xfo8x5" style={{ backgroundColor: Colors[index % Colors.length] ,color:'#ffffff'}}>
               <span className="css-zf56fy">
               <span aria-hidden="true" className="SVGInline">
                 <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                   <path d="M14 2H2C.9 2 0 2.9 0 4v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM2 14V6h12v8H2zM3 0h2v2H3zm8 0h2v2h-2z"></path>
                 </svg>
               </span>
               </span>
               </span>
               <span className="css-1ny2g5x">
               <span className="css-195jadi">{t('Date Signed')}</span>
               </span>
               </button>
               </div>
              
               )}
        </React.Fragment>
      ))}
</div> 
  );
};
export default withTranslation()(withRouter(DraggableElement));

