import { parseISO } from 'date-fns';
const formatTotalHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const sumTotalHours = (totalHoursArray) => {
    return totalHoursArray.reduce((acc, time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return acc + hours * 60 + minutes; // Convert to total minutes
    }, 0);
  };

  
  const countryCalculationRules = {

    nl: (item,totalworkhours,TotalContracWeekHours,accrualUnit,Contracperday,accrualRate,MaxAccrual) => {
      
      if (item.time_off_accrual === 'contract_hours') {
          if (accrualUnit === 'hours') {
            const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
            const totalVacationMinutes = weeklyHoursInMinutes * 4; // Multiply by 4 for Dutch law
            return totalVacationMinutes;
          }else if (accrualUnit === 'days') {
            const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']);
            const totalVacationMinutes = weeklyHoursInMinutes * 4;
            const hours = Math.floor(totalVacationMinutes / 60);
            return hours / 8;  
          }
        
        } else if (item.time_off_accrual === 'worked_hours') {
          if (accrualUnit === 'hours') {
           
            const weeklyHoursInMinutes = sumTotalHours([totalworkhours || '00:00']);
            
            const workedHours  = Math.floor(weeklyHoursInMinutes / 60) ;
            let vacationHours  = workedHours * accrualRate; //0.077
            vacationHours = Math.min(vacationHours, MaxAccrual);
            let vacationMinutes  = vacationHours * 60;
            
            return vacationMinutes ; 
          }else if (accrualUnit === 'days') {
            const weeklyHoursInMinutes = sumTotalHours([totalworkhours || '00:00']);
            const hours = Math.floor(weeklyHoursInMinutes / 60);
            let totalVacationDay = hours * accrualRate; //0.077
            totalVacationDay = Math.min(totalVacationDay, MaxAccrual);

            return totalVacationDay;  
          }
          
        }
        return 0;
      },
      fr: (item, totalworkhours, TotalContracWeekHours, accrualUnit, Contracperday,accrualRate,MaxAccrual) => {
        // France Vacation Calculation (2.5 days per month worked or 5 weeks per year)
        if (item.time_off_accrual === 'contract_hours') {
          if (accrualUnit === 'hours') {
            // Calculate the vacation based on contract hours (full year = 5 weeks)
            const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
            const totalVacationMinutes = weeklyHoursInMinutes * 5; // Multiply by 5 for 5 weeks of paid vacation in France
            totalVacationMinutes = Math.min(totalVacationMinutes, MaxAccrual * 60);
            return totalVacationMinutes;
          } else if (accrualUnit === 'days') {
            // For days calculation (30 days of paid vacation per year)
            const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']);
            const totalVacationMinutes = weeklyHoursInMinutes * 5; // 5 weeks of vacation
            let totalVacationDays = totalVacationMinutes / (8 * 60); // Convert minutes to hours
            totalVacationDays = Math.min(totalVacationDays, MaxAccrual);
            return totalVacationDays; 
          }
      
        } else if (item.time_off_accrual === 'worked_hours') {
          if (accrualUnit === 'hours') {
            // Vacation based on worked hours (2.5 days per month worked)
            const weeklyHoursInMinutes = sumTotalHours([totalworkhours || '00:00']);
            const hours = Math.floor(weeklyHoursInMinutes / 60); // Convert minutes to hours
            const totalWorkedMonths = hours / TotalContracWeekHours; // Calculate the number of months worked
            const totalVacationDays = totalWorkedMonths * 2.5; // 2.5 days per month worked in France
            let totalVacationMinutes = totalVacationDays * 8 * 60; // Convert days to minutes
            totalVacationMinutes = Math.min(totalVacationMinutes, MaxAccrual * 60)
            return totalVacationMinutes; // Return the total vacation entitlement in minutes
          } else if (accrualUnit === 'days') {
            // Convert worked hours into days based on French law (2.5 days per month worked)
            const weeklyHoursInMinutes = sumTotalHours([totalworkhours || '00:00']);
            const hours = Math.floor(weeklyHoursInMinutes / 60); // Convert minutes to hours
            const totalWorkedMonths = hours / TotalContracWeekHours; // Calculate the number of months worked
            const totalVacationDays = totalWorkedMonths * 2.5; // 2.5 days per month worked in France
            totalVacationDays = Math.min(totalVacationDays, MaxAccrual);
            return totalVacationDays;
          }
        }
        return 0;
      },
      de: (item, totalworkhours, TotalContracWeekHours, accrualUnit, Contracperday, accrualRate, MaxAccrual) => {
        const statutoryVacationWeeks = 4; // 4 weeks minimum as per German law
        
        if (item.time_off_accrual === 'contract_hours') {
            if (accrualUnit === 'hours') {
                const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
                let totalVacationMinutes = weeklyHoursInMinutes * statutoryVacationWeeks; // Multiply by 4 weeks
                totalVacationMinutes = Math.min(totalVacationMinutes, MaxAccrual * 60); // Apply MaxAccrual limit (converted to minutes)
                return totalVacationMinutes; // Return total vacation in minutes
            } else if (accrualUnit === 'days') {
                const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Weekly contracted hours
                let totalVacationMinutes = weeklyHoursInMinutes * statutoryVacationWeeks; // Multiply by 4 weeks
                let totalVacationDays = totalVacationMinutes / (8 * 60); // Convert minutes to days (assuming 8 hours/day)
                totalVacationDays = Math.min(totalVacationDays, MaxAccrual); // Apply MaxAccrual limit for days
                return totalVacationDays;
            }
        } else if (item.time_off_accrual === 'worked_hours') {
            if (accrualUnit === 'hours') {
                const totalWorkedMinutes = sumTotalHours([totalworkhours || '00:00']); // Convert worked hours to minutes
                const workedHours = totalWorkedMinutes / 60; // Convert minutes to hours
                let totalVacationHours = workedHours * (statutoryVacationWeeks / 52); // Pro-rate based on worked hours
                totalVacationHours = Math.min(totalVacationHours, MaxAccrual); // Apply MaxAccrual for hours
                return totalVacationHours * 60; // Return total vacation in minutes
            } else if (accrualUnit === 'days') {
                const totalWorkedMinutes = sumTotalHours([totalworkhours || '00:00']); // Convert worked hours to minutes
                const workedHours = totalWorkedMinutes / 60; // Convert minutes to hours
                let totalVacationDays = workedHours * (statutoryVacationWeeks / 52) / 8; // Convert hours to days
                totalVacationDays = Math.min(totalVacationDays, MaxAccrual); // Apply MaxAccrual for days
                return totalVacationDays;
            }
        }
    
        return 0; // Return 0 if neither 'contract_hours' nor 'worked_hours' applies
    },

    be: (item, totalworkhours, TotalContracWeekHours, accrualUnit, Contracperday, accrualRate, MaxAccrual) => {
      const statutoryVacationWeeks = 4; // 4 weeks of paid vacation in Belgium
  
      // Vacation accrual based on contract hours
      if (item.time_off_accrual === 'contract_hours') {
          if (accrualUnit === 'hours') {
              const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
              let totalVacationMinutes = weeklyHoursInMinutes * statutoryVacationWeeks; // Multiply by 4 weeks
  
              // Apply MaxAccrual if specified
              totalVacationMinutes = MaxAccrual ? Math.min(totalVacationMinutes, MaxAccrual * 60) : totalVacationMinutes;
              return totalVacationMinutes; // Return total vacation minutes
          } else if (accrualUnit === 'days') {
              const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
              const totalVacationMinutes = weeklyHoursInMinutes * statutoryVacationWeeks; // Multiply by 4 weeks
              const hours = Math.floor(totalVacationMinutes / 60); // Convert minutes to hours
              const totalVacationDays = hours / 8; // Convert to days (assuming 8 hours per day)
  
              // Apply MaxAccrual if specified
              return MaxAccrual ? Math.min(totalVacationDays, MaxAccrual) : totalVacationDays;
          }
  
      // Vacation accrual based on worked hours
      } else if (item.time_off_accrual === 'worked_hours') {
          if (accrualUnit === 'hours') {
              const workedMinutes = sumTotalHours([totalworkhours || '00:00']); // Convert worked hours to minutes
              const workedHours = Math.floor(workedMinutes / 60); // Convert minutes to hours
              let totalVacationHours = workedHours * (accrualRate || 0.092); // Use accrualRate, defaulting to 9.2% for Belgium
              totalVacationHours = MaxAccrual ? Math.min(totalVacationHours, MaxAccrual) : totalVacationHours;
              return totalVacationHours * 60; // Convert vacation hours back to minutes
          } else if (accrualUnit === 'days') {
              const workedMinutes = sumTotalHours([totalworkhours || '00:00']); // Convert worked hours to minutes
              const workedHours = Math.floor(workedMinutes / 60); // Convert minutes to hours
              let totalVacationDays = workedHours * (accrualRate || 0.092) / 8; // Convert worked hours to days (assuming 8 hours per day)
              totalVacationDays = MaxAccrual ? Math.min(totalVacationDays, MaxAccrual) : totalVacationDays;
              return totalVacationDays;
          }
      }
  
      return 0; // Default to 0 if no accrual logic applies
  },
  
      
  uk: (item, totalworkhours, TotalContracWeekHours, accrualUnit, Contracperday, accrualRate, MaxAccrual) => {
    const statutoryVacationWeeks = 5.6; // 5.6 weeks of paid leave in the UK

    // Vacation accrual based on contract hours
    if (item.time_off_accrual === 'contract_hours') {
        if (accrualUnit === 'hours') {
            const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
            let totalVacationMinutes = weeklyHoursInMinutes * statutoryVacationWeeks; // Multiply by 5.6 weeks

            // Apply MaxAccrual if specified
            totalVacationMinutes = MaxAccrual ? Math.min(totalVacationMinutes, MaxAccrual * 60) : totalVacationMinutes;
            return totalVacationMinutes; // Return total vacation in minutes
        } else if (accrualUnit === 'days') {
            const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
            let totalVacationMinutes = weeklyHoursInMinutes * statutoryVacationWeeks; // Multiply by 5.6 weeks
            const hours = Math.floor(totalVacationMinutes / 60); // Convert minutes to hours
            const totalVacationDays = hours / 8; // Convert to days (assuming 8 hours per day)

            // Apply MaxAccrual if specified
            return MaxAccrual ? Math.min(totalVacationDays, MaxAccrual) : totalVacationDays;
        }

    // Vacation accrual based on worked hours
    } else if (item.time_off_accrual === 'worked_hours') {
        if (accrualUnit === 'hours') {
            const workedMinutes = sumTotalHours([totalworkhours || '00:00']); // Convert worked hours to minutes
            const workedHours = Math.floor(workedMinutes / 60); // Convert minutes to hours
            let totalVacationHours = workedHours * (statutoryVacationWeeks / 52); // Pro-rate based on 5.6 weeks over 52 weeks
            totalVacationHours = MaxAccrual ? Math.min(totalVacationHours, MaxAccrual) : totalVacationHours;
            return totalVacationHours * 60; // Convert vacation hours back to minutes
        } else if (accrualUnit === 'days') {
            const workedMinutes = sumTotalHours([totalworkhours || '00:00']); // Convert worked hours to minutes
            const workedHours = Math.floor(workedMinutes / 60); // Convert minutes to hours
            let totalVacationDays = (workedHours * (statutoryVacationWeeks / 52)) / 8; // Convert hours to days (assuming 8 hours/day)
            totalVacationDays = MaxAccrual ? Math.min(totalVacationDays, MaxAccrual) : totalVacationDays;
            return totalVacationDays;
        }
    }

    return 0; // Return 0 if neither 'contract_hours' nor 'worked_hours' applies
},

us: (item, totalworkhours, TotalContracWeekHours, accrualUnit, Contracperday, accrualRate, MaxAccrual) => {
  const standardVacationWeeks = 2; // Common for new employees (2 weeks of vacation)

  // Vacation accrual based on contract hours
  if (item.time_off_accrual === 'contract_hours') {
      if (accrualUnit === 'hours') {
          // Calculate vacation based on contracted hours
          const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Convert weekly hours to minutes
          let totalVacationMinutes = weeklyHoursInMinutes * standardVacationWeeks; // Multiply by standard vacation weeks

          // Apply MaxAccrual if specified
          totalVacationMinutes = MaxAccrual ? Math.min(totalVacationMinutes, MaxAccrual * 60) : totalVacationMinutes;
          return totalVacationMinutes; // Return total vacation in minutes
      } else if (accrualUnit === 'days') {
          // Calculate vacation in days based on contracted hours
          const weeklyHoursInMinutes = sumTotalHours([TotalContracWeekHours || '00:00']); // Weekly contracted hours
          let totalVacationMinutes = weeklyHoursInMinutes * standardVacationWeeks; // Multiply by standard vacation weeks
          const hours = Math.floor(totalVacationMinutes / 60); // Convert minutes to hours
          const totalVacationDays = hours / 8; // Convert to days (assuming 8 hours per day)

          // Apply MaxAccrual if specified
          return MaxAccrual ? Math.min(totalVacationDays, MaxAccrual) : totalVacationDays;
      }

  // Vacation accrual based on worked hours
  } else if (item.time_off_accrual === 'worked_hours') {
      if (accrualUnit === 'hours') {
          // Calculate vacation based on actual worked hours
          const workedMinutes = sumTotalHours([totalworkhours || '00:00']); // Total worked hours in minutes
          const workedHours = Math.floor(workedMinutes / 60); // Convert minutes to hours
          let totalVacationHours = workedHours * (standardVacationWeeks / 52); // Pro-rate vacation based on worked hours
          totalVacationHours = MaxAccrual ? Math.min(totalVacationHours, MaxAccrual) : totalVacationHours;
          return totalVacationHours * 60; // Convert vacation hours back to minutes
      } else if (accrualUnit === 'days') {
          // Calculate vacation in days based on worked hours
          const workedMinutes = sumTotalHours([totalworkhours || '00:00']); // Total worked hours in minutes
          const workedHours = Math.floor(workedMinutes / 60); // Convert minutes to hours
          let totalVacationDays = (workedHours * (standardVacationWeeks / 52)) / 8; // Convert hours to days (assuming 8-hour days)
          totalVacationDays = MaxAccrual ? Math.min(totalVacationDays, MaxAccrual) : totalVacationDays;
          return totalVacationDays;
      }
  }

  return 0; // Return 0 if neither 'contract_hours' nor 'worked_hours' applies
},

      
    // Add more country-specific rules here
  };
  

  function calculateTenure(startdate, enddate) {
    // Parse the dates
    const start = new Date(startdate);
    const end = new Date(enddate);

    // Calculate the difference in years
    let years = end.getFullYear() - start.getFullYear();

    // Adjust for months and days to ensure full years are counted
    if (
        end.getMonth() < start.getMonth() || 
        (end.getMonth() === start.getMonth() && end.getDate() < start.getDate())
    ) {
        years--; // Subtract one year if end date is before the same month/day in the end year
    }

    return years;
}

  export const calculateAbsence = (getabsence,contracWithuren,absenceTotal,selectedYear) => {
    let currentBalance = 0;
    let accrualUnitType = 'hours';
    let typename = ''
    let accrualStartDate 
    
    if (contracWithuren && contracWithuren.total_work_hours && absenceTotal) {
      const totalworkhours = contracWithuren.total_work_hours 
    ? contracWithuren.total_work_hours.slice(0, 5) 
    : "00:00"

    
    const TotalWorkDays = contracWithuren.total_work_days || 0; // Default to 0 if undefined
    const TotalContracWeekHours = contracWithuren.total_week_hours || "00:00"; // Default to "00:00" if undefined
    const Contracperday = contracWithuren.contract_Perday ? contracWithuren.contract_Perday.split(',') : []
    const ContracDays = contracWithuren.contract_Days ? contracWithuren.contract_Days.split(',') : []
    getabsence.forEach((item,index) => {
     // console.log(`Processing item ${index + 1}:`, item);
      const countryCode = item.country?.toLowerCase() || 'default'; 
     
      const accrualUnit = item.accrual_unit;
      accrualUnitType = item.accrual_unit;
      typename = item.type_name
      const timeOffAccrual = item.time_off_accrual;
      const accrualRate = item.accrual_rate;
      const MaxAccrual = item.max_accrual;
      const supportsWaitHours = item.supports_wait_hours;
      const waitingPeriod = item.waiting_period;
      const disableHourCalculation = item.disable_hour_calculation;
    // console.log(`Country Code: ${countryCode}, Accrual Unit: ${accrualUnit}, Time Off Accrual: ${timeOffAccrual}`);
    
    if (supportsWaitHours === 1 && waitingPeriod) {
      const contractStartDate = new Date(contracWithuren.contract_Start);
     
       accrualStartDate = new Date(contractStartDate);
      accrualStartDate.setDate(contractStartDate.getDate() + waitingPeriod);
      // Skip accrual if the waiting period is not yet met
      if (new Date() < accrualStartDate) {
        console.log(`Accrual waitig period of ${waitingPeriod} days not met yet.`);
        return;
      }
    }else{
      accrualStartDate = new Date(contracWithuren.contract_Start);
    }
    

      const calculationFunction = countryCalculationRules[countryCode] || ((item) => 0);
      
      const minutes = calculationFunction(item,totalworkhours,TotalContracWeekHours,accrualUnit,Contracperday,accrualRate,MaxAccrual);
      //console.log(`Calculated Minutes for ${countryCode}: ${minutes}`)
      currentBalance = minutes;
    });
    const contractStartYear = new Date(contracWithuren.contract_Start).getFullYear()
 
    if (accrualUnitType === 'hours') {
     // console.log(currentBalance,'tenure')
      const hours = Math.floor(currentBalance / 60);
      const totalEntitlementHours = hours 
      
      const totalAbsenceHoursPreviousYear = absenceTotal.total_absence_hours_previous_year
      const remainingDaysPreviousYear = contractStartYear <= selectedYear 
      ? 0 
      : totalEntitlementHours - totalAbsenceHoursPreviousYear;
       
      const totalStart = remainingDaysPreviousYear + hours;
      const Newhours = Math.floor(totalStart); 
      const minutes = Math.round((totalStart - Newhours) * 60);
      const startSaldo = `${String(Newhours).padStart(2, '0')} h :${String(minutes).padStart(2, '0')} m`;
      const startDate = new Date(accrualStartDate)
      const endOfYear = new Date(contracWithuren.contract_End);
     
      const hoursTakenCurrentYear = absenceTotal.total_absence_hours_current_year;
      const totalDaysInYear = (new Date(`${selectedYear}-12-31`) - new Date(`${selectedYear}-01-01`)) / (1000 * 60 * 60 * 24) + 1;
      const remainingDaysInYear = (endOfYear - startDate) / (1000 * 60 * 60 * 24);
      const proratedEntitlement = (remainingDaysInYear / totalDaysInYear) * hours;
      
      const endBalance = (proratedEntitlement + remainingDaysPreviousYear)- hoursTakenCurrentYear ;
      const endhours = Math.floor(endBalance); // Extract the hours
      const endminutes = Math.round((endBalance - endhours) * 60); 
      const EndBalance = `${String(endhours).padStart(2, '0')} h : ${String(endminutes).padStart(2, '0')} m`;
      const typeName = typename  
      
    const totalAbsence = `${absenceTotal.total_absence_hours_current_year}:00`
    
    const Today = `${totalStart -absenceTotal.total_absence_hours_current_year}:00 hours`

    return {startSaldo,totalAbsence,typeName,EndBalance,Today};
    } else if (accrualUnitType === 'days') {
    
      const totalEntitlementDays = currentBalance
      const totalAbsenceDaysPreviousYear = absenceTotal.total_absence_days_previous_year;
      const remainingDaysPreviousYear = contractStartYear <= selectedYear 
    ? totalAbsenceDaysPreviousYear - totalAbsenceDaysPreviousYear 
    : 0;
      const startDate = new Date(accrualStartDate)
      const endOfYear = new Date(contracWithuren.contract_End);
      const totalDaysInYear = (new Date(`${selectedYear}-12-31`) - new Date(`${selectedYear}-01-01`)) / (1000 * 60 * 60 * 24) + 1;
      const remainingDaysInYear = (endOfYear - startDate) / (1000 * 60 * 60 * 24);
      const proratedEntitlement = (remainingDaysInYear / totalDaysInYear) * totalEntitlementDays;
      const EndBalance = formatDaysToDHm(remainingDaysPreviousYear + proratedEntitlement);
      const typeName = typename  
      const startSaldo = formatDaysToDHm(currentBalance + remainingDaysPreviousYear);
      const totalAbsence = `${absenceTotal.total_absence_days_current_year} days`
      const Today = `${(currentBalance + remainingDaysPreviousYear) - absenceTotal.total_absence_days_current_year} days`
    return {startSaldo,totalAbsence,typeName,EndBalance,Today};
    }

    }
    
  };



  function formatDaysToDHm(totalDays) {
    // Get the full number of days
    const days = Math.floor(totalDays);

    // Get the decimal part and convert it to hours
    const remainingDecimal = totalDays - days;
    const totalHours = remainingDecimal * 24;
    const hours = Math.floor(totalHours);

    // Convert remaining decimal of hours to minutes
    const remainingMinutes = (totalHours - hours) * 60;
    const minutes = Math.round(remainingMinutes);

    // Return the formatted result
    return `${days} d, ${hours} h, ${minutes} m`;
}
  export const sumTotalPreviousDayHours = (previousRemainHours, selectedYear,getabsence) => {
    // Ensure previousRemainHours is an array
    if (!Array.isArray(previousRemainHours)) {
      console.error("previousRemainHours is not an array:", previousRemainHours);
      return { preUniqueData: [], currentUniqueData: [] };
    }
  
    const currentYearData = [];
    const previousYearData = [];
  
    // Process the data and remove duplicates while separating current and previous year data
    previousRemainHours.forEach(currentItem => {
      const itemDate = parseISO(currentItem.uren_date); // Parse the date
      const itemYear = itemDate.getFullYear(); // Extract the year
  
      // Separate current year data and previous year data based on selectedYear
      if (itemYear === parseInt(selectedYear, 10)) {
        currentYearData.push(currentItem); // Data for the selected year
      } else if (itemYear < parseInt(selectedYear, 10)) {
        previousYearData.push(currentItem); // Data for years before the selected year
      }
    });
  
    // Remove duplicates based on `uren_date` for both previous and current year data
    const uniqueCurrentYearData = currentYearData.reduce((acc, currentItem) => {
      const foundItem = acc.find(item => new Date(item.uren_date).getTime() === new Date(currentItem.uren_date).getTime());
      if (!foundItem) {
        acc.push(currentItem); // Add unique item
      }
      return acc;
    }, []);
  
    const uniquePreviousYearData = previousYearData.reduce((acc, currentItem) => {
      const foundItem = acc.find(item => new Date(item.uren_date).getTime() === new Date(currentItem.uren_date).getTime());
      if (!foundItem) {
        acc.push(currentItem); // Add unique item
      }
      return acc;
    }, []);
  
    // Return both previous and current year data
    return {
      preUniqueData: uniquePreviousYearData.length > 0 ? uniquePreviousYearData : [],
      currentUniqueData: uniqueCurrentYearData.length > 0 ? uniqueCurrentYearData : []
    };
  };
  
  
  export const sumTotalPreviousDayHours1 = (previousRemainHours, selectedYear) => {
    // Ensure previousRemainHours is an array
    if (!Array.isArray(previousRemainHours)) {
      console.error("previousRemainHours is not an array:", previousRemainHours);
      return '';
    }
  
    // Filter the data for the selected year
    const filteredData = previousRemainHours.filter(item => {
        const itemDate = parseISO(item.uren_date); // Ensure consistent date parsing
        const itemYear = itemDate.getFullYear();   // Extract the year
        return itemYear === parseInt(selectedYear, 10); // Compare with selected year
      });

      if (filteredData.length === 0) {
        console.warn(`No data found for the year ${selectedYear}`);
      }
    // Remove duplicates based on uren_date
    const uniqueData = filteredData.reduce((acc, currentItem) => {
      const foundItem = acc.find(item => new Date(item.uren_date).getTime() === new Date(currentItem.uren_date).getTime());
  
      if (!foundItem) {
        acc.push(currentItem);
      }
  
      return acc;
    }, []);
  

    return uniqueData.length > 0 ? uniqueData :` No data for ${selectedYear}`;
  };
export const sumTotalDayHours = (time1, time2) => {

    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);
    const totalMinutes = (hours1 * 60 + minutes1) + (hours2 * 60 + minutes2);
  
    const finalHours = Math.floor(totalMinutes / 60);
    const finalMinutes = totalMinutes % 60;
  
    return `${String(finalHours).padStart(2, '0')}:${String(finalMinutes).padStart(2, '0')}`;
  };

  
  export const convertTimeToDecimal = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours + minutes / 60;
  };
  
  export const convertDecimalToTime = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };
  
  