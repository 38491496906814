import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_SIGNATURESIMG,
  GET_DOCUMENTEN,
  POST_SIGN_DATA,
  GET_SIGN_UPDATE,
  
  } from "./actionTypes";
 import {
  getSignatureImgByEmailSuccess,
  getSignatureImgByEmailFail,
  getDocumentSuccess,
  getDocumentFail,
  postSigndatatSuccess,
  postSigndataFail,
  getSignSuccess,
  getSignFail,
  } from "./actions";

  //Include Both Helper File with needed methods
import {
  getSignatureImg,
  getDocumentName,
  postSignData,
  postUpdateDoc,
  getSignerData,

  } from "helpers/fakebackend_helper";
 
  function* fetchSignatureImg({signaturesemail}) {
    try {
      const response = yield call(getSignatureImg, signaturesemail);
      yield put(getSignatureImgByEmailSuccess(response));
    } catch (error) {
      yield put(getSignatureImgByEmailFail(error));
    }
  }
  function* fetchDocuments({ payload: uuid  }) {
    try {
      const response = yield call(getDocumentName, uuid);
      yield put(getDocumentSuccess(response));
    } catch (error) {
      yield put(getDocumentFail(error));
    }
  }
  function* onPostSignData({ payload: jsonpostdata }) {
    try {
      const response = yield call(postSignData, jsonpostdata);
      yield put(postSigndatatSuccess(response));
    } catch (error) {
      yield put(postSigndataFail(error));
    }
  }
  function* onGetSignData({ payload: uuid }) {
    try {
      const response = yield call(getSignerData, uuid);
      yield put(getSignSuccess(response));
    } catch (error) {
      yield put(getSignFail(error));
    }
  }
  function* DocumentInfoSaga() {
    yield takeEvery(GET_SIGNATURESIMG, fetchSignatureImg);
    yield takeEvery(GET_DOCUMENTEN, fetchDocuments);
    yield takeEvery(POST_SIGN_DATA, onPostSignData);
    yield takeEvery(GET_SIGN_UPDATE, onGetSignData);
   
  }
  
  export default DocumentInfoSaga;
