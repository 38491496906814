import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
  } from "reactstrap";
  import PropTypes from "prop-types";
  import {
    blogData,
    blogCatData,
  } from "../../common/data";
  import { Link ,useLocation,useParams  } from "react-router-dom";
  import CustomNavbar from "components/HeaderUnUser/Header";
  import { withTranslation } from "react-i18next";
  import withRouter from "components/Common/withRouter";
const BlogView = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language 
  const location = useLocation();
  const { title } = useParams();

    useEffect(() => {
        const headerColor = document.getElementById('header_color');
      
        // Check if the element exists before accessing it
        if (headerColor) {
          headerColor.style.backgroundColor = '#ffffff';
        }
        const languageSwitcher = document.getElementById('language-switcher');
        if (languageSwitcher) {
          languageSwitcher.disabled = true;
        }
      
      }, [])

      const filteredBlogs = blogData.filter(blog => blog.lang === currentLanguage && blog.bslug === title);
      useEffect(() => {
        if (filteredBlogs.length === 0) {
          window.location.href = '/';
        }
      }, [filteredBlogs]);
      const filteredPopBlogs = blogData.filter(blog => blog.lang === currentLanguage);

      // Then, sort by the updated_at field in descending order to get the latest blogs
      const sortedBlogs = filteredPopBlogs.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      const latestBlogs = sortedBlogs.slice(0, 5);
      const popularPosts = latestBlogs.map(blog => ({
        imageSrc: blog.image, // Assuming 'blog.image' holds the image URL
        title: blog.title, // The blog title
        slug: blog.bslug,
        date: new Date(blog.updated_at).toLocaleDateString('en-US', { // Format the date
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      }));

      const tagsData = latestBlogs.map(blog => ({
        id: blog.category, // Assuming 'blog.image' holds the image URL
        name: blog.category_name, // The blog title
        slug: blog.cslug,
      }));
      useEffect(() => {
      
        // Function to update meta tags
        const updateMetaTags = () => {
          document.title = t(`${filteredBlogs[0].seo_description} - ${filteredBlogs[0].seo_title} | Shiftspec.com`);
          const metaElement = document.querySelector('meta[name="description"]');
          if (metaElement) {
            metaElement.setAttribute('content', `${filteredBlogs[0].title} || '' `);
          } else {
            addOrUpdateMeta('description', `${filteredBlogs[0].seo_description} || '' `);
          }
          addOrUpdateMeta('keywords', filteredBlogs[0].meta_tags);
          function addOrUpdateMeta(name, content) {
            // Check if a meta tag with the given name already exists
            let metaTag = document.querySelector(`meta[name="${name}"]`);
            
            if (metaTag) {
              // If the meta tag exists, update its content
              metaTag.setAttribute('content', content);
            } else {
              // If the meta tag doesn't exist, create it
              metaTag = document.createElement('meta');
              metaTag.setAttribute('name', name);
              metaTag.setAttribute('content', content);
              document.head.appendChild(metaTag); // Append it to <head>
            }
          }
          
          // Array of meta tags to add/update
          const metaTags = [
            { name: 'description', content: `${filteredBlogs[0].seo_title} || '' `  },
            { name: 'keywords', content: `${filteredBlogs[0].meta_tags} || '' ` },
            { name: 'author', content: 'Elyar Semi' }
          ];
          
          // Iterate over the metaTags array and add/update each meta tag
          metaTags.forEach(tag => {
            addOrUpdateMeta(tag.name, tag.content);
          });
          // Helper function to add or update meta tags
          const addOrUpdateMetaTag = (property, content) => {
            let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
            if (tag) {
              tag.setAttribute('content', content);
            } else {
              tag = document.createElement('meta');
              tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
              tag.setAttribute('content', content);
              document.head.appendChild(tag);
            }
          };
    
          // Open Graph Meta Tags
          addOrUpdateMetaTag('og:title', filteredBlogs[0]?.seo_title || 'Help Center');
          addOrUpdateMetaTag('og:description', filteredBlogs[0]?.seo_description || 'Find helpful articles to get started with ShiftSpec');
          addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
          addOrUpdateMetaTag('og:type', 'website');
    
          // Twitter Meta Tags
          addOrUpdateMetaTag('twitter:title', filteredBlogs[0]?.seo_title || 'Help Center');
          addOrUpdateMetaTag('twitter:description', filteredBlogs[0]?.seo_description || 'Find helpful articles to get started with ShiftSpec');
          addOrUpdateMetaTag('twitter:card', 'summary_large_image');
        };
    
        // Call the function to update meta tags
        updateMetaTags();
    
      }, [ location.pathname, t,filteredBlogs]);
  return (
    <React.Fragment>
    <CustomNavbar />

<section className="relative bg-white flex justify-content-center  overflow-hidden">
      <Row>
        {/* Category Sidebar Column */}
        

        {/* Blog Section Column */}
        <Col xs={12} sm={12} md={8} className="flex align-items-center justify-content-centerr">
        <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                    <div className="col-xl-8">
      {filteredBlogs.length > 0 ? (
        filteredBlogs.map((blog) => (
          <div key={blog.id}>
            {/* Blog Header */}
            <div className="text-center">
              <div className="mb-4">
                <Link to="#" className="badge bg-light font-size-12">
                  <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i> 
                  {blog.category_name}
                </Link>
              </div>
              <h1 className="text-2xl font-extrabold text-dark-30 mb-3">{blog.title}</h1>
              <p className="text-muted mb-4">
                <i className="mdi mdi-calendar me-1"></i> {new Date(blog.created_at).toLocaleDateString()}
              </p>
            </div>

            <hr />

            {/* Blog Metadata */}
            <div className="text-center">
              <Row>
                <Col sm={4}>
                  <div>
                    <p className="text-muted mb-2">{t('Categories')}</p>
                    <h5 className="font-size-15">{blog.category_name}</h5>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="mt-4 mt-sm-0">
                    <p className="text-muted mb-2">{t('Date')}</p>
                    <h5 className="font-size-15">{new Date(blog.created_at).toLocaleDateString()}</h5>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="mt-4 mt-sm-0">
                    <p className="text-muted mb-2">{t('Post by')}</p>
                    <h5 className="font-size-15">{blog.author}</h5>
                  </div>
                </Col>
              </Row>
            </div>

            <hr />

            {/* Blog Image */}
            <div className="my-5">
              <img
                src={blog.image}
                alt={blog.title}
                className="img-thumbnail mx-auto d-block"
              />
            </div>

            <hr />

            <div className="mt-4">
  <div
    className="text-muted font-size-14"
    dangerouslySetInnerHTML={{ __html: blog.content }} 
  /> 
  <hr />
</div>

          </div>
        ))
      ) : (
        <p>{t('No blog found for the selected language and title.')}</p>
      )}
    </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
        </Col>

        <Col xl={3} lg={4} className="mb-4">
          <Card>
            <CardBody>
            <div>
              <p className="text-muted mb-2">{t('Latest Blogs')}</p>

              <div className="list-group list-group-flush">
                {(popularPosts || []).map((item, index) => (
                  <Link to={`/blog/${item.slug}`} className="list-group-item text-muted py-3 px-2" key={index}>
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <img
                          src={item.imageSrc}
                          alt=""
                          className="avatar-md h-auto d-block rounded"
                        />
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-13 text-truncate">{item.title}</h5>
                        <p className="mb-0 text-truncate">{item.date}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <hr className="my-4" />

<div>
  <p className="text-muted mb-1">{t('Tags')}</p>

  <ul className="list-inline widget-tag">
    {
      (tagsData || []).map((item) => (
        <li className="list-inline-item" key={item.id}>
          <Link to={`/blog/tag/${item.slug}`} className="badge bg-light font-size-12 mt-2">
            {item.name}
          </Link>
        </li>
      ))
    }
  </ul>
</div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </section>

    <section className="relative lg:pb-10 pt-20 overflow-hidden bg-white">
  <Row>


  <Col sm={12} className="d-flex flex-column align-items-center justify-content-center text-center">
    <h2 className="font-bold text-dark text-[36px] sm:text-2xl md:text-3xl lg:text-[34px] lg:leading-[40px] leading-[40px] font-extrabold mb-4 mt-4">
    {t('A personal tour of Shiftspec')} 
      </h2>

      <a href='/register/starter'>
        <button
          title="Purchase"
          
          role="button"
          type="submit"
          className="css-pjhtp2"
          style={{ display: 'block', marginTop: '20px', marginBottom: '20px' }}
        >
          <span className="css-13wylk3">{t('Request a demo')}</span>
        </button>
      </a>
    </Col>

  </Row>
</section>

    </React.Fragment>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#00a0f0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    color: '#555',
  },
};

BlogView.propTypes = {
  history: PropTypes.object,
  t: PropTypes.isRequired,
};
export default withRouter(withTranslation()(BlogView));

