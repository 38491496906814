import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import './css.css';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import FileViewer from "react-file-viewer";
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
import {
  Button,
  Card,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  TabContent,
  TabPane,
  Form ,
  UncontrolledCollapse
} from "reactstrap";
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  getXlsdata,
  getcollaborator as onGetcollaborator,
  getVhicles as onGetVhicles,
  postVhicles as onPostgetVhicles,
  putVhicles as onPutVhicles,
  delVhicles as onDelVhicles,
  getVhiclesDoc as onGetVhiclesDoc,
  postVhiclesDoc as onPostVhiclesDoc,
  putVhiclesDoc as onPutVhiclesDoc,
  delVhiclesDoc as onDelVhiclesDoc,
} from "store/actions";
import Select from "react-select"
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};
//redux
import { useSelector, useDispatch } from "react-redux";
import { format, parseISO, isBefore, isEqual } from 'date-fns';

const S3_BUCKET = 'shiftspec';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5CBDQX2KENTO7LJY';
const SECRET_ACCESS_KEY = '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';
AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION
});
const s3 = new AWS.S3();
const Details = (props) => {
  const { fleet_id } = useParams();
  const {
    getVhiclesdata,
    xlsid,
    postVhiclesdata,
    putVhiclesdata,
    delVhiclesdata,
    getVhiclesDocdata,
    postVhiclesDocdata,
    putVhiclesDocdata,
    delVhiclesDocdata,
    client,
    error
  } = useSelector(state => ({
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    getVhiclesdata: state.Vhicles.getVhiclesdata,
    postVhiclesdata: state.Vhicles.postVhiclesdata,
    putVhiclesdata: state.Vhicles.putVhiclesdata,
    delVhiclesdata: state.Vhicles.delVhiclesdata,
    getVhiclesDocdata: state.Vhicles.getVhiclesDocdata,
    postVhiclesDocdata: state.Vhicles.postVhiclesDocdata,
    putVhiclesDocdata: state.Vhicles.putVhiclesDocdata,
    delVhiclesDocdata: state.Vhicles.delVhiclesDocdata,
    error: state.mails.error,
  }));
  
  const dispatch = useDispatch();
  const { t } = props;
  const navigate = useNavigate();
  const { permissions, loading } = useUserPermissions();

  const { rule, template_content = [], editor, plan_type, plan_status, plan_name } = permissions || {};

  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const [modal_small, setmodal_small] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [viewerKeys, setViewerKeys] = useState({});

  useEffect(() => {
    document.title = t("Vehicle Details | Comprehensive Management for Fleet Efficiency - ShiftSpec");
  }, [t]);

  //meta title xlsid
  useEffect(() => {
    dispatch(getXlsdata(account_id));
    dispatch(onGetcollaborator(account_id));
    dispatch(onGetVhicles(account_id));
    dispatch(onGetVhiclesDoc(fleet_id));
  }, [dispatch,fleet_id]);

  const [modalVisible, setModalVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [fleetData, setFleetData] = useState(null);
  const [currentPageGalary, setCurrentPageGalary] = useState(1);
  const [isGallery, setisGallery] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  useEffect(() => {
    // Simulating fetching data. Replace with actual data fetching logic.
    const fetchClientData = async () => {
      const fleetIdNumber = parseInt(fleet_id , 10); // Convert string to number
      const data = getVhiclesdata.find(item => item.fleet_id === fleetIdNumber);
 
     
      setFleetData(data);
    };
  
    fetchClientData();
  }, [fleet_id,getVhiclesdata]);

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const handleDrop = useCallback(async (acceptedFiles, type) => {
    const file = acceptedFiles[0];
    if (file) {
      setIsLoading(true);
      setImagePreview(null);
      const fileUrl = URL.createObjectURL(file);
      const params = {
        Bucket: S3_BUCKET,
        Key: `${company_name}/${type}/${file.name}`, // Path or key where file will be stored in S3
        Body: file,
        ACL: 'public-read', // Set file as public read
      };
      try {
        const data = await s3.upload(params).promise();
        // Get the URL from the response
        const fileUrl = data.Location;
        setTimeout(() => {
          setImagePreview(fileUrl);
          setIsLoading(false); // End loading
        }, 300)

        const formData = {
          id:fleet_id,
          tableName:"Vehicles",
          columnName:'fleet_image',
          value:fileUrl,
          condition:"fleet_id",
        }

        dispatch(onPutVhicles(formData));
        setModalVisible(!modalVisible);
        // Update form data with the S3 file URL
      } catch (error) {
        console.error('Error uploading file:', error);
        setIsLoading(false);
      }
     
    }
  }, []);


  const createDropzoneConfig = (type) => ({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, type),
    accept: 'image/*',
    maxFiles: 1,
  });

  const { getRootProps: getRootPropsProfile, getInputProps: getInputPropsProfile } = useDropzone(createDropzoneConfig('profile'));


  const handleModalToggle = () => {
    setModalVisible(!modalVisible);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
  };

  const handleImageClick = () => {
    setIsLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setIsLightboxOpen(false);
  };

function removeBodyCss() {
  document.body.classList.add("no_padding");
}

function tog_small() {
  setmodal_small(!modal_small);
  removeBodyCss();
}
const handleSubmitstatus = (e) => {
  e.preventDefault();
  const formData = {
    id:fleet_id,
    tableName:"Vehicles",
    columnName:'fleet_status',
    value:selectedStatus.value,
    condition:"fleet_id",
  }

  dispatch(onPutVhicles(formData));
  // Further logic to submit the selected status
};
const statusOptions = [
  { value: "Active", label: t("Active") },
  { value: "Inactive", label: t("Inactive") },
  { value: "Maintenance", label: t("Maintenance") },
  { value: "Retired", label: t("Retired") },
 
];


const statementDocTypes = Array.from(
  new Set(
    getVhiclesDocdata
      .filter((type) => type.folder_doc === "statement")
      .map((type) => type.doc_type) // Extract only doc_type for uniqueness
  )
).map((uniqueDocType) => {
  // Find the first matching item for the unique doc_type
  const correspondingItem = getVhiclesDocdata.find(
    (type) => type.doc_type === uniqueDocType && type.folder_doc === "statement"
  );
  return {
    value: correspondingItem.document_id,
    label: correspondingItem.doc_type,
  };
});

const galleryUniqueDocTypes = Array.from(
  new Set(getVhiclesDocdata.filter((type) => type.folder_doc === "gallery").map((type) => type.doc_type))
).map((docType) => {
  const correspondingItem = getVhiclesDocdata.find((type) => type.doc_type === docType && type.folder_doc === "gallery");
  return {
    value: correspondingItem.document_id,
    label: correspondingItem.doc_type,
  };
});
const handleStatusChange = (selectedOption) => {
  setSelectedStatus(selectedOption);
};





const [selectedDocType, setSelectedDocType] = useState("");
const [file, setFile] = useState(null);
const [selectedDocTypeGalary, setSelectedDocTypeGalary] = useState("");
const [fileGalary, setFileGalary] = useState(null);

const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  if (selectedFile) {
    setFile(selectedFile);
  }
};
const handleFileChangeGalary = (e) => {
  const selectedFile = e.target.files[0];
  if (selectedFile) {
    setFileGalary(selectedFile);
  }
};

const handleSubmitGalary = async (folder) => {
  if (!selectedDocTypeGalary || !fileGalary) {
    alert("Please select a document type and upload a file.");
    return;
  }

  setIsLoading(true);        
  const params = {
    Bucket: S3_BUCKET,
    Key: `${company_name}/${selectedDocTypeGalary}/${fileGalary.name}`,
    Body: fileGalary,
    ACL: "public-read",
  };

  try {
    const uploadResult = await s3.upload(params).promise();
    // Perform additional actions like saving the document type and file URL in the database
    const payload = {
      DocType: selectedDocTypeGalary,
      DocURL: uploadResult.Location,
      account_id:account_id,
      VehicleID:fleet_id,
      orginal_name:fileGalary.name,
      folder_doc:folder,
    };
    
    dispatch(onPostVhiclesDoc(payload));
  
  } catch (error) {
    console.error("Error uploading file:", error);
    alert(t("Failed to upload file."));
  } finally {
    setTimeout(() => {
      // Reset the form after successful submission
      setSelectedDocTypeGalary("");
      setFileGalary(null);
      setIsLoading(false);
    }, 2000);
  }
};
const handleSubmit = async (folder) => {
  if (!selectedDocType || !file) {
    alert(t("Please select a document type and upload a file."));
    return;
  }

  setIsLoading(true);        
  const params = {
    Bucket: S3_BUCKET,
    Key: `${company_name}/${selectedDocType}/${file.name}`,
    Body: file,
    ACL: "public-read",
  };

  try {
    const uploadResult = await s3.upload(params).promise();
    // Perform additional actions like saving the document type and file URL in the database
    const payload = {
      DocType: selectedDocType,
      DocURL: uploadResult.Location,
      account_id:account_id,
      VehicleID:fleet_id,
      orginal_name:file.name,
      folder_doc:folder,
    };
    
    dispatch(onPostVhiclesDoc(payload));
  
  } catch (error) {
    console.error("Error uploading file:", error);
    alert(t("Failed to upload file."));
  } finally {
    setTimeout(() => {
      // Reset the form after successful submission
      setSelectedDocType("");
      setFile(null);
      setIsLoading(false);
    }, 2000);
  }
};


const filteredGalleryData = getVhiclesDocdata.filter(
  (item) => item.folder_doc === "galary"
);

const filteredDocData = getVhiclesDocdata.filter(
  (item) => item.folder_doc === "statement"
);
const [groupedDocData, setGroupedDocData] = useState(
  filteredDocData.reduce((acc, item) => {
    if (!acc[item.doc_type]) {
      acc[item.doc_type] = [];
    }
    acc[item.doc_type].push(item);
    return acc;
  }, {})
);
const [pagination, setPagination] = useState({});
const paginate = (folderName, newPage) => {
  setPagination((prev) => ({
    ...prev,
    [folderName]: newPage,
  }));
};
const galaryimages = filteredGalleryData.map((item) => item.docurl);
const ITEMS_PER_PAGE = 9;
const itemsPerPage = 2;

const startIndex = (currentPageGalary - 1) * ITEMS_PER_PAGE;
const paginatedData = filteredGalleryData.slice(startIndex, startIndex + ITEMS_PER_PAGE);
const handlePageChangeGalary = (page) => {
  setCurrentPageGalary(page);
};

// Calculate total pages
const totalPages = Math.ceil(getVhiclesDocdata.length / ITEMS_PER_PAGE);

const inputRefs = {}
const handleUpdate = (documentId) => {
  const inputRef = inputRefs[documentId];
  if (inputRef && inputRef.current) {
    inputRef.current.click(); // Trigger the file input click
  }
};


const handleEditFileChange = async (event, documentId, doc_type) => {
  const file = event.target.files[0];
  if (file) {
    setIsLoading(true); // Start loading spinner or UI feedback

    const params = {
      Bucket: S3_BUCKET,
      Key: `${company_name}/${doc_type}/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    try {
      const uploadResult = await s3.upload(params).promise();

      // Prepare submission data
      const submissionData = {
        id: documentId,
        orginal_name: file.name,
        docurl: uploadResult.Location,
      };

      // Dispatch update action
      await dispatch(onPutVhiclesDoc(submissionData));
      setGroupedDocData((prevGroupedDocData) => {
        // Find and update the specific document
        const updatedGroupedDocData = { ...prevGroupedDocData };

        Object.keys(updatedGroupedDocData).forEach((docType) => {
          updatedGroupedDocData[docType] = updatedGroupedDocData[docType].map((doc) =>
            doc.document_id === documentId
              ? { ...doc, orginal_name: file.name, docurl: uploadResult.Location }
              : doc
          );
        });

        return updatedGroupedDocData;
      });
      // Update local state with the new document 
      setViewerKeys((prevKeys) => ({
        ...prevKeys,
        [documentId]: Date.now(), // Use timestamp as a unique key
      }));
      
    } catch (error) {
      console.error("Error uploading file:", error);
      toastr.error(t("Failed to update file."));
    } finally {
      setIsLoading(false); // Stop loading spinner or UI feedback
    }
  }
};


const handleRemove = async (documentId) => {
  // Prepare data for deletion API
  const document_id = {
    document_id: [documentId] // Wrap single ID in an array
  };

  try {
    // Call API to delete document
    await dispatch(onDelVhiclesDoc(document_id));

    // Update grouped documents state after successful deletion
    setGroupedDocData((prevGroupedDocData) => {
      const updatedGroupedDocData = { ...prevGroupedDocData };

      // Remove the document from the appropriate doc_type group
      Object.keys(updatedGroupedDocData).forEach((docType) => {
        updatedGroupedDocData[docType] = updatedGroupedDocData[docType].filter(
          (doc) => doc.document_id !== documentId // Exclude the removed document
        );

        // If the group is empty after removal, delete the key
        if (updatedGroupedDocData[docType].length === 0) {
          delete updatedGroupedDocData[docType];
        }
      });

      return updatedGroupedDocData;
    });

    // Update FileViewer keys for real-time updates
    setViewerKeys((prevKeys) => {
      const updatedKeys = { ...prevKeys };
      delete updatedKeys[documentId]; // Remove the key for the deleted document
      return updatedKeys;
    });

  } catch (error) {
    console.error("Error removing document:", error);
    toastr.error(t("Failed to remove document."));
  }
};



useEffect(() => {
  const depSuccessHandler = (message) => {
    toastr.success(t(message));
    dispatch(onGetVhicles(account_id));
    dispatch(onGetVhiclesDoc(fleet_id));
  };
  
  const depErrorHandler = (message) => {
    toastr.error(t(message));
  };

  if (postVhiclesDocdata) {
    postVhiclesDocdata.success 
      ? depSuccessHandler('Vehicle document uploaded successfully! Your file has been saved and is ready for use.') 
      : depErrorHandler('Failed to upload the vehicle document. Please try again or contact support if the issue persists.');
  }
  if (putVhiclesdata) {
    putVhiclesdata.success 
      ? depSuccessHandler('Vehicle record updated! The latest details are now reflected in the system.') 
      : depErrorHandler('Failed to update vehicle data. Please verify the information and try again.');
  }
  if (putVhiclesDocdata) {
    putVhiclesDocdata.success 
      ? depSuccessHandler('Vehicle document updated successfully! The changes have been saved.') 
      : depErrorHandler('Failed to update the vehicle document. Please try again or contact support if the issue persists.');
  }

  if (delVhiclesDocdata) {
    delVhiclesDocdata.success 
      ? depSuccessHandler('Vehicle document deleted successfully! The record has been removed.') 
      : depErrorHandler('Failed to delete the vehicle document. Please try again or contact support if the issue persists.');
  }

}, [putVhiclesdata,postVhiclesDocdata,putVhiclesDocdata,delVhiclesDocdata]);

  return (
    <React.Fragment>
      <Modal
                      size="sm"
                      isOpen={modal_small}
                      toggle={() => {
                        tog_small();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="mySmallModalLabel"
                        >
                        {t('Modify Status')} 
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_small(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      {fleetData && fleetData.fleet_image && (
   <form onSubmit={handleSubmitstatus}>
   <Select
options={statusOptions}
value={statusOptions.value}
onChange={handleStatusChange}
placeholder={t('Select Status')} 
/>
<Button style={{marginTop:'30px'}} variant="primary" type="submit" className="css-1bl3roz">
{t('Submit')}
</Button>
   </form>
  )}
                        
             
                      </div>
                    </Modal>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t('Detail')} breadcrumbItemLink="/collaborator" breadcrumbItem={t('Back To Previous Page')} />
    <Row>
    <Col xs="2">
    <div className="drop_img">
  {fleetData && fleetData.fleet_image ? (
    <img
      height="200"
      style={{
        maxWidth: '100%',
        cursor: 'pointer',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      }}
      src={fleetData.fleet_image}
      alt=""
      onClick={handleImageClick}
    />
  ) : (
    <div
      style={{
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px dashed #ccc',
      }}
    >
      {t('No Image Available')}
    </div>
  )}
           {isEditorAllowed && (
  <div
  className="bewerkenbtn"
  style={{
    width: '86%',
    marginTop: '10px',
  }}
>
  <button onClick={handleModalToggle} className="btn btn-success css-1bl3roz">
    {t('Modify')}
  </button>
</div>
)}


  {isLightboxOpen && fleetData && fleetData.fleet_image && (
    <div className="lightbox" onClick={handleLightboxClose}>
      <img className="lightbox-img" src={fleetData.fleet_image} alt={t('fleet image')} />
    </div>
  )}
</div>

{/* Modal */}
{modalVisible && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('Modify Profile Picture')} </h5>
                <button type="button" className="btn-close" onClick={handleModalToggle} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <form onSubmit={handleFormSubmit} >
                        <div className="card-body">
                         
                          <div
                           style={{
                           position:"relative",
                           display:'flex',
                          justifyContent:'center'
                            }}
                           >
                            <div className="drop_img col-5"
                            {...getRootPropsProfile()}
                            style={{
                            marginBottom: '10px',
                            backgroundColor: imagePreview ? '#f0f8ff' : '#fff',
                            padding: '10px',
                            border: '2px dashed #007bff',
                            borderRadius: '4px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            }}
                            >
                        {isLoading ? (
                      <div role="status" className="ms-2 spinner-border text-primary"><span className="visually-hidden">{t('Loading...')}</span></div>// Display a loading message or spinner
                    ) : imagePreview ? (
                      <img src={imagePreview} alt="Profile" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    ) : (
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 512 512"
  width="150px"
  height="150px"
  fill="#000000"
>
<path d="M119.5,384c-4.142,0-7.5,3.358-7.5,7.5c0,9.098-7.402,16.5-16.5,16.5S79,400.598,79,391.5S86.402,375,95.5,375
		c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5C78.131,360,64,374.131,64,391.5S78.131,423,95.5,423s31.5-14.131,31.5-31.5
		C127,387.358,123.642,384,119.5,384z"/>
	<path d="M439.5,384c-4.142,0-7.5,3.358-7.5,7.5c0,9.098-7.402,16.5-16.5,16.5s-16.5-7.402-16.5-16.5s7.402-16.5,16.5-16.5
		c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5c-17.369,0-31.5,14.131-31.5,31.5s14.131,31.5,31.5,31.5s31.5-14.131,31.5-31.5
		C447,387.358,443.642,384,439.5,384z"/>
	<path d="M447.5,256h-16c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16c4.142,0,7.5-3.358,7.5-7.5S451.642,256,447.5,256z"/>
	<path d="M327.5,320h-144c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h144c4.142,0,7.5-3.358,7.5-7.5S331.642,320,327.5,320z"/>
	<path d="M471.513,224H371.038l-68.199-82.764c-0.077-0.094-0.157-0.186-0.239-0.275c-6.198-6.817-14.583-11.219-23.6-12.537V95.5
		c0-17.369-14.131-31.5-31.5-31.5H215v-0.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V64h-41v-0.5c0-4.142-3.358-7.5-7.5-7.5
		s-7.5,3.358-7.5,7.5V64h-32.5C94.131,64,80,78.131,80,95.5v32.537c-13.185,0.383-25.202,7.168-32.316,18.347
		c-0.068,0.107-0.134,0.217-0.197,0.328L6.061,219.793C2.095,226.095,0,233.356,0,240.805V327.5
		c0,15.285,9.017,29.334,22.972,35.791c2.002,0.927,4.312,0.925,6.311-0.006c1.829-0.85,3.221-2.4,3.894-4.285h7.798
		C35.282,368.514,32,379.629,32,391.5c0,35.014,28.486,63.5,63.5,63.5s63.5-28.486,63.5-63.5c0-11.871-3.282-22.986-8.975-32.5
		h7.775c0.19,0.718,0.375,1.437,0.54,2.162c0.776,3.415,3.812,5.838,7.313,5.838h179.691c3.502,0,6.538-2.423,7.313-5.838
		c0.165-0.725,0.35-1.444,0.54-2.162h7.775c-5.693,9.514-8.975,20.629-8.975,32.5c0,35.014,28.486,63.5,63.5,63.5
		s63.5-28.486,63.5-63.5c0-11.871-3.282-22.986-8.975-32.5h7.775c0.19,0.718,0.375,1.437,0.54,2.162
		c0.776,3.415,3.812,5.838,7.313,5.838h1.823C500.448,367,511,356.447,511,343.477v-79.99C511,241.714,493.286,224,471.513,224z
		 M496,288h-8.5c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5h8.5V288z M304.563,166.916L351.602,224H116.042l24.522-45.65
		C145.1,171.238,152.822,167,161.27,167H303.5C303.862,167,304.215,166.966,304.563,166.916z M247.5,79
		c9.098,0,16.5,7.402,16.5,16.5V128h-49V79H247.5z M200,79v49h-41V79H200z M111.5,79H144v49H95V95.5C95,86.402,102.402,79,111.5,79z
		 M53.401,166.696c0.667,0.194,1.37,0.304,2.099,0.304h35.457l-24.535,45.671C61.896,219.769,54.187,224,45.754,224H20.918
		L53.401,166.696z M95.5,440C68.757,440,47,418.243,47,391.5S68.757,343,95.5,343s48.5,21.757,48.5,48.5S122.243,440,95.5,440z
		 M137.579,344c-11.213-9.944-25.948-16-42.079-16s-30.866,6.056-42.079,16H39.29c11.21-19.986,32.578-33,56.21-33
		c14.913,0,28.908,4.948,40.474,14.31c6.412,5.19,11.746,11.568,15.741,18.69H137.579z M415.5,440c-26.743,0-48.5-21.757-48.5-48.5
		s21.757-48.5,48.5-48.5s48.5,21.757,48.5,48.5S442.243,440,415.5,440z M457.579,344c-11.213-9.944-25.948-16-42.079-16
		s-30.866,6.056-42.079,16h-14.136c3.995-7.123,9.329-13.5,15.741-18.69C386.592,315.948,400.587,311,415.5,311
		s28.908,4.948,40.474,14.31c6.412,5.19,11.746,11.568,15.741,18.69H457.579z M491.139,351.172
		c-4.717-14.635-13.689-27.777-25.728-37.521C451.35,302.269,433.624,296,415.5,296s-35.85,6.269-49.911,17.651
		c-12.266,9.928-21.347,23.382-25.99,38.349H171.401c-4.643-14.967-13.724-28.421-25.99-38.349C131.35,302.269,113.624,296,95.5,296
		c-32.221,0-61.034,19.614-73.261,48.782C17.694,340.261,15,334.063,15,327.5V271h384.5c4.142,0,7.5-3.358,7.5-7.5
		s-3.358-7.5-7.5-7.5H15v-15.195c0-0.611,0.026-1.22,0.071-1.826C15.214,238.986,15.355,239,15.5,239h30.254
		c13.715,0,26.243-6.943,33.512-18.574c0.087-0.139,0.169-0.281,0.247-0.425l30.595-56.951c1.249-2.324,1.184-5.134-0.17-7.398
		S106.139,152,103.5,152H62.113c4.661-5.68,11.587-9,19.062-9h192.127c6.858,0,13.44,2.887,18.086,7.926l0.885,1.074H161.27
		c-13.729,0-26.271,6.951-33.547,18.594c-0.087,0.139-0.169,0.281-0.247,0.426l-30.582,56.931c-1.249,2.325-1.184,5.134,0.17,7.398
		S100.861,239,103.5,239h264h104.013c10.891,0,20.141,7.149,23.312,17H487.5c-12.958,0-23.5,10.542-23.5,23.5s10.542,23.5,23.5,23.5
		h8.5v40.477C496,346.866,494.011,349.8,491.139,351.172z"/>
</svg>

   )}
</div>

                            <input {...getInputPropsProfile()} />
                            <div className="dz-messages needsclicks" id="drop-region">
                            {imagePreview && (
                    <button type="button" className="trash img_class" id="trash" onClick={() => setImagePreview(null)}>
                      <i className="bx bx-trash"></i>{t('Trash')} 
                    </button>
                  )}
                      
                            </div>
                          </div>
                        </div>
              
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
            </Col>

            
            <Col xs="10">
            <Row>
  {fleetData && fleetData.fleet_image ? (
    <>
      <h5>
        {fleetData.Make}
        <span>
          <i className="mdi mdi-circle text-info align-middle me-1"></i>
          {fleetData.fleet_status}
        </span>
        {isEditorAllowed && (
  <span>
  <button type="submit"  onClick={() => {tog_small()}}className="btn  css-1bl3roz"style={{width:'140px',marginLeft:'10px', background: '#0463b9', border: 'none' }}>{t('Modify Status')}</button> 
  </span>
)}
        
      </h5>
      <div className="left_detail col-6">
        <div className="list_details">
          <strong>{t("Model")}:</strong> {fleetData.Model}
        </div>
        <div className="list_details">
          <strong>{t("Year")}:</strong> {format(new Date(fleetData.Year), DateTimeFormat)}
        </div>
        <div className="list_details">
          <strong>{t("VIN")}:</strong> {fleetData.VIN}
        </div>
        <div className="list_details">
          <strong>{t("Fuel Type")}:</strong> {fleetData.FuelType}
        </div>
      </div>
      <div className="right_detail col-6">
        <div className="list_details">
          <strong>{t("Department")}:</strong> {fleetData.dep_name}
        </div>
        <div className="list_details">
          <strong>{t("inFleetDate")}:</strong>{" "}
          {fleetData.inFleetDate ? format(new Date(fleetData.inFleetDate), DateTimeFormat) : t("N/A")}
        </div>
        <div className="list_details">
          <strong>{t("defleetDate")}:</strong>{" "}
          {fleetData.defleetDate ? format(new Date(fleetData.defleetDate), DateTimeFormat) : t("N/A")}
        </div>
      </div>
    </>
  ) : (
    <div className="text-center">
      <h5>{t("No vehicle data available")}</h5>
    </div>
  )}
</Row>

           </Col>


           <Col xs="12">
           <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">{t('Dcoument board')}</span>
                      </NavLink>
                    </NavItem>
                    
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                    <Row className="justify-content-center">
                   
                    <Col sm="6">
                        <ul className="list-group list-group-flush">
                                       
                          <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none' }}
                          >
                         {t('Statments')}  
                          </Button> 
                          {isEditorAllowed && (
      <li className="list-group-item">
                 
      <div className="doc-upload-container">
      <input
className="form-control doc_type"
name="doc_type"
list="datalistOptions"
id="doc_type"
placeholder={t("Type to folder name")}
required
value={selectedDocType}
onChange={(e) => setSelectedDocType(e.target.value)}
/>
<datalist id="datalistOptions">
{statementDocTypes.map((option, index) => (
<option key={index} value={option.label}>
{option.label}
</option>
))}
</datalist>
<input
type="file"
accept="application/pdf,image/*"
onChange={handleFileChange}
className="doc-file-input"
/>
<button onClick={() => handleSubmit('statement')} className="upload-btn" disabled={isLoading}>
{isLoading ? t("Uploading...") : t("Upload")}
</button>
</div>
</li> 
)}
                        
                  <li className="list-group-item">
                  <>
    {Object.entries(groupedDocData).map(([folderName, folderItems], index) => {
      const currentPageExtra = pagination[folderName] || 1;
      const indexOfLastItem = currentPageExtra * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = folderItems.slice(indexOfFirstItem, indexOfLastItem);
      
      
      return (
        <div key={index} className="mb-4">
          <h5>
            <i
              className="bx bx-check-circle bx-md check_Driver_deatails"
              style={{ verticalAlign: "middle" }}
            ></i>{" "}
            {folderName}
          </h5>
          <Row>
  {currentItems.map((item, idx) => {
    const inputRef = React.createRef();
    inputRefs[item.document_id] = inputRef; // Store the ref for the current document

    return ( // Add the return statement
      <Col key={ idx}  sm="6">
        <div className="ms-3 d-flex align-items-center edit-group">
          <p>
            <a
              href={item.docurl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Download Document")}
            </a>
          </p>
          {/* Update Button/Icon */}
          <button
            className="btn btn-sm btn-warning me-2"
            onClick={() => handleUpdate(item.document_id)}
            title={t("Update Document")}
          >
            <i className="mdi mdi-pencil"></i>
          </button>
          <input
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            accept="application/pdf,image/*"
            onChange={(event) => handleEditFileChange(event, item.document_id ,item.doc_type)}
          />
          {/* Remove Button/Icon */}
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleRemove(item.document_id)}
            title={t("Remove Document")}
          >
            <i className="mdi mdi-delete"></i>
          </button>
        </div>
        <div style={{ width: "100%", height: "200px", overflow: "hidden" }}>
          <FileViewer
            key={viewerKeys[item.document_id] || item.document_id}
            fileType={item.docurl.split(".").pop()}
            filePath={item.docurl}
            onError={(error) => console.error(error)}
            onLoad={() => console.log("File loaded")}
          />
        </div>
      </Col>
    );
  })}
</Row>

          {/* Pagination Buttons for each folder */}
          {folderItems.length > itemsPerPage && (
  <div className="btn-group float-end mt-3">
    {/* Render pagination buttons only if isEditorAllowed is true */}
    {isEditorAllowed && (
      <>
        <button
          type="button"
          className={`btn btn-primary btn-sm ${
            currentPageExtra === 1 ? "disabled" : ""
          }`}
          onClick={() => paginate(folderName, currentPageExtra - 1)}
          disabled={currentPageExtra === 1} // Disable the button if on the first page
        >
          <i className="fa fa-chevron-left"></i>
        </button>
        <button
          type="button"
          className={`btn btn-primary btn-sm ${
            indexOfLastItem >= folderItems.length ? "disabled" : ""
          }`}
          onClick={() => paginate(folderName, currentPageExtra + 1)}
          disabled={indexOfLastItem >= folderItems.length} // Disable the button if on the last page
        >
          <i className="fa fa-chevron-right"></i>
        </button>
      </>
    )}
  </div>
)}

        </div>
      );
    })}
  </>
                  </li>
                  </ul>
                        </Col>
                        <Col sm="6">
                        <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none' }}
                          >
                         {t('Galary')}  
                          </Button> 
                          <>
  <ul className="list-group list-group-flush">
  {isEditorAllowed && (
 <li className="list-group-item">
 <div className="doc-upload-container">
   <input
     className="form-control doc_type"
     name="doc_type"
     list="datalistOptions"
     id="doc_type"
     placeholder="Type to document folder"
     required
     value={selectedDocTypeGalary}
     onChange={(e) => setSelectedDocTypeGalary(e.target.value)}
   />
   <datalist id="datalistOptions">
     {galleryUniqueDocTypes.map((option, index) => (
       <option key={index} value={option.label}>
         {option.label}
       </option>
     ))}
   </datalist>
   <input
     type="file"
     accept="image/*"
     onChange={handleFileChangeGalary}
     className="doc-file-input"
   />
   <button
     onClick={() => handleSubmitGalary("galary")}
     className="upload-btn"
     disabled={isLoading}
   >
     {isLoading ? t("Uploading...") : t("Upload")}
   </button>
 </div>
</li>
)}
    
    <li className="list-group-item">
      <div className="image-grid">
        {paginatedData.map((image, index) => {
          const fileName = image.orginal_name || "";
          const fileExtension = fileName.split(".").pop().toLowerCase();
          const inputRef = React.createRef();

          return (
            <div className="popup-gallery d-flex flex-wrap" key={image.document_id || index}>
              {fileExtension === "pdf" ? (
                <a href={image.docurl} target="_blank" rel="noopener noreferrer">
                  {fileName}
                </a>
              ) : (
                <div className="img-fluid float-left">
                  <img
                    src={image.docurl}
                    alt={fileName || `Image ${index + 1}`}
                    className="small-image"
                    onClick={() => {
                      setisGallery(true);
                      setphotoIndex(index);
                    }}
                    height="100"
                    width="120"
                    style={{
                      maxWidth: "100%",
                      cursor: "pointer",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>
              )}
          {isEditorAllowed && (
  <div className="edit-buttons d-flex mt-2">
    {/* Edit Button */}
    <button
      className="btn btn-sm btn-warning me-2"
      onClick={() => {
        if (inputRef.current) inputRef.current.click(); // Trigger file input click
      }}
    >
      <i className="mdi mdi-pencil"></i>{t("Edit")}
    </button>
    <input
      type="file"
      ref={inputRef}
      style={{ display: "none" }}
      accept="image/*"
      onChange={(event) =>
        handleEditFileChange(event, image.document_id, image.doc_type)
      }
    />
    {/* Remove Button */}
    <button
      className="btn btn-sm btn-danger"
      onClick={() => handleRemove(image.document_id)}
    >
      <i className="mdi mdi-delete"></i> 
    </button>
  </div>
)}

            </div>
          );
        })}
      </div>
      {isGallery ? (
        <Lightbox
          mainSrc={galaryimages[photoIndex]}
          nextSrc={galaryimages[(photoIndex + 1) % galaryimages.length]}
          prevSrc={galaryimages[(photoIndex + galaryimages.length - 1) % galaryimages.length]}
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex(
              (photoIndex + galaryimages.length - 1) % galaryimages.length
            );
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % galaryimages.length);
          }}
          imageCaption={"Project " + parseFloat(photoIndex + 1)}
        />
      ) : null}
      {/* Pagination */}
      {totalPages > 1 && (
        <div className="btn-group float-end mt-3">
          {/* Previous Button */}
          <button
            type="button"
            className={`btn btn-primary btn-sm ${
              currentPageGalary === 1 ? "disabled" : ""
            }`}
            onClick={() => handlePageChangeGalary(currentPageGalary - 1)}
            disabled={currentPageGalary === 1}
          >
            <i className="fa fa-chevron-left"></i>
          </button>

          {/* Next Button */}
          <button
            type="button"
            className={`btn btn-primary btn-sm ${
              startIndex + ITEMS_PER_PAGE >= filteredGalleryData.length
                ? "disabled"
                : ""
            }`}
            onClick={() => handlePageChangeGalary(currentPageGalary + 1)}
            disabled={startIndex + ITEMS_PER_PAGE >= filteredGalleryData.length}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
      )}
    </li>
  </ul>
</>

                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
           </Col>
    </Row>
          
         
      
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(Details));

