//index.js
import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import PermissionsProvider from './components/Permissions/UserPermissionsContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PermissionsProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PermissionsProvider>
  </Provider>
);

serviceWorker.unregister();
