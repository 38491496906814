import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import Select from "react-select"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";
import './style.css';
import { map } from "lodash";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  postCollaborator as onPostcollaborator,
} from "store/actions";

import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import jsonData from '../../location/JSON/countries+cities.json';
const S3_BUCKET = 'analyticscarg-public';
const REGION = 'us-east-2';
const ACCESS_KEY = 'AKIA2NSVDMLW6CSKQOFQ';
const SECRET_ACCESS_KEY = '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';
AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION
});
const s3 = new AWS.S3();
const AddCollaborator = (props) => {
  const { t } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const {
    userdata,
    error
  } = useSelector(state => ({
    userdata: state.getClientData.userdata,
    error: state.getClientData.error,
  }));
  //meta title

  useEffect(() => {
    document.title = t("Welcome Collaborators - Add and Search Users | Shiftspec");
  }, [t]);
  const dispatch = useDispatch();
  const [activeTab, setactiveTab] = useState(1)


  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [send_mail_check, setSendMail] = useState(false);
  const [uploadResult, setUploadResult] = useState('');
  const  subject = t('You’ve Been Added as a Collaborator – Welcome Aboard!')
  const text = t('We are excited to inform you that you have been added as a collaborator to our team! Your contributions will play a crucial role in helping us achieve our goals, and we can’t wait to see what we’ll accomplish together.To get started, please log in to your account or follow the onboarding instructions provided in your email. If you have any questions or need assistance, feel free to reach out to us.Welcome to the team, and let’s make great things happen!')
  const [formData, setFormData] = useState({
    account_id: account_id,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    country: null,
    city: null,
    address: '',
    zip: '',
    nationality: '',
    gender: null,
    birthPlace: '',
    birthday: '',
    password: '',
    citizenServiceNumber: '',
    emergencyNumber: '',
    relationName: '',
    relation: '',
    serviceAreas: '',
    bankAccount: '',
    prooftype: '',
    proofnum: '',
    proofstart: '',
    proofexpiration: '',
    proofFrontImage: '',
    proofBackImage: '',
    profileImage: '',
    licensetype: '',
    licenseno: '',
    licensestart: '',
    licenseexpiration: '',
    licenseFrontImage: '',
    licenseBackImage: '',
    subject: subject,
    text: text,
    shortLink: 'https://shiftspec.com',
    company_name: company_name,
    sort: t('Welcome Aboard!'),
    btntext: t('Get Started Now'),
    send_mail_check: send_mail_check,
    
  });

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'firstName',
      'lastName',
      // 'phone',
       'email',
       'country',
       'city',
       'address',
       'zip',
      // 'nationality',
       'gender',
      // 'birthPlace',
      // 'birthday',
       'password',
      // 'citizenServiceNumber',
      // 'emergencyNumber',
      // 'relationName',
      // 'relation',
       'serviceAreas',
      // 'bankAccount',
      // 'prooftype',
      // 'proofnum',
      // 'proofstart',
      // 'proofexpiration',
      // 'proofFrontImage',
      // 'proofBackImage',
      // 'profileImage',
      // 'licensetype',
      // 'licenseno',
      // 'licensestart',
      // 'licenseexpiration',
      // 'licenseFrontImage',
      // 'licenseBackImage',
    ];

    requiredFields.forEach((field) => {
      if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
        newErrors[field] = t('This field is required');
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function toggleTab(tab) {
    if (validateForm()) {
      if (activeTab !== tab) {
        var modifiedSteps = [...passedSteps, tab]
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
          setPassedSteps(modifiedSteps)
        }
      }
    }
   

  }


  useEffect(() => {
    // Initialize data from JSON
    setData(jsonData);

    // Extract unique countries from data
    const uniqueCountries = jsonData.map(item => ({
      value: item.iso2,
      label: item.name
    }));
    setCountries(uniqueCountries);
  }, []);

  useEffect(() => {
    // Filter cities based on selected country
    if (selectedCountry) {
      const countryData = data.find(item => item.iso2 === selectedCountry.value);
      const filteredCities = countryData ? countryData.cities.map(city => ({
        value: city.id,
        label: city.name
      })) : [];
      setCities(filteredCities);
    } else {
      setCities([]);
    }
  }, [selectedCountry, data]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSelectChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
    setSelectedCountry(value);
    setSelectedCity(null);
  };
  const handleCityChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
    setSelectedCity(value);
    
  };

  const handleSelectGenderChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleDrop = useCallback(async (acceptedFiles, type) => {
    const file = acceptedFiles[0];
    if (file) {
      setUploading(true);
      const fileUrl = URL.createObjectURL(file);
      const params = {
        Bucket: S3_BUCKET,
        Key: `${type}/${file.name}`, // Path or key where file will be stored in S3
        Body: file,
        ACL: 'public-read', // Set file as public read
      };
      try {
        const data = await s3.upload(params).promise();
        // Get the URL from the response
        const fileUrl = data.Location;

        // Update form data with the S3 file URL
        setFormData(prevState => ({
          ...prevState,
          [`${type}Image`]: fileUrl,
        }));

        setUploadResult(`File uploaded successfully: ${fileUrl}`);
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadResult('Error uploading file');
      } finally {
        setUploading(false);
      }
     
    }
  }, []);

  const createDropzoneConfig = (type) => ({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, type),
    accept: 'image/*',
    maxFiles: 1,
  });

  const { getRootProps: getRootPropsProfile, getInputProps: getInputPropsProfile } = useDropzone(createDropzoneConfig('profile'));
  const { getRootProps: getRootPropsProofFront, getInputProps: getInputPropsProofFront } = useDropzone(createDropzoneConfig('proofFront'));
  const { getRootProps: getRootPropsProofBack, getInputProps: getInputPropsProofBack } = useDropzone(createDropzoneConfig('proofBack'));
  const { getRootProps: getRootPropsLicenseFront, getInputProps: getInputPropsLicenseFront } = useDropzone(createDropzoneConfig('licenseFront'));
  const { getRootProps: getRootPropsLicenseBack, getInputProps: getInputPropsLicenseBack } = useDropzone(createDropzoneConfig('licenseBack'));

  const genderOptions = [
    { value: 'male', label: t('Men') },
    { value: 'female', label: t('Women')},
    { value: 'other', label: t('Other') }
  ];

  const relationOptions = [
    { value: 'family', label: t('Family')},
    { value: 'friend', label:t('Friend')},
  ];

  const proofOptions = [
    { value: 'passport', label: t('Passport')},
    { value: 'idcard', label: t('Id card')},
  ];
 

  const submitForm = async () => {
    if (validateForm()) {
    dispatch(onPostcollaborator(formData));
   
    const timer = setTimeout(() => {
      navigate('/collaborator')
    }, 2000);
  
    return () => clearTimeout(timer); 
    } else {
      console.log('Form validation failed');
      // Handle validation error
    }
  };

  useEffect(() => {
    if (userdata.success) {
      toastr.success(t('Create New Collaborator has been successfully added.'));
     
    }else{
      toastr.error(t(userdata.message));
    }
    
  }, [userdata]);
  
  const handleNotificationChange = (e) => {
    const { checked } = e.target;

    setFormData((prev) => ({
      ...prev,
      send_mail_check: checked, // Update the send_mail_check in the state
    }));
  };

  


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t('Collaborators')} breadcrumbItemLink="/collaborator" breadcrumbItem={t('Back To Previous Page')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">{t('Welome New Collaborators!')}</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span>{t('Collaborator Details')} 
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> {t('Identidication')}
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span>{t('Driver License')} 
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span>{t('Confirm Detail')}  
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="firstName">
                                    {t('First name')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder={t('Please Enter Your fristname')}
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                  />
                                  {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="lastName">
                                    {t('Lastname')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    placeholder={t('Please Enter Your lastname')}
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                  />
                                   {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
                                </div>
                              </Col>
                              <Col lg="3">
              <div className="mb-3">
                <Label for="phone">{t('Phone Number')}</Label>
                <Input
                  type="number"
                  className="form-control"
                  id="phone"
                  placeholder={t('Please Enter Your phone No.')}
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                {errors.phone && <p className="text-danger">{errors.phone}</p>}
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="email">{t('Email')}</Label>
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder={t('Please Enter Your Email')}
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && <p className="text-danger">{errors.email}</p>}
              </div>
            </Col>
          </Row>
                            <Row>
                            <Col lg="2">
                                <div className="mb-3">
                                  <Label for="basicpill-Country-input3">
                                  {t('Country')} 
                                  </Label>
                                  <Select
          options={countries}
          value={formData.country}
          onChange={(value) => handleSelectChange(value, 'country')}
          placeholder={t('Country/region')} 
        />
         {errors.country && <p className="text-danger">{errors.country}</p>}
                                </div>
                              </Col>
                              <Col lg="2">
                                <div className="mb-3">
                                  <Label for="basicpill-city-input4">
                                  {t('City')} 
                                  </Label>
                                  <Select
          options={cities}
          value={formData.city}
          //onChange={handleCountryChange}
          onChange={(value) => handleCityChange(value, 'city')}
          placeholder={t('City')}
          isDisabled={!selectedCountry} // Disable if no country is selected
        />
        {errors.city && <p className="text-danger">{errors.city}</p>}
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="address">
                                  {t('Address')} 
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder={t('Please Enter Your address')} 
  
                                    value={formData.address}
                                    onChange={handleInputChange}
                                  />
                                   {errors.address && <p className="text-danger">{errors.address}</p>}
                                </div>
                              </Col>
                              <Col lg="2">
                                <div className="mb-2">
                                  <Label for="zip">
                                  {t('Postal code')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="zip"
                                    placeholder={t('Please Enter Your Postal code')}
                                    value={formData.zip}
                                    onChange={handleInputChange}
                                  />
                           {errors.zip && <p className="text-danger">{errors.zip}</p>}
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="nationality">
                                  {t('Nationality')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="nationality"
                                    placeholder={t('Please Enter Your Nationality')}
                                    value={formData.nationality}
                                    onChange={handleInputChange}
                                  />
                           {errors.nationality && <p className="text-danger">{errors.nationality}</p>}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                            <Col lg="2">
                                <div className="mb-3">
                                  <Label for="basicpill-Gender-input3">
                                  {t('Gender')}
                                  </Label>
                                  <Select
          options={genderOptions}
          value={formData.gender}
          onChange={(value) => handleSelectGenderChange(value, 'gender')}
          placeholder={t('Select Gender')}
        />
        {errors.gender && <p className="text-danger">{errors.gender}</p>}
                                </div>
                              </Col>
                              <Col lg="2">
                                <div className="mb-3">
                                  <Label for="birthPlace">
                                  {t('Birth place')} 
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="birthPlace"
                                    placeholder={t('Please Enter Your Birth place')} 
                                    value={formData.birthPlace}
                                    onChange={handleInputChange}
                                  />
                           {errors.birthPlace && <p className="text-danger">{errors.birthPlace}</p>}
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="birthday">
                                  {t('Brithday')} 
                                  </Label>
                                  <Input
                                    type="date"
                                    className="form-control"
                                    id="birthday"
                                    value={formData.birthday}
                                    onChange={handleInputChange}
                                  />
                           {errors.birthday && <p className="text-danger">{errors.birthday}</p>}
                                </div>
                              </Col>
                              <Col lg="2">
                                <div className="mb-2">
                                  <Label for="password">
                                  {t('Login password')}
                                  </Label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder={t('Please Enter Your Login password')}
                                    value={formData.password}
                                    onChange={handleInputChange}
                                  />
                           {errors.password && <p className="text-danger">{errors.password}</p>}
        
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="citizenServiceNumber">
                                  {t('citizen service number')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="citizenServiceNumber"
                                    placeholder={t('Please Enter Your citizen service number')}
                                    value={formData.citizenServiceNumber}
                                    onChange={handleInputChange}
                                  />
                           {errors.citizenServiceNumber && <p className="text-danger">{errors.citizenServiceNumber}</p>}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                            <Col lg="2">
                                <div className="mb-3">
                                  <Label for="emergencyNumber">
                                  {t('Emergency number')} 
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="emergencyNumber"
                                    placeholder={t('Please Enter Emergency number for contact')} 
                                    value={formData.emergencyNumber}
                                    onChange={handleInputChange}
                                  />
                           {errors.emergencyNumber && <p className="text-danger">{errors.emergencyNumber}</p>}
                                </div>
                              </Col>
                              <Col lg="2">
                                <div className="mb-3">
                                  <Label for="relationName">
                                  {t('Name of relation')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="relationName"
                                    placeholder={t('Please Enter Name of relation')}
                                    value={formData.relationName}
                                    onChange={handleInputChange}
                                  />
                           {errors.relationName && <p className="text-danger">{errors.relationName}</p>}
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="relation">
                                  {t('Relation')}
                                  </Label>
                                  <Select
          options={relationOptions}
          value={formData.relation}
          onChange={(value) => handleSelectChange(value, 'relation')}
          placeholder={t('Select relation')}
        />
        {errors.relation && <p className="text-danger">{errors.relation}</p>}
                                 
                                </div>
                              </Col>
                              <Col lg="2">
                                <div className="mb-2">
                                  <Label for="serviceAreas">
                                  {t('Service Areas')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="serviceAreas"
                                    placeholder={t('Please Enter Service Areas')}
                                    value={formData.serviceAreas}
                                    onChange={handleInputChange}
                                  />
                           {errors.serviceAreas && <p className="text-danger">{errors.serviceAreas}</p>}
        
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="bankAccount">
                                  {t('Bank account')}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="bankAccount"
                                    placeholder={t('Please Enter Your IBAN.')}
                                    value={formData.bankAccount}
                                    onChange={handleInputChange}
                                  />
                           {errors.bankAccount && <p className="text-danger">{errors.bankAccount}</p>}
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="prooftype">
                                    {t('Proof of identity')} 
                                    </Label>
                                    <Select
          options={proofOptions}
          value={formData.prooftype}
          onChange={(value) => handleSelectChange(value, 'prooftype')}
          placeholder={t('Select Type')}
        />
        {errors.prooftype && <p className="text-danger">{errors.prooftype}</p>}
                                 
                                  </div>
                                </Col>

                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="proofnum">
                                    {t('Identity Number')} 
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="proofnum"
                                      onChange={handleInputChange}
                                      placeholder={t('Please Enter Identity No.')} 
                                    />
                                    {errors.proofnum && <p className="text-danger">{errors.proofnum}</p>}
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="proofstart">
                                    {t('Proof bigin date')} 
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="proofstart"
                                      onChange={handleInputChange}
                                    />
                                    {errors.proofstart && <p className="text-danger">{errors.proofstart}</p>}
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="proofexpiration">
                                    {t('Proof expiration date')}  
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="proofexpiration"
                                      onChange={handleInputChange}
                                    />
                                    {errors.proofexpiration && <p className="text-danger">{errors.proofexpiration}</p>}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                               

                              <Col lg="4">
        <div
          className="dropzone mb-3"
          {...getRootPropsProfile()}
          style={{
            marginBottom: '10px',
            backgroundColor: formData.profileImage ? '#f0f8ff' : '#fff',
            padding: '10px',
            border: '2px dashed #007bff',
            borderRadius: '4px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <input {...getInputPropsProfile()} />
          {formData.profileImage ? (
            <img src={formData.profileImage} alt="Profile" style={{ maxWidth: '100%', maxHeight: '150px' }} />
          ) : (
            <p>{t('Drag & drop profile image here, or click to select one')}</p>
          )}
        </div>
      </Col>
      <Col lg="4">
        <div
          className="dropzone mb-3"
          {...getRootPropsProofFront()}
          style={{
            marginBottom: '10px',
            backgroundColor: formData.proofFrontImage ? '#f0f8ff' : '#fff',
            padding: '10px',
            border: '2px dashed #007bff',
            borderRadius: '4px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <input {...getInputPropsProofFront()} />
          {formData.proofFrontImage ? (
            <img src={formData.proofFrontImage} alt="Proof Front" style={{ maxWidth: '100%', maxHeight: '150px' }} />
          ) : (
            <p>{t('Drag & drop proof front image here, or click to select one')}</p>
          )}
        </div>
      </Col>
      <Col lg="4">
        <div
          className="dropzone mb-3"
          {...getRootPropsProofBack()}
          style={{
            marginBottom: '10px',
            backgroundColor: formData.proofBackImage ? '#f0f8ff' : '#fff',
            padding: '10px',
            border: '2px dashed #007bff',
            borderRadius: '4px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <input {...getInputPropsProofBack()} />
          {formData.proofBackImage ? (
            <img src={formData.proofBackImage} alt="Proof Back" style={{ maxWidth: '100%', maxHeight: '150px' }} />
          ) : (
            <p>{t('Drag & drop proof back image here, or click to select one')}</p>
          )}
        </div>
      </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                            <Row>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="licensetype">
                                    {t('driver`s license categories')}
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="licensetype"
                                      onChange={handleInputChange}
                                      placeholder={t('driver`s license categories')}
                                    />
                                    {errors.licensetype && <p className="text-danger">{errors.licensetype}</p>}
                                  </div>
                                </Col>

                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="licenseno">
                                    {t('Driver License Number')} 
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="licenseno"
                                      onChange={handleInputChange}
                                      placeholder={t('Please Enter No.')} 
                                    />
                                    {errors.licenseno && <p className="text-danger">{errors.licenseno}</p>}
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="licensestart">
                                    {t('License bigin date')} 
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="licensestart"
                                      onChange={handleInputChange}
                                    />
                                    {errors.licensestart && <p className="text-danger">{errors.licensestart}</p>}
                                  </div>
                                </Col>
                                <Col lg="3">
                                  <div className="mb-3">
                                    <Label for="licenseexpiration">
                                    {t('License expiration date')}
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="licenseexpiration"
                                      onChange={handleInputChange}
                                    />
                                    {errors.licenseexpiration && <p className="text-danger">{errors.licenseexpiration}</p>}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                               

                              <Col lg="6">
        <div
          className="dropzone mb-3"
          {...getRootPropsLicenseFront()}
          style={{
            marginBottom: '10px',
            backgroundColor: formData.licenseFrontImage ? '#f0f8ff' : '#fff',
            padding: '10px',
            border: '2px dashed #007bff',
            borderRadius: '4px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <input {...getInputPropsLicenseFront()} />
          {formData.licenseFrontImage ? (
            <img src={formData.licenseFrontImage} alt="License Front" style={{ maxWidth: '100%', maxHeight: '150px' }} />
          ) : (
            <p>{t('Drag & drop license front image here, or click to select one')}</p>
          )}
        </div>
      </Col>
      <Col lg="6">
        <div
          className="dropzone mb-3"
          {...getRootPropsLicenseBack()}
          style={{
            marginBottom: '10px',
            backgroundColor: formData.licenseBackImage ? '#f0f8ff' : '#fff',
            padding: '10px',
            border: '2px dashed #007bff',
            borderRadius: '4px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          <input {...getInputPropsLicenseBack()} />
          {formData.licenseBackImage ? (
            <img src={formData.licenseBackImage} alt="License Back" style={{ maxWidth: '100%', maxHeight: '150px' }} />
          ) : (
            <p>{t('Drag & drop license back image here, or click to select one')}</p>
          )}
        </div>
      </Col>
                                
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                {/* <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div> */}
                                <div>
                                  <h5>{t('Confirm Detail')}</h5>
                                  <p className="text-muted">{t('Please review your information below before submitting.')}</p>
                                </div>
                                <div className="card">
            <div className="card-body">
            <Col lg="12">
      <div className="mb-3">
        <div className="form-check form-checkbox-outline form-check-success mb-3">
        <Input
              className="form-check-input"
              name="send_notification"
              type="checkbox"
              id="send_notification"
              onChange={handleNotificationChange}
            
             />
          <label className="form-check-label" htmlFor="send_notification">
          {t('Send Notification')}
          </label>
        </div>
      </div>
      </Col>
            {/* <h6 className="card-title">Personal Information</h6> */}
              {/* <p><strong>First Name:</strong> {formData.firstName}</p>
              <p><strong>Last Name:</strong> {formData.lastName}</p>
              <p><strong>Phone:</strong> {formData.phone}</p>
              <p><strong>Email:</strong> {formData.email}</p>
              <p><strong>Country:</strong> {formData.country?.label}</p>
              <p><strong>City:</strong> {formData.city?.label}</p>
              <p><strong>Address:</strong> {formData.address}</p>
              <p><strong>ZIP Code:</strong> {formData.zip}</p>
              <p><strong>Nationality:</strong> {formData.nationality}</p>
              <p><strong>Gender:</strong> {formData.gender?.label}</p>
              <p><strong>Birth Place:</strong> {formData.birthPlace}</p>
              <p><strong>Birthday:</strong> {formData.birthday}</p>
              <p><strong>Emergency Number:</strong> {formData.emergencyNumber}</p>
              <p><strong>Relation Name:</strong> {formData.relationName}</p>
              <p><strong>Relation:</strong> {formData.relation?.label}</p> */}
              {/* <h6 className="mt-4">Uploaded Images</h6>
              <div className="row">
                <Col lg="4">
                  <div className="text-center">
                    {formData.profileImage ? (
                      <img src={formData.profileImage} alt="Profile" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    ) : (
                      <p>No Profile Image</p>
                    )}
                    <p>Profile Image</p>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="text-center">
                    {formData.proofFrontImage ? (
                      <img src={formData.proofFrontImage} alt="Proof Front" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    ) : (
                      <p>No Proof Front Image</p>
                    )}
                    <p>Proof Front Image</p>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="text-center">
                    {formData.proofBackImage ? (
                      <img src={formData.proofBackImage} alt="Proof Back" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    ) : (
                      <p>No Proof Back Image</p>
                    )}
                    <p>Proof Back Image</p>
                  </div>
                </Col>
              </div>
              <div className="row mt-3">
                <Col lg="6">
                  <div className="text-center">
                    {formData.licenseFrontImage ? (
                      <img src={formData.licenseFrontImage} alt="License Front" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    ) : (
                      <p>No License Front Image</p>
                    )}
                    <p>License Front Image</p>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="text-center">
                    {formData.licenseBackImage ? (
                      <img src={formData.licenseBackImage} alt="License Back" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    ) : (
                      <p>No License Back Image</p>
                    )}
                    <p>License Back Image</p>
                  </div>
                </Col>
              </div> */}
            </div>
            </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            {t('Previous')}
                          </Link>
                        </li>
                        <li
                          className={activeTab === 4 ? "next " : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              
                              if (activeTab < 4) {
                                toggleTab(activeTab + 1);
                              } else if (activeTab === 4) {
                                submitForm(); // Submit the form when on the last tab
                              }
                            }}
                            
                          
                          >
                             {activeTab === 4 ? t('Submit') : t('Next')}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(AddCollaborator));

