import React, { useEffect, useState } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  setData,
} from "../../common/data";
//Import Breadcrumb
import {
  Modal,
} from "reactstrap";
import classnames from "classnames";
import FeatureMenu from "./FeatureMenu";
import Industries from "./Industries";
import Resources from "./Resources";
import i18n from "../../i18n";

const Navbar = (props) => {
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);

  const redirectLink = user && Object.keys(user).length > 0 ? "/home" : "/login";
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");
  const [activeMenu, setActiveMenu] = useState(null); 
  const handleMenuClick = (menuName) => {
    // Toggle the menu state (open if clicked, close if clicked again)
    setActiveMenu((prev) => (prev === menuName ? null : menuName));
  };
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }
  const navigate = useNavigate();
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const handleClick = () => {
    navigate('/plan-and-price/new'); 
  };
  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, [])
 

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("I18N_LANGUAGE", selectedLanguage);
    setSelectedLang(selectedLanguage);
  };

  return (
    <React.Fragment>

<Modal
                      size="xl"
                      isOpen={modal_fullscreen}
                      toggle={() => {
                        tog_fullscreen();
                      }}
                      className="modal-fullscreen"
                    >
                      <div className="modal-header">
                      <span aria-hidden="true" className="css-h3gokb"></span>
                        <button
                          onClick={() => {
                            setmodal_fullscreen(false);
                          }}
                          type="button"
                          className=" css-zzmfyc"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <section className="css-1cjmmev">
<div className="css-1glp163">
<div className="css-1w0un78">
<ul className="css-vk4aun">
<li>
<button  type="button"
 className="css-s5oykz e1yg1y6k0"
 onClick={() => handleMenuClick("Solutions")}
 > {props.t("Solutions")}
<span aria-hidden="true" className="css-14zxrix">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M13.315 6.13814L12.1954 5.01855L8.00015 9.21382L3.80489 5.01855L2.6853 6.13814L8.00015 11.453L13.315 6.13814Z" fill="currentColor"></path
></svg>
</span>
</button>
{activeMenu === "Solutions" && (
        <div className="css-1w0un78">
        <ul className="css-vk4aun">
          <li >
           <a className="text-dark-30"  href="/employee-scheduling">{props.t("Employee scheduling")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/time-tracking">{props.t("Time-tracking")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/absence-management">{props.t("Absence management")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/digital-signature">{props.t("Digital Signature")}</a>
          </li>
        </ul>
        </div>     
                )}
 
</li>
<li>
<button  type="button" className="css-s5oykz e1yg1y6k0"
 onClick={() => handleMenuClick("Industries")}
>{props.t("Industries")}
<span aria-hidden="true" className="css-14zxrix">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M13.315 6.13814L12.1954 5.01855L8.00015 9.21382L3.80489 5.01855L2.6853 6.13814L8.00015 11.453L13.315 6.13814Z" fill="currentColor"></path>
</svg>
</span>
</button>
{activeMenu === "Industries" && (
        <div className="css-1w0un78">
        <ul className="css-vk4aun">
          <li >
           <a className="text-dark-30"  href="/retail">{props.t("Retail")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/restaurant">{props.t("Restaurant")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/transport">{props.t("Transport")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/recreation">{props.t("Recreation")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/hotel">{props.t("Hotel")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/production">{props.t("Production")}</a>
          </li>
        </ul>
        </div>     
                )}
</li>
<li>
<button  type="button" className="css-s5oykz e1yg1y6k0"
onClick={() => handleMenuClick("Resources")}
>{props.t("Resources")}
<span aria-hidden="true" className="css-14zxrix">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M13.315 6.13814L12.1954 5.01855L8.00015 9.21382L3.80489 5.01855L2.6853 6.13814L8.00015 11.453L13.315 6.13814Z" fill="currentColor"></path>
</svg>
</span>
</button>
{activeMenu === "Resources" && (
        <div className="css-1w0un78">
        <ul className="css-vk4aun">
          <li >
           <a className="text-dark-30"  href="/about-us">{props.t("About us")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/support">{props.t("Support")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/contact-sales">{props.t("Contact")}</a>
          </li>
          <li >
           <a className="text-dark-30" href="/blog">{props.t("Blog")}</a>
          </li>
        </ul>
        </div>     
                )}
</li>
<li> 
<button type="button" className="css-s5oykz e1yg1y6k0" onClick={handleClick}>
    {props.t("Plans & Pricing")}
      <span aria-hidden="true" className="css-14zxrix">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.315 6.13814L12.1954 5.01855L8.00015 9.21382L3.80489 5.01855L2.6853 6.13814L8.00015 11.453L13.315 6.13814Z"
            fill="currentColor"
          ></path>
        </svg>
      </span>
    </button>
</li>
</ul>
</div>
<hr className="css-1skinc4" />
<div className="css-1f75d59">
<ul className="css-17wku3l">
<li className="css-1iiagsl">
<a href="https://support.docusign.com/s/contactSupport?language=en_US" target="_self" tabindex="0" className="css-xcov25 ep5kedm0" data-context="nav-utility" data-type="link" data-action="support">
<div className="css-1eg020q ep5kedm1">{props.t("Support")}</div>
</a>
</li>
<li className="css-1iiagsl">
<a href="/plan-and-price/new" target="_self" tabindex="0" className="css-xcov25 ep5kedm0" data-context="nav-utility" data-type="link" data-action="access document">
<div className="css-1eg020q ep5kedm1">{props.t("Sign Up")}</div>
</a>
</li>
<li className="css-1rfzu4w">
<a href={redirectLink} target="_self" style={{color:'#007bff'}} tabindex="0" className="css-xcov25 ep5kedm0">
<div className="css-1eg020q ep5kedm1">{user && Object.keys(user).length > 0 ? props.t("Home") : props.t("Login")}</div>
</a>
</li>
</ul>
<ul className="css-1pib26a">

<li>
<a href="/plan-and-price/new" target="_self"  style={{color:'#007bff'}} className="css-1c2xcvc ep5kedm0"  >
<div className="css-1ra4uzk ep5kedm1">{props.t("Buy Now")}</div>
</a>
</li>
<li>
<a href="/register/starter" target="_self" style={{backgroundColor:'#007bff'}} className="css-1i4t09n ep5kedm0" >
<div className="css-1ra4uzk ep5kedm1" >{props.t("Try for Free")}</div>
</a>
</li>
<li>
<a href="/contact-sales" target="_self" style={{color:'#007bff'}} className="css-1c2xcvc ep5kedm0" >
<div className="css-1ra4uzk ep5kedm1">{props.t("Contact Sales")}</div>
</a>
</li>
</ul>
</div>
</div>

</section>
                      </div>
                   
                    </Modal>
      <div className="container-fluid">
<div className="css-mjxw24 eyj9ga91">

<nav className="css-1cjmmev">
            <div className="css-9brd0r e1p96sfz1">
              <div className="css-1grwys7 e1p96sfz0">
                <div className="css-l63qg9">
               
                  <ul className="css-1lhb9go">
                    <li className="css-fpl025">
                      <span className="css-1olokg6"></span>
                      <a
                        href={`tel:${setData[0].phone_number || ''}`}
                        target="_self"
                        className="css-sxqjov ep5kedm0"
                      >
                        <div className="css-g4mfjf ep5kedm1">
                        {props.t("Sales")}{setData[0].phone_number || ''}
                        </div>
                      </a>
                    </li>
                    {/* <li className="css-1111xiq">
                      <button className="css-1jjw43b">Search</button>
                    </li> */}
                    <li className="css-1111xiq">
                      <a
                        href="/contact-sales"
                        target="_self"
                        className="css-sxqjov ep5kedm0"
                      >
                        <div className="css-g4mfjf ep5kedm1"> {props.t("Support")} </div>
                      </a>
                    </li>
                    <li className="css-1111xiq">
                      <a
                        href="/plan-and-price/new"
                        target="_self"
                        className="css-sxqjov ep5kedm0"
                      >
                        <div className="css-g4mfjf ep5kedm1">{props.t("Sign Up")}</div>
                      </a>
                    </li>
                    <li className="css-1111xiq">
                      <a
                        href={redirectLink}
                        target="_self"
                        className="css-sxqjov ep5kedm0"
                        data-context="nav-utility"
                        data-type="link"
                        data-action="log in"
                      >
                        <div className="css-g4mfjf ep5kedm1">{user && Object.keys(user).length > 0 ? props.t("Go to home") : props.t("Login")}</div>
                      </a>
                    </li>
                    <li className="css-1111xiq">
      <div className="language-switcher">
        <select 
          value={i18n.language} 
          onChange={changeLanguage} 
          className="language-select"
          id="language-switcher"
          disabled={false}
        >
          <option value="en">{props.t("English")}</option>
          <option value="nl">{props.t("Netherlands")}</option>
        </select>
      </div>
    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
         

      <nav  className="css-b9x1zj">
  <div className="css-19njzqs e1p96sfz1" id="header_color">
    <div className="css-1grwys7 e1p96sfz0">
     <div className="css-3wbi5w">
     <div className="css-1jgav8z">
     <button  className="css-kkuep2" onClick={() => {
                        tog_fullscreen();
                      }}> 
<span aria-hidden="true" className="css-1p7pvml">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 6h20V4H2v2zM2 13h20v-2H2v2zM22 20H2v-2h20v2z" fill="currentColor"></path>
</svg>
</span>
</button>
     <a style={{margin:'revert-layer'}} href="/"  className="css-81kfzn ep5kedm0 d-flex" >
       <div className="css-1ra4uzk ep5kedm1">
       <img src={setData[0].website_logo  || ''} height={35} width={120} />
       </div>
     </a>
   </div>
   <ul className="css-kcwioq">
     <li className="css-853953">
     
  <FeatureMenu props={props} />
      
     </li>

     <li className="css-853953">
     <Industries props={props} />
     </li>

     <li className="css-853953">
     <Resources props={props} />
     </li>

     <li className="css-853953">
       <div className="css-qowjig">
        <a href="/plan-and-price/new">
        <button className="css-nx1dk9"> 
         {props.t("Pricing")}
         </button>
        </a>
      
       
       </div>
     </li>
   </ul>
   <div className="css-a8kaei">
          <div className="css-238vhp">
            <a className="css-lsiqut evij40y0" style={{color:'#007bff'}} href="/contact-sales">{props.t("Contact Sales")}</a>
          </div>
          <div className="css-238vhp">
            <a className="css-1vokhr7 evij40y0" style={{backgroundColor:'#007bff'}} href="/register/starter">{props.t("Try for Free")}</a>
          </div>
          <div className="css-238vhp">
            <a className="css-lsiqut evij40y0" style={{color:'#007bff'}} href={redirectLink}>
            {user && Object.keys(user).length > 0 ? props.t("Go to home") : props.t("Login")}
            </a>
          </div>
        </div>
     </div>
    </div>
  </div>
</nav>
</div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
