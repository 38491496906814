import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";

import Pie from "../AllCharts/echart/piechart"
import Gauge from "../AllCharts/echart/gaugechart"
import Line from "../AllCharts/echart/linechart"
import SpecifiedDomainRadarChart from '../AllCharts/rechart/SpecifiedDomainRadarChart';
import ColumnWithDataLabels from "../AllCharts/apex/ColumnWithDataLabels"
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import DashedLine from "../AllCharts/apex/dashedLine"
import {
  getMailsLists as onGetMailsLists,
  getSelectedMails as onGetSelectedMails,
  updateMail as onUpdateMail,
} from "store/actions";

import { withTranslation } from "react-i18next";
const EmailInbox = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [data, setData] = useState([]);
  const [completionData, setCompletionData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [series , setSeries ] = useState([]);
  const [seriesDoc , setseriesDocs ] = useState([]);
  const [totalDashboard, setTotalDashboard] = useState([]);
  const [userdata, setTotaluserdata] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalCompletion, setTotalCompletion] = useState(0);
  useEffect(() => {
    document.title = t("Rapport Dashboard | Comprehensive Reporting & Analytics for Workforce Management - ShiftSpec");
  }, [t]);
 
  const {
    mailslists  = [],
    selectedmails,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    selectedmails: state.mails.selectedmails,
    error: state.mails.error,
  }));
  

  const statusMapping = {
    sending: t('Send'),
    complete: t('Completed'),
    signed: t('In progress'),
    decline: t('Declined'),
  };


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetMailsLists(account_id));
  }, [dispatch,account_id]);

  useEffect(() => {
    if (!mailslists || mailslists.length === 0) {
      console.warn("No mailslists data available.");
      return;
    }
  
    // Your existing logic here
  }, [mailslists]);
  useEffect(() => {
    const dailyPosts  = Array(7).fill(0);
    const monthlyData = new Array(12).fill(0);
    const totalPagesData = new Array(12).fill(0);
  const totalDocumentsData = new Array(12).fill(0);
  const treesSavedData = new Array(12).fill(0);
  const currentYear = new Date().getFullYear();
  const uniqueUsers = new Set();
  const folderDataMap = new Map();
  let pendingCount = 0;
  let completionCount = 0;
  const sheetsPerTree = 8333;
    const statusCounts = {
      sending: 0,
      complete: 0,
      signed: 0,
      decline: 0
    };
    
    if (Array.isArray(mailslists)) {
  mailslists.forEach(mail => {
    const status = mail.post_status;
    uniqueUsers.add(mail.full_name);
    const { folder, post_status } = mail;
    if (!folderDataMap.has(folder)) {
      folderDataMap.set(folder, {
        folder,
        totalpost: 0,
        totalcompelation: 0,
        totalpanding: 0
      });
    }
    const folderData = folderDataMap.get(folder);
    folderData.totalpost++;
    if (post_status === 'completed') {
      folderData.totalcompelation++;
    } else if (post_status === 'sending') {
      folderData.totalpanding++;
    }

    // Update map with modified folder data
    folderDataMap.set(folder, folderData);
    if (mail.post_status === 'sending') {
      pendingCount++;
    }
    if (mail.post_status === 'complete') {
      completionCount++;
    }
    setTotalUsers(uniqueUsers.size); // Set size of uniqueUsers set as total users
    setTotalPending(pendingCount);
    setTotalCompletion(completionCount);
    if (status && statusMapping[status]) {
      statusCounts[status]++;
    }

    const create_time = new Date(mail.create_time);
    const year = create_time.getFullYear();
    const dayOfWeek = create_time.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    dailyPosts[dayOfWeek]++;

    if (create_time.getFullYear() === 2024) {
      // Get the month (0-indexed) 
      const month = create_time.getMonth();
      if (mail.doc_status === 'prepare') {
        monthlyData[month]++;
      }
    }

    if (create_time.getFullYear() === currentYear && mail.doc_status === 'prepare') {
      // Get the month (0-indexed)
      const month = create_time.getMonth();
      const pages = Number(mail.pdfpic) || 0;

      // Increment the total pages and documents for that month
      totalPagesData[month] += pages;; // Assuming each item has a total_pages field
      totalDocumentsData[month] += 1; // Each document adds 1 to the count
    }


  });
 }else{
  console.warn("mailslists is not an array or is undefined.");
 }
    const aggregatedData = Array.from(folderDataMap.values());
    aggregatedData.forEach((item) => {
      uniqueUsers.add(item.full_name); // Assuming mail.full_name is accessible in item
    });
    const uniqueUsersArray = Array.from(uniqueUsers);

    // Update aggregatedData to include the name property from uniqueUsers
    const modifiedData = aggregatedData.map((item, index) => ({
      ...item,
      name: item.name || (uniqueUsersArray[index] || t('Unknown User'))
    }));
    setTotaluserdata(modifiedData);
    for (let i = 0; i < 12; i++) {
      treesSavedData[i] = totalPagesData[i] / sheetsPerTree;
    }
    const transformedData = Object.keys(statusCounts).map(status => ({
      value: statusCounts[status],
      name: statusMapping[status]
    }));

    setData(transformedData);
    const totalItems = mailslists.length;
    setTotalDashboard(totalItems)
    const completedItems = statusCounts.complete;
    const completionRate = totalItems > 0 ? (completedItems / totalItems) * 100 : 0;
    const formattedCompletionRate = parseFloat(completionRate.toFixed(2));
    setCompletionData([{ value: formattedCompletionRate, name: t('Completion') }]);
    const seriess = [{
      name: t('Processing'),
      data: monthlyData.map(count => Number(count.toFixed(2))) // Round to two decimal places if necessary
    }];
       setSeries(seriess)
        // Order daysCounts according to Mon-Sun
        const chartData = dailyPosts.map((count, index) => ({
          count
        }));
        
        const dailyValue = chartData.map(data => data.count);
    
        setDailyData(dailyValue);
       
       
        const docstatics = [
          {
            name: t('Promote Environmental'),
            data: treesSavedData.map(trees => trees.toFixed(4)),
          },
          {
            name: t('Total Page'),
            data: totalPagesData,
          },
          {
            name: t('Total Document'),
            data: totalDocumentsData,
          },
        ];
        setseriesDocs(docstatics)
  }, [mailslists]);
 
  const [activeTab, setactiveTab] = useState(0);

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <div className="inbox-container">
            <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
              <div className="listHeader css-jcpkb3">
                <div className="listHeader_titleRow">
                  <h1 style={{ fontSize: '18px' }} className="listHeader_heading m-bottom-xx-small">Overview</h1>
                </div>
              </div>
            </div>
            <div className="css-rapors32" style={{ padding: '0 0 0 20px' }}>
              <div className="row">
                <div className="col-lg-6" style={{ border: '1px solid #cecece' }}>
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Document Status')}</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <Pie database={data} dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]' />
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-lg-6" style={{ border: '1px solid #cecece' }}>
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Campelation Rate')}</CardTitle>
                      <div id="gauge-chart" className="e-chart">
                        <Gauge database={completionData} dataColors='["--bs-success","--bs-primary","--bs-danger"]' />
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-lg-6" style={{ border: '1px solid #cecece' }}>
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Weekly Post')}</CardTitle>
                      <div id="line-chart" className="e-chart">
                        <Line dailyData={dailyData} dataColors='["--bs-success"]' />
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-lg-6" style={{ border: '1px solid #cecece' }}>
                  <Card>
                  <CardBody>
                  <CardTitle>{t('Document Volume')}</CardTitle>
                  <ColumnWithDataLabels series={series} dataColors='["--bs-primary"]'/>
                </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="inbox-container">
            <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
              <div className="listHeader css-jcpkb3">
                <div className="listHeader_titleRow">
                  <h1 style={{ fontSize: '18px' }} className="listHeader_heading m-bottom-xx-small">{t('Overview')}</h1>
                </div>
              </div>
            </div>
            <div className="css-rapors32" style={{ padding: '0 0 0 20px' }}>
              <div className="row">
                <div className="col-lg-12" style={{ border: '1px solid #cecece' }}>
                  <Card>
                    <CardBody>
                      <DashedLine series={seriesDoc} dataColors='["--bs-primary", "--bs-danger", "--bs-success"]'/>
                    </CardBody>
                  </Card>
                </div>
         
      
        
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="inbox-container">
            <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
              <div className="listHeader css-jcpkb3">
                <div className="listHeader_titleRow">
                  <h1 style={{ fontSize: '18px' }} className="listHeader_heading m-bottom-xx-small">{t('Overview')}</h1>
                </div>
              </div>
            </div>
            <div className="css-rapors32" style={{ padding: '0 0 0 20px' }}>
              <div className="row">
                <div className="col-lg-12" style={{ border: '1px solid #cecece' }}>
                  <Card>
                    <CardBody>
                      <CardTitle>{t('Recipient')}</CardTitle>
                      <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalUsers}</h5>
                        <p className="text-muted text-truncate">{t('Total Recipient')}</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalPending }</h5>
                        <p className="text-muted text-truncate">{t('Pending')}</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalCompletion }</h5>
                        <p className="text-muted text-truncate">{t('Campelation')}</p>
                      </div>
                    </Col>
                  </Row>
                  <SpecifiedDomainRadarChart data={userdata} />
                    </CardBody>
                  </Card>
                </div>
            
      
        
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        {(!mailslists || mailslists.length === 0) ? (
          <div>{t("No mailslists available")}</div> // Display a fallback UI
        ) : (
          <>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t("Reports")} breadcrumbItemLink="/home" breadcrumbItem={t("Back To Home")} />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0,
                        })}
                        onClick={() => {
                            setactiveTab(0);
                          
                          }}
                      >
                     {t("Dashboard")}    

                        <span className="ml-1 float-end">({totalDashboard})</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        onClick={() => {
                            setactiveTab(2);
                          
                          }}
                      >
                      {t("Document")} 
                       <span className="ml-1 float-end">({totalDashboard})</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 3,
                        })}
                        onClick={() => {
                            setactiveTab(3);
                          
                          }}
                      >
                        {t("Recipient")}
                        <span className="ml-1 float-end">({totalUsers})</span>
                      </NavLink>
                    </NavItem>
                
                  </Nav>
                </div>

               
         
              </Card>

             
              <div className="email-rightbar mb-3">
              <Card>
                  {renderContent()}
                </Card>
              </div>
            </Col>
          </Row>
          </>
        )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(EmailInbox));
