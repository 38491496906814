import React from 'react';
import { setData } from "../../common/data";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";

const Footer = (props) => {
  const { t } = props;

  return (
    <div className="container-fluid" style={{ marginTop: '10px' }}>
      {/* Footer */}
      <footer className="text-center text-lg-start text-white" style={{ backgroundColor: '#F8F3F0' }}>
        {/* Section: Social media */}
        <section className="d-flex justify-content-between p-4" style={{ backgroundColor: '#ece9e4' }}>
          {/* Left */}
          <div className="me-5 text-black">
            <span>{t("Get connected with us on social networks:")}  </span>
          </div>
          {/* Right */}
          <div>
            <a href="#!" className="text-black me-4">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#!" className="text-black me-4">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#!" className="text-black me-4">
              <i className="fab fa-google"></i>
            </a>
            <a href="#!" className="text-black me-4">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#!" className="text-black me-4">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="#!" className="text-black me-4">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </section>
        {/* Section: Links */}
        <section>
          <div className="container-fluid text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold text-black">{setData[0].website_name || ''}</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: '60px', backgroundColor: '#5100f8', height: '2px' }}
                />
                <p className="text-black">
                  {setData[0].footer_text || ''}
                </p>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold text-black">{t("Features")}</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: '60px', backgroundColor: '#5100f8', height: '2px' }}
                />
                <p><a href="/employee-scheduling" className="text-black">{t("Employee scheduling")}</a></p>
                <p><a href="/time-tracking" className="text-black">{t("Time-tracking")}</a></p>
                <p><a href="/absence-management" className="text-black">{t("Absence management")}</a></p>
                <p><a href="/digital-signature" className="text-black">{t("Digital Signature")}</a></p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold text-black">{t("Industries")}</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: '60px', backgroundColor: '#5100f8', height: '2px' }}
                />
                <p>
                  <a href="/retail" className="text-black">{t("Retail")}</a>
                </p>
                <p>
                  <a href="/restaurant" className="text-black">{t("Restaurant")}</a>
                </p>
                <p>
                  <a href="/transport" className="text-black">{t("Transport")}</a>
                </p>
                <p>
                  <a href="/recreation" className="text-black">{t("Recreation")}</a>
                </p>
                <p>
                  <a href="/hotel" className="text-black">{t("Hotel")}</a>
                </p>
                <p>
                  <a href="/production" className="text-black">{t("Production")}</a>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold text-black">{t("Contact")}</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: '60px', backgroundColor: '#5100f8', height: '2px' }}
                />
                <p className="text-black"><i className="fas fa-home mr-3 text-black"></i> {setData[0].website_name || ''}</p>
                <p className="text-black"><i className="fas fa-envelope mr-3 text-black"></i> {setData[0].contact_email || ''}</p>
                <p className="text-black"><i className="fas fa-phone mr-3 text-black"></i> {setData[0].phone_number  || ''}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          © 2020 Copyright:
          <a className="text-black" href={setData[0].website_url || ''}>{setData[0].website_name || ''}</a>
        </div>
      </footer>
    </div>
  );
};

Footer.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Footer));
