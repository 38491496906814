import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { map } from "lodash";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import { format } from "date-fns";
//redux
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  getMailsLists as onGetMailsLists,
  accountInvoice as onGetAccountInvoice,

} from "store/actions";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
const BasicTable = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  
  const {
    mailslists,
    invoice,
    loading,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    invoice: state.mails.invoice,
    error: state.mails.error,
    loading: state.mails.loading,
  }));
  //meta title
  useEffect(() => {
    document.title = t("Invoice Management - Manage Your Invoices with eSignatureHub.com");
  }, [t]);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage] = useState(12);
  const dispatch = useDispatch();
  useEffect(() => {
    if (account_id) {
      dispatch(onGetAccountInvoice(account_id));
    }
  }, [dispatch, account_id]);
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = Array.isArray(invoice) ? invoice.slice(indexOfFirstInvoice, indexOfLastInvoice) : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderPDF = (invoice) => (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <div className="invoice-title">
              <h4 className="float-end font-size-16">
                {t('Order')} # {invoice.invoice_id}
              </h4>
              <div className="auth-logo mb-4">
                {/* Add your logo images */}
              </div>
            </div>
            <hr />
            <Row>
              <Col sm="6">
                <address>
                  <strong>Billed To:</strong>
                  <br />
                  <span>{invoice.address}</span>
                  <br />
                </address>
              </Col>
              <Col sm="6" className="text-sm-end">
                <address>
                  <strong>Shipped To:</strong>
                  <br />
                  <span>{invoice.address}</span>
                  <br />
                </address>
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="mt-3">
                <address>
                  <strong>Payment Method:</strong>
                  <br />
                  {invoice.phone_number}
                  <br />
                  {invoice.email}
                </address>
              </Col>
              <Col sm="6" className="mt-3 text-sm-end">
                <address>
                  <strong>Order Date:</strong>
                  <br />
                  {invoice.orderDate}
                  <br />
                  <br />
                </address>
              </Col>
            </Row>
            <div className="py-2 mt-3">
              <h3 className="font-size-15 fw-bold">Order summary</h3>
            </div>
            <div className="table-responsive">
              <Table className="table-nowrap" ref={tableRef}>
                <thead>
                  <tr>
                    <th style={{ width: "70px" }}>No.</th>
                    <th>Item</th>
                    <th className="text-end">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{invoice.invoice_id}</td>
                    <td>{invoice.invoice_client_id}</td>
                    <td className="text-end">{invoice.amount_paid}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-end">
                      Sub Total
                    </td>
                    <td className="text-end">
                      {invoice.amount_due}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="border-0 text-end">
                      <strong>Shipping</strong>
                    </td>
                    <td className="border-0 text-end">
                      {invoice.amount_due}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="border-0 text-end">
                      <strong>Total</strong>
                    </td>
                    <td className="border-0 text-end">
                      <h4 className="m-0">
                        {invoice.amount_due}
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
 
  const exportAsPDF = (invoiceId) => {
    const invoiceToExport = currentInvoices.find(inv => inv.invoice_id === invoiceId);
    const input = renderPDF(invoiceToExport);

    setTimeout(() => {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, width, height);
        pdf.save(`invoice_${invoiceToExport.invoice_id}.pdf`);
      });
    }, 500); // Adjust the delay time as needed
  };

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  return (
    <React.Fragment>
    <div className="table-responsive" style={{padding:'20px'}}>
      <h4 className="card-title">{t('Invoices')}</h4>
      <p className="card-title-desc">
      {t('You currently receive 1 invoice a month. The invoice will be createdon the following day:')} 
         
      </p>
      <Table className="align-middle mb-0" >
        <thead>
          <tr>
            <th>{t('Date')}</th>
            <th>{t('Inv-No')}</th>
            <th>{t('Export')}</th>
            <th>{t('Amount')}</th>
            <th>{t('Status')}</th>
          </tr>
        </thead>
        <tbody>
          {currentInvoices.map((invoice) => (
            <tr key={invoice.invoice_id} id={`invoice-row-${invoice.invoice_id}`}>
             <td>{format(new Date(invoice.created), "MMMM dd, yyyy hh:mm a")}</td>
              <td>{invoice.invoice_unique_number}</td>
              <td>
                <button type="button" className="btn btn-light btn-sm" >
                {t('PDF')} 
                </button>
              </td>
              <td>{invoice.invoice_total}</td>
              <td>
              {invoice.invoice_status === "pending" ? (
                  <a href={invoice.payment_link} target="_blank" rel="noopener noreferrer" className="btn btn-light btn-sm">
                    {invoice.invoice_status}
                  </a>
                ) : (
                  <Button type="button" className="btn btn-light btn-sm">
                    {invoice.invoice_status}
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

        <Row style={{marginTop:'10px'}}>
        <Col xs="7">{t('Showing')} {indexOfFirstInvoice + 1} - {indexOfLastInvoice} {t('of')} {invoice.length}</Col>
        <Col xs="5">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={() => paginate(currentPage < Math.ceil(invoice.length / invoicesPerPage) ? currentPage + 1 : currentPage)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
        </Row>
      
    </div>
  </React.Fragment>
  )
}


export default withTranslation()(withRouter(BasicTable));