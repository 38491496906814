import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Button, Col, Row } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  getMailsLists as onGetMailsLists,
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  postContractType,
  postContract,
  postLinks,
  pustProof

} from "store/actions";
const S3_BUCKET = 'shiftspec';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5CBDQX2KENTO7LJY';
const SECRET_ACCESS_KEY = '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';
AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION
});
const s3 = new AWS.S3();
const LicenceBack = (props) => {
  const { user_id } = useParams();
  const { t,clientData ,isEditorAllowed} = props;
  const dispatch = useDispatch();
  const {
    proof,
    error,
  } = useSelector(state => ({

    proof: state.getClientData.proof,
    error: state.getClientData.error,
  }));
  const [isOpen, setIsOpen] = useState(false);
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [company_name , setCompany_name ] = useState(user.company_name );
  const fullname = `${clientData.first_name || ''} ${clientData.last_name || ''}`.trim();
  const fileInputRef = useRef(null);
  const handleOpenLightbox = () => {
    setIsOpen(true);
  };

  const handleCloseLightbox = () => {
    setIsOpen(false);
  };


  // Function to handle button click and trigger file input click
  const handleUploadClick = () => {
    fileInputRef.current.click(); // Simulate a click on the hidden file input
  };

  // Function to handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `${company_name}/${fullname}/${file.name}`, 
        Body: file,
        ACL: 'public-read', 
      };
      try {
        const data = await s3.upload(params).promise();
        const fileUrl = data.Location;
        const formData = {
          userid:user_id,
          columnName:'drivers_license_img_back',
          doc:fileUrl,
        }
        dispatch(pustProof(formData));
        
      } catch (error) {
        
      }

      // For example, you might want to display the selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = document.createElement('img');
        img.src = e.target.result;
        img.style.maxWidth = '200px';
        document.getElementById('licenceBack').appendChild(img);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (proof && proof.success) {
      toastr.success(t('The file has been updated successfully.'));
    }else if (proof && proof.error) {
      toastr.error(t('The file has been updated unsuccessfully.'));
    }
  }, [proof, dispatch,]);
  return (
    <>
      {clientData.drivers_license_img_back ? (
        <li className="list-group-item">
          <h5>
            <i className="bx bx-check-circle bx-md check_Driver_deatails" style={{ verticalAlign: 'middle' }}></i>
            {t('Driver`s License Back')}   
          </h5>
          <li className="list-group-item">
            <Row className="justify-content-center">
              <Col lg="8" md="10" sm="12">
                <div className="drop_img">
                  <a
                    href={clientData.drivers_license_img_back}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      handleOpenLightbox(); // Open lightbox on click
                    }}
                  >
                    <img
                      height="150"
                      src={clientData.drivers_license_img_back}
                      alt={t('Driver`s License Back')}
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                  <div id="licenceBack" style={{ marginTop: '20px' }}>

                  </div>  
                  <div className="bewerkenbtn" style={{ marginTop: '10px' }}>
                  <Button
                  color="outline-success"
                  style={{ width: '110px' }}
                  className="btn-sm css-1bl3roz"
                  onClick={handleUploadClick}
                  disabled={!isEditorAllowed}
                  >
                 {t('Modify')}
                  </Button>
                  <input
                  type="file"
                  ref={fileInputRef} // Reference to this input element
                  style={{ display: 'none' }} // Hide the input
                  onChange={handleFileChange} // Handle file selection
                  />
                  </div>
                </div>
              </Col>
            </Row>
          </li>
          {isOpen && (
            <Lightbox
              mainSrc={clientData.drivers_license_img_back}
              onCloseRequest={handleCloseLightbox}
            />
          )}
        </li>
      ) : (
        <li className="list-group-item">
          <h5>
            <i className="bx bx-minus bx-md" style={{ verticalAlign: 'middle' }}></i>
            {t('Driver`s License Back')} 
          </h5>
          <p> {t('Driver`s License Back is empty')}</p>
          <div className="bewerkenbtn" style={{ marginTop: '10px' }}>
        <Button
        color="outline-success"
        style={{ width: '110px' }}
        className="btn-sm css-1bl3roz"
        onClick={handleUploadClick}
        disabled={!isEditorAllowed}
        >
        {t('Upload')} 
        </Button>
        </div>
        <input
        type="file"
        ref={fileInputRef} // Reference to this input element
        style={{ display: 'none' }} // Hide the input
        onChange={handleFileChange} // Handle file selection
        />

        <div id="licenceBack" style={{ marginTop: '20px' }}>

        </div>
        </li>
      )}
    </>
  );
};

export default LicenceBack;
