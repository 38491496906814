import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { map } from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  getNotifications ,
} from "store/actions";
import { format, parseISO, isBefore, isEqual } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};
const EmailInbox = (props) => {
  const { t } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const {
    notification,
    error
  } = useSelector(state => ({
    notification: state.mails.notification,
    error: state.mails.error,
  }));
  //meta title

  useEffect(() => {
    document.title = t("Notification Hub - View and Manage Your Alerts | Shiftspec");
  }, [t]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items per page
  const [searchTerm, setSearchTerm] = useState('');

  // Filtered and paginated data state
  const [filteredData, setFilteredData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [errors, setError] = useState(null);
  const [showArrayData, setShowArrayData] = useState([]);
  const [showArrayTrashData, setShowArrayTrashData] = useState([]);
   
  useEffect(() => {
    dispatch(getNotifications(account_id));
  }, [dispatch, userId,account_id]);
 
    
    useEffect(() => {      
      setFilteredData(notification); // Initialize with filtered data
      setTotalItems(notification.length); // Total items count based on filtered data
    }, [notification]);
    
  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to handle search input change
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    filterData(value);
  };
 
  const filterData = (searchTerm) => {
    const lowercasedFilter = searchTerm.toLowerCase();
  
    const filteredData = notification.filter(item =>
      item.fullname.toLowerCase().includes(lowercasedFilter) ||
      item.note_type.toLowerCase().includes(lowercasedFilter)
    );
  
    setFilteredData(filteredData);
    setTotalItems(filteredData.length); // Update total items count
    setCurrentPage(1); // Reset to first page after filtering
  };
  // Function to paginate data
  const paginate = (data, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return data.slice(startIndex, startIndex + pageSize);
  };

  // Paginate the filtered data
  const paginatedData = paginate(filteredData, currentPage, itemsPerPage);
 
//showarray, setShowarrayData
const handleShowClick = (postId) => {
  // Check if postId is already in showArrayData
  const index = showArrayData.indexOf(postId);
  if (index === -1) {
    // If not found, add postId to showArrayData
    setShowArrayData([...showArrayData, postId]);
    
  } else {
    // If found, remove postId from showArrayData
    setShowArrayData(showArrayData.filter(id => id !== postId));
  }
};


const handleRemove = (id) => {
  const data = {
    id: [id] // Convert single ID to an array if it's not already
  };
  markAsRead([id]);
  // Add your logic for Remove action here
};



const handleDeleteClick = () => {
  const data = {
    id: showArrayData // assuming showArrayData is an array of IDs you want to delete
  };
  markAsRead(showArrayData);
  setShowArrayData([]);
};

const handlePermanentlyClick = () => {
  const data = {
    id: showArrayTrashData // assuming showArrayData is an array of IDs you want to permanently delete
  };

  setShowArrayData([]);
};


const markAsRead = async (notificationIds) => {
  
  try {
    const response = await axios.post(
      `https://dspmetrics.com/put/delete/${account_id}`,
      { notificationIds }
    );

    if (response.status === 200 && response.data.success) {
      
      toastr.success(t("Notifications marked as read successfully!"));
      dispatch(getNotifications(account_id));
    } else {
      
      toastr.error(t("Failed to mark notifications as read."), "Error");
    }
    
  } catch (err) {
    console.error("Error updating notifications:", err);
    setError(t("Failed to update notifications."));
  }
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t('Notofication')} breadcrumbItemLink="/home" breadcrumbItem={t('Back To Home')} />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
            

         
              <div className="mb-3">
                <Card>
                {notification.length > 0 ?
                    <>
                <div className="inbox-container">
                  <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
                  <div className="listHeader css-jcpkb3">
                  <div className="listHeader_titleRow" >
                                <h1 className="listHeader_heading m-bottom-xx-small" >{t('Notofication')}</h1>
                               
                                <div className="listHeader_filter">
                                    <div className="cheshire-templates" >
                                        <div className="m-left-large cheshire_visibleContent cheshire_visible-active" >
                                            <span className="templates_filterBtn u-inline-block" >
                                                <div className="listHeader_search">
                                                    <div className="css-0">
                                                        <Label for="KRkDwy3Kp3-sdfdsf" className="css-5gs0ys"></Label>
                                                    </div>
                                                    <div  className="css-k008qs">
                                                        <div className="input-text-box-wrapper css-1eq84o" >
                                                            <div  className="css-1nenmzo">
                                                                <span  className="css-zf56fy">
                                                                    <span aria-hidden="true" className="SVGInline">
                                                                        <svg className="SVGInline-svg" fill="#868686" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                            <path d="M10.926 9.426 16 14.5 14.5 16l-5.074-5.074a6 6 0 1 1 1.5-1.5zM6 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"></path>
                          </svg>
                        </span>
                    </span>
                </div> 
                
                <Input 
                id="KRkDwy3Kp3-sdfdsf"
                 placeholder={t("Search Inbox and Folders")}
                  className="css-1ez4hss" 
                   type="text" 
                   value={searchTerm}
                   onChange={handleSearchChange} 
                   />
            </div>
        </div>
    </div>
    <button aria-haspopup="true" aria-expanded="false" data-qa="manage-envelopes-header-form-menu" className="olv-button olv-ignore-transform css-qhnch2" type="button">
        <span data-qa="manage-envelopes-header-form-menu-icon" className="css-1pig3b7">
            <span className="css-zf56fy">
                <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                            <path d="M4.063 12H0v1h4.063a2 2 0 0 0 3.874 0H16v-1H7.937a2 2 0 0 0-3.874 0zm6-5H0v1h10.063a2 2 0 0 0 3.874 0H16V7h-2.063a2 2 0 0 0-3.874 0zm-8-5H0v1h2.063a2 2 0 0 0 3.874 0H16V2H5.937a2 2 0 0 0-3.874 0z"></path>
                          </svg>
                        </span>
                    </span>
                </span>
                <span aria-hidden="true" data-qa="manage-envelopes-header-form-menu-text" className="css-16hz1ch">{t('Filters')}</span>
                <span data-qa="manage-envelopes-header-form-menu-accessibility-text" className="css-1pdgtej">{t('Edit Filters')}</span>
            </button>
        </span>
    </div>
</div>
</div>
</div>
                  </div>
                  </div>
 

         <div className="css-1obf64m">
         <table className="css-1u2njnl ">
        <thead className="css-1s8spa1">
          <tr>
            <th className="css-17bqg1m">
            <div className="btn-group me-2 mb-2 mb-sm-0" id="deletebtn">
            {showArrayData.length > 0 && (
        <Button type="button" onClick={handleDeleteClick} className="btn btn-danger waves-light waves-effect">
          <i className="far fa-trash-alt"></i> 
        </Button>
      )}
           {showArrayTrashData.length > 0 && (
        <Button type="button" onClick={handlePermanentlyClick} className="btn btn-danger waves-light waves-effect">
          <i className="far fa-trash-alt"></i> 
        </Button>
      )}
              </div>                    
            </th>
            <th className="css-17bqg1m">
            <span className="css-3div8j">{t('Icon')}</span>
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Subject')}</span>
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Last Change')}</span>
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Status')}</span> 
            </th>
            <th className="css-rhk3of">
            <span className="css-3div8j">{t('Type')} </span> 
            </th>
            <th className="css-17bqg1m">
            <span className="css-11jaa7e">{t('Actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          
        {paginatedData.length > 0 ?
                    <>
        {map(paginatedData, (note, index) => (
        <tr  className="css-cir4u5" key={index}>
  <td data-delegate="ignore" className="css-85irbq"> 
  <div className="css-1mwciyk">
  <div className="css-1e0bg48">
    {/* <Input
    type="checkbox"
    
    onChange={() => handleShowClick(note.notification_id)}
    className="css-chekcsa32332"
    /> */}

  </div>
</div>

</td>
<td  className="css-85irbq">
  <div className="css-1mwciyk">
 
  <span role="img" aria-label="Completed" className="respTable-noColumns table_cell-icon icon">
   <span className="css-zf56fy33">
       <span aria-hidden="true" className="SVGInline">
           <svg className="SVGInline-svg" fill="#008938" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
<path d="M16 3.41 14.58 2l-8.92 8.92-4.25-4.24L0 8.09l5.66 5.66z"></path>
</svg>
</span>
</span>
</span>
</div>
</td>
<td className="css-85irbq ">
<span className="table_copy-line-1 table_copy-link css-rpuriw"  >
<div className="u-ellipsis ">{t('Name')}: {note.fullname}</div>
</span>
<span className="table_copy-line-2">
<span>
<span>{t('Message')}: {note.text_msg}</span>
</span>
</span>
</td>

<td className="css-85irbq">
<span className="table_date">{format(new Date(note.update_time), DateTimeFormat)}</span>
</td>
<td className="css-85irbq capitalize">
<span className="capitalize">{note.note_status}</span>
</td>
<td className="css-85irbq">
<span className="table_date">{note.note_type}</span>
</td>
<td data-delegate="ignore" className="css-85irbq capitalize">
<span className="css-79elbk">
<span className="css-1mw9iin">

<button  className="olv-button olv-ignore-transform css-lzi6zi" onClick={() => handleRemove(note.notification_id )}
 style={{ backgroundColor: '#f9f9f9', color: '#1e1e1e',borderInlineEnd:'1px solid #cccccc' }} type="button" >
<span className="css-16hz1ch" >{t('Remove')}</span>
</button>

</span>

</span>

</td>
</tr>
  ))}
</>
:  <tr className="css-cir4u5"> 
<td className="css-85irbq" style={{width:"100%"}}>
  <div className="align-items-center text-center p-4">
    <i className="mdi mdi-email-outline me-2 display-5"></i>
    <h4>{t('No Record Found')}</h4>
  </div>
</td>
</tr>
}
        </tbody>
      </table>
         </div>
                </div>
                </>
                    : <div className="align-items-center text-center p-4"> <i className="mdi mdi-email-outline me-2 display-5"></i> <h4> No Recored Found </h4>
                    </div>}
                </Card>
               
                {notification.length > 0 &&
                  <Row>
                    <Col xs="7">{t('Showing')} {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, totalItems)} {t('of')} {totalItems}</Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }
              
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(EmailInbox));

