import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import './style.css';
import timeZones from './TimeZone.json';
import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
//Import Breadcrumb

import classnames from "classnames";
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getMailsLists as onGetMailsLists,
  getSelectedMails as onGetSelectedMails,
  updateMail as onUpdateMail,
  accountClose,
  getAccountData,
  putAccountData,
} from "store/actions";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
const PlanAndBell = (props) => {
  const { t, permissions } = props;
  useEffect(() => {
    document.title = t("Plan and Billing - Manage Your Subscription and Payment Details | Shiftspec.com");
  }, [t]);
  const navigate = useNavigate();
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isPathAllowed = template_content.includes('setting');
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.account_token);
  const [language, setLanguage] = useState(user.language);
  const [timezone, setTimezone] = useState(user.timezone);
  const [valuta, setValuta] = useState(user.valuta);
  const [DateTimeFormat, setDateFormat] = useState(user.datetimeformat);
  const [account_id , setAccountid ] = useState(user.account_id );
  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setLanguage(currentLanguage);
  }, [])

  const changeOveralAction = (name,value) => {

  
    // Get the current user session from localStorage
    const authUser = localStorage.getItem("authUser");
  
    if (authUser) {
      const user = JSON.parse(authUser);
  
      // Update the language in the user object
      user[name] = value;
  
      // Update the authUser object in localStorage
      localStorage.setItem("authUser", JSON.stringify(user));
      if (name === 'timezone') {
        setTimezone(value);
      } else if (name === 'valuta') {
        setValuta(value);
        
      } else if (name === 'language') {
        i18n.changeLanguage(value);
        setLanguage(value);
        localStorage.setItem("I18N_LANGUAGE", value);
      }else if (name === 'datetimeformat') {
        setDateFormat(value);
        
      }
      // Optionally, if you have a state that represents language in the session, you can update it:
      
    }
  };
  const {
    mailslists,
    selectedmails,
    userid,
    getaccountdata,
    putaccountdata,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    selectedmails: state.mails.selectedmails,
    userid: state.mails.userid,
    getaccountdata: state.getAbsenceData.getaccountdata,
    putaccountdata: state.getAbsenceData.putaccountdata,
    error: state.mails.error,
  }));
  //meta title
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetMailsLists(account_id));
    dispatch(getAccountData(account_id));
  }, [dispatch]);

  
  const completedEnvelopes = mailslists.filter(envelope => envelope.post_status === "complete");
  const progress = `${completedEnvelopes.length}/${mailslists.length}`;
  const handleCloseAccount = async () => {

    dispatch(accountClose(account_id ));
  };

  useEffect(() => {
    if (userid.success) {
      toastr.success(userid.message);
      navigate('/logout')
    }
  
  }, [userid,mailslists]);



  const max = mailslists.length;
  const value = completedEnvelopes.length ;
  
  const percentage = (value / max) * 100;
  let className = 'progress-bar';

  if (percentage > 50) {
    className += ' blue';
  } else {
    className += ' red';
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    changeOveralAction(name, value);
    const postData = {
      id:account_id,
      columnName:name,
      value:value,
      tableName:'accounts',
      condition:'account_id',
    }

   
    dispatch(putAccountData(postData));
  };


  const accountData = getaccountdata.length > 0 ? getaccountdata[0] : {};
  const [formData, setFormData] = useState({
    language: accountData.language,
    timezone: accountData.timezone ,
    valuta: accountData.valuta,
  });
 
  return (
    <React.Fragment>
         <div className="inbox-container">
              <div className="listHeaderWrap listHeaderWrap-templates cheshireContainer">
                <div className="listHeader css-jcpkb3">
                  <div className="listHeader_titleRow">
                    <h2 style={{ fontSize: '20px' }} className="listHeader_heading m-bottom-xx-small">{t('Plan and Billing')}</h2>
                  </div>
                </div>
              </div>
              <div className="css-rapors32" style={{ padding: '0 0 0 20px' }}>
                <div className="row">
                  <div className="col-lg-12" style={{ display:'inline-flex' }}>
               
                    <div className="col-lg-6" style={{ borderRight: '1px solid #cecece' }}>
                  <Card>
                      <CardBody>
                       <CardTitle>{t('Account Close')}</CardTitle>
                       <p className="cSKbWOeYvUBPCWIgqt3A" >{t('Account Token:')}{token}</p>
                        {/* <p className="cSKbWOeYvUBPCWIgqt3A">Site: EU</p> */}
                        <div className="OyGBHIBSY5ycu__B0Zi3 css-5xdky1">
                        
                        {isEditorAllowed ? (
          <Button
            onClick={handleCloseAccount}
            color="danger"
            style={{ backgroundColor: '#d12424' }}
            className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn"
          >
           {t('Close Account')} 
          </Button>
        ) : (
          <p> {t('You do not have permission to close the account.')}</p>
        )}
                            </div>
                       
                      </CardBody>
                    </Card>
                    </div>
                    <div className="col-lg-6" >
                  <Card>
                      <CardBody>
                        <CardTitle>{t('Get More')}</CardTitle>
                        <p >{t('Upgrade now to add more users and advanced features.')}</p>
                        {isEditorAllowed && (
    <Link to={`/plan-and-price/${token}`}>
    <Button   color="Primira"  className=" btn  olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">               
    {t('View Planes')}
 </Button>
 </Link>
)}
                       
                      </CardBody>
                    </Card>
                    </div>
                  </div>
      
               
                </div>
              </div>
              <form>
      <Row>
        <Col lg='12'>
          <div className="w-full">
            <div className="flex w-full items-start justify-between">
              <div className="flex flex-col w-full max-w-sm mt- ng-star-inserted" style={{ padding: '0 30px', margin: '41px 0px' }}>
                <div className="font-semibold mb-1 text-word-mark-800 text-base">{t('Language settings')}</div>
                <div className="mb-2 text-base">{t('Manage your employee language and country settings.')}</div>
                <a target="_blank" className="font-medium text-primary-500 w-fit text-base mb-4 ng-star-inserted" href="/help-center"> {t('Learn more')} </a>
              </div>
              <div className="flex flex-col justify-end w-full max-w-sm [&>*]:mb-8 [&>:last-child]:mb-0">
                {/* Language Selection */}
                
                <div>
                  <label htmlFor="language" className="css-iekl2y">{t('Account Language')}</label>
                  <div className="css-hboir5">
                    <select
                      className="css-12ihcxq"
                      id="language"
                      name="language"
                      value={language}
                      disabled={!isEditorAllowed}
                      onChange={handleInputChange}
                    >
                      <option value="">{t('Select Language')}</option>
                      <option value="en">{t('English')}</option>
                      <option value="nl">{t('Netherlands')}</option>
                      <option value="de">{t('German')}</option>
                      <option value="fr">{t('French')}</option>
                    </select>
                    <span className="css-1baizyq">
                      <span className="css-lzaifp">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>

                {/* Timezone Selection */}
                <div>
                  <label htmlFor="timezone" className="css-iekl2y">{t('Timezone')}</label>
                  <div className="css-hboir5">
                    <select
                      className="css-12ihcxq"
                      id="timezone"
                      name="timezone"
                      value={timezone}
                      disabled={!isEditorAllowed}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">{t('Select Timezone')}</option>
                      {timeZones.map((zone, index) => (
          <optgroup key={index} label={zone.text}>
            {zone.utc.map((region, idx) => (
              <option key={idx} value={region}>
                {region}
              </option>
            ))}
          </optgroup>
        ))}
                    </select>
                    <span className="css-1baizyq">
                      <span className="css-lzaifp">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>

                {/* Currency Selection */}
                <div>
                  <label htmlFor="valuta" className="css-iekl2y">{t('Valuta (Currency)')}</label>
                  <div className="css-hboir5">
                    <select
                      className="css-12ihcxq"
                      id="valuta"
                      name="valuta"
                      value={valuta}
                      disabled={!isEditorAllowed}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">{t('Select Currency')}</option>
                      <option value="USD">{t('USD - US Dollar')}</option>
                      <option value="EUR">{t('EUR - Euro')}</option>
                      <option value="GBP">{t('GBP - British Pound')}</option>
                      <option value="TRY">{t('TRY - Turkish lira')}</option>
                    </select>
                    <span className="css-1baizyq">
                      <span className="css-lzaifp">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>

                <div>
                  <label htmlFor="valuta" className="css-iekl2y">{t('Date Format')}</label>
                  <div className="css-hboir5">
                    <select
                      className="css-12ihcxq"
                      id="datetimeformat"
                      name="datetimeformat"
                      value={DateTimeFormat}
                      disabled={!isEditorAllowed}
                      onChange={handleInputChange}
                      required
                    >
 <option value="">{t('Select Date/Time Format')}</option>
  <option value="MM/DD/YYYY">MM/DD/YYYY - (e.g., 12/31/2024)</option>
  <option value="DD/MM/YYYY">DD/MM/YYYY - (e.g., 31/12/2024)</option>
  <option value="YYYY-MM-DD">YYYY-MM-DD - (e.g., 2024-12-31)</option>
  <option value="MM-DD-YYYY">MM-DD-YYYY - (e.g., 12-31-2024)</option>
  <option value="DD-MM-YYYY">DD-MM-YYYY - (e.g., 31-12-2024)</option>
                    </select>
                    <span className="css-1baizyq">
                      <span className="css-lzaifp">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                          <path d="M4 5h8l-4 6z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Col>
      </Row>
    </form>
            </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(PlanAndBell));