import React, { useEffect } from 'react';

const TawkToWidget = () => {
  useEffect(() => {
    // Initialize Tawk.to script
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    
    (function () {
      var s1 = document.createElement('script');
      var s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://tawk.to/chat/6716313a2480f5b4f590c7e5/1ianb0iuu'; // Your Tawk.to widget URL
      s1.charset = 'UTF-8';
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []); // Empty dependency array means this runs only on mount

  return null; // No UI needed, it's a background script
};

export default TawkToWidget;
