import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import withRouter from 'components/Common/withRouter';
import PropTypes from "prop-types";

//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  changeLayoutMode
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

//components
import Header from "./Header";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

const Layout = (props) => {

  const dispatch = useDispatch();

  const {
    topbarTheme, layoutWidth, isPreloader, showRightSidebar, layoutModeType
  } = useSelector(state => ({
    topbarTheme: state.Layout.topbarTheme,
    layoutWidth: state.Layout.layoutWidth,
    isPreloader: state.Layout.isPreloader,
    showRightSidebar: state.Layout.showRightSidebar,
    layoutModeType: state.Layout.layoutModeType,
  }));

  /*
  document title
  */

  const pathName = useLocation();

  useEffect(() => {
    const title = pathName.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title =
      currentage + " | Shiftspec - Digital online signature web platforms";
  }, [pathName.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout("horizontal"));
  }, [dispatch]);

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);
  
    const preloader = document.getElementById("preloader");
    const status = document.getElementById("status");
  
    if (preloader && status) {
      if (isPreloader === true) {
        preloader.style.display = "block";
        status.style.display = "block";
  
        setTimeout(function () {
          preloader.style.display = "none";
          status.style.display = "none";
        }, 2500);
      } else {
        preloader.style.display = "none";
        status.style.display = "none";
      }
    }
  }, [isPreloader]);
  

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [dispatch, layoutModeType]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const shouldHideFooter = location.pathname === '/Planning/month' || '/Planning/week' || '/Planning/date'; 
  return (
    <React.Fragment>
     {/* <div id="preloader">
  <div id="status">
    <div className="spinner-chase">
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
    </div>
    <div className="digital-sign-online">
      <span className="sign-onlinetext">Shiftspec...</span>
    </div>
  </div>
</div> */}

     <div id="preloader">
  <div id="status">
  <div className="punchloader">
  <span className="punchloader__item"></span>
  <span className="punchloader__item"></span>
  </div>
  <div className="digital-sign-online">
      <span className="sign-onlinetext">Shiftspec</span>
    </div>
 
  </div>
</div>


      <div id="layout-wrapper">
      <Header
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        {/* <Navbar menuOpen={isMenuOpened} /> */}
        <div className="main-content">{props.children}</div>
        {!shouldHideFooter && <Footer />}
      </div>

      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayout: PropTypes.func,/*  */
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
};

export default withRouter(Layout);