import {
  GET_MAILS_LIST_FAIL,
  GET_MAILS_LIST_SUCCESS,
  GET_SELECTED_MAILS_SUCCESS,
  GET_SELECTED_MAILS_FAIL,
  SET_FOLDER_SELECTED_MAILS_SUCCESS,
  SET_FOLDER_SELECTED_MAILS_FAIL,
  SELECT_FOLDER_SUCCESS,
  SELECT_FOLDER_FAIL,
  UPDATE_MAIL_SUCCESS,
  UPDATE_MAIL_FAIL,
  SET_RESEND,
  SET_RESEND_SUCCESS,
  SET_RESEND_FAIL,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  SET_VALID_DATE_SUCCESS,
  SET_VALID_DATE_FAIL,
  CREATE_FOLDER,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAIL,
  UPDATE_FOLDER,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAIL,
  UPDATE_POST_FOLDER,
  UPDATE_POST_FOLDER_SUCCESS,
  UPDATE_POST_FOLDER_FAIL,
  DELETE_POST_DATA,
  DELETE_POST_DATA_SUCCESS,
  DELETE_POST_DATA_FAIL,
  PERMANENTLY_DELETE,
  PERMANENTLY_DELETE_SUCCESS,
  PERMANENTLY_DELETE_FAIL,
  RECOVERY_MAIL,
  RECOVERY_MAIL_SUCCESS,
  RECOVERY_MAIL_FAIL,
  ACCOUNT_CLOSE,
  ACCOUNT_CLOSE_SUCCESS,
  ACCOUNT_CLOSE_FAIL,
  ACCOUNT_PROFILE,
  ACCOUNT_PROFILE_SUCCESS,
  ACCOUNT_PROFILE_FAIL,
  EDIT_ACCOUNT_PROFILE,
  EDIT_ACCOUNT_PROFILE_SUCCESS,
  EDIT_ACCOUNT_PROFILE_FAIL,
  GET_ACCOUNT_INVOICE,
  GET_ACCOUNT_INVOICE_SUCCESS,
  GET_ACCOUNT_INVOICE_FAIL,
  GET_BRANDING_INFO,
  GET_BRANDING_INFO_SUCCESS,
  GET_BRANDING_INFO_FAIL,
  POST_BRANDING_INFO,
  POST_BRANDING_INFO_SUCCESS,
  POST_BRANDING_INFO_FAIL,
  PUT_BRANDING_INFO,
  PUT_BRANDING_INFO_SUCCESS,
  PUT_BRANDING_INFO_FAIL,
  DELETE_BRANDING_INFO,
  DELETE_BRANDING_INFO_SUCCESS,
  DELETE_BRANDING_INFO_FAIL,

  GET_TEMPLATE_INFO,
  GET_TEMPLATE_INFO_SUCCESS,
  GET_TEMPLATE_INFO_FAIL,
  POST_TEMPLATE_INFO,
  POST_TEMPLATE_INFO_SUCCESS,
  POST_TEMPLATE_INFO_FAIL,
  PUT_TEMPLATE_INFO,
  PUT_TEMPLATE_INFO_SUCCESS,
  PUT_TEMPLATE_INFO_FAIL,
  DELETE_TEMPLATE_INFO,
  DELETE_TEMPLATE_INFO_SUCCESS,
  DELETE_TEMPLATE_INFO_FAIL,

  GET_USERS_INFO,
  GET_USERS_INFO_SUCCESS,
  GET_USERS_INFO_FAIL,
  POST_USERS_INFO,
  POST_USERS_INFO_SUCCESS,
  POST_USERS_INFO_FAIL,
  PUT_USERS_INFO,
  PUT_USERS_INFO_SUCCESS,
  PUT_USERS_INFO_FAIL,
  DELETE_USERS_INFO,
  DELETE_USERS_INFO_SUCCESS,
  DELETE_USERS_INFO_FAIL,

  GER_PERMISSION_CONTENT,
  GER_PERMISSION_CONTENT_SUCCESS,
  GER_PERMISSION_CONTENT_FAIL,

  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  mailslists: [],
  error: null,
  selectedmails: [],
  folderId: [],
  resend: null,
  postid: null,
  validdata: null,
  newfolder: null,
  updatefolder: null,
  deletepost: null,
  permantly: null,
  revovery: null,
  userid: {},
  profiles: {},
  profilesdata: {},
  invoice: {},
  info: {},
  postinfo:{},
  putinfo:{},
  deleteinfo:null,
  rules:[],
  temps:null,
  puttemps:null,
  removeid:null,
  userrule:null,
  getuserrule:[],
  putuserrule:null,
  removeuser:null,
  permissionid:{},
  updatepostfolderdata: {},
  successMessage: '',
  errorMessage: '',
  loading: false,
  folders: [],
  notification:[],
};

const Mails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
      };

    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_MAILS_LIST_SUCCESS:
      return {
        ...state,
        mailslists: action.payload,
      };

    case GET_MAILS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SELECT_FOLDER_SUCCESS:
      return {
        ...state,
        folders: action.payload,
      };

    case SELECT_FOLDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_SELECTED_MAILS_SUCCESS:
      return {
        ...state,
        selectedmails: action.payload ? (action.payload.length > 1) ? action.payload : state.selectedmails.includes(action.payload)
          ? state.selectedmails.filter((id) => id !== action.payload)
          : [...state.selectedmails, action.payload] : []
      };

    case GET_SELECTED_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MAIL_SUCCESS:
      return {
        ...state,
        mailslists: state.mailslists.map(mail =>
          mail.id.toString() === action.payload.id.toString()
              ? { ...mail, ...action.payload }
              : mail
      )};

    case UPDATE_MAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SET_FOLDER_SELECTED_MAILS_SUCCESS:
      return {
        ...state,
        selectedmails: state.selectedmails,
        folderId: action.payload,
      };

    case SET_FOLDER_SELECTED_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }; 

      case UPDATE_STATUS:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case UPDATE_STATUS_SUCCESS:
        return {
        ...state,
        loading: false,
        error: null,
        postid: action.payload,
        };
        case UPDATE_STATUS_FAIL:
        return {
        ...state,
        loading: false,
        error: null,
        error: action.payload,
        };
      case SET_RESEND:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case SET_RESEND_SUCCESS:
        return {
        ...state,
        loading: false,
        error: null,
        resend: action.payload,
        };
        case SET_RESEND_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
        case SET_VALID_DATE_SUCCESS:
        return {
        ...state,
        validdata: action.payload,
        };
        case SET_VALID_DATE_FAIL:
        return {
        ...state,
        error: action.payload,
        };
        case CREATE_FOLDER:
          return {
            ...state,
            loading: true,
            error: null,
          };
          case CREATE_FOLDER_SUCCESS:
            return {
              ...state,
              loading: false,
              newfolder: action.payload,
              error: null,
            };
          case CREATE_FOLDER_FAIL:
            return {
              ...state,
              loading: false,
              error: action.payload,
            }; 

            case UPDATE_POST_FOLDER:
              return {
                ...state,
                loading: true,
                error: null,
              };
            case UPDATE_POST_FOLDER_SUCCESS:
            return {
            ...state,
            loading: false,
            updatepostfolderdata: action.payload,
            };
            case UPDATE_POST_FOLDER_FAIL:
            return {
            ...state,
            loading: false,
            error: action.payload,
            };
            case UPDATE_FOLDER:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case UPDATE_FOLDER_SUCCESS:
        return {
        ...state,
        loading: false,
        updatefolder: action.payload,
        };
        case UPDATE_FOLDER_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
        case DELETE_POST_DATA:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case DELETE_POST_DATA_SUCCESS:
        return {
        ...state,
        loading: false,
        deletepost: action.payload,
        };
        case DELETE_POST_DATA_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };

        case PERMANENTLY_DELETE:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case PERMANENTLY_DELETE_SUCCESS:
        return {
        ...state,
        loading: false,
        permantly: action.payload,
        };
        case PERMANENTLY_DELETE_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };


        case RECOVERY_MAIL:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case RECOVERY_MAIL_SUCCESS:
        return {
        ...state,
        loading: false,
        revovery: action.payload,
        };
        case RECOVERY_MAIL_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
        case ACCOUNT_CLOSE:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case ACCOUNT_CLOSE_SUCCESS:
        return {
        ...state,
        loading: false,
        userid: action.payload,
        };
        case ACCOUNT_CLOSE_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
        case ACCOUNT_PROFILE:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case ACCOUNT_PROFILE_SUCCESS:
        return {
        ...state,
        loading: false,
        profiles: action.payload,
        };
        case ACCOUNT_PROFILE_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
        case EDIT_ACCOUNT_PROFILE:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case EDIT_ACCOUNT_PROFILE_SUCCESS:
        return {
        ...state,
        loading: false,
        profilesdata: action.payload,
        };
        case EDIT_ACCOUNT_PROFILE_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
        case GET_ACCOUNT_INVOICE:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case GET_ACCOUNT_INVOICE_SUCCESS:
        return {
        ...state,
        loading: false,
        invoice: action.payload,
        };
        case GET_ACCOUNT_INVOICE_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
        case GET_BRANDING_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case GET_BRANDING_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        info: action.payload,
        };
        case GET_BRANDING_INFO_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };

        case POST_BRANDING_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case POST_BRANDING_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        postinfo: action.payload,
        };
        case POST_BRANDING_INFO_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };

        case PUT_BRANDING_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case PUT_BRANDING_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        putinfo: action.payload,
        };
        case PUT_BRANDING_INFO_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };


        case DELETE_BRANDING_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case DELETE_BRANDING_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        deleteinfo: action.payload,
        };
        case DELETE_BRANDING_INFO_FAIL:
        return {
        ...state,
        loading: false,
        deleteinfo: null,
        error: action.payload,
        };


        case GET_TEMPLATE_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case GET_TEMPLATE_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        rules: action.payload,
        error: null,
        };
        case GET_TEMPLATE_INFO_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };

        case POST_TEMPLATE_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case POST_TEMPLATE_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        temps: action.payload,
        };
        case POST_TEMPLATE_INFO_FAIL:
        return {
        ...state,
        loading: false,
        temps: null,
        error: action.payload,
        };

        case PUT_TEMPLATE_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case PUT_TEMPLATE_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        puttemps: action.payload,
        };
        case PUT_TEMPLATE_INFO_FAIL:
        return {
        ...state,
        loading: false,
        puttemps: null,
        error: action.payload,
        };


        case DELETE_TEMPLATE_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case DELETE_TEMPLATE_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        removeid: action.payload,
        };
        case DELETE_TEMPLATE_INFO_FAIL:
        return {
        ...state,
        loading: false,
        removeid: null,
        error: action.payload,
        };


        
        case GET_USERS_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case GET_USERS_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        getuserrule: action.payload,
        };
        case GET_USERS_INFO_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };

        case POST_USERS_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case POST_USERS_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        userrule: action.payload,
        };
        case POST_USERS_INFO_FAIL:
        return {
        ...state,
        loading: false,
        userrule: null,
        error: action.payload,
        };

        case PUT_USERS_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case PUT_USERS_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        putuserrule: action.payload,
        };
        case PUT_USERS_INFO_FAIL:
        return {
        ...state,
        loading: false,
        putuserrule: null,
        error: action.payload,
        };


        case DELETE_USERS_INFO:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case DELETE_USERS_INFO_SUCCESS:
        return {
        ...state,
        loading: false,
        removeuser: action.payload,
        };
        case DELETE_USERS_INFO_FAIL:
        return {
        ...state,
        loading: false,
        removeuser: null,
        error: action.payload,
        };

        case GER_PERMISSION_CONTENT:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case GER_PERMISSION_CONTENT_SUCCESS:
        return {
        ...state,
        loading: false,
        permissionid: action.payload,
        };
        case GER_PERMISSION_CONTENT_FAIL:
        return {
        ...state,
        loading: false,
        error: action.payload,
        };
    default:
      return state;
  }
};

export default Mails;