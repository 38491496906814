import React, { useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
  } from "reactstrap";
import CustomNavbar from "components/HeaderUnUser/Header";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
import PropTypes from "prop-types";
import RequesDemo from "../RequesDemo";
import SectionIndustries from "../SectionIndustries";
import { Link ,useLocation } from "react-router-dom";
const SoluTimeSheet = (props) => {
  const { t,i18n  } = props;
  const currentLanguage = i18n.language
  const location = useLocation();
  useEffect(() => {
      const headerColor = document.getElementById('header_color');
      
      // Check if the element exists before accessing it
      if (headerColor) {
        headerColor.style.backgroundColor = '#f4fff0';
      }
    }, [])

    useEffect(() => {
    
      // Function to update meta tags
      const updateMetaTags = () => {
        document.title = t(`Time tracking  | Shiftspec.com`);
        const metaElement = document.querySelector('meta[name="description"]');
        if (metaElement) {
          metaElement.setAttribute('content', 'Comprehensive time tracking software for your staff.');
        } else {
          addOrUpdateMeta('description', 'Save time with your punch clock by automating simple tasks.');
        }
        addOrUpdateMeta('keywords', 'Time Tracking, Employee Time Management, Work Hours Tracking, Timesheet Software, Clock In System, Online Time Tracker, Time Management Software, Employee Timesheets, Attendance Tracking, Project Time Tracking, Time Clock, Remote Employee Tracking, Productivity Monitoring, Time and Attendance, Shift Scheduling, Overtime Tracking, Freelance Time Tracker, Time Tracking App, Automated Timesheets, Employee Monitoring');

        function addOrUpdateMeta(name, content) {
          // Check if a meta tag with the given name already exists
          let metaTag = document.querySelector(`meta[name="${name}"]`);
          
          if (metaTag) {
            // If the meta tag exists, update its content
            metaTag.setAttribute('content', content);
          } else {
            // If the meta tag doesn't exist, create it
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag); // Append it to <head>
          }
        }
        
        // Array of meta tags to add/update
        const metaTags = [
          { name: 'description', content: 'Save time with your punch clock by automating simple tasks.' },
          { name: 'keywords', content: 'Time Tracking, Employee Time Management, Work Hours Tracking, Timesheet Software, Clock In System, Online Time Tracker, Time Management Software, Employee Timesheets, Attendance Tracking, Project Time Tracking, Time Clock, Remote Employee Tracking, Productivity Monitoring, Time and Attendance, Shift Scheduling, Overtime Tracking, Freelance Time Tracker, Time Tracking App, Automated Timesheets, Employee Monitoring' },
          { name: 'author', content: 'Elyar Semi' }
        ];
        
        // Iterate over the metaTags array and add/update each meta tag
        metaTags.forEach(tag => {
          addOrUpdateMeta(tag.name, tag.content);
        });
        // Helper function to add or update meta tags
        const addOrUpdateMetaTag = (property, content) => {
          let tag = document.querySelector(`meta[property="${property}"]`) || document.querySelector(`meta[name="${property}"]`);
          if (tag) {
            tag.setAttribute('content', content);
          } else {
            tag = document.createElement('meta');
            tag.setAttribute(property.startsWith('og:') || property.startsWith('twitter:') ? 'property' : 'name', property);
            tag.setAttribute('content', content);
            document.head.appendChild(tag);
          }
        };
  
        // Open Graph Meta Tags
        addOrUpdateMetaTag('og:title',  'Time tracking');
        addOrUpdateMetaTag('og:description', 'Save time with your punch clock by automating simple tasks  with ShiftSpec');
        addOrUpdateMetaTag('og:url', `https://www.shiftspec.com${location.pathname}`);
        addOrUpdateMetaTag('og:type', 'website');
  
        // Twitter Meta Tags
        addOrUpdateMetaTag('twitter:title',  'Time tracking');
        addOrUpdateMetaTag('twitter:description','Save time with your punch clock by automating simple tasks ShiftSpec');
        addOrUpdateMetaTag('twitter:card', 'summary_large_image');
      };

      // Call the function to update meta tags
      updateMetaTags();
  
    }, [ location.pathname, t]);
  return (
    <React.Fragment>
    <CustomNavbar />
  
    <section className="relative lg:pb-10 pt-20 overflow-hidden" style={{background:'#f4fff0'}}>
    <Row>
        <Col sm={6} className='flex align-items-center justify-content-center'>
       
        <div className=" text-left">
  <h1 className="font-bold text-dark text-[30px]  font-extrabold">
  {t('Time tracking')}
  </h1>
  <h2 className="text-base  font-light text-dark-30 ">
  {t('Comprehensive time tracking software for your staff')}
  </h2>
  
  {/* Input and Button Row */}
  <div className="flex items-center mt-4">
    <input
      type="text"
      placeholder={t('Your business email')}
      className="flex-grow px-4 py-2 border border-gray-300 rounded-l-lg text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-blue-500"
    />

    <a href='/register/starter'>
      <button
        title="Try for free"
        role="button"
        type="submit"
        className=" text-white bg-blue-500  font-semibold"
        style={styles.button}
      >
        {t('Try for Free')} 
      </button>
    </a>
  </div>
</div>

        </Col>
        <Col sm={6}>
        <div className="lg:col-span-5 text-center lg:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/aaaaaaaaaaaaaaaaaaaaaaaaaaaa.png"
          width="450"
          height="283"
          className="mx-auto lg:mr-0"
          alt="header image"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/aaaaaaaaaaaaaaaaaaaaaaaaaaaa.png 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
        <div className="lg:hidden flex flex-col items-center text-center mt-12">
          <div className="flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-5 mb-4 lg:mb-3 max-w-xs"></div>
        </div>
      </div>
      {/* Triangle SVG */}
  <svg
    className="absolute top-0  inset-x-0 -z-[5] mx-auto h-[40%] lg:translate-x-[20%] mt-32 opacity-20"
    style={{right:'24px'}}
    width="732"
    height="389"
    viewBox="0 0 732 389"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_26_895)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M625.302 293.386C626.169 291.133 627.896 289.21 630.278 288.153C631.888 287.438 633.666 287.188 635.412 287.432V287.432L720.091 299.248C722.671 299.608 724.861 300.98 726.316 302.906C727.772 304.833 728.491 307.315 728.131 309.895C727.91 311.483 727.287 312.988 726.322 314.268V314.268L676.344 380.577C674.776 382.658 672.513 383.906 670.122 384.242C667.731 384.578 665.211 384.002 663.13 382.434C661.85 381.469 660.836 380.194 660.185 378.729V378.729L625.484 300.603C624.426 298.223 624.434 295.639 625.302 293.386Z"
        stroke="#2A86CD"
        strokeWidth="6.29227"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.921 2.23295C172.217 1.48943 174.797 1.62246 177.117 2.80827C178.686 3.61016 180 4.83541 180.909 6.34525V6.34525L225.019 79.6095C226.363 81.8422 226.676 84.4081 226.094 86.7518C225.513 89.0955 224.038 91.2169 221.807 92.5606C220.434 93.3875 218.873 93.8506 217.271 93.9062V93.9062L134.303 96.784C131.7 96.8743 129.306 95.9005 127.54 94.253C125.774 92.6055 124.636 90.2844 124.545 87.6802C124.488 86.0775 124.841 84.487 125.57 83.059V83.059L164.428 6.9169C165.612 4.59653 167.624 2.97646 169.921 2.23295Z"
        stroke="#2A86CD"
        strokeWidth="6.29227"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.2132 113.629C63.4079 110.257 66.8895 107.722 71.1378 106.824C74.0107 106.216 76.9964 106.425 79.757 107.427V107.427L213.711 156.045C217.793 157.527 220.889 160.522 222.593 164.168C224.298 167.813 224.612 172.109 223.131 176.19C222.22 178.701 220.683 180.939 218.667 182.692V182.692L114.223 273.468C110.946 276.316 106.824 277.566 102.81 277.285C98.795 277.003 94.8872 275.191 92.0379 271.914C90.2844 269.897 89.0737 267.467 88.5203 264.852V264.852L59.01 125.459C58.1106 121.211 59.0185 117.002 61.2132 113.629Z"
        stroke="#2A86CD"
        strokeWidth="10.4871"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_26_895">
        <rect width="732" height="388.117" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
        </Col>
    </Row>
    </section>

    <section className="relative bg-white px-8 pt-12 pb-12">
  <Row className="align-items-center">
    {/* Image Section */}
    <Col xs={12} sm={12} md={6} className="mb-4 mb-md-0">
      <div className="text-center md:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/aaaaaaaaaaaaaaaaaaaaaaaaaaaa.png"
          width="100%"
          height="auto"
          className="img-fluid"
          alt="Workforce Management Solutions"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/aaaaaaaaaaaaaaaaaaaaaaaaaaaa.png 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
      </div>
    </Col>

    {/* Text Section */}
    <Col xs={12} sm={12} md={6} className="d-flex align-items-center justify-content-center">
  <div className="text-left px-3 px-md-4">
    {/* Title */}
    <span className='font-bold text-secondaryk'>{t('Reliable time tracking')}</span>
    <h3 className="text-dark text-[26px] font-extrabold mb-3">
    {t('Easily track hours in one central location')}
    </h3>

    {/* Subtitle */}
    <h2 className="text-base  font-light text-dark-30 mb-3">
    {t('Clocking in can be done through the app or a clock terminal (using a key fob or an bank card)')}
    </h2>

    <ul className="list-none text-base font-light text-dark-30 mb-4">
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Punching times are automatically linked to timesheets and rounded accordingly')}</span>
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('By having employees clock in on location, error-prone data entry is reduced')}</span>
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Clocked-in employees and registered hours are immediately visible')}</span> 
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Determine with Geolocation and IP from which locations employees are allowed to clock in')}</span>  
  </li>

</ul>



    {/* Button */}
    <a href='/register/starter'>
      <button
        title="Try for free"
        role="button"
        type="submit"
        className=" text-white bg-blue-500  font-semibold"
        style={styles.button2}
      >
       {t('Try for Free')} 
      </button>
    </a>
  </div>
</Col>

  </Row>
</section>



<section className="relative bg-white px-8 pt-12 pb-12">
  <Row className="align-items-center">
    <Col xs={12} sm={12} md={6} className="d-flex align-items-center justify-content-center">
  <div className="text-left px-3 px-md-4">
    {/* Title */}
    <span className='font-bold text-secondary'>{t('Automatic time tracking')}</span>
    <h3 className=" text-dark  ] font-extrabold mb-3">
    {t('Save time with your punch clock by automating simple tasks')}
    </h3>

    {/* Subtitle */}
    <h2 className="text-base sm:text-lg md:text-xl font-light text-dark-30 mb-3">
    {t('Automatically calculate surcharges, plus/minus-hours, and breaks')}
    </h2>

    <ul className="list-none text-base font-light text-dark-30 mb-4">
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Break time is applied based on the hours worked')}</span>
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Receive alerts when employees clock in late for their shift')}</span>
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Automatically remind employees when they forget to clock out')}</span> 
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Clocked working hours are rounded based on the work schedule')}</span>  
  </li>
</ul>



    {/* Button */}
    <a href='/register/starter'>
      <button
        title="Try for free"
        role="button"
        type="submit"
        className=" text-white bg-blue-500  font-semibold"
        style={styles.button2}
      >
        {t('Try for Free')} 
      </button>
    </a>
  </div>
</Col>
<Col xs={12} sm={12} md={6} className="mb-4 mb-md-0">
      <div className="text-center md:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.28.39_f1fa91aa.jpg"
          width="100%"
          height="auto"
          className="img-fluid"
          alt="Workforce Management Solutions"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-21+om+12.28.39_f1fa91aa.jpg 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
      </div>
    </Col>
  </Row>
</section>


<section className="relative bg-gray px-8 pt-12 pb-12">
  <Row className="align-items-center">
    {/* Image Section */}
    <Col xs={12} sm={12} md={6} className="mb-4 mb-md-0">
      <div className="text-center md:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/bbbbbbbbbbbbbbbbbbb.png"
          width="100%"
          height="auto"
          className="img-fluid"
          alt="Workforce Management Solutions"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/bbbbbbbbbbbbbbbbbbb.png 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
      </div>
    </Col>

    {/* Text Section */}
    <Col xs={12} sm={12} md={6} className="d-flex align-items-center justify-content-center">
  <div className="text-left px-3 px-md-4">
    {/* Title */}
    <span className='font-bold text-secondary'>{t('Kiosk app')}</span>
    <h3 className=" text-dark  font-extrabold mb-3">
    {t(`With Shiftspec's Kiosk, you can clock in from any mobile device`)}
    </h3>

    {/* Subtitle */}
    <h2 className="text-base sm:text-lg md:text-xl font-light text-dark-30 mb-3">
    {t('Manual time tracking and common errors are a thing of the past')}
    </h2>

    <ul className="list-none text-base font-light text-dark-30 mb-4">
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Clock in and out away from the office')}</span>
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Set location restrictions yourself')}</span> 
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Insight into who is working where')}</span>  
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Set up our time clock app in just a few minutes')}</span> 
  </li>
</ul>



    {/* Button */}
    <a href='/register/starter'>
      <button
        title="Try for free"
        role="button"
        type="submit"
        className=" text-white bg-blue-500  font-semibold"
        style={styles.button2}
      >
        {t('Try for Free')} 
      </button>
    </a>
  </div>
</Col>

  </Row>
</section>


<section className="relative bg-white px-8 pt-12 pb-12">
  <Row className="align-items-center">
    <Col xs={12} sm={12} md={6} className="d-flex align-items-center justify-content-center">
  <div className="text-left px-3 px-md-4">
  <span className='font-bold text-secondary'>{t('Labor costs control')}</span>
    <h3 className=" text-dark font-extrabold mb-3">
    {t('Link your payroll administration and maintain control over your labor costs')}
    </h3>

    {/* Subtitle */}
    <h2 className="text-base sm:text-lg md:text-xl font-light text-dark-30 mb-3">
    {t('Gain insight into personnel costs per hour, department, and role')}
    </h2>

    <ul className="list-none text-base font-light text-dark-30 mb-4">
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Irregularity surcharges are automatically calculated for employees')}</span>
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Keep labor costs low by integrating the schedule with the punch clock')}</span>
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Set different wage rates per job title')}</span> 
  </li>
  <li className="flex items-center mb-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" fill="none" className="mr-3">  {/* Updated to mr-3 */}
      <path fill="#31A7F0" d="M14.656.316a1.068 1.068 0 0 0-1.515 0L5.189 8.278 1.85 4.927A1.09 1.09 0 1 0 .333 6.496l4.098 4.098a1.067 1.067 0 0 0 1.516 0l8.71-8.71a1.067 1.067 0 0 0 0-1.568Z"></path>
    </svg>
    <span style={{paddingLeft:'10px'}}>{t('Record hours, breaks, overtime, and paid leave with the punch clock')}</span>  
  </li>

</ul>



    {/* Button */}
    <a href='/register/starter'>
      <button
        title="Try for free"
        role="button"
        type="submit"
        className=" text-white bg-blue-500  font-semibold"
        style={styles.button2}
      >
        {t('Try for Free')} 
      </button>
    </a>
  </div>
</Col>
<Col xs={12} sm={12} md={6} className="mb-4 mb-md-0">
      <div className="text-center md:text-right">
        <img
          src="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-09+om+18.06.38_3efa388b.jpg"
          width="100%"
          height="auto"
          className="img-fluid"
          alt="Workforce Management Solutions"
          srcSet="https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-12-09+om+18.06.38_3efa388b.jpg 1350w"
          sizes="(max-width: 450px) 100vw, 450px"
        />
      </div>
    </Col>
  </Row>
</section>

<SectionIndustries props={props} />
    <RequesDemo props={props} />

    </React.Fragment>
  );
};

// Inline styles for simplicity
const styles = {
  container: {
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  },
  button2: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px 4px 4px 4px',
    cursor: 'pointer',
  },
  section: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#00a0f0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '20px',
    color: '#555',
  },
  input: {
    flex: 1,
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '4px 0 0 4px',
    border: '1px solid #ccc',
    outline: 'none',
  },
};

SoluTimeSheet.propTypes = {
    history: PropTypes.object,
    t: PropTypes.isRequired,
  };

  export default withRouter(withTranslation()(SoluTimeSheet));

