import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Dropdown, DropdownToggle, DropdownMenu 
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import withRouter from "components/Common/withRouter";
  import megamenuImg from "../../assets/images/megamenu-img.png";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const Industries = ({props}) => {
    const [menu, setMenu] = useState(false);
  return (
    <Dropdown
    className="dropdown-mega d-none d-lg-block ms-2"
    isOpen={menu}
    toggle={() => setMenu(!menu)}
  >
    
    <div className="css-qowjig">
    <DropdownToggle
      className="css-nx1dk9"
      caret
      tag="button"
    >
     
      {props.t("Industries")}
      <span aria-hidden="true" className="css-1d1unjo">
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
     <path fillRule="evenodd" clipRule="evenodd" d="M13.315 6.13814L12.1954 5.01855L8.00015 9.21382L3.80489 5.01855L2.6853 6.13814L8.00015 11.453L13.315 6.13814Z" fill="currentColor"></path>
   </svg>
 </span>
    </DropdownToggle>
    <DropdownMenu className="dropdown-megamenu" style={{margin:'0 0 0 -22px',width:'100%'}}>
<Row>
{/* First Column with Menu Items */}
<Col sm={8}>
<Row>
  <Col md={6}>
    {/* <h5 className="font-size-14 mt-0">{props.t("UI Components")}</h5> */}
    <ul className="list-unstyled megamenu-list ">
      <li>
      <a
  href="/retail"
  className="flex items-center rounded-lg hover:bg-black"
  title="Retail"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
    <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path d="M23.992 6.986a1.5 1.5 0 0 0 0-.228l-2.4-6a1.2 1.2 0 0 0-1.2-.755H3.6a1.2 1.2 0 0 0-1.116.756l-2.399 6a1.5 1.5 0 0 0 0 .227.696.696 0 0 0-.084.216 4.8 4.8 0 0 0 2.4 4.08V22.8A1.2 1.2 0 0 0 3.598 24h16.794a1.2 1.2 0 0 0 1.2-1.2V11.33a4.8 4.8 0 0 0 2.399-4.128.696.696 0 0 0 0-.216ZM13.195 21.6h-2.399v-4.8h2.4v4.8Zm5.998 0h-3.598v-5.999a1.2 1.2 0 0 0-1.2-1.2H9.597a1.2 1.2 0 0 0-1.2 1.2v6H4.798V12a4.798 4.798 0 0 0 3.6-1.655 4.798 4.798 0 0 0 7.197 0A4.798 4.798 0 0 0 19.193 12v9.6Zm0-11.998a2.4 2.4 0 0 1-2.399-2.4 1.2 1.2 0 1 0-2.4 0 2.4 2.4 0 1 1-4.797 0 1.2 1.2 0 1 0-2.4 0 2.402 2.402 0 1 1-4.798.18l2.015-4.98h15.163l2.015 4.98a2.4 2.4 0 0 1-2.399 2.22Z"
     fill="#EF233C" 
     fillRule="nonzero"></path>
    </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Retail")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Plan the right people at the right times and save valuable time and money in your store.")} 
    </p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/restaurant"
  className="flex items-center rounded-lg"
  title="Restaurant"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path 
  d="M17.813 0c-.656 0-1.188.537-1.188 1.2v6.552l-1.188.804V1.2c0-.663-.531-1.2-1.187-1.2s-1.188.537-1.188 1.2v7.356l-1.187-.804V1.2c0-.663-.532-1.2-1.188-1.2C10.033 0 9.5.537 9.5 1.2v7.2c.002.401.203.775.534.996l3.028 2.052V22.8c0 .663.532 1.2 1.188 1.2.656 0 1.188-.537 1.188-1.2V11.448l3.028-2.052c.331-.22.532-.595.534-.996V1.2c0-.663-.532-1.2-1.188-1.2ZM5.938 0C2.658 0 0 2.686 0 6v7.2c0 .663.532 1.2 1.188 1.2H4.75v8.4c0 .663.532 1.2 1.188 1.2.655 0 1.187-.537 1.187-1.2V1.2c0-.663-.532-1.2-1.188-1.2ZM4.75 12H2.375V6c0-1.526.951-2.887 2.375-3.396V12Z"
   fill="#3CCEAC" fillRule="nonzero">

   </path>
  </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Restaurant")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Shiftspec will help you plan enough staff to meet the demand and still keep your costs as low as possible.")}
    </p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/transport"
  className="flex items-center rounded-lg"
  title="Transport"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path 
 d="M0 11.053v5.526c0 .61.488 1.105 1.09 1.105h1.092C2.182 19.515 3.647 21 5.455 21c1.807 0 3.272-1.485 3.272-3.316h6.546c0 1.831 1.465 3.316 3.272 3.316 1.808 0 3.273-1.485 3.273-3.316h1.091c.603 0 1.091-.495 1.091-1.105V3.316C24 1.485 22.535 0 20.727 0H10.91C9.102 0 7.636 1.485 7.636 3.316v2.21H5.455c-1.03 0-2 .492-2.619 1.327L.218 10.389a.678.678 0 0 0-.076.155l-.066.122a1.118 1.118 0 0 0-.076.387Zm17.455 6.631c0-.61.488-1.105 1.09-1.105.603 0 1.091.495 1.091 1.105s-.488 1.105-1.09 1.105c-.603 0-1.091-.494-1.091-1.105ZM9.818 3.316c0-.61.489-1.105 1.091-1.105h9.818c.603 0 1.091.494 1.091 1.105v12.158h-.85a3.254 3.254 0 0 0-2.423-1.086c-.922 0-1.801.394-2.421 1.086H9.818V3.316ZM7.636 9.947H3.273L4.582 8.18c.206-.278.53-.442.873-.442h2.181v2.21Zm-3.272 7.737c0-.61.488-1.105 1.09-1.105.603 0 1.091.495 1.091 1.105s-.488 1.105-1.09 1.105c-.603 0-1.091-.494-1.091-1.105Zm-2.182-5.526h5.454v3.073a3.243 3.243 0 0 0-4.603.243h-.851v-3.316Z"
   fill="#FF7D00" 
   fillRule="nonzero"
   ></path>
 
  </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Transport")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Fast and reliable planning, because every second counts on the road.")}
    </p>
  </div>
</a>
      </li>
     
    </ul>
  </Col>
  <Col sm={6}>
  <ul className="list-unstyled megamenu-list ">
  <li>
      <a
  href="/recreation"
  className="flex items-center rounded-lg"
  title="Recreation"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path 
 d="M21.684 4.92a11.82 11.82 0 0 0-5.16-4.034A11.994 11.994 0 0 0 5.309 2.04 12.009 12.009 0 0 0 0 11.992v.673a11.94 11.94 0 0 0 1.596 5.33 12.001 12.001 0 0 0 7.068 5.584c1.087.294 2.21.435 3.336.42.757.003 1.513-.069 2.256-.216a12.004 12.004 0 0 0 9.6-10.098c.09-.56.138-1.126.144-1.693a11.808 11.808 0 0 0-2.316-7.072Zm-2.4.925L18 9.279l-1.944.528-2.856-2.03v-2.22l2.988-2.174a9.373 9.373 0 0 1 3.144 2.462h-.048Zm-4.884 5.75-.936 2.799h-2.94L9.6 11.596 12 9.879l2.4 1.717ZM12 2.388c.446.005.89.041 1.332.108L12 3.455l-1.332-.96c.44-.067.886-.103 1.332-.108ZM3.456 7.634l.48 1.585-1.356.948c.17-.882.465-1.735.876-2.533ZM5.1 18.644l1.656.06L7.2 20.3a9.985 9.985 0 0 1-2.1-1.657Zm2.1-2.33-3.78-.132a9.405 9.405 0 0 1-.936-2.99l2.988-2.088 1.728.744 1.068 3.314L7.2 16.315Zm1.032-6.64-1.872-.84-1.092-3.602A9.516 9.516 0 0 1 7.8 3.383l3 2.174v2.22L8.232 9.676Zm4.968 11.84a9.69 9.69 0 0 1-3.156-.145l-.996-3.506.996-1.068h3.684l.804 1.2-1.332 3.518Zm2.892-.841.552-1.477 1.632.084a9.395 9.395 0 0 1-2.22 1.393h.036Zm4.152-3.746-3.732-.132-.852-1.32 1.068-3.315 1.812-.492 2.832 2.401a9.416 9.416 0 0 1-1.164 2.858h.036Zm.06-7 .576-1.537c.338.816.552 1.678.636 2.558l-1.212-1.021Z"
   fill="#606C38" 
   fillRule="nonzero"
   ></path>
 
  </svg>

  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Recreation")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Make sure that your staff are in the right place at the right time, for optimal customer satisfaction.")}
    </p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/hotel"
  className="flex items-center rounded-lg"
  title="Hotel"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path 
 d="M6.545 8.533c1.808 0 3.273-1.432 3.273-3.2 0-1.767-1.465-3.2-3.273-3.2-1.807 0-3.272 1.433-3.272 3.2 0 1.768 1.465 3.2 3.272 3.2Zm0-4.266c.603 0 1.091.477 1.091 1.066 0 .59-.488 1.067-1.09 1.067-.603 0-1.091-.478-1.091-1.067s.488-1.066 1.09-1.066Zm14.182-2.134H12c-.602 0-1.09.478-1.09 1.067v6.4H2.181V1.067C2.182.477 1.693 0 1.09 0 .488 0 0 .478 0 1.067v13.866C0 15.523.488 16 1.09 16c.603 0 1.092-.478 1.092-1.067v-3.2h19.636v3.2c0 .59.489 1.067 1.091 1.067.603 0 1.091-.478 1.091-1.067v-9.6c0-1.767-1.465-3.2-3.273-3.2ZM21.818 9.6h-8.727V4.267h7.636c.603 0 1.091.477 1.091 1.066V9.6Z"
   fill="#ED609C" 
   fillRule="nonzero"
   ></path>
 
  </svg>

  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Hotel")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Offer 5-star service by planning staff as efficiently as possible.")}
    </p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/production"
  className="flex items-center rounded-lg"
  title="Production"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path 
 d="M20.726 9.324V9.13c.015-3.995-2.596-7.53-6.435-8.716h-.069a9.635 9.635 0 0 0-2.981-.412C6.25.274 2.337 4.359 2.308 9.324A3.433 3.433 0 0 0 0 12.561c0 1.456.925 2.754 2.308 3.237.49 4.68 4.474 8.227 9.209 8.202h.265c4.564-.22 8.301-3.682 8.84-8.19A3.434 3.434 0 0 0 23 12.604a3.432 3.432 0 0 0-2.274-3.28Zm-9.002 12.388c-3.446.1-6.437-2.342-7.01-5.72h13.549c-.579 3.185-3.287 5.554-6.539 5.72Zm7.851-8.007H3.46a1.148 1.148 0 0 1-1.151-1.144c0-.631.515-1.144 1.15-1.144h2.303c.636 0 1.151-.512 1.151-1.143 0-.632-.515-1.144-1.15-1.144H4.61a7.312 7.312 0 0 1 3.453-5.891v3.603c0 .632.516 1.144 1.152 1.144.635 0 1.15-.512 1.15-1.144V2.37c.313-.052.628-.087.945-.103h.207c.386.005.77.04 1.151.103v4.472c0 .632.516 1.144 1.151 1.144.636 0 1.152-.512 1.152-1.144V3.205c.488.28.943.614 1.358.995a6.842 6.842 0 0 1 2.095 4.93h-1.151c-.636 0-1.151.512-1.151 1.144 0 .631.515 1.143 1.15 1.143h2.303c.636 0 1.151.513 1.151 1.144 0 .632-.515 1.144-1.15 1.144Z" 
 fill="#9D673E"
   fillRule="nonzero"
   ></path>
 
  </svg>

  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Production")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Make it easier for production and supply staff to communicate their availability and time off with our software.")}
    </p>
  </div>
</a>
      </li>
    </ul>
  </Col>
</Row>
</Col>

{/* Second Column with Image */}
<Col sm={4} className="megamenu-image-col">
<Row>
  <Col sm={12}>
    <div className="megamenu-image">
      <img
        src={'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-19+om+15.21.46_1d5886a7.png'}
        alt=""
        className="img-fluid mx-auto d-block"
      />
    </div>
  </Col>
</Row>
</Col>
</Row>
</DropdownMenu>

</div>

  </Dropdown>
  );
};

export default Industries;
