import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { map } from "lodash";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  Input,
} from "reactstrap"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { format } from "date-fns";
//redux
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  getMailsLists as onGetMailsLists,
  accountInvoice as onGetAccountInvoice,
  getAbsencePolicyData as onGetAbsencePolicyData,
  postAbsencePolicyData,
  putAbsencePolicyData,
  deleteAbsencePolicy,
  getAbsencePolicyData,
} from "store/actions";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
const Absencepolicy = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [modal_center, setmodal_center] = useState(false);
  const [upmodal_center, setUpmodal_center] = useState(false);
  const [errors, setErrors] = useState({});
  const [hoveredSection, setHoveredSection] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const handleMouseEnter = (section) => setHoveredSection(section);
  const handleMouseLeave = () => setHoveredSection(null);
  const {
    mailslists,
    invoice,
    getabsencepolicy,
    postabsencepolicy,
    putabsencepolicy,
    deleteabsencepolicy,
    loading,
    error
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    invoice: state.mails.invoice,
    getabsencepolicy: state.getAbsenceData.getabsencepolicy,
    postabsencepolicy: state.getAbsenceData.postabsencepolicy,
    putabsencepolicy: state.getAbsenceData.putabsencepolicy,
    deleteabsencepolicy: state.getAbsenceData.deleteabsencepolicy,
    error: state.mails.error,
    loading: state.mails.loading,
  }));
  //meta title

  
  const dispatch = useDispatch();
  useEffect(() => {
    if (account_id) {
      dispatch(onGetAccountInvoice(account_id));
      dispatch(onGetAbsencePolicyData(account_id));
    }
  }, [dispatch, account_id]);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_upcenter() {
    setUpmodal_center(!upmodal_center);
    removeBodyCss();
  }
  // Handle input changes
  const [policyData, setPolicyData] = useState({
    account_id:account_id,
    policy_name: '',
    time_off_accrual: 'contract_hours',
    waiting_period: 0,
    country:null,
  });
  const [policyUpData, setPolicyUpData] = useState({
    account_id:account_id,
    policy_name: '',
    country: '',
    time_off_accrual: '',
    waiting_period: ''
  });
  
  const openEditModal = (policy) => {
    setSelectedPolicy(policy);
    setPolicyUpData({
      account_id:account_id,
      policy_id:policy.policy_id,
      policy_name: policy.policy_name,
      country: policy.country,
      time_off_accrual: policy.time_off_accrual,
      waiting_period: policy.waiting_period
    });
    setUpmodal_center(true);
  };
  
  const handlePolicyUpChange = (e) => {
    const { name, value } = e.target;
    setPolicyUpData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  // Handle form input changes for absence policy
  const handlePolicyChange = (e) => {
    const { name, value } = e.target;
    setPolicyData({
      ...policyData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'account_id',
      'policy_name',
    ];
  
    requiredFields.forEach((field) => {
      if (!policyData[field]) {
        newErrors[field] = t('This field is required');
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const validateUpForm = () => {
    const newErrors = {};
    const requiredFields = [
      'account_id',
      'policy_name',
    ];
  
    requiredFields.forEach((field) => {
      if (!policyUpData[field]) {
        newErrors[field] = t('This field is required');
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(postAbsencePolicyData(policyData));;
      toastr.success(t('Form submitted.'));
      setmodal_center(!modal_center);
    } else {

      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }

  };

  const handleUpSubmit = (e) => {
    e.preventDefault();
    if (validateUpForm()) {

      dispatch(putAbsencePolicyData(policyUpData));;
      toastr.success(t('Form submitted.'));
      setUpmodal_center(!upmodal_center);
    } else {
      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }

  };

  const removeModal = (id) => {
    if (id) {
      
      dispatch(deleteAbsencePolicy(id));;
      toastr.success(t('Form submitted.'));
    } else {
      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }

  };

  useEffect(() => {
    if (postabsencepolicy && postabsencepolicy.success) {
      toastr.success(t('Absence policy created successfully! Your policy is now active and ready for use.'));
      dispatch(onGetAbsencePolicyData(account_id));;
    }
  
  }, [postabsencepolicy]);

  useEffect(() => {
    if (putabsencepolicy && putabsencepolicy.success) {
      toastr.success(t('Absence policy updated successfully! All changes have been saved and applied.'));
      dispatch(onGetAbsencePolicyData(account_id));;
    }
  
  }, [putabsencepolicy]);

  useEffect(() => {
    if (deleteabsencepolicy && deleteabsencepolicy.success) {
      toastr.success(t('Absence policy delete successfully!'));
      dispatch(onGetAbsencePolicyData(account_id));;
    }
  
  }, [deleteabsencepolicy]);



  return (
    <React.Fragment>
<Modal
                      isOpen={upmodal_center}
                      toggle={() => {
                        tog_upcenter();
                      }}
                      centered
                    >
                      <div className="modal-header">
                      
                        <h5 className="modal-title mt-0">{t('Update Absence Policy')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setUpmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      
      <small className="card-title-desc">
      {t('This form helps define the rules for how employees can take time off (vacation, sick leave, etc.), and how it’s managed in the system.')} 
      </small>

      <div className="row">
                  <div className="col-lg-12" >
                  <Card >
      <CardBody style={{padding:'revert'}}>
      <form onSubmit={handleUpSubmit}>
      <div className="css-list">
      <div>
      <label htmlFor="typeName" className="css-iekl2y">
           {t('Policy Name')}
            </label>
      
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="text"
              id="policy_name"
              name="policy_name"
              value={policyUpData.policy_name}
              onChange={handlePolicyUpChange}
              required
            />
          </div>
          {errors.policy_name && <p className="text-danger">{errors.policy_name}</p>}
        </div>
        <div>

        <label htmlFor="policyId" className="css-iekl2y">{t('Country')}</label>
          
          <div className="css-hboir5">
          <select
  className="css-12ihcxq"
  id="country"
  name="country"
  value={policyUpData.country}
  onChange={handlePolicyUpChange}
  
>
  <option value="">{t('Select Country')}</option>
  <optgroup label="Europe">
    <option value="DE">{t('Germany')}</option>
    <option value="FR">{t('France')}</option>
    <option value="NL">{t('Netherlands')}</option>
    <option value="UK">{t('United Kingdom')}</option>
    <option value="ES">{t('Spain')}</option>
    <option value="IT">{t('Italy')}</option>
    <option value="BE">{t('Belgium')}</option>
    <option value="SE">{t('Sweden')}</option>
    <option value="CH">{t('Switzerland')}</option>
    <option value="AT">{t('Austria')}</option>
  </optgroup>
  
  <optgroup label="North America">
    <option value="US">{t('United States')}</option>
    <option value="CA">{t('Canada')}</option>
  </optgroup>
  
  <optgroup label="Australia">
    <option value="AU">{t('Australia')}</option>
  </optgroup>
</select>

            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>
        <div>
        <Row>
          <Col lg='4'>
          <label htmlFor="policyId" className="css-iekl2y">{t('Time Off Accrua')}l</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'time_off_accrual' && (
        <div
        onMouseEnter={() => handleMouseEnter('time_off_accrual')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s">
          {t('As the administrator, you can manage the time off accrual policy here. This determines how an employee earns paid time off based on their contract or working hours. Ensure the accrual rate, waiting periods, and maximum accrual limits are clearly defined to maintain accurate time off balances for all employees.')} 
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'time_off_accrual' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('time_off_accrual')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    <span className="css-1pdgtej">{t('Meer leren')}</span>
                    </button>
                    </div>
          </Col>
        </Row>
         
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="time_off_accrual"
              name="time_off_accrual"
              value={policyUpData.time_off_accrual}
              onChange={handlePolicyUpChange}
              required
            >
               <option value="contract_hours">{t('Contract Hours')}</option>
          <option value="worked_hours">{t('Worked Hours')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>

        <div>
        <Row>
          <Col lg='4'>
          <label htmlFor="waiting_period" className="css-iekl2y">{t('Waiting Period (in days)')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'waiting_period' && (
        <div
        onMouseEnter={() => handleMouseEnter('waiting_period')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('The waiting period refers to the time an employee must wait before they can start accruing or using their time off. This period is usually defined in the employment contract or absence policy. During this time, employees are not eligible to earn or use paid time off.')} 
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'waiting_period' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('waiting_period')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    <span className="css-1pdgtej">{t('Meer leren')}</span>
                    </button>
                    </div>
          </Col>
        </Row>
         
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="text"
              id="waiting_period"
              name="waiting_period"
              value={policyUpData.waiting_period}
              onChange={handlePolicyUpChange}
              required
            />
          </div>
        </div>


        </div>
      {/* Submit Button */}
      <button type="submit" className="btn css-1bl3roz startbtn btn btn-Primira">
      {t('Update Policy')}
        </button>
    </form>
      </CardBody>
    </Card>
                  </div>
                </div>
                      </div>
                    </Modal>
<Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      centered
                    >
                      <div className="modal-header">
                      
                        <h5 className="modal-title mt-0">{t('Absence Policy')}</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      
      <small className="card-title-desc">
      {t('This form helps define the rules for how employees can take time off (vacation, sick leave, etc.), and how it’s managed in the system.')} 
      </small>

      <div className="row">
                  <div className="col-lg-12" >
                  <Card >
      <CardBody style={{padding:'revert'}}>
      <form onSubmit={handleSubmit}>
      <div className="css-list">
      <div>
      <label htmlFor="typeName" className="css-iekl2y">
      {t('Policy Name')} 
            </label>
      
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="text"
              id="policy_name"
              name="policy_name"
              value={policyData.policy_name}
              onChange={handlePolicyChange}
              required
            />
          </div>
          {errors.policy_name && <p className="text-danger">{errors.policy_name}</p>}
        </div>
        <div>

        <label htmlFor="policyId" className="css-iekl2y">{t('Country')}</label>
          
          <div className="css-hboir5">
          <select
  className="css-12ihcxq"
  id="country"
  name="country"
  value={policyData.country}
  onChange={handlePolicyChange}
  
>
<option value="">{t('Select Country')}</option>
<optgroup label="Europe">
  <option value="de">{t('Germany')}</option>
  <option value="fr">{t('France')}</option>
  <option value="nl">{t('Netherlands')}</option>
  <option value="en">{t('United Kingdom')}</option>
  <option value="es">{t('Spain')}</option>
  <option value="it">{t('Italy')}</option>
  <option value="nl">{t('Belgium')}</option>
  <option value="sv">{t('Sweden')}</option>
  <option value="de">{t('Switzerland')}</option>
  <option value="de">{t('Austria')}</option>
</optgroup>

<optgroup label="North America">
  <option value="en-US">{t('United States')}</option>
  <option value="en-CA">{t('Canada')}</option>
</optgroup>

<optgroup label="Australia">
  <option value="en-AU">{t('Australia')}</option>
</optgroup>
</select>


            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>
        <div>
        <Row>
          <Col lg='4'>
          <label htmlFor="policyId" className="css-iekl2y">{t('Time Off Accrual')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'time_off_accrual' && (
        <div
        onMouseEnter={() => handleMouseEnter('time_off_accrual')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s">
          {t('As the administrator, you can manage the time off accrual policy here. This determines how an employee earns paid time off based on their contract or working hours. Ensure the accrual rate, waiting periods, and maximum accrual limits are clearly defined to maintain accurate time off balances for all employees.')} 
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'time_off_accrual' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('time_off_accrual')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    <span className="css-1pdgtej">{t('Meer leren')}</span>
                    </button>
                    </div>
          </Col>
        </Row>
         
          <div className="css-hboir5">
            <select
              className="css-12ihcxq"
              id="time_off_accrual"
              name="time_off_accrual"
              value={policyData.time_off_accrual}
              onChange={handlePolicyChange}
              required
            >
               <option value="contract_hours">{t('Contract Hours')}</option>
          <option value="worked_hours">{t('Worked Hours')}</option>
            </select>
            <span className="css-1baizyq">
                <span className="css-lzaifp">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" focusable="false" aria-hidden="true" fill="currentColor">
                    <path d="M4 5h8l-4 6z"></path>
                  </svg>
                </span>
              </span>
          </div>
        </div>

        <div>
        <Row>
          <Col lg='4'>
          <label htmlFor="waiting_period" className="css-iekl2y">{t('Waiting Period (in days)')}</label>
          </Col>
          <Col lg='3'>
          {hoveredSection === 'waiting_period' && (
        <div
        onMouseEnter={() => handleMouseEnter('waiting_period')}
        onMouseLeave={handleMouseLeave}
        className="css-3nkkse scrollable-tooltip" id="css-3nkkse" style={{left:'36px'}}>
          <p className="css-1sad4s" style={{overflow:'auto'}}>
          {t('The waiting period refers to the time an employee must wait before they can start accruing or using their time off. This period is usually defined in the employment contract or absence policy. During this time, employees are not eligible to earn or use paid time off.')} 
          </p>
        </div>
      )}
          <div 
           className={`qouteStyle ${hoveredSection === 'waiting_period' ? "active" : ""}`}
           onMouseEnter={() => handleMouseEnter('waiting_period')}
           onMouseLeave={handleMouseLeave}
           >
            <button className="olv-button olv-ignore-transform css-1rp7g54" type="button">
              <span className="css-1rpan28">
                <span className="css-zf56fy">
                  <span aria-hidden="true" className="SVGInline">
                    <svg className="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                    <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
                    <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
                    </svg>
                    </span>
                    </span>
                    </span>
                    <span className="css-1pdgtej">{t('Meer leren')}</span>
                    </button>
                    </div>
          </Col>
        </Row>
         
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="text"
              id="waiting_period"
              name="waiting_period"
              value={policyData.waiting_period}
              onChange={handlePolicyChange}
              required
            />
          </div>
        </div>


        </div>
      {/* Submit Button */}
      <button type="submit" className="btn css-1bl3roz startbtn btn btn-Primira">
      {t('Create Policy')}
        </button>
    </form>
      </CardBody>
    </Card>
                  </div>
                </div>
                      </div>
                    </Modal>
    <div style={{padding:'20px'}}>
    <div className="table-responsive" style={{marginTop:'20px'}}>
        <Button
        id="pdfButton1"
        type="button"
        className="btn  css-1bl3roz"
        style={{ background: '#d6d8da',color:'#000000', border: 'none',height:'40px',justifyContent:'space-between' }}
        >
         {t('Absence Policy')}  
           <div className="mb-3 row" style={{position:'relative',top:'7px'}}>
    
           <div className="btn-group sub-header__buttons">
            <button className="css-1m5o92us" type="button" onClick={tog_center} >
              <span role="img" className="css-23dsfxs" >
                <svg viewBox="0 0 16 16" height="100%" width="100%" >
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span> 
              <span  className="css-1ixbps20l d-none d-md-block">{t('Absence Policy')}</span>
            </button>
          </div>
      </div>
      
        </Button>  
                      <Table className="align-middle mb-0">

                        <thead>
                          <tr>
                            <th> {t('Policy name')}</th>
                            <th>{t('Time Off Accrual')}</th>
                            <th>{t('Waiting Period')}</th>
                            <th>{t('Country')}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {getabsencepolicy.length > 0 ? (
  getabsencepolicy.map(item => (
    <tr key={item.policy_id}>
    <td>{item.policy_name}</td>
    <td>
  {item.time_off_accrual === 'contract_hours' ? t('Contract Hours') : 
   item.time_off_accrual === 'worked_hours' ? t('Worked Hours') : 'N/A'}
</td>
    <td>{item.waiting_period}</td>
    <td>{item.country}</td>
    <td>
  <button
   type="button" 
   className="btn btn-light btn-sm" 
   style={{ marginRight: '10px' }}
   onClick={() => openEditModal(item)}
   >{t('Edit')}</button>
  <button type="button"
   className="btn btn-light btn-sm"
   onClick={() => removeModal(item.policy_id)}
   >{t('Remove')}</button>
  </td>
  </tr>
  ))
) : (
  <tr>
  <td colSpan="7" style={{ textAlign: 'center' }}>
    <h6>{t('No Record Found')}</h6>
  </td>
</tr>
)}
                     
                         
                        </tbody>
                      </Table>
                    </div>
    
  
       
     
        
         
      
    </div>
  </React.Fragment>
  )
}


export default withTranslation()(withRouter(Absencepolicy));