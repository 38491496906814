import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,

} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";
import ShiftRaport from "./ShiftRaport"
import WorkerRaport from "./employers"
import AbsenceRaport from "./absence"
//redux
import { useSelector, useDispatch } from "react-redux"
import { withTranslation } from "react-i18next";
const Raport = (props) => {
  const { t, permissions } = props;
  const navigate = useNavigate();

  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
 

  useEffect(() => {
    document.title = t("Rapport Dashboard | Comprehensive Reporting & Analytics for Workforce Management - ShiftSpec");
  }, [t]);
 
  const [activeTab, setactiveTab] = useState(0);

  const renderContent = () => {
    switch (activeTab) {
      case 0: 
        return (
        <ShiftRaport permissions={permissions} />
        );
    
      case 2:
        return (
            <AbsenceRaport permissions={permissions} />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0,
                        })}
                        onClick={() => {
                            setactiveTab(0);
                          
                          }}
                      >
                     {t("Shift")}    

                        <span className="ml-1 float-end"></span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        onClick={() => {
                            setactiveTab(2);
                          
                          }}
                      >
                        {t("Absence")}
                        <span className="ml-1 float-end"></span>
                      </NavLink>
                    </NavItem>
                
                  </Nav>
                </div>

               
         
              </Card>

             
              <div className="email-rightbar mb-3">
              <Card>
                  {renderContent()}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Raport));
