import React from "react"
import { Container, Row, Col } from "reactstrap";

import CustomNavbar from "components/HeaderUnUser/Header";
//Import Breadcrumb
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
//Import Pricing Cards
import CardPricing from "./card-pricing"
import './css.css';
const PagesPricing = (props) => {
  const { t } = props;
  const { userType } = useParams();
  //meta title


  const pricings = [
    {
      id: 1,
      title: t('Starter'),
      description: t('Perfect for a first introduction to digital scheduling and timekeeping e-signature'),
      icon: "bx-walk",
      price: "3.8",
      duration: t('Per month'),
      link: "/register/starter",
      uplink: "/upgrade/starter",
      contact: "/contact-us",
      custom:true,
      features: [
        { title:  t('Max. 15 employees') },
        { title: t('Max. 1 department') },
        { title: t('Max. 5 teams') },
        { title: t('iPhone & Android App') },
        { title: t('Multilingual') },
        { title: t('Time-tracking') },
        { title: t('Employee schedule') },
        { title: t('Reusable templates') },
        { title: t('Customer support')},
      ],
    },
    {
      id: 2,
      title: t('Professional'),
      description: t('Everything you need for perfect employee scheduling and time registration'),
      icon: "bx-run",
      price: "4.7",
      duration: t('Per month'),
      link: "/register/professional",
      uplink: "/upgrade/professional",
      contact: "/contact-us",
      custom:true,
      features: [
        { title:  t('Unlimited employees') },
        { title: t('Max. 6 department') },
        { title: t('Unlimited teams') },
        { title: t('iPhone & Android App') },
        { title: t('Multilingual') },
        { title: t('Time-tracking') },
        { title: t('Employee schedule') },
        { title: t('Reusable templates') },
        { title: t('Customer support')},
        { title:  t('Shared templates') },
        { title: t('Collaborative commenting') },
        { title: t('Customised branding')},
        { title: t('Availability') },
        { title: t('Open shifts') },
        { title: t('Employee management') },
        { title: t('Absence requests') },
        { title: t('e-Signature') },
        { title: t('Vehicles management') },
        { title: t('Revenue') },
        { title: t('Weather forecast') },
        { title: t('Integrations') },
   
      ],
    },
    {
      id: 3,
      title: t('Enterprise'),
      description: t('Reduce your personnel costs by controlling forecasts and budgets'),
      icon: "bx-cycling",
      price: "7.9",
      duration: t('Per month'),
      link: "/register/enterprise",
      uplink: "/upgrade/enterprise",
      contact: "/contact-us",
      custom:true,
      features: [
        { title:  t('Unlimited employees') },
        { title: t('Unlimited department') },
        { title: t('Unlimited teams') },
        { title: t('iPhone & Android App') },
        { title: t('Multilingual') },
        { title: t('Time-tracking') },
        { title: t('Employee schedule') },
        { title: t('Reusable templates') },
        { title: t('Customer support')},
        { title:  t('Shared templates') },
        { title: t('Collaborative commenting') },
        { title: t('Customised branding')},
        { title: t('Availability') },
        { title: t('Open shifts') },
        { title: t('Employee management') },
        { title: t('Absence requests') },
        { title: t('e-Signature') },
        { title: t('Vehicles management') },
        { title: t('Revenue') },
        { title: t('Weather forecast') },
        { title: t('Integrations') },
        { title:  t('Signer attachments') },
        { title:  t('Payment') },
        { title: t('QR-Codes') },
        { title: t('Customised branding')},
        { title: t('Surcharge management') },
        { title: t('Open shifts') },
        { title: t('Required shifts') },
        { title: t('Skill management') },
        { title: t('Flexpool') },
        { title: t('Advanced surcharges') },
        { title: t('Custom absence types') },
        { title: t('Premium integrations') },
        { title: t('Custom fields') },
        { title: t('Publish schedules') },
        { title: t('Absence restrictions') },
      ],
    }
  ]
  return (
    <React.Fragment>
      <div>
      <CustomNavbar />
        <Container fluid>
         
          

          <Row className="justify-content-center">
            <Col lg={6}>
            <section className="pt-20">
      <div className="container-fluid max-w-6xl mx-auto px-6 py-12 sm:py-16 md:py-[70px]">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-[42px] lg:leading-[56px] font-extrabold text-dark-30 text-center">
        {t('Choose your Pricing plan')}
        </h1>
        <div className="text-sm sm:text-base md:text-lg text-dark-30 font-semibold text-center">
          {t('Easily create online work schedules and register hours')}
        </div>
      </div>
    </section>
              
            </Col>
          </Row>

          <Row>
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} userType={userType} />
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(withRouter(PagesPricing));