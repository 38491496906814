import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import FileViewer from "react-file-viewer";

import {
  postDocument,
  getMailsLists as onGetMailsLists,
  
} from "store/actions";
import { v4 as uuidv4 } from 'uuid';

const S3_BUCKET = 'shiftspec';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5CBDQX2KENTO7LJY';
const SECRET_ACCESS_KEY = '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';
AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION
});
const s3 = new AWS.S3();


const Docu = (props) => {
    const { t, clientData, mailslists ,folders,isEditorAllowed} = props;
    const {
      doc,
      error
    } = useSelector(state => ({
      doc: state.getClientData.doc,
      error: state.getClientData.error,
    }));
    const dispatch = useDispatch();
    const { user_id } = useParams();
    const formRef = useRef(null);
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [token, setToken] = useState(user.token);
    const [account_id , setAccountid ] = useState(user.account_id );
    const [company_name , setCompany_name ] = useState(user.company_name );

    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    const [docuData, setDocuData] = useState([]);
    const [paginationStates, setPaginationStates] = useState({}); 
    const [selectedFolder, setSelectedFolder] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [viewerKeys, setViewerKeys] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const generateUniqueId = () => {
        return uuidv4();
      };
    const itemsPerPage = 2;
  
    useEffect(() => {
      if (clientData) {
        // Filter mailslists by email and group by unique folder names
        const filteredDocu = mailslists.filter(item => item.email === clientData.email);
        const uniqueFolders = filteredDocu.reduce((acc, item) => {
          if (!acc[item.folder]) {
            acc[item.folder] = [];
          }
          acc[item.folder].push(item);
          return acc;
        }, {});
  
        setDocuData(Object.values(uniqueFolders));
  
        // Initialize pagination states for each folder
        const initialPagination = {};
        Object.keys(uniqueFolders).forEach(folder => {
          initialPagination[folder] = 1;
        });
        setPaginationStates(initialPagination);
      }
    }, [mailslists, clientData]);
  
    const paginate = (folder, pageNumber) => {
      setPaginationStates(prevState => ({
        ...prevState,
        [folder]: pageNumber
      }));
    };

   

    const handleFolderChange = (e) => {
      setSelectedFolder(e.target.value);
    };
  
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setUploadedFile(selectedFile);
      }
    };
      const fullname = `${clientData.first_name || ''} ${clientData.last_name || ''}`.trim();
      const [recipients, setRecipients] = useState([
        {
          recipientName: fullname,
          recipientEmail: clientData.email,
          recipientMsg: "",
          recipientroll: "true",
          recipientExpire: "",
          color: randomColor,
          recipienid: generateUniqueId(),
        }
      ]);
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (selectedFolder && uploadedFile) {
          setIsLoading(true);
    
          const params = {
            Bucket: S3_BUCKET,
            Key: `${company_name}/${fullname}/${uploadedFile.name}`,
            Body: uploadedFile,
            ACL: 'public-read', 
          };
    
          try {
            const data = await s3.upload(params).promise();
            const fileUrl = data.Location;
    
            const formData = {
              userid:user_id,
              recipients:recipients,
              doc_name:fileUrl,
              token:token,
              uuid:uuidv4(),
              orginal_name:uploadedFile.name,
              account_id:account_id,
              fullname:fullname,
              email: clientData.email, 
              folder:selectedFolder,
            }
            
            // Uncomment the line below to dispatch the form data
             dispatch(postDocument(formData));
    
            // Reset form
            setSelectedFolder("");
            setUploadedFile(null);
            if (formRef.current) {
              formRef.current.reset();
            }

           
          } catch (error) {
            console.error("Error uploading file:", error);
          } finally {
            setIsLoading(false);
          }
        } else {
          alert(t('Please select a folder and upload a file.'));
        }
      };
    
      useEffect(() => {
       
        if (doc && doc.success) {
          toastr.success(t('The file has been updated successfully.'));
          dispatch(onGetMailsLists(account_id));
          if (formRef.current && formRef.current.reset) {
            formRef.current.reset();
          } else {
            console.error('formRef.current is not a form element or does not have a reset method');
          }
        }else if (doc && doc.error) {
          toastr.error(t('The file has been updated unsuccessfully.'));
        }
      }, [doc, dispatch,]);
      const inputRefs = {}
      const handleUpdate = (documentId) => {
        
        const inputRef = inputRefs[documentId];
        if (inputRef && inputRef.current) {
          inputRef.current.click(); // Trigger the file input click
        }
      };
      
      
      const handleEditFileChange = async (event, documentId) => {
        const file = event.target.files[0];
        if (file) {

      
          const params = {
            Bucket: S3_BUCKET,
            Key: `${fullname}/${file.name}`,
            Body: file,
            ACL: 'public-read', 
          };
          try {
            const uploadResult = await s3.upload(params).promise();
      
            // Prepare submission data
            const submissionData = {
              id: documentId,
              orginal_name: file.name,
              docurl: uploadResult.Location,
            };
            console.log(submissionData,'submissionData')
            // Dispatch update action
            await dispatch(onPutVhiclesDoc(submissionData));
        
            // Update local state with the new document 
            setViewerKeys((prevKeys) => ({
              ...prevKeys,
              [documentId]: Date.now(), // Use timestamp as a unique key
            }));
         
          } catch (error) {
            console.error("Error uploading file:", error);
            toastr.error(t("Failed to update file."));
          } finally {
          }
        }
      };
      
      
      const handleRemove = async (documentId) => {
        // Prepare data for deletion API
        const document_id = {
          document_id: [documentId] // Wrap single ID in an array
        };
      
        // try {
        //   // Call API to delete document
        //   await dispatch(onDelVhiclesDoc(document_id));
      
        //   // Update grouped documents state after successful deletion
        //   setGroupedDocData((prevGroupedDocData) => {
        //     const updatedGroupedDocData = { ...prevGroupedDocData };
      
        //     // Remove the document from the appropriate doc_type group
        //     Object.keys(updatedGroupedDocData).forEach((docType) => {
        //       updatedGroupedDocData[docType] = updatedGroupedDocData[docType].filter(
        //         (doc) => doc.document_id !== documentId // Exclude the removed document
        //       );
      
        //       // If the group is empty after removal, delete the key
        //       if (updatedGroupedDocData[docType].length === 0) {
        //         delete updatedGroupedDocData[docType];
        //       }
        //     });
      
        //     return updatedGroupedDocData;
        //   });
      
        //   // Update FileViewer keys for real-time updates
        //   setViewerKeys((prevKeys) => {
        //     const updatedKeys = { ...prevKeys };
        //     delete updatedKeys[documentId]; // Remove the key for the deleted document
        //     return updatedKeys;
        //   });
      
        // } catch (error) {
        //   console.error("Error removing document:", error);
        //   toastr.error("Failed to remove document.");
        // }
      };
  return (
    <>
       <Col sm="6">
                        <ul className="list-group list-group-flush">
                                       
                          <Button
                          id="pdfButton1"
                          type="button"
                          className="btn  css-1bl3roz"
                          style={{ background: '#0463b9', border: 'none' }}
                          >
                         {t('Track employee tasks')}  
                          </Button>    
                  <li className="list-group-item">
                  <h5><i className="bx bx-check-circle bx-md check_Driver_deatails"style={{verticalAlign:'middle'}}></i>{t('Privacy Statement')}</h5>
                  <p>{t('Acknowledge Privacy Statement')}</p>
                  </li>
                
                  <li className="list-group-item">  
                  <h5><i className="bx bx-check-circle bx-md check_Driver_deatails" style={{verticalAlign:'middle'}}></i>{t('Onboarding')}</h5>
                  <p> {t('Onboarding Training')}</p>
                  </li>
                  <li className="list-group-item">  
                  <h5><i className="bx bx-check-circle bx-md check_Driver_deatails" style={{verticalAlign:'middle'}}></i>{t('invitation')}</h5>
                  <p>{t('Accept invitation')}</p>
                  </li>
                  </ul>
                        </Col>
                        <Col sm="6">
                        <ul className="list-group list-group-flush">
      <Button
        id="pdfButton1"
        type="button"
        className="btn css-1bl3roz"
        style={{ background: '#0463b9', border: 'none' }}
      >
       {t('Track employee protocol')}
      </Button> 
 

    { isEditorAllowed &&(
     <form ref={formRef} onSubmit={handleSubmit}>
     <div className="doc-upload-container">
       <select
         type="select"
         name="folder"
         id="folderSelect"
         value={selectedFolder}
         onChange={handleFolderChange}
         className="doc-type-select"
       >
         <option value="">{t("-- Select a Folder --")}</option>
         {folders && folders.length > 0 ? (
           folders.map((folder, index) => (
             <option key={index} value={folder.folder_name}>
               {folder.folder_name}
             </option>
           ))
         ) : (
           <option value="" disabled>
             {t("No folders available")}
           </option>
         )}
       </select>
       <input
         type="file"
         name="file"
         id="fileUpload"
         onChange={handleFileChange}
         accept="application/pdf,image/*"
         className="doc-file-input"
       />


       <button type="submit" className="upload-btn" disabled={isLoading}>
         {isLoading ? t('Uploading...') : t('Upload')}
       </button>
     </div>
   </form>
)}
      {docuData.map((folderItems, index) => {
       const folderName = folderItems[0]?.folder;
       if (folderName === 'trash') {
        return null;
      }
        const currentPage = paginationStates[folderName] || 1;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = folderItems.slice(indexOfFirstItem, indexOfLastItem);

        return (
          <div key={index} className="mb-4">
       
            <h5><i className="bx bx-check-circle bx-md check_Driver_deatails" style={{verticalAlign:'middle'}}></i> {folderName}</h5>
            <Row>
            {currentItems
  .filter(item => item.folder !== 'trash') // Filter out items with folder_name 'trash'
  .map((item, idx) => {
    const inputRef = React.createRef();
    inputRefs[item.doc_id] = inputRef
    return(
      <Col key={idx} sm="6">

      <div className="ms-3 d-flex align-items-center edit-group">
          <p>
            <a
              href={item.doc_name}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Download Document")}
            </a>
          </p>
          {/* Update Button/Icon */}
          {/* <button
            className="btn btn-sm btn-warning me-2"
            onClick={() => handleUpdate(item.doc_id)}
            title={t("Update Document")}
          >
            <i className="mdi mdi-pencil"></i>
          </button>
          <input
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            accept="application/pdf,image/*"
            onChange={(event) => handleEditFileChange(event, item.doc_id)}
          />
          
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleRemove(item.document_id)}
            title={t("Remove Document")}
          >
            <i className="mdi mdi-delete"></i>
          </button> */}
        </div>
      <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
      <FileViewer
       key={viewerKeys[item.doc_id] || item.doc_id}
       fileType={item.doc_name.split(".").pop()}
        filePath={item.doc_name}
        onError={(error) => console.error(error)}
        onLoad={() => console.log('File loaded')}
      />
    </div>
      
    </Col>
    )
   
  })
}
            </Row>

            {/* Pagination Buttons for each folder */}
            {folderItems.length > itemsPerPage && (
            <div className="btn-group float-end mt-3">
            <button 
              type="button" 
              className={`btn btn-primary btn-sm ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={() => paginate(folderName, currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="fa fa-chevron-left"></i>
            </button>
            <button 
              type="button" 
              className={`btn btn-primary btn-sm ${indexOfLastItem >= folderItems.length ? 'disabled' : ''}`}
              onClick={() => paginate(folderName, currentPage + 1)}
              disabled={indexOfLastItem >= folderItems.length}
            >
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
            )}
          </div>
        );
      })}
    </ul>
                        </Col>
    </>
  );
};

export default Docu;
