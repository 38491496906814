import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter ,Progress,Spinner} from 'reactstrap';

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
import {
  getSignature,
  getcollaborator as onGetcollaborator,
  getMailsLists as onGetMailsLists,
  getReportData,
  getContractEmployer,
} from "store/actions";
import SignatureModal from "./SignatureModal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";
import withRouter from "components/Common/withRouter";
toastr.options = {
  positionClass: 'toast-top-right', // This positions the toast in the center
  preventDuplicates: true,
  timeOut: 3000,
  closeButton: true,
  progressBar: true,
};
const WelcomeComp = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const { permissions,loading } = useUserPermissions();

  const {  template_content = [], editor, plan_name } = permissions || {};
 
  const isSignatureAllowed = template_content.includes('signature');
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  if (authUser) {
    // User is authenticated, you can use this data
    const user = JSON.parse(authUser);
    const [template_content , setTemplate_content ] = useState(user.template_content );
    const [plan_name , setPlan_name ] = useState(user.plan_name );
    const [editor , setEditor ] = useState(user.editor );
    const [company_name , setCompany_name ] = useState(user.company_name );
   const [fristname, setFristname] = useState(user.firstName);
   const [lastname, setLastname] = useState(user.lastName);
   const [token, setToken] = useState(user.token);
   const [userId, setuserId] = useState(user.clientId);
   const [account_id , setAccountid ] = useState(user.account_id );
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [get_modal_res, setget_modal_res] = useState(false);
  const fullNames = `${fristname} ${lastname}`;
  const shortName = `${fristname.charAt(0) || ''}${lastname.charAt(0) || ''}`;
  const frisletter = `${fristname.charAt(0) || ''}.${lastname}`;
  const [showSpinner, setShowSpinner] = useState(false);
  const tog_scroll = () => {
    if (isSignatureAllowed) {
      setmodal_scroll(!modal_scroll);
      removeBodyCss();
    } else {
      toastr.error(t('You do not have permission to open this modal.'));
    }
  };
 

  const {
    signatureData,
    mailslists,
    contractemployer,
    client,
    getreportdata,
  } = useSelector(state => ({
    mailslists: state.mails.mailslists,
    signatureData: state.Signature.signatureData,
    contractemployer: state.getShiftData.contractemployer,
    client: state.getClientData.client,
    getreportdata: state.getReportData.getreportdata,
  }));

  useEffect(() => {
    if (account_id) {
      dispatch(onGetcollaborator(account_id));
      dispatch(onGetMailsLists(account_id));
      dispatch(getReportData(account_id));
      dispatch(getContractEmployer(account_id));
      dispatch(getSignature(account_id));
    } else {
      // Handle the case where user.clientId is not defined
      console.error(t('User ID is not defined'));
    }
  }, [dispatch]);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const [fullName, setFullName] = useState(fullNames || '');
   const [initials, setInitials] = useState(shortName || '');

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleInitialsChange = (event) => {
    setInitials(event.target.value);
  };
  
  const handleModalData = (data) => {
    setget_modal_res(data);
    
    if (data === 'true') {
      setShowSpinner(true);
     setTimeout(() => {
      
     
        setmodal_scroll(false);
        setShowSpinner(false);
       
      }, 1000);
      
    } else {
      setTimeout(() => {
      
     
        setmodal_scroll(true);
        setShowSpinner(true);
       
      }, 1000);
     
    }
   
  };
  const filteredClients = client.filter(
    (c) => c.client_status === "active"
  );

  const filteredEnvelop = mailslists.filter(
    (m) => m.post_status === "complete"
  );
  
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-white bg-soft">
        <div className="row">
                <div className="col-xl-5">
                  <div className="text-center p-4 border-end ">
                    <div className="row">
                      <div className="col-xl-6">
                      <div className="relative" style={{top:'16px'}}>
                      <h5 className="text-truncate pb-1">{company_name || ''}</h5>
                      <h5 className="text-truncate pb-1">{frisletter}</h5>
                      </div>
                  </div>
                  <div className="col-xl-6">
                   
                    <div  className="hover-state-gray css-zm3qx2 " data-toggle="modal" data-bs-target=".bs-example-modal-lg" onClick={() => {
                        tog_scroll();
                      }}> 
                      <div className="css-cmcycf">
                        <span>{t('Ondertekend door:')}</span>
                        <div className="css-fv3lde">
                        {signatureData.map(signature => (
                    <img
                     key={signature.Signature_Id}
                     src={`${signature.signature_png}`}
                     alt=""
                      className="css-sjq1pw"
                      id="signatureImage"
                       />
                       ))}
 
                        {/* <span className=" css-lebles_id3">E.Semi</span> */} 
                    </div>
                        <span aria-hidden="true" className="css-1j983t3">{token}</span>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                </div>
                {showSpinner && (
                <div
      style={{
        position: 'fixed',
       top: 0,
       left: 0,
       right: 0,
       bottom: 0,
       backgroundColor: 'rgb(255 255 255 / 60%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
      }}
    >
       
      <div>
        <Spinner color="primary" />
      </div>
     
    </div>
    )}
                <Modal
                      isOpen={modal_scroll}
                      toggle={() => {
                        tog_scroll();
                      }}
                      scrollable={true}
                      size="lg"
                      
  
                    >
                      <div className="modal-header">
                      
                        <button
                          type="button"
                          onClick={() =>
                            setmodal_scroll(false)
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="row">
                        <div className="col-6">
                         <div className="mb-3">
                      <label className="form-label">{t('Full Name*')}</label>
                      <input
                  type="text"
                  className="form-control"
                  id="fullNameInput"
                  value={fullName}
                  onChange={handleFullNameChange}
                  disabled
                      />
                    </div>
                    </div>
                    <div className="col-6">
                    <div className="mb-3">
                  <label className="form-label">{t('Initials*')}</label>
                  <input
                   type="text"
                   className="form-control"
                   id="initialsInput"
                   value={initials}
                   onChange={handleInitialsChange}
                   disabled
                   />
                  </div>
                   </div>
                 </div>
         
                 <hr />
                 <SignatureModal  onModalData={handleModalData} />
                      
                      </div>
                    </Modal>
                <div className="col-xl-7">
                    <div className="p-4 text-center text-xl-start">
                        <div className="row">
                            <div className="col-3">
                                <div>
                                    <p className="text-muted mb-2 text-truncate">{t('Active Employees')}</p>
                                    <h5>{filteredClients.length || 0} <small>{t('Employees')}</small></h5>
                                </div>
                            </div>
                            <div className="col-3">
                              <div>
                                  <p className="text-muted mb-2 text-truncate">{t('Total Report')}</p>
                                  <h5>{getreportdata.length || 0} <small>{t('Reports')}</small></h5>
                              </div>
                          </div>
                          <div className="col-3">
                            <div>
                                <p className="text-muted mb-2 text-truncate">{t('complate envelop')}</p>
                                <h5>{filteredEnvelop.length || 0} <small>{t('envelop')}</small></h5>
                            </div>
                        </div>
                            <div className="col-3">
                                <div>
                                    <p className="text-muted mb-2 text-truncate">{t('Existing Contract')}</p>
                                    <h5>{contractemployer.length || 0} <small>{t('person')}</small></h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mt-4">
                            <a  className="text-decoration-underline text-reset">See Profile <i className="mdi mdi-arrow-right"></i></a>
                        </div> */}
                    </div>
    
                </div>
            </div>
        </div>
      
      </Card>
    </React.Fragment>
  )
}
}

export default withTranslation()(withRouter(WelcomeComp));