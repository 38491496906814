import {
  GET_COLLABORATOR_SUCCESS,
  GET_COLLABORATOR_FAIL,
  POST_COLLABORATOR_SUCCESS,
  POST_COLLABORATOR_FAIL,
  PUT_COLLABORATOR_SUCCESS,
  PUT_COLLABORATOR_FAIL,
  POST_CONTRACT,
  POST_CONTRACT_SUCCESS,
  POST_CONTRACT_FAIL,
  GET_XLSDATA_SUCCESS,
  GET_XLSDATA_FAIL,
  POST_DEP,
  POST_DEP_SUCCESS,
  POST_DEP_FAIL,
  PUT_DEP,
  PUT_DEP_SUCCESS,
  PUT_DEP_FAIL,
  DELETE_DEP,
  DELETE_DEP_SUCCESS,
  DELETE_DEP_FAIL,
  POST_TEAM,
  POST_TEAM_SUCCESS,
  POST_TEAM_FAIL,
  PUT_TEAM,
  PUT_TEAM_SUCCESS,
  PUT_TEAM_FAIL,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  POST_CONTRACT_TYPE,
  POST_CONTRACT_TYPE_SUCCESS,
  POST_CONTRACT_TYPE_FAIL,
  PUT_CONTRACT_TYPE,
  PUT_CONTRACT_TYPE_SUCCESS,
  PUT_CONTRACT_TYPE_FAIL,
  DELETE_CONTRACT_TYPE_SUCCESS,
  DELETE_CONTRACT_TYPE_FAIL,
  POST_LINK,
  POST_LINK_SUCCESS,
  POST_LINK_FAIL,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAIL,
  PUT_COLLABORATOR_DOC,
  PUT_COLLABORATOR_DOC_SUCCESS,
  PUT_COLLABORATOR_DOC_FAIL,
  POST_DOCUMENT,
  POST_DOCUMENT_SUCCESS,
  POST_DOCUMENT_FAIL,
  PUT_COLLABORATOR_PROFILE,
  PUT_COLLABORATOR_PROFILE_SUCCESS,
  PUT_COLLABORATOR_PROFILE_FAIL,
  } from "./actionTypes";
  const INIT_STATE = {
    client: [],
    userdata: {},
    putuser: {},
    contract:[],
    contractdata:null,
    xlsid: {},
    postDeps: null,
    proof: null,
    putDeps: null,
    deleteDeps: null,
    postTeams: null,
    putTeams: null,
    deleteTeams: null,
    postContractTypes: null,
    putContractTypes: null,
    deleteContactTypes: null,
    link: null,
    doc: null,
    profile: null,
    loading: false,
    error: {},
  };
  const getClientData = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_COLLABORATOR_SUCCESS:
       
        return {
          ...state,
          client: action.payload,
        };
  
      case GET_COLLABORATOR_FAIL:
        return {
          ...state,
          error: action.payload,
        };
     case POST_COLLABORATOR_SUCCESS:
      return {
        ...state,
        userdata: action.payload,
      };

    case POST_COLLABORATOR_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      case PUT_COLLABORATOR_SUCCESS:
        return {
          ...state,
          putuser: action.payload,
        };
      case PUT_COLLABORATOR_FAIL:
        return {
          ...state,
          error: action.payload,
        };


        case GET_CONTRACT:
          return {
            ...state,
            loading: true,
            error: null, // Reset the error when a new request is made
          };
        case GET_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contract: action.payload,
        error: null,
       
      };

      case GET_CONTRACT_FAIL:
        return {
          ...state,
          loading: false,
          contract: null,
          error: action.payload,
        };
    
            case POST_CONTRACT:
              return {
                ...state,
                loading: true,
                error: null, 
              };
      case POST_CONTRACT_SUCCESS:
        return {
          ...state,
          loading: false,
          contractdata: action.payload,
          error: null, 
        };
        case POST_CONTRACT_FAIL:
          return {
            ...state,
            loading: false,
            contractdata: null, 
            error: action.payload,
          };
    
        case GET_XLSDATA_SUCCESS:
          return {
            ...state,
            xlsid: action.payload,
          };

          case GET_XLSDATA_FAIL:
            return {
              ...state,
              error: action.payload,
            };
            case POST_DEP:
              return {
                ...state,
                loading: true,
                error: null, // Reset the error when a new request is made
              };
            case POST_DEP_SUCCESS:
          return {
            ...state,
            loading: false,
            postDeps: action.payload,
            error: null,
          };

          case POST_DEP_FAIL:
            return {
              ...state,
              loading: false,
              postDeps: null, // Clear any previous link data
              error: action.payload,
            };
            case PUT_DEP:
              return {
                ...state,
                loading: true,
                error: null, // Reset the error when a new request is made
              };
            case PUT_DEP_SUCCESS:
            return {
            ...state,
            loading: false,
            putDeps: action.payload,
            error: null,
            
            };
            
            case PUT_DEP_FAIL:
            return {
              ...state,
              loading: false,
              putDeps: null,
              error: action.payload,
            };
         

            case DELETE_DEP_SUCCESS:
          return {
            ...state,
            deleteDeps: action.payload,
          };

          case DELETE_DEP_FAIL:
            return {
              ...state,
              error: action.payload,
            };
            case POST_TEAM:
              return {
                ...state,
                loading: true,
                error: null, // Reset the error when a new request is made
              };
            case POST_TEAM_SUCCESS:
          return {
            ...state,
            loading: false,
            postTeams: action.payload,
            error: null,
           
          };

          case POST_TEAM_FAIL:
            return {
              ...state,
              loading: false,
              postTeams: null,
              error: action.payload,
            };
            case PUT_TEAM:
              return {
                ...state,
                loading: true,
                error: null, // Reset the error when a new request is made
              };
            case PUT_TEAM_SUCCESS:
          return {
            ...state,
            loading: false,
            putTeams: action.payload,
            error: null,
          };

          case PUT_TEAM_FAIL:
            return {
              ...state,
              loading: false,
              putTeams: null,
              error: action.payload,
            };

            case DELETE_TEAM_SUCCESS:
          return {
            ...state,
            deleteTeams: action.payload,
          };

          case DELETE_TEAM_FAIL:
            return {
              ...state,
              error: action.payload,
            };
            case POST_CONTRACT_TYPE:
              return {
                ...state,
                loading: true,
                error: null, // Reset the error when a new request is made
              };
            case POST_CONTRACT_TYPE_SUCCESS:
          return {
            ...state,
            loading: false,
            postContractTypes: action.payload,
            error: null, 
          };

          case POST_CONTRACT_TYPE_FAIL:
            return {
              ...state,
      loading: false,
      postContractTypes: null, 
      error: action.payload,
            };

            case PUT_CONTRACT_TYPE_SUCCESS:
          return {
            ...state,
            putContractTypes: action.payload,
          };

          case PUT_CONTRACT_TYPE_FAIL:
            return {
              ...state,
              error: action.payload,
            };

            case DELETE_CONTRACT_TYPE_SUCCESS:
          return {
            ...state,
            deleteContactTypes: action.payload,
          };

          case DELETE_CONTRACT_TYPE_FAIL:
            return {
              ...state,
              error: action.payload,
            };

            case POST_LINK:
              return {
                ...state,
                loading: true,
                error: null, // Reset the error when a new request is made
              };
            case POST_LINK_SUCCESS:
              return {
                ...state,
                loading: false,
                link: action.payload,
                error: null, // Clear any previous errors
              };
            case POST_LINK_FAIL:
              return {
                ...state,
                loading: false,
                link: null, // Clear any previous link data
                error: action.payload,
              };

              case PUT_COLLABORATOR_DOC:
                return {
                  ...state,
                  loading: true,
                  error: null, // Reset the error when a new request is made
                };
              case PUT_COLLABORATOR_DOC_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  proof: action.payload,
                  error: null, // Clear any previous errors
                };
              case PUT_COLLABORATOR_DOC_FAIL:
                return {
                  ...state,
                  loading: false,
                  proof: null, // Clear any previous link data
                  error: action.payload,
                };

                case POST_DOCUMENT:
                  return {
                    ...state,
                    loading: true,
                    error: null, // Reset the error when a new request is made
                  };
                case POST_DOCUMENT_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    doc: action.payload,
                    error: null, // Clear any previous errors
                  };
                case POST_DOCUMENT_FAIL:
                  return {
                    ...state,
                    loading: false,
                    doc: null, // Clear any previous link data
                    error: action.payload,
                  };


                  case PUT_COLLABORATOR_PROFILE:
                    return {
                      ...state,
                      loading: true,
                      error: null, // Reset the error when a new request is made
                    };
                  case PUT_COLLABORATOR_PROFILE_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      profile: action.payload,
                      error: null, // Clear any previous errors
                    };
                  case PUT_COLLABORATOR_PROFILE_FAIL:
                    return {
                      ...state,
                      loading: false,
                      profile: null, // Clear any previous link data
                      error: action.payload,
                    };
      default:
        return state;
    }
  };
  
  export default getClientData;