import React, { useRef, useState, useEffect,useMemo  } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Modal,
  Input,
  Col,
  Row,
} from "reactstrap";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { format, isValid, parseISO,differenceInMinutes  } from 'date-fns';
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  putUren,
  getUren,
  putAbsence,
  deleteAbsence,
} from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};

function formatDateuren_up(dateString) {
  const date = new Date(dateString); // Convert string to Date object
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
const AbsenceModal = ({t, show, handleClose,selectedAbsenceItem,getalltabsence,setIsReloadData}) => {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [language, setLanguage] = useState(user.language);
  const [timezone, setTimezone] = useState(user.timezone);
  const [valuta, setValuta] = useState(user.valuta);
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const [sendNotification, setSendNotification] = useState(false);
  const {
    puturen,
    putabsence,
    deleteabsence,
    error
  } = useSelector(state => ({
    puturen: state.getShiftData.puturen,
    urendata: state.getShiftData.urendata,
    putabsence: state.getAbsenceData.putabsence,
    deleteabsence: state.getAbsenceData.deleteabsence,
    error: state.getShiftData.error,
  }));
  const [absenceUpData, setAbsenceUpData] = useState({
    abseceid: selectedAbsenceItem?.absence?.abseceid,
    typeid: selectedAbsenceItem?.absence?.absence_type_id,
    worker_id: [selectedAbsenceItem?.absence?.worker_id],// ['10' ,'20']
    startdate: formatDateuren_up(selectedAbsenceItem?.absence?.startdate_absence),//2024-05-31
    enddate: formatDateuren_up(selectedAbsenceItem?.absence?.enddate_absence), // 2025-05-31
    description: selectedAbsenceItem?.absence?.description_absence,
    absencestatus: selectedAbsenceItem?.absence?.absencestatus,
    substatus: selectedAbsenceItem?.absence?.substatus,
    status:'vk',
    contractId:[selectedAbsenceItem?.absence?.contrct_id],
    perday:[selectedAbsenceItem?.absence?.perday],
    days:[selectedAbsenceItem?.absence?.days],
    send_mail_check: false,
    totalHours:selectedAbsenceItem?.absence?.totalhours,
  });

 
  const formatDate = (date, formatString) => {
    // Ensure the date is parsed to a valid Date object
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
  
    // Verify the date is valid
    if (!isValid(parsedDate)) {
      console.error("Invalid date provided:", date);
      return "Invalid date";
    }
  
    // Format the date
    return format(parsedDate, formatString);
  };
  const startDate = selectedAbsenceItem?.absence?.startdate_absence;
  const endDate = selectedAbsenceItem?.absence?.enddate_absence;
  const formattedStart = formatDate(new Date(startDate), DateTimeFormat);
  const formattedEnd = formatDate(new Date(endDate), DateTimeFormat);
  const StatusItem = ({ iconType, iconColor, message }) => {
    return (
      <div className="status-item flex gap-3">
        <div className={`icon ${iconColor}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={iconType === 'check' ? "0 0 14 14" : "0 0 20 20"}
            height="100%"
            width="100%"
            aria-hidden="true"
          >
            <path
              fill="currentColor"
              d={
                iconType === 'check'
                  ? "M12.702 2.273a.925.925 0 0 0-1.313 0L4.497 9.175 1.602 6.27A.945.945 0 1 0 .29 7.63L3.84 11.18a.924.924 0 0 0 1.313 0l7.548-7.548a.926.926 0 0 0 0-1.36Z"
                  : "M9.977 13.646a.904.904 0 1 0 0 1.808.904.904 0 0 0 0-1.808Zm9.644 1.329L12.345 2.322a2.71 2.71 0 0 0-4.736 0L.38 14.975a2.712 2.712 0 0 0 2.314 4.094h14.569a2.711 2.711 0 0 0 2.359-4.094Zm-1.564 1.807a.903.903 0 0 1-.795.461H2.692a.903.903 0 0 1-.794-.46.904.904 0 0 1 0-.905l7.23-12.653a.904.904 0 0 1 1.609 0l7.275 12.653a.904.904 0 0 1 .045.922v-.018ZM9.977 6.416a.904.904 0 0 0-.903.904v3.615a.904.904 0 0 0 1.807 0V7.32a.904.904 0 0 0-.904-.904Z"
              }
            />
          </svg>
        </div>
        <p className="status-message">{message}</p>
      </div>
    );
  };

  const checkForOverlap = (selectedAbsenceItem, getAllTabAbsence) => {
    const selectedStart = new Date(selectedAbsenceItem.absence.startdate_absence);
    const selectedEnd = new Date(selectedAbsenceItem.absence.enddate_absence);
  
    // Check each absence in getAllTabAbsence for overlap or adjacency
    return getAllTabAbsence.every((absence) => {
      const absenceStart = new Date(absence.startdate_absence);
      const absenceEnd = new Date(absence.enddate_absence);
  
      // Check for overlapping or adjacent dates
      const noOverlap = selectedEnd < absenceStart || selectedStart > absenceEnd;
      const noAdjacency = selectedEnd < new Date(absenceStart.setDate(absenceStart.getDate() - 1)) ||
                          selectedStart > new Date(absenceEnd.setDate(absenceEnd.getDate() + 1));
  
      return noOverlap && noAdjacency;
    });
  };

  const checkHoursAlignment = (selectedAbsenceItem) => {
    // Parse the daily hours from the schedule string
    const dailyHours = selectedAbsenceItem.absence.perday.split(',').map(Number); // E.g., ["8","8","8","8","8"] -> [8,8,8,8,8]
  
    // Calculate total scheduled hours per week
    const weeklyScheduledHours = dailyHours.reduce((sum, hours) => sum + hours, 0);
  
    // Calculate absence duration in days
    const startDate = new Date(selectedAbsenceItem.absence.startdate_absence);
    const endDate = new Date(selectedAbsenceItem.absence.enddate_absence);
    const absenceDurationDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1; // Include the start date
  
    // Calculate the total scheduled hours for the entire absence period
    const weeksInAbsence = Math.floor(absenceDurationDays / 5); // Full weeks
    const extraDays = absenceDurationDays % 5; // Remaining days
  
    // Total scheduled hours for the absence period
    const totalScheduledHours = (weeksInAbsence * weeklyScheduledHours) +
      dailyHours.slice(0, extraDays).reduce((sum, hours) => sum + hours, 0); // Add hours for any remaining days
  
    // Requested hours for the absence period
    const requestedHours = parseFloat(selectedAbsenceItem.absence.totalhours);
    // Check if requested absence hours align with the scheduled hours for the absence period
    return requestedHours <= totalScheduledHours;
  };
  
  const StatusList = ({ selectedAbsenceItem, getAllTabAbsence, balance }) => {
    const { hasSufficientBalance, isContractValid } = checkAbsenceEligibility(selectedAbsenceItem, getAllTabAbsence, balance);
    // absencestatus  Example additional checks (you'll need to define these functions based on your actual requirements)
    const noAbsenceRestrictions = !selectedAbsenceItem.absence.requires_approval; // Assume requires_approval indicates restrictions
    const hoursInLineWithSchedule = checkHoursAlignment(selectedAbsenceItem); // Define function based on schedule rules
    const noOverlappingAbsences = checkForOverlap(selectedAbsenceItem, getAllTabAbsence); // Define overlap checking logic
    const getStatusColor = (status) => {
      return status === "approved" ? "text-word-mark-800" : "text-warning-500";
    };
    const statusItems = [
      {
        iconType: absenceUpData.absencestatus === "approved" ? "check" : "warning",
        iconColor: getStatusColor(absenceUpData.absencestatus),
        message: `${('Status')}: ${absenceUpData.absencestatus.charAt(0).toUpperCase() + absenceUpData.absencestatus.slice(1)}`,
      },
      {
        iconType: hasSufficientBalance ? "check" : "warning",
        iconColor: hasSufficientBalance ? "text-word-mark-800" : "text-warning-500",
        message: hasSufficientBalance ? t("There is enough balance") : t("Insufficient balance for this absence"),
      },
      {
        iconType: isContractValid ? "check" : "warning",
        iconColor: isContractValid ? "text-word-mark-800" : "text-warning-500",
        message: isContractValid ? t("Contract is active" ): t("Contract is not active for the entire absence period"),
      },
      {
        iconType: noAbsenceRestrictions ? "check" : "warning",
        iconColor: noAbsenceRestrictions ? "text-word-mark-800" : "text-warning-500",
        message: noAbsenceRestrictions ? t("No absence restrictions during this period") : t("Absence restrictions apply"),
      },
      {
        iconType: hoursInLineWithSchedule ? "check" : "warning",
        iconColor: hoursInLineWithSchedule ? "text-word-mark-800" : "text-warning-500",
        message: hoursInLineWithSchedule ? t("Hours are in line with schedule") : t("Hours are not in line with schedule"),
      },
      {
        iconType: noOverlappingAbsences ? "check" : "warning",
        iconColor: noOverlappingAbsences ? "text-word-mark-800" : "text-warning-500",
        message: noOverlappingAbsences ? t("No overlapping or adjacent absences exist") : t("Overlapping or adjacent absences detected"),
      },
    ];
  
    return (
      <div>
        {statusItems.map((status, index) => (
          <StatusItem
            key={index}
            iconType={status.iconType}
            iconColor={status.iconColor}
            message={status.message}
          />
        ))}
      </div>
    );
  };
 
  const checkAbsenceEligibility = (selectedAbsenceItem, getAllTabAbsence, balance) => {
    let totalHoursUsed = 0;
    const filteredAbsences = getAllTabAbsence.filter(
      (absence) => absence.client_id === selectedAbsenceItem.absence.client_id
    );
   
    // Step 1: Calculate total hours from all absences
    filteredAbsences.forEach((absence) => {
      totalHoursUsed += parseFloat(absence.totalhours);
    }); 

    
    // Step 2: Check if balance covers the total requested hours
    const hasSufficientBalance = balance <= totalHoursUsed + parseFloat(selectedAbsenceItem.absence.totalhours);
  
    // Step 3: Validate contract based on extension_end_date
    const contractEndDate = new Date(selectedAbsenceItem.absence.extension_end_date);
    const absenceEndDate = new Date(selectedAbsenceItem.absence.enddate_absence);
    const isContractValid = absenceEndDate <= contractEndDate;
  
    return {
      hasSufficientBalance,
      isContractValid,
    };
  };
  

  
  const handleSubmit = async (updatedAbsenceData) => {
  
    dispatch(putAbsence(absenceUpData));
  };

  useEffect(() => {
    handleSubmit(absenceUpData);
  }, [absenceUpData.absencestatus]);
  const handleToggleStatus = (newStatus) => {
    
    setAbsenceUpData((prevState) => ({
      ...prevState,
      absencestatus: newStatus,
    }));
    
  };



  // Set status to "rejected"
  const handleReject = () => {
    setAbsenceUpData((prevState) => ({
      ...prevState,
      absencestatus: "rejected",
    }));
   
  };
  const handleNotificationChange = (e) => {
    const { checked } = e.target;

    setAbsenceUpData((prev) => ({
      ...prev,
      send_mail_check: checked, // Update the send_mail_check in the state
    })); 
  };

  const removeModal = (id) => {
    if (id) {
      dispatch(deleteAbsence(id));
    } else {
    }

  };


  
  useEffect(() => {
    const depSuccessHandler = (message) => {
      toastr.success(t(message));
      setIsReloadData(true)
    };
    
    const depErrorHandler = (message) => {
      toastr.error(t(message));
    };
  
    if (putabsence) {
      putabsence.success 
        ? depSuccessHandler('Absence record modified. The schedule has been updated accordingly.')
        : depErrorHandler('Failed to update the absence. Please try again later.');
    }
  
    if (deleteabsence) {
      deleteabsence.success 
        ? depSuccessHandler('Absence record removed. The schedule has been updated accordingly.') 
        : depErrorHandler('Failed to delete the absence. Please try again later.');
    }
    
  }, [deleteabsence, putabsence,account_id,setIsReloadData]);
  return (
    <React.Fragment>
      <Modal
                     
                      isOpen={show} 
                      toggle={handleClose} centered
                      style={{ bottom: '0' }}
                      scrollable={true}
                      size="md"
                    >
                      <div className="modal-header d-flex justify-content-between align-items-center">
  {/* Title on the left */}
  <h5 className="modal-title mt-0" id="mySmallModalLabel">
    {t('Absence request')}
  </h5>

  {/* Buttons on the right */}
  <div className="d-flex align-items-center">
    {/* Remove Button */}
    <button
      type="button"
      className="relative inline-flex cursor-pointer select-none items-center justify-center overflow-visible rounded-base border border-solid text-center font-semibold outline-none children-icon:flex-none focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:pointer-events-none aria-disabled:pointer-events-none aria-disabled:text-grey-400 bg-transparent border-transparent disabled:text-grey-400 focus-visible:outline-grey-300/25 hover:bg-grey-300/25 p-1.5 text-base text-grey-800 me-2"
      style={{ width: '27px', height: '27px' }}
      aria-label="Remove"
      onClick={() => removeModal(selectedAbsenceItem.absence.abseceid )}
    >
      <span className="flex items-center justify-center h-5 w-5" style={{ width: '100%', height: '100%' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 36 36"
          className="fill-current h-3.5 inline-block notranslate w-3.5"
          aria-hidden="true"
          role="img"
        >
          <path
            fill="currentColor"
            d="M14.4 28.8a1.8 1.8 0 0 0 1.8-1.8V16.2a1.8 1.8 0 1 0-3.6 0V27a1.8 1.8 0 0 0 1.8 1.8Zm18-21.6h-7.2V5.4A5.4 5.4 0 0 0 19.8 0h-3.6a5.4 5.4 0 0 0-5.4 5.4v1.8H3.6a1.8 1.8 0 1 0 0 3.6h1.8v19.8a5.4 5.4 0 0 0 5.4 5.4h14.4a5.4 5.4 0 0 0 5.4-5.4V10.8h1.8a1.8 1.8 0 1 0 0-3.6Zm-18-1.8a1.8 1.8 0 0 1 1.8-1.8h3.6a1.8 1.8 0 0 1 1.8 1.8v1.8h-7.2V5.4ZM27 30.6a1.8 1.8 0 0 1-1.8 1.8H10.8A1.8 1.8 0 0 1 9 30.6V10.8h18v19.8Zm-5.4-1.8a1.8 1.8 0 0 0 1.8-1.8V16.2a1.8 1.8 0 1 0-3.6 0V27a1.8 1.8 0 0 0 1.8 1.8Z"
          />
        </svg>
      </span>
    </button>

    {/* Close Button */}
    <button
      type="button"
      className="btn-close"
      data-dismiss="modal"
      aria-label="Close"
      onClick={handleClose}
    >
      <span aria-hidden="true"></span>
    </button>
  </div>
</div>

                      <div className="modal-body">
                      {/* absence-summary-item */}
                      <div className="absence-summary-item text-word-mark-800">
  <div className="flex items-start gap-3">
    <div className="avatar-badge-group shrink-0 flex flex-col-reverse items-center justify-center pt-1">
      <div className="badge-icon box-content border-2 border-solid -mt-1 flex h-6 items-center justify-center rounded-lg w-6">
        <div className="icon-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="100%"
            width="100%"
            fill="currentColor"
            className="absence-icon"
          >
            <path d="M21.75 12a1 1 0 0 0-.55-.89l-6.12-3.06v-4a3.08 3.08 0 1 0-6.16 0v4L2.8 11.11a1 1 0 0 0-.55.89v3.33a1 1 0 0 0 .43.83 1 1 0 0 0 .92.11l5.32-2V18l-1.82.6a1 1 0 0 0-.68.95V22a1 1 0 0 0 .3.71 1 1 0 0 0 .7.29h9.17a1 1 0 0 0 1-1v-2.5a1 1 0 0 0-.68-.95L15.08 18v-3.72l5.32 2a1 1 0 0 0 .92-.11 1 1 0 0 0 .43-.83Z" />
          </svg>
        </div>
      </div>
      <div className="avatar">
        <img
          src={selectedAbsenceItem.absence.profile_img}
          alt="avatar"
          className="avatar-img"
        />
      </div>
    </div>
    <div className="summary-item flex flex-col gap-1 overflow-hidden w-full">
      <div className="title-section mt-0.5">
        <div className="flex items-center justify-between gap-2 whitespace-nowrap leading-6">
          <div className="name font-bold text-xl leading-6 truncate">{selectedAbsenceItem.absence.frist_name} {selectedAbsenceItem.absence.last_name}</div>
        </div>
        <div className="details flex items-center justify-between gap-2 whitespace-nowrap leading-6">
          <div className="status-info truncate flex gap-2 text-sm">
            <span>{selectedAbsenceItem.absence.count_as === 'vk' ? t('Vacation') : selectedAbsenceItem.absence.count_as === 'zk' ? t('Sick') : t('Other')}</span>
            <span style={{textTransform:'capitalize'}}>{absenceUpData.absencestatus}</span>
          </div>
        </div>
      </div>
      <div className="date-info flex items-center justify-between gap-2 whitespace-nowrap leading-6">
        <div className="date-details flex gap-2 text-sm">
        <span>{formattedStart} - {formattedEnd}</span>
        <span>({selectedAbsenceItem.absence.totalhours} {t('Hours')})</span>
        </div>
      </div>
    </div>
  </div>
</div>
{/* absence-summary-list */}
<div className="status-list">
  <div className="flex flex-col gap-4">
    
    {/* Worth checking Header */}
    <div className="status-header mb-2 flex items-center gap-3">
      <div className="icon warning-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          height="100%"
          width="100%"
          aria-hidden="true"
        >
          <path
            fill="currentColor"
            d="M9.977 13.646a.904.904 0 1 0 0 1.808.904.904 0 0 0 0-1.808Zm9.644 1.329L12.345 2.322a2.71 2.71 0 0 0-4.736 0L.38 14.975a2.712 2.712 0 0 0 2.314 4.094h14.569a2.711 2.711 0 0 0 2.359-4.094Zm-1.564 1.807a.903.903 0 0 1-.795.461H2.692a.903.903 0 0 1-.794-.46.904.904 0 0 1 0-.905l7.23-12.653a.904.904 0 0 1 1.609 0l7.275 12.653a.904.904 0 0 1 .045.922v-.018ZM9.977 6.416a.904.904 0 0 0-.903.904v3.615a.904.904 0 0 0 1.807 0V7.32a.904.904 0 0 0-.904-.904Z"
          />
        </svg>
      </div>
      <h4 className="status-title">{t('Worth checking')}</h4>
    </div>

    {/* Status Items */}
    <StatusList
  selectedAbsenceItem={selectedAbsenceItem}
  getAllTabAbsence={getalltabsence}
  balance={selectedAbsenceItem.absence.balance}
/>
    
  </div>
</div>

</div>

<div className="modal-footer d-flex justify-content-between align-items-center">
  {/* Left side: Notify Employee checkbox */}
  <div 
  className="mb-3"
  style={{position:'relative',top:'12px'}}
  >
    <div className="form-check form-checkbox-outline form-check-success mb-3">
      <Input
        className="form-check-input"
        name="send_notification"
        type="checkbox"
        id="send_notification"
        defaultChecked={absenceUpData.send_mail_check}
        onChange={handleNotificationChange}
      />
      <label className="form-check-label" htmlFor="send_notification">
      {t('Notify Employee')} 
      </label>
    </div>
  </div>

  {/* Right side: Buttons */}
  <div>
  
          {absenceUpData.absencestatus === "approved" ? (
            <button
              type="button"
              className="btn btn-light me-2"
              onClick={() => handleToggleStatus("pending")}
            >
              {t('Pending')} 
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-light me-2"
              onClick={() => handleToggleStatus("approved")}
            >
              {t('Approved')} 
            </button>
          )}
 
    <button type="submit" className="btn btn-primary"  onClick={handleReject}>
    {t('Decline')} 
    </button>
  </div>
</div>

                    </Modal>

    </React.Fragment>
  );
};
export default withTranslation()(withRouter(AbsenceModal));

