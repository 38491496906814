
import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate ,useParams,useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardTitle,
  FormGroup,
} from "reactstrap";
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
import AWS from 'aws-sdk';
import * as XLSX from "xlsx";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import ShiftModal from './ShiftModal';
import TrashModal from './TrashModal';
import AbsenceModal from './AbsenceModal';
import AddSignleDateUren from './AddSignleDateUren';
import PutSignleDateUren from './PutSignleDateUren';
import DatShiftModal from './DateShift';
import TeamAndShift from './TeamAndShift';
import PrintShiftModal from './PrintShiftModal';
import PlanningModal from './Planningmodal';
import { addWeeks, format, parseISO, isValid } from 'date-fns';
import {
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  getShift,
  postUren,
  postShift,
  getAllAbsence,
  selectFolders,
  getUren,
  getShiftTeam,
  putDep,
  getContractEmployer,
} from "store/actions";
import Select from "react-select"
//redux
import { useSelector, useDispatch } from "react-redux";
import './style.css';


function getWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
}


const roundToNearestHour = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return `${String(minutes >= 30 ? hours + 1 : hours).padStart(2, '0')}:00`;
};


// Helper function to get the start of the week (Sunday)
function getStartOfWeek(date) {
  const day = date.getDay();
  const diff = date.getDate() - day; // Adjust when day is not Sunday
  return new Date(date.setDate(diff));
}

// Helper function to get the end of the week (Saturday)
function getEndOfWeek(date) {
  const day = date.getDay();
  const diff = date.getDate() + (6 - day); // Adjust when day is not Saturday
  return new Date(date.setDate(diff));
}

function getStartOfWeekFromWeekNumber(year, week) {
  const startOfYear = new Date(year, 0, 1);
  const days = (week - 1) * 7;
  const startOfWeek = new Date(startOfYear.setDate(startOfYear.getDate() + days));
  return getStartOfWeek(startOfWeek);
}

function hexToRGBA(hexColor) {
  const hex = hexColor.slice(1); // Remove the leading #
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, 1)`;
}

function dimColor(color, opacity) {
  // Ensure the color is defined and in RGBA format
  if (!color) {
    console.warn("dimColor received an undefined color, using fallback.");
    return `rgba(0, 0, 0, ${opacity})`; // Fallback to black
  }

  // Handle hex color format
  if (color.startsWith("#")) {
    color = hexToRGBA(color);
  }

  // Parse the RGBA values
  const rgba = color.match(/[\d.]+/g);
  if (!rgba) {
    return color; // Invalid color format, return original
  }

  // Reduce the opacity
  const dimmedColor = `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${opacity})`;
  return dimmedColor;
}

function formatTime(timeString) {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}`;
}
function formatDateuren(dateString) {
  const date = new Date(dateString); // Convert string to Date object
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
function formatDate(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}
const departmentLimits = {
  starter: 1,
  professional: 6,
  enterprise: Infinity, // Assuming enterprise has no limit
};

const TeamLimits = {
  starter: 5,
  professional: Infinity,
  enterprise: Infinity, // Assuming enterprise has no limit
};
const Timesheet = (props) => {
  const { t } = props;
  const { type: paramType,date:urlDate } = useParams();
  const daysOfWeek = ["zo","ma","di","wo","do","vr","za"];
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const daysOfWeekNames = [t("Sun"), t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat")];
const dateSchedule = ["01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00"];
  const [type, setType] = useState(paramType || "month");
  const [dateurl, setDateUrl] = useState(urlDate || formatDate(new Date()));
  const { permissions } = useUserPermissions();
  const { editor, plan_name,template_content = [],teamleider } = permissions || {};
  const isEditorAllowed = editor === 1;
  const maxDepartments = departmentLimits[plan_name] || 1;
  const maxTeams = TeamLimits[plan_name] || 5;


  const {
    client,
    xlsid,
    contractdata,
    postTeams,
    postContractTypes,
    shift,
    shiftdata,
    putshift,
    removeshift,
    puturen,
    removeuren,
    contractemployer,
    getalltabsence,
    postDeps,
    urendata,
    posturen,
    teamdata,
    putDeps,
    error
  } = useSelector(state => ({
    getalltabsence: state.getAbsenceData.getalltabsence,
    urendata: state.getShiftData.urendata,
    postDeps: state.getClientData.postDeps,
    putDeps: state.getClientData.putDeps,
    teamdata: state.getShiftData.teamdata,
    posturen: state.getShiftData.posturen,
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    shiftDatabase: state.getClientData.shiftDatabase,
    postTeams: state.getClientData.postTeams,
    postContractTypes: state.getClientData.postContractTypes,
    removeuren: state.getShiftData.removeuren,
    shift: state.getShiftData.shift,
    shiftdata: state.getShiftData.shiftdata,
    putshift: state.getShiftData.putshift,
    removeshift: state.getShiftData.removeshift,
    puturen: state.getShiftData.puturen,
    contractemployer: state.getShiftData.contractemployer,
    error: state.getShiftData.error,
  }));
 
  const dispatch = useDispatch();
 
  const location = useLocation();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
 
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [language, setLanguage] = useState(user.language);
  const [timezone, setTimezone] = useState(user.timezone);
  const [valuta, setValuta] = useState(user.valuta);
  const [DateTimeFormat, setDateFormat] = useState(user.datetimeformat);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShiftItem, setSelectedDateShiftItem] = useState({teamid: null, depid: null });
  const [isShiftModalOpen, setIsShiftModalOpen] = useState(false);
  const [isTrashModalOpen, setIsTrashModalOpen] = useState(false);
  const [isAbsenceModalOpen, setIsAbsenceModalOpen] = useState(false);
  const [isDateShiftModalOpen, setIsDateShiftModalOpen] = useState(false);
  const [isPrintShiftModalopen, setIsPrintShiftModalOpen] = useState(false);
  const [selectedTrashItem, setSelectedTrashItem] = useState({employers_id: null, id: null, date: null });
  const [selectedAbsenceItem, setSelectedAbsenceItem] = useState({ absence: null });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedAddItem, setSelectedAddItem] = useState({teamid:null,depid:null, date: null,dep_name:null,type:false});
  const [isPutModalOpen, setIsPutModalOpen] = useState(false);
  const [selectedPutItem, setSelectedPutItem] = useState({start:null,eind:null,item:null,teamid:null,depid:null,employers_id: null, id: null, date: null, shiftid: null,shiftname:null});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [currentDetails, setCurrentDetails] = useState({});
  const [isDepSliderVisible, setDepSliderVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({teamid: null, depid: null });
  const [isTeamAndShiftModalOpen, setIsTeamAndShiftModalOpen] = useState(false);
  const itemsPerPage = 12;
  const [currentPageDep, setCurrentDepPage] = useState(1);
  const [searchDep, setSearchDep] = useState(""); 
  const [checkedItems, setCheckedItems] = useState([]);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [isReloadData, setIsReloadData] = useState(false);
  const [isDisableDepPut, setIsDisableDepPut] = useState(false);
  const [isDropOpen, setIsDropOpen] = useState(false)
  
  const [putDepartMend, setPutDepartment] = useState({
    id: null, 
    columnName: 'dep_name',
    value: null,
    tableName: 'departments',
    condition:'departments_id'
  });


const [filteredDataModal, setFilteredDataModal] = useState([]);
const [departmentOptions, setDepartmentOptions] = useState([]);
const [selectedDepartment, setSelectedDepartment] = useState(null);
const [teamOptions, setTeamOptions] = useState([]);
const [shiftOptions, setShiftOptions] = useState([]);
const [selectedShift, setSelectedShiftName] = useState(null);
const [newTeamName, setNewTeamName] = useState('');
const [newShiftName, setNewShiftName] = useState('');
const [isCreatingNewTeam, setIsCreatingNewTeam] = useState(false);
const [isCreatingNewShift, setIsCreatingNewShift] = useState(false);
const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
const [selectedTeamId, setSelectedTeamId] = useState(null);
const [searchQuery, setSearchQuery] = useState('');
const [selectedUsers, setSelectedUsers] = useState([]);
const [selectAll, setSelectAll] = useState(false);
const [showHerhaalPerson, setShowHerhaalPerson] = useState(false);
const [showEinddatum, setShowEinddatum] = useState(false);
const [startTime, setStartTime] = useState('');
const [endTime, setEndTime] = useState('');
const [consolidatedEmployerList, setConsolidatedEmployerList] = useState([]);
const [workingTime, setWorkingTime] = useState(null);
const [formattedTime, setFormattedTime] = useState('');
const [departmentName, setDepartmentName] = useState("");
const [departmentAddress, setDepartmentAddress] = useState("");

const weekOptions = Array.from({ length: 52 }, (v, i) => ({
  value: `${i + 1}`,
  label: `${t('Week')} ${i + 1}`,
}));
const [toast, setToast] = useState(false);
const toggleToast = () => {
  setToast(!toast);
};

const [addDepModal, setAddDepModal] = useState(false);
const toggleAddDep = () => {
  setAddDepModal(!addDepModal);
};





const handlePostSubmitDepart = () => {

  const data = {
    account_id: account_id,
    dep_name: departmentName,
    dep_adres:departmentAddress,
  };

  dispatch(postDep(data));
  // Add your submission logic here
};

  useEffect(() => {
    document.title = t("Schedule Management | Streamline Employee Shifts and Workforce Planning - ShiftSpec");
  }, [t]);
 

  useEffect(() => {
   
    if (paramType && urlDate) {
      setType(paramType); // Update the state when the URL param changes
      setDateUrl(urlDate)
    }
  }, [paramType,urlDate]);
 

  const handleOpenTeamAndShiftModal = (team) => {
    setSelectedTeam(team);
    setIsTeamAndShiftModalOpen(true);
  };
  const handleCloseTeamAndShiftModal = () => {
    setIsTeamAndShiftModalOpen(false);
    setSelectedTeam(null); // Clear selected team when closing the modal
  };
  const toggleDepSlider = () => {
    setDepSliderVisible(!isDepSliderVisible);
  };

  const handleOpenShiftModal = () => setIsShiftModalOpen(true);
  const handleCloseShiftModal = () => setIsShiftModalOpen(false);
  const handleOpenDateShiftModal = (teamid, depid ) => {
    setSelectedDateShiftItem({teamid, depid });
    setIsDateShiftModalOpen(true);
  };
  const handleCloseDateShiftModal = () => setIsDateShiftModalOpen(false);
  
  const handleOpenTrashModal = (employers_id,id, date) => {
    setSelectedTrashItem({employers_id, id, date });
    setIsTrashModalOpen(true);
    
  };
  const handleClosePrintShiftModal = () => setIsPrintShiftModalOpen(false);
  const handlePrintShiftModal = () => {
    setIsPrintShiftModalOpen(true);
  };
  const handleOpenAbsenceModal = (absence) => {
    setSelectedAbsenceItem({absence});
    setIsAbsenceModalOpen(true);
 
  };
  const handleCloseAbnseceModal = () => setIsAbsenceModalOpen(false);
  const handleCloseTrashModal = () => setIsTrashModalOpen(false);
  
  const handleOpenAddModal = (teamid,depid, date,dep_name,type) => {
    setSelectedAddItem({ teamid,depid, date,dep_name,type });
    setIsAddModalOpen(true);
    
  };
  const handleCloseAddModal = () => setIsAddModalOpen(false);
  const handleOpenPutModal = (start,eind,item,teamid,depid,employers_id, id, date,shiftid,shiftname) => {
    setSelectedPutItem({start,eind,item,teamid,depid,employers_id, id, date,shiftid,shiftname});
    setIsPutModalOpen(true);
    
  };
  const handleClosePutModal = () => setIsPutModalOpen(false);


  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTabClick = (path,type) => {

    if (location.pathname !== path) {
      setLoading(true);
      navigate(path);
      setActiveTab(path);
      setType(type);
      setDateUrl(urlDate)
      setTimeout(() => {
        setLoading(false);
        
      }, 2000);
    }
  };

  const handelNextOrPrev = (value) => {
    let newStartDate, newEndDate;
  
    // Handle week navigation
    if (type === "week") {
      const currentWeekNumber = currentDetails.weekNumber;
      const currentYear = currentDetails.year;
  
      if (value === 'prev') {
        // Going to the previous week
        newStartDate = new Date(currentDetails.startDate);
        newStartDate.setDate(newStartDate.getDate() - 7);
        newEndDate = new Date(currentDetails.endDate);
        newEndDate.setDate(newEndDate.getDate() - 7);
      } else {
        // Going to the next week
        newStartDate = new Date(currentDetails.startDate);
        newStartDate.setDate(newStartDate.getDate() + 7);
        newEndDate = new Date(currentDetails.endDate);
        newEndDate.setDate(newEndDate.getDate() + 7);
      }
      setDateUrl(formatDate(newStartDate));
    // Handle month navigation
    } else if (type === "month") {
      const currentMonth = new Date(currentDetails.startDate).getMonth();
      const currentYear = new Date(currentDetails.startDate).getFullYear();
  
      if (value === 'prev') {
        // Going to the previous month
        newStartDate = new Date(currentYear, currentMonth - 1, 1);
        newEndDate = new Date(currentYear, currentMonth, 0); // Last day of previous month
      } else {
        // Going to the next month
        newStartDate = new Date(currentYear, currentMonth + 1, 1);
        newEndDate = new Date(currentYear, currentMonth + 2, 0); // Last day of next month
      }
  
      setDateUrl(formatDate(newStartDate));
    // Handle date navigation (daily)
    } else if (type === "date") {
      newStartDate = new Date(currentDetails.startDate);
  
      if (value === 'prev') {
        newStartDate.setDate(newStartDate.getDate() - 1); // Previous day
      } else {
        newStartDate.setDate(newStartDate.getDate() + 1); // Next day
      }
      setDateUrl(formatDate(newStartDate));
    }
  };

    // Function to change the URL when date changes
    const changeUrlDate = () => {
      const newPath = `/shift/${type}/${dateurl}`;
      navigate(newPath, { replace: true });
    };
  const handleDateChange = (event) => {
    const value = event.target.value;
    let selectedDate;
    if (type === "week" && value.includes('-W')) {
      const [year, week] = value.split('-W');
      selectedDate = getStartOfWeekFromWeekNumber(parseInt(year), parseInt(week));
  } else {
    selectedDate = new Date(value);
} 

if (!isNaN(selectedDate.getTime())) {
  setDateUrl(formatDate(selectedDate));
} else {
  console.error("Invalid Date provided:", value);
}

};


function getWorkStatusDetails(work_status, dienst_start_time, dienst_eind_time, totalhour, shift_color,shift_name) {
  
  const details = {
    checkin: {
      colors: shift_color || '#239933',
      textContent: dienst_start_time.slice(0, 5),
      totalWorkHour:formatTime(totalhour),
    },
    checkout: {
      colors: shift_color || '#992323',
      textContent: `${dienst_start_time.slice(0, 5)} - ${dienst_eind_time.slice(0, 5)}`,
      totalWorkHour:formatTime(totalhour),
    },
    wait: {
      colors: shift_color,
      textContent: `${shift_name}`,
      totalWorkHour:`${dienst_start_time.slice(0, 5)} - ${dienst_eind_time.slice(0, 5)}`,
    },
    auto: {
      colors: shift_color || '#236899',
      textContent: totalhour.slice(0, 5),
      totalWorkHour:formatTime(totalhour),
    },
    vk: {
      colors: shift_color,
      textContent: `${shift_name}`,
      totalWorkHour:`${dienst_start_time.slice(0, 5)} - ${dienst_eind_time.slice(0, 5)}`,
      
    },
    zk: {
      colors: shift_color,
      textContent: `${shift_name}`,
      totalWorkHour:`${dienst_start_time.slice(0, 5)} - ${dienst_eind_time.slice(0, 5)}`,
    },
    no: {
      colors: shift_color,
      textContent: `${shift_name}`,
      totalWorkHour:`${dienst_start_time.slice(0, 5)} - ${dienst_eind_time.slice(0, 5)}`,
    },
    default: {
      colors: shift_color || '',
      textContent: '',
      totalWorkHour:formatTime(totalhour),
    },
  };

  // Return the corresponding details based on work_status, or default if not found
  return details[work_status] || details.default;
}

const formatTitle = (firstDay, lastDay, monthName, year) => {
   // title: `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`,
  if (DateTimeFormat === "MM/DD/YYYY") {
    return `${monthName} ${firstDay.getDate()} - ${lastDay.getDate()} ${year}`;
  } else if (DateTimeFormat === "DD/MM/YYYY") {
    return `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`;
  } else if (DateTimeFormat === "YYYY-MM-DD") {
    return `${year} ${monthName} ${firstDay.getDate()} - ${lastDay.getDate()}`;
  } else if (DateTimeFormat === "MM-DD-YYYY") {
    return `${monthName} ${firstDay.getDate()} - ${lastDay.getDate()} ${year}`;
  } else if (DateTimeFormat === "DD-MM-YYYY") {
    return `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`;
  } else {
    // Default format
    return `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`;
  }
};

  function displayCurrentTemp(type) {
    try {
      
      const selectedDate = new Date(dateurl)
      if (!(selectedDate instanceof Date) || isNaN(selectedDate.getTime())) {
        throw new Error("Invalid Date object provided");
      }

        if (type === "month") {
            const month = selectedDate.getMonth();
            const year = selectedDate.getFullYear();

            const firstDay = new Date(year, month, 1);
            const firstDayOfWeek = daysOfWeekNames[firstDay.getDay()];

            const lastDay = new Date(year, month + 1, 0);
            const lastDayOfWeek = daysOfWeekNames[lastDay.getDay()];

            const totalDays = lastDay.getDate();
            const monthName = monthNames[month];
            const startDate = formatDate(firstDay);
            const endDate = formatDate(lastDay);
             // Create a 'today' object
            const todayDate = new Date(); 
            const isTodayInCurrentMonth = todayDate.getMonth() === month && todayDate.getFullYear() === year;

            const today = isTodayInCurrentMonth
              ? {
                  day: todayDate.getDate(),
                  dayOfWeek: daysOfWeekNames[todayDate.getDay()],
                  date: formatDate(todayDate), // Formatted date as yyyy-mm-dd
                }
              : null;
            return {
                type: "month",
                firstDay: firstDay.getDate(),
                firstDayOfWeek: firstDayOfWeek,
                lastDay: lastDay.getDate(),
                lastDayOfWeek: lastDayOfWeek,
                monthName: monthName,
                totalDays: totalDays,
               // title: `${firstDay.getDate()} - ${lastDay.getDate()} ${monthName} ${year}`,
                title: formatTitle(firstDay, lastDay, monthName, year),
                year: year,
                startDate: startDate, // Formatted start date (yyyy-mm-dd)
                endDate: endDate, // Formatted end date (yyyy-mm-dd)
                today: today
            };
        } else if (type === "week") {

        
            const currentWeekNumber = getWeekNumber(selectedDate);
            const startOfWeek = getStartOfWeek(selectedDate);
            const endOfWeek = getEndOfWeek(selectedDate);
            
            const totalDays = (endOfWeek - startOfWeek) / (1000 * 60 * 60 * 24) + 1;
            const startDate = formatDate(startOfWeek);
            const endDate = formatDate(endOfWeek);

            const todayDate = new Date();
            const isTodayInCurrentWeek = todayDate >= startOfWeek && todayDate <= endOfWeek;
            const today = isTodayInCurrentWeek
              ? {
                  day: todayDate.getDate(),
                  dayOfWeek: daysOfWeekNames[todayDate.getDay()],
                  date: formatDate(todayDate), // Formatted date as yyyy-mm-dd
                }
              : null;
            return {
              type: "week",
                weekNumber: currentWeekNumber,
                startOfWeek: startOfWeek.getDate(),
                startOfWeekMonthName: monthNames[startOfWeek.getMonth()],
                endOfWeek: endOfWeek.getDate(),
                endOfWeekMonthName: monthNames[endOfWeek.getMonth()],
                totalDays: totalDays,
                title: `Week ${currentWeekNumber}, ${selectedDate.getFullYear()}`,
                year: selectedDate.getFullYear(),
                startDate: startDate, // Formatted start date (yyyy-mm-dd)
                endDate: endDate, // Formatted end date (yyyy-mm-dd)
                today: today, 
            };
        } else if (type === "date") {
            const dayOfWeek = daysOfWeekNames[selectedDate.getDay()];
            const date = selectedDate.getDate();
            const monthName = monthNames[selectedDate.getMonth()];
            const year = selectedDate.getFullYear();
            const formattedDate = formatDate(selectedDate);
            return {
                type: "date",
                dayOfWeek: dayOfWeek,
                date: date,
                monthName: monthName,
                title: `${dayOfWeek}, ${date} ${monthName} ${year}`, 
                year: year,
                startDate: formattedDate,
                endDate: formattedDate
            };
        } else {
            console.warn("Invalid type provided to displayCurrentTemp");
            return null;
        }
    } catch (error) {
        console.error("Error in displayCurrentTemp:", error);
        return null;
    }
}



useEffect(() => {

  const fetchData = async () => {
    dispatch(getShiftTeam(account_id));
    dispatch(onGetcollaborator(account_id));
    dispatch(getXlsdata(account_id));
    dispatch(getShift(account_id));
    dispatch(getContractEmployer(account_id));
    dispatch(getAllAbsence(account_id));
    const data = displayCurrentTemp(type);
    setCurrentDetails(data);

  };
  fetchData();
  
  if (isReloadData) {
    
    fetchData();
    setIsReloadData(false);
  }
  changeUrlDate();
}, [dispatch, type ,isReloadData,dateurl]);


//type,inputValue,currentDetails
// Example usage:


useEffect(() => { 
  if (!currentDetails) {
    console.error('currentDetails is null or undefined 1');
    return [];
  }
const obj = {
  start:currentDetails.startDate,
  eind:currentDetails.endDate,
  account_id:account_id,
}

  dispatch(getUren(obj));
}, [currentDetails,dispatch]);

const generateDates = (teamname) => {
  if (!currentDetails) {
    console.error('currentDetails is null or undefined 2');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }
  const dates = [];
 
  if (currentDetails.type === "month") {
   
   
    for (let i = 1; i <= currentDetails.totalDays; i++) {
      const date = new Date(currentDetails.year, monthNames.indexOf(currentDetails.monthName), i);
      const formattedDate = `${currentDetails.year}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
      dates.push({
        formattedDate,
        displayDate: `${daysOfWeekNames[date.getDay()]} ${i}`,
        teamnames: teamname 
      });
    }
  } else if (currentDetails.type === "week") {
    let currentDate = new Date(currentDetails.year, monthNames.indexOf(currentDetails.startOfWeekMonthName), currentDetails.startOfWeek);
    for (let i = 0; i < currentDetails.totalDays; i++) {
      const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
      dates.push({
        formattedDate,
        displayDate: `${daysOfWeekNames[currentDate.getDay()]} ${currentDate.getDate()}`,
        teamnames: teamname 
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
  } else if (currentDetails.type === "date") {

    const formattedDate = `${currentDetails.year}-${String(monthNames.indexOf(currentDetails.monthName) + 1).padStart(2, '0')}-${String(currentDetails.date).padStart(2, '0')}`;
     
    const timeSlots = dateSchedule.map(time => ({
      formattedDate,
      timeSlot: time,
      teamnames: teamname 
    }));
    dates.push(...timeSlots); // Flatten the array to include all time slots
  }

  return dates;
};
const renderDates = () => {
  if (!currentDetails) {
    console.error('currentDetails is null or undefined 3');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }
  if (currentDetails.type === "month") {
    const dates = [];
      const startDate = new Date(currentDetails.startDate); // Assuming this is in the first month already
      const yyyy = startDate.getFullYear();
      const mm = String(startDate.getMonth() + 1).padStart(2, '0'); // Zero-based index for months
      
      for (let i = 1; i <= currentDetails.totalDays; i++) {
        // Generate date string for each day
        const day = String(i).padStart(2, '0'); // Ensure day is 2 digits
        const formattedDate = `${yyyy}-${mm}-${day}`;
        dates.push({ day, formattedDate });
      }
    
      return dates.map(({ day, formattedDate }) => (
        <div key={`months-${day}`} className="css-s3o0sad3 css-s3o0fff3">
          <a
            href={formattedDate}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {`${t(currentDetails.monthName)} ${day}`}
          </a>
        </div>
      ));
    
  } else if (currentDetails.type === "week") {
    const dates = [];
  const startDate = new Date(currentDetails.year, monthNames.indexOf(currentDetails.startOfWeekMonthName), currentDetails.startOfWeek);
  
  let currentDate = new Date(startDate); // Clone the start date

  for (let i = 0; i < currentDetails.totalDays; i++) {
    // Dynamically calculate year, month, and day
    const yyyy = currentDate.getFullYear();
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adjust for zero-based months
    const dd = String(currentDate.getDate()).padStart(2, '0');

    const dayName = monthNames[currentDate.getMonth()]; // Month name
    const formattedDate = `${yyyy}-${mm}-${dd}`; // Format: YYYY-MM-DD

    // Push formatted date for rendering
    dates.push(`${dayName} ${currentDate.getDate()} ${formattedDate}`);

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Map through dates to render JSX
  return dates.map((date) => {
    const [dayName, day, formattedDate] = date.split(" "); // Split into parts

    return (
      <div key={`weeks-${formattedDate}`} className="css-s3o0sad3 css-s3o0fff3">
        <a
          href={formattedDate}
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {`${t(dayName)} ${day}`}
        </a>
      </div>
    );
  });
  } else if (currentDetails.type === "date") {

    return dateSchedule.map(time => (
      <div key={`time-${time}`} className="css-s3o0sad3 css-s3o0fff3">
          {time}
      </div>
  ));
    
  }
};


const renderWithDateName = () => {
  if (!currentDetails) {
    console.error('currentDetails is null or undefined 4');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }
  if (currentDetails.type === "month") {
      const dates = [];
      const { firstDay, totalDays, monthName, year,today } = currentDetails;
      
      for (let i = firstDay; i <= totalDays; i++) {
        const date = new Date(year, monthNames.indexOf(monthName), i);
        const dayName = daysOfWeekNames[date.getDay()];
        const dayLabel = today && today.day === i ? t("Today") : `${dayName} ${i}`;
        dates.push(dayLabel);
      }
      return dates.map(date => (
        <div
        key={`month2-${date}`}
          className="css-s3o0sad3 css-s3o0fff3"
          style={date === t("Today") ? { background: "#0096ec", padding: "10px", color: "white" } : {}}
        >
          {date}
        </div>
      ));
      
  } else if (currentDetails.type === "week") {
    const dates = [];
    const { firstDay, totalDays, monthName, year,today } = currentDetails;
    let currentDate = new Date(currentDetails.year, monthNames.indexOf(currentDetails.startOfWeekMonthName), currentDetails.startOfWeek);

    for (let i = 0; i < totalDays; i++) {
      const dayName = daysOfWeekNames[currentDate.getDay()];
      
      const dayLabel = today && today.dayOfWeek === dayName ? t("Today") : `${dayName} ${currentDate.getDate()}`;
      dates.push(dayLabel);
      // dates.push(`${dayName}  ${currentDate.getDate()}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates.map(date => (
      <div
      key={`week2-${date}`}
        className="css-s3o0sad3 css-s3o0fff3"
        style={date === t("Today") ? { background: "#0096ec", padding: "10px", color: "white" } : {}}
      >
        {date}
      </div>
    ));
  } else if (currentDetails.type === "date") {

    return dateSchedule.map(time => (
      <div key={`time2-${time}`} className="css-s3o0sad3 css-s3o0fff3">
          {time}
      </div>
  ));
    
  }
};


const ScheduleItem = ({ title, value, backgroundColor, borderColor }) => (
  <div
  className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts"
  style={{ backgroundColor, borderColor }}
>
  <div className="relative left-0 top-0">
    <div
      className="rounded-full h-[26px] top-[-13px] absolute min-w-[6px] w-[6px] ml-[2px]"
      style={{ background: borderColor }}
    ></div>
  </div>
  <div className="sh-tl__static w-full" style={{justifyContent:'center'}}>
    <div className="flex">
      <div className="flex relative left_7">
        <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
        {title}
        </span>
      </div>
    </div>
    <div className="block">
      <div className="block relative left_7">
        <span className="overflow-hidden sb-text-grey-600 width-40px relative text-ellipsis text-xs">
       {value}
        </span>
      </div>
    </div>
  </div>
</div>
);


const renderSchedule = (teamname,team) => {
  const dates = generateDates(teamname);

  if (!currentDetails) {
    console.error('currentDetails is null or undefined 5');
    return [];
  }
  if (!type) {
    console.error('Type is missing in currentDetails');
    return [];
  }

  
  if (currentDetails.type === "month") {
    // Check if there are any matching employees for the team
    const hasMatchingEmployees = Object.values(urendata).some(
      (data) => data.teamNames === teamname
    );
 
    return (
      <>
        {/* Map through `urendata` only if `hasMatchingEmployees` */}
        {hasMatchingEmployees && Object.keys(urendata).map((employerId) => {
          const { items, teamNames, employeeName, profileImage } = urendata[employerId];
          const isTeamMatch = teamname === teamNames;

          return (
            <div
              key={employerId}
              className={`css-k23serv3s`}
              style={{ display: isTeamMatch ? "inline-flex" : "none" }}
            >
              {isTeamMatch && (
                <>
               
                <div className="css-li23s54 border-style">
                <a
  href={`/details/collaborator/${employerId}`}
  target="_blank"
  rel="noopener noreferrer"
  style={{ textDecoration: 'none', color: 'inherit' }}
>
  <img
    style={{ marginRight: '5px' }}
    className="rounded-circle header-profile-user_plane"
    src={profileImage || 'default_profile_image_url.jpg'}
    alt={`${employeeName}_profile_shiftOnline`}
  />
  {employeeName}
</a>
                  </div>
                  {/* Render dates for team-matching entries */}
                  {dates.map(({ formattedDate }) => {
                    const itemsForDate = items.filter(
                      (item) => item.team_name === teamname && formatDateuren(item.uren_date) === formattedDate
                    );
                    
                    const absenceDetails = getalltabsence.find(absence => {
                      const absenceStartDate = formatDateuren(absence.startdate_absence); 
                      const absenceEndDate = formatDateuren(absence.enddate_absence);
                      const isSameWorker = String(absence.workerid) === String(employerId); // Type cast to ensure match
                      const isSameOrAfterStart = formattedDate >= absenceStartDate;
                      const isSameOrBeforeEnd = formattedDate <= absenceEndDate;
                    
                      return isSameWorker && isSameOrAfterStart && isSameOrBeforeEnd;
                    });
                    
                    const isAbsent = !!absenceDetails; // True if absenceDetails is found
                    const absenceStartDate = isAbsent ? absenceDetails.startdate_absence : null;
                    const absenceEndDate = isAbsent ? absenceDetails.enddate_absence : null;
                    const absenceStatus = isAbsent ? absenceDetails.absencestatus : null;
                    const count_as = isAbsent ? absenceDetails.count_as : null;
                    const substatus = isAbsent ? absenceDetails.substatus : null;
                    // Conditionally render `SchedulePlus` or schedule items
                    return itemsForDate.length === 0 ? (
                      <div key={`formattedDate-${formattedDate}`} style={{ display: 'block' }}>
 <div className={`css-s3o0sad3 border-style `} >
 {isAbsent && (
                                <div className="css-block css-vk-absen">
                                  <div className="schedule-template " id="schedule-template">
                                  <div className="tooltip-text-absence">
  <div className="tooltip-header">
    <span className="count-as">
    {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
    </span>
    <span className="absence-status">{absenceStatus}</span>
  </div>
  <div className="absence-dates">
  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
  </div>
</div>

                                    <div
                                      className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts"
                                    >
                                      <div className="sh-tl__static w-full" style={{ justifyContent: 'center' }}>
                                        <div className="flex">
                                          <div className="flex relative">
                                          <button
                            className="css-1m92us_absence"
                            style={{ backgroundColor: absenceStatus === 'approved' ? '#e4d03b' : '#f1f1f1' }}
                            type="button"
                            disabled={!isEditorAllowed}
                            onClick={() => handleOpenAbsenceModal(absenceDetails)}
                          >
                           <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                                              {t('Vacation')}
                                            </span>
                           </button>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                        <div className="add-40os34">
                          <button
                            className="css-1m92us add-plus schedule-button"
                            type="button"
                            style={{ width: '100%' }}
                            disabled={!isEditorAllowed}
                            onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate,team.dep_name,false)}
                          >
                            <span className="css-230ofxs" role="img" aria-hidden="true">
                              <i className="bx bx-plus"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                      </div>
                     
                    ) : (
                      <div key={`formattedDate2-${formattedDate}`}  style={{ display: 'block' }}>
                
                        {itemsForDate.map((item) => {
                          
                          const { colors, textContent,totalWorkHour } = getWorkStatusDetails(
                            item.work_status,
                            item.start_time_uren,
                            item.eind_time_uren,
                            item.total_day_hour,
                            item.shift_color,
                            item.shift_name,
                          );
                          const dimmedColor = dimColor(colors, 0.1);
                          return (
                            <div
                              key={item.uren_id}
                              className={`css-s3o0sad3 border-style   `}
                            >
                              {/* Render the absence block if isAbsent is true */}
                              {isAbsent && (
                                <div className="css-block css-vk-absen">
                                  <div className="schedule-template " id="schedule-template">
                                  <div className="tooltip-text-absence">
  <div className="tooltip-header">
    <span className="count-as">
    {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
    </span>
    <span className="absence-status">{absenceStatus}</span>
  </div>
  <div className="absence-dates">
  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
  </div>
</div>

                                    <div
                                      className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts"
                                    >
                                      <div className="sh-tl__static w-full" style={{ justifyContent: 'center' }}>
                                        <div className="flex">
                                          <div className="flex relative">
                                          <button
                            className="css-1m92us_absence"
                            type="button"
                            style={{ backgroundColor: absenceStatus === 'approved' ? '#e4d03b' : '#f1f1f1' }}
                            onClick={() => handleOpenAbsenceModal(absenceDetails)}
                            disabled={!isEditorAllowed}
                          >
                           <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                           {t('Vacation')}
                                            </span>
                           </button>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          
                              {/* Render the regular schedule block regardless of isAbsent */}
                              <div className="css-block parent-container">
                                <div className="schedule-template" id="schedule-template">
                                  <div className="new-btn_group me-2 edit-vds3g">
                                    <div className="tooltip-text">
                                    <div className="tooltip-header">
                                    <span className="count-as">
                                    {`${item.shift_name} ${item.start_time_uren} - ${item.eind_time_uren}`}
                                    </span>
                                    </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenPutModal(
                                          currentDetails.startDate,
                                          currentDetails.endDate,
                                          item,
                                          item.team_id_uren,
                                          item.departmen_id_uren,
                                          item.employers_id,
                                          item.uren_id,
                                          formatDateuren(item.uren_date),
                                          item.shift_id_uren,
                                          item.shift_name
                                        )
                                      }
                                    >
                                      <i className="bx bx-pencil"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenTrashModal(item.employers_id, item.uren_id, formatDateuren(item.uren_date))
                                      }
                                    >
                                      <i className="bx bx-trash"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenAddModal(
                                          item.team_id_uren,
                                          item.departmen_id_uren,
                                          formatDateuren(item.uren_date),
                                          item.dep_name,
                                          false
                                        )
                                      }
                                    >
                                      <i className="bx bx-plus"></i>
                                    </button>
                                  </div>
                                  <ScheduleItem
                                    title={textContent}
                                    value={totalWorkHour}
                                    backgroundColor={dimmedColor}
                                    borderColor={colors}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}

        {/* Rooster Placeholder if no matching employees */}
        {!hasMatchingEmployees && (
          <div className="css-k23serv3s">
            <div className="css-li23s54 border-style">
              <span>{t('Schedule')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
              <div className="css-s3o0sad3 border-style targethover" key={`formattedDate3-${formattedDate}`}>
                <div className="add-40os34">
                  <button
                    className="css-1m92us add-plus schedule-button"
                    type="button"
                    style={{ width: '100%' }}
                    disabled={!isEditorAllowed}
                    onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate,team.dep_name,false)}
                  >
                    <span className="css-230ofxs" role="img" aria-hidden="true">
                      <i className="bx bx-plus"></i>
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  
  }else if (currentDetails.type === "week") {
    const hasMatchingEmployees = Object.values(urendata).some(
      (data) => data.teamNames === teamname
    );
 
    return (
      <>
        {/* Map through `urendata` only if `hasMatchingEmployees` */}
        {hasMatchingEmployees && Object.keys(urendata).map((employerId) => {
          const { items, teamNames, employeeName, profileImage } = urendata[employerId];
          const isTeamMatch = teamname === teamNames;

          return (
            <div
              key={employerId}
              className="css-k23serv3s week-sv232 grid-full-width"
                style={{ display: isTeamMatch ? "inline-flex" : "none" }}
            >
              {isTeamMatch && (
                <>
                  <div className="css-li23s54 border-style" style={{ width: '28%' }}>
                  <a
  href={`/details/collaborator/${employerId}`}
  target="_blank"
  rel="noopener noreferrer"
  style={{ textDecoration: 'none', color: 'inherit' }}
>
  <img
    style={{ marginRight: '5px' }}
    className="rounded-circle header-profile-user_plane"
    src={profileImage || 'default_profile_image_url.jpg'}
    alt={`${employeeName}_profile_shiftOnline`}
  />
  {employeeName}
</a>
                  </div>

                  {/* Render dates for team-matching entries */}
                  {dates.map(({ formattedDate }) => {
                    const itemsForDate = items.filter(
                      (item) => item.team_name === teamname && formatDateuren(item.uren_date) === formattedDate
                    );
                    
                    const absenceDetails = getalltabsence.find(absence => {
                      const absenceStartDate = formatDateuren(absence.startdate_absence); 
                      const absenceEndDate = formatDateuren(absence.enddate_absence);
                      const isSameWorker = String(absence.workerid) === String(employerId); // Type cast to ensure match
                      const isSameOrAfterStart = formattedDate >= absenceStartDate;
                      const isSameOrBeforeEnd = formattedDate <= absenceEndDate;
                    
                      return isSameWorker && isSameOrAfterStart && isSameOrBeforeEnd;
                    });
                    
                    const isAbsent = !!absenceDetails; // True if absenceDetails is found
                    const absenceStartDate = isAbsent ? absenceDetails.startdate_absence : null;
                    const absenceEndDate = isAbsent ? absenceDetails.enddate_absence : null;
                    const absenceStatus = isAbsent ? absenceDetails.absencestatus : null;
                    const count_as = isAbsent ? absenceDetails.count_as : null;
                    const substatus = isAbsent ? absenceDetails.substatus : null;
                    // Conditionally render `SchedulePlus` or schedule items
                    return itemsForDate.length === 0 ? (
                      <div key={`formattedDateweek-${formattedDate}`} style={{ display: 'block' }}>
 <div className={`css-s3o0sad3 border-style `}style={{ height: '100%' }} >
 {isAbsent && (
                                <div className="css-block css-vk-absen">
                                  <div className="schedule-template " id="schedule-template">
                                  <div className="tooltip-text-absence">
  <div className="tooltip-header">
    <span className="count-as">
    {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
    </span>
    <span className="absence-status">{absenceStatus}</span>
  </div>
  <div className="absence-dates">
  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
  </div>
</div>

                                    <div
                                      className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts"
                                    >
                                      <div className="sh-tl__static w-full" style={{ justifyContent: 'center' }}>
                                        <div className="flex">
                                          <div className="flex relative">
                                          <button
                            className="css-1m92us_absence"
                             disabled={!isEditorAllowed}
                            style={{ backgroundColor: absenceStatus === 'approved' ? '#e4d03b' : '#f1f1f1' }}
                            type="button"
                            onClick={() => handleOpenAbsenceModal(absenceDetails)}
                          >
                           <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                           {t('Vacation')} 
                                            </span>
                           </button>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                        <div className="add-40os34">
                          <button
                            className="css-1m92us add-plus schedule-button"
                            type="button"
                            style={{ width: '100%' }}
                            disabled={!isEditorAllowed}
                            onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate,team.dep_name,false)}
                          >
                            <span className="css-230ofxs" role="img" aria-hidden="true">
                              <i className="bx bx-plus"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                      </div>
                     
                    ) : (
                      <div key={`formattedDateweek3-${formattedDate}`} style={{ display: 'block' }}>
                
                        {itemsForDate.map((item) => {
                          
                          const { colors, textContent,totalWorkHour } = getWorkStatusDetails(
                            item.work_status,
                            item.start_time_uren,
                            item.eind_time_uren,
                            item.total_day_hour,
                            item.shift_color,
                            item.shift_name,
                          );
                          const dimmedColor = dimColor(colors, 0.1);
                          return (
                            <div
                              key={item.uren_id}
                              className={`css-s3o0sad3 border-style   `}
                            >
                              {/* Render the absence block if isAbsent is true */}
                              {isAbsent && (
                                <div className="css-block css-vk-absen">
                                  <div className="schedule-template " id="schedule-template">
                                  <div className="tooltip-text-absence">
  <div className="tooltip-header">
    <span className="count-as">
    {count_as === 'vk' ? t('Vacation') : count_as === 'zk' ? t('Sick') : t('Other')}
    </span>
    <span className="absence-status">{absenceStatus}</span>
  </div>
  <div className="absence-dates">
  {`${t('From')} ${ formatDateuren(absenceStartDate) } ${t('To')} ${ formatDateuren(absenceEndDate) }`}
  </div>
</div>

                                    <div
                                      className="h-[34px] schedule-item clickable flex items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts"
                                    >
                                      <div className="sh-tl__static w-full" style={{ justifyContent: 'center' }}>
                                        <div className="flex">
                                          <div className="flex relative">
                                          <button
                            className="css-1m92us_absence"
                            type="button"
                            disabled={!isEditorAllowed}
                            onClick={() => handleOpenAbsenceModal(absenceDetails)}
                          >
                           <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                           {t('Vacation')} 
                                            </span>
                           </button>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          
                              {/* Render the regular schedule block regardless of isAbsent */}
                              <div className="css-block parent-container">
                                <div className="schedule-template" id="schedule-template">
                                  <div className="new-btn_group me-2 edit-vds3g">
                                    <div className="tooltip-text">
                                    <div className="tooltip-header">
                                    <span className="count-as">
                                    {`${item.shift_name} ${item.start_time_uren} - ${item.eind_time_uren}`}
                                    </span>
                                    </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenPutModal(
                                          currentDetails.startDate,
                                          currentDetails.endDate,
                                          item,
                                          item.team_id_uren,
                                          item.departmen_id_uren,
                                          item.employers_id,
                                          item.uren_id,
                                          formatDateuren(item.uren_date),
                                          item.shift_id_uren,
                                          item.shift_name
                                        )
                                      }
                                    >
                                      <i className="bx bx-pencil"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenTrashModal(item.employers_id, item.uren_id, formatDateuren(item.uren_date))
                                      }
                                    >
                                      <i className="bx bx-trash"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="css-shord3 bg-hover-2"
                                      disabled={!isEditorAllowed}
                                      onClick={() =>
                                        handleOpenAddModal(
                                          item.team_id_uren,
                                          item.departmen_id_uren,
                                          formatDateuren(item.uren_date),
                                          item.dep_name,
                                          false
                                        )
                                      }
                                    >
                                      <i className="bx bx-plus"></i>
                                    </button>
                                  </div>
                                  <ScheduleItem
                                    title={textContent}
                                    value={totalWorkHour}
                                    backgroundColor={dimmedColor}
                                    borderColor={colors}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}

        {/* Rooster Placeholder if no matching employees */}
        {!hasMatchingEmployees && (
          <div className="css-k23serv3s week-sv232 grid-full-width">
            <div className="css-li23s54 border-style" style={{ width: '28%' }}>
              <span>{t('Schedule')}</span>
            </div>
            {dates.map(({ formattedDate }) => (
              <div key={`formattedDate2ass-${formattedDate}`} style={{ display: 'inline-flex', height: '40px' }}>
              <div className="css-s3o0sad3 border-style targethover">
                <div className="add-40os34">
                  <button
                    className="css-1m92us add-plus schedule-button"
                    type="button"
                    style={{ width: '100%' }}
                    disabled={!isEditorAllowed}
                    onClick={() => handleOpenAddModal(team.team_id, team.dep_id, formattedDate,team.dep_name,false)}
                  >
                    <span className="css-230ofxs" role="img" aria-hidden="true">
                      <i className="bx bx-plus"></i>
                    </span>
                  </button>
                </div>
              </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
  
else if (currentDetails.type === "date") {
  const hasMatchingEmployees = Object.values(urendata).some(
    (data) => data.teamNames === teamname
  );

  return (
    <>
      {Object.keys(urendata).map(employerId => {
        const { items, teamNames, employeeName, profileImage } = urendata[employerId];
        const isTeamMatch = teamname === teamNames;
        if (isTeamMatch || !hasMatchingEmployees) {
          // style={{ display: isTeamMatch ? "block" : "none" }}
          return (
            <div key={employerId}
             className="css-k23serv3s" 
            >
              {isTeamMatch ? (
                <>
                  {/* Display Team Profile Section */}
                  <div className="css-li23s54 border-style" >
                  <a
  href={`/details/collaborator/${employerId}`}
  target="_blank"
  rel="noopener noreferrer"
  style={{ textDecoration: 'none', color: 'inherit' }}
>
  <img
    style={{ marginRight: '5px' }}
    className="rounded-circle header-profile-user_plane"
    src={profileImage || 'default_profile_image_url.jpg'}
    alt={`${employeeName}_profile_shiftOnline`}
  />
  {employeeName}
</a>
                  </div>

                  {/* Render time slots with items for this date */}
                  {dates.map(({ formattedDate, timeSlot }) => {
                    const itemsForDate = items.filter(
                      item => item.team_name === teamname && formatDateuren(item.uren_date) === formattedDate
                    );
                    const absenceDetails = getalltabsence.find(absence => {
                      const absenceStartDate = formatDateuren(absence.startdate_absence); 
                      const absenceEndDate = formatDateuren(absence.enddate_absence);
                      const isSameWorker = String(absence.workerid) === String(employerId); // Type cast to ensure match
                      const isSameOrAfterStart = formattedDate >= absenceStartDate;
                      const isSameOrBeforeEnd = formattedDate <= absenceEndDate;
                    
                      return isSameWorker && isSameOrAfterStart && isSameOrBeforeEnd;
                    });
                    
                    const isAbsent = !!absenceDetails; // True if absenceDetails is found
                    // Check if there's an item for this time slot
                    const matchingItem = itemsForDate.find(
                      item => roundToNearestHour(item.start_time_uren) === timeSlot
                    );
                    
                    if (matchingItem) {
                      const startIndex = dateSchedule.indexOf(roundToNearestHour(matchingItem.start_time_uren));
                      const endIndex = dateSchedule.indexOf(roundToNearestHour(matchingItem.eind_time_uren));
                      let blockWidth, leftPosition;
                      if (startIndex > endIndex) {
                        // Use these widths to create two blocks for the shift spanning overnight
                        blockWidth = `${(startIndex - endIndex) * 100}px`;
                        leftPosition = `${endIndex * 105}px`; // Calculate initial offset for part 1
                      } else {
                        // Normal case: shift within the same day
                        blockWidth = `${(endIndex - startIndex) * 100}px`; // Width for single shift
                        leftPosition = `${startIndex * 105}px`; // Initial offset
                      }
             

                      const { colors, textContent, totalWorkHour } = getWorkStatusDetails(
                        matchingItem.work_status,
                        matchingItem.start_time_uren,
                        matchingItem.eind_time_uren,
                        matchingItem.total_day_hour,
                        matchingItem.shift_color,
                        matchingItem.shift_name
                      );
                      const dimmedColor = dimColor(colors, 0.1);
                    
                      return (
                        <div key={matchingItem.uren_id}>
                            <div className="css-s3o0sad3 targethover">
                        <div className="relative">
                          <div className="schedule-template" id="schedule-template">
                            
                            {/* Conditionally render the absence element above the ScheduleItem */}
                            {isAbsent && (
                              <div key={absenceDetails.abseceid} style={{backgroundColor:'#fffe3c' ,display: 'block', zIndex: 1, width: '2400px' }} className="css-s3o0sad3">
                                <div className="relative">
                                  <div className="h-[34px] schedule-item clickable items-center group rounded border-[2px] border-solid schedule-item-ghost sh-tl__conflicts">
                                    <div className="relative left-0 top-0">
                                      <div className="rounded-full h-[26px] top-[-13px] absolute min-w-[6px] w-[6px] ml-[2px]"></div>
                                    </div>
                                    <div className="sh-tl__static" style={{ justifyContent: 'center' }}>
                                      <div className="flex">
                                        <div className="flex relative left_7">
                                          <span className="overflow-hidden sb-text-grey-600 width-75px shift_name relative text-ellipsis text-xs">
                                          {t('Vacation')} 
                                          </span>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="block relative left_7">
                                          <span className="overflow-hidden sb-text-grey-600 width-40px relative text-ellipsis text-xs">
                                          {t('Vacation')} 
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                      
                          </div>
                        </div>
                      </div>

                      <div style={{ zIndex: 1, left: leftPosition  }} className="css-s3o0sad3 targethover">
                        <div style={{ width: blockWidth }} className="relative">
                          <div className="schedule-template" id="schedule-template">
                            {/* ScheduleItem component */}
                            <ScheduleItem
                              title={textContent}
                              value={totalWorkHour}
                              backgroundColor={dimmedColor}
                              borderColor={colors}
                            />
                          </div>
                        </div>
                      </div>
                        </div>
                      
                      
                      );
                    }
                    // else {
                    //   // Render empty slot if no item exists for the time slot
                    //   return (
                    //     <div key={timeSlot} className="css-s3o0sad3 targethover h-[40px]">
                    //       <div className="add-40os34"></div>
                    //     </div>
                    //   );
                    // }
                  })}
                </>
              ) : (
                !hasMatchingEmployees && (
                  // "Rooster" placeholder for teams without any employees
                  <div className="css-k23serv3s">
                    <div className="css-li23s54 border-style">
                      <span>{t('Schedule')}</span>
                    </div>
                    {dates.map(({ timeSlot }) => (
                      <div key={`fortimeSlot-${timeSlot}`} className="css-s3o0sad3 targethover h-[40px]">
                        <div className="add-40os34"></div>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          );
        }
        return null; // Skip rendering if no match
      })}
    </>
  );
}


};

const filteredTeamByTeamLeider = teamdata.filter((team) => {
  const matches = teamleider.includes(String(team.team_id));
  return matches;
});


useEffect(() => {
  if (filteredTeamByTeamLeider.length > 0) {
    setCheckedItems(filteredTeamByTeamLeider.map(dep => ({ depid: dep.departments_id, checked: true })));
  }
}, [filteredTeamByTeamLeider,teamdata]);

const filteredDepData = useMemo(() => 
  filteredTeamByTeamLeider.filter(dep => 
    dep.dep_name?.toLowerCase().includes(searchDep?.toLowerCase() || "")
  ), 
  [filteredTeamByTeamLeider, searchDep]
);

const totalTeamPages = Math.ceil(filteredDepData.length / itemsPerPage);
const startTeamIndex = (currentPageDep - 1) * itemsPerPage;
const paginatedDep = useMemo(() => {
  const filteredData = filteredTeamByTeamLeider.filter(dep => 
    dep.dep_name && // Ensure dep_name exists
    dep.dep_name.toLowerCase().includes(searchDep.toLowerCase() || "")
  );
  const startIndex = (currentPageDep - 1) * itemsPerPage;
  return filteredData.slice(startIndex, startIndex + itemsPerPage);
}, [filteredTeamByTeamLeider, searchDep, currentPageDep]);



const handleSearchDepChange = (e) => {
  const newValue = e.target.value;
  if (newValue !== searchDep) {
    setSearchDep(newValue);
    setCurrentDepPage(1); 
  }
};
const handlePageChangeDep = (newPage) => {
  setCurrentDepPage(newPage);
};

const handleCheckboxChange = (event, depid) => {
  setCheckedItems(prevItems => 
    prevItems.map(item => item.depid === depid ? { ...item, checked: event.target.checked } : item)
  );
};



const filteredTeamData = useMemo(() => 
  filteredTeamByTeamLeider.filter(dep => checkedItems.some(item => item.checked && item.depid === dep.departments_id)), 
  [filteredTeamByTeamLeider, checkedItems]
);




 const handlePutDepartmentChange = (e) => {
  const id = e.target.getAttribute('data-id');
  setPutDepartment((prevData) => ({
    ...prevData,
    id: id,    // Set the department ID
    value: e.target.value,          // Set the new value of the input
  }));
  setIsDisableDepPut(true)
};

const handlePutSubmitDepart = () => {

  if (putDepartMend.id && putDepartMend.value) {
    dispatch(putDep(putDepartMend));
  }
};

useEffect(() => {
  const depSuccessHandler = (message) => {
    toastr.success(t(message));
    const obj = {
      start:currentDetails.startDate,
      eind:currentDetails.endDate,
      account_id:account_id,
    }
    dispatch(getUren(obj));
    dispatch(getShiftTeam(account_id));
  };
  
  const depErrorHandler = (message) => {
    toastr.error(t(message));
  };

  if (putDeps) {
    putDeps.success 
      ? depSuccessHandler('The department data has been successfully updated.') 
      : depErrorHandler('Failed to update department data.');
  }
  if (posturen) {
    posturen.success 
      ? depSuccessHandler('Schedule created successfully! Your schedule is now active and visible to the team.') 
      : depErrorHandler('Failed to create schedule. Please check the details and try again.');
  }
  if (postDeps) {
    postDeps.success 
      ? depSuccessHandler('New department data has been successfully submitted.')
      : depErrorHandler('Failed to submt department data.');
  }
}, [putDeps, postDeps,posturen]);
const navigationButtons = [
  { label: t('Dag'), onClick: () => handleTabClick(`/shift/date/${dateurl}`, 'date'), activeTab: '/shift/day' },
  { label: t('Week'), onClick: () => handleTabClick(`/shift/week/${dateurl}`, 'week'), activeTab: '/shift/week' },
  { label: t('Maand'), onClick: () => handleTabClick(`/shift/month/${dateurl}`, 'month'), activeTab: '/shift/month' }
];


const filterUrendataByTeam = (urendata, filteredTeamData) => {
  // Create a lookup for team IDs from filteredTeamData
  const teamIds = new Set(filteredTeamData.map(team => team.team_id));

  // Filter urendata based on whether items contain any matching team_id_uren
  const filteredUrendata = Object.fromEntries(
    Object.entries(urendata).filter(([_, data]) => 
      data.items.some(item => teamIds.has(item.team_id_uren))
    )
  );

  return filteredUrendata;
};

const filteredUrendata = filterUrendataByTeam(urendata, filteredTeamData);

  return (
    
    <React.Fragment>
      
      {isPrintShiftModalopen && (
  <PrintShiftModal t={t} 
  show={isPrintShiftModalopen} 
  handleClose={handleClosePrintShiftModal} 
  urendata={filteredUrendata} 
  absence={getalltabsence}
  currentDetails={currentDetails}
   />
)}
 
{isModalOpen && (
  <PlanningModal t={t} show={isModalOpen} handleClose={handleCloseModal} setIsReloadData={setIsReloadData} />
)}
{isShiftModalOpen && (
  <ShiftModal t={t} show={isShiftModalOpen} handleClose={handleCloseShiftModal}  />
)}
{isDateShiftModalOpen && (
     <DatShiftModal 
     t={t} 
     show={isDateShiftModalOpen} 
     handleClose={handleCloseDateShiftModal} 
     selectedShiftItem={selectedShiftItem} 
   />
)}
{isTrashModalOpen && (
 <TrashModal 
 t={t} 
 show={isTrashModalOpen} 
 handleClose={handleCloseTrashModal} 
 selectedTrashItem={selectedTrashItem} 
/>
)}


      {isAbsenceModalOpen && (
   <AbsenceModal 
   t={t} 
   show={isAbsenceModalOpen} 
   handleClose={handleCloseAbnseceModal} 
   selectedAbsenceItem={selectedAbsenceItem} 
   getalltabsence={getalltabsence}
   setIsReloadData={setIsReloadData}
 />
)}
   

{isAddModalOpen && (
     <AddSignleDateUren 
     t={t} 
     show={isAddModalOpen} 
     handleClose={handleCloseAddModal} 
     selectedAddItem={selectedAddItem} 
     setIsReloadData={setIsReloadData}
   />
)}
    

{isPutModalOpen && (
  <PutSignleDateUren 
    t={t} 
    show={isPutModalOpen} 
    handleClose={handleClosePutModal} 
    selectedPutItem={selectedPutItem}
    setIsReloadData={setIsReloadData} 
  />
)}



{selectedTeam && (
        <TeamAndShift
          t={t}
          show={isTeamAndShiftModalOpen}
          handleClose={handleCloseTeamAndShiftModal}
          selectedTeam={selectedTeam}
          setIsReloadData={setIsReloadData} 
        />
      )}
       
                    <Modal isOpen={toast}>
    <ModalHeader toggle={toggleToast}>
      {t('Upgrade your plan')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t(`You have reached the limit of the plan. Please upgrade your account to continue.`)} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
                    <Modal isOpen={addDepModal}>
    <ModalHeader toggle={toggleAddDep} style={{backgroundColor:'#31a7f0',color:'white'}}>
    {t('Add New Department')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>

                <FormGroup>
          <Label for="departmentName">{t("Department Name")}</Label>
          <Input
            type="text"
            id="departmentName"
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
            placeholder={t("Enter department name")}
          />
        </FormGroup>

        <FormGroup>
          <Label for="departmentAddress">{t("Department Address")}</Label>
          <Input
            type="text"
            id="departmentAddress"
            value={departmentAddress}
            onChange={(e) => setDepartmentAddress(e.target.value)}
            placeholder={t("Enter or select address")}
          />
        </FormGroup>        
        <Button
          color="Primary"
          className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn"
          onClick={handlePostSubmitDepart}
        >
          {t("Submit")}
        </Button>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>
        <Container fluid>
          {/* left Sidebar  */}
<div

className="right-slider-diens-0 modalopen" 
style={{
position: 'fixed',
width: '350px',
height: '320px',
overflow:'auto',
background: '#fff',
zIndex: 1000,
padding: '10px',
 top: '125px',
right:'225px',
display: isDepSliderVisible ? 'block' : 'none',
}}>
<div>
<div   className="closecontainer" style={{ paddingBottom: '10px', direction: 'rtl' }}>
<div onClick={toggleDepSlider} className="close_are" id="close_side" style={{ color: 'rgb(207, 8, 8)' }}>
<span>{t("close")}</span>
<i className="bx bx-chevron-right" style={{ fontSize: '20px' }}></i>
</div>
</div>

<div className="section_search col-12">
<div className="search-container">
<input type="text"
 className="search-input form-control"
 placeholder="search or add new --->"
 onChange={handleSearchDepChange}
  />

{paginatedDep && paginatedDep.length >= maxDepartments ? (
  // If the number of departments has reached the limit, show the toast toggle button
  <button className="css-smal4s9" disabled={!isEditorAllowed} type="button" onClick={toggleToast}>
    <span className="css-23dsfxs">
      <i className="bx bx-plus" style={{ fontSize: '24px' }}></i>
    </span>
  </button>
) : (
  // If the number of departments is below the limit, allow adding a new department
  <button className="css-smal4s9" disabled={!isEditorAllowed} type="button" onClick={toggleAddDep}>
    <span className="css-23dsfxs">
      <i className="bx bx-plus" style={{ fontSize: '24px' }}></i>
    </span>
  </button>
)}



</div>

</div>
<label htmlFor="dienst_siade_check">{t('Update Team')}</label>
                        {paginatedDep && paginatedDep.length > 0 ? (
  paginatedDep.map((dep, index) => (

    <div key={index} className="section_search col-12">
<div className="search-container">
<input
    className="form-check-input relative"
    type="checkbox"
    defaultChecked
    onChange={(e) => handleCheckboxChange(e, dep.departments_id)}
    style={{ right: "5px",width: "18px",height: "18px",top: "6px" }}
  />

<input type="text"
 className="search-input form-control"
 placeholder={t("search by department name")}
 defaultValue={dep.dep_name}
 data-id={dep.departments_id}
 onChange={handlePutDepartmentChange}
  />
<button className=" css-smal4s9" type="button">
<span className="css-23dsfxs">
  <i className="bx bx-pencil" style={{ fontSize: '24px' }}></i>
</span>
</button>
</div>

</div>

  ))
) : (
  <p>{t('No teams available.')}</p>
)}

<Row>
        <Col xs="7">
          {t('Showing')} {currentPageDep === 1 ? 1 : (currentPageDep - 1) * itemsPerPage + 1} - {Math.min(currentPageDep * itemsPerPage, filteredTeamByTeamLeider.length)} {t('of')} {filteredTeamByTeamLeider.length}
        </Col>
        <Col xs="5">
          <div className="btn-group float-end">
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPageDep === 1}
              onClick={() => handlePageChangeDep(currentPageDep - 1)}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              disabled={currentPageDep === totalTeamPages}
              onClick={() => handlePageChangeDep(currentPageDep + 1)}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Col>
      </Row>
</div>
</div>
        <div className="content-header">
          
      <div className="content-header__buttons">
        <div className="flex-1 flex">
          <div role="group" className="btn-group sub-header__buttons">
          <ul className="nav nav-pills">
    {navigationButtons.map((btn, index) => (
      <li key={`forindex-${index}`} className="nav-item">
        <a onClick={btn.onClick} className={`nav-link ${activeTab === btn.activeTab ? 'active' : ''}`}>
          <span>{btn.label}</span>
        </a>
      </li>
    ))}
  </ul>
          </div>
          <div role="group" className="btn-group sub-header__buttons">
            <button type="button" onClick={() => handelNextOrPrev('prev')} className="css-sq80gj" id="prev-month-btn">
              <span className="css-1tsc6yp">
                <span aria-label="Achteruit navigeren in datums" role="img" className="css-34iy07">
                <svg aria-hidden="true" focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 5l4 4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
                <span role="img" aria-label="Achteruit navigeren in datums" className="css-34iy07">
                <svg aria-hidden="true" focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 5l4 4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
              </span>
            </button>
            <button type="button" className="css-sq80gj">
              <input type={type}  onChange={handleDateChange}  style={{ width: '21px', border: 'none', outline: 'none', fontSize: '16px', color: '#1bbee3' }} />
            </button>
            {currentDetails ? (
            <p className="css-10ewgza d-none d-lg-block" id="month-label">
                {currentDetails.title}
            </p>
        ) : (
            <p>{t('Error: Could not load month details')}</p>
        )}
            <button type="button" onClick={() => handelNextOrPrev('next')} className="css-sq80gj" id="next-month-btn">
              <span className="css-1tsc6yp">
                <span aria-label="Vooruit navigeren in datums" role="img" className="css-34iy07">
                <svg aria-hidden="true"  focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9l4-4-4-4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
                <span role="img" aria-label="Vooruit navigeren in datums" className="css-34iy07">
                <svg aria-hidden="true"  focusable="false" width="6" height="10" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9l4-4-4-4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
                </span>
              </span>
            </button>
          </div>
          <div className="btn-group sub-header__buttons">
            <button className="css-1m5o92us" type="button"
  onClick={handleOpenModal}
  disabled={!isEditorAllowed}
             >
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
              <span  className="css-1ixbps20l d-none d-md-block">{t('Add schedule')}</span>
            </button>
            <button onClick={() => setIsDropOpen(!isDropOpen)} style={{ borderLeft:'1px solid',width: '20%' }} className=" css-1m5o92sus" type="button" >
            <span _ngcontent-jjn-c215="" role="img" className="css-23dsfxsu" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="sum">
        <i className="bx bx-chevron-down"></i>
        </span>
            </button>
            {isDropOpen && (
           <ul
           className="dropUl"
            >
            <li
            className="dropLi"
            onClick={handlePrintShiftModal}
            >
            {t('Print Schedule')} 
            </li>
           </ul>
            )}
          </div>
          <div className="btn-group sub-header__buttons">
            <button  disabled={!isEditorAllowed} style={{background:'#53a400'}} onClick={handleOpenShiftModal} className=" css-1m5o92us" type="button" data-bs-toggle="modal" data-bs-target="#dienst_tovoegt">
              <span role="img" className="css-23dsfxs" aria-hidden="true">
                <svg viewBox="0 0 16 16" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg>
              </span>
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">{t('Add Shift')}</span>
            </button>
          </div>
          <div className="btn-group sub-header__buttons"style={{marginLeft:'10px'}} >
            {/* <button className="css-1m92us" type="button" style={{fontWeight:'700'}}>
              <span role="img" className="css-230ofxs" aria-hidden="true">
                <i className="bx bx-chevron-left"></i>
              </span>
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">Filters</span>
            </button> */}
            <button  onClick={toggleDepSlider}  style={{fontWeight:'700',marginLeft: '10px'}} className=" css-1m92us" type="button" >
              <span data-qa="submit-username-text" className="css-1ixbps20l d-none d-md-block">{t('All departments')}</span>
              <span role="img" className="css-230ofxs" aria-hidden="true">
                <i className="bx bx-chevron-down"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* header */}
    <div className="page__contentB">
    <div className="card card__schedule-optimized ng-star-inserted">
   
<div className="top_reels" id="top_reels" style={{background:'#ffffff'}}>
{loading && (
        <div style={{display:'flex',background:'#fff'}} className="loadingPage" id="loadingPage">
        <span className="loader"></span>
        </div>
      )}
<div className={type === 'week' ? 'css-k23serv3s blue-sv232 sticky_day grid-full-width' : ' sticky_day css-k23serv3s blue-sv232 sticky_day'}>
              <div className="css-li23s54" style={type === 'week' ? { width: '28%' } : { width: '200px' }} ></div>
              {renderDates()}
            </div>
          
            {filteredTeamData && filteredTeamData.length > 0 ? (
  filteredTeamData.map((team, index) => (
    <div  key={team.team_id} className="col-lg-12 no-left-padding-row">
      
    <div className="block-style-rows block-border-radius ">
      
<div className="card-heading card-heading__table department-header-border" 
  style={
    type === 'month' 
      ? { width: '263%' }
      : type === 'date' 
      ? { width: '214%' }
      : { width: '100%' } 

  }
>


  <div className="sticky-department-header-left-col card-heading__title card-heading__no-padding">
    <button
      className="btn btn-white button-icon-text"
      type="button"
      disabled={!isEditorAllowed}
      onClick={() => handleOpenTeamAndShiftModal({ 
        teamid: team.team_id, 
        depid: team.dep_id,
        color: team.team_color,
        teamname: team.team_name,
      })}
    >
      <span
        className="mat-icon notranslate si button-icon__icon mat-icon-no-color"
        data-mat-icon-type="svg"
        data-mat-icon-name="sum"
      >
        <i
          className="bx bx-chevron-right"
          style={{ position: 'relative', top: '1px', fontSize: '20px' }}
        ></i>
      </span>
      <span className="css-1ixbps20l">{t('Shift/Teams')} </span>
    </button>
    <div id="schedultitle" className="schedultitle">
    {currentDetails ? (
      <span
      id="date_currently"
      className="css-1ixbps20l"
      style={{ fontWeight: 600 }}
    >
      {company_name} - {team.team_name} {currentDetails.title}
    </span>
  ) : (
    <span
    id="date_currently"
    className="css-1ixbps20l"
    style={{ fontWeight: 600 }}
  >
   {t('Error: Could not load  details')}
  </span>
      
  )}
      
    </div>
  </div>
  <div className="sticky-department-header-right-col card-heading__button-group card-optimized-roster">
    <button
      className=" css-1m5o92us"
      type="button"
      onClick={() => handleOpenAddModal(team.team_id,team.dep_id ,urlDate,team.dep_name ,true)}
      disabled={!isEditorAllowed}                              
    >
      <span
        className="css-23dsfxs"
        data-mat-icon-type="svg"
        data-mat-icon-name="sum"
      >
        <i
          className="bx bx-plus"
          style={{ fontSize: '20px' }}
        ></i>
      </span>
      <span className="css-1ixbps20l d-none d-md-block">{t('Add schedule')} </span>
    </button>
  </div>
</div>
</div>





      {/* Team specific row */}
      <div
        className={type === 'week' ? 'css-k23serv3s blue-sv232 grid-full-width' : 'css-k23serv3s blue-sv232'}
        style={{
          backgroundColor: dimColor(team.team_color, 0.1),
          borderBottom: `3px solid ${team.team_color}`,
        }}
      >
<div className="css-li23s54" style={type === 'week' ? { width: '28%' } : { width: '200px' }}>

<div className="row" style={{ width: '100px' }}>
            <div className="col-6 css-23fs434">
              <span
                className="css-spanfs3"
                style={{ border: '2px solid rgb(133, 160, 133)' }}
              >
                <i className="bx bx-chevron-right"></i>
              </span>
            </div>
            <div className="col-6" style={{ display: 'inline-grid' }}>
              {/* Dynamic team name */}
              <span>{team.dep_name}</span>
              <small style={{ position:'relative',width:'101px',textOverflow:'ellipsis' }}>{team.team_name}</small>
            </div>
          </div>
        </div>
        {renderWithDateName()}
      </div>

      {/* Schedule section */}
      <div className="css-sa3422a">
      {renderSchedule(team.team_name,team)}
      </div>
    </div>
  ))
) : (
  <p>{t('No teams available.')}</p>
)}
   
      </div>

      </div>  

    </div>
   

{/* department-period-row */}

<div className="row" style={{ marginLeft: 'auto', marginRight: 'auto', padding: 'unset' }}>
  <div className="col-10 sectionTitle_top" style={{ display: 'none' }}>
    <div className="row seconRow_top">
      <div className="col-9 no-left-padding-row">
        <div className="btnmod">
          <button
            className="olv-button olv-ignore-transform css-1m92us ex-css3s4"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            disabled={!isEditorAllowed}
          >
            <span
              role="img"
              className="css-230ofxs"
              aria-hidden="true"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <i className="bx bx-chevron-right"></i>
            </span>
            <span data-qa="submit-username-text" className="css-1ixbps20l">
            {t('Shift/Teams')}
            </span>
          </button>
        </div>
      </div>
      <div className="col-3 no-left-padding-row">
        <div>
          <button
            className="olv-button olv-ignore-transform css-1m5o92us"
            type="button"
            style={{ width: '80%' }}
            disabled={!isEditorAllowed}
          >
            <span
              role="img"
              className="css-23dsfxs"
              aria-hidden="true"
              data-mat-icon-type="svg"
              data-mat-icon-name="sum"
            >
              <svg
                filename="sum.svg"
                viewBox="0 0 16 16"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
              </svg>
            </span>
            <span data-qa="submit-username-text" className="css-1ixbps20l">
            {t('Add schedule')} 
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="row css-k23serv3s ">

</div>
        </Container>
      
      
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Timesheet));

