import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "https://dspmetrics.com";

const axiosApi = axios.create({
  baseURL: API_URL,
  // headers: {
  //   Authorization: `${token}`,
  //   // common: {
  //   //   Authorization: `${token}`, // Use template literal to set Authorization header
  //   // },
  //   timeout: 10000,
  // },
});


axiosApi.defaults.headers.common["Authorization"] = token;

// axiosApi.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API call failed:', error.config);
    console.error('Error response:', error.response ? error.response.data : 'No response received');
    return Promise.reject(error);
  }
);


export async function get(url, config = {}) {
  
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}


export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
