import React, { useRef, useState, useEffect,useMemo  } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  InputGroup,
} from "reactstrap";
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  postContractType,
  postContract,
  getShift,
  postShift,
  putShift,
  deleteShift,
  getUren,
  postUren,
  putUren,
  removeUren,
  getContractEmployer,
} from "store/actions";
import Select from "react-select"
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";
import { startOfDay } from "date-fns";
const daysOfWeek = ["zo","ma","di","wo","do","vr","za"];

const PutSignleDateUren = ({t, show, handleClose,selectedPutItem,setIsReloadData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  
  const {
    client,
    xlsid,
    contractdata,
    postDeps,
    postTeams,
    postContractTypes,
    shift,
    shiftdata,
    putshift,
    removeshift,
    urendata,
    posturen,
    puturen,
    removeuren,
    contractemployer,
    error
  } = useSelector(state => ({
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    shiftDatabase: state.getClientData.shiftDatabase,
    postDeps: state.getClientData.postDeps,
    postTeams: state.getClientData.postTeams,
    postContractTypes: state.getClientData.postContractTypes,
    removeuren: state.getShiftData.removeuren,
    shift: state.getShiftData.shift,
    shiftdata: state.getShiftData.shiftdata,
    putshift: state.getShiftData.putshift,
    removeshift: state.getShiftData.removeshift,
    urendata: state.getShiftData.urendata,
    posturen: state.getShiftData.posturen,
    puturen: state.getShiftData.puturen,
    contractemployer: state.getShiftData.contractemployer,
    error: state.getClientData.error,
  }));

  useEffect(() => {
    dispatch(onGetcollaborator(account_id));
    dispatch(getXlsdata(account_id));
    dispatch(getShift(account_id));
    dispatch(getContractEmployer(account_id));
  }, [dispatch  ,account_id]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [selectedShift, setSelectedShiftName] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [workingTime, setWorkingTime] = useState(null);
  const [formattedTime, setFormattedTime] = useState('');
  const [shiftDatabase, setShiftDatabase] = useState({
    account_id: account_id,
    discription: selectedPutItem?.item?.discription_uren || null,
    eind_date_svc: selectedPutItem.date,
    eind_time: selectedPutItem?.item?.eind_time_uren || null,//2024-05-31
    pause: selectedPutItem?.item?.pause_uren || null, 
    repeat_arr: ['ma', 'di'],
    repeat_select: 1,
    send_mail_check: false,
    start_time: selectedPutItem?.item?.start_time_uren || null,
    svc_date: selectedPutItem.date,// ['8' ,'6' ,'6']
    svc_dienst: null,
    svc_dep: selectedPutItem?.depid || null,
    svc_team: selectedPutItem?.teamid || null,
    werknemerid: [`${selectedPutItem?.employers_id}`] || [],
    werknemer:[selectedPutItem?.item?.last_name || null],
    contractid: [`${selectedPutItem?.item?.contract_id}`],
    depa_id: selectedPutItem.depid, 
    eind_date_check: false, 
    repeat_check: false,
    
  });

 const employ_name = `${selectedPutItem?.item?.frist_name || null} ${selectedPutItem?.item?.last_name || null}`
 
 
  
  useEffect(() => {
    if (selectedPutItem.depid) {
      // Introduce a 1-second delay before setting the options
        if (Array.isArray(xlsid) && xlsid.length > 0) {
          // Filter the teams by the selected department ID
          const filteredTeams = xlsid.filter(dept => dept.dep_id === selectedPutItem.depid);
  
          // Create a Set to track unique team names to avoid duplication
          const seenTeams = new Set();
          const uniqueTeams = filteredTeams.filter(team => {
            if (!seenTeams.has(team.team_id)) {
              seenTeams.add(team.team_id);
              setShiftDatabase({
                ...shiftDatabase,
                svc_team: team.team_id,
              });
              return true;
            }
            return false;
          });
  
          // Map the unique teams to the options array
          const options = [
            ...(uniqueTeams.length > 0 ? uniqueTeams.map(team => ({
              value: team.team_id,
              label: team.team_name,
            })) : []),
           
          ];
  
          // Set the filtered and unique team options
          setTeamOptions(options);
        } else {
          setTeamOptions([]);
        }
     
    } else {
      setTeamOptions([]); // Clear team options if no department is selected
    }
  }, [selectedPutItem.depid, xlsid]);
  

  useEffect(() => {
    if (!selectedPutItem.teamid || !Array.isArray(shift) || shift.length === 0) {
      setShiftOptions([]); // Clear options if no team selected or no shifts available
      return;
    }
  
    // Filter shifts by selectedTeamId
    const filteredShifts = shift.filter(s => s.team_id_shift === selectedPutItem.teamid);
    
    // Use Set to find unique shifts
    const seenShiftIds = new Set();
    const uniqueShifts = filteredShifts.filter(s => {
      if (!seenShiftIds.has(s.shift_id)) {
        seenShiftIds.add(s.shift_id);
        return true;
      }
      return false;
    });
  
    if (uniqueShifts.length > 0) {
      // Update shift in shiftDatabase
      setShiftDatabase(prev => ({
        ...prev,
        svc_dienst: selectedPutItem.shiftid,
        svc_name: selectedPutItem.shiftname,
      }));
      
      // Set the first unique shift as selected by default
      setSelectedShiftName({
        value:selectedPutItem.shiftid,
        label: selectedPutItem.shiftname
      });

      setStartTime(selectedPutItem?.item?.start_time_uren);
      setEndTime(selectedPutItem?.item?.eind_time_uren);
      setShiftDatabase(prevData => ({
        ...prevData,
        eind_time: selectedPutItem?.item?.start_time_uren,
        start_time: selectedPutItem?.item?.eind_time_uren,
      }));
      // Map options for dropdown and add "Create New Shift"
      const options = [
        ...uniqueShifts.map(s => ({
          value: s.shift_id,
          label: s.shift_name,
        })),
        
      ];
      setShiftOptions(options);
    } else {
   
      setShiftOptions([]);
    }
  
  }, [selectedPutItem.teamid, shift]);
  
  

  const handleNotificationChange = (e) => {
    const { checked } = e.target;
    setShiftDatabase((prev) => ({
      ...prev,
      send_mail_check: checked, // Update the send_mail_check in the state
    }));
  };

  
const handleUrendate = (e) => {
  setShiftDatabase((prev) => ({
    ...prev,
    svc_date: e.target.value, // Save selected date in 'eind_time'
  }));
};
const handlePauseChange = (e) => {
  setShiftDatabase(prevData => ({
    ...prevData,
    pause: e.target.value,
  }));
}
const handleNotesChange = (e) => {
  setShiftDatabase(prevData => ({
    ...prevData,
    discription: e.target.value,
  }));
}

 
const handleStartTimeChange = (selectedDates) => {
  const newStartDate = selectedDates[0]; // Flatpickr passes an array, use the first date
  setShiftDatabase(prevData => ({
    ...prevData,
    start_time: newStartDate ? newStartDate.toTimeString().slice(0, 5) : "", // Format as "HH:mm"
  }));
};

const handleEndTimeChange = (selectedDates) => {
  const newEndDate = selectedDates[0]; // Flatpickr passes an array, use the first date
  setShiftDatabase(prevData => ({
    ...prevData,
    eind_time: newEndDate ? newEndDate.toTimeString().slice(0, 5) : "", // Format as "HH:mm"
  }));
};

const handleShiftChange = (selectedOption) => {
  setSelectedShiftName(selectedOption);
    const selectedShiftData = shift.find((shiftItem) =>
      String(shiftItem.shift_id) === String(selectedOption.value)
    );
    if (selectedShiftData) {
      setStartTime(selectedShiftData.start_time_shift);
      setEndTime(selectedShiftData.eind_time_shift);
      setShiftDatabase({
        ...shiftDatabase,
        svc_dienst: selectedOption.value,
        svc_name: selectedOption.label,
        start_time: selectedShiftData.start_time_shift,
        eind_time: selectedShiftData.eind_time_shift,
      });
    } 

  
};
  


  const validateForm = () => {
    const newErrors = {};

    if (!shiftDatabase.account_id) newErrors.account_id = t('Account  is required.');
  if (!shiftDatabase.eind_time) newErrors.eind_time = t('End time  is required.');
  if (!shiftDatabase.pause) newErrors.pause = t('Break time is required.');
  if (!shiftDatabase.svc_dienst) newErrors.svc_dienst = t('Shift is required.');
  if (!shiftDatabase.svc_name) newErrors.svc_name = t('Shift name is required.');
  if (!shiftDatabase.start_time) newErrors.start_time = t('Start date is required.');
   if (!shiftDatabase.svc_date) newErrors.svc_date = t('Date is required.');
  if (!shiftDatabase.werknemerid.length) newErrors.werknemerid = t('Employeer is required.');
  if (!shiftDatabase.depa_id) newErrors.depa_id = t('Department is required.');
  if (!shiftDatabase.discription) newErrors.discription = t('Discription is required.');
  if (shiftDatabase.repeat_check) {
    if (!shiftDatabase.repeat_select) newErrors.repeat_select = t('Repeat  is required.');
    if (!shiftDatabase.repeat_arr.length) newErrors.repeat_arr = t('Repeat date time is required.');
  }
  if (shiftDatabase.eind_date_check) {
    if (!shiftDatabase.eind_date_svc) newErrors.eind_date_svc = t('End date is required.');
  }
    
    
    return newErrors // Returns true if there are no errors
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toastr.error(error));
      // Proceed with form submission or further logic getUren
    } else {
      
      dispatch(postUren(shiftDatabase));
    }
  };

  useEffect(() => {
    if (posturen && posturen.success) {
      toastr.success(t('Schedule created successfully! Your schedule is now active and visible to the team'));
      dispatch(getXlsdata(account_id));
      const obj = {
        start:selectedPutItem.start,
        eind:selectedPutItem.eind,
        account_id:account_id,
      }
      
        dispatch(getUren(obj));
    }else if (posturen && posturen.error) {
      toastr.error(t('Failed to create schedule. Please check the details and try again.'));
    }
  }, [posturen, dispatch, account_id]);
  const convertTo24HourFormat = (time) => {
    if (!time) return { hours: 0, minutes: 0 };

    // Check if the time is in 12-hour format
    if (time.toLowerCase().includes('am') || time.toLowerCase().includes('pm')) {
      const [timePart, modifier] = time.split(' ');
      let [hours, minutes] = timePart.split(':').map(Number);

      if (modifier.toLowerCase() === 'pm' && hours !== 12) {
        hours += 12;
      } else if (modifier.toLowerCase() === 'am' && hours === 12) {
        hours = 0;
      }
      return { hours, minutes };
    }

    // Assume time is in 24-hour format (e.g., 14:30)
    const [hours, minutes] = time.split(':').map(Number);
    return { hours, minutes };
  };

  useEffect(() => {
    if (shiftDatabase.start_time && shiftDatabase.eind_time && shiftDatabase.pause) {
      const { hours: startHour, minutes: startMinute } = convertTo24HourFormat(shiftDatabase.start_time);
      const { hours: endHour, minutes: endMinute } = convertTo24HourFormat(shiftDatabase.eind_time);
      const pauseMinutes = parseInt(shiftDatabase.pause, 10);

      let totalMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute) - pauseMinutes;

      if (totalMinutes < 0) {
        totalMinutes += 24 * 60;
      }

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      setWorkingTime(`${hours}:${minutes.toString().padStart(2, '0')}`);
      setFormattedTime(`${shiftDatabase.start_time} - ${shiftDatabase.eind_time} (${shiftDatabase.pause})`);
    }
  }, [shiftDatabase]);
  return (
    <React.Fragment>
       <Modal
                      size="lg"
                      isOpen={show} 
                      toggle={handleClose} centered
                      style={{ bottom: '0',maxWidth: '900px', width: '90%' }}
                      scrollable={true}
                    >
                      <div className="modal-header" style={{backgroundColor:'#31a7f0',color:'white'}}>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Date')}:{`${selectedPutItem.date}`}
                        </h5>
                        <button
                        onClick={handleClose}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {show && (  
 <div className="modal-body">
  <Row>
  <Col lg="8">
  <form onSubmit={handleSubmit}>
  <div className="row">
    <div className="col-lg-12">
      <Row>
        <Col lg="12">
          <div className="mb-3 row">
            <label htmlFor="search_driver" className="col-md-1 col-form-label">{t('Date')}:</label>
            <div className="col-md-3 position-relative">
              <Input
                className="form-control col-md-8"
                onChange={handleUrendate}
                defaultValue={selectedPutItem.date}
                type="date"
                disabled={true} // Use `disabled` instead of `isDisabled`
                id="search_driver"
              />
            </div>
          </div>
        </Col>

        <Col lg="12">
          <div className="mb-3 row">
            <label htmlFor="svc_team" className="col-md-2 col-form-label">{t('Team')}</label>
            <div className="col-md-7" id="select_team">
              <Select
                id="team_id"
                name="team_id"
                options={teamOptions}
                placeholder={t("Select Team")}
                value={teamOptions.find(option => option.value === selectedPutItem.teamid) || null}
                isDisabled={true}
              />
            </div>
          </div>
        </Col>

        <Col lg="12">
          <div className="mb-3 row">
            <label htmlFor="contract_type" className="col-md-2 col-form-label">{t('Shift')}</label>
            <div className="col-md-7" id="contract_type">
              <Select
                id="contract_type"
                name="contract_type"
                options={shiftOptions}
                onChange={handleShiftChange}
                value={selectedShift || { value: '', label: t('Select Contract Type') }} // Default option for shift
              />
            </div>
          </div>
        </Col>

        <Col lg="12">
          <div className="mb-3 row medewerkers" id="medewerkers">
            <label htmlFor="svc_team" className="col-md-4 col-form-label">{t('Employeer')}</label>
            <div className="col-md-7" id="contract_type">
              <div className="card_pay_plane">
                <div>
                  <img
                    className="rounded-circle header-profile-user_plane"
                    src={selectedPutItem?.item?.profile_img || null}
                    alt="Avatar"
                  />
                </div>
                <div className="card__content_pay_plane">
                  <span>{employ_name}</span>
                </div>
                <label className="checkbox-control_pay_plane">
                  <p className="time_cost">{selectedPutItem?.item?.service_type || null}</p>
                  <p className="time_cost">{selectedPutItem?.item?.client_status || null}</p>
                  <Input
                    type="checkbox"
                    className="checkbox_pay_plane plane_checkbox_pay_plane"
                    checked={true} // Set checked without quotes
                    disabled={true}
                  />
                  <span className="checkbox-control__target_pay_plane"></span>
                </label>
              </div>
            </div>
          </div>
        </Col>

        <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="start_time" className="col-md-4 col-form-label">
        {t('Start Time & End Time')}
        </label>
        <div className="col-lg-4">
          <div className="mb-3">
          <InputGroup>
          <Flatpickr
            id="start_time"
            className="form-control d-block"
            placeholder={t("Select time")}
            defaultValue={shiftDatabase.start_time || startTime}
            onChange={handleStartTimeChange}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
          />
</InputGroup>
      
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-3">

          <InputGroup>
          <Flatpickr
            id="end_time"
            className="form-control d-block"
            placeholder={t("Select time")}
            defaultValue={shiftDatabase.eind_time|| endTime}
            onChange={handleEndTimeChange}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
          />
</InputGroup>
  
          </div>
        </div>
      </div>
       </Col>

        <Col lg="12">
          <div className="mb-3 row">
            <label htmlFor="hourly_rate" className="col-md-2 col-form-label d-flex justify-content-center align-items-center">
            {t('Break')}
            </label>
            <div className="col-md-3">
              <Input
                onChange={handlePauseChange}
                defaultValue={shiftDatabase.pause}
                className="form-control"
                type="number"
                name="hourly_rate"
                placeholder="0"
                id="hourly_rate"
              />
            </div>
          </div>
        </Col>

        <Col lg="12">
          <div className="mb-3 row">
            <label htmlFor="description" className="col-md-3 col-form-label">{t('Description')}</label>
            <div className="col-md-9">
              <textarea
                id="description"
                className="form-control"
                rows="3"
                onChange={handleNotesChange}
                value={shiftDatabase.discription || ''} // Use `value` instead of children
              />
            </div>
          </div>
        </Col>

        <Col lg="12">
          <div className="mb-3">
            <div className="form-check form-checkbox-outline form-check-success mb-3">
              <Input
                className="form-check-input"
                name="send_notification"
                type="checkbox"
                id="send_notification"
                onChange={handleNotificationChange}
                checked={shiftDatabase.send_mail_check}
              />
              <label className="form-check-label" htmlFor="send_notification">{t('Send Notification')}</label>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light" onClick={handleClose}>{t('Cancel')}</button>
    <button type="submit" className="btn btn-primary">{t('Save')}</button>
  </div>
</form>
  </Col>
  <Col lg="4">
  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ borderBottom: '1px solid #ddd' }}>
        <th style={{ padding: '5px', textAlign: 'left' }}>{t('Time')}</th>
        <th style={{ padding: '5px', textAlign: 'left' }}>{t('Payout')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: '5px', textAlign: 'left' }}>
            {formattedTime || ''}
          </td>
          <td style={{ padding: '5px', textAlign: 'left' }}>
            {workingTime ? `${workingTime}` : ''}
          </td>
        </tr>
      </tbody>
    </table>
</Col>
  </Row>


</div>

                      )}
                     
                    </Modal>

    </React.Fragment>
  );
};
export default withTranslation()(withRouter(PutSignleDateUren));

