const helpCenterData = [];

const getClientData = async () => {
  try {
    const response = await fetch("https://dspmetrics.com/get/helpcenter");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();

    // Process each item in the result and create the hierarchy
    result.forEach(item => {
      const { icon, id, name, description, lang, submenus, url } = item;

      // Initialize the hierarchy structure for each main menu item
      let hierarchy = {
        mainMenu: {
          id: id,
          name: name,
          description: description,
          lang: lang,
          url: url,
          icon: icon,
          subMenu: [], // Initialize subMenu as an array
        },
      };

      // Process submenus if they exist
      if (submenus && submenus.length > 0) {
        submenus.forEach(sub => {
          let subMenu = {
            id: sub.id,
            name: sub.name,
            description: sub.description,
            lang: sub.lang,
            url: sub.url,
            icon: sub.icon,
            childMenu: [], // Initialize childMenu as an array
            textContent: [], // Initialize textContent as an array to hold multiple contexts
          };

          // Process childmenus within each submenu
          if (sub.childmenus && sub.childmenus.length > 0) {
            sub.childmenus.forEach(child => {
              let childMenu = {
                id: child.id,
                name: child.name,
                description: child.description,
                lang: child.lang,
                url: child.url,
                icon: child.icon,
                textContent: [] // Initialize textContent in childMenu in case it's needed
              };

              // Process context (text content) within each child menu if present
              if (child.contexts && child.contexts.length > 0) {
                child.contexts.forEach(context => {
                  let textContent = {
                    id: context.id,
                    content: context.content,
                    seo_description: context.seo_description,
                    lang: context.lang,
                    seo_keywords: context.seo_keywords,
                    seo_title: context.seo_title,
                    title: context.title,
                  };

                  // Add each context as an object to the childMenu's textContent array
                  childMenu.textContent.push(textContent);
                });
              }

              // Add the child menu (with its text content) to the submenu
              subMenu.childMenu.push(childMenu);
            });
          }

          // Add the submenu (with its child menus and text content) to the main menu
          hierarchy.mainMenu.subMenu.push(subMenu);
        });
      }

      // Push the structured data into the helpCenterData array
      helpCenterData.push(hierarchy);
    });

    return helpCenterData; // Return the organized data
  } catch (error) {
    console.error('Error fetching help center data:', error);
    throw error; // Re-throw the error to handle it later
  }
};

const fetchData = async () => {
  try {
    const data = await getClientData(); // Get the data from getClientData()
    // console.log("Fetched Data:", JSON.stringify(data, null, 2)); // Use the fetched data
  } catch (error) {
    console.error("Error fetching client data:", error);
  }
};

// Call the fetchData function to fetch and process the data
fetchData();

export { helpCenterData };
