import {
  GET_SHIFT,
  GET_SHIFT_SUCCESS,
  GET_SHIFT_FAIL,
  POST_SHIFT,
  POST_SHIFT_SUCCESS,
  POST_SHIFT_FAIL,
  PUT_SHIFT,
  PUT_SHIFT_SUCCESS,
  PUT_SHIFT_FAIL,
  DELETE_SHIFT,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAIL,
  GET_UREN,
  GET_UREN_SUCCESS,
  GET_UREN_FAIL,
  POST_UREN,
  POST_UREN_SUCCESS,
  POST_UREN_FAIL,
  PUT_UREN,
  PUT_UREN_SUCCESS,
  PUT_UREN_FAIL,
  DELETE_UREN,
  DELETE_UREN_SUCCESS,
  DELETE_UREN_FAIL,
  GET_CONTRACT_EMPLOYEER,
  GET_CONTRACT_EMPLOYEER_SUCCESS,
  GET_CONTRACT_EMPLOYEER_FAIL,
  GET_TEAM,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAIL,
  POST_NEW_TEAM,
  POST_NEW_TEAM_SUCCESS,
  POST_NEW_TEAM_FAIL,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAIL,
  POST_INSPECTION_EMPLOYEE,
  POST_INSPECTION_EMPLOYEE_SUCCESS,
  POST_INSPECTION_EMPLOYEE_FAIL,
  GET_SURCHARGER,
  GET_SURCHARGER_SUCCESS,
  GET_SURCHARGER_FAIL,
  POST_SURCHARGER,
  POST_SURCHARGER_SUCCESS,
  POST_SURCHARGER_FAIL,
  PUT_SURCHARGER,
  PUT_SURCHARGER_SUCCESS,
  PUT_SURCHARGER_FAIL,
  DELETE_SURCHARGER,
  DELETE_SURCHARGER_SUCCESS,
  DELETE_SURCHARGER_FAIL,
  } from "./actionTypes";
  const INIT_STATE = {
    shift: [],
    shiftdata: null,
    putshift: null,
    removeshift:null,
    urendata:[],
    schedule:[],
    posturen: null,
    puturen: null,
    removeuren: null,
    teamdata: [],
    contractemployer: [],
    postnewteam:null,
    inspection:null,
    getsurcharger: [],
    putsurcharger:null,
    postsurcharger:null,
    deletesurcharger:null,
    loading: false,
    error: {},
  };
  const getShiftData = (state = INIT_STATE, action) => {
    switch (action.type) {
      case DELETE_SURCHARGER:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case DELETE_SURCHARGER_SUCCESS:
    return {
      ...state,
      loading: false,
      deletesurcharger: action.payload,
      error: null,
     
    };
    case DELETE_SURCHARGER_FAIL:
      return {
        ...state,
        loading: false,
        deletesurcharger: null,
        error: action.payload,
      };

      case POST_SURCHARGER:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case POST_SURCHARGER_SUCCESS:
    return {
      ...state,
      loading: false,
      postsurcharger: action.payload,
      error: null,
     
    };
    case POST_SURCHARGER_FAIL:
      return {
        ...state,
        loading: false,
        postsurcharger: null,
        error: action.payload,
      };


      case PUT_SURCHARGER:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case PUT_SURCHARGER_SUCCESS:
    return {
      ...state,
      loading: false,
      putsurcharger: action.payload,
      error: null,
     
    };
    case PUT_SURCHARGER_FAIL:
      return {
        ...state,
        loading: false,
        putsurcharger: null,
        error: action.payload,
      };

      case GET_SURCHARGER:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case GET_SURCHARGER_SUCCESS:
    return {
      ...state,
      loading: false,
      getsurcharger: action.payload,
      error: null,
     
    };

    case GET_SURCHARGER_FAIL:
      return {
        ...state,
        loading: false,
        getsurcharger: [],
        error: action.payload,
      };

      case POST_INSPECTION_EMPLOYEE:
        return {
          ...state,
          loading: true,
          error: null, // Reset the error when a new request is made
        };
      case POST_INSPECTION_EMPLOYEE_SUCCESS:
    return {
      ...state,
      loading: false,
      inspection: action.payload,
      error: null,
     
    };

    case POST_INSPECTION_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        inspection: null,
        error: action.payload,
      };
   
      case GET_TEAM_SUCCESS:
       
      return {
        ...state,
        teamdata: action.payload,
      };

    case GET_TEAM_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      case GET_SHIFT_SUCCESS:
       
        return {
          ...state,
          shift: action.payload,
        };
  
      case GET_SHIFT_FAIL:
        return {
          ...state,
          error: action.payload,
        };
        case POST_SHIFT:
          return {
            ...state,
            loading: true,
            error: null, // Reset the error when a new request is made
          };
        case POST_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        shiftdata: action.payload,
        error: null,
       
      };

      case POST_SHIFT_FAIL:
        return {
          ...state,
          loading: false,
          shiftdata: null,
          error: action.payload,
        };
    

            case PUT_SHIFT:
              return {
                ...state,
                loading: true,
                error: null, 
              };
      case PUT_SHIFT_SUCCESS:
        return {
          ...state,
          loading: false,
          putshift: action.payload,
          error: null, 
        };
        case PUT_SHIFT_FAIL:
          return {
            ...state,
            loading: false,
            putshift: null, 
            error: action.payload,
          };
    

          case DELETE_SHIFT:
            return {
              ...state,
              loading: true,
              error: null, 
            };
    case DELETE_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        removeshift: action.payload,
        error: null, 
      };
      case DELETE_SHIFT_FAIL:
        return {
          ...state,
          loading: false,
          removeshift: null, 
          error: action.payload,
        };
        

        case GET_UREN:
          return {
            ...state,
            loading: true,
            error: null, 
          };
  case GET_UREN_SUCCESS:
    return {
      ...state,
      loading: false,
      urendata: action.payload,
      error: null, 
    };
    case GET_UREN_FAIL:
      return {
        ...state,
        loading: false,
        urendata: null, 
        error: action.payload,
      };

      case GET_SCHEDULE:
        return {
          ...state,
          loading: true,
          error: null, 
        };
case GET_SCHEDULE_SUCCESS:
  return {
    ...state,
    loading: false,
    schedule: action.payload,
    error: null, 
  };
  case GET_SCHEDULE_FAIL:
    return {
      ...state,
      loading: false,
      schedule: null, 
      error: action.payload,
    };
      case POST_UREN:
        return {
          ...state,
          loading: true,
          error: null, 
        };
case POST_UREN_SUCCESS:
  return {
    ...state,
    loading: false,
    posturen: action.payload,
    error: null, 
  };
  case POST_UREN_FAIL:
    return {
      ...state,
      loading: false,
      posturen: null, 
      error: action.payload,
    };




    case PUT_UREN:
        return {
          ...state,
          loading: true,
          error: null, 
        };
case PUT_UREN_SUCCESS:
  return {
    ...state,
    loading: false,
    puturen: action.payload,
    error: null, 
  };
  case PUT_UREN_FAIL:
    return {
      ...state,
      loading: false,
      puturen: null, 
      error: action.payload,
    };



    case DELETE_UREN:
      return {
        ...state,
        loading: true,
        error: null, 
      };
case DELETE_UREN_SUCCESS:
return {
  ...state,
  loading: false,
  removeuren: action.payload,
  error: null, 
};
case DELETE_UREN_FAIL:
  return {
    ...state,
    loading: false,
    removeuren: null, 
    error: action.payload,
  };


  case GET_CONTRACT_EMPLOYEER:
    return {
      ...state,
      loading: true,
      error: null, 
    };
case GET_CONTRACT_EMPLOYEER_SUCCESS:
return {
...state,
loading: false,
contractemployer: action.payload,
error: null, 
};
case GET_CONTRACT_EMPLOYEER_FAIL:
return {
  ...state,
  loading: false,
  contractemployer: null, 
  error: action.payload,
};

case POST_NEW_TEAM:
  return {
    ...state,
    loading: true,
    error: null, // Reset the error when a new request is made
  };
case POST_NEW_TEAM_SUCCESS:
return {
...state,
loading: false,
postnewteam: action.payload,
error: null,

};

case POST_NEW_TEAM_FAIL:
return {
  ...state,
  loading: false,
  postnewteam: null,
  error: action.payload,
};
      default:
        return state;
    }
  };
  
  export default getShiftData;