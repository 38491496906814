// ModalComponent.js
import React, { useRef, useState, useEffect ,useCallback} from "react";
import {
    Button,
    Col,
    Row,
    Nav,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "reactstrap";
  import classnames from "classnames";
import { withTranslation } from 'react-i18next';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
const convertImageToBase64 = (url) => {
  return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
              resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
  });
};
const ModalComponent =  ({clientData, t, show, handleClose }) => {

  const [images, setImageUrl] = useState('https://analyticscargo.com/public/assets/images/logop.jpg');

  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const printContentRef = useRef(); 
  useEffect(() => {
      const loadScript = (src, id) => {
          return new Promise((resolve, reject) => {
              if (document.getElementById(id)) {
                  resolve();
                  return;
              }

              const script = document.createElement('script');
              script.src = src;
              script.id = id;
              script.async = true;
              script.onload = resolve;
              script.onerror = reject;
              document.body.appendChild(script);
          });
      };

      const loadScripts = async () => {
          try {
              await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js', 'jspdf');
              await loadScript('https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.2/html2pdf.bundle.min.js', 'html2pdf');
              setScriptsLoaded(true); // Indicate that scripts have been loaded
          } catch (error) {
              console.error('Error loading scripts:', error);
          }
      };

      loadScripts();

      return () => {
          const jspdfScript = document.getElementById('jspdf');
          const html2pdfScript = document.getElementById('html2pdf');
          if (jspdfScript) document.body.removeChild(jspdfScript);
          if (html2pdfScript) document.body.removeChild(html2pdfScript);
      };
  }, []);

  const handleConvertHtmlToPdf = async () => {
    try {
        const element = document.querySelector('.wrapperPdf');
        if (!element) {
            throw new Error('Element not found: .wrapperPdf');
        }
        
        // Configure options for html2pdf
        const opt = {
          margin: 1,
          filename: `${clientData.company_name}_Personalia_Formulier.pdf`, // Correct filename syntax
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true, logging: true },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        if (window.html2pdf) {
            await window.html2pdf()
                .from(element)
                .set(opt)
                .save();
                toastr.success(t('PDF generated successfully'));
           
        } else {
            console.error('html2pdf is not available');
        }
    } catch (error) {
        console.error('Error in handleConvertHtmlToPdf:', error);
    }
};
  
  
  
  const today = new Date();

// Format the date to 'YYYY-MM-DD' using the user's locale
const formattedDate = new Intl.DateTimeFormat('default', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
}).format(today);
 
  return (
    <Modal size="xl" isOpen={show} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>
      {t('Modify Profile Information')}
      </ModalHeader>
      <ModalBody>
      {show && (
       <div className='wrapperPdf'id='wrapperPdf' ref={printContentRef}>
       <Row style={{ maxWidth: '575px', margin: 'auto', textAlign: 'center' }}>
    <Col xs="7" className="d-flex flex-column justify-content-center">
      <div className="personal-contact-container">
        <h3>{t('Personal Details Form')}</h3>
        <p>
        {t('Please fill out this form completely on your own. Remember to include your address and ID if you`re under 23.')} 
        </p>
      </div>
    </Col>

    <Col xs="5" className="d-flex justify-content-center align-items-center">
    
      <img width="80"src={images} alt="" />
    </Col>
  </Row>
        <Row>
        <Col xs="12">
        <div className="personal-contact-container">
      <div className="table-container_cv">
      <div className="table-row" style={{border:'none'}}>
           <div className="table-cell"style={{ background:'#03be07'}}><strong>{t('Personal Details')}</strong></div>
          <div className="table-cell"style={{background:'white', border:'none'}}></div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('First Name')}:</strong></div>
          <div className="table-cell">{clientData.frist_name}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Last Name')}:</strong></div>
          <div className="table-cell">{clientData.last_name}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Gender')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="radio" checked={clientData.gender === 'man'} name="gender" value="male" /> {t('Male')}
            </label>
            <label>
              <input type="radio" checked={clientData.gender === 'woman'} name="gender" value="female" /> {t('Female')}
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Birthday')}:</strong></div>
          <div className="table-cell">
          <div className="inline-container">
          <span className="spanright" style={{ width: '100%',justifyContent:'center' }}>
            <a href="" className="partone" style={{ width: '30%' }}>{clientData.nationality}</a>
            <div className="table-cell tes" style={{width: '40%',background:'#e2f0d9',color:'black',justifyContent:'center'}}><strong>{t('ID-type')}:</strong></div>
            <a href="" className="partthree" style={{ width: '30%' }}>{clientData.proof_of_identity}</a>
          </span>
        </div>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong> {t('Place of birth')}:</strong></div>
          <div className="table-cell">
          <div className="inline-container">
          <span className="spanright" style={{ width: '100%',justifyContent:'center' }}>
            <a href="" className="partone" style={{ width: '30%' }}>{clientData.brith_place}</a>
            <div className="table-cell tes" style={{width: '40%',background:'#e2f0d9',color:'black',justifyContent:'center'}}><strong>{t('Document no.')}:</strong></div>
            <a href="" className="partthree" style={{ width: '30%' }}>{clientData.identity_number}</a>
          </span>
        </div>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Marital status.')} :</strong></div>
          <div className="table-cell">
          <div className="inline-container">
          <span className="spanright" style={{ width: '100%',justifyContent:'center' }}>
            {/* <a href="" className="partone" style={{ width: '30%' }}></a> */}
            <input type="text" className="partone"  style={{ width: '30%' }} />
            <div className="table-cell tes" style={{width: '40%',background:'#e2f0d9',color:'black',justifyContent:'center'}}><strong>{t('Date of issue.')}:</strong></div>
            <a href="" className="partthree" style={{ width: '30%' }}>{clientData.proof_bigin_date}</a>
          </span>
        </div>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong> {t('Citizen Service Number')}:</strong></div>
          <div className="table-cell">
          <div className="inline-container">
          <span className="spanright" style={{ width: '100%',justifyContent:'center' }}>
            <a href="" className="partone" style={{ width: '30%' }}>{clientData.bsn_n}</a>
            <div className="table-cell tes" style={{width: '40%',background:'#e2f0d9',color:'black',justifyContent:'center'}}><strong>{t('Valid until')}:</strong></div>
            <a href="" className="partthree" style={{ width: '30%' }}>{clientData.proof_expiration_date}</a>
          </span>
        </div>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Bank account (IBAN)')}:</strong></div>
          <div className="table-cell">{clientData.bank_account}</div>
        </div>
      </div>
      <div className="table-container_cv">
      <div className="table-row" style={{border:'none'}}>
          <div className="table-cell"style={{ border:'none',background:'#03be07'}}><strong>{t('ADDRESS DETAILS')}</strong></div>
          
        <div className="table-cell"style={{background:'white', border:'none'}}></div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Address')}:</strong></div>
          <div className="table-cell">{clientData.address}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Postcode')}:</strong></div>
          <div className="table-cell">{clientData.zip}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Place of residence')}:</strong></div>
          <div className="table-cell">{clientData.city}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Country')}:</strong></div>
          <div className="table-cell">{clientData.country}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Phone number')}:</strong></div>
          <div className="table-cell">{clientData.country}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Driving license')}:</strong></div>
          <div className="table-cell">{clientData.license_type}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Email')}:</strong></div>
          <div className="table-cell">{clientData.email}</div>
        </div>
      </div>

      <div className="table-container_cv">
      <div className="table-row" style={{border:'none'}}>
          <div className="table-cell"style={{ border:'none',background:'#03be07'}}><strong>{t('EMERGENCY CONTACT')}</strong></div>
          
        <div className="table-cell"style={{background:'white', border:'none'}}></div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Full name')}:</strong></div>
          <div className="table-cell">{clientData.name_em}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Phone number')}:</strong></div>
          <div className="table-cell">{clientData.emergency_nummer}</div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Relation')}:</strong></div>
          <div className="table-cell">{clientData.relation}</div>
        </div>
      </div>

      <div className="table-container_cv">
      <div className="table-row" >
          <div className="table-cell"style={{ background:'#03be07',flex:'2 1'}}><strong>{t('PAYROLL TAX EMPLOYMENT CONTRACT')}</strong></div>
          
          <div className="table-cell">
            <label>
            {t('Yes')} 
            </label>
            <label>
            {t('No')} 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1', background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Applying payroll tax credit')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="checkbox" name="Yes" value="Yes" /> 
            </label>
            <label>
              <input type="checkbox" name="No" value="No" /> 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Are you receiving any benefits?')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="checkbox" name="Yes" value="Yes" /> 
            </label>
            <label>
              <input type="checkbox" name="No" value="No" /> 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Have you ever received benefits in the past?')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="checkbox" name="Yes" value="Yes" /> 
            </label>
            <label>
              <input type="checkbox" name="No" value="No" /> 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('If so, what period?')}:</strong></div>
          <div className="table-cell">
          <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Type of benefit')}:</strong></div>
          <div className="table-cell">
          <input type="text" style={{flex:'1'}} />
         
          </div>
        </div>
      </div>

      <div className="table-container_cv">
      <div className="table-row">
          <div className="table-cell"style={{ background:'#03be07',flex:'5 1'}}><strong>{t('EDUCATION')}</strong></div>
          
          <div className="table-cell"style={{ background:'#03be07'}}>
          
            <label>
            {t('Diploma')}
            </label>
          </div>
        </div>
      <div className="table-row">
        <div className="table-cell" style={{ background:'#bfbfbf',flex:'5 1',justifyContent:'space-around'}}>
          <label><strong>{t('Education / Level')}</strong></label>
          <label><strong> {t('Field of Study')}</strong></label>
          <label><strong> {t('Period')}</strong></label>
        </div>
        <div className="table-cell">
          <label>{t('Yes')}</label>
          <label>{t('No')}</label>
        </div>
      </div>

      {[...Array(3)].map((_, rowIndex) => (
        <div className="table-row" key={rowIndex}>
          <div className="table-cell input-group" style={{ gap:'0px', background:'#bfbfbf',flex:'5 1'}}>
            {[...Array(3)].map((_, inputIndex) => (
              <label key={inputIndex} className="input-label">
                <input type="text" className="input-field"  />
              </label>
            ))}
          </div>
          <div className="table-cell" >
            {[...Array(2)].map((_, checkboxIndex) => (
              <label key={checkboxIndex}>
                <input type="checkbox" />
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>

      <div className="table-container_cv">
      <div className="table-row" >
          <div className="table-cell"style={{ background:'#03be07',flex:'1'}}><strong>{t('Work Experience')}</strong></div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Company Name')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Position')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Start Date')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Reason for Leaving')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Description of Responsibilities')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Company Name 2')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Position')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Start Date')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Reason for Leaving')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>

        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Description of Responsibilities')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
      </div>

      <div className="table-container_cv">
      <div className="table-row" style={{border:'none'}}>
          <div className="table-cell"style={{border:'none', background:'#03be07'}}><strong>{t('References')}</strong></div>
          
        <div className="table-cell"style={{background:'white', border:'none'}}></div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Name of employer')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Reference position')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Phone number')}:</strong></div>
          <div className="table-cell">
            <input type="text" style={{flex:'1'}} />
          </div>
        </div>

      </div>

      <div className="table-container_cv">
      <div className="table-row">
          <div className="table-cell"style={{ background:'#03be07',flex:'2 1'}}><strong>{t('OVERIGE VRAGEN')}</strong></div>
          
          <div className="table-cell">
            <label>
            {t('Yes')}
            </label>
            <label>
            {t('No')}
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1', background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Have you previously applied with us?')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="checkbox" name="Yes" value="Yes" /> 
            </label>
            <label>
              <input type="checkbox" name="No" value="No" /> 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Have you previously worked for our organization?')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="checkbox" name="Yes" value="Yes" /> 
            </label>
            <label>
              <input type="checkbox" name="No" value="No" /> 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('If yes, where and in what capacity?')}:</strong></div>
          <div className="table-cell">
          <input type="text" style={{flex:'1'}} />
           
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Have you ever had contact with the police?')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="checkbox" name="Yes" value="Yes" /> 
            </label>
            <label>
              <input type="checkbox" name="No" value="No" /> 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('If yes, for what purpose?')}:</strong></div>
          <div className="table-cell">
          <input type="text" style={{flex:'1'}} />
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('Werken er bekenden van u bij onze organsatie?')}:</strong></div>
          <div className="table-cell">
            <label>
              <input type="checkbox" name="Yes" value="Yes" /> 
            </label>
            <label>
              <input type="checkbox" name="No" value="No" /> 
            </label>
          </div>
        </div>
        <div className="table-row">
          <div className="table-cell" style={{flex:'2 1',background:'#e2f0d9',color:'black',justifyContent:'flex-end'}}><strong>{t('If yes, where and what is your relationship with')}:</strong></div>
          <div className="table-cell">
          <input type="text" style={{flex:'1'}} />
          </div>
        </div>
      </div>
    </div>
           </Col>
           <Col xs="12" style={{maxWidth:'600px' ,margin:'auto'}}>
        <div>
        
        <div style={{marginTop:'30px',fontStyle:'italic'}}>
         <p>{t('* Je hebt geen bezwaar tegen het onderzoeken van de gegevens en dat wij met jouw vorige werkgever(s) contact opnemen voor meer informatie.')}</p>
         </div>
         <div className="lineli" style={{marginTop:'30px',border:"2px solid" ,padding:'11px',fontWeight:'500'}}>
          <p className="spanleft"  >

          {t('Met ondertekenen van dit formulier verklaar ik bovenstaande naar waarheid te hebben ingevuld en geef ik toestemming om mijn persoonsgegevens te gebruiken voor de invoer van mijn gegevens met betrekking tot mijn aankomend dienstverband, aanmelding pensioenfonds en arbodienst. Tevens geef ik toestemming om mijn e-mailadres voor interne doeleinden te gebruiken.')}    
                                
          </p>
                            
        </div>

        <div className="table-container_cv">
      <div className="table-row" style={{marginTop:'30px',border:'none'}}>
          <div className="table-cell"style={{background:'#00b050',flex:'2 1'}}><strong>{t('Signature')}</strong></div>
          <div className="table-cell" style={{background:'white',border:'none'}}>
          </div>
        </div>
        

        <div className="table-row" style={{marginTop:'10px',border:'none'}}>
          <div className="table-cell"style={{background:'white',flex:'2 1',border:'none'}}>
          <span
          className="spanleft"
          style={{

            margin:'2px 36px',
            backgroundColor: '#ffffff',
            border: 'none',
            textAlign: 'right'
          }}
        >
        {t('Date')}:
        </span>
        <span
          className="spanleft"
          style={{
            backgroundColor: '#ffffff',
            borderBottom: '2px solid',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            textAlign: 'center'
          }}
        >
         {formattedDate}  
        </span>
          </div>
          <div className="table-cell" style={{background:'white',border:'none'}}>
        
          </div>
        </div>
        
      </div>


      <div className="table-container_cv">
      <div className="table-row" style={{border:'none'}}>
          <div className="table-cell"style={{background:'#e2f0d9',flex:'2 1',justifyContent:'space-around'}}>
          <label >
          {t('Name')}  
            </label>
            <label>
            {t('Signature')} 
            </label>
          </div>
          <div className="table-cell" style={{background:'white',border:'none'}}>
          
          </div>
        </div>
        

        <div className="table-row" style={{marginTop:'10px',border:'none'}}>
        <div className="table-cell"style={{flex:'2 1'}}>
        <span
          className="spanleft"
          style={{
            width: '50%',
            backgroundColor: '#ffffff',
            border: '2px solid',
            textAlign: 'left',
            padding: '15px'
          }}
        >
          &nbsp;
        </span>
        <span
          className="spanleft"
          style={{
            width: '50%',
            backgroundColor: '#ffffff',
            border: '2px solid',
            textAlign: 'left',
            padding: '15px',
            borderLeft: 'none'
          }}
        >
          &nbsp;
        </span>
          </div>
          <div className="table-cell" style={{background:'white',border:'none'}}>
        
          </div>
        </div>

        
      </div>


        </div>
           </Col>    
        </Row>

        <Row>
      

  
        </Row>
       </div>
       

      )}
      </ModalBody>
      <ModalFooter>
      <div
      style={{ display:'flex',justifyContent:'flex-end',gap:'10px'}}
       >
      <Button color="secondary"
className="btn  css-1bl3roz"
style={{ background: '#ed4167', border: 'none' }}
      onClick={handleClose}>
          {t('Close')}
        </Button>
        <Button
          id="pdfButton1"
          type="button"
          className="btn  css-1bl3roz"
          style={{ background: '#0463b9', border: 'none' }}
          disabled={!scriptsLoaded}
          onClick={handleConvertHtmlToPdf}
        >
         {t('Download')}  
        </Button>
      </div>
      </ModalFooter>
    </Modal>
  );
};

export default withTranslation()(ModalComponent);
