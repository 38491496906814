import React, { useEffect, useState } from "react";
import { Row, Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,Col,CardText, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Modal } from "reactstrap";
  import classnames from "classnames";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { registerUser,loginUser } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import PaymentForm  from "components/Payment/PaymentForm";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";

import {
  setData,
} from "../../common/data";
import { useNavigate ,useParams,useHistory } from 'react-router-dom';
  const Register =  (props) => {
    const { t } = props;

  //meta title
  useEffect(() => {
    document.title = t("Register for Free - Get 30 Days of Unlimited Access to Shiftspec");
  }, [t]);

  const [modal_fullscreen, setmodal_fullscreen] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [isFormValid, setIsFormValid] = useState(false);

  const { plane } = useParams();
  const planData = {
    starter: {
      price: 3.8,
      perUserText: `€3.8 ${t('per user')}`,
      displayCompanyVat: false,
    },
    professional: {
      price: 4.7,
      perUserText: `€4.7 ${t('per user')}`,
      displayCompanyVat: true,
    },
    enterprise: {
      price: 7.9,
      perUserText: `€7.9 ${t('per user')}`,
      displayCompanyVat: true,
    }
  };
  const [showOptions, setShowOptions] = useState(false); 
  const [selectedOption, setSelectedOption] = useState('annual');

  const [showUserOptions, setShowUserOptions] = useState(false); 
  const [selectedOptionUser, setSelectedOptionUser] = useState('1');
  const [numberOfUsers, setNumberOfUsers] = useState(1); // Default number of users
  const [subtotal, setSubtotal] = useState('');
  const [registerData, setRegisteData] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const divStyle = {
    minWidth: '119.992px',
    position: 'fixed',
    left: '11px',
    top: '0px',
    transform: 'translate(1027.56px, 136.538px)',
    willChange: 'transform'
  };
 

  const handleOptionSelect = (option, event) => {
    event.preventDefault(); // Error: event is undefined
    setSelectedOption(option);
    setShowOptions(false);
  };
  const toggleOptions = (event) => {
    event.preventDefault();
    setShowOptions(!showOptions);
  };
  const optionsList = (
    <div className="css-khkg76" style={divStyle}>
      {/* Example options */}
      <div className="css-iy41r8">
      <ul role="presentation" style={{listStyle:'none'}}>
        <li
          data-value="annual"
          className="css-x80nqe"
          onClick={(e) => handleOptionSelect('annual', e)}
          aria-selected={selectedOption === 'annual' ? 'true' : 'false'}
          role="option"
          tabIndex="0"
        >
          <div className="css-rzwwa2">
            
            <span>{t('Annual')}</span>
          </div>
        </li>
        <li
          data-value="monthly"
          className="css-x80nqe"
          onClick={(e) => handleOptionSelect('monthly', e)}
          aria-selected={selectedOption === 'monthly' ? 'true' : 'false'}
          role="option"
          tabIndex="0"
        >
          <div className="css-1kdb0ci">
            <span>{t('Monthly')}</span>
          </div>
        </li>
      </ul>
    </div>
    </div>
  );


  const handleOptionSelectUser = (option, event) => {
    event.preventDefault(); // Prevents form submission if inside a form
    setSelectedOptionUser(option);
    setShowUserOptions(false); // Hide options after selection
  };
  const toggleOptionsUser = () => {
    setShowUserOptions(!showUserOptions); // Toggle visibility of options
  };
  const optionsListUser = (
    <div className="css-khkg76" style={divStyle}>
      <div className="css-iy41r8">
        <ul role="presentation" style={{ listStyle: 'none' }}>
          {[...Array(100).keys()].map((num) => (
            <li
              key={num + 1}
              data-value={`user-${num + 1}`}
              className="css-x80nqe"
              onClick={(e) => handleOptionSelectUser(`${num + 1}`, e)}
              aria-selected={selectedOptionUser === `${num + 1}` ? 'true' : 'false'}
              role="option"
              tabIndex="0"
            >
              <div className="css-rzwwa2">
                <span>{num + 1}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );



  let title;
  if (plane === 'starter') {
    title = t('Shiftspec Starter');
  } else if (plane === 'professional') {
    title = t('Shiftspec Professional');
  } else if (plane === 'enterprise') {
    title = t('Shiftspec Enterprise');
  } else {
    title = t('Shiftspec'); // Default or fallback
  }

  const basePrice = planData[plane].price;

  const monthlyPrice = basePrice * selectedOptionUser;

  const annualPrice = monthlyPrice * 12;

  const totalPrice = selectedOption === 'monthly' ? monthlyPrice : annualPrice;

const formattedPrice = totalPrice.toFixed(2);

const displayPeriod = selectedOption === 'monthly' ? 'month' : 'year';
// Construct the output string
const priceOutput = `€${formattedPrice} / ${t(displayPeriod)}`;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      fristname: "",
      lastname: "",
      phone: "",
      country: "",
      city: "",
      address: "",
      password: "",
      plan_name: plane,
      plan_type: selectedOption,
      number_of_users: selectedOptionUser,
      plan_status: "active",
      vatnr: "",
      company: "",
      language: "en",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      valuta: "EUR",
    },

  
    validationSchema: Yup.object({
      email: Yup.string().required(t('Please Enter Your Email')),
      fristname: Yup.string().required(t('Please Enter Your fristname')),
      lastname: Yup.string().required(t('Please Enter Your lastname')),
      password: Yup.string()
      .required(t('Please Enter Your Password'))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
        t('Password must be at least 8 characters long, include at least one letter, and one special character')
      ),
      phone: planData[plane].displayCompanyVat ? Yup.string().required(t("Please Enter Your phone Number")) : Yup.string(),
      country: planData[plane].displayCompanyVat ? Yup.string().required(t("Please Enter Your country Name")) : Yup.string(),
      city: planData[plane].displayCompanyVat ? Yup.string().required(t("Please Enter Your city Name")) : Yup.string(),
      address: planData[plane].displayCompanyVat ? Yup.string().required(t("Please Enter Your address")) : Yup.string(),
      company: planData[plane].displayCompanyVat ? Yup.string().required(t("Please Enter Your Company Name")) : Yup.string(),
      vatnr: planData[plane].displayCompanyVat ? Yup.string().required(t("Please Enter Your Vat Number")) : Yup.string(),
      
   
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
     
      setRegisteData(values)
      handleNextStep()
    },
    
    
    validate: (values) => {
      const errors = {};
      const isValid = Object.keys(validation.errors).length === 0 && validation.touched;
      setIsFormValid(isValid);
      return errors;
    }
  });



  const toggleTab = (tab) => {
    if (validation.isValid && validation.dirty) {
      setActiveTab(tab);
    }
  };
  const handleNextStep = () => {
    if (validation.isValid && validation.dirty) {
      setActiveTab("2");
    }
  };


 
    const checkPasswordStrength = (password) => {
      return {
        length: password.length >= 8,
        hasLetter: /[a-zA-Z]/.test(password),
        hasSpecialChar: /[@#$%^&*(),.?":{}|<>]/.test(password),
      };
    };

    const passwordChecks = checkPasswordStrength(validation.values.password || "");
  return (
    <React.Fragment>
      
      <Modal
        size="xl"
        isOpen={modal_fullscreen}
        toggle={() => {
          tog_fullscreen();
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header">
        
          <div className="css-8rgqp2-HEADER_BAR_LEFT">
            <div className="css-cskqfi-EmptyHeader">
              <a href="/" className="css-51fonb-HEADER_APP_DESCRIPTION_NON_PROD">
                <img width="114" height="24" src={setData[0].website_logo || 'https://shiftspec.s3.eu-north-1.amazonaws.com/logo.png'} alt="Shiftspec" />
              </a>
            </div>
           
          </div>
          <div className="center-content" style={{flex:1,textAlign:'center'}}>
          <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                    <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggleTab("1")}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">{t('Account')}</span>
          </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
            // style={{ cursor: isFormValid ? "pointer" : "not-allowed" }}
            // className={classnames({ active: activeTab === "2" })}
            // onClick={() => toggleTab("2")}
            // disabled={!isFormValid}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user"></i>
            </span>
            <span className="d-none d-sm-block">{t('Payment')}</span>
          </NavLink>
                    </NavItem>
                
                  </Nav>
  </div>
  <div className="css-8rgqp2-HEADER_BAR_RIGHT" style={{position:'relative',right:0}}>

  </div>
        </div>
       
      
        <div className="modal-body">
        <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                    <Row className="justify-content-left">
            <Col md={8} lg={6} xl={7}>
            <section className="css-1nd7z9v">
            <h1 className="css-7glort">{t('Sign up to Shiftspec')}</h1>
            <div className="css-1x7nnnc">
            <main className="css-1bg3kgs">
    <section>
    <fieldset className="css-0">
    <legend className="css-2bow0d">{t('Add account details')}</legend>
    <form onSubmit={validation.handleSubmit}>
  

<div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="email" 
     name="email" 
     maxLength="50" 
     autoComplete="email"
     placeholder={t('Enter email')}
      id="email"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.email || ""}
        invalid={validation.touched.email && !!validation.errors.email}
        />
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    
    
    <div className="css-g9uw22"></div>
    {validation.touched.email && validation.errors.email && (
          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
        )}
    </div>
    </span>
    </div>
    </div>


    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="fristname" 
     maxLength="50" 
     autoComplete="fristname"
     placeholder={t('Enter Fristname')}
      id="fristname"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.fristname || ""}
        invalid={validation.touched.fristname && !!validation.errors.fristname}
        />
     
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
  
    <div className="css-g9uw22"></div>
    {validation.touched.fristname && validation.errors.fristname && (
          <FormFeedback type="invalid">{validation.errors.fristname}</FormFeedback>
       )}
    </div>
    </span>
    </div>
    </div>

    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="lastname" 
     maxLength="50" 
     autoComplete="lastname"
     placeholder= {t('Enter Lastname')}
      id="lastname"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.lastname || ""}
        invalid={validation.touched.lastname && !!validation.errors.lastname}
        />
   
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
  
    <div className="css-g9uw22"></div>
    {validation.touched.lastname && validation.errors.lastname && (
          <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
        )}
    </div>
    </span>
    </div>
    </div>
    {planData[plane].displayCompanyVat && (
        <>
    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="company" 
     maxLength="50" 
     autoComplete="company"
     placeholder= {t('Enter Company Name')}
      id="company"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.company || ""}
        invalid={validation.touched.company && !!validation.errors.company}
      />
     
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>

    <div className="css-g9uw22"></div>
    {validation.touched.company && validation.errors.company && (
        <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    </div>


    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="vatnr" 
     maxLength="50" 
     autoComplete="vatnr"
     placeholder={t('Enter VAT Number')}
      id="vatnr"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.vatnr || ""}
        invalid={validation.touched.vatnr && !!validation.errors.vatnr}
      />
     
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    <div className="css-g9uw22"></div>

    {validation.touched.vatnr && validation.errors.vatnr && (
        <FormFeedback type="invalid">{validation.errors.vatnr}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    </div>


       <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="number" 
     name="phone" 
     maxLength="50" 
     autoComplete="phone"
     placeholder={t('Enter Phone Numbe')}
      id="phone"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.phone || ""}
        invalid={validation.touched.phone && !!validation.errors.phone}
      />
    
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>

    <div className="css-g9uw22"></div>
    {validation.touched.phone && validation.errors.phone && (
        <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    </div>

    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="country" 
     maxLength="50" 
     autoComplete="country"
     placeholder={t('Enter Country Name')}
      id="country"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.country || ""}
        invalid={validation.touched.country && !!validation.errors.country}
      />
    
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    <div className="css-g9uw22"></div>
    {validation.touched.country && validation.errors.country && (
        <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    </div>


    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="city" 
     maxLength="50" 
     autoComplete="city"
     placeholder={t('Enter City Name')}
      id="city"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.city || ""}
          invalid={validation.touched.city && !!validation.errors.city}
        />
       
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    <div className="css-g9uw22"></div>
    {validation.touched.city && validation.errors.city && (
          <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
        )}
    </div>
    </span>
    </div>
    </div>

    <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="text" 
     name="address" 
     maxLength="50" 
     autoComplete="address"
     placeholder={t('Enter Address')}
      id="address"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.address || ""}
        invalid={validation.touched.address && !!validation.errors.address}
      />
     
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    <div className="css-g9uw22"></div>
    {validation.touched.address && validation.errors.address && (
        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
      )}
    </div>
    </span>
    </div>
    </div>

    
  
    </>
      )}
      <div className="css-ho7zc7">
    <div>
    <span className="css-15ylkfs">
    <Input
     type="password" 
     name="password" 
     maxLength="50" 
     autoComplete="password"
     placeholder={t('Enter Password')}
      id="password"
        className="fs-mask css-xv1qun" 
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.password || ""}
        invalid={validation.touched.password && !!validation.errors.password}
      />
     
    <div className="dxp-field-label css-1bzl99f" data-size="lg">
    <div className="css-5q8i94"></div>
    <div className="css-g9uw22"></div>
    {validation.touched.password && validation.errors.password && (
        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
      )}
    </div>
    
    </span>
      {/* Add password format tips */}
      <div className="password-tips mt-2">
          <ul className="css-vk4aun text-sm relative" style={{ left: "10px", fontSize: "15px" }}>
            <li
              style={{
                color: passwordChecks.length
                  ? "green"
                  : validation.values.password
                  ? "red"
                  : "gray",
              }}
            >
              {t('• Must be at least 8 characters long')}
            </li>
            <li
              style={{
                color: passwordChecks.hasLetter
                  ? "green"
                  : validation.values.password
                  ? "red"
                  : "gray",
              }}
            >
             {t('• Must include at least one letter')} 
            </li>
            <li
              style={{
                color: passwordChecks.hasSpecialChar
                  ? "green"
                  : validation.values.password
                  ? "red"
                  : "gray",
              }}
            >
              {t('• Must include at least one special character (e.g., @, #, $, etc.)')}
            </li>
          </ul>
        </div>
    </div>
    </div>
  
        <button title="Purchase"  onClick={handleNextStep}  role="button" type="submit"   className="css-pjhtp2">
    <span className="css-13wylk3">{t('Purchase')}</span>
</button>
      </form>
      </fieldset>

      <div className="mt-5 text-center">
                <p>
                {t('Already have an account ?')}{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    {t('Login')}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {t('Shiftspec. Crafted with')}{" "}
                  <i className="mdi mdi-heart text-danger" />{t('by Bluewhite')}
                </p>
              </div>
    </section>
              </main>
              </div>
             </section>
            </Col>
            <Col md={12} lg={12} xl={5}>
            <aside className="css-15ftrm">
<section data-id="cart" className="css-1hmz7kv">
<div className="css-1163voy">
<div className="css-1933b8g">
<div className="css-1flj9lk">
<div className="css-ozibme">{t('Billed')} {selectedOption}</div>
<div className="css-2rpr5t"> {title}</div>
</div>
</div>
</div>
<div className="css-tryvo5">
<label htmlFor="billing-options" className="css-f3vz0n">{t('Billing options')}</label>
<span className="css-nyjqug">
<button className="css-1u8e4p3" data-id="billing-options" type="button"
onClick={(e) => toggleOptions( e)}
>
<span className="css-cp3ir1">{selectedOption}</span>
<span aria-hidden="true" className="css-kfo8f7">
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" role="img" aria-hidden="true" className="css-1gp9wld">
<path d="m19 9.476-6.257 6.218a1.055 1.055 0 0 1-1.486 0L5 9.476 6.485 8 12 13.48 17.515 8 19 9.476z" fill="currentColor"></path>
</svg>
</span>
</button>
{showOptions && optionsList}

</span>
</div>
<div className="css-1as96tz"><div>
<label htmlFor="product-quantity" className="css-f3vz0n">{t('Users')}</label>
<div data-id="product-quantity-sublabel" className="css-o38vei">
<span className="css-mmvz9h">{planData[plane].perUserText}</span>
</div>
</div>
<span className="css-nyjqug">
<button
        className="css-1u8e4p3"
        type="button"
        onClick={toggleOptionsUser}
        aria-haspopup="listbox"
        aria-expanded={showUserOptions ? 'true' : 'false'}
      >
<span className="css-cp3ir1">{selectedOptionUser}</span>
<span aria-hidden="true" className="css-kfo8f7">
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" role="img" aria-hidden="true" className="css-1gp9wld"><path d="m19 9.476-6.257 6.218a1.055 1.055 0 0 1-1.486 0L5 9.476 6.485 8 12 13.48 17.515 8 19 9.476z" fill="currentColor"></path>
</svg>
</span>
</button>
{showUserOptions && optionsListUser}
</span>
</div>
<div className="css-i13758"><p>{t('For more than 50 users, call')}
<a
      href={`tel:${setData[0].phone_number}`}
      target="_blank"
      rel="noopener noreferrer"
      className="css-tc8190"
     
    >
      {setData[0].phone_number}
    </a>
   </p>
</div>
<div className="css-kh3xa">
  <div className="css-bcffy2">
    <div className="css-f3vz0n">{t('Promo code')}</div>
    <button title="Add" aria-label="Add" role="button" data-id="promo-add" data-context="checkout-order-summary" data-action="add promo code" className="css-3jh86a" aria-disabled="false"><span className="css-13wylk3">Add</span></button>
    </div>
    </div>
    <div className="css-1mjthai">
      <div className="css-1eetuzj">
        <span className="css-f3vz0n">{t('Subtotal')}</span>
        <span data-id="subtotal-value" className="css-f3vz0n">
  {priceOutput}
</span>
</div>
</div>
<div className="css-taf7pb">
  <span className="css-u38nm9">{t('Due today')}</span>
  <div className="css-2qga7i">

  {priceOutput}
  </div>
</div>
</section>
</aside>
            </Col>
          </Row>
                    </TabPane>
                    <TabPane tabId="2">
                    <Row className="justify-content-left">
            <Col md={8} lg={6} xl={7}>
            <PaymentForm registerData={registerData} />
            </Col>
            <Col md={12} lg={12} xl={5}>
            <aside className="css-15ftrm">
<section data-id="cart" className="css-1hmz7kv">
<div className="css-1163voy">
<div className="css-1933b8g">
<div className="css-1flj9lk">
<div className="css-ozibme">{t('Billed')} {selectedOption}</div>
<div className="css-2rpr5t"> {title}</div>
</div>
</div>
</div>
<div className="css-tryvo5">
<label htmlFor="billing-options" className="css-f3vz0n">{t('Billing options')}</label>
<span className="css-nyjqug">
<button className="css-1u8e4p3" data-id="billing-options" type="button"  onClick={toggleOptions}>
<span className="css-cp3ir1">{selectedOption}</span>
<span aria-hidden="true" className="css-kfo8f7">
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" role="img" aria-hidden="true" className="css-1gp9wld">
<path d="m19 9.476-6.257 6.218a1.055 1.055 0 0 1-1.486 0L5 9.476 6.485 8 12 13.48 17.515 8 19 9.476z" fill="currentColor"></path>
</svg>
</span>
</button>
{showOptions && optionsList}

</span>
</div>
<div className="css-1as96tz"><div>
<label htmlFor="product-quantity" className="css-f3vz0n">{t('Users')}</label>
<div data-id="product-quantity-sublabel" className="css-o38vei">
<span className="css-mmvz9h">{planData[plane].perUserText}</span>
</div>
</div>
<span className="css-nyjqug">
<button
        className="css-1u8e4p3"
        type="button"
        onClick={toggleOptionsUser}
        aria-haspopup="listbox"
        aria-expanded={showUserOptions ? 'true' : 'false'}
      >
<span className="css-cp3ir1">{selectedOptionUser}</span>
<span aria-hidden="true" className="css-kfo8f7">
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" role="img" aria-hidden="true" className="css-1gp9wld"><path d="m19 9.476-6.257 6.218a1.055 1.055 0 0 1-1.486 0L5 9.476 6.485 8 12 13.48 17.515 8 19 9.476z" fill="currentColor"></path>
</svg>
</span>
</button>
{showUserOptions && optionsListUser}
</span>
</div>
<div className="css-i13758"><p>{t('For more than 50 users, call')}
<a
      href={`tel:${setData[0].phone_number}`}
      target="_blank"
      rel="noopener noreferrer"
      className="css-tc8190"
     
    >
      {setData[0].phone_number}
    </a>
   </p>
</div>
<div className="css-kh3xa">
  <div className="css-bcffy2">
    <div className="css-f3vz0n">{t('Promo code')}</div>
    <button title="Add" aria-label="Add" role="button" data-id="promo-add" data-context="checkout-order-summary" data-action="add promo code" className="css-3jh86a" aria-disabled="false"><span className="css-13wylk3">Add</span></button>
    </div>
    </div>
    <div className="css-1mjthai">
      <div className="css-1eetuzj">
        <span className="css-f3vz0n">{t('Subtotal')}</span>
        <span data-id="subtotal-value" className="css-f3vz0n">
        {priceOutput}
</span>
</div>
</div>
<div className="css-taf7pb">
  <span className="css-u38nm9">{t('Due today')}</span>
  <div className="css-2qga7i">

  {priceOutput}
  </div>
</div>
</section>
</aside>
            </Col>
          </Row>
                    </TabPane>
         
                  </TabContent>
          
       
        </div>
      </Modal>
    </React.Fragment>
  );
};


Register.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(Register));