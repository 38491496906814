export const GET_VEHICLES = "GET_VEHICLES"
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS"
export const GET_VEHICLES_FAIL = "GET_VEHICLES_FAIL"

//select folder
export const POST_VEHICLES = "POST_VEHICLES"
export const POST_VEHICLES_SUCCESS = "POST_VEHICLES_SUCCESS"
export const POST_VEHICLES_FAIL = "POST_VEHICLES_FAIL"

//Selected mail
export const PUT_VEHICLES = "PUT_VEHICLES"
export const PUT_VEHICLES_SUCCESS = "PUT_VEHICLES_SUCCESS"
export const PUT_VEHICLES_FAIL = "PUT_VEHICLES_FAIL"

//set folder on selected mails
export const DELETE_VEHICLES = "DELETE_VEHICLES"
export const DELETE_VEHICLES_SUCCESS = "DELETE_VEHICLES_SUCCESS"
export const DELETE_VEHICLES_FAIL = "DELETE_VEHICLES_FAIL"


export const GET_VEHICLES_DOC = "GET_VEHICLES_DOC"
export const GET_VEHICLES_DOC_SUCCESS = "GET_VEHICLES_DOC_SUCCESS"
export const GET_VEHICLES_DOC_FAIL = "GET_VEHICLES_DOC_FAIL"

//select folder
export const POST_VEHICLES_DOC = "POST_VEHICLES_DOC"
export const POST_VEHICLES_DOC_SUCCESS = "POST_VEHICLES_DOC_SUCCESS"
export const POST_VEHICLES_DOC_FAIL = "POST_VEHICLES_DOC_FAIL"

//Selected mail
export const PUT_VEHICLES_DOC = "PUT_VEHICLES_DOC"
export const PUT_VEHICLES_DOC_SUCCESS = "PUT_VEHICLES_DOC_SUCCESS"
export const PUT_VEHICLES_DOC_FAIL = "PUT_VEHICLES_DOC_FAIL"

//set folder on selected mails
export const DELETE_VEHICLES_DOC = "DELETE_VEHICLES_DOC"
export const DELETE_VEHICLES_DOC_SUCCESS = "DELETE_VEHICLES_DOC_SUCCESS"
export const DELETE_VEHICLES_DOC_FAIL = "DELETE_VEHICLES_DOC_FAIL"

