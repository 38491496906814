import React, { useRef, useState, useEffect,useMemo  } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useUserPermissions } from '../../components/Permissions/UserPermissionsContext';
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardTitle,
} from "reactstrap";
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import {
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  postContractType,
  postContract,
  postLinks,
  getAbsenceType,
  getContractEmployer,
  postAbsence,
} from "store/actions";
import Select from "react-select"
import { format, addDays } from "date-fns";
//redux
import { useSelector, useDispatch } from "react-redux";
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};
const CollaboratirList = (props) => {
  
  const dispatch = useDispatch();
  const { t,i18n} = props;
  const navigate = useNavigate();
  const { permissions, loading } = useUserPermissions();

  const {  template_content = [], editor, plan_name,teamleider } = permissions || {};
  const isEditorAllowed = editor === 1;
  const [toast, setToast] = useState(false);
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [language, setLanguage] = useState(user.language);
  const [timezone, setTimezone] = useState(user.timezone);
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const [valuta, setValuta] = useState(user.valuta);
  const {
    client,
    xlsid,
    contractdata,
    postDeps,
    postTeams,
    postContractTypes,
    link,
    getabsencetype,
    contractemployer,
    postabsence,
    error
  } = useSelector(state => ({
    getabsencetype: state.getAbsenceData.getabsencetype,
    postabsence: state.getAbsenceData.postabsence,
    client: state.getClientData.client,
    xlsid: state.getClientData.xlsid,
    contractdata: state.getClientData.contractdata,
    postDeps: state.getClientData.postDeps,
    postTeams: state.getClientData.postTeams,
    postContractTypes: state.getClientData.postContractTypes,
    link: state.getClientData.link,
    contractemployer: state.getShiftData.contractemployer,
    error: state.getClientData.error,
  }));
  useEffect(() => {
    document.title = t("ShiftSpec - Efficient Collaborator Management | Streamline Workforce Collaboration & Productivity");
  }, [t]);

  //meta title xlsid
  useEffect(() => {
    dispatch(onGetcollaborator(account_id));
    dispatch(getXlsdata(account_id));
    dispatch(getAbsenceType(account_id));
    dispatch(getContractEmployer(account_id));
  }, [dispatch]);
  const departmentLimits = {
    starter: 1,
    professional: 6,
    enterprise: Infinity, // Assuming enterprise has no limit
  };

  const TeamLimits = {
    starter: 1,
    professional: Infinity,
    enterprise: Infinity, // Assuming enterprise has no limit
  };
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState('');
  const [shortLink] = useState(`https://192.168.2.11/add/collaborator/mobile/${account_id}`); // Replace with your actual link
  const [copySuccess, setCopySuccess] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12); // Number of items per page
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataModal, setFilteredDataModal] = useState([]);
  const [filteredContractDataModal, setFilteredContractDataModal] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [clientStatus, setClientStatus] = useState('');
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_absence, setmodal_absence] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const [teamOptions, setTeamOptions] = useState([]);
  const [contractOptions, setContractOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [newTeamName, setNewTeamName] = useState('');
  const [newContractType, setNewContractType] = useState('');
  const [isCreatingNewTeam, setIsCreatingNewTeam] = useState(false);
  const [isCreatingNewContract, setIsCreatingNewContract] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [userID, setUserId] = useState([]);
  const [selectabsenceAll, setSelectAbsenceAll] = useState(false);
  const [absenceuserID, setAbsenceUserId] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);;
  const [errors, setErrors] = useState({});
  const totalClients = client.length

  const maxDepartments = departmentLimits[plan_name] || 1;
  const maxTeams = TeamLimits[plan_name] || 1;
  // Get unique team_id 
  
  
  const [hours, setHours] = useState({
    mo: 0,
    tu: 0,
    we: 0,
    th: 0,
    fr: 0,
    sa: 0,
    su: 0,
  });

  const [totalHours, setTotalHours] = useState(0);
  const [absenceData, setAbsenceData] = useState({
    account_id: account_id,
    typeid: null,
    worker_id: [],// ['10' ,'20']
    worker_name: [],
    startdate: '',//2024-05-31
    enddate: '', // 2025-05-31
    description: null,
    absencestatus: 'approved',
    substatus: 'display',
    status:'vk',
    contractId:[],
    perday:[],
    days:[],
  });
  
  const [contractData, setContractData] = useState({
    account_id: account_id,
    userId: userId,
    department_id: null,
    dep_name: null,
    startdate: '',//2024-05-31
    einddate: '', // 2025-05-31
    loontype: false,
    uurloon: null,
    team_id: null,
    team_name: null,
    perday: [],// ['8' ,'6' ,'6']
    notes: null,
    contract_type: null,
    worker_id: [],// ['10' ,'20']
    worker_name: [],// ['hani' ,'joe']
    totalweek: null,//32:10
    days: [], // New field, e.g., ['mo', 'tu', 'we']
  });
 
 useEffect(() => {
  // Introduce a 2-second delay before setting the options
  const timer = setTimeout(() => {
    if (Array.isArray(xlsid) && xlsid.length > 0) {
      // Create a Set to track seen contract type IDs
      const seenContractTypes = new Set();
      const uniqueContractTypes = [];

      // Filter unique contract types, accounting for potential null type_id
      xlsid.forEach(contract => {
        if (contract.type_id !== null && !seenContractTypes.has(contract.type_id)) {
          seenContractTypes.add(contract.type_id);
          uniqueContractTypes.push(contract);
        }
      });

      const options = [
        { value: '', label: t('Select Contract Type') },
        ...uniqueContractTypes.map(contract => ({
          value: contract.type_id,
          label: contract.type_name,
        })),
        { value: 'new', label: t('New Contract Type') },
      ];
      setContractOptions(options);
    } else {
      // Handle case where xlsid is empty or not an array
      setContractOptions([
        { value: '', label: t('Select Contract Type') },
        { value: 'new', label: t('New Contract Type') },
      ]);
    }
  }, 2000); // 2000 milliseconds = 2 seconds

  return () => clearTimeout(timer);
}, [xlsid]);


  
  useEffect(() => {
    // Introduce a 2-second delay before setting the options
    const timer = setTimeout(() => {
      if (Array.isArray(xlsid) && xlsid.length > 0) {
        // Create a Set to track seen department IDs
        const seenDepartments = new Set();
        const uniqueDepartments = [];
  
        // Filter unique departments
        xlsid.forEach(dept => {
          if (!seenDepartments.has(dept.departments_id)) {
            seenDepartments.add(dept.departments_id);
            uniqueDepartments.push(dept);
          }
        });
        const filteredResults = client.filter(dept => dept.service_type === uniqueDepartments[0].dep_name);

        setFilteredDataModal(filteredResults)
        const options = uniqueDepartments.length > 0
        ? [
            ...uniqueDepartments.map((dept, index) => ({
              
              value: dept.departments_id,
              label: dept.dep_name,
              default: index === 0, // Set the first department as the defaults
            })),
            // { value: 'new', label: 'Create New Department' },
          ]
        : [{ value: '', label: t('Select Department') }];
        
        setDepartmentOptions(options);
        const defaultOption = options.find(opt => opt.default) || options[0];
        setSelectedDepartment(defaultOption);
        setSelectedDepartmentId(defaultOption.value);
        setContractData({
          ...contractData,
          department_id: defaultOption.value,
          dep_name: defaultOption.label,
        });
      } else {
        // Handle case where xlsid is empty or not an array
        setDepartmentOptions([
          { value: '', label: t('Select Department') },
        ]);
      }
    }, 2000); // 2000 milliseconds = 2 secondss
  
    return () => clearTimeout(timer);
  }, [xlsid]);
  
  useEffect(() => {
    if (selectedDepartmentId) {
      // Introduce a 1-second delay before setting the options
        if (Array.isArray(xlsid) && xlsid.length > 0) {
          // Filter the teams by the selected department ID
          const filteredTeams = xlsid.filter(dept => dept.dep_id === selectedDepartmentId);
  
          // Create a Set to track unique team names to avoid duplication
          const seenTeams = new Set();
          const uniqueTeams = filteredTeams.filter(team => {
            if (!seenTeams.has(team.team_id)) {
              seenTeams.add(team.team_id);
              return true;
            }
            return false;
          });
          if (uniqueTeams.length > 0) {
            setSelectedTeam({ value: uniqueTeams[0].team_id, label: uniqueTeams[0].team_name });
            setContractData({
              ...contractData,
              team_id: uniqueTeams[0].team_id,
              team_name: uniqueTeams[0].team_name ,
            });
          }
  
          // Map the unique teams to the options array
          const options = [
            ...(uniqueTeams.length > 0
              ? uniqueTeams.map(team => ({
                  value: team.team_id,
                  label: team.team_name,
                }))
              : []),
            ...(uniqueTeams.length < maxTeams
              ? [{ value: 'new', label: t('Create New Team') }]
              : [{ value: 'over', label: t('Team limit reached!')}]),
          ];
  
          // Set the filtered and unique team options
          setTeamOptions(options);
        } else {
          setTeamOptions([]);
        }
     
    } else {
      setTeamOptions([]); // Clear team options if no department is selected
    }
  }, [selectedDepartmentId, xlsid]);
  


  const handleDepartmentChange = (selectedOption) => {
    if (selectedOption.value === 'new') {
      // User chose to create a new department
      setIsCreatingNew(true);
      setSelectedDepartment(null); // Clear the selection
      setSelectedDepartmentId(null);
      setContractData({
        ...contractData,
        department_id: null,
        dep_name: null,
      });
    } else {
      // User selected an existing department
      setSelectedDepartment(selectedOption);
      setSelectedDepartmentId(selectedOption.value); // Set the selected department ID
      setIsCreatingNew(false);
      setContractData({
        ...contractData,
        department_id: selectedOption.value,
        dep_name: selectedOption.label,
      });
    }

    filterClients( selectedOption.label)
    
   
  };




  const handleTeamChange = (selectedOption) => {
    if (selectedOption.value === 'new') {
      setIsCreatingNewTeam(true);
      setSelectedTeam(null);
      setContractData({
        ...contractData,
        team_id: null,
        team_name: null,
      });
    }else if (selectedOption.value === 'over') {
      toggleToast()
    }  else {
      setSelectedTeam(selectedOption);
      setIsCreatingNewTeam(false);
      setContractData({
        ...contractData,
        team_id: selectedOption.value,
        team_name: selectedOption.label,
      });
    }
  };

  const handleContractChange = (selectedOption) => {
    if (selectedOption.value === 'new') {
      setIsCreatingNewContract(true);
      setSelectedContract(null);
      setContractData({
        ...contractData,
        contract_type: null,
      });
    } else {
      setSelectedContract(selectedOption);
      setContractData({
        ...contractData,
        contract_type: selectedOption.value,
      });
      setIsCreatingNewContract(false);
    }
  };

  const handleNewTeamChange = (e) => {
    setNewTeamName(e.target.value);
  };

  const handleAddNewTeam = () => {
    if (newTeamName.trim()) {
      const isDuplicate = teamOptions.some(option => option.value === newTeamName);
  
      if (!isDuplicate) {
        const newOption = { value: newTeamName, label: newTeamName };
        setTeamOptions(prevOptions => [...prevOptions, newOption]);
        setSelectedTeam(newOption);
        setNewTeamName('');
        setIsCreatingNewTeam(false);
  
        const data = {
          account_id: account_id,
          team_name: newTeamName,
          dep_id: selectedDepartmentId, // Use the selected department ID
        };

        dispatch(postTeam(data));
        
      } else {
        toastr.error(t('This team already exists.')); // Use toastr for notifications
      }
    } else {
      toastr.error(t('Please enter a valid team name.')); // Use toastr for notifications
    }
  };

  const handleNewContractChange = (e) => {
    setNewContractType(e.target.value);
  };

  const handleAddNewContract = () => {
    if (newContractType.trim()) {
      const isDuplicate = contractOptions.some(option => option.value === newContractType);
  
      if (!isDuplicate) {
        const newOption = { value: newContractType, label: newContractType };
      setContractOptions(prevOptions => [...prevOptions, newOption]);
      setSelectedContract(newOption);
      setNewContractType('');
      setIsCreatingNewContract(false);
  
        const data = {
          account_id: account_id,
          type_name: newContractType,
        };
  
        dispatch(postContractType(data));
       
      } else {
        toastr.error(t('This Type already exists.')); // Use toastr for notifications
      }
    } else {
      toastr.error(t('Please enter a valid Type.')); // Use toastr for notifications
    }
  

   
  };



  
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggle() {
    setModal(!modal);
    removeBodyCss();
  
  }
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shortLink).then(() => {
      setCopySuccess(t('Link copied to clipboard!'));
    }).catch(err => {
      setCopySuccess(t('Failed to copy link.'));
    });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  
  const handleSendEmail = () => {
      const  subject = t('Welcome to the Team! Here`s Your Invitation to Collaborate')
      const text = t('We are thrilled to welcome you to our team!As a new collaborator, you will be an integral part of our projects and initiatives. To get started, we have prepared a few resources and next steps to help you integrate smoothly into our workflow.')
    const data ={
      to:email,
      subject:subject,
      text:text,
      shortLink:shortLink,
      company_name:company_name,
      account_id:account_id,
      btntext:t('Join the Team'),
      sort:t('Onboarding Invitation'),
    }
    // Implement the email sending logic here
    dispatch(postLinks(data));
  };



  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_absence() {
    setmodal_absence(!modal_absence);
    removeBodyCss();
  }
  useEffect(() => {
    let data = client.filter(item => item.client_status !== 'close');
    // Apply search filtering
    if (searchTerm) {
      data = data.filter(item =>
        (item.first_name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.last_name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.email || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.address || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.nationality || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.service_type || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.mobile_number || '').toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply client status filter
    if (clientStatus) {
      data = data.filter(item => item.client_status === clientStatus);
    }

    setFilteredData(data);
    setTotalItems(data.length);
    setFilteredContractDataModal(contractemployer);
  }, [client, searchTerm, clientStatus,contractemployer]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusChange = (e) => {
    setClientStatus(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const filterData = (client) => {
    return client.map(item => {
      return {
        frist_name: item.frist_name,
        last_name: item.last_name,
        phonenumber: item.phonenumber,
        country: item.country,
        city: item.city,
        address: item.address,
        zip: item.zip,
        email: item.email,
        startOfDay: item.registration_date,
        profile_img: item.profile_img,
        nationality: item.nationality,
        gender: item.gender,
        brith_place: item.brith_place,
        brithday: item.brithday,
        mobile_number: item.mobile_number,
        emergency_nummer: item.emergency_nummer,
        emergency_name: item.name_em,
        relation: item.relation,
        service_type	: item.service_type	,
        bank_account: item.bank_account,
        bsn_n: item.bsn_n,
        driver_license_n: item.driver_license_n,
        license_type: item.license_type,
        license_bigin_date: item.license_bigin_date,
        license_expiration_date: item.license_expiration_date,
        proof_of_identity: item.proof_of_identity,
        identity_number: item.identity_number,
        proof_bigin_date: item.proof_bigin_date,
        proof_expiration_date: item.proof_expiration_date,
        drivers_license_img_front: item.drivers_license_img_front,
        drivers_license_img_back: item.drivers_license_img_back,
        identification_front: item.identification_front,
        identification_back: item.identification_back,
        // Only include fields you need
      };
    });
  };
  const AddCollaborator = () => {
    navigate('/add/collaborator')

  };
  const handleDownloadXls = () => {
 
    const filteredData = filterData(client); // Filter the data
    const worksheet = utils.json_to_sheet(filteredData); // Convert filtered data to worksheet
    const workbook = utils.book_new(); // Create a new workbook
    utils.book_append_sheet(workbook, worksheet, 'Report'); // Append the worksheet to the workbook
    writeFile(workbook, `${company_name}_filtered_report.xlsx`); // Generate and download the XLS file
  };

  const [isCreatingNew, setIsCreatingNew] = useState(false);

  const handleInputChange =  (day, value) => {
    const numValue = value === '' ? null : Math.max(0, Math.min(24, parseFloat(value) || 0));
    
    setContractData(prevData => {
      // Create a copy of the perday array
      const updatedPerday = [...prevData.perday];

      // Update or add the value in the array
      if (numValue === null) {
          // If value is null (i.e., input is cleared), remove the value at the specified index
          const index = Object.keys(hours).indexOf(day);
          if (index > -1) {
              updatedPerday.splice(index, 1);
          }
      } else {
          // Otherwise, update the value at the specified index
          const index = Object.keys(hours).indexOf(day);
          if (index > -1) {
              updatedPerday[index] = numValue;
          } else {
              // Add a new value if it doesn't exist
              updatedPerday.push(numValue);
          }
      }

      // Remove any null or invalid entries
      return {
          ...prevData,
          perday: updatedPerday.filter(v => v !== null),
      };
  });


  setContractData(prevData => {
    // Create a copy of the perday array
    const updatedPerday = [...prevData.days];

    // Update or add the value in the array
    if (numValue === null) {
        // If value is null (i.e., input is cleared), remove the value at the specified index
        const index = Object.keys(hours).indexOf(day);
        if (index > -1) {
            updatedPerday.splice(index, 1);
        }
    } else {
        // Otherwise, update the value at the specified index
        const index = Object.keys(hours).indexOf(day);
        if (index > -1) {
            updatedPerday[index] = day;
        } else {
            // Add a new value if it doesn't exist
            updatedPerday.push(day);
        }
    }

    // Remove any null or invalid entries
    return {
        ...prevData,
        days: updatedPerday.filter(v => v !== null),
    };
});
    
    setHours(prevHours => ({
      ...prevHours,
      [day]: numValue
    }));
  };
  function convertToHourFormat(hours) {
    const wholeHours = Math.floor(hours);
    const remainingMinutes = Math.round((hours - wholeHours) * 60);
    const formattedHours = wholeHours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}
  useEffect(() => {
    const total = Object.values(hours).reduce((acc, curr) => acc + curr, 0);
    const totalTime = convertToHourFormat(total);
    setTotalHours(totalTime);
    setContractData(prevData => ({
      ...prevData,
      totalweek: totalTime,
    }));
  }, [hours]);

  const handleSearchChangeModal = (event) => {
    const query = event.target.value.toLowerCase();
    filterClients(query)
};



  const handleSelectAll = () => {
    const checkboxes = document.querySelectorAll('.plane_checkbox_pay_plane');
    if (selectAll) {
      // Deselect all clients
      setUserId([]);
      setContractData({
        ...contractData,
        worker_id: [], // Clear worker_id
        worker_name: [],
      });
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false
      });
    } else {
      
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true
      });
      const allClientIds = filteredDataModal.map(client => client.client_id);
      const allWorkerNames = filteredDataModal.map(client => client.frist_name); 
      setUserId(filteredDataModal.map(client => client.client_id));
      setContractData({
        ...contractData,
        worker_id: allClientIds, // Set worker_id to the list of all client IDs
        worker_name: allWorkerNames,
      });
    }
    setSelectAll(!selectAll); // Toggle the select all state
  }

  const handleUserSelection = (clientId) => {
    const selectedClient = filteredDataModal.find(client => client.client_id === clientId);
    if (userID.includes(clientId)) {
      const updatedUserIds = userID.filter(id => id !== clientId);
      const updatedWorkerNames = contractData.worker_name.filter(
        (name) => name !== selectedClient?.frist_name // Filter out the corresponding name
      );
      // Remove clientId if it's already in the array
      setUserId(userID.filter(id => id !== clientId));
      setContractData({
        ...contractData,
        worker_id: updatedUserIds, // Update worker_id without the removed ID
        worker_name:updatedWorkerNames,
      });
    } else {
      const updatedUserIds = [...userID, clientId];
      const updatedWorkerNames = [...contractData.worker_name, selectedClient?.frist_name];
      // Add clientId to the array
      setUserId([...userID, clientId]);
      setContractData({
        ...contractData,
        worker_id: updatedUserIds, // Update worker_id with the added ID
        worker_name:updatedWorkerNames,
      });
    }
  };
  
  const handleStartTimeChange = (e) => {
    const newStartDate = e.target.value;
    setContractData(prevData => ({
      ...prevData,
      startdate: newStartDate,
    }));
  };

  const handleEndTimeChange = (e) => {
    const newEndDate = e.target.value;
    setContractData(prevData => ({
      ...prevData,
      einddate: newEndDate,
    }));
  };
  const handleHourlyRateChange = (e) => {
    setContractData(prevData => ({
      ...prevData,
      uurloon: e.target.value,
    }));
  };


  const handleLoontypeChange = (e) => {
    setContractData(prevData => ({
      ...prevData,
      loontype: e.target.checked, // Use e.target.checked to get true or false
    }));
  };

  const handleNotesChange = (e) => {
    setContractData(prevData => ({
      ...prevData,
      notes: e.target.value,
    }));
  }

  const handleAbsenceTypeChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const status = selectedOption.getAttribute('data-status');
    const typeid = e.target.value;
    setAbsenceData({
      ...absenceData,
      typeid: typeid,
      status: status  // If you need to store status as well
    });
  };

  const handleAbsenceStatusChange = (e) => {
    const value = e.target.value;
    if (value === 'remove') {
      setAbsenceData({
        ...absenceData,
        substatus: value,
        status: 'wait'  // If you need to store status as well
      });
    }else{
      setAbsenceData({
        ...absenceData,
        substatus: value,
      });
    }
 
  };
  const handleUpdateInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setAbsenceData({
      ...absenceData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };
  const handleSelectContractAll = () => {
    const checkboxes = document.querySelectorAll('.plane_checkbox_Absence_plane');
    if (selectabsenceAll) {
     
      // Deselect all clients
      setAbsenceUserId([]);
      setAbsenceData({
        ...absenceData,
        worker_id: [],
        worker_name: [],
        contractId: [],
        perday:[],
        days:[],
      });
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false
      });
    } else {
      
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true
      });
      const allClientIds = filteredContractDataModal.map(contract => contract.client_id);
      const allWorkerNames = filteredContractDataModal.map(client => client.frist_name);
      const allContractIds = filteredContractDataModal.map(contract => contract.contrct_id);
      const allPerday = filteredContractDataModal.map(contract => contract.perday);
      const allDays = filteredContractDataModal.map(contract => contract.days);
      const workingDays = allDays.map(days => days.split(',').map(day => day.trim())); // ["mo", "tu", "we", "th", "fr"]
      const workingHours = allPerday.map(perday => perday.split(',').filter(Boolean).map(Number)); // [8, 8, 8, 8, 8]
  
      setAbsenceUserId(filteredContractDataModal.map(contract => contract.client_id));
      setAbsenceData({
        ...absenceData,
        worker_id: allClientIds,
        worker_name: allWorkerNames,
        contractId: allContractIds,
        perday:workingHours,
        days:workingDays,
      });
    }
    setSelectAbsenceAll(!selectabsenceAll); // Toggle the select all state
  }

  const handleContractUserSelection = (clientId, contractid, perday, days) => {
    // Find the selected client from the filtered data
    const selectedClient = filteredContractDataModal.find(client => client.client_id === clientId);
  
    // Convert days and perday into appropriate formats
    const workingDays = days.split(',').map(day => day.trim()); // Ensure the format matches
    const workingHours = perday.split(',').map(Number);
  
    // Check if clientId exists in the absenceuserID array
    if (absenceuserID.includes(clientId)) {
      const updatedUserIds = absenceuserID.filter(id => id !== clientId);
      const updatedWorkerNames = absenceData.worker_name.filter(
        (name) => name !== selectedClient?.frist_name // Filter out the corresponding name
      );
      const updatedContractIds = absenceData.contractId.filter(id => id !== contractid);
      const updatedPerday = absenceData.perday.filter((_, index) => absenceData.contractId[index] !== contractid);
      const updatedDays = absenceData.days.filter((_, index) => absenceData.contractId[index] !== contractid);
  
      // Update state to remove the client
      setAbsenceUserId(updatedUserIds);
      setAbsenceData({
        ...absenceData,
        worker_id: updatedUserIds,
        worker_name: updatedWorkerNames,
        contractId: updatedContractIds,
        perday: updatedPerday,
        days: updatedDays,
      });
    } else {
      const updatedUserIds = [...absenceuserID, clientId];
      const updatedWorkerNames = [...(absenceData.worker_name || []), selectedClient?.frist_name];
      const updatedContractIds = [...(absenceData.contractId || []), contractid];
      const updatedPerday = [...(absenceData.perday || []), workingHours];
      const updatedDays = [...(absenceData.days || []), workingDays];
  
      // Update state to add the client
      setAbsenceUserId(updatedUserIds);
      setAbsenceData({
        ...absenceData,
        worker_id: updatedUserIds,
        worker_name: updatedWorkerNames,
        contractId: updatedContractIds,
        perday: updatedPerday,
        days: updatedDays,
      });
    }
  };
  

  const handleSearchChangeAbsenceModal = (event) => {
    const query = event.target.value.toLowerCase();
    filterContractClients(query)
  };
  const filterContractClients = ( keyword) => {
    const searchStr = (keyword || '').toString().toLowerCase().trim();
    const filteredResults = contractemployer.filter(client => {
      const matchesSearch = (
        (client.first_name || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.last_name || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.email || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.address || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.nationality || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.service_type || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.mobile_number || '').toLowerCase().includes(searchStr.toLowerCase())||
        client.service_type === searchStr
      );
  
      
      return matchesSearch;
    });
    setFilteredContractDataModal(filteredResults);
  };

  function formatDateuren(dateString) {
    const date = new Date(dateString); // Convert string to Date object
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }



  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setAbsenceData({
      ...absenceData,
      startdate: formatDateuren(start),
      enddate: formatDateuren(end), 
  
    });
    setStartDate(start);
    setEndDate(end);
  };
  
  const filterClients = (keyword) => {
    // Ensure keyword is a string and lowercase it
    const searchStr = (keyword || '').toString().toLowerCase().trim();
  
    // Filter the clients array
    const filteredResults = client.filter(client => {
      const matchesSearch = (
        (client.first_name || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.last_name || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.email || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.address || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.nationality || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.service_type || '').toLowerCase().includes(searchStr.toLowerCase()) ||
        (client.mobile_number || '').toLowerCase().includes(searchStr.toLowerCase())||
        client.service_type === searchStr
      );
  
      
      return matchesSearch;
    });
  
    // Update the modalFilterData state
    setFilteredDataModal(filteredResults);
  };


  const validateForm = () => {
    const newErrors = {};

    if (!contractData.account_id) newErrors.account_id = t('Account ID is required.');
    if (!contractData.department_id) newErrors.department_id = t('Department ID is required.');
    if (!contractData.dep_name) newErrors.dep_name = t('Department name is required.');
    if (!contractData.startdate) newErrors.startdate = t('Start date is required.');
    if (!contractData.einddate) newErrors.einddate = t('End date is required.');
    if (!contractData.worker_id.length) newErrors.worker_id = t('At least one worker ID is required.');
    if (!contractData.totalweek) newErrors.totalweek = t('Total week time is required.');
    if (!contractData.days.length) newErrors.days = t('At least one day is required.');
    if (!contractData.contract_type) newErrors.contract_type = t('Contract type is required.');
    
    
    
    return newErrors // Returns true if there are no errors
  };


  

 
  const validateAbsenceForm = () => {
    const newErrors = {};

    if (!absenceData.account_id) newErrors.account_id = t('Account ID is required.');
    if (!absenceData.typeid) newErrors.typeid = t('Absence Type ID is required.');
    if (!absenceData.startdate) newErrors.startdate = t('Start date is required.');
    if (!absenceData.enddate) newErrors.enddate = t('End date is required.');
    if (!absenceData.worker_id.length) newErrors.worker_id = t('At least one worker ID is required.');
    if (!absenceData.absencestatus) newErrors.absencestatus = t('Inspection is required.');
    if (!absenceData.substatus) newErrors.substatus = t('Intermediate services is required.');

    // if (!absenceData.totalhours.length) newErrors.totalhours = t('totalhours services is required.');
    return newErrors // Returns true if there are no errors
  };
  

  
  
  const handleAbsenceSubmit = (e) => {
    e.preventDefault();

      const validationErrors = validateAbsenceForm();
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        Object.values(validationErrors).forEach((error) => toastr.error(error));
        // Proceed with form submission or further logic
      } else {  
    dispatch(postAbsence(absenceData)); 
      }

    
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const validationErrors = validateForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toastr.error(error));
      // Proceed with form submission or further logic
    } else {
     
      dispatch(postContract(contractData));
      // Handle the validation errors (e.g., show them in the UI)
      
    }
  };

  useEffect(() => {
    const depSuccessHandler = (message) => {
      toastr.success(t(message));
      dispatch(onGetcollaborator(account_id));
      dispatch(getXlsdata(account_id));
      dispatch(getAbsenceType(account_id));
              setAbsenceData({
                ...absenceData,
                account_id: account_id,
                typeid: null,
                worker_id: [],// ['10' ,'20']
                startdate: '',//2024-05-31
                enddate: '', // 2025-05-31
                description: null,
                absencestatus: 'approved',
                substatus: 'display',
                status:'vk',
                contractId:[], 
                perday:[],
                days:[],
              });
      setContractData({
        account_id: account_id,
        userId: userId,
        department_id: null,
        dep_name: null,
        startdate: '',
        einddate: '',
        loontype: false,
        uurloon: null,
        team_id: null,
        team_name: null,
        perday: [],
        notes: null,
        contract_type: null,
        worker_id: [],
        worker_name: [],
        totalweek: null,
        days: [],
      });
      setModal(false);
    };
    
    const depErrorHandler = (message) => {
      toastr.error(t(message));
    };
  
    if (contractdata) {
      contractdata.success 
        ? depSuccessHandler('Contract created successfully! Your new contract is now active and ready to use.') 
        : depErrorHandler('Failed to create the contract. Please check the details and try again or contact support if the issue persists.');
    }
    if (postTeams) {
      postTeams.success 
        ? depSuccessHandler('Team created successfully! Your team is now set up and ready to collaborate.') 
        : depErrorHandler('Failed to create the team. Please check the details and try again or contact support if the issue persists.');
    }
    if (postDeps) {
      postDeps.success 
        ? depSuccessHandler('New department data has been successfully submitted.')
        : depErrorHandler('Failed to submt department data.');
    }
    if (postContractTypes) {
      postContractTypes.success 
        ? depSuccessHandler('Contract type added successfully! It is now available for use.')
        : depErrorHandler('Failed to add the contract type. Please check the details and try again, or contact support for assistance.');
    }

    if (link) {
      link.success 
        ? depSuccessHandler('Link sent successfully! Please check your email for further instructions.')
        : depErrorHandler('Failed to send the link. Please verify the email address and try again, or contact support for assistance.');
    }

    if (postabsence) {
      postabsence.success 
        ? depSuccessHandler('Absence recorded successfully! The details have been saved and updated.') 
        : depErrorHandler('Failed to record the absence. Please try again or contact support if the issue persists.');
    }
  }, [postTeams, postDeps,contractdata,account_id,postContractTypes,link,postabsence]);


 
  const toggleToast = () => {
    setToast(!toast);
};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Modal isOpen={toast}>
    <ModalHeader toggle={toggleToast}>
      {t('Upgrade your plan')}
    </ModalHeader>
    <ModalBody className="text-center">

      <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
    </ModalBody>
  </Modal>

          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t('Collaborators')} breadcrumbItemLink="/home" breadcrumbItem={t('Back To Home')} />
          <Modal
                      size="lg"
                      isOpen={modal_absence}
                      toggle={() => {
                        tog_absence();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <div>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Absence')}
                        </h5>
                        </div>
                        
                        <button
                          onClick={() => {
                            tog_absence(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <form onSubmit={handleAbsenceSubmit}>
  <div className="row">
    <div className="col-lg-12">
     <Row>
     <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-4 col-form-label">
        {t('Absence Type')}
        </label> 
        <div className="col-md-8" id="select_dep">
        {getabsencetype.length > 0 ? (
  <select
   id="typeid" 
   name="typeid" 
   className="form-select"
   onChange={handleAbsenceTypeChange} 
   value={absenceData.typeid}
  
   >
    <option value=''>
    {t('Select Absece Type')}
      </option>
    {getabsencetype.map(item => (
      <option key={item.absence_type_id}  data-status={item.count_as} value={item.absence_type_id}>
        {item.type_name}
      </option>
    ))}
  </select>
) : (
  <p>{t('No Record Found')}</p>
)}
        </div>
      </div>
      </Col>

      <Col lg="6">
      <div className="mb-3 row">
        <label htmlFor="search_driver" className="col-md-3 col-form-label">{t('Search By..')}</label>
        <div className="col-md-8 position-relative">
        <Input className="form-control col-md-8"
         type="text" 
         placeholder={t('Search By..')}
          id="search_driver" 
          onChange={handleSearchChangeAbsenceModal}
          />
        </div>
      </div>
       </Col>
      <Col lg="6">
      <div className="mb-3 row">
        <label htmlFor="selectAll_employees" className="col-md-3 col-form-label">
        {t('Employee')}
        </label>
        <div className="col-md-9 select_all">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
          <Input className="form-check-input"
           type="checkbox"
           id="selectAll_employees" 
           onChange={handleSelectContractAll}/>
            <label className="form-check-label" htmlFor="selectAll_employees">
            {t('Select All')}
            </label>
          </div>
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row medewerkers" id="medewerkers">
        <label htmlFor="svc_team" className="col-md-4 col-form-label"></label>
        <div className="col-md-8" style={{ background: "aliceblue" }}>
        <ul className="plane_grid_pay_plane" id="plane_grid_pay_plane">
  {filteredContractDataModal && filteredContractDataModal.length > 0 ? (
    filteredContractDataModal.map(client => (
      <li key={client.client_id} className={`card_pay_plane ${userID.includes(client.client_id) ? 'selected' : ''}`}>
        <div>
          <img
            className="rounded-circle header-profile-user_plane"
            src={client.profile_img}
            alt="Avatar"
          />
        </div>
        <div className="card__content_pay_plane">
          <span>{`${client.frist_name} ${client.last_name}`}</span>
        </div>
        <label className="checkbox-control_pay_plane">
          <p className="time_cost">{client.service_type}</p>
          <p className="time_cost">{client.client_status}</p>
          <Input
            type="checkbox"
            className="checkbox_pay_plane plane_checkbox_Absence_plane"
           
            onChange={() => handleContractUserSelection(client.client_id,client.contrct_id,client.perday,client.days)}
          />
          <span className="checkbox-control__target_pay_plane"></span>
        </label>
      </li>
    ))
  ) : (
    <p>{t('No clients found.')}</p>
  )}
</ul>

        </div>
      </div>
       </Col>
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="Period" className="col-md-2 col-form-label">
        {t('Period')}
        </label>
        <div className="col-lg-8">
        <div className="css-q10huv">
        <div className="css-hboir5">
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          className="form-control "
          placeholderText="yyyy-mm-dd - yyyy-mm-dd"
          endDate={endDate}
          selectsRange
          dateFormat={'yyyy-MM-dd'} // Format according to locale
          inline={false} // So the datepicker opens as a dropdown
          onClickOutside={() => setIsDatePickerOpen(false)} // Close on click outside
        />
          </div>
          </div>
    
        </div>
      </div>
       </Col>
  
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              
              id="description"
              name="description"
              className="form-control"
              rows="3"
              value={absenceData.description}
              onChange={handleUpdateInputChange}
            ></textarea>
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="approved" className="col-md-2 col-form-label">
        {t('Inspection')}
  </label>
  <div className="col-lg-4">
  <select
         className="form-select" 
         name="absencestatus"
         id="absencestatus"
        value={absenceData.absencestatus}
        onChange={handleUpdateInputChange}
        >

      <option value="approved">{t('approved')}</option>
      <option value="requested">{t('requested')}</option>
      <option value="rejected">{t('rejected')}</option>
        </select>

  </div>
      </div>

      </Col>

      <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="Period" className="col-md-4 col-form-label">
        {t('Intermediate services')}
  </label>
  <div className="col-lg-8">
  <select
         className="form-select" 
         id="substatus"
         name="substatus"
         value={absenceData.substatus}
         onChange={handleAbsenceStatusChange}
        >
      <option value="display">{t('Display in rooster')}</option>
      <option value="remove">{t('Remove from grid')}</option>
        </select>

  </div>
      </div>

      </Col>

         <Col lg="12">
      <div className="mb-3" style={{display:'flex',justifyContent:'center'}}>
        <div className="form-check form-checkbox-outline form-check-success mb-3">
        <Input className="form-check-input" name="send_notification" type="checkbox" id="send_notification" />
          <label className="form-check-label" htmlFor="send_notification">
          {t('Hide days of absence without value')}
          </label>
        </div>
      </div>
      </Col>
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light"  onClick={() => {tog_absence(false)}}>                
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>

                      </div>
                    </Modal>
                   
                     <Modal
                      size="lg"
                      isOpen={modal_xlarge}
                      toggle={() => {
                        tog_xlarge();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <div>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Setting contract')}
                        </h5>
                        <small>
  {t('To set up a new contract, fill in all the required fields . Ensure to add all necessary details and click "Save" to complete the process. If you are updating an existing contract, make sure to review all the details carefully and save any changes made.')}
</small>
                        </div>
                        
                        <button
                          onClick={() => {
                            setmodal_xlarge(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <form onSubmit={handleSubmit}>
  <div className="row">
    <div className="col-lg-12">
     <Row>
     <Col lg="6">
      <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-4 col-form-label">
        {t('Department')}
        </label>
        <div className="col-md-7" id="select_dep">
        <Select
           id="department_id"
           name="department_id"
           options={departmentOptions}
           placeholder={t('Select Department')}
           onChange={handleDepartmentChange}
           value={selectedDepartment}
           
          />
        </div>
      </div>
      {isCreatingNew && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-2 col-form-label">
          {t('New Department')}
          </label>
          <div className="col-md-8">
            <input
               type="text"
               id="new_department"
               className="form-control"
               value={newDepartmentName}
               placeholder={t('Enter new department name')}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              
            >
              {t('Add Department')}
            </button>
          </div>
        </div>
      )}

 
      </Col>
      <Col lg="6">
      <div>
      <div className="mb-3 row">
        <label htmlFor="svc_team" className="col-md-2 col-form-label">
        {t('Team')}
        </label>
        <div className="col-md-7" id="select_team">
        <Select
       id="team_id"
       name="team_id"
       options={teamOptions}
       placeholder={t('Select Team')}
       onChange={handleTeamChange}
       value={selectedTeam}
       isDisabled={!selectedDepartmentId} 
        
        />
        </div>
      </div>
      {isCreatingNewTeam  && (
        <div className="mb-3 row">
          <label htmlFor="new_department" className="col-md-4 col-form-label">
          {t('New Team')}
          </label>
          <div className="col-md-8">
            <input
             type="text"
             id="new_team"
             className="form-control"
             value={newTeamName}
             onChange={handleNewTeamChange}
             placeholder={t('Enter new team name')}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewTeam}
            >
             {t('Add Team')}
            </button>
          </div>
        </div>
      )}

      </div>
      </Col>
    
      <Col lg="12">
      <div>
      <div className="mb-3 row">
        <label htmlFor="contract_type" className="col-md-2 col-form-label">
        {t('Contract Type')}
        </label>
        <div className="col-md-7" id="contract_type">
        <Select
            id="contract_type"
            name="contract_type"
            options={contractOptions}
            placeholder={t('Select Contract Type')}
            onChange={handleContractChange}
            value={selectedContract}
          />
        </div>
      </div>
      {isCreatingNewContract && (
        <div className="mb-3 row">
          <label htmlFor="new_contract" className="col-md-5 col-form-label">
          {t('New Contract Type')}
          </label>
          <div className="col-md-7">
            <input
              type="text"
              id="new_contract"
              className="form-control"
              value={newContractType}
              onChange={handleNewContractChange}
              placeholder={t('Enter new contract type')}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleAddNewContract}
            >
              {t('Add Contract Type')}
            </button>
          </div>
        </div>
      )}
      </div>
       </Col>
      <Col lg="6">
      <div className="mb-3 row">
        <label htmlFor="search_driver" className="col-md-3 col-form-label">{t('Search By..')}</label>
        <div className="col-md-9 position-relative">
        <Input className="form-control col-md-8"

         type="text" 
         placeholder={t('Search By..')}
          id="search_driver" 
          onChange={handleSearchChangeModal}
          />
        </div>
      </div>
       </Col>
      <Col lg="6">
      <div className="mb-3 row">
        <label htmlFor="selectAll_employees" className="col-md-3 col-form-label">
        {t('Employee')}
        </label>
        <div className="col-md-5 select_all">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
          <Input className="form-check-input"
           type="checkbox"
           id="selectAll_employees" 
          
           onChange={handleSelectAll}/>
            <label className="form-check-label" htmlFor="selectAll_employees">
            {t('Select All')}
            </label>
          </div>
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row medewerkers" id="medewerkers">
        <label htmlFor="svc_team" className="col-md-4 col-form-label"></label>
        <div className="col-md-8" style={{ background: "aliceblue" }}>
        <ul className="plane_grid_pay_plane" id="plane_grid_pay_plane">
  {filteredDataModal && filteredDataModal.length > 0 ? (
    filteredDataModal.map(client => (
      <li key={client.client_id} className={`card_pay_plane ${userID.includes(client.client_id) ? 'selected' : ''}`}>
        <div>
          <img
            className="rounded-circle header-profile-user_plane"
            src={client.profile_img}
            alt="Avatar"
          />
        </div>
        <div className="card__content_pay_plane">
          <span>{`${client.frist_name} ${client.last_name}`}</span>
        </div>
        <label className="checkbox-control_pay_plane">
          <p className="time_cost">{client.service_type}</p>
          <p className="time_cost">{client.client_status}</p>
          <Input
            type="checkbox"
            className="checkbox_pay_plane plane_checkbox_pay_plane"
           
            onChange={() => handleUserSelection(client.client_id)}
          />
          <span className="checkbox-control__target_pay_plane"></span>
        </label>
      </li>
    ))
  ) : (
    <p>{t('No clients found.')}</p>
  )}
</ul>

        </div>
      </div>
       </Col>
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="start_time" className="col-md-4 col-form-label">
        {t('Start Time & End Time')}
        </label>
        <div className="col-lg-4">
          <div className="mb-3">
            <div className="input-group">
            <Input id="start_time"
             type="date" 
            className="form-control"
            name="start_time"
            
            onChange={handleStartTimeChange}
             />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="mb-3">
            <div className="input-group">
            <Input id="end_time" 
            type="date"
             name="end_time" 
             className="form-control"
             
             onChange={handleEndTimeChange}
              />
            </div>
          </div>
        </div>
      </div>
       </Col>
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="hourly_rate" className="col-md-2 col-form-label d-flex justify-content-center align-items-center">
        {t('Hourly Rate')}
        </label>
        <div className="col-md-3">
        <Input
              className="form-control"
              type="number"
              name="hourly_rate"
              placeholder="0"
              id="hourly_rate"
              value={contractData.uurloon}
              onChange={handleHourlyRateChange}
            />
        </div>
        <div className="col-md-3">
          <div className="form-check form-checkbox-outline form-check-success mb-3">
          <Input
                className="form-check-input"
                type="checkbox"
                name="tax_withholding"
                id="tax_withholding"
                onChange={handleLoontypeChange}
                
              />
            <label className="form-check-label" htmlFor="tax_withholding">
            {t('Tax Withholding')}
            </label>
          </div>
      
        </div>
      </div>
       </Col>
      <Col lg="12">

      <div id="toggleContainer" >
        <div className="mb-3 row">
          <label className="col-md-3 col-form-label"> {t('Contract Hours Per Day')}</label>
          <div className="col-md-9 table-responsive" id="checkboxContainer">
          <table className="table table-striped" id="checkboxTable">
      <thead style={{ background: "aliceblue" }}>
        <tr className="main">
        <th><label htmlFor="mo">{t('Mon')}</label></th>
        <th><label htmlFor="tu">{t('Tue')}</label></th>
        <th><label htmlFor="we">{t('Wed')}</label></th>
        <th><label htmlFor="th">{t('Thu')}</label></th>
        <th><label htmlFor="fr">{t('Fri')}</label></th>
        <th><label htmlFor="sa">{t('Sat')}</label></th>
        <th><label htmlFor="su">{t('Sun')}</label></th>
        <th><label htmlFor="total_hours">{t('Total')}</label></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.keys(hours).map(day => (
            <td key={day}>
              <label className="checkbox">
                <input
                  type="text"
                  value={hours[day]}
                  onChange={(e) => handleInputChange(day, e.target.value)}
                  style={{ width: "45px", border: "none", marginBottom: "5px" }}
                  className="day-input"
                  data-id={day}
                />
                <span className="checkbox-indicator"></span>
              </label>
            </td>
          ))}
          <td>
            <label className="checkbox">
              <input
                type="text"
                value={totalHours}
                readOnly
                style={{ width: "45px", border: "none", marginBottom: "5px" }}
                className="total-hours"
                data-id="total"
                id="total_hours"
              />
              <span className="checkbox-indicator"></span>
            </label>
          </td>
        </tr>
      </tbody>
    </table>
          </div>
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              
              id="description"
              name="description"
              className="form-control"
              rows="3"
              value={contractData.notes}
              onChange={handleNotesChange}
            ></textarea>
        </div>
      </div>
      </Col>
   
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light" onClick={() => {tog_xlarge(false)}}>
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>

                      </div>
                    </Modal>

                    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('Send Invitation Link')}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column">
            <div className="mb-3">
              <Button color="primary" onClick={handleCopyLink}>{t('Copy Link')}</Button>
              {copySuccess && <div className="mt-2">{copySuccess}</div>}
            </div>
            <div className="mb-3">
              <Label for="email">{t('Send to Email')}</Label>
              <Input
                type="email"
                id="email"
                placeholder="Enter email address"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSendEmail}>{t('Send')}</Button>
          <Button color="secondary" onClick={toggle}>{t('Cancel')}</Button>
        </ModalFooter>
      </Modal>
          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
               <div className="row" style={{direction:"rtl",marginBottom:'10px'}}>
      { isEditorAllowed &&(
<div className="col-md-2">
<button
    type="button"
    onClick={() => {
      tog_xlarge();
    }}
    data-toggle="modal"
    data-target=".bs-example-modal-xl"
    className="btn btn-primary css-1bl3roz"
  >
    {t('Contract')}
  </button>
</div>
)}

{isEditorAllowed && (
  <>
    {plan_name === "starter" && totalClients >= 15 ? (
      // If "starter" plan and the client limit is reached, show the "Show Plan" button
    
       <div className="col-md-4">
       <button
         type="button"
         onClick={toggleToast}
         id="liveToastBtn"
         className="btn btn-primary css-1bl3roz"
       >
         {t('Add Collaborator')}
       </button>
     </div>
    ) : (
      // Otherwise, show the "Add Collaborator" button
      <div className="col-md-4">
        <button
          type="button"
          onClick={AddCollaborator}
          className="btn btn-primary css-1bl3roz"
        >
          {t('Add Collaborator')}
        </button>
      </div>
    )}
  </>
)}

{ isEditorAllowed &&(
  <>
    {plan_name === "starter"  ? (
      // If "starter" plan and the client limit is reached, show the "Show Plan" button
      <div className="col-md-2">
         <button type="button" id="liveToastBtn" onClick={() => { toggleToast()}} className="btn btn-primary css-1bl3roz "> {t('Absence')} </button>
    </div>
   
    ) : (
      // Otherwise, show the "Add Collaborator" button
      <div className="col-md-2">
      <button type="button" onClick={() => { tog_absence()}} className="btn btn-primary css-1bl3roz "> {t('Absence')} </button>
 </div>
    )}
  </>
)}


        
                <div className="col-md-2">
                <button type="button" onClick={toggle} className="btn btn-success css-1bl3roz">
                    <i className="bx bx-link font-size-16 align-middle me-2"></i>
                     {t('Invitation')}
                   </button>
                </div>
                <div className="col-md-2">
                <button type="button" disabled={!isEditorAllowed} onClick={handleDownloadXls} style={{background:'#34c38f'}} className="btn btn-success css-1bl3roz">
                    <i className="bx bx-link font-size-16 align-middle me-2"></i>
                    {t('Download Xls')}
                   </button>
                </div>
               </div>
              <div className="row">
            
                    <div className="col-md-3">
                        <div className="mb-3">
                            <label className="form-label">{t('Status')}</label>
                            <select className="form-select Select_filter" id="client_status" onChange={handleStatusChange}>
                                <option selected="" disabled="" value="">{t('Choose Status')}</option>
                                <option value="">{t('All Collaborators')}</option>
                                <option value="willekeurig">{t('Processed')}</option>
                                <option value="active">{t('Active')}</option>
                                <option value="onboarding">{t('Onboarding')}</option>
                                <option value="inactive">{t('Inactive')}</option>
                                <option value="vertrokken">{t('Left')}</option>
                            </select>
                        </div>
                        
                    </div>
                 
                    
                    <div className="col-md-3">
                        <div className="mb-3">
                            <label className="form-label">{t('Service')}</label>
                            <Input type="text" id="service_type" className="form-control" onChange={handleSearchChange} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        
                        <div className="mb-3">
                            
                                
                            <div className="position-relative ">
                                <label className="form-label">{t('Name')}</label>
                                <Input type="text" id="autocomplete_search" className="form-control" onChange={handleSearchChange} />
                               
                            </div>
                            
                        </div>
                    </div>
                </div>
         
              <div className="mb-3">
             
              <div className="table-responsive">
                    <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                            <tr>
                                <th scope="col" >#</th>
                                <th scope="col">{t('Name')}</th>
                                <th scope="col">{t('Email')}</th>
                                <th scope="col">{t('Mobile Number')}</th>
                                <th scope="col">{t('Add time')}</th>
                                <th scope="col">{t('Service Areas')}</th>
                                <th scope="col">{t('Status')}</th>
                                
                            </tr>
                        </thead>
                        <tbody id="loadCollborator">
                        {paginatedData.map((item, index) => (
                <tr key={index}>
         <td>
  <div>
    <a href={isEditorAllowed ? `/details/collaborator/${item.clientId}` : undefined}>
      <img className="rounded-circle avatar-xs" src={item.profile_img} alt="" />
    </a>
  </div>
</td>
<td>
  <h5 className="font-size-14 mb-1">
    <a
      href={isEditorAllowed ? `/details/collaborator/${item.clientId}` : undefined}
      className={isEditorAllowed ? "text-dark" : "text-muted"}
      style={{ pointerEvents: isEditorAllowed ? "auto" : "none" }}
    >
      {item.frist_name ? item.frist_name : t('Empty')}
    </a>
  </h5>
  <p className="text-muted mb-0">
    <a
      href={isEditorAllowed ? `/details/collaborator/${item.clientId}` : undefined}
      style={{ pointerEvents: isEditorAllowed ? "auto" : "none" }}
    >
      {t('Lastname')}: {item.last_name ? item.last_name : t('Empty')}
    </a>
  </p>
</td>
          <td>
            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">{item.email ? item.email : t('Empty')}</a></h5>
          </td>
          <td>
            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">{item.mobile_number ? item.mobile_number : t('Empty')}</a></h5>
          </td>
          <td>
            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark col_table">{format(new Date(item.registration_date), DateTimeFormat)}</a></h5>
          </td>
          <td>
            <div>
              <a href="#" className="badge badge-soft-primary font-size-11 m-1">{item.service_type ? item.service_type : t('Empty')}</a> 
            </div>
          </td>
          <td>
            <a href="#" id="status_user" className="badge badge-soft-success font-size-11 m-1">{item.client_status ? item.client_status : t('Empty')}</a>
          </td>
        </tr>
       ))}
                        </tbody>
                    </table>
                </div>
              
                {client.length > 0 &&
                  <Row>
                    <Col xs="7">{t('Showing')} {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, totalItems)} {t('of')} {totalItems}</Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }
              
              </div>

           
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(CollaboratirList));

