import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Dropdown, DropdownToggle, DropdownMenu 
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import withRouter from "components/Common/withRouter";
  import megamenuImg from "../../assets/images/megamenu-img.png";

const FeatureMenu = ({props}) => {
    const [menu, setMenu] = useState(false);
  return (
    <Dropdown
    className="dropdown-mega d-none d-lg-block ms-2"
    isOpen={menu}
    toggle={() => setMenu(!menu)}
  >
    
    <div className="css-qowjig">
    <DropdownToggle
      className="css-nx1dk9"
      caret
      tag="button"
    >
     
      {props.t("Solutions")}
      <span aria-hidden="true" className="css-1d1unjo">
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
     <path fillRule="evenodd" clipRule="evenodd" d="M13.315 6.13814L12.1954 5.01855L8.00015 9.21382L3.80489 5.01855L2.6853 6.13814L8.00015 11.453L13.315 6.13814Z" fill="currentColor"></path>
   </svg>
 </span>
    </DropdownToggle>
    <DropdownMenu className="dropdown-megamenu" style={{margin:'0 0 0 -22px',width:'100%'}}>
<Row>
{/* First Column with Menu Items */}
<Col sm={8}>
<Row>
  <Col md={8}>
    {/* <h5 className="font-size-14 mt-0">{props.t("UI Components")}</h5> */}
    <ul className="list-unstyled megamenu-list ">
      <li>
      <a
  href="/employee-scheduling"
  className="flex items-center rounded-lg hover:bg-black"
  title="Employee scheduling"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
    <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M11 19.636c.608 0 1.1-.488 1.1-1.09 0-.603-.492-1.091-1.1-1.091-.608 0-1.1.488-1.1 1.09 0 .603.492 1.091 1.1 1.091Zm5.5 0c.608 0 1.1-.488 1.1-1.09 0-.603-.492-1.091-1.1-1.091-.608 0-1.1.488-1.1 1.09 0 .603.492 1.091 1.1 1.091Zm0-4.363c.608 0 1.1-.489 1.1-1.091 0-.603-.492-1.091-1.1-1.091-.608 0-1.1.488-1.1 1.09 0 .603.492 1.092 1.1 1.092Zm-5.5 0c.608 0 1.1-.489 1.1-1.091 0-.603-.492-1.091-1.1-1.091-.608 0-1.1.488-1.1 1.09 0 .603.492 1.092 1.1 1.092Zm7.7-13.091h-1.1V1.09C17.6.488 17.108 0 16.5 0c-.608 0-1.1.488-1.1 1.09v1.092H6.6V1.09C6.6.488 6.108 0 5.5 0c-.608 0-1.1.488-1.1 1.09v1.092H3.3C1.477 2.182 0 3.647 0 5.455v15.272C0 22.535 1.477 24 3.3 24h15.4c1.823 0 3.3-1.465 3.3-3.273V5.455c0-1.808-1.477-3.273-3.3-3.273Zm1.1 18.545c0 .603-.492 1.091-1.1 1.091H3.3c-.608 0-1.1-.488-1.1-1.09v-9.819h17.6v9.818Zm0-12H2.2V5.455c0-.603.492-1.091 1.1-1.091h1.1v1.09c0 .603.492 1.091 1.1 1.091.608 0 1.1-.488 1.1-1.09V4.364h8.8v1.09c0 .603.492 1.091 1.1 1.091.608 0 1.1-.488 1.1-1.09V4.364h1.1c.608 0 1.1.488 1.1 1.09v3.273ZM5.5 15.273c.608 0 1.1-.489 1.1-1.091 0-.603-.492-1.091-1.1-1.091-.608 0-1.1.488-1.1 1.09 0 .603.492 1.092 1.1 1.092Zm0 4.363c.608 0 1.1-.488 1.1-1.09 0-.603-.492-1.091-1.1-1.091-.608 0-1.1.488-1.1 1.09 0 .603.492 1.091 1.1 1.091Z"
        fill="#ee4367"
        fillRule="nonzero"
      />
    </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Employee scheduling")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
      {props.t("Create optimal schedules by taking into account availability, skills and more")}
    </p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/time-tracking"
  className="flex items-center rounded-lg"
  title="Time-tracking"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path d="m17.875 7.684 1.138-1.135c.49-.495.49-1.297 0-1.792a1.247 1.247 0 0 0-1.775 0l-1.125 1.148a9.926 9.926 0 0 0-12.238 0l-1.137-1.16a1.251 1.251 0 0 0-1.782.006 1.28 1.28 0 0 0 .007 1.798l1.15 1.148A10.053 10.053 0 0 0 0 13.879c-.011 4.42 2.828 8.334 7.007 9.658 4.179 1.323 8.724-.253 11.216-3.888a10.17 10.17 0 0 0-.348-11.965ZM10 21.45c-4.142 0-7.5-3.39-7.5-7.57 0-4.182 3.358-7.571 7.5-7.571 4.142 0 7.5 3.39 7.5 7.57 0 2.008-.79 3.934-2.197 5.354A7.465 7.465 0 0 1 10 21.45ZM7.5 2.524h5c.69 0 1.25-.565 1.25-1.262C13.75.565 13.19 0 12.5 0h-5c-.69 0-1.25.565-1.25 1.262 0 .697.56 1.262 1.25 1.262Zm3.75 7.57c0-.697-.56-1.262-1.25-1.262s-1.25.565-1.25 1.262v2.385a1.905 1.905 0 0 0-.502 2.086A1.876 1.876 0 0 0 10 15.782c.777 0 1.474-.484 1.752-1.217a1.905 1.905 0 0 0-.502-2.086v-2.385Z" fill="#3CCEAC" fillRule="nonzero"></path>
  </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Time-tracking")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Register employees' hours worked without risk of error with the punch clock in the app")}
    </p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/absence-management"
  className="flex items-center rounded-lg"
  title="Absence management"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg className="main-menu-svg" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
  <path d="M20.4 4.842h-3.6v-1.21C16.8 1.626 15.188 0 13.2 0h-2.4C8.812 0 7.2 1.626 7.2 3.632v1.21H3.6c-.955 0-1.87.383-2.546 1.064A3.648 3.648 0 0 0 0 8.474v10.894C0 21.374 1.612 23 3.6 23h16.8c1.988 0 3.6-1.626 3.6-3.632V8.474c0-2.006-1.612-3.632-3.6-3.632ZM9.6 3.632c0-.669.537-1.21 1.2-1.21h2.4c.663 0 1.2.541 1.2 1.21v1.21H9.6v-1.21Zm12 15.736c0 .669-.537 1.21-1.2 1.21H3.6c-.663 0-1.2-.541-1.2-1.21v-6.113H6v1.271c0 .669.537 1.21 1.2 1.21.663 0 1.2-.541 1.2-1.21v-1.27h7.2v1.27c0 .669.537 1.21 1.2 1.21.663 0 1.2-.541 1.2-1.21v-1.27h3.6v6.112Zm0-8.473H2.4V8.474c0-.669.537-1.21 1.2-1.21h16.8c.663 0 1.2.541 1.2 1.21v2.42Z" fill="#FFD240" fillRule="nonzero"></path>
 
  </svg>
  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Absence management")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("Immediate insight into leave requests and balances, all from a central overview")} 
    </p>
  </div>
</a>
      </li>
      <li>
      <a
  href="/digital-signature"
  className="flex items-center rounded-lg"
  title="Digital Signature"
  style={{
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '8px',
    transition: 'all 0.3s ease',
    textDecoration: 'none'
  }}
  onMouseOver={(e) => {
    e.currentTarget.style.backgroundColor = 'aliceblue';
  }}
  onMouseOut={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent';
  }}
>
  <div>
  <svg
  fill="#00a0f0"
width="24" height="24"
  viewBox="0 0 36 36"
  xmlns="http://www.w3.org/2000/svg"
  stroke="#00a0f0"
>
  <g>
    <path
      d="M17,33.9c-3.9,0-7.9-1.5-10.5-4.5c-0.9,0.7-2,1.3-3.1,1.8c-0.5,0.2-1.1,0-1.3-0.5c-0.2-0.5,0-1.1,0.5-1.3
      c1-0.4,1.9-0.9,2.7-1.5c-1.2-1.9-1.9-4.3-1.9-7.1c0-5.3,2.5-8.1,4.8-8.1c1.5,0,3.2,1.2,3.2,4.7c0,4.7-1.1,8.2-3.5,10.8
      c2.2,2.6,5.6,3.9,9,3.9c0.6,0,1,0.4,1,1S17.6,33.9,17,33.9z M8.2,14.6c-1,0-2.8,1.8-2.8,6.1c0,2.2,0.5,4.1,1.4,5.6
      c1.8-2.1,2.7-5.1,2.7-9.1C9.5,15.7,9,14.6,8.2,14.6z"
    />
    <path
      d="M33.3,4.8c-0.8-1.4-2.1-2.4-3.6-2.8c-0.5-0.1-1.1,0.2-1.2,0.7l-0.9,3.4l-1.6-2.8c-0.1-0.2-0.4-0.4-0.7-0.5
      c-0.3-0.1-0.6,0-0.8,0.2c-1.1,0.8-1.8,1.9-2.2,3.2l-4.2,15.4c-0.4,1.5-0.2,3.2,0.6,4.6c0.6,1.1,1.6,1.9,2.7,2.5l-1.1,4
      c-0.1,0.5,0.2,1.1,0.7,1.2c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.3,1-0.7l1.1-3.9c0.2,0,0.4,0,0.6,0c1,0,2-0.3,3-0.8c1.4-0.8,2.4-2.1,2.8-3.6
      l1.6-5.8c0,0,0,0,0,0l1.6-5.8c0.1-0.4,0-0.8-0.4-1.1c-0.3-0.2-0.8-0.3-1.1-0.1l-4,2.3l0.6-2.1l5.7-3.2c0.3-0.2,0.5-0.5,0.5-0.8
      C34.2,7.1,33.9,5.9,33.3,4.8z M24.2,6.8c0.1-0.5,0.3-0.9,0.6-1.3l1.9,3.4l-1.4,5.1l-2.1-3.7L24.2,6.8zM20.4,25.2
      c-0.5-0.9-0.7-2-0.4-3l2.5-9.2l2.1,3.7L23.2,22c0,0,0,0,0,0l-1.3,4.7C21.3,26.4,20.8,25.9,20.4,25.2z M27.7,24.3c-0.3,1-0.9,1.9-1.9,2.4
      c-0.6,0.4-1.3,0.5-2,0.5L25,23l3.7-2.1L27.7,24.3z M30.3,15.1l-0.8,3.1l-3.7,2.1l0.8-3.1L30.3,15.1zM28.6,9.6l0.1-0.4
      c0.1-0.1,0.1-0.3,0.1-0.4l1.2-4.5c0.6,0.3,1.1,0.9,1.5,1.5c0.3,0.6,0.5,1.2,0.5,1.8L28.6,9.6z"
    />
  </g>
</svg>

  </div>
  <div className="ml-3">
    <p className="text-sm font-bold">{props.t("Digital Signature")}</p>
    <p className="text-xs font-medium text-oxford-blue-500 hidden lg:block">
    {props.t("revolutionized e-signatures and now we're leading in Intelligent Agreement Management")} 
    </p>
  </div>
</a>
      </li>
    </ul>
  </Col>
  <Col sm={4}>
  <ul className="list-unstyled megamenu-list ">
  <li>
<div style={{display:'grid'}}>
                         <a href="#" className="text-sm font-bold text-dark-30 block m-4" title="Punch clock">
                         {props.t("Punch clock")}  
                            </a>

                            <a href="#" className="text-sm font-bold text-dark-30 block m-4" title="Mobile app">
                            {props.t("Mobile app")}   
                            </a>
                            <a href="#" className="text-sm font-bold text-dark-30 block m-4" title="Reports">
                            {props.t("Reports")}    
                            </a>
                            <a href="#" className="text-sm font-bold text-dark-30 block m-4" title="Payroll">
                            {props.t("Payroll")}   
                            </a>
                            <a href="#" className="text-sm font-bold text-dark-30 block m-4" title="HRM">
                            {props.t("Signature")}  
                            </a>   
</div>
    </li>

    </ul>
  </Col>
</Row>
</Col>

{/* Second Column with Image */}
<Col sm={4} className="megamenu-image-col">
<Row>
  <Col sm={12}>
    <div className="megamenu-image">
      <img
        src={'https://shiftspec.s3.eu-north-1.amazonaws.com/Afbeelding+van+WhatsApp+op+2024-10-19+om+14.38.09_c251c7b3.png'}
        alt=""
        className="img-fluid mx-auto d-block"
      />
    </div>
  </Col>
</Row>
</Col>
</Row>
</DropdownMenu>

</div>

  </Dropdown>
  );
};

export default FeatureMenu;
