/* Shift */
export const GET_SHIFT = "GET_SHIFT"
export const GET_SHIFT_SUCCESS = "GET_SHIFT_SUCCESS"
export const GET_SHIFT_FAIL = "GET_SHIFT_FAIL"

export const POST_SHIFT = "POST_SHIFT"
export const POST_SHIFT_SUCCESS = "POST_SHIFT_SUCCESS"
export const POST_SHIFT_FAIL = "POST_SHIFT_FAIL"

export const PUT_SHIFT = "PUT_SHIFT"
export const PUT_SHIFT_SUCCESS = "PUT_SHIFT_SUCCESS"
export const PUT_SHIFT_FAIL = "PUT_SHIFT_FAIL"

export const DELETE_SHIFT = "DELETE_SHIFT"
export const DELETE_SHIFT_SUCCESS = "DELETE_SHIFT_SUCCESS"
export const DELETE_SHIFT_FAIL = "DELETE_SHIFT_FAIL"

/* UREN */
export const GET_UREN = "GET_UREN"
export const GET_UREN_SUCCESS = "GET_UREN_SUCCESS"
export const GET_UREN_FAIL = "GET_UREN_FAIL"
export const GET_SCHEDULE = "GET_SCHEDULE"
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS"
export const GET_SCHEDULE_FAIL = "GET_SCHEDULE_FAIL"
export const POST_UREN = "POST_UREN"
export const POST_UREN_SUCCESS = "POST_UREN_SUCCESS"
export const POST_UREN_FAIL = "POST_UREN_FAIL"

export const PUT_UREN = "PUT_UREN"
export const PUT_UREN_SUCCESS = "PUT_UREN_SUCCESS"
export const PUT_UREN_FAIL = "PUT_UREN_FAIL"

export const DELETE_UREN = "DELETE_UREN"
export const DELETE_UREN_SUCCESS = "DELETE_UREN_SUCCESS"
export const DELETE_UREN_FAIL = "DELETE_UREN_FAIL"

/* CONTRACTEN COLLABORATOR */
export const GET_CONTRACT_EMPLOYEER = "GET_CONTRACT_EMPLOYEER"
export const GET_CONTRACT_EMPLOYEER_SUCCESS = "GET_CONTRACT_EMPLOYEER_SUCCESS"
export const GET_CONTRACT_EMPLOYEER_FAIL = "GET_CONTRACT_EMPLOYEER_FAIL"

export const GET_TEAM = "GET_TEAM"
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS"
export const GET_TEAM_FAIL = "GET_TEAM_FAIL"

export const POST_NEW_TEAM = "POST_NEW_TEAM"
export const POST_NEW_TEAM_SUCCESS = "POST_NEW_TEAM_SUCCESS"
export const POST_NEW_TEAM_FAIL = "POST_NEW_TEAM_FAIL"


export const POST_INSPECTION_EMPLOYEE = "POST_INSPECTION_EMPLOYEE"
export const POST_INSPECTION_EMPLOYEE_SUCCESS = "POST_INSPECTION_EMPLOYEE_SUCCESS"
export const POST_INSPECTION_EMPLOYEE_FAIL = "POST_INSPECTION_EMPLOYEE_FAIL"

export const GET_SURCHARGER = "GET_SURCHARGER"
export const GET_SURCHARGER_SUCCESS = "GET_SURCHARGER_SUCCESS"
export const GET_SURCHARGER_FAIL = "GET_SURCHARGER_FAIL"

export const POST_SURCHARGER = "POST_SURCHARGER"
export const POST_SURCHARGER_SUCCESS = "POST_SURCHARGER_SUCCESS"
export const POST_SURCHARGER_FAIL = "POST_SURCHARGER_FAIL"

export const PUT_SURCHARGER = "PUT_SURCHARGER"
export const PUT_SURCHARGER_SUCCESS = "PUT_SURCHARGER_SUCCESS"
export const PUT_SURCHARGER_FAIL = "PUT_SURCHARGER_FAIL"

export const DELETE_SURCHARGER = "DELETE_SURCHARGER"
export const DELETE_SURCHARGER_SUCCESS = "DELETE_SURCHARGER_SUCCESS"
export const DELETE_SURCHARGER_FAIL = "DELETE_SURCHARGER_FAIL"