import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Alert,
} from "reactstrap";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import axios from "axios";

const Recoverpw = (props) => {
  const { t } = props;
  const location = useLocation();
  const [token, setToken] = useState("");
  const [forgetError, setForgetError] = useState("");
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get("token");
    setToken(tokenParam);
  }, [location]);

  // Update page title
  document.title = t("Recover Password | Shiftspec - React Admin & Dashboard Template");

  // Formik validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      token: token,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
        t('Password must be at least 8 characters long, include at least one letter, and one special character')
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('Passwords must match'))
      .required(t('Confirm Password is required')),
    }),
    onSubmit: async (values) => {
    
      try {
        const response = await axios.post("https://dspmetrics.com/api/updatePassword", values);
       
        if (response.data.success) {
          setForgetSuccessMsg(t("Your password has been updated successfully."));
          setForgetError("");
        } else {
          setForgetError(t("Failed to update password. Please try again."));
          setForgetSuccessMsg("");
        }
      } catch (error) {
        setForgetError(t("An error occurred. Please try again later."));
        setForgetSuccessMsg("");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary" style={{ backgroundColor: "#000000" }}>
                  <Row>
                    <Col xs={5}>
                      <div className="text-white p-1 flex justify-content-center">
                        <img
                          src="https://shiftspec.s3.eu-north-1.amazonaws.com/web-logo+-+dark.png"
                          alt="Logo Light"
                          height="30"
                        />
                      </div>
                    </Col>
                    <Col xs={7}>
                      <div className="text-white p-1 flex justify-content-center">
                        <h5>{t("Update your password")}</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {forgetError && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    )}
                    {forgetSuccessMsg && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{t("New Password")}</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder={t("Please Enter Your New Password")}
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password && validation.errors.password && (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        )}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{t("Confirm Password")}</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder={t("Please Confirm Your Password")}
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword && (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="text-end">
                        <button className="btn btn-primary w-md" type="submit">
                          {t("Reset")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t("Remember It?")}{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {t("Sign In here")}
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  {t("Shiftspec. Crafted with")}{" "}
                  <i className="mdi mdi-heart text-danger" />{" "}
                  {t("by Shiftspec")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Recoverpw.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(Recoverpw));
