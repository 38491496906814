import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { SketchPicker } from 'react-color'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axios from "axios"
import toastr from "toastr"; 
import "toastr/build/toastr.min.css";
//Import Breadcrumb
import {
  setData,
} from "../../common/data";
import classnames from "classnames";
import { v4 as uuidv4 } from 'uuid';
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getBrandingInfo,
  postBrandingInfo,
  putBrandingInfo,
  deleteBrandingInfo,
  updateMail as onUpdateMail,
} from "store/actions";
import { withTranslation } from "react-i18next";
const Branding = (props) => {
  const { t, permissions } = props;
  useEffect(() => {
    document.title = t("Branding - Customize and Manage Your Brand Identity | eSignatureHub.com");
  }, [t]);
  const navigate = useNavigate();
  const { rule, template_content, editor,plan_type,plan_status,plan_name } = permissions;
  const isEditorAllowed = editor === 1;
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggleDropdown = (branding_id) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [branding_id]: !prevState[branding_id] // Toggle the state for the given branding_id
    }));
  };
  

  const {
    info,
    postinfo,
    putinfo,
    deleteinfo,
    loading,
    error
  } = useSelector(state => ({
    info: state.mails.info,
    postinfo: state.mails.postinfo,
    putinfo: state.mails.putinfo,
    deleteinfo: state.mails.deleteinfo,
    loading: state.mails.loading,
    error: state.mails.error,
  }));
  //meta title
  const dispatch = useDispatch();
  useEffect(() => {
    if (account_id) {
      dispatch(getBrandingInfo(account_id));
    }
  }, [dispatch, account_id,setData]);

 
  const [modal_scroll, setmodal_scroll] = useState(false);
  useEffect(() => {
    if (setData && setData[0]) {
      setHeaderBackground(setData[0].background_header_color);
      setHeaderText(setData[0].text_header_color);
      setButtomBackground(setData[0].background_button_color);
      setButtomText(setData[0].text_button_color);
      setSendingLogoFile(setData[0].website_logo);
      setEmailLogoFile(setData[0].email_logo);
      setResetSendingLogoFile(setData[0].website_logo);
      setResetEmailLogoFile(setData[0].email_logo);
    }
  }, [setData]);
  const [headerBackground, setHeaderBackground] = useState(setData && setData[0] ? setData[0].background_header_color : '#FFFFFF'); // Fallback color
  const [headerText, setHeaderText] = useState(setData && setData[0] ? setData[0].text_header_color : '#000000'); // Fallback text color
  const [buttomBackground, setButtomBackground] = useState(setData && setData[0] ? setData[0].background_button_color : '#FFFFFF');
  const [buttomText, setButtomText] = useState(setData && setData[0] ? setData[0].text_button_color : '#000000');
  const [sendingLogoFile, setSendingLogoFile] = useState(setData && setData[0] ? setData[0].website_logo : '');
  const [emailLogoFile, setEmailLogoFile] = useState(setData && setData[0] ? setData[0].email_logo : '');
  const [resetsendingLogoFile, setResetSendingLogoFile] = useState(setData && setData[0] ? setData[0].website_logo : '');
  const [resetemailLogoFile, setResetEmailLogoFile] = useState(setData && setData[0] ? setData[0].email_logo : '');
  const [brandname , setBrandName ] = useState(null); 
  const [companyname , setCompanyName ] = useState(null);
  const [errorMessages, setError] = useState('');
  const [sendingLogoFileF, setSendingLogoFileF] = useState(null);
  const [emailLogoFileF, setEmailLogoFileF] = useState(null);

  const [editsendingLogoFileF, setSendingLogoFileFedit] = useState(null);
  const [editemailLogoFileF, setEmailLogoFileFedit] = useState(null);
  const [editbrandname , setBrandNameedit ] = useState(null); 
  const [editcompanyname , setCompanyNameedit ] = useState(null);
  const [editheaderBackground, setHeaderBackgroundedit] = useState('');
  const [editheaderText, setHeaderTextedit] = useState('');
  const [editbuttomBackground , setButtomBackgroundedit ] = useState(''); 
  const [editbuttomText , setButtomTextedit ] = useState(''); 
  const [editsendingLogoFile, setSendingLogoFileedit] = useState(null);
  const [editemailLogoFile, setEmailLogoFileedit] = useState(null);


  
 const [customActiveTab, setcustomActiveTab] = useState("1");
 const [customActiveTab2, setcustomActiveTab2] = useState("1");
 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage] = useState(12);
 const [modalOpen, setModalOpen] = useState(false);
 const [editItem, setEditItem] = useState(null);
 const [defaultItem, setDefaultItem] = useState(null);
 const [sendingLogoFileDefault, setSendingLogoFileDefault] = useState(setData[0].website_logo);
 const [emailLogoFileDefault, setEmailLogoFileDefault] = useState(setData[0].email_logo);
 const [headerBackgroundDefault, setHeaderBackgroundDefault] = useState(setData[0].background_header_color);
 const [headerTextDefault, setHeaderTextDefault] = useState(setData[0].text_header_color);
 const [buttomBackgroundDefault, setButtomBackgroundDefault] = useState(setData[0].background_button_color);
 const [buttomTextDefault, setButtomTextDefault] = useState(setData[0].text_button_color);
 const [planenames, setPlaneName] = useState('');
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const toggleCustom2 = tab => {
    if (customActiveTab2 !== tab) {
      setcustomActiveTab2(tab);
    }
  };
  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
 
  const handleColorChangeHeaderBackground = (event) => {
    setHeaderBackground(event.target.value);
  };
  const handleColorChangeHeaderText = (event) => {
    setHeaderText(event.target.value);
  };
  const handleColorChangeButtomBackground = (event) => {
    setButtomBackground(event.target.value);
  };

  const handleColorChangeButtomText = (event) => {
    setButtomText(event.target.value);
  };
 
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(info) ? info.slice(indexOfFirstItem, indexOfLastItem) : {};
  const iSplan_name = currentItems.length > 0 ? currentItems[0].plan_name : plan_name;

  useEffect(() => {
    if (info && info.length > 0) {
      const defaultItem = info.find(item => item.branding_status === 'default');
      if (defaultItem) {
        setDefaultItem(defaultItem);
        setSendingLogoFileDefault(defaultItem.sending_logo);
        setEmailLogoFileDefault(defaultItem.email_logo);
        setHeaderBackgroundDefault(defaultItem.header_background_color);// #214e9f
        setHeaderTextDefault(defaultItem.header_text);//#ffffff
        setButtomBackgroundDefault(defaultItem.button_background_color);// #ffc820
        setButtomTextDefault(defaultItem.button_text_color);// #333333
      }
    }
  }, [info]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSendingLogoChange = (event) => {
    const file = event.target.files[0]; // Assuming only one file is selected
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setSendingLogoFile(fileUrl);
      setSendingLogoFileF(file);
    }
  };

  // Function to handle email logo change
  const handleEmailLogoChange = (event) => {
    const file = event.target.files[0]; // Assuming only one file is selected
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setEmailLogoFile(fileUrl);
      setEmailLogoFileF(file);
    }
  };

  const resetSendingLogo = () => {
    setSendingLogoFile(resetsendingLogoFile);
    setSendingLogoFileF(null);
  };

  // Function to reset email logo to default  
  const resetEmailLogo = () => {
    setEmailLogoFile(resetemailLogoFile);
    setEmailLogoFileF(null);
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!brandname || !companyname || !userId) {
      setError(t('Brand name, company name, and user ID cannot be empty.'));
      toastr.error(t('Brand name, company name, and user ID cannot be empty.'));
      return;
    }

    if (!sendingLogoFileF || !emailLogoFileF) {
      setError(t('Sending logo and email logo are required.'));
      toastr.error(t('Sending logo and email logo are required.'));
      return;
    }
  
    const formData = new FormData();
    formData.append('account_id', account_id);
    formData.append('client_id', userId);
    formData.append('headerBackground', headerBackground);
    formData.append('headerText', headerText);
    formData.append('buttomBackground', buttomBackground);
    formData.append('buttomText', buttomText);
    formData.append('brandname', brandname);
    formData.append('companyname', companyname);
   
    formData.append("sendingLogoFile", sendingLogoFileF);
    formData.append("emailLogoFile", emailLogoFileF);
 
  

    axios.post(`https://dspmetrics.com/api/branding/post`, formData)
    .then((response) => {
      if (response.data && response.data.message) {
        toastr.success(response.data.message); 
        dispatch(getBrandingInfo(account_id));
        setHeaderBackground('#214e9f')
        setHeaderText('#ffffff')
        setButtomBackground('#ffc820')
        setButtomText('#333333')
        setSendingLogoFile('https://docucdn-a.akamaihd.net/olive/images/2.64.0/global-assets/ds-logo-default.svg#docusign')
        setEmailLogoFile('https://docucdn-a.akamaihd.net/olive/images/2.64.0/global-assets/ds-logo-default.svg#docusign')
        setBrandName('')
        setCompanyName('')
        tog_scroll()
        setError('')
      } else {
        toastr.success(t('Your brand information has been successfully updated!'));
        dispatch(getBrandingInfo(account_id));
      
        
      }
      
    })
  };

  const deleteItem = (branding_id) => {

    dispatch(deleteBrandingInfo(branding_id));
   
  };

  useEffect(() => {
   
    if (deleteinfo && deleteinfo.success) {
      toastr.success(t('The brand has been successfully deleted.'));
      dispatch(getBrandingInfo(account_id));
    }
  
  }, [deleteinfo,dispatch]);
  const setDefaultBrand = (brandingId) => {
    const form ={
      account_id:account_id,
      branding_id:brandingId
    }
    dispatch(postBrandingInfo(form));
  };

  useEffect(() => {
   
    if (postinfo && postinfo.success) {
      toastr.success(t('Your brand has been updated successfully'));
      dispatch(getBrandingInfo(account_id));
    }
  
  }, [postinfo]);

  const editItemHandler = (item) => {
    setEditItem(item);
    setSendingLogoFileFedit(null);
    setEmailLogoFileFedit(null);
    setBrandNameedit(item.brand_name);
    setCompanyNameedit(item.company_name);
    setHeaderBackgroundedit(item.header_background_color);
    setHeaderTextedit(item.header_text);
    setButtomBackgroundedit(item.button_background_color);
    setButtomTextedit(item.button_text_color);
    setSendingLogoFileedit(item.sending_logo);
    setEmailLogoFileedit(item.email_logo);
    toggleModal();
  };
  const edithandleColorChangeHeaderBackground = (event) => {
    setHeaderBackgroundedit(event.target.value);
  };
  const edithandleColorChangeHeaderText = (event) => {
    setHeaderTextedit(event.target.value);
  };
  const edithandleColorChangeButtomBackground = (event) => {
    setButtomBackgroundedit(event.target.value);
  };

  const edithandleColorChangeButtomText = (event) => {
    setButtomTextedit(event.target.value);
  };

  const edithandleSendingLogoChange = (event) => {
    const file = event.target.files[0]; // Assuming only one file is selected
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setSendingLogoFileedit(fileUrl);
      setSendingLogoFileFedit(file);
    }
  };

  // Function to handle email logo change
  const edithandleEmailLogoChange = (event) => {
    const file = event.target.files[0]; // Assuming only one file is selected
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setEmailLogoFileedit(fileUrl);
      setEmailLogoFileFedit(file);
    }
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleSubmitEdit = (e) => {
    e.preventDefault();
    if (!editbrandname || !editcompanyname || !account_id) {
      setError(t('Brand name, company name, and user ID cannot be empty.'));
      toastr.error(t('Brand name, company name, and user ID cannot be empty.'));
      return;
    }

    if (!editsendingLogoFileF || !editemailLogoFileF) {
      setError(t('Sending logo and email logo are required.'));
      toastr.error(t('Sending logo and email logo are required.'));
      return;
    }
    
    const formData = new FormData();
    formData.append('account_id', account_id);
    formData.append('client_id', userId);
    formData.append('branding_id', editItem.branding_id);
    formData.append('headerBackground', editheaderBackground);
    formData.append('headerText', editheaderText);
    formData.append('buttomBackground', editbuttomBackground);
    formData.append('buttomText', editbuttomText);
    formData.append('brandname', editbrandname);
    formData.append('companyname', editcompanyname);
   
    formData.append("sendingLogoFile", editsendingLogoFileF );
    formData.append("emailLogoFile", editemailLogoFileF);
    axios.post(`https://dspmetrics.com/api/branding/update`, formData)
    .then((response) => {
      if (response.data && response.data.message) {
        toastr.success(t('Your brand has been updated successfully')); 
        dispatch(getBrandingInfo(account_id));
      } else {
        toastr.error(t('Form submission was unsuccessful.'));
       
        dispatch(getBrandingInfo(account_id));
        
      }
      
    })
    toggleModal(); // Close the modal after editing
  };



  return (
    <React.Fragment>
      <Modal  size="lg" isOpen={modalOpen} toggle={toggleModal} scrollable={true}>
      <div className="modal-header">
                     
                     <h5 className="modal-title mt-0">
                     {t('Customize a Sending Brand')} 
                     </h5>
                     <button
                       onClick={() =>
                        toggleModal(false)
                      }
                       className="close"
                       data-dismiss="modal"
                       aria-label="Close"
                     >
                       <span aria-hidden="true">&times;</span>
                     </button>
                   </div>
                   <div className="modal-body">
                   <div className="css-qf5tof">
   <div className="css-ix6jir">
     <div className="css-fta723"></div>
     {errorMessages && <p style={{ color: 'red' }}>{errorMessages}</p>}
     <div style={{ display: 'flex' }}>
       <div className="RcWeCMK_5wbOQGDuetx7" style={{ minWidth: '220px' }}>
     
         <div className="hQyI2mPrdJXCvjzvWr_A h6Dyc_0RkOthtRStRg3T">
         <div className="css-list">
                
                <div >
                <label style={{fontSize:'12px',fontWeight:'600'}} htmlFor="VWpFAduzDw" className="css-iekl2y">{t('Brand Name *')}</label> 
                <div className="css-hboir5">
            <Input
             className="css-12ihcxq"
             type="text"
              value={editbrandname}  
              onChange={(e) => setBrandNameedit(e.target.value)}
             />
            </div>
                </div>
                <div >
                <label style={{fontSize:'12px',fontWeight:'600'}} htmlFor="VWpFAduzDw" className="css-iekl2y">{t('Company Name')}</label> 
                <div className="css-hboir5">
            <Input
             className="css-12ihcxq"
             type="text"
             value={editcompanyname} 
             onChange={(e) => setCompanyNameedit(e.target.value)}
             />
            </div>
                </div>

                </div>
           <header>
             <h2 style={{fontSize:'18px',fontWeight:'600'}}>{t('Logos')}</h2>
           </header>
          
           <div className="U4ua1_aVL9FEdgqUhSB2">
             <h4 style={{fontSize:'14px',fontWeight:'600'}} className="IixPejJalslN1nHnA2dc r9wZB_PBsIbUNl_tKVWU Aq4fEEQKY10alPYc193i">{t('Sending Logo')}</h4>
             <div className="G7jaZc86XiIKysmIyFAp">
               <div className="JcZQ8ANnx3vqP6Jqhfvw">
                 <span className="FdWpOmwtO778cTuj86Pw">
                   <img
                     alt="Test Brand"
                     src={editsendingLogoFile}
                     className="css-1sz9cjp"
                    
                   />
                 </span>
                 <span onClick={resetSendingLogo} data-qa="brand_logo_close_button" style={{ verticalAlign: 'middle', paddingLeft: '10px', cursor: 'pointer' }}>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" fill="currentColor" focusable="false" data-qa="brand_close">
                     <path d="M20 5.36L18.64 4 12 10.65 5.36 4 4 5.36 10.65 12 4 18.66 5.34 20 12 13.35 18.66 20 20 18.66 13.35 12 20 5.36z"></path>
                   </svg>
                 </span>
               </div>
             </div>
           </div>
           <input
               accept="jpg,jpeg,gif,png"
               id="s68K5CCwTJsdfdsf"
               type="file"
               onChange={edithandleSendingLogoChange}
               className="css-12s44sk"
             />
           <label className="olv-button olv-ignore-transform css-goyd0e" htmlFor="s68K5CCwTJsdfdsf">
               <span data-qa="brand_left_nav_upload_a_custom_email_logo_lbl-text" className="css-16hz1ch"></span>
             </label>
           <div className="U4ua1_aVL9FEdgqUhSB2">
             <h4  style={{fontSize:'14px',fontWeight:'600'}} className="IixPejJalslN1nHnA2dc r9wZB_PBsIbUNl_tKVWU Aq4fEEQKY10alPYc193i">{t('Email Logo')}</h4>
             <div className="G7jaZc86XiIKysmIyFAp">
               <div className="JcZQ8ANnx3vqP6Jqhfvw">
                 <span className="FdWpOmwtO778cTuj86Pw">
                   <img
                     alt="Test Brand"
                     src={editemailLogoFile}
                     className="css-1sz9cjp"
                   />
                 </span>
                 <span onClick={resetEmailLogo} data-qa="brand_logo_close_button" style={{ verticalAlign: 'middle', paddingLeft: '10px', cursor: 'pointer' }}>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" fill="currentColor" focusable="false" data-qa="brand_close">
                     <path d="M20 5.36L18.64 4 12 10.65 5.36 4 4 5.36 10.65 12 4 18.66 5.34 20 12 13.35 18.66 20 20 18.66 13.35 12 20 5.36z"></path>
                   </svg>
                 </span>
               </div>
             </div>
             
            
             <input
       accept="image/*"
       id="s68K5CCwTJ"
       type="file"
       className="css-12s44sk"
       onChange={edithandleEmailLogoChange}  // Implement your change handler function
     />
             <label className="olv-button olv-ignore-transform css-goyd0e" htmlFor="s68K5CCwTJ">
               <span data-qa="brand_left_nav_upload_a_custom_email_logo_lbl-text" className="css-16hz1ch">{t('Upload Email Logo')}</span>
             </label>
           </div>
         </div>
         <div className="zkX22XJv_CDqK_o6beoO" id="tooltip-container">
           <span className="css-ku1ana" role="button" >
             <span className="css-7gzz9h">{t('Image Requirements')}</span>
           </span>
         </div>
         
         <div className="zkX22XJv_CDqK_o6beoO">
           <div>
             <header>
               <h2 style={{fontSize:'18px',fontWeight:'600'}}>{t('Colors')}</h2>
             </header>
             <div>
               <div className="css-1e2z90s"></div>
             </div>
            
             <div className="L049JjcqkWxmi1SN1MIQ">
               
               <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px' }}>
                 <button type="button" className="css-pkaues">
                 <input
      
      type="color"
      id="example-color-input"
      value={editheaderBackground}
      onChange={edithandleColorChangeHeaderBackground}
    />
                   <div>
                     <p className="css-1hj83rq">{t('Header Background')}</p>
                     <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#000000</p>
                   </div>
                 </button>
               </div>
               <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px', borderBottom: '1px solid rgb(224, 224, 224)' }}>
                 <button data-qa="headerText_selector" type="button" className="css-pkaues">
                 <input
      
      type="color"
      id="example-color-input"
      value={editheaderText}
      onChange={edithandleColorChangeHeaderText}
    />
                   <div>
                     <p className="css-1hj83rq">{t('Header Text')}</p>
                     <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#ffffff</p>
                   </div>
                 </button>
               </div>

               <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px', borderBottom: '1px solid rgb(224, 224, 224)' }}>
                 <button data-qa="headerText_selector" type="button" className="css-pkaues">
                 <input
      
      type="color"
      id="example-color-input"
      value={editbuttomBackground}
      onChange={edithandleColorChangeButtomBackground}
    />
                   <div>
                     <p className="css-1hj83rq">{t('Button Background')}</p>
                     <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#ffffff</p>
                   </div>
                 </button>
               </div>


               <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px', borderBottom: '1px solid rgb(224, 224, 224)' }}>
                 <button data-qa="headerText_selector" type="button" className="css-pkaues">
                 <input
      
      type="color"
      id="example-color-input"
      value={editbuttomText}
      onChange={edithandleColorChangeButtomText}
    />
                   <div>
                     <p className="css-1hj83rq">{t('Button Text')}</p>
                     <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#ffffff</p>
                   </div>
                 </button>
               </div>
             </div>
             
           
           </div>
         </div>
         <div className="zkX22XJv_CDqK_o6beoO" id="tooltip-container">
           <span className="css-ku1ana" role="button" >
             <span className="css-7gzz9h">{t('Color Requirements')}</span>
           </span>
         </div>
         <div className="css-7ge43u"></div>
         <div className="gBrEOum9gh8gfS7RQY3H"></div>
       </div>
       <div className="GRfnMhqudHi6fULN6PJw" style={{ background: 'rgb(244, 244, 244)', flexGrow: 1, width: '480px' }}>
       <Nav tabs className="nav-tabs-custom nav-justified">
                 <NavItem>
                   <NavLink
                     style={{ cursor: "pointer" }}
                     className={classnames({
                       active: customActiveTab2 === "1",
                     })}
                     onClick={() => {
                       toggleCustom2("1");
                     }}
                   >
                     <span className="d-block d-sm-none">
                       <i className="fas fa-home"></i>
                     </span>
                     <span className="d-none d-sm-block">{t('Signing')}</span>
                   </NavLink>
                 </NavItem>
                 <NavItem>
                   <NavLink
                     style={{ cursor: "pointer" }}
                     className={classnames({
                       active: customActiveTab2 === "2",
                     })}
                     onClick={() => {
                       toggleCustom2("2");
                     }}
                   >
                     <span className="d-block d-sm-none">
                       <i className="far fa-user"></i>
                     </span>
                     <span className="d-none d-sm-block">{t('Email')}</span>
                   </NavLink>
                 </NavItem>

               </Nav>

               <TabContent
                 activeTab={customActiveTab2}
                 className="p-3 text-muted"
               >
                 <TabPane tabId="1">
                   <Row>
                   <div className="css-1g2akdj">
   <span aria-hidden="true" className="SVGInline">
     <svg className="SVGInline-svg" viewBox="0 0 835 1127" focusable="false">
       <defs>
         <circle id="a" cx="16" cy="16" r="16"></circle>
       </defs>
       <g fill="none" fillRule="evenodd">
         <path className="css-0" fill="#E9E9E9" d="M0 0h835v1127H0z"></path>
         <path fill="#FFF" fillRule="nonzero" d="M16.038 149H817.96v963H16.04z"></path>
         <g className="css-0" fillRule="nonzero">
           <path fill="#E9E9E9" d="M59 191.2h94.8v16H59zM59 471h707v16H59zM59 231h707v16H59zM59 311h707v16H59zM59 391h707v16H59zM59 511h707v16H59zM59 591h707v16H59zM59 791h707v16H59zM59 271h707v16H59zM59 351h707v16H59zM59 431h707v16H59zM59 551h707v16H59zM59 751h707v16H59zM59 631h707v16H59zM59 831h707v16H59zM59 711h707v16H59zM59 911h707v16H59zM59 671h707v16H59zM59 871h707v16H59zM59 951h337v16H59z"></path>
           <path fill="#F5C431" d="M165 391h84.8v16H165zM559 551h84.8v16H559zM59 1037h84.8v16H59z" opacity=".5"></path>
         </g>
         <g>
           <path fill="#FFF" fillRule="nonzero" d="M0 0h835v129H0z"></path>
           <text className="css-0" fill="#333" fontFamily="MavenProBold, Maven Pro" fontSize="17.6" fontWeight="bold">
             <tspan x="24.1" y="26">{t('Please Review Act on These Documents')}</tspan>
           </text>
           <text className="css-0" fill="#333" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontSize="10.4" fontWeight="bold">
             <tspan x="66.6" y="58">{t('Amy Templeton')}</tspan>
           </text>
           <text className="css-0" fill="#999" fontFamily="HelveticaNeue, Helvetica Neue" fontSize="10.4">
             <tspan x="65.8" y="70">{t('Example Company')}</tspan>
           </text>
           <circle className="css-0" cx="16" cy="16" r="16" fill="#B8BFCD" fillRule="nonzero" stroke="#B8BFCD" strokeWidth="1.2" transform="translate(24 49)"></circle>
           <g>
             <g className="css-1jk87co">
               <defs>
                 <filter id="offsetFilter1" x="-1" y="-1" width="450" height="25">
                   <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                 </filter>
               </defs>
               <rect x="667" y="28" width="139" height="46" filter="url(#offsetFilter1)" fill="rgba(0,0,0,0.50)"></rect>
               <rect x="667" y="28" width="139" height="46" style={{ fill: '#fff' }}></rect>
             </g>
           </g>
           <image x="667" y="28" width="139" height="46" className="css-0" href={editsendingLogoFile}></image>
           <g transform="translate(24 49)">
             <mask id="b" fill="#fff">
               <use href="#a"></use>
             </mask>
             <g mask="url(#b)">
               <path fill="#FFF" fillRule="nonzero" d="M16 18a5.4 5.4 0 1 1 .1-11 5.4 5.4 0 0 1 0 11zm0 1.3c5.5 0 11 2.8 11 5.6v8H5.2v-8c0-2.8 5.5-5.6 10.9-5.6z"></path>
             </g>
           </g>
           <g>
             <g className="css-1jk87co">
               <defs>
                 <filter id="offsetFilter2" x="-1" y="-1" width="834" height="38">
                   <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                 </filter>
               </defs>
               <rect filter="url(#offsetFilter2)" width="834" height="38" x="0" y="97.8" fill="rgba(0,0,0,0.50)"></rect>
             </g>
             <path className="css-0" fill={editheaderBackground} fillRule="nonzero" d="M0 97.8h834.2v37.7H0z"></path>
             <g className="css-1jk87co">
               <defs>
                 <filter id="offsetFilter3" x="-1" y="-1" width="210" height="20">
                   <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                 </filter>
               </defs>
               <rect filter="url(#offsetFilter3)" width="210" height="20" x="-60" y="-30" fill="rgba(0,0,0,0.50)" transform="translate(77 136)"></rect>
               <rect width="210" height="20" x="-60" y="-30"  transform="translate(77 136)"></rect>
             </g>
             <text className="css-0" fill={editheaderText} fontFamily="HelveticaNeue, Helvetica Neue" fontSize="12" transform="translate(24 104.8)">
               <tspan x=".1" y="15">{t('Please review the documents below.')}</tspan>
             </text>
             <g transform="translate(711 102.8)">
               <g className="css-1jk87co">
                 <defs>
                   <filter id="offsetFilter4" x="-1" y="-1" width="186.6" height="43.8">
                     <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                   </filter>
                 </defs>
                 <rect filter="url(#offsetFilter4)" width="96.2" height="27.3" x=".3" y=".6" fill="rgba(0,0,0,0.50)" fillRule="nonzero" rx="2.4"></rect>
               </g>
               <rect className="css-0" width="96.2" height="27.3" x=".3" y=".6" fill={editbuttomBackground} fillRule="nonzero" rx="2.4"></rect>
               <g className="css-1jk87co">
                 <defs>
                   <filter id="offsetFilter5" x="-1" y="-1" width="136" height="23.8">
                     <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                   </filter>
                 </defs>
                 <rect filter="url(#offsetFilter5)" width="72" height="18" fill="rgba(0,0,0,0.50)" fillRule="nonzero" rx=".9" x="10" y="6"></rect>
                 <rect width="72" height="18" fill={editbuttomBackground} fillRule="nonzero" rx=".9" x="10" y="6"></rect>
               </g>
               <text className="css-0" fill={editbuttomText} fontFamily="MavenProBold, Maven Pro" fontSize="12" fontWeight="bold" transform="translate(15 8)">
                 <tspan x=".5" y="10.6">{t('CONTINUE')}</tspan>
               </text>
             </g>
           </g>
         </g>
       </g>
     </svg>
   </span>
 </div>
                   </Row>
                 </TabPane>
                 <TabPane tabId="2">
                   <Row>
                   <div className="css-llvpmk">
   <span aria-hidden="true" className="SVGInline">
     <svg className="SVGInline-svg" viewBox="0 0 636 1127" focusable="false">
       <defs>
         <ellipse id="a" cx="21.7" cy="21.8" rx="21.7" ry="21.8" />
       </defs>
       <g fill="none" fillRule="evenodd">
         <g className="css-0">
           <path fill="#FFF" fillRule="nonzero" d="M0 0h636v1127H0z" />
           <path fill="#E9E9E9" fillRule="nonzero" d="M25 449h64.8v9H25zM25 474h145.8v9H25zM25 491h145.8v9H25z" />
           <ellipse
             cx="21.7"
             cy="21.8"
             fill="#B8BFCD"
             fillRule="nonzero"
             stroke="#B8BFCD"
             strokeWidth=".9"
             rx="21.7"
             ry="21.8"
             transform="translate(24.8 383.7)"
           />
           <g transform="translate(24.8 383.7)">
             <mask id="b" fill="#fff">
               <use xlinkHref="#a" />
             </mask>
             <g mask="url(#b)">
               <path
                 fill="#FFF"
                 fillRule="nonzero"
                 d="M21.7 24.6c-4 0-7.4-3.3-7.4-7.4a7.4 7.4 0 1 1 14.7 0c0 4.1-3.3 7.4-7.3 7.4zm0 1.9c7.3 0 14.7 3.8 14.7 7.6V45H7V34c0-3.7 7.3-7.5 14.7-7.5z"
               />
             </g>
           </g>
           <path fill="#E9E9E9" fillRule="nonzero" d="M80.2 393.7H145v9H80.2zM80.2 410.7H145v9H80.2z" />
           <g fillRule="nonzero">
             <path fill="#EAEAEA" d="M24 528h588v575H24z" />
             <g fill="#CCC">
               <path d="M51.6 556.4h145.8v9H51.6zM51.6 573.6h528.3v9H51.6zM51.6 590.8h76.5v9H51.6zM52 616h141.3v9H52zM52 828h141.3v9H52zM52 963h141.3v9H52zM51.6 675h141.3v9H51.6zM52 752h180v9H52zM52 887h180v9H52zM52 1022h180v9H52zM52 633h387v9H52zM52 845h387v9H52zM52 980h387v9H52zM51.6 692.2h519.3v9H51.6zM52 769h524.7v9H52zM52 904h524.7v9H52zM52 1039h524.7v9H52zM52 803h524.7v9H52zM52 938h524.7v9H52zM52 786h524.7v9H52zM52 921h524.7v9H52zM52 1056h524.7v9H52zM52 1073h524.7v9H52zM52 650h231.3v9H52zM52 862h231.3v9H52zM52 997h231.3v9H52zM51.6 709.4h519.3v9H51.6zM51.6 726.6h241.2v9H51.6z" />
             </g>
           </g>
         </g>
         <g transform="translate(24 82)">
           <g className="css-1jk87co">
             <defs>
               <filter id="offsetFilter1" x="-1" y="-1" width="588" height="272">
                 <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
               </filter>
             </defs>
             <rect filter="url(#offsetFilter1)" width="588" height="272" x="0" y="0" fill="rgba(0,0,0,0.50)" />
           </g>
           <path className="css-0" fill={editheaderBackground} fillRule="nonzero" d="M0 0h588v272.1H0z" />
           <g>
             <g className="css-1jk87co">
               <defs>
                 <filter id="offsetFilter2" x="-1" y="-1" width="450" height="25">
                   <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                 </filter>
               </defs>
               <rect x="0" y="-62" width="139" height="46" filter="url(#offsetFilter2)" fill="rgba(0,0,0,0.50)" />
               <rect x="0" y="-62" width="139" height="46" style={{ fill: '#fff' }} />
             </g>
           </g>
           <image
             x="0"
             y="-62"
             width="139"
             height="46"
             className="css-0"
             xlinkHref={editemailLogoFile}
             
           />
           <image
             className="css-0"
             width="80"
             height="80"
             x="254"
             y="26"
             xlinkHref={editemailLogoFile}
           />
           <g>
       <g className="css-1jk87co">
         <defs>
           <filter id="offsetFilter3" x="-1" y="-1" width="450" height="25">
             <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
           </filter>
         </defs>
         <rect filter="url(#offsetFilter3)" width="400" height="27" x="-2" y="-4" fill="rgba(0,0,0,0.50)" transform="translate(96 136)"></rect>
         <rect width="400" height="27" x="-2" y="-4" fill={editheaderBackground} transform="translate(96 136)"></rect>
       </g>
       <text className="css-0" fill={editheaderText} fontFamily="HelveticaNeue, Helvetica Neue" fontSize={15}  transform="translate(106 136)">
         <tspan x="-2" y="14">{t('Amy Templeton sent you a document to review and sign.')}</tspan>
       </text>
     </g>
           <g transform="translate(200 194)">
       <g className="css-1jk87co">
         <defs>
           <filter id="offsetFilter4" x="-1" y="-1" width="186.6" height="43.8">
             <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
           </filter>
         </defs>
         <rect filter="url(#offsetFilter4)" width="188.6" height="43.8" fill="rgba(0,0,0,0.50)" fillRule="nonzero"  rx=".9"></rect>
       </g>
       <rect className="css-0" width="188.6" height="43.8" fill={editbuttomBackground} fillRule="nonzero" rx=".9"></rect>
       <g className="css-1jk87co">
         <defs>
         <filter id="offsetFilter5" x="-1" y="-1" width="136" height="23.8">
             <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
           </filter>
         </defs>
         <rect filter="url(#offsetFilter5)" width="136" height="23.8" fill="rgba(0,0,0,0.50)" fillRule="nonzero" rx=".9" x="22" y="10"></rect>
         <rect width="136" height="23.8" fill={editbuttomBackground} fillRule="nonzero" rx=".9" x="22" y="10"></rect>
       </g>
       <text 
className="css-0" 
fill={buttomText} 
fontFamily="Helvetica-Bold, Helvetica" 
fontSize="12.3" 
fontWeight="bold" 
transform="translate(28 13)"
>
         <tspan x=".9" y="13">{t('REVIEW DOCUMENT')}</tspan>
       </text>
     </g>
         
         </g>
       </g>
     </svg>
   </span>

 </div>
                   </Row>
                 </TabPane>
          
               </TabContent>

</div>
       
     </div>
   </div>
 </div>
                   
                   </div>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitEdit}>{t('Save Changes')}</Button>
          <Button color="secondary" onClick={toggleModal}>{t('Cancel')}</Button>
        </ModalFooter>
      </Modal>
              <Modal
              size="lg"
              isOpen={modal_scroll}
              toggle={() => {
              tog_scroll();
              }}
              scrollable={true}
              >
              
                      <div className="modal-header">
                     
                        <h5 className="modal-title mt-0">
                        {t('Customize a Sending Brand')} 
                        </h5>
                        <button
                          type="button"
                          onClick={() =>
                            setmodal_scroll(false)
                          }
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="css-qf5tof">
      <div className="css-ix6jir">
        <div className="css-fta723"></div>
        {errorMessages && <p style={{ color: 'red' }}>{errorMessages}</p>}
        <div style={{ display: 'flex' }}>
          <div className="RcWeCMK_5wbOQGDuetx7" style={{ minWidth: '220px' }}>
        
            <div className="hQyI2mPrdJXCvjzvWr_A h6Dyc_0RkOthtRStRg3T">
            <div className="css-list">
                   
                   <div >
                   <label style={{fontSize:'12px',fontWeight:'600'}} htmlFor="VWpFAduzDw" className="css-iekl2y">{t('Brand Name *')} </label> 
                   <div className="css-hboir5">
               <Input
                className="css-12ihcxq"
                type="text"
                 value={brandname}  
                 onChange={(e) => setBrandName(e.target.value)}
                />
               </div>
                   </div>
                   <div >
                   <label style={{fontSize:'12px',fontWeight:'600'}} htmlFor="VWpFAduzDw" className="css-iekl2y">{t('Company Name')}</label> 
                   <div className="css-hboir5">
               <Input
                className="css-12ihcxq"
                type="text"
                value={companyname} 
                onChange={(e) => setCompanyName(e.target.value)}
                />
               </div>
                   </div>

                   </div>
              <header>
                <h2 style={{fontSize:'18px',fontWeight:'600'}}>{t('Logos')}</h2>
              </header>
             
              <div className="U4ua1_aVL9FEdgqUhSB2">
                <h4 style={{fontSize:'14px',fontWeight:'600'}} className="IixPejJalslN1nHnA2dc r9wZB_PBsIbUNl_tKVWU Aq4fEEQKY10alPYc193i">{t('Sending Logo')}</h4>
                <div className="G7jaZc86XiIKysmIyFAp">
                  <div className="JcZQ8ANnx3vqP6Jqhfvw">
                    <span className="FdWpOmwtO778cTuj86Pw">
                      <img
                        alt="Test Brand"
                        src={sendingLogoFile}
                        className="css-1sz9cjp"
                       
                      />
                    </span>
                    <span onClick={resetSendingLogo} data-qa="brand_logo_close_button" style={{ verticalAlign: 'middle', paddingLeft: '10px', cursor: 'pointer' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" fill="currentColor" focusable="false" data-qa="brand_close">
                        <path d="M20 5.36L18.64 4 12 10.65 5.36 4 4 5.36 10.65 12 4 18.66 5.34 20 12 13.35 18.66 20 20 18.66 13.35 12 20 5.36z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <input
                  accept="jpg,jpeg,gif,png"
                  id="s68K5CCwTJsdfdsf"
                  type="file"
                  onChange={handleSendingLogoChange}
                  className="css-12s44sk"
                />
              <label className="olv-button olv-ignore-transform css-goyd0e" htmlFor="s68K5CCwTJsdfdsf">
                  <span data-qa="brand_left_nav_upload_a_custom_email_logo_lbl-text" className="css-16hz1ch">{t('Upload Sending Logo')}</span>
                </label>
              <div className="U4ua1_aVL9FEdgqUhSB2">
                <h4  style={{fontSize:'14px',fontWeight:'600'}} className="IixPejJalslN1nHnA2dc r9wZB_PBsIbUNl_tKVWU Aq4fEEQKY10alPYc193i">{t('Email Logo')}</h4>
                <div className="G7jaZc86XiIKysmIyFAp">
                  <div className="JcZQ8ANnx3vqP6Jqhfvw">
                    <span className="FdWpOmwtO778cTuj86Pw">
                      <img
                        alt="Test Brand"
                        src={emailLogoFile}
                        className="css-1sz9cjp"
                      />
                    </span>
                    <span onClick={resetEmailLogo} data-qa="brand_logo_close_button" style={{ verticalAlign: 'middle', paddingLeft: '10px', cursor: 'pointer' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" fill="currentColor" focusable="false" data-qa="brand_close">
                        <path d="M20 5.36L18.64 4 12 10.65 5.36 4 4 5.36 10.65 12 4 18.66 5.34 20 12 13.35 18.66 20 20 18.66 13.35 12 20 5.36z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                
               
                <input
          accept="image/*"
          id="s68K5CCwTJ"
          type="file"
          className="css-12s44sk"
          onChange={handleEmailLogoChange}  // Implement your change handler function
        />
                <label className="olv-button olv-ignore-transform css-goyd0e" htmlFor="s68K5CCwTJ">
                  <span data-qa="brand_left_nav_upload_a_custom_email_logo_lbl-text" className="css-16hz1ch">{t('Upload Email Logo')}</span>
                </label>
              </div>
            </div>
            <div className="zkX22XJv_CDqK_o6beoO" id="tooltip-container">
              <span className="css-ku1ana" role="button" >
                <span className="css-7gzz9h">{t('Image Requirements')}</span>
              </span>
            </div>
            <div className="zkX22XJv_CDqK_o6beoO">
              <div>
                <header>
                  <h2 style={{fontSize:'18px',fontWeight:'600'}}>{t('Colors')}</h2>
                </header>
                <div>
                  <div className="css-1e2z90s"></div>
                </div>
               
                <div className="L049JjcqkWxmi1SN1MIQ">
                  
                  <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px' }}>
                    <button type="button" className="css-pkaues">
                    <input
         
         type="color"
         id="example-color-input"
         value={headerBackground}
         onChange={handleColorChangeHeaderBackground}
       />
                      <div>
                        <p className="css-1hj83rq">{t('Header Background')}</p>
                        <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#000000</p>
                      </div>
                    </button>
                  </div>
                  <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px', borderBottom: '1px solid rgb(224, 224, 224)' }}>
                    <button data-qa="headerText_selector" type="button" className="css-pkaues">
                    <input
         
         type="color"
         id="example-color-input"
         value={headerText}
         onChange={handleColorChangeHeaderText}
       />
                      <div>
                        <p className="css-1hj83rq">{t('Header Text')}</p>
                        <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#ffffff</p>
                      </div>
                    </button>
                  </div>

                  <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px', borderBottom: '1px solid rgb(224, 224, 224)' }}>
                    <button data-qa="headerText_selector" type="button" className="css-pkaues">
                    <input
         
         type="color"
         id="example-color-input"
         value={buttomBackground}
         onChange={handleColorChangeButtomBackground}
       />
                      <div>
                        <p className="css-1hj83rq">{t('Button Background')}</p>
                        <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#ffffff</p>
                      </div>
                    </button>
                  </div>


                  <div className="I9sBRc7lZ_ShcSFecjep OyGBHIBSY5ycu__B0Zi3" style={{ maxWidth: '200px', borderBottom: '1px solid rgb(224, 224, 224)' }}>
                    <button data-qa="headerText_selector" type="button" className="css-pkaues">
                    <input
         
         type="color"
         id="example-color-input"
         value={buttomText}
         onChange={handleColorChangeButtomText}
       />
                      <div>
                        <p className="css-1hj83rq">{t('Button Text')}</p>
                        <p className="olv-color-swatch olv-ignore-transform css-10o70l8">#ffffff</p>
                      </div>
                    </button>
                  </div>
                </div>
                
              
              </div>
            </div>
            <div className="zkX22XJv_CDqK_o6beoO" id="tooltip-container">
              <span className="css-ku1ana" role="button" >
                <span className="css-7gzz9h">{t('Color Requirements')}</span>
              </span>
            </div>
            <div className="css-7ge43u"></div>
            <div className="gBrEOum9gh8gfS7RQY3H"></div>
          </div>
          <div className="GRfnMhqudHi6fULN6PJw" style={{ background: 'rgb(244, 244, 244)', flexGrow: 1, width: '480px' }}>
          <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab2 === "1",
                        })}
                        onClick={() => {
                          toggleCustom2("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">{t('Signing')}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab2 === "2",
                        })}
                        onClick={() => {
                          toggleCustom2("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">{t('Email')}</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  <TabContent
                    activeTab={customActiveTab2}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                      <div className="css-1g2akdj">
      <span aria-hidden="true" className="SVGInline">
        <svg className="SVGInline-svg" viewBox="0 0 835 1127" focusable="false">
          <defs>
            <circle id="a" cx="16" cy="16" r="16"></circle>
          </defs>
          <g fill="none" fillRule="evenodd">
            <path className="css-0" fill="#E9E9E9" d="M0 0h835v1127H0z"></path>
            <path fill="#FFF" fillRule="nonzero" d="M16.038 149H817.96v963H16.04z"></path>
            <g className="css-0" fillRule="nonzero">
              <path fill="#E9E9E9" d="M59 191.2h94.8v16H59zM59 471h707v16H59zM59 231h707v16H59zM59 311h707v16H59zM59 391h707v16H59zM59 511h707v16H59zM59 591h707v16H59zM59 791h707v16H59zM59 271h707v16H59zM59 351h707v16H59zM59 431h707v16H59zM59 551h707v16H59zM59 751h707v16H59zM59 631h707v16H59zM59 831h707v16H59zM59 711h707v16H59zM59 911h707v16H59zM59 671h707v16H59zM59 871h707v16H59zM59 951h337v16H59z"></path>
              <path fill="#F5C431" d="M165 391h84.8v16H165zM559 551h84.8v16H559zM59 1037h84.8v16H59z" opacity=".5"></path>
            </g>
            <g>
              <path fill="#FFF" fillRule="nonzero" d="M0 0h835v129H0z"></path>
              <text className="css-0" fill="#333" fontFamily="MavenProBold, Maven Pro" fontSize="17.6" fontWeight="bold">
                <tspan x="24.1" y="26">{t('Please Review Act on These Documents')}</tspan>
              </text>
              <text className="css-0" fill="#333" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontSize="10.4" fontWeight="bold">
                <tspan x="66.6" y="58">{t('Amy Templeton')}</tspan>
              </text>
              <text className="css-0" fill="#999" fontFamily="HelveticaNeue, Helvetica Neue" fontSize="10.4">
                <tspan x="65.8" y="70">{t('Example Company')}</tspan>
              </text>
              <circle className="css-0" cx="16" cy="16" r="16" fill="#B8BFCD" fillRule="nonzero" stroke="#B8BFCD" strokeWidth="1.2" transform="translate(24 49)"></circle>
              <g>
                <g className="css-1jk87co">
                  <defs>
                    <filter id="offsetFilter1" x="-1" y="-1" width="450" height="25">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                    </filter>
                  </defs>
                  <rect x="667" y="28" width="139" height="46" filter="url(#offsetFilter1)" fill="rgba(0,0,0,0.50)"></rect>
                  <rect x="667" y="28" width="139" height="46" style={{ fill: '#fff' }}></rect>
                </g>
              </g>
              <image x="667" y="28" width="139" height="46" className="css-0"  href={emailLogoFile}></image>
              <g transform="translate(24 49)">
                <mask id="b" fill="#fff">
                  <use href="#a"></use>
                </mask>
                <g mask="url(#b)">
                  <path fill="#FFF" fillRule="nonzero" d="M16 18a5.4 5.4 0 1 1 .1-11 5.4 5.4 0 0 1 0 11zm0 1.3c5.5 0 11 2.8 11 5.6v8H5.2v-8c0-2.8 5.5-5.6 10.9-5.6z"></path>
                </g>
              </g>
              <g>
                <g className="css-1jk87co">
                  <defs>
                    <filter id="offsetFilter2" x="-1" y="-1" width="834" height="38">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                    </filter>
                  </defs>
                  <rect filter="url(#offsetFilter2)" width="834" height="38" x="0" y="97.8" fill="rgba(0,0,0,0.50)"></rect>
                </g>
                <path className="css-0" fill={headerBackground} fillRule="nonzero" d="M0 97.8h834.2v37.7H0z"></path>
                <g className="css-1jk87co">
                  <defs>
                    <filter id="offsetFilter3" x="-1" y="-1" width="210" height="20">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                    </filter>
                  </defs>
                  <rect filter="url(#offsetFilter3)" width="210" height="20" x="-60" y="-30" fill="rgba(0,0,0,0.50)" transform="translate(77 136)"></rect>
                  <rect width="210" height="20" x="-60" y="-30"  transform="translate(77 136)"></rect>
                </g>
                <text className="css-0" fill={headerText} fontFamily="HelveticaNeue, Helvetica Neue" fontSize="12" transform="translate(24 104.8)">
                  <tspan x=".1" y="15">{t('Please review the documents below.')}</tspan>
                </text>
                <g transform="translate(711 102.8)">
                  <g className="css-1jk87co">
                    <defs>
                      <filter id="offsetFilter4" x="-1" y="-1" width="186.6" height="43.8">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                      </filter>
                    </defs>
                    <rect filter="url(#offsetFilter4)" width="96.2" height="27.3" x=".3" y=".6" fill="rgba(0,0,0,0.50)" fillRule="nonzero" rx="2.4"></rect>
                  </g>
                  <rect className="css-0" width="96.2" height="27.3" x=".3" y=".6" fill={buttomBackground} fillRule="nonzero" rx="2.4"></rect>
                  <g className="css-1jk87co">
                    <defs>
                      <filter id="offsetFilter5" x="-1" y="-1" width="136" height="23.8">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
                      </filter>
                    </defs>
                    <rect filter="url(#offsetFilter5)" width="72" height="18" fill="rgba(0,0,0,0.50)" fillRule="nonzero" rx=".9" x="10" y="6"></rect>
                    <rect width="72" height="18" fill={buttomBackground} fillRule="nonzero" rx=".9" x="10" y="6"></rect>
                  </g>
                  <text className="css-0" fill={buttomText} fontFamily="MavenProBold, Maven Pro" fontSize="12" fontWeight="bold" transform="translate(15 8)">
                    <tspan x=".5" y="10.6">{t('CONTINUE')}</tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
    </div>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                      <div className="css-llvpmk">
      <span aria-hidden="true" className="SVGInline">
        <svg className="SVGInline-svg" viewBox="0 0 636 1127" focusable="false">
          <defs>
            <ellipse id="a" cx="21.7" cy="21.8" rx="21.7" ry="21.8" />
          </defs>
          <g fill="none" fillRule="evenodd">
            <g className="css-0">
              <path fill="#FFF" fillRule="nonzero" d="M0 0h636v1127H0z" />
              <path fill="#E9E9E9" fillRule="nonzero" d="M25 449h64.8v9H25zM25 474h145.8v9H25zM25 491h145.8v9H25z" />
              <ellipse
                cx="21.7"
                cy="21.8"
                fill="#B8BFCD"
                fillRule="nonzero"
                stroke="#B8BFCD"
                strokeWidth=".9"
                rx="21.7"
                ry="21.8"
                transform="translate(24.8 383.7)"
              />
              <g transform="translate(24.8 383.7)">
                <mask id="b" fill="#fff">
                  <use xlinkHref="#a" />
                </mask>
                <g mask="url(#b)">
                  <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M21.7 24.6c-4 0-7.4-3.3-7.4-7.4a7.4 7.4 0 1 1 14.7 0c0 4.1-3.3 7.4-7.3 7.4zm0 1.9c7.3 0 14.7 3.8 14.7 7.6V45H7V34c0-3.7 7.3-7.5 14.7-7.5z"
                  />
                </g>
              </g>
              <path fill="#E9E9E9" fillRule="nonzero" d="M80.2 393.7H145v9H80.2zM80.2 410.7H145v9H80.2z" />
              <g fillRule="nonzero">
                <path fill="#EAEAEA" d="M24 528h588v575H24z" />
                <g fill="#CCC">
                  <path d="M51.6 556.4h145.8v9H51.6zM51.6 573.6h528.3v9H51.6zM51.6 590.8h76.5v9H51.6zM52 616h141.3v9H52zM52 828h141.3v9H52zM52 963h141.3v9H52zM51.6 675h141.3v9H51.6zM52 752h180v9H52zM52 887h180v9H52zM52 1022h180v9H52zM52 633h387v9H52zM52 845h387v9H52zM52 980h387v9H52zM51.6 692.2h519.3v9H51.6zM52 769h524.7v9H52zM52 904h524.7v9H52zM52 1039h524.7v9H52zM52 803h524.7v9H52zM52 938h524.7v9H52zM52 786h524.7v9H52zM52 921h524.7v9H52zM52 1056h524.7v9H52zM52 1073h524.7v9H52zM52 650h231.3v9H52zM52 862h231.3v9H52zM52 997h231.3v9H52zM51.6 709.4h519.3v9H51.6zM51.6 726.6h241.2v9H51.6z" />
                </g>
              </g>
            </g>
            <g transform="translate(24 82)">
              <g className="css-1jk87co">
                <defs>
                  <filter id="offsetFilter1" x="-1" y="-1" width="588" height="272">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                  </filter>
                </defs>
                <rect filter="url(#offsetFilter1)" width="588" height="272" x="0" y="0" fill="rgba(0,0,0,0.50)" />
              </g>
              <path className="css-0" fill={headerBackground} fillRule="nonzero" d="M0 0h588v272.1H0z" />
              <g>
                <g className="css-1jk87co">
                  <defs>
                    <filter id="offsetFilter2" x="-1" y="-1" width="450" height="25">
                      <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                    </filter>
                  </defs>
                  <rect x="0" y="-62" width="139" height="46" filter="url(#offsetFilter2)" fill="rgba(0,0,0,0.50)" />
                  <rect x="0" y="-62" width="139" height="46" style={{ fill: '#fff' }} />
                </g>
              </g>
              <image
                x="0"
                y="-62"
                width="139"
                height="46"
                className="css-0"
                xlinkHref={emailLogoFile}
               
              />
              <image
                className="css-0"
                width="80"
                height="80"
                x="254"
                y="26"
                xlinkHref={emailLogoFile}
              />
              <g>
          <g className="css-1jk87co">
            <defs>
              <filter id="offsetFilter3" x="-1" y="-1" width="450" height="25">
                <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
              </filter>
            </defs>
            <rect filter="url(#offsetFilter3)" width="400" height="27" x="-2" y="-4" fill="rgba(0,0,0,0.50)" transform="translate(96 136)"></rect>
            <rect width="400" height="27" x="-2" y="-4" fill={headerBackground} transform="translate(96 136)"></rect>
          </g>
          <text className="css-0" fill={headerText} fontFamily="HelveticaNeue, Helvetica Neue" fontSize={15}  transform="translate(106 136)">
            <tspan x="-2" y="14">{t('Amy Templeton sent you a document to review and sign.')}</tspan>
          </text>
        </g>
              <g transform="translate(200 194)">
          <g className="css-1jk87co">
            <defs>
              <filter id="offsetFilter4" x="-1" y="-1" width="186.6" height="43.8">
                <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
              </filter>
            </defs>
            <rect filter="url(#offsetFilter4)" width="188.6" height="43.8" fill="rgba(0,0,0,0.50)" fillRule="nonzero"  rx=".9"></rect>
          </g>
          <rect className="css-0" width="188.6" height="43.8" fill={buttomBackground} fillRule="nonzero" rx=".9"></rect>
          <g className="css-1jk87co">
            <defs>
            <filter id="offsetFilter5" x="-1" y="-1" width="136" height="23.8">
                <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
              </filter>
            </defs>
            <rect filter="url(#offsetFilter5)" width="136" height="23.8" fill="rgba(0,0,0,0.50)" fillRule="nonzero" rx=".9" x="22" y="10"></rect>
            <rect width="136" height="23.8" fill={buttomBackground} fillRule="nonzero" rx=".9" x="22" y="10"></rect>
          </g>
          <text 
  className="css-0" 
  fill={buttomText} 
  fontFamily="Helvetica-Bold, Helvetica" 
  fontSize="12.3" 
  fontWeight="bold" 
  transform="translate(28 13)"
>
            <tspan x=".9" y="13">{t('REVIEW DOCUMENT')}</tspan>
          </text>
        </g>
            
            </g>
          </g>
        </svg>
      </span>
   
    </div>
                      </Row>
                    </TabPane>
             
                  </TabContent>
   
  </div>
          
        </div>
      </div>
    </div>
                      
                      </div>
                      <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() =>
                              setmodal_scroll(false)
                            }
                          >
                           {t('Cancel')} 
                          </button>
                          <button type="submit" className="btn btn-primary"  onClick={handleSubmit}>
                          {t('Save Changes')}
                          </button>
                        </div>
                     </Modal>
         <div className="inbox-container" style={{overflow:'hidden'}}>
         
              <div className="row" >
              {iSplan_name === "starter" ? (
            <div className="col-lg-12">
              <Card>
                <CardBody>
                  <CardTitle>{t('Upgrade your plan')}</CardTitle>
                  <p>{t('You have reached the limit of the start plan. Please upgrade your account to continue.')} </p>
                  <Link to={`/plan-and-price/${token}`}>
                    <Button color="Primary" className="btn olv-button buttons-container olv-ignore-transform css-1bl3roz startbtn">
                    {t('View Plans')}
                    </Button>
                  </Link>
                </CardBody> 
              </Card>
            </div>
          ) : (
            <div className="col-lg-12">
                 
            <Card>
          <CardBody>
        
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">{t('Signing')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block">{t('Sending')}</span>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent
              activeTab={customActiveTab}
              className="p-3 text-muted"
            >
              <TabPane tabId="1">
              <CardTitle className="h2">{t('Customize What Recipients See')}</CardTitle>
            
                <Row >
                {currentItems.length > 0 ? (
currentItems.map((item) => (
<Col sm="4" key={item.branding_id} style={{ marginTop: '10px' }}>
<div className="css-1frfvw8">
  <div className="css-7r1z99">
    <div className="css-6t6lyd">
      <div className="css-1shlyym">
        <span className="cardHoverButton css-1qq4o7v" style={{ cursor: 'pointer' }} onClick={() => setDefaultBrand(item.branding_id)}>
          <button onClick={() => setDefaultBrand(item.branding_id)} type="button" className="olv-button olv-ignore-transform css-1rc02wc css-1qq4o7vbtns">
            <a aria-hidden="true" className="css-1ixbp0l">{t('Set Default Brand')}</a>
            <span className="css-1am6aje">{t('Set Default Brand')}</span>
          </button>
        </span>
        <span className="css-78lt08">
          <span className="css-naupo3">{item.branding_status}</span>
        </span>
        <div className="css-32d82q">
          <span className="css-150ral2">
            <img alt="Test Brandsdsd" src={item.sending_logo} className="css-1sz9cjp" />
          </span>
        </div>
      </div>
    </div>
    <div className="css-6t6lyd">
      <div className="fxsjMpXQ_su8WhSkN8nm css-ipf7y1">
        <div className="w4EGE1yYMfGWs7nXAypr css-60g013">{item.brand_name}</div>
        <div className="css-8qo53f23">
          <button type="button" className="olv-button olv-ignore-transform css-eolcwi" onClick={() => toggleDropdown(item.branding_id)}>
            <span className="css-g4t7oo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" fill="currentColor" focusable="false">
                <path d="M10 12a2 2 0 1 1 2 2 2 2 0 0 1-2-2zm2-6a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 16a2 2 0 1 0-2-2 2 2 0 0 0 2 2z"></path>
              </svg>
            </span>
          </button>
          <Dropdown isOpen={dropdownOpen[item.branding_id]} toggle={() => toggleDropdown(item.branding_id)}>
  <DropdownMenu>
    {isEditorAllowed ? (
      <>
        <DropdownItem onClick={() => editItemHandler(item)}>{t('Edit')}</DropdownItem>
        <DropdownItem onClick={() => deleteItem(item.branding_id)}>{t('Delete')}</DropdownItem>
      </>
    ) : (
      <DropdownItem disabled>{t('You do not have permission to perform these actions')}</DropdownItem>
    )}
  </DropdownMenu>
</Dropdown>

        </div>
      </div>
    </div>
  </div>
</div>
</Col>
))
) : (
<h4>{t('No Record Found')}</h4>

)}

                </Row>
             
                <Row style={{ marginTop: "10px" }}>
                <Col xs="7" style={{ display: info.length > 0 ? 'block' : 'none' }}>
                {t('Showing')} {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, info.length)}{t('of')}  {info.length}
</Col>
  <Col xs="5">
    <div className="btn-group float-end">
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
      >
        <i className="fa fa-chevron-left" />
      </Button>
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={() =>
          paginate(
            currentPage < Math.ceil(info.length / itemsPerPage) ? currentPage + 1 : currentPage
          )
        }
      >
        <i className="fa fa-chevron-right" />
      </Button>
    </div>
  </Col>
</Row>
              </TabPane>
              <TabPane tabId="2">
              <h2 className="h2">{t('Customize What Recipients See')} </h2>
                <Row>
                 
                  <div className="L049JjcqkWxmi1SN1MIQ">
<p>{t('Add your logo and colors to your sending experience.')}</p>
</div>
<div className="Q8oHp7bEjhmehslY4uZL">
<div className="css-1joc5e2">
<div type="sending" className="css-4rf33w">
<div>
<h4>{t('Sending')}</h4>
</div>
<div className="css-deihcw">

<img alt="Test Brand" src={sendingLogoFileDefault} className="css-1sz9cjp" />

</div>
</div>
<div type="sending" className="css-4rf33w">
<div>
<h4>{t('Email Logo')}</h4>
</div>
<div className="css-deihcw">
<img alt="Test Brand" src={emailLogoFileDefault} className="css-1sz9cjp" />

</div>
</div>
<div className="css-155374s"><div>
<h4>{t('Colors')}</h4>
</div>
<div className=" qwmnuOGOC1wmlU6tG8yQ bY_yVLq5GDOtwoNfuMMN" style={{ backgroundColor: headerBackgroundDefault }}></div>
<div className=" qwmnuOGOC1wmlU6tG8yQ bY_yVLq5GDOtwoNfuMMN" style={{ backgroundColor: headerTextDefault }}></div>
<div className=" qwmnuOGOC1wmlU6tG8yQ bY_yVLq5GDOtwoNfuMMN"  style={{ backgroundColor: buttomBackgroundDefault }}></div>
<div className=" qwmnuOGOC1wmlU6tG8yQ bY_yVLq5GDOtwoNfuMMN"  style={{ backgroundColor:buttomTextDefault}}></div>

</div>
</div>
</div>
<div className="qRVNXulY64Dd0f5OBGQb">
{isEditorAllowed && (
  <button 
    className="olv-button olv-ignore-transform css-1t3ymg9" 
    onClick={() => {
      tog_scroll();
    }}
    data-toggle="modal"
    type="button"
  >
    <span className="css-16hz1ch">{t('Edit Your Theme')}</span>
  </button>
)}

</div>
<div>
<h3>{t('Advanced Configuration')}</h3>
<p>{t('Modify advanced settings to customize destination URLs.')}</p>
</div>
<div>
{/* <span className="css-ku1ana" role="button">
<span className="css-7gzz9h">{t('Header and Footer Links')}</span>
</span> */}
</div>
                 
                </Row>
              </TabPane>

            </TabContent>
          </CardBody>
        </Card>
            </div>
          )}
             
                </div>
            </div>
    </React.Fragment>
  );
};


export default withTranslation()(withRouter(Branding));
