import axios from "axios";
import { del, get, post, put  } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method

//post setfolderonmails POST_FAKE_REGISTER


const postFakeRegister = (userData) => {
  return axios
    .post(`https://dspmetrics.com${url.POST_FAKE_REGISTER}`, userData)
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! The page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! Something went wrong. Please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err.response.data.message || "An error occurred";
            break;
        }
      }
      throw message;
    });
};
// Login Method

const postFakeLogin = (data) => {
 
  return axios
    .post(`${url.POST_FAKE_LOGIN}`, data)
    .then((response) => {
   
      if (response.status >= 200 && response.status <= 299) return response.data;
      throw response.data;
    })
    .catch((err) => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! The page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! Something went wrong. Please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err.response.data.message || "An error occurred";
            break;
        }
      }
      throw message;
    });
};

const postFakeForgetPwd = data => {
  
  return post(url.POST_FAKE_PASSWORD_FORGET, data);
};

// postForgetPwd
//const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);
// postDcouments
//const postDcouments = data => post(url.POST_DOCUMENTS, data);
// postSignature
const postSignature = data => post(url.POST_SIGNATUR, data);

// postHandwriting
const postHandwriting = data => post(url.POST_HANDWRITNG, data);
// postStemp
const postStemp = data => {
  data =>  post(`${url.POST_STEMP}?token=${data.token}&fullNames=${data.fullNames}`, data)
};




export const postReciepien = (data) => {
  const formData = new FormData();
  data.files.forEach((file) => {
    formData.append("files", file);
  });
  formData.append("recipients", JSON.stringify(data.recipients));
    formData.append("userId", userId);
    formData.append("uuid", uuid);
    formData.append("status", isstatus);
    formData.append("token", token);
    formData.append("fullNames", fullNames)
  // You need to adjust this endpoint based on your API
  return post(`${url.POST_RECIEPIEN_DOC}?token=${data.token}&fullNames=${data.fullNames}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// postNewDoc
const postNewDoc = data => post(url.POST_NEW_DOC, data);
// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Documents
//export const getDocuments = () => get(url.GET_DOCUMENTS);
// get Signature

export const getSignatures  = userId  =>
  get(`${url.GET_SIGNATUR}/${userId}`, { params: { userId } });

export const getSignerData  = userId  =>
  get(`${url.GET_DOC_SIGN_DETAIL}/${userId}`);

export const getTemplate  = template  =>
  get(`${url.GET_TEMPLATE}/${template}`);
export const getTemplateData  = templatedata  =>
  get(`${url.DELETE_TEMPLATE_DATA}/${templatedata}`);
export const deleteTemplate  = deletetemp  =>
  post(`${url.DELETE_TEMPLATE}/${deletetemp}`);

  export const getSignatureImg  = email  =>
  get(`${url.GET_SIGNATURIMG}/${email}`, { params: { email } });

  export const getDocumentName  = id  =>
  get(`${url.GET_DOCUMENT}/${id}`, { params: { id } });
// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });
//post setfolderonmails
export const postresend = postdata => post(url.POST_RESEND, postdata);
//post setfolderonmails
export const updatepoststatus= statusid => post(`${url.UPDATE_POST_STATUS}/${statusid}`);
//post setfolderonmails
export const addvalidate = validdata => post(url.ADD_NEW_VALID, validdata);

//post setfolderonmails 
export const addNewFolder = newfolder => post(url.ADD_NEW_FOLDER, newfolder);
 
//post setfolderonmails
export const upatefolder = updatefolder => post(url.UPDATE_FOLDER, updatefolder);
//post setfolderonmails
export const deletepostdataapi = deletepost => post(url.DELETE_POSTDATA, deletepost);

export const deletepermantlyapi = permantly => post(url.PERMANENTLY_DELETE, permantly);

export const recoveryapi = revovery => post(url.RECOVERY_MAIL, revovery);
export const closeapi= userid => post(`${url.ACCOUNT_CLOSE}/${userid}`);
export const getprofilesapi = (profiles) => {
  return get(`${url.GET_ACCOUNT_PROFILE}/${profiles}`);
};

export const editprofilesapi = profilesdata => post(url.EDIT_ACCOUNT_PROFILE, profilesdata);
export const invoiceapi= invoice => get(`${url.GET_ACCOUNT_INVOICE}/${invoice}`);
export const getbrandingapi = info => get(`${url.GET_BRANDING_INFO}/${info}`);
export const postbrandingapi = postinfo => post(url.POST_BRANDING_INFO, postinfo);
export const editbrandingapi= putinfo => post(`${url.EDIT_BRANDING_INFO}/${putinfo}`);
export const deletebrandingapi= deleteinfo => post(`${url.DELETE_BRANDING_INFO}/${deleteinfo}`);
export const upatepostfolderapi = updatepostfolderdata => post(url.UPDATE_POST_FOLDER_API, updatepostfolderdata);

export const postruletemp = temps => post(url.POST_TEMPLATE_RULE, temps);
export const gettemprule= rules => get(`${url.GET_TEMPLATE_RULE}/${rules}`);
export const puttemprule = puttemps => post(url.PUT_TEMPLATE_RULE, puttemps);
export const deletetemprule= removeid => post(`${url.DELETE_TEMPLATE_RULE}/${removeid}`);


export const postruleuser = userrule => post(url.POST_User_RULE, userrule);
export const getuserrule= getuserrule => get(`${url.GET_User_RULE}/${getuserrule}`);
export const putuserrule = putuserrule => post(url.PUT_User_RULE, putuserrule);
export const deleteuserrule= removeuser => post(`${url.DELETE_User_RULE}/${removeuser}`);

export const getPermission= permissionid => get(`${url.GET_PPERMISSION}/${permissionid}`);

const postSignData = jsonpostdata => {
  return post(`${url.POST_SIGN_DATA}/${jsonpostdata.uuid}`, jsonpostdata);
};

// Absence 
export const getAccountDataApi= getaccountdata => get(`${url.GET_ACCOUNT_DATA}/${getaccountdata}`);
export const getAbsencePolicyDataApi= getabsencepolicy => get(`${url.GET_ABSENCE_POLICY}/${getabsencepolicy}`);
export const getAbsenceTypeApi= getabsencetype => get(`${url.GET_ABSENCE_TYPE}/${getabsencetype}`);
export const getAbsenceApi= getabsence => get(`${url.GET_ABSENCE}/${getabsence}`);
export const getAllAbsenceApi= getalltabsence => get(`${url.GET_ALL_ABSENCE}/${getalltabsence}`);
export const getCorrectionApi= getcorrection => get(`${url.GET_CORRECTION}/${getcorrection}`);
export const putAccountDataApi= putaccountdata => post(url.PUT_ACCOUNT_DATA,putaccountdata);
export const postAbsencePolicyDataApi= postabsencepolicy => post(url.POST_ABSENCE_POLICY,postabsencepolicy);
export const putAbsencePolicyDataApi= putabsencepolicy => post(url.PUT_ABSENCE_POLICY,putabsencepolicy);
export const deleteAbsencePolicyApi= deleteabsencepolicy => del(`${url.DELETE_ABSENCE_POLICY}/${deleteabsencepolicy}`);
export const postAbsencetypeApi= postabsencetype => post(url.POST_ABSENCE_TYPE,postabsencetype);
export const putAbsencetypeApi= putabsencetype => post(url.PUT_ABSENCE_TYPE,putabsencetype);
export const deleteAbsenceTypeApi= deleteabsencetype => del(`${url.DELETE_ABSENCE_TYPE}/${deleteabsencetype}`);
export const putAbsenceApi= putabsence => put(url.PUT_ABSENCE,putabsence);
export const postAbsenceApi= postabsence => post(url.POST_ABSENCE,postabsence);
export const deleteAbsenceApi= deleteabsence => del(`${url.DELETE_ABSENCE}/${deleteabsence}`);
export const postCorrectionApi= postcorrection => post(url.POST_CORRECTION,postcorrection);
export const putCorrectionApi= putcorrection => put(url.PUT_CORRECTION,putcorrection);
export const getFullAbsenceAccess= useraccess => get(`${url.GET_EMPLOYEERS_FULL_ABSENCE}/${useraccess}`);
export const getAbsenceTotalApi= getTotalAbsence => get(`${url.GET_ABSENCE_TOTAL}/${getTotalAbsence.id}/${getTotalAbsence.year}`);
export const getHoursTotalApi= ContractAndWorkHours => get(`${url.GET_HOURS_TOTAL}/${ContractAndWorkHours.id}/${ContractAndWorkHours.year}`);
export const postInspectionTimeSheetApi= inspection => post(url.POST_INSPECTION,inspection);

export const getSurchargerApi= getsurcharger => get(`${url.GET_SURCHARGER}/${getsurcharger}`);
export const putSurchargerApi= putsurcharger => post(url.PUT_SURCHARGER,putsurcharger);
export const postSurchargerApi= postsurcharger => post(url.POST_SURCHARGER,postsurcharger);
export const deleteSurchargerApi= deletesurcharger => post(`${url.DELETE_SURCHARGER}/${deletesurcharger}`);
// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);
export const getSettingApi= setData => get(url.GET_SETTING,setData);
export const getHelpCenterApi= helpCenterData => get(url.GET_HELPCENTER,helpCenterData);
export const getBlogDataApi= blogData => get(url.GET_BLOGDATA,blogData);
export const getBlogCatApi= blogCatData => get(url.GET_BLOGCATDATA,blogCatData);
// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices


// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });


export const getcollaborators = client => get(`${url.GET_USERS}/${client}`)

// add user
// export const addNewUser = userdata => {
//   return axios.post(url.ADD_NEW_USER, userdata, {
//     headers: {
//       'Content-Type': 'multipart/form-data', // Ensure correct headers
//     },
//   });
// };
export const addNewUser= userdata => post(url.ADD_NEW_USER,userdata);

export const updateUser= putuser => post(url.UPDATE_USER,putuser);
export const getContract= contract => get(`${url.GET_CONTRACT}/${contract}`);
export const getNotification= notification => get(`${url.GET_NOTIFICATION}/${notification}`);
export const postContract= contractdata => post(url.POST_CONTRACT,contractdata);
export const getXLS= xlsid => get(`${url.GET_XLS}/${xlsid}`);

export const postDepsapi= postDeps => post(url.POST_DEPART, postDeps);

export const deleteDepsapi= deleteDeps => post(`${url.DELETE_DEPART}/${deleteDeps}`);
export const postTeamsapi= postTeams => post(url.POST_TEAM,postTeams);
export const postNewTeamApi= postnewteam => post(url.POST_NEW_TEAM, postnewteam);
export const putTeamsapi= putTeams => post(`${url.PUT_TEAM}/${putTeams}`);
export const deleteTeamsapi= deleteTeams => post(`${url.DELETE_TEAM}/${deleteTeams}`);
export const postContractTypesapi= postContractTypes => post(url.POST_CONTRACT_TYPE,postContractTypes);
export const putContractTypesapi= putContractTypes => post(`${url.PUP_CONTRACT_TYPE}/${putContractTypes}`);
export const deleteContactTypesapi= deleteContactTypes => post(`${url.DELETE_CONTRACT_TYPE}/${deleteContactTypes}`);
export const postLinkapi = (link) => post(url.POST_LINK, link);
export const putProofApi = (proof) => post(url.PUT_PROOF, proof);
export const postDocApi = (doc) => post(url.POS_DOCUMENT_COLLABORATOR, doc);
export const postProfileApi = (profile) => post(url.PUT_COLLABORATOR_PROFILE, profile);


// SHIFT

export const getShiftApi= shift => get(`${url.GET_SHIFT}/${shift}`);
export const getTeamdataApi= teamdata => get(`${url.GET_TEAM_DATA}/${teamdata}`);
export const postShiftApi= shiftdata => post(url.POST_SHIFT,shiftdata);
export const putShiftApi= putshift => post(url.PUT_SHIFT,putshift);
export const deleteShiftApi= removeshift => post(url.DELETE_SHIFT,removeshift);
export const postUrenApi= posturen => post(url.POST_UREN,posturen);
 export const getUrenApi = (urendata) => {
  return post(url.GET_UREN, urendata);
};
export const getScheduleApi = (schedule) => {
  return post(url.GET_UREN_SCHEDULE, schedule);
};
export const putDepsapi = (putDeps) => {
  return post(url.PUT_DEPART, putDeps);
};
//export const putDepsapi= putDeps => post(url.PUT_DEPART,putDeps);
export const putUrenApi= puturen => post(url.PUT_UREN,puturen);
export const removeUrenApi= removeuren => post(url.DELETE_UREN,removeuren);
export const getContractEmployerApi= contractemployer => get(`${url.GET_CONTRACT_EMPLOYEERS}/${contractemployer}`);


export const postReportApi= postreportdata => post(url.POST_REPORT,postreportdata);
export const removeReportApi = removereportdata => del(`${url.DELETE_REPORT}/${removereportdata}`);
export const getReportApi= getreportdata => get(`${url.GET_REPORT}/${getreportdata}`);
export const postReportAbsenceApi= postreportdataabsence => post(url.POST_REPORT_ABSENCE,postreportdataabsence);

export const getVhiclesApi=  getVhiclesdata => get(`${url.GET_VEHICLES}/${getVhiclesdata}`);
export const postVhiclesApi = postVhiclesdata => post(url.POST_VEHICLES,postVhiclesdata);
export const putVhiclesApi= putVhiclesdata => post(url.PUT_VEHICLES,putVhiclesdata);
export const delVhiclesApi= delVhiclesdata => post(url.DELETE_VEHICLES,delVhiclesdata);

export const getVhiclesDocApi=  getVhiclesDocdata => get(`${url.GET_VEHICLES_DOC}/${getVhiclesDocdata}`);
export const postVhiclesDocApi = postVhiclesDocdata => post(url.POST_VEHICLES_DOC,postVhiclesDocdata);
export const putVhiclesDocApi= putVhiclesDocdata => post(url.PUT_VEHICLES_DOC,putVhiclesDocdata);
export const delVhiclesDocApi= delVhiclesDocdata => post(url.DELETE_VEHICLES_DOC,delVhiclesDocdata);

// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);

export const getWebInfo = (websetting) => {
  console.log(url.GET_WEB_INFO, websetting,'url.GET_WEB_INFO, websetting')
  return get(url.GET_WEB_INFO, websetting);
};

// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });

// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get maillist
export const getMailsLists  = id  =>
  get(`${url.GET_MAILS_LIST}/${id}`, { params: { id } });

//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist

export const selectFolders = (userId) => {
  const endpoint = `${url.SELECT_FOLDER}/${userId}`;
  return get(endpoint);
};


// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });


export {
  postFakeRegister,
  getLoggedInUser,
  isUserAuthenticated,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  postSignature,
  postHandwriting,
  postStemp,
  postNewDoc,
  postSignData,
};
