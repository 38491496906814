import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import CustomNavbar from "components/HeaderUnUser/Header";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate ,useHistory } from 'react-router-dom';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// actions
import { loginUser } from "../../store/actions";
import { withTranslation } from "react-i18next";
import logo from "assets/images/logo.svg";

const Login = (props) => {
  const { t } = props;

  //meta title
  useEffect(() => {
    document.title = t("Login Title");
  }, [t]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password:'',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Please Enter Your Email")),
      password: Yup.string().required(t("Please Enter Your Password")),
    }),
    onSubmit: async (values) => {
      try {
        
        const formData = new URLSearchParams();
        formData.append('email', values.email);
        formData.append('password', values.password);
      
        const response = await fetch("https://dspmetrics.com/post-fake-login", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: formData.toString()
        });
      const data = await response.json();
     
      if (data.success) {

        dispatch(loginUser(data.user, navigate));
     
      } else {
        toastr.error(data.message);
        setErrorMessage(data.message);
      }
    } catch (error) {
      
      setErrorMessage(t("An error occurred during login Please try again"));
      console.error("Error during login:", error);
    }
    }
  });
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));


 

 

  return (
    <React.Fragment>
          <CustomNavbar />
      <div className="">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col xs={12}>
                      <div style={{backgroundColor:'#007bff'}} className="text-white p-1 flex justify-content-center">
                        <h4 className="text-white ">{t("Enter Your Credentials")}</h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
  {error || errorMessage ? (
        <Alert color="danger">
          {error ? <span>{error.toString()}</span> : null}
          {errorMessage ? <span>{errorMessage}</span> : null}
        </Alert>
      ) : null}

       
            <div className="mb-3">
                        <Label className="form-label">{t("Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={t("Type Your Email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{t("Password")}</Label>
                        <Input
                        name="password"
                        value={validation.values.password || ""}
                        type="password"
                        placeholder={t("Type Your Password")}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={validation.touched.password && validation.errors.password ? true : false}
                        autoComplete="current-password" // Updated attribute name
                       />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {t("Remember me")}
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          style={{backgroundColor:'#007bff'}}
                        >
                            {t("Log In")}
                        </button>
                      </div>

                   

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {t("Forgot your password?")}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                {t("Don't have an account ?")}{" "}
                  <Link to="/register/starter" className="fw-medium text-primary">
                    {" "}
                    {t("Signup now")}{" "}
                  </Link>{" "}
                </p>
                <p>
                  Copyright © {new Date().getFullYear()} Shiftspec, Inc. All rights reserve{" "}
                  <i className="mdi mdi-heart text-danger" /> 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};



Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.isRequired,
};

export default withTranslation()(withRouter(Login));