// PaymentForm.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

// Load your Stripe public key
const stripePromise = loadStripe('pk_test_51LS80uABzhlLVUaglgWWvmRImFx0rW5GWWytSoVaSv9S3XpdDxXUvQBoDtk0sJunpKRzhQwz3a2T6IHg89IIvVf200Z6RwKCB8');

const PaymentForm = ({registerData}) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm registerData={registerData} />
    </Elements>
  );
};

export default PaymentForm;
