import React, { useRef, useState, useEffect ,useCallback} from "react";
import { Link, useNavigate ,useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";

import {
  Button,
  Table,
  Card,
  Col,
  CardText,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import './style.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getcollaborator as onGetcollaborator,
  getXlsdata,
  postDep,
  postTeam,
  postContractType,
  postContract,
  postLinks,
  getContract as onGetContract,
  getAbsenceType,
  getAbsence,
  deleteAbsence,
  putAbsence,
  postAbsence,
  getCorrection,
  postCorrection,
  putCorrection,
  getContractEmployer,
  getFullAccess,
  getAbsenceTotal,
  getHoursTotal,
} from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import {sumTotalDayHours,
  convertTimeToDecimal,
  convertDecimalToTime,
  sumTotalPreviousDayHours,calculateAbsence
} from "../../components/Utils/AbsenceUtils";
const normalizeDateFormat = (formatString) => {
  // Replace incorrect tokens with valid date-fns tokens
  return formatString
    .replace(/DD/g, 'dd')   // Day tokens to lowercase
    .replace(/YYYY/g, 'yyyy'); // Year tokens to lowercase
};
const Absence = (props) => {
  const { user_id } = useParams();
  const {
      client,
      xlsid,
      contractdata,
      postDeps,
      postTeams,
      postContractTypes,
      link,
      contract,
      getabsence,
      getabsencetype,
      postabsence,
      deleteabsence,
      putabsence,
      getcorrection,
      postcorrection,
      putcorrection,
      contractemployer,
      useraccess,
      getTotalAbsence,
      ContractAndWorkHours,
      error
    } = useSelector(state => ({
      ContractAndWorkHours: state.getAbsenceData.ContractAndWorkHours,
      getTotalAbsence: state.getAbsenceData.getTotalAbsence,
      useraccess: state.getAbsenceData.useraccess,
      getabsencetype: state.getAbsenceData.getabsencetype,
      getabsence: state.getAbsenceData.getabsence,
      postabsence: state.getAbsenceData.postabsence,
      deleteabsence: state.getAbsenceData.deleteabsence,
      getcorrection: state.getAbsenceData.getcorrection,
      postcorrection: state.getAbsenceData.postcorrection,
      putcorrection: state.getAbsenceData.putcorrection,
      putabsence: state.getAbsenceData.putabsence,
      client: state.getClientData.client,
      xlsid: state.getClientData.xlsid,
      contractdata: state.getClientData.contractdata,
      postDeps: state.getClientData.postDeps,
      postTeams: state.getClientData.postTeams,
      postContractTypes: state.getClientData.postContractTypes,
      link: state.getClientData.link,
      contract: state.getClientData.contract,
      contractemployer: state.getShiftData.contractemployer,
      error: state.getClientData.error,
    }));
  
    
  const dispatch = useDispatch();
  const { t,clientData,isEditorAllowed } = props;
  const navigate = useNavigate();
  const authUser = localStorage.getItem("authUser");
  const user = JSON.parse(authUser);
  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [userId, setuserId] = useState(user.clientId);
  const [token, setToken] = useState(user.token);
  const [account_id , setAccountid ] = useState(user.account_id );
  const [company_name , setCompany_name ] = useState(user.company_name );
  const [timezone, setTimezone] = useState(user.timezone);
  const [DateTimeFormat, setDateFormat] = useState(normalizeDateFormat(user.datetimeformat));
  const getTotal = {
    id: user_id,
    year: selectedYear,
  };
  useEffect(() => {
    dispatch(getAbsenceType(account_id));
    dispatch(getAbsence(user_id));
    dispatch(getCorrection(user_id));
    dispatch(getContractEmployer(account_id));
    dispatch(getFullAccess(user_id));
    dispatch(getAbsenceTotal(getTotal));
    dispatch(getHoursTotal(getTotal));
  }, [dispatch,selectedYear,user_id, account_id]);
  
  const [modal_absence, setmodal_absence] = useState(false);
  const [modal_absence_cor, setmodal_absence_cor] = useState(false);
  const [updatemodal_center, setUpdatmodal_center] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate_up, setStartDate_up] = useState(null);
  const [correctionDate, setCorrectionDate] = useState(null);
  const [endDate_up, setEndDate_up] = useState(null);
  const [errors, setErrors] = useState({});
  const [currentPage_ab, setCurrentPage_ab] = useState(1);
  const [currentPage_cor, setCurrentPage_cor] = useState(1);
  const itemsPerPage = 3; 
  const [hoveredSection, setHoveredSection] = useState(null);
  const handleMouseEnter = (section) => setHoveredSection(section);
  const handleMouseLeave = () => setHoveredSection(null);
  const [action, setAction] = useState('');
  const [subAction, setSubAction] = useState('');
  const [sumAbsenceTbl, setSumAbsence] = useState("00:00");
  const [yearRange, setYearRange] = useState([]);
  const [newBalance, setNewBalance] = useState({
    EndBalance: '00 h : 00 m',
    Today: '00 h : 00 m',
    startSaldo: '00 h : 00 m',// ['10' ,'20']
    totalAbsence: '00:00 hours',//2024-05-31
    typeName: '', // 2025-05-31
  });
  const [absenceUpData, setAbsenceUpData] = useState({
    abseceid: null,
    typeid: null,
    worker_id: [user_id],// ['10' ,'20']
    worker_name:[clientData.frist_name],
    startdate: '',//2024-05-31
    enddate: '', // 2025-05-31
    description: null,
    absencestatus: 'approved',
    substatus: 'display',
    status:'vk',
    contractId:[],
    perday:[],
    days:[],
    account_id: account_id,
  });

  const [absenceData, setAbsenceData] = useState({
    account_id: account_id,
    typeid: null,
    worker_id: [user_id],// ['10' ,'20']
    worker_name:[clientData.frist_name],
    startdate: '',//2024-05-31
    enddate: '', // 2025-05-31
    description: null,
    absencestatus: 'approved',
    substatus: 'display',
    status:'vk',
    contractId:[],
    perday:[],
    days:[], 
  });

  const [absencecorData, setAbsencecorData] = useState({
    account_id: account_id,
    absence_type_id : 3,
    worker_id: [user_id],
    worker_name:[clientData.frist_name],
    correction_date: null,
    correction_amount: 0, 
    balance_before: 3,
    balance_after: 0,
    reason: null,
    action:'Correct',
    transfer_to:null,
    saldo:null,
    hours_in_day:null
  });

 
  useEffect(() => {
    if (ContractAndWorkHours && ContractAndWorkHours.total_work_hours) {
        const contractStartYear = new Date(ContractAndWorkHours.contract_Start).getFullYear();
        const contractEndYear = new Date(ContractAndWorkHours.contract_End).getFullYear();
        const yearsArray = Array.from(
          { length: contractEndYear - contractStartYear + 1 },
          (_, index) => contractStartYear + index
        );
  
        setYearRange(yearsArray);
        const filteredContracts = contractemployer.filter(contract => String(contract.worker_id) === `${user_id}`);
        const contractIds = filteredContracts.map(contract => contract.contrct_id);
  
        // Map `perday` and `days` from the filtered contracts, assuming `perday` and `days` are stored as strings.
        const workingHours = filteredContracts.map(contract => 
          contract.perday.split(',').map(Number) // Convert perday string to an array of numbers
        );
  
        const workingDays = filteredContracts.map(contract => 
          contract.days.split(',').map(day => day.trim()) // Convert days string to an array of day abbreviations
        );
  
        setAbsenceData(prevData => ({
          ...prevData,
          contractId: contractIds, 
          perday: workingHours, // Set the array of working hours
          days: workingDays,    // Set the array of working days
        }));
  
        setAbsenceUpData(prevData => ({
          ...prevData,
          perday: workingHours, 
          days: workingDays,    // Set the array of working days
        }));

        if (getabsence && getabsence.length > 0 && getTotalAbsence && getTotalAbsence.length > 0) {
          const sumAbsence = (getabsence.length > 0 && getabsence[0].time_off_accrual === 'contract_hours')
            ? calculateAbsence(getabsence, ContractAndWorkHours, getTotalAbsence[0], selectedYear) // Pass it as an array
            : calculateAbsence(getabsence, ContractAndWorkHours, getTotalAbsence[0], selectedYear);
            
          setSumAbsence(sumAbsence);
          
          correctionBalance();
          setAbsencecorData({
            ...absencecorData,
            saldo: sumAbsence.sumAbsence,
            balance_before: sumAbsence.sumAbsence,
          });
        }
  
    }
  }, [getabsence, getTotalAbsence, ContractAndWorkHours]);
  
  function formatDateuren_up(dateString) {
    const date = new Date(dateString); // Convert string to Date object
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }


  const formatTime = (decimalHours) => {
    const hours = Math.floor(decimalHours); // Get the whole number of hours
    const minutes = Math.round((decimalHours - hours) * 60); // Convert the fraction of hours to minutes
  
    // Ensure both hours and minutes are always 2 digits
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };


  function tog_absence() {
    setmodal_absence(!modal_absence);
    removeBodyCss();
  }

  function tog_absencecor() {
    setmodal_absence_cor(!modal_absence_cor);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const handleAbsenceTypeChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const status = selectedOption.getAttribute('data-status');
    const typeid = e.target.value;
    setAbsenceData({
      ...absenceData,
      typeid: typeid,
      status: status  // If you need to store status as well
    });
  };

  const handleAbsenceStatusChange = (e) => {
    const value = e.target.value;
    if (value === 'remove') {
      setAbsenceData({
        ...absenceData,
        substatus: value,
        status: 'wait'  // If you need to store status as well
      });
    }else{
      setAbsenceData({
        ...absenceData,
        substatus: value,
      });
    }
 
  };
  const handleUpdateInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setAbsenceData({
      ...absenceData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };
  

  
  function calculateTotalHours(startdate, enddate, perday, days) {
    // Split the working days and hours from the contract
    const workingDays = days.split(','); // e.g., ["mo", "tu", "we", "th", "fr"]
    const workingHours = perday.split(',').map(Number); // e.g., [5, 8, 8, 7, 5]
    const daysOfWeekNames = ["su", "mo", "tu", "we", "th", "fr", "sa"]; // Day names (Sunday to Saturday)
  
    // Convert startdate and enddate to Date objects
    const start = new Date(startdate);
    const end = new Date(enddate);
    
    let totalHours = 0;
    let currentDate = start;
    
    // Loop through the date range
    while (currentDate <= end) {
      // Get the day of the week (e.g., "mo", "tu", etc.)
      const dayOfWeek = daysOfWeekNames[currentDate.getUTCDay()]; // Get day index and map to short day name
      
      // Find if this day is a working day
      const dayIndex = workingDays.indexOf(dayOfWeek);
      
      // If it's a working day, add the corresponding hours
      if (dayIndex !== -1) {
        totalHours += workingHours[dayIndex];
      }
      
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    // Calculate full hours and minutes
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);
  
    // Format hours and minutes into HH:MM format
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  }

 

  function formatDateLocal(dateString) {
    const timezoneToLocale = (timezonesformat) => {
      switch (timezonesformat) {
        case "Europe/Amsterdam":
          return "nl-NL"; // Dutch (Netherlands)
        case "Europe/London":
          return "en-GB"; // English (United Kingdom)
        case "Europe/Paris":
          return "fr-FR"; // French (France)
        case "America/New_York":
          return "en-US"; // English (United States)
        // Add more timezones and their locales as needed
        default:
          return "en-US"; // Fallback to English (United States)
      }
    };

    const locale = timezoneToLocale(timezone);
    const date = new Date(dateString);
    // Define date formatting options
    const options = {
        weekday: 'short',  // Short weekday format, e.g., Mon, Tue
        year: 'numeric',   // Full year format, e.g., 2023
        month: '2-digit',  // Month as a two-digit number
        day: '2-digit',    // Day as a two-digit number
    };

    // Use Intl.DateTimeFormat for locale-specific formatting
    return new Intl.DateTimeFormat(locale, options).format(date);
  }

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setAbsenceData({
      ...absenceData,
      startdate: formatDateuren_up(start),
      enddate: formatDateuren_up(end),  
    });
    setStartDate(start);
    setEndDate(end);
  };

  const validateAbsenceForm = () => {
    const newErrors = {};

    if (!absenceData.account_id) newErrors.account_id = t('Account ID is required.');
    if (!absenceData.typeid) newErrors.typeid = t('Absence Type ID is required.');
    if (!absenceData.startdate) newErrors.startdate = t('Start date is required.');
    if (!absenceData.enddate) newErrors.enddate = t('End date is required.');
    if (!absenceData.worker_id.length) newErrors.worker_id = t('At least one worker ID is required.');
    if (!absenceData.absencestatus) newErrors.absencestatus = t('Inspection is required.');
    if (!absenceData.substatus) newErrors.substatus = t('Intermediate services is required.');
   // if (!absenceData.days.length) newErrors.days = t('At least one day is required.');
    
    return newErrors // Returns true if there are no errors
  };
  
  const handleAbsenceSubmit = (e) => {
    e.preventDefault();
    
    const validationErrors = validateAbsenceForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toastr.error(error));
      // Proceed with form submission or further logic
    } else {
        
       dispatch(postAbsence(absenceData));
  
      setmodal_absence(!modal_absence)
    }
  };


  const handleAbsenceTypeChange_up = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const status = selectedOption.getAttribute('data-status');
    const typeid = e.target.value;
    setAbsenceUpData({
      ...absenceUpData,
      typeid: typeid,
      status: status  // If you need to store status as well
    });
  };

  const handleAbsenceStatusChange_up = (e) => {
    const value = e.target.value;
    if (value === 'remove') {
      setAbsenceUpData({
        ...absenceUpData,
        substatus: value,
        status: 'wait'  // If you need to store status as well
      });
    }else{
      setAbsenceUpData({
        ...absenceUpData,
        substatus: value,
      });
    }
 
  };
  const handleUpdateInputChange_up = (e) => {
    const { id, value, type, checked } = e.target;
    setAbsenceUpData({
      ...absenceUpData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };
  
  
  const handleDateChange_up = (dates) => {
    const [start, end] = dates;
    setAbsenceUpData({
      ...absenceUpData,
      startdate: formatDateuren_up(start),
      enddate: formatDateuren_up(end),  
    });
    setStartDate_up(start);
    setEndDate_up(end);
  };

  const validateAbsenceForm_up = () => {
    const newErrors = {};
    if (!absenceUpData.abseceid) newErrors.abseceid = t('Absence ID is required.');
    if (!absenceUpData.typeid) newErrors.typeid = t('Absence Type ID is required.');
    if (!absenceUpData.startdate) newErrors.startdate = t('Start date is required.');
    if (!absenceUpData.enddate) newErrors.enddate = t('End date is required.');
    if (!absenceUpData.worker_id.length) newErrors.worker_id = t('At least one worker ID is required.');
    if (!absenceUpData.absencestatus) newErrors.absencestatus = t('Inspection is required.');
    if (!absenceUpData.substatus) newErrors.substatus = t('Intermediate services is required.');
    return newErrors // Returns true if there are no errors
  };
  
  const handleAbsenceSubmit_up = (e) => {
    e.preventDefault();
    
    const validationErrors = validateAbsenceForm_up();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toastr.error(error));
      // Proceed with form submission or further logic
    } else {
     
      dispatch(putAbsence(absenceUpData));
     setUpdatmodal_center(!updatemodal_center)
    }
  };
  function tog_Updatecenter() {
    setUpdatmodal_center(!updatemodal_center);
    removeBodyCss();
  }
  const openEditModal = (absence) => {
    const totalHours = calculateTotalHours(absence.startdate_absence, absence.enddate_absence,absence.perday,absence.days)
    const hours = totalHours.split(':')[0];
    setAbsenceUpData({
      account_id: account_id,
      abseceid: absence.abseceid,
      typeid:absence.typeid,
      worker_id:[absence.workerid],
      worker_name:[clientData.frist_name],
      startdate:formatDateuren_up(absence.startdate_absence),
      enddate:formatDateuren_up(absence.enddate_absence),
      description:absence.description_absence,
      absencestatus:absence.absencestatus,
      substatus:absence.substatus,
      contractId:[absence.ab_contrac_id], 
      totalHours:hours,
    });
    
    setStartDate_up(new Date(absence.startdate_absence));
    setEndDate_up(new Date(absence.enddate_absence));
    tog_Updatecenter(true);
    removeBodyCss();
  };

  const removeModal = (id) => {
    if (id) {
      
      dispatch(deleteAbsence(id));
      toastr.success(t('Form submitted.'));
    } else {
      toastr.success(t('Form validation failed.'));
      // Handle validation error
    }

  };

  

  const totalItems =  getabsence ? getabsence.length : 0;
const indexOfLastItem = currentPage_ab * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems =  getabsence ? getabsence.slice(indexOfFirstItem, indexOfLastItem): [];

const totalItems_cor = getcorrection ? getcorrection.length : 0;
const indexOfLastItem_cor = currentPage_cor * itemsPerPage;
const indexOfFirstItem_cor = indexOfLastItem_cor - itemsPerPage;
const currentItems_cor = getcorrection ? getcorrection.slice(indexOfFirstItem_cor, indexOfLastItem_cor): [];


const convertTimeToDecimals = (timeString) => {
  console.log('convertTimeToDecimals',timeString)
  if (typeof timeString !== 'string') {
    console.error("Invalid timeString:", timeString);
    return 0;  // Return 0 or another fallback value when the input is invalid
  }
  const [hours, minutes] = timeString.split(':').map(Number);;
  return hours + minutes / 60;
};
const correctionBalance = ( ) => {
  
  const EndBalance = sumAbsenceTbl.EndBalance
  const startSaldo = currentItems_cor.length > 0 ? currentItems_cor[0].balance_after : sumAbsenceTbl.startSaldo
  
  const totalAbsenceInDecimal = convertTimeToDecimals(sumAbsenceTbl.totalAbsence|| "00:00");
 const Today = currentItems_cor.length > 0 ? startSaldo - totalAbsenceInDecimal : sumAbsenceTbl.Today;
  const totalAbsence = sumAbsenceTbl.totalAbsence
  setNewBalance(prevData => ({
    ...prevData, 
    EndBalance: `${EndBalance} h : 00 m`,
    Today: `${Today}:00`,
    startSaldo: `${startSaldo} h : 00 m`,
    totalAbsence: totalAbsence,
    typeName: sumAbsenceTbl.typeName,
  }));
  
}



const handlePageChange = (newPage) => {
  setCurrentPage_ab(newPage);
}

const handlePageChange_cor = (newPage) => {
  setCurrentPage_cor(newPage);
}


const handleActionChange = (e) => {
  setAbsencecorData({
    ...absencecorData,
    action: e.target.value,
  });
  setAction(e.target.value); 
  if (e.target.value !== 'Transfer') {
    setSubAction(''); // Reset sub-action if it's not 'Transfer'
  }
};

const handleSubActionChange = (e) => {
  setAbsencecorData({
    ...absencecorData,
    transfer_to: e.target.value,
  });
  setSubAction(e.target.value); // Update sub-action state when a new sub-option is selected
};

const handleCorrectionAmountChange = (e) => {
  const newCorrectionAmount = parseFloat(e.target.value) || 0; // Ensure it's a number
  const balanceBefore = parseFloat(newBalance.startSaldo) || 0; // Ensure it's a number
  const newBalanceAfter = balanceBefore + newCorrectionAmount;
  setAbsencecorData({
    ...absencecorData,
    correction_amount: newCorrectionAmount,
    balance_after: newBalanceAfter.toFixed(2),
    balance_before: balanceBefore.toFixed(2),
  });
};


const handleAbsenceTypeChange_COR = (e) => {
  const selectedOption = e.target.options[e.target.selectedIndex];
  const balance = selectedOption.getAttribute('data-balance');
  const typeid = e.target.value;
  setAbsencecorData({
    ...absencecorData,
    absence_type_id: typeid,
  });
};

const handleCorrectionReasonChange = (e) => {
  setAbsencecorData({
    ...absencecorData,
    reason: e.target.value,
  });
  
};

const handleDateChange_cor = (selectedDate) => {
  if (!contractemployer || contractemployer.length === 0) {
    toastr.error(t('No contract data available"'));
    console.error("No contract data available");
    return;
  }

  // Find the relevant contract for the worker (based on worker_id or any other logic)
  const relevantContract = contractemployer.find(contract => `${contract.worker_id}` === `${user_id}`);
 
  if (!relevantContract) {
    toastr.error(t('Relevant contract data is missing or invalid'));
    console.error("Relevant contract data is missing or invalid");
    return;
  }

  // Convert the days and perday strings into arrays
  const daysArray = relevantContract.days.split(","); // ['mo', 'tu', 'we', 'th', 'fr']
  const hoursArray = relevantContract.perday.split(",").map(Number); // [5, 5, 5, 4, 2]

  // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
  const dayOfWeekIndex = new Date(selectedDate).getDay();

  // Map dayOfWeekIndex (0-6) to 'mo', 'tu', etc.
  const dayNames = ["su", "mo", "tu", "we", "th", "fr", "sa"];
  const selectedDayName = dayNames[dayOfWeekIndex];

  // Find if the selected day exists in the contract
  const dayIndexInContract = daysArray.indexOf(selectedDayName);

  // If the day is found, get the corresponding hours from the contract
  let hoursForSelectedDay = 0;
  if (dayIndexInContract !== -1) {
    hoursForSelectedDay = hoursArray[dayIndexInContract];
  } else {
  }
  // Set the absence data with the calculated hours for the selected day
  setAbsencecorData({
    ...absencecorData,
    correction_date: formatDateuren_up(selectedDate),
    hours_in_day: hoursForSelectedDay, // Set the hours for the selected day
  });

  setCorrectionDate(selectedDate);
};


const validateAbsenceForm_cor = () => {
  const newErrors = {};
  if (!absencecorData.absence_type_id) newErrors.absence_type_id = t('Absence Type ID is required.');
  if (!absencecorData.correction_date) newErrors.correction_date = t('End date is required.');
  if (!absencecorData.worker_id.length) newErrors.worker_id = t('At least one worker ID is required.');
  if (!absencecorData.correction_amount) newErrors.correction_amount = t('Inspection is required.');
  if (!absencecorData.action) newErrors.action = t('Intermediate services is required.');
  if (!absencecorData.balance_after) newErrors.balance_after = t('Intermediate services is required.');
  if (!absencecorData.balance_before) newErrors.balance_before = t('Intermediate services is required.');
  return newErrors // Returns true if there are no errors
};
const handleAbsenceSubmit_cor = (e) => {
  e.preventDefault();
  
  const validationErrors = validateAbsenceForm_cor();
  setErrors(validationErrors);
  if (Object.keys(validationErrors).length > 0) {
    Object.values(validationErrors).forEach((error) => toastr.error(error));
    // Proceed with form submission or further logic
  } else {
   
    dispatch(postCorrection(absencecorData));
    tog_absencecor(!updatemodal_center)
  }
};


useEffect(() => {
  const depSuccessHandler = (message) => {
    toastr.success(t(message));
    dispatch(getCorrection(user_id));
    dispatch(getAbsence(user_id));
    dispatch(getAbsenceTotal(getTotal));
    dispatch(getHoursTotal(getTotal));
  };
  
  const depErrorHandler = (message) => {
    toastr.error(t(message));
  };
  
  if (postcorrection) {
    postcorrection.success 
      ? depSuccessHandler('Your correction has been processed and is now reflected in the system.') 
      : depErrorHandler('Failed to submit the correction. Please try again later.');
  }
  if (deleteabsence) {
    deleteabsence.success 
      ? depSuccessHandler('Absence record removed. The schedule has been updated accordingly.') 
      : depErrorHandler('Failed to delete the absence. Please try again later.');
  }
  if (putabsence) {
    putabsence.success 
      ? depSuccessHandler('Absence record modified. The schedule has been updated accordingly.')
      : depErrorHandler('Failed to update the absence. Please try again later.');
  }
  
  if (postabsence) {
    postabsence.success 
      ? depSuccessHandler('Absence recorded successfully! The details have been saved and updated.') 
      : depErrorHandler('Failed to record the absence. Please try again or contact support if the issue persists.');
  }

}, [postcorrection, deleteabsence,putabsence,account_id,postabsence]);

  return (
    <React.Fragment>

<Modal
                      size="lg"
                      isOpen={modal_absence_cor}
                      toggle={() => {
                        tog_absencecor();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <div>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Add Correction')}
                        </h5>
                        </div>
                        
                        <button
                          onClick={() => {
                            tog_absencecor(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span >&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <form onSubmit={handleAbsenceSubmit_cor}>
  <div className="row">

    <div className="col-lg-12">
     <Row>
     <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-2 col-form-label">
        {t('Absence Type')}
        </label> 
        <div className="col-md-4" id="select_dep">
        {getabsencetype && getabsencetype.length > 0 ? (
  <select
   id="typeid" 
   name="typeid" 
   className="form-select"
   onChange={handleAbsenceTypeChange_COR} 
   defaultValue={absencecorData.absence_type_id}
  
   >
    <option value=''>
    {t('Select Absece Type')}
      </option>
    {getabsencetype.map(item => (
      <option key={item.absence_type_id}  data-status={item.count_as} value={item.absence_type_id}>
        {item.type_name}
      </option>
    ))}
  
  </select>
) : (
  <p>{t('No Record Found')}</p>
)}
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="Action" className="col-md-2 col-form-label">
        {t('Action')}
  </label>
  <div className="col-lg-4">
  <select
        className="form-select"
        name="Action"
        id="Action"
        value={action}
        onChange={handleActionChange}
      >
        <option value="">{t('Select Action')}</option>
        <option value="Correct">{t('Correct')}</option>
        <option value="Payout">{t('Payout')}</option>
        <option value="Transfer">{t('Transfer')}</option>
      </select>
      {action === 'Transfer' && (
        <div>
          <label htmlFor="subAction">{t('Transfer to')}</label>
          <select
            className="form-select"
            name="subAction"
            id="subAction"
            value={subAction}
            onChange={handleSubActionChange}
          >
            <option value="">{t('Select Transfer Option')}</option>
            <option value="plus_minus_hours">{t('Plus minus hours')}</option>
            <option value="time_off_balance">{t('Time off balance')}</option>
          </select>
        </div>
      )}
  </div>
      </div>

      </Col>
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="Period" className="col-md-2 col-form-label">
        {t('Correction Date')}
        </label>
        <div className="col-lg-4">
        <div className="css-q10huv">
        <div className="css-hboir5">
        <DatePicker
  selected={correctionDate} // Use state for single date
  onChange={handleDateChange_cor} // Update this function to handle a single date
  className="form-control"
  placeholderText="yyyy-mm-dd"
  dateFormat={'yyyy-MM-dd'} // Format the date according to locale
  inline={false} // So the datepicker opens as a dropdown
  //onClickOutside={() => setIsDatePickerOpen(false)} // Close on click outside
/>
          </div>
          </div>
    
        </div>
      </div>
       </Col>

       <Col lg="6">
       {/* balance_after */}
 <div>
          <label htmlFor="balance_after" className="css-iekl2y">{t('Correction Balace (hours):')}</label>
          <div className="css-hboir5">
            <Input
              className="css-12ihcxq"
              type="number"
              id="correction_amount"
              name="correction_amount"
              step="0.01"
              placeholder="0.00"
              defaultValue={absencecorData.correction_amount}
             onChange={handleCorrectionAmountChange }
            />
          </div>
        </div>
       </Col>
      <Col lg="12" style={{marginTop:'10px'}}>
      <div className="mb-3 row">
        <label htmlFor="reason" className="col-md-3 col-form-label">
        {t('reason')}
        </label>
        <div className="col-md-6">
        <textarea
              onChange={handleCorrectionReasonChange }
              id="reason"
              name="reason"
              className="form-control"
              rows="3"
            ></textarea>
        </div>
      </div>
      </Col>
     

      
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light"  onClick={() => {tog_absencecor(false)}}>       
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>

                      </div>
                    </Modal>
       <Modal
                      size="lg"
                      isOpen={updatemodal_center}
                      toggle={() => {
                        tog_Updatecenter();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <div>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Update Absence')}
                        </h5>
                        </div>
                        
                        <button
                          onClick={() => {
                            tog_Updatecenter(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span >&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <form onSubmit={handleAbsenceSubmit_up}>
  <div className="row">
    <div className="col-lg-12">
     <Row>
     <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-4 col-form-label">
        {t('Absence Type')}
        </label> 
        <div className="col-md-8" id="select_dep">
        {getabsencetype && getabsencetype.length > 0 ? (
  <select
   id="typeid" 
   name="typeid" 
   className="form-select"
   onChange={handleAbsenceTypeChange_up} 
   defaultValue={absenceUpData.typeid}
  
   >
    <option value=''>
    {t('Select Absece Type')}
      </option>
    {getabsencetype.map(item => (
      <option key={item.absence_type_id}  data-status={item.count_as} value={item.absence_type_id}>
        {item.type_name}
      </option>
    ))}
  </select>
) : (
  <p>{t('No Record Found')}</p>
)}
        </div>
      </div>
      </Col>

      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="Period" className="col-md-2 col-form-label">
        {t('Period')}
        </label>
        <div className="col-lg-8">
        <div className="css-q10huv">
        <div className="css-hboir5">
        <DatePicker  
          selected={startDate_up}
          onChange={handleDateChange_up}
          startDate={startDate_up}
          className="form-control "
          placeholderText="yyyy-mm-dd - yyyy-mm-dd"
          endDate={endDate_up}
          selectsRange
          dateFormat={'yyyy-MM-dd'} // Format according to locale
          inline={false} // So the datepicker opens as a dropdown
          // onClickOutside={() => setIsDatePickerOpen_up(false)} // Close on click outside
        />
          </div>
          </div>
    
        </div>
      </div>
       </Col>
  
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              
              id="description"
              name="description"
              className="form-control"
              rows="3"
              value={absenceUpData.description}
              onChange={handleUpdateInputChange_up}
            >{absenceUpData.description}</textarea>
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="approved" className="col-md-2 col-form-label">
        {t('Inspection')}
  </label>
  <div className="col-lg-4">
  <select
         className="form-select" 
         name="absencestatus"
         id="absencestatus"
        defaultValue={absenceUpData.absencestatus}
        onChange={handleUpdateInputChange_up}
        >

      <option value="approved">{t('approved')}</option>
      <option value="requested">{t('requested')}</option>
      <option value="rejected">{t('rejected')}</option>
        </select>

  </div>
      </div>

      </Col>

      <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="Period" className="col-md-4 col-form-label">
        {t('Intermediate services')}
  </label>
  <div className="col-lg-8">
  <select
         className="form-select" 
         id="substatus"
         name="substatus"
         defaultValue={absenceUpData.substatus}
         onChange={handleAbsenceStatusChange_up}
        >
      <option value="display">{t('Display in rooster')}</option>
      <option value="remove">{t('Remove from grid')}</option>
        </select>

  </div>
      </div>

      </Col>
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light"  onClick={() => {tog_Updatecenter(false)}}>       
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>

                      </div>
                    </Modal>
            <Modal
                      size="lg"
                      isOpen={modal_absence}
                      toggle={() => {
                        tog_absence();
                      }}
                      scrollable={true}
                    >
                      <div className="modal-header">
                        <div>
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          {t('Absence')}
                        </h5>
                        </div>
                        
                        <button
                          onClick={() => {
                            tog_absence(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span >&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <form onSubmit={handleAbsenceSubmit}>
  <div className="row">
    <div className="col-lg-12">
     <Row>
     <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="svc_dep" className="col-md-4 col-form-label">
        {t('Absence Type')}
        </label> 
        <div className="col-md-8" id="select_dep">
        {getabsencetype && getabsencetype.length > 0 ? (
  <select
   id="typeid" 
   name="typeid" 
   className="form-select"
   onChange={handleAbsenceTypeChange} 
   value={absenceData.typeid}
  
   >
    <option value=''>
    {t('Select Absece Type')}
      </option>
    {getabsencetype.map(item => (
      <option key={item.absence_type_id}  data-status={item.count_as} value={item.absence_type_id}>
        {item.type_name}
      </option>
    ))}
  </select>
) : (
  <p>{t('No Record Found')}</p>
)}
        </div>
      </div>
      </Col>

      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="Period" className="col-md-2 col-form-label">
        {t('Period')}
        </label>
        <div className="col-lg-8">
        <div className="css-q10huv">
        <div className="css-hboir5">
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          className="form-control "
          placeholderText="yyyy-mm-dd - yyyy-mm-dd"
          endDate={endDate}
          selectsRange
          dateFormat={'yyyy-MM-dd'} // Format according to locale
          inline={false} // So the datepicker opens as a dropdown
         // onClickOutside={() => setIsDatePickerOpen(false)} // Close on click outside
        />
          </div>
          </div>
    
        </div>
      </div>
       </Col>
  
      <Col lg="12">
      <div className="mb-3 row">
        <label htmlFor="description" className="col-md-3 col-form-label">
        {t('Description')}
        </label>
        <div className="col-md-9">
        <textarea
              
              id="description"
              name="description"
              className="form-control"
              rows="3"
              value={absenceData.description}
              onChange={handleUpdateInputChange}
            ></textarea>
        </div>
      </div>
      </Col>
      <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="approved" className="col-md-2 col-form-label">
        {t('Inspection')}
  </label>
  <div className="col-lg-4">
  <select
         className="form-select" 
         name="absencestatus"
         id="absencestatus"
        value={absenceData.absencestatus}
        onChange={handleUpdateInputChange}
        >

      <option value="approved">{t('approved')}</option>
      <option value="requested">{t('requested')}</option>
      <option value="rejected">{t('rejected')}</option>
        </select>

  </div>
      </div>

      </Col>

      <Col lg="12">
      <div className="mb-3 row">
      <label htmlFor="Period" className="col-md-4 col-form-label">
        {t('Intermediate services')}
  </label>
  <div className="col-lg-8">
  <select
         className="form-select" 
         id="substatus"
         name="substatus"
         value={absenceData.substatus}
         onChange={handleAbsenceStatusChange}
        >
      <option value="display">{t('Display in rooster')}</option>
      <option value="remove">{t('Remove from grid')}</option>
        </select>

  </div>
      </div>

      </Col>

         <Col lg="12">
      <div className="mb-3" style={{display:'flex',justifyContent:'center'}}>
        <div className="form-check form-checkbox-outline form-check-success mb-3">
        <Input className="form-check-input" name="send_notification" type="checkbox" id="send_notification" />
          <label className="form-check-label" htmlFor="send_notification">
          {t('Hide days of absence without value')}
          </label>
        </div>
      </div>
      </Col>
      </Row>
    </div>
    <div className="col-lg-4"></div>
  </div>
  <div className="modal-footer">
    <button type="button" className="btn btn-light"  onClick={() => {tog_absence(false)}}>
                            
                          
    {t('Cancel')}
    </button>
    <button type="submit" className="btn btn-primary">
    {t('Save')}
    </button>
  </div>
</form>

                      </div>
                    </Modal>
   <>
        <Row className="justify-content-center">
        <Button
        id="pdfButton1"
        type="button"
        className="btn  css-1bl3roz"
        style={{ background: '#0463b9', border: 'none',marginBottom:'15px' }}
        >
        {t('Absence')}:{clientData.frist_name}  
        </Button>  
        <div className="table-responsive" style={{marginTop:'20px'}}>
        <div
  id="pdfButton1"
  role="button"
  className="btn css-1bl3roz"
  style={{
    background: '#d6d8da',
    color: '#000000',
    border: 'none',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
>
  <span>
    {t('Leave balances')}: {clientData.frist_name}
  </span>
  <div className="mb-3 row" style={{ position: 'relative', top: '12px' }}>
    <div className="col-lg-12">
      <div className="css-q10huv">
        <div className="css-hboir5">
          <select
            className="css-12ihcxq"
            id="yearSelect"
            defaultValue={selectedYear}
            onChange={(e) => handleYearChange(e.target.value)}
          >
            {yearRange.length > 0 ? (
              yearRange.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))
            ) : (
              <option disabled>{t('No available years')}</option>
            )}
          </select>
          <span className="css-1baizyq">
            <span className="css-lzaifp">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16"
                height="16"
                focusable="false"
                fill="currentColor"
              >
                <path d="M4 5h8l-4 6z"></path>
              </svg>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

                      

                      <Table className="align-middle mb-0">

                    <thead>
                    <tr>
                    <th>{t('Name')}</th>
                    <th>{t('Today')}</th>
                    <th>{t('Taken')}</th>
                    <th>{t('Start saldo')}</th>
                    <th>{t('Eind Saldo')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td>{newBalance.typeName} </td>
                    <td>{newBalance.Today} </td>
                    <td>{newBalance.totalAbsence} </td>
                    <td>{newBalance.startSaldo} </td>
                    <td>{newBalance.EndBalance} </td>
                    </tr>
                  

                    </tbody>

                    </Table>
                    </div>


                    <div className="table-responsive" style={{marginTop:'20px'}}>
                    <div
  id="pdfButton1"
  role="button"
  className="btn css-1bl3roz"
  style={{
    background: '#d6d8da',
    color: '#000000',
    border: 'none',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
>
  <span>
    {t('Absence')}: {clientData.frist_name}
  </span>
  <div className="mb-3 row" style={{ position: 'relative', top: '7px' }}>
    <div className="btn-group sub-header__buttons">
      <button
        onClick={() => {
          if (absenceData.days.length === 0) {
            toastr.error(
              t('Contract is missing. Please ensure the Contract is set before proceeding.')
            );
          } else {
            tog_absence();
          }
        }}
        disabled={!isEditorAllowed}
        className="css-1m5o92us"
        type="button"
      >
        <span role="img" className="css-23dsfxs">
          <svg viewBox="0 0 16 16" height="100%" width="100%">
            <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
          </svg>
        </span>
        <span className="css-1ixbps20l d-none d-md-block">{t('Add Absence')}</span>
      </button>
    </div>
  </div>
</div>

                      <Table className="align-middle mb-0">

                        <thead>
                          <tr>
                            <th>{t('Absence Type')}</th>
                            <th>{t('Start date')}</th>
                            <th>{t('End date')}</th>
                            <th>{t('Hours')}</th>
                            <th>{t('Application date')}</th>
                            <th>{t('Status')}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {currentItems && currentItems.length > 0 ? (
  currentItems.map(item => (
    <tr key={item.abseceid }>
    <td
        style={{
          display:'flex',justifyContent:'center',color:'black',textTransform:'uppercase',
          // backgroundColor:
          // item.count_as === 'vk'
          //   ? '#b3d000'
          //   : item.count_as === 'zk'
          //   ? '#d05300'
          //   : '#cc00d0',
         }}
    >
    <i
    className={
      item.count_as === 'vk'
        ? 'bx bxs-plane'
        : item.count_as === 'zk'
        ? 'bx bxs-plus-square'
        : 'bx bxs-no-entry'
    }
    style={{
       marginRight: '8px',color:'#1a1a1a',padding:'4px',
       backgroundColor:
       item.count_as === 'vk'
         ? '#b3d000'
         : item.count_as === 'zk'
         ? '#d05300'
         : '#cc00d0',
       }}
  ></i>
    {item.count_as === 'vk' ? 'Vacation' : 
            locale === 'zk' ? 'Sick' : 
            'Non-attendance'}
    </td>
    <td>{formatDateLocal(item.startdate_absence)}</td>
    <td>{formatDateLocal(item.enddate_absence)}</td>
    <td>{calculateTotalHours(item.startdate_absence, item.enddate_absence,item.perday,item.days)}</td>
<td>{formatDateLocal(item.createtime_absence)}</td>
    <td
     style={{
      display:'flex',justifyContent:'center',color:'white',textTransform:'uppercase',
      backgroundColor:
      item.absencestatus === 'requested'
        ? '#368dfe'
        : item.absencestatus === 'approved'
        ? '#43b504'
        : '#b50404',
     }}
     >{t(item.absencestatus)}</td>
    <td>
  <button
   type="button" 
   className="btn btn-light btn-sm" 
   style={{ marginRight: '10px' }}
   disabled={!isEditorAllowed}
   onClick={() => openEditModal(item)}
   >{t('Edit')}</button>
  <button type="button"
   className="btn btn-light btn-sm"
   disabled={!isEditorAllowed}
   onClick={() => removeModal(item.abseceid )}
   >{t('Remove')}</button>
  </td>
  </tr>
  ))
) : (
  <tr>
        <td colSpan="7" style={{ textAlign: 'center' }}>
          <h6>{t('No Record Found')}</h6>
        </td>
      </tr>

)}
                  
        
                        </tbody>
                        <tfoot>
    <tr>
      <td colSpan="7">
        <Row>
          <Col xs="7">
            {t('Showing')}{' '}
            {currentPage_ab === 1
              ? 1
              : (currentPage_ab - 1) * itemsPerPage + 1}{' '}
            -{' '}
            {Math.min(currentPage_ab * itemsPerPage, totalItems)}{' '}
            {t('of')} {totalItems}
          </Col>
          <Col xs="5">
            <div className="btn-group float-end">
              <Button
                type="button"
                color="primary"
                size="sm"
                disabled={currentPage_ab === 1}
                onClick={() => handlePageChange(currentPage_ab - 1)}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <Button
                type="button"
                color="primary"
                size="sm"
                disabled={
                  currentPage_ab === Math.ceil(totalItems / itemsPerPage)
                }
                onClick={() => handlePageChange(currentPage_ab + 1)}
              >
                <i className="fa fa-chevron-right" />
              </Button>
            </div>
          </Col>
        </Row>
      </td>
    </tr>
  </tfoot>
                      </Table>
                    </div>

                    <div className="table-responsive" style={{marginTop:'20px'}}>
                    <div
  id="pdfButton1"
  role="button"
  className="btn css-1bl3roz"
  style={{
    background: '#d6d8da',
    color: '#000000',
    border: 'none',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
>
  {hoveredSection === 'Correction' && (
    <div
      onMouseEnter={() => handleMouseEnter('Correction')}
      onMouseLeave={handleMouseLeave}
      className="css-3nkkse scrollable-tooltip"
      id="css-3nkkse"
      style={{ left: '130px', top: '-4px' }}
    >
      <p
        className="css-1sad4s"
        style={{ overflow: 'auto' }}
      >
        {t(
          'A `correction` for absence refers to adjustments made to an employee’s time-off balance, which might be necessary due to discrepancies or administrative updates. These corrections ensure that the employee’s record reflects the accurate amount of time off they`ve earned, used, or need to account for.'
        )}
      </p>
    </div>
  )}
  <div
    className={`qouteStyle ${
      hoveredSection === 'Correction' ? 'active' : ''
    }`}
    onMouseEnter={() => handleMouseEnter('Correction')}
    onMouseLeave={handleMouseLeave}
  >
    {t('Correction')}:
    <span className="css-1rp7g54">
      <span className="css-1rpan28">
        <span className="css-zf56fy">
          <span className="SVGInline">
            <svg
              className="SVGInline-svg"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              focusable="false"
            >
              <path d="M9 6H7V4h2v2zm0 6H7V7h2v5z"></path>
              <path d="M8 2c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6m0-2C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z"></path>
            </svg>
          </span>
        </span>
      </span>
    </span>
  </div>
  <div className="mb-3 row" style={{ position: 'relative', top: '7px' }}>
    <div className="btn-group sub-header__buttons">
      <button
        onClick={() => {
          tog_absencecor();
        }}
        disabled={!isEditorAllowed}
        className="css-1m5o92us"
        type="button"
      >
        <span role="img" className="css-23dsfxs">
          <svg viewBox="0 0 16 16" height="100%" width="100%">
            <path d="M15 7H9V1c0-.55-.45-1-1-1S7 .45 7 1v6H1c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1V9h6c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
          </svg>
        </span>
        <span className="css-1ixbps20l d-none d-md-block">
          {t('Add Correction')}
        </span>
      </button>
    </div>
  </div>
</div>


        <Table className="align-middle mb-0">

                        <thead>
                          <tr>
                          <th>{t('balance on date')}</th>
                            <th>{t('Correction Balace')}</th>
                            <th>{t('New Balace')}</th>
                            <th>{t('Correction Date')}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {currentItems_cor && currentItems_cor.length > 0 ? (
  currentItems_cor.map(item => (
    <tr key={item.correction_id  }>
      <td>{formatDateLocal(item.correction_date)} </td>
      <td>{formatTime(item.correction_amount)}</td>
      
      <td>{formatTime(item.balance_after)}</td>
<td>{formatDateLocal(item.update_time)}</td>
    <td>
  <button
   type="button" 
   className="btn btn-light btn-sm" 
   style={{ marginRight: '10px' }}
   disabled={!isEditorAllowed}
   onClick={() => {tog_absencecor()}}
   >{t('Edit')}
   </button>
  </td>
  </tr>
  ))
) : (
  <tr>
  <td colSpan="7" style={{ textAlign: 'center' }}>
    <h6>{t('No Record Found')}</h6>
  </td>
</tr>
)}
                  
        
                        </tbody>

                      </Table>
            
                    </div>
       </Row>
       </>
    </React.Fragment>
  );
};
export default withTranslation()(withRouter(Absence));

